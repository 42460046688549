import React, { useState, useEffect, useRef } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import ForgotStyle from './ResetPassword.module.css';
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import myaxios from 'api/axios'// Importing Axios instance for API calls
import ErrorToast from 'components/ErrorToast/ErrorToast';// ErrorToast component
import forgotImg from '../../assets/images/forgotpass.svg'// Image for forgot password
import jwt_decode from 'jwt-decode';// JWT decoder
import { passwordEncryptor } from 'utility/passwordEncryptor.js'// Utility for password encryption

const ResetPassword = () => {

  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const navigate = useNavigate()
  const [token, setToken] = useState("");
  const [claims, setClaims] = useState({});

  const [data, setData] = useState({
    password: "",
    confirmPassword: ""
  })

  const [formErrors, setFormErrors] = useState({
    password: '',
    confirmPassword: ""
  });

   // Handle input field changes
  const handleInputField = (e) => {
    const { name, value } = e.target;


    setData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

   // Effect hook to decode JWT token
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.size > 0) {
      let token = params.get("token")
      setToken(token)
      let decoded = jwt_decode(token);

      setClaims({ email: decoded.sub, time: decoded.exp });
    }
    else {
      navigate("/login", { replace: true })
    }

  }, [])

// Validate password and confirm password
  const handleValidation = (e) => {
    const { name, value } = e.target;
    let errorMessage = '';

    if (name === 'password') {
      if (!value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)) {
        errorMessage =
          'Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, and one number.';
      }
    } else if (name === 'confirmPassword') {
      if (value !== data.password) {
        errorMessage = "Passwords don't match";
      }
    }
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const [errorMessage, setErrorMessage] = useState('');
  const passwordSubmit = async (e) => {
    setErrorMessage('')
    e.preventDefault();
    const hasErrors = Object.values(formErrors).some((error) => error !== '');
    const notEmpty = Object.values(data).some((dd) => dd.length < 1);

    if (!hasErrors && !notEmpty) {     
      const encPass = await passwordEncryptor(data.password, 'AesPassword12345');
      const encCPass = await passwordEncryptor(data.confirmPassword, 'AesPassword12345');

      await myaxios.post(`/procurement/resetPassword/${claims.email}`,
        {
          newPassword: encPass,
          confirmPassword: encCPass
        },
      )
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            navigate("/login")
          }

        }).catch((err) => {
          if (err.response.status == 400) {
            setErrorMessage("Old password cannot be a new password!");
          } else {
            setErrorMessage("Something went wrong, please try again later!");
          }
        })
    } else {
      setFormErrors((prev) => ({
        ...prev,
        fromError: "Please Check all the Feilds",
      }));
      setErrorMessage("Passwords don't match");

    }
  };



  return (
    <div className={ForgotStyle.mainPage}>
        {errorMessage && <ErrorToast message={errorMessage} />}
      
      <div className={ForgotStyle.container}>
        <div className={ForgotStyle.companyLogo} >
          <img src={forgotImg} alt="loading" />
          <h3>Reset password</h3>
        </div>
        <form action='' onSubmit={passwordSubmit} style={{ gap: '25px' }}
          className={` ${formStyle.inputForm} ${ForgotStyle.inputButton} `}  >
          <div className={formStyle.inputRow}>
            <input
              type="password"
              placeholder="New Password*"
              autoComplete="off"
              name="password"
              value={data.password}
              onChange={handleInputField}
              onBlur={handleValidation}
            />
            <span>{formErrors.password}</span>
          </div>

          {/* Re-enter New Password */}
          <div className={formStyle.inputRow}>
            <input
              type="password"
              placeholder="Re-enter New Password*"
              autoComplete="off"
              name="confirmPassword"
              value={data.confirmPassword}
              onChange={handleInputField}
              onBlur={handleValidation}
            />
            <span>{confirmPasswordError}</span>
          </div>

          <button type='submit' className={formStyle.formPrimarySubmit}>
            Reset Password
          </button>
          <Link to='/login' style={{ textAlign: 'center' }}> Back Login</Link>

        </form>

      </div>
    </div>
  )
}

export default ResetPassword
