import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import superadminStyle from '../../commonCss.module.css';
import Tooltip from '@mui/material/Tooltip';
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import DateFormater from 'utility/DateFormater';
import { useNavigate } from 'react-router-dom';
import { Modal, TextField } from '@mui/material';
import { Box, Typography } from '@mui/material';
import FranchiseStyle from './FranchiseList.module.css'
import Grid from '@mui/material/Grid';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import TableSorterDropDown from 'components/TableSorterDropDown/TableSorterDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import GroupSorterData from 'utility/GroupSorterData';
import BackButton from 'components/BackButton/BackButton';
import { HelpOutlineOutlined } from '@mui/icons-material';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 681,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  border: 'none',
  borderRadius: '8px',
};
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 365,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  border: 'none',
  borderRadius: '8px',
};
const shareEmailId = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 576,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  border: 'none',
  borderRadius: '8px',
};

const inputStyle = {
};

const FranchiseList = () => {

  // Importing custom hooks
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
  const { showToast, Toast } = useToast();
  const privateAxios = PrivateComponent();
  const navigate = useNavigate();

  // =====================search======================

  // State for search input value
  const [searchValue, setSearchValue] = useState('');

  // =====================search======================  

  // =====================data======================

  // State for holding fetched data
  const [data, setData] = useState([]);
  const [sorterData, setSorterData] = useState({});

  // Fetching data from API on component mount
  const getData = async () => {
    openBackdrop();// Show backdrop while fetching data
    try {

      const res = await privateAxios.get('/franchise/getAllFranchise');
      const { data } = res;
      setData(data);
      setSortedData(data);// Set data for sorting
      setSorterData(GroupSorterData(res.data)); // Group and sort data

    } catch (error) {
      showToast("error", error.message); // Show error toast if data fetching fails
    }
    closeBackdrop(); // Close backdrop after data fetching completes
  }

  useEffect(() => {
    getData();// Fetch data on component mount
  }, [])

  // =====================data======================


  // ===================sorting states==================

  // State for sorted data
  const [sortedData, setSortedData] = useState([]);

  // State for sorting dropdown
  const [sorterOpen, setSorterOpen] = useState("");

  // Toggle sorting dropdown
  const toggleSorter = (type) => {
    if (sorterOpen === type) {
      setSorterOpen("");
    }
    else {
      setSorterOpen(type);
    }

  }


 // Handle sorting by type and value
  const handleSorting = (type, value) => {

    if (value === "All") {
      setSortedData(data);
    }
    else {
      setSortedData(data.filter((dd) => dd[type] === value));
    }
  }

   // Handle date sorting
  const dateSorting = (type, value) => {
    let sortedData = []

    if (value === "asc") {
      sortedData = [...data].sort((a, b) => {
        return new Date(a[type]) - new Date(b[type]);
      });
    }
    else {
      sortedData = [...data].sort((a, b) => {
        return new Date(b[type]) - new Date(a[type]);
      });
    }

    setSortedData(sortedData);
  }

  // ===================sorting states==================


  // =====================handle active org======================

    // Handle enabling/disabling franchise
  const handleCheckBox = async (orgId, kamId, type) => {
    openBackdrop();
    try {
      let url = "";
      if (type) {
        url = `/procureadmin/DisableOrganisation`
      }
      else {
        url = `/procureadmin/enableOrganisation`

      }
      const res = await privateAxios.post(`${url}/${orgId}/${kamId}`);
      if (res.status === 200) {

        if (!type) {

          showToast("success", "Franchises Enabled Successfully");
        }
        else {
          showToast("success", "Franchises Disabled Successfully");
        }
      }
      getData();// Refresh data after enable/disable operation

    } catch (error) {
      showToast("error", error.message)
    }
    closeBackdrop();
  }

  // =====================handle active org======================

  // Add Franchise Modal 
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const handleOpen = () => {
    setOpen(true);
    setErrorMessage(''); // Clear previous error messages when opening the modal

  };

  const handleClose = () => {
    setOpen(false);
    setErrorMessage(''); // Clear error messages when closing the modal

  };
  //API integration Franchise 
  const [franchiseData, setFranchiseData] = useState({});

  const [formErrors, setFormErrors] = useState({});

  const handleValidation = (e) => {
    const { name, value } = e.target;
    let errorMessage = '';
    if (name === 'name') {
      if (value.trim() === '') {
        errorMessage = 'Name is required';
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        errorMessage = 'Name should only contain letters and spaces';
      }
    } else if (name === 'orgName') {
      if (value.trim() === '') {
        errorMessage = 'Organisation Name is required';
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        errorMessage = 'Organisation Name should only contain letters and spaces';
      }
    } else if (name === 'email') {
      if (value.trim() === '') {
        errorMessage = 'Mail id is required';
      }
      else if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
        errorMessage = 'Invalid Email Id'
      }
    } else if (name === 'phone') {
      if (value.length < 4) {
        errorMessage = 'Phone number is required.';
      }
      else if (value.length < 10) {
        errorMessage = 'Phone number length should be greater than 10 ';
      }
    }
    else if (name === 'gst') {
      if (value.trim() === '') {
        errorMessage = 'GST / VAT details are required';
      } else if (!/^([0-9]{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1})$/.test(value)) {
        errorMessage = 'Invalid GST format. It should be like XXAAAAA1234A1Z1.';
      }
    }


    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };



  const handleInputField = (e) => {
    const { name, value } = e.target;

    // Define a regular expression pattern that allows only letters and spaces
    const pattern = /^[a-zA-Z\s]*$/;

    if (name === 'name') {
      if (!pattern.test(value)) {
        // If the input doesn't match the pattern, remove unwanted characters
        const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
        setFranchiseData((prev) => {
          return {
            ...prev,
            [name]: filteredValue,
          };
        });
      } else {
        setFranchiseData((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    }
    else if (name === 'gst') {
      // Define a regular expression pattern for GST format (adjust as needed)
      const gstPattern = /^([0-9]{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1})$/;

      if (!gstPattern.test(value)) {
        // If the input doesn't match the GST pattern, remove unwanted characters
        const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
        setFranchiseData((prev) => {
          return {
            ...prev,
            [name]: filteredValue,
          };
        });
      } else {
        setFranchiseData((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    }
    else {
      setFranchiseData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const handleCreateFranchise = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    const hasErrors = Object.values(formErrors).some((error) => error !== '');
    const notEmpty = Object.values(franchiseData).some((dd) => dd.length < 1);
    if (!hasErrors && !notEmpty) {
      try {
        const payload = {
          orgName: franchiseData.orgName,
          name: franchiseData.name,
          email: franchiseData.email,
          phoneNo: franchiseData.phone,
          gstNo: franchiseData.gst
        }
        // Update the API endpoint and use franchiseData as the payload
        const res = await privateAxios.post('/franchise/createFranchise', payload);

        if (res.status === 200) {
          const { email, password } = res.data;
          setCreatedAccountDetails({ email, password });
          showToast('success', 'Franchise created successfully');
          handleCreateOpen();
          handleClose();
          getData(); // Refresh data after successful creation

        }
      } catch (error) {
        showToast('error', error.message);
      }
    }
    else {
      alert("please fill all the fields")
    }
    openBackdrop();



    closeBackdrop();
  };

   // State for created account details modal
  const [createdAccountDetails, setCreatedAccountDetails] = useState({ email: '', password: '' });


  // Create ID Password Modal
  const [createIdModal, setCreateId] = useState(false);

  const handleCreateOpen = () => {
    setCreateId(true);
  }

  const handleCreateClose = () => {
    setCreateId(false);
  }

  // Share Email-Id And Password 
  const [shareEmail, setShareEmail] = useState(false);

  const handleShareOpen = () => {
    setShareEmail(true);
    // handleShareFranchise(); // Call handleShareFranchise when the Share button is clicked
  }

  const handleShareClose = () => {
    setShareEmail(false);
  }
  // State for email and password in the Share modal
  const [shareEmailData, setShareEmailData] = useState({
    email: '',
    password: '',
  });

  const handleShareInputChange = (field, value) => {
    setShareEmailData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };




  return (
    <>
      <Toast />
      <BackdropComponent />
      <div className={workSpaceStyle.workSpaceMain}>

        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1 style={{ gap: '10px' }}>
              <BackButton />
              Franchises
            </h1>
          </div>
          <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
            <input type="text"
              onChange={(e) => setSearchValue(e.target.value)}

              value={searchValue}
              placeholder='Search Here...' />
          </div>

          <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: 'auto', minWidth: 'auto' }} >
            <button className={workSpaceStyle.headerbtn} style={{ backgroundColor: "#16469D", color: "white", cursor: 'pointer', }}
              onClick={handleOpen}
            >Add Franchise</button>

            <Tooltip title='help'>
              <a href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081296-franchises-tab" target="_blank">
                <HelpOutlineOutlined sx={{ cursor: 'pointer' }} />
              </a>
            </Tooltip>

          </div>
        </div>


        <div className={workSpaceStyle.workSpaceMainDiv}>

          <table className={superadminStyle.admintable}>
            <thead>
              <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
                <th>

                  <p onClick={() => toggleSorter("organisationName")}>Franchise Name
                    <span>{sorterOpen === "organisationName" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                  </p>
                  {
                    sorterOpen === "organisationName" && <TableSorterDropDown data={sorterData.organisationName} onClick={(item) => handleSorting("organisationName", item)} toggle={() => toggleSorter("organisationName")} />
                  }
                </th>
                <th>

                  <p onClick={() => toggleSorter("kamName")}>KAM Name
                    <span>{sorterOpen === "kamName" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                  </p>
                  {
                    sorterOpen === "kamName" && <TableSorterDropDown data={sorterData.kamName} onClick={(item) => handleSorting("kamName", item)} toggle={() => toggleSorter("kamName")} />
                  }
                </th>
                <th>

                  <p onClick={() => toggleSorter("kamEmail")}> Email
                    <span>{sorterOpen === "kamEmail" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                  </p>
                  {
                    sorterOpen === "kamEmail" && <TableSorterDropDown data={sorterData.kamEmail} onClick={(item) => handleSorting("kamEmail", item)} toggle={() => toggleSorter("kamEmail")} />
                  }
                </th>
                <th>

                  <p onClick={() => toggleSorter("registeredOn")}> Registerd On
                    <span>{sorterOpen === "registeredOn" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                  </p>
                  {
                    sorterOpen === "registeredOn" && <TableSorterDropDown type={"date"} data={sorterData.registeredOn} onClick={(item) => dateSorting("registeredOn", item)} toggle={() => toggleSorter("registeredOn")} />
                  }

                </th>
                <th>

                  <p onClick={() => toggleSorter("dueDate")}>  Due date
                    <span>{sorterOpen === "dueDate" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                  </p>
                  {
                    sorterOpen === "dueDate" && <TableSorterDropDown type={"date"} data={sorterData.dueDate} onClick={(item) => dateSorting("dueDate", item)} toggle={() => toggleSorter("dueDate")} />
                  }
                </th>
                <th>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>

              {
                sortedData
                  ?.filter((val) =>
                    val.organisationName.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                    val.kamName.toLowerCase().includes(searchValue.trim().toLowerCase())
                  )
                  .map((row, index) => {
                    return (
                      <tr key={index} className={superadminStyle.admintableRow}>
                        <td>
                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate(`/superadmin/franchises/franchises-approved/${row.kamId}/${row.id}`)}
                          >
                            {row.organisationName}
                          </p>
                        </td>
                        <td>{row.kamName}</td>
                        <td>{row.kamEmail}</td>
                        <td>{DateFormater(row.registeredOn)}</td>
                        <td>{row.dueDate.split("T")[0]}</td>
                        <td>
                          <div className={superadminStyle.actionIcons}>
                            <ToggleSwitch
                              checked={!row.isDisable}
                              name={`checkBox_${row.kamName}`}
                              onChange={() => handleCheckBox(row.id, row.kamId, !row.isDisable)}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
              }

            </tbody>
          </table>
        </div>


        <Modal
          open={open}>

          <div className={workSpaceStyle.modalCont}
            style={{
              width: "100%",
              maxWidth: "800px",
              minHeight: "auto",
              alignItems: "flex-start",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "20px"
            }}>


            <div style={{
              display: "flex",
              height: "100%",
              width: "100%",
              justifyContent: "flex-start",
              alignItems: "felx-start",
              flexDirection: "column",
              gap: "20px"

            }}>

              <div className={workSpaceStyle.modalHeader}>
                <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Create a franchise</h2>
                <span onClick={() => { handleClose(); setFormErrors({}) }}><CloseIcon /></span>
              </div>


              <form action="" onSubmit={handleCreateFranchise} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>

                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                  <div className={formStyle.inputRow}>
                    <label htmlFor="">Organisation / Franchise Name*</label>
                    <input
                      type="text"
                      placeholder="Organisation / Franchise Name"
                      rows="4"
                      name="orgName"
                      onChange={(e) => handleInputField(e)}
                      onBlur={(e) => handleValidation(e)}
                    />

                    <span className={FranchiseStyle.errorMessage}>{formErrors?.orgName}</span>
                  </div>
                  <div className={formStyle.inputRow}> <label htmlFor="">Name *</label>
                    <input
                      style={inputStyle}
                      type="text"
                      placeholder="KAM name"
                      rows="4"
                      name='name'
                      onChange={(e) => handleInputField(e)}
                      onBlur={(e) => handleValidation(e)}
                    />
                    <span className={FranchiseStyle.errorMessage}>{formErrors?.name}</span>
                  </div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                  <div className={formStyle.inputRow}>
                    <label htmlFor="">KAM / Owner Email ID*</label>
                    <input
                      style={inputStyle}
                      type="email"
                      placeholder="KAM Email"
                      rows="4"
                      name="email"
                      onChange={(e) => handleInputField(e)}
                      onBlur={(e) => handleValidation(e)}
                    />
                    <span className={FranchiseStyle.errorMessage}>
                      {formErrors?.email}
                    </span>
                  </div>
                  <div className={formStyle.inputRow}>
                    <label htmlFor="">Phone Number*</label>
                    <input
                      style={inputStyle}
                      type="text"
                      placeholder="KAM Phone Number"
                      rows="4"
                      name="phone"
                      onChange={(e) => handleInputField(e)}
                      onBlur={(e) => handleValidation(e)}
                    />
                    <span className={FranchiseStyle.errorMessage}>
                      {formErrors?.phone}
                    </span>
                  </div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}>
                  <div className={formStyle.inputRow}>
                    <label htmlFor="">GST  Number</label>
                    <input
                      style={inputStyle}
                      type="text"
                      placeholder="KAM GST Number"
                      rows="4"
                      name='gst'
                      onChange={(e) => handleInputField(e)}
                      onBlur={(e) => handleValidation(e)}
                    />
                    <span className={FranchiseStyle.errorMessage}>
                      {formErrors?.gst}
                    </span>
                  </div>
                </div>
                <div className={FranchiseStyle.createButton}>
                  <Button variant='contained' sx={{
                    backgroundColor: "#16469D", color: "white",
                    padding: '10px 29px 10px 29px', textTransform: 'capitalize'
                  }} type='submit'
                  >Create</Button>

                </div>
              </form>



            </div>
          </div>
        </Modal>

        <Modal
          open={createIdModal}
          // onClose={handleCreateClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style1} className={FranchiseStyle.createHeading}>
            <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
              <div className={FranchiseStyle.accCreated} style={{ margin: '0px', padding: "0px" }}>
                <h1>Account created</h1>
                <h2 onClick={handleCreateClose}><CloseIcon /></h2>
              </div>
              <p style={{ margin: '0px', padding: "0px" }}>Here are the account details</p>
            </div>


            <Grid container spacing={2} sx={{ display: 'flex' }}>

              <Grid item xs={12} className={FranchiseStyle.createGrid} sx={{ display: 'flex', flexDirection: 'column', gap: '5px', position: 'relative' }}>
                <label htmlFor="">User ID*</label>
                <input
                  style={inputStyle}
                  type="text"
                  placeholder="Enter Email"
                  value={createdAccountDetails.email}
                  readOnly

                />
                <FileCopyOutlinedIcon
                  style={{ cursor: 'pointer', position: 'absolute', bottom: '13px', right: '5px' }}
                  onClick={() => {

                    navigator.clipboard.writeText(createdAccountDetails.email);
                    showToast('success', 'Copied Email');
                  }}
                />
                {/* <span>< ContentCopyIcon /></span> */}
                <span>{formErrors.organizationName}</span>
              </Grid>
              <Grid item xs={12} className={FranchiseStyle.createGrid} sx={{ display: 'flex', flexDirection: 'column', gap: '5px', position: 'relative' }}>
                <label htmlFor="">Password*</label>
                <input
                  style={inputStyle}
                  type="text"
                  placeholder="Enter password"
                  value={createdAccountDetails.password}
                  readOnly
                />
                <FileCopyOutlinedIcon
                  style={{ cursor: 'pointer', position: 'absolute', bottom: '13px', right: '5px' }}
                  onClick={() => {

                    navigator.clipboard.writeText(createdAccountDetails.password);

                    showToast('success', 'Copied Password');
                  }}
                />
                <span>{formErrors.organizationName}</span>
              </Grid>


            </Grid>
            <div>
              <Button variant='contained'
                sx={{ backgroundColor: "#16469D", color: "white", textTransform: 'capitalize', padding: '10px 29px 10px 29px', }}
                onClick={handleCreateClose}>
                Done
              </Button>
            </div>
          </Box>
        </Modal>

      </div>
    </>
  )
}

export default FranchiseList


