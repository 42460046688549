import React, { useState } from 'react'
import styles from './CustomSelector.module.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CustomSelector = ({ children,value,style,disabled }) => {

    const [open, setOpen] = useState(false);
    
    const toggle = () => {
        if(disabled) return;
        setOpen(prev=>!prev);
    }

    return (
        <div className={open?`${styles.selectorContainer} ${styles.selectorContainerOpen}`:`${styles.selectorContainer}`} style={style}> 

            <div onClick={toggle} >

                <p className='' style={{textAlign:disabled?"center":"left",width:"100%"}}>{value}</p>
               { !disabled&& <span>
                    {
                        open?

                        <KeyboardArrowUpIcon />
                        :
                        <KeyboardArrowDownIcon />
                    }
                </span>}
            </div>

            {
                open&&
                <>
                <div className={styles.backDrop} onClick={toggle}></div>
                <ul className={styles.dropDownList}>
                    {
                        children
                    }
                    
                </ul>
                </>
            }

        </div>
    )
}

export default CustomSelector
