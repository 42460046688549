import React from 'react'
import { useState } from 'react';
import { Box, Button, Tooltip, TextField } from '@mui/material';
import ChattStyle from './ChattPanel.module.css'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { PrivateComponent } from 'api/axios';
import SendIcon from '@mui/icons-material/Send';
import useAuth from 'hooks/useAuth';
import { useRef } from 'react';

function ChattPanel({ handlecreateTaskModal, taskId, chats, setChats }) {

  const privateAxios = PrivateComponent();
  // New state for tab selection
  const [selectedTab, setSelectedTab] = useState('comment'); // Default: 'comments'
  const [commentInput, setCommentInput] = useState(''); // New state for comment input

  const { auth } = useAuth();




  // Function to handle tab selection
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const handleCommentInputChange = (event) => {
    setCommentInput(event.target.value);
  };
  // Sample comment data array
  const [comment, setComments] = useState([]);
  // Add a ref to the comment container element
  const commentContainerRef = useRef(null);
  // Function to scroll the comment container to the bottom
  const scrollToBottom = () => {
    if (commentContainerRef.current) {
      commentContainerRef.current.scrollTop = commentContainerRef.current.scrollHeight;
    }
  };

  const [chattHistory, setChattHistory] = useState([]);


  function getInitials(name) {
    const nameParts = name.split(" ");
    if (nameParts.length > 1) {
      return nameParts[0].charAt(0) + nameParts[1].charAt(0);
    } else {
      return nameParts[0].charAt(0);
    }
  }

  const handleDate = (createdAt) => {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);

    // Check if it's today
    if (
      currentDate.getDate() === createdDate.getDate() &&
      currentDate.getMonth() === createdDate.getMonth() &&
      currentDate.getFullYear() === createdDate.getFullYear()
    ) {
      // Format time as 'hh:mm AM/PM'
      const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
      const time = createdDate.toLocaleTimeString(undefined, timeOptions);
      return `Today  ${time}`;
    }

    // Check if it's yesterday
    currentDate.setDate(currentDate.getDate() - 1);
    if (
      currentDate.getDate() === createdDate.getDate() &&
      currentDate.getMonth() === createdDate.getMonth() &&
      currentDate.getFullYear() === createdDate.getFullYear()
    ) {
      // Format time as 'hh:mm AM/PM'
      const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
      const time = createdDate.toLocaleTimeString(undefined, timeOptions);
      return `Yesterday at ${time}`;
    }

    // If it's neither today nor yesterday, format it as 'yyyy-MM-dd hh:mm AM/PM'
    const options = { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", hour12: true };
    return createdDate.toLocaleDateString(undefined, options);
  };


  const handleLocalChat = (e) => {
    e.preventDefault();
    if (commentInput !== "") {

      let date = new Date()
      const lchat = {
        name: auth.name,
        createdAt: date.toISOString(),
        comment: commentInput,
      }
      setChats([...chats, lchat])
    }

    setCommentInput("");
  }





  return (
    <>
      <div className={ChattStyle.TaskChatPannelWrapper}>
        <div className={ChattStyle.chattSections} >

          <div className={ChattStyle.taskTabSelectorsWrapper}>
            <div className={ChattStyle.tabButtons}>
              <button
                className={selectedTab === 'comment' ? ChattStyle.activeTab : ''}
                onClick={() => handleTabChange('comment')}>
                Comment
              </button>
              <button
                className={selectedTab === 'chattHistory' ? ChattStyle.activeTab : ''}
                onClick={() => handleTabChange('chattHistory')}>
                Task History
              </button>
            </div>

            <div className={ChattStyle.handlecreateTaskModal}>
              <Tooltip title="Help">
                <HelpOutlineOutlinedIcon />
              </Tooltip>
              <span onClick={handlecreateTaskModal} >
                <Tooltip title="cancel" >
                  <ClearIcon />
                </Tooltip>
              </span>
            </div>
          </div>

          {selectedTab === 'comment' && (
            <div className={ChattStyle.chatBodyWrapper}>
              <div className={ChattStyle.commentsTab} style={{ overflowY: "auto", flexGrow: "1" }} ref={commentContainerRef}>
                {chats.map((comment, index) => (
                  <div key={index}
                    className={ChattStyle.commentContainer}>
                    <div className={ChattStyle.chatCard}>
                      <h1>{getInitials(comment.name.toUpperCase())} </h1>
                      <Box className={ChattStyle.commentDetailsWrapper}>
                        <Box className={ChattStyle.headings}>
                          <h2>{comment.name}</h2>
                          <h4>{handleDate(comment.createdAt)}</h4>
                        </Box>
                        <Box className={ChattStyle.discripton}>
                          <h3>{comment.comment}</h3>
                        </Box>
                      </Box>
                    </div>
                  </div>
                ))}
              </div>
              <div className={ChattStyle.commentFormWrapper}>
                <form onSubmit={handleLocalChat}
                  className={ChattStyle.commentForm}>
                  <input
                    placeholder="Write a comment..."
                    value={commentInput}
                    multiline  // Allow multiline input
                    onChange={handleCommentInputChange} />

                  <button type='submit'>
                    <SendIcon />
                  </button>

                </form>
              </div>

            </div>
          )}

          {selectedTab === 'chattHistory' && (
            <div className={ChattStyle.chattHistoryTab} style={{ overflowY: "auto", flexGrow: "1" }} ref={commentContainerRef}>
              {chattHistory.map((history, index) => (
                <div key={index} className={ChattStyle.historycircle}>
                  <div>
                    <h1>{getInitials(auth.name)}</h1>
                    <div>
                      <Box className={ChattStyle.historyHeadings}>
                        <h2>{history.name}</h2>
                        <h4>{handleDate(history.createdAt)}</h4>
                      </Box>
                      <h3 style={{ fontSize: '12px' }}>{history.logDetails} </h3>
                    </div>
                  </div>
                  {index !== chattHistory.length + 1 && <hr className={ChattStyle.commentDivider} />} {/* Add a divider if not the last history item */}
                </div>
              ))}
            </div>
          )}

        </div>
      </div>
    </>
  )
}

export default ChattPanel