import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import superadminApprovalStyle from '../../commonCss.module.css';
import superadminStyle from '../../commonCss.module.css';
import adminApprovalStyle from './OrganisationAppoval.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OrgProductSelector from 'components/OrgProductSelector/OrgProductSelector';


const OrganisationAppoval = () => {

    const { orgId, kamId } = useParams(); // Destructuring orgId and kamId from URL parameters
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Destructuring functions and component from custom hook useBackdrop
    const { showToast, Toast } = useToast(); // Destructuring functions and component from custom hook useToast
    const privateAxios = PrivateComponent(); // Creating instance of PrivateComponent from axios
    const [selectedItems, setSelectedItems] = useState([]); // State for selected items
    const navigate = useNavigate(); // Function for navigation

    const handleCheckboxChange = (product) => {// Function to handle checkbox change
        const allreadySelected = selectedItems.some((item) => item.id === product.id);// Checking if product is already selected
        if (allreadySelected) {
            setSelectedItems(selectedItems.filter((item) => item.id !== product.id))// If selected, remove it
        }
        else {
            setSelectedItems([...selectedItems, product])// If not selected, add it
        }
    }


    // =======================org details=============================

    const [orgData, setOrgData] = useState({})// State for organization data

    const getOrgData = async () => {// Function to fetch organization data
        openBackdrop();// Open backdrop
        try {
            const res = await privateAxios.get(`/procureadmin/getOrganisation/${orgId}`);
            const { data } = res;// Destructure data from response
            setOrgData(data); // Set organization data

        } catch (error) {
            showToast("error", "Error Getting Data")// Show error toast if error occurs
        }
        closeBackdrop();// Close backdrop
    }

    useEffect(() => {// Fetch organization data on component mount
        getOrgData();
    }, [])


    // ====================flat tree========================

    const flattenCategories = (categories, result = []) => {//Function to Flatten categories
        categories.forEach(category => {// Iterate throgh categories
            result.push(category);// Push category to result array
            if (category.branch && category.branch.length > 0) {//Check if category has branches
                flattenCategories(category.branch, result);// Recursively flatten branches
            }
            delete category.branch;//Delete branch property
        });
        return result;// Return flattened array
    }

    // ====================tree builder========================


    const buildTree = (categories) => {// Function to build structure from categories 
        let tree = [];//Initialize tree array
        let lookup = {};// Initialize lookup object

        categories.forEach(category => {//Iterate through categories    
            category.branch = [];//Initialize branch array
            lookup[category.categoryId] = category;//Add categoty lookup object 
        });

        categories.forEach(category => {// Iterate through categories again  
            if (category.parent !== null) {// Check if category has parent 
                const parent = lookup[category.parent];//Get parent category 
                parent.branch.push(category);//Add category to parent's branch
            } else {
                tree.push(category);//If no parent, add category to tree root
            }
        });
        return tree; //Return tree structure
    }

    // ===================================tree builder===============================================


    const [selectedProduct, setSelectedProduct] = useState([]); // State for selected product
    const [selectedCategories, setSelectedCategories] = useState([]); // State for selected categories

    const getProductData = async () => {// Function to fetch product data
        openBackdrop();// Open backdrop
        try {
            const productRes = await privateAxios.get(`/procureadmin/getAllProductRequests/${orgId}`);// Fetch product data
            const productData = productRes.data; // Destructure data from response
            setSelectedProduct(productData); // Set selected product data
        } catch (error) {
            showToast("error", "Error Getting ProductData")// Show error toast if error occurs
        }
        closeBackdrop(); // Close backdrop
    }

    useEffect(() => {
        getProductData();// Fetch product data when organization data changes
    }, [orgData])




    //  ====================get category path==========================

    const getCategoryPath = (categoryId) => { // Function to get category path

        const category = selectedCategories.find((category) => category.categoryId === categoryId); // Find category by categoryId
        if (category) {
            return category.parent ? `${getCategoryPath(category.parent)} > ${category.categoryName}` : category.categoryName;// If parent exists, recursively get category path
        }
        return '';// Return empty string if category not found
    }


    // ====================add product modal========================

    const [addProduct, setAddProduct] = useState(false);// State for add product modal visibility

    const handleAddProductModal = () => {// Function to toggle add product modal
        setAddProduct(prev => !prev);
    }


    // ====================handle add product success modal================

    const showAddProductSuccess = (type) => {// Function to show add product success modal
        handleAddProductModal(); // Close add product modal

        if (type) {
            showToast("success", "Product Added Successfully")// Show success toast if type is true
        }
        else {
            showToast("error", "Error Adding Product") // Show error toast if type is false
        }
    }


    // ====================remove product========================


    const handleRemoveProduct = (productId) => () => { // Function to remove product

        setSelectedItems(prev => {// Update selected items state
            return prev.filter((item) => item.productId !== productId);// Remove product from selected items

        });

        setSelectedProduct(prev => {// Update selected product state
            return prev.filter((item) => item.productId !== productId)// Remove product from selected product

        });

    }

    // ====================Match Category with product============================

    const findAllUpperLevelCategory = (categoryId) => { // Function to find all upper level categories
        const category = selectedCategories.find((category) => category.categoryId === categoryId);
        if (category) {
            // console.log(category);
            return category.parent ? [category, ...findAllUpperLevelCategory(category.parent)] : [category];
        }
        return [];

    }


    // Function to match categories with selected products
    const matchCategoryWithProduct = () => {
        let matchingCategory = []; // Initialize array to store matching categories
        if (selectedItems.length === 0) {// Check if any product is selected
            showToast("error", "Please Select Atlest One Product");// Show error toast if no product is selected
        }
        else {
            selectedItems.forEach((product) => { // Iterate through selected product
                const category = selectedCategories.find((category) => category.categoryId === product.categoryId);// Find category of each product
                if (category) {
                    const categoryArray = findAllUpperLevelCategory(category.categoryId);// Find all upper level categories of current category

                    if (matchingCategory.length === 0) {
                        matchingCategory = [...categoryArray]; // If no matching category found yet, assign current categories to matchingCategory
                    }
                    else {
                        let updatedCategories = [...matchingCategory]; // Copy current matching categories
                        categoryArray.forEach((category) => { // Iterate through found categories
                            if (!matchingCategory.some((item) => item.categoryId === category.categoryId)) {
                                updatedCategories = [...updatedCategories, category];// Add category to matchingCategory if not already present
                            }
                        });

                        matchingCategory = updatedCategories;// Update matchingCategory with updated categories
                    }
                }
            })
        }
        return matchingCategory;// Return matching categories
    }


    // ====================Approve Organisation=========================

    // Function to approve organization
    const handleApproveOrganisation = async () => {
        openBackdrop();// Open backdrop
        try {
            if (selectedItems.length !== 0) { // Check if any product is selected
                const payload = {// Prepare payload for API request
                    ids: selectedItems.map((item) => item.reqId), // Extract IDs of selected items
                    orgId: orgId,
                }
                const res = await privateAxios.post(`/procureadmin/organisationApproval/${orgId}/${kamId}`); // Approve organization
                const productRes = await privateAxios.post(`/procureadmin/approveProducts`, payload); // Approve products associated with organization

                if (res.status === 200 && productRes.status === 200) {// If both requests are successful
                    showToast("success", "Organisation Approved Successfully"); // Show success toast
                    navigate("/superadmin/organisation-requests")// Navigate to organization requests page
                }
                else {
                    showToast("error", "Error Approving Organisation")// Show error toast
                }
                getOrgData(); // Fetch updated organization data
                getProductData(); // Fetch updated product data
            }
            else {
                showToast("error", "Please Select Atlest One Product");// Show error toast if no product is selected
            }
        } catch (error) {
            showToast("error", "Error Approving Organisation"); // Show error toast if approval fails
        }

        closeBackdrop();// Close backdrop

    }


    // ====================disable Organisation===========================

    // Function to disable organization
    const handleDisableOrganisation = async () => {
        openBackdrop(); // Open backdrop
        try {
            const res = await privateAxios.post(`/procureadmin/DeclineOrganisation/${orgId}/${kamId}`); // Disable organization
            if (res.status === 200) { // If request is successful
                showToast("success", "Organisation Rejected Successfully");// Show success toast
            }
            getOrgData(); // Fetch updated organization data
        } catch (error) {
            showToast("error", "Error Rejecting Organisation"); // Show error toast if rejection fails
        }
        closeBackdrop();// Close backdrop
    }



    // ====================handle add product ================

    // Function to add products
    const handleAddProduct = async (data) => {
        openBackdrop(); // Open backdrop
        try {
            const { products, categories } = data; // Destructure products and categories from data
            const catRes = await privateAxios.post(`/procureadmin/addApprovedCategories/${orgId}`, categories); // Add approved categories
            if (catRes.status === 200) { // If categories added successfully
                const prodRes = await privateAxios.post(`/procureadmin/addProductsInApproval/${orgId}`, products);// Add products in approval
                if (prodRes.status === 200) {
                    showToast("success", "product Added Successfully");// Show success toast
                }
                else {
                    showToast("error", "Error Adding Product"); // Show error toast if adding product fails
                }
            }
            else {
                showToast("error", "Error Adding Category"); // Show error toast if adding category fails

            }
            getProductData(); // Fetch updated product data
            handleAddProductModal(); // Close add product modal
        }
        catch (error) {
            showToast("error", error.message);// Show error toast if any error occurs
        }

        closeBackdrop();// Close backdrop
    }


    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain} style={{ padding: "20px" }}>


                <div className={adminApprovalStyle.adminFirstContainer}>
                    <div className={adminApprovalStyle.adminFirstInsideContainer}>
                        <div className={adminApprovalStyle.adminHeadSection}>
                            <p>Approval request from <strong>{orgData.organisationName}</strong></p>
                        </div>

                        <form action='' className={adminApprovalStyle.AdminInfoTable}>

                            <div className={adminApprovalStyle.formAdminInfoColumn}>

                                <div className={adminApprovalStyle.formAdminGroup}>
                                    <label>Organisation Name</label>
                                    <h5>
                                        {orgData.organisationName}
                                    </h5>
                                </div>
                                <div className={adminApprovalStyle.formAdminGroup}>
                                    <label>KAM Email ID</label>
                                    <h5>
                                        {orgData.kamEmail}
                                    </h5>

                                </div>
                                <div className={adminApprovalStyle.formAdminGroup}>
                                    <label>Managed by</label>
                                    <h5>
                                        {orgData.kamName}
                                    </h5>

                                </div>


                            </div>
                            <div className={adminApprovalStyle.formAdminInfoColumn}>
                                <div className={adminApprovalStyle.formAdminGroup}>
                                    <label>KAM Phone Number</label>
                                    <h5>
                                        {orgData.phone}
                                    </h5>

                                </div>
                                <div className={adminApprovalStyle.formAdminGroup}>
                                    <label>GST/VAT Details</label>
                                    <h5>
                                        {orgData.gst}
                                    </h5>

                                </div>
                                <div className={adminApprovalStyle.formAdminGroup}>
                                    <label>Registered on</label>
                                    <h5>
                                        {orgData?.registeredOn?.split("T")[0]}
                                    </h5>

                                </div>
                            </div>
                        </form>

                    </div>
                </div>


                <div className={adminApprovalStyle.adminSecondContainer}>
                    <div className={adminApprovalStyle.adminSecondHeading}>
                        <div className={adminApprovalStyle.headpart}>
                            Requested Product
                        </div>
                        <div className={adminApprovalStyle.headpart}>
                            <button onClick={handleAddProductModal}><span>+</span> Product  </button>
                        </div>
                    </div>

                    <div className={superadminApprovalStyle.productTableWrapper}>


                        <table className={superadminStyle.productTable}>
                            <thead>
                                <tr className={`${superadminStyle.productTableRow} ${superadminStyle.productTableHeader} `}>
                                    <th>
                                        Products ({selectedProduct?.length})
                                    </th>
                                    <th>
                                        <input
                                            style={{ cursor: 'pointer' }}
                                            onChange={() => selectedItems.length === selectedProduct.length ? setSelectedItems([]) : setSelectedItems(selectedProduct)}
                                            checked={selectedItems.length === selectedProduct.length}
                                            type="checkbox" />
                                    </th>
                                    {/* <th style={{width:"5%"}}></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {selectedProduct?.map((product) => (
                                    <tr className={superadminStyle.productTableRow}>
                                        <td>
                                            <div className={superadminStyle.productTableDesc}>
                                                <h4>{product.productName}</h4>
                                                <span>{product.branch}</span>

                                            </div>
                                        </td>
                                        <td>
                                            <input
                                                style={{ cursor: 'pointer' }}
                                                onChange={() => handleCheckboxChange(product)}
                                                checked={selectedItems.some((item) => item.id === product.id)}
                                                type="checkbox"
                                            />
                                        </td>
                                        {/* <td>
                                            <span onClick={handleRemoveProduct(product.productId)}>
                                                <CloseIcon />
                                            </span>
                                        </td> */}
                                    </tr>

                                ))}
                            </tbody>

                        </table>
                    </div>
                </div>

                <div className={superadminApprovalStyle.decisionButton} style={{ height: "60px" }}>
                    {
                        orgData.isRequested
                            ?
                            <>
                                <button className={superadminApprovalStyle.rejectButton}>Reject</button>
                                <button className={superadminApprovalStyle.approveButton} onClick={handleApproveOrganisation}>Approve</button>
                            </>
                            :
                            orgData.isAccepted ?
                                <button className={superadminApprovalStyle.approveButton} style={{ backgroundColor: "green" }}>Approved</button>
                                :
                                <button className={superadminApprovalStyle.rejectButton} style={{ backgroundColor: "red", color: "white" }} onClick={handleDisableOrganisation}>Rejected</button>
                    }

                </div>
            </div>


            <Modal
                open={addProduct}
                onClose={handleAddProductModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>


                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "felx-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Add Product</h2>
                            <span onClick={handleAddProductModal}><CloseIcon /></span>
                        </div>


                        <OrgProductSelector productsArray={selectedProduct} handleAddProductModal={handleAddProductModal} onsubmit={handleAddProduct} orgId={orgId} />
                    </div>



                </div>


            </Modal>


        </>
    )
}

export default OrganisationAppoval
