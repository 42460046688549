import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import modalStyle from 'components/ModalCommonCSS/ModalCommonCSS.module.css'
import SendIcon from '@mui/icons-material/Send';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import TaskStyle from "../TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { Typography } from "@mui/material";
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import Modal from '@mui/material/Modal';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import { useEffect } from 'react';
import { PrivateComponent } from 'api/axios';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import Alert from '@mui/material/Alert';
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast'


const UserListComponent = ({ getAllUser, usersStyle, role, data, setSortOrder, workSpace, sortOrder, allUsersData, seachString }) => {
    // Import custom hooks
    const { showToast, Toast } = useToast();
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

    // States for modals, form data, and errors
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentUser, setCurrentUser] = useState("");
    const privateAxios = PrivateComponent();
    const [userId, setUserId] = useState("");
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        accessRole: "",
        designation: "",
        workSpace: ""
    })
    const [editUserModal, setEditUserModal] = useState(false);
    const [filterData, setFilterData] = useState([]);

    // States for categories and form errors
    const [categories, setCategories] = useState([]);
    const [formError, setFormErrors] = useState({
        name: "",
        email: "",
        accessRole: "",
        designation: "",
        workSpace: "",
        formError: ""
    })

    // Function to handle user deletion
    const handleDeleteUser = async (userId) => {
        openBackdrop();
        try {
            await privateAxios.delete(`/adminuser/disableUser/${userId}`);
            handleDeleteTaskModal();
            setCurrentUser("");
            getAllUser();
            showToast("success", "User Removed Successfully")
        } catch (error) {
            console.error('Error deleting vendor:', error);
            showToast("error", "Error Removing User")
        }
        closeBackdrop();
    };

    // handle Delete task modal
    const handleDeleteTaskModal = (userId) => {
        setDeleteModal(prev => !prev);

    }

    // Update form data when categories change
    useEffect(() => {
        setFormData(prev => {
            return {
                ...prev,
                workSpace: categories,
            }
        })

        if (categories?.length > 0) {
            // If categories are selected, clear form error
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                workSpace: '',
                formError: ""
            }));
        } else {
            // If no categories are selected, show form error
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                workSpace: 'Select at least one category',
                formError: ""
            }));
        }
    }, [categories])

    // Function to handle edit user action
    const handleEditData = (data) => {
        let WorkSpaceArray = data?.kamWorkspaceEntity?.map((er) => {
            return er.userWorkspaceName;
        })
        setFormData({
            name: data.name,
            email: data.email,
            accessRole: role.toUpperCase(),
            designation: data.designation.name,
            workSpace: "",
        })
        setCategories(WorkSpaceArray);
    }

    // Function to toggle edit user modal
    const handleEditUserModal = (id) => {
        setEditUserModal(prev => !prev)
        setUserId(id)
    }

    // Function to handle form submission
    const handleFormSubmit = (e) => {
        e.preventDefault();
    }

    // Function to handle form input changes
    const handleInput = (e) => {
        setFormData(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })
    }

    // Update filtered data when workspace changes
    useEffect(() => {
        if (workSpace.toLowerCase() === "all") {
            setFilterData(data);
        }
        else {
            let fill = data?.filter(userData => userData?.kamWorkspaceEntity?.some(ws => {
                return ws.userWorkspaceName.toLowerCase() === workSpace?.toLowerCase()
            }))

            setFilterData(fill);
        }
    }, [workSpace, data]);




    // Function to handle resend invite action
    const handleResendInvite = async (userId, sendOrNot) => {
        openBackdrop()
        try {
            if (!sendOrNot) {
                await privateAxios.post(`/adminuser/resendInvite/${userId}`);// Send resend invite request
                setShowSuccessAlert(true);// Show success alert
            }
            else {
                alert("user is alreaded loged in") // Show alert if user is already logged in
            }
            closeBackdrop() // Close backdrop loader

        } catch (error) {
            console.error('Error resending invite:', error);
        }
        closeBackdrop()// Close backdrop loader
    };

    // Function to handle edit form submission
    const handleEditFormSubmit = async (e) => {
        e.preventDefault();
        try {
            await privateAxios.post(`/adminuser/editUser/${userId}`, {
                role: formData.accessRole,
                designation: formData.designation,
                workspace: formData.workSpace
            });// Send edit user request
            getAllUser(); // Refresh user list
            handleEditUserModal(); // Close edit user modal
            showToast("success", "User Updated Successfully") // Show success toast
        } catch (error) {
            console.error('Error updating user:', error);// Log error
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                formError: 'Error updating user'
            }));
            showToast("error", "Error Updating User") // Show error toast
        }

    };



    // Function to format last seen time
    const handleLastSeen = (loginTime) => {
        const loginDate = new Date(loginTime);
        const currentDate = new Date();
        const timeDifference = currentDate - loginDate;
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
        const daysDifference = Math.floor(hoursDifference / 24);

        let displayString;

        if (daysDifference > 0) {
            // If the difference is more than 1 day, display the date
            displayString = `${loginDate.toDateString()}`;
        } else if (hoursDifference > 0) {
            // If the difference is more than 1 hour, display the hours
            displayString = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else if (minutesDifference > 0) {
            // If the difference is more than 1 minute, display the minutes
            displayString = `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
        } else {
            // If the login was within the last minute, display "Just now"
            displayString = "Just now";
        }

        return displayString;
    }

    // Hide success alert after certain time
    useEffect(() => {
        let timeoutId;

        if (showSuccessAlert) {
            timeoutId = setTimeout(() => {
                setShowSuccessAlert(false);
            }, 5000); // 3000 milliseconds (3 seconds)
        }

        return () => {
            clearTimeout(timeoutId); // Clear the timeout if the component unmounts or the alert is hidden before 3 seconds
        };
    }, [showSuccessAlert]);









    return (

        <>
            <Toast />
            <div>
                {
                    filterData.filter((item) => item.name.trim().toLowerCase().includes(seachString?.toLowerCase()))
                        .map((userData, index) => {
                            if (!userData.disable) {
                                return (

                                    <ul className={`${usersStyle.userTableData} ${usersStyle.userTablerow}`} key={index}>
                                        <li className={usersStyle.userTableName}><ProfileIcon data={{ email: userData?.email }} height={"26px"} width={"26px"} showProfileBanner={userData.name == "No Name" ? false : true} /> <p>{userData?.name}</p></li>
                                        <li className={usersStyle.userTableEmail}><Tooltip title={userData?.email}>{`${userData?.email.slice(0, 18)}...`} </Tooltip></li>
                                        <li className={usersStyle.userTableDesignation}>{userData?.designation?.name}</li>
                                        <li className={usersStyle.userTableWorkspace}>{userData?.kamWorkspaceEntity?.map(tt => { return (<p>{tt.userWorkspaceName}</p>) })} </li>
                                        <li className={usersStyle.userTableActive}>{userData?.loggedIn ? handleLastSeen(userData.user.loginTime) : <>Never logged in <div><ErrorOutlineIcon /></div> </>}</li>

                                        <li className={usersStyle.userTableAction}>
                                            <i onClick={() => { handleEditUserModal(userData.id); handleEditData(userData) }} >
                                                <Tooltip title='Edit'><EditIcon /></Tooltip>
                                            </i>
                                            <i>
                                                <Tooltip title='delete' onClick={() => { handleDeleteTaskModal(); setCurrentUser(userData.id) }}>
                                                    <DeleteOutlineIcon /></Tooltip>
                                            </i>
                                            <i >
                                                {!userData.loggedIn && (
                                                    <Tooltip title='send' onClick={() => handleResendInvite(userData.id, userData?.loggedIn)}>
                                                        <SendIcon /></Tooltip>
                                                )}
                                            </i>
                                        </li>
                                    </ul>
                                )
                            }
                        })}
            </div>



            {showSuccessAlert && (
                <div style={{ display: 'flex', minWidth: "max-content", left: "50%", justifyContent: 'center', alignItems: 'center', position: "fixed", bottom: "100px" }}>
                    <Alert severity="success"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}>Invite sent successfully</Alert>
                </div>
            )}

            <Modal
                open={editUserModal}
            // onClose={handleEditUserModal}
            >
                <div className={modalStyle.modalContainer}>
                    <form
                        onSubmit={handleEditFormSubmit}
                        action="" className={modalStyle.formContainer} style={{ gap: "15px" }}>
                        <div className={modalStyle.modalHeader}
                        // onClick={handleEditUserModal}
                        >

                            <h3>Edit user</h3>
                            <span onClick={handleEditUserModal}>
                                <CloseIcon />
                            </span>
                        </div>

                        <div className={modalStyle.formGroup}>
                            <label htmlFor=""> Name:</label>
                            <input disabled type="text" value={formData.name} onChange={handleInput} name='name' placeholder='Enter Emails Separated by commas' />
                            <span>{formError.name}</span>
                        </div>
                        <div className={modalStyle.formGroup}>
                            <label htmlFor=""> Email:</label>
                            <input disabled type="text" value={formData.email} onChange={handleInput} name='email' placeholder='Enter Emails Separated by commas' />
                            <span>{formError.email}</span>
                        </div>

                        <div className={modalStyle.formGroup}>
                            <label htmlFor=""> Access Role:</label>

                            <select
                                value={formData.accessRole}
                                onChange={handleInput}
                                className={formStyle.formSelect}
                                name='accessRole'>
                                <option value="" style={{ color: "#878699" }}>Plese Select a Role</option>
                                {/* <option value="kam">Key Access Manager</option> */}
                                <option value="ADMIN">Admin</option>
                                <option value="USER">User</option>
                            </select>
                            <span>{formError.accessRole}</span>
                        </div>
                        <div className={modalStyle.formGroup}>
                            <label htmlFor=""> Designation :</label>
                            <input type="text" value={formData.designation} onChange={handleInput} name='designation' placeholder='Enter Designation' />

                            <span>{formError.accessRole}</span>
                        </div>
                        <div className={modalStyle.formGroup}>
                            <label htmlFor=""> Assign A Workspace:</label>

                            <MultiSelect categories={categories}
                                setCategories={setCategories}
                                availableDropDown={['Technical Specifications', 'Commercial', 'Procurement', 'Approvals',]} />
                            <span>{formError.accessRole}</span>
                        </div>

                        <div>
                            <button className={formStyle.modalSmallPrimaryButton} style={{ fontSize: '14px' }} type='submit'>Save </button>
                        </div>
                        <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span>


                    </form>
                </div>
            </Modal>

            <Modal
                open={deleteModal}
            // onClose={handleDeleteTaskModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto' }}>
                    <form action="" style={{ width: '100%' }} >

                        <div className={TaskStyle.modalKam} >
                            <Typography variant="h1">Remove User</Typography>
                            <Typography variant="h6">Are you sure you went to Remove User?</Typography>

                            <div className={TaskStyle.kamModalButton}>
                                <Button variant="contained" onClick={() => handleDeleteUser(currentUser)}>Remove</Button>

                                <Link><p onClick={handleDeleteTaskModal}>Cancel</p></Link>
                            </div>

                        </div>

                    </form>
                </div>
            </Modal>


            <BackdropComponent />

        </>


    );
};

export default UserListComponent;