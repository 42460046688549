import React, { useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import revisionEditStyle from '../../pages/RevisionEdit/RevisionEdit.module.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import useBlobDownload from 'hooks/useBlobDownload';
import useBackdrop from 'hooks/useBackdrop';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import useToast from 'hooks/useToast';


// =====================mui compoents====================
const CompareDocumentsTab = ({ approovalStatus, approverRemarks, addDocumentModal, handleAddDocumentModal, documentRemark, setDocumentRemark, getDataProcure, documents, handleDocumentUpload, setDocuments, revisionId }) => {
  // Importing necessary hooks and components
  const { auth } = useAuth(); // Using the useAuth hook to get authentication information
  const privateAxios = PrivateComponent(); // Getting private Axios instance using PrivateComponent
  const { fileDownload } = useBlobDownload(); // Using useBlobDownload hook to handle file downloads
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Using useBackdrop hook to manage backdrop state
  const { showToast, Toast } = useToast(); // Using useToast hook to show toast messages
  const [deleteModal, setDeleteModal] = useState(false); // State for delete modal visibility
  const [deleteId, setDeleteId] = useState(""); // State for delete document ID

  const inputRef = useRef(); // Creating a ref for file input element
  const [documentName, setDocumentName] = useState(""); // State for document name
  const [selectedFiles, setSelectedFiles] = useState([]); // State for selected files
  const [selectedFileNames, setSelectedFileNames] = useState([]); // State for selected file names
  const [documentMore, setDocumentMore] = useState(""); // State for expanded document details
  const [renameModal, setRenameModal] = useState(false) // State for rename modal visibility
  const [renameText, setRenameText] = useState(""); // State for rename text
  const [docId, setDocId] = useState(""); // State for document ID
  const [documentChats, setDocumentChats] = useState([]); // State for document chats
  const [docChatLoading, setDocChatLoading] = useState(true); // State for document chat loading
  const [docType, setDocType] = useState(false); // State for document type


  // Function to handle file input change
  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    // Append newly selected files to existing selected files
    setSelectedFiles([...selectedFiles, ...files]);
    // Update selected file names
    setSelectedFileNames([...selectedFileNames, ...files.map((file) => file.name)]);
  };

  // Function to remove selected file by index
  const handleRemoveSelectedFile = (index) => {
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);

    const updatedSelectedFileNames = [...selectedFileNames];
    updatedSelectedFileNames.splice(index, 1);

    setSelectedFiles(updatedSelectedFiles);
    setSelectedFileNames(updatedSelectedFileNames);
  };

  // Function to truncate file name if too long
  function truncateFileName(fileName, maxLength) {
    if (typeof fileName !== 'string' || fileName.length === 0) {
      return ''; // Return an empty string or handle this case as needed
    }

    if (fileName.length <= maxLength) {
      return fileName;
    }

    const truncatedFileName = fileName.substring(0, maxLength - 5) + '...';
    return truncatedFileName;
  }

  // Function to handle delete modal visibility and document ID
  const handleDeleteModal = (id) => {
    setDeleteModal(prev => !prev);
    if (id !== null) {
      setDeleteId(id)
    }
  }

  // Function to handle rename modal visibility and document name
  const handleRenameModal = (name) => {
    setRenameModal(prev => !prev)
    if (name !== null) {
      setRenameText(name)
    }

  }

  // Function to handle document validation before upload
  const handleDocumentValidation = async () => {
    openBackdrop()

    if (documentName !== "" && selectedFiles.length > 0 && revisionId !== "0") {
      // Handle document upload
      const res = await handleDocumentUpload(documentName, selectedFiles, docType, revisionId);
      setSelectedFiles([]);
      setSelectedFileNames([]);
      setDocumentName("");
      handleAddDocumentModal()
      closeBackdrop();//close loader

    }
    else {
      closeBackdrop();

      if (revisionId === null) {
        alert("this is the default Revision please save a copy and then upload documents")
      }
      else {
        closeBackdrop();//close backdrop
        alert("all feilds are required check document name and files ")
      }
    }
  }

  // Function to handle document deletion
  const handleDocDelete = async (docId) => {
    try {
      await privateAxios.delete(`/commercial/deleteDocumentOfCommercialRevision/${docId}`).then(res => {
        getDataProcure();
      }).catch(err => {
      })
    } catch (error) {
    }
  }

  // Function to handle document renaming
  const handleRenameDoc = async (e) => {
    e.preventDefault();
    try {
      if (docId) {
        await privateAxios.post(`/commercial/renameDocumentOfCommercialRevision/${docId}?name=${renameText}`).then(res => {
          handleRenameModal();
          getDataProcure()
          setRenameText("");
        }).catch(err => {
          handleRenameModal();
        })
      }
      else {
        alert("Document ID Not Present")
        handleRenameModal();
      }

    } catch (error) {
      handleRenameModal();
      // console.log(error)
    }
  }


  // State for expanded confidential document
  const [openConfidential, setOpenConfidential] = useState("");


  // Function to handle document chat visibility
  const [documentChatOpen, setDocumentChatOpen] = useState("")
  const handleChatOpen = (data, docId) => {

    if (data === documentChatOpen) {
      setDocumentChatOpen("")
      setDocumentChats("");
    }
    else {
      getDocumentChat(docId)
      setDocumentChatOpen(data);
    }
  }

  // State for document chat input value
  const [documentChatValue, setDocumentChatValue] = useState("")

  // Function to handle sending document chat
  const handleDocumentChatSend = async (e, documentId) => {
    e.preventDefault();
    try {
      if (documentChatValue.length > 0) {

        await privateAxios.post(`/commercial/addRemarkOncommercialDocumentTemplate/${auth.userData.id}/${documentId}`, { remark: documentChatValue }).then(res => {
          setDocumentChatValue("");
          getDocumentChat(documentId);
        }).catch((err) => {
        })
      }
    } catch (error) {
      // console.log(error)
    }

  }

  // Function to get document chat
  const getDocumentChat = async (documentId) => {
    handleChatNotification();
    try {
      await privateAxios.get(`/commercial/getRemarksOfcommercialDocumentTemplate/${documentId}`).then(res => {

        setDocumentChats(res.data);
        setDocChatLoading(false)
      }).catch((err) => {
        // console.log(err);
        setDocChatLoading(false)
      })
    } catch (error) {
      // console.log(error)
      setDocChatLoading(false)
    }

  }

  // Function to handle expanding or collapsing document details
  const handleDocumentMore = (index) => {

    if (documentMore === index) {
      setDocumentMore("");
    }
    else {
      setDocumentMore(index)
    }

  }

  // Function to toggle visibility of confidential document
  const handleCofidentialDoc = (id) => {
    if (openConfidential === id) {
      setOpenConfidential("");
    }
    else {
      setOpenConfidential(id);
    }
  }


  // Function to trigger chat notification
  const [reRender, setReRender] = useState(false);

  const handleChatNotification = () => {
    setReRender(prev => !prev);

  }




  return (
    <>
      <Toast />
      <div className={revisionEditStyle.documentsTabMain} style={{ gap: "15px" }}>

        {
          Object.values(documents).map((vdocuments, index) => {
            return (
              <div>
                <h3>{Object.keys(documents)[index]}</h3>
                <table className={revisionEditStyle.DocumentTable}>
                  <thead>
                    <tr className={`${revisionEditStyle.DocumentTableRow} ${revisionEditStyle.DocumentTableHead}`}>
                      <td>Title</td>
                      <td>Date Added</td>
                      {/* <td>Remark</td> */}
                      <td>
                        <div className={revisionEditStyle.documentIconRow}>

                          <a><FileDownloadIcon /></a>


                          <a><MoreVertIcon /></a>

                        </div>
                      </td>

                    </tr>
                  </thead>
                  <tbody>
                    {vdocuments?.map((doc, index) => {
                      if (doc.docType !== "CONFIDENTIAL") {


                        return (
                          <tr className={revisionEditStyle.DocumentTableRow}>
                            <td>{doc.name || doc.url}</td>
                            <td>{doc?.createdAt?.split('T')[0] || "Null"}</td>

                            <td> <div className={revisionEditStyle.documentIconRow}>

                              <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}><FileDownloadIcon /></Link>

                            </div></td>
                          </tr>
                        )
                      }
                    })
                    }

                  </tbody>
                </table>




                <div className={revisionEditStyle.accordianDocumentab}>
                  <div className={revisionEditStyle.accordianDocumentabHeader} onClick={() => handleCofidentialDoc(index)}><span>
                    {openConfidential === index ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                  </span>Confidential Documents</div>

                  <div className={revisionEditStyle.accordianDocumentabBody}>

                    {openConfidential === index &&
                      <table className={revisionEditStyle.DocumentTable}>
                        <tbody>
                          {vdocuments?.map((doc, index) => {
                            if (doc.docType === "CONFIDENTIAL") {
                              return (
                                <tr className={revisionEditStyle.DocumentTableRow}>
                                  <td>{doc.name || doc.url}</td>
                                  <td>{doc?.date?.split('T')[0] || "Null"}</td>
                                  {/* <td><Tooltip title='Comments'><ChatIcon /></Tooltip></td> */}
                                  <td> <div className={revisionEditStyle.documentIconRow}>

                                    <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}><FileDownloadIcon /></Link>

                                  </div></td>
                                </tr>
                              )
                            }
                          })
                          }

                        </tbody>
                      </table>
                    }
                  </div>
                </div>
              </div>
            )
          })
        }

        {approverRemarks?.length > 0 &&

          <table className={revisionEditStyle.DocumentTable}>
            <tr className={`${revisionEditStyle.DocumentTableRow} ${revisionEditStyle.DocumentTableHead}`}>
              <th>
                Approver Remarks
              </th>
              <th style={{ textAlign: "left", paddingRight: "20px" }}>
                From
              </th>
            </tr>
            {approverRemarks?.map((rem, index) => {
              return (
                <tr className={revisionEditStyle.DocumentTableRow}>

                  <td className={revisionEditStyle.reactQuillTd}>
                    <textarea
                      readOnly={true}
                      style={{ border: "none", outline: "none", padding: "10px", minHeight: "40px" }}
                      // theme="snow" 
                      value={rem.remark}
                    />
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "20px" }}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <ProfileIcon data={{ email: rem?.email }} height={"26px"} width={"26px"} showProfileBanner={rem.name == "No Name" ? false : true} />
                      {rem.name}
                    </div>
                  </td>

                </tr>
              )
     
            })}
          </table>
        }

        {auth.roles !== "KAM"
          &&
          <div style={{ display: "flex", height: "20vh", width: "100%", flexDirection: "column", gap: "5px" }}>
            <h3 style={{ marginBottom: "3px", fontSize: "16px", fontWeight: "800" }}>Add Remark</h3>
            <textarea name="" id="" cols="30" rows="10" value={documentRemark} onChange={(e) => {
              if (approovalStatus !== "APPROVAL" && approovalStatus !== "REJECTED") {
                setDocumentRemark(e.target.value)

              } else {
                showToast("error", "You can't add remark in after Decision");
              }
            }} className={revisionEditStyle.descriptionCompareTextBox} style={{ height: "100%", padding: "10px", textIndent: "0" }} />
          </div>
        }
      

      </div>
      <Modal
        open={addDocumentModal}
        onClose={handleAddDocumentModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" style={{ maxWidth: "100%", }} className={formStyle.inputForm}>

            <div className={workSpaceStyle.modalHeader} >
              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}></span>Add Document</h3>
              <div onClick={handleAddDocumentModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <label>Name</label>
              <input type="text" value={documentName} onChange={(e) => setDocumentName(e.target.value)} placeholder='Document Name' />
            </div>

            <div className={formStyle.inputRow}>
              <label>Upload File</label>
              <div style={{ height: "100px", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#f6f6f6", borderRadius: "10px", border: "1px dotted grey" }}>

                <input type="file" multiple
                  ref={inputRef}
                  onChange={handleFileInputChange}
                  hidden placeholder='Document Name' />

                <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", width: "100%", overflow: "auto", padding: "10px 10px" }}>
                  {!selectedFileNames.length > 0 && <p style={{ fontSize: "14px", }}>Upload A file</p>}
                  <ul style={{ width: "100%", display: "flex", flexDirection: "column", }}>
                    {selectedFileNames.map((fileName, index) => (

                      <li key={index}       
                        style={{
                          display: 'flex',
                          justifyContent: "space-between", alignItems: 'center',
                          border: "1px solid grey",
                          padding: "0 10px"
                        }}>
                        <p style={{ fontSize: "14px" }}>
                          {truncateFileName(fileName, 15)}
                        </p>
                        <span onClick={() => handleRemoveSelectedFile(index)} style={{ textDecoration: 'none', border: 'none', display: "flex", justifyContent: "center", alignItems: "center" }}
                        ><CloseIcon sx={{ textDecoration: "none", border: 'none' }} /></span>
                      </li>
                    ))}
                  </ul>
                  <p style={{ fontSize: "14px", color: "blue" }} onClick={() => inputRef.current.click()}>Upload</p>
                </div>

              </div>           
            </div>

            <div className={formStyle.inputRowCheckBox}>
              <input type="checkbox" checked={docType} onChange={(e) => setDocType(e.target.checked)} />
              <label>Confidential Document</label>             
            </div>

            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='button' onClick={handleDocumentValidation}>Add </button>           
            </div>

          </form>

        </div>
      </Modal>


      <Modal
        open={renameModal}
        onClose={handleRenameModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={handleRenameDoc} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>

            <div className={workSpaceStyle.modalHeader} >
              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><DriveFileRenameOutlineIcon /></span>Rename</h3>
              <div onClick={handleRenameModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <input type="text" value={renameText} onChange={(e) => setRenameText(e.target.value)} placeholder='Revision Name' />
            </div>

            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>
              <p onClick={() => { setRenameText(""); handleRenameModal() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

          </form>

        </div>
      </Modal>

    </>
  )
}

export default CompareDocumentsTab
