import React, { useState } from 'react'
import SubcategorySelector from './SubcategorySelector';
import TaskStyle from './SuperAdminProduct.module.css'
import axios from 'api/axios';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect } from 'react';
import useBackdrop from "hooks/useBackdrop";
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';

const CategorySelector = ({ setProductSelected, productSelected, category, selectedSubcategories, setSelectedSubcategories }) => {
    const { auth } = useAuth(); // Use custom hook to get auth object
    const privateAxios = PrivateComponent();  // Initialize private Axios instance using custom component/hook
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Destructure methods and component from custom backdrop hook
    const [catSelectDropdown, setCatSelectDropdown] = useState(false);// State to manage visibility of category select dropdown

    const [productDropDown, setProductDropDown] = useState(false);  // State to manage visibility of product dropdown
    const [productList, setProductList] = useState([])  // State to hold the list of products

    const [catSearch, setCatSearch] = useState("");  // State to hold the search term for categories
    const [proSearch, setProSearch] = useState(""); // State to hold the search term for products


  // Toggle category dropdown visibility
    const handleCatSelector = () => {
        setCatSelectDropdown(prev => !prev)
    }

    // Toggle product dropdown visibility
    const handleProductDropDown = () => {
        setProductDropDown(prev => !prev)
    }

    // Fetch product data based on category id and level
    const getPorductData = async (id, level) => {
        openBackdrop();
        try {
            axios.get(`${process.env.REACT_APP_ECOM}/api/productsforlevel/${id}/${level}`)
                .then((res) => {
                    setProductList(res.data);
                    // console.log(res);
                    closeBackdrop();
                })
                .catch((error) => {
                    console.error('Error fetching products:', error);
                    closeBackdrop();
                });
        } catch (error) {
            setProductList("");
            closeBackdrop();
        }
    };

     // Fetch product data when the selected subcategory changes
    useEffect(() => {
        if (selectedSubcategories[selectedSubcategories.length - 1]?.Branch.length === 0) {
            const sub = selectedSubcategories[selectedSubcategories.length - 1]
            getPorductData(sub.Id, sub.Level);
        }
    }, [selectedSubcategories])


    // Handle category selection and fetch subcategories
    const handleCategorySelect = async (category) => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_ECOM}/api/hierarchy/${category.id}/1`).then(res => {
                setSelectedSubcategories([{ ...res.data }]);
                handleCatSelector()
                setProductSelected("");
                setCatSelectDropdown(false);
                closeBackdrop();
            }).catch((err) => {
                console.log(err)
                handleCatSelector()
                closeBackdrop();
            })

        } catch (err) {
            handleCatSelector()
        }
        closeBackdrop();
    };


 // Handle subcategory selection and update the selected subcategories
    const handleSubcategorySelect = (subcategory) => {
        const tt = selectedSubcategories.filter((cat) => {
            return cat.Level < subcategory.Level;
        })
        const newSub = [
            ...tt,
            { ...subcategory },
        ]
        setProductSelected("");
        setSelectedSubcategories(newSub);
    };

 // Handle product selection and update the selected product
    const handleProductSelect = (pro) => {
        setProductSelected(pro)
        setProductDropDown(false)
    }






    return (
        <>
            <ul className={TaskStyle.list}>
                <li>
                    <ListAltRoundedIcon sx={{ color: '#6A6A6A' }} /> 
                    {/* <img src={Attachments} alt="Attachments icon Loading"
                        style={{
                            width: '15px'
                        }} /> */}

                    Category </li>
                <li style={{ flexGrow: '1' }}>
                    <div className={TaskStyle.selectorDiv} >
                        <p onClick={handleCatSelector} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", minWidth: "200px" }} >{selectedSubcategories[0]?.Name || "Please Select A category"}
                            <span>
                                {catSelectDropdown
                                    ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }

                            </span>
                        </p>
                        {catSelectDropdown && <ThreeDotDropDown toggle={handleCatSelector} style={{ bottom: "100%", top: "auto", width: "100%", borderRadius: "4px", maxHeight: "150px", overflow: "auto" }}>
                            <li style={{ backgroundColor: "white" }}>
                                <input type="text" placeholder='Serach Category' value={catSearch} onChange={(e) => setCatSearch(e.target.value)}
                                    style={{ width: "100%", padding: "5px", border: "none", outline: "none", border: "1px solid #ccc", color: "#000" }}
                                />
                            </li>

                            {category.filter((cat) => cat.name?.toLowerCase().includes(catSearch.trim()?.toLowerCase())).map((category) => {
                                // console.log(category)
                                return (
                                    <li key={category.id} onClick={() => handleCategorySelect(category)}>
                                        {category.name}
                                    </li>
                                )
                            }

                            )}
                        </ThreeDotDropDown>
                        }
                    </div>

                </li>
            </ul>


            {selectedSubcategories.length > 0 &&

                selectedSubcategories.map((selectedCategory, index) => {
                    // console.log(selectedCategory);
                    if (selectedCategory.Branch.length > 0) {
                        return (


                            <ul className={TaskStyle.list}>
                                <li>

                                    <RecentActorsOutlinedIcon sx={{ color: '#6A6A6A' }} />
                                    {/* <img src={Attachments} alt="Attachments icon Loading"
                                        style={{
                                            width: '15px'
                                        }} /> */}
                                    Sub Category
                                </li>




                                <SubcategorySelector


                                    category={selectedCategory}
                                    selectedSubcategories={selectedSubcategories}
                                    onSelectSubcategory={(subcategory) => handleSubcategorySelect(subcategory)}
                                    index={index}
                                />

                            </ul>
                        )
                    }
                }
                )}

            {selectedSubcategories[selectedSubcategories.length - 1]?.Branch.length === 0 && <ul className={TaskStyle.list}>
                <li> <InboxOutlinedIcon sx={{ color: '#6A6A6A' }} /> Product </li>
                <li style={{ flexGrow: '1' }}>
                    <div className={TaskStyle.selectorDiv} >
                        <p onClick={() => { handleProductDropDown(); }} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", minWidth: "200px" }}>{productSelected.name || "Please Select A Product"}
                            <span>
                                {productDropDown
                                    ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }

                            </span>
                        </p>
                        {productDropDown && <ThreeDotDropDown toggle={handleProductDropDown} style={{ bottom: "100%", top: "auto", width: "100%", borderRadius: "4px", maxHeight: "300px", overFlow: "auto" }}>
                            <li style={{ backgroundColor: "white" }}>
                                <input type="text" placeholder='Serach Products' value={proSearch} onChange={(e) => setProSearch(e.target.value)}
                                    style={{ width: "100%", padding: "5px", border: "none", outline: "none", border: "1px solid #ccc", color: "#000" }}
                                />
                            </li>
                            <ul className={TaskStyle.selectlistModal}>


                                {productList.filter((pro) => pro.name.toLowerCase().includes(proSearch.trim().toLowerCase())).map((pro) => {
                                    // console.log(pro)
                                    return (
                                        <li
                                            key={pro.productId}
                                            onClick={() => handleProductSelect(pro)}
                                        >
                                            {pro.name}
                                        </li>
                                    )
                                }

                                )}
                            </ul>
                        </ThreeDotDropDown>
                        }
                    </div>

                </li>
            </ul>}


            <BackdropComponent />
        </>
    )
}

export default CategorySelector
