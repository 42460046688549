import React, { useEffect, useRef } from 'react'
import usersStyle from './Users.module.css'
import modalStyle from 'components/ModalCommonCSS/ModalCommonCSS.module.css'
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useState } from 'react';
import RoleTabComponent from './RoleTabComponent';
import CloseIcon from '@mui/icons-material/Close';
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import Modal from '@mui/material/Modal';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import { PrivateComponent } from 'api/axios'
import useAuth from 'hooks/useAuth';
import useBackdrop from 'hooks/useBackdrop';
import Alert from '@mui/material/Alert';

// Import necessary dependencies and custom hooks
const Users = () => {
    const { auth } = useAuth();  // Retrieve authentication information using custom hook
    const privateAxios = PrivateComponent();// Initialize private axios instance using custom hook
    const emailRef = useRef(); // Reference for email input field

    // State variables for managing component state
    const [tabOpen, settabOpen] = useState({
        kam: false,
        admin: false,
        user: false
    })
    const [formData, setFormData] = useState({
        email: "",
        accessRole: "",
        designation: "",
        workSpace: ""
    })

    const [formError, setFormErrors] = useState({
        email: "",
        accessRole: "",
        designation: "",
        workSpace: "",
        formError: ""
    })

    const [tabShow, setTabShow] = useState({
        KAM: true,
        USER: true,
        ADMIN: true,
    })
    const [userData, setUserData] = useState([])

    const [workSpaceSelected, setWorkSpaceSelected] = useState("ALL");
    const [roleSelected, setRoleSelected] = useState("All");
    const [roledropDownOpen, setRoleDropDownOpen] = useState(false)
    const [workspacedropDownOpen, setWorkspaceDropDownOpen] = useState(false)
    const [addUserModal, setAddUserModal] = useState(false);
    const [totalUser, setTotalUser] = useState("");
    const [categories, setCategories] = useState([]);
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const [allUsersData, setAllUsersData] = useState([]);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    // Function to toggle tab visibility
    const handleTabOpen = (tab) => {
        const tt = tab.toLowerCase();
        settabOpen(prev => {
            return {
                ...prev,
                [tt]: !prev[tt]
            }
        })
    }

    // Function to toggle add user modal visibility
    const handleAddUserModal = () => {
        openBackdrop()
        setAddUserModal(prev => !prev);
        setCategories([]);

        setFormData({
            email: "",
            accessRole: "",
            designation: "",
            workSpace: ""
        })

        setFormErrors({
            email: "",
            accessRole: "",
            designation: "",
            workSpace: "",
            formError: ""
        })
        closeBackdrop()
    }

    // Function to handle input changes
    const handleInput = (e) => {
        setFormData(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [e.target.name]: '',
            formError: "",
        }));
    }


    // Function to handle workspace sorting
    const handleWorkspaceSorter = (value) => {
        setWorkspaceDropDownOpen(false);
        setWorkSpaceSelected(value);
    }

    // Function to handle role sorting
    const handleRoleSorter = (value) => {
        setRoleDropDownOpen(false)
        setRoleSelected(value)
        const sv = value.toLowerCase()
        switch (sv) {
            case "all": setTabShow({
                KAM: true,
                USER: true,
                ADMIN: true,
            })
                break;
            case "kam": setTabShow({
                KAM: true,
                USER: false,
                ADMIN: false,
            })
                break;
            case "user": setTabShow({
                KAM: false,
                USER: true,
                ADMIN: false,
            })
                break;
            case "admin": setTabShow({
                KAM: false,
                USER: false,
                ADMIN: true,
            })
        }
    }

    // useEffect hook to handle side effects after render
    useEffect(() => {
        // Update form data with categories
        setFormData(prev => {
            return {
                ...prev,
                workSpace: categories,
            }
        })

        // Reset workspace error if categories are selected
        if (categories.length > 0) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                workSpace: '',
                formError: ""
            }));
        } else {
        }

    }, [categories])

    // Function to handle form validation
    const handleValidation = (e) => {
        const { name, value } = e.target;
        let errorMessage = '';
        // Validation for email field
        if (name === 'email') {
            if (value.trim() === '') {
                errorMessage = 'Add at least one email address';
            } else {
                // Split emails, remove duplicates, and check for validity
                const emailArr = value.split(',').map((email) => email.trim().toLowerCase());
                const uniqueEmails = Array.from(new Set(emailArr));
                if (uniqueEmails.length < emailArr.length) {
                    errorMessage = 'Repeated emails found';
                }

                const invalidEmails = uniqueEmails.filter((email) => {
                    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
                    return !emailRegex.test(email.toLowerCase());
                });

                if (invalidEmails.length > 0) {
                    errorMessage = `Invalid email: ${invalidEmails.join(', ')}`;
                }
            }
        }

        // Validation for access role field
        if (name === 'accessRole') {
            if (value.trim() === '') {
                errorMessage = 'Select an Access Role';
            }
        }
        // Validation for designation field
        if (name === 'designation') {
            if (value.trim() === '') {
                errorMessage = 'Select a Designation';
            }
        }
        // Validation for designation field
        if (categories.length == 0) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                workSpace: 'Select at least one category',
            }));
        }
        // Update form errors
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage,
            formError: ""
        }));
    }

    // Function to handle user invitation
    const handleInviteUser = async (e) => {
        openBackdrop()

        e.preventDefault();
        setFormErrors(prev => {
            return {
                ...prev,
                formError: ""
            }
        })
        // Function to handle user invitation
        const hasErrors = Object.values(formError).some((error) => error !== '');
        const notEmpty = Object.values(formData).some((dd) => dd.length < 1);

        if (!hasErrors && !notEmpty) {
            const emailArr = formData.email.split(",").map((email) => {
                return email.trim().toLowerCase();
            });

            await privateAxios.post(`/adminuser/inviteUser/${auth.orgId}`,
                { emails: emailArr, role: formData.accessRole, designation: formData.designation, workspace: formData.workSpace },

            )
                .then((res) => {
                    if (res.status === 200) {
                        setShowSuccessAlert(true); // Set success alert
                        const data = res.data;
                        getAllUser(); // Fetch all users again
                        handleAddUserModal(); // Close add user modal
                    }
                    closeBackdrop(); // Close backdrop after operation

                }).catch((err) => {
                    // Set form error in case of API failure
                    setFormErrors(prev => {
                        return {
                            ...prev,
                            formError: err?.response?.data.message || "Something Went Wrong"
                        }
                    })
                })
            closeBackdrop(); // Close backdrop after operation
        } else {
            // Set form error if validation fails
            setFormErrors(prev => {
                return {
                    ...prev,
                    formError: "Please Check all the Feilds"
                }
            })
        }
        closeBackdrop(); // Close backdrop after operation

    }

    // Function to fetch all users
    const getAllUser = async () => {
        try {
            await privateAxios.get(`/procurement/getAllUsers/${auth.orgId}`)   //Here old API to fetch users
                .then((res) => {
                    if (res.status === 200) {
                        const data = res.data;
                        setAllUsersData(res.data.data.employees)
                        const dataArr = [];
                        const groupedData = {};
                        res.data.data.employees.forEach(entry => {
                            const role_name = entry?.user.userRoles.name;
                            if (!groupedData[role_name]) {
                                groupedData[role_name] = [];
                            }
                            if (!entry.disable) {
                                if (entry.name === null) {
                                    let upentry = {
                                        ...entry,
                                        name: "No Name"
                                    };
                                    groupedData[role_name].push(upentry);
                                }
                                else {

                                    groupedData[role_name].push(entry);
                                }
                            }
                        })

                        let tt = 0;
                        if (groupedData?.KAM) {
                            tt += groupedData?.KAM.length;
                        }
                        if (groupedData?.USER) {
                            tt += groupedData.USER.length;
                        }
                        if (groupedData?.ADMIN) {
                            tt += groupedData.ADMIN.length;
                        }
                        setTotalUser(tt)
                        setUserData({
                            USER: groupedData?.USER,
                            KAM: groupedData?.KAM,
                            ADMIN: groupedData?.ADMIN
                        })
                    }

                }).catch((err) => {
                })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAllUser() // Fetch all users on component mount
    }, [])


    // Effect hook to handle success alert display
    useEffect(() => {
        let timeoutId;
        if (showSuccessAlert) {
            timeoutId = setTimeout(() => {
                setShowSuccessAlert(false);
            }, 3000); // 3000 milliseconds (3 seconds)
        }
        return () => {
            clearTimeout(timeoutId); // Clear the timeout if the component unmounts or the alert is hidden before 3 seconds
        };
    }, [showSuccessAlert]);



    //   ******************************Serching************************************************

    // State variable and handler for search string
    const [seachString, setSearchString] = useState("");
    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
    };


    // ---------------------------Sorting-------------------------------------

    // State variable and handler for search string
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' for ascending, 'desc' for descending


    // Function to handle sorting
    const handleSort = () => {
        const upData = userData;

        if (sortOrder === 'asc') {
            // Sort user data in ascending order
            const uKam = upData?.KAM?.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
            const uAdmin = upData?.ADMIN?.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
            const uUser = upData?.USER?.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
            setUserData({
                KAM: uKam,
                ADMIN: uAdmin,
                USER: uUser
            })

            setSortOrder('desc');
        } else {
            // Sort user data in descending order
            const uKam = upData?.KAM?.sort((a, b) => (b.name || '').localeCompare(a.name || ''));
            const uAdmin = upData?.ADMIN?.sort((a, b) => (b.name || '').localeCompare(a.name || ''));
            const uUser = upData?.USER?.sort((a, b) => (b.name || '').localeCompare(a.name || ''));
            setUserData({
                KAM: uKam,
                ADMIN: uAdmin,
                USER: uUser
            })
            setSortOrder('asc');
        }
    };

    // Function to handle workspace string conversion
    const handleWorkspaceString = (value) => {
        switch (value.toLowerCase()) {
            case "all": return "All";
            case "technical specifications": return "Technical";
            case "commercial": return "Commercial";
            case "Procurement": return "Procurement";
            case "approvals": return "Approvals";
        }
    }


    return (
        <div className={workSpaceStyle.workSpaceMain}>

            <div className={workSpaceStyle.workSpaceHeader}>
                <div className={workSpaceStyle.workSpaceHeaderLeft}>
                    <h1><PeopleOutlineIcon />Users</h1>
                    <p>All Users <span>{totalUser}</span></p>
                </div>



                <div className={workSpaceStyle.workSpaceHeaderSearchbar}>
                    <input type="text"
                        onChange={handleSearchChange}
                        value={seachString}
                        placeholder='Search Here...'
                    // onChange={(e) => setSearchString(e.target.value)} 
                    />
                </div>



                <div className={workSpaceStyle.workSpaceHeaderRight}>
                    <div style={{ width: "60px" }} className={workSpaceStyle.WorkSpaceHeaderSorters}>
                        <p>Roles:</p>
                        <h3 onClick={() => { setRoleDropDownOpen(prev => !prev); setWorkspaceDropDownOpen(false) }}><AdminPanelSettingsIcon />{roleSelected}</h3>


                        {roledropDownOpen &&
                            <>
                                <div>
                                    <div className={workSpaceStyle.workSpaceHeaderDropDownBackDrop} onClick={() => setRoleDropDownOpen(false)}></div>

                                    <div className={workSpaceStyle.workSpaceHeaderSorterDropdown}>
                                        <Tooltip title='Filter Role'>
                                            <h5>Role:</h5>
                                        </Tooltip>
                                        <ul>
                                            <li onClick={() => handleRoleSorter("All")}>All</li>
                                            <li onClick={() => handleRoleSorter("KAM")}>Key Access Manager</li>
                                            <li onClick={() => handleRoleSorter("admin")}>Admin</li>
                                            <li onClick={() => handleRoleSorter("user")}>User</li>

                                        </ul>
                                    </div>
                                </div>
                            </>
                        }

                    </div>

                    <div className={workSpaceStyle.WorkSpaceHeaderSorters}>
                        <p>WorkSpace:</p>
                        <h3 onClick={() => { setWorkspaceDropDownOpen(prev => !prev); setRoleDropDownOpen(false) }}>
                            <Tooltip title='Filter WorkSpace'>
                                <ImportExportIcon />
                            </Tooltip>{handleWorkspaceString(workSpaceSelected)}

                        </h3>

                        {workspacedropDownOpen &&
                            <>
                                <div>
                                    <div className={workSpaceStyle.workSpaceHeaderDropDownBackDrop} onClick={() => setWorkspaceDropDownOpen(false)}></div>

                                    <div className={workSpaceStyle.workSpaceHeaderSorterDropdown}>

                                        <h5>WorkSpaces:</h5>
                                        <ul>
                                            <li onClick={() => handleWorkspaceSorter("All")}>All</li>
                                            <li onClick={() => handleWorkspaceSorter("technical specifications")}>Technical</li>
                                            <li onClick={() => handleWorkspaceSorter("Procurement")}>Procurement</li>
                                            <li onClick={() => handleWorkspaceSorter("commercial")}>Commercial</li>
                                            <li onClick={() => handleWorkspaceSorter("approvals")}>Approval</li>
                                        </ul>
                                    </div>
                                </div>
                            </>
                        }

                    </div>

                    <div className={workSpaceStyle.workSpaceHeaderAddButton}>
                        <Tooltip title='Add'>
                            <button onClick={handleAddUserModal}>

                                <AddIcon />
                                Add</button></Tooltip>
                    </div>
                    <div className={`${workSpaceStyle.WorkSpaceHeaderhelp} `}>

                        <Tooltip title="Help">
                            <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000080855-add-users">
                                <HelpOutlineOutlinedIcon />
                            </a>
                        </Tooltip>

                    </div>
                </div>

            </div>

            <div className={usersStyle.userTable}>
                <ul className={`${usersStyle.userTableHeader} ${usersStyle.userTablerow}`}>
                    <li onClick={() => handleSort('name')}>
                        Name {sortOrder === 'asc' ? '▲' : '▼'}
                    </li>

                    <li>Email</li>
                    <li>Designation</li>
                    <li>Assigned WorkSpace</li>
                    <li>Last Active</li>
                    <li>Action</li>
                </ul>


                <div className={usersStyle.roleTab}>


                    {
                        tabShow.KAM &&
                        userData.KAM && <RoleTabComponent
                            key={"KAM"}
                            data={userData.KAM}
                            role={"KAM"}
                            seachString={seachString}
                            workSpace={workSpaceSelected}
                            isOpen={tabOpen["KAM".toLowerCase()]}
                            handleTabOpen={handleTabOpen}
                            getAllUser={getAllUser}
                        // allUsersData={allUsersData}

                        />}

                    {/* {console.log(userData.ADMIN)} */}
                    {

                        tabShow.ADMIN &&
                        userData.ADMIN && <RoleTabComponent
                            key={"ADMIN"}
                            data={userData.ADMIN}
                            role={"ADMIN"}
                            seachString={seachString}
                            workSpace={workSpaceSelected}
                            isOpen={tabOpen["ADMIN".toLowerCase()]}
                            handleTabOpen={handleTabOpen}
                            getAllUser={getAllUser}
                        // allUsersData={allUsersData}

                        />}
                    {
                        tabShow.USER &&
                        userData.USER && <RoleTabComponent
                            key={"USER"}
                            data={userData.USER}
                            seachString={seachString}
                            role={"USER"}
                            workSpace={workSpaceSelected}
                            isOpen={tabOpen["USER".toLowerCase()]}
                            handleTabOpen={handleTabOpen}
                            getAllUser={getAllUser}

                        />}


                    {/* {Object.keys(userData).map((role) => (
                       
                        <RoleTabComponent
                            key={role}
                            data={userData[role]}
                            role={role}
                            isOpen={tabOpen[role.toLowerCase()]}
                            handleTabOpen={handleTabOpen}
                        />
                    ))} */}







                </div>

            </div>


            <Modal
                open={addUserModal}
            // onClose={handleAddUserModal}

            >
                <div className={modalStyle.modalContainer}>


                    <form action="" onSubmit={handleInviteUser}

                        className={modalStyle.formContainer} >
                        <div className={modalStyle.modalHeader}
                        // onClick={handleAddUserModal}
                        >


                            <h3>Invite members on board</h3>
                            <span onClick={handleAddUserModal}>
                                <Tooltip title='Close'><CloseIcon /></Tooltip>
                            </span>
                        </div>

                        <div className={modalStyle.formGroup}>
                            <label htmlFor=""> Email:</label>
                            <input onChange={handleInput} autoComplete='off' name='email' onBlur={handleValidation} ref={emailRef}
                                type="text" placeholder='Enter Emails Separated by commas' />
                            <span>{formError.email}</span>
                            <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span>
                        </div>

                        {/* 
                            <Select
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                          
                                    value={formData.accessRole}
                                    onChange={handleInput}
                                    className={formStyle.formSelect}
                                    name='accessRole'
                                  

                                >
                                     <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                    <MenuItem value={"kam"}>Key Access Manager</MenuItem>
                                    <MenuItem value={"admin"}>Admin</MenuItem>
                                    <MenuItem value={"user"}>User</MenuItem>
                                </Select> */}
                        <div className={modalStyle.formGroup}>
                            <label htmlFor=""> Access Role:</label>

                            <select
                                value={formData.accessRole}
                                onChange={handleInput}
                                className={formStyle.formSelect}
                                name='accessRole'
                                onBlur={handleValidation}
                            >
                                <option value="" style={{ color: "#878699" }}>Plese Select a Role</option>
                                {/* <option value="KAM">Key Access Manager</option> */}
                                <option value="ADMIN">Admin</option>
                                <option value="USER">User</option>
                            </select>
                            <span>{formError.accessRole}</span>
                        </div>
                        <div className={modalStyle.formGroup}>
                            <label htmlFor=""> Designation :</label>
                            <input onChange={handleInput} autoComplete='off' value={formData.designation} name='designation' onBlur={handleValidation}
                                type="text" placeholder='Please Enter A designation' />

                            {/* <select
                                value={formData.designation}
                                onChange={handleInput}
                                className={formStyle.formSelect}
                                name='designation'
                                onBlur={handleValidation}
                            >
                                <option value="" style={{ color: "#878699" }}>Add A Role</option>
                                <option value="Enginer">Enginer</option>
                                <option value="Project Lead">Project Head</option>
                                <option value="Some Lead">Some Lead</option>
                            </select> */}
                            <span>{formError.designation}</span>
                        </div>
                        <div className={modalStyle.formGroup}>
                            <label htmlFor=""> Assign A Workspace:</label>

                            {/* <select
                                value={formData.workSpace}
                                onChange={handleInput}
                                className={formStyle.formSelect}
                                name='workSpace'>
                                <option value="" style={{ color: "#878699" }}>Plese Select a WorkSpace</option>
                                <option value="technical specification">Technical Specification</option>
                                <option value="procurement">Procurement</option>
                                <option value="approval">Approval</option>
                                <option value="commercial">Commercial</option>
                            </select> */}
                            <MultiSelect categories={categories}
                                name='workSpace'
                                setCategories={setCategories}

                                availableDropDown={['Technical Specifications', 'Commercial', 'Procurement', 'Approvals']} />


                            {/* <span>{formErrors.accessRole}</span>  */}
                            <span>{formError.workSpace}</span>
                        </div>

                        <div>
                            <button className={formStyle.modalSmallPrimaryButton} type='submit'>Invite </button>
                        </div>


                    </form>

                </div>
            </Modal>

            <BackdropComponent />

            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                }}>

                {showSuccessAlert && (
                    <Alert severity="success" sx={{
                        display: 'flex',
                        justifyContent: 'center',

                        // position:'absolute'
                        // ,left:'50%',
                        //  top:'50%',
                        //  zIndex:'500',
                        width: '20%'
                    }}>Invite sent successfully</Alert>
                )}

            </div>

        </div>
    )
}

export default Users