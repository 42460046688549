import React, { useEffect, useState, useRef } from 'react'
import styleHead from "./ProcaHeader.module.css"
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';


const ProcaHeader = ({ onPricingButtonClick }) => {
    const navigate = useNavigate();


    return (
        <div className={styleHead.HeaderMain} >
            <div className={styleHead.heading}>
                <h1>
                    Goodbye to <br /> manual <span>procurement</span></h1>
                <p>Reliable, flexible and simple-to-adopt spend management platform that drives sourcing and procurement excellence</p>
                <div className={styleHead.headButton}>
                    <Button variant="contained" onClick={() => { navigate('/demopage') }}>Get Demo</Button>
                </div>
            </div>
            <div className={styleHead.headerImg}>
                <img src="https://res.cloudinary.com/dtffniutw/image/upload/v1698405863/Dashboard_xbb0im.png" alt="" />
            </div>
        </div>
    )
}

export default ProcaHeader
