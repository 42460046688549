import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import revisionEditStyle from './RevisionEdit.module.css'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AttributeUnitSelector from 'components/AttributeUnitSelector/AttributeUnitSelector';
import ReactQuill from 'react-quill';
import { PrivateComponent } from 'api/axios';
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import useAuth from 'hooks/useAuth';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown'
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import TaskStyle from "pages/TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import { Typography, Button } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';



const AttributeTab = ({ duplicateError, handleColumnDelete, handleColumnNameChange, handleAddColumn, handleRowDelete, handleAddRow, columns, setColumns, tableRows, setTableRows }) => {

  // Initialize privateAxios instance
  const privateAxios = PrivateComponent();

  // Ref for the table
  const tableRef = useRef(null);

  // State for managing unit dropdown open/close
  const [unitOpen, setUnitOpen] = useState("");

  // State for managing which column is being edited
  const [columnEdit, setColumnEdit] = useState("")

  // Get auth object from useAuth hook
  const { auth } = useAuth()

  // State for units
  const [units, setUnits] = useState();

  // State for delete modal
  const [deleteModal, setDeleteModal] = useState(false);

  // State for delete item id
  const [deleteId, setDeleteId] = useState("");

  // State for column rename text
  const [columnRenameText, setColumnRenameText] = useState("");

  // State for column rename modal
  const [columnRenameModal, setColumnRenameModal] = useState(false);

  // State for column id to rename
  const [renameColumnId, setRenameColumnId] = useState("");

  // Function to toggle column rename modal
  const handleColumnRenameModal = () => {
    setColumnRenameModal(prev => !prev)
  }

  // Function to handle column rename
  const handleRenameColumn = (e) => {
    e.preventDefault();

    if (renameColumnId !== "") {
      handleColumnNameChange(renameColumnId, columnRenameText);
      //Reset state
      setRenameColumnId("");
      setColumnRenameText("");
      handleColumnRenameModal();
    }
  }

  // Function to toggle delete modal
  const handleDeleteModal = (id) => {
    setDeleteModal(prev => !prev);
    if (id !== null) {
      setDeleteId(id)
    }
  }

  // Function to toggle unit dropdown
  const handleUnitOpen = (id) => {
    if (unitOpen === id) {
      setUnitOpen("")
    }
    else {
      setUnitOpen(id);
    }
  }

  const handleColumnEdit = (index) => {
    if (columnEdit === index) {
      setColumnEdit("")
    }
    else {
      setColumnEdit(index);
    }
  }
  // Function to handle unit value change
  const handleUnitValue = (value, id) => {
    setTableRows(prev => {
      return prev.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            unit: value,
            status: "UPDATED"
          }

        }
        return row;
      })
    })
  }

  // Function to handle input value change
  const handleValue = (e, id) => {
    setTableRows(prev => {
      return prev.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            [e.target.name]: e.target.value,
            status: "UPDATED"
          }

        }
        return row;
      })
    })

  }

  // Function to handle additional fields value change
  const handleAdditionalFields = (e, id, index) => {
    const { name, value } = e.target;

    setTableRows((prev) =>
      prev.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            additionalFields: row.additionalFields.map((r, i) =>
              i === index ? { ...r, columnValue: value } : r
            ),
            status: "UPDATED"
          };
        }
        return row;
      })
    );
  };

  // Function to fetch units
  const getUnits = async () => {
    try {
      await privateAxios.get(`/technicalSpecification/getAllUnits/${auth.orgId}`).then(res => {
        setUnits(res.data)
      }).catch(err => {
      })

    } catch (error) {

    }
  }
  // Fetch units on component mount
  useEffect(() => {
    getUnits();
  }, [])

  // Function to scroll to selected row
  const handleSelect = (value) => {
    const selectedValue = value;
    const selectedRow = tableRows.find((row) => row.name === selectedValue);
    if (selectedRow) {
      const selectedRowElement = document.getElementById(`row-${selectedRow.id}`);
      if (selectedRowElement) {
        selectedRowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };



  // ========================jump to  row=================

  // State for jump to row functionality
  const [jumpSearch, setJumpSearch] = useState("");
  const [jumpListShow, setJumpListShow] = useState(false);


  // Function to toggle jump list
  const toggleJumpList = () => {
    setJumpListShow(prev => !prev);
  }

  // ========================jump to  row=================


  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", height: "100%", width: "100%" }}>


        <div className={revisionEditStyle.jumpToRow} >
          <div style={{ position: "relative" }}>
            <span onClick={toggleJumpList}><FormatListBulletedIcon /></span>

            {jumpListShow &&
              <>
                <div className={revisionEditStyle.jumpListBackDrop} onClick={toggleJumpList}>

                </div>
                <div className={revisionEditStyle.jumpListDropDown}>
                  <div className={revisionEditStyle.rowSearchBar}>
                    <input type="text" value={jumpSearch} onChange={(e) => setJumpSearch(e.target.value)}
                      placeholder="Search for Row..." />
                  </div>
                  <ul className={revisionEditStyle.jumpToRowList}>
                    {tableRows.filter((row) => row.name.trim().toLowerCase().includes(jumpSearch.toLowerCase())).map((row) => {
                      return (
                        <li onClick={() => handleSelect(row.name)}>{row.name}</li>
                      )
                    })}
                  </ul>
                </div>

              </>
            }

          </div>

        </div>
        <div className={revisionEditStyle.attributeTableMain}>
          {/* <button onClick={() => handleSelect({ target: { value: 36 } })}>Click me </button> */}
          <table className={revisionEditStyle.attributeTable} ref={tableRef}>
            {/* Render table header */}
            <thead>
              <tr style={{ outline: "1px solid #d2d2d2", position: "sticky", top: "0", backgroundColor: "#fafafa", zIndex: "1" }}>
                {columns.map((column, index) => (
                  <th key={index}>
                    {column.name === "Add" ? (
                      <span onClick={handleAddColumn}>
                        <AddIcon />
                      </span>
                    ) : (
                      <div className={revisionEditStyle.attributeHeader}>
                        <h3>{column.name}</h3>
                        {column.editable && <span
                          style={{ position: "relative" }}
                          onClick={() => handleColumnEdit(index)}
                        ><MoreHorizIcon />
                          {columnEdit === index && <ThreeDotDropDown>
                            <li onClick={() => { handleColumnRenameModal(); setRenameColumnId(column.id) }}>Rename Column</li>
                            <li onClick={() => handleColumnDelete(column.id)}>Delete Column</li>
                          </ThreeDotDropDown>}
                        </span>}

                      </div>
                    )}
                  </th>
                ))}
                <th><p></p></th>
              </tr>
            </thead>
            <tbody>
              {tableRows.map((row, index) => (
                <TableRow
                  key={row.id}
                  row={row}
                  rowIndex={index}
                  columns={columns}
                  handleRowDelete={handleRowDelete}
                  // handleName={handleName}
                  handleValue={handleValue}
                  handleAdditionalFields={handleAdditionalFields}
                  handleUnitOpen={handleUnitOpen}
                  handleUnitValue={handleUnitValue}
                  unitOpen={unitOpen}
                  units={units}
                  getUnits={getUnits}
                  duplicateError={duplicateError}
                  handleDeleteModal={() => handleDeleteModal(row.id)}
                />
              ))}
            </tbody>
          </table>

          <div className={revisionEditStyle.tableFooterAddAttribute} onClick={handleAddRow}><span  ><AddCircleOutlineIcon /></span>Add Attribute</div>
        </div>
      </div>
      <Modal
        open={columnRenameModal}
        onClose={handleColumnRenameModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={handleRenameColumn} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><PersonAddAltOutlinedIcon /></span>Rename</h3>
              <div onClick={handleColumnRenameModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>

              <input type="text" value={columnRenameText} onChange={(e) => setColumnRenameText(e.target.value)} placeholder='Revision Column' />
              {/* <span>{formErrors.accessRole}</span>  */}
              {/* <span>{formError.workSpace}</span> */}
            </div>



            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>

              <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Change </button>


              <p onClick={() => { setColumnRenameText(""); handleColumnRenameModal()() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

            {/* <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span> */}
          </form>

        </div>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={handleDeleteModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', width: '375px' }}>
          <form action="" style={{ maxWidth: '100%' }} >

            <div className={TaskStyle.modalKam}>
              <Typography variant="h1">Delete Attribute</Typography>
              <Typography variant="h6">Are you sure you want to delete this Attribute?</Typography>

              <div className={TaskStyle.kamModalButton}>
                <Button onClick={() => { handleRowDelete(deleteId); handleDeleteModal() }} variant="contained">Delete</Button>

                <p onClick={handleDeleteModal}>Cancel</p>
              </div>

            </div>

          </form>
        </div>
      </Modal>


    </>
  )
};


const TableRow = ({
  row,
  rowIndex,
  columns,
  duplicateError,
  handleRowDelete,
  // handleName,
  handleValue,
  handleAdditionalFields,
  handleUnitOpen,
  handleUnitValue,
  unitOpen,
  getUnits,
  units,
  handleDeleteModal
}) => {

  // console.log(duplicateError);
  // console.log(row.id);
  return (
    <tr key={row.id} style={duplicateError?.includes(row.id) ? { outline: "1px solid red" } : {}} id={`row-${row.id}`} >
      <td>
        <span style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
          <p>{rowIndex + 1}</p>
        </span>
      </td>
      <td style={{ minWidth: "200px" }} className={revisionEditStyle.nameCell}>
        <input type="text" name="name" value={row.name} onChange={(e) => handleValue(e, row.id)} />
      </td>
      <td style={{ minWidth: "120px", maxWidth: "120px" }} className={revisionEditStyle.nameCell}>
        <input type="text" name="type" value={row.type} onChange={(e) => handleValue(e, row.id)} />
      </td>
      <td style={{ minWidth: "180px", maxWidth: "180px" }} className={revisionEditStyle.nameCell}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          <div style={{ position: "relative" }} className={revisionEditStyle.unitBox} >
            <div
              style={{ width: "100%", border: "1px solid #B6B6B6", borderRadius: "4px", minHeight: "30px", display: "flex", alignItems: "center", padding: "5px" }}

              onClick={() => handleUnitOpen(row.id)}
              dangerouslySetInnerHTML={{ __html: row.unit }}>
              {/* <ReactQuill modules={{ toolbar: false }} readOnly={true} theme="snow" value={row.unit} style={{ width: "100%", height: "100%" }} /> */}

            </div>

            {unitOpen === row.id &&
              <AttributeUnitSelector getUnits={getUnits} units={units} handleUnitOpen={handleUnitOpen} handleValue={handleUnitValue} value={row.unit} rowId={row.id} />
            }
          </div>
        </div>
      </td>
      <td style={{ maxWidth: "120px", minWidth: "100px" }} className={revisionEditStyle.valueField}>
        <input type="text" name="value" value={row.value} onChange={(e) => handleValue(e, row.id)} />
      </td>
      <td style={{ maxWidth: "250px", minWidth: "100px" }} className={revisionEditStyle.remarkField}>
        <textarea
          type="text"
          name="remark"
          value={row.remark}
          onChange={(e) => handleValue(e, row.id)
          }
        />
      </td>
      {/* {console.log(typeof(row.additionalFields))} */}
      {row?.additionalFields?.map((col, index) => (
        <td key={index} style={{ maxWidth: "250px", minWidth: "100px" }} className={revisionEditStyle.remarkField}>
          <textarea
            type="text"
            name={`additional_Colmun_${row.id}_${index}`}
            value={col.columnValue}
            onChange={(e) => handleAdditionalFields(e, row.id, index)}
          />
        </td>
      ))}
      <td style={{ minWidth: "38px" }}>
        <p></p>
      </td>
      <td style={{ minWidth: "38px" }}>
        <span onClick={() => handleDeleteModal()}>
          <DeleteOutlineIcon />
        </span>
      </td>
    </tr>
  );
};

export default AttributeTab
