
import React from 'react'
import TaskStyle from '../../../CreateTask/TaskPannel/TaskPannel.module.css'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GroupsIcon from '@mui/icons-material/Groups';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import ChattPanel from '../../../CreateTask/ChattPanel/ChattPanel';
import { PrivateComponent } from 'api/axios';
import { useRef } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import useAuth from 'hooks/useAuth';
import CategorySelector from '../../../CreateTask/TaskPannel/CategorySelector';
import useBackdrop from "hooks/useBackdrop";
import DescriptionIcon from '@mui/icons-material/Description';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tooltip } from '@mui/material';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import commercialStyle from '../commercialStyles.module.css';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import formStyle from '../../../../components/FormsCommonCss/FormsCommonCss.module.css'
import RedirectRegistrationStyle from '../../../RedirectRegistration/RedirectRegistrationStyle.module.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import useToast from 'hooks/useToast'
import FetchUsersComponent from 'components/FetchUsersComponent/FetchUsersComponent'
import CommercialTemplateSelector from './CommercialTemplateSelector';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PrioritySelector from 'components/TaskComponents/PrioritySelector/PrioritySelector';
import OptionSelector from 'components/TaskComponents/OptionSelector/OptionSelector';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',

};

const styleUploadFiles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    p: 2,

};

const styleSelectEmails = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    p: 2,
};




function CreateTaskCommercial({ handlecreateTaskModal, getAllTasks }) {
    const { showToast, Toast } = useToast()
    const { auth } = useAuth();
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const currentDate = new Date().toISOString().slice(0, 10);
    const privateAxios = PrivateComponent();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const inputRef = useRef(null);
    const dateRef = useRef(null);
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    const [files, setFiles] = useState("");
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [data, setData] = useState({    // set all data

        title: "",
        description: "",
        startDate: currentDate, // Set the default value to today's date
        endDate: "",
        status: "TODO",
        priority: "",

    })
    const [deliveryTerms, setDeliveryTerms] = useState([]);
    const [formError, setFormError] = useState({})
    // New state for upload modal
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [emailModalOpen, setEmailModalOpen] = useState(false); // New state for email selection modal
    const [kamId, setKamId] = useState('');
    const [selectedEmails, setSelectedEmails] = useState([]);
    const [selectedFileNames, setSelectedFileNames] = useState([]); // State to store selected file names
    const [productSelected, setProductSelected] = useState("");
    // Create a state variable to store file sizes
    const [fileSizes, setFileSizes] = useState([]);

    const handleUploadModalClose = () => {
        setUploadModalOpen(false);
    };

    const [chats, setChats] = useState([]);
    const workspaceId = 2;
    const [userList, allEmails, notLoggedIn] = FetchUsersComponent({ orgId: auth.orgId, workspaceId: workspaceId })



    ////////// ==================================== Handle shipping terms here ============================/////////

    const [shippingModalOpen, setShippingModalOpen] = useState(false);
    const [currentTerm, setCurrentTerm] = useState(''); // State variable to store the current term
    const handleShippingModalOpen = (term) => {
        setSelectedCategories([]);
        setDropdownOpen(false)
        setCurrentTerm(term);
        setShippingModalOpen(true);
    };
    const handleShippingModalClose = () => {
        setShippingModalOpen(false);
    };

    // ======================fetch category========================


    const [availableCategory, setAvailableCategory] = useState([]);
    const fetchCategory = async () => {
        openBackdrop();
        try {
            await privateAxios.get(`/procurement/getCategories/${auth.orgId}`).then(res => {
                setAvailableCategory(res.data.data.categories);
            }).catch((err) => {
                alert("error getting categories");
            })
        } catch (error) {
            // console.log(error)
        }

        closeBackdrop();
    }

    useEffect(() => {
        fetchCategory();
    }, [])


    const handleEmailModalOpen = () => {
        setEmailModalOpen(true); // Open the modal
    };


    const handleEmailCheckboxChange = (email) => {
        const isSelected = selectedEmails.includes(email);
        // Clone the selectedEmails array to avoid mutating the state directly
        const updatedSelectedEmails = [...selectedEmails];

        if (updatedSelectedEmails.includes(email)) {
            // If the email is already in the selectedEmails, remove it
            const index = updatedSelectedEmails.indexOf(email);
            if (index > -1) {
                updatedSelectedEmails.splice(index, 1);
            }
        } else {
            // If the email is not in the selectedEmails, add it
            updatedSelectedEmails.push(email);
        }

        // Update the state with the new selected emails
        setSelectedEmails(updatedSelectedEmails);
    };

    const handleRemoveSelectedFile = (index) => {
        const updatedSelectedFiles = [...selectedFiles];
        const updatedSelectedFileNames = [...selectedFileNames];
        updatedSelectedFiles.splice(index, 1);
        updatedSelectedFileNames.splice(index, 1);
        setSelectedFiles(updatedSelectedFiles);
        setSelectedFileNames(updatedSelectedFileNames);
    };


    const handleEmailModalClose = () => {
        // Store only the first letter of the last selected email in selectedEmailsDisplay
        if (selectedEmails.length > 0) {
            const lastSelectedEmail = selectedEmails[selectedEmails.length - 1];
            const firstLetter = lastSelectedEmail.charAt(0);

        }

        setEmailModalOpen(false);
    };
    // Declare and initialize setIsDateSelected and isDateSelected
    const [isDateSelected, setIsDateSelected] = useState(false);

    //files
    const handleUploadModalOpen = () => {
        setSelectedFiles([]); // Clear the selected files
        setUploadModalOpen(true);
    };
    // Function to handle file selection
    const handleFileInputChange = (e) => {
        const files = e.target.files || e.dataTransfer.files;
        setFiles(files);

        // Get the file sizes and update the state
        const sizes = Array.from(files).map((file) => formatFileSize(file.size));
        setFileSizes(sizes);

        const fileNames = Array.from(files).map((file) => file.name);
        setSelectedFiles([...selectedFiles, ...files]);
        setSelectedFileNames([...selectedFileNames, ...fileNames]);
    };

    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        return parts.length > 1 ? `.${parts.pop()}` : '';
    };



    function truncateFileName(fileName, maxLength) {
        if (typeof fileName !== 'string' || fileName.length === 0) {
            return ''; // Return an empty string or handle this case as needed
        }

        if (fileName.length <= maxLength) {
            return fileName;
        }


        const truncatedFileName = fileName.substring(0, maxLength - 5) + '...';
        return truncatedFileName;
    }
    // Function to format file size for display
    const formatFileSize = (size) => {
        if (size < 1024) {
            return size + ' B';
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(2) + ' KB';
        } else if (size < 1024 * 1024 * 1024) {
            return (size / (1024 * 1024)).toFixed(2) + ' MB';
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        }
    };




    const handleInputValidation = () => {
        const isEmpty = Object.values(data).some(dd => dd === '');

        if (!isEmpty && selectedEmails.length !== 0 && selectedTemplates.length !== 0) {
            setFormError({});
            return true;
        } else {
            setFormError({});
            if (data.title === '') {
                alert('Please Enter title');
                setFormError(prev => ({
                    ...prev,
                    title: "Please Enter Title"
                }));
            }
            else if (data.description === '') {
                alert('Please Enter Description');
                setFormError(prev => ({
                    ...prev,
                    description: "Please Add Description"
                }));
            }
            else if (data.startDate === '') {
                alert('Please select start date');
                setFormError(prev => ({
                    ...prev,
                    startDate: "Please select start date"
                }));
            }
            else if (data.endDate === '') {
                alert('Please select end date');
                setFormError(prev => ({
                    ...prev,
                    endDate: "Please select end date"
                }));
            } else if (data.priority === '') {
                alert('Please select priority');
                setFormError(prev => ({
                    ...prev,
                    priority: "Please select priority"
                }));
            } else if (selectedEmails.length === 0) {
                alert('Please select email');
                console.log(deliveryTerms.length == 0)
                setFormError(prev => ({
                    ...prev,
                    emailSelected: "Please select Atleast One Person"
                }));
            } else if (selectedTemplates.length == 0) {
                alert('Select atleast one term');
                setFormError(prev => ({
                    ...prev,
                    termSelected: "Please select Atleast One term"
                }));
            }
            return false;
        }
    };



    const handleCreateTask = async () => {
        if (handleInputValidation()) {
            openBackdrop();
            try {
                const assignBreadCrum = `${selectedSubcategories.map((sub) => sub.categoryName).join(">")} > ${productSelected.productName}`;
                const payload = {
                    title: data.title,
                    description: data.description,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    userExpectedCompletionDate: "",
                    status: data.status,
                    priority: data.priority,
                    assign: assignBreadCrum,
                    product: { productId: productSelected.productId },
                    template: selectedTemplates.map((temp) => {
                        return {
                            id: temp.id
                        }
                    })
                };

                const response = await privateAxios.post(`/commercialTask/createCommercialTasks/${auth.userData.id}/${auth.orgId}`, payload)
                    .then(async (response) => {
                        const taskId = response.data.taskId;
                        if (taskId !== null) {
                            if (files.length > 0) {
                                const fd = new FormData();
                                for (let i = 0; i < files.length; i++) {
                                    fd.append(`files`, files[i]);
                                }
                                await privateAxios.post(`/task/uploadTaskAttachments/${taskId}`, fd, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                    }
                                }).catch((err) => {
                                    alert("error uploading Files")
                                    console.error(err);
                                });
                            }


                            await privateAxios.post(`/task/addUsersToTask/${taskId}`, { emails: selectedEmails }).catch(err => {
                                alert("Error Adding User to task")
                            })

                            if (chats.length > 0) {
                                chats.forEach(async (chat) => {
                                    await privateAxios.post(`/task/addComments/${taskId}`,
                                        { comment: chat.comment, name: chat.name })
                                })
                            }

                            setUploadModalOpen(false);
                            setFiles("");
                            setProductSelected("");
                            getAllTasks();
                        }
                        else {
                            alert("error getting task Id");
                        }
                    }).catch(err => {
                    })
                handlecreateTaskModal()
                closeBackdrop()

            } catch (error) {
                console.log(error)
                closeBackdrop()
            }
        }
        else {
        }
    };


    const handlePriorityChange = (event) => {
        setData({ ...data, priority: event.target.value });
    };
    const handleStatusChange = (event) => {
        setData({ ...data, status: event.target.value });
    };


    const handleStartDateChange = (event) => {
        const selectedStartDate = event.target.value;
        const startDate = new Date(selectedStartDate);
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 1); // Set the end date to the day after the start date

        // Update the start and end date in your component's state
        setData({
            ...data,
            startDate: selectedStartDate,
            endDate: endDate.toISOString().slice(0, 10) // Format as 'YYYY-MM-DD'
        });
    };


    const handleEndDateChange = (event) => {
        setData({ ...data, endDate: event.target.value });

        // Check if the end date is earlier than the start dat
    };

    const handleEcDateChange = (event) => {
        const selectedEcDate = event.target.value;
        setData({ ...data, userExpectedCompletionDate: selectedEcDate });

        // Set the minimum date for the "End date" to the selected "E/C date"
        dateRef.current.min = selectedEcDate;
    };


    // ----------------------------Date----------------------------

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return date.toLocaleDateString(undefined, options);
    };


    // ----------------------------Date----------------------------


    const [hoveredFileIndex, setHoveredFileIndex] = useState(null);
    // Event handler for onMouseEnter
    const handleMouseEnter = (index) => {
        setHoveredFileIndex(index);
    };

    // Event handler for onMouseLeave
    const handleMouseLeave = () => {
        setHoveredFileIndex(null);
    };


    const handleFilePreview = (file) => {
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = file.name;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(a);
    }


    // ======================fetch Templates==========================

    const [templates, setTemplates] = useState([]);
    const [templatesData, setTemplatesData] = useState([]);

    const getTemplate = async () => {
        openBackdrop();
        try {
            const res = await privateAxios.get(`/procureadmin/getAllOrganisationSpecificationTemplates/${auth.orgId}`);
            setTemplates(res.data);
        } catch (error) {
            showToast("error", "error getting templates")
        }
        closeBackdrop();
    }


    const getTemplateData = async (specId) => {
        openBackdrop();
        try {
            const res = await privateAxios.get(`/procureadmin/getAllApprovedTemplates/${auth.orgId}/${specId}`);
            setTemplatesData(res.data);
        } catch (error) {
            showToast("error", "error getting templates data")
        }

        closeBackdrop();

    }

    useEffect(() => {
        getTemplate();
    }, [])
    // ======================fetch Templates==========================

    // ======================template states and dropdowns=============

    const [selectedTemplates, setSelectedTemplates] = useState([])
    const [templateDropDown, setTemplateDropDown] = useState("")

    const handleTemplateDropDown = (id) => {
        if (id === undefined || id === null || id === "") {
            setTemplateDropDown("")
        }
        else {
            if (templateDropDown === id) {
                setTemplateDropDown("");

            }
            else {
                setTemplateDropDown(id);
                getTemplateData(id);
            }


        }


    }

    const handleTemplateSelect = (temp, option) => {
        const alreadyPresent = selectedTemplates.some((opt) => opt.tempId === temp.id);
        if (!alreadyPresent) {
            setSelectedTemplates((prev) => {
                let template = {
                    ...option,
                    tempId: temp.id,
                    tempName: temp.specName,

                };
                return [...prev, template];
            });
        } else {
            setSelectedTemplates((prev) => {
                let template = {
                    ...option,
                    tempId: temp.id,
                    tempName: temp.specName,

                };
                let temps = prev.filter((las) => las.tempId !== temp.id); // Modified line
                return [...temps, template];
            });
        }
    };

    // ======================template states and dropdowns=============


    const termArray = ["Shipping Term", "Payment Term", "Billing Schedule"];
    const rawData = ['FOB', 'CIFd', 'Ex-Works', 'CIF', 'FOBED', 'CID', 'ExRef', 'CAD'];

    const termArrayTerms = {
        "shippingterm": ["Term 1", "Term 2", "Term 3"],
        "paymentterm": ["Pay 1", "Pay 2", "Pay 3"],
        "billingschedule": ["Bill 1", "Bill 2", "Bill 3"]
    }
    const [selectedTerm, setSelectedTerm] = useState('');

    const handleTermStatusChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedTerm(selectedValue);
    };

    const selectedTermFromDropDown = (term, item) => {
        console.log(term + " " + item);
        const termExists = deliveryTerms.some((obj) => obj.hasOwnProperty(term));
        if (termExists) {
            const updatedDeliveryTerms = deliveryTerms.map((obj) =>
                obj.hasOwnProperty(term) ? { [term]: item } : obj
            );
            setDeliveryTerms(updatedDeliveryTerms);
        } else {
            const selectedObject = { [term]: item };
            setDeliveryTerms((prevValues) => [...prevValues, selectedObject]);
        }
    };
    useEffect(() => {
        console.log(deliveryTerms);
    }, [deliveryTerms]);



    //Modal
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addBtnToggle, setAddBtnToggle] = useState(true)
    const [selectedCategories, setSelectedCategories] = useState([]);
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const handleRemoveCategory = (event, category) => {
        event.stopPropagation();
        const updatedCategories = selectedCategories.filter((cat) => cat !== category);
        setSelectedCategories(updatedCategories);

    };
    const handleCategoryToggle = (category) => {
        const categoryIndex = selectedCategories.findIndex((cat) => cat === category);
        if (categoryIndex !== -1) {
            // Category already selected, remove it
            const updatedCategories = [...selectedCategories];
            updatedCategories.splice(categoryIndex, 1);
            setSelectedCategories(updatedCategories);
        } else {
            // Category not selected, add it
            setSelectedCategories([...selectedCategories, category]);
        }

        console.log(selectedCategories)
    };
    useEffect(() => {
        console.log(selectedCategories)
    }, [selectedCategories])

    const [requestModal, setRequestModal] = useState(false)
    const [remark, setRemark] = useState('')
    const handleRemarkChange = (event) => {
        setRemark(event.target.value);
    };
    const handleTermsRequest = () => {
        if (selectedCategories.length == 0) {
            alert("Select atleast one term!")
        } else {
            console.log("selectedCategories : " + selectedCategories)
            console.log("Remark : " + remark)
            setShippingModalOpen(false)
            setRequestModal(true)
        }

    }
    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={TaskStyle.taskModalWrapper} >

                <div className={TaskStyle.taskModal}>

                    <div className={TaskStyle.taskInfoWrapper} >
                        <div className={TaskStyle.taskInfo}>

                            <div className={TaskStyle.taskNav}>
                                <h1 className={TaskStyle.createTask} >Create Task </h1>
                                {/* <h4>#001</h4> */}
                            </div>

                            <div className={TaskStyle.taskHeading}>

                                <input
                                    placeholder='Title'
                                    value={data.title}
                                    onChange={(e) => setData({ ...data, title: e.target.value })}
                                />

                                <textarea value={data.description}
                                    placeholder='description'
                                    onChange={(e) => setData({ ...data, description: e.target.value })}
                                />
                            </div>
                            <div className={TaskStyle.taskDetailsWrapper}>

                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Start Date
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <input

                                            type="date"
                                            value={data.startDate}
                                            min={new Date().toISOString().slice(0, 10)}
                                            onChange={handleStartDateChange}
                                        />
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            End Date
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <input
                                            type="date"
                                            value={data.endDate}
                                            onChange={handleEndDateChange}
                                            ref={dateRef}
                                            min={data.startDate} // Set the minimum date for the end date
                                        />
                                    </div>


                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            E/C date
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <input
                                            disabled
                                            type="date"
                                            value={data.userExpectedCompletionDate}
                                            onChange={handleEcDateChange}
                                        />
                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <DateRangeIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Status
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <OptionSelector
                                         value={data.status}
                                         onChange={handleStatusChange}
                                         name="status"
                                         // inputStyleProps={}
                                         // optionStyleProps={}
                                         options={[
                                             {
                                                 value: "TODO",
                                                 color: "#000",
                                                //  backgroundColor: "#ec5252",
                                                 label: "To-Do",
                                             },
                                             {
                                                 value: "INPROGRESS",
                                                 color: "#000",
                                                //  backgroundColor: "#ec5252",
                                                 label: "In-Progress",
                                             },
                                             {
                                                 value: "DONE",
                                                 color: "#000",
                                                //  backgroundColor: "#f5ac38",
                                                 label: "Done",
                                             },
                                             {
                                                 value: "COMPLETED",
                                                 color: "#000",
                                                //  backgroundColor: "#b3f289",
                                                 label: "Completed",
                                             },

                                         ]}
                                        
                                        />
                                      
                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <DateRangeIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Priority
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <PrioritySelector
                                            value={data.priority}
                                            onChange={handlePriorityChange}
                                            name="priortiy"
                                            // inputStyleProps={}
                                            // optionStyleProps={}
                                            options={[
                                                {
                                                    value: "HIGH",
                                                    color: "#fff",
                                                    backgroundColor: "#ec5252",
                                                    label: "High",
                                                },
                                                {
                                                    value: "MEDIUM",
                                                    color: "#fff",
                                                    backgroundColor: "#f5ac38",
                                                    label: "Medium",
                                                },
                                                {
                                                    value: "LOW",
                                                    color: "#fff",
                                                    backgroundColor: "#b3f289",
                                                    label: "Low",
                                                },

                                            ]}
                                        />



                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <GroupsIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Assignee
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <p>
                                            {auth.name}
                                        </p>
                                    </div>



                                </div>


                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol} >
                                        <span>
                                            <GroupsIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Assigned to
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>

                                        {Array.isArray(selectedEmails) && selectedEmails.length > 0 && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {selectedEmails.map((email, index) => (
                                                    <div key={email} style={{ display: 'flex', alignItems: 'center' }}>
                                                        {index !== 0 && <div style={{ fontSize: '12px', padding: '0px 5px' }}></div>}
                                                        <div
                                                        >
                                                            <ProfileIcon data={{ email: email }} height={"26px"} width={"26px"} showProfileBanner={true} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <AddIcon onClick={handleEmailModalOpen} sx={{ color: '#6A6A6A' }} />
                                    </div>



                                </div>


                                
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol} style={{ height: "100%" }} >
                                        <span>
                                            <AttachFileIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Attachment
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol} style={{ height: "auto", padding: "4px 12px" }}>
                                        <div className={TaskStyle.taskFileWrapper}>

                                            {selectedFiles.map((file, index) => (
                                                <div key={index} className={TaskStyle.selectedFileItem}
                                                >
                                                    <p
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleFilePreview(file)}
                                                    >

                                                        {truncateFileName(file.name, 15)}

                                                    </p>
                                                    <span onClick={() => handleRemoveSelectedFile(index)} style={{ textDecoration: 'none', border: 'none' }}>
                                                        <CloseIcon

                                                        />
                                                    </span>
                                                </div>
                                            ))}

                                            <button className={TaskStyle.uploadButton}
                                                onClick={() => { handleUploadModalOpen() }}
                                            >Upload Files</button>

                                        </div>
                                    </div>



                                </div>

                                <CategorySelector
                                    category={availableCategory}
                                    selectedSubcategories={selectedSubcategories}
                                    setSelectedSubcategories={setSelectedSubcategories}
                                    productSelected={productSelected}
                                    setProductSelected={setProductSelected}
                                />

                                {templates.map((temp, index) => {
                                    return (
                                        <CommercialTemplateSelector
                                            showToast={showToast}
                                            data={temp}
                                            key={index}
                                            selectedTemplates={selectedTemplates}
                                            setSelectedTemplates={setSelectedTemplates}
                                            openBackdrop={openBackdrop}
                                            closeBackdrop={closeBackdrop}
                                            handleTemplateSelect={handleTemplateSelect}
                                        />
                                    )
                                })}

                            </div>


                        </div>
                        <div style={{ display: "flex", height: "40px" }}>
                            <button
                                style={{
                                    backgroundColor: '#16469D',
                                }}
                                className={TaskStyle.taskButton}
                                onClick={handleCreateTask}
                            >Create a Task</button>
                        </div>

                        
                    </div>



                    <ChattPanel chats={chats} setChats={setChats} handlecreateTaskModal={handlecreateTaskModal} />



                </div >

            </div >


            {/* Modals related to commercial tasks */}
            <Modal open={shippingModalOpen} onClose={() => { }}>
                <Box sx={styleSelectEmails} className={commercialStyle.commercialModalss}>
                    <div className={commercialStyle.modalHeadingNames}>
                        <h3 className={commercialStyle.modalHeadingCo}>
                            Add template for {currentTerm}
                        </h3>
                        <CloseIcon sx={{ color: '#6A6A6A' }} onClick={handleShippingModalClose} />
                    </div>

                    <div className={formStyle.inputRow}>
                        <label className={commercialStyle.formShippingItem} style={{ fontSize: '14px', fontWeight: '450', marginBottom: '7px' }}>Delivery Terms</label>
                        <div className={RedirectRegistrationStyle.dropdownContainer}>
                            <div
                                className={`${RedirectRegistrationStyle.dropdown} ${dropdownOpen && RedirectRegistrationStyle.open}`}
                                onClick={() => {

                                    if (addBtnToggle) {
                                        toggleDropdown();
                                    }
                                }
                                }
                            >
                                <div className={RedirectRegistrationStyle.selectedCategories}>
                                    {selectedCategories.length > 0 ?
                                        selectedCategories.map((category, index) => (
                                            <div key={index} className={RedirectRegistrationStyle.selectedCategory}>
                                                {category}
                                                <CloseIcon
                                                    className={RedirectRegistrationStyle.closeIcon}
                                                    onClick={(event) => handleRemoveCategory(event, category)}
                                                />
                                            </div>
                                        )) :
                                        ''}
                                </div>
                                <KeyboardArrowDownIcon
                                    className={RedirectRegistrationStyle.arrowIcon}
                                    onClick={toggleDropdown}
                                />
                            </div>
                            {dropdownOpen && (
                                <div className={RedirectRegistrationStyle.dropdownContent}>
                                    {rawData.map((category, index) => (
                                        <div
                                            key={index}
                                            className={RedirectRegistrationStyle.dropdownItem}
                                            onClick={() => handleCategoryToggle(category)}
                                        >
                                            <span>{category}</span>
                                            <input
                                                type="checkbox"
                                                name='categorySelect'
                                                checked={selectedCategories.some((cat) => cat === category)}
                                                readOnly
                                                required
                                            />
                                        </div>
                                    ))}


                                </div>

                            )}

                        </div>

                    </div>

                    <label className={commercialStyle.formShippingItem}>
                        Remarks
                        <textarea onChange={handleRemarkChange} style={{ color: 'black', border: '1px solid #ccc' }} type="text" />
                    </label>


                    <div className={commercialStyle.formShippingItem}>

                        <button onClick={handleTermsRequest}>Send Request</button>

                    </div>

                </Box>
            </Modal>

            {/* *************************************Upload Files Modal ******************************/}

            <Modal open={uploadModalOpen}
            //  onClose={handleUploadModalClose}

            >
                <Box sx={styleUploadFiles} className={TaskStyle.uploadFilesModal}
                    style={{
                        borderRadius: '6px',

                    }}>
                    <div style={{
                        display: 'flex', justifyContent: 'space-between'
                    }}>
                        <Typography variant="h5" component="h2"
                        >
                            Upload Files
                        </Typography>
                        <Tooltip>
                            <CancelIcon
                                onClick={handleUploadModalClose}
                            />
                        </Tooltip>
                    </div>
                    <Box className={TaskStyle.dragAndDrop}>
                        <Typography variant="subtitle1">Drag and drop files here</Typography>
                        <Button variant="contained" onClick={() => inputRef.current.click()}
                            sx={{ textTransform: 'capitalize' }}>
                            Upload
                            <input
                                multiple // Allow multiple file selection
                                ref={inputRef}
                                type="file"
                                hidden
                                onChange={handleFileInputChange}
                            />
                        </Button>
                        <div className={TaskStyle.selectedFilesList}
                            style={{
                                display: 'flex', gap: '10px',
                                backgroundColor: '#D9D9D9'
                            }}
                        >
                            <div
                                //  key={index}
                                className={`${TaskStyle.selectedFileItem} ${TaskStyle.hoveredDiv2}`}
                                style={{
                                    border: '1px solid #D9D9D9',
                                    backgroundColor: '#F6FAFF',
                                    borderRadius: '8px', display: 'flex', gap: '10px',

                                }}>
                            </div>

                        </div>

                    </Box>
                    <div className={TaskStyle.FileSelect}
                    >
                        {selectedFiles.map((file, index) => (
                            <div
                                key={index} className={`${TaskStyle.selectedFileItem} ${TaskStyle.hoveredDiv}`}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={() => handleMouseLeave()}
                                style={{
                                    borderRadius: '8px',
                                    display: 'flex',
                                    gap: '10px',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    marginBottom: '20px',
                                    backgroundColor: hoveredFileIndex === index ? '#F6F6F6' : '#ffff',
                                }}>
                                <div style={{ display: 'flex', width: '70%', padding: '12px', alignItems: 'center', gap: '10px' }}>
                                    <DescriptionIcon
                                        style={{
                                            color: '#16469D',

                                        }} />
                                    <p
                                        style={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            lineHeight: '13.8px',
                                            color: '#000000'
                                        }}>{truncateFileName(file.name, 30)}</p>

                                    <Button
                                        variant='text'
                                        target='_blank'
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            lineHeight: '13.8px',
                                            color: '#16469D',
                                            textTransform: 'capitalize',
                                        }}
                                        onClick={() => handleFilePreview(file)}
                                    >
                                        {/* Preview */}
                                    </Button>

                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        padding: '12px',
                                        alignItems: 'center',
                                    }}>
                                    <p style={{
                                        fontWeight: '400',
                                        fontSize: '10px',
                                        lineHeight: '11.5px',
                                        color: '#000000'
                                    }}>{fileSizes[index]}</p>
                                </div>
                                {hoveredFileIndex === index && (
                                    <CancelIcon
                                        className={TaskStyle.hoveredDiv2}
                                        onClick={() => handleRemoveSelectedFile(index)}
                                        style={{
                                            cursor: 'pointer',
                                            color: '#16469D',
                                            fontSize: '18px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>

                    <div className={TaskStyle.saveDiscardButtons}>
                        <Button variant="contained"
                            onClick={() => {
                                handleUploadModalClose();
                            }} >
                            Upload files
                        </Button>
                    </div>
                </Box>
            </Modal>

            <Modal open={emailModalOpen}
                onClose={handleEmailModalClose}>
                <Box sx={styleSelectEmails}
                    className={TaskStyle.thirdModal}>
                    <Typography variant="h5" >
                        <PersonIcon sx={{ color: '#6A6A6A' }} /> Add member
                    </Typography>
                    <Typography variant="h6"> Board member</Typography>
                    <div className={TaskStyle.emailSelection}>
                        {/* Display the list of users */}
                        {loadingUsers ? (
                            <p>Loading users...</p>
                        ) : (
                            userList.map((email) => {
                                // console.log(email)
                                return (
                                    <label >
                                        {email}
                                        <input
                                            style={{ width: '20px' }}
                                            type="checkbox"
                                            checked={selectedEmails.includes(email)}
                                            onChange={() => handleEmailCheckboxChange(email)}
                                        />
                                    </label>
                                )
                            })
                        )}

                        {notLoggedIn?.map((email, index) => {
                            return (
                                <label>{email}
                                    <input style={{ width: '105px' }} disabled value={"Never Logged In"}></input>
                                </label>
                            )
                        })}
                    </div>

                </Box>
            </Modal>

            <Modal open={requestModal} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className={commercialStyle.requestModalClass}>
                    <div className={commercialStyle.requestModalIcon}><CheckCircleOutlineIcon /> </div>
                    <div className={commercialStyle.requestModalSpan}>Request sent successfully <span onClick={() => { alert("navigate to requests page"); setRequestModal(false) }}>View Request</span></div>
                </div>
            </Modal>


        </>
    )
}

export default CreateTaskCommercial