import React from 'react'
import { useState } from 'react'
import optionStyle from './OptionSelector.module.css'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const OptionSelector = ({ value, onChange, name, options, inputStyleProps, optionStyleProps, disabled }) => {

    const [listOpen, setListOpen] = useState(false)
    const handleClick = () => {
    }

    return (
        <div className={optionStyle.selectorWrapper}>
            <p onClick={() => { setListOpen(prev => !prev) }}
                style={{
                    backgroundColor: options.find((item) => item.value === value)?.backgroundColor,
                    color: options.find((item) => item.value === value)?.color
                }}

            >{value || "Select an Option"}
                {
                    listOpen
                        ?
                        <ArrowDropUpIcon />
                        :
                        <ArrowDropDownIcon />
                }
            </p>
            {
                (!disabled && listOpen) && (
                    <>

                        <div className={optionStyle.backdrop}
                            onClick={() => { setListOpen(prev => !prev) }}
                        ></div>

                        <ul className={optionStyle.dropDownList}>
                            {options?.map((option, index) => (
                                <li key={index} onClick={() => { onChange({ target: { value: option?.value, name: name } }); setListOpen(prev => !prev) }}
                                    style={{ backgroundColor: option?.backgroundColor, color: option?.color }}
                                >{option?.label}</li>
                            ))}
                        </ul>

                    </>
                )
            }

        </div>
    )
}

export default OptionSelector
