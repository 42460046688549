import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import revisionEditStyle from './CommercialRevisionCompare.module.css'


const CompareDescriptionTab = ({ revisionDescription }) => {
  return (
    <div className={revisionEditStyle.specficationTabMain}>
      <div className={revisionEditStyle.descriptionBox}>
        <ReactQuill modules={{ toolbar: false }}
          readOnly={true}
          style={{width:"100%",height:"45vh"}}
          theme="snow" 
          value={Object.values(revisionDescription)[0]?.name}
        />
      </div>

        {Object.values(revisionDescription).map((spec, index) => {
          return (
            <>
            <h3>{Object.keys(revisionDescription)[index]}</h3>
                 <textarea name="" id="" cols="30" rows="10" value={spec.vendorRemark}  onChange={()=>{console.log("Editing Not Allowed")}}className={revisionEditStyle.descriptionCompareTextBox}/>
            </>   
          )
        })}
    
    </div>
  )
}

export default CompareDescriptionTab
