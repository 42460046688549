import React from 'react'
import documentChatStyle from './DocumentChat.module.css';
import ProfileIcon from '../ProfileIcon/ProfileIcon';

const DocumentChatRow = ({name,date,chat,email}) => {
  return (
    <div className={documentChatStyle.documentChatRow}>
    <div className={documentChatStyle.documentChatRowHeader}>
      <ProfileIcon data={{ name:name, email:email, type: "KAM", profileLink: "SomeLink" }} height={"26px"} width={"26px"} showProfileBanner={false} />

      <div className={documentChatStyle.documentChatRowInfo} >
        <p>{name}</p>
        <span>{date?.split("T")[0]}</span>
      </div>
    </div>
    <p className={documentChatStyle.documentChatRowChat}>
      {chat}
    </p>
  </div>
  )
}

export default DocumentChatRow
