import React, { useEffect, useState } from 'react'
import navbarStyle from './Navbar.module.css'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import GridViewIcon from '@mui/icons-material/GridView';
import ChecklistIcon from '@mui/icons-material/Checklist';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

const VendorNavbar = () => {

      // Initialize navigate and location hooks
  const navigate = useNavigate();
  const location = useLocation(); 

  const [workSpacesDropOpen, setWorkSpacesDropOpen] = useState(false);

    // State to store current URL
  const [url, setUrl] = useState(location.pathname || "");
  
    // Get authentication state from useAuth hook
  const { auth } = useAuth(); //
  const handleNavigate = (url) => {

    navigate(url)
    setWorkSpacesDropOpen(false)
  }

  const handleNavItemClick = (navItem) => {
    setWorkSpacesDropOpen(prev => !prev)
  }

  useEffect(() => {
    const urlArray = location.pathname.split("/");
    setUrl(urlArray[2])
  }, [location])

  return (
    <div className={navbarStyle.navMain}>


      <List sx={{ padding: "0px" }} style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

        <ListItem disablePadding onClick={() => handleNavigate("dashboard")}>
          <ListItemButton
            selected={url === 'dashboard'}
            sx={{
              '&:focus': {
                backgroundColor: '#E3F5FF',
              },
              backgroundColor: url === 'dashboard' ? '#E3F5FF' : 'transparent',

              borderRadius: "8px"
            }}
          >
            <GridViewIcon 
              
                sx={{
                  borderRadius: '3px',
                  color: url === 'dashboard' ? '#16469D' : '#344054',
                  marginRight: '12px',
                }}
              /> 
            <ListItemText
              primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}

              sx={{
                color: url === 'dashboard' ? '#16469D' : '#344054',
              }}
            >
              Dashboard

            </ListItemText>

          </ListItemButton>
        </ListItem>


        <ListItem disablePadding onClick={() => handleNavigate("organisations")}>
          <ListItemButton
            selected={url === 'organisations'}
            sx={{
              '&:focus': {
                backgroundColor: '#E3F5FF',
              },
              backgroundColor: url === 'organisations' ? '#E3F5FF' : 'transparent',

              borderRadius: "8px"
            }}
          >
            <ChecklistIcon
                sx={{
                  borderRadius: '4px',
                  color: url === 'organisations' ? '#16469D' : '#344054',
                  marginRight: '12px',
                  border: '2px solid ',
                }}
              /> 
            <ListItemText
              primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}

              sx={{
                color: url === 'organisations' ? '#16469D' : '#344054',
              }}
            >
              My Tasks

            </ListItemText>
            <span className={navbarStyle.navItemBubble}>
              10
            </span>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={() => handleNavigate("underconstruction")}>
          <ListItemButton
            selected={url === 'underconstruction'}
            sx={{
              '&:focus': {
                backgroundColor: '#E3F5FF',
              },
              backgroundColor: url === 'underconstruction' ? '#E3F5FF' : 'transparent',

              borderRadius: "8px"
            }}
          >
            <TextsmsOutlinedIcon
                sx={{
                  
                  color: url === 'underconstruction' ? '#16469D' : '#344054',
                  marginRight: '12px', 
                }}
              />
            <ListItemText
              primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}

              sx={{
                color: url === 'underconstruction' ? '#16469D' : '#344054',
              }}
            >
              Messages

            </ListItemText>
            <span className={navbarStyle.navItemBubble}>
              10
            </span>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={() => handleNavigate("organisations")}>
          <ListItemButton
            selected={url === 'mytasks'}
            sx={{
              '&:focus': {
                backgroundColor: '#E3F5FF',
              },
              backgroundColor: url === 'mytasks' ? '#E3F5FF' : 'transparent',

              borderRadius: "8px"
            }}
          >
            <CorporateFareIcon
                sx={{ 
                  color: url === 'mytasks' ? '#16469D' : '#344054',
                  marginRight: '12px', 
                }}
              />
            <ListItemText
              primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}

              sx={{
                color: url === 'mytasks' ? '#16469D' : '#344054',
              }}
            >
              Organisations

            </ListItemText>
            <span className={navbarStyle.navItemBubble}>
              10
            </span>
          </ListItemButton>
        </ListItem>



      </List>

      <div>
       

      

      </div>




    </div>
  )
}

export default VendorNavbar
