import React, { useState } from 'react'
import headerStyle from './Header.module.css'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SettingsIcon from '@mui/icons-material/Settings';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import workSpaceStyle from '../WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const VendorHeader = ({ handleDrawerOpen, isOpen }) => {
  // State to manage profile expansion panel open/close
  const [headerProfileExpandOpen, setHeaderProfileExpandOpen] = useState(false)

  // Get authentication state and functions from useAuth hook
  const { auth, setLogout } = useAuth();

  // Initialize navigate function for routing
  const navigate = useNavigate();

  // Function to handle navigation to a specific URL
  const handleNavigate = (url) => {
    navigate(url)
  }

  // Function to generate initials from user's name
  const handleInitals = (name) => {
    const initalsArray = name.split(" ");
    let initial = "";
    if (initalsArray.length > 1) {

      initial = initalsArray[0].charAt(0) + initalsArray[1].charAt(0);
    }
    else {
      initial = initalsArray[0].charAt(0);
    }

    return initial

  }


  // Dispatch function from Redux
  const dispatch = useDispatch();

  // Function to handle user logout
  const handleLogout = () => {
    // Dispatch action to set logout state
    dispatch(setLogout());
    // Navigate to login page after logout
    navigate('/login', { state: { data: null }, replace: true });

  };


  return (
    <div className={headerStyle.headerMain}>
      <div className={headerStyle.headerContent}>
        <div className={headerStyle.MenuTogglerDiv}>
          <div className={headerStyle.hamBurger} onClick={handleDrawerOpen}><MenuIcon /> </div>
          <div className={headerStyle.headerClientLogo}>

            <button className={headerStyle.defaultLogoSty} onClick={() => handleNavigate("companyProfile")}>Your Logo Here</button>

          </div>
        </div>


        <div className={headerStyle.headerLinks}>
          <div className={headerStyle.headerIcons}>
            <Link >
              <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
            </Link>
          </div>

          <div className={headerStyle.headerProfile} onClick={() => setHeaderProfileExpandOpen(prev => !prev)}>

            <div className={headerStyle.headerProfileimg} >{handleInitals(auth.name)}</div>

            <div className={headerStyle.headerProfileText} >
              <p> {auth.name}</p>
              {
                headerProfileExpandOpen
                  ? <KeyboardArrowUpIcon />
                  : <KeyboardArrowDownIcon />
              }
            </div>

          </div>


          {headerProfileExpandOpen &&
            <>

              <div className={workSpaceStyle.workSpaceHeaderDropDownBackDrop} onClick={() => setHeaderProfileExpandOpen(false)}></div>
              <div className={headerStyle.headerProfileExpand} >
                <Link to={"profile"} className={headerStyle.headerProfileExpandHeader} >

                  <div className={headerStyle.headerProfileimg} >{handleInitals(auth.name)}</div>

                  <p> {auth.name} </p>
                </Link>
                <Divider />
                <ul className={headerStyle.headerProfileExpandList}>
                  <Link to={"profile"}><PermIdentityIcon />Profile</Link>
                  <Link to={"companyProfile"}><SettingsIcon />Settings</Link>
                  <Link><InsertDriveFileIcon />Plans</Link>
                </ul>
                <Divider />
                <ul className={headerStyle.headerProfileExpandList}>
                  <Link onClick={handleLogout} to={"/login"} state={{ data: null }} replace="true"><LogoutIcon />Logout</Link>
                </ul>

              </div>
            </>
          }

        </div>
      </div>
    </div >
  )
}

export default VendorHeader
