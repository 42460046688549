import React, { useState, useRef, useEffect } from "react";
import CompanyProfileStyle from './CompanyProfile.module.css';
import ProfileStyle from '../ProfilePage/ProfileStyle.module.css';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import { Button, Tooltip } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { PrivateComponent } from 'api/axios';
import { useDispatch } from 'react-redux';
import { HelpOutlineOutlined } from "@mui/icons-material";


const CompanyProfilePage = () => {
    // Initializing privateAxios using a custom hook or component for authenticated API calls
    const privateAxios = PrivateComponent();
    // Destructuring auth, setUserData, and setOrgLogo from the useAuth hook
    const { auth, setUserData, setOrgLogo } = useAuth();
    // State to store categories
    const [categories, setCategories] = useState([]);
    // Initializing dispatch for Redux actions
    const dispatch = useDispatch();

    // Handler for dropping files into a designated drop area
    const handleDrop = (e) => {
        e.preventDefault();// Prevent default behavior for drop event

        const files = e.dataTransfer.files;// Get the dropped files
        if (files.length > 0) {// Check if any files are dropped
            const file = files[0];// Get the first dropped file

            if (file.type.startsWith('image/')) {// Check if the file is an image
                // Valid image file dropped, handle it
                handleImage(file);  // Call handleImage function to process the file
            } else {
                // Invalid file type dropped
                alert('Invalid file type. Please drop an image file.');// Show alert for invalid file type
            }
        }
    };

    // Handler for image upload through a file input
    const handleImageUpload = () => {
        const input = document.createElement('input');// Create an input element
        input.type = 'file'; // Set input type to file
        input.accept = 'image/*';// Accept only image files
        input.onchange = async (e) => {// Handle the change event
            const file = e.target.files[0]; // Get the selected file

            if (file) { // Check if a file is selected
                handleImage(file);// Call handleImage function to process the file
            }
        };
        input.click();// Programmatically click the input to open the file dialog
    };

    // Function to handle the image file processing and upload
    const handleImage = async (file) => {
        const formData = new FormData();// Create a FormData object
        formData.append('file', file);// Append the file to FormData

        try {
            // Post the image file to the server using privateAxios
            await privateAxios.post(`/procurement/uploadOrganisationLogo/${auth.orgId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',// Set the content type to multipart/form-data
                },
            }).then((res) => {
                // Dispatch an action to set the organization logo in the global state
                dispatch(setOrgLogo({ orgLogo: res.data }));
                alert('Logo updated successfully.');// Show success alert
            });
        } catch (error) {
            // Handle errors during the upload process
            console.error('Error updating logo:', error);
            alert('An error occurred while updating the logo.');// Show error alert
        }
    };

    // Function to fetch categories from the server
    const fetchCategory = async () => {
        try {
            // Get categories using privateAxios
            await privateAxios.get(`/procurement/getCategories/${auth.orgId}`).then(res => {
                // Set the categories state with the response data
                setCategories(res.data.data.categories);
            }).catch((err) => {
                // Handle errors during the fetch process
                alert("error getting categories");// Show error alert
            })
        } catch (error) {
            // Additional error handling if necessary
        }
    }

    // useEffect hook to fetch categories when the component mounts
    useEffect(() => {
        fetchCategory();// Call fetchCategory to get categories
    }, []);// Empty dependency array means this runs once after initial render


    

    return (
        <div className={workSpaceStyle.workSpaceMain}>
            <div className={ProfileStyle.profileMainContainer}>
                <div className={ProfileStyle.profileFirstRow}>
                    <div>
                        <h3>Company Profile</h3>
                    </div>
                    <div>
                        <Tooltip title='Help'>
                            <a target="_blank" href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000080858-company-profile">
                                <HelpOutlineOutlined />
                            </a>

                        </Tooltip>
                    </div>
                </div>


                <div className={ProfileStyle.profileMainRow}>
                    <form action='' className={ProfileStyle.ProfileInfoTable}>
                        <div className={ProfileStyle.formInfoColumn}>

                            <div className={ProfileStyle.formGroup}>
                                <label>Organization Name</label>
                                <input type='text' placeholder="Organization Name" value={auth.companyName} disabled />
                            </div>
                            <div className={ProfileStyle.formGroup}>
                                <label>Plan</label>
                                <input type='text' placeholder="Plan" value={auth?.allData?.susbscriptionName} disabled />
                            </div>


                        </div>
                        <div className={ProfileStyle.formInfoColumn}>
                            <div className={ProfileStyle.formGroup}>
                                <label>GST/VAT Details</label>
                                <div className={ProfileStyle.mobileInputField}>
                                    <input type="text" placeholder="GST/VAT Details" value={auth.allData["gst/vat"]} disabled />
                                    {/* <button type='button'>Change</button> */}
                                </div>
                            </div>
                            <div className={ProfileStyle.formGroup}>
                                <label>Selected Categories</label>
                                <div className={CompanyProfileStyle.selectedCateDta}>
                                    {categories?.map(category => category.categoryName).join(', ')}
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className={CompanyProfileStyle.CompanyProfilePictureBox}>
                        <div className={CompanyProfileStyle.companyprofileLastRow}>
                            <div>
                                <figure className="image-container" >
                                    {auth.orgLogo !== null ?
                                        <img src={`${process.env.REACT_APP_BASEURL}/procurement/file/${auth.orgLogo.newLogo}`} />

                                        : auth.roles === "KAM" || auth.roles === "VENDOR" ?

                                            <div
                                                className={CompanyProfileStyle.dropzone}
                                                onDrop={handleDrop}
                                                onDragOver={(e) => e.preventDefault()}
                                            >
                                                <p>Drag & Drop your logo here <br /> <span style={{ margin: '50px' }}>or</span> </p>
                                                <button onClick={handleImageUpload} className={CompanyProfileStyle.uploadPicInDropzone}>Upload</button>
                                            </div> : ''
                                    }

                                    {auth.roles === "KAM" || auth.roles === "VENDOR" ?
                                        <figcaption>Recommended resolution 580X80px</figcaption> : ''}

                                </figure>
                            </div>
                            <div>
                                {auth.roles === "KAM" || auth.roles === "VENDOR" ? auth.orgLogo !== null ?
                                    <Button variant="contained" onClick={handleImageUpload}>
                                        Change Logo
                                    </Button> : '' : <div style={{ fontWeight: '500' }}>Company Logo </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div >
    )
}
export default CompanyProfilePage;