import React, { useState, useEffect } from 'react'
import TaskStyle from '../../../../../CreateTask/TaskPannel/TaskPannel.module.css'
import commercialStyle from '../../../../../CommercialViewKAM/KamCreateTask/commercialStyles.module.css';
import useAuth from 'hooks/useAuth';
import { PrivateComponent } from 'api/axios';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AttachFileIcon from '@mui/icons-material/AttachFile';


const CommercialTemplateSelector = ({ showToast, data, key, selectedTemplates, setSelectedTemplates, handleTemplateSelect, openBackdrop, closeBackdrop }) => {
    // State variables
    const [avaiableTemplate, setAvaiableTemplate] = useState([]);
    const [templateDropDown, setTemplateDropDown] = React.useState(false);

    // Custom hook for authentication
    const { auth } = useAuth();
    const privateAxios = PrivateComponent();

    // Toggle template dropdown
    const handleTemplateDropDown = () => {
        setTemplateDropDown(prev => !prev)
    }
    // Fetch available templates
    const getAvailableTemplate = async () => {
        openBackdrop();
        try {
            const res = await privateAxios.get(`/procureadmin/getAllImportedTemplates/${data.id}`);
            setAvaiableTemplate(res.data);
        } catch (error) {
            showToast("error", "error getting templates data")
        }
        closeBackdrop();

    }

    useEffect(() => {
        // Fetch available templates on component mount
        getAvailableTemplate();
    }, [])

    // State variable for add template modal
    const [addTemplateModal, setAddTemplateModal] = useState(false);
    // Toggle add template modal
    const handleAddTemplateModal = () => {
        setAddTemplateModal(prev => !prev);
    }


    // ================get Avaiable Template to Import========

    // Fetch templates from super admin
    const [adminTemplates, setAdminTemplates] = useState([]);


    const getDataFromSuperAdmin = async () => {
        openBackdrop();
        try {
            const res = await privateAxios.get(`/procureadmin/getAllImportedTemplates/${data.id}`);
            // Filter templates not already available
            const requestedTemplates = res.data.filter((req) => {
                return avaiableTemplate.some(tee => tee.name !== req.name)
            })
            setAdminTemplates(requestedTemplates)
        } catch (error) {
            showToast("error", error.message)
        }
        closeBackdrop();
    }


    useEffect(() => {
        // Fetch data from super admin when addTemplateModal state changes
        if (addTemplateModal) {
            getDataFromSuperAdmin();
        }
    }, [addTemplateModal])



    // ================handle Template CheckBox========

    // State variable for selected templates
    const [selectedTemplateItem, setSelectedTemplateItem] = useState([]);

    // Handle template checkbox selection
    const handleTemplateCheckBox = (temp) => {
        if (selectedTemplateItem.some((rt) => rt.id === temp.id)) {
            setSelectedTemplateItem(prev => prev.filter((rt) => rt.id !== temp.id))
        } else {
            setSelectedTemplateItem(prev => [...prev, temp])
        }
    }


    // Request selected templates
    const handleRequestTemplate = async () => {
        openBackdrop();
        try {
            const payload = {
                ids: selectedTemplateItem.map((temp) => temp.id),
                orgId: auth.orgId,
            }
            const res = await privateAxios.post(`/procureadmin/requestTemplateInOrganisationTemplate`, payload)
            showToast("success", "Template Requested Successfully");

        } catch (error) {
            showToast("error", "Error Requesting Template");
        }
        handleAddTemplateModal();
        closeBackdrop();
    }




    return (
        <>

            <ul className={TaskStyle.list} >
                <li >
                    <AttachFileIcon sx={{ color: '#6A6A6A', transform: 'rotate(30deg)', height: '18px' }} />
                    {data.name}
                </li>

                <li
                    style={{ flexGrow: '1' }}
                >
                    <div style={{ position: "relative" }} className={commercialStyle.selectorMain}>
                        <p onClick={() => handleTemplateDropDown()}>
                            {selectedTemplates.find((selected) => selected.tempId === data.id)?.name || 'Select a template'} <span><KeyboardArrowUpIcon /></span></p>
                        {
                            templateDropDown &&
                            <ul className={commercialStyle.listDropDown}>
                                {/* <li
                            key={`option-add `}
                            onClick={() => {handleAddTemplateModal(); handleTemplateDropDown() }}
                        >+ Add</li> */}
                                {avaiableTemplate.map((opt) => {


                                    return (

                                        <li
                                            key={`option-${opt.id}`}
                                            onClick={() => { handleTemplateSelect(data, opt); handleTemplateDropDown() }}
                                        >{opt.name}</li>
                                    )
                                })}

                            </ul>
                        }

                    </div>







                </li>
            </ul>



            {/* <Modal
        open={addTemplateModal}
        onClose={handleAddTemplateModal}

    >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>


            <div style={{
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "20px"

            }}>

                <div className={workSpaceStyle.modalHeader}>
                    <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Import Template in {data.specName}</h2>
                    <span onClick={handleAddTemplateModal}><CloseIcon /></span>
                </div>

                <div className={commercialStyle.templatesWrapper}>
                    {
                        adminTemplates.map((temp, index) => {
                            return (
                                <div className={commercialStyle.templateCard}>
                                    <p>{temp.name}</p>
                                    <input type="checkbox" checked={selectedTemplateItem.some((rt) => rt.id === temp.id)} onChange={() => handleTemplateCheckBox(temp)} />
                                </div>
                            )
                        })
                    }


                </div>

                <div style={{width:"100%",display:"flex",}}>
                    <button style={{height:"35px",
                    padding:"10px 15px",
                    backgroundColor:"#16469D",
                    border:"none",
                    color:"white",
                    borderRadius:"5px",
                    cursor:"pointer",
                    
                    }} 
                    onClick={handleRequestTemplate}
                    >Send Request</button>
                </div>


            </div>



        </div>


    </Modal> */}

        </>
    )
}

export default CommercialTemplateSelector
