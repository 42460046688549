import React, { useEffect, useState } from 'react'// Import React and necessary hooks
import { Badge, Typography } from "@mui/material";// Import Badge and Typography components from Material-UI
import { PrivateComponent } from 'api/axios'; // Import PrivateComponent from axios
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'; // Import AccessTimeOutlinedIcon from Material-UI


const WatchIconWithBadge = ({ url, id, reRender }) => { // Functional component definition with props
    const privateAxios = PrivateComponent(); // Get instance of axios for private requests

    // State to store notification count
    const [notiCount, setNotiCount] = useState("0");

    // Function to get the notification count for a given URL and ID
    const getRevisionNotificationCount = async () => {
        try {
            // Make a GET request to the specified URL with the provided ID
            const res = await privateAxios.get(`${url}/${id}`)

            // Check if data is not null, undefined, or an empty string
            if (res.data !== null && res.data !== undefined && res.data !== "") {
                // Update the notification count state with the fetched data
                setNotiCount(res.data)
            }
            else {
                // If the data is null, undefined, or an empty string, set notification count to "0"
                setNotiCount("0")
            }

        } catch (error) {
            console.log(error) // Log any errors to the console
        }
    }

    // Effect to fetch the notification count whenever reRender prop changes
    useEffect(() => {
        getRevisionNotificationCount(); // Call the function to fetch the notification count
    }, [reRender]);


    
    return (
        <>

            {notiCount !== "0" ? <Badge style={{ height: "20px" }} badgeContent={notiCount} color="primary">
                <AccessTimeOutlinedIcon />
            </Badge>
                :
                <AccessTimeOutlinedIcon />
            }
        </>
    )
}

export default WatchIconWithBadge
