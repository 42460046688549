import React, { useState, useEffect } from 'react';
import TaskStyle from './SuperAdminProduct.module.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';

const SubcategorySelector = ({ category, selectedSubcategories, onSelectSubcategory, index }) => {
   // Use state to manage whether the subcategory selector dropdown is open or closed
   const [subCatSelect, setSubCatSelect] = useState(false);
   // Use state to manage the currently selected subcategory
   const [selectedSubCat, setSelectedSubCat] = useState(null);
   // Use state to manage the subcategory search input value
   const [subCatSearch, setSubCatSearch] = useState("");
 

   // Function to handle clicks on a subcategory
   const handleSubcategoryClick = (subcategory) => {
    // Call the parent component's callback function with the selected subcategory
    onSelectSubcategory(subcategory);
    // Set the selected subcategory state with the subcategory name
    setSelectedSubCat(subcategory.Name);
    // Toggle the subcategory selector dropdown
    handleSubCatSelect();
  };

  // Function to toggle the subcategory selector dropdown open/closed state
  const handleSubCatSelect = () => {
    setSubCatSelect((prev) => !prev);
  };




  return (
    <li style={{ flexGrow: '1' }}>
      <div className={TaskStyle.selectorDiv}>
        <p onClick={() => handleSubCatSelect()} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", minWidth: "200px" }} >
          {(index < (selectedSubcategories.length - 1) && selectedSubCat) ||
            "Please Select A Sub Category"}
          <span>
            {subCatSelect ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </span>
        </p>

        {subCatSelect && (
          <ThreeDotDropDown toggle={handleSubCatSelect} style={{
            bottom: "100%",
            top: "auto",
            width: "100%",
            borderRadius: "4px",
            maxHeight: "150px",
            overflow: "auto"
          }}>
            <li style={{ backgroundColor: "white" }}>
              <input type="text" placeholder="Serarch Sub-Categories" value={subCatSearch} onChange={(e) => setSubCatSearch(e.target.value)}
                style={{ width: "100%", padding: "5px", border: "none", outline: "none", border: "1px solid #ccc", color: "#000" }}
              />
            </li>
            {category.Branch.filter((subcategory) => subcategory.Name.toLowerCase().includes(subCatSearch.trim().toLowerCase())).map((subcategory) => (
              <li
                key={subcategory.categoryId}
                onClick={() => handleSubcategoryClick(subcategory)}
              >
                {subcategory.Name}
              </li>
            ))
            }
          </ThreeDotDropDown>
        )}
      </div>
    </li>
  );
};

export default SubcategorySelector;
