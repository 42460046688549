import React from 'react'
import documentChatStyle from './DocumentChat.module.css';


const DocumentChatInput = ({documentId,handleDocumentChatSend,chatValue,setChatValue}) => {

  return (
    <>
    <div className={documentChatStyle.chatInputMain}>
    <form className={documentChatStyle.chatInputForm} 
    onSubmit={(e)=>handleDocumentChatSend(e,documentId)}
    >
     <input type="text" placeholder='Add Comments' value={chatValue} onChange={(e)=>setChatValue(e.target.value)} />
        <button type='submit'>Comment</button>
    </form>
    </div>
    </>
  )
}

export default DocumentChatInput
