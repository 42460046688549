import React, { useState, useRef } from 'react';
import ProfileStyle from './ProfileStyle.module.css';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import CustomModal from 'components/CustomModal/CustomModal';
import { MuiOtpInput } from 'mui-one-time-password-input';
import PhoneInput from 'react-phone-input-2';
import Tooltip from '@mui/material/Tooltip';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useAuth from 'hooks/useAuth';
import useBackdrop from 'hooks/useBackdrop';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { PrivateComponent } from 'api/axios';
import ErrorToast from 'components/ErrorToast/ErrorToast';
import { Button } from '@mui/material';
import { setAllData } from 'state/AuthState';
import Alert from '@mui/material/Alert';
import useToast from 'hooks/useToast';
import { passwordEncryptor } from 'utility/passwordEncryptor.js'
import { HelpOutlineOutlined } from '@mui/icons-material';

const Profile = () => {
    // Initialize private axios instance for API calls
    const privateAxios = PrivateComponent(); // Hooks to manage backdrop state
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Authentication-related hooks
    const { auth, setUserData, setMobNum, setProfilePic } = useAuth(); // Toast notification hooks
    const { Toast, showToast } = useToast(); // Toast notification hooks

    // Modal state for various modals in the component
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Functions to open and close modal
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // Refs for password input fields
    const inputRefNewPassword = useRef(null);
    const inputRefConfirmPassword = useRef(null);

    // State variables for OTP verification, mobile number, and other UI states
    const [verifyOtp, setVerifyOtp] = useState('')
    const [mobileNumber, setmobileNumber] = useState('')
    const dispatch = useDispatch();
    const [changeTabOpen, setChangeTabOpen] = useState(false);
    const [OTPNewNum, setOTPNewNum] = useState(false);
    const [OTPOldMail, setOTPOldMail] = useState(false);
    const [updateMobileOpen, setupdateMobileOpen] = useState(false);
    const [changenumToMail, setchangenumToMail] = useState(false);
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [mobOTPError, setMobOTPError] = useState("")
    const [phoneVerifyId, setPhoneVerifyId] = useState("");
    const [oldPhoneVerified, setOldPhoneVerified] = useState(false);
    const [phoneOtp, setPhoneOtp] = useState("");
    const [updatedMob, setUpdatedMob] = useState('');
    const [designation, setDesignation] = useState('');
    const [successUpdatedDesignation, setSuccessUpdateDesignation] = useState(false);
    const [successAddDesignation, setSuccessAddDesignation] = useState(false);
    const [showAddDesignationAlert, setShowAddDesignationAlert] = useState(false);
    const [showUpdateDesignationAlert, setShowUpdateDesignationAlert] = useState(false);

    const [OTPOldNum, setOTPOldNum] = useState(false);

    // Set designation from auth data when component mounts
    useEffect(() => {
        if (auth.allData.designation !== null) {
            setDesignation(auth.allData?.designation?.name)
        }
    }, [])
    const [hasVerifyApi, setHasVerifyApi] = useState(false);

    // Toggle change tab state
    const handleChangeTab = () => {
        setChangeTabOpen((prev) => !prev);
    };

    // Handle OTP request for the old phone number
    const handleOTPOldNumTab = () => {
        const phoneNumber = auth.mobNum.charAt(0) !== '+' ? `+${auth.mobNum}` : auth.mobNum;
        privateAxios.post(`/procurement/sendOtpOnRegisteredPhoneToChange/${auth.email}`, {
            phone: phoneNumber
        })
            .then((res) => {
                if (res.status === 200) {
                    startResendTimer();
                    setChangeTabOpen(false);
                    setOTPOldNum((prev) => !prev);
                    setPhoneVerifyId(res.data.data.kamId);
                    setPhoneOtp("");
                }
            })
            .catch((err) => {
            })

    };

    // Submit OTP for phone number verification
    const submitOTP = (e) => {
        e.preventDefault();
        setMobOTPError('')

        if (phoneVerifyId !== "" && phoneOtp.length > 0) {
            privateAxios.post(`/procurement/verifyOtpOnRegisteredPhoneToChange/${phoneVerifyId}/${auth.mobNum}`, { mobOtpCode: phoneOtp })
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        setOldPhoneVerified(true);
                        setOTPOldNum(false);
                        setOTPNewNum(false);
                        setOTPOldMail(false);
                        setupdateMobileOpen((prev) => !prev);
                        setmobileNumber('')
                    } else {
                        setMobOTPError("Incorrect OTP!")
                    }
                }).catch(err => {
                    setMobOTPError("Soemthing Went Wrong Please Try Again or Refresh")
                })

        }
        else {
            setMobOTPError("Please Enter the OTP!");
        }
    };

    // Change phone number to email verification
    const changePhonetoMail = () => {
        setChangeTabOpen(false);
        setchangenumToMail((prev) => !prev);
    }

    // Handle OTP request for the old email
    const handleOTPOldEmailTab = () => {
        privateAxios.post(`procurement/sendOtpOnRegisteredEmailToChangeNo`, {
            email: auth.email
        })
            .then((res) => {
                if (res.status === 200) {
                    setPhoneVerifyId(res.data.data.kamId);
                    setchangenumToMail(false);
                    setOTPOldMail((prev) => !prev);
                    setVerifyOtp('')
                    setMobOTPError('')
                }
            }).catch(err => {
            })
    };

    // Toggle OTP email modal
    const handleOTPOldEmailTabc = () => {
        setOTPOldMail((prev) => !prev);
    }

    // Submit OTP for email verification
    const submitMailOTP = (e) => {
        e.preventDefault();
        if (phoneVerifyId !== "" && verifyOtp.length > 0) {
            privateAxios.post(`/procurement/verifyOtpOnRegisteredEmailToChange/${auth.email}/${phoneVerifyId}`, { emailOtpCode: verifyOtp })
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        setOldPhoneVerified(true);
                        setOTPOldNum(false);
                        setOTPNewNum(false);
                        setOTPOldMail(false);
                        setupdateMobileOpen((prev) => !prev);
                        setmobileNumber('')
                    } else {
                        setMobOTPError("Incorrect OTP!")
                    }
                }).catch(err => {
                    setMobOTPError("Soemthing Went Wrong!")
                })
        }
        else {
            setMobOTPError("Please Enter the OTP!");
        }
    };


    // State for mobile number validation error
    const [mobilenumErr, setMobilenumErr] = useState("");

    // Handle change of verification OTP
    const handleChange = (newValue) => {
        setVerifyOtp(newValue)
    };

    // Handle OTP request for new mobile number
    const handleNewNumOTP = (e) => {
        e.preventDefault();
        setMobilenumErr('')
        if (mobileNumber.length < 3) {
            setMobilenumErr("Enter Mobile Number!");
        } else if (mobileNumber.length < 8) {
            setMobilenumErr("Enter Correct Mobile Number!")
        } else if ("+" + mobileNumber == auth.mobNum) {
            setMobilenumErr("You have entered the same Mobile Number!")
        } else {
            privateAxios.post(`/procurement/UpdatePhoneAndSendOtp/${phoneVerifyId}/${auth.email}`,
                { phone: mobileNumber }
            )
                .then((res) => {
                    if (res.status === 200) {
                        setUpdatedMob("+" + res.data.data.phone)
                        setupdateMobileOpen(false);
                        setOTPNewNum((prev) => !prev);
                        setVerifyOtp("")
                    }
                }).catch(err => {
                    setMobOTPError("Soemthing Went Wrong Please Try Again or Refresh")
                })
        }

    }

    // Handle number change and verification
    const handleNumberChange = (e) => {
        setMobOTPError('');
        e.preventDefault();
        if (phoneVerifyId !== "" && verifyOtp.length > 0) {
            privateAxios.post(`/procurement/verifyOtpOnUpdatedPhone/${phoneVerifyId}/+${mobileNumber}`, { mobOtpCode: verifyOtp })
                .then((res) => {
                    if (res.status === 200) {
                        startResendTimer();
                        alert("Updated Successfully!");
                        dispatch(setMobNum({ mobNum: updatedMob }));
                        setOTPNewNum(false);
                    }
                }).catch(err => {
                    setMobOTPError("Incorrect OTP")
                })
        }
        else {
            setMobOTPError("Please Enter the OTP!");
        }
    }


    // State for add mobile modal
    const [addMobileOpen, setAddMobileOpen] = useState(false);
    const [OTPAddNum, setOTPAddNum] = useState(false);
    const [numberAddErr, setNumberAddErr] = useState("")
    const [addedNum, setAddedNum] = useState("")

    // Handle add button click
    const handleAddButtonClick = () => {
        setAddMobileOpen((prev) => !prev);
        setVerifyOtp('')
        setmobileNumber("")
        setNumberAddErr('')
        setOTPAddNum(false);
    }

    // Handle add number OTP modal toggle
    const handleAddNumOTPS = () => {
        setVerifyOtp('')
        setmobileNumber("")
        setNumberAddErr('')
        setOTPAddNum(false);
    }

    // Handle add number OTP request
    const handleAddNumOTP = (e) => {
        e.preventDefault();

        if (mobileNumber.length < 3) {
            setNumberAddErr("Enter Mobile Number");
        } else if (mobileNumber.length < 8) {
            setNumberAddErr("Enter Correct Mobile Number")
        } else {
            privateAxios.post(`/procurement/addPhoneForEmployee/${auth.userData.id}`,
                { phone: mobileNumber })
                .then((res) => {
                    if (res.status === 200) {
                        setAddMobileOpen(false);
                        setOTPAddNum((prev) => !prev);
                        setVerifyOtp('');
                        setMobOTPError('');
                        setAddedNum(mobileNumber)
                    }
                }).catch(err => {

                })
        }
    }

    // Handle add number verification
    const handleNumberAdd = (e) => {
        setMobOTPError('');
        e.preventDefault();

        if (verifyOtp.length > 0) {
            privateAxios.post(`/procurement/verifySubKamAndUserMobileOtp/${auth.userData.id}/+${mobileNumber}`, { mobOtpCode: verifyOtp })
                .then((res) => {
                    if (res.status === 200) {
                        setOTPAddNum(false)
                        dispatch(setMobNum({ mobNum: addedNum }));
                        alert("submitted Number")
                    }
                }).catch(err => {
                    setMobOTPError("Incorrect OTP!")
                })
        }
        else {
            setMobOTPError("Please Enter the OTP!");
        }
    }



    // Upload profile picture : 
    const handleImageUpload = () => {

        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async (e) => {
            const file = e.target.files[0];

            if (file) {
                const formData = new FormData();
                formData.append('file', file);

                try {
                    openBackdrop();
                    await privateAxios.post(`/adminuser/uploadProfilePhoto`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(res => {
                        closeBackdrop();

                        const updatedUserData = { ...auth.userData, photo: res.data };
                        // console.log(res.data)
                        dispatch(setUserData({
                            userData: updatedUserData
                        }));
                        dispatch(setProfilePic({ profilePic: res.data }));
                        // Handle success
                        alert('Profile photo updated successfully.');
                    })

                } catch (error) {
                    closeBackdrop();

                    // Handle error
                    console.error('Error updating profile photo:', error);
                    alert('An error occurred while updating profile photo.');
                }
            }
        };
        input.click();
    }

    //Function to Allow only numbers 0-9, backspace, and delete key  
    const handleKeyPress = (e) => {
        const keyCode = e.keyCode || e.which;
        const keyValue = String.fromCharCode(keyCode);

        const validInput = /^[0-9\b]+$/.test(keyValue) || (
            (keyCode >= 96 && keyCode <= 105) && // Numeric keypad
            (keyCode !== 110 && keyCode !== 190) // Exclude decimal point
        );

        if (!validInput) {
            e.preventDefault();
        }
    };

    //Get Initial Name: 
    const getInitials = (name) => {
        const nameParts = name.split(' ');
        const initials = nameParts.map((part) => part.charAt(0).toUpperCase()).join('');
        return initials;
    }


    // Update password functionality:
    const [updatePassword, setupdatePassword] = useState(false);
    const changePassword = () => {
        setupdatePassword((prev) => !prev);
    }
    const HandleResetPassword = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        openBackdrop();
        // Check for form errors
        if (formErrors.newPassword || formErrors.confirmPassword) {
            // Don't send the API request if there are errors
            closeBackdrop();
            // console.log("Validation errors exist. Cannot reset password.");
            return;
        }

        // Check if new password and confirm password match
        if (passwordChange.newPassword !== passwordChange.confirmPassword) {
            closeBackdrop();
            setErrorMessage("Passwords do not match");
            return;
        }
        if (passwordChange.newPassword === passwordChange.confirmPassword) {
            closeBackdrop();
            if (passwordChange.newPassword.trim() === '') {
                // Display an error message or handle empty new password
                // console.log("New password cannot be empty");
                setErrorMessage("New password cannot be empty");
                return;
            }

            let newpass = await passwordEncryptor(passwordChange.newPassword, 'AesPassword12345');
            let confirmPassword = await passwordEncryptor(passwordChange.confirmPassword, 'AesPassword12345');


            await privateAxios.post(`/procurement/resetPasswordInProfile/${auth.email}`, {
                newPassword: newpass,
                confirmPassword: confirmPassword
            })
                .then((res) => {
                    // Handle successful password change
                    closeBackdrop();
                    alert("Password changed successfully.");
                    // Reset the input fields and state variables
                    setpasswordChange({
                        newPassword: "",
                        confirmPassword: ""
                    });
                    setupdatePassword((prev) => !prev);

                })
                .catch((err) => {
                    closeBackdrop();
                    // Handle error
                    console.error("Error changing password:", err);
                    if (err.response.status == 400) {
                        setErrorMessage("Old password cannot be a new password!");
                    } else {
                        setErrorMessage("Something went wrong, please try again later!");
                    }


                });


        } else {
            // Passwords do not match
            closeBackdrop();
        }
    }
    const [passwordChange, setpasswordChange] = useState({
        newPassword: "",
        confirmPassword: "",

    })

    const [formErrors, setFormErrors] = useState({
        newPassword: "",
        confirmPassword: "",
    });

    const handleInputField = (e) => {

        setpasswordChange(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [e.target.name]: '',
        }));
    };

    const handleValidation = (e) => {
        const { name, value } = e.target;
        let errorMessage = '';


        if (name === 'newPassword') {
            if (value.length == 0) {
                errorMessage = 'Please Enter password'
            }
            else if (value.length < 8) {
                errorMessage = 'Password must be at least 8 characters long';
            } else if (!/[!@#$%^&*()_~]/.test(value)) {
                errorMessage += 'Password must include at least one special character. ';
            } else if (!/[A-Z]/.test(value)) {
                errorMessage += 'Password must include at least one capital letter. ';
            } else if (!/\d/.test(value)) {
                errorMessage += 'Password must include at least one number. ';
            } else if (!/[a-z]/.test(value)) {
                errorMessage += 'Password must include at least one small letter. ';
            }
        }
        if (name === 'confirmPassword') {
            if (value.length == 0 && passwordChange.newPassword > 8) {
                errorMessage = 'Please Enter password'
            }
            else if (value !== passwordChange.newPassword) {
                errorMessage = 'Passwords does not match';
            }
        }

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage,
        }));
    };

        // State for resend OTP button
    const [resendTimer, setResendTimer] = useState(60);
    let timerInterval;

    const startResendTimer = () => {
        if (hasVerifyApi) {
            setResendTimer(60);
            clearInterval(timerInterval); // Clear the previous timer if it exists
            timerInterval = setInterval(() => {
                setResendTimer(prevTime => prevTime - 1);
            }, 1000);
        }
    };


    useEffect(() => {
        if (resendTimer > 0 && OTPOldNum === true) {

            timerInterval = setInterval(() => {
                setResendTimer(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(timerInterval);
        }
    }, [resendTimer, OTPOldNum]);


    useEffect(() => {

        if (resendTimer > 0 && OTPNewNum === true) {

            timerInterval = setInterval(() => {
                setResendTimer(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(timerInterval);
        }
    }, [resendTimer, OTPNewNum]);

    // const [OTPOldNum, setOTPOldNum] = useState(false);

    const handleResendOTP = () => {
        setResendTimer(60); // Reset the timer to 60 seconds

        privateAxios.post(`/procurement/resendMobileOtpWhileRegister/${auth.userData.id}`)
            .then((res) => {
                if (res.status === 200) {
                    startResendTimer();
                } else {
                }
            })
            .catch((error) => {
                console.error(error);
            })
    };


    const addOrEditDesignation = async () => {
        openBackdrop();
        try {
            let url = "";


            if (auth.allData?.designation?.name?.trim().length > 0) {
                url = `/procurement/editDesignation/${auth.userData.id}/${auth.allData?.designation?.id}`
            }
            else {

                url = `/procurement/addDesignation/${auth.userData.id}`
            }
            if (designation.trim().length > 0) {



                const res = privateAxios.post(`${url}`, { name: designation })
                    .then((response) => {
                        if (response.status === 200) {

                            let updateAllData = {
                                ...auth?.allData,
                                designation: {
                                    ...auth?.allData?.designation,
                                    name: designation,
                                }
                            }
                            dispatch(setAllData({
                                allData: updateAllData,
                            }))
                            showToast("success", "Data Updated Successfully")

                        }
                    })
                    .catch((error) => {
                        console.error('Error adding designation:', error);
                    });
            }
            else {
                showToast("caution", "Please Enter  Designation")
            }

        } catch (error) {
            showToast("error", "Error Updating Data")
        }
        closeBackdrop();
    }


    // Function to handle input changes
    const handleDesignationChange = (e) => {
        setDesignation(e.target.value);
    };

    // Function to fetch all designations
    const fetchDesignations = () => {
        privateAxios.get(`/procurement/getAllDesignation/${auth.userData.id}`)
            .then((response) => {
                // Handle the response and set the designations in the state
                const designationName = response.data.data.designation.name;
                setDesignation(designationName);
            })
            .catch((error) => {
                // Handle errors, e.g., show an error message
                console.error('Error fetching designations:', error);
            });
    };

    // Fetch designations when the component mounts
    useEffect(() => {
        fetchDesignations();
    }, []);



    useEffect(() => {
        let timeoutId;

        if (successAddDesignation) {
            timeoutId = setTimeout(() => {
                setSuccessAddDesignation(false);
            }, 3000); // 3000 milliseconds (3 seconds)
        }

        return () => {
            clearTimeout(timeoutId); // Clear the timeout if the component unmounts or the alert is hidden before 3 seconds
        };
    }, [successAddDesignation]);

    useEffect(() => {
        let timeoutId;

        if (successUpdatedDesignation) {
            timeoutId = setTimeout(() => {
                setSuccessUpdateDesignation(false);
            }, 3000); // 3000 milliseconds (3 seconds)
        }

        return () => {
            clearTimeout(timeoutId); // Clear the timeout if the component unmounts or the alert is hidden before 3 seconds
        };
    }, [successUpdatedDesignation]);


    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', paddingRight: '20%' }}>
                    {errorMessage && <ErrorToast message={errorMessage} />}
                </div>
                <div className={ProfileStyle.profileMainContainer}>
                    <div className={ProfileStyle.profileFirstRow}>
                        <div>
                            <h3>My Profile</h3>
                        </div>
                        <div>
                            {auth.roles === 'USER' && (

                                <Tooltip title='Help'>
                                    <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081394-user-profile-section">
                                        <HelpOutlineOutlined />
                                    </a>
                                </Tooltip>
                            )}
                            {auth.roles === 'ADMIN' && (

                                <Tooltip title='Help'>
                                    <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081395-admin-profile-section">
                                        <HelpOutlineOutlined />
                                    </a>
                                </Tooltip>
                            )}


                            {auth.roles === 'KAM' && (

                                <Tooltip title='Help'>
                                    <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081396-kam-profile">
                                        <HelpOutlineOutlined />
                                    </a>
                                </Tooltip>
                            )}

                        </div>
                    </div>


                    <div className={ProfileStyle.profileMainRow}>
                        <form action='' className={ProfileStyle.ProfileInfoTable}>
                            <div className={ProfileStyle.formInfoColumn}>

                                <div className={ProfileStyle.formGroup}>
                                    <label>Name</label>
                                    <input type='text' placeholder='Name' value={auth.name} disabled />
                                </div>
                                <div className={ProfileStyle.formGroup}>
                                    <label>Email</label>
                                    <input type='email' placeholder='Email' value={auth.email} disabled />
                                </div>
                                <div className={ProfileStyle.formGroup}>
                                    <label>Role</label>
                                    <input type='text' placeholder='Role' value={auth.roles} disabled />
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: '10px'
                                    }}>
                                    {auth.roles !== "USER" &&
                                        <Button
                                            sx={{ textTransform: 'capitalize' }}
                                            variant='contained'
                                            type="button" onClick={addOrEditDesignation}
                                        // disabled={auth.roles === "USER" ? true : false}
                                        // disabled={!designation.trim()} // Disable the button if designation is empty

                                        >Save Changes</Button>
                                    }
                                </div>
                            </div>

                            <div className={ProfileStyle.formInfoColumn}>
                                <div className={ProfileStyle.formGroup}>
                                    <label>Mobile Number</label>
                                    <div className={ProfileStyle.mobileInputField}>
                                        {auth.mobNum ?
                                            (<div><input type='tel' placeholder='Mobile Number' value={auth.mobNum} disabled />
                                                <button type='button' onClick={handleChangeTab}>Change</button> </div>
                                            ) : (<div>    <input type='tel' placeholder='Mobile Number' value='Add Mobile Number' disabled />
                                                <button type='button' onClick={handleAddButtonClick}>Add</button> </div>)
                                        }
                                    </div>
                                </div>

                                <div className={ProfileStyle.formGroup}>
                                    <label>Designation</label>
                                    <input
                                        placeholder='Designation'
                                        value={designation}
                                        onChange={handleDesignationChange}
                                        // disabled
                                        disabled={auth.roles === "USER" ? true : false}
                                    />
                                    {/* <button type="button" onClick={() => addDesignation(auth.kamId)}>Add Designation</button> */}



                                    {/* <button type="button" onClick={() => editDesignation(auth.empId, auth.allData.designation.id)}>Edit Designation</button> */}
                                </div>

                                {/* <div className={ProfileStyle.formGroup}>
                                <label>Categories</label>
                                <input type='text' placeholder='Categories' disabled />
                            </div> */}
                            </div>
                        </form>
                        {showAddDesignationAlert && (
                            <div style={{ display: 'flex', minWidth: "max-content", left: "50%", justifyContent: 'center', alignItems: 'center', position: "fixed", bottom: "100px" }}>
                                <Alert severity="success" sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>Designation Added</Alert>
                            </div>
                        )}


                        {showUpdateDesignationAlert && (
                            <div style={{ display: 'flex', minWidth: "max-content", left: "50%", justifyContent: 'center', alignItems: 'center', position: "fixed", bottom: "100px" }}>
                                <Alert severity="success" sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>Designation Updated</Alert>
                            </div>
                        )}
                        <div className={ProfileStyle.ProfilePictureBox}>
                            <figure>
                                {/* <img src='https://res.cloudinary.com/dtffniutw/image/upload/v1691730477/steel_1_hjofaw.png' /> */}
                                {auth.profilePic?.newImage ? (
                                    <img
                                        src={`${process.env.REACT_APP_BASEURL}/adminuser/file/${auth.profilePic.newImage}`}
                                    />
                                ) : (
                                    <div className={ProfileStyle.initialsContainer}>
                                        <p className={ProfileStyle.initials}>{getInitials(auth.name)}</p>
                                    </div>
                                )}


                                <figcaption><button onClick={handleImageUpload}>Edit Photo</button></figcaption>

                            </figure>
                            <button type='button' onClick={changePassword}>Change Password</button>
                        </div>

                    </div>

                </div>

                <>
                    {/* Old Mobile Number Modal and Verification */}
                    <>
                        <CustomModal
                            isOpen={changeTabOpen}
                            onClose={handleChangeTab}
                            title="Verification required"
                            content={
                                <>
                                    <div className={ProfileStyle.changeModalInfo}>
                                        <p>For better security, OTP is sent to a previously used number on your account.</p>
                                    </div>
                                    <div className={ProfileStyle.changeModalInput}>
                                        <input style={{ color: "black" }} placeholder='+9188888888888' value={auth.mobNum} disabled />
                                        <p>In case you don't have access to this number please <button type='button' onClick={changePhonetoMail}>click here</button></p>
                                    </div>
                                    <div className={ProfileStyle.changeModalButton}>
                                        <button type='button' onClick={handleOTPOldNumTab}>Request OTP</button>
                                    </div>
                                </>
                            }
                        />



                        <CustomModal
                            isOpen={OTPOldNum}
                            onClose={handleOTPOldNumTab}

                            // onClose={closeModal}
                            title="Verify With OTP"
                            content={
                                <>
                                    <div className={ProfileStyle.changeModalInfo}>
                                        <p>Sent to SMS to {auth.mobNum}</p>
                                    </div>
                                    <div>
                                        <MuiOtpInput className={ProfileStyle.verifyOTPInput} style={{ width: "100%", gap: "6px" }} length={5} value={phoneOtp} onChange={(e) => setPhoneOtp(e)} onKeyDown={handleKeyPress} />
                                        {mobOTPError ? <span style={{ marginTop: "15px", color: 'red', fontSize: '12px' }}>{mobOTPError}</span> : ''}
                                    </div>
                                    <div className={ProfileStyle.changeModalButton}>
                                        <button type='button' onClick={submitOTP}>Verify</button>
                                    </div>

                                    {/* <Button
                                    variant="text"
                                    type="button"
                                    onClick={handleOTPOldNumTab}
                                    disabled={resendDisabled}
                                    sx={{textTransform:'capitalize'}}
                                >
                                     {resendDisabled ? `Resend OTP in ${formatCountdown(countdown)}` : "Resend OTP"}
                                </Button> */}


                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <button
                                            style={{
                                                border: 'none',
                                                cursor: 'pointer',
                                                backgroundColor: '#ffff',
                                                color: resendTimer > 0 ? '#00000' : '#2871df', // Set text color conditionally
                                            }}
                                            variant="text"
                                            onClick={handleResendOTP}
                                            disabled={resendTimer > 0}
                                        >
                                            {resendTimer > 0
                                                ? `Resend in 00:${resendTimer < 10 ? '0' + resendTimer : resendTimer}`
                                                : 'Resend OTP'}
                                        </button>
                                    </div>

                                </>

                            }
                        />
                    </>


                    {/* Mail modal and verification */}
                    <>
                        <CustomModal
                            isOpen={changenumToMail}
                            onClose={changePhonetoMail}
                            title="Verification required"
                            content={
                                <>
                                    <div className={ProfileStyle.changeModalInfo}>
                                        <p>For better security, OTP is sent to your registered email ID</p>
                                    </div>
                                    <div className={ProfileStyle.changeModalInput}>
                                        <input style={{ color: "black" }} placeholder='mailId@gmail.com' value={auth.email} disabled />
                                        {/* <p>In case you don't have access to this email ID please <button type='button'>click here</button></p> */}
                                    </div>
                                    <div className={ProfileStyle.changeModalButton}>
                                        <button type='button' onClick={handleOTPOldEmailTab}>Request OTP</button>
                                    </div>
                                </>
                            }
                        />
                        <CustomModal
                            isOpen={OTPOldMail}
                            onClose={handleOTPOldEmailTabc}
                            title="Verify With OTP"
                            content={
                                <>
                                    <div className={ProfileStyle.changeModalInfo}>
                                        <p>Sent to {auth.email}</p>
                                    </div>
                                    <div>
                                        <MuiOtpInput className={ProfileStyle.verifyOTPInput} style={{ width: "100%", gap: "6px" }} length={5} value={verifyOtp} onChange={handleChange} onKeyDown={handleKeyPress} />
                                        {mobOTPError ? <span style={{ marginTop: "15px", color: 'red', fontSize: '12px' }}>{mobOTPError}</span> : ''}
                                    </div>
                                    <div className={ProfileStyle.changeModalButton}>
                                        <button type='button' onClick={submitMailOTP}>Verify</button>
                                    </div>
                                </>
                            }
                        />
                    </>


                    {/* Update new number and verify it */}
                    <>
                        <CustomModal
                            isOpen={updateMobileOpen}
                            onClose={submitOTP}
                            title="Update mobile number"
                            content={
                                <>
                                    <div style={{ marginTop: '20px', marginBottom: '10px' }} className={ProfileStyle.changeModalInput} >

                                        <PhoneInput
                                            style={{ width: '100%', alignItems: 'left' }}
                                            country={'in'}
                                            value={mobileNumber}
                                            onChange={(e) => { setmobileNumber(e) }}
                                            name='MobileNum'
                                            placeholder='Mobile Number'

                                            inputStyle={{ width: "100%", placeholder: 'Mobile Number', }} // Add padding to accommodate the button
                                        />
                                        {mobilenumErr ? <span style={{ marginTop: "15px", color: 'red', fontSize: '12px' }}>{mobilenumErr}</span> : ''}
                                    </div>
                                    <div style={{ marginTop: '10px' }} className={ProfileStyle.changeModalButton}>
                                        <button style={{ marginTop: '10px' }} type='button' onClick={handleNewNumOTP}>Submit</button>
                                    </div>
                                </>
                            }
                        />
                        <CustomModal
                            isOpen={OTPNewNum}
                            onClose={handleNewNumOTP}
                            title="Verify With OTP"
                            content={
                                <>
                                    <div className={ProfileStyle.changeModalInfo}>
                                        <p>Sent to SMS to {mobileNumber}</p>
                                    </div>
                                    <div>
                                        <MuiOtpInput className={ProfileStyle.verifyOTPInput} style={{ width: "100%", gap: "6px" }} length={5} value={verifyOtp} onChange={handleChange} onKeyDown={handleKeyPress} />
                                    </div>
                                    {mobOTPError ? <span style={{ marginTop: "15px", color: 'red', fontSize: '12px' }}>{mobOTPError}</span> : ''}
                                    <div className={ProfileStyle.changeModalButton}>
                                        <button type='button' onClick={handleNumberChange}>Verify</button>
                                    </div>
                                    <button
                                        style={{
                                            border: 'none',
                                            backgroundColor: '#ffff'
                                        }}
                                        variant="text"
                                        onClick={handleResendOTP}
                                        disabled={resendTimer > 0}
                                    >
                                        {resendTimer > 0
                                            ? `Resend in 00:${resendTimer < 10 ? '0' + resendTimer : resendTimer}`
                                            : 'Resend'}
                                    </button>
                                </>
                            }
                        />
                    </>


                    {/* Add number and verify it */}
                    <>
                        <CustomModal
                            isOpen={addMobileOpen}
                            onClose={handleAddButtonClick}
                            title="Add mobile number"
                            content={
                                <>
                                    <div className={ProfileStyle.changeModalInput} >

                                        <PhoneInput
                                            style={{ width: '100%', alignItems: 'left' }}
                                            country={'in'}
                                            value={mobileNumber}
                                            onChange={(e) => { setmobileNumber(e) }}


                                            inputStyle={{ width: "100%" }} // Add padding to accommodate the button
                                        />
                                        {numberAddErr ? <span style={{ marginTop: "15px", color: 'red', fontSize: '12px' }}>{numberAddErr}</span> : ''}

                                    </div>
                                    <div className={ProfileStyle.changeModalButton}>
                                        <button type='button' onClick={handleAddNumOTP}>Submit</button>
                                    </div>
                                </>
                            }
                        />
                        <CustomModal
                            isOpen={OTPAddNum}
                            onClose={handleAddNumOTPS}
                            title="Verify With OTP"
                            content={
                                <>
                                    <div className={ProfileStyle.changeModalInfo}>
                                        <p>Sent to SMS to {mobileNumber}</p>
                                    </div>
                                    <div>
                                        <MuiOtpInput className={ProfileStyle.verifyOTPInput} style={{ width: "100%", gap: "6px" }} length={5} value={verifyOtp} onChange={handleChange} onKeyDown={handleKeyPress} />
                                    </div>
                                    {mobOTPError ? <span style={{ marginTop: "15px", color: 'red', fontSize: '12px' }}>{mobOTPError}</span> : ''}
                                    <div className={ProfileStyle.changeModalButton}>
                                        <button type='button' onClick={handleNumberAdd}>Verify</button>
                                    </div>
                                </>
                            }
                        />
                    </>


                    {/* Update password Modal: */}
                    <>
                        <CustomModal
                            isOpen={updatePassword}
                            onClose={(e) => {
                                e.preventDefault(); // Prevent default behavior
                                changePassword(); // Close the modal
                                setpasswordChange({
                                    newPassword: "",
                                    confirmPassword: ""
                                });
                                setFormErrors({
                                    newPassword: "",
                                    confirmPassword: ""
                                });
                            }}
                            title="New Password"
                            content={
                                <>

                                    <div className={`${ProfileStyle.changeModalInput} ${passwordChange.newPassword ? ProfileStyle.emptyInput : ''}`}>
                                        <input
                                            name='newPassword'
                                            required
                                            // id="outlined-basic"
                                            variant="outlined"
                                            value={passwordChange.newPassword}
                                            onChange={handleInputField}
                                            onBlur={handleValidation}
                                            type={showPasswordNew ? 'text' : 'password'}
                                            error={isPasswordValid}
                                            ref={inputRefNewPassword}
                                            autocomplete="off"
                                        />


                                        <span className={ProfileStyle.placeholder} onClick={() => inputRefNewPassword.current.focus()}>
                                            {passwordChange.newPassword === '' ? 'New Password' : ''}
                                        </span>
                                        <div className={ProfileStyle.VisibleS}>
                                            {showPasswordNew ? (
                                                <Tooltip title='Hide'><VisibilityOff onClick={() => setShowPasswordNew(false)} /></Tooltip>
                                            ) : (
                                                <Tooltip title='Show'><Visibility onClick={() => setShowPasswordNew(true)} /></Tooltip>
                                            )}
                                        </div>
                                        <span className={ProfileStyle.validationErrors}>{formErrors.newPassword}</span>
                                    </div>


                                    <div className={`${ProfileStyle.changeModalInput} ${passwordChange.confirmPassword ? ProfileStyle.emptyInput : ''}`}>
                                        <input
                                            name='confirmPassword'
                                            required
                                            // id="outlined-basic"
                                            variant="outlined"
                                            value={passwordChange.confirmPassword}
                                            onChange={handleInputField}
                                            onBlur={handleValidation}
                                            type={showPasswordConfirm ? 'text' : 'password'}
                                            error={isPasswordValid}
                                            ref={inputRefConfirmPassword}
                                            autocomplete="off"
                                        />


                                        <span className={ProfileStyle.placeholder} onClick={() => inputRefConfirmPassword.current.focus()}>
                                            {passwordChange.confirmPassword === '' ? 'Confirm Password' : ''}
                                        </span>
                                        <div className={ProfileStyle.VisibleS}>
                                            {showPasswordConfirm ? (
                                                <Tooltip title='Hide'><VisibilityOff onClick={() => setShowPasswordConfirm(false)} /></Tooltip>
                                            ) : (
                                                <Tooltip title='Show'><Visibility onClick={() => setShowPasswordConfirm(true)} /></Tooltip>
                                            )}
                                        </div>
                                        <span className={ProfileStyle.validationErrors}>{formErrors.confirmPassword}</span>
                                    </div>


                                    <div className={ProfileStyle.changeModalButton}>
                                        <button type='button' onClick={HandleResetPassword}>Reset Password</button>
                                    </div>

                                </>
                            }
                        />
                    </>
                </>

            </div >
        </>
    )
}

export default Profile;



