import React, { useEffect, useState } from 'react'
import styles from './CreateCategoryModal.module.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Modal } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';


const formValidationSchema = yup.object({

    // Validation rule for name: must be a string and is required with a custom error message
    name: yup.string().required("Name is Required"),

    // Validation rule for description: must be a string and is required with a custom error message
    description: yup.string().required('Description is required')
})


const CreateCategoryModal = ({ showToast, onSubmit, openBackdrop, closeBackdrop, open, onClose, data }) => {



    // useState hook to manage the initial form values
    const [initialValues, setInitialValues] = useState({
        name: '',
        description: ''
    })
console.log("data",data)
    // useEffect hook to set the initial form values when the modal opens and data is provided
    useEffect(() => {
        if (data && open) {

            // Update the initialValues state with the provided data
            setInitialValues({ name: data.name, description: data.description });
        }
    }, [data, open]); // Dependencies for useEffect: runs when data or open changes
  

    // Function to handle form submission
    const formSubmit = async (values, { setSubmitting }) => {
        // Call the onSubmit prop with form values
        await onSubmit(values);
        // Set submitting state to false after submission
        setSubmitting(false);
    }



    return (
        <>

            <Modal
                open={open}
                onClose={onClose}
            >
                <div className={styles.modalContainer}>
                    <div className={styles.contentWrapper}>

                        <div className={styles.modalHeader}>
                            <h3>
                                 Category
                            </h3>
                            <span onClick={onClose}>
                                <ClearIcon />
                            </span>
                        </div>


                        <Formik
                            initialValues={initialValues}
                            validationSchema={formValidationSchema}
                            onSubmit={formSubmit}
                            enableReinitialize={true}
                        >

                            {
                                ({ isSubmitting, values, handleBlur, handleChange, touched }) => (
                                    <Form className={styles.formContainer}>
                                        <div className={styles.formRow}>
                                            <label htmlFor="name">
                                                Category Name
                                            </label>
                                            <Field name="name" type="text" placeholder={`Enter your Category name`} />
                                            <ErrorMessage name='name' component="span" />


                                        </div>
                                        <div className={styles.formRow}>
                                            <label htmlFor="name">
                                                Description
                                            </label>
                                            <textarea name="description"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder={`Enter your Category Description`}
                                                value={values.description}

                                            />
                                            <ErrorMessage name='description' component="span" />


                                        </div>

                                     
                                     {/* Conditionally render the button if data is undefined or null */}
                                {(data === undefined || data === null) && (
                                    <div className={styles.formBtn}>
                                        <button type="submit" disabled={isSubmitting}>
                                            Create
                                        </button>
                                    </div>
                                )}

                                    
                                    </Form>
                                )
                            }
                        </Formik>

                    </div>



                </div>
            </Modal>

        </>
    )
}

export default CreateCategoryModal
