import React from 'react'
import ProcaContact from './ProcaContact/ProcaContact'


const DemoPage = () => {
  return (
    <>
      <ProcaContact />
    </>
  )
}

export default DemoPage



