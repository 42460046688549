import React, { useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import revisionEditStyle from './RevisionEdit.module.css'
const SpecificationTab = ({ specificationValue, setSpecificationValue, handelSpecification, handleSaveRevison }) => {


  return (
    <div className={revisionEditStyle.specficationTabMain}>
      <div className={revisionEditStyle.richEditor}>
        <ReactQuill theme="snow" value={specificationValue} onChange={setSpecificationValue} />
      </div>
    </div>
  )
}

export default SpecificationTab
