import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import styles from './VendorTechnicalRevisionView.module.css'
import BackButton from 'components/BackButton/BackButton';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import Tooltip from '@mui/material/Tooltip';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useAuth from 'hooks/useAuth';
import { PrivateComponent } from 'api/axios';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AttributeTab from 'components/TechnicalSpecificationTabs/AttributeTab/AttributeTab';
import SpecificationTab from 'components/TechnicalSpecificationTabs/SpecificationTab/SpecificationTab';
import DocumentsTab from 'components/TechnicalSpecificationTabs/DocumentTab/DocumentTab';
import RenameModal from 'components/RenameModal/RenameModal';
import SendForApprovalModal from 'components/SendForApprovalModal/SendForApprovalModal';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatRow from 'components/ChatRows/ChatRow';
import { Modal } from '@mui/material';
import EditTaskPannel from 'pages/EditCreateTask/EditTaskPannel/EditTaskPannel';
import { createExcel } from 'utility/excelUtils';
import SendToApprovalCommityModal from 'components/SendToApprovalCommityModal/SendToApprovalCommityModal';


const VendorTechnicalRevisionView = () => {
    const { showToast, Toast } = useToast(); // Custom hook for showing toast notifications
    const { taskId, productId, revisionId } = useParams(); // Get URL parameters
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Custom hook for managing backdrop
    const { auth } = useAuth() // Custom hook for authentication context
    const privateAxios = PrivateComponent() // Custom axios instance
    const location = useLocation(); // Hook to get the current location
    const { state } = location; // State passed through navigation
    const navigate = useNavigate(); // Hook to programmatically navigate
    const searchParams = new URLSearchParams(location.search); // To parse URL query parameters
    const [isDefaultRevision, setIsDefaultRevision] = useState(false); // State for checking if it's the default revision
    const [viewType, setViewType] = useState(""); // State for the view type

    // =======================set View Type==============================

    // Set the view type based on the state passed through navigation
    useEffect(() => {
        if (state) {
            setViewType(state.from) // Set view type from state
        }
    }, [state]) // Dependency array to re-run the effect when state changes


    // =======================set View Type==============================


    //=======================button states==========================


    // State variables to manage button states
    const [workSpaceHeaderRevisionMore, setworkSpaceHeaderRevisionMore] = useState(false); // For more options in header
    const [multiSave, setMultiSave] = useState(false); // For multi save state
    const [multiAddDropDown, setMultiAddDropDown] = useState(false); // For multi add dropdown state

    // Toggle the multi save state
    const handleMultiSave = () => {
        setMultiSave(prev => !prev) // Toggle state
    }

    //=======================button states========================== 


    // =================Modal States===========================


    // State variables to manage modal visibility
    const [renameModal, setRenameModal] = useState(false); // For rename modal
    const handleRenameModal = () => {
        setRenameModal(prev => !prev) // Toggle state
    }

    const [approvalModal, setApprovalModal] = useState(false); // For approval modal
    const handleApprovalModal = () => {
        setApprovalModal(prev => !prev) // Toggle state
    }

    const [deleteModal, setDeleteModal] = useState(false); // For delete modal
    const handleDeleteModal = () => {
        setDeleteModal(prev => !prev) // Toggle state
    }

    const [duplicateNameModal, setDuplicateNameModal] = useState(false); // For duplicate name modal
    const handleDuplicateNameModal = () => {
        setDuplicateNameModal(prev => !prev) // Toggle state
    }

    const [saveAsModal, setSaveAsModal] = useState(false); // For save as modal
    const handleSaveAsModal = () => {
        setSaveAsModal(prev => !prev) // Toggle state
    }

    const [kamModal, setKamModal] = useState(false); // For KAM modal
    const handleSendToKamModal = (data) => {
        setKamModal(prev => !prev) // Toggle state
    }

    // State variables to manage reassigned data and modal visibility
    const [reassignedData, setReassignedData] = useState({}); // For reassigned data
    const [reassignModal, setReassignModal] = useState(false); // For reassign modal

    const handleReassignModal = () => {
        setReassignModal(prev => !prev) // Toggle state
    }

    // =================Modal States===========================



    // ==================Unauthorized Access========================

    // State variables to manage unauthorized access
    const [loading, setLoading] = useState(true); // For loading state
    const [showUnAuthorizedToast, setShowUnAuthorizedToast] = useState(false); // For unauthorized access toast


    // ==================Unauthorized Access========================


    // ==================Active Tab========================

    // State variable to manage active tab
    const [tabActive, setTabActive] = useState("0"); // For active tab state

    // Set the active tab based on URL query parameter
    useEffect(() => {
        if (searchParams.get("tab") !== null && searchParams.get("tab") !== undefined && searchParams.get("tab") !== "") {
            setTabActive(searchParams.get("tab"));// Set active tab from query parameter
        }
    }, [Location, searchParams])// Dependency array to re-run the effect when location or searchParams change

    const handleTabChange = (index) => {
        setTabActive(index)// Set active tab
        navigate(`?tab=${index}`, { replace: true })// Navigate to the new tab URL
    }
    // ==================Active Tab========================


    // ==================Product Details========================


    // State variables to manage product details and attributes
    const [specificationValue, setSpecificationValue] = useState(""); // For specification value
    const [productDetails, setProductDetails] = useState({}); // For product details
    const [documents, setDocuments] = useState([]); // For documents
    const [tableRows, setTableRows] = useState([]); // For table rows
    const [columns, setColumns] = useState([]); // For columns
    const [duplicateRow, setDuplicateRow] = useState([]); // For duplicate rows

    // Function to validate attributes
    const handleAttributeValidation = (type) => {
        const hasNullName = tableRows.some(rr => rr.name === null);// Check if any name is null

        if (hasNullName) {
            alert("please check Attribute Name No Name can be empty");// Alert if any name is null
            return false;
        }
        else {
            const nameOccurrences = {}; // Object to store name occurrences
            const duplicateNamesId = []; // Array to store duplicate IDs
            tableRows.forEach((item) => {
                const name = item.name;
                // Check if the name has been encountered before
                if (nameOccurrences[name]) {
                    // If it has, mark it as a duplicate
                    duplicateNamesId.push(item.id);
                } else {
                    // Otherwise, record it in the occurrences object
                    nameOccurrences[name] = true;
                }
            });
            if (duplicateNamesId.length > 0) {
                // console.log(duplicateError)
                setDuplicateRow(duplicateNamesId)
                if (type === "check") {

                    alert("Please Remove Duplicate Attributes");
                    return false;
                }
                else {
                    return duplicateNamesId
                }
            }
            else {
                setDuplicateRow([]); // Clear duplicate rows
                return true; // Return true if no duplicates
            }

        }
    }

    // Function to delete all duplicate rows
    const deleteAllDuplicateRows = () => {
        const ids = handleAttributeValidation();// Validate attributes
        if (ids.length > 0) {
            const newRows = tableRows.filter((item) => !ids.includes(item.id));// Filter out duplicate rows
            setTableRows(newRows);// Set new table rows
        }
    }

    // Function to build table rows
    const buildRows = async (data) => {
        openBackdrop();// Open backdrop
        try {
            const attributes = data.map((dd) => {
                let addFields = dd.additionalFields;// Get additional fields
                if (typeof addFields === 'string' && addFields !== "{}") {
                    try {
                        addFields = JSON.parse(addFields); // Parse additional fields
                    } catch (error) {
                        addFields = [];// Set to empty array on error
                    }
                }
                else {
                    addFields = [];// Set to empty array if not a string
                }
                return {
                    ...dd,
                    additionalFields: addFields,// Set additional fields
                };
            });


            const maxAdditionalColumn = attributes[0].additionalFields?.length; // Get max additional column length

            if (maxAdditionalColumn > 0) {
                let newColumnNames = attributes[0].additionalFields.map((col) => {
                    return {
                        id: col.columnId,
                        name: col.columnName,
                        createdBy: col.createdBy,
                    }
                });
                const newColumns = [
                    ...newColumnNames, // Set new columns
                ];
                setColumns(newColumns); // Set columns state
            }
            setTableRows(attributes); // Set table rows state

            closeBackdrop() // Close backdrop
        } catch (error) {
            console.error("Error Sorting Data:", error); // Log error
            showToast("error", "Error Sorting Data") // Show toast notification
        }
        closeBackdrop();// Close backdrop
    };

    // Function to get product details data
    const getData = async () => {
        try {
            // TASK ID CHECK IS REMAINING BUT WE THOUGHT ABOUT IT.
            const response = await privateAxios.get(`/technicalSpecification/getVendorRevisionById/${revisionId}`);
            let data = response.data;
            setProductDetails(data) // Set product details
            buildRows(data.productAttributes); // Build rows with product attributes
            setSpecificationValue(data.productSpecification?.name); // Set specification value
            setDocuments(data.productDocuments); // Set documents
            setIsDefaultRevision(data.default); // Set default revision state

        } catch (error) {
            // Handle error if needed
        }
    }

    // Fetch product details on component mount
    useEffect(() => {
        getData(); // Call getData function
    }, []) // Empty dependency array to run only once


    // ==================Product Details========================


    // ==================Task Profiles========================


      // State variables to manage task profiles
      const [taskProfiles, setTaskProfiles] = useState([]); // For task profiles


// Function to get task details
    const getTaskDetails = async () => {
        try {
            await privateAxios.get(`/task/getTaskById/${taskId}`).then(res => {
                setTaskProfiles(res.data.assignTo);
                setReassignedData(res.data);
            })
        } catch (error) {
        }
    }
    useEffect(() => {
        getTaskDetails();
    }, [])

    // ==================Task Profiles========================

    // ==================Chat Slider========================

// State variables to manage chat slider
const [chatSlider, setChatSlider] = useState(false) // For chat slider visibility
const [chatValue, setChatValue] = useState(""); // For chat input value
const [revisionChats, setRevisionChats] = useState([]); // For revision chats

    const handleChatSlider = () => {
        setChatSlider(prev => !prev)// Toggle chat slider
    }

    
    // Fetch revision chats when chat slider is open
    useEffect(() => {
        if (chatSlider === true) {
            getRevisionChats();// Fetch revision chats
        }

    }, [chatSlider])// Dependency array to re-run the effect when chatSlider changes

      // Function to handle chat send
    const handleChatSend = async (e) => {
        e.preventDefault()// Prevent default form submission
        try {
            if (chatValue.length > 0) {

                await privateAxios.post(`/technicalSpecification/addRemarkOnVendorRevisionByKam/${auth.userData.id}/${revisionId}`, { remark: chatValue }).then(res => {
                    getRevisionChats(); // Fetch updated chats
                    setChatValue("") // Clear chat input
                })
            }
        } catch (error) {
             // Handle error if needed
        }

    }

 // Function to get revision chats
    const getRevisionChats = async () => {
        try {
            await privateAxios.get(`/technicalSpecification/getRemarksOfRevisionOfVendorandKam/${revisionId}`).then(res => {
                setRevisionChats(res.data) // Set revision chats
                handleChatNotification(); // Handle chat notification
            })
        } catch (error) {
              // Handle error if needed
        }
    }

 // State variable to re-render on chat notification
    const [reRender, setReRender] = useState(false);

    const handleChatNotification = () => {
        setReRender(prev => !prev); // Toggle re-render state

    }



    // ==================Chat Slider========================


    // ======================Document Data=====================



    // State variable to manage document modal
    const [addDocumentModal, setAddDocumentModal] = useState(false); // For add document modal visibility
    const handleAddDocumentModal = () => {
        setAddDocumentModal(prev => !prev); // Toggle add document modal visibility
    }
    // ======================Document Data=====================


    // ======================Save Revision=====================\

    // Function to handle save as revision
    const handleSaveAsRevison = async (data) => {
        showToast("caution", "Not Allowed") // Show toast notification
    }


     // Function to handle save revision
    const handleSaveRevision = async () => {
        openBackdrop()
        try {

            if (handleAttributeValidation("check")) { // Validate attributes
                const updatedtableRow = tableRows.map((row) => {
                    const { id, status, ...rest } = row;// Destructure row to exclude id and status
                    return {
                        ...rest,
                        additionalFields: JSON.stringify(row.additionalFields)// Stringify additional fields
                    };
                });

                const updatedDocuments = documents.map(dd => {
                    const tr = dd;
                    delete tr.id;// Delete id from documents
                    return dd;
                });

                const requestPayload =
                {
                    name: productDetails.name,
                    productName: productDetails.productName,
                    productSpecification: { name: specificationValue },
                    productAttributes: updatedtableRow,
                    productId: productDetails.productId,
                    type: productDetails.type,

                }
                await privateAxios.post(`/technicalSpecification/updateVendorRevision/${revisionId}`, requestPayload)
                await getData(); // Fetch updated data
                showToast("success", "Revision Saved")// Show toast notification
            }


        } catch (error) {
            showToast("error", "Error Saving Revision")// Show toast notification
        }
        closeBackdrop(); // Close backdrop
    }


    // ======================Save Revision=====================

    // ======================Rename Revision====================

     // Function to handle change revision name
    const handleChangeRevisionName = async (data) => {
        showToast("caution", "Not Allowed") // Show toast notification
    }


    // ======================Rename Revision====================


    // ======================Duplicate Revision====================

      // Function to handle duplicate revision
    const handleDuplicateRevisison = async (data) => {
        showToast("caution", "Not Allowed")// Show toast notification
    }



    // ======================Duplicate Revision====================


    // =====================Delete Revision===============================


  // Function to handle revision delete
  const handleRevisionDelete = async () => {
    showToast("caution", "Not Allowed") // Show toast notification
}


    // =====================Delete Revision===============================

    // ====================Send Revision for Approval from vendor to Kam============

 // Function to send revision to KAM
    const handleSendToKAM = async (data) => {
        openBackdrop();// Open backdrop
        let URL = `/technicalSpecification/sendVendorRevisionToKAMFromVendor/${revisionId}`

        try {
            await privateAxios.post(`${URL}`, { comments: data.comment }) // API call to send revision to KAM
            handleSendToKamModal();// Toggle KAM modal visibility
            showToast("success", "Revision Sent To KAM")// Show toast notification


        } catch (error) {
            showToast("error", "Error Sending Data") // Show toast notification
        }
        closeBackdrop();// Close backdrop
    }


    // ====================Send Revision for Approval from vendor to Kam============



    // =========================Download Revision==============================


 // Function to handle download revision
    const handleDownloadRevision = () => {
        let tempElement = document.createElement('div');
        tempElement.innerHTML = specificationValue; // Set inner HTML to specification value
        let extractedText = tempElement.textContent; // Extract text content
        extractedText = extractedText.trim(); // Trim extracted text

        // taking nessarry feilds from attributes
        const Attributes = tableRows.map((attri) => {
            const modifiedAttri = { ...attri };
            delete modifiedAttri.id; // Delete id from attributes
            if (revisionId !== 0) {
                delete modifiedAttri.revisedValue;
                delete modifiedAttri.vendorRemark;

            }
            const ttadditionsFields = [];
            attri.additionalFields.forEach((rem, index) => {
                ttadditionsFields[rem.columnName] = rem.columnValue;// Map additional fields
            });
            delete modifiedAttri.additionalFields;
            return { ...modifiedAttri, ...ttadditionsFields };// Merge attributes with additional fields

        });

        let revisionDocument = []
        if (isDefaultRevision) {

            revisionDocument = documents.map((doc) => {
                return {
                    name: "Not Available",
                    link: doc.url,
                    type: "normal"

                }
            })

        }
        else {
            revisionDocument = documents.map((doc) => {
                return {
                    name: doc?.name,
                    link: doc.url,
                    type: doc?.type

                }
            })
        }


        createExcel([[{ Specification: extractedText }], Attributes, revisionDocument], (productDetails.name || "Revision 0"))
    }



    // =========================Download Revision==============================


    // =========================Jump to Row==============================


     // State variable to manage jump search input
    const [jumpSearch, setJumpSearch] = useState("");// For jump search input value

    // Function to handle row selection and scroll to the selected row
    const handleSelect = (value) => {
        const selectedValue = value;// Get selected value
        const selectedRow = tableRows.find((row) => row.name === selectedValue);// Find the selected row
        if (selectedRow) {
            const selectedRowElement = document.getElementById(`row-${selectedRow.id}`);// Get the element of the selected row
            if (selectedRowElement) {
                selectedRowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });// Scroll to the selected row
            }
        }
    };


    // =========================Jump to Row==============================




    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>
                {!showUnAuthorizedToast ?

                    <>
                        <div className={workSpaceStyle.workSpaceHeader}>
                            <div className={workSpaceStyle.workSpaceHeaderLeft} style={{ maxWidth: "none" }}>
                                <h1>
                                    <BackButton />
                                    {productDetails.name || "Not Name"}</h1>
                                <p>#0{productDetails.id} {productDetails?.productName || "Dafault"}</p>
                            </div>

                            <div className={workSpaceStyle.workSpaceHeaderRight}>
                                <div className={workSpaceStyle.workSpaceThreeDotVertical}>
                                    <Tooltip title='Comments'>
                                        <span onClick={() => { handleChatSlider(); }}>

                                            <ChatIconWithBadge url={`/technicalSpecification/getNotficationCount`} id={revisionId} reRender={reRender} />
                                        </span>
                                    </Tooltip>
                                </div>

                                {tabActive === "2" && !isDefaultRevision &&
                                    <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative" }}>
                                        <button onClick={handleAddDocumentModal}><AddIcon />Add</button>


                                    </div>}
                                <div className={workSpaceStyle.multiSaveButton}>
                                    <button onClick={handleMultiSave}>
                                        Save
                                        <span>
                                            {multiSave
                                                ? <KeyboardArrowUpIcon />
                                                : <KeyboardArrowDownIcon />
                                            }
                                            {/*  */}
                                        </span>
                                    </button>

                                    {multiSave &&
                                        <ul>
                                            <li onClick={() => { handleSaveRevision(); setMultiSave(false); }}>Update & Save </li>
                                        </ul>
                                    }

                                </div>

                                <div className={workSpaceStyle.workSpaceThreeDotVertical} style={{ position: "relative" }}>

                                    <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={() => setworkSpaceHeaderRevisionMore(prev => !prev)}>
                                        <MoreVertIcon />
                                    </span>
                                    {
                                        workSpaceHeaderRevisionMore &&
                                        <ThreeDotDropDown toggle={() => setworkSpaceHeaderRevisionMore(prev => !prev)} >

                                            {auth.roles === "VENDOR"
                                                &&
                                                <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleSendToKamModal(); }}>
                                                    <span><PersonAddAltOutlinedIcon /></span>Send for Approval To KAM</li>
                                            }

                                            {auth.roles === "KAM" &&
                                                <>
                                                    <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleApprovalModal(); }}>
                                                        <span><PersonAddAltOutlinedIcon /></span>Send for Approval</li>

                                                    <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleRenameModal() }}>
                                                        <span><DriveFileRenameOutlineIcon /></span> Rename
                                                    </li>
                                                    <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleDuplicateNameModal() }}>
                                                        <span><ContentCopyIcon /></span>Duplicate</li>
                                                    <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleDeleteModal(); }}>
                                                        <span><DeleteOutlineIcon /></span>Delete</li>



                                                    <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleDownloadRevision(); }}><span>
                                                        <FileDownloadOutlinedIcon />
                                                    </span>Download</li>
                                                </>
                                            }
                                            <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); deleteAllDuplicateRows() }}>
                                                <span><DriveFileRenameOutlineIcon /></span>
                                                Delete Duplicate Entry
                                            </li>
                                        </ThreeDotDropDown>
                                    }

                                </div>
                                <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
                                    <Tooltip title='Help'>
                                        <a target='_blank' href='https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081345-technical-specification-revision-details'>
                                            <HelpOutlineOutlinedIcon />
                                        </a>
                                    </Tooltip>
                                </div>
                            </div>

                        </div>

                        <div className={styles.tabHeaderMain}>
                            <div className={styles.tabHeaderWapper}>


                                <div
                                    onClick={() => handleTabChange("0")}
                                    className={tabActive === "0" ? `${styles.revisionTab} ${styles.revisionTabActive} ` : `${styles.revisionTab}`}>Specification</div>
                                <div
                                    onClick={() => handleTabChange("1")}
                                    className={tabActive === "1" ? `${styles.revisionTab} ${styles.revisionTabActive} ` : `${styles.revisionTab}`}>Attributes</div>
                                <div
                                    onClick={() => handleTabChange("2")}
                                    className={tabActive === "2" ? `${styles.revisionTab} ${styles.revisionTabActive} ` : `${styles.revisionTab}`}>Documents</div>
                            </div>

                            {
                                tabActive === "1" &&
                                <div className={styles.searchContainer}>
                                    <div className={styles.searchBox}>
                                        <input type="text" placeholder="Search" value={jumpSearch} onChange={(e) => setJumpSearch(e.target.value)} />
                                    </div>

                                    {
                                        jumpSearch.length > 0 &&
                                        <>
                                            <div className={styles.jumpListDropDown}>
                                                <ul className={styles.jumpToRowList}>
                                                    {tableRows.filter((row) => row.name.trim().toLowerCase().includes(jumpSearch.toLowerCase())).map((row) => {
                                                        return (
                                                            <li onClick={() => handleSelect(row.name)}>{row.name}</li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>

                                        </>
                                    }

                                </div>
                            }


                        </div>

                        <div className={styles.tabBodyMain} >

                            {tabActive === "0" && <SpecificationTab
                                value={specificationValue}
                                onChange={setSpecificationValue}
                            />}
                            {tabActive === "1" && <AttributeTab
                                tableRows={tableRows}
                                setTableRows={setTableRows}
                                showToast={showToast}
                                columns={columns}
                                setColumns={setColumns}
                                duplicateRow={duplicateRow}
                                viewType={"VENDOR"} />}
                            {tabActive === "2" && <DocumentsTab
                                isDefaultRevision={isDefaultRevision}
                                documents={documents}
                                setDocuments={setDocuments}
                                addDocumentModal={addDocumentModal}
                                handleAddDocumentModal={handleAddDocumentModal}
                                revisionId={revisionId ?? "2"}
                                viewType={viewType}
                                getDataProcure={getData}
                                showToast={showToast} />}

                        </div>

                    </>
                    :
                    <>
                        {
                            !loading &&

                            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <h2 style={{ color: "red" }}>Technical Specification Not Available!!</h2>
                                <h3 style={{ color: "red" }}>Contact Admin!!</h3>
                            </div>
                        }
                    </>

                }

            </div >



            <RenameModal
                open={renameModal}
                onClose={handleRenameModal}
                onSubmit={handleChangeRevisionName}
                prefilled={productDetails?.name}
                header="Rename"
            />
            <RenameModal
                open={saveAsModal}
                onClose={handleSaveAsModal}
                onSubmit={handleSaveAsRevison}
                prefilled={productDetails?.name}
                header="Save As"
            />
            <RenameModal
                open={duplicateNameModal}
                onClose={handleDuplicateNameModal}
                onSubmit={handleDuplicateRevisison}
                prefilled={productDetails?.name}
                header="Duplicate"
            />


            <SendToApprovalCommityModal
                open={approvalModal}
                onClose={handleApprovalModal}
                showToast={showToast}
                revisionId={revisionId}

                header={"Send for Approval"}
            />

            <SendForApprovalModal
                open={kamModal}
                onClose={handleSendToKamModal}
                onSubmit={handleSendToKAM}
                prefilled={""}
                header="Send For Approval"
            />


            <Modal
                open={reassignModal}
                onClose={handleReassignModal}
            >
                <EditTaskPannel handleEditTaskModal={handleReassignModal} editData={reassignedData} />
            </Modal>



            <DeleteConfirmation
                onCancel={handleDeleteModal}
                onConfirm={() => { handleRevisionDelete(); handleDeleteModal() }}
                open={deleteModal}
            >
                <h2>Delete Revision</h2>
                <p>Are you sure you want to delete this Revision?</p>

            </DeleteConfirmation>


            <ChatPannel
                open={chatSlider}
                onClose={handleChatSlider}
            >
                <>
                    <ChatHeader
                        onClose={handleChatSlider}
                        downloadData={revisionChats.map((chat) => {
                            return { name: chat.name, date: chat.createdAt, chat: chat.remark };
                        })}
                        helpData={{ link: "tosomewhere" }}
                        chatName={productDetails?.name}
                    >
                        <h3>{productDetails?.name}</h3>
                    </ChatHeader>

                    <ChatRows>

                        {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
                            return (
                                <ChatRow key={index} name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} email={rev.email} />
                            )
                        })
                            :
                            <p style={{ textAlign: "center" }}>
                                No Previous Chat
                            </p>
                        }

                    </ChatRows>

                    <ChatInputField onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

                </>
            </ChatPannel>


        </>
    )
}

export default VendorTechnicalRevisionView
