import React from 'react'
import { useState, useEffect } from 'react';
import categoryData from "../../api/categories.json"
import Button from '@mui/material/Button';
import TaskStyle from "../TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PrivateComponent } from 'api/axios';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import useBackdrop from 'hooks/useBackdrop';
import useAuth from 'hooks/useAuth';
import { useRef } from 'react';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Modal from '@mui/material/Modal';
import EditTaskPannel from 'pages/EditCreateTask/EditTaskPannel/EditTaskPannel';
import EditTasksCommercial from 'pages/CommercialViewKAM/KamCreateTask/TasksModals/EditTasksCommercial';


const AdminTask = () => {
    // Initialize state variables
    const taskData = categoryData;
    const [data, setData] = useState([])
    const { client } = useParams();
    const privateAxios = PrivateComponent();

    const [sortedData, setSortedData] = useState([]);
    const [search, setSearch] = useState("")
    const [searchValue, setSearchValue] = useState("")
    const navigate = useNavigate();

    // State variables for modals and dropdowns
    const [deleteKamModal, setDeleteKamModal] = useState(false);
    const [selectedOptionId, setSelectedOptionId] = useState(null);
    const [prioritySelectorOpen, setPrioritySelectorOpen] = useState('');
    const [statusSelectorOpen, setStatusSelectorOpen] = useState('');
    const [addKamModal, setaddKamModal] = useState(false);
    const [workspacedropDownOpen, setWorkspaceDropDownOpen] = useState(false)

    const [multiClick, setMultiClick] = useState(false);
    const handleMultiClick = () => {
        setMultiClick(prev => !prev)
    }

    const [multiClick2, setMultiClick2] = useState(false);
    const handleMultiClick2 = () => {
        setMultiClick2(prev => !prev)
    }

    const [multiClick3, setMultiClick3] = useState(false);
    const handleMultiClick3 = () => {
        setMultiClick3(prev => !prev)
    }

    const [multiClick4, setMultiClick4] = useState(false);
    const handleMultiClick4 = () => {
        setMultiClick4(prev => !prev)
    }

    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const [createTaskModal, setCreateTaskModal] = useState(false)

    const { auth } = useAuth();
    const emailRef = useRef();
    const [tabOpen, settabOpen] = useState({
        kam: false,
        admin: false,
        user: false
    })
    const [initialRender, setInitialRender] = useState(true);
    const [editTaskModal, setEditTaskModal] = useState(false)

    const [formData, setFormData] = useState({
        email: "",
        accessRole: "",
        designation: "",

    })

    const [formError, setFormErrors] = useState({
        email: "",
        accessRole: "",
        designation: "",

        formError: ""
    })
    const [editData, setEditData] = useState({})



    const [tabShow, setTabShow] = useState({
        KAM: true,
        USER: true,
        ADMIN: true,
    })
    const [tabLabelShow, setLabelTabShow] = useState({
        KAM: true,
        USER: true,
        ADMIN: true,
    })
    const [tabAssignShow, setAssignTabShow] = useState({
        KAM: true,
        USER: true,
        ADMIN: true,
    })
    const [userData, setUserData] = useState([])
    const [workSpaceSelected, setWorkSpaceSelected] = useState("ALL");
    const [totalUser, setTotalUser] = useState("");
    const [updatedData, setUpdatedData] = useState([]);

    // Handlers for modal toggles
    const handlecreateTaskModal = () => {
        setCreateTaskModal(prev => !prev)
    }
    const handleEditTaskModal = () => {
        setEditTaskModal(prev => !prev)
    }
    const handleaddKamModal = () => {
        setaddKamModal(prev => !prev);

        setFormData({
            email: "",
            accessRole: "",
            designation: "",

        }
        )

        setFormErrors({
            email: "",
            accessRole: "",
            designation: "",

            formError: ""
        })

    }

    // Handler for input changes
    const handleInput = (e) => {
        setFormData(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [e.target.name]: '',
            formError: "",
        }));

    }

    // Function to handle workspace sorter
    const handleWorkspaceSorter = (value) => {
        setWorkspaceDropDownOpen(false);
        setWorkSpaceSelected(value);

    }
    const [taskPage, setTaskPage] = useState(1)

    // Function to load more tasks
    const loadMore = async (page, size) => {
        try {
            const response = await privateAxios.get(`/task/getTaskByRoles?pageNo=${page}&pageSize=${size}`)
                .then((res) => {
                    // console.log(res.data)
                    setData(prev => {
                        return [...prev, ...res.data]
                    });


                    // console.log(data)
                    setTaskPage(prev => prev + 1);

                }).catch((error) => {
                    // console.log(error)
                }
                )
        } catch (error) {
            // console.log(error)
        }
    }

    //------------------------------------- get all ts tasks-----------------------------------


    // Function to get all tasks
    const getAllTasks = async () => {
        openBackdrop();

        try {
            // console.log(auth.roles==="ADMIN")

            if (auth.roles === "ADMIN") {
                const response = await privateAxios.get(`/task/getTasksOfAdmin`)
                    .then((res) => {
                        // console.log("GTSHG" + res.data)
                        setData(res.data);

                        if (res.status === 200) {
                            const data = res.data;
                            closeBackdrop();
                        }
                        // console.log(res.data)
                        closeBackdrop();

                    }).catch((error) => {
                        // console.log(error)
                        closeBackdrop();
                    }
                    )
            } else {
                const response = await privateAxios.get(`/task/getTaskByRoles`)
                    .then((res) => {
                        // console.log(res.data)
                        setData(res.data);

                        if (res.status === 200) {
                            const data = res.data;
                            closeBackdrop();
                        }
                        // console.log(res.data)
                        closeBackdrop();

                    }).catch((error) => {
                        // console.log(error)
                        closeBackdrop();
                    }
                    )
            }

        } catch (error) {
            // console.log(error)
            closeBackdrop();
        }

    }

    useEffect(() => {
        getAllTasks()

    }, []);
    //  -------------------------------------------------------------------------------------------------------------

    // State and handlers for status filter
    const [statusDropDownOpen, setStatusDropDownOpen] = useState(false)
    const [statusSelected, setStatusSelected] = useState("All");


    const fetchData = async (value) => {
        setStatusSelected(value)
        setStatusDropDownOpen(false)
        setStatusSelected(value)
        openBackdrop();
        try {
            let param = "ALL"
            switch (value.toLowerCase()) {
                case "to do": param = "TODO";
                    break;
                case "in progress": param = "INPROGRESS";
                    break;
                case "done": param = "DONE";
                    break;
                case "completed": param = "COMPLETED";
                    break;
                default: param = "ALL"
            }
            if (param === "ALL") {
                const response = privateAxios.get(`/task/getTaskByRoles`).then((res) => {
                    setData(res.data);
                    closeBackdrop();
                }).catch(err => {
                    closeBackdrop();
                })
            } else {
                const response = privateAxios.get(`/task/getTaskByStatusInAdmin?status=${param}`).then((res) => {
                    setData(res.data);
                    closeBackdrop();
                })
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            closeBackdrop();
        }
    };





    // --------------------------------------------------------------get all Assign------------------------------------------------

    // State and handlers for assign filter
    const [assignDropDownOpen, setAssignDropDownOpen] = useState(false)
    const [assignSelected, setAssignSelected] = useState("All");
    const [userList, setUserList] = useState([]);
    const [loadingKam, setLoadingKam] = useState(false);
    const [userSorterList, setUserSorterList] = useState([]);


    const fetchUsers = async () => {

        try {
            // console.log("fetcg users")
            setLoadingKam(true);
            // await privateAxios.get(`/adminuser/getAllUsers/${auth.orgId}`).then((res) => {
            await privateAxios.get(`/task/getAllWorkspaceUsers/${auth.orgId}`).then((res) => {
                const emails = res.data
                    .filter(tt => tt.disable === false).map((tr => tr.email));
                // console.log(emails)
                setUserList(emails);
                setUserSorterList(emails)

            })

        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoadingKam(false);
            // console.log("fetcg users finally")
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [])

    // --------------------------------------------------------------Filter-Assign-----------------------------------------------------------------
    const fetchData2 = async (email) => {
        // console.log(email)
        setAssignSelected(email)
        openBackdrop();
        try {
            if (email.toLowerCase() === "all") {
                getAllTasks();
                closeBackdrop();
            } else {
                await privateAxios.get(`/task/getTaskByAssigned?email=${email}`).then((res) => {
                    setData(res.data);
                    closeBackdrop();
                }).catch(err => {
                    closeBackdrop();
                })
            }
            setAssignDropDownOpen(false)
            closeBackdrop();
        } catch (error) {
            closeBackdrop();
        }
    };

    // ----------------------------------------------------------------Filter Label----------------------------------------------------------------------

    // State and handlers for label filter
    const [labelDropDownOpen, setLabelDropDownOpen] = useState(false)
    const [labelSelected, setLabelSelected] = useState("All");


    // Function to fetch data based on the priority value selected
    const fetchData3 = async (value) => {
        // Close the label dropdown menu
        setLabelDropDownOpen(false);
        // Set the selected label to the value passed
        setLabelSelected(value);
        // Open a backdrop (loading indicator)
        openBackdrop();
        try {
            // Default parameter for API call
            let param = "ALL";
            // Switch case to determine the parameter based on the value passed
            switch (value.toLowerCase()) {
                case "low": param = "LOW";
                    break;
                case "medium": param = "MEDIUM";
                    break;
                case "high": param = "HIGH";
                    break;
                default: param = "ALL"
            }
            // If parameter is "ALL", fetch all tasks by roles
            if (param === "ALL") {
                const response = privateAxios.get(`/task/getTaskByRoles`).then((res) => {
                    // Set the fetched data to the state
                    setData(res.data);
                    // Close the backdrop
                    closeBackdrop();

                }).catch(err => {
                    // Handle error and close the backdrop
                    closeBackdrop();
                })
            } else {
                // Fetch tasks by priority
                const response = privateAxios.get(`/task/getTaskByPriorityInAdmin?priority=${param}`).then((res) => {
                    // Set the fetched data to the state
                    setData(res.data);
                    // Close the backdrop
                    closeBackdrop();
                }).catch(err => {
                    // Handle error and close the backdrop
                    closeBackdrop();
                })
            }

        } catch (error) {
            // Log the error and close the backdrop
            console.error('Error fetching data:', error);
            closeBackdrop();
        }
    };




    // New state for email selection modal
    const [emailModalOpen, setEmailModalOpen] = useState(false);

    // Function to open the email modal
    const handleEmailModalOpen = () => {
        setEmailModalOpen(true);
    };

    // Function to close the email modal
    const handleEmailModalClose = () => {
        setEmailModalOpen(false);
    };


    // -------------------------------------------------------------

    // Function to handle call to action (CTA) navigation
    const handleCTA = async (productId, taskId) => {
        navigate(`/${client}/technical-specification/${taskId}/${productId}`)

    }

    //---------------------------------------------------------------

    // Function to handle option selection
    const handleChoose = (selectedId) => {
        setSelectedOptionId(selectedId);
    };

    // Function to toggle the delete modal
    const handleDeleteModal = () => {
        setDeleteKamModal(prev => !prev);
    }


    // State for selected option
    const [selectedOption, setSelectedOption] = useState(null);

    // Function to handle option selection and set state
    const handleOptionSelect = (option) => {
        setSelectedOption(option);
    };

    // Function to toggle the priority selector dropdown
    const handlePrioritySelector = (index) => {
        if (prioritySelectorOpen === index) {
            setPrioritySelectorOpen('')
        } else {
            setPrioritySelectorOpen(index)
        }
    }


    // Function to change the priority of a task
    const handlePriorityChange = (id, value) => {
        setData(prev => {
            return prev.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        label: value
                    }
                }
                return item
            })

        });
    };


    // Function to toggle the status selector dropdown
    const handleStatusSelector = (index) => {
        if (statusSelectorOpen === index) {
            setStatusSelectorOpen('')
        } else {
            setStatusSelectorOpen(index)
        }
    }

    // Function to change the status of a task
    const handleStatusChange = async (taskId, value) => {
        try {
            let param = "ALL"
            switch (value.toLowerCase()) {
                case "to do": param = "TODO";
                    break;
                case "in progress": param = "INPROGRESS";
                    break;
                case "done": param = "DONE";
                    break;
                case "completed": param = "COMPLETED";
                    break;
                default: param = "ALL"
            }
            await privateAxios.post(`/task/updateTaskStatus/${taskId}?status=${param}`).then(res => {
                // Refresh the tasks list after status update
                getAllTasks();
            })
        } catch (error) {
            // console.log(error)
        }
    };
    // ----------------------------------------------get all users-----------------------------------------------------------

    // Function to get all users
    const getAllUser = async () => {
        try {
            await privateAxios.get(`/procurement/getAllUsers/${auth.orgId}`)
                .then((res) => {
                    if (res.status === 200) {
                        const data = res.data;
                        const dataArr = [];
                        const groupedData = {};
                        // Group users by their role
                        res.data.data.employees.forEach(entry => {
                            const role_name = entry?.user.entityType;
                            if (!groupedData[role_name]) {
                                groupedData[role_name] = [];
                            }
                            groupedData[role_name].push(entry);
                        })

                        let tt = 0;

                        if (res.data?.KAM) {
                            tt += res.data.kamEntity.length;
                        }

                        if (groupedData?.USER) {
                            tt += groupedData.USER.length;
                        }

                        if (groupedData?.ADMIN) {
                            tt += groupedData.ADMIN.length;
                        }

                        // Set total user count and user data by role
                        setTotalUser(tt)
                        setUserData({
                            USER: groupedData?.USER,
                            KAM: res.data?.KAM,
                            ADMIN: groupedData?.ADMIN
                        })
                    }

                }).catch((err) => {
                }
                )

        } catch (error) {
        }
    }

    // Fetch all users on component mount
    useEffect(() => {
        getAllUser()
    }, [])
    // -------------------------------------------------New Sortting----------------------------------------------------------------------

    // State for start date dropdown
    const [isStartDateDropdownOpen, setIsStartDateDropdownOpen] = useState(false);

    // Function to toggle the start date dropdown
    const toggleStartDateDropdown = () => {
        setIsStartDateDropdownOpen(!isStartDateDropdownOpen);
    };

    // Function to handle sorting by start date
    const handleStartDateSort = (sortType) => {
        let sortedItems = [...data];

        if (sortType === 'Newest') {
            sortedItems.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        } else if (sortType === 'Oldest') {
            sortedItems.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        }

        setSortedData(sortedItems);
        // Close the dropdown
        setIsStartDateDropdownOpen(false);
    };
    // ---------------------------------------------------------------------------------------------------------------------------

    // State for end date dropdown
    const [isEndDateDropdownOpen, setIsEndDateDropdownOpen] = useState(false);

    // Function to toggle the end date dropdown
    const toggleEndDateDropdown = () => {
        setIsEndDateDropdownOpen(!isEndDateDropdownOpen);
    };

    // Function to handle sorting by end date
    const handleEndDateSort = (sortType) => {
        let sortedItems2 = [...updatedData];

        if (sortType === 'Newest') {
            sortedItems2.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
        } else if (sortType === 'Oldest') {
            sortedItems2.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
        }

        setSortedData(sortedItems2);
        setIsEndDateDropdownOpen(false);
    };


    // ---------------------------------------------------------Seraching-Api-------------------------------------------------------------

    // Function to handle search input changes
    const handleSerachChange = async (e) => {
        setSearchValue(e.target.value)
        try {
            if (e.target.value === "") {
                // Fetch all tasks if search input is empty
                getAllTasks();
            }
            else {
                // Fetch tasks based on search input
                await privateAxios.post(`/task/searchTask?title=${e.target.value}&description=${e.target.value}`).then(res => {
                    setData(res.data);
                }).catch(err => {
                })
            }

        } catch (error) {
        }
    };




    return (
        <div className={TaskStyle.table1} style={{ height: '100%' }}>
            <div className={workSpaceStyle.workSpaceMain} style={{ gap: '0' }}>

                <div className={workSpaceStyle.workSpaceHeader} style={{ marginBottom: '20px' }}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <h1 style={{ gap: '10px' }}>
                            <span> <PeopleOutlineIcon /> </span>
                            Technical Specification
                        </h1>
                        <p>All Task <span>{data.length}</span></p>

                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
                        <input
                            type="text"
                            onChange={handleSerachChange}
                            value={searchValue}
                            placeholder='Search Here...'
                        />
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: '450px', minWidth: '450px' }} >
                        <div className={workSpaceStyle.WorkSpaceHeaderSorters} style={{ width: '25%' }}>
                            <p>Status:</p>

                            <h3 onClick={() => { setStatusDropDownOpen(prev => !prev); setWorkspaceDropDownOpen(false) }}><Tooltip title='Filter Status'><AdminPanelSettingsIcon /></Tooltip>{statusSelected}</h3>

                            {statusDropDownOpen &&
                                <>
                                    <div>
                                        <div className={workSpaceStyle.workSpaceHeaderDropDownBackDrop} onClick={() => setStatusDropDownOpen(false)}></div>

                                        <div className={workSpaceStyle.workSpaceHeaderSorterDropdown} style={{ width: '140px' }}>
                                            <h5 style={{ fontSize: '15px' }}>Status:</h5>

                                            {/* <ul onChange={e => setStatusSelected(e.target.value)}> */}
                                            <ul>
                                                <li style={{ fontSize: '14px' }} onClick={() => fetchData("All")}>All</li>
                                                <li style={{ fontSize: '14px' }} onClick={() => fetchData("To Do")}>To-do</li>
                                                <li style={{ fontSize: '14px' }} onClick={() => fetchData("In Progress")}>In Progress</li>
                                                <li style={{ fontSize: '14px' }} onClick={() => fetchData("Done")}>Done</li>
                                                {/* <li style={{ fontSize: '14px' }} onClick={() => fetchData("Completed")}>Completed</li> */}

                                            </ul>
                                        </div>

                                    </div>
                                </>
                            }
                        </div>


                        <div className={workSpaceStyle.WorkSpaceHeaderSorters} style={{ width: '20%' }}>
                            <p>Lables:</p>
                            <h3 onClick={() => { setLabelDropDownOpen(prev => !prev); setWorkspaceDropDownOpen(false) }}><Tooltip title='Filter Lables'><ImportExportIcon /></Tooltip>{labelSelected}</h3>
                            {labelDropDownOpen &&
                                <>
                                    <div>
                                        <div className={workSpaceStyle.workSpaceHeaderDropDownBackDrop} onClick={() => setLabelDropDownOpen(false)}></div>

                                        <div className={workSpaceStyle.workSpaceHeaderSorterDropdown} style={{ width: '140px' }}>
                                            <h5 style={{ fontSize: '15px' }}>Priority:</h5>
                                            <ul >
                                                <li style={{ fontSize: '14px' }} onClick={() => fetchData3("All")}>All</li>
                                                <li style={{ fontSize: '14px' }} onClick={() => fetchData3("Low")}>Low</li>
                                                <li style={{ fontSize: '14px' }} onClick={() => fetchData3("Medium")}>Medium</li>
                                                <li style={{ fontSize: '14px' }} onClick={() => fetchData3("High")}>High</li>

                                            </ul>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                            <Tooltip title='Help'>
                                <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081383-create-task-technical-specification">
                                    <HelpOutlineOutlinedIcon />
                                </a>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                <table style={{ borderCollapse: 'collapse' }}>
                    <thead>
                        <tr className={TaskStyle.theader}>
                            <th>Task ID</th>
                            <th>Title</th>
                            <th>
                                <div className={TaskStyle.SortingDate}>
                                    <p onClick={handleMultiClick3} style={{ color: '#000' }}>
                                        <span style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
                                            Start Date<ImportExportIcon onClick={toggleStartDateDropdown} />
                                        </span>
                                    </p>
                                    {isStartDateDropdownOpen &&
                                        <ul >
                                            <li onClick={() => handleStartDateSort('Newest')}>Newest</li>
                                            <li onClick={() => handleStartDateSort('Oldest')}>Oldest </li>
                                        </ul>
                                    }
                                </div>
                            </th>

                            <th>
                                <div className={TaskStyle.SortingDate}>
                                    <p className={TaskStyle.jjjjj} onClick={handleMultiClick4} style={{ color: '#000' }}>
                                        <span style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
                                            End Date<ImportExportIcon onClick={toggleEndDateDropdown} />
                                        </span>
                                    </p>
                                    {isEndDateDropdownOpen &&
                                        <ul >
                                            <li onClick={() => handleEndDateSort('Newest')}>Newest</li>
                                            <li onClick={() => handleEndDateSort('Oldest')}>Oldest </li>
                                        </ul>
                                    }
                                </div>
                            </th>
                            <th>E/C Date</th>
                            <th>Status</th>
                            <th>Assigned To</th>
                            <th>Label</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                </table>

                <table style={{ borderCollapse: 'collapse' }}>
                    <tbody >
                        {
                            data.filter((item) =>
                                item?.title?.trim().toLowerCase().includes(search?.toLowerCase())).map((item, index) =>
                                    <tr key={item.taskId} className={TaskStyle.tbody}>
                                        <td>{index + 1}</td>
                                        <td onClick={() => { setEditData(item); handleEditTaskModal(); }}>{item.title}</td>
                                        <td style={{ backgroundColor: '#d2d2d2' }}>{item.startDate}</td>
                                        <td style={{ backgroundColor: '#d2d2d2' }}>{item.endDate}</td>
                                        <td>{item.completedDate}</td>

                                        <td className={TaskStyle.SelectBar} style={{ backgroundColor: '#d2d2d2' }}>
                                            <div onClick={() => handleStatusSelector(index)} className={TaskStyle.statusSelector}>
                                                <p onClick={handleMultiClick2} style={{ color: '#000' }}
                                                >{item.status.substring(0, 1).toUpperCase() + item.status.substring(1, item.status.length).toLowerCase()}
                                                    <span>
                                                        {statusSelectorOpen === index
                                                            ? <KeyboardArrowUpIcon />
                                                            : <KeyboardArrowDownIcon />
                                                        }
                                                    </span>
                                                </p>
                                                {statusSelectorOpen === index &&
                                                    <ul>
                                                        <li onClick={() => handleStatusChange(item.taskId, "To Do")}>To Do</li>
                                                        <li onClick={() => handleStatusChange(item.taskId, "In Progress")}>In Progress</li>
                                                        <li onClick={() => handleStatusChange(item.taskId, "Done")}>Done</li>
                                                    </ul>
                                                }
                                            </div>
                                        </td>

                                        <td>
                                            <div className={TaskStyle.addAssignMain} style={{ width: '100%', justifyContent: 'center' }}>
                                                <div className={TaskStyle.addAssign}>
                                                    {item.assignTo.length > 0 && item.assignTo.slice(0, 2).map((iit, index) => {
                                                        const colorArray = ["#0077b6", "#f77f00", "#a7c957"]
                                                        const randomIndex = Math.floor(Math.random() * colorArray.length);

                                                        const randomColor = colorArray[randomIndex]
                                                        if (iit?.disabled === false) {
                                                            return (

                                                                <div key={index} className={TaskStyle.name1} style={{ backgroundColor: randomColor }}>
                                                                    <ProfileIcon data={{ email: iit?.email }} height={"26px"} width={"26px"} showProfileBanner={true} />
                                                                </div>
                                                            )
                                                        }

                                                    })}

                                                </div>
                                            </div>
                                        </td>

                                        <td className={TaskStyle.SelectBar}>
                                            <div onClick={() => handlePrioritySelector(index)} className={TaskStyle.prioritySelector}>
                                                <p onClick={handleMultiClick}
                                                    style={
                                                        {
                                                            backgroundColor: item.priority === 'HIGH' ? '#ec5252' : item.priority === 'MEDIUM' ? '#f5ac38' : item.priority === 'LOW' ? '#b3f289' : '#fff',
                                                            color: item.priority === 'High' ? '#fff' : item.priority === 'Medium' ? '#fff' : item.priority === 'Low' ? '#fff' : '#fff',
                                                            height: "27px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center"

                                                        }
                                                    }
                                                >{item.priority.substring(0, 1).toUpperCase() + item.priority.substring(1, item.priority.length).toLowerCase()}
                                                </p>
                                            </div>
                                        </td>

                                        <td className={TaskStyle.taskDisplayBtn} >
                                            {
                                                item.type === "Technical Specification" ?
                                                    <Button variant="contained" onClick={() => navigate(`/${client}/technical-specification/${item.taskId}/${item.taskProductId}`)}>CTA</Button>
                                                    :

                                                    <Button variant="contained" onClick={() => navigate(`/${client}/commercial/${item.taskId}`)}>CTA</Button>
                                            }
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            </div>

            <Modal
                open={editTaskModal}
                onClose={handleEditTaskModal}>
                {
                    editData.type === "Technical Specification"
                        ?
                        <EditTaskPannel getAllTasks={getAllTasks} handleEditTaskModal={handleEditTaskModal} editData={editData} setEditData={setEditData} />
                        :
                        <EditTasksCommercial getAllTasks={getAllTasks} handleEditTaskModal={handleEditTaskModal} editData={editData} setEditData={setEditData} />
                }

            </Modal>
        </div>
    );

}

export default AdminTask
