import React, { useState,useEffect } from 'react'
import ApprovalRevisionStyle from './ApprovalRevisionTable.module.css'
import { useNavigate } from 'react-router-dom';
import ProfileIcon from '../ProfileIcon/ProfileIcon';


const ApprovalRevisionTable = ({addData,selectedRevisions,setSelectedRevisions}) => {

   // State variables
  const [checkBoxAll, setCheckBoxAll] = useState(false); // State for checkbox all
  const [revisionMore, setRevisionMore] = useState(""); // State for expanding/collapsing revisions
  const navigate = useNavigate(); // Hook for navigation

  // Function to handle selecting/deselecting a revision
  const handleVendorRevisionSelect = (data) => {
    const selectedIndex = selectedRevisions.findIndex(
      (item) => item.id === data.id
    );

    if (selectedIndex === -1) {
      setSelectedRevisions((prevSelected) => [...prevSelected, data]); // Add the revision to selected revisions
    } else {

      setSelectedRevisions((prevSelected) =>
        prevSelected.filter((item) => item.id !== data.id)// Remove the revision from selected revisions
      );
    }

  }

   // Function to toggle select all checkbox
  const handleSelectAllToggle = () => {

    if (selectedRevisions.length === addData.length) {
      setSelectedRevisions([]); // Deselect all revisions
      setCheckBoxAll(false); // Uncheck select all checkbox
    } else {
      setSelectedRevisions(addData); // Select all revisions
      setCheckBoxAll(true); // Check select all checkbox
    }
  }

    // Effect to update select all checkbox state when selected revisions or addData change
  useEffect(() => {
    if (selectedRevisions.length === addData.length) {
      setCheckBoxAll(true);// If all revisions are selected, check select all checkbox
    }
    else {
      setCheckBoxAll(false);// Otherwise, uncheck select all checkbox
    }
  }, [selectedRevisions, addData])

   // Function to navigate to different routes based on fileType
  const handleNavigate = (id, fileType) => {
    if (fileType === "PRODUCT") {
      navigate(`vendor/${id}?tab=0`)
    }
    else if (fileType === "GROUP") {
      navigate(`group/${id}`)
    }
    else if (fileType === "COMPARISION") {
      navigate(`compare/${id}?tab=0`)
    }
  }

  // Function to render fileType span based on type
  const handleFileType = (fileType) => {
    if (fileType === "PRODUCT") {
      return (
        <span style={{ padding: "2px 10px", fontWeight: "500", borderRadius: "4px" }}>Product</span>
      )
    }
    else if (fileType === "GROUP") {
      return (
        <span style={{ backgroundColor: "#EEEEEE", fontWeight: "500", padding: "2px 10px", borderRadius: "4px" }}>Group</span>
      )
    }
    else if (fileType === "COMPARISION") {
      return (
        <span style={{ backgroundColor: "#16469D", fontWeight: "500", color: "white", padding: "2px 10px", borderRadius: "4px" }}>Comparision</span>
      )
    }
  }

   // Function to format date string
  const formatDateString = (dateString) => {
    const dateObject = new Date(dateString);
    // Use JavaScript's Date methods to format the date as needed
    const formattedDate = dateObject.toLocaleDateString(); // This will give you the date in "MM/DD/YYYY" or "DD/MM/YYYY" format
    return formattedDate;
  }

// Function to toggle expansion/collapsing of revisions
  const handleToggle = (id) => {
    if (revisionMore === id) {
      setRevisionMore("");// Collapse if already expanded
    }
    else {
      setRevisionMore(id);// Expand
    }
  }

  // Function to check if two arrays have the same values
  const haveSameValues = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false; // Arrays have different lengths, so they can't have the same values.
    }
  
    return array1.every(item => array2.includes(item)); // Check if every item in array1 is included in array2
  };




  return (
    <div className={ApprovalRevisionStyle.approvalVendorMain}>
    <table className={ApprovalRevisionStyle.approvalVendorTable}>
      <thead>
        <tr className={`${ApprovalRevisionStyle.tableHead}`} >


          <td className={`${ApprovalRevisionStyle.tableColumnSticky}`} style={{ minWidth: "200px" }}>
            <div className={ApprovalRevisionStyle.tdRow}>
              <input type="checkbox" onChange={() => handleSelectAllToggle()} checked={checkBoxAll} />
              <p>Name</p>
            </div>
          </td>

          <td> Products </td>
          <td> File Type </td>
          <td> Category </td>
          <td> Submited On </td>
          <td> Owned  By </td>
          <td> Last Updated </td>
        </tr>
      </thead>
      <tbody>
        {addData.map((row, index) => {
          const isChecked = selectedRevisions.some((selectedRow) => selectedRow.id === row.id);
          return (
            <tr key={index} className={`${ApprovalRevisionStyle.tableRow}`}>
              <td className={`${ApprovalRevisionStyle.tableColumnSticky2} `}>
                <div className={ApprovalRevisionStyle.tdRow}>
                  <input type="checkbox" onChange={() => handleVendorRevisionSelect(row)} checked={isChecked} />
                  <p>{row.name}</p>
                </div>
              </td>

              <td >{row.productName}</td>
              <td >{handleFileType(row.vendorRevisionType)}</td>
              <td >{row.type}</td>
              <td >{row.submittedOn?.split("T")[0]}</td>
              <td >
                <div className={ApprovalRevisionStyle.tdRow}>
                  <ProfileIcon data={{ name: row.sentTo, email: row.vendorEmail, type: "KAM", profileLink: "SomeLink" }} height={"26px"} width={"26px"} showProfileBanner={true} /> {row.sentTo}
                </div>
              </td>
              <td >{formatDateString(row.lastModified)}</td>
             
            </tr>
          )
        }).reverse()
        }

      </tbody>
    </table>
  </div>
  )
}

export default ApprovalRevisionTable
