import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LoginStyle from './LoginStyle.module.css';
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import myaxios from 'api/axios'
import { useDispatch } from 'react-redux';
import useAuth from 'hooks/useAuth';
import useBackdrop from 'hooks/useBackdrop';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Alert from '@mui/material/Alert';
import { AES, enc } from 'crypto-js';
import { passwordEncryptor } from '../../utility/passwordEncryptor.js'




const Login = () => {

  // Custom hook to handle backdrop operations
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

  // Ref to focus on the email input field
  const emailRef = useRef();

  // Custom hook to get auth details and set login
  const { auth, setLogin } = useAuth();

  // Redux dispatch function
  const dispatch = useDispatch()

  // React Router's navigate function
  const navigate = useNavigate();

  // State to handle the visibility of the password
  const [showPassword, setShowPassword] = useState(false);

  // State to check if the user is already logged in
  const [logedIn, setLogedIn] = useState(true);

  // State to handle form data
  const [data, setData] = useState({
    email: "",
    password: "",
  })

  // State to handle form errors
  const [formErrors, setFormErrors] = useState({
    email: '',
    password: '',
  });


  // Handle input field changes
  const handleChange = (event, field) => {
    let actualValue = event.target.value
    setData({
      ...data,
      [field]: actualValue,
    })
  }


  // Effect to navigate based on user role and stage count
  // useEffect(() => {
  //   if (auth.companyName !== null && auth.token !== null && auth.userData !== null && auth.userData.stageCount === 5 && ["KAM", "ADMIN", "KAM"].includes(auth.roles)) {
  //     navigate(`/${auth.companyName}`, { state: { data: null }, replace: true })
  //   }
  //   else if (auth.roles === "VENDOR" && auth.userData.stageCount === 5 && auth.token !== null && auth.userData !== null) {
  //     navigate(`/vendor`, { state: { data: null }, replace: true })
  //   }
  //   else if (auth.roles === "SUPERADMIN" && auth.userData.stageCount === 5 && auth.token !== null && auth.userData !== null) {
  //     navigate(`/superadmin`, { state: { data: null }, replace: true })
  //   }
  //   else {
  //     setLogedIn(false);
  //   }
  // }, [])

  // Effect to focus on the email input field when user is not logged in
  useEffect(() => {
    if (logedIn === false) {
      emailRef?.current?.focus();
    }
  }, [logedIn, emailRef])

  // Handle input field change and clear errors
  const handleInputField = (e) => {
    const { name, value } = e.target;
    const lowercasedValue = name === 'email' ? value.toLowerCase() : value;

    setData((prev) => ({
      ...prev,
      [name]: lowercasedValue,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };


  // Validate form fields
  const handleValidation = (e) => {
    const { name, value } = e.target;
    let errorMessage = '';


    if (name === 'email') {
      if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
        errorMessage = 'Invalid email address';
      }
    } else if (name === 'password') {
      if (value.length === 0) {
        errorMessage = 'Please enter password';
      }
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  // State to handle error messages
  const [errorMessage, setErrorMessage] = useState('');

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    openBackdrop(); // Open a loading backdrop

    console.log("All Data:" + setLogin);// Log the login data for debugging
    if (data.password.length === 0) {// Check if password field is empty
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        password: 'Please enter password',// Set error message for password field
      }));
    }

    // Encrypt the password using AES with a 128-bit key
    const secretKey = 'AesPassword12345'; // Define the secret key for encryption
    const encryptedPassword = AES.encrypt(data.password, secretKey).toString();// Encrypt the password

    const encPass = await passwordEncryptor(data.password, 'AesPassword12345'); // Encrypt the password using another function
    const hasErrors = Object.values(formErrors).some((error) => error !== ''); // Check if there are any form errors
    const notEmpty = Object.values(data).some((dd) => dd.length < 1);// Check if any form field is empty
    setErrorMessage(''); // Reset error message
    if (!hasErrors && !notEmpty) {// If there are no errors and no empty fields

      await myaxios.post(`/procurement/login`, { usernameOrEmail: data.email, password: encPass })// Send login request
        .then(async (res) => {
          const ttres = res.data; // Get response data

          if (ttres.data.stageCount < 2) {// Check if stage count is less than 2
            navigate('/underVerification', { replace: true }) // Navigate to under verification page
          }
          else if (ttres.data.stageCount === 4) {// Check if stage count is 4
            navigate('/underVerification', { replace: true }) // Navigate to under verification page
          }
          else {
            if (res.data.data.role.name !== "VENDOR" && res.data.data.role.name !== "SUPERADMIN") { // Check if role is neither VENDOR nor SUPERADMIN

              const logindata = res.data; // Get login data
              if (ttres.data.stageCount === 2) { // Check if stage count is 2

                navigate('/redirectRegistration', { // Navigate to redirect registration page
                  state: {
                    data: {
                      subId: 1,
                      token: logindata.accessToken,
                      name: logindata.name,
                      email: logindata.data.email,
                      userData: logindata.data

                    }
                  },
                  replace: true
                });
              }
              else {
                await myaxios.get(`/procurement/getEmployeeDetails`, {  // Get employee details
                  headers: {
                    'Authorization': `Bearer ${res.data.accessToken}`, // Set authorization header
                  }
                }).then((res2) => {
                  const data = res2.data; // Get response data

                  if (ttres.data.stageCount === 3) { // Check if stage count is 3

                    navigate(`/productselect`, {// Navigate to product select page
                      state: {
                        data: {
                          token: logindata.accessToken,
                          name: logindata.name,
                          email: logindata.data.email,
                          userData: logindata.data,
                          orgId: data.data.organisationId,
                        }
                      }, replace: true
                    })
                  }
                  else if (ttres.data.stageCount === 5) { // Check if stage count is 5

                    let workArray = data?.data?.workspaces?.map((work) => {// Map workspaces to get their IDs
                      switch (work.id) {
                        case 1: return "technical-specification";// Return appropriate workspace name
                          break;
                        case 2: return "commercial";
                          break;
                        case 3: return "procurement";
                          break;
                        case 4: return "approvals";
                          break;
                      }

                    })

                    dispatch(setLogin({// Dispatch login action
                      token: logindata.accessToken,
                      name: logindata.name,
                      email: logindata.data.email,
                      roles: logindata.data.role.name,
                      allData: data.data,
                      userData: logindata.data,
                      companyName: data.data.organisationName,
                      workSpaces: workArray,
                      orgId: data.data.organisationId,
                      orgLogo: data.data.orgLogo,
                      mobNum: logindata.data.phone,
                      profilePic: data.data.profilePhoto,

                    }))
                    navigate(`/${data.data.organisationName}`, { state: { data: null }, replace: true })
                  }
                })
              }
            }
            else { // If role is either VENDOR or SUPERADMIN
              const logindata = res.data; // Get login data
              dispatch(setLogin({// Dispatch login action
                token: logindata?.accessToken,
                name: logindata?.name,
                email: logindata?.data.email,
                roles: logindata?.data.role.name,
                allData: "",
                userData: logindata?.data,
                companyName: "",
                workSpaces: [],
                orgId: logindata?.data?.orgId,
                orgLogo: "",
                mobNum: logindata?.data.phone,
                profilePic: "",

              }))

              if (logindata?.data.role.name === "VENDOR") { // Check if role is VENDOR
                navigate(`/vendor`, { state: { data: null }, replace: true }); // Navigate to vendor page
              } else {
                navigate(`/superadmin`, { state: { data: null }, replace: true });  // Navigate to superadmin page
              }
            }
          }
        }).catch((err) => { // Handle errors
          if (err?.response?.status === 404) {
            closeBackdrop();// Close backdrop
            setErrorMessage('User not found'); // Set error message
          } else if (err?.response?.status === 400) {
            closeBackdrop();// Close backdrop
            setErrorMessage('Email or password is incorrect'); // Set error message
          } else if (err?.response?.status === 403) {
            closeBackdrop();// Close backdrop
            setErrorMessage('Your account is yet to be approved. Please contact admin');// Set error message
          } else if (err?.response?.status === 409) {
            closeBackdrop();// Close backdrop
            setErrorMessage('Your account is Disabled. Please contact admin'); // Set error message
          } else if (err?.response?.status === 420) {
            closeBackdrop();// Close backdrop
            setErrorMessage('Kindly Complete the Registration Process');// Set error message
          } else {
            closeBackdrop();// Close backdrop
            setErrorMessage('An unexpected error occurred');// Set error message
            console.log(err);// Log error for debugging
          }
        })
      console.log('Form is valid. Submitting...');// Log successful form submission
    } else {
      closeBackdrop();// Close backdrop
      console.log('Form has errors. Cannot submit.'); // Log form errors
    }
  };




  return (
    <div className={LoginStyle.mainPage}>

      {errorMessage &&
        <Alert severity="error" sx={{ position: 'absolute', top: '12%', width: '25%', justifyContent: 'left', border: '2px solid #5F2120' }}>{errorMessage}!</Alert>}
      {/* {!logedIn &&  */}
      <div className={LoginStyle.container}>

        <div className={LoginStyle.welcome}><h3>Welcome back! <span>&#128075;</span></h3></div>
        <form action='/'
          onSubmit={handleSubmit}
          className={formStyle.inputForm} >
          <div className={formStyle.inputRow}>

            <input
              ref={emailRef}
              type="email"
              placeholder="Enter your email"
              autoComplete="off"
              name='email'
              value={data.email}
              onChange={handleInputField}
              onBlur={handleValidation}

            />
            <span>{formErrors.email}</span>
          </div>
          <div className={formStyle.inputRow} >
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter Your Password"
              autoComplete="off"
              name='password'
              value={data.password}
              onChange={handleInputField}
              onBlur={handleValidation}

            />
            <div className={LoginStyle.VisiblePass}>
              {showPassword ? (
                <VisibilityOff onClick={() => setShowPassword(false)} />
              ) : (
                <Visibility onClick={() => setShowPassword(true)} />
              )}
            </div>
            <span>{formErrors.password}</span>
          </div>
          <div className={formStyle.forgotPasswordLink}>
            <Link to="/forgotPass">Forgot Password?</Link>
          </div>

          <button type='submit' className={formStyle.formPrimarySubmit}>
            Login
          </button>

        </form>

        <p className={LoginStyle.registerLink}>Don't have an account? <Link to="/procureregister">Sign up</Link> </p>


      </div>
      {/* } */}
      <BackdropComponent />
    </div>
  )
}

export default Login


/*This login page component collects and validates user credentials, then authenticates the user via an API call.
 It handles state and navigation using React hooks, React Router, and Redux, and includes features like password encryption and visibility toggle. 
 The component also provides conditional rendering and error handling based on the user's authentication status and role.*/