import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import revisionEditStyle from 'pages/RevisionEdit/RevisionEdit.module.css'
import useBackdrop from 'hooks/useBackdrop'
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatRow from 'components/ChatRows/ChatRow';
import TempleteDescriptionTab from './TempleteDescriptionTab';
import TempleteDucumentsTab from './TempleteDocumentsTab';
import TempleteQuestionsTab from './TempleteQuestionsTab'
import TaskStyle from "pages/TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import { createExcel } from 'utility/excelUtils'
// =======================icons===========================

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// =======================icons===========================

// =====================mui compoents====================
import Tooltip from '@mui/material/Tooltip';
import { Typography, Button, Badge } from '@mui/material';
import Modal from '@mui/material/Modal';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import useToast from 'hooks/useToast';
import axios from 'axios'
import BackButton from 'components/BackButton/BackButton'



// =====================mui compoents====================

const CommercialDetails = () => {

  const { showToast, Toast } = useToast(); // Destructuring showToast and Toast from useToast hook
  const privateAxios = PrivateComponent(); // Getting an instance of privateAxios from PrivateComponent
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Destructuring backdrop functions from useBackdrop hook
  const location = useLocation(); // Getting current location from react-router-dom
  const { state } = location; // Destructuring state from location
  const { taskId, tempId, templeteName, revisionId, productId, templateId, orgId, revId } = useParams(); // Destructuring parameters from URL
  const navigate = useNavigate(); // Getting navigate function from react-router-dom
  const { auth } = useAuth(); // Getting auth data from useAuth hook
  const [revisionDetails, setRevisionDetails] = useState(null); // Initializing state for revision details


  // ================Back Button========

  // Back Button handler
  const handleBackButton = () => {
    navigate(-1); // Navigates to the previous page
  };


  // ==============tab selector and state=================

  // Tab selector and state
  const searchParams = new URLSearchParams(location.search); // Parsing URL search parameters
  const [tabActive, setTabActive] = useState("0"); // Initializing state for active tab
  useEffect(() => {
    if (searchParams.get("tab") !== undefined && searchParams.get("tab") !== null) {
      setTabActive(searchParams.get("tab"));// Setting active tab from URL parameters
    }
  }, [Location, searchParams]);// Running effect when location or searchParams change

  const handleTabChange = (index) => {
    setTabActive(index); // Setting active tab
    navigate(`?tab=${index}`, { replace: true }); // Updating URL without adding to history
  }


  // =============header three dot=============

  // Header three dot dropdown state
  const [headerThreeDot, setHeaderThreeDot] = useState(false)
  const handleheaderThreeDot = () => {
    setHeaderThreeDot(prev => !prev);// Toggling header three dot dropdown
  }

  // =================multisave ================

  // Multi-save state
  const [multiSave, setMultiSave] = useState(false);
  const handleMultiSave = () => {
    setMultiSave(prev => !prev); // Toggling multi-save state
  }


  // ==================chat state =================

  // Chat state
  const [chatSlider, setChatSlider] = useState(false);
  const [revisionChats, setRevisionChats] = useState([]);
  const [chatValue, setChatValue] = useState("");

  const handleChatSlider = () => {
    setChatSlider(prev => !prev);// Toggling chat slider state
  }

  const handleChatSend = async (e) => {
    e.preventDefault();// Preventing default form submission
    if (chatValue !== "") {
      try {
        const res = await privateAxios.post(`/commercial/addRemarkOncommercialRevision/${auth.userData.id}/${revisionId}`, { remark: chatValue })
        if (res.status === 200) {
          getRevisionChat(); // Fetching updated chat
          setChatValue(""); // Resetting chat input field
        }
      } catch (error) {
        console.log(error);
        showToast("error", "error adding Comment");// Showing error toast
      }
    }

  }


  const getRevisionChat = async () => {
    handleChatNotification(); //Handling chat notification
    try {
      const res = await privateAxios.get(`/commercial/getRemarksOfcommercialRevision/${revisionId}`)
      setRevisionChats(res.data);// Setting revision chats
    } catch (error) {
      console.log(error);
      showToast("error", "error getting Chats");// Showing error toast
    }
  }

  useEffect(() => {
    if (chatSlider) {
      getRevisionChat();// Fetching revision chat when chat slider is open
    }
  }, [chatSlider])// Running effect when chatSlider changes

  // ==================chat state =================

  // ==================rename  state =================

  // Rename state
  const [renameText, setRenameText] = useState("");
  const [saveAs, setSaveAs] = useState(false);
  const [renameModal, setRenameModal] = useState(false);

  const handleRenameModal = () => {
    setRenameModal((prev) => !prev); // Toggling rename modal state
    setRenameText(revisionDetails?.name || ""); // Setting rename text to current revision names
  }

  // ==================rename  state =================


  // ============document states=========================

  // Document states
  const [documents, setDocuments] = useState([]);
  const [addDocumentModal, setAddDocumentModal] = useState(false);
  const handleAddDocumentModal = () => {
    setAddDocumentModal(prev => !prev);// Toggling add document modal state
  }
  const handleDocumentUpload = async (documentName, documentFiles, docType) => {

    try {
      const fd = new FormData();
      for (let i = 0; i < documentFiles.length; i++) {
        fd.append(`files`, documentFiles[i]);// Appending document files to form data
      }
      fd.append("name", documentName); // Appending document name to form data
      fd.append("docType", docType ? "CONFIDENTIAL" : "NORMAL"); // Appending document type to form data
      fd.append("uploadedBy", auth.roles); // Appending uploader role to form data
      const res = await privateAxios.post(`/procureadmin/uploadDocumentsOnTemplate/${templateId}`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      const { data } = res;
      await getDataProcure();// Fetching updated data
      showToast("success", "Document Uploaded Successfully"); // Showing success toast
    } catch (error) {
      showToast("error", "Error Uploading Document");// Showing error toast
    }


  }

  // ============document states=========================

  // Other states
  const [revisionDescription, setRevisionDescription] = useState("");// Initializing state for revision description

  const [questionRows, setQuestionRows] = useState([]);// Initializing state for question rows

  const handleAddQuestionRow = () => {
    const newRow = {
      id: questionRows[questionRows.length - 1].id + 1,// Generating new ID for the row
      name: "",
      unit: "text",
      value: "",
      remark: "",
      vendorRemark: "",
      expectdAnswer: ""

    }

    setQuestionRows((prev) => {
      return [...prev,newRow];// Adding new row to question rows
    });
  }

  const handleQuestionRowDelete = (id) => {
    setQuestionRows((prev) => {
      return prev.filter((row) => row.id !== id);// Removing row with specified ID
    })

  }
  const handleInputChange = (e, rowIndex) => {
    const { value, name } = e.target;
    setQuestionRows((prev) => {
      return prev.map((row, index) => {
        if (index === rowIndex) {
          if (name === "unit") {
            return {
              ...row,
              [name]: value,
              expectdAnswer: value
            }
          }
          else {
            return {
              ...row,
              [name]: value,

            }
          }

        }
        else {
          return row;
        }
      })
    })
  }


  // ===================Question State========================

  // ===================get data================

  // State variables and setters
  const [isEcomRevision, setIsEcomRevision] = useState(false);
  // Function to fetch data for e-commerce
  const getDataEcom = async () => {
    try {
      // Fetch data from API
      const res = await axios.get(`https://ecom.kjssteel.com/api/GetCommercialData/${productId}`);
      const { data } = res;
      // Set revision details
      setRevisionDetails({
        id: "",
        name: data.name,
        productId: data.productId,
        productName: data.name,
        description: {
          name: data?.productSpecification?.name
        },
        questions: data.questions.map((ques) => {
          return {
            ...ques,
            unit: ques.unit === null || ques.unit === "" || ques.unit === undefined ? "text" : ques.unit === "days" ? "date" : ques.unit,
          }
        }),
        documents: data.documents, // Fix here
      });
 // Set question rows
      setQuestionRows(data.questions);
       // Set documents
      setDocuments(data.productDocuments);
       // Set revision description
      setRevisionDescription(data?.productSpecification?.name)

    } catch (error) {
    }
  }

  // Function to fetch data for procurement
  const getDataProcure = async () => {
    openBackdrop();
    try {
      let url = ""
      // Determine URL based on revisionId
      if (revisionId === undefined || revisionId === null || revisionId === "") {
        url = `/commercial/getDefaultCommercialRevisionByOrganisationIdAndProductId/${orgId}/${productId}`
      }
      else {
        url = `/commercial/getCommercialRevisionByRevisionIdAndProductId/${revisionId}/${productId}`
      }
      // Fetch data from API
      const res = await privateAxios.get(`${url}`)
      const { data } = res;
       // Set revision details
      setRevisionDetails({
        id: data.id,
        name: data.name,
        productId: data.productId,
        productName: data.productName,
        description: {
          name: data?.description?.name
        },
        questions: data.questions.map((ques) => {
          return {
            ...ques,
            unit: ques.unit === null || ques.unit === "" || ques.unit === undefined ? "text" : ques.unit === "days" ? "date" : ques.unit,
          }
        }),
        documents: data.documents, // Fix here
      });
 // Set question rows
      setQuestionRows(data.questions);
       // Set documents
      setDocuments(data.documents);
       // Set revision description
      setRevisionDescription(data?.description?.name)
      closeBackdrop();
    } catch (error) {
      console.log(error);
      closeBackdrop()
    }
  }

// Effect hook to fetch data on component mount
  useEffect(() => {
    if (orgId === undefined || orgId === null || orgId === "") {
      getDataEcom();
      setIsEcomRevision(true);
    }
    else {
      getDataProcure();
    }

  }, [])

  // ===================get data================

  // ===================saveas revisions================

  // Function to handle saving as a revision
  const handleSaveAsRevision = async (e) => {
    openBackdrop();
    e.preventDefault();
    try {
 // Prepare payload for saving
      const updatedQuestions = questionRows.map((ques) => {
        let que = ques;
        delete que.id;
        if (que.unit === "" || que.unit === null || que.unit === undefined) {
          que.unit = "text"
        }
        return que;
      })
      const updatedDocuments = documents.map((doc) => {
        let dd = doc;
        delete dd.id;
        return dd;
      })
      let payload = {}
      if (revisionId === 0) {
        payload = { ...revisionDetails, name: renameText, description: { name: revisionDescription }, questions: updatedQuestions }
      }
      else {
        payload = { ...revisionDetails, name: renameText, description: { name: revisionDescription }, documents: updatedDocuments, questions: updatedQuestions }
      }
      // Post data to API
      const res = await privateAxios.post(`/commercial/addCommercialRevision/${auth.orgId}/${taskId}/${tempId}`, payload)
      const { data } = res;
      handleRenameModal();
      showToast("success", "Revision Saved")
      handleBackButton();
      closeBackdrop();

    } catch (error) {
      showToast("error", "Error Saving Revision")
      handleRenameModal();
      closeBackdrop();
    }
  }

  // ==================save as Revisions========================

  // ==================save Revisions========================

  // Function to handle saving revision
  const handleSaveRevision = async () => {
    openBackdrop(); // Open backdrop for loading indication

    console.log(questionRows)
    try {
     // Remove id from questions before updating
      const updatedQuestions = questionRows.map((ques) => {
        let que = ques;
        delete que.id;
        return que;
      })
         // Remove id from documents if exists
      const updatedDocuments = documents?.map((doc) => {
        let dd = doc;
        delete dd.id;
        return dd;
      }) || []

       // Prepare payload for updating revision
      const payload = {
        name: revisionDetails.name,
        description: { name: revisionDescription },
        questions: updatedQuestions
      }

 // Send updated revision to server
      const res = await privateAxios.post(`/commercial/updateCommercialRevision/${revisionDetails.id}`, payload)
      const { data } = res;
      showToast("success", "Revision Saved"); // Show success toast
      getDataProcure(); // Refresh data
      closeBackdrop(); // Close backdrop after operation
    } catch (error) {
      showToast("error", "Error Saving Revision"); // Show error toast
      console.log(error); // Log error for debugging
      closeBackdrop(); // Close backdrop after operation
    }
  }
  // ==================save Revisions========================


  // =============change revision Name==============================

  // Function to handle changing template name
  const handleChangeTempleteName = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
       // Send request to update template name
      const res = await privateAxios.post(`/procureadmin/renameTemplate/${templateId}/${renameText}`)
      if (res.status == 200) {
        getDataProcure(); // Refresh data
        showToast("success", "Name Update Successfull"); // Show success toast
        handleRenameModal(); // Close rename modal
      }
    } catch (error) {
      showToast("error", "Name Update failed");// Show error toast
    }
  }

  // =============change revision Name==============================

  // ============duplicate revision==============================

  // Function to handle duplication of revision
  const [duplicateNameModal, setDuplicateNameModal] = useState(false);
  const [duplicateName, setDuplicateName] = useState("");
  const handleDuplicateNameModal = (e) => {
    setDuplicateNameModal(prev => !prev);
    setDuplicateName(revisionDetails?.name || "");
  }

  const handleDuplicateRevisison = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
        // Send request to duplicate template
      const res = await privateAxios.post(`/procureadmin/duplicateTemplate/${templateId}?name=${duplicateName}`);
      showToast("success", "Duplicate Revision Created"); // Show success toast
      handleBackButton(); // Navigate back
      handleDuplicateNameModal(); // Close duplicate modal
    } catch (error) {
      showToast("error", "Error Creating Revision Duplicate"); // Show error toast
      handleDuplicateNameModal(); // Close duplicate modal
    }

  }
  // ============duplicate revision==============================

  // ================delete Revision================================

  
  const [deleteModal, setDeleteModal] = useState(false);
  const handleDeleteModal = () => {
    setDeleteModal(prev => !prev)
  }

  // Function to handle deletion of revision
  const handleDeleteRevision = async () => {
    try {
         // Send request to delete template
      const res = privateAxios.delete(`/procureadmin/DisableTemplate/1/${templateId}`);
      console.log(res.data); // Log response data
      handleBackButton(); // Navigate back
    } catch (error) {
      showToast("error", "Error deleting Revision");// Show error toast
    }
  }

  // ================delete Revision================================


  // =================download revision==============================

  // Function to handle downloading revision
  const handleDownloadRevision = () => {
    // extracting text from Richeditor string
    let tempElement = document.createElement('div');
    tempElement.innerHTML = revisionDescription;
    let extractedText = tempElement.textContent;
    extractedText = extractedText.trim();

    // taking nessarry feilds from attributes
    const questions = questionRows.map((attri) => {
      const modifiedAttri = { ...attri };
      delete modifiedAttri.id;
      if (revisionId !== 0) {
        delete modifiedAttri.revisedValue;
        delete modifiedAttri.vendorRemark;

      }
      return { ...modifiedAttri };

    });

      // Create revision document data
    let revisionDocument = []
    if (revisionId === "0") {

      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })

    }
    else {
      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })
    }

     // Create Excel with revision data
    createExcel([[{ description: extractedText }], questions, revisionDocument], (revisionDetails.name || "Revision 0"))
  }


  // =================download revision==============================


  // ==============send to Kam=====================================
  const [comments, setComments] = useState("")
  const [organisationId, setOrganisationId] = useState("")
  const [formError, setFormError] = useState({})
  const [sendToOrganisationModal, setSendToOrganisationModal] = useState(false);
  
  const handleSendToOrganisationModal = (data) => {
    setSendToOrganisationModal(prev => !prev)
  }

  // Function to handle sending revision to organisation
  const handleSendToOrganisationSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
      // Send template to organization
      await privateAxios.post(`/procureadmin/sendTemplateToOrganization/${templateId}/${organisationId}`, { comments: comments }).then((res) => {
        handleSendToOrganisationModal()
        showToast("success", "Revision Sent to Organisation"); // Show success toast
      }).catch((err) => {
        showToast("error", "Error Sending to Organisation ");// Show error toast
      })
    } catch (error) {
      showToast("error", "Error Sending to Organisation"); // Show error toast
    }
  }
  // ==============send to Kam=====================================


  // =====================fetch approved Organsiation========================

  // State variable to store approved organizations
  const [approvedOrgs, setApprovedOrgs] = useState([]);

  // Function to fetch approved organizations
  const getApprovedOrgs = async () => {
    openBackdrop();// Open backdrop for loading indication
    try {
         // Fetch approved organizations from API
      const res = await privateAxios.get(`/procureadmin/getAllApprovedOrganisations`);
      setApprovedOrgs(res.data);// Set approved organizations in state
    } catch (error) {
      showToast("error", "Error Fetching Approved Orgs");// Show error toast
    }
    closeBackdrop();// Close backdrop after operation
  }

  // Effect hook to fetch approved organizations when sendToOrganisationModal changes
  useEffect(() => {
    if (sendToOrganisationModal) {
      getApprovedOrgs();// Fetch approved organizations
    }
  }, [sendToOrganisationModal])

  // =====================fetch approved Organsiation========================


  // =====================chat notification========================

  // Function to toggle re-render state for chat notification
  const [reRender, setReRender] = useState(false);

  const handleChatNotification = () => {
    setReRender(prev => !prev);// Toggle re-render state for chat notification
  }


  return (
    <>
      <BackdropComponent />
      <Toast />

      <div className={workSpaceStyle.workSpaceMain}>

        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1> <BackButton />{revisionDetails?.name || "No Name"}</h1>
            <p>{revisionDetails?.productName || "Dafault"}</p>

          </div>

          <div className={workSpaceStyle.workSpaceHeaderRight}>

            {!isEcomRevision &&
              <>
                {tabActive === "2" && <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative" }}>
                  <button onClick={handleAddDocumentModal}><AddIcon />Add</button>
                </div>}
                <div className={workSpaceStyle.multiSaveButton} style={{ width: "auto" }}>
                  <button onClick={handleDownloadRevision} style={{ padding: "0px 10px" }}>
                    <span >
                      <FileDownloadIcon style={{ color: "white" }} />
                    </span>
                    Download
                  </button>
                </div>
              </>
            }


            <div className={workSpaceStyle.workSpaceThreeDotVertical} style={{ position: "relative" }}>
              <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={handleheaderThreeDot}>
                <Tooltip title='more'><MoreVertIcon /></Tooltip>
              </span>
              {
                headerThreeDot
                &&
                <ThreeDotDropDown toggle={handleheaderThreeDot}>

                  {!isEcomRevision &&
                    <li onClick={() => { handleSaveRevision(); handleheaderThreeDot() }}>
                      <span><ContentCopyIcon /></span>Update & Save </li>
                  }
                  <li onClick={() => { handleDownloadRevision(); handleheaderThreeDot() }}>
                    <span><FileDownloadOutlinedIcon /></span>Download</li>

                </ThreeDotDropDown>
              }
            </div>

            <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
              <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
            </div>
          </div>

        </div>

        <div className={revisionEditStyle.tabHeaderMain}>
          <div
            onClick={() => handleTabChange("0")}
            className={tabActive === "0" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Description</div>
          <div
            onClick={() => handleTabChange("1")}
            className={tabActive === "1" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Questions</div>
          <div
            onClick={() => handleTabChange("2")}
            className={tabActive === "2" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Documents</div>
        </div>

        <div className={revisionEditStyle.tabBodyMain} >
          {tabActive === "0" && <TempleteDescriptionTab setRevisionDescription={setRevisionDescription} revisionDescription={revisionDescription} />}
          {tabActive === "1" && <TempleteQuestionsTab questionRows={questionRows} handleInputChange={handleInputChange} handleAddQuestionRow={handleAddQuestionRow} handleQuestionRowDelete={handleQuestionRowDelete} />}
          {tabActive === "2" && <TempleteDucumentsTab addDocumentModal={addDocumentModal} handleAddDocumentModal={handleAddDocumentModal} getDataProcure={getDataProcure} documents={documents} handleDocumentUpload={handleDocumentUpload} />}

        </div>

      </div>

      <Modal
        open={renameModal}
        onClose={handleRenameModal}
      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={!saveAs ? handleChangeTempleteName : handleSaveAsRevision} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}>
                {!saveAs ? "Rename" : "Save As"}</h3>
              <div onClick={handleRenameModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <input type="text" value={renameText} onChange={(e) => setRenameText(e.target.value)} placeholder='Revision Name' />
            </div>

            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>
              <p onClick={() => { setRenameText(""); handleRenameModal() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>
          </form>

        </div>
      </Modal>
      {/* End Rename Modal  */}

      {/* duplicate Revision */}
      <Modal
        open={duplicateNameModal}
        onClose={handleDuplicateNameModal}
      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={handleDuplicateRevisison} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><PersonAddAltOutlinedIcon /></span>Rename</h3>
              <div onClick={handleDuplicateNameModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <input type="text" value={duplicateName} onChange={(e) => setDuplicateName(e.target.value)} placeholder='Rename Name' required />
            </div>

            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>
              <p onClick={() => { setDuplicateName(""); handleDuplicateNameModal() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

          </form>

        </div>
      </Modal>

      {/* duplicate Revision */}

      {/* chat pannel */}

      <ChatPannel
        open={chatSlider}
        onClose={handleChatSlider}
      >
        <>
          <ChatHeader onClose={handleChatSlider}
            downloadData={revisionChats.map((chat) => { return { name: chat.name, date: chat.createdAt, chat: chat.remark } })
            }
            helpData={{ link: "tosomewhere" }}
            chatName={revisionDetails?.name}
          >
            <h3>{revisionDetails?.name}</h3>
          </ChatHeader>

          <ChatRows>
            {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
              return (

                <ChatRow key={index} name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} email={rev.email} />
              )
            })
              :
              <p style={{ textAlign: "center" }}>
                No Previous Chat
              </p>
            }

          </ChatRows>

          <ChatInputField onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

        </>
      </ChatPannel>

      {/* end chat pannel */}

      {/* delete Confirmaton */}
      <Modal
        open={deleteModal}
        onClose={handleDeleteModal}
      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', width: '375px' }}>
          <form action="" style={{ maxWidth: '100%' }} >

            <div className={TaskStyle.modalKam}>
              <Typography variant="h1">Delete Document</Typography>
              <Typography variant="h6">Are you sure you want to delete this Revision?</Typography>

              <div className={TaskStyle.kamModalButton}>
                <Button onClick={() => { handleDeleteRevision(); handleDeleteModal() }} variant="contained">Delete</Button>

                <p onClick={handleDeleteModal}>Cancel</p>
              </div>

            </div>

          </form>
        </div>
      </Modal>

      {/* delete Confirmaton */}

      {/* approval mOdal */}

      <Modal
        open={sendToOrganisationModal}
        onClose={handleSendToOrganisationModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={handleSendToOrganisationSubmit} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}>
                <span style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                  <PersonAddAltOutlinedIcon /></span>Send To Organisation</h3>
              <div onClick={handleSendToOrganisationModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <label htmlFor=""> Orgnisation:</label>
              <select name="orgnisations" id="" onChange={(e) => setOrganisationId(e.target.value)} value={organisationId}  >
                <option value="">Select Organisation</option>

                {approvedOrgs.map((org) => {
                  return (
                    <option value={org.id}>{org.kamEmail}</option>
                  )
                })}

              </select>
            </div>

            <div className={formStyle.inputRow}>
              <label htmlFor=""> Comments if Any:</label>
              <textarea style={{ paddingTop: "10px", height: "120px" }} onChange={(e) => setComments(e.target.value)} value={comments}></textarea>
              <span>{formError.workSpace}</span>
            </div>

            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "40px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Send </button>
              <p onClick={() => { setComments(""); handleSendToOrganisationModal() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

            <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span>
          </form>

        </div>
      </Modal>

    </>
  )
}

export default CommercialDetails
