import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import styles from './TechnicalRevisionView.module.css'
import BackButton from 'components/BackButton/BackButton';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import Tooltip from '@mui/material/Tooltip';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useAuth from 'hooks/useAuth';
import { PrivateComponent } from 'api/axios';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import AttributeTab from 'components/TechnicalSpecificationTabs/AttributeTab/AttributeTab';
import SpecificationTab from 'components/TechnicalSpecificationTabs/SpecificationTab/SpecificationTab';
import DocumentsTab from 'components/TechnicalSpecificationTabs/DocumentTab/DocumentTab';
import RenameModal from 'components/RenameModal/RenameModal';
import SendForApprovalModal from 'components/SendForApprovalModal/SendForApprovalModal';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatRow from 'components/ChatRows/ChatRow';
import SendToVendorModal from 'components/SendToVendorModal/SendToVendorModal';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { Modal } from '@mui/material';
import EditTaskPannel from 'pages/EditCreateTask/EditTaskPannel/EditTaskPannel';
import { createExcel } from 'utility/excelUtils'


const TechnicalRevisionView = () => {
    const { showToast, Toast } = useToast();//Hook for toast notifications
    const { taskId, productId, revisionId } = useParams();// param from URL
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();//Hook for managing backdrop
    const { auth } = useAuth(); //Authentication hook
    const privateAxios = PrivateComponent(); //Axios instance for private API calls
    const location = useLocation();//Location hook for getting the current URL
    const { state } = location;//State from location 
    const navigate = useNavigate();//Hook for navigation 
    const searchParams = new URLSearchParams(location.search);
    const [isDefaultRevision, setIsDefaultRevision] = useState(false);
    const [viewType, setViewType] = useState("");// State for view type



    // ===========================set View Type=============================

    useEffect(() => {
        if (state) {
            setViewType(state.from)
        }
    }, [state])


    //============================button states===============================

    const [workSpaceHeaderRevisionMore, setworkSpaceHeaderRevisionMore] = useState(false);
    const [multiSave, setMultiSave] = useState(false);

    const handleMultiSave = () => {
        setMultiSave(prev => !prev)
    }



    // =============================Modal States============================

    // Modals state management
    const [renameModal, setRenameModal] = useState(false);// State for rename modal
    const handleRenameModal = () => {// Function to toggle rename modal
        setRenameModal(prev => !prev)
    }

    const [approvalModal, setApprovalModal] = useState(false);// State for approval  modal
    const handleApprovalModal = () => {// Function to toggle approval  modal
        setApprovalModal(prev => !prev)
    }

    const [deleteModal, setDeleteModal] = useState(false);// State for delete modal
    const handleDeleteModal = () => {// Function to toggle delete modal
        setDeleteModal(prev => !prev)
    }

    const [duplicateNameModal, setDuplicateNameModal] = useState(false);// State for duplicate name modal
    const handleDuplicateNameModal = () => {// Function to toggle duplicate name modal
        setDuplicateNameModal(prev => !prev)
    }

    const [saveAsModal, setSaveAsModal] = useState(false);// State for save as modal
    const handleSaveAsModal = () => {// Function to toggle save as modal
        setSaveAsModal(prev => !prev)
    }

    const [vendorModal, setVendorModal] = useState(false);// State for vendor modal
    const handleSendToVendorModal = (data) => {// Function to toggle vendor modal
        setVendorModal(prev => !prev)
    }



    const [reassignedData, setReassignedData] = useState({});// State for reassigned data
    const [reassignModal, setReassignModal] = useState(false);// State for reassign modal


    const handleReassignModal = () => {// Function to toggle reassign modal
        setReassignModal(prev => !prev)
    }


    // ==================Unauthorized Access========================

    // Unauthorized Access state management
    const [loading, setLoading] = useState(true); // State for loading indicator
    const [showUnAuthorizedToast, setShowUnAuthorizedToast] = useState(false);// State for unauthorized access toast


    // ==================Active Tab========================

    // Active Tab state management
    const [tabActive, setTabActive] = useState("0");// State for active tab

    // Effect to update active tab based on URL parameter
    useEffect(() => {
        if (searchParams.get("tab") !== null && searchParams.get("tab") !== undefined && searchParams.get("tab") !== "") {

            setTabActive(searchParams.get("tab"));
        }
    }, [Location, searchParams])

    // Function to handle tab change
    const handleTabChange = (index) => {

        setTabActive(index)
        navigate(`?tab=${index}`, { replace: true })
    }

    // ==================Product Details========================


    // Product Details state management
    const [specificationValue, setSpecificationValue] = useState("");// State for product specification value
    const [productDetails, setProductDetails] = useState({});// State for product details
    const [documents, setDocuments] = useState([]);// State for documents
    const [tableRows, setTableRows] = useState([]); // State for table rows
    const [columns, setColumns] = useState([]);// State for columns
    const [duplicateRow, setDuplicateRow] = useState([]);// State for duplicate rows

    // Function to handle attribute validation
    const handleAttributeValidation = (type) => {
        // Check for null attribute names
        const hasNullName = tableRows.some(rr => rr.name === null);

        if (hasNullName) {
            alert("please check Attribute Name No Name can be empty");
            return false;
        }
        else {
            // Check for duplicate attribute names
            const nameOccurrences = {};
            const duplicateNamesId = [];
            tableRows.forEach((item) => {
                const name = item.name;
                // Check if the name has been encountered before
                if (nameOccurrences[name]) {
                    // If it has, mark it as a duplicate
                    duplicateNamesId.push(item.id);
                } else {
                    // Otherwise, record it in the occurrences object
                    nameOccurrences[name] = true;
                }
            });
            if (duplicateNamesId.length > 0) {
                // console.log(duplicateError)
                setDuplicateRow(duplicateNamesId)
                if (type === "check") {

                    alert("Please Remove Duplicate Attributes");
                    return false;
                }
                else {
                    return duplicateNamesId
                }
            }
            else {
                setDuplicateRow([]);
                return true;
            }

        }
    }

    // Function to delete all duplicate rows
    const deleteAllDuplicateRows = () => {
        const ids = handleAttributeValidation();
        if (ids.length > 0) {

            const newRows = tableRows.filter((item) => !ids.includes(item.id));
            setTableRows(newRows);
        }
    }


    // Function to build rows
    const buildRows = async (data) => {
        openBackdrop()
        try {
            // Processing attributes data
            const attributes = data.map((dd) => {
                let addFields = dd.additionalFields;
                if (typeof addFields === 'string' && addFields !== "{}") {
                    try {
                        addFields = JSON.parse(addFields);
                    } catch (error) {
                        // console.log("Error parsing additionalFields:", error);
                        addFields = [];
                    }
                }
                else {
                    addFields = [];
                }
                return {
                    ...dd,
                    additionalFields: addFields,
                };
            });

            // Handling additional columns
            const maxAdditionalColumn = attributes[0].additionalFields?.length;
            if (maxAdditionalColumn > 0) {
                let newColumnNames = attributes[0].additionalFields.map((col) => {
                    return {
                        id: col.columnId,
                        name: col.columnName,
                        createdBy: col.createdBy,
                    }
                });
                const newColumns = [
                    ...newColumnNames,

                ];
                setColumns(newColumns);
            }
            setTableRows(attributes);

            closeBackdrop()
        } catch (error) {
            console.error("Error Sorting Data:", error);
            showToast("error", "Error Sorting Data")
        }
        closeBackdrop();
    };

    // Function to get data
    const getData = async () => {

        try {

            // TASK ID CHECK IS REMAINING BUT WE THOUGHT ABOUT IT.
            const response = await privateAxios.get(`/technicalSpecification/getRevisionByRevisionIdAndProductId/${revisionId}/${productId}`);
            let data = response.data;
            setProductDetails(data)
            buildRows(data.productAttributes);
            setSpecificationValue(data.productSpecification?.name);
            setDocuments(data.productDocuments);
            setIsDefaultRevision(data.default);

        } catch (error) {

        }
    }
    useEffect(() => {
        getData();
    }, [])

    // ==================Task Profiles========================

    // Task Profiles state management
    const [taskProfiles, setTaskProfiles] = useState([]);

    // Function to get task details
    const getTaskDetails = async () => {
        try {
            await privateAxios.get(`/task/getTaskById/${taskId}`).then(res => {
                setTaskProfiles(res.data.assignTo);
                setReassignedData(res.data);
            })
        } catch (error) {
            // console.log(error)
        }
    }
    useEffect(() => {
        getTaskDetails();
    }, [])

    // ==================Task Profiles========================

    // ==================Chat Slider========================

    // Chat Slider state management
    const [chatSlider, setChatSlider] = useState(false)
    const [chatValue, setChatValue] = useState("");
    const [revisionChats, setRevisionChats] = useState([]);

    // Function to handle chat slider
    const handleChatSlider = () => {
        setChatSlider(prev => !prev)

    }
    useEffect(() => {

        if (chatSlider === true) {

            getRevisionChats()
        }

    }, [chatSlider])

    // Function to handle chat send
    const handleChatSend = async (e) => {
        e.preventDefault()
        try {
            if (chatValue.length > 0) {

                await privateAxios.post(`/technicalSpecification/addRemarkOnRevisionByEmployee/${auth.userData.id}/${revisionId}`, { remark: chatValue }).then(res => {
                    // console.log(res);
                    getRevisionChats();
                    setChatValue("")

                })
            }
        } catch (error) {
            // console.log(error)
        }

    }

    // Function to get revision chats
    const getRevisionChats = async () => {
        try {
            // await privateAxios.post('/technicalSpecification/revisionRemarkNotification/{remarkid}')
            await privateAxios.get(`/technicalSpecification/getRemarksOfRevision/${revisionId}`).then(res => {
                setRevisionChats(res.data)
                handleChatNotification();
            })
        } catch (error) {
            // console.log(error)
        }
    }

    const [reRender, setReRender] = useState(false);// State for re-rendering component

    // Function to handle chat notification
    const handleChatNotification = () => {
        setReRender(prev => !prev);

    }


    // Document Data state management
    const [addDocumentModal, setAddDocumentModal] = useState(false);

    // Function to handle add document modal
    const handleAddDocumentModal = () => {
        setAddDocumentModal(prev => !prev);
    }


    // Function to handle save as revision
    const handleSaveAsRevison = async (data) => {
        // console.log("handle save revsion")

        openBackdrop()
        try {

            if (handleAttributeValidation("check")) {


                const updatedtableRow = tableRows.map((row) => {
                    const { id, status, ...rest } = row;
                    return {
                        ...rest,
                        additionalFields: JSON.stringify(row.additionalFields),
                        createdBy: "KAM"
                    };
                });

                const updatedDocuments = documents.map(dd => {
                    const tr = dd;
                    delete tr.id;
                    return dd;
                });

                const requestPayload =
                {
                    name: data.name,
                    productName: productDetails.productName,
                    productSpecification: { name: specificationValue },
                    productAttributes: updatedtableRow,
                    productDocuments: updatedDocuments,
                    productId: productDetails.productId,
                    type: productDetails.type,

                }

                const res = await privateAxios.post(`/technicalSpecification/addTSRevision/${auth.orgId}/${taskId}`, requestPayload).then((res) => {
                    alert("Revision has been created ")

                    navigate(-1)


                })
            }


            else {
                handleSaveAsModal();

            }
            closeBackdrop();
        } catch (error) {
            // console.log(error)
            showToast("error", "Error Creating Revision")
            closeBackdrop();
        }

    }

    // Function to handle saving revision
    const handleSaveRevision = async () => {
        openBackdrop(); // Open backdrop for loading indicator
        try {
            // Check attribute validation before saving
            if (handleAttributeValidation("check")) {
                // Update table rows and documents data
                const updatedtableRow = tableRows.map((row) => {
                    const { id, status, ...rest } = row;
                    return {
                        ...rest,
                        additionalFields: JSON.stringify(row.additionalFields),
                        createdBy: "KAM"
                    };
                });

                const updatedDocuments = documents.map(dd => {
                    const tr = dd;
                    delete tr.id;
                    return dd;
                });

                // Prepare request payload
                const requestPayload =
                {
                    name: productDetails.name,
                    productName: productDetails.productName,
                    productSpecification: { name: specificationValue },
                    productAttributes: updatedtableRow,
                    // productDocuments: updatedDocuments,
                    productId: productDetails.productId,
                    type: productDetails.type,

                }
                // console.log(requestPayload)
                await privateAxios.post(`/technicalSpecification/updateRevision/${revisionId}`, requestPayload)
                // Get updated data
                await getData();
                showToast("success", "Revision Saved")// Show success toast
            }


        } catch (error) {
            showToast("error", "Error Saving Revision")// Show error toast
        }
        closeBackdrop();// Close backdrop
    }


    // ======================Rename Revision====================


    // Function to handle renaming revision
    const handleChangeRevisionName = async (data) => {
        openBackdrop();
        // console.log(productDetails.id)
        try {
            // Check if it's not the default revision before renamings
            if (!isDefaultRevision) {
                // Send post request to rename revision
                await privateAxios.post(`/technicalSpecification/renameRevision/${productDetails.id}`, { name: data.name })
                // Get updated data
                await getData();
                showToast("success", "Revision Renamed"); // Show success toast
            }

            else {
                showToast("error", "Error while Rename Revision DefaultRevsion can't be renamed");// Show error toast
            }


        } catch (error) {
            showToast("error", "Error while Rename Revision")

        }
        handleRenameModal();// Close rename modal
        closeBackdrop();
    }


    // ======================Rename Revision====================


    // ======================Duplicate Revision====================


    // Function to handle duplicating revision
    const handleDuplicateRevisison = async (data) => {
        openBackdrop();
        try {
            // Send post request to duplicate revision
            await privateAxios.post(`/technicalSpecification/duplicateRevision/${productDetails.id}?name=${data.name}`).then((res) => {
                navigate(-1);// Navigate back
            })
        } catch (error) {
            showToast("error", "Error Duplicating Revision"); // Show error toast
        }
        closeBackdrop();
    }



    // ======================Duplicate Revision====================


    // =====================Delete Revision===============================

    // Function to handle deleting revision
    const handleRevisionDelete = async () => {

        openBackdrop();
        try {
            // Check if revision ID is not null before deleting
            if (productDetails.id !== null) {
                // Send delete request to delete revision
                await privateAxios.delete(`/technicalSpecification/deleteRevision/${productDetails.id}`).then((res) => {
                    // console.log(res);

                    navigate(-1, { replace: true })

                })
            }
        } catch (error) {
            // console.log(error)
            showToast("error", "Error Deleting Revision!")
        }
        closeBackdrop()
    }


    // =====================Delete Revision===============================



    // ====================Send Revision for Approval from user to Kam============

    // Function to handle submitting revision for approval
    const handleAppovalSubmit = async (data) => {

        openBackdrop();
        let URL = ``;
        if (viewType === "APVIEW" && auth.roles === "ADMIN") {
            URL = `/technicalSpecification/sendRevisionForApprovalToKAMFromSUBKAM/${auth.userData.id}/${revisionId}`;
        }
        else {
            URL = `/technicalSpecification/sendRevisionForApprovalToKAM/${auth.userData.id}/${revisionId}`
        }

        try {
            // Send post request to submit revision for approval
            await privateAxios.post(`${URL}`, { comments: data.comment })

            handleApprovalModal();
            showToast("success", "Approval Sent To KAM")


        } catch (error) {
            // console.log(error);
            showToast("error", "Error Sending Data")
        }
        closeBackdrop();
    }


    // ====================Send Revision for Approval from user to Kam============


    // ====================Send Revision to vendor ===========================

    // Function to handle sending revision to vendor
    const handleSendToVendor = async (data) => {
        openBackdrop()
        try {
            // Send post request to send revision to vendor
            const res = await privateAxios.post(`/technicalSpecification/sendRevisionForApprovalToVendor/${auth.userData.id
                }/${revisionId}`, {
                emails: data.emails,
                comments: data.comments,
                dueDate: data.dueDate,
            })
            showToast("success", "Revision Sent to Vendor")
            handleSendToVendorModal()
            closeBackdrop();
        } catch (error) {
            showToast("error", "Error while sending Revision to Vendor")
            closeBackdrop();
        }


    }

    // ====================Send Revision to vendor ===========================

    //========================= Decision on Revision=========================

    // Function to handle decision on revision (approve or reject)
    const handleDecisionOnRevision = async (type) => {
        openBackdrop();
        try {
            let URL = ``
            // Determine URL based on decision type
            if (type) {
                URL = `/technicalSpecification/approveRevisionByKam/${revisionId}`

            }
            else {
                URL = `/technicalSpecification/rejectRevisionByKam/${revisionId}`
            }
            // Send post request to approve or reject revision
            const response = await privateAxios.post(`${URL}`);

            if (response.status === 200) {
                // Show success toast based on decision
                if (type) {
                    showToast("success", "Revision Approved")
                }
                else {
                    showToast("success", "Revision Rejected")
                }
            }

            getData(); // Get updated data
            closeBackdrop();
        } catch (error) {
            showToast("error", "Error in the Process")
            closeBackdrop();
        }
    }



    //========================= Decision on Revision========================= 


    // =========================Download Revision==============================]

    // Function to handle downloading revision
    const handleDownloadRevision = () => {
        let tempElement = document.createElement('div');
        tempElement.innerHTML = specificationValue;
        let extractedText = tempElement.textContent;
        extractedText = extractedText.trim();

        // taking nessarry feilds from attributes
        const Attributes = tableRows.map((attri) => {
            const modifiedAttri = { ...attri };
            delete modifiedAttri.id;
            if (revisionId !== 0) {
                delete modifiedAttri.revisedValue;
                delete modifiedAttri.vendorRemark;

            }
            const ttadditionsFields = [];
            attri.additionalFields.forEach((rem, index) => {
                ttadditionsFields[rem.columnName] = rem.columnValue;

            });
            delete modifiedAttri.additionalFields;
            return { ...modifiedAttri, ...ttadditionsFields };

        });

        let revisionDocument = [];
        // Check if it's the default revision to handle documents differently
        if (isDefaultRevision) {
            // Map documents to a different structure if it's the default revision
            revisionDocument = documents.map((doc) => {
                return {
                    name: "Not Available",
                    link: doc.url,
                    type: "normal"
                }
            })

        }
        else {
            // Map documents normally
            revisionDocument = documents.map((doc) => {
                return {
                    name: doc?.name,
                    link: doc.url,
                    type: doc?.type
                }
            })
        }

        // Create Excel with extracted data
        createExcel([[{ Specification: extractedText }], Attributes, revisionDocument], (productDetails.name || "Revision 0"))
    }



    // =========================Download Revision==============================


    // =========================Jump to Row==============================
    
    // Function to handle jumping to a specific row
    const [jumpSearch, setJumpSearch] = useState("");

    const handleSelect = (value) => {
        const selectedValue = value;
        // Find the selected row
        const selectedRow = tableRows.find((row) => row.name === selectedValue);
        if (selectedRow) {
            // Scroll to the selected row
            const selectedRowElement = document.getElementById(`row-${selectedRow.id}`);
            if (selectedRowElement) {
                selectedRowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    };



    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>
                {!showUnAuthorizedToast ?  // Conditionally rendering based on showUnAuthorizedToast state 
                    <>
                        <div className={workSpaceStyle.workSpaceHeader}>
                            <div className={workSpaceStyle.workSpaceHeaderLeft} style={{ maxWidth: "none" }}>
                                <h1>
                                    <BackButton />
                                    {productDetails.name || "Not Name"}</h1>
                                <p>#0{productDetails.id} {productDetails?.productName || "Dafault"}</p>

                            </div>

                            <div className={workSpaceStyle.workSpaceHeaderRight}>

                                {
                                    (viewType === "APVIEW" && auth.roles === "KAM") &&

                                    <div className={workSpaceStyle.workSpaceHeaderDecisionButton}>
                                        {
                                            productDetails.revisionStatus === "PENDING"
                                                ?
                                                <>
                                                    <button className={workSpaceStyle.workSpaceHeaderDecisionApprovalBtn} onClick={() => { handleDecisionOnRevision(true) }}><span><CheckIcon /></span> Approve</button>
                                                    <button className={workSpaceStyle.workSpaceHeaderDecisionRejectBtn} onClick={() => { handleDecisionOnRevision(false) }}><span><CloseIcon /></span>Reject</button>
                                                </>
                                                :
                                                productDetails.revisionStatus === "APPROVED" ?
                                                    <button className={workSpaceStyle.workSpaceHeaderDecisionApprovalBtn}><span><CheckIcon /> </span>Approved</button>
                                                    :
                                                    <button className={workSpaceStyle.workSpaceHeaderDecisionRejectBtn} ><span><CloseIcon /></span>Rejected</button>
                                        }
                                    </div>
                                }

                                {!isDefaultRevision &&
                                    <div className={workSpaceStyle.workSpaceHeaderProfiles}>
                                        {taskProfiles.map((profile, index) => {
                                            return (
                                                <ProfileIcon key={index} data={{ email: profile.email }} height={"26px"} width={"26px"} showProfileBanner={true} />
                                            )
                                        })}

                                    </div>
                                }
                                {!isDefaultRevision && <div className={workSpaceStyle.workSpaceThreeDotVertical}>

                                    <Tooltip title='Comments'>
                                        <span onClick={() => { handleChatSlider(); }}>
                                            <ChatIconWithBadge url={`/technicalSpecification/getNotficationCount`} id={revisionId} reRender={reRender} />
                                        </span>
                                    </Tooltip>

                                </div>
                                }

                                {tabActive === "2" && !isDefaultRevision &&
                                    <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative" }}>
                                        <button onClick={handleAddDocumentModal}><AddIcon />Add</button>
                                    </div>}
                                <div className={workSpaceStyle.multiSaveButton}>
                                    <button onClick={handleMultiSave}>
                                        Save
                                        <span>
                                            {multiSave
                                                ? <KeyboardArrowUpIcon />
                                                : <KeyboardArrowDownIcon />
                                            }
                                        </span>
                                    </button>

                                    {multiSave &&
                                        <ul>
                                            {!isDefaultRevision &&
                                                <li
                                                    onClick={() => { handleSaveRevision(); setMultiSave(false); }}
                                                >Update & Save
                                                </li>
                                            }
                                            <li
                                                onClick={() => { setMultiSave(false); handleSaveAsModal(); }}
                                            >Save as </li>
                                        </ul>
                                    }

                                </div>

                                <div className={workSpaceStyle.workSpaceThreeDotVertical} style={{ position: "relative" }}>

                                    <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={() => setworkSpaceHeaderRevisionMore(prev => !prev)}>
                                        <MoreVertIcon />
                                    </span>
                                    {
                                        workSpaceHeaderRevisionMore &&
                                        <ThreeDotDropDown toggle={() => setworkSpaceHeaderRevisionMore(prev => !prev)} >
                                            {!isDefaultRevision &&
                                                <>
                                                    {
                                                        viewType === "PRVIEW" ?
                                                            <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleApprovalModal(); }}>
                                                                <span><PersonAddAltOutlinedIcon /></span>Send for Approval</li>
                                                            :
                                                            (viewType === "APVIEW" && auth.roles === "ADMIN") ?
                                                                <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleApprovalModal(); }}>
                                                                    <span><PersonAddAltOutlinedIcon /></span>Send for Approval</li>
                                                                :

                                                                <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleSendToVendorModal(); }}>
                                                                    <span><PersonAddAltOutlinedIcon /></span>Send to Vendor</li>

                                                    }


                                                    {
                                                        (viewType === "APVIEW" && auth.roles !== "USER") &&

                                                        <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleReassignModal() }} >
                                                            <span><PersonOutlinedIcon /> </span>Re-assign
                                                        </li>
                                                    }
                                                    <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleRenameModal() }}>
                                                        <span><DriveFileRenameOutlineIcon /></span> Rename
                                                    </li>
                                                    <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleDuplicateNameModal() }}>
                                                        <span><ContentCopyIcon /></span>Duplicate</li>
                                                    <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleDeleteModal(); }}>
                                                        <span><DeleteOutlineIcon /></span>Delete</li>
                                                </>
                                            }
                                            <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); deleteAllDuplicateRows() }}>
                                                <span><DriveFileRenameOutlineIcon /></span>
                                                Delete Duplicate Entry
                                            </li>
                                            <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleDownloadRevision(); }}><span>
                                                <FileDownloadOutlinedIcon />
                                            </span>Download</li>
                                        </ThreeDotDropDown>
                                    }

                                </div>
                                <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
                                    {auth.roles === "KAM" && (
                                        <Tooltip title='Help'>
                                            <a target='_blank' href='https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000080852-approval'>
                                                <HelpOutlineOutlinedIcon />
                                            </a>
                                        </Tooltip>
                                    )}

                                    {auth.roles === "USER" && (
                                        <Tooltip title='Help'>
                                            <a target='_blank' href='https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081386-technical-revision-display'>
                                                <HelpOutlineOutlinedIcon />
                                            </a>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>

                        </div>

                        <div className={styles.tabHeaderMain}>
                            <div className={styles.tabHeaderWapper} >


                                <div
                                    onClick={() => handleTabChange("0")}
                                    className={tabActive === "0" ? `${styles.revisionTab} ${styles.revisionTabActive} ` : `${styles.revisionTab}`}>Specification</div>
                                <div
                                    onClick={() => handleTabChange("1")}
                                    className={tabActive === "1" ? `${styles.revisionTab} ${styles.revisionTabActive} ` : `${styles.revisionTab}`}>Attributes</div>
                                <div
                                    onClick={() => handleTabChange("2")}
                                    className={tabActive === "2" ? `${styles.revisionTab} ${styles.revisionTabActive} ` : `${styles.revisionTab}`}>Documents</div>
                            </div>
                            {
                                tabActive === "1" &&
                                <div className={styles.searchContainer}>
                                    <div className={styles.searchBox}>
                                        <input type="text" placeholder="Search" value={jumpSearch} onChange={(e) => setJumpSearch(e.target.value)} />
                                    </div>

                                    {
                                        jumpSearch.length > 0 &&

                                        <>

                                            <div className={styles.jumpListDropDown}>

                                                <ul className={styles.jumpToRowList}>
                                                    {tableRows.filter((row) => row.name.trim().toLowerCase().includes(jumpSearch.toLowerCase())).map((row) => {
                                                        return (
                                                            <li onClick={() => handleSelect(row.name)}>{row.name}</li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>

                                        </>
                                    }

                                </div>
                            }
                        </div>

                        <div className={styles.tabBodyMain} >

                            {tabActive === "0" && <SpecificationTab

                                value={specificationValue}
                                onChange={setSpecificationValue}
                            />}
                            {tabActive === "1" && <AttributeTab
                                tableRows={tableRows}
                                setTableRows={setTableRows}
                                showToast={showToast}
                                columns={columns}
                                setColumns={setColumns}
                                duplicateRow={duplicateRow}
                                viewType={""} />}
                            {tabActive === "2" && <DocumentsTab
                                isDefaultRevision={isDefaultRevision}
                                documents={documents}
                                setDocuments={setDocuments}
                                addDocumentModal={addDocumentModal}
                                handleAddDocumentModal={handleAddDocumentModal}
                                revisionId={revisionId ?? "2"}
                                getDataProcure={getData}
                                showToast={showToast} />}

                        </div>

                    </>
                    :
                    <>
                        {
                            !loading &&

                            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <h2 style={{ color: "red" }}>Technical Specification Not Available!!</h2>
                                <h3 style={{ color: "red" }}>Contact Admin!!</h3>
                            </div>
                        }
                    </>
                }

            </div >



            <RenameModal
                open={renameModal}
                onClose={handleRenameModal}
                onSubmit={handleChangeRevisionName}
                prefilled={productDetails?.name}
                header="Rename"
            />
            <RenameModal
                open={saveAsModal}
                onClose={handleSaveAsModal}
                onSubmit={handleSaveAsRevison}
                prefilled={productDetails?.name}
                header="Save As"
            />
            <RenameModal
                open={duplicateNameModal}
                onClose={handleDuplicateNameModal}
                onSubmit={handleDuplicateRevisison}
                prefilled={productDetails?.name}
                header="Duplicate"
            />


            <SendForApprovalModal
                open={approvalModal}
                onClose={handleApprovalModal}
                onSubmit={handleAppovalSubmit}
                prefilled={""}
                header="Send For Approval"
            />


            <SendToVendorModal
                open={vendorModal}
                onClose={handleSendToVendorModal}
                header={"Send to Vendor"}
                onSubmit={handleSendToVendor}
            />


            <Modal
                open={reassignModal}
                onClose={handleReassignModal}
            >
                <EditTaskPannel handleEditTaskModal={handleReassignModal} editData={reassignedData} />

            </Modal>



            <DeleteConfirmation
                onCancel={handleDeleteModal}
                onConfirm={() => { handleRevisionDelete(); handleDeleteModal() }}
                open={deleteModal}
            >
                <h2>Delete Revision</h2>
                <p>Are you sure you want to delete this Revision?</p>

            </DeleteConfirmation>


            <ChatPannel
                open={chatSlider}
                onClose={handleChatSlider}
            >
                <>
                    <ChatHeader
                        onClose={handleChatSlider}
                        downloadData={revisionChats.map((chat) => {
                            return { name: chat.name, date: chat.createdAt, chat: chat.remark };
                        })}
                        helpData={{ link: "tosomewhere" }}
                        chatName={productDetails?.name}
                    >
                        <h3>{productDetails?.name}</h3>
                    </ChatHeader>

                    <ChatRows>

                        {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
                            return (

                                <ChatRow key={index} name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} email={rev.email} />
                            )
                        })
                            :
                            <p style={{ textAlign: "center" }}>
                                No Previous Chat
                            </p>
                        }

                    </ChatRows>

                    <ChatInputField onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

                </>
            </ChatPannel>


        </>
    )
}

export default TechnicalRevisionView
