import React, { useEffect, useState } from 'react';
import usersStyle from './Users.module.css'
import UserListComponent from './UserListComponent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const RoleTabComponent = ({ getAllUser,data, role, isOpen, handleTabOpen,workSpace,seachString }) => {
    return (
        <>
            <div className={usersStyle.roleTabHeader} onClick={() => handleTabOpen(role)}>
                {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                <h4>{role} ({data?.length})</h4>
            </div>
            {isOpen && (
                <UserListComponent getAllUser={getAllUser} data={data} usersStyle={usersStyle} seachString={seachString} role={role} workSpace={workSpace} />
            )}
        </>
    );
};

export default RoleTabComponent;