import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import superadminStyle from '../../commonCss.module.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';
import GroupSorterData from 'utility/GroupSorterData.js';
import TableSorterDropDown from 'components/TableSorterDropDown/TableSorterDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import BackButton from 'components/BackButton/BackButton';
import CreateCategoryModal from 'components/CreateCategoryModal/CreateCategoryModal';
import CategorySelector from 'components/CategorySelector/CategorySelector';


const CategoriesView = () => {
    // Hook to access the current location object
    let location = useLocation();
    // Hooks for managing backdrop and toast notifications
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const { showToast, Toast } = useToast();
    // Axios instance with private configurations
    const privateAxios = PrivateComponent();
    // Hook for navigation
    const navigate = useNavigate();
    // State for storing array of IDs
    const [idsArray, setIdsArray] = useState([]);
    // State for storing search value
    const [searchValue, setSearchValue] = useState('');
    // State for storing the open sorter type
    const [sorterOpen, setSorterOpen] = useState("");


    // Function to toggle sorter visibility
    const toggleSorter = (type) => {
        if (sorterOpen === type) {
            setSorterOpen(""); // Close sorter if already open
        } else {
            setSorterOpen(type); // Open sorter
        }
    }


    // Effect to update idsArray based on the current path
    useEffect(() => {
        let pathname = location.pathname;
        let pathArray = pathname.split("/");
        setIdsArray(pathArray.slice(3)); // Set IDs array based on the path
    }, [location]);


    // States for data management
    const [data, setData] = useState([]);
    const [sorterData, setSorterData] = useState({});
    const [sortedData, setSortedData] = useState([]);


    // Function to fetch data from the server
    const getData = async () => {
        openBackdrop();// Open backdrop
        try {
            // Set URL based on whether idsArray is empty
            let URL = idsArray.length == 0 ? `/procurement/getAllCategories` : `/procurement/getAllCategoriesByParent/${idsArray[idsArray.length - 1]}`
            const res = await privateAxios.get(URL);
            setData(res.data);// Set fetched data
            setSortedData(res.data);// Set fetched data
            setSorterData(GroupSorterData(res.data)); // Group data for sorting
        } catch (error) {
            showToast("error", error.message) // Show error toast
        }
        closeBackdrop(); // Close backdrop
    }
    // Effect to fetch data when idsArray changes
    useEffect(() => {
        getData();
    }, [idsArray])


    // =====================handle active org======================


    // Function to handle checkbox change for category activation
    const handleCheckBox = async (id) => {
        openBackdrop();// Open backdrop
        try {
            setData((prev) => {
                return prev.map((val) => {
                    if (val.id === id) {
                        return { ...val, active: !val.active } // Toggle active state
                    }
                    return val;
                })
            })
        } catch (error) {
            showToast("error", error.message) // Show error toast
        }
        closeBackdrop();//Close backdrop
    }


    // ====================add product modal========================

    // States for modals and edit data
    const [createCategoryModal, setCreateCategoryModal] = useState(false);
    const [editCategoryModal, setEditCategoryModal] = useState(false);
    const [editData, setEditData] = useState(null);


    // Function to toggle create category modal
    const handleCreateCategoryModal = () => {
        setCreateCategoryModal(prev => !prev);
    }


    // Function to toggle edit category modal and set edit data
    const handleEditCategoryModal = (data) => {
        setEditCategoryModal(prev => !prev);
        if (data) {

            setEditData(data); // Set edit data
        }

    }

    // Function to handle sorting of data
    const handleSorting = (type, value) => {
        if (value === "All") {
            setSortedData(data); // Show all data
        }
        else {
            setSortedData(data.filter((dd) => dd[type] === value));// Filter data by type and value
        }
    }

    // ===================sorting states==================

    // Function to handle form submission for creating category
    const formSubmit = async (values) => {
        openBackdrop(); // Open backdrop
        try {
            let payload = {
                categoryName: values.name,
                description: values.description,
                level: `${idsArray.length + 1}`,
                parent: idsArray.length === 0 ? null : `${idsArray[idsArray.length - 1]}`,
            }

            const res = await privateAxios.post(`procurement/createCategories`, [payload])
            await getData();// Refresh data
            showToast("success", "Category Added")// Show success toast
        } catch (error) {
            showToast("error", "Error Creating Categories")// Show error toast
        }
        handleCreateCategoryModal(); // Close create category modal
        closeBackdrop(); // Close backdrop
    }

    // Function to handle e-commerce import
    const ecomImport = async (data) => {
        openBackdrop(); // Open backdrop
        try {
            const res = await privateAxios.post(`procurement/createCategoriesFromEcom`, data)
            await getData();// Refresh data
            showToast("success", "Category Added");// Show success toast
        } catch (error) {
            showToast("error", "Error Creating Categories");// Show error toast
        }
        handleCategoryModal();// Close category modal
        closeBackdrop();// Close backdrop
    }


    // Function to handle navigation based on category data
    const handleNavigate = (data) => {
        let path = "";

        if (idsArray.length === 0) {
            path = data.categoryId;// Set path if idsArray is empty
        }
        else {
            let temp = idsArray.join("/");
            path = `${temp}/${data.categoryId}`;// Set path with idsArray
        }
        navigate(path); // Navigate to the path
    }

    // State for category modal visibility
    const [categoryModal, setCategoryModal] = useState(false);

    // Function to toggle category modal visibility
    const handleCategoryModal = () => {
        setCategoryModal(prev => !prev)
    }




    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>



                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <h1 style={{ gap: '10px' }}>
                            <BackButton />
                            Categories
                            {/* <i style={{ fontSize: "15px" }}>({auth.roles})</i> */}
                            {/* <span>(ADMIN)</span> */}
                        </h1>
                        {/* <p>Available <span>{data.length}</span></p> */}
                        <p>Available <span>{data.length}</span></p>

                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
                        <input type="text"
                            onChange={(e) => setSearchValue(e.target.value)}

                            value={searchValue}
                            placeholder='Search Here...' />
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: 'auto', minWidth: 'auto' }} >
                        {/* <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: '450px', gap: '10px' }}> */}

                        {

                            idsArray.length == 0 &&
                            <button className={workSpaceStyle.headerbtn} style={{ backgroundColor: "#16469D", color: "white" }} onClick={() => { handleCategoryModal() }}>Import Category</button>
                        }

                        <button className={workSpaceStyle.headerbtn} style={{ backgroundColor: "#16469D", color: "white" }} onClick={() => { handleCreateCategoryModal() }}>Create Category</button>



                        <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                            <Tooltip title='Help'>
                                <a href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081303-categories-management-in-workspace">
                                    <HelpOutlineOutlinedIcon />
                                </a>
                            </Tooltip>
                        </div>
                    </div>

                </div>


                <div className={workSpaceStyle.workSpaceMainDiv}>

                    <table className={superadminStyle.admintable}>
                        <thead>
                            <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
                                <th style={{ width: "10%" }}>
                                    Category Id.
                                </th>
                                <th>
                                    <p onClick={() => toggleSorter("name")}>Category
                                        <span>{sorterOpen === "name" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "name" && <TableSorterDropDown data={sorterData.name} onClick={(item) => handleSorting("name", item)} toggle={() => toggleSorter("name")} />
                                    }
                                </th>
                                <th>


                                    <p>description

                                    </p>

                                </th>

                                {/* <th>
                                <p onClick={() => toggleSorter("organisationName")}> Organisation
                                        <span>{sorterOpen === "organisationName" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "organisationName" && <TableSorterDropDown data={sorterData.organisationName} onClick={handleSorting} toggle={() => toggleSorter("organisationName")} />
                                    }
                                </th> */}

                                <th>
                                    Action
                                </th>

                            </tr>
                        </thead>
                        <tbody>

                            {

                                sortedData
                                    .filter((val) => val?.name?.toLowerCase().includes(searchValue.trim().toLowerCase()))

                                    .map((row, index) => {
                                        return (


                                            <tr className={superadminStyle.admintableRow} key={index}>
                                                <td>
                                                    {row.categoryId}
                                                </td>
                                                <td>
                                                    {row.name}
                                                </td>
                                                <td>
                                                    {row.description}
                                                </td>

                                                {/* <td>
                                                    {row.organisationName}
                                                </td> */}


                                                <td>
                                                    <div className={superadminStyle.actionIcons} >
                                                        <span onClick={() => handleEditCategoryModal(row)}>

                                                            <EditIcon />
                                                        </span>
                                                        <span onClick={() => handleNavigate(row)}>

                                                            <RemoveRedEyeIcon />
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )

                                    }
                                    )
                            }


                        </tbody>


                    </table>


                </div>

            </div>

            <CreateCategoryModal open={createCategoryModal} onClose={handleCreateCategoryModal} onSubmit={formSubmit} />

            <CreateCategoryModal open={editCategoryModal} onClose={handleEditCategoryModal} onSubmit={formSubmit} data={editData} />


            <CategorySelector open={categoryModal} onClose={handleCategoryModal} onsubmit={ecomImport} />





        </>
    )
}

export default CategoriesView
