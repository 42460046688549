import React from 'react'
import unAuthorizedStyle from '../../components/WorkSpaceCommonCss/Unauthorized.module.css';
import { useNavigate } from 'react-router-dom'; 
const UnAuthorized = () => {

  const navigate = useNavigate(); // Initialize the useNavigate hook
  const handleHomeButtonClick = () => {
    navigate('/'); // Navigate to the home page route
  };
  
  return (
    <div className={unAuthorizedStyle.MainCompoUnauth} style={{height:"100%"}}>
      <div className={unAuthorizedStyle.MainCompoUnauthBox}>
        <div className={unAuthorizedStyle.mainHeadingError}>
          <h1>4</h1><span><img src='https://res.cloudinary.com/del6gyrgn/image/upload/v1693028784/qqqqq_jctqa1.png' /></span><h1>1</h1>

        </div>
        <div className={unAuthorizedStyle.uauthMeassage}>
          <h2>Unauthorized Access</h2>
          <p>We're sorry, but it seems like you don't have the necessary<br />authorization to access this page. Our systems have detected an<br />unauthorized attempt to access restricted content.</p>
        </div>
       
      </div>
    </div>
  )
}

export default UnAuthorized
