import React, { useEffect } from 'react'
import superadminStyle from '../../commonCss.module.css'
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch'
import { PrivateComponent } from "api/axios";
import { useNavigate, useParams } from 'react-router-dom';
import DateFormater from 'utility/DateFormater';

const ProductsTab = ({ openBackdrop, closeBackdrop, showToast, data, getProductData }) => {
  // Get privateAxios instance from PrivateComponent
  const privateAxios = PrivateComponent();

  // Get orgId from URL params
  const { orgId } = useParams();

  // Get navigate function from useNavigate hook
  const navigate = useNavigate();

  // Function to handle checkbox change
  const handleCheckBox = async (id, type) => {
    openBackdrop();//Open backdrop
    try {
      let url = "";
      // Determine URL based on type
      if (type) {
        url = `/procureadmin/DisableProductForOrganisation`
      }
      else {
        url = `/procureadmin/EnableProductForOrganisation`
      }
      // Send a POST request to enable/disable product
      const res = await privateAxios.post(`${url}/${id}/${orgId}`);
      // If request is successful
      if (res.status === 200) {  // Show success message based on type
        if (!type) {
          showToast("success", "Porduct Enabled for Organaistion");
        }
        else {
          showToast("success", "Porduct Disabled for Organaistion");
        }
      }
      // Fetch updated product data
      getProductData();
    } catch (error) {
      // Show error message if request fails
      showToast("error", error.message)
    }
    // Close backdrop regardless of success or failure
    closeBackdrop();
  }



  return (
    <table className={superadminStyle.admintable}>
      <thead>
        <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
          <th style={{ width: "45%" }}>
            Products
          </th>
          <th>
            Requested On
          </th>
          <th>
            Active
          </th>
          <th>
            Action
          </th>

        </tr>
      </thead>
      <tbody>
        {
          data.map((row, index) => {
            // console.log(row);
            return (
              <tr className={superadminStyle.admintableRow}>

                <td>
                  {row.productName}
                </td>
                <td>
                  {DateFormater(row.approvedOn)}
                </td>
                <td>
                  <div className={superadminStyle.actionIcons}>

                    <ToggleSwitch checked={!row.isDisable} name={`checkBox_index`}
                      onChange={() => handleCheckBox(row.id, !row.isDisable)}
                    />
                  </div>
                </td>
                <td>
                  <p style={{ color: "#16469D", textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => { navigate(`/superadmin/products/organisation-by-product/${row.productId}/${orgId}`) }}>

                    View Details
                  </p>
                </td>
              </tr>

            )
          })
        }

      </tbody>
    </table>

  )
}

export default ProductsTab
