import React, { useEffect, useState } from 'react';
import styles from './UnitSelector.module.css';
import ReactQuill from 'react-quill';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';

const modules = {
    toolbar: [
        [{ script: "sub" }, { script: "super" }],
        ["clean"],
    ],
};

const UnitSelector = ({ value, onChange}) => {
    const { auth } = useAuth();
    const privateAxios = PrivateComponent();



    
    const [toggleEditor, setToggleEditor] = useState(false);
    const handleEditorToggle = () => {
        setToggleEditor(prev => !prev);
    };

     // unit states
     const [units, setUnits] = useState([]);

     const getUnits = async () => {
         try {
             await privateAxios.get(`/technicalSpecification/getAllUnits/${auth.orgId}`).then(res => {
                 // console.log(res.data)
                 setUnits(res.data)
             }).catch(err => {
                 // console.log(err);
             })
 
         } catch (error) {
 
         }
     }
 
    
     
 
 
 
 
 
 
 
     // unit states 

    const [dropDownToggle, setDropDownToggle] = useState(false);
    const handleDropDownToggle = () => {
        setDropDownToggle(prev => !prev);
        
    }

    useEffect(() => {

        if (dropDownToggle) {
          
            getUnits();
        }
        else {
            setToggleEditor(false)
        }
    }, [dropDownToggle])



  



    const handleUnitUpload = async () => {
        try {


            if(value==="")
            {
                return;
            }
            else
            {
                if (units?.find((data) => data.unit === value)) {
                    return;
                }
                else{
                    let url = auth.roles === "VENDOR" ? `/technicalSpecification/addUnitOfAttributeInVendor/${auth.userData.id}` : `/technicalSpecification/addUnitOfAttribute/${auth.orgId}`;

                    await privateAxios.post(`${url}`, { unit: value }).then((res) => {
                        getUnits();
                        setToggleEditor(false);
                        setDropDownToggle(false);
                    }).catch(err => {
                        // console.log(err);
                    })
                }
            }
            
        } catch (error) {
            // console.log(error)
        }
    }


    return (
        <div className={styles.unitSelectorWrapper}>

            <p onClick={() => {handleDropDownToggle() }}
                dangerouslySetInnerHTML={{ __html: value }}
                className={styles.unitSelectorValue}
            ></p>

            {
                dropDownToggle &&
                <>
                    <div className={styles.backDrop} onClick={handleDropDownToggle}></div>
                    {
                        toggleEditor ?
                            <>
                                <div className={styles.unitSelectorEditor} >
                                    <ReactQuill modules={modules} theme="snow" value={value} onChange={(e)=>onChange({target:{value:e,name:"unit"}})} />
                                    <button onClick={()=>{handleUnitUpload();}}>
                                        set
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                <ul className={styles.unitSelectorList}>
                                    <li onClick={() => { handleEditorToggle() }}>
                                        + Add Unit
                                    </li>
                                    {units?.map((data, index) => {
                                        // console.log(data)
                                        return (
                                            <li key={index} onClick={() => {onChange({target:{value:data.unit,name:"unit"}}); handleDropDownToggle(); }} dangerouslySetInnerHTML={{ __html: data.unit }}>

                                            </li>
                                        )

                                    })}

                                </ul>
                            </>

                    }


                </>


            }


        </div>
    )
}

export default UnitSelector
