import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import axios from 'axios';
import useToast from 'hooks/useToast';
import useBackdrop from 'hooks/useBackdrop';
import ProdStyle from './ProductSelector.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'


const ProductSelector = ({ productsArray, handleAddProductModal, onsubmit, orgId }) => {
    const { Toast, showToast } = useToast(); // Destructure Toast and showToast from useToast
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();// Destructure backdrop methods from useBackdrop
    const [availableCategories, setAvailableCategories] = useState([]); // State for available categories
    const [selectedSubcategories, setSelectedSubcategories] = useState([]); // State for selected subcategories
    const [productList, setProductList] = useState([]); // State for product list
    const [productSelected, setProductSelected] = useState([]); // State for selected products

// Function to fetch categories
    const getCategories = async () => {
        openBackdrop(); // Show backdrop
        try {
            await axios.get(`${process.env.REACT_APP_ECOM}/api/subgroups`).then(res => {
                if (res.status === 200) {
                    setAvailableCategories(res.data);// Set available categories if request is successful
                }
            })
        } catch (error) {
            showToast("error", "Error Fetching Categories");// Show error toast if request fails
        }
        closeBackdrop(); // Hide backdrop
    }
   // useEffect to fetch categories on component mount
    useEffect(() => {
        getCategories();// Call getCategories
    }, [])

 // Function to fetch subcategories based on category
    const getCategoryData = async (category) => {
        openBackdrop(); // Show backdrop
        try {
            const res = await axios.get(`${process.env.REACT_APP_ECOM}/api/hierarchy/${category.id}/${category.level}`)
            setSelectedSubcategories([{ ...res.data }]);// Set selected subcategories
            setProductSelected(""); // Reset selected products

        } catch (err) {
            showToast("error", "Error Fetching Sub Categories")// Show error toast if request fails
        }
        closeBackdrop();// Hide backdrop
    };

      // Function to fetch products based on subcategory
    const getPorductData = async (id, level) => {
        openBackdrop();// Show backdrop
        try {
            const res = await axios.get(`${process.env.REACT_APP_ECOM}/api/productsforlevel/${id}/${level}`)
            setProductList(res.data); // Set product list
            setProductSelected("");// Reset selected products
        } catch (error) {
            setProductList([]); // Reset product list on error
            setProductSelected(""); // Reset selected products on error
            showToast("error", "No Products"); // Show error toast if request fails
        }
        closeBackdrop(); // Hide backdrop
    }
 // useEffect to fetch products when selected subcategories change
    useEffect(() => {
        if (selectedSubcategories[selectedSubcategories.length - 1]?.Branch.length === 0) {
            const sub = selectedSubcategories[selectedSubcategories.length - 1]
            getPorductData(sub.Id, sub.Level);// Fetch product data for the last subcategory
        }
    }, [selectedSubcategories]);

    // =======================category Selector========================


// State for category search input
    const [catSearch, setCatSearch] = useState("");

      // State to manage category dropdown visibility
    const [catSelectDropdown, setCatSelectDropdown] = useState(false);

     // Function to toggle category dropdown visibility
    const handleCatSelectorDropDown = () => {
        setCatSelectDropdown((prev) => !prev);
    }

     // Function to handle category selection
    const handleCategorySelector = async (cat) => {
        handleCatSelectorDropDown(); // Toggle dropdown visibility
        await getCategoryData({ ...cat, level: 1 }); // Fetch category data
        setProductList([]);// Reset product list
        setProductSelected(""); // Reset selected products
    }

    // =======================category Selector========================

    // =======================Subcategory Selector========================

    // Function to handle subcategory selection
    const handleSubcategorySelect = (subcategory, index) => {
        const tt = selectedSubcategories.filter((cat) => {
            return cat.Level < subcategory.Level;// Filter subcategories based on level
        })
        const newSub = [
            ...tt,
            { ...subcategory },
        ]
        setProductList([]);// Reset product list
        setProductSelected("");// Reset selected products
        setSelectedSubcategories(newSub); // Set selected subcategories
    };

    // =======================Subcategory Selector========================

    // ====================tree builder========================

    // Function to build category tree
    const buildTree = (categories) => {
        let tree = [];// Initialize tree
        let lookup = {};// Initialize lookup object

        categories.forEach(category => {
            category.branch = []; // Initialize branch array
            lookup[category.categoryId] = category;// Populate lookup object
        });

        categories.forEach(category => {
            if (category.parent !== null) {
                const parent = lookup[category.parent];
                parent.branch.push(category);// Add category to parent's branch
            } else {
                tree.push(category); // Add category to tree if no parent
            }
        });
        return tree;// Return constructed tree
    }

    // ====================tree builder========================


    // =========================handle Discard==========================

    // Function to handle discard action
    const handleDiscard = () => {
        setSelectedSubcategories([]); // Reset selected subcategories
        setProductSelected([]); // Reset selected products
        handleAddProductModal(); // Close add product modal
    }

    // =========================handle Discard==========================

     // Function to handle product addition
    const handleAddPro = () => {
        if (productList.length === 0) {
            alert("Please Select a Product");// Show alert if no product selected
        } else {
            try {
                let updatedCategories = selectedSubcategories.map((cat) => {

                    if (orgId !== null && orgId !== undefined && orgId !== "") {
                        return {
                            categoryId: cat.Id,
                            categoryName: cat.Name,
                            parent: cat.ParentID,
                            organisationId: orgId,//show be changed to state.data.orgId
                            level: cat.Level,
                            branch: [],
                        }
                    }
                    else {
                        return {
                            categoryId: cat.Id,
                            categoryName: cat.Name,
                            parent: cat.ParentID,
                            level: cat.Level,
                            branch: [],
                        }
                    }
                })
                const updateProducts = productSelected?.map((pro) => {
                    if (orgId !== null && orgId !== undefined && orgId !== "") {
                        return {
                            productName: pro.name,
                            productId: pro.productId,
                            organisationId: orgId,//show be changed to state.data.orgId
                            categoryId: selectedSubcategories[selectedSubcategories.length - 1].Id,
                        }
                    }
                    else {
                        return {
                            productName: pro.name,
                            productId: pro.productId,
                            categoryId: selectedSubcategories[selectedSubcategories.length - 1].Id,
                        }
                    }
                })
                const tree = buildTree(updatedCategories); // Build category tree
                onsubmit({ categories: tree, products: updateProducts }); // Submit categories and products
            } catch (error) {
                return null; // Handle error
            }
        }
    }





    return (
        <>
            <Toast />
            <BackdropComponent />

            <div className={ProdStyle.productSelectors}>
                <div className={ProdStyle.productSelectorColumn}>
                    <label htmlFor="">Select Category</label>
                    <div className={ProdStyle.productSelector}  >
                        <p onClick={() => { handleCatSelectorDropDown(); }} >
                            {selectedSubcategories[0]?.Name || "Please Select A category"}
                            <span>
                                {catSelectDropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </span>
                        </p>
                        {catSelectDropdown &&
                            <>

                                <div className={ProdStyle.dropdownBackDrop} onClick={handleCatSelectorDropDown}>
                                </div>
                                <div className={ProdStyle.productDropDownDiv} >

                                    <div className={ProdStyle.productSearchBar}>
                                        <input type="text" value={catSearch} onChange={(e) => setCatSearch(e.target.value)}
                                            placeholder="Search for Products..." />
                                    </div>
                                    <ul className={ProdStyle.productDropDownList}>

                                        {availableCategories.filter((cat) => cat.name.trim().toLowerCase().includes(catSearch.toLowerCase())).map((cat) => {
                                            return (
                                                <li onClick={() => handleCategorySelector(cat)}>{cat.name}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </>
                        }
                    </div>
                </div>

                {selectedSubcategories.length > 0 &&
                    selectedSubcategories.map((selectedCategory, index) => {

                        if (selectedCategory.Branch.length > 0) {
                            return (
                                <div className={ProdStyle.productSelectorColumn}>
                                    <label> Sub-Category</label>
                                    <SubcategorySelectorInput
                                        category={selectedCategory}
                                        selectedSubcategories={selectedSubcategories}
                                        onSelectSubcategory={(subcategory) => handleSubcategorySelect(subcategory, index)}
                                        index={index}

                                    />
                                </div>
                            )
                        }
                    }
                    )}


                {selectedSubcategories[selectedSubcategories.length - 1]?.Branch.length === 0 &&
                    <div className={ProdStyle.productSelectorColumn}>
                        <label>
                            {/* <GroupsIcon sx={{ color: '#6A6A6A' }} /> */}
                            Product </label>
                        <ProductSelectorInput productList={productList} productSelected={productSelected}

                            setProSelected={setProductSelected} productsArray={productsArray} />

                    </div>}

            </div>


            <div className={workSpaceStyle.d_flex_end} style={{ gap: "10px" }}>
                <button className={`${workSpaceStyle.modalBtn}`} style={{ color: "#16469D" }} onClick={handleDiscard}> Discard</button>
                <button className={`${workSpaceStyle.modalBtn}`} style={{ backgroundColor: "#16469D", color: "white" }} onClick={handleAddPro}>Add Product</button>

            </div>
        </>
    )
}

export default ProductSelector



const SubcategorySelectorInput = ({ category, selectedSubcategories, onSelectSubcategory, index }) => {

    const [subCatSelectDropDown, setSubCatSelectDropDown] = useState(false);
    const [subCatSelected, setSubCatSelected] = useState("");
    const [subCatSearch, setSubCatSearch] = useState("");
    const handleSubCatSelectorDropDown = () => {
        setSubCatSelectDropDown(prev => !prev)
    }

    const handleSubCategorySelector = (subCat) => {
        handleSubCatSelectorDropDown();
        onSelectSubcategory(subCat);
        setSubCatSelected(subCat.Name);


    }

    return (
        <div className={ProdStyle.AllProductmodal} key={index} >
            <div className={ProdStyle.productSelector} >

                <p onClick={() => {


                    handleSubCatSelectorDropDown();


                }



                }
                // style={{ color: subCatSelected ? 'black' : 'gray' }}
                >{(index < (selectedSubcategories.length - 1) && subCatSelected) || "Please Select A Sub-Category"}
                    <span>
                        {subCatSelectDropDown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </span>
                </p>
                {
                    subCatSelectDropDown &&
                    <>


                        <div className={ProdStyle.dropdownBackDrop} onClick={handleSubCatSelectorDropDown}>
                        </div>
                        <div className={ProdStyle.productDropDownDiv}>
                            <div className={ProdStyle.productSearchBar}>
                                <input type="text" value={subCatSearch} onChange={(e) => setSubCatSearch(e.target.value)} />
                            </div>
                            <ul className={ProdStyle.productDropDownList} >

                                {category.Branch.filter((subCat) => {
                                    return subCat.Name && subCat.Name.trim().toLowerCase().includes(subCatSearch.toLowerCase());
                                }).map((subCat) => {
                                    return (
                                        <li onClick={() => handleSubCategorySelector(subCat)}>{subCat.Name}</li>
                                    )
                                })}

                            </ul>
                        </div>
                    </>
                }
            </div>

        </div>
    )
}


const ProductSelectorInput = ({ productSelected, productList, addBtnToggle, setProSelected, productsArray }) => {

    // console.log(productsArray)
    const [availableCategories, setAvailableCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        setAvailableCategories(productList)
        if (productList.length === 0) {
            setSelectedCategories([])
        }
    }, [productList])


    // console.log(productList)
    const toggleDropdown = () => {

        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        setProSelected(selectedCategories)
    }, [selectedCategories])

    const handleCategoryToggle = (category) => {
        if (!productsArray.some((selectedProduct) => selectedProduct.productId === category.productId)) {
            const categoryIndex = selectedCategories.findIndex((cat) => cat.name === category.name);

            if (categoryIndex !== -1) {
                // Category already selected, remove it
                const updatedCategories = [...selectedCategories];
                updatedCategories.splice(categoryIndex, 1);
                setSelectedCategories(updatedCategories);
            } else {
                // Category not selected, add it
                setSelectedCategories([...selectedCategories, category]);
            }
        }
    };


    const handleRemoveCategory = (event, category) => {
        event.stopPropagation();


        const updatedCategories = selectedCategories.filter((cat) => cat.name !== category.name);
        setSelectedCategories(updatedCategories);


    };

    return (

        <div className={formStyle.inputRow}>
            <div className={ProdStyle.dropdownContainer}>
                <div
                    className={`${ProdStyle.dropdown} ${dropdownOpen && ProdStyle.open}`}
                    onClick={() => {




                        toggleDropdown();

                    }
                    }
                >
                    <div className={ProdStyle.selectedCategories}>
                        {selectedCategories.length > 0 ?
                            selectedCategories.map((category, index) => (
                                <div key={index} className={ProdStyle.selectedCategory}>
                                    {category.name}
                                    <CloseIcon
                                        className={ProdStyle.closeIcon}
                                        onClick={(event) => handleRemoveCategory(event, category)}
                                    />
                                </div>
                            )) :
                            'Select products'}
                    </div>
                    <KeyboardArrowDownIcon
                        className={ProdStyle.arrowIcon}

                    />
                </div>
                {dropdownOpen && (
                    <>


                        <div className={ProdStyle.dropdownBackDrop} onClick={toggleDropdown}>
                        </div>
                        <div className={ProdStyle.dropdownContent}>
                            {availableCategories.map((category, index) => (
                                <div
                                    key={index}
                                    className={ProdStyle.dropdownItem}
                                    onClick={() => handleCategoryToggle(category)}
                                    style={{
                                        backgroundColor: productsArray.some((selectedProduct) => selectedProduct.productId === category.productId) ? '#efeaea' : 'white',
                                        color: productsArray.some((selectedProduct) => selectedProduct.productId === category.productId) ? '#737374' : 'black',
                                        cursor: productsArray.some((selectedProduct) => selectedProduct.productId === category.productId) ? 'auto' : 'pointer',
                                    }}
                                >
                                    <span>{category.name}</span>
                                    <input
                                        type="checkbox"
                                        name='categorySelect'
                                        checked={selectedCategories.some((cat) => cat.name === category.name) || productsArray.some((selectedProduct) => selectedProduct.productId === category.productId)}
                                        readOnly
                                        required
                                        disabled={productsArray.some((selectedProduct) => selectedProduct.productId === category.productId)}
                                    />
                                </div>
                            ))}


                        </div>
                    </>
                )}

            </div>

        </div>


    )
} 