import React, { useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './SpecificationTab.module.css'
import useAuth from 'hooks/useAuth';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';

const SpecificationTab = ({ data, showToast, specificationRemark, setSpecificationRemark, approverRemarks, approovalStatus, viewType }) => {
  const { auth } = useAuth();


  return (
    <div className={styles.specficationTabMain}>

      <table className={styles.specficationTable}>
        <tr className={`${styles.tableHeader}`}>
          <th>
            Specification
          </th>
          <th style={{ textAlign: "left", paddingRight: "20px" }}>
            From
          </th>
        </tr>
        {Object.values(data).map((spec, index) => {
          return (
            <tr className={styles.tableRow}>


              {/* // <div className={styles.richEditor} style={{ border: "1px solid grey" }}> */}

              <td className={styles.reactQuillTd}>
                <ReactQuill modules={{ toolbar: false }}
                  readOnly={true}
                  style={{ border: "none", outline: "none" }}
                  // theme="snow" 
                  value={spec.name}
                />
              </td>
              <td className={styles.vendorInfo}>
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                  <span
                    style={
                      {
                        width: "25px",
                        height: "25px",
                        backgroundColor: "purple",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%"
                      }
                    }>{Object.keys(data)[index].charAt(0)}</span>
                  {Object.keys(data)[index]}
                </div>
              </td>

              {/* </div> */}
            </tr>
          )

        })}
      </table>
      {
        viewType === "WAPPROVAL" &&
        <>

          {approverRemarks?.length > 0 &&

            <table className={styles.specficationTable}>
              <tr className={`${styles.tableHeader}`}>
                <th>
                  Approver Remark
                </th>
                <th style={{ textAlign: "left", paddingRight: "20px" }}>
                  From
                </th>
              </tr>
              {approverRemarks?.map((rem, index) => {
                return (
                  <tr className={styles.tableRow}>


                    {/* // <div className={styles.richEditor} style={{ border: "1px solid grey" }}> */}

                    <td className={styles.reactQuillTd}>
                      <ReactQuill modules={{ toolbar: false }}
                        readOnly={true}
                        style={{ border: "none", outline: "none" }}
                        // theme="snow" 
                        value={rem.remark}
                      />
                    </td>
                    <td className={styles.vendorInfo}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <ProfileIcon data={{ email: rem?.email }} height={"26px"} width={"26px"} showProfileBanner={rem.name == "No Name" ? false : true} />
                        {rem.name}
                      </div>
                    </td>

                    {/* </div> */}
                  </tr>
                )

              })}
            </table>
          }

          {auth.roles !== "KAM"
            &&
            <div
              style={{
                isplay: "flex",
                height: "20vh",
                width: "100%",
                flexDirection: "column",
                gap: "5px"
              }}>
              <h3
                style={{
                  marginBottom: "3px",
                  fontSize: "16px",
                  fontWeight: "800"
                }}>
                Add Remark
              </h3>
              <textarea name="" id=""
                value={specificationRemark}
                onChange={(e) => {
                  if (approovalStatus !== "APPROVAL" && approovalStatus !== "REJECTED") {
                    setSpecificationRemark(e.target.value)
                  } else {
                    showToast("error", "You can't add remark in after Decision");
                  }
                }
                }
                className={styles.descriptionCompareTextBox}
                style={{ height: "100%" }} />
            </div>


          }

        </>
      }
    </div>
  )
}

export default SpecificationTab
