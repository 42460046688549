import React from 'react'
import documentChatStyle from './DocumentChat.module.css';


const DocumentChatPannel = ({children,onClose,documentId,revisionId}) => {
  return (
    onClose &&
    <div className={documentChatStyle.documentChatPannelMain}>
     {children}
    </div>

  )
}

export default DocumentChatPannel
