import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import superadminStyle from '../../commonCss.module.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';
import GroupSorterData from 'utility/GroupSorterData.js';
import TableSorterDropDown from 'components/TableSorterDropDown/TableSorterDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DateFormater from 'utility/DateFormater';
import axios from 'axios';
import commercialListStyle from '../CommercialList/CommercialList.module.css'
import BackButton from 'components/BackButton/BackButton';

const CommercialCategoryList = () => {
    // Hook to handle backdrop (loading screen)
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    // Hook to handle toast notifications
    const { showToast, Toast } = useToast();
    // Custom Axios instance for making requests
    const privateAxios = PrivateComponent();
    // Hook for navigation
    const navigate = useNavigate();
    // State to store template data
    const [templateData, setTemplateData] = useState([]);


    // =====================search======================
    // State to store search value
    const [searchValue, setSearchValue] = useState('');


    // ===================sorting states==================

    // State to manage the open state of the sorter dropdown
    const [sorterOpen, setSorterOpen] = useState("");

    // Function to toggle the sorter dropdown
    const toggleSorter = (type) => {
        if (sorterOpen === type) {
            setSorterOpen("");
        }
        else {
            setSorterOpen(type);
        }

    }

    // ===================sorting states==================


    // =====================data======================

    // State to store fetched data
    const [data, setData] = useState([]);
    // State to store sorted data
    const [sortedData, setSortedData] = useState([]);
    // State to store sorter data
    const [sorterData, setSorterData] = useState({});

    // Function to fetch data
    const getData = async () => {
        openBackdrop();
        try {

            const res = await privateAxios.get('/procureadmin/getAllSpecificTemplates');

            console.log(res.data);
            setData(res.data)
            setSortedData(res.data)

            setSorterData(GroupSorterData(res.data));

        } catch (error) {
            showToast("error", error.message)
        }
        closeBackdrop();
    }
    // Fetch data on component mount
    useEffect(() => {
        getData();
    }, [])

    // =====================data======================

    // ===================sorting states==================

    // Function to handle sorting by type and value
    const handleSorting = (type, value) => {
        if (value === "All") {
            setSortedData(data);
        }
        else {
            setSortedData(data.filter((dd) => dd[type] === value));
        }
    }

    // Function to handle date sorting
    const dateSorting = (type, value) => {
        console.log(value);
        let sortedData = []

        if (value === "asc") {
            sortedData = [...data].sort((a, b) => {
                return new Date(a[type]) - new Date(b[type]);
            });
        }
        else {
            sortedData = [...data].sort((a, b) => {
                return new Date(b[type]) - new Date(a[type]);
            });
        }
        setSortedData(sortedData);
    }

    // ===================sorting states==================


    // =====================handle active org======================

    // Function to handle checkbox state change
    const handleCheckBox = async (id) => {
        openBackdrop();
        try {
            setData((prev) => {
                return prev.map((val) => {
                    if (val.id === id) {
                        return { ...val, active: !val.active }
                    }
                    return val;
                })
            })
        } catch (error) {
            showToast("error", error.message)
        }
        closeBackdrop();
    }

    // =====================handle active org======================

    // State to store selected categories
    const [selectedCategories, setSelectedCategories] = useState([]);

    // State to control the add category modal visibility
    const [addCategory, setAddCategory] = useState(false);

    // Function to toggle add category modal
    const handleAddCategoryModal = () => {
        setAddCategory(prev => !prev);
    }


    // ====================add Category modal========================

    // State to store e-commerce template categories
    const [ecomTemplateCat, setEcomTemplateCat] = useState([]);

    // Function to fetch e-commerce template categories
    const getEcomTemplateCat = async () => {
        openBackdrop();
        try {
            const resEcom = await axios.get(`${process.env.REACT_APP_ECOM}/api/hierarchy/200021/1`);

            const ecomData = resEcom.data.Branch.map((val) => {
                return {

                    specificationId: val.Id,
                    name: val.Name,
                    // level: val.Level,
                }
            }
            )

            const sortedEomData = ecomData.filter((tr) => !data.some((rt) => rt.specificationId === tr.specificationId));
            setEcomTemplateCat(sortedEomData);


        } catch (error) {
            showToast("error", "Error Getting Categories")
            console.log(error);
        }
        closeBackdrop();

    }

    // Fetch e-commerce template categories when addCategory state changes
    useEffect(() => {
        if (addCategory) {
            getEcomTemplateCat();
        }

    }, [addCategory])

    // ==================handle Category checkbox=====================

    // State to store selected categories for checkbox
    const [selectedCategory, setSelectedCategory] = useState([]);


    // Function to handle category checkbox state change
    const handleCategoryCheckBox = (temp) => {
        if (selectedCategory.some((rt) => rt.specificationId === temp.specificationId)) {
            setSelectedCategory(prev => prev.filter((rt) => rt.specificationId !== temp.specificationId))
        }
        else {
            setSelectedCategory(prev => [...prev, temp]);
        }
    }
    // ==================handle Category checkbox=====================

    // ====================handle add product success modal================


    // Function to handle category addition
    const handleCategoryAdd = async () => {
        openBackdrop();
        try {
            if (selectedCategory.length > 0) {

                const res = await privateAxios.post(`/procureadmin/importSpecificationTemplate`, selectedCategory);
                if (res.status === 200) {
                    showToast("success", "Catgory Imported")
                }
                getData();
            }
            else {
                showToast("error", "select atleast one Category")
            }
        } catch (error) {
            showToast("error", "Error Importing Category")
        }
        handleAddCategoryModal();
        setSelectedCategories([]);
        closeBackdrop();


    }


    // ====================handle add product success modal================


    // ===================toggle more====================

    // State to control the visibility of the more dropdown
    const [moreDropdown, setMoreDropdown] = useState(null);

    // Function to toggle the more dropdown
    const toggleMoreDrop = (index) => {
        if (moreDropdown === index) {
            setMoreDropdown(null);
        }
        else {
            setMoreDropdown(index);

        }

    }

    // ===================toggle more====================

    // ====================delete template modal========================

    // State to control the delete template modal visibility
    const [deleteTemplateModal, setDeleteTemplateModal] = useState(false);

    // Function to toggle delete template modal and set template data
    const handleDeleteTemplateModal = (data) => {

        setDeleteTemplateModal(prev => !prev);
        if (data !== undefined && data !== null && data !== "") {
            setTemplateData(data);
        }
    }

    // ====================delete template modal========================

    // ====================delete template========================

    // Function to handle template deletion
    const handleDeleteTemplate = async () => {
        console.log(templateData);
    }











    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>



                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <h1 style={{ gap: '10px' }}> <BackButton />
                            Commercial Category List
                            {/* <i style={{ fontSize: "15px" }}>({auth.roles})</i> */}
                            {/* <   span>(ADMIN)</> */}
                        </h1>
                        {/* <p>Available <span>{data.length}</span></p> */}
                        <p>Available <span>{data.length}</span></p>

                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
                        <input type="text"
                            onChange={(e) => setSearchValue(e.target.value)}

                            value={searchValue}
                            placeholder='Search Here...' />
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: 'auto', minWidth: 'auto' }} >
                        {/* <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: '450px', gap: '10px' }}> */}











                        <button className={workSpaceStyle.headerbtn} style={{ backgroundColor: "#16469D", color: "white" }} onClick={() => { handleAddCategoryModal() }}>Import Category</button>



                        <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                            <Tooltip title='Help'>
                                <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081308-commercial-category-list-page">
                                    <HelpOutlineOutlinedIcon />
                                </a>
                            </Tooltip>
                        </div>
                    </div>

                </div>


                <div className={workSpaceStyle.workSpaceMainDiv}>

                    <table className={superadminStyle.admintable}>
                        <thead>
                            <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
                                <th style={{ width: "5%" }}>
                                    Sr No.
                                </th>
                                <th style={{ width: "60%" }}>
                                    <p onClick={() => toggleSorter("name")}>Name
                                        <span>{sorterOpen === "name" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "name" && <TableSorterDropDown data={sorterData.name} onClick={(item) => handleSorting("name", item)} toggle={() => toggleSorter("name")} />
                                    }
                                </th>
                                <th>


                                    <p onClick={() => toggleSorter("createdAt")}>Created On
                                        <span>{sorterOpen === "createdAt" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "createdAt" && <TableSorterDropDown type={"date"} data={sorterData.createdAt} onClick={(e) => dateSorting("createdAt", e)} toggle={() => toggleSorter("updatedAt")} />
                                    }
                                </th>

                                <th style={{ width: "15%" }}>
                                    Action
                                </th>

                            </tr>
                        </thead>
                        <tbody>

                            {

                                sortedData
                                    .filter((val) => val.name.toLowerCase().includes(searchValue.trim().toLowerCase()))
                                    .map((row, index) => {
                                        return (


                                            <tr className={superadminStyle.admintableRow} key={index}>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    {row.name}
                                                </td>
                                                <td>
                                                    {DateFormater(row.createdAt)}
                                                </td>

                                                <td>
                                                    <div className={superadminStyle.actionIcons}>
                                                        <span onClick={() => navigate(`${row.specificationId}/${row.id}`)}>

                                                            <RemoveRedEyeIcon />
                                                        </span>

                                                    </div>
                                                </td>
                                            </tr>
                                        )

                                    }
                                    )
                            }

                        </tbody>


                    </table>


                </div>

            </div>

            <Modal
                open={addCategory}
                onClose={handleAddCategoryModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>


                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "felx-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Add Category</h2>
                            <span onClick={handleAddCategoryModal}><CloseIcon /></span>
                        </div>


                    </div>
                    <div className={commercialListStyle.templatesWrapper}>
                        {
                            ecomTemplateCat.length > 0 ?


                                ecomTemplateCat?.map((temp, index) => {
                                    return (
                                        <div className={commercialListStyle.templateCard}>
                                            <p>{temp.name}</p>
                                            <input type="checkbox" checked={selectedCategory.some((rt) => rt.specificationId === temp.specificationId)} onChange={() => handleCategoryCheckBox(temp)} />
                                        </div>
                                    )
                                })

                                :
                                <h3>No New Category to Import</h3>
                        }




                    </div>
                    {
                        ecomTemplateCat.length > 0 &&

                        <div className={workSpaceStyle.d_flex_start} style={{ gap: "10px" }}>
                            <button className={`${workSpaceStyle.modalBtn}`} style={{ backgroundColor: "#16469D", color: "white" }} onClick={handleCategoryAdd}>Add Category</button>
                            <button className={`${workSpaceStyle.modalBtn}`} style={{ color: "#16469D" }} onClick={() => { handleAddCategoryModal(); setSelectedCategories([]); }}> Discard</button>

                        </div>


                    }


                </div>


            </Modal>




            <Modal
                open={deleteTemplateModal}
                onClose={handleDeleteTemplateModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', width: '375px' }}>
                    <form action="" style={{ width: '100%' }} >

                        <div
                            className={workSpaceStyle.rejectModalText}
                        >
                            <h2>Delete Template</h2>
                            <p>Are you sure you want to Delete this Template?</p>

                            <div
                                className={workSpaceStyle.rejectModalButton}
                            >
                                <button onClick={() => { handleDeleteTemplate(); handleDeleteTemplateModal() }} variant="contained">Delete</button>

                                <p onClick={handleDeleteTemplateModal}>Cancel</p>
                            </div>

                        </div>

                    </form>
                </div>
            </Modal>



        </>
    )
}

export default CommercialCategoryList
