import React, { useState } from 'react'
import headerStyle from './Header.module.css'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SettingsIcon from '@mui/icons-material/Settings';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';

import { Link, useParams } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import workSpaceStyle from '../WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PrivateComponent } from 'api/axios';

const Header = ({ clientName, handleDrawerOpen, isOpen }) => {

  // Initialize privateAxios for making authenticated requests
  const privateAxios = PrivateComponent();
  // State to manage profile expansion panel open/close
  const [headerProfileExpandOpen, setHeaderProfileExpandOpen] = useState(false);
  // Get client parameter from route
  const { client } = useParams();
  // Get authentication state and functions from useAuth hook
  const { auth, setLogout, setOrgLogo } = useAuth();
  // Initialize navigate function for routing
  const navigate = useNavigate();
  // Dispatch function from Redux
  const dispatch = useDispatch();

  // Function to handle navigation to a specific URL
  const handleNavigate = (url) => {
    navigate(url);
  };


  // Function to generate initials from user's name
  const handleInitals = (name) => {
    const initalsArray = name?.split(" ");
    let initial = "";
    if (initalsArray?.length > 0) {
      if (initalsArray?.length > 1) {

        initial = initalsArray[0]?.charAt(0) + initalsArray[1]?.charAt(0);
      }
      else {
        initial = initalsArray[0]?.charAt(0);
      }
    }
    return initial
  }

  // Function to handle user logout
  const handleLogout = () => {
    // Dispatch action to set logout state
    dispatch(setLogout());
    // Navigate to login page after logout
    navigate('/login', { state: { data: null }, replace: true });
  };


  // Function to handle image upload
  const handleImageUpload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (e) => {
      const file = e.target.files[0];

      if (file) {
        handleImage(file);
      }
    };
    input.click();
  };

  // Function to handle image upload to server
  const handleImage = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      // Upload image to server
      await privateAxios.post(`/procurement/uploadOrganisationLogo/${auth.orgId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        // Dispatch action to set organization logo
        dispatch(setOrgLogo({ orgLogo: res.data }));
        // Show success message
        alert('Logo updated successfully.');
      });
    } catch (error) {
      console.error('Error updating logo:', error);
      alert('An error occurred while updating the logo.');
    }
  };


  return (
    <div className={headerStyle.headerMain}>
      <div className={headerStyle.headerContent}>
        <div className={headerStyle.MenuTogglerDiv}>
          <div className={headerStyle.hamBurger} onClick={handleDrawerOpen}><MenuIcon /> </div>
          <div className={headerStyle.headerClientLogo}>
            {auth.orgLogo !== null ?
              <img src={`${process.env.REACT_APP_BASEURL}/procurement/file/${auth.orgLogo.newLogo}`} alt="Company Logo" /> :

              <button className={headerStyle.defaultLogoSty} onClick={handleImageUpload}>Your Logo Here</button>
            }
          </div>
        </div>


        <div className={headerStyle.headerLinks}>
          <div className={headerStyle.headerIcons}>
            {auth.roles !== "USER" &&
              <Link to={`/${client}/users/`}>
                <Tooltip title='Add Person' ><PersonAddAltOutlinedIcon /></Tooltip>
              </Link>
            }
            <Link >
              <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
            </Link>
          </div>
          <div className={headerStyle.headerProfile}
            onClick={() => setHeaderProfileExpandOpen(prev => !prev)}
          >
            {auth.profilePic?.newImage ?
              <img className={headerStyle.smallProfileIcon}
                src={`${process.env.REACT_APP_BASEURL}/adminuser/file/${auth.profilePic.newImage}`}
              />
              :
              <div className={headerStyle.headerProfileimg} >{handleInitals(auth.name)}</div>
            }
            <div className={headerStyle.headerProfileText} >
              <p>
                {auth?.name}
              </p>
              {
                headerProfileExpandOpen
                  ? <KeyboardArrowUpIcon />
                  : <KeyboardArrowDownIcon />
              }
            </div>
          </div>


          {headerProfileExpandOpen &&
            <>

              <div className={workSpaceStyle.workSpaceHeaderDropDownBackDrop} onClick={() => setHeaderProfileExpandOpen(false)}></div>
              <div className={headerStyle.headerProfileExpand} >
                <Link to={"profile"} onClick={() => setHeaderProfileExpandOpen(false)} className={headerStyle.headerProfileExpandHeader} >
                  {auth.profilePic?.newImage ?
                    <img className={headerStyle.smallProfileIcon} style={{ width: "35px", height: '35px' }}
                      src={`${process.env.REACT_APP_BASEURL}/adminuser/file/${auth.profilePic.newImage}`}
                    />
                    :
                    <div className={headerStyle.headerProfileimg} >{handleInitals(auth.name)}</div>
                  }
                  <p>
                    {auth.name}
                  </p>
                </Link>
                <Divider />
                <ul className={headerStyle.headerProfileExpandList}>
                  <Link to={"profile"} onClick={() => setHeaderProfileExpandOpen(false)} ><PermIdentityIcon />Profile</Link>
                  {auth.roles === "KAM" &&
                    <Link to={"companyProfile"} onClick={() => setHeaderProfileExpandOpen(false)}><SettingsIcon />Company Profile</Link>
                  }
                  <Link onClick={() => setHeaderProfileExpandOpen(false)}><InsertDriveFileIcon />Plans</Link>
                </ul>
                <Divider />
                <ul className={headerStyle.headerProfileExpandList}>
                  <Link onClick={(e) => { e.preventDefault(); handleLogout(); }} state={{ data: null }} replace="true"><LogoutIcon />Logout</Link>
                </ul>

              </div>
            </>

          }
        </div>
      </div>
    </div >
  )
}

export default Header
