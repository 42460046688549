import React from 'react'
import RegisterHeaderStyle from './RegisterHeader.module.css'



const RegisterHeader = () => {
  return (
    <div className={RegisterHeaderStyle.header}>
        <div className={RegisterHeaderStyle.headerImg}>
            <img src="https://res.cloudinary.com/dtffniutw/image/upload/v1691730477/steel_1_hjofaw.png" alt="" />
        </div>

    </div>
  )
}

export default RegisterHeader