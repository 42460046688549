import React from 'react'
import { useEffect } from 'react'
import useBackdrop from 'hooks/useBackdrop'
import { useState } from 'react';
import myaxios from 'api/axios'
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { setLogin } from 'state/AuthState';
import { useNavigate } from 'react-router-dom'

// Component for handling redirection after vendor login
const RedirectVendorLogin = () => {
  const dispatch = useDispatch();
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
  const navigate = useNavigate();
  const [tokenDetails, setTokenDetails] = useState("")
  const [token, setToken] = useState("");
  const [redirectURL, setRedirectURL] = useState("");

  // Effect hook to run once after component mounts
  useEffect(() => {
    openBackdrop();// Show backdrop while processing

    // Parse URL parameters to get token and redirect URL
    const params = new URLSearchParams(window.location.search);
    if (params.size > 0 && params.get('token') !== "") {
      let token = params.get('token')
      let redirecturl = params.get('redirecturl')
      const decodedToken = jwt_decode(token);
      setTokenDetails(decodedToken);
      setToken(token);
      setRedirectURL(redirecturl);
    }
    else {
      // If token not found in URL, redirect to login page
      navigate("/login", { replace: true })
    }
  }, [])

  // Function to verify token validity and perform login  
  const verifyToken = async () => {
    try {
      // Verify token with backend API
      await myaxios.post(`/procurement/verifyTokenForRedirectLoginOfVendor`, { token: token }).then(async (res) => {
        const logindata = res.data;

        // Dispatch action to set login state
        dispatch(setLogin({
          token: logindata.accessToken,
          name: logindata.name,
          email: logindata.data.email,
          roles: logindata.data.role.name,
          allData: null,
          userData: logindata.data,
          companyName: null,
          workSpaces: null,
          orgId: null,
          orgLogo: null,
          mobNum: logindata.data.phone,
          profilePic: null,
        }));

        // Redirect to vendor page or custom redirect URL
        if (redirectURL === "" || redirectURL === undefined || redirectURL === null) {
          navigate(`/vendor`, { state: { data: null }, replace: true });
        } else {
          window.location.replace(redirectURL);
        }
      }).catch((error) => {
        // If token verification fails, redirect to login page
        navigate("/login", { replace: true })
      })
    } catch (error) {
      // If an error occurs during token verification, redirect to login page
      navigate("/login", { replace: true })
    }
  };

  // Effect hook to run when token changes
  useEffect(() => {
    if (token !== "") {
      verifyToken(); // Call token verification function
    }
  }, [token])

  return (
    <BackdropComponent /> // Render backdrop component
  )
}

export default RedirectVendorLogin

// The RedirectVendorLogin component handles the redirection after a vendor login attempt.
//  It parses URL parameters to extract the authentication token and redirect URL. 
//  Then, it verifies the token with the backend API and dispatches the user's login details. Finally,
//   it redirects the user to the vendor page or a custom redirect URL upon successful authentication.