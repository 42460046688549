// Importing necessary dependencies and components
import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import ctRevisionStyle from './CommercialTempleteRevisions.module.css'
import useBackdrop from 'hooks/useBackdrop'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatRow from 'components/ChatRows/ChatRow';
import SendForApprovalModal from 'components/SendForApprovalModal/SendForApprovalModal';

// Importing icons and MUI components
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';


// Importing MUI components and custom hooks
import Tooltip from '@mui/material/Tooltip';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import useToast from 'hooks/useToast'
import { PrivateComponent } from 'api/axios'
import useAuth from 'hooks/useAuth';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge'
import BackButton from 'components/BackButton/BackButton'
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation'
import RenameModal from 'components/RenameModal/RenameModal'

// Functional component definition
const CommercialTempleteRevisions = () => {
    // State variables initialization
    const privateAxios = PrivateComponent();
    const { showToast, Toast } = useToast();
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop()
    const location = useLocation();
    const { state } = location;
    const { taskId, productId, tempId } = useParams();
    const navigate = useNavigate();
    const [pageLoading, setPageLoading] = useState(false);
    const { auth } = useAuth();
    const [revisionId, setRevisionId] = useState("");
    const [templeteName, setTempleteName] = useState("");


    // Handling back button click
    const handleBackButton = () => {
        navigate(-1);
    }


    const [revisionSearch, setRevisionSearch] = useState("");


    // ==========================status change -=================

    // State variables for status dropdown
    const [selectedStatus, setSelectedStatus] = useState("");
    const [statusDropDown, setStatusDropDown] = useState(false);

    // Handling status change
    const handleStatusChange = (newStatus) => {
        setSelectedStatus(newStatus);
        handleStatusSortingDropDown();
    };

    // Toggling status dropdown
    const handleStatusSortingDropDown = () => {
        setStatusDropDown(prev => !prev);
    };


    // Function to display status pill with appropriate style
    const handleStatusString = (status) => {

        let statusStyle = {}
        switch (status) {
            case "CREATED": statusStyle = { backgroundColor: "#caf0f8", color: "#023e8a" };
                break;
            case "PENDING": statusStyle = { backgroundColor: "#FFF0C9", color: "#EDBD15" };
                break;
            case "REJECTED": statusStyle = { backgroundColor: "#FFD7D7", color: "#EC5252" };
                break;
            case "APPROVED": statusStyle = { backgroundColor: "#DCFCE7", color: "#1CA35E" };
                break;
            case "DEFAULT": statusStyle = { backgroundColor: "#DCFCE7", color: "#1CA35E" };
                break;

        }


        return (
            <div className={ctRevisionStyle.StatusPillMAin}>
                <span className={ctRevisionStyle.StatusPill} style={statusStyle}>{status?.charAt(0).toUpperCase() + status?.slice(1).toLowerCase()}</span>
            </div>
        )
    }


    // ==========================lastModified change -=================

    // State variables for last modified dropdown
    const [lastModifiedSort, setLastModifiedSort] = useState("");
    const [lastModifiedSortDropDown, setLastModifiedSortDropDown] = useState(false);

    // Handling last modified change
    const handleLastModifiedChange = (newStatus) => {
        setLastModifiedSort(newStatus);
        handleLastModifiedChangeDropDown();
    };

    // Toggling last modified dropdown
    const handleLastModifiedChangeDropDown = () => {
        setLastModifiedSortDropDown(prev => !prev);
    };


    // State variable for revision more
    const [revisionMore, setRevisionMore] = useState("");

    // Handling revision more
    const handleRevisionMore = (id) => {
        if (revisionMore === id) {
            setRevisionMore("")
        }
        else {
            setRevisionMore(id);
        }

    }

    // ===========================get data====================

    // Fetching data from backend
    const [porcureRevisions, setPorcureRevisions] = useState([]);

    const getDataFromProcure = async () => {
        openBackdrop();
        try {
            const res = await privateAxios.get(`/commercial/getAllCommercialRevisions/${productId}/${auth.orgId}/${taskId}/${tempId}`)

            setPorcureRevisions(res.data);
            setTempleteName(res.data[0].name);

        } catch (error) {
            console.log(error);
        }
        closeBackdrop();
    }
    // Fetching data from backend when component mounts
    useEffect(() => {
        getDataFromProcure();
    }, [])



    // =============change revision Name==============================

    // State variables for renaming modal
    const [renameText, setRenameText] = useState("");
    const [renameModal, setRenameModal] = useState(false);

    // Handling renaming modal
    const handleRenameModal = (data) => {
        setRenameModal(prev => !prev)
        if (data !== null && data !== undefined && data !== "") {

            setRevisionId(data.revId);
            setRenameText(data.name);
        }
    }

    // Handling change of template name
    const handleChangeTempleteName = async (data) => {
        try {
            const res = await privateAxios.post(`/commercial/renameCommercialRevision/${revisionId}?name=${data.name}`)
            if (res.status == 200) {

                getDataFromProcure();
                showToast("success", "Name Update Successfull");
                handleRenameModal();
            }
        } catch (error) {
            console.log(error);
            showToast("error", "Name Update failed22");

        }

    }

    // ============duplicate revision==============================

    // State variables for duplicate revision modal
    const [duplicateNameModal, setDuplicateNameModal] = useState(false);
    const [duplicateName, setDuplicateName] = useState("");

    // Handling duplicate revision modal
    const handleDuplicateNameModal = (data) => {
        setDuplicateNameModal(prev => !prev);
        if (data !== null && data !== undefined && data !== "") {

            setRevisionId(data.revId);
            setDuplicateName(data.name);
        }
    }

    // Handling duplicate revision creation
    const handleDuplicateRevisison = async (data) => {

        try {
            const res = await privateAxios.post(`/commercial/duplicateCommercialRevision/${revisionId}?name=${data.name}`);
            await getDataFromProcure();
            showToast("success", "Duplicate Revision Created");
            handleDuplicateNameModal();
        } catch (error) {
            showToast("error", "Error Creating Revision Duplicate");
            handleDuplicateNameModal();
        }

    }

    // ================delete Revision================================

    // State variables for delete revision modal
    const [deleteModal, setDeleteModal] = useState(false);

    // Handling delete revision modal
    const handleDeleteModal = (id) => {
        setDeleteModal(prev => !prev)
        setRevisionId(id);
    }


    // Handling delete revisio
    const handleDeleteRevision = async () => {
        try {
            const res = await privateAxios.delete(`/commercial/deleteCommercialRevision/${revisionId}`);
            console.log(res.data);
            await getDataFromProcure();
            showToast("success", "deleting Revision Successfull");

        } catch (error) {
            showToast("error", "Error deleting Revision");
        }
    }



    // State variables for chat functionality
    const [chatSlider, setChatSlider] = useState(false);
    const [revisionChats, setRevisionChats] = useState([]);
    const [chatValue, setChatValue] = useState("");

    // State variable for revision data
    const [revData, setRevData] = useState("");

    // Handling chat slider
    const handleChatSlider = (revData) => {
        setChatSlider(prev => !prev);
        setRevData(revData)
    }

    // Handling sending chat message
    const handleChatSend = async (e, revId) => {
        e.preventDefault();
        if (chatValue !== "") {
            try {
                const res = await privateAxios.post(`/commercial/addRemarkOncommercialRevision/${auth.userData.id}/${revData.revId}`, { remark: chatValue })
                if (res.status === 200) {
                    getRevisionChat();
                    setChatValue("")
                }
            } catch (error) {
                console.log(error);
                showToast("error", "error adding Comment")

            }
        }
    }

    // Fetching revision chat data
    const getRevisionChat = async () => {
        handleChatNotification();
        try {
            const res = await privateAxios.get(`/commercial/getRemarksOfcommercialRevision/${revData.revId}`)
            setRevisionChats(res.data);
        } catch (error) {
            console.log(error);
            showToast("error", "error getting Chats");
        }
    }
    useEffect(() => {
        if (chatSlider === true && revData !== "") {

            getRevisionChat(revData.revId)
        }

    }, [chatSlider, revData])



    // ==============send to Kam=====================================

    const [approvalModal, setApprovalModal] = useState(false);
       // Handling Approval  modal
    const handleApprovalModal = (data) => {
        setApprovalModal(prev => !prev)
        setRevData(data)
    }

    const handleAppovalSubmit = async (data) => {

        openBackdrop();

        try {
            await privateAxios.post(`/commercial/sendCommercialRevisionForApprovalToKAM/${auth.userData.id}/${revData.revId}`, { comments: data.name }).then((res) => {
                handleApprovalModal()
                showToast("success", "Revision Sent For Approval")

            }).catch((err) => {
                showToast("error", "Error Sendind Revision to Kam")
            })
        } catch (error) {
            showToast("error", "Error Sendind Revision to Kam")
        }

        getDataFromProcure();
        closeBackdrop();
    }


    // =====================chat notification========================

    // Handling chat notification
    const [reRender, setReRender] = useState(false);
    const handleChatNotification = () => {
        setReRender(prev => !prev);
    }


    return (
        <>
            <Toast />
            <BackdropComponent />
            {
                pageLoading ?
                    <div className={workSpaceStyle.workSpaceMain}>
                        <div style={{ width: "100%", display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
                            Loading...
                        </div>
                    </div>
                    :
                    <div className={workSpaceStyle.workSpaceMain}>

                        <div className={workSpaceStyle.workSpaceHeader}>
                            <div className={workSpaceStyle.workSpaceHeaderLeft}>
                                <h1><BackButton />{templeteName || "No Name"}</h1>
                                <p>Revisons <span>{porcureRevisions.length}</span></p>
                                {/* <p>Revisons <span>{handleRevisionCount()}</span></p> */}

                            </div>
                            <div className={workSpaceStyle.workSpaceHeaderSearchbar}>
                                <input type="text" placeholder='Search Revisions...' value={revisionSearch} onChange={(e) => setRevisionSearch(e.target.value)} />
                            </div>
                            <div className={workSpaceStyle.workSpaceHeaderRight}>


                                <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
                                    {auth.roles === 'USER' && (
                                        <Tooltip title='Help'>
                                            <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081393-commercial-revision-display">
                                                <HelpOutlineOutlinedIcon />
                                            </a>
                                        </Tooltip>

                                    )}

                                </div>
                            </div>

                        </div>

                        <div className={ctRevisionStyle.tableMain}>

                            <table className={ctRevisionStyle.taskCategoryTable}>
                                <thead>
                                    <tr className={ctRevisionStyle.taskCategoryTableHeader}>
                                        <th>Title </th>
                                        <th style={{ position: "relative" }} ><div className={ctRevisionStyle.headerSorter} onClick={handleLastModifiedChangeDropDown}>
                                            <p>
                                                Last Modified
                                            </p>
                                            <span>
                                                {lastModifiedSortDropDown
                                                    ? <KeyboardArrowUpIcon />
                                                    : <KeyboardArrowDownIcon />
                                                }</span>
                                        </div>
                                            {lastModifiedSortDropDown &&
                                                <>
                                                    <div className={workSpaceStyle.popsBackDrop} onClick={handleLastModifiedChangeDropDown}>

                                                    </div>
                                                    <ul className={ctRevisionStyle.headerSorterList} style={{ position: "absolute", top: "110%" }} >
                                                        <li onClick={() => handleLastModifiedChange('lastest')}>Latest</li>
                                                        <li onClick={() => handleLastModifiedChange('oldest')}>Oldest</li>


                                                    </ul>
                                                </>
                                            }
                                        </th>
                                        <th style={{ position: "relative" }}>
                                            <div className={ctRevisionStyle.headerSorter} onClick={handleStatusSortingDropDown}>
                                                <p> Status </p> <span>
                                                    {statusDropDown
                                                        ? <KeyboardArrowUpIcon />
                                                        : <KeyboardArrowDownIcon />
                                                    }</span>
                                            </div>
                                            {statusDropDown &&
                                                <>
                                                    <div className={workSpaceStyle.popsBackDrop} onClick={handleStatusSortingDropDown}>

                                                    </div>
                                                    <ul className={ctRevisionStyle.headerSorterList} style={{ position: "absolute", top: "110%" }} onChange={(e) => handleStatusChange(e.target.value)}>
                                                        <li onClick={() => handleStatusChange('')}>All</li>
                                                        <li onClick={() => handleStatusChange('Created')}>Created</li>
                                                        <li onClick={() => handleStatusChange('Pending')}>Pending</li>
                                                        <li onClick={() => handleStatusChange('Rejected')}>Rejected</li>
                                                        <li onClick={() => handleStatusChange('Approved')}>Approved</li>

                                                    </ul>
                                                </>
                                            }
                                        </th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {porcureRevisions.map((row) => {

                                        return (


                                            <tr className={ctRevisionStyle.taskCategoryTableData}>
                                                <td onClick={() => navigate(`${row.id}`)}>{row.name}</td>
                                                <td>{row.lastModified?.split("T")[0]}</td>
                                                <td>{handleStatusString(row.revisionStatus)}</td>
                                                <td >
                                                    <div className={ctRevisionStyle.actionTd}>
                                                        {
                                                            !row.default
                                                            &&
                                                            <span onClick={() => handleChatSlider({ revId: row.id, name: row.name })}>
                                                                <Tooltip title='Chats' >

                                                                    <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialRevision`} id={row.id} reRender={reRender} />


                                                                </Tooltip>
                                                            </span>
                                                        }
                                                        <span>
                                                            <EditIcon onClick={() => navigate(`${row.id}`, { state: { from: "PRVIEW" } })} />
                                                        </span>
                                                        {
                                                            !row.default
                                                            &&

                                                            <span style={{ position: "relative" }}>
                                                                <MoreVertIcon onClick={() => handleRevisionMore(row.id)} />
                                                                {
                                                                    revisionMore === row.id &&
                                                                    <ThreeDotDropDown toggle={handleRevisionMore}>
                                                                        <li
                                                                            onClick={() => { handleRevisionMore(); handleApprovalModal({ revId: row.id, name: row.name }) }}
                                                                        > <span><PersonAddAltOutlinedIcon /></span>Send for Approval</li>
                                                                        <li
                                                                            onClick={() => { handleRenameModal({ revId: row.id, name: row.name }); handleRevisionMore(); }}
                                                                        > <span><DriveFileRenameOutlineIcon /></span> Rename</li>
                                                                        <li

                                                                            onClick={() => { handleDuplicateNameModal({ revId: row.id, name: row.name }); handleRevisionMore() }}
                                                                        ><span><ContentCopyIcon /></span>Duplicate</li>
                                                                        {/* <li

                                                                            onClick={() => { showToast("caution", "something happened"); handleRevisionMore() }}
                                                                        ><span><ContentCopyIcon /></span>Move</li>
                                                                        <li
                                                                            onClick={() => { handleRevisionMore() }}
                                                                        ><span><FileDownloadOutlinedIcon /></span>Download</li> */}
                                                                        <li
                                                                            onClick={() => { handleDeleteModal(row.id); handleRevisionMore() }}
                                                                        ><span><DeleteOutlineIcon /></span>Delete</li>
                                                                    </ThreeDotDropDown>
                                                                }

                                                            </span>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>


                            </table>


                        </div>




                    </div>



            }


            <RenameModal
                open={renameModal}
                onClose={handleRenameModal}
                onSubmit={handleChangeTempleteName}
                prefilled={renameText}
                header="Rename"
            />

            <RenameModal
                open={duplicateNameModal}
                onClose={handleDuplicateNameModal}
                onSubmit={handleDuplicateRevisison}
                prefilled={duplicateName}
                header="Duplicate"
            />





            {/* delete Confirmaton */}

            <DeleteConfirmation
                onCancel={handleDeleteModal}
                onConfirm={() => { handleDeleteRevision(); handleDeleteModal() }}
                open={deleteModal}
            // confimBtnText={""} 
            // discardText={""}
            >
                <h2>Delete Revision</h2>
                <p>Are you sure you want to delete this Revision?</p>

            </DeleteConfirmation>





            <ChatPannel
                open={chatSlider}
                onClose={handleChatSlider}
            >
                <>
                    <ChatHeader onClose={handleChatSlider}
                        downloadData={
                            revisionChats.map((chat) => { return { name: chat.name, date: chat.createdAt, chat: chat.remark } })
                        }
                        helpData={{ link: "tosomewhere" }}
                        chatName={revData?.name}
                    >
                        <h3>{revData?.name}</h3>
                    </ChatHeader>

                    <ChatRows>
                        {/* <ChatRow name="Ravinder" chat="soemfhdf a saf sa" date="12-04-2023" index="1"/> */}
                        {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
                            return (

                                <ChatRow name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} />
                            )
                        })
                            :
                            <p style={{ textAlign: "center" }}>
                                No Previous Chat
                            </p>
                        }

                    </ChatRows>

                    <ChatInputField revisionId={revData?.revId} onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

                </>
            </ChatPannel>
            {/* chat */}

            {/* approval mOdal */}

            <SendForApprovalModal
                open={approvalModal}
                onClose={handleApprovalModal}
                onSubmit={handleAppovalSubmit}
                prefilled={""}
                header="Send For Approval"
            />





            {/* approval mOdal */}

        </>

    )
}

export default CommercialTempleteRevisions
