import { useState, useEffect, useRef } from 'react';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import { PrivateComponent } from 'api/axios'
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import VendorApprovastyle from "../Approvals/VendorApproval.module.css"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import useBackdrop from 'hooks/useBackdrop';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChatRow from '../../components/ChatRows/ChatRow';
import { createExcel } from 'utility/excelUtils'
import DeleteIcon from '@mui/icons-material/Delete';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge.js';
import useToast from 'hooks/useToast.js';
import DateFormater from 'utility/DateFormater';
import SendToApprovalCommityModal from 'components/SendToApprovalCommityModal/SendToApprovalCommityModal';
import RenameModal from 'components/RenameModal/RenameModal';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';

const nameData = ([])


const VendorApproval = ({ handleVendorRevisionSelect, searchValue, selectedVendorRevisions, setVendorApprovalCount, compareModal, setSelectedVendorRevisions, handleCompareNameModal, groupModal, handleGroupNameModal }) => {
    // React hooks and state declarations
    const { auth } = useAuth();
    const navigate = useNavigate();
    const privateAxios = PrivateComponent();
    const [data, setData] = useState([]); // State to store data
    const { showToast, Toast } = useToast(); // Toast notifications
    const { BackdropComponent, closeBackdrop, openBackdrop } = useBackdrop(); // Backdrop component and methods
    const [revisionMore, setRevisionMore] = useState(""); // State for revision more options
    const [sendVendor, setSendVendor] = useState(false); // State for sending vendor


    const handlesendVendor = () => {
        setSendVendor(prev => !prev);
    }

    const [openMassege, setOpenMassege] = useState(false);// State for message modal

    const handleMassege = () => {
        setOpenMassege(prev => !prev);
    }
    const [multiClick, setMultiClick] = useState(false);// State for multi-click
    const handleMultiClick = () => {
        setMultiClick(prev => !prev)
    }
    const [multiClick2, setMultiClick2] = useState(false);// State for another multi-click
    const handleMultiClick2 = () => {
        setMultiClick2(prev => !prev)
    }


    const [moreDropdown, setMoreDropdown] = useState(null);// State for more dropdown

    // Function to toggle more dropdown
    const toggleMoreDrop = (index) => {

        if (moreDropdown === index) {
            setMoreDropdown(null);
        } else {
            setMoreDropdown(index);
        }
    };

    // Table Sorters state and functions
    const [sorterData, setSorterData] = useState();
    const [sortedData, setSortedData] = useState([]);
    const [sorterOpen, setSorterOpen] = useState("");


    // Function to toggle table sorter
    const handleTableSorterToggle = (sorter) => {

        if (sorterOpen === sorter) {
            setSorterOpen("");
        }
        else {
            setSorterOpen(sorter);
        }
    }

    useEffect(() => {
        setSortedData(data);
    }, [data]);


    // Function to handle sorting based on different criteria
    const handleSorting = (value, type) => {

        // console.log(value);
        switch (type) {
            case "product": setSortedData(data.filter((dd) => dd.productName === value));

                break;
            case "fileType": setSortedData(data.filter((dd) => dd.vendorRevisionType === value));

                break;
            case "category": setSortedData(data.filter((dd) => dd.type === value));

                break;
            case "submitedOn": dateSorting("submitedOn", value);
                break;
            case "ownedBy": setSortedData(data.filter((dd) => dd.sentTo === value));
                break;
            case "lastModified": dateSorting("lastModified", value);
                break;
            default: clearSorting();
        }

        setSorterOpen("");
    }

    // Function to clear sorting
    const clearSorting = () => {
        setSortedData(data);
        setSorterOpen("");
    }

    // Function for date sorting
    const dateSorting = (type, value) => {
        // console.log(value);
        let sortedData = []
        if (type === "submitedOn") {
            if (value === "asc") {
                sortedData = [...data].sort((a, b) => {
                    return new Date(a.submittedOn) - new Date(b.submittedOn);
                });
            }
            else {
                sortedData = [...data].sort((a, b) => {
                    return new Date(b.submittedOn) - new Date(a.submittedOn);
                });
            }

        }
        else {
            if (value === "asc") {
                sortedData = [...data].sort((a, b) => {
                    return new Date(a.lastModified) - new Date(b.lastModified);
                });
            }
            else {
                sortedData = [...data].sort((a, b) => {
                    return new Date(b.lastModified) - new Date(a.lastModified);
                });
            }

        }

        setSortedData(sortedData);
    }


    // Popup states and functions
    const [isPopupOpen, setPopupOpen] = useState(false);

    const openPopup = () => {
        setPopupOpen(true);
    };

    const closePopup = () => {
        setPopupOpen(false);
    };


    // Chat states and functions
    const [revId, setRevId] = useState("")
    const [chatSlider, setChatSlider] = useState(false)
    const [chatValue, setChatValue] = useState("");
    const [revisionChats, setRevisionChats] = useState([]);
    const [revData, setRevData] = useState("");

    // Function to handle chat slider
    const handleChatSlider = (revData) => {
        setChatSlider(prev => !prev)
        setRevData(revData);


    }
    useEffect(() => {
        // Fetch revision chats when chat slider is true and revision data is available
        if (chatSlider === true && revData !== "") {
            getRevisionChats(revData.id, revData.type);
        }
    }, [chatSlider])

    // Function to handle chat sending
    const handleChatSend = async (e, revId) => {
        e.preventDefault();
        try {
            let url = ""; // Define the API endpoint based on file type
            switch (revData.type.toLowerCase()) {
                case "commercial":
                    url = `/commercial/addRemarkOnCommercialRevisionByVendor`;
                    break;
                case "technical specification":
                    url = `/technicalSpecification/addRemarkOnVendorRevisionByKam`;
                    break;
                case "procure":
                    url = ``;
                    break;
                case "approval":
                    url = ``;
                    break;
                default:
                    return;
            }

            if (chatValue.length > 0) {
                await privateAxios.post(`${url}/${auth.userData.id}/${revData.id}`, { remark: chatValue }).then(res => {

                    console.log("idddddd", res);
                    console.log("chatttttt", res)
                    getRevisionChats(revId);
                    setChatValue("");
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Function to fetch revision chats
    const getRevisionChats = async (revId, type) => {
        handleChatNotification();
        // openBackdrop();
        try {


            let url = ""
            switch (revData.type.toLowerCase()) {
                case "commercial": url = `/commercial/getRemarksOfCommercialVendorRevision`;
                    break;
                case "technical specification": url = `/technicalSpecification/getRemarksOfRevisionOfVendorandKam`;
                    break;
                case "procure": url = ``;
                    break;
                case "approval": url = ``;
                    break;
                default: return;
            }

            await privateAxios.get(`${url}/${revId}`).then(res => {
                // handleChatNotification();
                setRevisionChats(res.data)
                console.log("chatttttt", res)
            })

        } catch (error) {
            // console.log(error)
        }
        // closeBackdrop();
    }


    const getAllRevisionsForApprovalOfVendor = async () => {
        openBackdrop()
        try {
            // Make a GET request to the API endpoint with empId as a parameter
            await privateAxios.get(`/technicalSpecification/getAllVendorRevisionsInApproval/${auth.userData.id}`)
                .then((res) => {


                    const groupedObject = {};

                    // Iterate through each object in the array
                    res.data.forEach(obj => {
                        for (const key in obj) {
                            const value = obj[key];

                            // Check if the key is already in the groupedObject
                            if (key in groupedObject) {
                                // If it's an array and the value is not already in it, push the value
                                if (Array.isArray(groupedObject[key]) && !groupedObject[key].includes(value)) {
                                    groupedObject[key].push(value);
                                } else if (!Array.isArray(groupedObject[key]) && groupedObject[key] !== value) {
                                    // If it's not an array and the value is different, create an array and add both values
                                    groupedObject[key] = [groupedObject[key], value];
                                }
                            } else {
                                // If the key is not in groupedObject, add it with the value
                                groupedObject[key] = [value];
                            }
                        }
                    });
                    // console.log(groupedObject)
                    // Now groupedObject contains the grouped attributes
                    setSorterData(groupedObject);
                    setData(res.data);
                    setVendorApprovalCount(res.data.length)


                })
                .catch((error) => {
                    console.error('Error handling response:', error);
                });
            // setData(revisionsData);
            closeBackdrop()
        } catch (error) {
            closeBackdrop()
            console.error('Error fetching revisions for approval:', error);
        }
    };

    useEffect(() => {
        // Fetch revisions for approval when the component mounts
        getAllRevisionsForApprovalOfVendor();
    }, []);

    const formatDateString = (dateString) => {
        const dateObject = new Date(dateString);
        // Use JavaScript's Date methods to format the date as needed
        const formattedDate = dateObject.toLocaleDateString(); // This will give you the date in "MM/DD/YYYY" or "DD/MM/YYYY" format
        return formattedDate;
    }

    // State for checkbox all
    const [checkBoxAll, setCheckBoxAll] = useState(false)

    // Function to handle comparing vendor revisions
    const handleCompareVendorRevision = async (data) => {
        openBackdrop();
        try {

            const revisionIds = selectedVendorRevisions.map((rev) => { return rev.id });
            const names = selectedVendorRevisions[0].productName
            const ptype = selectedVendorRevisions[0].type

            await privateAxios.post(`/technicalSpecification/compareVendorRevisions/${auth.userData.id}`, {
                ids: revisionIds,
                name: data.name,
                productName: names,
                type: ptype

            }).then((res) => {

                handleCompareNameModal();
                setSelectedVendorRevisions([])
                getAllRevisionsForApprovalOfVendor();
                showToast("success", "Comparision Created")
            })
        } catch (error) {
            handleGroupNameModal();
            alert("Error Creating Group");
            showToast("error", "Error Creating Comparision")
        }
        // console.log("compare function called");
        closeBackdrop();

    }

    // Function to handle grouping vendor revisions
    const handleGroupVendorRevision = async (data) => {
        openBackdrop();
        try {
            const revisionId = selectedVendorRevisions.map((rev) => { return rev.id });
            //  console.log(revisionId)
            await privateAxios.post(`/technicalSpecification/groupVendorRevisions/${auth.userData.id}`, {
                ids: revisionId,
                name: data.name
            }).then((res) => {
                // console.log("Group function called");

                handleGroupNameModal();
                setSelectedVendorRevisions([]);
                getAllRevisionsForApprovalOfVendor();
                showToast("success", "Group Created")
            })
        } catch (error) {
            handleGroupNameModal();
            alert("Error Creating Group");
            showToast("error", "Error Creating Group")
        }

        closeBackdrop();
    }

    // Function to handle select all toggle
    const handleSelectAllToggle = () => {

        if (selectedVendorRevisions.length === data.length) {
            setSelectedVendorRevisions([]);
            setCheckBoxAll(false);
        }
        else {
            setSelectedVendorRevisions(data);
            setCheckBoxAll(true);
        }
    }
    useEffect(() => {
        // Update checkbox all state based on selected revisions
        if (selectedVendorRevisions.length === data.length) {
            setCheckBoxAll(true);
        }
        else {
            setCheckBoxAll(false);
        }

    }, [selectedVendorRevisions, data])

    // Function to handle navigation
    const handleNavigate = (id, fileType, category) => {

        if (fileType === "GROUP") {
            navigate(`group/${id}`)
        }
        else {


            if (category.toLowerCase() === "technical specification") {
                if (fileType === "PRODUCT") {
                    navigate(`vendor/${id}?tab=0`)
                }

                else if (fileType === "COMPARISION") {
                    navigate(`compare/${id}?tab=0`)
                }
            }
            else if (category.toLowerCase() === "commercial") {
                if (fileType === "PRODUCT") {
                    navigate(`vendor/commercial/${id}?tab=0`)
                } else if (fileType === "COMPARISION") {
                    navigate(`vendor/commercial/compare/${id}/?tab=0`)
                }
            }
        }
    }

    // Function to handle file type display
    const handleFileType = (fileType) => {

        if (fileType === "PRODUCT") {
            return (
                <span style={{ padding: "2px 10px", fontWeight: "500", borderRadius: "4px" }}>Product</span>
            )
        }
        else if (fileType === "GROUP") {
            return (
                <span style={{ backgroundColor: "#EEEEEE", fontWeight: "500", padding: "2px 10px", borderRadius: "4px" }}>Group</span>
            )
        }
        else if (fileType === "COMPARISION") {
            return (
                <span style={{ backgroundColor: "#16469D", fontWeight: "500", color: "white", padding: "2px 10px", borderRadius: "4px" }}>Comparision</span>
            )
        }
    }

    // Function to toggle revision more options
    const handleToggle = (id) => {
        if (revisionMore === id) {
            setRevisionMore("")
        }
        else {
            setRevisionMore(id);
        }
    }

    // Function to handle last seen time
    const handleLastSeen = (loginTime) => {
        const loginDate = new Date(loginTime);
        const currentDate = new Date();
        const timeDifference = currentDate - loginDate;
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
        const daysDifference = Math.floor(hoursDifference / 24);

        let displayString;

        if (daysDifference > 0) {
            // If the difference is more than 1 day, display the date
            displayString = `${loginDate.toDateString()}`;
        } else if (hoursDifference > 0) {
            // If the difference is more than 1 hour, display the hours
            displayString = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else if (minutesDifference > 0) {
            // If the difference is more than 1 minute, display the minutes
            displayString = `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
        } else {
            // If the login was within the last minute, display "Just now"
            displayString = "Just now";
        }

        return displayString;
    }
    // =====================chat notification========================

    // Function to handle chat notification
    const [reRender, setReRender] = useState(false);

    const handleChatNotification = () => {
        setReRender(prev => !prev);

    }

    // =====================chat notification========================

    const [documents, setDocuments] = useState([])
    const [tableRows, setTableRows] = useState([]);

    // Function to handle downloading revision data as an Excel file
    const handleDownloadRevision = () => {

        // extracting text from Richeditor string
        let tempElement = document.createElement('div');
        tempElement.innerHTML = specificationValue;
        let extractedText = tempElement.textContent;
        extractedText = extractedText.trim();

        // taking nessarry feilds from attributes
        const Attributes = tableRows.map((attri) => {
            const modifiedAttri = { ...attri };
            delete modifiedAttri.id;

            const remarks = {};
            attri.remark.forEach((rem, index) => {
                remarks[`Remark ${index}`] = rem.value;
            });
            delete modifiedAttri.remark;
            return { ...modifiedAttri, ...remarks };

        });
        console.log("aa", tableRows)
        console.log(Attributes)
        // documents
        let revisionDocument = []
        if (revId === "0") {

            revisionDocument = documents.map((doc) => {
                return {
                    name: "Not Available",
                    link: doc.url,
                    type: "normal"

                }
            })

        }
        else {
            revisionDocument = documents.map((doc) => {
                return {
                    name: "Not Available",
                    link: doc.url,
                    type: "normal"

                }
            })
        }

        createExcel([[{ Specification: extractedText }], Attributes, revisionDocument],
            ("Revision 0")
        )
    }

    const [specificationValue, setSpecificationValue] = useState('');
    const handelSpecification = () => {
        // console.log(specificationValue)
    }

    // ========================send for approval states=====================

    // State for send for approval modal
    const [sendForApprovalModal, setSendForApproalModal] = useState(false);

    // Function to toggle send for approval modal
    const handleSendForApprovalModal = (revData) => {
        setSendForApproalModal(prev => !prev);
        if (revData !== undefined && revData !== null && revData !== "") {

            setRevData(revData);
        }
    }





    // ========================send for approval states=====================

    // State for delete modal
    const [deleteModal, setDeleteModal] = useState(false);

    // Function to toggle delete modal and set revision ID
    const handleDeleteModal = (id) => {
        setDeleteModal(prev => !prev);
        if (id !== undefined && id !== null && id !== "") {

            setRevId(id);
        }
    }


    // Function to handle deletion of a revision
    const handleDeleteRevision = async () => {
        openBackdrop();
        try {
            const res = await privateAxios.delete(`/technicalSpecification/deleteGroupOrComparision/${revId}`)
            handleDeleteModal();
            getAllRevisionsForApprovalOfVendor();
            showToast("success", "Revision Deleted");

        }
        catch (error) {
            console.log(error)
            showToast("error", "Error Deleting Revision")
        }

        closeBackdrop();

    }


    // ========================delete =====================

    // State for rename modal
    const [renameModal, setRenameModal] = useState(false);
    const [renameData, setRenameData] = useState({});
    // Function to toggle rename modal and set data for renaming
    const handleRenameModal = (data) => {
        setRenameModal(prev => !prev);
        console.log(data);
        if (data !== undefined && data !== null && data !== "") {

            setRenameData(data);
        }
    }

    // Function to handle renaming of a revision
    const handleRenameRevision = async (data) => {
        openBackdrop();
        try {
            const res = await privateAxios.post(`/technicalSpecification/renameGroupOrComparison/${renameData.id}?name=${data.name}`)
            handleRenameModal();
            getAllRevisionsForApprovalOfVendor();
            showToast("success", "Revision Renamed Successfully");

        }
        catch (error) {
            console.log(error)
            showToast("error", " Error Renaming Revision")
        }


        closeBackdrop();
    }

    // ========================rename =====================

    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={VendorApprovastyle.approvalVendorMain}>
                <table className={VendorApprovastyle.approvalVendorTable}>
                    <thead>
                        <tr className={`${VendorApprovastyle.tableHead}`} style={{ zIndex: '2' }}>


                            <td className={`${VendorApprovastyle.tableColumnSticky}`} style={{ minWidth: "200px" }}>
                                <div className={VendorApprovastyle.tdRow}>
                                    <input type="checkbox" onChange={() => handleSelectAllToggle()} checked={checkBoxAll} />
                                    <p>Name</p>
                                </div>

                            </td>

                            <td >
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("product")}>Products
                                        <span>{sorterOpen === "product" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "product" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("product")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.productName?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "product")} >{ss === null ? "Group" : ss}</li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </>
                                    }

                                </div>

                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("type")}>File Type
                                        <span>{sorterOpen === "type" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "type" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("type")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.vendorRevisionType?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "type")} >{ss === null ? "Group" : ss}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("category")}> Category
                                        <span>{sorterOpen === "category" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "category" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("category")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    // sorterData.sentBy.map((ss)=>{
                                                    sorterData?.type?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "category")} >{ss}</li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("submitedOn")}>Submited On
                                        <span>{sorterOpen === "submitedOn" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "submitedOn" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("submitedOn")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                <li onClick={() => handleSorting("asc", "submitedOn")} >Latest</li>
                                                <li onClick={() => handleSorting("desc", "submitedOn")} >Oldest</li>

                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("ownedBy")}>Owned  By
                                        <span>{sorterOpen === "ownedBy" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "ownedBy" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("ownedBy")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.sentTo?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "ownedBy")} >{ss === null ? "Null" : ss}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("lastUpdated")}>Last Updated
                                        <span>{sorterOpen === "lastUpdated" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "lastUpdated" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("lastUpdated")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                <li onClick={() => handleSorting("asc", "lastModified")} >Latest</li>
                                                <li onClick={() => handleSorting("desc", "lastModified")} >Oldest</li>

                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td
                            // style={{textAlign:"right"}}
                            >
                                Actions
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {console.log(sortedData)} */}
                        {sortedData.filter((rev) => rev.name.trim().toLowerCase().includes(searchValue.toLowerCase()) || rev?.productName?.trim().toLowerCase().includes(searchValue.toLowerCase())).map((row, index) => {
                            const isChecked = selectedVendorRevisions.some((selectedRow) => selectedRow.id === row.id);
                            return (
                                <tr key={index} className={`${VendorApprovastyle.tableRow}`}>
                                    <td className={`${VendorApprovastyle.tableColumnSticky2} `}>
                                        <div className={VendorApprovastyle.tdRow}>
                                            <input type="checkbox" onChange={() => handleVendorRevisionSelect(row)} checked={isChecked} />
                                            <p>{row.name}</p>
                                        </div>
                                    </td>

                                    <td >{row.productName}</td>
                                    <td >{handleFileType(row.vendorRevisionType)}</td>
                                    <td >{row.type}</td>
                                    <td >{DateFormater(row.submittedOn)}</td>
                                    <td >
                                        <div className={VendorApprovastyle.tdRow}>
                                            <ProfileIcon data={{ email: row.vendorEmail }} height={"26px"} width={"26px"} showProfileBanner={true} /> {row.sentTo}
                                        </div>
                                    </td>
                                    <td >{handleLastSeen(row.lastModified)}</td>
                                    <td >
                                        <div className={VendorApprovastyle.actionsRow}>

                                            <span onClick={() => handleSendForApprovalModal({ id: row.id, type: row.type })}>
                                                <PersonAddAltIcon />
                                            </span>
                                            {/* <span onClick={()=>navigate(`vendor/${row.id}?tab=0`)}> */}
                                            <span onClick={() => handleNavigate(row.id, row.vendorRevisionType, row.type)}>
                                                <RemoveRedEyeIcon />
                                            </span>

                                            {row.vendorRevisionType === "PRODUCT" && <Tooltip title='Comments'>

                                                <span onClick={() => handleChatSlider({ id: row.id, type: row.type, name: row.name })}>

                                                    {row?.type?.toLowerCase() === 'technical specification'
                                                        ?
                                                        <ChatIconWithBadge url={`/technicalSpecification/getNotficationCountOfVendorTsRevision`} id={row.id} reRender={reRender} />
                                                        :
                                                        <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialVendorRevision`} id={row.id} reRender={reRender} />


                                                    }

                                                </span>

                                            </Tooltip>}




                                            {
                                                row.vendorRevisionType !== "PRODUCT" &&
                                                <span style={{ position: "relative" }} onClick={() => handleToggle(row.id)}>
                                                    <MoreVertIcon />
                                                    {

                                                        revisionMore === row.id &&
                                                        <ThreeDotDropDown toggle={() => handleToggle(row.id)} >


                                                            {/* <li>Move</li> */}
                                                            {/* <li onClick={handleDownloadRevision}>Download</li> */}
                                                            <li onClick={() => handleDeleteModal(row.id)}><span><DeleteIcon /></span> Delete</li>
                                                            <li onClick={() => handleRenameModal({ id: row.id, name: row.name })}><span><DriveFileRenameOutlineIcon /></span> Rename</li>
                                                        </ThreeDotDropDown>
                                                    }

                                                </span>}
                                        </div>
                                    </td>
                                </tr>
                            )
                        }).reverse()
                        }

                    </tbody>
                </table>



            </div>
            <ChatPannel
                open={chatSlider}
                onClose={handleChatSlider}
            >
                <>
                    <ChatHeader onClose={handleChatSlider}

                        downloadData={revisionChats.map((chat) => {
                            return { name: chat.name, chat: chat.remark, date: chat.createdAt, };
                        })}
                        helpData={{ link: "tosomewhere" }}
                        chatName={revData.name}

                    >
                        <h3>{revData?.name} </h3>
                    </ChatHeader>

                    <ChatRows >
                        {/* <ChatRow name="Ravinder" chat="soemfhdf a saf sa" date="12-04-2023" index="1"/> */}
                        {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
                            return (

                                <ChatRow name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} />
                            )
                        })
                            :
                            <p style={{ textAlign: "center" }}>
                                No Previous Chat
                            </p>
                        }
                    </ChatRows>

                    <ChatInputField revisionId={revData.id} onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

                </>
            </ChatPannel>

            {/* Compare Nmae MoDAL */}

            <RenameModal
                open={renameModal}
                onClose={handleRenameModal}
                onSubmit={handleRenameRevision}
                prefilled={renameData?.name}
                header="Rename"
            />
            <RenameModal
                open={compareModal}
                onClose={handleCompareNameModal}
                onSubmit={handleCompareVendorRevision}
                prefilled={""}
                header="Comparision Name"
            />
            <RenameModal
                open={groupModal}
                onClose={handleGroupNameModal}
                onSubmit={handleGroupVendorRevision}
                prefilled={""}
                header="Group Name"
            />


            <DeleteConfirmation
                onCancel={handleDeleteModal}
                onConfirm={() => { handleDeleteRevision(); }}
                open={deleteModal}
            // confimBtnText={""} 
            // discardText={""}
            >
                <h2>Delete</h2>
                <p>Are you sure you want to delete?</p>

            </DeleteConfirmation>



            {/* Group Name Modal */}


            <SendToApprovalCommityModal
                open={sendForApprovalModal} onClose={handleSendForApprovalModal}
                showToast={showToast}
                revisionId={revData.id}

                header={"Send for Approval"}
            />


        </>
    )
}

export default VendorApproval




