import React from 'react'
import styleDemo from './ProcaDemo.module.css'
import Button from '@mui/material/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';


const ProcaDemo = () => {

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className={styleDemo.demoMain}>
            <div className={styleDemo.demoDiv}>
                <div className={styleDemo.information}>
                    <h6>Register Now!</h6>
                    <h1>Start managing your procurement today  </h1>
                    <p>Are you ready to make your business more organized? Download Spend.In now!</p>

                    <Button variant="contained" onClick={() => { navigate('/demopage') }}>Get Free Demo</Button>

                </div>
                <div className={styleDemo.contentImg}>
                    <img src="https://res.cloudinary.com/dtffniutw/image/upload/v1698473092/Content_1_lo8f27.png" alt="" />
                </div>
            </div>
        </div>
    )
}

export default ProcaDemo
