import React, { useEffect, useRef, useState } from 'react'
import ProCont from '../ProcaContact/ProcaContact.module.css'
import formStyle from '../../../components/FormsCommonCss/FormsCommonCss.module.css'
import Button from '@mui/material/Button';



const ProcaContact = () => {

    // useRef to focus on email input initially
    const emailRef = useRef();

    // State to manage form data and errors
    const [data, setData] = useState({
        name: '',
        email: '',
        phone: '',
        companyName: '',
        message: '',
    })
    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        phone: '',
        companyName: '',
        message: '',
    })

    // Effect hook to focus on email input when component mounts
    useEffect(() => {
        emailRef?.current?.focus();
    }, [])

    // Function to handle input field changes
    const handleInputField = (e) => {
        const { name, value } = e.target;
        const pattern = /^[a-zA-Z\s]*$/;
        // Conditionally update state based on input name and value
        if (name === 'name') {
            if (!pattern.test(value)) {
                // Replace non-alphabetic characters with empty string
                const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
                setData((prev) => {
                    return {
                        ...prev,
                        [name]: filteredValue,
                    };
                });
            } else {
                setData((prev) => {
                    return {
                        ...prev,
                        [name]: value,
                    };
                });
            }
        }
    }

    // Function to handle input field validation
    const handleValidation = (e) => {
        const { name, value } = e.target;
        let errorMessage = '';
        // Validate based on input name
        if (name === 'name') {
            if (value.trim() === '') {
                errorMessage = 'Name is required';
            } else if (!/^[a-zA-Z0-9]*$/.test(value)) {
                errorMessage = 'Name should only contain letters and spaces';
            }
        }
        else if (name === 'email') {
            if (value.trim() === '') {
                errorMessage = 'Mail id is required';
            }
            else if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
                errorMessage = 'Invalid Email Id'
            }
        } else if (name === 'phone') {
            if (value.length < 4) {
                errorMessage = 'Phone number is required.';
            }
            else if (value.length < 12) {
                errorMessage = 'Phone number length should be greater than 10 ';
            }
        }
        else if (name === 'companyName') {
            if (value.trim() === '') {
                errorMessage = 'companyName is required.';
            }
            else {
                errorMessage = 'companyName lshould only contain letters and spaces';
            }
        }
        else if (name === 'message') {
            if (value.trim() === '') {
                errorMessage = 'Message is required';
            }
        }

        // Update formErrors state with the validation error message
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage,
        }));
    };

    // Function to block numbers in input field
    function blockNumbers(event) {
        const input = event.target;
        const inputValue = input.value;
        const sanitizedInput = inputValue.replace(/[0-9]/g, '');// Replace numbers with empty string
        input.value = sanitizedInput;// Update input value
    }

    // Function to block alphabetic characters in input field
    function blockCharacter(event) {
        const input = event.target;
        const inputValue = input.value;
        const sanitizedInput = inputValue.replace(/[A-Za-z]/g, ''); // Replace alphabetic characters with empty string
        input.value = sanitizedInput; // Update input value
    }



    return (
        <div className={ProCont.mainContact}>
            <div className={ProCont.startContact}>

                <form action="/">
                    <h1>Schedule a Demo</h1>
                    <p>We'd love to hear from you. Please fill out this form to schedule a demo with us or
                        give us a call on<span>+91 93217-14104.</span></p>
                    <div className={ProCont.formContact}>
                        <div className={formStyle.inputRow}>
                            <label>Name <span>*</span></label>
                            <input
                                name='name'
                                onInput={(e) => blockNumbers(e)}
                                autoComplete='off'
                                placeholder='Enter your name'
                                type='text'
                                value={data.name}
                                onChange={handleInputField}
                                onBlur={handleValidation}
                            />
                            <span>{formErrors.name}</span>

                        </div>
                        <div className={formStyle.inputRow}>
                            <label>Email <span>*</span></label>
                            <input
                                name='email'
                                ref={emailRef}
                                autoComplete='off'
                                placeholder='Enter your work email'
                                type='email'
                                value={data.email}
                                onChange={handleInputField}
                                onBlur={handleValidation}
                            />
                            <span>{formErrors.email}</span>

                        </div>
                        <div className={formStyle.inputRow}>
                            <label>Phone Number <span>*</span></label>
                            <input
                                name='phone'
                                autoComplete='off'
                                onInput={(e) => blockCharacter(e)}
                                placeholder='Enter your phone number'
                                type='text'
                                pattern="[0-9]*"
                                value={data.phone}
                                onChange={handleInputField}
                                onBlur={handleValidation}
                            />
                            <span>{formErrors.phone}</span>

                        </div>
                        <div className={formStyle.inputRow}>
                            <label>Company Name <span>*</span></label>
                            <input
                                name='companyName'
                                autoComplete='off'
                                placeholder='Enter company name'
                                type='text'
                                onChange={handleInputField}
                                onBlur={handleValidation}
                            />
                            <span>{formErrors.companyName}</span>

                        </div>
                        <div className={formStyle.inputRow}>
                            <label>Requirement <span>*</span></label>
                            <textarea
                                name='massege'
                                autoComplete='off'
                                placeholder='Tell us what you need'
                                type='text'
                                onChange={handleInputField}
                                onBlur={handleValidation}
                            />
                            <span>{formErrors.message}</span>

                        </div>

                    </div>
                    <Button type="submit" variant="contained">Submit</Button>
                </form>

            </div>
        </div>
    )
}

export default ProcaContact
