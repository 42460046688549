import React, { useEffect, useRef, useState } from 'react'
import styles from './AttributeTab.module.css'
import { PrivateComponent } from 'api/axios'
import CustomSelector from 'components/CustomSelector/CustomSelector';
import useAuth from 'hooks/useAuth';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


// ---------------------rules-----------------------

const rules = {
    "NUMBER": ["LESS", "MORE", "EQUAL"],
    "TEXT": [],
    "DATE": ["LESS", "MORE", "EQUAL"],
    "BOOLEAN": ["YES", "NO"],
    "RANGE": ["INRANGE", "OUTRANGE"],
    "SINGLESELECT": [],


}

// ---------------------rules-----------------------



const AttributeTab = ({ showToast, tableRows,setTableRows, columns, attributeRemark, setAttributeRemark, approverRemarks, approovalStatus, viewType }) => {

    const privateAxios = PrivateComponent();
    const { auth } = useAuth();

    const handleInputChange = (e, id) => {
        const { name, value } = e.target;

        setTableRows(prev => {
            return prev.map(row => {
                if (row.id === id) {
                    if (auth.roles === "VENDOR" && (name === "revisedValue" || name === "vendorRemark")) {
                        return {
                            ...row,
                            [name]: value
                        };
                    }

                    if (auth.roles !== "VENDOR" && name !== "revisedValue" && name !== "vendorRemark") {
                        if (name === "valueType") {
                            return {
                                ...row,
                                [name]: value,
                                ruleAttribute: ""
                            };
                        } else {
                            return {
                                ...row,
                                [name]: value
                            };
                        }
                    }
                }

                return row;
            });
        });
    };

    return (
        <>

            <div className={styles.tableWrapper}>
                <table className={styles.attributeTable}>
                    <thead>
                        <tr className={styles.tableHeader}>

                            <th style={{ width: "70px", maxWidth: "100px", minWidth: "70px" }}>
                                <p style={{ textAlign: "left" }}>Sr No.</p>
                            </th>
                            <th style={{ minWidth: "250px" }}>
                                <p>
                                    Name
                                </p>
                            </th>
                            <th>
                                <p>
                                    Type
                                </p>
                            </th>
                            <th>
                                <p>
                                Value Type
                                </p>
                            </th>
                            <th>
                                <p>
                                    Unit
                                </p>
                            </th>

                            <th style={{ minWidth: "200px" }}>
                                <p>
                                    Value
                                </p>
                            </th>

                            {
                                columns?.vendorValueColumns?.map((item, index) => {

                                    return (
                                        <th>
                                            <p>
                                                {item}
                                            </p>
                                        </th>
                                    )
                                })
                            }



                            <th style={{ minWidth: "250px" }}>
                                <p>
                                    Remark
                                </p>
                            </th>
                            {

                                columns?.vendorRemarkColumns?.map((item, index) => {

                                    return (
                                        <th style={{ minWidth: "250px" }}>
                                            <p>
                                                {item}
                                            </p>
                                        </th>
                                    )
                                }
                                )
                            }


                            {
                                columns?.additionalColumnNames?.map((item, index) => {

                                    return (
                                        <th style={{ minWidth: "250px" }}>
                                            <p>
                                                {item}
                                            </p>
                                        </th>
                                    )
                                })
                            }

                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableRows.map((item, index) => {
                                return (
                                    <tr
                                        id={`row-${item.id}`}

                                        key={`attributeTable_${index}`} className={styles.tableData}>
                                        <td >
                                            <p style={{ textAlign: "center", paddingLeft: "0" }}>{index + 1}</p>
                                        </td>
                                        <td>
                                            <p>

                                                {item.name}

                                            </p>

                                        </td>
                                        <td>
                                            <p>
                                                {item.type}

                                            </p>

                                        </td>
                                        <td>

                                            <p>
                                                {item.valueType}

                                            </p>

                                        </td>
                                        <td>

                                            <p>
                                                {item.unit}

                                            </p>

                                        </td>


                                        <td>
                                        <input type="text" value={item.value}
                                                name="value" onChange={(e) => handleInputChange(e, item.id)}
                                                disabled={auth.roles === "VENDOR" ? true : false}
                                            />
                                            {/* <p>
                                                {item.value}

                                            </p> */}


                                        </td>


                                        {
                                            item.revisedValue.map((vendor, index) => {

                                                return (
                                                    <td key={index}>


                                                        <VendorValueInput
                                                            value={vendor.value}
                                                            kamValue={item.valueType === "SINGLESELECT" ? item.expectedAns : item.value}
                                                            dropOption={item.value}
                                                            rule={item.ruleAttribute}
                                                            type={item.valueType}
                                                            name="revisedValue"
                                                        />
                                                    </td>
                                                )
                                            })
                                        }



                                        <td>


                                            <p>
                                                {item.remark}

                                            </p>
                                        </td>


                                        {
                                            item.vendorRemark.map((vendor, index) => {

                                                return (
                                                    <td key={index}>
                                                        <p>
                                                            {vendor.value}

                                                        </p>
                                                    </td>
                                                )
                                            })
                                        }



                                        {
                                            item.additionalFields.map((field) => {
                                                return (
                                                    <td>
                                                        <p>
                                                            {field.columnValue}
                                                        </p>
                                                    </td>
                                                )

                                            }
                                            )
                                        }
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>

                {
                    viewType === "WAPPROVAL" &&
                    <>

                        {approverRemarks?.length > 0 &&

                            <table className={styles.specficationTable}>
                                <tr className={`${styles.tableHeader}`}>
                                    <th>
                                        Approver Remark
                                    </th>
                                    <th style={{ textAlign: "left", paddingRight: "20px" }}>
                                        From
                                    </th>
                                </tr>
                                {approverRemarks?.map((rem, index) => {
                                    return (
                                        <tr className={styles.tableRow}>

                                            <td className={styles.reactQuillTd}>
                                                <ReactQuill modules={{ toolbar: false }}
                                                    readOnly={true}
                                                    style={{ border: "none", outline: "none" }}
                                                    // theme="snow" 
                                                    value={rem.remark}
                                                />
                                            </td>
                                            <td className={styles.vendorInfo}>
                                                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                                    <ProfileIcon data={{ email: rem?.email }} height={"26px"} width={"26px"} showProfileBanner={rem.name == "No Name" ? false : true} />
                                                    {rem.name}
                                                </div>
                                            </td>

                                            {/* </div> */}
                                        </tr>
                                    )

                                })}
                            </table>
                        }

                        {auth.roles !== "KAM"
                            &&
                            <div style={{ display: "flex", height: "20vh", width: "100%", flexDirection: "column", gap: "5px" }}>
                                <h3 style={{ marginBottom: "3px", fontSize: "16px", fontWeight: "800" }}>Add Remark</h3>
                                <textarea name="" id="" cols="30" rows="10" value={attributeRemark} onChange={(e) => {
                                    if (approovalStatus !== "APPROVAL" && approovalStatus !== "REJECTED") {
                                        setAttributeRemark(e.target.value)

                                    } else {
                                        showToast("error", "You can't add remark in after Decision");
                                    }


                                }} className={styles.descriptionCompareTextBox} style={{ height: "100%" }} />
                            </div>


                        }

                    </>
                }
            </div >

        </>
    )
}

export default AttributeTab




const VendorValueInput = ({ value, onChange, kamValue, dropOption, rule, type, name, style, ...props }) => {
    const [vStyle, setVStyle] = useState({
        ...style,
        color: "black",

    });


    useEffect(() => {
        let newStyle = { ...style };

        switch (type) {
            case 'NUMBER':
                switch (rule) {
                    case 'LESS':
                        newStyle.backgroundColor = parseFloat(value) <= parseFloat(kamValue) ? '#00ff001a' : '#ff00001a';
                        break;
                    case 'MORE':
                        newStyle.backgroundColor = parseFloat(value) >= parseFloat(kamValue) ? ' #00ff001a' : '#ff00001a';
                        break;
                    case 'EQUAL':
                        newStyle.backgroundColor = parseFloat(value) === parseFloat(kamValue) ? '#00ff001a' : '#ff00001a';
                        break;
                    default:
                        newStyle.backgroundColor = '';
                }
                break;

            case 'TEXT':
                newStyle.backgroundColor = value?.trim() === kamValue?.trim() ? '#00ff001a' : '#ff00001a'
                break;
            case 'RANGE':
                const rte = kamValue?.split("-").map((item) => item.trim());

                if (rte?.length > 1) {

                    switch (rule) {
                        case 'INRANGE':
                            newStyle.backgroundColor = (parseFloat(value) >= parseFloat(rte[0]) && parseFloat(value) <= parseFloat(rte[1])) ? '#00ff001a' : '#ff00001a';
                            break;
                        case 'OUTRANGE':
                            newStyle.backgroundColor = (parseFloat(value) <= parseFloat(rte[0]) || parseFloat(value) >= parseFloat(rte[1])) ? ' #00ff001a' : '#ff00001a';
                            break;

                        default:
                            newStyle.backgroundColor = '';
                    }
                }
                else {
                    newStyle.backgroundColor = '';
                }

                break;
            case 'BOOLEAN':
                newStyle.backgroundColor = value?.trim() === kamValue?.trim() ? '#00ff001a' : '#ff00001a'
                break;
            case 'SINGLESELECT':


                newStyle.backgroundColor = value?.trim() === kamValue?.trim() ? '#00ff001a' : '#ff00001a'



                break;


            case 'DATE':
                switch (rule) {
                    case 'LESS':

                        newStyle.backgroundColor = new Date(value) < new Date(kamValue) ? '#00ff001a' : '#ff00001a';

                        break;
                    case 'MORE':

                        newStyle.backgroundColor = new Date(value) > new Date(kamValue) ? ' #00ff001a' : '#ff00001a';

                        break;
                    case 'EQUAL':

                        newStyle.backgroundColor = new Date(value) === new Date(kamValue) ? '#00ff001a' : '#ff00001a';

                        break;
                }

                break;
            // Add other cases as needed

            default:
                newStyle.backgroundColor = '';
        }

        setVStyle(newStyle);
    }, [value, kamValue, rule, type, style]);

    return (
        <p style={vStyle}>
            {value}
        </p>
    )
};

const KamValueInput = ({ value, onChange, type, name, style, ...props }) => {
    const inputRef = useRef(null);
    const { auth } = useAuth();
    const calculateWidth = () => {
        if (inputRef.current) {
            const inputWidth = inputRef.current.scrollWidth;
            const containerWidth = inputRef.current.parentElement.clientWidth;

            // Check if the input width is greater than the container width
            if (inputWidth > containerWidth) {
                return `${inputWidth}px`;
            }
        }

        // If the input width is not greater than the container width, use 100%.
        return '100%';
    };

    const vStyle = {
        ...style,
        // width: calculateWidth(),
        width: "100%",
        height: "100%",
        resize: "vertical",
        border: "none",
        paddingTop: "10px"

    };

    switch (type) {
        case 'NUMBER':
            return <textarea ref={inputRef} type="number" value={value ?? ''} onChange={onChange} name={name} style={{ ...vStyle, textAlign: 'center' }} {...props} />;
        case 'DATE':
            return <input ref={inputRef} type="date" value={value ?? ''} onChange={onChange} name={name} style={{ ...style, textAlign: 'center' }} {...props} />;
        case 'BOOLEAN':
            return (
                <CustomSelector value={value} style={style} {...props} disabled={auth.roles === "VENDOR" ? true : false}>
                    <li onClick={() => onChange({ target: { value: 'YES', name: name } })}>YES</li>
                    <li onClick={() => onChange({ target: { value: 'NO', name: name } })}>NO</li>
                </CustomSelector>
            );
        case 'RANGE':
            return <textarea ref={inputRef} type="range" value={value ?? ''} style={{ ...vStyle, textAlign: 'center' }} name={name} onChange={onChange} {...props} />;
        case 'SINGLESELECT':
            return <textarea ref={inputRef} type="text" value={value ?? ''} style={{ ...vStyle, textAlign: 'center' }} name={name} onChange={onChange} {...props} />;
        default:
            return <textarea ref={inputRef} type="text" value={value ?? ''} style={{ ...vStyle, textAlign: 'center' }} name={name} onChange={onChange} {...props} />;
    }
};
const ExpectedAnsInput = ({ value, onChange, type, kamValue, name, style, ...props }) => {
    const inputRef = useRef(null);

    let options = [];
    if (kamValue == undefined || kamValue == null || kamValue == "") {
        options = ["please add value separeated by comma"];
    }
    else {

        options = kamValue.split(",").map((item) => item.trim());
    }

    const vStyle = {
        ...style,
        // width: calculateWidth(),
        width: "100%",
        height: "100%",
        resize: "vertical",
        border: "none",
        paddingTop: "10px"

    };

    switch (type) {

        case 'SINGLESELECT':
            return (
                <CustomSelector value={value} style={style} {...props}>
                    {
                        options.map((item) => {
                            return (
                                <li onClick={() => onChange({ target: { value: item, name: name } })}>{item}</li>
                            )
                        })
                    }

                </CustomSelector>
            );


        default:
            return <textarea ref={inputRef} type="text" value={value ?? ''} style={{ ...vStyle, textAlign: 'center' }} name={name} onChange={onChange} {...props} />;
    }
};



