import React, { useEffect, useState } from 'react'
import approvalStyle from './WorkSpaceApproval.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatRow from 'components/ChatRows/ChatRow';
import useAuth from 'hooks/useAuth';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import { useNavigate, useParams } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';

const UserViewApproval = ({ data, handleLastSeen, searchValue, approvalRevisions, handleFileType }) => {

    // Initializing state variables and importing necessary hooks
    const privateAxios = PrivateComponent();
    const { auth } = useAuth();
    const navigate = useNavigate();
    const { client } = useParams();
    const [sorterData, setSorterData] = useState();
    const [sortedData, setSortedData] = useState([]);
    const [sorterOpen, setSorterOpen] = useState("");

    // Function to toggle sorter dropdown
    const handleTableSorterToggle = (sorter) => {
        if (sorterOpen === sorter) {
            setSorterOpen("");
        }
        else {
            setSorterOpen(sorter);
        }
    }

    // Effect to update sortedData when data changes
    useEffect(() => {
        setSortedData(data);
    }, [data]);

    // Function to handle sorting based on different criteria
    const handleSorting = (value, type) => {
        switch (type) {
            case "product": setSortedData(data.filter((dd) => dd.productName === value));

                break;
            case "fileType": setSortedData(data.filter((dd) => dd.vendorRevisionType === value));

                break;
            case "category": setSortedData(data.filter((dd) => dd.type === value));

                break;
            case "submitedOn": dateSorting("submitedOn", value);
                break;
            case "ownedBy": setSortedData(data.filter((dd) => dd.sentTo === value));
                break;
            case "lastModified": dateSorting("lastModified", value);
                break;
            default: clearSorting();
        }
        setSorterOpen("");
    }

    // Function to clear sorting
    const clearSorting = () => {
        setSortedData(data);
        setSorterOpen("");
    }


    // Function to sort data based on date
    const dateSorting = (type, value) => {
        let sortedData = []
        if (type === "submitedOn") {
            if (value === "asc") {
                sortedData = [...data].sort((a, b) => {
                    return new Date(a.submittedOn) - new Date(b.submittedOn);
                });
            }
            else {
                sortedData = [...data].sort((a, b) => {
                    return new Date(b.submittedOn) - new Date(a.submittedOn);
                });
            }
        }

        else {
            if (value === "asc") {
                sortedData = [...data].sort((a, b) => {
                    return new Date(a.lastModified) - new Date(b.lastModified);
                });
            }
            else {
                sortedData = [...data].sort((a, b) => {
                    return new Date(b.lastModified) - new Date(a.lastModified);
                });
            }
        }
        setSortedData(sortedData);
    }


    const [moreIcon, setMoreIcon] = useState("");

    // Function to toggle chat panel
    const handleMoreIcon = (id) => {
        if (moreIcon === id) {
            setMoreIcon("")
        }
        else {
            setMoreIcon(id);
        }
    }



    // ======================chat pannel=================================
    // chat states 

    // Initializing state variables for chat panel
    const [chatSlider, setChatSlider] = useState(false)
    const [chatValue, setChatValue] = useState("");
    const [revisionChats, setRevisionChats] = useState([]);
    const [revData, setRevData] = useState("");


    const handleChatSlider = (revData) => {
        setChatSlider(prev => !prev)
        setRevData(revData);
    }

    // Effect to fetch revision chats when chat slider is opened
    useEffect(() => {
        if (chatSlider === true && revData !== "") {

            getRevisionChats(revData);
        }
    }, [chatSlider])

    // Function to handle sending chat messages
    const handleChatSend = async (e, revId) => {
        e.preventDefault();
        try {
            let url = `/approval/addRemarkOnApprovalRevision`; // Define the API endpoint based on file type

            if (chatValue.length > 0) {
                await privateAxios.post(`${url}/${auth.userData.id}/${revData.id}`, { remark: chatValue }).then(res => {
                    getRevisionChats(revData);
                    setChatValue("");
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Function to fetch revision chats
    const getRevisionChats = async (revData) => {
        handleChatNotification();
        // openBackdrop();
        try {
            let url = "/approval/getRemarksOfApprovalRevision"
            await privateAxios.get(`${url}/${revData.id}`).then(res => {
                // handleChatNotification();
                setRevisionChats(res.data)
            })
        } catch (error) {
        }
    }

    // =====================chat notification========================

    // State variable and function to handle chat notification
    const [reRender, setReRender] = useState(false);

    const handleChatNotification = () => {
        setReRender(prev => !prev);

    }

    // =====================chat notification========================

    // Function to navigate based on data type
    const handleNavigate = (data) => {

        if (data?.fileType === "GROUP" || data?.fileType === "Group") {
            navigate(`/${client}/approvals/group/${data.id}`)
        }
        else {
            if (data.type.toLowerCase() === "technical specification") {
                navigate(`/${client}/approvals/technical/${data.id}/${data.revId}`)
            }
            else if (data.type.toLowerCase() === "commercial") {
                navigate(`/${client}/approvals/commercial/${data.id}/${data.revId}`)
            }
        }
    }

    return (
        <>

            <div className={approvalStyle.tableWraper}>
                <table className={approvalStyle.ApprovalTable} >
                    <thead>
                        <tr className={`${approvalStyle.tableHead}`} style={{ zIndex: '2' }}>

                            <td className={approvalStyle.tableColumnSticky} style={{ minWidth: "250px" }}>
                                <div className={approvalStyle.tdRow}>
                                    <p>Name</p>
                                </div>
                            </td>

                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("product")}>Products
                                        <span>{sorterOpen === "product" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "product" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("product")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.productName?.map((ss) => {
                                                        return (
                                                            <li onClick={() => handleSorting(ss, "product")} >{ss === null ? "Group" : ss}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>

                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("type")}>File Type
                                        <span>{sorterOpen === "type" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "type" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("type")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.vendorRevisionType?.map((ss) => {
                                                        return (
                                                            <li onClick={() => handleSorting(ss, "type")} >{ss === null ? "Group" : ss}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("category")}> Category
                                        <span>{sorterOpen === "category" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "category" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("category")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.type?.map((ss) => {
                                                        return (
                                                            <li onClick={() => handleSorting(ss, "category")} >{ss}</li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </>
                                    }
                                </div>
                            </td>

                            <td>
                                <div className={workSpaceStyle.tableSorterDiv}>
                                    <p onClick={() => handleTableSorterToggle("submitedOn")}>Submited On
                                        <span>{sorterOpen === "submitedOn" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "submitedOn" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("submitedOn")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                <li onClick={() => handleSorting("asc", "submitedOn")} >Latest</li>
                                                <li onClick={() => handleSorting("desc", "submitedOn")} >Oldest</li>
                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>

                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("duedate")}>Due Date
                                        <span>{sorterOpen === "duedate" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "duedate" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("duedate")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.sentTo?.map((ss) => {
                                                        return (
                                                            <li onClick={() => handleSorting(ss, "duedate")} >{ss === null ? "Null" : ss}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>

                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("lastUpdated")}>Last Modified
                                        <span>{sorterOpen === "lastUpdated" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "lastUpdated" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("lastUpdated")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                <li onClick={() => handleSorting("asc", "lastModified")} >Latest</li>
                                                <li onClick={() => handleSorting("desc", "lastModified")} >Oldest</li>

                                            </ul>
                                        </>
                                    }
                                </div>
                            </td>

                            <td> Actions </td>

                        </tr>
                    </thead>


                    <tbody>
                        {approvalRevisions
                            .filter((row) => row.name.trim().toLowerCase().includes(searchValue.toLowerCase()))
                            .map((row, index) => {
                                return (
                                    <tr className={`${approvalStyle.tableRow}`}>

                                        <td className={approvalStyle.tableColumnSticky}>{row.name} </td>
                                        <td>{row.productName} </td>
                                        <td>{handleFileType(row.fileType)} </td>
                                        <td>{row.category} </td>
                                        <td>{handleLastSeen(row.submittedOn)} </td>
                                        <td>{handleLastSeen(row.dueDate)} </td>
                                        <td>{handleLastSeen(row.lastModified)} </td>

                                        <td>
                                            <div className={approvalStyle.iconRow}>
                                                <span onClick={() => handleNavigate({ id: row.id, fileType: row.fileType, type: row.category, revId: row.id })}>
                                                    <RemoveRedEyeIcon />
                                                </span>
                                                <span onClick={() => handleChatSlider({ id: row.id, type: row.category, revName: row.name })}>
                                                    <ChatIconWithBadge
                                                        url={`/approval/getNotficationCountOfApprvalRevision`}
                                                        id={row.id}
                                                        reRender={reRender}
                                                    />
                                                </span>
                                            </div>
                                        </td>

                                    </tr>
                                )
                            })
                        }
                    </tbody>


                </table>

            </div>

            
            <ChatPannel
                open={chatSlider}
                onClose={handleChatSlider}
            >
                <>
                    <ChatHeader onClose={handleChatSlider}
                        downloadData={
                            revisionChats.map((chat) => { return { name: chat.name, date: chat.createdAt, chat: chat.remark } })
                        } helpData={{ link: "tosomewhere" }}
                        chatName={revData?.revName}
                    >
                        <h3>{revData?.revName} </h3>
                    </ChatHeader>

                    <ChatRows >
                        {/* <ChatRow name="Ravinder" chat="soemfhdf a saf sa" date="12-04-2023" index="1"/> */}
                        {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
                            return (

                                <ChatRow name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} />
                            )
                        })
                            :
                            <p style={{ textAlign: "center" }}>
                                No Previous Chat
                            </p>
                        }
                    </ChatRows>

                    <ChatInputField revisionId={revData.id} onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

                </>
            </ChatPannel>

        </>
    )
}

export default UserViewApproval
