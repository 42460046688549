import React, { useEffect, useState } from 'react'
import AttributeTab from 'components/TechnicalSpecificationTabs/AttributeTab/AttributeTab';// Importing AttributeTab component
import useToast from 'hooks/useToast';// Importing custom hook for toast messages
import useBackdrop from 'hooks/useBackdrop';// Importing custom hook for backdrop
import { PrivateComponent } from 'api/axios'; // Importing PrivateComponent from Axios for authenticated requests


const TestPage = () => {

  // Custom hook initializations
  const { showToast, Toast } = useToast(); // Toast hooks
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();// Backdrop hooks
  const privateAxios = PrivateComponent();// Private Axios instance

  // State for table rows
  const [tableRows, setTableRows] = useState([]);

  // State for duplicate rows
  const [duplicateRow, setDuplicateRow] = useState([]);

  // Function to handle attribute validation
  const handleAttributeValidation = (type) => {
    const hasNullName = tableRows.some(rr => rr.name === null);

    if (hasNullName) {
      alert("please check Attribute Name No Name can be empty");
      return false;
    }
    else {
      const nameOccurrences = {};
      const duplicateNamesId = [];
      tableRows.forEach((item) => {
        const name = item.name;
        // Check if the name has been encountered before
        if (nameOccurrences[name]) {
          // If it has, mark it as a duplicate
          duplicateNamesId.push(item.id);
        } else {
          // Otherwise, record it in the occurrences object
          nameOccurrences[name] = true;
        }
      });
      if (duplicateNamesId.length > 0) {
        // console.log(duplicateError)
        setDuplicateRow(duplicateNamesId)
        if (type === "check") {

          alert("Please Remove Duplicate Attributes");
          return false;
        }
        else {
          return duplicateNamesId
        }
      }
      else {
        setDuplicateRow([]);
        return true;
      }
    }
  }

  const deleteAllDuplicateRows = () => {
    const ids = handleAttributeValidation();
    if (ids.length > 0) {
      const newRows = tableRows.filter((item) => !ids.includes(item.id));
      setTableRows(newRows);
    }
  }

  const [productData, setProductData] = useState(null);

  const getData = async () => {

    try {

      // TASK ID CHECK IS REMAINING BUT WE THOUGHT ABOUT IT.
      const response = await privateAxios.get(`/technicalSpecification/getRevisionByRevisionIdAndProductId/2/600084`);
      let data = response.data;
      setProductData({
        ...data,
        productAttributes: data.productAttributes.slice(0, 5)
      }
      );
    } catch (error) {
    }
  }
  useEffect(() => {
    getData();
  }, [])


  return (
    <>
      <button onClick={handleAttributeValidation}>Check Duplicate</button>
      <button onClick={deleteAllDuplicateRows}>Delete Duplicate</button>
      <AttributeTab
        tableRows={tableRows}
        setTableRows={setTableRows}
        showToast={showToast}
        data={productData?.productAttributes}
        closeBackdrop={closeBackdrop}
        openBackdrop={openBackdrop}
        duplicateRow={duplicateRow}
        viewType={""}

      />

    </>
  )
}

export default TestPage
