import React from 'react'
import dStyle from './DeleteConfirmation.module.css'
import { Modal } from '@mui/material'

const DeleteConfirmation = ({ children, onCancel, onConfirm, open, confimBtnText, discardBtnText,style }) => {
    return (
        <Modal
            open={open}
            onClose={onCancel}

        >
            <div className={dStyle.modalContainer} style={style}>
                <form action="" className={dStyle.formContainer} 
                onSubmit={(e)=>{e.preventDefault();onConfirm();}} 
                >

                    <div className={dStyle.contentWrapper}>
                        {
                            children
                        }
                    </div>
                    <div className={dStyle.buttonWrapper}>


                        <button type='submit'
                            // onClick={onConfirm}
                            style={{ backgroundColor: "#EC5252", color: "#fff" }}
                            >{confimBtnText || "Delete"}</button>

                        <button onClick={onCancel}>{discardBtnText || "Cancel"}</button>
                    </div>

                </form>
            </div>
        </Modal>
    )
}

export default DeleteConfirmation
