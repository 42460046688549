import React, { useEffect, useState } from 'react'
import cStyle from 'components/ModalCommonCSS/ModalCommonCSS.module.css'
import style from './SendToVendorModal.module.css'
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';


const RenameModal = ({ open, onClose, onSubmit, header }) => {
    // Function to get an instance of axios for making private API calls
    const privateAxios = PrivateComponent();
    
    // Custom hook to get authentication details
    const { auth } = useAuth();
    
    // State to manage the comment input
    const [comment, setComment] = useState("");
    
    // State to manage the due date input
    const [dueDate, setDueDate] = useState("");
    
    // State to store the list of vendor emails
    const [emails, setEmails] = useState([]);
    
    // State to store the list of selected vendor emails
    const [selectedEmails, setSelectedEmails] = useState([]);
    
     // Function to fetch the list of vendors
    const fetchVendors = async () => {
        try {
              // API call to get all vendors for the organization
            const response = await privateAxios.get(`/vendor/getAllVendors/${auth.orgId}`);

            if (response.status === 200) { // Check if the response is successful
                 // Filter to get only enabled vendors and map their emails
                const vendorEmails = response.data.filter((vendor) => !vendor.disable).map((vendor) => vendor.email);
             
                setEmails(vendorEmails); // Update the 'email' state with the fetched vendor emails
            }
        } catch (error) {
            console.error('Error fetching vendors:', error);// Log any errors
        }
    };

     // Effect to fetch vendors when the component mounts
    useEffect(() => {
        fetchVendors();
    }, []);


 // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if(selectedEmails.length!==0 && dueDate!=="") {// Check if required fields are filled
        const data={
            dueDate:dueDate,
            comments:comment,
            emails:selectedEmails
        }
        await onSubmit(data);// Call the onSubmit function with the form data
        setSelectedEmails([]);// Reset the selected emails state
        setDueDate("");// Reset the due date state
        setComment("");// Reset the comment state
    }
    else{
        if(selectedEmails.length===0)
        {// Check if no vendors are selected
            alert("Please select at least one vendor");
        }
        else if(dueDate==="")
        {// Check if no due date is selected
            alert("Please select a due date");
        }
    }
    }

   // Function to handle modal close
   const handleCloseModal = () => {
    setSelectedEmails([]); // Reset the selected emails state
    setDueDate(""); // Reset the due date state
    setComment(""); // Reset the comment state
    onClose(); // Call the onClose function to close the modal
};




    return (
        <Modal
            open={open}
            onClose={handleCloseModal}

        >
            <div className={cStyle.modalContainer}>

                <form action="" onSubmit={handleSubmit} className={style.formContainer} style={{gap:"15px"}} >
                    <div className={style.modalHeader} >

                        <h3>
                            <span>
                                <PeopleOutlineIcon />
                            </span>
                            {header}
                        </h3>
                        <span onClick={handleCloseModal}>
                            <CloseIcon />
                        </span>
                    </div>

                    <div className={style.formGroup}>
                        <label htmlFor=""> Select Vendor:</label>
                        <MultiSelect

                            categories={selectedEmails}
                            setCategories={setSelectedEmails}
                            availableDropDown={emails}
                            placeholder="Please Select Member"
                        />
                    </div>
                    <div className={style.formGroup}>
                        <label htmlFor=""> Due Date:</label>
                        <input type="date"
                            id='date' name='date'
                            value={dueDate}
                            onChange={(e) => setDueDate(e.target.value)}
                            placeholder='Please Select A date' />
                    </div>
                    <div className={style.formGroup}>
                        <label htmlFor="comment">Comments (if any)</label>

                        <textarea type="text" id='comment' name='comment' value={comment} onChange={(e) => setComment(e.target.value)} />
                    </div>




                    <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>

                        <button className={`${cStyle.modalBtn} ${cStyle.pimaryModalBtn}`} type='submit'>Send </button>


                        <p
                            onClick={() => {handleCloseModal() }}
                            className={`${cStyle.modalBtn} ${cStyle.secondaryModalBtn}`}
                        >Cancel</p>
                    </div>


                </form>

            </div>
        </Modal>
    )
}

export default RenameModal
