import React from 'react'
import { useState, useEffect } from 'react';
import kamAssignStyle from "./KamTask.module.css"
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import useAuth from 'hooks/useAuth';
import useBackdrop from 'hooks/useBackdrop';
import { PrivateComponent } from 'api/axios';



const KamTask = () => {
    // State to hold the search input value
    const [searchValue, setSearchValue] = useState('');
    
    // Context hook to get authentication information
    const { auth } = useAuth();
    
    // State to hold fetched data
    const [data, setData] = useState([]);
    
    // Hooks for handling backdrop loading indicators
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    
    // States to manage visibility of status and priority selectors
    const [statusSelectorOpen, setStatusSelectorOpen] = useState('');
    const [prioritySelectorOpen, setPrioritySelectorOpen] = useState('');
    
    // Hook for making authenticated requests
    const privateAxios = PrivateComponent();
    
    // States and handlers for managing multi-click functionality
    const [multiClick, setMultiClick] = useState(false);
    const handleMultiClick = () => {
        setMultiClick(prev => !prev)
    }
    const [multiClick2, setMultiClick2] = useState(false);
    const handleMultiClick2 = () => {
        setMultiClick2(prev => !prev)
    }
    const [multiClick3, setMultiClick3] = useState(false);
    const handleMultiClick3 = () => {
        setMultiClick3(prev => !prev)
    }
    const [multiClick4, setMultiClick4] = useState(false);
    const handleMultiClick4 = () => {
        setMultiClick4(prev => !prev)
    }
 // State and handler for showing/hiding the super admin modal
    const [viewSuperAdminModal, setViewSuperAdminModal] = useState(false)

       // State to temporarily hold data for the modal
    const [tempData, SetTempData] = useState(null)
    const handleViewtasksModal = (data) => {

        setViewSuperAdminModal(prev => !prev)
        if (data !== null && data !== undefined && data !== "") {
            SetTempData(data)
        }
    }

    // Handlers for toggling status and priority selectors
    const handleStatusSelector = (index) => {

        if (statusSelectorOpen === index) {
            setStatusSelectorOpen('')
        } else {
            setStatusSelectorOpen(index)
        }
    }
    const handlePrioritySelector = (index) => {

        if (prioritySelectorOpen === index) {
            setPrioritySelectorOpen('')
        } else {
            setPrioritySelectorOpen(index)
        }


    }


    // -------------------------------------------------New Sortting----------------------------------------------------------------------

 // State and handler for the start date sort dropdown
    const [isStartDateDropdownOpen, setIsStartDateDropdownOpen] = useState(false);

    const toggleStartDateDropdown = () => {
        setIsStartDateDropdownOpen(!isStartDateDropdownOpen);
    };

      // Handler to sort data by start date
    const handleStartDateSort = (sortType) => {
        let sortedItems = [...data];

        if (sortType === 'Newest') {
            sortedItems.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        } else if (sortType === 'Oldest') {
            sortedItems.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        }

        setData(sortedItems);

        // Close the dropdown
        setIsStartDateDropdownOpen(false);
    };
    // ---------------------------------------------------------------------------

       // State and handler for the end date sort dropdown
    const [isEndDateDropdownOpen, setIsEndDateDropdownOpen] = useState(false);

    const toggleEndDateDropdown = () => {
        setIsEndDateDropdownOpen(!isEndDateDropdownOpen);
    };


     // Handler to sort data by end date
    const handleEndDateSort = (sortType) => {
        let sortedItems2 = [...data];

        if (sortType === 'Newest') {
            sortedItems2.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
        } else if (sortType === 'Oldest') {
            sortedItems2.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
        }

        setData(sortedItems2);

        setIsEndDateDropdownOpen(false);
    };




    
        // ----------------------------------------------get all users-----------------------------------------------------------

 // State and handler for selecting priority
    const [selectPriority, setSelectPriority] = useState('')

    const handlePriorityChange = () => {
        setSelectPriority(selectPriority)
    }

  // State and handler for selected item (e.g., a game)
    const [selected, setSelected] = useState('');

    const handleGame = (id, game) => {
        setSelected(id === selected ? '' : id);
    }

       // Handler for updating the search input value
    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
    };

     // Function to fetch all tasks
    const getAllTasks = async () => {
        openBackdrop(); // Show backdrop loading indicator

        try {

            if (auth.roles === "KAM") {
                const response = await privateAxios.get(`/task/getTasksOfAdmin`)
                    .then((res) => {
                        setData(res.data);

                        if (res.status === 200) {
                            const data = res.data;
                            closeBackdrop();// Hide backdrop on success
                        }
                        closeBackdrop();

                    }).catch((error) => {
                        closeBackdrop();// Ensure backdrop is closed
                    }
                    )
            } else {
                const response = await privateAxios.get(`/task/getTaskByRoles`)
                    .then((res) => {
                        setData(res.data);

                        if (res.status === 200) {
                            const data = res.data;
                            closeBackdrop();
                        }
                        closeBackdrop();

                    }).catch((error) => {
                        closeBackdrop();
                    }
                    )
            }

        } catch (error) {
            closeBackdrop();
        }

    }
    // Fetch tasks on component mount

    useEffect(() => {
        getAllTasks()

    }, []);


     // Function to update the status of a task

    const handleStatusChange =async (taskId, value) => {
        try {
            let param = "ALL"
            switch (value.toLowerCase()) {
                case "to do": param = "TODO";
                    break;
                case "in progress": param = "INPROGRESS";
                    break;
                case "done": param = "DONE";
                    break;
                case "completed": param = "COMPLETED";
                    break;
                default: param = "ALL"
            }
            await privateAxios.post(`/task/updateTaskStatus/${taskId}?status=${param}`).then(res => {
                getAllTasks();// Refresh tasks after updating status
            })
        } catch (error) {
            // console.log(error)
        }
    };

    return (
        <div className={workSpaceStyle.workSpaceMain}>
            <div className={workSpaceStyle.workSpaceHeader}>

                <div className={workSpaceStyle.workSpaceHeaderLeft}>
                    <h1 style={{ gap: '10px' }}><span style={{ fontSize: "20px" }}><TaskOutlinedIcon /></span>
                        Assign To Me
                    </h1>
                    <p>All Task <span>
                        {/* {data.length} */}12
                    </span></p>

                </div>
                <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
                    <input type="text"
                        onChange={handleSearchChange}
                        value={searchValue}
                        placeholder='Search Here...' />
                </div>
                <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '15%', display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
                </div>
            </div>
            <div className={kamAssignStyle.table1}>

                <table style={{ borderCollapse: 'collapse' }}>
                    <thead>
                        <tr className={kamAssignStyle.theader}>
                            <th>Task ID</th>
                            <th>Title</th>
                            <th>
                                <div className={kamAssignStyle.SortingDate}>
                                    <p onClick={handleMultiClick3} style={{ color: '#000' }}>
                                        <span style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                            Start Date<ImportExportIcon onClick={toggleStartDateDropdown} />
                                        </span>
                                    </p>
                                    {isStartDateDropdownOpen &&
                                        <ul >
                                            <li onClick={() => handleStartDateSort('Newest')}>Newest</li>
                                            <li onClick={() => handleStartDateSort('Oldest')}>Oldest </li>
                                        </ul>
                                    }
                                </div>
                            </th>
                            <th >
                                <div className={kamAssignStyle.SortingDate}>
                                    <p className={kamAssignStyle.jjjjj} onClick={handleMultiClick4} style={{ color: '#000' }}>
                                        <span style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
                                            End Date<ImportExportIcon onClick={toggleEndDateDropdown} />
                                        </span>
                                    </p>
                                    {isEndDateDropdownOpen &&
                                        <ul >
                                            <li onClick={() => handleEndDateSort('Newest')}>Newest</li>
                                            <li onClick={() => handleEndDateSort('Oldest')}>Oldest </li>
                                        </ul>
                                    }
                                </div>
                            </th>
                            <th>E/C Date</th>
                            <th>Status</th>
                            <th>Task</th>
                            <th>Label</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                </table>
                <table style={{ borderCollapse: 'collapse' }}>
                    <tbody >
                        {
                            Array.isArray(data) ? (
                                data?.filter((item) =>
                                    item.title?.trim().toLowerCase().includes(searchValue?.toLowerCase())
                                )
                                    .map((item, index) => (
                                        <tr className={kamAssignStyle.tbody}>
                                            <td>{index + 1}</td>
                                            <td>{item.title}</td>
                                            <td style={{ backgroundColor: '#d2d2d2' }}>{item.startDate}</td>
                                            <td style={{ backgroundColor: '#d2d2d2' }}>{item.endDate}</td>
                                            <td>{item.completedDate}</td>

                                            <td className={kamAssignStyle.SelectBar} style={{ backgroundColor: '#d2d2d2' }}>

                                                <div onClick={() => handleStatusSelector(index)} className={kamAssignStyle.statusSelector}>
                                                    <p onClick={handleMultiClick2} style={{ color: '#000' }}

                                                    >{item.status?.substring(0, 1).toUpperCase() + item.status?.substring(1, item.status.length).toLowerCase()}
                                                        <span>
                                                            {statusSelectorOpen === index
                                                                ? <KeyboardArrowUpIcon />
                                                                : <KeyboardArrowDownIcon />
                                                            }
                                                            {/*  */}
                                                        </span>
                                                    </p>
                                                    {statusSelectorOpen === index &&
                                                        <ul>
                                                            <li onClick={() => handleStatusChange(item.taskId, "To Do")}>To Do</li>
                                                            <li onClick={() => handleStatusChange(item.taskId, "In Progress")}>In Progress</li>
                                                            <li onClick={() => handleStatusChange(item.taskId, "Done")}>Done</li>
                                                        </ul>
                                                    }
                                                </div>


                                            </td>

                                            <td className={kamAssignStyle.SelectBar} >
                                                {item.type === 'Technical Specification' ? (
                                                    <span>Technical</span>
                                                ) : item.type === 'Commercial' ? (
                                                    <span>Commercial</span>
                                                ) : null}
                                            </td>


                                            <td className={kamAssignStyle.SelectBar}>
                                                <div onClick={() => handlePrioritySelector(index)} className={kamAssignStyle.prioritySelector}>
                                                    <p onClick={handleMultiClick}
                                                        style={
                                                            {
                                                                backgroundColor: item.priority === 'HIGH' ? '#ec5252' : item.priority === 'MEDIUM' ? '#f5ac38' : item.priority === 'LOW' ? '#b3f289' : '#fff',
                                                                color: item.priority === 'High' ? '#fff' : item.priority === 'Medium' ? '#fff' : item.priority === 'Low' ? '#fff' : '#fff',
                                                                height: "27px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }
                                                        }
                                                    >{item.priority?.substring(0, 1).toUpperCase() + item.priority?.substring(1, item.priority.length).toLowerCase()}
                                                      
                                                    </p>
                                                   
                                                </div>

                                            </td>
                                            <td className={kamAssignStyle.taskDisplayBtn} >
                                                <Button variant="contained"
                                                >CTA</Button>
                                            </td>
                                        </tr>
                                    ))
                            ) : (
                                <td colSpan="7">Data is not an array.</td>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default KamTask
