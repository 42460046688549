// CommercialTab component
import React, { useEffect, useState } from 'react';
import superadminStyle from '../../commonCss.module.css';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { PrivateComponent } from 'api/axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';



const CommercialTab = ({ openBackdrop, closeBackdrop, showToast, orgId, handleProductCheckBox, commercialRes }) => {
  // Custom axios instance for private requests
  const privateAxios = PrivateComponent();
  // React router's navigation hook
  const navigate = useNavigate()
  // Store the organization ID from props
  const data = orgId;

  // Enable template for franchise
  const enableTemplate = async (id) => {
    try {
      await privateAxios.post(`/procureadmin/EnableTemplateForOrganisation/${id}/${orgId}`, {
        isDisable: false,
      });
      // Update UI after successful enablement
      handleProductCheckBox();
      showToast('success', 'Template Enable for Franchise');
    } catch (error) {
      showToast('error', 'Error Enabling Template');
    }
    // Close backdrop after operation
    closeBackdrop();
  };

  // Disable template for franchise
  const disableTemplate = async (tempId) => {
    try {
      await privateAxios.post(`/procureadmin/DisableTemplateForOrganisation/${tempId}/${orgId}`, {
        isDisable: true,
      });
      // Update UI after successful disablement
      showToast('success', 'Template Disabled for Franchise');
      handleProductCheckBox();
    } catch (error) {
      showToast('error', 'Error Disabling Template');
    }
    // Close backdrop after operation
    closeBackdrop();
  };

  // Handle toggle template switch
  const handleToggleTemplate = (tempId, isDisable) => {
    openBackdrop();
    // If template is currently disabled, enable it; otherwise, disable it
    isDisable ? enableTemplate(tempId) : disableTemplate(tempId);
  };

  // Handle view details of a template
  const handleViewDetails = (tempId) => {
    navigate(`/superadmin/franchises/commercial/revisions/${tempId}/${orgId}`, {
      state: { type: 'Commercial' }
    });
  };

  // Fetch and handle product checkbox on organization change
  useEffect(() => {
    handleProductCheckBox();
  }, [orgId]);


  
  return (
    <div style={{ maxHeight: '50vh', overflowY: 'auto' }} >
      <table className={superadminStyle.admintable}>
        <thead>
          <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
            <th style={{ width: '45%' }}>Commercials</th>
            <th>Requested On</th>
            <th>Active</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {commercialRes &&
            commercialRes.map((item) => (
              <tr key={item.id} className={superadminStyle.admintableRow}>
                <td>{item.name}</td>
                <td>{item.requestedOn.split("T")[0]}</td>
                <td>
                  <div className={superadminStyle.actionIcons}>
                    <ToggleSwitch
                      checked={!item.isDisable}
                      name={`checkBox_${item.id}`}
                      onChange={() => handleToggleTemplate(item.tempId, item.isDisable)}
                    />
                  </div>
                </td>
                <td>
                  <p style={{ color: '#16469D', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => handleViewDetails(item.tempId)}>View Details</p>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default CommercialTab;
