import React, { useEffect, useState } from 'react';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { Button, Modal, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import specificTempStyle from './CommercialTempleteSpecificView.module.css';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import { useNavigate } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import useAuth from 'hooks/useAuth';
import CloseIcon from '@mui/icons-material/Close';
import BackButton from 'components/BackButton/BackButton';

function CommercialTempleteSpecificViewRequest() {
    const { auth } = useAuth();// Using the auth state from the useAuth hook
    const privateAxios = PrivateComponent();// Getting the privateAxios function from the custom axios instance
    const { client, specificationId, productId } = useParams();// Getting route parameters using useParams hook
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Using custom hooks for managing backdrop
    const { showToast, Toast } = useToast();// Using custom hooks for showing toast messages

    // ******************************Searching************************************************

    const [searchString, setSearchString] = useState('');// State for search string
    const navigate = useNavigate();// Getting navigation function from react-router-dom
    const handleSearchChange = (e) => {// Handling search input change
        setSearchString(e.target.value);
    };

    // ---------------------------Sorting-------------------------------------
    const [data, setData] = useState([]);// State for data

    const [moreDropDown, setMoreDropDown] = useState("");// State for dropdown menu
    const handleMoreDropDown = (id) => {// Handling dropdown menu
        if (moreDropDown === id) {
            setMoreDropDown("");
        }
        else {
            setMoreDropDown(id);
        }
    }


    const handleStatus = (status) => {// Function to handle status display
        let cstyle = {}
        switch (status?.toLowerCase()) {
            case "approved": cstyle = { backgroundColor: "#DCFCE7", color: "#1CA35E" };
                break;
            case "rejected": cstyle = { backgroundColor: "#FFD0CD", color: "#D93025" };
                break;
            case "pending": cstyle = { backgroundColor: "#FFF0C9", color: "#EDBD15" };
                break;
            // default: cstyle = { backgroundColor: "#808080", color: "#101010" };
        }

        return (
            <span className={specificTempStyle.specitemplatetableRowStatus} style={cstyle}>
                {status}
            </span>
        )
    }

    // =================HANDLE GET DATA=======================

    const getTemplateData = async () => {// Function to get template data
        openBackdrop();// Opening backdrop
        try {
            const res = await privateAxios.get(`/procureadmin/getAllTemplatesInsideMyTemplates/${auth.orgId}/${specificationId}`)
            console.log(res);
            const datar = res.data
            setData(datar);// Opening backdrop

        } catch (error) {
            showToast("error", "Error getting Templates")// Showing error toast
        }
        closeBackdrop();// Closing backdrop
    }
    useEffect(() => {
        getTemplateData();// Fetching data on component mount
    }, [])


    // ================Back Button========

    const handleBackButton = () => {// Function to handle back button click
        navigate(-1);// Navigating back
    }


    // ================Add Template Modal========
    const [addTemplateModal, setAddTemplateModal] = useState(false);// State for add template modal
    const handleAddTemplateModal = () => { // Function to handle add template modal
        setAddTemplateModal(!addTemplateModal);
    }


    // ================get Avaiable Template to Import========

    const [adminTemplates, setAdminTemplates] = useState([]); // State for available admin templates
    const [selectedTemplate, setSelectedTemplate] = useState([]); // State for selected template
 
    const getDataFromSuperAdmin = async () => {// Function to get data from super admin
        openBackdrop(); // Opening backdrop
        try {

            const res = await privateAxios.get(`/procureadmin/getAllImportedTemplatesInKam/${specificationId}`);
            const requestedTemplates = res.data.filter((req) => {
                return !data.some(tee => tee.id === req.id)
            })
            setAdminTemplates(requestedTemplates) // Setting admin templates to state
        } catch (error) {
            showToast("error", error.message) // Showing error toast
        }
        closeBackdrop(); // Closing backdrop
    }

    useEffect(() => {
        if (addTemplateModal) {
            getDataFromSuperAdmin(); // Fetching data when addTemplateModal is true
        }
    }, [addTemplateModal])

    // ================handle Template CheckBox========

    const [selectedTemplateItem, setSelectedTemplateItem] = useState([]);// State for selected template items

    const handleTemplateCheckBox = (temp) => {// Function to handle template checkbox
        if (selectedTemplateItem.some((rt) => rt.id === temp.id)) {
            setSelectedTemplateItem(prev => prev.filter((rt) => rt.id !== temp.id))
        } else {
            setSelectedTemplateItem(prev => [...prev, temp])
        }
    }

    // =================handleRequestTemplate=====================

    const handleRequestTemplate = async () => {  // Function to handle request template
        openBackdrop();// Opening backdrop
        try {
            const payload = {
                ids: selectedTemplateItem.map((temp) => temp.id),
                orgId: auth.orgId,
            }
            const res = await privateAxios.post(`/procureadmin/requestTemplateInOrganisationTemplate`, payload)
            showToast("success", "Template Requested Successfully");// Showing success toast
            getTemplateData();// Refreshing template data
        } catch (error) {
            showToast("error", "Error Requesting Template");// Showing error toast
        }
        handleAddTemplateModal();// Closing add template modal
        closeBackdrop();// Closing backdrop

    }



    return (
        <>
            <BackdropComponent />
            <Toast />

            <div className={workSpaceStyle.workSpaceMain}>
                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <h1>
                            <BackButton />
                            {data[0]?.category || "Templates"}
                        </h1>
                        <p> Available <span> {data?.length}</span> </p>
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderSearchbar}>
                        <input
                            type="text"
                            onChange={handleSearchChange}
                            value={searchString}
                            placeholder="Search Here..."
                        />
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderRight}>
                        <button className={workSpaceStyle.headerbtn} style={{ backgroundColor: "#16469D", color: "white" }} onClick={() => { handleAddTemplateModal() }}>Add Template</button>
                        <Tooltip title="help">
                            <HelpOutlineIcon />
                        </Tooltip>
                    </div>
                </div>
                <div className={specificTempStyle.tableCon}>

                    <table className={specificTempStyle.specitemplatetable}>
                        <thead>
                            <tr className={specificTempStyle.specitemplatetableRow} >
                                <th>Type</th>

                                <th>Status</th>
                                <th>
                                    -

                                    -
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {data.filter((item) =>
                                item.name.trim().toLowerCase().includes(searchString.toLowerCase()))
                                .map((item, index) => (
                                    <tr className={specificTempStyle.specitemplatetableRow} >
                                        <td >{item.name}</td>
                                        <td>
                                            <div style={{ width: "100%", justifyContent: 'center', alignItems: "center", display: "flex" }}>
                                                {handleStatus(item.status)}

                                            </div>
                                        </td>

                                        <td>
                                            <div className={specificTempStyle.specitemplatetableRowIcon}>
                                                <span style={{ position: "relative" }}>
                                                    <MoreVertIcon onClick={() => handleMoreDropDown(item.id)} />
                                                    {moreDropDown === item.id &&
                                                        <ThreeDotDropDown toggle={() => handleMoreDropDown(item.id)}>
                                                            <li onClick={() => { handleMoreDropDown(item.id); navigate(`/${client}/commercial`) }}>
                                                                Create A Task
                                                            </li>
                                                        </ThreeDotDropDown>}
                                                </span>
                                            </div>
                                        </td>

                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                {data.length === 0 &&
                    <div className={specificTempStyle.noTemplatePlaceHolder}>
                        <div className={specificTempStyle.noTemplatePlaceHolderChild}>
                            <span>
                                <PostAddOutlinedIcon />
                            </span>
                            <p>You have templates added to your shipping terms</p>
                            <button style={{ cursor: "pointer" }} className={specificTempStyle.noTemplatePlaceHolderbtn} onClick={() => { handleAddTemplateModal() }}>
                                <span>
                                    <AddIcon />
                                </span>
                                Add
                            </button>
                        </div>
                    </div>
                }
            </div >


            <Modal
                open={addTemplateModal}
                onClose={handleAddTemplateModal}
            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>
                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Import Template in {data[0]?.category}</h2>
                            <span onClick={handleAddTemplateModal}><CloseIcon /></span>
                        </div>

                        <div className={specificTempStyle.templatesWrapper}>

                            {adminTemplates.length > 0 ?
                                adminTemplates.map((temp, index) => {
                                    return (
                                        <div className={specificTempStyle.templateCard}>
                                            <p>{temp.name}</p>
                                            <input type="checkbox" checked={selectedTemplateItem.some((rt) => rt.id === temp.id)} onChange={() => handleTemplateCheckBox(temp)} />
                                        </div>
                                    )
                                })
                                :
                                <h4>
                                    No New Template Available to Import
                                </h4>
                            }
                        </div>

                        {adminTemplates.length > 0 &&
                            <div style={{ width: "100%", display: "flex", }}>
                                <button style={{
                                    height: "35px",
                                    padding: "10px 15px",
                                    backgroundColor: "#16469D",
                                    border: "none",
                                    color: "white",
                                    borderRadius: "5px",
                                    cursor: "pointer",

                                }}
                                    onClick={handleRequestTemplate}
                                >Send Request</button>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default CommercialTempleteSpecificViewRequest;
