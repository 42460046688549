import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useToast from 'hooks/useToast';
import revisionEditStyle2 from '../../pages/RevisionEdit/RevisionEdit.module.css'
import ProfileIcon from '../../components/ProfileIcon/ProfileIcon';
import revisionEditStyle from './CommercialRevisionCompare.module.css'
import useAuth from 'hooks/useAuth';


const CompareDescriptionTab = ({ approovalStatus, approverRemarks, revisionDescription, descriptionRemark, setDescriptionRemark }) => {
  const { showToast,Toast } = useToast();
  const { auth } = useAuth();

  return (
    <>
    <Toast/>
    <div className={revisionEditStyle.specficationTabMain}>

      <div className={revisionEditStyle.descriptionBox}>
        <ReactQuill modules={{ toolbar: false }}
          readOnly={true}
          style={{ width: "100%", height: "45vh" }}
          theme="snow"
          value={Object.values(revisionDescription)[0]?.name}
        />
      </div>

      {Object.values(revisionDescription).map((spec, index) => {
        return (
          <>
            <h3>{Object.keys(revisionDescription)[index]}</h3>
            <textarea name="" id="" cols="30" rows="10" 
             style={{ outline: "none", padding: "10px", minHeight: "40px" }}
            value={spec?.vendorRemark} onChange={() => { console.log("Editing Not Allowed") }} className={revisionEditStyle.descriptionCompareTextBox} />
          </>
        )
      })}


      {approverRemarks?.length > 0 &&

        <table className={revisionEditStyle2.DocumentTable}>
          <tr className={`${revisionEditStyle2.DocumentTableRow} ${revisionEditStyle2.DocumentTableHead}`}>
            <th>
              Approver Remarks
            </th>
            <th style={{ textAlign: "left", paddingRight: "20px" }}>
              From
            </th>
          </tr>
          {approverRemarks?.map((rem, index) => {
            return (
              <tr className={revisionEditStyle2.DocumentTableRow}>
                <td className={revisionEditStyle2.reactQuillTd}>
                  <textarea
                    readOnly={true}
                    style={{ border: "none", outline: "none", padding: "10px", minHeight: "40px" }}
                    value={rem.remark}
                  />
                </td>
                <td style={{ textAlign: "right", paddingRight: "20px" }}>
                  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <ProfileIcon data={{ email: rem?.email }} height={"26px"} width={"26px"} showProfileBanner={rem.name == "No Name" ? false : true} />
                    {rem.name}
                  </div>
                </td>
              </tr>
            )
          })}
        </table>
      }

      {auth.roles !== "KAM"
        &&
        <div style={{ display: "flex", height: "20vh", width: "100%", flexDirection: "column", gap: "5px" }}>
          <h3 style={{ marginBottom: "3px", fontSize: "16px", fontWeight: "800" }}>Add Remark</h3>
          <textarea name="" id="" cols="30" rows="10" value={descriptionRemark} onChange={(e) => {
            if (approovalStatus !== "APPROVAL" && approovalStatus !== "REJECTED") {
              setDescriptionRemark(e.target.value)

            } else {
              showToast("error", "You can't add remark in after Decision");
            }
          }} className={revisionEditStyle2.descriptionCompareTextBox} style={{ height: "100%", padding: "10px", textIndent: "0" }} />
        </div>

      }

    </div>
    </>
  )
}

export default CompareDescriptionTab
