import React from 'react'
import toggleStyle from './ToggleSwitch.module.css'


const ToggleSwitch = ({name,onChange,checked,value,style}) => {
    return (
        <label className={toggleStyle.switch} style={style}>
            <input type="checkbox" onChange={onChange} name={name} checked={checked} value={value} />
            <span className={`${toggleStyle.slider} ${toggleStyle.round}`}></span>
        </label>
    )
}

export default ToggleSwitch
