import React, { useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styles from './Document.module.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Tooltip from '@mui/material/Tooltip';

import DocumentChatPannel from 'components/DocumentChat/DocumentChatPannel';
import DocumentChatRows from 'components/DocumentChat/DocumentChatRows';
import DocumentChatInput from 'components/DocumentChat/DocumentChatInput';
import { Link } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';
import DocumentChatRow from 'components/DocumentChat/DocumentChatRow';
import useAuth from 'hooks/useAuth';
import useBlobDownload from 'hooks/useBlobDownload';
import useBackdrop from 'hooks/useBackdrop';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';
import DocumentUploadModal from 'components/DocumentUploadModal/DocumentUploadModal';
import RenameModal from 'components/RenameModal/RenameModal';


const DocumentsTab = ({ isDefaultRevision,viewType, showToast, addDocumentModal, handleAddDocumentModal, getDataProcure, documents,revisionId }) => {
  const { auth } = useAuth(); // Get authentication data from useAuth hook
  const privateAxios = PrivateComponent(); // Axios instance for private requests
  const { fileDownload } = useBlobDownload(); // File download function
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Backdrop functionality

   // State for delete modal
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

    // State for document more options
  const [documentMore, setDocumentMore] = useState("");

    // State for rename modal
  const [renameModal, setRenameModal] = useState(false)
  const [renameText, setRenameText] = useState("");
  const [docId, setDocId] = useState("");
  
  // State for document chats
  const [documentChats, setDocumentChats] = useState([])
  const [docChatLoading, setDocChatLoading] = useState(true);


 // Function to toggle delete modal
  const handleDeleteModal = (id) => {
    setDeleteModal(prev => !prev);
    if (id !== null) {
      setDeleteId(id)
    }
  }

    // Function to toggle rename modal
  const handleRenameModal = (name) => {
    setRenameModal(prev => !prev)
    if (name !== null && name !== undefined && name !== "") {
      setRenameText(name)
    }

  }

    // Function to handle document upload
  const handleDocumentUpload = async (data) => {
    openBackdrop()
    try {
      const fd = new FormData();
      fd.append(`files`, data.files);
      fd.append("name", data.fileName)
      fd.append("docType", data.type)
      fd.append("uploadedBy", auth.roles)

      let URL="";
      if(viewType==="APVVIEW")
      {
        URL=`/technicalSpecification/uploadProductDocumentsOnVendorRevision/${revisionId}`
      }
      else{
        URL=`/commercial/uploadCommercialDocuments/${revisionId}`
      }

      const res = await privateAxios.post(`${URL}`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      getDataProcure();
      handleAddDocumentModal();
      showToast("success", "Document Uploaded")

    } catch (error) {
      showToast("error", "Error Uploading Document")

    }
    closeBackdrop();
  }




 // Function to handle document deletion
  const handleDocDelete = async (docId) => {
    openBackdrop();
    try {
      await privateAxios.delete(`/technicalSpecification/deleteDocumentOfRevision/${docId}`).then(res => {
        // console.log(res.data);
        getDataProcure();
      }).catch(err => {
        // console.log(err)
        showToast("success", "Document Deleted Successfully")
      })
    } catch (error) {
      showToast("error", "Error Deleting Document")
      // console.log(error)
    }
    closeBackdrop();
  }


 // Function to handle document renaming
  const handleRenameDoc = async (data) => {
  openBackdrop();
    try {
      // console.log(docId)
      if (docId) {
        await privateAxios.post(`/technicalSpecification/renameDocument/${docId}?name=${data.name}`).then(res => {
         
          handleRenameModal();
          getDataProcure()
          setRenameText("");
        }).catch(err => {
          // console.log(err)
          handleRenameModal();
        })
      }
      else {
        alert("Document ID Not Present")
        handleRenameModal();
      }

    } catch (error) {
      handleRenameModal();
      // console.log(error)
    }

    closeBackdrop();
  }
  const [openConfidential, setOpenConfidential] = useState(false)


  // ---------------------document chat states--------------------

  // Function to handle opening/closing document chat
  const [documentChatOpen, setDocumentChatOpen] = useState("")
  const handleChatOpen = (data, docId) => {

    if (data === documentChatOpen) {
      setDocumentChatOpen("")
      setDocumentChats("");
    }
    else {
      getDocumentChat(docId)
      setDocumentChatOpen(data);
    }
  }

  // State for document chat value
  const [documentChatValue, setDocumentChatValue] = useState("")

  // Function to handle sending document chat message
  const handleDocumentChatSend = async (e, documentId) => {
    e.preventDefault();
    try {
      if (documentChatValue.length > 0) {

        await privateAxios.post(`/technicalSpecification/addRemarkOnDocumentByEmployee/${auth.userData.id}/${documentId}`, { remark: documentChatValue }).then(res => {
          // console.log(res.data);
          setDocumentChatValue("");
          // console.log(documentChatValue)
          getDocumentChat(documentId);
        }).catch((err) => {
          // console.log(err);
        })
      }
    } catch (error) {
      // console.log(error)
    }

  }

  // Function to fetch document chat
  const getDocumentChat = async (documentId) => {
    try {
      await privateAxios.get(`/technicalSpecification/getRemarksOfDocument/${documentId}`).then(res => {
        handleChatNotification()
        setDocumentChats(res.data);
        setDocChatLoading(false)
      }).catch((err) => {
        // console.log(err);
        setDocChatLoading(false)
      })
    } catch (error) {
      // console.log(error)
      setDocChatLoading(false)
    }

  }

// Function to handle showing/hiding additional document options
  const handleDocumentMore = (index) => {

    if (documentMore === index) {
      setDocumentMore("");
    }
    else {
      setDocumentMore(index)
    }

  }


  // =====================chat notification========================

  // Function to handle chat notification
  const [reRender, setReRender] = useState(false);

  const handleChatNotification = () => {
    setReRender(prev => !prev);

  }

  // =====================chat notification========================




  // ---------------------------------------

  return (
    <>
      <div className={styles.documentsTabMain}>

        <table className={styles.DocumentTable}>
          <thead>
            <tr className={`${styles.DocumentTableRow} ${styles.DocumentTableHead}`}>
              <td>Title</td>
              <td>Date Added</td>
              <td>Remark</td>
              <td>
                <div className={styles.documentIconRow}>

                  <a><FileDownloadIcon /></a>


                  <a><MoreVertIcon /></a>

                </div>
              </td>

            </tr>
          </thead>
          <tbody>
            {documents?.map((doc, index) => {
              if (doc.docType !== "CONFIDENTIAL") {


                return (
                  <tr key={index} className={styles.DocumentTableRow}>
                    <td>{doc.name || doc.url}</td>
                    <td>{doc?.date?.split('T')[0] || "Null"}</td>
                    <td style={{ position: "relative" }} >
                      <Tooltip title='Comments'>
                        <span onClick={() => { handleChatOpen(index, doc.id); }}>

                          <ChatIconWithBadge url={`/technicalSpecification/getNotficationCountOfProductDocument`} id={doc.id} reRender={reRender} />
                        </span>
                      </Tooltip>
                      {isDefaultRevision ? <>{documentChatOpen === index && <div className={styles.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                        <DocumentChatRows>
                          {docChatLoading ? <div>Chat Loading ..... </div> :

                            documentChats.length === 0 ? <p>No Previous Chats</p> :
                              documentChats?.map((chat, index) => {
                                return (
                                  <DocumentChatRow key={index}
                                    name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                    email={chat.email}
                                  // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                  />
                                )

                              })}
                        </DocumentChatRows>
                        <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                      </DocumentChatPannel>}
                    </td>
                    <td> <div className={styles.documentIconRow}>

                      <Link

                        onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}
                      ><FileDownloadIcon /></Link>


                      <Link style={{ position: "relative" }} className={styles.documentMoreDots} onClick={(e) => { e.preventDefault(); handleDocumentMore(index) }} ><MoreVertIcon />
                        {documentMore === index && <ul style={{ position: "absolute" }}>
                          {
                            !isDefaultRevision

                            && <li onClick={() => { handleRenameModal(doc?.name); setDocId(doc.id) }}> <span><DriveFileRenameOutlineIcon /></span>Rename</li>
                          }
                          {
                            !isDefaultRevision
                            // revisionId === "0"
                            &&
                            <li onClick={() => handleDeleteModal(doc.id)}> <span><DeleteOutlineIcon /></span>Delete</li>
                          }
                          {/* <li> <span><DeleteOutlineIcon /></span>Some Other Option</li> */}
                        </ul>}
                      </Link>

                    </div></td>
                  </tr>
                )
              }
            })
            }

          </tbody>
        </table>




        <div className={styles.accordianDocumentab}>
          <div className={styles.accordianDocumentabHeader} onClick={() => setOpenConfidential(prev => !prev)}><span>
            {openConfidential ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </span>Confidential Documents</div>

          <div className={styles.accordianDocumentabBody}>

            {openConfidential &&
              <table className={styles.DocumentTable}>
                <tbody>
                  {documents?.map((doc, index) => {
                    if (doc.docType === "CONFIDENTIAL") {
                      return (
                        <tr key={index} className={styles.DocumentTableRow}>
                          <td>{doc.name || doc.url}</td>
                          <td>{doc?.date?.split('T')[0] || "Null"}</td>
                          <td style={{ position: "relative" }} >
                            <Tooltip title='Comments'>
                              <span onClick={() => { handleChatOpen(index, doc.id); }}>

                                <ChatIconWithBadge url={`/technicalSpecification/getNotficationCountOfProductDocument`} id={doc.id} reRender={reRender} />
                              </span>
                            </Tooltip>
                            {isDefaultRevision ? <>{documentChatOpen === index && <div className={styles.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                              <DocumentChatRows>
                                {docChatLoading ? <div>Chat Loading ..... </div> :

                                  documentChats.length === 0 ? <p>No Previous Chats</p> :
                                    documentChats?.map((chat) => {
                                      return (
                                        <DocumentChatRow key={index}
                                          name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                          email={chat.email}

                                        // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                        />
                                      )

                                    })}
                              </DocumentChatRows>
                              <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                            </DocumentChatPannel>}
                          </td>
                          <td> <div className={styles.documentIconRow}>

                            <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}
                            // target='_blank'
                            ><FileDownloadIcon /></Link>


                            <Link style={{ position: "relative" }} className={styles.documentMoreDots} onClick={(e) => { e.preventDefault(); handleDocumentMore(index) }} ><MoreVertIcon />
                              {documentMore === index && <ul style={{ position: "absolute" }}>
                                {
                                  !isDefaultRevision
                                  // revisionId === "0"
                                  && <li onClick={() => { handleRenameModal(doc?.name || ""); setDocId(doc.id) }}> <span><DriveFileRenameOutlineIcon /></span>Rename</li>
                                }
                                {
                                  !isDefaultRevision
                                  // revisionId === "0"
                                  &&
                                  <li onClick={() => handleDeleteModal(doc.id)}> <span><DeleteOutlineIcon /></span>Delete</li>
                                }
                                {/* <li> <span><DeleteOutlineIcon /></span>Some Other Option</li> */}
                              </ul>}
                            </Link>

                          </div></td>
                        </tr>
                      )
                    }
                  })

                  }

                </tbody>
              </table>

            }
          </div>

          <BackdropComponent />
        </div>


      </div>

      <DocumentUploadModal
        open={addDocumentModal}
        onClose={handleAddDocumentModal}
        onSubmit={handleDocumentUpload}

      />




      <RenameModal
        open={renameModal}
        onClose={handleRenameModal}
        onSubmit={handleRenameDoc}
        prefilled={renameText}
        header="Rename"
      />

  


      <DeleteConfirmation
        onCancel={handleDeleteModal}
        onConfirm={() => { handleDocDelete(deleteId); handleDeleteModal() }}
        open={deleteModal}
      // confimBtnText={""} 
      // discardText={""}
      >
        <h2>Delete Document</h2>
        <p>Are you sure you want to delete this Document?</p>

      </DeleteConfirmation>



    </>
  )
}

export default DocumentsTab



/**
 * RevisionEdit.js
 * 
 * This is the Document Tab of Revision Edit component, responsible for rendering Document Tab of Revision Edit. 
 * 
 *
 * Author: Ravinder Kumar
 *   
 * 
 * Created on: 2023-08-29
 * 
 * 
 * Last Modified on: 2023-09-28
 */