import React, { useEffect } from 'react'
import templeteStyle from './CommercialRevisionCompare.module.css'
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import TaskStyle from "../../pages/TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import { useState } from 'react';
import revisionEditStyle2 from '../../pages/RevisionEdit/RevisionEdit.module.css'
import { Typography, Button, Badge } from '@mui/material';
import Modal from '@mui/material/Modal';
import useAuth from 'hooks/useAuth';
import useToast from 'hooks/useToast';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';



const CompareQuestionsTab = ({ approovalStatus, approverRemarks, questionRows, setQestionRemark, qestionRemark, handleInputChange, handleAddQuestionRow, handleQuestionRowDelete, columns }) => {

  const { showToast, Toast } = useToast();
  const { auth } = useAuth();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const handleDeleteModal = (id) => {
    setDeleteModal(prev => !prev);
    setDeleteId(id)
  }


  return (
    <>
      <Toast />
      <div className={templeteStyle.questionsTabMain} style={{ gap: "15px" }}>
        <table className={templeteStyle.questionsTable}>
          <thead>
            <tr className={`${templeteStyle.questionsTableRow}`}>
              {columns?.map((col) => {
                return (
                  <th>{col}</th>
                )
              })}
            </tr>
          </thead>

          <tbody>
            {
              questionRows.map((row, index) => {
                return (
                  <tr className={`${templeteStyle.questionsTableRow}`}>
                    <td>
                      <span style={{ width: "50px", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                        {index + 1}
                      </span>
                    </td>

                    <td>
                      <input
                        value={row.name}
                        className={templeteStyle.questionRowInput}
                        name="name"
                        style={{ minWidth: "180px" }}
                      />
                    </td>

                    <td>
                      <input type="text" name='unit' value={row.unit} className={templeteStyle.questionRowInput} />
                    </td>

                    <td>
                      <input type="text" name='value' value={row.value} className={templeteStyle.questionRowInput} />
                    </td>

                    {row?.revisedValue.map(val => {
                      return (
                        <td style={{ minWidth: "max-content" }}>
                          <VendorValueInput
                            value={val.value}
                            kamValue={row.unit === "SINGLESELECT" ? row.expectedAns : row.value}
                            dropOption={val.value}
                            rule={row.ruleAttribute}
                            type={row.unit}
                            name="revisedValue"
                          />
                        </td>
                      )
                    })}

                    <td>
                      <input
                        value={row.remark}
                        className={templeteStyle.questionRowInput}
                        name="remark"
                      />
                    </td>

                    {row.vendorRemark.map(val => {
                      return (
                        <td>
                          <input type="text" name='unit' value={val.value} className={templeteStyle.questionRowInput} />
                        </td>
                      )
                    })}
                  </tr>
                )

              })
            }

          </tbody>
        </table>
        {approverRemarks?.length > 0 &&

          <table className={revisionEditStyle2.DocumentTable}>
            <tr className={`${revisionEditStyle2.DocumentTableRow} ${revisionEditStyle2.DocumentTableHead}`}>
              <th>
                Approver Remarks
              </th>
              <th style={{ textAlign: "left", paddingRight: "20px" }}>
                From
              </th>
            </tr>
            {approverRemarks?.map((rem, index) => {
              return (
                <tr className={revisionEditStyle2.DocumentTableRow}>
                  <td className={revisionEditStyle2.reactQuillTd}>
                    <textarea
                      readOnly={true}
                      style={{ border: "none", outline: "none", padding: "10px", minHeight: "40px" }}
                      value={rem.remark}
                    />
                  </td>
                  <td style={{ textAlign: "right", paddingRight: "20px" }}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                      <ProfileIcon data={{ email: rem?.email }} height={"26px"} width={"26px"} showProfileBanner={rem.name == "No Name" ? false : true} />
                      {rem.name}
                    </div>
                  </td>            
                </tr>
              )
            })}
          </table>
        }

        {auth.roles !== "KAM"
          &&
          <div style={{ display: "flex", height: "20vh", width: "100%", flexDirection: "column", gap: "5px" }}>
            <h3 style={{ marginBottom: "3px", fontSize: "16px", fontWeight: "800" }}>Add Remark</h3>
            <textarea name="" id="" cols="30" rows="10" value={qestionRemark} onChange={(e) => {
              if (approovalStatus !== "APPROVAL" && approovalStatus !== "REJECTED") {
                setQestionRemark(e.target.value)
              } else {
                showToast("error", "You can't add remark in after Decision");
              }
            }} className={revisionEditStyle2.descriptionCompareTextBox} style={{ height: "100%", padding: "10px", textIndent: "0" }} />
          </div>
        }

      </div>

      <Modal
        open={deleteModal}
        onClose={handleDeleteModal}
      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', width: '375px' }}>
          <form action="" onSubmit={(e) => {
            e.preventDefault();
            handleQuestionRowDelete(deleteId);
            handleDeleteModal();
          }} style={{ maxWidth: '100%' }} >

            <div className={TaskStyle.modalKam}>
              <Typography variant="h1">Delete Attribute</Typography>
              <Typography variant="h6">Are you sure you want to delete this Attribute?</Typography>

              <div className={TaskStyle.kamModalButton}>
                <Button type='submit' variant="contained">Delete</Button>
                <p onClick={handleDeleteModal}>Cancel</p>
              </div>

            </div>

          </form>
        </div>
      </Modal>


    </>
  )
}

export default CompareQuestionsTab;


const VendorValueInput = ({ value, onChange, kamValue, dropOption, rule, type, name, style, ...props }) => {
  const [vStyle, setVStyle] = useState({
    ...style,
    color: "black",

  });



  useEffect(() => {
    let newStyle = { ...style };

    switch (type) {
      case 'NUMBER':
        switch (rule) {
          case 'LESS':
            newStyle.backgroundColor = parseFloat(value) < parseFloat(kamValue) ? '#00ff001a' : '#ff00001a';
            break;
          case 'MORE':
            newStyle.backgroundColor = parseFloat(value) > parseFloat(kamValue) ? ' #00ff001a' : '#ff00001a';
            break;
          case 'EQUAL':
            newStyle.backgroundColor = parseFloat(value) === parseFloat(kamValue) ? '#00ff001a' : '#ff00001a';
            break;
          default:
            newStyle.backgroundColor = '';
        }
        break;

      // case 'TEXT':
      //     newStyle.backgroundColor = value?.trim() === kamValue?.trim() ? '#00ff001a' : '#ff00001a'
      //     break;
      case 'RANGE':
        const rte = kamValue?.split("-").map((item) => item.trim());

        if (rte?.length > 1) {

          switch (rule) {
            case 'INRANGE':
              newStyle.backgroundColor = (parseFloat(value) >= parseFloat(rte[0]) && parseFloat(value) <= parseFloat(rte[1])) ? '#00ff001a' : '#ff00001a';
              break;
            case 'OUTRANGE':
              newStyle.backgroundColor = (parseFloat(value) <= parseFloat(rte[0]) || parseFloat(value) >= parseFloat(rte[1])) ? ' #00ff001a' : '#ff00001a';
              break;

            default:
              newStyle.backgroundColor = '';
          }
        }
        else {
          newStyle.backgroundColor = '';
        }

        break;
      case 'BOOLEAN':
        newStyle.backgroundColor = value?.trim() === kamValue?.trim() ? '#00ff001a' : '#ff00001a'
        break;
      case 'SINGLESELECT':


        newStyle.backgroundColor = value?.trim() === kamValue?.trim() ? '#00ff001a' : '#ff00001a'



        break;


      case 'DATE':
        switch (rule) {
          case 'LESS':

            newStyle.backgroundColor = new Date(value) < new Date(kamValue) ? '#00ff001a' : '#ff00001a';

            break;
          case 'MORE':

            newStyle.backgroundColor = new Date(value) > new Date(kamValue) ? ' #00ff001a' : '#ff00001a';

            break;
          case 'EQUAL':

            newStyle.backgroundColor = new Date(value) === new Date(kamValue) ? '#00ff001a' : '#ff00001a';

            break;
        }

        break;
      // Add other cases as needed

      default:
        newStyle.backgroundColor = '#FFFF001a';
    }

    setVStyle(newStyle);
  }, [value, kamValue, rule, type, style]);

  // console.log(vStyle)

  return (
    <p style={vStyle}>
      {value}
    </p>
  )
};


