import React, { useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import revisionEditStyle from 'pages/RevisionEdit/RevisionEdit.module.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Tooltip from '@mui/material/Tooltip';
import DocumentChatPannel from 'components/DocumentChat/DocumentChatPannel';
import DocumentChatRows from 'components/DocumentChat/DocumentChatRows';
import DocumentChatInput from 'components/DocumentChat/DocumentChatInput';
import { Link } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';
import DocumentChatRow from 'components/DocumentChat/DocumentChatRow';
import useAuth from 'hooks/useAuth';
import useBlobDownload from 'hooks/useBlobDownload';
import useBackdrop from 'hooks/useBackdrop';
import TaskStyle from "pages/TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import { Typography, Badge, Button } from '@mui/material';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';



// =====================mui compoents====================
const TempleteDocumentsTab = ({addDocumentModal, handleAddDocumentModal, getDataProcure, documents, handleDocumentUpload, setDocuments, revisionId}) => {
  const { auth } = useAuth();// Authentication related data 
  const privateAxios = PrivateComponent();//Axios instance for private requests
  const {fileDownload}=useBlobDownload();
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();//backdrop related functions 

  const [deleteModal,setDeleteModal]=useState(false);
  const [deleteId,setDeleteId]=useState("");

  const inputRef = useRef();
  const [documentName, setDocumentName] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([])
  const [documentMore, setDocumentMore] = useState("");
  const [renameModal, setRenameModal] = useState(false)
  const [renameText, setRenameText] = useState("");
  const [docId, setDocId] = useState("");
  const [documentChats, setDocumentChats] = useState([])
  const [docChatLoading, setDocChatLoading] = useState(true);
  const [docType, setDocType] = useState(false);
  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    // setFiles(files); 
    setSelectedFiles([...selectedFiles, ...files]);
    setSelectedFileNames([...selectedFileNames, ...files.map((file) => file.name)]); // Update selected file names
  };
  const handleRemoveSelectedFile = (index) => {
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);

    const updatedSelectedFileNames = [...selectedFileNames];
    updatedSelectedFileNames.splice(index, 1);

    setSelectedFiles(updatedSelectedFiles);
    setSelectedFileNames(updatedSelectedFileNames);
  };

  function truncateFileName(fileName, maxLength) {
    if (typeof fileName !== 'string' || fileName.length === 0) {
      return ''; // Return an empty string or handle this case as needed
    }

    if (fileName.length <= maxLength) {
      return fileName;
    }

    const truncatedFileName = fileName.substring(0, maxLength - 5) + '...';
    return truncatedFileName;
  }

  const handleDeleteModal=(id)=>{
    setDeleteModal(prev=>!prev);
    if(id!==null)
    {
      setDeleteId(id)
    }
  }

  const handleRenameModal = (name) => {
    setRenameModal(prev => !prev)
    if(name!==null)
    {
      setRenameText(name)
    }

  }

  const handleDocumentValidation = async () => {
    openBackdrop()

    if (documentName !== "" && selectedFiles.length > 0 && revisionId !== "0") {

      const res = await handleDocumentUpload(documentName, selectedFiles, docType, revisionId);
      setSelectedFiles([]);
      setSelectedFileNames([]);
      setDocumentName("");
      handleAddDocumentModal()
      closeBackdrop();

    }
    else {
      closeBackdrop();

      if (revisionId === null) {
        alert("this is the default Revision please save a copy and then upload documents")
      }
      else {
        closeBackdrop();
        alert("all feilds are required check document name and files ")
      }
    }
  }

  const handleDocDelete = async (docId) => {
    try {
      await privateAxios.delete(`/commercial/deleteDocumentOfCommercialRevision/${docId}`).then(res => {
        // console.log(res.data);
        getDataProcure();
      }).catch(err => {
        // console.log(err)
      })
    } catch (error) {

      // console.log(error)
    }
  }
  const handleRenameDoc = async (e) => {
    e.preventDefault();
    try {
      // console.log(docId)
      if (docId) {
        await privateAxios.post(`/commercial/renameDocumentOfCommercialRevision/${docId}?name=${renameText}`).then(res => {
          // console.log(res.data);
          handleRenameModal();
          getDataProcure()
          setRenameText("");
        }).catch(err => {
          // console.log(err)
          handleRenameModal();
        })
      }
      else {
        alert("Document ID Not Present")
        handleRenameModal();
      }

    } catch (error) {
      handleRenameModal();
      // console.log(error)
    }
  }
  const [openConfidential, setOpenConfidential] = useState(false)


  // ---------------------document chat states--------------------
  const [documentChatOpen, setDocumentChatOpen] = useState("")
  const handleChatOpen = (data, docId) => {

    if (data === documentChatOpen) {
      setDocumentChatOpen("")
      setDocumentChats("");
    }
    else {
      getDocumentChat(docId)
      setDocumentChatOpen(data);
    }
  }

  const [documentChatValue, setDocumentChatValue] = useState("")

  const handleDocumentChatSend = async (e, documentId) => {
    e.preventDefault();
    try {
      if (documentChatValue.length > 0) {

        await privateAxios.post(`/commercial/addRemarkOncommercialDocumentTemplate/${auth.userData.id}/${documentId}`, { remark: documentChatValue }).then(res => {
          // console.log(res.data);
          setDocumentChatValue("");
          // console.log(documentChatValue)
          getDocumentChat(documentId);
        }).catch((err) => {
          // console.log(err);
        })
      }
    } catch (error) {
      // console.log(error)
    }

  }

  const getDocumentChat = async (documentId) => {
    handleChatNotification();
    try {
      await privateAxios.get(`/commercial/getRemarksOfcommercialDocumentTemplate/${documentId}`).then(res => {

        setDocumentChats(res.data);
        setDocChatLoading(false)
      }).catch((err) => {
        // console.log(err);
        setDocChatLoading(false)
      })
    } catch (error) {
      // console.log(error)
      setDocChatLoading(false)
    }

  }


  const handleDocumentMore = (index) => {

    if (documentMore === index) {
      setDocumentMore("");
    }
    else {
      setDocumentMore(index)
    }

  }


  // =====================chat notification========================
  const [reRender, setReRender] = useState(false);

  const handleChatNotification = () => {
      setReRender(prev => !prev);

  }

  // =====================chat notification========================
  return (
    <>
    <div className={revisionEditStyle.documentsTabMain}>

      <table className={revisionEditStyle.DocumentTable}>
        <thead>
          <tr className={`${revisionEditStyle.DocumentTableRow} ${revisionEditStyle.DocumentTableHead}`}>
            <td>Title</td>
            <td>Date Added</td>
            <td>Remark</td>
            <td>
              <div className={revisionEditStyle.documentIconRow}>

                <a><FileDownloadIcon /></a>


                <a><MoreVertIcon /></a>

              </div>
            </td>

          </tr>
        </thead>
        <tbody>
          {documents?.map((doc, index) => {
            if (doc.docType !== "CONFIDENTIAL") {


              return (
                <tr key={index} className={revisionEditStyle.DocumentTableRow}>
                  <td>{doc.name || doc.url}</td>
                  <td>{doc?.date?.split('T')[0] || "Null"}</td>
                  <td style={{ position: "relative" }} >
                    <Tooltip title='Comments'>
                    <span onClick={() => { handleChatOpen(index, doc.id); }}>
                            <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialDocuments`} id={doc.id} reRender={reRender}/>
                                                 
                            </span>
                    </Tooltip>
                    {revisionId === "0" ? <>{documentChatOpen === index && <div className={revisionEditStyle.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                      <DocumentChatRows>
                        {docChatLoading ? <div>Chat Loading ..... </div> :

                          documentChats.length === 0 ? <p>No Previous Chats</p> :
                            documentChats?.map((chat,index) => {
                              return (
                                <DocumentChatRow key={index}
                                  name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                  email={chat.email}
                                // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                />
                              )

                            })}
                      </DocumentChatRows>
                      <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                    </DocumentChatPannel>}
                  </td>
                  <td> <div className={revisionEditStyle.documentIconRow}>

                    <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url,doc.name) }}><FileDownloadIcon /></Link>


                    <Link style={{ position: "relative" }} className={revisionEditStyle.documentMoreDots} onClick={(e) => { e.preventDefault(); handleDocumentMore(index) }} ><MoreVertIcon />
                      {documentMore === index && <ul style={{ position: "absolute" }}>
                        {
                          revisionId !== "0"
                        
                          && <li onClick={() => { handleRenameModal(doc?.name|| ""); setDocId(doc.id) }}> <span><DriveFileRenameOutlineIcon /></span>Rename</li>
                        }
                        {
                          revisionId !== "0"
                          // revisionId === "0"
                          &&
                          <li onClick={() => handleDeleteModal(doc.id)}> <span><DeleteOutlineIcon /></span>Delete</li>
                        }
                        {/* <li> <span><DeleteOutlineIcon /></span>Some Other Option</li> */}
                      </ul>}
                    </Link>

                  </div></td>
                </tr>
              )
            }
          })
          }

        </tbody>
      </table>




      <div className={revisionEditStyle.accordianDocumentab}>
        <div className={revisionEditStyle.accordianDocumentabHeader} onClick={() => setOpenConfidential(prev => !prev)}><span>
          {openConfidential ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
        </span>Confidential Documents</div>

        <div className={revisionEditStyle.accordianDocumentabBody}>

          {openConfidential &&
            <table className={revisionEditStyle.DocumentTable}>
              <tbody>
                {documents?.map((doc, index) => {
                  if (doc.docType === "CONFIDENTIAL") {
                    return (
                      <tr key={index} className={revisionEditStyle.DocumentTableRow}>
                        <td>{doc.name || doc.url}</td>
                        <td>{doc?.date?.split('T')[0] || "Null"}</td>
                        <td style={{ position: "relative" }} >
                          <Tooltip title='Comments'>
                            <span onClick={() => { handleChatOpen(index, doc.id); }}>
                            <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialDocuments`} id={doc.id} reRender={reRender}/>
                                                 
                            </span>
                          
                          </Tooltip>
                          {revisionId === "0" ? <>{documentChatOpen === index && <div className={revisionEditStyle.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                            <DocumentChatRows>
                              {docChatLoading ? <div>Chat Loading ..... </div> :

                                documentChats.length === 0 ? <p>No Previous Chats</p> :
                                  documentChats?.map((chat) => {
                                    return (
                                      <DocumentChatRow key={index}
                                        name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                        email={chat.email}
                                      // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                      />
                                    )

                                  })}
                            </DocumentChatRows>
                            <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                          </DocumentChatPannel>}
                        </td>
                        <td> <div className={revisionEditStyle.documentIconRow}>

                          <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url,doc.name) }}
                          // target='_blank'
                          ><FileDownloadIcon /></Link>


                          <Link style={{ position: "relative" }} className={revisionEditStyle.documentMoreDots} onClick={(e) => { e.preventDefault(); handleDocumentMore(index) }} ><MoreVertIcon />
                            {documentMore === index && <ul style={{ position: "absolute" }}>
                              {
                                revisionId !== "0"
                                // revisionId === "0"
                                && <li onClick={() => { handleRenameModal(doc?.name|| ""); setDocId(doc.id) }}> <span><DriveFileRenameOutlineIcon /></span>Rename</li>
                              }
                              {
                                revisionId !== "0"
                                // revisionId === "0"
                                &&
                                <li onClick={() => handleDeleteModal(doc.id)}> <span><DeleteOutlineIcon /></span>Delete</li>
                              }
                              {/* <li> <span><DeleteOutlineIcon /></span>Some Other Option</li> */}
                            </ul>}
                          </Link>

                        </div></td>
                      </tr>
                    )
                  }
                })

                }

              </tbody>
            </table>

          }
        </div>

        <BackdropComponent />
      </div>


    </div>
    <Modal
      open={addDocumentModal}
      onClose={handleAddDocumentModal}

    >
      <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

        <form action=""
          // onSubmit={}
          style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
          <div className={workSpaceStyle.modalHeader} >

            <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}></span>Add Document</h3>
            <div onClick={handleAddDocumentModal}>
              <Tooltip title='Close'><CloseIcon /></Tooltip>
            </div>
          </div>

          <div className={formStyle.inputRow}>
            <label>Name</label>
            <input type="text" value={documentName} onChange={(e) => setDocumentName(e.target.value)} placeholder='Document Name' />
         
          </div>
          <div className={formStyle.inputRow}>
            <label>Upload File</label>
            <div style={{ height: "100px", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#f6f6f6", borderRadius: "10px", border: "1px dotted grey" }}>

           
              <input type="file" multiple
                ref={inputRef}
                onChange={handleFileInputChange}
                hidden placeholder='Document Name' />
              <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", width: "100%", overflow: "auto", padding: "10px 10px" }}>

                {!selectedFileNames.length > 0 && <p style={{ fontSize: "14px", }}>Upload A file</p>}
                <ul style={{ width: "100%", display: "flex", flexDirection: "column", }}>
                  {selectedFileNames.map((fileName, index) => (

                    <li key={index}
                      //  className={TaskStyle.selectedFileItem}
                      style={{
                        display: 'flex',
                        justifyContent: "space-between", alignItems: 'center',
                        border: "1px solid grey",
                        padding: "0 10px"
                      }}>
                      <p style={{ fontSize: "14px" }}>

                        {truncateFileName(fileName, 15)}
                      </p>
                      <span onClick={() => handleRemoveSelectedFile(index)} style={{ textDecoration: 'none', border: 'none', display: "flex", justifyContent: "center", alignItems: "center" }}
                      ><CloseIcon sx={{ textDecoration: "none", border: 'none' }} /></span>
                    </li>

                  ))}
                </ul>


                <p style={{ fontSize: "14px", color: "blue" }} onClick={() => inputRef.current.click()}>Upload</p>
              </div>
            </div>
          
          </div>
          <div className={formStyle.inputRowCheckBox}>
            <input type="checkbox" checked={docType} onChange={(e) => setDocType(e.target.checked)} />
            <label>Confidential Document</label>
           
          </div>


          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='button' onClick={handleDocumentValidation}>Add </button>
          
          </div>

         
        </form>

      </div>
    </Modal>


    <Modal
      open={renameModal}
      onClose={handleRenameModal}

    >
      <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

        <form action="" onSubmit={handleRenameDoc} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
          <div className={workSpaceStyle.modalHeader} >

            <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><DriveFileRenameOutlineIcon /></span>Rename</h3>
            <div onClick={handleRenameModal}>
              <Tooltip title='Close'><CloseIcon /></Tooltip>
            </div>
          </div>

          <div className={formStyle.inputRow}>

            <input type="text" value={renameText} onChange={(e) => setRenameText(e.target.value)} placeholder='Revision Name' />
          
          </div>



          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>

            <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>


            <p onClick={() => { setRenameText(""); handleRenameModal() }} style={{ cursor: "pointer" }}>Discard</p>
          </div>

        
        </form>

      </div>
    </Modal>

    <Modal
      open={deleteModal}
      onClose={handleDeleteModal}

    >
      <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', width: '375px' }}>
        <form action="" style={{ maxWidth: '100%' }} >

          <div className={TaskStyle.modalKam}>
            <Typography variant="h1">Delete Document</Typography>
            <Typography variant="h6">Are you sure you want to delete this Document?</Typography>

            <div className={TaskStyle.kamModalButton}>
              <Button onClick={() => {handleDocDelete(deleteId); handleDeleteModal()}} variant="contained">Delete</Button>

              <p onClick={handleDeleteModal}>Cancel</p>
            </div>

          </div>

        </form>
      </div>
    </Modal>
  </>
  )
}

export default TempleteDocumentsTab
