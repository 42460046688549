import React, { useState, useEffect } from 'react';
import TaskStyle from './TaskPannel.module.css';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';


// Functional component for selecting subcategories within a category
const SubcategorySelector = ({ category, selectedSubcategories, onSelectSubcategory, index }) => {

  // State for controlling subcategory selection dropdown visibility
  const [subCatSelect, setSubCatSelect] = useState(false);

  // State for storing the selected subcategory
  const [selectedSubCat, setSelectedSubCat] = useState(null);

  // Handle click on a subcategory
  const handleSubcategoryClick = (subcategory) => {
    // Call the onSelectSubcategory function with the selected subcategory
    onSelectSubcategory(subcategory);
    // Set the selected subcategory name
    setSelectedSubCat(subcategory.categoryName);
    // Toggle the subcategory selection dropdown
    handleSubCatSelect();
  };

  // Toggle subcategory selection dropdown
  const handleSubCatSelect = () => {
    setSubCatSelect((prev) => !prev);
  };

  // State for subcategory search
  const [subCatSearch, setSubCatSearch] = useState("");



  return (
    <div className={TaskStyle.taskDetailsCol} >
      <div className={TaskStyle.selectorDiv}>
        <p onClick={() => handleSubCatSelect()} style={{ fontSize: "13px", color: "#3F3F3F", fontWeight: "500", display: "flex", alignItems: "center", justifyContent: "space-between", minWidth: "200px" }} >
          {(index < (selectedSubcategories.length - 1) && selectedSubCat) ||
            "Please select a sub-category"}
          <span>
            {subCatSelect ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </span>
        </p>

        {subCatSelect && (
          <ThreeDotDropDown toggle={handleSubCatSelect} style={{ bottom: "100%", top: "auto", width: "100%", borderRadius: "4px", maxHeight: "300px", overFlow: "auto" }}>
            <li style={{ backgroundColor: "white" }}>
              <input type="text" value={subCatSearch} onChange={(e) => setSubCatSearch(e.target.value)}
                style={{ width: "100%", padding: "5px", border: "none", outline: "none", border: "1px solid #ccc", color: "#000" }}
              />
            </li>
            {category.branch.filter((subcategory) => subcategory.categoryName.toLowerCase().includes(subCatSearch.trim().toLowerCase())).map((subcategory) => (
              <li
                key={subcategory.categoryId}
                onClick={() => handleSubcategoryClick(subcategory)}
              >
                {subcategory.categoryName}
              </li>
            ))
            }
          </ThreeDotDropDown>
        )}
      </div>
    </div>
  );
};

export default SubcategorySelector;
