import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import styles from './TechnicalComparisionRevisionView.module.css'
import BackButton from 'components/BackButton/BackButton';
import Tooltip from '@mui/material/Tooltip';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useAuth from 'hooks/useAuth';
import { PrivateComponent } from 'api/axios';
import AddIcon from '@mui/icons-material/Add';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'
import CloseIcon from '@mui/icons-material/Close';
import AttributeTab from 'components/TechnicalSpecificationCompareTabs/AttributeTab/AttributeTab';
import SpecificationTab from 'components/TechnicalSpecificationCompareTabs/SpecificationTab/SpecificationTab';
import DocumentsTab from 'components/TechnicalSpecificationCompareTabs/DocumentTab/DocumentTab';
import RenameModal from 'components/RenameModal/RenameModal';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatRow from 'components/ChatRows/ChatRow';
import { Modal } from '@mui/material';
import SendToApprovalCommityModal from 'components/SendToApprovalCommityModal/SendToApprovalCommityModal';
import ApprovalRevisionTable from 'components/ApprovalRevisionTable/ApprovalRevisionTable';
import CompareRankingModal from 'components/CompareRankingModal/CompareRankingModal';
import BarChartIcon from '@mui/icons-material/BarChart';

const TechnicalComparisionRevisionView = () => {
    // Importing necessary hooks and components
    const { showToast, Toast } = useToast(); // Toast component and its function
    const { taskId, productId, revisionId } = useParams(); // Parameters from URL
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Backdrop component and its functions
    const { auth } = useAuth(); // Authentication details
    const privateAxios = PrivateComponent(); // Private Axios instance
    const location = useLocation(); // Location hook
    const { state } = location; // State from location
    const navigate = useNavigate(); // Navigation function
    const searchParams = new URLSearchParams(location.search); // Parameters from query string
    const [viewType, setViewType] = useState(""); // State for view type


    // =======================set View Type==============================

    // Effect for setting view type based on state
    useEffect(() => {
        if (state) {
            setViewType(state.from);
        }
    }, [state]);


    //=======================button states==========================
    // Button states
    const [workSpaceHeaderRevisionMore, setworkSpaceHeaderRevisionMore] = useState(false); // State for workspace header
    const [multiSave, setMultiSave] = useState(false); // State for multi-saves

    // Function to toggle multi-save state
    const handleMultiSave = () => {
        setMultiSave(prev => !prev);
    };

    // =================Modal States===========================


    // Modal states
    const [renameModal, setRenameModal] = useState(false); // State for rename modal
    const handleRenameModal = () => {
        setRenameModal(prev => !prev);
    };
    const [approvalModal, setApprovalModal] = useState(false); // State for approval modal
    const handleApprovalModal = () => {
        setApprovalModal(prev => !prev);
    };
    const [deleteModal, setDeleteModal] = useState(false); // State for delete modal
    const handleDeleteModal = () => {
        setDeleteModal(prev => !prev);
    };
    const [duplicateNameModal, setDuplicateNameModal] = useState(false); // State for duplicate name modal
    const handleDuplicateNameModal = () => {
        setDuplicateNameModal(prev => !prev);
    };
    const [saveAsModal, setSaveAsModal] = useState(false); // State for save as modal
    const handleSaveAsModal = () => {
        setSaveAsModal(prev => !prev);
    };
    const [vendorModal, setVendorModal] = useState(false); // State for vendor modal
    const handleSendToVendorModal = (data) => {
        setVendorModal(prev => !prev);
    };
    const [reassignModal, setReassignModal] = useState(false); // State for reassign modal
    const handleReassignModal = () => {
        setReassignModal(prev => !prev);
    };

    // ==================Active Tab========================
    // Active tab state
    const [tabActive, setTabActive] = useState("0"); // State for active tab

    // Effect for setting active tab based on query string
    useEffect(() => {
        if (searchParams.get("tab") !== null && searchParams.get("tab") !== undefined && searchParams.get("tab") !== "") {
            setTabActive(searchParams.get("tab"));
        }
    }, [Location, searchParams])


    // Function to handle tab change
    const handleTabChange = (index) => {
        setTabActive(index)
        navigate(`?tab=${index}`, { replace: true })
    }


    // ==================Product Details========================


    // Product details states
    const [specificationArray, setSpecificationArray] = useState(""); // State for product specifications
    const [productDetails, setProductDetails] = useState({}); // State for product details
    const [documentsArray, setDocumentsArray] = useState([]); // State for documents array
    const [tableRows, setTableRows] = useState([]); // State for table rows
    const [columns, setColumns] = useState([]); // State for table columns
    const [duplicateRow, setDuplicateRow] = useState([]); // State for duplicate rows

    // Function to handle attribute validation
    const handleAttributeValidation = (type) => {
        const hasNullName = tableRows.some(rr => rr.name === null);
        if (hasNullName) {
            alert("please check Attribute Name No Name can be empty");
            return false;
        }
        else {
            const nameOccurrences = {};
            const duplicateNamesId = [];
            tableRows.forEach((item) => {
                const name = item.name;
                // Check if the name has been encountered before
                if (nameOccurrences[name]) {
                    // If it has, mark it as a duplicate
                    duplicateNamesId.push(item.id);
                } else {
                    // Otherwise, record it in the occurrences object
                    nameOccurrences[name] = true;
                }
            });
            if (duplicateNamesId.length > 0) {
                // console.log(duplicateError)
                setDuplicateRow(duplicateNamesId)
                if (type === "check") {

                    alert("Please Remove Duplicate Attributes");
                    return false;
                }
                else {
                    return duplicateNamesId
                }
            }
            else {
                setDuplicateRow([]);
                return true;
            }
        }
    }

    // Function to delete all duplicate rows
    const deleteAllDuplicateRows = () => {
        const ids = handleAttributeValidation();
        if (ids.length > 0) {
            const newRows = tableRows.filter((item) => !ids.includes(item.id));
            setTableRows(newRows);
        }
    }

    // Function to check count
    const CheckCount = (item) => {
        let count = {
            satisfied: 0,
            unstatisfied: 0,
            neutral: 0,
        };

        // Handle neutral cases
        if (!item || !item.revisedValue) {
            count.unstatisfied = 1
            return count
        }
        if (!item || !item.valueType) {
            count.neutral = 1;
            return count;
        }

        switch (item.valueType) {
            case 'NUMBER':
                const revisedValue = parseFloat(item.revisedValue);
                const value = parseFloat(item.value);
                switch (item.ruleAttribute) {
                    case 'LESS':
                        if (revisedValue <= value) count.satisfied = 1;
                        else count.unstatisfied = 1;
                        break;
                    case 'MORE':
                        if (revisedValue >= value) count.satisfied = 1;
                        else count.unstatisfied = 1;
                        break;
                    case 'EQUAL':
                        if (revisedValue === value) count.satisfied = 1;
                        else count.unstatisfied = 1;
                        break;
                    default:
                        count.neutral = 1;
                }
                break;
            case 'RANGE':
                const range = item.value?.split("-").map(item => parseFloat(item.trim()));
                if (range && range.length === 2) {
                    const revisedValue = parseFloat(item.revisedValue);
                    if (revisedValue >= range[0] && revisedValue <= range[1]) count.satisfied = 1;
                    else count.unstatisfied = 1;
                } else {
                    count.neutral = 1;
                }
                break;
            case 'BOOLEAN':
                if (item.revisedValue?.trim() === item.value?.trim()) count.satisfied = 1;
                else count.unstatisfied = 1;
                break;
            case 'SINGLESELECT':

                if (item.revisedValue?.trim() === item.expectedAns?.trim()) count.satisfied = 1;
                else count.unstatisfied = 1;
                break;
            case 'TEXT':

                if (item.revisedValue?.trim() === item.value?.trim()) count.satisfied = 1;
                else count.unstatisfied = 1;
                break;
            case 'DATE':
                const revisedDate = new Date(item.revisedValue);
                const originalDate = new Date(item.value);
                switch (item.ruleAttribute) {
                    case 'LESS':
                        if (revisedDate < originalDate) count.satisfied = 1;
                        else count.unstatisfied = 1;
                        break;
                    case 'MORE':
                        if (revisedDate > originalDate) count.satisfied = 1;
                        else count.unstatisfied = 1;
                        break;
                    case 'EQUAL':
                        if (revisedDate.getTime() === originalDate.getTime()) count.satisfied = 1;
                        else count.unstatisfied = 1;
                        break;
                    default:
                        count.neutral = 1;
                }
                break;
            default:
                count.neutral = 1;
        }
        return count;
    };


    // State for vendor rankings
    const [vendorRankings, setVendorRankings] = useState([]);// State for vendor rankings

    // Function to build rows
    const buildRows = async (data) => {
        openBackdrop();

        try {
            const attributeArray = [];
            data.slice(1).forEach((res, index) => {
                const attributes = res.productAttributes.map((dd) => {
                    let addFields = dd.additionalFields;
                    if (typeof addFields === 'string') {
                        try {
                            addFields = JSON.parse(addFields);
                        } catch (error) {
                            addFields = [];
                        }
                    }
                    return {
                        ...dd,
                        additionalFields: addFields,
                    };
                });

                if (!attributeArray[res.sentTo]) {
                    attributeArray[res.sentTo] = attributes;
                } else {
                    attributeArray[`${res.sentTo.trim()}_${index}`] = attributes;
                }
            });




            const attributeValues = Object.values(attributeArray);
            const groupedData = {};
            const vendorRanking = {}
            for (const [vendorName, dataArray] of Object.entries(attributeArray)) {
                let count = {
                    satisfied: 0,
                    unstatisfied: 0,
                    neutral: 0,
                }

                dataArray.forEach((item) => {
                    const tempCount = CheckCount(item);
                    count.satisfied = count.satisfied + tempCount.satisfied;
                    count.unstatisfied = count.unstatisfied + tempCount.unstatisfied;
                    count.neutral = count.neutral + tempCount.neutral;

                    const updatedItem = {
                        ...item,
                        vName: vendorName,
                    };

                    const itemName = item.name;

                    if (!groupedData[itemName]) {
                        groupedData[itemName] = [];
                    }
                    groupedData[itemName].push(updatedItem);


                });
                vendorRanking[vendorName] = count;
            }
            setVendorRankings(vendorRanking);


            const addedData = Object.values(groupedData).map((data) => [
                ...data,
                ...(data.length < attributeValues.length
                    ? Array.from({ length: attributeValues.length - data.length }, (_, i) => ({
                        id: data[0].id + i,
                        name: data[0].name,
                        type: data[0].type,
                        unit: data[0].unit,
                        value: "N/A",
                        revisedValue: "N/A",
                        remark: "N/A",
                        vendorRemark: "N/A",
                        additionalFields: [],
                        valueType: data[0].valueType,
                        ruleAttribute: data[0].ruleAttribute,
                        expectedAns: data[0].expectedAns,
                        createdBy: "N/A",
                        vName: "N/A",
                    }))
                    : []),
            ]);

            const resultArray = addedData.map((smt, index) =>
                smt.reduce(
                    (obb, obj) => ({
                        ...obb,
                        revisedValue: [...obb.revisedValue, { value: obj.revisedValue, sentBy: obj.vName }],
                        vendorRemark: [...obb.vendorRemark, { value: obj.vendorRemark, sentBy: obj.vName }],
                        additionalFields: [...obb.additionalFields, ...obj.additionalFields],
                    }),
                    {
                        id: index + 1,
                        name: smt[0].name,
                        type: smt[0].type,
                        unit: smt[0].unit,
                        value: smt[0].value,
                        remark: smt[0].remark,
                        valueType: smt[0].valueType,
                        ruleAttribute: smt[0].ruleAttribute,
                        expectedAns: smt[0].expectedAns,
                        createdBy: "N/A",
                        revisedValue: [],
                        vendorRemark: [],
                        additionalFields: [],
                    }
                )
            );

            const attributes = resultArray || [];
            buildColumn(attributes[0]);
            setTableRows(attributes);
        } catch (error) {
            console.error("Error Sorting Data:", error);
            showToast("error", "Error Sorting Data");
        } finally {
            closeBackdrop();
        }
    };

    // Function to build documents and specifications
    const buildDocAndSpecification = (data) => {
        const doc = {};
        const specifications = {};

        data.slice(1).forEach((res, index) => {
            const key = res.sentTo === null ? `No Vendor Name_${index}` : `${res.sentTo.trim()}`;

            if (!doc[key]) {
                doc[key] = res.productDocuments;
                specifications[key] = res.productSpecification;
            }
        });

        const documents = {}

        Object.values(doc).forEach((item, index) => {
            let key = Object.keys(doc)[index];
            let confidential = [];
            let normal = [];
            item.forEach(dd => {
                if (doc.docType === "CONFIDENTIAL") {
                    confidential.push(dd);
                }
                else {
                    normal.push(dd)
                }
            })
            documents[key] = {
                normal,
                confidential
            }
        })

        setDocumentsArray(documents);
        setSpecificationArray(specifications);
    };

    // Function to build columns
    const buildColumn = (data) => {
        let vendorValueColumns = data.revisedValue.map((vv) => {
            return `${vv.sentBy} Value`
        })
        let vendorRemarkColumns = data.vendorRemark.map((vv) => {
            return `${vv.sentBy} Remark`
        })
        let additionalColumnNames = data.additionalFields.map((col) => {
            return col.columnName

        });

        const newColumns = { vendorValueColumns, vendorRemarkColumns, additionalColumnNames }
        setColumns(newColumns);
    }


    const getData = async () => {
        openBackdrop();
        try {
            // TASK ID CHECK IS REMAINING BUT WE THOUGHT ABOUT IT.
            const response = await privateAxios.get(`/technicalSpecification/getCompareRevisionById/${revisionId}`);
            let data = response.data;
            setProductDetails(data[0])
            buildRows(data);
            buildDocAndSpecification(data);


        } catch (error) {

            showToast("error", "Error Fetching Data");
        }

        closeBackdrop();
    }
    useEffect(() => {
        getData();
    }, [])




    // ==================Chat Slider========================


    // Chat states
    const [chatSlider, setChatSlider] = useState(false); // State for chat slider
    const [chatValue, setChatValue] = useState(""); // State for chat input value
    const [revisionChats, setRevisionChats] = useState([]); // State for revision chats

    // Function to toggle chat slider
    const handleChatSlider = () => {
        setChatSlider(prev => !prev);
    };

    // Effect to fetch revision chats when chat slider is true
    useEffect(() => {

        if (chatSlider === true) {

            getRevisionChats()
        }

    }, [chatSlider])

    // Function to handle sending chat message
    const handleChatSend = async (e) => {
        e.preventDefault()
        try {
            if (chatValue.length > 0) {

                await privateAxios.post(`/technicalSpecification/addRemarkOnRevisionByEmployee/${auth.userData.id}/${revisionId}`, { remark: chatValue }).then(res => {
                    getRevisionChats();
                    setChatValue("")

                })
            }
        } catch (error) {
            // console.log(error)
        }

    }


    // Function to fetch revision chats
    const getRevisionChats = async () => {
        try {
            await privateAxios.get(`/technicalSpecification/getRemarksOfRevision/${revisionId}`).then(res => {
                setRevisionChats(res.data)
                handleChatNotification();
            })
        } catch (error) {
        }
    }

    // State for re-rendering to handle chat notification
    const [reRender, setReRender] = useState(false);

    // Function to handle chat notification
    const handleChatNotification = () => {
        setReRender(prev => !prev);

    }

    // ======================Document Data=====================


    // Document modal state
    const [addDocumentModal, setAddDocumentModal] = useState(false);// State for add document modal

    // Function to toggle add document modal
    const handleAddDocumentModal = () => {
        setAddDocumentModal(prev => !prev);
    }


    // ======================Rename Revision====================

    // Function to handle changing revision name
    const handleChangeRevisionName = async (data) => {
        openBackdrop();
        try {

            await privateAxios.post(`/technicalSpecification/renameGroupOrComparison/${productDetails.id}?name=${data.name}`)

            await getData();
            showToast("success", "Revision Renamed")
        } catch (error) {
            console.log(error)
            showToast("error", "Error while Rename Revision")

        }
        handleRenameModal();
        closeBackdrop();
    }


    // ======================Duplicate Revision====================

    // Function to handle duplicating revision
    const handleDuplicateRevisison = async (data) => {
        openBackdrop();
        try {
            await privateAxios.post(`/technicalSpecification/duplicateRevision/${productDetails.id}?name=${data.name}`).then((res) => {
                navigate(-1)
            })
        } catch (error) {
            showToast("error", "Error Duplicating Revision")
        }
        closeBackdrop();
    }



    // =====================Delete Revision===============================

    // Function to handle deleting revision
    const handleRevisionDelete = async () => {
        openBackdrop();
        try {
            if (productDetails.id !== null) {
                await privateAxios.delete(`/technicalSpecification/deleteRevision/${productDetails.id}`).then((res) => {
                    navigate(-1, { replace: true })
                })
            }
        } catch (error) {
            showToast("error", "Error Deleting Revision!")
        }
        closeBackdrop()
    }



    // ====================Send Revision for Approval from user to Kam============

    // Function to handle submitting revision for approval
    const handleAppovalSubmit = async (data) => {

        openBackdrop();
        let URL = ``;
        if (viewType === "APVIEW" && auth.roles === "ADMIN") {
            URL = `/technicalSpecification/sendRevisionForApprovalToKAMFromSUBKAM/${auth.userData.id}/${revisionId}`;
        }
        else {
            URL = `/technicalSpecification/sendRevisionForApprovalToKAM/${auth.userData.id}/${revisionId}`
        }

        try {
            await privateAxios.post(`${URL}`, { comments: data.comment })

            handleApprovalModal();
            showToast("success", "Approval Sent To KAM")


        } catch (error) {
            // console.log(error);
            showToast("error", "Error Sending Data")
        }
        closeBackdrop();
    }


    // ====================Send Revision to vendor ===========================

    // Function to handle sending revision to vendor
    const handleSendToVendor = async (data) => {


        openBackdrop()
        try {
            const res = await privateAxios.post(`/technicalSpecification/sendRevisionForApprovalToVendor/${auth.userData.id
                }/${revisionId}`, {
                emails: data.emails,
                comments: data.comments,
                dueDate: data.dueDate,
            })
            showToast("success", "Revision Sent to Vendor")
            handleSendToVendorModal()
            closeBackdrop();
        } catch (error) {
            // console.log(error);
            showToast("error", "Error while sending Revision to Vendor")
            closeBackdrop();
        }


    }


    //========================= Decision on Revision=========================

    // Function to handle decision on revision
    const handleDecisionOnRevision = async (type) => {
        openBackdrop();
        try {
            let URL = ``
            if (type) {
                URL = `/technicalSpecification/approveRevisionByKam/${revisionId}`

            }
            else {
                URL = `/technicalSpecification/rejectRevisionByKam/${revisionId}`
            }
            const response = await privateAxios.post(`${URL}`);

            if (response.status === 200) {
                if (type) {
                    showToast("success", "Revision Approved")

                }
                else {
                    showToast("success", "Revision Rejected")
                }

            }

            getData();
            closeBackdrop();
        } catch (error) {
            showToast("error", "Error in the Process")
            closeBackdrop();
        }
    }



    // =========================Jump to Row==============================

    // State for jump search
    const [jumpSearch, setJumpSearch] = useState("");

    // Function to handle selecting row
    const handleSelect = (value) => {
        const selectedValue = value;
        // console.log(selectedValue);
        const selectedRow = tableRows.find((row) => row.name === selectedValue);
        // console.log(selectedRow);
        if (selectedRow) {
            const selectedRowElement = document.getElementById(`row-${selectedRow.id}`);
            // console.log(selectedRowElement)
            if (selectedRowElement) {
                selectedRowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    };





    // ========================== add More Revision Modal States=================


    // Add more revision modal states
    const [addProductRevisionModal, setAddProductRevisionModal] = useState(false); // State for add product revision modal
    const [addRevisionData, setAddRevisionData] = useState([]); // State for add revision data
    const [selectedVendorRevisions, setSelectedVendorRevisions] = useState([]); // State for selected vendor revisions

    // Function to toggle add product revision modal
    const handleAddProductRevisionModal = () => {
        setAddProductRevisionModal(prev => !prev)

    }

    // Effect to fetch revisions when add product revision modal is true
    useEffect(() => {
        if (addProductRevisionModal === true) {
            fetchRevision();
        }
    }, [addProductRevisionModal])

    // Function to fetch revisions
    const fetchRevision = async () => {
        try {
            openBackdrop();
            if (productDetails.productName !== null) {

                await privateAxios.post(`/technicalSpecification/getRevisionsToAddInComparision/${auth.userData.id}`, {
                    productName: productDetails.productName
                })
                    .then((res) => {

                        setAddRevisionData(res.data);
                        const alreadyRevId = productDetails.compare.map((tt => tt.revId));
                        const selectedRevision = res.data.filter(rev => {

                            return alreadyRevId.includes(rev.id);



                        })
                        setSelectedVendorRevisions(selectedRevision)

                    })
                    .catch((error) => {
                        console.error('Error handling response:', error);
                        alert("Error Geeting data")
                    });
            }
            else {
                alert("Product Name is Not Available")
            }
            closeBackdrop()
        } catch (error) {
            closeBackdrop();
        }
    }

    // Function to check if arrays have same values
    const haveSameValues = (array1, array2) => {
        if (array1.length !== array2.length) {
            return false; // Arrays have different lengths, so they can't have the same values.
        }

        return array1.every(item => array2.includes(item));
    };

    // Function to handle sending selected revisions for comparison
    const handleAddProductSend = async () => {

        try {
            if (selectedVendorRevisions.length > 1) {
                const alreadyRevId = productDetails.compare.map((tt => tt.revId));
                const selectedRevId = selectedVendorRevisions.map(rev => rev.id);

                const ret = haveSameValues(alreadyRevId, selectedRevId);
                if (!ret) {
                    await privateAxios.post(`/technicalSpecification/addRevisionInComparision/${revisionId}`, {
                        ids: selectedRevId
                    }).then((res) => {
                        getData();
                        handleAddProductRevisionModal();
                    })
                }
                else {
                    handleAddProductRevisionModal();
                }


            }
            else {
                alert("please Select Atleast 2 revisions")
            }

        } catch (error) {
            // console.log(error)
        }
    }


    // ========================== add More Revision Modal States=================


    const [rankingModal, setRankingModal] = useState(false);

    // Function to toggle ranking modal
    const handleRankingModal = () => {
        setRankingModal(prev => !prev);

    }



    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>



                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft} style={{ maxWidth: "none" }}>
                        <h1>
                            <BackButton />
                            {productDetails.name || "Not Name"}</h1>
                        <p>#0{productDetails.id} {productDetails?.productName || "Dafault"}</p>

                    </div>

                    <div className={workSpaceStyle.workSpaceHeaderRight}>

                        <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative", width: "auto" }}>
                            <button onClick={() => { handleRankingModal() }} style={{ padding: "0 10px" }}><BarChartIcon />Ranking</button>



                        </div>
                        <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative" }}>
                            <button onClick={handleAddProductRevisionModal}><AddIcon />Add</button>


                        </div>
                        {/* {
                                    (viewType === "APVIEW" && auth.roles === "KAM") &&

                                    <div className={workSpaceStyle.workSpaceHeaderDecisionButton}>
                                        {
                                            productDetails.revisionStatus === "PENDING"
                                                ?
                                                <>
                                                    <button className={workSpaceStyle.workSpaceHeaderDecisionApprovalBtn} onClick={() => { handleDecisionOnRevision(true) }}><span><CheckIcon /></span> Approve</button>
                                                    <button className={workSpaceStyle.workSpaceHeaderDecisionRejectBtn} onClick={() => { handleDecisionOnRevision(false) }}><span><CloseIcon /></span>Reject</button>
                                                </>
                                                :
                                                productDetails.revisionStatus === "APPROVED" ?
                                                    <button className={workSpaceStyle.workSpaceHeaderDecisionApprovalBtn}><span><CheckIcon /> </span>Approved</button>

                                                    :
                                                    <button className={workSpaceStyle.workSpaceHeaderDecisionRejectBtn} ><span><CloseIcon /></span>Rejected</button>


                                        }
                                    </div>
                                } */}






                        <div className={workSpaceStyle.workSpaceThreeDotVertical} style={{ position: "relative" }}>

                            <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={() => setworkSpaceHeaderRevisionMore(prev => !prev)}>
                                <MoreVertIcon />
                            </span>
                            {
                                workSpaceHeaderRevisionMore &&
                                <ThreeDotDropDown toggle={() => setworkSpaceHeaderRevisionMore(prev => !prev)} >



                                    <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleApprovalModal(); }}>
                                        <span><PersonAddAltOutlinedIcon /></span>Send for Approval</li>
                                    <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleRenameModal(); }}>
                                        <span><PersonAddAltOutlinedIcon /></span>Rename </li>





                                    <li onClick={() => {
                                        setworkSpaceHeaderRevisionMore(prev => !prev);
                                        //  handleDownloadRevision();
                                    }}
                                    ><span>
                                            <FileDownloadOutlinedIcon />
                                        </span>Download</li>
                                </ThreeDotDropDown>
                            }

                        </div>
                        <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
                            <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
                        </div>
                    </div>

                </div>

                <div className={styles.tabHeaderMain}>
                    <div className={styles.tabHeaderWapper} >


                        <div
                            onClick={() => handleTabChange("0")}
                            className={tabActive === "0" ? `${styles.revisionTab} ${styles.revisionTabActive} ` : `${styles.revisionTab}`}>Specification</div>
                        <div
                            onClick={() => handleTabChange("1")}
                            className={tabActive === "1" ? `${styles.revisionTab} ${styles.revisionTabActive} ` : `${styles.revisionTab}`}>Attributes</div>
                        <div
                            onClick={() => handleTabChange("2")}
                            className={tabActive === "2" ? `${styles.revisionTab} ${styles.revisionTabActive} ` : `${styles.revisionTab}`}>Documents</div>
                    </div>
                    {
                        tabActive === "1" &&
                        <div className={styles.searchContainer}>
                            <div className={styles.searchBox}>
                                <input type="text" placeholder="Search" value={jumpSearch} onChange={(e) => setJumpSearch(e.target.value)} />
                            </div>

                            {
                                jumpSearch.length > 0 &&

                                <>

                                    <div className={styles.jumpListDropDown}>

                                        <ul className={styles.jumpToRowList}>
                                            {tableRows.filter((row) => row.name.trim().toLowerCase().includes(jumpSearch.toLowerCase())).map((row) => {
                                                return (
                                                    <li onClick={() => handleSelect(row.name)}>{row.name}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>

                                </>
                            }

                        </div>
                    }
                </div>

                <div className={styles.tabBodyMain} >

                    {tabActive === "0" && <SpecificationTab

                        data={specificationArray}

                    />}
                    {tabActive === "1" && <AttributeTab
                        tableRows={tableRows}
                        setTableRows={setTableRows}
                        showToast={showToast}
                        columns={columns}
                        setColumns={setColumns}
                        duplicateRow={duplicateRow}
                        viewType={""} />}
                    {tabActive === "2" && <DocumentsTab
                        data={documentsArray}
                        showToast={showToast} />}

                </div>




            </div >


            <CompareRankingModal
                open={rankingModal}
                onClose={handleRankingModal}
                data={vendorRankings}
                total={tableRows.length}
                type={"attributes"}
            />



            <RenameModal
                open={renameModal}
                onClose={handleRenameModal}
                onSubmit={handleChangeRevisionName}
                prefilled={productDetails?.name}
                header="Rename"
            />

            <RenameModal
                open={duplicateNameModal}
                onClose={handleDuplicateNameModal}
                onSubmit={handleDuplicateRevisison}
                prefilled={productDetails?.name}
                header="Duplicate"
            />




            <SendToApprovalCommityModal
                open={approvalModal}
                onClose={handleApprovalModal}
                showToast={showToast}
                revisionId={revisionId}

                header={"Send for Approval"}
            />



            <ChatPannel
                open={chatSlider}
                onClose={handleChatSlider}
            >
                <>
                    <ChatHeader
                        onClose={handleChatSlider}
                        downloadData={revisionChats.map((chat) => {
                            return { name: chat.name, date: chat.createdAt, chat: chat.remark };
                        })}
                        helpData={{ link: "tosomewhere" }}
                        chatName={productDetails?.name}
                    >
                        <h3>{productDetails?.name}</h3>
                    </ChatHeader>

                    <ChatRows>

                        {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
                            return (

                                <ChatRow key={index} name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} email={rev.email} />
                            )
                        })
                            :
                            <p style={{ textAlign: "center" }}>
                                No Previous Chat
                            </p>
                        }

                    </ChatRows>

                    <ChatInputField onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

                </>
            </ChatPannel>

            <Modal
                open={addProductRevisionModal}
                onClose={handleAddProductRevisionModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxWidth: "80vw", width: "100%", maxHeight: "90vh" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "10px", height: "100%" }}>
                        <div className={workSpaceStyle.modalHeader} >

                            <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><PersonAddAltOutlinedIcon /></span>Add More Files For Comparision</h3>
                            <div onClick={handleAddProductRevisionModal}>
                                <Tooltip title='Close'><CloseIcon /></Tooltip>
                            </div>
                        </div>


                        <ApprovalRevisionTable addData={addRevisionData} selectedRevisions={selectedVendorRevisions} setSelectedRevisions={setSelectedVendorRevisions} />
                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                            <button style={{ height: "40px", maxWidth: "100px" }} className={formStyle.modalSmallPrimaryButton} onClick={handleAddProductSend}>Apply </button>

                        </div>

                    </div>

                </div>
            </Modal>



        </>
    )
}

export default TechnicalComparisionRevisionView
