import React from 'react'
import chatPannelStyle from './ChatPannel.module.css'
const ChatPannel = ({children,open,onClose}) => {
  return (
    <>
   
    <div className={open?`${chatPannelStyle.chatPannelBackDrop}`:`${chatPannelStyle.chatPannelBackDropHide}`} onClick={onClose}>
    </div>
    <div className={open?`${chatPannelStyle.chatPannelMain} ${chatPannelStyle.chatPannelMainOpen}`:`${chatPannelStyle.chatPannelMain}`}>
      {children}
    </div>
    </>
  )
}

export default ChatPannel
