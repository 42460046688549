import React, { useState, useEffect, useRef } from 'react';
import vendorStyle from '../VendorView/Vendors.module.css';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import modalStyle from 'components/ModalCommonCSS/ModalCommonCSS.module.css'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import userStyle from '../Users/Users.module.css';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@mui/material';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import useBackdrop from 'hooks/useBackdrop';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import Alert from '@mui/material/Alert';
import useToast from 'hooks/useToast';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';


const Vendors = () => {

      // Custom hooks and state initialization
      const { auth } = useAuth(); // Get authentication details
      const privateAxios = PrivateComponent(); // Axios instance for authenticated requests
      const [searchQuery, setSearchQuery] = useState(''); // State for search query
      const [addVendorModal, setAddVendorModal] = useState(false); // State for add vendor modal
      const [disabledVendors, setDisabledVendors] = useState([]); // State for disabled vendors
      const [showSuccessAlert, setShowSuccessAlert] = useState(false); // State for showing success alert
      const { showToast, Toast } = useToast(); // Custom hook for toast notifications
      const { BackdropComponent, openBackdrop, closeBackdrop } = useBackdrop(); // Custom hook for backdrop component

    // -------------------------Sorting by Name----------------------------------

    // State for sorting
    const [sortOrder, setSortOrder] = useState('asc'); // State for sort order
    const [sortColumn, setSortColumn] = useState(''); // State for sort column

     // Handle sorting by column
    const handleSort = (column) => {// If the column is already sorted
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');// Toggle sort order
        } else {
            setSortColumn(column); // Set new sort column
            setSortOrder('asc'); // Set sort order to ascending
        }
    };

   // Reference for email input
   const emailRef = useRef(); // Create a reference for email input field

     // State for login details and form errors
    const [loginDetail, setLoginDetail] = useState({
        email: "",// Initialize email as empty string
    });

    const [formErrors, setFormErrors] = useState({
        email: "", // Initialize email error as empty string
        formErrors: "", // Initialize general form error as empty string
    });

       // Toggle add vendor modal
    const handleAddVendorModal = () => {
        openBackdrop();

        setAddVendorModal(prev => !prev); // Toggle add vendor modal state
        setFormErrors({
            email: "", // Reset email error
            fromError: "", // Reset form error
        });
        setLoginDetail({
            email: "", // Reset email input
        });
        closeBackdrop(); // Hide backdrop
    };


     // Focus on email input when component mounts
    useEffect(() => {
        emailRef.current?.focus(); // Focus on email input field
    }, []);

       // Handle input field change
    const handleInputField = (e) => {
        const { name, value } = e.target; // Destructure name and value from event target

        setLoginDetail((prev) => ({
            ...prev,
            [name]: value, // Update login detail state
        }));

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',// Clear any existing error for the field
        }));
    };

     // Validate form input
    const handleValidation = (e) => {
        const { name, value } = e.target; // Destructure name and value from event target

        setLoginDetail((prev) => ({
            ...prev,
            [name]: value,// Update login detail state
        }));

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',// Clear any existing error for the field
        }));

        if (name === 'email') {// If validating email field
            if (value.trim() === '') {// Check if value is empty
                setFormErrors((prev) => ({
                    ...prev,
                    email: 'Add at least one email address.',// Set error for empty email
                }));
            } else {
                const emailArr = value.split(",").map((email) => email.trim().toLowerCase()); // Split and trim email addresses
                const uniqueEmails = Array.from(new Set(emailArr)); // Get unique email addresses
                if (uniqueEmails.length < emailArr.length) {// Check for repeated emails
                    setFormErrors((prev) => ({
                        ...prev,
                        email: 'Repeated emails found.',// Set error for repeated emails
                    }));
                } else {
                    const invalidEmails = uniqueEmails.filter((email) => {
                        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;// Email regex for validation
                        return !emailRegex.test(email.toLowerCase()); // Filter invalid emails
                    });

                    if (invalidEmails.length > 0) {// Check for invalid emails
                        setFormErrors((prev) => ({
                            ...prev,
                            email: `Invalid email(s): ${invalidEmails.join(', ')}`,// Set error for invalid emails
                        }));
                    } else {
                        setFormErrors((prev) => ({
                            ...prev,
                            email: '',// Clear email error
                        }));
                    }
                }
            }
        }
    };

     // Validate a single email address
    const validateEmail = (email) => {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/; // Email regex for validation
        return emailRegex.test(email); // Return true if email is valid, false otherwise
    };

    // Handle vendor registration
    const handleVendorRegister = async (e) => {
        e.preventDefault(); // Prevent default form submission
        openBackdrop(); // Show backdrop

        const emailArr = loginDetail.email.split(",").map((email) => email.trim().toLowerCase()); // Split and trim email addresses
        const emptyEmail = emailArr.some((email) => email.length === 0); // Check for empty emails
        const invalidEmail = emailArr.some((email) => !validateEmail(email)); // Check for invalid emails

        if (emptyEmail) {// If any email is empty
            setFormErrors((prev) => ({
                ...prev,
                email: 'Provide at least one email address.',// Set error for empty email
            }));
        } else if (invalidEmail) { // If any email is invalid
             // No need to set form errors again as it's already handled in handleValidation
            // setFormErrors((prev) => ({
            //     ...prev,
            //     email: 'Invalid email address format.',
            // }));
        } else {
            const uniqueEmails = Array.from(new Set(emailArr));// Get unique email addresses
            if (uniqueEmails.length < emailArr.length) {// Check for repeated emails
                setFormErrors((prev) => ({
                    ...prev,
                    email: 'Repeated emails found.', // Set error for repeated emails
                }));
            } else {
                await privateAxios.post(`/vendor/inviteVendor/${auth.userData.id}/${auth.orgId}`, {
                    emails: emailArr,// Send unique email addresses to the backend
                })
                    .then((res) => {
                        if (res.status === 200) {
                            const data = res.data;
                            setShowSuccessAlert(true); // Show success alert on successful response
                            handleAddVendorModal(); // Close add vendor modal
                            fetchVendors(); // Fetch updated vendor list
                        }
                        closeBackdrop();// Hide backdrop
                    })
                    .catch((err) => {
                        setFormErrors((prev) => ({
                            ...prev,
                            email: err?.response?.data?.message,// Set backend error message
                        }));
                    });
            }
        }
        closeBackdrop();// Hide backdrop
    };

    // State for vendor data
    const [vendorAllData, setVendorAllData] = useState([]);

    // Fetch vendors from backend
    const fetchVendors = async () => {
        await privateAxios.get(`/procurement/getAllUsers/${auth.orgId}`)  // Fetch vendors from backend

            .then(res => {
                if (res.status === 200) {
                    const data = res.data.data.vendors.filter(ttr => !ttr.disable);// Filter out disabled vendors
                    setVendorAllData(data);// Set vendor data
                }
            })
            .catch(error => {
                console.error(error);// Log any errors
            });
    };

     // Fetch vendors on component mount
    useEffect(() => {
        fetchVendors();// Fetch vendors on mount
    }, []);

    // Resend invite to vendor
    const handleSendInvite = async (vendorId) => {
        openBackdrop();// Show backdrop
        await privateAxios.post(`/vendor/resendInvite/${auth.orgId}/${vendorId}`)
            .then((res) => {
                if (res.status === 200) {
                    setShowSuccessAlert(true);// Show success alert on successful response
                }
                closeBackdrop();// Hide backdrop
            })
            .catch((err) => {
                 // Handle error if needed
            });
        closeBackdrop(); // Hide backdrop
    };

      // Delete (disable) vendor
    const handleDeleteVendor = async (vendorId) => {
        openBackdrop(); // Show backdrop
        try {
            await privateAxios.delete(`/vendor/disableVendor/${vendorId}/${auth.orgId}`)
                .then((res) => {
                    setVendorAllData(prevVendorData => prevVendorData.filter(vendorData => vendorData.id !== vendorId));// Remove vendor from list

                    setDisabledVendors(prevDisabledVendors => [
                        ...prevDisabledVendors,
                        vendorId// Add vendor ID to disabled vendors list
                    ]);

                    showToast("success", "Vendor Removed Successfully");// Show success toast
                })

        } catch (error) {
            console.error('Error deleting vendor:', error);// Log error
            showToast("error", "Error Removing vendor"); // Show error toast
        }
        closeBackdrop(); // Hide backdrop
    };


    // Define sortedVendorData by sorting vendorAllData
    const sortedVendorData = [...vendorAllData].sort((a, b) => {
        if (sortColumn === 'name') {
            // Check if 'name' is not null or undefined before calling localeCompare
            const nameA = a.name || '';// Get vendor name or empty string if undefined
            const nameB = b.name || '';
            return sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        } else if (sortColumn === 'email') {
            // Check if 'email' is not null or undefined before calling localeCompare
            const emailA = a.email || ''; // Get vendor email or empty string if undefined
            const emailB = b.email || '';
            return sortOrder === 'asc' ? emailA.localeCompare(emailB) : emailB.localeCompare(emailA);
        }
        return 0;
    });



    // Calculate last seen time for vendor
    const handleLastSeen = (loginTime) => {
        const loginDate = new Date(loginTime); // Convert login time to Date object
        const currentDate = new Date(); // Get current date
        const timeDifference = currentDate - loginDate; // Calculate time difference
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60)); // Convert difference to hours
        const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60); // Convert remaining difference to minutes
        const daysDifference = Math.floor(hoursDifference / 24); // Convert hours to days

        let displayString;
        if (daysDifference > 0) {
            // If the difference is more than 1 day, display the date
            displayString = `${loginDate.toDateString()}`;
        } else if (hoursDifference > 0) {
            // If the difference is more than 1 hour, display the hours
            displayString = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else if (minutesDifference > 0) {
            // If the difference is more than 1 minute, display the minutes
            displayString = `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
        } else {
            // If the login was within the last minute, display "Just now"
            displayString = "Just now";
        }
        return displayString;
    }

    // Show success alert for 5 seconds
    useEffect(() => {
        let timeoutId;

        if (showSuccessAlert) {
            timeoutId = setTimeout(() => {
                setShowSuccessAlert(false); // Hide success alert after 5 seconds
            }, 5000); // 5000 milliseconds (5 seconds)
        }

        return () => {
            clearTimeout(timeoutId); // Clear the timeout if the component unmounts or the alert is hidden before 3 seconds
        };
    }, [showSuccessAlert]);



    // =====================delete modal=======================

    // State for delete modal and temporary data
    const [deleteModal, setDeleteModal] = useState(false); // State for delete modal
    const [tempData, setTempData] = useState({}); // State for temporary data

      // Toggle delete modal
    const handleDeleteTaskModal = (id) => {
        setDeleteModal(prev => !prev); // Toggle delete modal state
        setTempData(id); // Set temporary data to vendor ID
    }



    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>
                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <h1><Tooltip title="All Members"><PeopleOutlineIcon /></Tooltip>Vendors</h1>
                        <p>All Users <span>+{sortedVendorData?.length}</span></p>
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderRight}>
                        <div className={workSpaceStyle.workSpaceHeaderSearchbar}>
                            <input type="text" placeholder='Search Here...'
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)} />
                        </div>
                        <div className={workSpaceStyle.workSpaceHeaderAddButton}>
                            <Tooltip title='Add'>
                                <button onClick={handleAddVendorModal}>
                                    <AddIcon />
                                    Add</button></Tooltip>
                        </div>
                        <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
                            <Tooltip title="Help">
                            <a target='_blank' href='https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000080856-add-vendors'>
                            <HelpOutlineOutlinedIcon />
                            </a>
                           </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={userStyle.userTable}>
                    <ul className={`${userStyle.userTableHeader} ${userStyle.userTablerow}  ${vendorStyle.userTablerow2}`}>
                        <li onClick={() => handleSort('name')} style={{ display: "flex", justifyContent: "flex-start" }}>
                            {/* <span> */}
                            Name
                            {/* </span> */}
                        </li>
                        <li onClick={() => handleSort('email')}>Email</li>
                        <li>GST / VAT Details</li>
                        <li>Last Active</li>
                        <li>Action</li>
                    </ul>
                    {vendorAllData?.length > 0
                        ? <div>
                            {sortedVendorData
                                // .filter(vendorData => !vendorData.disable)
                                .filter(
                                    (vendorData) =>
                                        vendorData.name?.trim().toLowerCase().includes(searchQuery.toLowerCase()) ||
                                        vendorData.email?.trim().toLowerCase().includes(searchQuery.toLowerCase()) ||
                                        (vendorData.gstNumber &&
                                            vendorData?.gstNumber
                                                .toLowerCase()
                                                .includes(searchQuery.toLowerCase()))
                                )
                                .map((vendorData, index) => (
                                    <ul className={`${userStyle.userTableData} ${userStyle.userTablerow}  ${vendorStyle.userTablerow2}`} key={index}>
                                        <li className={userStyle.userTableName}
                                            style={{ gap: '10px' }}>
                                            <span >
                                                <ProfileIcon data={{ email: vendorData?.email }} height={"26px"} width={"26px"} showProfileBanner={vendorData.name ? true : false} />
                                                {/* {vendorData?.name ? vendorData?.name?.charAt(0) : "?"} */}
                                            </span>
                                            <p style={{ color: '#000', fontSize: '14px', fontWeight: '500' }}
                                            >  {vendorData?.name ? vendorData?.name : vendorData?.email?.split("@")[0]}</p></li>
                                        <li className={userStyle.userTableEmail}
                                        > <Tooltip title={vendorData?.email}>{vendorData?.email?.substr(0, 15) + '...'}</Tooltip></li>

                                        <li className={userStyle.userTableDesignation}
                                            style={{ color: '#202020', fontWeight: '500', fontSize: '14px' }}
                                        >
                                            {vendorData?.gstNumber ? (
                                                vendorData?.gstNumber
                                            ) : (
                                                <div style={{ color: '#202020', fontWeight: '500', fontSize: '12px' }}>GST Number Not Present</div>
                                            )}
                                        </li>

                                        <li className={userStyle.userTableActive}>

                                            {vendorData?.loggedIn && vendorData?.approved ? handleLastSeen(vendorData?.dateCreated) : <>Never logged in <div><ErrorOutlineIcon /></div> </>}
                                        </li>
                                        <li className={userStyle.userTableWorkspace}>
                                            <div style={{ color: '#737585', display: 'flex', gap: '10px' }}>
                                                <Tooltip title="Delete"
                                                    onClick={() => handleDeleteTaskModal(vendorData.id)}
                                                ><DeleteOutlineIcon /></Tooltip>
                                                {!vendorData.loggedIn && (
                                                    <Tooltip title="Send"
                                                        onClick={() => handleSendInvite(vendorData.id)}
                                                    ><NearMeOutlinedIcon />
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                ))}
                        </div>
                        : <div className={vendorStyle.noDataComponent}>
                            <div>
                                <PersonIcon sx={{ width: '80px', height: '80px', color: '#929292' }} />
                                <Typography variant='h6'>Add team members and organise them under workspace</Typography>
                                <button onClick={handleAddVendorModal}>
                                    <Tooltip title='Add'><AddIcon style={{ color: "white" }} /></Tooltip>
                                    Add</button>
                            </div>
                        </div>
                    }
                </div>


                {showSuccessAlert && (
                    <div style={{ display: 'flex', minWidth: "max-content", left: "50%", justifyContent: 'center', alignItems: 'center', position: "fixed", bottom: "100px" }}>
                        <Alert severity="success"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%'
                            }}>Invite sent successfully</Alert>
                    </div>
                )}

            </div>


            <Modal
                open={addVendorModal}
                onClose={handleAddVendorModal}
            >
                <div className={modalStyle.modalContainer}>
                    <form className={modalStyle.formContainer} onSubmit={handleVendorRegister}>
                        <div className={modalStyle.modalHeader} onClick={handleAddVendorModal}>
                            <h3>Invite members on board</h3>

                            <span>
                                <Tooltip title='Close'><CloseIcon /></Tooltip>
                            </span>
                        </div>
                        <div className={modalStyle.formGroup}>
                            <input
                                name='email'
                                type='text'
                                ref={emailRef}
                                autoComplete="off"
                                value={loginDetail.email}
                                onChange={handleInputField}
                                onBlur={handleValidation}
                                placeholder='Enter email separated by commas'
                            ></input>
                            <span>{formErrors.email}</span>
                        </div>


                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>

                            <button className={`${modalStyle.modalBtn} ${modalStyle.pimaryModalBtn}`} type='submit'>Invite </button>


                            {/* <p
                                onClick={() => {
                                    // setName("");
                                    onClose()
                                }}
                                className={`${cStyle.modalBtn} ${cStyle.secondaryModalBtn}`}
                            >Discard</p> */}
                        </div>
                      
                    </form>
                </div>
            </Modal>




            <DeleteConfirmation
                onCancel={handleDeleteTaskModal}
                onConfirm={() => { handleDeleteVendor(tempData); handleDeleteTaskModal() }}
                open={deleteModal}
            // confimBtnText={""} 
            // discardText={""}
            >
                <h2>Remove Vendor</h2>
                <p>Are you sure you want to Remove this Vendor?</p>

            </DeleteConfirmation>




        </>
    );
};

export default Vendors;
