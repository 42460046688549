import React from 'react';
import './App.css';
import useAuth from './hooks/useAuth';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import UnAuthorized from './components/UnAuthorized';
import Login from './pages/Login/Login';
import Layout from './pages/Layout/Layout';
import VendorLayout from './pages/Layout/VendorLayout';
import TestPage from './pages/TestPage/TestPage';
import SliderTwo from './components/SliderTwo/SliderTwo';
import UnderVerification from './pages/UnderVerificationPage/UnderVerification';
import EmailVerifyOTP from './pages/EmailVerifyOTPPage/EmailVerifyOTP';
import RequireAuth from './components/RequireAuth';
import Dashboard from './pages/Dashboard/Dashboard'
import HomePage from './pages/HomePage/HomePage';
import Users from './pages/Users/Users';
import TechnicalSpecification from './pages/TechnicalSpecification/TechnicalSpecification';
import Profile from './pages/ProfilePage/Profile';
import UserProfile from './pages/ProfilePage/UserProfile';
import VendorRegister from './pages/VendorRegister/VendorRegister';
import UserRegister from './pages/UserRegister/UserRegister';
import EmailVerify from './pages/EmailVerify/EmailVerify';
import Vendors from './pages/Vendors/Vendors';
import Logout from './pages/Logout/Logout';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/ResetPassword/ResetPassword';
import RequireRole from './components/RequireRole';
import RequireVendorAuth from './components/RequireVendorAuth';
import RequireWorkSpace from './components/RequireWorkSpace';
import { Outlet } from 'react-router-dom'
import ProductRevisions from './pages/ProductRevisions/ProductRevisions';
import VendorDashboard from './pages/VendorView/VendorDashboard';
import OrganisationsList from './pages/VendorView/OrganisationsList';
import VendorProfile from './pages/VendorView/VendorProfile';
import VendorCompanyProfile from './pages/VendorView/VendorCompanyProfile';
import CompanyProfilePage from './pages/CompanyProfilePage/CompanyProfilePage';
import SessionExpire from './pages/SessionExpire/SessionExpire';
import Approvals from './pages/Approvals/Approvals';
import VendorRevisonList from './pages/Vendors/VendorRevisonList';
import VendorRevision from './pages/Vendors/VendorRevision/VendorRevision';
import ProcureRegisterNew from './pages/ProcureRegister/ProcureRegisterNew';
import RedirectRegistrationNew from './pages/RedirectRegistration/RediectRegistrationNew';
import ProductSelect from './pages/RedirectRegistration/ProductSelect/ProductSelect';
import ProductShow from './pages/RedirectRegistration/ProductShow/ProductShow';
import RevisionGroup from './pages/RevisionGroup/RevisionGroup';
import ProductSelectNew from './pages/RedirectRegistration/ProductSelectNew';
import SuperAdminDashboard from 'pages/SuperAdmin/Dashboard/SuperAdminDashboard';
import SuperAdminLayout from './pages/Layout/SuperAdminLayout';

//Admin
import QuotesSent from './pages/QuotesSent/QuotesSent';
import RevisionViewQuotesSent from './pages/QuotesSent/RevisionViewQuotesSent';
import RedirectLogin from './pages/RedirectLogin/RedirectLogin';
import AdminTask from './pages/Admin/AdminTask';
import SubscriptionExpire from './pages/Subscription/SubscriptionExpire';
import RedirectVendorLogin from './pages/RedirectLogin/RedirectVendorLogin';

import DemoPage from './pages/DemoPage/DemoPage';
import FeaturePage from './pages/FeaturePage/FeaturePage';
import HomeLayout from './pages/HomeLayout/HomeLayout';

import CommercialTempleteRevisionView from './pages/CommercialTempleteRevisionView/CommercialTempleteRevisionView';
import CommercialTempleteViewVendor from './pages/CommercialTempleteViewVendor/CommercialTempleteViewVendor';
import CommercialTempleteViewKam from './pages/CommercialTempleteView/CommercialTempleteViewKam';
import CommercialTempleteSpecificView from './pages/CommercialTempleteSpecificView/CommercialTempleteSpecificView';

//Commercial Part:
import CreateTaskForCommercial from './pages/CommercialViewKAM/KamCreateTask/ViewCommercial';
import CommercialTempleteRevisions from './pages/CommercialTempleteRevisions/CommercialTempleteRevisions';
import UnderConstruction from './pages/TestPage/UnderConstruction';
import CommercialRevisionCompare from './pages/CommercialRevisionCompare/CommercialRevisionCompare'
import WorkSpaceApproval from './pages/WorkSpaceApproval/WorkSpaceApproval';
import ApprovalGroupView from './pages/ApprovalGroupView/ApprovalGroupView';
import CommercialApprovalRevisionCompare from './pages/CommercialApprovalRevisionCompare/CommercialApprovalRevisionCompare';
import TabUnAuthorized from './pages/UnAuthorized/TabUnAuthorized';

import QuoteSentCommercialTempleteRevisionView from './pages/QuoteSentCommercialTempleteRevisionView/QuoteSentCommercialTempleteRevisionView';
import OrganisationRequest from './pages/SuperAdmin/Organisations/OrganisationRequest/OrganisationRequest';
import OrganisationList from './pages/SuperAdmin/Organisations/OrganisationList/OrganisationList';
import OrganisationAppoval from 'pages/SuperAdmin/Organisations/OrganisationAppoval/OrganisationAppoval';
import ProductList from 'pages/SuperAdmin/Products/ProductList/ProductList';
import KamTask from 'pages/Kam/KamTask';

import OrganisationByProduct from 'pages/SuperAdmin/Products/ProductByOrganisation/OrganisationByProduct';
import ProductDetails from 'pages/SuperAdmin/Products/ProductDetails/ProductDetails';
import OrganisationApproved from 'pages/SuperAdmin/Organisations/OrganisationApproved/OrganisationApproved';
import ProductRequest from 'pages/SuperAdmin/Products/ProductRequest/ProductRequest';
import CommercialRequest from 'pages/SuperAdmin/Commercial/CommercialRequest/CommercialRequest';
import CommercialList from 'pages/SuperAdmin/Commercial/CommercialList/CommercialList';
import CommercialDetails from 'pages/SuperAdmin/Commercial/CommercialDetails/CommercialDetails';

import FranchiseList from 'pages/SuperAdmin/Franchise/FranchiseList/FranchiseList';
import FranchiseApproved from 'pages/SuperAdmin/Franchise/FranchiseApproved/FranchiseApproved';
import FranchiseTask from 'pages/SuperAdmin/Franchise/FranchiseTask/FranchiseTask';
import RequireSuperAdminAuth from 'components/RequireSuperAdminAuth';
import CommercialCategoryList from 'pages/SuperAdmin/Commercial/CommercialCategoryList/CommercialCategoryList';
import CommercialTempleteSpecificViewRequest from 'pages/CommercialTempleteSpecificView/CommercialTempleteSpecificViewRequest';

import KamAprovalRequest from 'pages/SuperAdmin/KamApproval/KamAprovalRequest/KamAprovalRequest';
import OrganisationByCommercial from 'pages/SuperAdmin/Commercial/OrganisationByCommercial/OrganisationByCommercial';
import FranchiseRevisions from 'pages/SuperAdmin/Franchise/FranchiseRevisions/FranchiseRevisions';
import TechnicalRevisionView from 'pages/TechnicalRevisionView/TechnicalRevisionView';
import VendorTechnicalRevisionView from 'pages/VendorTechnicalRevisionView/VendorTechnicalRevisionView';
import TechnicalComparisionRevisionView from 'pages/TechnicalComparisionRevisionView/TechnicalComparisionRevisionView';
import TechnicalApprovalRevisionView from 'pages/TechnicalApprovalRevisionView/TechnicalApprovalRevisionView';
import VendorCommercialTempleteRevisionView from 'pages/VendorCommercialTempleteRevisionView/VendorCommercialTempleteRevisionView';
import PartImport from 'pages/PartImport/PartImport';
import FileImport from 'pages/FileImport/FileImport';
import CategoriesView from 'pages/SuperAdmin/Categories/View/CategoriesView';




function App() {


  const { name, companyName } = useAuth();

  return (

    <BrowserRouter>
      <Routes>

        <Route path='/commercial-revision' element={<CommercialTempleteViewVendor />} />
        <Route path='/subsExpire' element={<SubscriptionExpire />} />
        <Route path='/' element={<HomeLayout />} >
          <Route index element={<HomePage />} />

          <Route path='how-it-works' element={<HomePage />} />
          <Route path='demopage' element={<DemoPage />} />
          <Route path='feature' element={<FeaturePage />} />
        </Route>

        {/* <Route path='/test' element={<TechnicalComparisionRevisionView />} /> */}
        <Route path='/test2' element={<PartImport />} />
        <Route path='/login' element={<Login />} />

        {/* <Route path='/redirectRegistration' element={<RedirectRegistration />} /> */}
        <Route path='/testProductSelect' element={<ProductSelectNew />} />
        <Route path='/redirectlogin' element={<RedirectLogin />} />
        <Route path='/redirectvendorlogin' element={<RedirectVendorLogin />} />
        <Route path='/redirectRegistration' element={<RedirectRegistrationNew />} />
        <Route path='/procureregister' element={<ProcureRegisterNew />} />
        {/* <Route path='/documentEditor' element={<DocumentEditor />} /> */}


        {/* <Route path='/redirectRegistration' element={<RedirectRegistration />} /> */}
        <Route path='/verifyEmail' element={<EmailVerifyOTP />} />
        <Route path='/underVerification' element={<UnderVerification />} />
        <Route path='/slider' element={<SliderTwo />} />
        <Route path={'unauthorized'} element={<UnAuthorized />} />
        {/* <Route path='/procureregister' element={<ProcureRegister />} /> */}
        {/* <Route path='/corporateRegistration' element={<CorporateRegistration />} /> */}
        <Route path='/vendorRegister' element={<VendorRegister />} />
        <Route path='/userRegister' element={<UserRegister />} />
        <Route path='/emailVerify' element={<EmailVerify />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/forgotPass' element={<ForgotPassword />} />
        <Route path='/resetPass' element={<ResetPassword />} />
        <Route path='/sessionExpire' element={<SessionExpire />} />
        <Route path='/test' element={<TestPage />} />
        <Route path='/terms' element={<TestPage />} />
        <Route path='/privacy' element={<TestPage />} />
        <Route path='/productSelect' element={<ProductSelect />} />
        <Route path='/productshow' element={<ProductShow />} />








        <Route element={<RequireAuth />}>
          <Route path='/:client/' element={<Layout />}>
            {/* /Tader/commercial/1/60122 */}
            {/* /Tader/technical-specification/1/60122/564 */}

            <Route path='unauthorized' element={<TabUnAuthorized />} />
            <Route index element={<Dashboard />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route element={<RequireRole allowedRoles={["KAM", "USER", "ADMIN"]} />}>


              <Route element={<RequireWorkSpace allowedWorkSpace={"technical-specification"} />} >
                <Route path='technical-specification/' element={<Outlet />}>
                  <Route index element={<TechnicalSpecification />} />
                  <Route path=':taskId/:productId' element={<Outlet />}>
                    <Route index element={<ProductRevisions />} />

                    <Route path=':revisionId' element={<TechnicalRevisionView />} />
                  </Route>
                  {/*------------------ old logic  for category routing-------------------------- */}
                  {/* <Route path=':category/*' element={<Outlet />} >

                    <Route path='*' element={<TaskCategory />} />
                    <Route path='products' element={<Outlet />} >
                      <Route index element={<ProductPage />} />
                      <Route path='revision/' element={<Outlet />} >
                        <Route index element={<ProductRevisions />} />
                        <Route path=':revision' element={<RevisionEdit />} />
                      </Route>

                    </Route>
                  </Route> */}

                  {/*------------------ old logic  for category routing-------------------------- */}



                </Route>
              </Route>



              <Route element={<RequireWorkSpace allowedWorkSpace={"commercial"} />} >
                <Route path='commercial' element={<Outlet />} >

                  <Route index element={<CreateTaskForCommercial />} />
                  <Route path='templates/' element={<Outlet />}>
                    <Route index element={<CommercialTempleteViewKam />} />
                    <Route path=':specificationId' element={<Outlet />}>
                      {/* <Route index element={<TestPage3 />} /> */}
                      <Route index element={<CommercialTempleteSpecificViewRequest />} />
                      <Route path=':revisionId' element={<CommercialTempleteRevisionView />} />
                    </Route>
                  </Route>
                  <Route path=':taskId' element={<Outlet />}>
                    <Route index element={<CommercialTempleteSpecificView />} />
                    <Route path=':productId/:tempId/' element={<Outlet />}>
                      {/* <Route index element={<TestPage3 />} /> */}
                      <Route index element={<CommercialTempleteRevisions />} />
                      <Route path=':revisionId' element={<CommercialTempleteRevisionView />} />
                    </Route>
                  </Route>

                </Route>
              </Route>
              <Route element={<RequireWorkSpace allowedWorkSpace={"procurement"} />} >
                <Route path='procurement' element={<Outlet />} >
                  <Route index element={<UnderConstruction />} />
                </Route>
              </Route>

              {/* /rader/approvals/prathamesh */}
              <Route element={<RequireWorkSpace allowedWorkSpace={"approvals"} />} >
                <Route path='approvals/' element={<Outlet />} >
                  <Route index element={<WorkSpaceApproval />} />
                  <Route path='group/:revisionId' element={<ApprovalGroupView />} />

                  <Route path='commercial' element={<Outlet />}>
                    <Route path=':taskId/:revisionId' element={<CommercialApprovalRevisionCompare />} />

                  </Route>
                  <Route path='technical' element={<Outlet />}>
                    <Route path=':taskId/:revisionId' element={<TechnicalApprovalRevisionView />} />

                  </Route>


                </Route>
              </Route>
              {/* <Route path='procurement' element={<RequireWorkSpace allowedWorkSpace={"procurement"} componet={TestPage} />} />
              <Route path='approvals' element={<RequireWorkSpace allowedWorkSpace={"approvals"} componet={TestPage} />} />  */}
            </Route>


            <Route path='underconstruction' element={<UnderConstruction />} />
            <Route path='profile' element={<Profile />} />
            <Route path='userProfile' element={<UserProfile />} />

            <Route path='CompanyProfile' element={<CompanyProfilePage />} />

            <Route element={<RequireRole allowedRoles={["KAM", "ADMIN"]} />}>

              <Route path='mytasks/' element={<Outlet />}>


                <Route path="approval" element={<Outlet />} >
                  <Route index element={<Approvals />} />

                  <Route path="user" element={<Outlet />}>

                    <Route path=":taskId/:productId/:revisionId" element={<TechnicalRevisionView />} />
                    <Route path='commercial/:taskId/:productId/:revisionId' element={<CommercialTempleteRevisionView />} />
                  </Route>
                  <Route path="compare/:revisionId" element={<TechnicalComparisionRevisionView />} />
                  <Route path="group/:groupId" element={<RevisionGroup />} />
                  <Route path="vendor" element={<Outlet />} >
                    <Route path=":revisionId" element={<VendorTechnicalRevisionView />} />
                    <Route path="commercial/:revisionId" element={<VendorCommercialTempleteRevisionView />} />
                    <Route path="commercial/compare/:revisionId" element={<CommercialRevisionCompare />} />
                  </Route>

                </Route>

                <Route path='assigned-to-me' element={<AdminTask />} />
                <Route path="quotes-sent" element={<Outlet />} >
                  <Route index element={<QuotesSent />} />
                  <Route path="view/:revisionId" element={<RevisionViewQuotesSent />} />
                  <Route path="commercialview/:revisionId" element={<QuoteSentCommercialTempleteRevisionView />} />

                </Route>
                <Route path='assigedkam' element={<KamTask />} />

              </Route>



              <Route path='vendors' element={<Vendors />} />
              <Route path='vendorRevisonList' element={<Outlet />} >
                <Route index element={<VendorRevisonList />} />
                <Route path='vendorRevision/' element={<Outlet />} >
                  {/* <Route index element={<VendorRevision />} /> */}
                  <Route path='commercial/:vendorRevision' element={<VendorRevision />} />
                  <Route path=':vendorRevision' element={<VendorRevision />} />
                </Route>
              </Route>

              <Route path='users' element={<Users />} />

            </Route>
            <Route path='unauthorized' element={<UnAuthorized />} />




          </Route>
        </Route>


        <Route element={<RequireVendorAuth />}>


          <Route path='/vendor' element={<VendorLayout />}>
            <Route path='underconstruction' element={<UnderConstruction />} />

            <Route index element={<VendorDashboard />} />
            <Route path='dashboard' element={<VendorDashboard />} />
            {/* <Route path='vendorCommercialTaskVendor' element={<CommercialTempleteViewVendor />} /> */}
            <Route path='organisations/' element={<Outlet />} >
              <Route index element={<OrganisationsList />} />
              <Route path=':client/:orgId' element={<Outlet />} >
                <Route index element={<VendorRevisonList />} />
                <Route path='technical/:revisionId' element={<VendorTechnicalRevisionView />} />

                <Route path='commercial/:revisionId' element={<VendorCommercialTempleteRevisionView />} />


              </Route>
            </Route>

            <Route path='profile' element={<VendorProfile />} />
            <Route path='companyProfile' element={<VendorCompanyProfile />} />
          </Route>

        </Route>


        <Route element={<RequireSuperAdminAuth />}>


          <Route path='/superadmin' element={<SuperAdminLayout />}>
            <Route path='underconstruction' element={<UnderConstruction />} />
            {/* <Route path='approvalsss' element={<AdminApproval />} /> */}

            <Route index element={<SuperAdminDashboard />} />

            <Route path='dashboard' element={<SuperAdminDashboard />} />

            {/* organisation Routes */}

            <Route path='organisation-lists' element={<OrganisationList />} />

            <Route path='organisation-requests' element={<OrganisationRequest />} />

            <Route path='organisation' element={<Outlet />} >

              <Route path='organisation-approval/:kamId/:orgId' element={<OrganisationAppoval />} />
              <Route path='organisation-approved/:kamId/:orgId' element={<OrganisationApproved />} />

            </Route>


            <Route path='categories' element={<Outlet />} >

              <Route index element={<CategoriesView />} />
              <Route path='*' element={<CategoriesView />} />

            </Route>



            {/* organisation Routes */}

            {/* Product  Routes */}
            <Route path='products-lists' element={<ProductList />} />
            <Route path='products-requests' element={<ProductRequest />} />





            <Route path='products' element={<Outlet />} >

              <Route path='fileImport' element={<PartImport />} />

              <Route path='organisation-by-product/:productId' element={<Outlet />} >
                <Route index element={<OrganisationByProduct />} />
                <Route path='view' element={<ProductDetails />} />
                <Route path=':orgId' element={<ProductDetails />} />
                <Route path=':orgId' element={<ProductDetails />} />
              </Route>




            </Route>
            {/* Product  Routes */}

            {/* Commercial  Routes */}


            <Route path='commercial-list' element={<Outlet />} >

              <Route index element={<CommercialCategoryList />} />
              <Route path=':ecomSpecId/:catId' element={<Outlet />} >

                <Route index element={<CommercialList />} />
                <Route path='fileImport' element={<FileImport />} />
              </Route>

            </Route>
            <Route path='commercial-requests' element={<Outlet />} >
              <Route index element={<CommercialRequest />} />
              <Route path=':catId' element={<CommercialRequest />} />
            </Route>


            <Route path='commercial' element={<Outlet />} >


              <Route path='organisation-by-commercial/:templateId/:productId' element={<Outlet />} >
                <Route index element={<OrganisationByCommercial />} />
                <Route path='view' element={<CommercialDetails />} />
                <Route path=':orgId' element={<CommercialDetails />} />
              </Route>


              {/* <Route path='view/:templateId' element={<CommercialDetails />} /> */}
              {/* <Route path='organisation-by-product/:productId' element={<Outlet />} >

              <Route index element={<OrganisationByProduct />} />
            
            </Route> */}



            </Route>


            {/* Commercial  Routes */}


            {/* Franchises Routing  */}
            <Route path='franchisestask' element={<FranchiseTask />} />
            <Route path='franchises-list' element={<FranchiseList />} />
            <Route path='franchises' element={<Outlet />} >


              <Route path='franchises-approved/:kamId/:orgId' element={<FranchiseApproved />} />




              <Route path='product/revisions/:productId/:orgId' element={<Outlet />} >
                <Route index element={<FranchiseRevisions />} />
                <Route path=':revisionId' element={<ProductDetails />} />

              </Route>


              <Route path='commercial/revisions/:templateId/:orgId' element={<Outlet />} >
                <Route index element={<FranchiseRevisions />} />
                <Route path=':productId/:revisionId' element={<CommercialDetails />} />
              </Route>




            </Route>

            {/* Franchises Routing  */}


            {/* Kam Apprval  Routing  */}

            <Route path='kam-approvals' element={<KamAprovalRequest />} />

            {/* Kam Apprval  Routing  */}



          </Route>
        </Route>


      </Routes >

    </BrowserRouter >


  );
}

export default App;


