import React, { useEffect, useState,useRef } from 'react'
import { Box, Typography } from '@mui/material'
import FootStyle from "./Footer.module.css"
import { Image } from 'cloudinary-react'
import { Link } from 'react-router-dom'
import { NavLink, useNavigate } from 'react-router-dom'


const Footer = () => {
    const footerRef = useRef(null);
    return (
        <div className={FootStyle.FooterMain} ref={footerRef} >
            <div className={FootStyle.FooterStart}>
                <div className={FootStyle.FooterContainer1}>
                    <div className={FootStyle.FooterBox1}>
                        <Box className={FootStyle.FooterIcon}>
                            <NavLink to="/home">
                                <Image publicId="https://res.cloudinary.com/dtffniutw/image/upload/v1691751303/Vector_usvzba.png" cloudName="dxluokypg" />
                            </NavLink>
                        </Box>
                        <Typography variant='h6'>Kamdhenu Commerz , 401 , 4TH FLOOR, Sector 14, Kharghar, Navi Mumbai, Maharashtra 410210</Typography>
                    </div>
                    <div className={FootStyle.FooterBox2}>
                        <Box className={FootStyle.FooterBox}>
                            <Typography variant='h4'>Company</Typography>
                            <label>
                                <ul>
                                    <Link to="/" style={{ textDecoration: 'none' }}><li>Home</li></Link>
                                    <Link to="/about us" style={{ textDecoration: 'none' }}><li>About Us</li></Link>
                                    <a href="https://www.kjssteel.com/#/Partner%20with%20us" style={{ textDecoration: 'none' }} target='_blank'><li>Partners</li></a>
                                    <Link to="/product catalogue" target='_blank' style={{ textDecoration: 'none' }}><li>Product catalogue</li></Link>

                                </ul>
                            </label>
                        </Box>
                        <Box className={FootStyle.FooterBox}>
                            <Typography variant='h4'>Help</Typography>
                            <label>
                                <ul>
                                    <a href="https://www.kjssteel.com/#/faq" style={{ textDecoration: 'none' }} target='_blank'><li>FAQs</li></a>
                                    <Link to="/contact us" style={{ textDecoration: 'none' }}><li>Contact us</li></Link>
                                </ul>
                            </label>
                        </Box>
                        <Box className={FootStyle.FooterBox}>
                            <Typography variant='h4'>Media</Typography>
                            <label>
                                <ul>
                                    <Link to="/comingsoon" style={{ textDecoration: 'none' }}><li>Blog</li></Link>
                                    <Link to="/media" style={{ textDecoration: 'none' }}><li>News Articles</li></Link>
                                    <Link to="/comingsoon" style={{ textDecoration: 'none' }}><li>Press releases</li></Link>
                                </ul>
                            </label>
                        </Box>
                        <Box className={FootStyle.FooterBox}>
                            <Typography variant='h4'>Career</Typography>
                            <label>
                                <ul>
                                    <Link to="/careers" style={{ textDecoration: 'none' }}><li>Job opening</li></Link>
                                    <Link to="/jobapp" style={{ textDecoration: 'none' }}><li>Job application</li></Link>
                                </ul>
                            </label>
                        </Box>
                        <Box className={FootStyle.FooterBox} >
                            <Typography variant='h4' sx={{display:'flex'}}>Contact us</Typography>
                            <label>
                                <ul>
                                    <a href="https://api.whatsapp.com/send?phone=9321714104" target="_blank" style={{ textDecoration: 'none',display:'flex',marginBottom:'-5%'}}><li>93217 14104</li></a>
                                    <a href="mailto:info@kjsscosmos.com" target='blank' style={{ textDecoration: 'none' }}><li>info@kjsscosmos.com</li></a>
                                </ul>
                            </label>
                        </Box>
                        <Box className={`${FootStyle.FooterBox} ${FootStyle.FooterBoxHide}`}>
                            <Typography variant='h4' sx={{display:'flex',marginBottom:'-5%'}}>Legal</Typography>
                            <label style={{paddingBottom:'0'}}>
                                <ul>
                                <Link to='https://www.kjssteel.com/disclaimer' target='_blank' style={{ textDecoration: 'none' }}><li>Disclaimer</li></Link>
                                <Link to='https://www.kjssteel.com/privacy' target='_blank' style={{ textDecoration: 'none' }}><li>Privacy Policy</li></Link>
                                <Link to='https://www.kjssteel.com/terms' target='_Blank' style={{ textDecoration: 'none' }}><li>Terms of Service</li></Link>
                                </ul>
                            </label>
                        </Box>
                    </div>
                </div>
                <div className={FootStyle.FooterContainer2}>
                    <Box className={FootStyle.FooterDownBox1}>
                        <Typography variant='h6'>Follow us on</Typography>

                        <Box className={FootStyle.SocialIcons}>
                            <a href="https://www.linkedin.com/in/kjss-cosmos-pvt-ltd-056613258/" target="_blank">
                                <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680581659/LinkedIn_qyemfg.svg" cloudName="dxluokypg" />
                            </a>

                            <a href="https://twitter.com/KjssCosmos" target="_blank">
                                <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680581659/Twitter_ujajbb.svg" cloudName="dxluokypg" />
                            </a>

                            <a href="https://www.instagram.com/pr.kjsscosmospvtltd/" target="_blank">
                                <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680581659/Instagram_cebpv7.svg" cloudName="dxluokypg" />
                            </a>

                            <a href="https://www.facebook.com/profile.php?id=100086595487175" target="_blank">
                                <Image publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1680581658/Facebook_d6ws5h.svg" cloudName="dxluokypg" />
                            </a>

                        </Box>

                        <Box className={FootStyle.footercopyright}>&copy;  KJSSCOSMOS Pvt. Ltd.</Box>

                    </Box>
                    <Box className={FootStyle.footertos}>
                        <Link to='https://www.kjssteel.com/disclaimer' target='_blank' style={{ textDecoration: 'none' }}>
                        <span style={{ color: '#c8c4c4' }}>Disclaimer</span>
                        </Link>
                        <span>|</span>
                        <Link to='https://www.kjssteel.com/privacy' target='_blank' style={{ textDecoration: 'none' }}>
                        <span style={{ color: '#c8c4c4' }}>Privacy Policy</span>
                        </Link>
                        <span>|</span>
                        <Link to='https://www.kjssteel.com/terms' target='_Blank' style={{ textDecoration: 'none' }}>
                        <span style={{ color: '#c8c4c4' }}>Terms of Service</span>
                        </Link>
                    </Box>

                </div>
            </div>
        </div>
    )
}

export default Footer