import axios, { PrivateComponent } from 'api/axios';
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx';
import styles from './FileImport.module.css'
import ExcelUploader from 'components/ExcelUploader/ExcelUploader';
import useAuth from 'hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';


const FileImport = () => {

  // Destructuring functions and components from hooks
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Backdrop functions
  const { Toast, showToast } = useToast(); // Toast notification functions
  const [jsonData, setJsonData] = useState(null); // State to store JSON data
  const privateAxios = PrivateComponent(); // Custom Axios instance
  const { auth } = useAuth(); // Authentication data
  const { ecomSpecId, catId } = useParams(); // Route parameters
  const navigate = useNavigate(); // Navigation function
  const [filesData, setFilesData] = useState([]); // State to store files data

  // Function to handle file changes (file upload)
    const handleFileChange = (files) => {
        const tempArray = [];

        const readFile = (file, index) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryString = event.target.result;
                const workbook = XLSX.read(binaryString, { type: 'binary' });

                // Get data from the first sheet (product data)
                const productDataSheet = workbook.SheetNames[0];
                const proData = workbook.Sheets[productDataSheet];

                const data = XLSX.utils.sheet_to_json(proData)[0] || {};
                const descriptionSheet = workbook.SheetNames[1];
                const descData = workbook.Sheets[descriptionSheet];
                const desc = (XLSX.utils.sheet_to_json(descData)[0] || {}).description || '';
                const questionDataSheet = workbook.SheetNames[2];
                const questionData = XLSX.utils.sheet_to_json(workbook.Sheets[questionDataSheet]);
                const documentDataSheet = workbook.SheetNames[3];
                const docData = XLSX.utils.sheet_to_json(workbook.Sheets[documentDataSheet]);

                tempArray.push({
                    ...data,

                   
                    specificationId:ecomSpecId,
                    description: { name: desc },
                    questions: questionData,
                    documents: docData
                });

                if (index === files.length - 1) {
                    // All files have been processed
                    setFilesData(tempArray);
                }
            };
            reader.readAsBinaryString(file);
        };

        files.forEach((file, index) => {
            readFile(file, index);
        });
    };

   
 // Function to handle adding file data
    const handleAddFile=async()=>{
        openBackdrop(); // Open backdrop for loading indication
        try {
            const res=await privateAxios.post(`/procureadmin/importTemplate/${catId}`,filesData);// Send files data to server
            navigate(-1); // Navigate back
        } catch (error) {
            showToast("error","Error Uploading Data");// Show toast notification in case of error
        }

        closeBackdrop();// Close backdrop after the operation
    }


    return (
        <>
            <Toast />
            <BackdropComponent />

            <div className={styles.Container} style={{ height: "100%" }}>
                <div className={styles.mainContainer}>
                    <div className={styles.inputGroup}>
                        <div className={styles.inputRow}>
                            <label htmlFor="">Upload Template</label>
                            <ExcelUploader onChange={handleFileChange} />
                        </div>

                        <button onClick={handleAddFile} className={styles.upBtn}>
                        Upload
                    </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FileImport
