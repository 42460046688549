import React from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import revisionEditStyle from './RevisionEdit.module.css'
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useAuth from 'hooks/useAuth';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useState } from 'react';
import SpecificationTab from './SpecificationTab';
import AttributeTab from './AttributeTab';
import DocumentsTab from './DocumentsTab';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { PrivateComponent } from 'api/axios'
import axios from 'axios';
import { createExcel } from 'utility/excelUtils'
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import BackButton from 'components/BackButton/BackButton';


const ProductDetails = () => {
  // Custom hook for toast notifications
  const { showToast, Toast } = useToast();
  // Custom hook for getting URL parameters
  const { productId, orgId, revisionId } = useParams();
  // Custom hook for handling backdrop (loading spinner)
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
  // Custom hook for authentication data
  const { auth } = useAuth();
  // Axios instance for authenticated API requests
  const privateAxios = PrivateComponent();
  // Custom hook for accessing location (URL and state)
  const location = useLocation();
  const { state } = location;
  // URL search parameters
  const searchParams = new URLSearchParams(location.search);
  // State for delete confirmation modal
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  // State for active tab
  const [tabActive, setTabActive] = useState("0");
  // State for multi-save feature
  const [multiSave, setMultiSave] = useState(false);
  // State for multi-add dropdown
  const [multiAddDropDown, setMultiAddDropDown] = useState(false);
  // Hook for navigation
  const navigate = useNavigate();
  // State for product details
  const [productDetails, setProductDetails] = useState({});
  // State for workspace header revision more
  const [workSpaceHeaderRevisionMore, setworkSpaceHeaderRevisionMore] = useState(false);
  // State for documents
  const [documents, setDocuments] = useState([]);
  // State for rename text and modal
  const [renameText, setRenameText] = useState("");
  const [renameModal, setRenameModal] = useState(false);
  // State for save as feature
  const [saveAs, setSaveAs] = useState(false);
  // State for e-commerce revision
  const [isEcomRevision, setIsEcomRevision] = useState(false);
  // State for duplicate name and modal
  const [duplicateName, setDuplicateName] = useState("");
  const [duplicateNameModal, setDuplicateNameModal] = useState(false);
  // State for approval modal
  const [approvalModal, setApprovalModal] = useState(false);

  // Handler for toggling approval modal
  const handleApprovalModal = (data) => {
    setApprovalModal(prev => !prev)
  }

  // Handler for toggling delete modal and setting delete IDs
  const handleDeleteModal = (id) => {
    setDeleteModal(prev => !prev);
    if (id !== null) {
      setDeleteId(id)
    }
  }


  // -----------------------Attribute Data----------------------

   // Initial columns for attribute data table
  const initialColumns = [
    { id: "-1", name: "Sr No.", editable: false }, { id: "-1", name: "Name", editable: false }, { id: "-1", name: "Type", editable: false }, { id: "-1", name: "Unit", editable: false }, { id: "-1", name: "Value", editable: false }, { id: "-1", name: "Remark", editable: false }, { id: "-1", name: "Add", editable: false }
  ];

    // State for columns and table rows
  const [columns, setColumns] = useState(initialColumns);
  const [tableRows, setTableRows] = useState([]);

 // Handler for adding a new column to the table
  const handleAddColumn = () => {
    if (tableRows.length < 1) {
      alert("please add a row first")
    }
    else {
      const addColumnIndex = columns.findIndex((column) => column.name === 'Add');
      let newColumns = [];
      let currentId = (tableRows[0].additionalFields[tableRows[0].additionalFields.length - 1]?.columnId || 0) + 1;

      // Inserting the new column before the 'Add' column
      newColumns = [
        ...columns.slice(0, addColumnIndex),
        { id: currentId, name: 'New Column', editable: true },
        ...columns.slice(addColumnIndex, columns.length),
      ];

      // console.log(newColumns);
      setColumns(newColumns);

      // Creating a new row with the corresponding column information
      let newRow = {
        columnId: currentId,
        columnName: 'New column',
        createdBy: auth.userData.id,
        columnValue: '',
      };

      // Adding the new row to each existing row's additionalFields
      const newTableRows = tableRows.map((row) => ({
        ...row,
        additionalFields: [...row.additionalFields, newRow],
      }));
      setTableRows(newTableRows);
    }
  };

   // Handler for adding a new row to the table
  const handleAddRow = () => {

    let additionalColumns = []
    if (tableRows.length > 0) {

      additionalColumns = tableRows[0].additionalFields.map((col) => {
        return {
          ...col,
          columnValue: ""
        }
      })
    }

    const newRow = {
      id: tableRows.length + 1,
      name: "",
      type: "",
      unit: "",
      value: "",
      remark: "",
      additionalFields: additionalColumns

    };
    setTableRows([...tableRows, newRow]);
  };

    // Handler for deleting a row from the table
  const handleRowDelete = (id) => {
    const updatedRows = tableRows.filter(row => row.id !== id);
    setTableRows(updatedRows);
  };

  // Handler for changing the name of a column
  const handleColumnNameChange = (id, text) => {


    setColumns(prev => {
      return prev.map(col => {
        if (col.id === id) {
          return {
            ...col,
            name: text,
          }
        }
        else {
          return col
        }
      })

    })


    setTableRows(prev => {
      return prev.map(row => {
        return {
          ...row,
          additionalFields: row.additionalFields.map((field) => {
            if (field.columnId === id) {
              return {
                ...field,
                columnName: text
              }
            }
            else {
              return field;
            }
          }),
        }
      })
    })

  }

   // Handler for deleting a column
  const handleColumnDelete = (id) => {

    setColumns(prev => {
      return prev.filter(col => col.id !== id);

    })


    setTableRows(prev => {
      return prev.map(row => {
        return {
          ...row,
          additionalFields: row.additionalFields.filter((field) => field.columnId !== id),
        }
      })
    })

  }

// Fetch data for e-commerce product
  const getDefaultData = async () => {
    openBackdrop()
   
    try {

    

      const response = await privateAxios.get(`procurement/getDefaultProduct/${productId}`);
      let data = response.data;

      console.log(data.attributes)
       // Update product attributes with additional fields
      const updatedProAttri = data.attributes.map((dd) => {
        let addFields = dd.additionalFields;
        if (typeof addFields === 'string' && addFields !== "{}") {
          try {
            addFields = JSON.parse(addFields);
          } catch (error) {
            addFields = [];
          }
        }
        else {
          addFields = [];
        }
        return {
          ...dd,
          additionalFields: addFields,
        };
      });

      data = {
        ...data,
        productAttributes: updatedProAttri,
      }
      // Update product details state
      setProductDetails(data);

      const attributes = data?.productAttributes;
      const maxAdditionalColumn = attributes[0].additionalFields?.length;
      // console.log(maxAdditionalColumn);
      if (maxAdditionalColumn > 0) {
        const addColumnIndex = initialColumns.findIndex(column => column.name === "Add");



        let newColumnNames = attributes[0].additionalFields.map((col) => {
          return {
            id: col.columnId,
            name: col.columnName,
            editable: true,
          }
        });

 // Insert new columns before the 'Add' column
        const newColumns = [
          ...initialColumns.slice(0, addColumnIndex),
          ...newColumnNames, // New column to be added before the 'Add' column
          { id: "-1", name: "Add", editable: false }
        ];
        // Update columns state
        setColumns(newColumns);
      }

      // Update table rows state
      setTableRows(attributes);
      // Set specification value state
      setSpecificationValue(data.specification?.name);
      // Set rename text state
      setRenameText(data.name);
      // Update documents state
      setDocuments(data.docs);
      closeBackdrop()
    } catch (error) {
      console.error("Error fetching data:", error);
      closeBackdrop();

    }
  }

    // Fetch data for procurement product
  const getDataProcure = async () => {
    openBackdrop()
    try {

      let url = '';
 // Determine URL based on revisionId
      if (revisionId === undefined || revisionId === null || revisionId === "" || revisionId === "0") {
        url = `/technicalSpecification/getDefaultRevisionByOrganisationIdAndProductId/${orgId}/${productId}`
      }
      else {
        url = `/technicalSpecification/getRevisionByRevisionIdAndProductId/${revisionId}/${productId}`
      }

      const response = await privateAxios.get(`${url}`);
      let data = response.data;

       // Update product attributes with additional fields
      const updatedProAttri = data.productAttributes.map((dd) => {
        let addFields = dd.additionalFields;
        if (typeof addFields === 'string' && addFields !== "{}") {
          try {
            addFields = JSON.parse(addFields);
          } catch (error) {
            addFields = [];
          }
        }
        else {
          addFields = [];
        }
        return {
          ...dd,
          additionalFields: addFields,
        };
      });

      data = {
        ...data,
        productAttributes: updatedProAttri,
      }
      // Update product details state
      setProductDetails(data);

      const attributes = data?.productAttributes;
      const maxAdditionalColumn = attributes[0].additionalFields?.length;
      // console.log(maxAdditionalColumn);
      if (maxAdditionalColumn > 0) {
        const addColumnIndex = initialColumns.findIndex(column => column.name === "Add");



        let newColumnNames = attributes[0].additionalFields.map((col) => {
          return {
            id: col.columnId,
            name: col.columnName,
            editable: true,
          }
        });

 // Insert new columns before the 'Add' column
        const newColumns = [
          ...initialColumns.slice(0, addColumnIndex),
          ...newColumnNames, // New column to be added before the 'Add' column
          { id: "-1", name: "Add", editable: false }
        ];
        // Update columns state
        setColumns(newColumns);
      }

      // Update table rows state
      setTableRows(attributes);
      // Set specification value state
      setSpecificationValue(data.productSpecification?.name);
      // Set rename text state
      setRenameText(data.name);
      // Update documents state
      setDocuments(data.productDocuments);
      closeBackdrop()
    } catch (error) {
      console.error("Error fetching data:", error);
      closeBackdrop();

    }
  };

// useEffect to fetch data based on orgId
  useEffect(() => {
    if (orgId === undefined || orgId === null || orgId === "") {
      getDefaultData();
      setIsEcomRevision(true);
    }
    else {
      getDataProcure();
    }
  }, [])





 // useEffect to set the active tab based on URL search params
  useEffect(() => {
    if (searchParams.get("tab") !== null && searchParams.get("tab") !== undefined && searchParams.get("tab") !== "") {

      setTabActive(searchParams.get("tab"));
    }
    else {
      setTabActive("0")
    }
  }, [Location, searchParams])

   // Function to toggle multi save state
  const handleMultiSave = () => {
    setMultiSave(prev => !prev)
  }

    // Function to toggle rename modal and set rename text
  const handleRenameModal = () => {
    setRenameModal(prev => !prev)
    // console.log(data)
    setRenameText(productDetails?.name)
  }

// Function to toggle duplicate name modal and set duplicate name
  const handleDuplicateNameModal = () => {
    setDuplicateNameModal(prev => !prev);
    setDuplicateName(productDetails?.name)
  }


   // Function to change active tab and update URL
  const handleTabChange = (index) => {
    setTabActive(index)
    navigate(`?tab=${index}`, { replace: true })
  }



  // -----------------------Document Data----------------------

   // State to control the add document modal visibility
  const [addDocumentModal, setAddDocumentModal] = useState(false);

   // Function to toggle add document modal
  const handleAddDocumentModal = () => {
    setAddDocumentModal(prev => !prev);
  }

  // -----------------------Specification Data----------------------


   // State to hold specification value
  const [specificationValue, setSpecificationValue] = useState('');

  // Function to handle specification value (placeholder)
  const handelSpecification = () => {
    // console.log(specificationValue)
  }

 // State to hold duplicate error IDs
  const [duplicateError, setDuplicateError] = useState([]);

  // Function to validate attribute names for duplicates and emptiness
  const handleAttributeValidation = () => {
    const hasNullName = tableRows.some(rr => rr.name === null);

    if (hasNullName) {
      alert("please check Attribute Name No Name can be empty");
      return false;
    }
    else {
      const nameOccurrences = {};
      const duplicateNamesId = [];
      tableRows.forEach((item) => {
        const name = item.name;
        // Check if the name has been encountered before
        if (nameOccurrences[name]) {
          // If it has, mark it as a duplicate
          duplicateNamesId.push(item.id);
        } else {
          // Otherwise, record it in the occurrences object
          nameOccurrences[name] = true;
        }
      });
      if (duplicateNamesId.length > 0) {
        // console.log(duplicateError)
        setDuplicateError(duplicateNamesId)
        alert("Please Remove Duplicate Attributes");
        return false;
      }
      else {
        setDuplicateError([]);
        return true;
      }
    }
  }

  // Function to handle saving the revision
  const handleSaveRevision = async () => {
    openBackdrop()
    try {

      if (handleAttributeValidation()) {


        const updatedtableRow = tableRows.map((row) => {
          const rr = row;
          delete rr.id
          return {
            ...rr,
            additionalFields: JSON.stringify(row.additionalFields)
          }


        })

        const updatedProducts = productDetails;
        delete updatedProducts.lastModified
        delete updatedProducts.id;

        const updatedDocuments = documents
          .map(dd => {
            const tr = dd;
            delete tr.id;
            return dd;
          });

        const requestPayload = {
          name: productDetails.name,
          productName: productDetails.productName,
          productId: productDetails.productId,
          productSpecification: { name: specificationValue },
          productAttributes: updatedtableRow
        }
        // console.log(requestPayload)
        await privateAxios.post(`/technicalSpecification/updateRevision/${revisionId}`, requestPayload).then(res => {
          window.location.reload()
        }).catch((err) => {
          // console.log("error saving revision");
          alert("error saving revision")
        })
      }
      closeBackdrop();

    } catch (error) {
      // console.log(error);
      closeBackdrop();
    }
  }


  // ========================delete all duplicate columns===================

   // Function to delete all duplicate columns
  const deleteAllDuplicateColumns = () => {
    const duplicateRowId = duplicateError;
    const updatedRows = tableRows.filter(row => !duplicateRowId.includes(row.id));
    setTableRows(updatedRows);
  }

  // ========================delete all duplicate columns===================


 // Function to handle changing the revision name (placeholder)
  const handleChangeRevisionName = async (e) => {
    e.preventDefault();
  }


  // Function to handle document upload
  const handleDocumentUpload = async (data) => {
    openBackdrop()
    try {
      const fd = new FormData();
      fd.append(`files`, data.files);
      fd.append("name", data.fileName)
      fd.append("docType", data.type)
      fd.append("uploadedBy", auth.roles)

      await privateAxios.post(`/technicalSpecification/uploadProductDocuments/${productDetails.id}`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      getDataProcure();
      handleAddDocumentModal();
      showToast("success", "Document Uploaded")

    } catch (error) {
      showToast("error", "Error Uploading Document")

    }
    closeBackdrop();
  }



  // Function to handle downloading the revision as an Excel file
  const handleDownloadRevision = () => {
    // extracting text from Richeditor string
    let tempElement = document.createElement('div');
    tempElement.innerHTML = specificationValue;
    let extractedText = tempElement.textContent;
    extractedText = extractedText.trim();

    // taking nessarry feilds from attributes
    const Attributes = tableRows.map((attri) => {
      const modifiedAttri = { ...attri };
      delete modifiedAttri.id;
      if (revisionId !== 0) {
        delete modifiedAttri.revisedValue;
        delete modifiedAttri.vendorRemark;

      }
      const ttadditionsFields = [];
      attri.additionalFields.forEach((rem, index) => {

        ttadditionsFields[rem.columnName] = rem.columnValue;

      });
      delete modifiedAttri.additionalFields;
      return { ...modifiedAttri, ...ttadditionsFields };

    });
    let revisionDocument = []
    if (revisionId === "0") {

      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })

    }
    else {
      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })
    }


    createExcel([[{ Specification: extractedText }], Attributes, revisionDocument], (productDetails.name || "Revision 0"))
  }





  return (
    <>
      <Toast />
      <BackdropComponent />
      <div className={workSpaceStyle.workSpaceMain}>

        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1>
              <BackButton />
              {productDetails.name || "Not Name"}</h1>
            <p>#0{productDetails.id} {productDetails?.productName || "Default"}</p>

          </div>
          <div className={workSpaceStyle.workSpaceHeaderRight}>




            {!isEcomRevision &&
              <>



                {tabActive === "1" && <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative" }}>
                  <button onClick={() => { setMultiAddDropDown(prev => !prev) }}><AddIcon />Add</button>
                  {multiAddDropDown &&
                    <ul className={workSpaceStyle.workSpaceHeaderAddButtonDropDown}>
                      <li onClick={handleAddColumn}>Add Column</li>
                      <li onClick={handleAddRow}>Add Row</li>
                    </ul>
                  }

                </div>}
                {tabActive === "2" && revisionId !== "0" && <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative" }}>
                  <button onClick={handleAddDocumentModal}><AddIcon />Add</button>


                </div>}
                <div className={workSpaceStyle.multiSaveButton}>
                  <button onClick={handleMultiSave}>
                    Save
                    <span>
                      {multiSave
                        ? <KeyboardArrowUpIcon />
                        : <KeyboardArrowDownIcon />
                      }
                      {/*  */}
                    </span> </button>

                  {multiSave &&
                    <ul>
                      <li onClick={() => { handleSaveRevision(); setMultiSave(false); }}>Update & Save</li>
                      {/* <li onClick={() => { setMultiSave(false); handleRenameModal(); setSaveAs(true) }}>Save as </li> */}
                    </ul>
                  }



                </div>
              </>

            }
            <div className={workSpaceStyle.workSpaceThreeDotVertical}>

              <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={() => setworkSpaceHeaderRevisionMore(prev => !prev)} style={{ position: "relative" }}>
                <Tooltip title='more'><MoreVertIcon /></Tooltip>
                {
                  workSpaceHeaderRevisionMore &&
                  <ul>
                    {/* <li onClick={handleApprovalModal}> <span><PersonAddAltOutlinedIcon /></span>Send for Approval</li> */}
                    {/* <li onClick={handleRenameModal}> <span><DriveFileRenameOutlineIcon /></span> Rename</li> */}
                    {/* <li onClick={handleDuplicateNameModal}><span><ContentCopyIcon /></span>Duplicate</li> */}
                    <li onClick={handleDownloadRevision}><span>
                      <FileDownloadOutlinedIcon />
                    </span>Download</li>
                    {/* <li onClick={handleDeleteModal}><span><DeleteOutlineIcon /></span>Delete</li> */}
                  </ul>
                }
              </span>
            </div>
            <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
              <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
            </div>
          </div>

        </div>


        <div className={revisionEditStyle.tabHeaderMain}>
          <div
            onClick={() => handleTabChange("0")}
            className={tabActive === "0" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Specification</div>
          <div
            onClick={() => handleTabChange("1")}
            className={tabActive === "1" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Attributes</div>
          <div
            onClick={() => handleTabChange("2")}
            className={tabActive === "2" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Documents</div>
        </div>

        <div className={revisionEditStyle.tabBodyMain} >

          {tabActive === "0" && <SpecificationTab specificationValue={specificationValue} setSpecificationValue={setSpecificationValue} handelSpecification={handelSpecification} handleSaveRevison={"sa"} />}
          {tabActive === "1" && <AttributeTab handleColumnDelete={handleColumnDelete} handleColumnNameChange={handleColumnNameChange} duplicateError={duplicateError} handleRowDelete={handleRowDelete} handleAddColumn={handleAddColumn} handleAddRow={handleAddRow} columns={columns} setColumns={setColumns} tableRows={tableRows} setTableRows={setTableRows} />}
          {tabActive === "2" && <DocumentsTab documents={documents} setDocuments={setDocuments} addDocumentModal={addDocumentModal} handleAddDocumentModal={handleAddDocumentModal} revisionId={revisionId} handleDocumentUpload={handleDocumentUpload} getDataProcure={getDataProcure} />}

        </div>

      </div>
      {/* rename modal */}
      <Modal
        open={renameModal}
        onClose={handleRenameModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={handleChangeRevisionName} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              {/* <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><PersonAddAltOutlinedIcon /></span>Rename</h3> */}
              <div onClick={handleRenameModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>

              <input type="text" value={renameText} onChange={(e) => setRenameText(e.target.value)} placeholder='Revision Name' />
              {/* <span>{formErrors.accessRole}</span>  */}
              {/* <span>{formError.workSpace}</span> */}
            </div>



            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>

              <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>


              <p onClick={() => { setRenameText(""); handleRenameModal() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

            {/* <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span> */}
          </form>

        </div>
      </Modal>

      {/* end rename modal */}








    </>
  )
}

export default ProductDetails


/**
 * RevisionEdit.js
 * 
 * This is the Revision Edit component, responsible for rendering User Prespective  of Revision Edit. 
 * 
 *
 * Author: Ravinder Kumar
 *   
 * 
 * Created on: 2023-08-29
 * 
 * 
 * Last Modified on: 2023-09-28
 */