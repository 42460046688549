import React from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import chatHeaderStyle from './ChatHeader.module.css'
import { createExcel } from 'utility/excelUtils'


const ChatHeader = ({chatName,children,onClose,downloadData}) => {

  return (
    <div className={chatHeaderStyle.chatHeaderMain}>
      {children}
      <div>
        <span onClick={()=>createExcel([downloadData],chatName)}><Tooltip title="Download chat"><DownloadIcon/></Tooltip></span>
        <span><Tooltip title="Help">
          <HelpOutlineIcon/>
          </Tooltip></span>
        <span onClick={onClose}><Tooltip title="Close"><CloseIcon/></Tooltip></span>
      </div>
    </div>
  )
}

export default ChatHeader
