import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import revisionEditStyle from 'pages/RevisionEdit/RevisionEdit.module.css'
import useBackdrop from 'hooks/useBackdrop'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatRow from 'components/ChatRows/ChatRow';
import { createExcel } from 'utility/excelUtils'
import SendForApprovalModal from 'components/SendForApprovalModal/SendForApprovalModal';
// =======================icons===========================
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
// =======================icons===========================

// =====================mui compoents====================
import Tooltip from '@mui/material/Tooltip';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import useToast from 'hooks/useToast';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import BackButton from 'components/BackButton/BackButton';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';
import RenameModal from 'components/RenameModal/RenameModal';
import DescriptionTab from 'components/CommercialSpecificationTabs/DescriptionTab/DescriptionTab';
import QuestionTab from 'components/CommercialSpecificationTabs/QuestionTab/QuestionTab';
import DocumentsTab from 'components/CommercialSpecificationTabs/DocumentTab/DocumentTab';
import SendToVendorModal from 'components/SendToVendorModal/SendToVendorModal';



// =====================mui compoents====================

const CommercialTempleteView = () => {

  // Importing necessary hooks and components
  const { showToast, Toast } = useToast(); // Toast notification
  const privateAxios = PrivateComponent(); // Axios instance for private requests
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Backdrop for loading state
  const location = useLocation(); // Location hook for getting the current URL
  const { state } = location; // State from location
  const { taskId, tempId, templeteName, revisionId, productId } = useParams(); // Params from URL
  const navigate = useNavigate(); // Navigation hook
  const { auth } = useAuth(); // Authentication hook
  const [revisionDetails, setRevisionDetails] = useState(null); // State for revision details
  const [isDefaultRevision, setIsDefaultRevision] = useState(false); // State for default revision
  const [viewType, setViewType] = useState("");  // State for view type


  // =======================set View Type==============================

  // Set view type based on location state
  useEffect(() => {
    if (state) {
      setViewType(state.from)
    }
  }, [state])


  // =========================Back Button=============================


  // Handle back button click
  const handleBackButton = () => {
    const currentPathname = window.location.pathname;
    const lastSlashIndex = currentPathname.lastIndexOf('/');
    const previousPathname = currentPathname.substring(0, lastSlashIndex);
    navigate(-1);
  }

  // ==========================tab selector and state======================

  // Tab selector and state
  const searchParams = new URLSearchParams(location.search);
  const [tabActive, setTabActive] = useState("0");
  useEffect(() => {
    if (searchParams.get("tab") !== undefined && searchParams.get("tab") !== null) {
      setTabActive(searchParams.get("tab"));
    }
  }, [Location, searchParams])

  // Change active tab
  const handleTabChange = (index) => {
    setTabActive(index)
    navigate(`?tab=${index}`, { replace: true })
  }


  // ==========================header three dot==============================

  // State for header three dots menu
  const [headerThreeDot, setHeaderThreeDot] = useState(false)
  const handleheaderThreeDot = () => {
    setHeaderThreeDot(prev => !prev);
  }

  // =============================multisave ==================================

  // State for multisave
  const [multiSave, setMultiSave] = useState(false);
  const handleMultiSave = () => {
    setMultiSave(prev => !prev)
  }

  // ================================chat state ============================

  // State for chat
  const [chatSlider, setChatSlider] = useState(false);
  const [revisionChats, setRevisionChats] = useState([]);
  const [chatValue, setChatValue] = useState("");


  // Toggle chat slider
  const handleChatSlider = () => {
    setChatSlider(prev => !prev);
  }

  // Send chat message
  const handleChatSend = async (e) => {
    e.preventDefault();
    if (chatValue !== "") {
      try {
        const res = await privateAxios.post(`/commercial/addRemarkOncommercialRevision/${auth.userData.id}/${revisionId}`, { remark: chatValue })
        if (res.status === 200) {
          getRevisionChat();
          setChatValue("")
        }
      } catch (error) {
        showToast("error", "error adding Comment")

      }
    }

  }

  // Get revision chat messages
  const getRevisionChat = async () => {
    handleChatNotification();
    try {
      const res = await privateAxios.get(`/commercial/getRemarksOfcommercialRevision/${revisionId}`)
      setRevisionChats(res.data);
    } catch (error) {
      showToast("error", "error getting Chats");
    }
  }

  useEffect(() => {
    if (chatSlider) {
      getRevisionChat();
    }
  }, [chatSlider])


  // ==================rename  state =================

  // State for rename modal
  const [renameText, setRenameText] = useState("");
  const [saveAs, setSaveAs] = useState(false);
  const [renameModal, setRenameModal] = useState(false);


  // Toggle rename modal
  const handleRenameModal = () => {
    setRenameModal(prev => !prev)
  }

  // State for save as modal
  const [saveAsModal, setSaveAsModal] = useState(false);
  const handleSaveAsModal = () => {
    setSaveAsModal(prev => !prev)
  }

  // State for vendor modal
  const [vendorModal, setVendorModal] = useState(false);
  const handleSendToVendorModal = (data) => {
    setVendorModal(prev => !prev)
  }

  // State for reassign modal
  const [reassignModal, setReassignModal] = useState(false);
  const handleReassignModal = () => {
    setReassignModal(prev => !prev)
  }

  // ============document states=========================


  // State for document upload
  const [documents, setDocuments] = useState([]);
  const [addDocumentModal, setAddDocumentModal] = useState(false);

  // Toggle add document modal
  const handleAddDocumentModal = () => {
    setAddDocumentModal(prev => !prev)
  }

  // Function to handle document upload
  const handleDocumentUpload = async (data) => {

    // Open backdrop to indicate loading
    openBackdrop()
    try {
      // Create a new FormData object
      const fd = new FormData();
      // Append data to FormData object
      fd.append(`files`, data.files);
      fd.append("name", data.fileName)
      fd.append("docType", data.type)
      fd.append("uploadedBy", auth.roles)

      // Send a POST request with Axios
      const res = await privateAxios.post(`/commercial/uploadCommercialDocuments/${revisionId}`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })

      // After successful upload, fetch updated data
      await getDataProcure();
      // Close the document modal
      handleAddDocumentModal();
      // Show success toast message
      showToast("success", "Document Uploaded Successfully");
    } catch (error) {
      // If there's an error, handle it
      handleAddDocumentModal();
      // Show error toast message
      showToast("error", "Error Uploading Document");
    }
 // Close backdrop after execution
    closeBackdrop();

  }

// State variables initialization
  const [revisionDescription, setRevisionDescription] = useState("");
  const [questionRows, setQuestionRows] = useState([]);

  // Function to add a new question row
  const handleAddQuestionRow = () => {
        // Create a new row object with incremented id
    const newRow = {
      id: questionRows[questionRows.length - 1].id + 1,
      name: "",
      unit: "text",
      value: "",
      remark: "",
      vendorRemark: "",
      expectdAnswer: ""

    }

       // Update questionRows state
    setQuestionRows((prev) => {
      return [
        ...prev,
        newRow
      ]
    })
  }

  // Function to delete a question row by id
  const handleQuestionRowDelete = (id) => {
        // Filter out the row with the given id
    setQuestionRows((prev) => {
      return prev.filter((row) => row.id !== id)
    })

  }

  // Function to handle input change in a question row
  const handleInputChange = (e, rowIndex) => {
    const { value, name } = e.target;
    // Update the state with new value
    setQuestionRows((prev) => {
      return prev.map((row, index) => {
        if (index === rowIndex) {
           // Update specific row based on index and input name
          if (name === "unit") {
            return {
              ...row,
              [name]: value,
              expectdAnswer: value
            }
          }
          else {
            return {
              ...row,
              [name]: value,

            }
          }

        }
        else {
          return row;
        }
      })
    })

  }

  // ===================get data================

// Function to fetch data related to Ecom
  const getDataEcom = () => {

    try {
     // Set initial revision details
      setRevisionDetails({
        name: "com rev 2",
        productId: productId,
        productName: "SCREENS AND VIBRO FEEDERS",
        description: {
          name: "size"
        },
        questions: [
          {
            id: 1,
            name: "Some Question",
            unit: "date",
            value: "Some Value",
            remark: "Sosadsa dsa fa faf",
            vendorRemark: "",
            expectdAnswer: ""

          },
          {
            id: 2,
            name: "Some Question2",
            unit: "text",
            value: "Some Value2",
            remark: "Sosadsa dsa fa faf2",
            vendorRemark: "",
            expectdAnswer: ""
          },

        ],
        documents: [
          {
            name: "doc confidential",
            url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            docType: "CONFIDENTIAL"
          },
          {
            name: "doc normal",
            url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            docType: "NORMAL"
          }

        ]
      })

 // Set initial question rows
      setQuestionRows([
        {
          id: 1,
          name: "Some Question",
          unit: "date",
          value: "Some Value",
          remark: "Sosadsa dsa fa faf",
          vendorRemark: "",
          expectdAnswer: ""

        },
        {
          id: 2,
          name: "Some Question2",
          unit: "text",
          value: "Some Value2",
          remark: "Sosadsa dsa fa faf2",
          vendorRemark: "",
          expectdAnswer: ""
        },

      ])

         // Set initial documents
      setDocuments([
        {
          name: "doc confidential",
          url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          docType: "CONFIDENTIAL"
        },
        {
          name: "doc normal",
          url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          docType: "NORMAL"
        }

      ])
 // Set initial revision description
      setRevisionDescription("size")
// Handle errors
    } catch (error) {

    }
  }

  // Function to fetch data related to Procure
  const getDataProcure = async () => {
      // Open backdrop to indicate loading
    openBackdrop();
    try {
        // Send GET request to fetch data
      const res = await privateAxios.get(`/commercial/getCommercialRevisionByRevisionIdAndProductId/${revisionId}/${productId}`)

      const { data } = res;
      // Set revision details
      setRevisionDetails({
        name: data.name,
        productId: data.productId,
        productName: data.productName,
        description: {
          name: data?.description?.name
        },
        questions: data.questions.map((ques) => {
          return {
            ...ques,
            unit: ques.unit === null || ques.unit === undefined || ques.unit === ""
              ?
              "TEXT"
              :
              ques.unit === 'days'
                ?
                "DATE"
                :
                ques.unit.toUpperCase()
          }
        }),
        documents: data.documents,
        default: data.default,
        revisionStatus: data.revisionStatus,
      });
        // Set question rows
      setQuestionRows(data.questions.map((ques) => {
        return {
          ...ques,
          unit: ques.unit === null || ques.unit === undefined || ques.unit === "" ? "TEXT" : ques.unit === 'days' ? "DATE" : ques.unit.toUpperCase()
        }
      }))
      setIsDefaultRevision(data.default)
      setDocuments(data.documents)
      setRevisionDescription(data.description.name)

    } catch (error) {
      console.log("Error", error);
    }
      // Close backdrop after execution
    closeBackdrop();
  }

// Initialize data fetching on component mount
  useEffect(() => {
    getDataProcure();
  }, [])

  const deleteAllDuplicateRows = () => {
    // const ids = handleAttributeValidation();
    // if (ids.length > 0) {

    //     const newRows = tableRows.filter((item) => !ids.includes(item.id));
    //     setTableRows(newRows);
    // }
  }

  // ===================get data================

  // =======================question rows Validation===========================

// State variable for handling duplicate errors
  const [duplicateError, setDuplicateError] = useState([]);

  // Function to validate questions
  const handleQuestinValidation = () => {
    const hasNullName = questionRows.some(rr => rr.name === null || rr.name === undefined || rr.name === "");
    if (hasNullName) {
      alert("please check Attribute Name No Name can be empty");
      return false;
    }
    else {
      const nameOccurrences = {};
      const duplicateNamesId = [];
      questionRows.forEach((item) => {
        const name = item.name;
        // Check if the name has been encountered before
        if (nameOccurrences[name]) {
          // If it has, mark it as a duplicate
          duplicateNamesId.push(item.id);
        } else {
          // Otherwise, record it in the occurrences object
          nameOccurrences[name] = true;
        }
      });
      if (duplicateNamesId.length > 0) {
        // console.log(duplicateError)
        setDuplicateError(duplicateNamesId)
        alert("Please Remove Duplicate Attributes");
        return false;
      }
      else {
        setDuplicateError([]);
        return true;
      }

    }

  }


  // ===================saveas revisions================
// Function to handle saving revisions
  const handleSaveAsRevision = async (temp) => {
    openBackdrop();

    if (handleQuestinValidation()) {
      try {
 // Prepare payload for saving revision
        const updatedQuestions = questionRows.map((ques) => {
          let que = ques;
          delete que.id;
          if (que.unit === null || que.unit === undefined || que.unit === "") {
            que.unit = "TEXT"
          }
          return que;
        })
        const updatedDocuments = documents.map((doc) => {
          let dd = doc;
          delete dd.id;
          return dd;
        })
        let payload = {}
        if (revisionId === 0) {
          payload = { ...revisionDetails, name: temp.name, description: { name: revisionDescription }, questions: updatedQuestions }

        }
        else {
          payload = { ...revisionDetails, name: temp.name, description: { name: revisionDescription }, documents: updatedDocuments, questions: updatedQuestions }

        }
   // Send POST request to add commercial revision
        const res = await privateAxios.post(`/commercial/addCommercialRevision/${auth.orgId}/${taskId}/${tempId}`, payload)
        handleSaveAsModal();
        showToast("success", "Revision Saved")
        handleBackButton();
        closeBackdrop();

      } catch (error) {
        console.log(error);
        showToast("error", "Error Saving Revision")
        handleSaveAsModal();
        closeBackdrop();
      }

    }
    else {
      closeBackdrop();
    }
  }

  // ==================save Revisions========================
// Function to handle saving revisions
  const handleSaveRevision = async () => {
    openBackdrop();
    if (handleQuestinValidation()) {
      try {
  // Prepare payload for saving revision
        const updatedQuestions = questionRows.map((ques) => {
          let que = ques;
          delete que.id;
          if (que.unit === null || que.unit === undefined || que.unit === "") {
            que.unit = "TEXT"
          }
          return que;
        })
        const updatedDocuments = documents.map((doc) => {
          let dd = doc;
          delete dd.id;
          return dd;
        })
        const payload = { name: revisionDetails.name, description: { name: revisionDescription }, documents: updatedDocuments, questions: updatedQuestions }
          // Send POST request to update commercial revision
        const res = await privateAxios.post(`/commercial/updateCommercialRevision/${revisionId}`, payload)
        const { data } = res;
        console.log(data);

        showToast("success", "Revision Saved")
        getDataProcure();
        closeBackdrop();

      } catch (error) {
        showToast("error", "Error Saving Revision")

        closeBackdrop();
      }
    }
    else {
      closeBackdrop();
    }
  }

  // =============change revision Name==============================

  // Function to handle renaming commercial revision
  const handleChangeTempleteName = async (data) => {

    openBackdrop();
    try {
      const res = await privateAxios.post(`/commercial/renameCommercialRevision/${revisionId}?name=${data.name}`)
      if (res.status == 200) {

        getDataProcure();
        showToast("success", "Name Update Successfull");
        handleRenameModal();
      }
    } catch (error) {
      console.log(error);
      showToast("error", "Name Update failed");

    }
    closeBackdrop();
  }

  // ============duplicate revision==============================

  // State variables for managing duplicate revision modal
  const [duplicateNameModal, setDuplicateNameModal] = useState(false);
  const [duplicateName, setDuplicateName] = useState("");
  // Function to toggle duplicate revision modal
  const handleDuplicateNameModal = (e) => {
    setDuplicateNameModal(prev => !prev);
    setDuplicateName(revisionDetails?.name || "");
  }

  // Function to handle duplicating commercial revision
  const handleDuplicateRevisison = async (data) => {
    openBackdrop();
    try {
      const res = await privateAxios.post(`/commercial/duplicateCommercialRevision/${revisionId}?name=${data.name}`);
      showToast("success", "Duplicate Revision Created");
      handleBackButton();
      handleDuplicateNameModal();
    } catch (error) {
      showToast("error", "Error Creating Revision Duplicate");
      handleDuplicateNameModal();
    }
    closeBackdrop();
  }
  // ================delete Revision================================

  // State variable for managing delete modal
  const [deleteModal, setDeleteModal] = useState(false);
  // Function to toggle delete modal
  const handleDeleteModal = () => {
    setDeleteModal(prev => !prev)
  }

  // Function to handle deleting commercial revision
  const handleDeleteRevision = async () => {
    try {
      const res = privateAxios.delete(`/commercial/deleteCommercialRevision/${revisionId}`);
      console.log(res.data);
      handleBackButton();
    } catch (error) {
      showToast("error", "Error deleting Revision");
    }
  }

  // =================download revision==============================

  // Function to handle downloading revision
  const handleDownloadRevision = () => {

    // extracting TEXT from Richeditor string
    let tempElement = document.createElement('div');
    tempElement.innerHTML = revisionDescription;
    let extractedText = tempElement.textContent;
    extractedText = extractedText.trim();

    // taking nessarry feilds from attributes
    const questions = questionRows.map((attri) => {
      const modifiedAttri = { ...attri };
      delete modifiedAttri.id;
      if (revisionId !== 0) {
        delete modifiedAttri.revisedValue;
        delete modifiedAttri.vendorRemark;

      }
      return { ...modifiedAttri };

    });
    // console.log(tableRows)
    // console.log(Attributes)
    // documents
    let revisionDocument = []
    if (revisionId === "0") {

      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })

    }
    else {
      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })
    }


    createExcel([[{ description: extractedText }], questions, revisionDocument], (revisionDetails.name || "Revision 0"))
  }


  // ==============send to Kam=====================================

  const [approvalModal, setApprovalModal] = useState(false);
  const handleApprovalModal = (data) => {
    setApprovalModal(prev => !prev)
  }

  // Function to handle sending the revision for approval to KAM
  const handleAppovalSubmit = async (data) => {



    try {
      await privateAxios.post(`/commercial/sendCommercialRevisionForApprovalToKAM/${auth.userData.id}/${revisionId}`, { comments: data.name }).then((res) => {
        handleApprovalModal()
        showToast("success", "Approval Sent To KAM")
      }).catch((err) => {

        showToast("error", "Error Sending Revision to Kam")
      })
    } catch (error) {
      // console.log(error);
      showToast("error", "Error Sending Revision to Kam")
    }
  }

  // =====================chat notification========================

  // State variable for managing chat notification
  const [reRender, setReRender] = useState(false);

  // Function to toggle chat notification
  const handleChatNotification = () => {
    setReRender(prev => !prev);

  }


  // ======================Decision Handle============================

  // Function to handle decision on revision by KAM
  const handleDecisionOnRevision = async (isApproval) => {
    openBackdrop();
    try {

      if (isApproval) {
        const response = await privateAxios.post(`/technicalSpecification/approveRevisionByKam/${revisionId}`);
        if (response.status === 200) {
          alert("Revision Approved Successfully!")
        }
      }
      else {
        const response = await privateAxios.post(`/technicalSpecification/rejectRevisionByKam/${revisionId}`);
        if (response.status === 200) {
          alert("Revision Rejected!")
        }
      }

      getDataProcure();
      closeBackdrop();
    } catch (error) {
      console.error('Error fetching vendors:', error);
      closeBackdrop();
    }
  }


  // ======================Decision Handle============================

  // ====================Send Revision to vendor ===========================

  // Function to handle sending revision for approval to vendor
  const handleSendToVendor = async (data) => {


    openBackdrop()
    try {
      const res = await privateAxios.post(`/technicalSpecification/sendRevisionForApprovalToVendor/${auth.userData.id
        }/${revisionId}`, {
        emails: data.emails,
        comments: data.comments,
        dueDate: data.dueDate,
      })
      showToast("success", "Revision Sent to Vendor")
      handleSendToVendorModal()
      closeBackdrop();
    } catch (error) {
      // console.log(error);
      showToast("error", "Error while sending Revision to Vendor")
      closeBackdrop();
    }


  }

  // ====================Send Revision to vendor ===========================



  return (
    <>
      <BackdropComponent />
      <Toast />

      <div className={workSpaceStyle.workSpaceMain}>

        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1><BackButton />{revisionDetails?.name || "No Name"}</h1>
            <p>{revisionDetails?.productName || "Dafault"}</p>

          </div>

          <div className={workSpaceStyle.workSpaceHeaderRight}>

            {
              (viewType === "APVIEW" && auth.roles === "KAM") &&

              <div className={workSpaceStyle.workSpaceHeaderDecisionButton}>
                {
                  revisionDetails?.revisionStatus === "PENDING"
                    ?
                    <>
                      <button className={workSpaceStyle.workSpaceHeaderDecisionApprovalBtn} onClick={() => { handleDecisionOnRevision(true) }}><span><CheckIcon /></span> Approve</button>
                      <button className={workSpaceStyle.workSpaceHeaderDecisionRejectBtn} onClick={() => { handleDecisionOnRevision(false) }}><span><CloseIcon /></span>Reject</button>
                    </>
                    :
                    revisionDetails?.revisionStatus === "APPROVED" ?
                      <button className={workSpaceStyle.workSpaceHeaderDecisionApprovalBtn}><span><CheckIcon /> </span>Approved</button>

                      :
                      <button className={workSpaceStyle.workSpaceHeaderDecisionRejectBtn} ><span><CloseIcon /></span>Rejected</button>


                }
              </div>
            }


            {(tabActive === "2" && !isDefaultRevision) && <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative" }}>
              <button onClick={handleAddDocumentModal}><AddIcon />Add</button>


            </div>}
            <div className={workSpaceStyle.multiSaveButton}>
              <button onClick={handleMultiSave}>
                Save
                <span>
                  {multiSave
                    ? <KeyboardArrowUpIcon />
                    : <KeyboardArrowDownIcon />
                  }
                  {/*  */}
                </span> </button>

              {multiSave &&
                <ul>
                  {!revisionDetails?.default && <li onClick={() => { setMultiSave(false); handleSaveRevision(); }}>Update & Save</li>}
                  <li onClick={() => { setMultiSave(false); handleSaveAsModal(); }}>Save as </li>
                </ul>
              }



            </div>




            {!revisionDetails?.default &&
              <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={handleChatSlider}>
                <Tooltip title='Comments'>
                  <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialRevision`} id={revisionId} reRender={reRender} />

                </Tooltip>
              </span>
            }
            <div className={workSpaceStyle.workSpaceThreeDotVertical} style={{ position: "relative" }}>

              <span className={workSpaceStyle.workSpaceHeaderRevisionMore}
                onClick={handleheaderThreeDot}
              >
                <Tooltip title='more'><MoreVertIcon /></Tooltip>

              </span>
              {
                headerThreeDot
                &&
                <ThreeDotDropDown toggle={handleheaderThreeDot}>
                  {!isDefaultRevision &&
                    <>
                      {
                        viewType === "PRVIEW" ?
                          <li onClick={() => { handleheaderThreeDot(); handleApprovalModal(); }}>
                            <span><PersonAddAltOutlinedIcon /></span>Send for Approval</li>
                          :
                          (viewType === "APVIEW" && auth.roles === "ADMIN") ?
                            <li onClick={() => { handleheaderThreeDot(); handleApprovalModal(); }}>
                              <span><PersonAddAltOutlinedIcon /></span>Send for Approval</li>
                            :

                            <li onClick={() => { handleheaderThreeDot(); handleSendToVendorModal(); }}>
                              <span><PersonAddAltOutlinedIcon /></span>Send to Vendor</li>

                      }


                      {
                        (viewType === "APVIEW" && auth.roles !== "USER") &&

                        <li onClick={() => { handleheaderThreeDot(); handleReassignModal() }} >
                          <span><PersonOutlinedIcon /> </span>Re-assign
                        </li>
                      }
                      <li onClick={() => { handleheaderThreeDot(); handleRenameModal() }}>
                        <span><DriveFileRenameOutlineIcon /></span> Rename
                      </li>
                      <li onClick={() => { handleheaderThreeDot(); handleDuplicateNameModal() }}>
                        <span><ContentCopyIcon /></span>Duplicate</li>
                      <li onClick={() => { handleheaderThreeDot(); handleDeleteModal(); }}>
                        <span><DeleteOutlineIcon /></span>Delete</li>
                    </>
                  }
                  <li onClick={() => { handleheaderThreeDot(); deleteAllDuplicateRows() }}>
                    <span><DriveFileRenameOutlineIcon /></span>
                    Delete Duplicate Entry
                  </li>
                  <li onClick={() => { handleheaderThreeDot(); handleDownloadRevision(); }}><span>
                    <FileDownloadOutlinedIcon />
                  </span>Download</li>


                  {/* {!revisionDetails.default &&
                    <>

                      <li
                        onClick={() => { handleheaderThreeDot(); handleApprovalModal() }}
                      > <span><PersonAddAltOutlinedIcon /></span>Send for Approval</li>
                      <li
                        onClick={() => { handleRenameModal(); handleheaderThreeDot(); setSaveAs(false) }}
                      > <span><DriveFileRenameOutlineIcon /></span> Rename</li>
                      <li

                        onClick={() => { handleheaderThreeDot(); handleDuplicateNameModal(); }}
                      ><span><ContentCopyIcon /></span>Duplicate</li>

                    </>
                  }
                  <li
                    onClick={() => { handleDownloadRevision(); handleheaderThreeDot() }}
                  ><span><FileDownloadOutlinedIcon /></span>Download</li>
                  {revisionId !== "0" &&
                    <li
                      onClick={() => { handleheaderThreeDot(); handleDeleteModal(); }}
                    ><span><DeleteOutlineIcon /></span>Delete</li>

                  } */}
                </ThreeDotDropDown>

              }
            </div>
            <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
              {auth.roles === 'USER' && (
                <Tooltip title='Help'>
                  <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081392-commercial-revision-work">
                    <HelpOutlineOutlinedIcon />
                  </a>
                </Tooltip>
              )}
            </div>
          </div>

        </div>

        <div className={revisionEditStyle.tabHeaderMain}>
          <div
            onClick={() => handleTabChange("0")}
            className={tabActive === "0" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Description</div>
          <div
            onClick={() => handleTabChange("1")}
            className={tabActive === "1" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Questions</div>
          <div
            onClick={() => handleTabChange("2")}
            className={tabActive === "2" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Documents</div>
        </div>

        <div className={revisionEditStyle.tabBodyMain} >
          {tabActive === "0" && <DescriptionTab
            onChange={setRevisionDescription} value={revisionDescription}
          />}
          {tabActive === "1" && <QuestionTab
            viewType={""}
            tableRows={questionRows}
            duplicateRow={duplicateError}
            setTableRows={setQuestionRows}
            showToast={showToast}
          // questionRows={questionRows}
          // handleInputChange={handleInputChange}
          // handleAddQuestionRow={handleAddQuestionRow}
          // handleQuestionRowDelete={handleQuestionRowDelete}

          />}
          {tabActive === "2" && <DocumentsTab

            isDefaultRevision={isDefaultRevision}
            viewType={""}
            showToast={showToast}
            addDocumentModal={addDocumentModal}
            handleAddDocumentModal={handleAddDocumentModal}
            getDataProcure={getDataProcure}
            documents={documents}
            revisionId={revisionId}
          />}

        </div>



      </div>


      <RenameModal
        open={renameModal}
        onClose={handleRenameModal}
        onSubmit={handleChangeTempleteName}
        prefilled={revisionDetails?.name}
        header="Rename"
      />
      <RenameModal
        open={saveAsModal}
        onClose={handleSaveAsModal}
        onSubmit={handleSaveAsRevision}
        prefilled={revisionDetails?.name}
        header="Save As"
      />
      <RenameModal
        open={duplicateNameModal}
        onClose={handleDuplicateNameModal}
        onSubmit={handleDuplicateRevisison}
        prefilled={revisionDetails?.name}
        header="Duplicate"
      />



      {/* duplicate Revision */}

      {/* chat pannel */}

      <ChatPannel
        open={chatSlider}
        onClose={handleChatSlider}
      >
        <>
          <ChatHeader onClose={handleChatSlider}
            downloadData={revisionChats.map((chat) => { return { name: chat.name, date: chat.createdAt, chat: chat.remark } })
            }
            helpData={{ link: "tosomewhere" }}
            chatName={revisionDetails?.name}
          >
            <h3>{revisionDetails?.name}</h3>
          </ChatHeader>

          <ChatRows>
            {/* <ChatRow name="Ravinder" chat="soemfhdf a saf sa" date="12-04-2023" index="1"/> */}
            {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
              return (

                <ChatRow key={index} name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} email={rev.email} />
              )
            })
              :
              <p style={{ textAlign: "center" }}>
                No Previous Chat
              </p>
            }

          </ChatRows>

          <ChatInputField onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

        </>
      </ChatPannel>

      {/* end chat pannel */}


      {/* delete Confirmaton */}


      <DeleteConfirmation
        onCancel={handleDeleteModal}
        onConfirm={() => { handleDeleteRevision(); handleDeleteModal() }}
        open={deleteModal}
      // confimBtnText={""} 
      // discardText={""}
      >
        <h2>Delete Revision</h2>
        <p>Are you sure you want to delete this Revision?</p>

      </DeleteConfirmation>

      <SendForApprovalModal
        open={approvalModal}
        onClose={handleApprovalModal}
        onSubmit={handleAppovalSubmit}
        prefilled={""}
        header="Send For Approval"
      />

      <SendToVendorModal
        open={vendorModal}
        onClose={handleSendToVendorModal}
        header={"Send to Vendor"}
        onSubmit={handleSendToVendor}
      />




    </>
  )
}

export default CommercialTempleteView
