import React, { useState, useEffect } from 'react'
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import revisionEditStyle from '../../pages/RevisionEdit/RevisionEdit.module.css'
import useBackdrop from 'hooks/useBackdrop'
import ProductRevisonsStyle from '../ProductRevisions/ProductRevisions.module.css';
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CompareQuestionsTab from './CompareQuestionsTab'
import CompareDocumentsTab from './CompareDocumentsTab'
import CompareDescriptionTab from './CompareDescriptionTab'
import { createExcel } from 'utility/excelUtils'
// =======================icons===========================


import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import ApprovalRevisionTable from '../../components/ApprovalRevisionTable/ApprovalRevisionTable';
import BarChartIcon from '@mui/icons-material/BarChart';

// =====================mui compoents====================
import Tooltip from '@mui/material/Tooltip';
import { Typography, Button, Badge } from '@mui/material';
import Modal from '@mui/material/Modal';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import useToast from 'hooks/useToast';
import BackButton from 'components/BackButton/BackButton';
import SendToApprovalCommityModal from 'components/SendToApprovalCommityModal/SendToApprovalCommityModal';
import CompareRankingModal from 'components/CompareRankingModal/CompareRankingModal';

const CommericalRevisionCompare = () => {
  // Importing custom hooks for toast notifications and backdrop components
  const { showToast, Toast } = useToast();
  const privateAxios = PrivateComponent();
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop()

  // Importing hooks for navigation and location 
  const location = useLocation();
  const { state } = location;
  const { revisionId } = useParams();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [compareHeaderData, setCompareHeaderData] = useState("");
  const [workSpaceHeaderRevisionMore, setworkSpaceHeaderRevisionMore] = useState(false);


  // ================Back Button========
  // Handler for the back button
  const handleBackButton = () => {
    navigate(-1);
  }

  // ==============tab selector and state=================

  // State and effect for tab selection
  const searchParams = new URLSearchParams(location.search);
  const [tabActive, setTabActive] = useState("0");
  useEffect(() => {
    if (searchParams.get("tab") !== undefined && searchParams.get("tab") !== null) {

      setTabActive(searchParams.get("tab"));
    }
  }, [Location, searchParams])


  // Handler for changing tabs
  const handleTabChange = (index) => {
    setTabActive(index)
    navigate(`?tab=${index}`, { replace: true })
  }


  // =============header three dot=============

  // State and handler for three-dot menu in the header
  const [headerThreeDot, setHeaderThreeDot] = useState(false)
  const handleheaderThreeDot = () => {
    setHeaderThreeDot(prev => !prev);
  }

  // =================multisave ================

  // State and handler for multi-save feature
  const [multiSave, setMultiSave] = useState(false);

  const handleMultiSave = () => {
    setMultiSave(prev => !prev)
  }

  // ===================Question State========================

  // State for storing questions and columns data
  const [questionRows, setQuestionRows] = useState([]);
  const [columns, setColumns] = useState([]);



  // ===================== Description State==============

  // State for storing revision description
  const [revisionDescription, setRevisionDescription] = useState("");



  //  ===============docuemnt states========================

  // State for storing documents
  const [documents, setDocuments] = useState([]);


  // ===================get data================

  // Function to check count based on item rules
  const CheckCount = (item) => {
    let count = {
      satisfied: 0,
      unstatisfied: 0,
      neutral: 0,
    };

    // Handle neutral cases
    if (!item || !item.unit) {
      count.neutral = 1;
      return count;
    }

    switch (item.unit) {
      case 'NUMBER':
        const revisedValue = parseFloat(item.revisedValue);
        const value = parseFloat(item.value);
        switch (item.ruleAttribute) {
          case 'LESS':
            if (revisedValue < value) count.satisfied = 1;
            else count.unstatisfied = 1;
            break;
          case 'MORE':
            if (revisedValue > value) count.satisfied = 1;
            else count.unstatisfied = 1;
            break;
          case 'EQUAL':
            if (revisedValue === value) count.satisfied = 1;
            else count.unstatisfied = 1;
            break;
          default:
            count.neutral = 1;
        }
        break;
      case 'RANGE':
        const range = item.value?.split("-").map(item => parseFloat(item.trim()));
        if (range && range.length === 2) {
          const revisedValue = parseFloat(item.revisedValue);
          if (revisedValue >= range[0] && revisedValue <= range[1]) count.satisfied = 1;
          else count.unstatisfied = 1;
        } else {
          count.neutral = 1;
        }
        break;
      case 'BOOLEAN':
        if (item.revisedValue?.trim() === item.value?.trim()) count.satisfied = 1;
        else count.unstatisfied = 1;
        break;
      case 'SINGLESELECT':
        if (item.revisedValue?.trim() === item.expectedAnswer?.trim()) count.satisfied = 1;
        else count.unstatisfied = 1;
        break;
      case 'DATE':
        const revisedDate = new Date(item.revisedValue);
        const originalDate = new Date(item.value);
        switch (item.ruleAttribute) {
          case 'LESS':
            if (revisedDate < originalDate) count.satisfied = 1;
            else count.unstatisfied = 1;
            break;
          case 'MORE':
            if (revisedDate > originalDate) count.satisfied = 1;
            else count.unstatisfied = 1;
            break;
          case 'EQUAL':
            if (revisedDate.getTime() === originalDate.getTime()) count.satisfied = 1;
            else count.unstatisfied = 1;
            break;
          default:
            count.neutral = 1;
        }
        break;
      default:
        count.neutral = 1;
    }
    return count;
  };


  // State for storing vendor rankings
  const [vendorRankings, setVendorRankings] = useState({});

  // Function to fetch data and process it
  const getDataProcure = async () => {
    openBackdrop();
    try {
      const response = await privateAxios.get(`/technicalSpecification/getCompareRevisionById/${revisionId}`);
      const { data } = response;
      // console.log(data);
      if (data) {
        // const cmpData=data[0]
        setCompareHeaderData(data[0]);
      }
      // const data = revisionsData;
      const questionArray = []
      data.slice(1).forEach((res, index) => {
        const questions = res.questions

        if (!questionArray[res.sentTo]) {
          questionArray[res.sentTo] = questions;
        }
        else {
          questionArray[`${res.sentTo.trim()}_${index}`] = questions;



        }


      })

      // console.log(questionArray);



      const groupedData = {};
      const vendorRanking = {}

      // Iterate through the data array
      Object.values(questionArray).forEach((dataArray, index) => {
        const vendorName = Object.keys(questionArray)[index];

        let count = {
          satisfied: 0,
          unstatisfied: 0,
          neutral: 0,
        }

        dataArray.forEach((item) => {

          const tempCount = CheckCount(item);

          count.satisfied = count.satisfied + tempCount.satisfied;
          count.unstatisfied = count.unstatisfied + tempCount.unstatisfied;
          count.neutral = count.neutral + tempCount.neutral;

          const updatedItem = {
            ...item,
            vName: vendorName
          }
          const itemName = item.name;

          // Check if the item name is already a key in the groupedData object
          if (!groupedData[itemName]) {
            // If not, create an empty array for that key
            groupedData[itemName] = [];
          }

          // Push the item into the corresponding array based on its name
          groupedData[itemName].push(updatedItem);
        });


        vendorRanking[vendorName] = count;




      });

      setVendorRankings(vendorRanking);


      const addedData = Object.values(groupedData).map((data) => {
        if (data.length < Object.values(questionArray).length) {
          let extracolumns = []
          for (let i = data.length; i < Object.values(questionArray).length; i++) {


            const rt = {

              id: (data[0].id + i),
              name: data[0].name,
              unit: data[0].unit,
              value: "N/A",
              remark: "N/A",
              vendorRemark: "N/A",
              vName: "N/A",
              expectedAnswer: data[0].expectedAnswer,
              vendorRemark: data[0].vendorRemark,
              ruleAttribute: data[0].ruleAttribute,
              revisedValue: "N/A"
            }

            extracolumns.push(rt);
          }
          return [
            ...data, ...extracolumns
          ]

        }
        else {
          return data
        }

      })


      //     // Convert the groupedData object to an array of arrays
      // console.log(addedData)
      const resultArray = Object.values(addedData).map((smt) => {
        let obb = {
          name: smt[0].name,
          unit: smt[0].unit,
          value: smt[0].value,
          remark: smt[0].remark,
          vendorRemark: [],
          revisedValue: [],
          expectedAnswer: smt[0].expectedAnswer,
          ruleAttribute: smt[0].ruleAttribute,

        };
        smt.forEach((obj, index) => {
          obb = {
            ...obb,
            revisedValue: [...obb.revisedValue, { value: obj.revisedValue, sentBy: obj.vName }],
            vendorRemark: [...obb.vendorRemark, { value: obj.vendorRemark, sentBy: obj.vName }],


          }

        })

        return obb;
      });




      const attributes = resultArray || [];


      let VendorRevisedColumns = attributes[0].revisedValue.map((vv) => {
        return `${vv.sentBy} Value`
      })
      let VendorRemarkColumns = attributes[0].vendorRemark.map((vv) => {
        return `${vv.sentBy} Remark`
      })


      const newColumns = ['Sr No.', 'Name', 'Unit', 'Value',


        ...VendorRevisedColumns,
        "KAM Remark",

        ...VendorRemarkColumns,

      ];

      // console.log(newColumns);
      setColumns(newColumns);



      setQuestionRows(attributes);

      const doc = [];
      const description = []
      data.slice(1).forEach((res, index) => {
        // console.log(res);
        if (res.sentTo === null) {
          doc[`No Vendor Name_${index}`] = res.documents
          description[`No Vendor Name_${index}`] = res.description

        }
        else {
          if (!doc[res.sentTo]) {
            doc[res.sentTo] = res.documents;
            description[res.sentTo] = res.description
              ;
          }
          else {
            doc[`${res.sentTo.trim()}_${index}`] = res.documents
            description[`${res.sentTo.trim()}_${index}`] = res.description


          }
        }

      })
      setDocuments(doc)
      setRevisionDescription(description)

      closeBackdrop()
    } catch (error) {
      console.error("Error:", error);
      closeBackdrop();
    }

  };

  useEffect(() => {

    getDataProcure();

  }, [])


  // =====================chat states==================


  // State variables for chat functionality
  const [chatSlider, setChatSlider] = useState(false); // Whether chat slider is open or closed
  const [chatValue, setChatValue] = useState(""); // Value of chat input
  const [revisionChats, setRevisionChats] = useState([]); // Array to store revision chats

  // Handler for toggling chat slider
  const handleChatSlider = () => {
    setChatSlider(prev => !prev)
  }

  // Effect to fetch revision chats when chat slider is opened
  useEffect(() => {
    if (chatSlider === true) {

      getRevisionChats()
    }

  }, [chatSlider])

  // Handler for sending chat messages
  const handleChatSend = async (e) => {
    e.preventDefault()
    try {
      if (chatValue.length > 0) {

        await privateAxios.post(`/technicalSpecification/addRemarkOnRevisionByEmployee/${auth.userData.id}/${revisionId}`, { remark: chatValue }).then(res => {
          getRevisionChats(); // Refresh revision chats after sending message
          setChatValue(""); // Clear chat input after sending
        })
      }
    } catch (error) {
      // console.log(error)
    }

  }

  // Function to fetch revision chats
  const getRevisionChats = async () => {
    try {
      await privateAxios.get(`/technicalSpecification/getRemarksOfRevision/${revisionId}`).then(res => {
        setRevisionChats(res.data);// Update revision chats state
      })
    } catch (error) {
      // console.log(error)
    }
  }



  // ===========================add revision======================


  // State variables for adding revisions
  const [addProductRevisionModal, setAddProductRevisionModal] = useState(false); // Whether add product revision modal is open
  const [addRevisionData, setAddRevisionData] = useState([]); // Data for revisions to add
  const [selectedVendorRevisions, setSelectedVendorRevisions] = useState([]); // Selected vendor revisions


  // Handler for toggling add product revision modal
  const handleAddProductRevisionModal = () => {
    setAddProductRevisionModal(prev => !prev)

  }

  // Effect to fetch revisions when add product revision modal is opened
  useEffect(() => {
    if (addProductRevisionModal === true) {
      fetchRevision();
    }
  }, [addProductRevisionModal])

  // Function to fetch revisions for adding
  const fetchRevision = async () => {
    try {
      openBackdrop();
      if (compareHeaderData.productName !== null) {

        await privateAxios.post(`/technicalSpecification/getRevisionsToAddInComparision/${auth.userData.id}`, {
          productName: compareHeaderData.productName
        })
          .then((res) => {

            setAddRevisionData(res.data);
            const alreadyRevId = compareHeaderData.compare.map((tt => tt.revId));
            const selectedRevision = res.data.filter(rev => {

              return alreadyRevId.includes(rev.id);



            })
            setSelectedVendorRevisions(selectedRevision)

          })
          .catch((error) => {
            console.error('Error handling response:', error);
            alert("Error Geeting data")
          });
      }
      else {
        alert("Product Name is Not Available")
      }
      closeBackdrop()
    } catch (error) {
      // console.log(error);
      closeBackdrop();
    }
  }
  // Function to check if arrays have same values
  const haveSameValues = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false; // Arrays have different lengths, so they can't have the same values.
    }

    return array1.every(item => array2.includes(item));
  };

  //Handle for adding selected revisions
  const handleAddProductSend = async () => {
    try {
      if (selectedVendorRevisions.length > 1) {
        const alreadyRevId = compareHeaderData.compare.map((tt => tt.revId));
        const selectedRevId = selectedVendorRevisions.map(rev => rev.id);

        const ret = haveSameValues(alreadyRevId, selectedRevId);
        if (!ret) {
          await privateAxios.post(`/technicalSpecification/addRevisionInComparision/${revisionId}`, {
            ids: selectedRevId
          }).then((res) => {
            getDataProcure();
            handleAddProductRevisionModal();
          })
        }
        else {
          handleAddProductRevisionModal();
        }


      }
      else {
        alert("please Select Atleast 2 revisions")
      }

    } catch (error) {
      // console.log(error)
    }
  }
  // ===========================add revision======================

  // ========================send for approval states=====================

  // State variable for send for approval modal
  const [sendForApprovalModal, setSendForApproalModal] = useState(false);

  // Handler for toggling send for approval modal
  const handleSendForApprovalModal = () => {
    setSendForApproalModal(prev => !prev);

  }



  // =====================download revision=================

  // Handler for downloading revision
  const handleDownloadRevision = () => {

    // extracting text from Richeditor string
    const descriptionArray = [];
    Object.keys(revisionDescription).forEach((key) => {
      const tempElement = document.createElement('div');
      tempElement.innerHTML = revisionDescription[key].name;
      let extractedText = tempElement.textContent;
      extractedText = extractedText.trim();
      descriptionArray.push({
        sentTo_recivedFrom: key,
        description: extractedText
      });

    })




    // taking nessarry feilds from attributes
    const Attributes = questionRows.map((attri) => {
      const modifiedAttri = { ...attri };


      const vendorRemarks = {};
      attri.vendorRemark.forEach((rem, index) => {
        vendorRemarks[`${rem.sentBy}_remark`] = rem.value;
      });
      const vendorValue = {};
      attri.value.forEach((val, index) => {
        vendorValue[`${val.sentBy}_value`] = val.value;
      });

      delete modifiedAttri.revisedValue;
      delete modifiedAttri.vendorRemark;

      return { ...modifiedAttri, ...vendorValue, ...vendorRemarks };

    });

    // documents
    let revisionDocument = []


    Object.keys(documents).forEach((key) => {
      if (key.startsWith("No Vendor Name")) {
        revisionDocument = documents[key].map((doc) => {
          return {
            name: doc.nam,
            link: doc.url,
            type: doc.docType,
            sentTo_recivedFrom: key

          }
        })
      }
      else {
        revisionDocument = documents[key].map((doc) => {
          return {
            name: doc.nam,
            link: doc.url,
            type: doc.docType,
            sentTo_recivedFrom: key

          }
        })
      }

    })


    createExcel([descriptionArray, Attributes, revisionDocument], (compareHeaderData.name || "Revision 0"))
  }



  // =====================download revision=================

  // State variable for ranking modal
  const [rankingModal, setRankingModal] = useState(false);

  // Handler for toggling ranking modal
  const handleRankingModal = () => {
    setRankingModal(prev => !prev);

  }



  return (
    <>
      <BackdropComponent />
      <Toast />

      <div className={workSpaceStyle.workSpaceMain}>

        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1><BackButton />{compareHeaderData?.name || "No Name"}</h1>
            <p>{compareHeaderData?.productName || "Dafault"}</p>

          </div>

          <div className={workSpaceStyle.workSpaceHeaderRight}>

            <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative", width: "auto" }}>
              <button onClick={() => { handleRankingModal() }} style={{ padding: "0 10px" }}><BarChartIcon />Ranking</button>
            </div>

            <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative" }}>
              <button onClick={() => { handleAddProductRevisionModal() }}><AddIcon />Add</button>
            </div>

            <div className={workSpaceStyle.workSpaceThreeDotVertical}>

              <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={handleChatSlider}>
                <Tooltip title='Comments'>
                  <Badge className={ProductRevisonsStyle.BadgeCSS} badgeContent={4} color="primary">
                    <ChatIcon />
                  </ Badge>
                </Tooltip>
              </span>
            </div>





            <div className={workSpaceStyle.workSpaceThreeDotVertical} style={{ position: "relative" }}>

              <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={() => setworkSpaceHeaderRevisionMore(prev => !prev)} >
                <Tooltip title='more'><MoreVertIcon /></Tooltip>
              </span>
              {
                workSpaceHeaderRevisionMore &&
                <ThreeDotDropDown toggle={() => setworkSpaceHeaderRevisionMore(prev => !prev)}>
                  <li
                    onClick={() => { handleSendForApprovalModal(); setworkSpaceHeaderRevisionMore(prev => !prev) }}
                  > <span><PersonAddAltOutlinedIcon /></span>Send for Approval</li>

                  <li
                    onClick={handleDownloadRevision}
                  ><span><FileDownloadOutlinedIcon /></span>Download</li>
                  {revisionId !== "0" && <li
                  // onClick={handleDeleteTask}
                  ><span><DeleteOutlineIcon /></span>Delete</li>}
                </ThreeDotDropDown>
              }

            </div>
            <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
              <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
            </div>
          </div>


        </div>

        <div className={revisionEditStyle.tabHeaderMain}>
          <div
            onClick={() => handleTabChange("0")}
            className={tabActive === "0" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Description</div>
          <div
            onClick={() => handleTabChange("1")}
            className={tabActive === "1" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Questions</div>
          <div
            onClick={() => handleTabChange("2")}
            className={tabActive === "2" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Documents</div>
        </div>


        <div className={revisionEditStyle.tabBodyMain} >
          {tabActive === "0" && <CompareDescriptionTab
            // setRevisionDescription={setRevisionDescription}
            revisionDescription={revisionDescription}
          />}
          {tabActive === "1" && <CompareQuestionsTab
            questionRows={questionRows}
            columns={columns}
          //  handleInputChange={handleInputChange} handleAddQuestionRow={handleAddQuestionRow} handleQuestionRowDelete={handleQuestionRowDelete}
          />}
          {tabActive === "2" && <CompareDocumentsTab
            //  addDocumentModal={addDocumentModal} handleAddDocumentModal={handleAddDocumentModal} getDataProcure={getDataProcure} 
            documents={documents}
          // handleDocumentUpload={handleDocumentUpload} 
          />}

        </div>

      </div>


      <Modal
        open={addProductRevisionModal}
        onClose={handleAddProductRevisionModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxWidth: "80vw", width: "100%", maxHeight: "90vh" }}>
          <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "10px", height: "100%" }}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><PersonAddAltOutlinedIcon /></span>Add More Files For Comparision</h3>
              <div onClick={handleAddProductRevisionModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>


            <ApprovalRevisionTable addData={addRevisionData} selectedRevisions={selectedVendorRevisions} setSelectedRevisions={setSelectedVendorRevisions} />
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "40px", maxWidth: "100px" }} className={formStyle.modalSmallPrimaryButton} onClick={handleAddProductSend}>Apply </button>

            </div>

          </div>

        </div>
      </Modal>


      <SendToApprovalCommityModal
        open={sendForApprovalModal} onClose={handleSendForApprovalModal}
        showToast={showToast}
        revisionId={revisionId}

        header={"Send for Approval"}
      />


      <CompareRankingModal
        open={rankingModal}
        onClose={handleRankingModal}
        data={vendorRankings}
        total={questionRows.length}
        type={"question"}
      />

    </>
  )
}

export default CommericalRevisionCompare
