import React from 'react'
import styleContent from './ProcaContent.module.css'


const data = [
    {
        id: 1,
        img: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698410782/Frame_33671_b0qxor.png',
        header: 'eRFX',
        para: 'Design and manage all your RFIs, RFQs, RFPs, and RFTs at a centralised dashboard.',
    },
    {
        id: 2,
        img: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698410789/Frame_33671_1_fpmpcs.png',
        header: 'Approval Workflow',
        para: 'Fast-track decisions with streamlined, easy-to-use approvals across your organisation',
    },
    {
        id: 3,
        img: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698410789/Frame_33673_ryzdss.png',
        header: 'Spend Insights & Reports',
        para: 'Unlock data-driven strategies with real-time spend analytics and comprehensive reports',
    },
]

const ProcaContent = () => {
    return (
        <div className={styleContent.ContentMain}>
            <div>
                <div className={styleContent.content}>
                    <h6>WHY USE  PROCA?</h6>
                    <h1>Easy, Simple, Affordable</h1>
                    <p>With our procurement management software, you get the power of automation to your procurement processes.</p>
                </div>
                <div className={styleContent.ContentDiv}>
                    <div className={styleContent.information}>
                        {
                            data.map((item) => {
                                return (
                                    <div key={item.id}>
                                        <div>
                                        <img style={{width:'50px',height:'50px'}} src={item.img} alt="" />
                                        </div>
                                        <div className={styleContent.iconContent}>
                                            <h4>{item.header}</h4>
                                            <p>{item.para}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                    <div className={styleContent.contentImg}>
                        <img src="https://res.cloudinary.com/dtffniutw/image/upload/v1698420088/Content_c3aofp.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProcaContent
