import { useState, useEffect, useRef } from 'react';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import VendorApprovastyle from '../Approvals/VendorApproval.module.css'
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import { PrivateComponent } from 'api/axios'
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import ChatRow from 'components/ChatRows/ChatRow';
import useBackdrop from 'hooks/useBackdrop';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import useToast from 'hooks/useToast.js';
import ApprovalRevisionTable from 'components/ApprovalRevisionTable/ApprovalRevisionTable';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge.js';
import SendToApprovalCommityModal from 'components/SendToApprovalCommityModal/SendToApprovalCommityModal';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';


const RevisionGroup = () => {
    const { auth } = useAuth();// Hook for authentication context
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Hooks for managing backdrops
    const { groupId, client } = useParams(); // Hook for URL parameters
    const navigate = useNavigate(); // Hook for navigation
    const privateAxios = PrivateComponent(); // Axios instance for private API calls
    const [data, setData] = useState([]); // State for storing revision data
    const [groupData, setGroupData] = useState(""); // State for storing group data
    const [searchValue, setSearchValue] = useState(""); // State for search input
    const { showToast, Toast } = useToast(); // Hooks for toast notifications

    // Function to fetch all grouped revisions
    const getAllGroupedRevisions = async () => {
        openBackdrop();// Show loading backdrop
        try {
            privateAxios.get(`/technicalSpecification/getGroupRevisionById/${groupId}`)
                .then((res) => {
                    // Handle the response data here
                    setData(res.data.slice(1));
                    setGroupData(res.data[0])
                    // You can update your component state or perform any other actions with the data.
                })
                .catch((error) => {
                    console.error('Error handling response:', error);
                });
            // setData(revisionsData)
            closeBackdrop()
        } catch (error) {
            closeBackdrop()
            console.error('Error fetching revisions for approval:', error);
        }
    };

    useEffect(() => {
        // Fetch revisions for approval when the component mounts
        getAllGroupedRevisions();
    }, []);

    const formatDateString = (dateString) => {
        const dateObject = new Date(dateString);
        // Use JavaScript's Date methods to format the date as needed
        const formattedDate = dateObject.toLocaleDateString(); // This will give you the date in "MM/DD/YYYY" or "DD/MM/YYYY" format
        return formattedDate;
    }

 // State and handlers for selected vendor revisions
    const [selectedVendorRevisions, setSelectedVendorRevisions] = useState([]);
    const [compareModal, setCompareModal] = useState(false); // State for compare modal
    const [groupModal, setGroupModal] = useState(false); // State for group modal
    const [compareName, setCompareName] = useState(""); // State for compare name

    const handleVendorRevisionSelect = (data) => {
        const selectedIndex = selectedVendorRevisions.findIndex(
            (item) => item.id === data.id
        );
        if (selectedIndex === -1) {
            setSelectedVendorRevisions((prevSelected) => [...prevSelected, data]);
        } else {
            setSelectedVendorRevisions((prevSelected) =>
                prevSelected.filter((item) => item.id !== data.id)
            );
        }

    };

 // Validation function for comparing revisions
    const compareValidation = () => {
        if (selectedVendorRevisions.length < 2) {
            alert("please select atleast 2 Options")
        }
        else {
            const firstObjectName = selectedVendorRevisions[0].productName;
            const firstObjectCategory = selectedVendorRevisions[0].type;
            let allObjectsHaveSameName = true;
            let allObjectsHaveSameCategory = true;

            for (let i = 1; i < selectedVendorRevisions.length; i++) {
                if (selectedVendorRevisions[i].productName !== firstObjectName) {
                    allObjectsHaveSameName = false;
                    break;
                }
                if (selectedVendorRevisions[i].type !== firstObjectCategory) {
                    allObjectsHaveSameCategory = false;
                    break;
                }
            }

            const hasGroup = selectedVendorRevisions.some(obj => obj.vendorRevisionType === "GROUP");
            if (allObjectsHaveSameName && !hasGroup && allObjectsHaveSameCategory) {
                handleCompareNameModal();

            } else if (hasGroup) {
                alert("Compare Can't have Group in it");
            }
            else if (!allObjectsHaveSameName) {
                alert("Not all Selected Revision have the same name.");
            }
            else if (!allObjectsHaveSameCategory) {
                alert("Not all Selected Revision have the same Category.");
            }
        }
    }

     // Handlers to toggle modals
    const handleCompareNameModal = () => {
        setCompareModal(prev => !prev);
    }
    const groupValidation = () => {
        if (selectedVendorRevisions.length < 2) {
            alert("please select atleast 2 Options")
        }
        else {

            handleGroupNameModal();
        }
    }
    const handleGroupNameModal = () => {
        setGroupModal(prev => !prev);
    }

       // Navigation handler based on file type and category
    const handleNavigate = (id, fileType, category) => {
        if (fileType === "GROUP") {
            navigate(`/${client}/mytasks/approval/group/${id}`)
        }
        else {


            if (category.toLowerCase() === "technical specification") {
                if (fileType === "PRODUCT") {
                    navigate(`/${client}/mytasks/approval/vendor/${id}?tab=0`)
                }

                else if (fileType === "COMPARISION") {
                    navigate(`/${client}/mytasks/approval/compare/${id}?tab=0`)
                }
            }
            else if (category.toLowerCase() === "commercial") {
                if (fileType === "PRODUCT") {
                    navigate(`/${client}/mytasks/approval/vendor/commercial/${id}?tab=0`)
                } else if (fileType === "COMPARISION") {
                    navigate(`/${client}/mytasks/approval/vendor/commercial/compare/${id}/?tab=0`)
                }
            }
        }
    }

  // Function to render file type badges
    const handleFileType = (fileType) => {
        if (fileType === "PRODUCT") {
            return (
                <span style={{ padding: "2px 10px", fontWeight: "500", borderRadius: "4px" }}>Product</span>
            )
        }
        else if (fileType === "GROUP") {
            return (
                <span style={{ backgroundColor: "#EEEEEE", fontWeight: "500", padding: "2px 10px", borderRadius: "4px" }}>Group</span>
            )
        }
        else if (fileType === "COMPARISION") {
            return (
                <span style={{ backgroundColor: "#16469D", fontWeight: "500", color: "white", padding: "2px 10px", borderRadius: "4px" }}>Comparision</span>
            )
        }
    }


  // State and handlers for adding more files to a group
    const [addfileGroupModal, setAddFileGroupModal] = useState(false);
    const [addGroupData, setAddGroupData] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleAddfileGroupModal = () => {
        setAddFileGroupModal(prev => !prev);
    }

    // Effect hook to fetch revisions when add file modal opens
    useEffect(() => {
        if (addfileGroupModal === true) {
            fetchRevision();
        }
    }, [addfileGroupModal])


    // Function to fetch all vendor revisions
    const fetchRevision = async () => {
        try {
            openBackdrop();
            await privateAxios.get(`/technicalSpecification/getAllVendorRevisionsInApproval/${auth.userData.id}`)
                .then((res) => {
                    setAddGroupData(res.data);
                    const alreadyGrpId = groupData.group.map((tt => tt.vendorRevisionIds));
                    const ssFiles = res.data.filter(rev => {
                        return alreadyGrpId.includes(rev.id);
                    })
                    setSelectedFiles(ssFiles)
                })
                .catch((error) => {
                    console.error('Error handling response:', error);
                    handleAddfileGroupModal();
                    alert("Error Geeting data")
                });

            closeBackdrop()
        } catch (error) {
            closeBackdrop();
        }
    }

// Utility function to compare two arrays for same values
    const haveSameValues = (array1, array2) => {
        if (array1.length !== array2.length) {
            return false; // Arrays have different lengths, so they can't have the same values.
        }

        return array1.every(item => array2.includes(item));
    };

      // Handler for adding files to a group
    const handleAddFileSend = async () => {
        try {
            if (selectedFiles.length > 1) {
                const alreadyGrpId = groupData.group.map((tt => tt.vendorRevisionIds));
                const selectedGroupId = selectedFiles.map(rev => rev.id);

                const ret = haveSameValues(alreadyGrpId, selectedGroupId);
                if (!ret) {
                    await privateAxios.post(`/technicalSpecification/addRevisionInGroup/${groupId}`, {
                        ids: selectedGroupId
                    }).then((res) => {
                        getAllGroupedRevisions();
                        handleAddfileGroupModal();
                    })
                }
                else {
                    handleAddfileGroupModal();
                }
            }
            else {
                alert("please Select Atleast 2 Files")
            }

        } catch (error) {
        }
    }

     // Handler for comparing group revisions
    const handleCompareGroupRevision = async (e) => {
        e.preventDefault();
        try {
            openBackdrop();
            const revisionIds = selectedVendorRevisions.map((rev) => { return rev.id });
            const names = selectedVendorRevisions[0].productName
            await privateAxios.post(`/technicalSpecification/compareVendorRevisions/${auth.userData.id}`, {
                ids: revisionIds,
                name: compareName,
                productName: names

            }).then((res) => {
                setCompareName("");
                handleCompareNameModal();
                setSelectedVendorRevisions([])
                getAllGroupedRevisions();
            })
            closeBackdrop()
        } catch (error) {
            handleGroupNameModal();
            closeBackdrop()
            alert("Error Creating Group");
        }


    }
    // =====================chat notification========================


      // State and handler for chat notifications
    const [reRender, setReRender] = useState(false);

    const handleChatNotification = () => {
        setReRender(prev => !prev);

    }

    // --------------------------------Chatt-----------------------------------------
// Chat-related states and handlers
    const [revId, setRevId] = useState("")
    const [chatSlider, setChatSlider] = useState(false)
    const [chatValue, setChatValue] = useState("");
    const [revisionChats, setRevisionChats] = useState([]);
    const [revData, setRevData] = useState("");
    const handleChatSlider = (revData) => {
        setChatSlider(prev => !prev)
        setRevData(revData);


    }
    useEffect(() => {
        if (chatSlider === true && revData !== "") {

            getRevisionChats(revData.id, revData.type);
        }

    }, [chatSlider])

     // Handler for sending chat messages
    const handleChatSend = async (e, revId) => {
        e.preventDefault();
        try {
            let url = ""; // Define the API endpoint based on file type
            switch (revData.type.toLowerCase()) {
                case "commercial":
                    url = `/commercial/addRemarkOnCommercialRevisionByVendor`;
                    break;
                case "technical specification":
                    url = `/technicalSpecification/addRemarkOnVendorRevisionByKam`;
                    break;
                case "procure":
                    url = ``;
                    break;
                case "approval":
                    url = ``;
                    break;
                default:
                    return;
            }

            if (chatValue.length > 0) {
                await privateAxios.post(`${url}/${auth.userData.id}/${revData.id}`, { remark: chatValue }).then(res => {
                    getRevisionChats(revId);
                    setChatValue("");
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

  // Function to fetch revision chats
    const getRevisionChats = async (revId, type) => {
        handleChatNotification();
        try {
            let url = ""
            switch (revData.type.toLowerCase()) {
                case "commercial": url = `/commercial/getRemarksOfCommercialVendorRevision`;
                    break;
                case "technical specification": url = `/technicalSpecification/getRemarksOfRevisionOfVendorandKam`;
                    break;
                case "procure": url = ``;
                    break;
                case "approval": url = ``;
                    break;
                default: return;
            }

            await privateAxios.get(`${url}/${revId}`).then(res => {
                setRevisionChats(res.data)
                console.log("chatttttt", res)
            })

        } catch (error) {

        }
        // closeBackdrop();
    }

    // ========================send for approval states=====================

   // State and handler for sending revisions for approval
    const [sendForApprovalModal, setSendForApproalModal] = useState(false);
  
    const handleSendForApprovalModal = (revData) => {
        setSendForApproalModal(prev => !prev);
        if (revData !== undefined && revData !== null && revData !== "") {

            setRevData(revData);
        }
    }


   
    // ========================send for approval states=====================

     // State and handler for showing more revision details
    const [revisionMore, setRevisionMore] = useState("")

    const handleToggle = (id) => {
        if (revisionMore === id) {
            setRevisionMore("")
        }
        else {
            setRevisionMore(id);
        }
    }


    return (
        <>
            <BackdropComponent />
            <Toast />
            <div className={workSpaceStyle.workSpaceMain}>
                <div className={workSpaceStyle.workSpaceHeader}>

                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <h1>{groupData.name || "No Name"}</h1>
                            <span style={{ fontSize: "10px", backgroundColor: "#D2D2D2", padding: "2px", borderRadius: "2px" }}>Group
                            </span>
                        </span>
                        <p>{`#0${groupData.id}  ${groupData.name}`}</p>

                    </div>


                    <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{}}>
                        <input type="text"
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            placeholder='Search Here...' />
                    </div>

                    <div className={workSpaceStyle.workSpaceHeaderRight}>

                        <button className={workSpaceStyle.createGroupBtn}
                            onClick={handleAddfileGroupModal}>
                            Add files
                        </button>
                        <button className={workSpaceStyle.CompareBtn}
                            onClick={compareValidation}
                        >
                            Compare
                        </button>

                    </div>

                </div>

                <div className={VendorApprovastyle.approvalVendorMain} style={{ width: "100%" }}>
                    <table className={VendorApprovastyle.approvalVendorTable}>
                        <thead>
                            <tr className={`${VendorApprovastyle.tableHead}`}>
                                <td className={`${VendorApprovastyle.tableColumnSticky}`} style={{ minWidth: "200px" }}>
                                    <div className={VendorApprovastyle.tdRow}>
                                        <p>Name</p>
                                    </div>
                                </td>

                                <td> Products </td>
                                <td> File Type </td>
                                <td> Category </td>
                                <td> Submited On </td>
                                <td> Sent By </td>
                                <td> Last Updated </td>
                                <td> Actions </td>
                            </tr>
                        </thead>
                        <tbody>
                            {data.filter(row => row.name.trim().trim().toLowerCase().includes(searchValue.toLowerCase()) || row.productName.trim().trim().toLowerCase().includes(searchValue.toLowerCase())).map((row, index) => {
                                const isChecked = selectedVendorRevisions.some((selectedRow) => selectedRow.id === row.id);
                                return (
                                    <tr key={index} className={`${VendorApprovastyle.tableRow}`}>
                                        <td className={`${VendorApprovastyle.tableColumnSticky2} `}>
                                            <div className={VendorApprovastyle.tdRow}>
                                                <input type="checkbox" onChange={() => handleVendorRevisionSelect(row)} checked={isChecked} />
                                                <p>{row.name}</p>
                                            </div>
                                        </td>

                                        <td >{row.productName}</td>
                                        <td >{handleFileType(row.vendorRevisionType)}</td>
                                        <td >{row.type}</td>
                                        <td >{row.submittedOn?.split("T")[0]}</td>
                                        <td >
                                            <div className={VendorApprovastyle.tdRow}>
                                                <ProfileIcon data={{ name: row.sentTo, email: row.vendorEmail, type: "KAM", profileLink: "SomeLink" }} height={"26px"} width={"26px"} showProfileBanner={true} /> {row.sentTo}
                                            </div>
                                        </td>
                                        <td >{formatDateString(row.lastModified)}</td>
                                        <td >
                                            <div className={VendorApprovastyle.actionsRow}>

                                                <span onClick={() => handleSendForApprovalModal({ id: row.id, type: row.type })}>
                                                    <PersonAddAltIcon />
                                                </span>
                                                <span onClick={() => handleNavigate(row.id, row.vendorRevisionType, row.type)}>
                                                    <RemoveRedEyeIcon />
                                                </span>
                                                {row.vendorRevisionType === "PRODUCT" && <Tooltip title='Comments'>
                                                    <span onClick={() => handleChatSlider({ id: row.id, type: row.type, name: row.name })}>
                                                        {row?.type?.toLowerCase() === 'technical specification'
                                                            ?
                                                            <ChatIconWithBadge url={`/technicalSpecification/getNotficationCountOfVendorTsRevision`} id={row.id} reRender={reRender} />
                                                            :
                                                            <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialVendorRevision`} id={row.id} reRender={reRender} />
                                                        }
                                                    </span>

                                                </Tooltip>}

                                                {
                                                 row.vendorRevisionType !== "PRODUCT" &&
                                                <span style={{ position: "relative" }} onClick={() => handleToggle(row.id)}>
                                                <MoreVertIcon />
                                                {
                                                    revisionMore === row.id &&
                                                    <ThreeDotDropDown toggle={() => handleToggle(row.id)}>
                                                        <li><span><DriveFileRenameOutlineIcon/></span> Rename</li>
                                                    </ThreeDotDropDown>
                                                }

                                            </span>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }).reverse()
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <ChatPannel
                open={chatSlider}
                onClose={handleChatSlider}
            >
                <>
                    <ChatHeader onClose={handleChatSlider}
                        downloadData={revisionChats.map((chat) => {
                            return { name: chat.name, date: chat.createdAt, chat: chat.remark };
                        })}
                        helpData={{ link: "tosomewhere" }}
                        chatName={revData?.name}
                    >
                        <h3>{revData?.name} </h3>
                    </ChatHeader>

                    <ChatRows>
                        {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
                            return (
                                <ChatRow name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} />
                            )
                        })
                            :
                            <p style={{ textAlign: "center" }}>
                                No Previous Chat
                            </p>
                        }
                    </ChatRows>
                    <ChatInputField revisionId={revData.id} onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />
                </>
            </ChatPannel>

            <Modal
                open={addfileGroupModal}
                onClose={handleAddfileGroupModal}
            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxWidth: "80vw", width: "100%", maxHeight: "90vh" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "10px", height: "100%" }}>
                        
                        <div className={workSpaceStyle.modalHeader} >
                            <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><PersonAddAltOutlinedIcon /></span>Add More Files</h3>
                            <div onClick={handleAddfileGroupModal}>
                                <Tooltip title='Close'><CloseIcon /></Tooltip>
                            </div>
                        </div>

                        <ApprovalRevisionTable addData={addGroupData} selectedRevisions={selectedFiles} setSelectedRevisions={setSelectedFiles} />
                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                            <button style={{ height: "40px", maxWidth: "100px" }} className={formStyle.modalSmallPrimaryButton} onClick={handleAddFileSend}>Apply </button>
                        </div>

                    </div>
                </div>
            </Modal>

            <Modal
                open={compareModal}
                onClose={handleCompareNameModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>
                    <form action="" onSubmit={handleCompareGroupRevision} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>

                        <div className={workSpaceStyle.modalHeader} >
                            <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            </span>Name As</h3>
                            <div onClick={handleCompareNameModal}>
                                <Tooltip title='Close'><CloseIcon /></Tooltip>
                            </div>
                        </div>

                        <div className={formStyle.inputRow}>
                            <input type="text" value={compareName} onChange={(e) => setCompareName(e.target.value)} placeholder='Name' />
                        </div>

                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                            <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>
                            <p onClick={() => { setCompareName(""); handleCompareNameModal() }} style={{ cursor: "pointer" }}>Discard</p>
                        </div>

                    </form>
                </div>
            </Modal>


            <SendToApprovalCommityModal
                open={sendForApprovalModal} onClose={handleSendForApprovalModal}
                showToast={showToast}
                revisionId={revData.id}
                header={"Send for Approval"}
            />
           

        </>
    )
}

export default RevisionGroup
