import { Modal } from '@mui/material'
import React, { useRef, useState } from 'react'
import docModalStyle from './DocumentUploadModal.module.css'
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
const DocumentUploadModal = ({ open, onClose, onSubmit }) => {

    const [fileName, setFileName] = useState("");  // State for storing the file name
    const [confidential, setConfidential] = useState(false);  // State for storing the confidentiality status
    const inputRef = useRef(null);  // Reference for the file input element

    const [fileSelected, setFileSelected] = useState(null);  // State for storing the selected file
    const [fileError, setFileError] = useState('');  // State for storing file error message

    const handleFileInputChange = (e) => {// Handler for file input change
        const selectedFile = e.target.files[0];// Get the selected file

        // Check if a file is selected
        if (selectedFile) {
            // Check file extension for allowed types
            const allowedTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg', 'png']; // Allowed file types
            const fileType = selectedFile.name.split('.').pop().toLowerCase();// Get the file extension

            if (allowedTypes.includes(fileType)) {// Check if the file type is allowed
                setFileSelected(selectedFile);// Set the selected file
                setFileError(''); // Clear any previous file error
            } else {
                setFileError('Invalid file type. Allowed types: pdf, doc, docx, xls, xlsx, jpg, jpeg, png'); // Set file error message
                setFileSelected(null); // Clear the selected file
            }
        } else {
            // Reset input value to allow selecting the same file again
            inputRef.current.value = ''; // Reset input value to allow selecting the same file again
            setFileSelected(null); // Clear the selected file
        }
    };

    const formatFileSize = (fileSizeInBytes) => {// Function to format file size
        if (fileSizeInBytes < 1024) {// File size in bytes
            return fileSizeInBytes + ' B';
        } else if (fileSizeInBytes < 1024 * 1024) { // File size in kilobytes
            return (fileSizeInBytes / 1024).toFixed(2) + ' KB';
        } else if (fileSizeInBytes < 1024 * 1024 * 1024) {// File size in megabytes
            return (fileSizeInBytes / (1024 * 1024)).toFixed(2) + ' MB';
        } else {// File size in gigabytes
            return (fileSizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        }
    }


    const downloadFile = (file) => {  // Function to download the file
        const blob = new Blob([file]);  // Create a blob from the file
        const downloadLink = document.createElement('a');  // Create a link element
        downloadLink.href = window.URL.createObjectURL(blob);  // Create a URL for the blob
        downloadLink.download = file.name;  // Set the download attribute with the file name
        document.body.appendChild(downloadLink);  // Append the link to the body
        downloadLink.click();  // Programmatically click the link to trigger download
        document.body.removeChild(downloadLink);  // Remove the link from the body
    };

    const handleSubmit = async (e) => {  // Handler for form submission
        e.preventDefault();  // Prevent default form submission
        const data = {  // Prepare the data object
            fileName: fileName,  // File name
            files: fileSelected,  // Selected file
            type: confidential ? "CONFIDENTIAL" : "NORMAL",  // File type based on confidentiality
        }
        await onSubmit(data);  // Call the onSubmit prop with the data
        // onClose();
        inputRef.current.value = '';  // Reset the file input value
        setFileName("");  // Reset the file name
        setFileSelected(null);  // Clear the selected file
        setConfidential(false);  // Reset the confidentiality status
    }

    return (
        <Modal
            open={open}
            onClose={onClose}

        >
            <div className={docModalStyle.modalContainer}>

                <div className={docModalStyle.modalWrapper} >


                    <div className={docModalStyle.modalHeader}>
                        <h2>Add Document</h2>
                        <span>
                            <CloseIcon onClick={onClose} />
                        </span>
                    </div>

                    <form onSubmit={handleSubmit} className={docModalStyle.modalBody}>
                        <div className={docModalStyle.formGroup}>
                            <label htmlFor="title">Name</label>
                            <input type="text" name="title" id="title" placeholder='Document Name' onChange={(e) => setFileName(e.target.value)} />
                        </div>

                        <div className={docModalStyle.formGroup}>
                            <label >Upload file</label>
                            {
                                fileSelected === null

                                    ?
                                    <div className={docModalStyle.uploadArea} onClick={() => inputRef.current.click()} >
                                        <div>

                                            Drag and drop your file here
                                            <span
                                                className={docModalStyle.browseBtn}
                                            >Browse</span>
                                        </div>
                                    </div>
                                    :
                                    <div className={docModalStyle.fileRow}>
                                        <div className={docModalStyle.fileRowHeader}>
                                            <span>
                                                <DescriptionIcon />
                                            </span>
                                            <h4>
                                                {fileSelected?.name}
                                                <span
                                                    onClick={() => downloadFile(fileSelected)}
                                                >Preview</span>
                                            </h4>
                                        </div>
                                        <div className={docModalStyle.fileSize}>
                                            <h6>
                                                {formatFileSize(fileSelected?.size)}
                                            </h6>

                                        </div>

                                        <div className={docModalStyle.cutIcon}>
                                            <span>
                                                <CloseIcon onClick={() => {
                                                    inputRef.current.value = '';
                                                    setFileSelected(null);
                                                }} />
                                            </span>
                                        </div>
                                    </div>

                            }
                            <input
                                type="file"
                                name="file"
                                id="file"
                                hidden

                                ref={inputRef}
                                onChange={handleFileInputChange}
                            />

                            {fileError && (
                                <div className={docModalStyle.errorMsg}>{fileError}</div>
                            )}



                        </div>
                        <div className={docModalStyle.formGroupCheckBox}>
                            <input type="checkbox" name="file" id="file" checked={confidential} onChange={() => setConfidential(prev => !prev)} />
                            <label htmlFor="file">Mark as Confidential</label>
                        </div>


                        <div className={docModalStyle.formGroup}
                        >
                            <button type="submit"
                                style={{
                                    backgroundColor: (fileName.length !== 0 && fileSelected !== null) ? "#16469D" : "#C3C3C3"
                                }}
                                disabled={(fileName.length === 0 || fileSelected === null)}

                            >Add</button>
                        </div>
                    </form>



                </div>
            </div>
        </Modal>
    )
}

export default DocumentUploadModal
