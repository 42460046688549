import React, { useEffect, useState } from 'react'
import ChatIcon from '@mui/icons-material/Chat';
import { Badge, Typography } from "@mui/material";
import { PrivateComponent } from 'api/axios';


const ChatIconWithBadge = ({ url, id, reRender }) => {
    const privateAxios = PrivateComponent();// Axios instance for private requests

    // ===============get notification count=======================

    // State for notification count
    const [notiCount, setNotiCount] = useState("0");

    // Function to get revision notification count
    const getRevisionNotificationCount = async () => {
        try {
            const res = await privateAxios.get(`${url}/${id}`);// Fetch notification count from API
            if (res.data !== null && res.data !== undefined && res.data !== "") {
                setNotiCount(res.data);// Update notification count state
            }
            else {
                setNotiCount("0");// Set notification count to 0 if data is empty
            }

        } catch (error) {
            console.log(error); // Log error if encountered
        }
    }

    // Effect to fetch notification count on component mount and re-render
    useEffect(() => {
        getRevisionNotificationCount();
    }, [reRender])

    return (
        <>

            {notiCount !== "0" ? <Badge style={{ height: "20px" }} badgeContent={notiCount} color="primary">
                <ChatIcon />
            </Badge>
                :
                <ChatIcon />
            }
        </>
    )
}

export default ChatIconWithBadge
