import React,{useState} from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './SpecificationTab.module.css'
const SpecificationTab = ({value,onChange}) => {

  
  return (
    <div className={styles.specficationTabMain}>
   
    <div className={styles.richEditor}>
    
      <ReactQuill  theme="snow" value={value} onChange={onChange} />     
     
    </div>
    
    </div>
  )
}

export default SpecificationTab
