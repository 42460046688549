import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AuthReducer from './state/AuthState';
import { configureStore } from '@reduxjs/toolkit';

import { Provider } from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist"
import storage from "redux-persist/lib/storage"
import { PersistGate } from 'redux-persist/integration/react';


// Configuration for persisting Redux state
const persistConfig = { key: "proc-auth", storage, version: 1 };

// Create a persisted reducer
const persistreducer = persistReducer(persistConfig, AuthReducer);

// Create root for rendering the app
const root = ReactDOM.createRoot(document.getElementById('root'));

// Configure the Redux store
const store = configureStore({
  reducer: {
    authData: persistreducer,// Add persisted reducer to the store

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore specific Redux Persist actions for serializability check
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      },
    }),

})


// Render the React application
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);


