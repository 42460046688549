import React, { useState, useEffect } from 'react'// Importing necessary React hooks and components
import { Modal, Typography, useScrollTrigger } from '@mui/material'; // Importing MUI components
import Box from '@mui/material/Box';// Importing Box component from MUI
import dashboardStyle from './Dashboard.module.css'// Importing CSS module for Dashboard styling
import useAuth from 'hooks/useAuth'// Importing custom hook for authentication
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';// Importing icon from MUI
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone'; // Importing icon from MUI
import AddIcon from '@mui/icons-material/Add';// Importing icon from MUI
import MoreVertIcon from '@mui/icons-material/MoreVert';// Importing icon from MUI
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';// Importing icon from MUI
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';// Importing icon from MUI
import Tooltip from '@mui/material/Tooltip';// Importing Tooltip component from MUI
import headerStyle from '../../components/Header/Header.module.css'// Importing CSS module for Header styling
import { PrivateComponent } from 'api/axios';// Importing private Axios instance for API requests
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';// Importing common workspace styles
import CloseIcon from '@mui/icons-material/Close';// Importing icon from MUI
import useToast from 'hooks/useToast';// Importing custom hook for Toast notifications
import useBackdrop from 'hooks/useBackdrop';// Importing custom hook for Backdrop
import ProductSelector from 'components/ProductSelector/ProductSelector';// Importing ProductSelector component
import { Co2Sharp, HelpOutlineOutlined } from '@mui/icons-material'; // Importing icons from MUI

const Dashboard = () => {// Defining the Dashboard component

  const [products, setProducts] = useState([]);// State to hold products
  const { auth } = useAuth();// Destructuring auth from useAuth hook
  const privateAxios = PrivateComponent();// Initializing private Axios instance
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();// Destructuring backdrop functions from useBackdrop hook
  const { showToast, Toast } = useToast();// Destructuring toast functions from useToast hook

  const timeOfDay = () => {// Function to determine time of day
    const currentTime = new Date();// Getting current date and time
    const currentHour = currentTime.getHours(); // Getting current hour

    if (currentHour >= 5 && currentHour < 12) {// Morning condition
      return "Morning";
    } else if (currentHour >= 12 && currentHour < 18) {// Afternoon condition
      return "Afternoon";
    } else {
      return "Evening"; // Evening condition
    }
  }

  const fetchProducts = async () => { // Async function to fetch products

    try {
      await privateAxios.get(`/procureadmin/getApprovedProductsOfOrg/${auth.orgId}`).then(res => {
        setProducts(res.data);// Setting fetched products
      }).catch((err) => {
        alert("error getting categories");
      })
    } catch (error) {
    }
  }

  useEffect(() => {// useEffect hook to fetch products on component mount
    fetchProducts();
  }, [])

  // ===================add product modal==================

  const [addProduct, setAddProduct] = useState(false); // State for add product modal visibility

  const handleAddProductModal = () => {// Function to toggle add product modal
    setAddProduct(prev => !prev);
  }


  // ===================add product modal==================

  // ====================handle add product success modal================

  const showAddProductSuccess = (type) => {// Function to show add product success message
    handleAddProductModal();
    if (type) {
      showToast("success", "Product Added Successfully")
    }
    else {
      showToast("error", "Error Adding Product")
    }
  }



  // ====================handle add product ================

  const handleAddProduct = async (data) => {
    openBackdrop();
    try {
      const { products, categories } = data;
      const catRes = await privateAxios.post(`/procureadmin/requestForSingleCategory/${auth.orgId}`, categories);
      if (catRes.status === 200) {
        const prodRes = await privateAxios.post(`/procureadmin/requestForProduct/${auth.orgId}`, products);
        if (prodRes.status === 200) {
          showToast("success", "product Requested Successfully");
        }
        else {
          showToast("error", "Error Requesting Product");
        }
      }
      else {
        showToast("error", "Error Requesting Category");
      }
      handleAddProductModal();
    }
    catch (error) {
      showToast("error", error.message);// show error massege
    }
    closeBackdrop();

  }

  return (
    <>
      <Toast />
      <BackdropComponent />
      <div className={dashboardStyle.dashboardMain}>
        <div className={dashboardStyle.dashboardHeader}>

          {auth.profilePic?.newImage ?
            <img className={headerStyle.smallProfileIcon} style={{ width: "60px", height: '60px' }}
              src={`${process.env.REACT_APP_BASEURL}/adminuser/file/${auth.profilePic.newImage}`}
            />
            :
            <div className={dashboardStyle.dashboardHeaderImg}>
              <div>{auth.name.charAt(0)}</div></div>
          }


          <div className={dashboardStyle.dashboardHeaderText}>
            Good {timeOfDay()}, <span> {auth.name}!</span>
          </div>
        </div>

        <div className={dashboardStyle.dashboardCards}>
          <div className={dashboardStyle.dashboardCard}>
            <div className={dashboardStyle.header}>
              <Box className={dashboardStyle.headerContent}>
                <ListTwoToneIcon />
                <Typography variant='h6'>Procurement Products</Typography>
              </Box>
              <Box className={dashboardStyle.headerIcons}>
                <Tooltip title='Filter'> <FilterAltTwoToneIcon /></Tooltip>
                {
                  auth.roles === "KAM" &&
                  <Tooltip title='Add' onClick={handleAddProductModal}><AddIcon /></Tooltip>
                }
                <Tooltip title='Help'>
                  {
                    auth.roles === "KAM" &&
                    <a target='_blank' href='https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000080848-adding-products-to-the-website'>
                      <HelpOutlineOutlined />
                    </a>
                  }
                </Tooltip>
              </Box>
            </div>
            <div className={dashboardStyle.dashboardCardCont}>
              {
                products.map((item) =>
                  <div className={dashboardStyle.cardData} style={{ height: "35px" }}>
                    <Typography variant='h6'>{item.productName}</Typography>
                  </div>
                )
              }

            </div>

          </div>
          <div className={dashboardStyle.dashboardCard}>
            <div className={dashboardStyle.header}>
              <Box className={dashboardStyle.headerContent}>
                <FolderOutlinedIcon />
                <Typography variant='h6'>Workspaces</Typography>
              </Box>
              <Box className={dashboardStyle.headerIcons}>

                <Tooltip title='More'><MoreVertIcon /></Tooltip>
              </Box>
            </div>
            {
              auth.workSpaces.map((item) =>
                <div style={{ padding: '6px' }} className={dashboardStyle.cardData}>
                  <div className={dashboardStyle.workData}>
                    <StarBorderOutlinedIcon />
                    <Box sx={{ background: 'green', width: '15px', height: '15px', borderRadius: '50%' }} />
                    <Typography variant='h6'>{item}</Typography>
                  </div>
                </div>
              )
            }
          </div>
          <div className={dashboardStyle.dashboardCard}>
            <div className={dashboardStyle.header}>
              <Box className={dashboardStyle.headerContent}>
                <FolderOutlinedIcon />
                <Typography variant='h6'>Announcements</Typography>
              </Box>
              <Box className={dashboardStyle.headerIcons}>
                <Tooltip title='Add'><AddIcon /></Tooltip>
                <Tooltip title='More'><MoreVertIcon /></Tooltip>
              </Box>
            </div>
          </div>
          <div className={dashboardStyle.dashboardCard}>
            <div className={dashboardStyle.header}>
              <Box className={dashboardStyle.headerContent}>
                <FolderOutlinedIcon />
                <Typography variant='h6'>My Tasks</Typography>
              </Box>
              <Box className={dashboardStyle.headerIcons}>
                <Tooltip title='Add'><AddIcon /></Tooltip>
                <Tooltip title='More'><MoreVertIcon /></Tooltip>
              </Box>
            </div>
          </div>
        </div>
      </div >


      <Modal
        open={addProduct}
        onClose={handleAddProductModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>
          <div style={{
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "flex-start",
            alignItems: "felx-start",
            flexDirection: "column",
            gap: "20px"

          }}>

            <div className={workSpaceStyle.modalHeader}>
              <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Add Product</h2>
              <span onClick={handleAddProductModal}><CloseIcon /></span>
            </div>

            <ProductSelector
              productsArray={products}

              handleAddProductModal={handleAddProductModal}
              onsubmit={handleAddProduct}
              orgId={auth.orgId}

            />
          </div>
        </div>
      </Modal>

    </>
  )
}

export default Dashboard
