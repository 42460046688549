import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import superadminStyle from '../../commonCss.module.css';
import Tooltip from '@mui/material/Tooltip';
import GroupSorterData from 'utility/GroupSorterData.js';
import TableSorterDropDown from 'components/TableSorterDropDown/TableSorterDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import DateFormater from 'utility/DateFormater.js';
import BackButton from 'components/BackButton/BackButton';


const ProductRequest = () => {
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Using custom hook for backdrop
    const { showToast, Toast } = useToast(); // Using custom hook for toast notifications
    const privateAxios = PrivateComponent(); // Getting private Axios instance
    const navigate = useNavigate(); // Hook for navigation
    const [searchValue, setSearchValue] = useState(''); // State for search value
    const [sorterOpen, setSorterOpen] = useState(""); // State for tracking which sorter is open

    // Function to toggle the visibility of sorter dropdown
    const toggleSorter = (type) => {
        if (sorterOpen === type) {
            setSorterOpen(""); // Close the sorter if it's already open
        } else {
            setSorterOpen(type); // Open the sorter
        }
    }


    // =====================data======================

    const [data, setData] = useState([]); // State for storing fetched data
    const [sorterData, setSorterData] = useState({}); // State for storing data for the sorter
    const [sortedData, setSortedData] = useState([]); // State for storing sorted data

// Function to fetch data from the server
    const getData = async () => {
        openBackdrop(); // Open the backdrop
        try {
            const res = await privateAxios.get('/procureadmin/getAllRequestedSingleProducts');
            setData(res.data); // Set fetched data to state
            setSortedData(res.data); // Set fetched data to sorted data
            setSorterData(GroupSorterData(res.data)); // Group data for sorting
        } catch (error) {
            showToast("error", error.message)// Show error toast
        }
        closeBackdrop();// Close the backdrop
    }

    useEffect(() => {
        getData();// Fetch data on component mount
    }, [])


    // =====================decision======================

       // Function to handle product approval or decline
    const handleDecision = (id, decision) => async () => {
        openBackdrop();// Open the backdrop
        try {
            let url = ``
            if (decision) {
                url = `/procureadmin/approveSingleProduct`; // URL for approving product
            }
            else {
                url = `/procureadmin/declineSingleProduct`; // URL for declining product
            }

            const res = await privateAxios.post(`${url}/${id}`);

            if (res.status === 200) {
                if (decision) {
                    showToast("success", "Product Approved Successfully"); // Show success toast for approval
                }
                else {
                    showToast("success", "Product Declined Successfully"); // Show success toast for decline
                }
            }

            getData(); // Refresh data
        } catch (error) {
            showToast("error", error.message);// Show error toast
        }

        closeBackdrop(); // Close the backdrop
    }

    // Function to handle sorting of data
    const handleSorting = (type, value) => {
        if (value === "All") {
            setSortedData(data);// Show all data
        }
        else {
         setSortedData(data.filter((dd) => dd[type] === value));// Filter data by type and value
        }
    }

    // Function to handle date sorting of data
    const dateSorting = (type, value) => {
        console.log(value);
        let sortedData = []

        if (value === "asc") {
            sortedData = [...data].sort((a, b) => {
                return new Date(a[type]) - new Date(b[type]);// Sort data in ascending order
            });
        }
        else {
            sortedData = [...data].sort((a, b) => {
                return new Date(b[type]) - new Date(a[type]);// Sort data in descending order
            });
        }
        setSortedData(sortedData);// Set sorted data to state
    }





    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>



                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <h1 style={{ gap: '10px' }}>
                        <BackButton/>
                            Products List
                            {/* <i style={{ fontSize: "15px" }}>({auth.roles})</i> */}
                            {/* <span>(ADMIN)</span> */}
                        </h1>
                        {/* <p>Available <span>{data.length}</span></p> */}
                        <p>Available <span>{data.length}</span></p>

                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
                        <input type="text"
                            onChange={(e) => setSearchValue(e.target.value)}

                            value={searchValue}
                            placeholder='Search Here...' />
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: 'auto', minWidth: 'auto' }} >
                        {/* <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: '450px', gap: '10px' }}> */}

                        <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                            <Tooltip title='Help'>
                            <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081305-products-request">
                            <HelpOutlineOutlinedIcon />
                            </a>
                            </Tooltip>
                        </div>
                    </div>

                </div>


                <div className={workSpaceStyle.workSpaceMainDiv}>

                    <table className={superadminStyle.admintable}>
                        <thead>
                            <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>

                                <th style={{ width: "40%" }}>
                                    <p onClick={() => toggleSorter("productName")}>Products
                                        <span>{sorterOpen === "productName" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "productName" && <TableSorterDropDown data={sorterData.productName} onClick={(item) => handleSorting("productName", item)} toggle={() => toggleSorter("productName")} />
                                    }
                                </th>
                                <th>


                                    <p onClick={() => toggleSorter("organisationName")}>Organisation From
                                        <span>{sorterOpen === "organisationName" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "organisationName" && <TableSorterDropDown data={sorterData.organisationName} onClick={(item) => handleSorting("organisationName", item)} toggle={() => toggleSorter("organisationName")} />
                                    }
                                </th>
                                <th>


                                    <p onClick={() => toggleSorter("requestedOn")}> Requested On
                                        <span>{sorterOpen === "requestedOn" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "requestedOn" && <TableSorterDropDown type="date" data={sorterData.requestedOn} onClick={(item) => dateSorting("requestedOn", item)} toggle={() => toggleSorter("requestedOn")} />
                                    }
                                </th>

                                <th style={{ textAlign: "center" }}>
                                    Action
                                </th>

                            </tr>
                        </thead>
                        <tbody>

                            {

                                sortedData?.filter((val) => val.organisationName.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                                    val.productName.toLowerCase().includes(searchValue.trim().toLowerCase()))
                                    .map((row, index) => {
                                        return (


                                            <tr className={superadminStyle.admintableRow} key={index}>

                                                <td>
                                                    {row.productName}
                                                </td>
                                                <td>
                                                    {row.organisationName}
                                                </td>
                                                <td>
                                                    {DateFormater(row?.requestedOn)}
                                                </td>


                                                <td>
                                                    <div className={superadminStyle.decisionBtnWrapper}>
                                                        {
                                                            row.isRequested ?
                                                                <>

                                                                    <button className={`${superadminStyle.rowDecisionbtn} ${superadminStyle.rowDecisionSecondarybtn}`} onClick={handleDecision(row.id, false)}>
                                                                        Decline
                                                                    </button>
                                                                    <button className={`${superadminStyle.rowDecisionbtn} ${superadminStyle.rowDecisionPrimarybtn}`} onClick={handleDecision(row.id, true)}>
                                                                        Approve
                                                                    </button>
                                                                </>
                                                                :
                                                                row.isApproved ?
                                                                    <>
                                                                        <button className={`${superadminStyle.rowDecisionbtn}`} style={{ fontWeight: "600", color: "#16469D" }}>
                                                                            Approved
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <button className={`${superadminStyle.rowDecisionbtn}`}>
                                                                            Declined
                                                                        </button>
                                                                    </>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )

                                    }
                                    )
                            }

                        </tbody>


                    </table>


                </div>






            </div>
        </>
    )
}

export default ProductRequest
