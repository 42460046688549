import React, { useState, useRef, useEffect } from 'react';
// Importing CSS modules for styling
import ProfileStyle from './ProfileStyle.module.css';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
// Importing Tooltip component from Material UI
import Tooltip from '@mui/material/Tooltip';
// Custom hook for authentication
import useAuth from 'hooks/useAuth';
// Hook to get the current location object, including state
import { useLocation } from 'react-router-dom';
// Importing Help icon from Material UI
import { HelpOutlineOutlined } from '@mui/icons-material';


const Profile = () => {
    // Destructuring auth object from useAuth hook
    const { auth } = useAuth();
    // Getting the current location
    const location = useLocation();
    // Extracting state from the location object
    const { state } = location;
    // State to store profile data
    const [data, setData] = useState([]);

     // Effect to set data state when component mounts or state changes
    useEffect(() => {
        if (state !== null) {// Check if state is not null
            setData(state.data);// Set the data state with state data
        }
    }, [])// Empty dependency array means this runs once after initial render


    return (
        <div className={workSpaceStyle.workSpaceMain}>

            <div className={ProfileStyle.profileMainContainer}>
                <div className={ProfileStyle.profileFirstRow}>
                    <div>
                        <h3>{data?.data?.name}</h3>
                    </div>
                    <div>
                        {auth.roles === 'USER' && (

                            <Tooltip title='Help'>
                                <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081394-user-profile-section">
                                    <HelpOutlineOutlined />
                                </a>
                            </Tooltip>
                        )}
                    </div>
                </div>


                <div className={ProfileStyle.profileMainRow}>
                    <form action='' className={ProfileStyle.ProfileInfoTable}>
                        <div className={ProfileStyle.formInfoColumn}>

                            <div className={ProfileStyle.formGroup}>
                                <label>Name</label>
                                <input type='text' placeholder='Name'
                                    value={data?.data?.name}  // Displaying the name
                                    disabled />
                            </div>
                            <div className={ProfileStyle.formGroup}>
                                <label>Email</label>
                                <input type='email' placeholder='Email'
                                    value={data?.email}  // Displaying the email
                                    disabled />
                            </div>
                            <div className={ProfileStyle.formGroup}>
                                <label>Role</label>
                                <input type='text' placeholder='Role'
                                    value={data?.data?.role?.name} // Displaying the role
                                    disabled />
                            </div>
                        </div>
                        <div className={ProfileStyle.formInfoColumn}>
                            <div className={ProfileStyle.formGroup}>
                                <label>Mobile Number</label>
                                <input type='text' placeholder='Phone'
                                    value={data?.data?.phone} // Displaying the phone number
                                    disabled />
                            </div>
                            <div className={ProfileStyle.formGroup}>
                                <label>Designation</label>
                                <input placeholder='Designation'
                                    value={data?.data?.designation?.name} // Displaying the designation
                                    disabled />
                            </div>
                        </div>
                    </form>
                    <div className={ProfileStyle.ProfilePictureBox}>
                        <figure>
                            {data?.data?.profilePic?.newImage ? // Check if profile picture exists
                                <img
                                    style={{ width: '150px', height: '150px', borderRadius: '50%' }}
                                    src={`${process.env.REACT_APP_BASEURL}/adminuser/file/${data?.data?.profilePic?.newImage}`}  // Display profile picture
                                /> :
                                <div className={ProfileStyle.initialsContainer}>
                                    <p className={ProfileStyle.initials}>{data?.data?.name.charAt(0)}</p>
                                </div>
                            }

                            <figcaption>
                                {/* <button 
                            onClick={handleImageUpload}
                            >Edit Photo</button> */}
                            </figcaption>

                        </figure>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Profile;



