import React from "react";
import { useLocation, Navigate, Outlet, useParams } from "react-router-dom";// Import necessary hooks from react-router-dom
import useAuth from "../hooks/useAuth"; // Import custom hook to get authentication details
import jwt_decode from 'jwt-decode'; // Import jwt_decode library to decode JWT tokens
import { useDispatch } from 'react-redux'; // Import useDispatch hook from react-redux
import { setLogout } from '../state/AuthState';// Import action creator to logout user

const RequireSuperAdminAuth = () => {
    const { auth } = useAuth(); // Get authentication details using custom hook
    const location = useLocation(); // Get current location
    const { client } = useParams(); // Get URL parameters
    const dispatch = useDispatch(); // Get dispatch function from redux store

    // Check if user is authenticated, has SUPERADMIN role, and stageCount is 5
    if (!auth || !auth.token || auth.roles !== "SUPERADMIN" || auth?.userData?.stageCount !== 5) {
         // Redirect to login page if conditions are not met
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
  // Decode JWT token and check if it's expired
    const payloadToken = jwt_decode(auth.token);
    const isTokenExpired = Date.now() >= payloadToken.exp * 1000;

    if (!isTokenExpired) {
        // If token is not expired, render child routes
            return <Outlet />;
    } else {
        // If token is expired, dispatch logout action and redirect to login page
        dispatch(setLogout());
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
};

export default RequireSuperAdminAuth;
