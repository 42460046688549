import * as React from 'react';
import { useState, useEffect } from 'react';
import TaskStyle from "./TaskDisplay.module.css"
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Hidden, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PrivateComponent } from 'api/axios'
import PersonIcon from '@mui/icons-material/Person';
import workSpaceStyle from '../../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import categoryData from '../../../api/categories.json'
import useAuth from 'hooks/useAuth';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import useBackdrop from "hooks/useBackdrop";
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import { format } from 'date-fns';
import FetchUsersComponent from 'components/FetchUsersComponent/FetchUsersComponent'
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';


const optionList = [
  { taskId: 1, name: "Option 1" },
  { taskId: 2, name: "Option 2" },
  { taskId: 3, name: "Option 3" },
];

const styleSelectEmails = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  border: 'none',
  p: 2,
};



const TaskDisplay2 = ({ getAllKam, search, handlecreateTaskModal, setEditData, handleEditTaskModal, updatedData, priorityData, assignData, editData, loadMore, taskPage, setTaskPage, }) => {
  // Initialize state variables
  const privateAxios = PrivateComponent();
  const [sortedData, setSortedData] = useState([]);
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
  const { auth } = useAuth()
  const navigate = useNavigate();
  const [deleteKamModal, setDeleteKamModal] = useState(false);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [prioritySelectorOpen, setPrioritySelectorOpen] = useState('');
  const [statusSelectorOpen, setStatusSelectorOpen] = useState('');
  const [selectedEmailTaskId, setSelectedEmailTaskId] = useState("")

  const taskData = categoryData;
  const [multiClick, setMultiClick] = useState(false);
  const handleMultiClick = () => {
    setMultiClick(prev => !prev)
  }
  const [multiClick2, setMultiClick2] = useState(false);
  const handleMultiClick2 = () => {
    setMultiClick2(prev => !prev)
  }
  const [multiClick3, setMultiClick3] = useState(false);
  const handleMultiClick3 = () => {
    setMultiClick3(prev => !prev)
  }
  const [multiClick4, setMultiClick4] = useState(false);
  const handleMultiClick4 = () => {
    setMultiClick4(prev => !prev)
  }

  const [data, setData] = useState([])

  // Initialize state variables for email selection
  const [selectedEmails, setSelectedEmails] = useState([]);

  // New state for email selection modal
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [selectedEmailsDisplay, setSelectedEmailsDisplay] = useState([]);

  // Fetch necessary data
  const workspaceId = 1;
  const [userList, allEmails, notLoggedIn] = FetchUsersComponent({ orgId: auth.orgId, workspaceId: workspaceId })

  // Function to handle opening email selection modal
  const handleEmailModalOpen = (emailArr, taskId) => {
    const emails = emailArr.filter((err) => err.disabled === false).map((per) => { return per.email })
    setSelectedEmails(emails);
    setSelectedEmailsDisplay(emails)
    setSelectedEmailTaskId(taskId)
    setEmailModalOpen(true);
  };

  // Effect to reset selected email task ID when email modal is closed
  useEffect(() => {
    if (emailModalOpen === false) {
      setSelectedEmailTaskId("");
    }
  }, [emailModalOpen])

  // Function to handle closing email selection modal
  const handleEmailModalClose = async () => {
    setEmailModalOpen(false);
  };

  // Effect to update sorted data when updatedData changes
  useEffect(() => {
    if (updatedData) {
      setSortedData(updatedData);
    }
  }, [updatedData])



  // ------------------------------------get All users(assign)-----------------------------------
  const [loadingKam, setLoadingKam] = useState(false);

  // Function to handle checkbox change for email selection
  const handleEmailCheckboxChange = (email) => {
    // Toggle the selected email on checkbox change
    setSelectedEmails(prev => {
      if (prev.includes(email)) {
        return prev.filter((em) => em != email)
      }
      else {
        return [...prev, email]
      }
    })
  };

  // Function to add or remove assigned emails to a task
  const handleAssignEmailChange = async (taskId) => {
    if (selectedEmails.length > 0 && taskId !== "") {
      openBackdrop();
      try {
        // API call to add or remove emails
        await privateAxios
          .post(`/task/addUsersToTask/${taskId}`, { emails: selectedEmails })
          .then((res) => {
            getAllKam();
            setEmailModalOpen(false);

            if (selectedEmails && selectedEmailsDisplay) {
              // Logic to determine added and deleted emails
              const addedEmails = selectedEmails.filter(email => !selectedEmailsDisplay.includes(email));
              const deletedEmails = selectedEmailsDisplay.filter(email => !selectedEmails.includes(email));

              let previousLogs = '';
              let newLogs = '';

              if (!arraysAreEqual(selectedEmails, selectedEmailsDisplay)) {
                if (addedEmails.length > 0 && deletedEmails.length === 0) {
                  previousLogs += `Email${addedEmails.length > 1 ? 's' : ''} ${addedEmails.join(', ')} ${addedEmails.length > 1 ? 'were' : 'was'} not present. `;
                  newLogs += `Email${addedEmails.length > 1 ? 's' : ''} ${addedEmails.join(', ')} ${addedEmails.length > 1 ? 'were' : 'was'} added. `;
                } else if (deletedEmails.length > 0 && addedEmails.length === 0) {
                  previousLogs += `Email${deletedEmails.length > 1 ? 's' : ''} ${deletedEmails.join(', ')} ${deletedEmails.length > 1 ? 'were' : 'was'} present. `;
                  newLogs += `Email${deletedEmails.length > 1 ? 's' : ''} ${deletedEmails.join(', ')} ${deletedEmails.length > 1 ? 'were' : 'was'} removed. `;
                } else {
                  previousLogs += `Email${deletedEmails.length > 1 ? 's' : ''} ${deletedEmails.join(', ')} ${deletedEmails.length > 1 ? 'were' : 'was'} present but removed now. `;
                  newLogs += `Email${addedEmails.length > 1 ? 's' : ''} ${addedEmails.join(', ')} ${addedEmails.length > 1 ? 'were' : 'was'} added. `;
                }
                // Prepare payload to send for logging
                const payloadToSend = [{
                  name: auth.name,
                  logDetails: `${auth.name} updated the emails`,
                  previousLogs,
                  newLogs,
                }];
                // Call function to post task history
                handlePostTaskHistory(payloadToSend, taskId)
              }
            }
          })
          .catch((err) => {
          });
      } catch (error) {
      }
      closeBackdrop();
    } else {
    }
  };

  // Function to check if two arrays are equal
  function arraysAreEqual(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
    return true;
  }



  // --------------------------------------------------------------------------------------------

  // Function to handle selection of an option
  const handleChoose = (selectedId) => {
    setSelectedOptionId(selectedId);
  };

  // Function to toggle the delete modal
  const handleDeleteModal = () => {
    setDeleteKamModal(prev => !prev);
  }

  // State and function to handle selection of an option
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  // Functions to handle opening/closing priority and status selectors
  const handlePrioritySelector = (index) => {
    if (prioritySelectorOpen === index) {
      setPrioritySelectorOpen('')
    } else {
      setPrioritySelectorOpen(index)
    }
  }


  const handleStatusSelector = (index) => {
    if (statusSelectorOpen === index) {
      setStatusSelectorOpen('')
    } else {
      setStatusSelectorOpen(index)
    }
  }


  // Function to update task status
  const handleStatusChange = async (taskId, oldValue, value) => {
    // Check if status has changed
    if (oldValue.toLowerCase() !== value.toLowerCase().replace(/ /g, '')) {
      try {
        let param = 'ALL';

        // Map status values to API parameters
        switch (value.toLowerCase()) {
          case 'to do':
            param = 'TODO';
            break;
          case 'in progress':
            param = 'INPROGRESS';
            break;
          case 'done':
            param = 'DONE';
            // Update completedDate to today's date
            const todayDate = format(new Date(), 'yyyy-MM-dd');
            await privateAxios.post(`/task/updateTaskStatus/${taskId}?status=${param}&completedDate=${todayDate}`).then((res) => {

              const payloadToSend = [{
                name: auth.name,
                logDetails: `${auth.name} updated the status`,
                previousLogs: oldValue,
                newLogs: res.data.status,
              }]
              handlePostTaskHistory(payloadToSend, taskId);
              window.location.reload();
              getAllKam();
            });
            break;
          case 'completed':
            param = 'COMPLETED';
            break;
          default:
            param = 'ALL';
        }

        // For other statuses, don't update completedDate
        if (value.toLowerCase() !== 'done') {
          await privateAxios.post(`/task/updateTaskStatus/${taskId}?status=${param}`).then((res) => {
            const payloadToSend = [{
              name: auth.name,
              logDetails: `${auth.name} updated the status`,
              previousLogs: oldValue,
              newLogs: res.data.status,
            }]

            console.log(payloadToSend)

            handlePostTaskHistory(payloadToSend, taskId);
            window.location.reload();
            getAllKam();
          });
        }
      } catch (error) {
        // console.log(error);
      }
    }

  };
  // -----------------------------------------Update Priority Api---------------------------------------------------

  // Function to update task priority
  const handlePriorityChange = async (taskId, oldValue, value) => {
    if (oldValue.toLowerCase() !== value.toLowerCase().replace(/ /g, '')) {
      try {
        // console.log("This is handling" + taskId)
        let param = "ALL"
        switch (value.toLowerCase()) {
          case "low": param = "LOW";
            break;
          case "medium": param = "MEDIUM";
            break;
          case "high": param = "HIGH";
            break;
          default: param = "ALL"
        }
        await privateAxios.post(`/task/updateTaskPriority/${taskId}?priority=${param}`).then(res => {
          window.location.reload();
          const payloadToSend = [{
            name: auth.name,
            logDetails: `${auth.name} updated the priority`,
            previousLogs: oldValue,
            newLogs: res.data.priority,
          }]

          handlePostTaskHistory(payloadToSend, taskId);
          getAllKam();

        })
      } catch (error) {
        // console.log(error)
      }
    }
  };

  // -------------------------------------------------New Sortting----------------------------------------------------------------------

  // Functions to handle sorting by start date
  const [isStartDateDropdownOpen, setIsStartDateDropdownOpen] = useState(false);

  const toggleStartDateDropdown = () => {
    setIsStartDateDropdownOpen(!isStartDateDropdownOpen);
  };

  const handleStartDateSort = (sortType) => {
    let sortedItems = [...updatedData];

    if (sortType === 'Newest') {
      sortedItems.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    } else if (sortType === 'Oldest') {
      sortedItems.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    }

    setSortedData(sortedItems);

    // Close the dropdown
    setIsStartDateDropdownOpen(false);
  };


  // Functions to handle sorting by end date
  const [isEndDateDropdownOpen, setIsEndDateDropdownOpen] = useState(false);

  const toggleEndDateDropdown = () => {
    setIsEndDateDropdownOpen(!isEndDateDropdownOpen);
  };

  const handleEndDateSort = (sortType) => {
    let sortedItems2 = [...updatedData];
    if (sortType === 'Newest') {
      sortedItems2.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
    } else if (sortType === 'Oldest') {
      sortedItems2.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
    }
    setSortedData(sortedItems2);
    setIsEndDateDropdownOpen(false);
  };



  // Effect to handle email icon click
  const [isEmailIconClicked, setEmailIconClicked] = useState(false);

  useEffect(() => {
    if (isEmailIconClicked) {
      // Reset the flag to false after the API call
      setEmailIconClicked(false);
    }
  }, [isEmailIconClicked]);


  // State and function to handle temporary task ID
  const [tempTaskId, setTempTaskId] = useState("");

  // Function to delete a task
  const deleteTask = async () => {
    try {
      privateAxios.delete(`/task/deleteTask/${tempTaskId}`)
        .then((res) => {
          if (res.status === 200) {
            window.location.reload();
          }
        });

    } catch (error) {
      console.error('Error deleting Task:', error);
    }
  };

  // Function to post task history
  const handlePostTaskHistory = async (payloadToSend, taskId) => {
    try {
      await privateAxios.post(`/task/getTaskHistory/${taskId}`, payloadToSend).then((res) => {
      })
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <div className={TaskStyle.table1}>
      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr className={TaskStyle.theader}>
            <th>Task Id</th>
            <th style={{ textAlign: "left" }}>Title</th>
            <th>
              <div className={TaskStyle.SortingDate}>
                <p onClick={toggleStartDateDropdown} >
                  Start Date
                  <span className=''>
                    <ImportExportIcon />
                  </span>
                </p>
                {isStartDateDropdownOpen &&
                  <ul >
                    <li onClick={() => handleStartDateSort('Newest')}>Newest</li>
                    <li onClick={() => handleStartDateSort('Oldest')}>Oldest </li>
                  </ul>
                }
              </div>
            </th>
            {/* <th>End Date</th> */}

            <th >
              <div className={TaskStyle.SortingDate}>
                <p onClick={toggleEndDateDropdown}>
                  End Date
                  <span className=''>
                    <ImportExportIcon />
                  </span>
                </p>
                {isEndDateDropdownOpen &&
                  <ul >
                    <li onClick={() => handleEndDateSort('Newest')}>Newest</li>
                    <li onClick={() => handleEndDateSort('Oldest')}>Oldest </li>
                  </ul>
                }
              </div>
            </th>
            <th>E/C Date</th>
            <th>Status</th>
            <th>Assigned To</th>
            <th>Label</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>

      <div className={TaskStyle.addTask}
        onClick={handlecreateTaskModal} >
        <Tooltip title='New Task'>
          <AddCircleOutlineOutlinedIcon />
        </Tooltip>
        <p>Create a task</p>
      </div>

      <table style={{ borderCollapse: 'collapse' }}>
        <tbody >
          {
            sortedData.filter((item) =>

              item?.title?.trim().toLowerCase().includes(search?.toLowerCase())
            ).map((item, index) =>

              <tr key={item.taskId} className={TaskStyle.tbody}>
                <td >{index + 1}</td>
                <td onClick={() => { setEditData(item); handleEditTaskModal(); }}>{item.title}</td>

                <td>{item.startDate}</td>
                <td>{item.endDate}</td>
                <td>{item.completedDate}</td>

                <td className={TaskStyle.SelectBar} style={{ backgroundColor: '#d2d2d2' }}>

                  <div onClick={() => handleStatusSelector(index)} className={TaskStyle.statusSelector}>
                    <p onClick={handleMultiClick2} style={{ color: '#000' }}

                    >{item.status.substring(0, 1).toUpperCase() + item.status.substring(1, item.status.length).toLowerCase()}

                      <span>
                        {statusSelectorOpen === index
                          ? <KeyboardArrowUpIcon />
                          : <KeyboardArrowDownIcon />
                        }
                        {/*  */}
                      </span>
                    </p>

                    {statusSelectorOpen === index &&
                      <ul>
                        <li onClick={() => handleStatusChange(item.taskId, item.status, "To Do")}>To do</li>
                        <li onClick={() => handleStatusChange(item.taskId, item.status, "In Progress")}>In Progress</li>
                        <li onClick={() => handleStatusChange(item.taskId, item.status, "Done")}>Done</li>
                        <li onClick={() => handleStatusChange(item.taskId, item.status, "Completed")}>Completed</li>
                      </ul>
                    }
                  </div>


                </td>

                <td>
                  <div className={TaskStyle.addAssignMain}>
                    <div className={TaskStyle.addAssign}>


                      {item.assignTo.slice(0, 2).map((iit) => {

                        const colorArray = ["#0077b6", "#f77f00", "#a7c957"]
                        const randomIndex = Math.floor(Math.random() * colorArray.length);

                        const randomColor = colorArray[randomIndex]
                        if (iit?.disabled === false) {
                          return (

                            <div className={TaskStyle.name1} style={{ backgroundColor: randomColor }}>
                              {/* {iit?.email?.charAt(0).substring(0, 1).toUpperCase()} */}
                              <ProfileIcon data={{ email: iit?.email }} height={"26px"} width={"26px"} showProfileBanner={false} />
                            </div>
                          )
                        }
                      })}
                      <div className={TaskStyle.name3}>
                        <Tooltip title='Add Member'>
                          <DataSaverOnOutlinedIcon onClick={() => {
                            handleEmailModalOpen(item.assignTo, item.taskId);
                            setEmailIconClicked(true); // Set the flag to true when clicked
                          }} />
                        </Tooltip>
                      </div>

                    </div>
                  </div>
                </td>

                <td className={TaskStyle.SelectBar}>
                  <div onClick={() => handlePrioritySelector(index)} className={TaskStyle.prioritySelector}>
                    <p onClick={handleMultiClick}
                      style={
                        {
                          backgroundColor: item.priority === 'HIGH' ? '#ec5252' : item.priority === 'MEDIUM' ? '#f5ac38' : '#b3f289',

                        }
                      }
                    >{item.priority.substring(0, 1).toUpperCase() + item.priority.substring(1, item.priority.length).toLowerCase()}
                      <span>
                        {prioritySelectorOpen === index
                          ? <KeyboardArrowUpIcon />
                          : <KeyboardArrowDownIcon />
                        }

                      </span>
                    </p>
                    {prioritySelectorOpen === index &&
                      <ul>
                        <li style={{ backgroundColor: "#ec5252", color: '#fff' }} onClick={() => handlePriorityChange(item.taskId, item.priority, "High")}>High</li>
                        <li style={{ backgroundColor: "#f5ac38", color: '#fff' }} onClick={() => handlePriorityChange(item.taskId, item.priority, "Medium")}>Medium</li>
                        <li style={{ backgroundColor: "#b3f289", color: '#fff' }} onClick={() => handlePriorityChange(item.taskId, item.priority, "Low")}>Low</li>
                      </ul>
                    }
                  </div>

                </td>

                <td  >
                  <div className={TaskStyle.icons} >
                    <Link onClick={(e) => { e.preventDefault(); setEditData(item); handleEditTaskModal(); }}><Tooltip title='Edit Task'><EditOutlinedIcon /></Tooltip></Link>
                    <Link><Tooltip title='Delete Task'>
                      <DeleteOutlineOutlinedIcon onClick={() => { handleDeleteModal(); setTempTaskId(item.taskId) }} />
                    </Tooltip></Link>
                  </div>
                </td>

              </tr>
            )
          }


        </tbody>
      </table>


      {sortedData.length !== 0 && sortedData.length % 10 === 0 &&
        <p className={TaskStyle.loadmoreBtn} onClick={() => { loadMore((taskPage), 10) }}>load more</p>}
      <Modal
        open={emailModalOpen}
        onClose={handleEmailModalClose}>
        <div className={workSpaceStyle.modalCont} style={{ width: '410px', minHeight: 'auto' }}>

          <Box className={TaskStyle.taskModalDisplay}
            style={{ width: "100%" }}
          >
            <span >
              <PersonIcon sx={{ color: '#000' }} /><p>Add member</p>
            </span>
            <Typography variant="h6"> Board member</Typography>
            <div className={TaskStyle.emailSelection}>

              {loadingKam ? (
                <p>Loading users...</p>
              ) : (
                userList?.map((email, index) => {

                  return (
                    <label key={index}>
                      {email}
                      <input style={{ width: '20px' }} type="checkbox"
                        checked={selectedEmails.includes(email)}
                        onChange={() => handleEmailCheckboxChange(email)}
                      />
                    </label>
                  )
                }
                )
              )}

              {notLoggedIn?.map((email, index) => {
                return (
                  <label>{email}
                    <input style={{ width: '90px' }} disabled value={"Not Logged In"}></input>
                  </label>
                )
              })}

              <div><Button variant='contained' style={{ width: "20%", height: "30px", borderRadius: '6px', backgroundColor: '#16469d', color: '#fff', cursor: 'pointer', border: 'none', textTransform: 'capitalize' }}
                onClick={() => handleAssignEmailChange(selectedEmailTaskId)}
                disabled={selectedEmails.length === 0} >Save</Button></div>

            </div>

          </Box>
        </div>
      </Modal>

      <DeleteConfirmation
        onCancel={handleDeleteModal}
        onConfirm={deleteTask}
        open={deleteKamModal}
      >
        <h2>Delete Task</h2>
        <p>Are you sure you want to delete task?</p>

      </DeleteConfirmation>

      <BackdropComponent />
    </div>
  );
}
export default TaskDisplay2