import { React, useState, useEffect } from "react";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import usersStyle from "../Users/Users.module.css"
import { useNavigate } from "react-router-dom";
import { PrivateComponent } from 'api/axios'
import useBackdrop from 'hooks/useBackdrop';
import { Button, Tooltip } from "@mui/material";
import useToast from "hooks/useToast";
import useAuth from "hooks/useAuth";
import { HelpOutlineOutlined } from "@mui/icons-material";

const OrganisationsList = () => {
  // Importing necessary hooks and functions
  const { Toast, showToast } = useToast(); // Using custom hook for toast notifications
  const navigate = useNavigate(); // Using hook for navigation
  const [searchValue, setsearchValue] = useState(""); // State variable for search value
  const privateAxios = PrivateComponent(); // Initialize private axios instance using custom hook
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Using custom hook for backdrop functionalities
  const { auth } = useAuth(); // Using custom hook for authentication
  const [organisationData, setOrganisationData] = useState([]); // State variable to store organisation data

  // Function to fetch all organisation data
  const getAllOrgData = async () => {
    openBackdrop(); // Open backdrop to indicate loading
    try {
      const response = await privateAxios.get(`/vendor/getAllVendorOrganisation`)
        .then((res) => {
          let data = res.data
          setOrganisationData(data); // Set organisation data after fetching
          if (res.status === 200) {
            const data = res.data; // Not sure why this is declared again, seems redundant
          }
          closeBackdrop(); // Close backdrop after successful operation
        }).catch((error) => {
          // console.log(error)
          closeBackdrop(); // Close backdrop in case of error
        })
    } catch (error) {
      // console.log(error)
      closeBackdrop(); // Close backdrop in case of error
    }
  }


  // Function to handle acceptance of an organisation
  const handleAccept = async (orgId) => {
    try {
      const res = await privateAxios.post(`/vendor/acceptOrganisation/${auth.userData.id}/${orgId}`)
      showToast("success", "Organisation Approved"); // Show success toast notification
      getAllOrgData(); // Refresh organisation data
    } catch (error) {
      showToast("error", "Error In the Action"); // Show error toast notification
    }
  };

  // Function to handle declining an organisation
  const handleDecline = async (orgId) => {
    try {
      const res = await privateAxios.post(`/vendor/declineOrganisation/${auth.userData.id}/${orgId}`)
      showToast("success", "Organisation Declined"); // Show success toast notification
      getAllOrgData(); // Refresh organisation data
    } catch (error) {
      showToast("error", "Error In the Action"); // Show error toast notification
    }
  }

  // Function to handle deleting an organisation
  const handleDelete = async (orgId) => {
    try {
      const res = await privateAxios.post(`/vendor/disableOrganisation/${auth.userData.id}/${orgId}`)
      showToast("caution", "Organisation Deleted"); // Show caution toast notification
      getAllOrgData(); // Refresh organisation data
    } catch (error) {
      showToast("error", "Error In the Action"); // Show error toast notification
    }
  };


  // Effect hook to fetch organisation data on component mount
  useEffect(() => {
    getAllOrgData(); // Fetch all organisation data
  }, []);


  return (
    <>
      <Toast />
      <div className={workSpaceStyle.workSpaceMain}>
        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1><PeopleOutlineIcon />Organisations</h1>
          </div>
          <div className={workSpaceStyle.workSpaceHeaderSearchbar}>
            <input
              type="text"
              placeholder='Search organisations...'
              onChange={(e) => setsearchValue(e.target.value)}
              value={searchValue}
            />
          </div>
          <div className={`${workSpaceStyle.WorkSpaceHeaderhelp} `}>

            <Tooltip title='Help'>
              <a target="_blank" href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081323-my-tasks-organisation-">
                <HelpOutlineOutlined />
              </a>
            </Tooltip>
          </div>
        </div>


        <div>
          <ul className={usersStyle.VendorheaderStyleOrg}>
            <li>Organisations Name</li>
            <li>GST/VAT No.</li>
            <li></li>
          </ul>
          {

            organisationData?.filter((item) =>
              item.orgName.trim().toLowerCase().includes(searchValue.toLocaleLowerCase()))
              .map((item) => {
                // const isApproved = item.isApproved;
                // const isDenied = item.isDenied;

                return (
                  <ul key={item.orgId} className={usersStyle.StyleOrganisationVendor} style={{ cursor: 'pointer' }}>
                    <div>

                      <li onClick={() => { item.isApproved && navigate(`${item.orgName.toLowerCase().replace(/ /g, "-")}/${item.id}`) }} > {item.orgName} </li>
                      <li> {item.gstDetails}</li>

                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <li className={usersStyle.requestButton}>
                        {
                          (!item.isDenied && !item.isApproved) ?
                            <div>
                              <Button variant="text" onClick={() => handleDecline(item.id)}>
                                Decline
                              </Button>
                              <Button variant="contained" onClick={() => handleAccept(item.id)}>
                                Accept
                              </Button>
                            </div>
                            :
                            (!item.isApproved && item.isDenied) ?
                              <p>Declined</p> :
                              <div style={{ padding: '0px 20px' }}>
                                <Button variant="contained" onClick={() => handleDelete(item.id)}>
                                  Delete
                                </Button>
                              </div>
                        }
                      </li>


                    </div>

                  </ul>
                )
              })

          }
        </div>
        <BackdropComponent />
      </div>
    </>
  )
}
export default OrganisationsList;