import React from 'react'
import threeDotStyle from './ThreeDotDropDown.module.css';

const ThreeDotDropDown = ({children,style,toggle}) => {
  return (
    <>
    <div className={threeDotStyle.backDrop} onClick={toggle}></div>
    <ul className={threeDotStyle.threeDotMain} style={style}>
      {children}
    </ul>
    </>
  )
}

export default ThreeDotDropDown
