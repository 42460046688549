import React from 'react'
import useAuth from 'hooks/useAuth'
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
const Logout = () => {

    const {setLogout } = useAuth();
    const dispatch=useDispatch();

    useEffect(()=>{
        dispatch(setLogout());
    },[])
  return (
    <div>
      Loged Out
      <Link to={"/login"}> Login</Link>
    </div>
  )
}

export default Logout
