import React, { useEffect, useState } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import superadminStyle from '../../commonCss.module.css';
import Tooltip from '@mui/material/Tooltip';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';
import DateFormater from 'utility/DateFormater';
import BackButton from 'components/BackButton/BackButton';
import useAuth from 'hooks/useAuth';


const OrganisationRequest = () => {
  // Custom hooks
  const { auth } = useAuth();
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
  const { showToast, Toast } = useToast();
  const privateAxios = PrivateComponent();
  const navigate = useNavigate();

  // State for search value and data
  const [searchValue, setSearchValue] = React.useState('');
  const [data, setData] = useState([]);

  // Function to fetch data
  const getData = async () => {
    openBackdrop();// Show backdrop

    try {
      const res = await privateAxios.get(`/procureadmin/getAllOrganisationRequests`);
      const { data } = res;
      console.log(data);
      setData(data);

    } catch (error) {
      showToast("error", "Error Getting data"); // Show error toast
    }

    closeBackdrop();// Close backdrop
  }

  // Fetch data on component mount
  useEffect(() => {
    getData();
  }, [])


  return (
    <>
      <Toast />
      <BackdropComponent />
      <div className={workSpaceStyle.workSpaceMain}>



        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1 style={{ gap: '10px' }}> <BackButton />
              Organisations Request
            </h1>
            <p>Available <span>{data.length}</span></p>

          </div>
          <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
            <input type="text"
              onChange={(e) => setSearchValue(e.target.value)}

              value={searchValue}
              placeholder='Search Here...' />
          </div>
          <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: 'auto', minWidth: 'auto' }} >

            <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
              {auth.roles === 'SUPERADMIN' && (

                <Tooltip title='Help'>
                  <a target='_blank' href='https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081298-organization-list'>

                    <HelpOutlineOutlinedIcon />
                  </a>
                </Tooltip>
              )}
            </div>
          </div>

        </div>

        <div className={workSpaceStyle.workSpaceMainDiv}>

          <table className={superadminStyle.admintable}>
            <thead>
              <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
                <th> Organisation</th>
                <th> KAM Name </th>
                <th> Email </th>
                <th> Phone</th>
                <th> Registerd On </th>
                <th> Action</th>
              </tr>
            </thead>
            <tbody>

              {data?.filter((val) => val.organisationName.toLowerCase().includes(searchValue.trim().toLowerCase())
                || val.kamName.toLowerCase().includes(searchValue.trim().toLowerCase()))
                .map((row, index) => {
                  return (
                    <tr className={superadminStyle.admintableRow} key={index}>
                      <td >
                        {row.organisationName}
                      </td>
                      <td>
                        {row.kamName}
                      </td>
                      <td>
                        {row.kamEmail}
                      </td>
                      <td>
                        {row.phone}
                      </td>
                      <td>
                        {DateFormater(row.registeredOn)}
                      </td>
                      <td>
                        <div className={superadminStyle.actionIcons}>
                          <span onClick={() => navigate(`/superadmin/organisation/organisation-approval/${row.kamId}/${row.id}`)}>
                            <RemoveRedEyeIcon />
                          </span>
                        </div>
                      </td>
                    </tr>
                  )
                })
              }

            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default OrganisationRequest
