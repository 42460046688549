import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import revisionEditStyle from '../../pages/RevisionEdit/RevisionEdit.module.css'
import useAuth from 'hooks/useAuth';

const TempleteDescriptionTab = ({setRevisionDescription,revisionDescription,descriptionVendorRemark,setDescriptionVendorRemark}) => {
  const {auth}=useAuth();
  return (
    <div className={revisionEditStyle.specficationTabMain} style={{display:'flex',gap:'20px'}}>
   
    <div className={revisionEditStyle.richEditor}>
      <ReactQuill modules={{toolbar:false}} theme="snow" value={revisionDescription} readOnly />      
    </div>


    <div style={{display:"flex",flexDirection:"column",gap:"8px"}}>
      <h3 style={{fontSize:"16px"}}>Vendor Remark</h3>
      <textarea name="" id="" cols="30" rows="10" style={{minHeight:'20vh',maxHeight:'50vh',overflowY:'scroll',padding:"10px",textIndent:"0px"}} 
      value={descriptionVendorRemark} 
      onChange={(e) => {
        if (auth.roles === 'VENDOR') {
          setDescriptionVendorRemark(e.target.value);
        } else {
          e.preventDefault();
        }
      }}
      readOnly={auth.roles !== 'VENDOR'}
      >

      </textarea>
    </div>

    </div>
  )
}

export default TempleteDescriptionTab
