import React from 'react'
import styleHead from "./ProcaHeader.module.css"
import Button from '@mui/material/Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';


const ProcaHeader = () => {
    const navigate = useNavigate();
    return (
        <div className={styleHead.HeaderMain}>
            <div className={styleHead.heading}>
                <p>Features</p>
                <h1>Why use Proca?</h1>
                <p>Senectus et netus et malesuada fames ac turpis. Sagittis vitae et leo duis ut diam.</p>
                <div className={styleHead.headButton}>
                    <Button variant="contained" onClick={() => { navigate('/login') }}>Get free trial</Button>
                </div>
            </div>
            <div className={styleHead.headerImg}>
                    <img src="https://res.cloudinary.com/dtffniutw/image/upload/v1698661911/QUOTES_Recieved_from_user_1_j7xpqb.png" alt="" />
            </div>
        </div>
    )
}

export default ProcaHeader
