// Import necessary React components and hooks
import React from 'react'
// Import CSS styles from specific modules
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import revisionEditStyle from './RevisionEdit.module.css'
// Import MUI components for icons and tooltips
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
// Import React Router hooks for navigation and URL parameters
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// Import custom hook for authentication
import useAuth from 'hooks/useAuth';
// Import React's useState and useEffect hooks
import { useState, useEffect } from 'react';
// Import custom components for tabs
import SpecificationTab from './SpecificationTab';
import AttributeTab from './AttributeTab';
import DocumentsTab from './DocumentsTab';
// Import React Router hook for location
import { useLocation } from 'react-router-dom';
// Import API component and utility functions
import { PrivateComponent } from 'api/axios'
import { createExcel } from 'utility/excelUtils'
// Import custom hook for backdrop loading state
import useBackdrop from 'hooks/useBackdrop';
// Import back button component
import BackButton from 'components/BackButton/BackButton';

// Main component definition
const RevisionViewQuotesSent = () => {
  // Destructure URL parameters using useParams hook
  const { taskId, productId, revisionId } = useParams();
  // Destructure methods from custom useBackdrop hook
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
  // Get authentication context using custom hook
  const { auth } = useAuth();
  // Initialize private Axios instance
  const privateAxios = PrivateComponent();
  // Get location and state from useLocation hook
  const location = useLocation();
  const { state } = location;
  // Create URLSearchParams object for query parameters
  const searchParams = new URLSearchParams(location.search);

  // Define state variables for component
  const [tabActive, setTabActive] = useState(0 || searchParams.get("tab")); // Active tab index
  const [multiSave, setMultiSave] = useState(false); // Multi-save state
  const navigate = useNavigate(); // Navigation hook
  const [productDetails, setProductDetails] = useState({}); // Product details
  const [documents, setDocuments] = useState([]); // Documents array
  const [renameModal, setRenameModal] = useState(false); // Rename modal state
  const [approvalModal, setApprovalModal] = useState(false); // Approval modal state

  // Function to toggle approval modal
  const handleApprovalModal = (data) => {
    setApprovalModal(prev => !prev);
  };
  // -----------------------Attribute Data----------------------


  // Initial columns for attributes table
  const initialColumns = [
    { id: "-1", name: "Sr No.", editable: false }, { id: "-1", name: "Name", editable: false }, { id: "-1", name: "Type", editable: false }, { id: "-1", name: "Unit", editable: false }, { id: "-1", name: "Value", editable: false }, { id: "-1", name: "Remark", editable: false }
  ];


  // State variables for columns and table rows
  const [columns, setColumns] = useState(initialColumns);
  const [tableRows, setTableRows] = useState([]);
  const [specificationValue, setSpecificationValue] = useState("")// Specification value


  // Function to fetch data from API
  const getDataProcure = async () => {
    openBackdrop()
    try {
      // Make API request to get revision data by ID
      const response = await privateAxios.get(`/technicalSpecification/getQuoteSentRevisionById/${revisionId}`);
      let data = response.data;
      // console.log(data);

      // Parse and update additional fields in product attributes
      const updatedProAttri = data.productAttributes.map((dd) => {
        let addFields = dd.additionalFields;
        if (typeof addFields === 'string') {
          try {
            addFields = JSON.parse(addFields);
          } catch (error) {
            // console.log("Error parsing additionalFields:", error);
            addFields = [];
          }
        }
        return {
          ...dd,
          additionalFields: addFields,
        };
      });


      // Update product details with parsed attributes
      data = {
        ...data,
        productAttributes: updatedProAttri,
      }

      setProductDetails(data); // Set product details state
      const attributes = data?.productAttributes; // Extract attributes
      const maxAdditionalColumn = attributes[0].additionalFields?.length; // Get max additional columns

      // Update columns if there are additional fields
      if (maxAdditionalColumn > 0) {
        // const addColumnIndex = initialColumns.length-1;

        let newColumnNames = attributes[0].additionalFields.map((col) => {
          return {
            id: col.columnId,
            name: col.columnName,
            editable: true,
          }
        });



        const newColumns = [
          ...initialColumns,
          ...newColumnNames, // New column to be added before the 'Add' column

        ];

        setColumns(newColumns); // Set new columns state
      }


      setTableRows(attributes); // Set table rows state
      setSpecificationValue(data.productSpecification?.name); // Set specification value state
      setDocuments(data.productDocuments); // Set documents state
      closeBackdrop(); // Hide backdrop
    } catch (error) {
      console.error("Error fetching data:", error);
      closeBackdrop(); // Hide backdrop on error
      // Handle error (e.g., display an error message to the user)
    }
  };
  useEffect(() => {


    getDataProcure();


  }, [revisionId])


  // Update active tab when search parameters change
  useEffect(() => {
    setTabActive(searchParams.get("tab"));
  }, [Location, searchParams])

  // Function to toggle multi-save state
  const handleMultiSave = () => {
    setMultiSave(prev => !prev)
  }

  // Function to toggle rename modal
  const handleRenameModal = () => {
    setRenameModal(prev => !prev)
    // console.log(data)
  }

  // Function to handle tab change
  const handleTabChange = (index) => {
    setTabActive(index); // Set active tab state
    navigate(`?tab=${index}`, { replace: true }); // Navigate to new tab URL
  }


  // Function to handle downloading revision data as Excel
  const handleDownloadRevision = () => {
    // extracting text from Richeditor string
    let tempElement = document.createElement('div');
    tempElement.innerHTML = specificationValue;
    let extractedText = tempElement.textContent;
    extractedText = extractedText.trim();

    // Process attributes for Excel export
    const Attributes = tableRows.map((attri) => {
      const modifiedAttri = { ...attri };
      delete modifiedAttri.id;

      const remarks = {};
      attri.remark.forEach((rem, index) => {
        remarks[`Remark ${index}`] = rem.value;
      });
      delete modifiedAttri.remark;
      return { ...modifiedAttri, ...remarks };

    });

    // Process documents for Excel export
    let revisionDocument = []
    if (revisionId === "0") {

      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })

    }
    else {
      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })
    }
    // Create Excel file with extracted data
    createExcel([[{ Specification: extractedText }], Attributes, revisionDocument], (productDetails.name || "Revision 0"))
  }


  return (
    <>

      <BackdropComponent />
      <div className={workSpaceStyle.workSpaceMain}>

        <div className={workSpaceStyle.workSpaceHeader} >
          <div className={workSpaceStyle.workSpaceHeaderLeft} style={{ maxWidth: "none" }}>
            <h1>  <BackButton />{productDetails.name || "Not Name"}</h1>
            <p>1 Product Name</p>
          </div>

          <div className={workSpaceStyle.workSpaceHeaderRight}>
            <div className={workSpaceStyle.workSpaceThreeDotVertical}>
            </div>
            <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
              <Tooltip title='Help'>
                <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000080854-quotes-send">
                  <HelpOutlineOutlinedIcon />
                </a>
              </Tooltip>
            </div>
          </div>

        </div>


        <div className={revisionEditStyle.tabHeaderMain}>
          <div
            onClick={() => handleTabChange("0")}
            className={tabActive === "0" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Specification</div>
          <div
            onClick={() => handleTabChange("1")}
            className={tabActive === "1" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Attributes</div>
          <div
            onClick={() => handleTabChange("2")}
            className={tabActive === "2" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Documents</div>
        </div>

        <div className={revisionEditStyle.tabBodyMain} >

          {tabActive === "0" && <SpecificationTab specificationValue={specificationValue} handleSaveRevison={"sa"} />}
          {tabActive === "1" && <AttributeTab columns={columns} setColumns={setColumns} tableRows={tableRows} setTableRows={setTableRows} />}
          {tabActive === "2" && <DocumentsTab documents={documents} setDocuments={setDocuments} getDataProcure={getDataProcure} />}

        </div>

      </div>






    </>
  )
}

export default RevisionViewQuotesSent


/**
 * RevisionEdit.js
 * 
 * This is the Revision Edit component, responsible for rendering User Prespective  of Revision Edit. 
 * 
 *
 * Author: Ravinder Kumar
 *   
 * 
 * Created on: 2023-08-29
 * 
 * 
 * Last Modified on: 2023-09-28
 */