import React from 'react'
import chatRowStyle from './ChatRows.module.css'
import ProfileIcon from '../ProfileIcon/ProfileIcon';
import DateFormater from 'utility/DateFormater';


const ChatRow = ({name,date,chat,index,email}) => {
  return (
    <div key={index} className={chatRowStyle.chatRowMain}>
        
            <ProfileIcon data={{name:name,email:email,type:"KAM",profileLink:"SomeLink"}} showProfileBanner={true} height={"26px"} width={"26px"}/>

        <div className={chatRowStyle.chatDescription}>
            <div className={chatRowStyle.chatNameRow}>
                <h4>{name}</h4>
                <span>{DateFormater(date)}</span>
            </div>
            <div className={chatRowStyle.chatRowChat}>
                <p>{chat}</p>
            </div>
        </div>
    </div>
  )
}

export default ChatRow
