import React, { useEffect, useState } from 'react'
import ProdShow from './ProductShow.module.css'// Import CSS module for ProductShow
import RedirectRegistrationStyle from '../../RedirectRegistration/RedirectRegistrationStyle.module.css';// Import CSS module for RedirectRegistration
import SliderTwo from 'components/SliderTwo/SliderTwo'; // Import SliderTwo component
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';// Import DeleteOutlineIcon from Material-UI
import { useLocation, useNavigate } from 'react-router-dom';// Import hooks from React Router
import axios, { PrivateComponent } from 'api/axios';// Import axios instance and PrivateComponent from API directory
import useAuth from 'hooks/useAuth';// Import useAuth hook
import { useDispatch } from 'react-redux';// Import useDispatch hook from Redux
import { setLogout } from 'state/AuthState'; // Import action from AuthState slice
import useBackdrop from 'hooks/useBackdrop';// Import useBackdrop hook
import Tooltip from '@mui/material/Tooltip'; // Import Tooltip component from Material-UI
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';// Import DeleteConfirmation component

// ProductShow component definition
const ProductShow = () => {
    const privateAxios = PrivateComponent()// Get private axios instance
    const location = useLocation();// Get location object from React Router
    const dispatch = useDispatch()// Get dispatch function from Redux
    const { state } = location;// Destructure state object from location
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();// Destructure functions from useBackdrop hook

    // State variables
    const [products, setProducts] = useState([]); // State for products
    const [stateCategories, setStateCategories] = useState([]);// State for categories from location state
    const [selectedCategories, setSelectedCategories] = useState([]);// State for selected categories
    const navigate = useNavigate();// Get navigate function from React Router
    const { auth } = useAuth();// Get auth object from useAuth hook

    // State for product to delete and delete modal visibility
    const [productToDelete, setProductToDelete] = useState(null);
    const [deleteProductModal, setDeleteProductModal] = useState(false);

    // Function to toggle delete product modal and set product to delete
    const handleDeleteModal = (productId) => {
        setDeleteProductModal(prev => !prev); // Toggle delete modal
        if (productId !== undefined && productId !== null && productId !== "") {
            setProductToDelete(productId)// Set product to delete
        }

    }
    // ====================flat tree========================

    // Function to flatten nested categories
    const flattenCategories = (categories, result = []) => {
        categories.forEach(category => {
            result.push(category);
            if (category.branch && category.branch.length > 0) {
                flattenCategories(category.branch, result);
            }
            delete category.branch;
        });
        return result;
    }
    // ==============================flat tree========================

    //  ====================get category path==========================

    // Function to get category path by categoryId
    const getCategoryPath = (categoryId) => {

        const category = selectedCategories.find((category) => category.categoryId === categoryId);
        if (category) {
            return category.parent ? `${getCategoryPath(category.parent)} > ${category.categoryName}` : category.categoryName;
        }
        return '';


    }

    //  ====================get category path==========================


    // Effect to initialize products and categories
    useEffect(() => {

        const images = [
            "https://res.cloudinary.com/dtffniutw/image/upload/v1696503218/unsplash_s-gYAbQToXk_romfby.png",

            "https://res.cloudinary.com/dtffniutw/image/upload/v1696503218/unsplash_s-gYAbQToXk_2_ylgxq8.png",

            "https://res.cloudinary.com/dtffniutw/image/upload/v1696503218/unsplash_s-gYAbQToXk_2_ylgxq8.png",

            "https://res.cloudinary.com/dtffniutw/image/upload/v1696503218/unsplash_s-gYAbQToXk_romfby.png",

            "https://res.cloudinary.com/dtffniutw/image/upload/v1696503218/unsplash_s-gYAbQToXk_2_ylgxq8.png",

        ]



        // Get the random image URL
        // If state is null, logout and navigate to home page
        if (state.products === null && state.categories === null && state.orgId === null) {
            dispatch(setLogout());
            navigate("/", { state: {}, replace: true });
        }
        else {
            // Map over products, assign random image to each product, and set state
            const pp = state?.products?.map((pro) => {
                const randomIndex = Math?.floor(Math.random() * images.length);
                return {
                    ...pro,
                    productImg: images[randomIndex]
                }
            })
            setProducts(pp);// Set products
            setStateCategories(state?.categories);// Set categories from location state
            setSelectedCategories(state?.flatCategory);// Set selected categories
        }

    }, [state]);// Dependency array

    // Function to handle confirmation of selected product
    const handleConfirmProducts = async () => {
        openBackdrop()
        let orgId = state.orgId;

        await axios.post(`${process.env.REACT_APP_BASEURL}/procurement/addCategories/${orgId}`,
            stateCategories,
            {
                headers: {
                    'Authorization': `Bearer ${state.token}`,
                }
            }
        ).then(async (res) => {
            await axios.post(`${process.env.REACT_APP_BASEURL}/procurement/addProducts/${orgId}`,
                products,
                {
                    headers: {
                        'Authorization': `Bearer ${state.token}`,
                    }
                }
            ).then((res2) => {
                navigate('/underVerification', { replace: true });
            })

        }).catch((err) => {
            alert("error adding Porducts. contact Admin")
        })
        closeBackdrop();
    }

    // Function to handle deletion of a product
    const handleDeleteProduct = (productId) => {
        // Use filter to remove the product with the specified ID
        const updatedProducts = products.filter((item) => item.productId !== productId);
        setProducts(updatedProducts);
    };

    return (
        <div className={ProdShow.ProStart}>
            <div className={ProdShow.ProMain}>
                <div className={ProdShow.pageCont} >
                    <h1>Here's a quick overview of the products you've selected</h1>
                    <div className={ProdShow.productWrapper}>
                        {
                            products?.map((item) =>
                                <div className={ProdShow.prodata}>
                                    <div className={ProdShow.proImg}>
                                        <img src={item.productImg} alt="" />

                                    </div>
                                    <div className={ProdShow.proInfo}>


                                        <div className={ProdShow.headerWrapper}>
                                            <h3>{item.productName}</h3>
                                            <div className={ProdShow.headerIconWrapper}>
                                                <Tooltip title='Delete'><DeleteOutlineIcon onClick={() => handleDeleteModal(item.productId)} /></Tooltip>
                                                {/* <Tooltip title='Error'><ErrorOutlineIcon /></Tooltip> */}
                                            </div>
                                        </div>

                                        <h5>{getCategoryPath(item.categoryId)}</h5>
                                    </div>


                                </div>
                            )
                        }
                    </div>


                    <button onClick={handleConfirmProducts} className={ProdShow.confirmBtn} >Confirm & Register</button>
                </div>
            </div>
            <div className={RedirectRegistrationStyle.slider}>
                <SliderTwo />
            </div>



            <DeleteConfirmation
                onCancel={handleDeleteModal}
                onConfirm={() => { handleDeleteProduct(productToDelete); handleDeleteModal() }}
                open={deleteProductModal}
            // confimBtnText={""} 
            // discardText={""}
            >
                <h2>Remove Product</h2>
                <p>Are you sure you want to Remoce this Product?</p>

            </DeleteConfirmation>




            <BackdropComponent />

        </div>
    )
}

export default ProductShow
