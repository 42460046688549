import React, { useState, useEffect } from 'react'

// Importing CSS modules
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import axios from 'axios';
import useToast from 'hooks/useToast';
import useBackdrop from 'hooks/useBackdrop';
import ProdStyle from './CategorySelector.module.css'

// Importing icons and components from Material-UI
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Other imports
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';

const CategorySelector = ({ productsArray,open, onClose, onsubmit, orgId }) => {
      // Hooks
      const { Toast, showToast } = useToast();
      const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
      const [availableCategories, setAvailableCategories] = useState([]); // State for available categories
      const [selectedSubcategories, setSelectedSubcategories] = useState({}); // State for selected subcategories

  // Function to fetch categories from API
    const getCategories = async () => {
        openBackdrop();
        try {
              // Fetch categories from the API
            await axios.get(`${process.env.REACT_APP_ECOM}/api/subgroups`).then(res => {
                if (res.status === 200) {
                    setAvailableCategories(res.data);// Set available categories state
                }
            })

        } catch (error) {
            showToast("error", "Error Fetching Categories"); // Show toast on error
        }
        closeBackdrop();// Close backdrop
    }

     // Fetch categories on component mount
    useEffect(() => {
        getCategories();
    }, [])

 // Function to fetch subcategories of a category
    const getCategoryData = async (category) => {
        openBackdrop();// Open backdrop
        try {
              // Fetch categories from the API
            const res = await axios.get(`${process.env.REACT_APP_ECOM}/api/hierarchy/${category.id}/${category.level}`)
          setSelectedSubcategories(res.data); // Set selected subcategories state
        } catch (err) {
            showToast("error", "Error Fetching Sub Categories"); // Show toast on error

        }
        closeBackdrop()
    };

  // Function to flatten category branch data
    function flattenBranch(data) {
        const flattened = [];
    
        function flatten(item) {
            flattened.push({
                categoryId:item.Id,
                categoryName: item.Name,
                description: "Ecom Cat",
                level: item.Level,
                parent: item.ParentID,
            });
            item.Branch.forEach(branchItem => {
                flatten({ ...branchItem, Branch: [] });
            });
        }
    
        data.forEach(item => {
            flatten(item);
        });
    
        return flattened;
    }

  // Function to handle selected categories
    const handleCategories = () => {
     
        try {
            if(selectedSubcategories)
            {
                let catgories =flattenBranch(selectedSubcategories.Branch);// Flatten selected subcategories
                catgories.push({
                    categoryId:selectedSubcategories.Id,
                    categoryName: selectedSubcategories.Name,
                    description: "Ecom Cat",
                    level: selectedSubcategories.Level,
                    parent: selectedSubcategories.ParentID,
                })
    
                onsubmit(catgories); // Submit selected categories
            }
           
        } catch (error) {
            console.log(error)
            showToast("error", "Error itrating throught category");// Show toast on error
        }
    }


    // =======================category Selector========================

   // State for category search
   const [catSearch, setCatSearch] = useState("");
   // State for category dropdown visibility
   const [catSelectDropdown, setCatSelectDropdown] = useState(false);

    // Function to toggle category selector dropdown
    const handleCatSelectorDropDown = () => {
        setCatSelectDropdown((prev) => !prev);

    }

      // Function to handle category selection
    const handleCategorySelector = async (cat) => {
        handleCatSelectorDropDown(); // Toggle category selector dropdown
        await getCategoryData({ ...cat, level: 1 }); // Fetch subcategories
    }

    // =======================category Selector========================

    // =========================handle Discard==========================

     // Function to handle discard
    const handleDiscard = () => {
        setSelectedSubcategories({}); // Reset selected subcategories
        onClose(); // Close modal
    }
    

    // =========================handle Discard==========================





    return (
        <>
            <Toast />
            <BackdropComponent />
            <Modal
                open={open}
                onClose={onClose}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>


                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "felx-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Add Product</h2>
                            <span onClick={onClose}><CloseIcon /></span>
                        </div>

                        <div className={ProdStyle.productSelectors}>
                            <div className={ProdStyle.productSelectorColumn}>
                                <label htmlFor="">Select Category</label>
                                <div className={ProdStyle.productSelector}  >
                                    <p onClick={() => { handleCatSelectorDropDown(); }} >
                                        {selectedSubcategories.Name || "Please Select A category"}
                                        <span>
                                            {catSelectDropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </span>
                                    </p>
                                    {catSelectDropdown &&
                                        <>

                                            <div className={ProdStyle.dropdownBackDrop} onClick={handleCatSelectorDropDown}>
                                            </div>
                                            <div className={ProdStyle.productDropDownDiv} >

                                                <div className={ProdStyle.productSearchBar}>
                                                    <input type="text" value={catSearch} onChange={(e) => setCatSearch(e.target.value)}
                                                        placeholder="Search for Products..." />
                                                </div>
                                                <ul className={ProdStyle.productDropDownList}>

                                                    {availableCategories
                                                    .filter((cat) => cat.name.trim().toLowerCase().includes(catSearch.toLowerCase()))
                                                    .map((cat) => {
                                                        return (
                                                            <li onClick={() => handleCategorySelector(cat)}>{cat.name}</li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>



                        </div>


                        <div className={workSpaceStyle.d_flex_end} style={{ gap: "10px" }}>
                            <button className={`${workSpaceStyle.modalBtn}`} style={{ color: "#16469D" }} onClick={handleDiscard}> Discard</button>
                            <button className={`${workSpaceStyle.modalBtn}`} style={{ backgroundColor: "#16469D", color: "white" }} onClick={handleCategories}>Import Category</button>

                        </div>

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default CategorySelector


