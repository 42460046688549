import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useToast from 'hooks/useToast';
import superadminStyle from '../../commonCss.module.css';
import Tooltip from '@mui/material/Tooltip';
import { HelpOutlineOutlined, Sort } from '@mui/icons-material';
import GroupSorterData from 'utility/GroupSorterData.js';
import { useNavigate } from 'react-router-dom';
import DateFormater from 'utility/DateFormater';
import BackButton from 'components/BackButton/BackButton';
import useBackdrop from 'hooks/useBackdrop';
import { Button } from '@mui/material';


const KamAprovalRequest = () => {
    // Importing necessary hooks and components
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const { showToast, Toast } = useToast();
    const privateAxios = PrivateComponent();
    const navigate = useNavigate();

    // Setting up state for search value
    const [searchValue, setSearchValue] = useState('');

    // Setting up state for sorting
    const [sorterOpen, setSorterOpen] = useState("");

    // Function to toggle sorting options
    const toggleSorter = (type) => {
        if (sorterOpen === type) {
            setSorterOpen("");
        }
        else {
            setSorterOpen(type);
        }
    }

    // Function to handle sorting
    const handleSorting = (type) => {
        console.log(type);
    }


    // =====================data======================

    // Setting up state for data
    const [data, setData] = useState([]);
    const [sorterData, setSorterData] = useState({});

    // Function to fetch data
    const getData = async () => {
        openBackdrop();
        try {
            const res = await privateAxios.get('/procureadmin/getAllKamRequests');
            setData(res.data)
            setSorterData(GroupSorterData(res.data));
        } catch (error) {
            showToast("error", error.message)
        }
        closeBackdrop();
    }
    // Fetch data on component mount
    useEffect(() => {
        getData();
    }, [])


    // =====================decision======================

    // Function to handle approval or decline of KAM requests
    // const handleTemplateDecision = (id, decision) => async () => {
    //     openBackdrop();
    //     try {
    //         let url = ``
    //         if (decision) {
    //             url = `/procurement/kamApproveRequest`
    //         }
    //         else {
    //             url = `/procurement/kamApproveRequest`
    //         }

    //         const res = await privateAxios.post(`${url}/${id}`);

    //         if (res.status === 200) {
    //             if (decision) {
    //                 showToast("success", "KAM Approved Successfully")
    //             }
    //             else {
    //                 showToast("success", "KAM Declined Successfully")
    //             }
    //         }
    //         getData();
    //     } catch (error) {
    //         showToast("error", error.message)
    //     }
    //     closeBackdrop();
    // }

    const handleTemplateDecision = (id, decision) => async () => {
        openBackdrop();
        try {
            const url = decision ? '/procurement/kamApproveRequest' : '/procurement/kamApproveRequest';
            const res = await privateAxios.post(`${url}/${id}`);
            if (res.status === 200) {
                showToast('success', decision ? 'KAM Approved Successfully' : 'KAM Declined Successfully');
                getData();
            }
        } catch (error) {
            showToast('error', error.message);
        }
        closeBackdrop();
    }




    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>

                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <h1 style={{ gap: '10px' }}> <BackButton />
                            User Request
                        </h1>
                        <p>Available <span>{data.length}</span></p>
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
                        <input type="text"
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            placeholder='Search Here...' />
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: 'auto', minWidth: 'auto' }} >
                        <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                            <Tooltip title='help'>
                                <a href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081297-kam-approvals-page" target="_blank">
                                    <HelpOutlineOutlined sx={{ cursor: 'pointer' }} />
                                </a>
                            </Tooltip>
                        </div>
                    </div>

                </div>


                <div className={workSpaceStyle.workSpaceMainDiv}>

                    <table className={superadminStyle.admintable}>
                        <thead>
                            <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
                                <th> Sr No. </th>
                                <th> Name </th>
                                <th> Phone Number </th>
                                <th> Email </th>
                                <th> GST/VAT </th>
                                <th> Registered On </th>
                                <th style={{ textAlign: "center" }}> Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.filter((val) => val.kamName?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                                    val.kamEmail?.toLowerCase().includes(searchValue.trim().toLowerCase()))
                                    .map((row, index) => {
                                        return (
                                            <tr className={superadminStyle.admintableRow} key={index}>

                                                <td> {index + 1} </td>
                                                <td> {row?.kamName} </td>
                                                <td> {row?.phone} </td>
                                                <td> {row?.kamEmail} </td>
                                                <td> {row?.gst} </td>
                                                <td> {DateFormater(row?.requeston)} </td>

                                                <td>
                                                    <div className={superadminStyle.decisionBtnWrapper}>
                                                        {row.status === false ? (
                                                            <button className={`${superadminStyle.rowDecisionbtn} ${superadminStyle.rowDecisionPrimarybtn}`} onClick={handleTemplateDecision(row.id, true)}>
                                                                Approve
                                                            </button>
                                                        ): (
                                                        
                                                            <button 
                                                            disabled
                                                            className={superadminStyle.disableButton}
                                                            
                                                           
                                                          
                                                            >
                                                                Approved
                                                            </button>
                                                        )}

                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })}
                        </tbody>

                    </table>
                </div>
            </div>
        </>
    )
}

export default KamAprovalRequest
