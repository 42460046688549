import React, { useState } from 'react'; // Import React and useState hook
import Backdrop from '@mui/material/Backdrop';// Import Backdrop component from Material-UI
import CircularProgress from '@mui/material/CircularProgress';// Import CircularProgress component from Material-UI
import LoaderStyle from "./useBackdrop.module.css"// Import custom CSS module for loader styles


const useBackdrop = () => {
  // State to manage the open/close state of the backdrop
  const [open, setOpen] = useState(false);

  // Function to open the backdrop
  const openBackdrop = () => {
    setOpen(true);
  };

  // Function to close the backdrop
  const closeBackdrop = () => {
    setOpen(false);
  };

  // Backdrop component with CircularProgress and custom loader
  const BackdropComponent = () => (
    <Backdrop
      sx={{ color: '#fff', zIndex: "9999999" }}
      open={open}

    >
      <CircularProgress color="inherit" />
      <div className="dotSpinner">
        <div className={LoaderStyle.dotSpinner__dot}></div>
        <div className={LoaderStyle.dotSpinner__dot}></div>
        <div className={LoaderStyle.dotSpinner__dot}></div>
        <div className={LoaderStyle.dotSpinner__dot}></div>
        <div className={LoaderStyle.dotSpinner__dot}></div>
        <div className={LoaderStyle.dotSpinner__dot}></div>
        <div className={LoaderStyle.dotSpinner__dot}></div>
        <div className={LoaderStyle.dotSpinner__dot}></div>
      </div>
    </Backdrop>
  );

  // Return the openBackdrop, closeBackdrop functions and BackdropComponent
  return { openBackdrop, closeBackdrop, BackdropComponent };
}

export default useBackdrop