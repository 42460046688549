import React from 'react'
import { useState } from 'react';
import { Box, Button, Tooltip, TextField } from '@mui/material';
import ChattStyle from 'pages/CreateTask/ChattPanel/ChattPanel.module.css'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { PrivateComponent } from 'api/axios';
import { useEffect } from 'react';
import SendIcon from '@mui/icons-material/Send';
import useAuth from 'hooks/useAuth';
import { useRef } from 'react';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import DateFormater from 'utility/DateFormater';

function EditChattPanel({ handleEditTaskModal, data, handleUploadModalClose }) {

  const privateAxios = PrivateComponent();
  // New state for tab selection
  const [selectedTab, setSelectedTab] = useState('comment'); // Default: 'comments'
  const [commentInput, setCommentInput] = useState(''); // New state for comment input

  const { auth } = useAuth();
  const taskId = data?.taskId;

  // Function to handle tab selection
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };
  const handleCommentInputChange = (event) => {
    setCommentInput(event.target.value);
  };
  // Sample comment data array
  const [comment, setComments] = useState([]);
  // Add a ref to the comment container element
  const commentContainerRef = useRef(null);



  // Function to scroll the comment container to the bottom
  const scrollToBottom = () => {
    if (commentContainerRef.current) {
      commentContainerRef.current.scrollTop = commentContainerRef.current.scrollHeight;
    }
  };

  const [chattHistory, setChattHistory] = useState([]);
  const [chattAddHistory, setAddChattHistory] = useState([]);


  const handleSubmitComment = async (e) => {
    e.preventDefault();
    try {
      if (commentInput.trim() === "") { // Check if the comment input is empty or contains only whitespace
        alert("Please enter a comment before sending.");
        return; // Exit the function
      }

      else {
        const commentData = {
          comment: commentInput,
        };

        await privateAxios.post(`/task/addComments/${taskId}`,
          { comment: commentInput, name: auth.name }).then(() => {
            fetchComments();
            scrollToBottom();
          })

      }



      setCommentInput(''); //clear field
    } catch (error) {
      console.error('Error submitting comment:', error);
    }
  };

  const fetchComments = async () => {
    try {
      // const commentData = {
      //   comment: commentInput,
      //   name: comment.name,
      //   timestamp: comment.timestamp, 
      // };
      if (taskId !== null || taskId !== '') {
        await privateAxios.get(`/task/getAllComments/${taskId}`).then((res) => {
          setComments(res.data)
          scrollToBottom();
          // console.log(" response:", res);
        }).catch((err) => {
          // console.log(err)
        })
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };


  useEffect(() => {
    if (taskId !== null || taskId !== '') {

      fetchComments();
    }
  }, [selectedTab, data]);

  function getInitials(name) {
    const nameParts = name?.split(" ");
    if (nameParts?.length > 1) {
      return nameParts[0].charAt(0) + nameParts[1].charAt(0);
    } else {
      return nameParts[0].charAt(0);
    }
  }




  const previousDateRef = useRef('');

  const handleDate = (createdAt) => {
    const dateComes = handleDateFormatted(createdAt);

    if (dateComes === previousDateRef.current) {
      return '';
    }
    previousDateRef.current = dateComes;

    return dateComes;
  }

  const handleDateFormatted = (createdAt) => {
    const createdDate = new Date(createdAt);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (isSameDay(createdDate, today)) {
      return 'Today';
    } else if (isSameDay(createdDate, yesterday)) {
      return 'Yesterday';
    } else {
      const daysAgo = Math.floor((today - createdDate) / (1000 * 60 * 60 * 24));
      if (daysAgo === 1) {
        return '2 Days Ago';
      } else if (daysAgo <= 7) {
        return `${daysAgo} Days Ago`;
      } else {
        const monthNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        const day = createdDate.getDate();
        const month = monthNames[createdDate.getMonth()];
        const year = createdDate.getFullYear();
        return `${month} ${day} ${year}`;
      }
    }
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const formatTime = (createdAt) => {
    const createdDate = new Date(createdAt);
    const now = new Date();
    const timeDiffInSeconds = Math.floor((now - createdDate) / 1000);

    if (timeDiffInSeconds < 60) {
      return `${timeDiffInSeconds}s`;
    } else if (timeDiffInSeconds < 3600) {
      const minutes = Math.floor(timeDiffInSeconds / 60);
      return `${minutes}m`;
    } else if (timeDiffInSeconds < 86400 && isSameDay(createdDate, now)) {
      const hours = Math.floor(timeDiffInSeconds / 3600);
      return `${hours}h`;
    } else {
      const ampm = createdDate.getHours() >= 12 ? 'pm' : 'am';
      const hours = createdDate.getHours() % 12 || 12;
      const minutes = createdDate.getMinutes();
      return `${hours}:${minutes < 10 ? '0' : ''}${minutes}${ampm}`;
    }
  };




  const fetchTaskHistory = async () => {

    try {
      if (taskId !== null || taskId !== '') {


        await privateAxios.get(`/task/getAllTaskHistory/${taskId}`).then((res) => {



          // console.log(" histo:", res.data);
          const data = res.data.map((his) => {
            let logDetails;

            try {

              logDetails = JSON.parse(his.logDetails);
            } catch (error) {

              logDetails = [his.logDetails];
            }
            return {
              ...his,
              logDetails: logDetails
            }
          })

          const groupedData = data.reduce((acc, obj) => {
            const dateWithoutTime = obj.createdAt.split('T')[0]; // Extracting date without time
            acc[dateWithoutTime] = acc[dateWithoutTime] || [];
            acc[dateWithoutTime].push(obj);
            return acc;
          }, {});
          console.log(" histo: parsed", groupedData);
          setChattHistory(groupedData);
          // console.log(chattHistory[0].createdAt)
        }).catch(err => {
          // console.log(err)
        })
      }
    } catch (error) {
      console.error('Error fetching task history:', error);
    }
  };

  useEffect(() => {
    if (taskId !== null || taskId !== '') {

      fetchTaskHistory();
    }
  }, [data, selectedTab]);

  const [expandedDetailIndex, setExpandedDetailIndex] = useState(null);
  const handleHistoryHeadingsClick = (index) => {
    // Toggle the expandedDetailIndex to show/hide details
    if (expandedDetailIndex === index) {
      setExpandedDetailIndex(null); // Collapse if already expanded
    } else {
      setExpandedDetailIndex(index); // Expand if not already expanded
    }
  };

  // useEffect(() => {
  //   fetchAddTaskHistory();
  // }, []);

  // console.log(comment)
  return (
    <>
      <div className={ChattStyle.TaskChatPannelWrapper}>
        {/* ... (existing modal content) */}
        <div className={ChattStyle.chattSections} >
          <div className={ChattStyle.taskTabSelectorsWrapper}>
            <div className={ChattStyle.tabButtons}>
              <button
                className={selectedTab === 'comment' ? ChattStyle.activeTab : ''}
                onClick={() => handleTabChange('comment')}
              >
                Comment
              </button>
              <button
                className={selectedTab === 'chattHistory' ? ChattStyle.activeTab : ''}
                onClick={() => handleTabChange('chattHistory')}
              >
                Task History
              </button>
            </div>

            <div className={ChattStyle.handlecreateTaskModal}>
              <Tooltip title="Help">
           
                <HelpOutlineOutlinedIcon />

              </Tooltip>
              <span onClick={handleEditTaskModal} >

                <Tooltip title="cancel" >

                  <ClearIcon />
                </Tooltip>
              </span>
            </div>
          </div>
          {selectedTab === 'comment' && (
            <div
              className={ChattStyle.chatBodyWrapper}
              ref={commentContainerRef}>

              <div className={ChattStyle.commentsTab} style={{ overflowY: "auto", flexGrow: "1" }} >

                {comment.map((comment, index) => (

                  <div key={index} className={ChattStyle.commentContainer}>
                    <div className={ChattStyle.chatCard}>

                      <h1><ProfileIcon data={{ email: comment.email }} height={"26px"} width={"26px"} showProfileBanner={true} /></h1>
                      <Box className={ChattStyle.commentDetailsWrapper}>
                        <Box className={ChattStyle.headings}>
                          <h2>{comment.name}</h2>
                          <h4>{handleDate(comment.createdAt)}</h4>
                        </Box>
                        <Box className={ChattStyle.discripton}>
                          <h3>{comment.comment}</h3>
                        </Box>
                      </Box>
                    </div>

                  </div>
                ))}
              </div>
              <div className={ChattStyle.commentFormWrapper}>
                <form onSubmit={handleSubmitComment}
                  className={ChattStyle.commentForm}
                >


                  <input
                    placeholder="Write a comment..."

                    value={commentInput}
                    multiline  // Allow multiline input
                    onChange={handleCommentInputChange}
                  />
                  <button type='submit'>

                    <SendIcon />
                  </button>


                </form>
              </div>
            </div>
          )}

          {selectedTab === 'chattHistory' && (
            <div className={ChattStyle.chattHistoryTab}>

              {
                Object.values(chattHistory).map((histArray, index) => {

                  const currentDate = Object.keys(chattHistory)[index];
                  return <div className={ChattStyle.chatHistCard}>
                    <h5

                      className={ChattStyle.headerDate}
                    >
                      {DateFormater(currentDate)}</h5>
                    {histArray.reverse().map((hist, index) => (  // Reverse the array






                      <div key={index} className={ChattStyle.chatHistWrapper}>


                        <div className={ChattStyle.chatHistheader} onClick={() => handleHistoryHeadingsClick(index)}>

                          <span>
                            <ProfileIcon data={{ email: hist.email }} height={"25px"} width={"25px"} showProfileBanner={false} />
                          </span>
                          <div className={ChattStyle.heading}>

                            <h2>{hist?.logDetails}
                            </h2>
                            <span>{formatTime(hist.createdAt)}</span>





                          </div>

                        </div>

                        {index === expandedDetailIndex && (
                          <div className={ChattStyle.historyLogs}>
                            <p> <span style={{ fontWeight: '500' }}>New: {hist.newLogs}  </span> </p>
                            <p><span style={{ fontWeight: '500' }}>Previous: </span> {hist.previousLogs}</p>
                          </div>
                        )}

                      </div>
                    ))}
                  </ div>

                })

              }


            </div>
          )}


        </div>

      </div>
    </>
  )
}

export default EditChattPanel