import React,{useState} from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import revisionEditStyle from './VendorRevision.module.css'


const VendorSpecification = ({specificationValue,setSpecificationValue,handelSpecification,handleSaveRevison}) => {

  
  return (
    <div className={revisionEditStyle.specficationTabMain}>
   
    <div className={revisionEditStyle.richEditor}>
      <ReactQuill  theme="snow" value={specificationValue} onChange={setSpecificationValue} />     
    </div>
    <div className={revisionEditStyle.SpecificationTabBottomButton}>
      
      <button onClick={handleSaveRevison}>Save</button>
      <p onClick={()=>setSpecificationValue("")}>Cancel</p>
    </div>


    </div>
  )
}

export default VendorSpecification
