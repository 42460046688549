
const GroupSorterData=(data)=>{
    const groupedObject = {};
    
    data.forEach(obj => {
      
        for (const key in obj) {
            const value = obj[key];

            if (key in groupedObject) {
                if (Array.isArray(groupedObject[key]) && !groupedObject[key].includes(value)) {
                    groupedObject[key].push(value);
                } else if (!Array.isArray(groupedObject[key]) && groupedObject[key] !== value) {
                    groupedObject[key] = [groupedObject[key], value];
                }
            } else {
                groupedObject[key] = [value];
            }
        }
    });

    return groupedObject;


}

export default GroupSorterData;