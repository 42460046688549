import axios from "axios";
import React from "react";
// import { useSelector, useDispatch } from 'react-redux';
import useAuth from "../hooks/useAuth";




const baseUrl=`${process.env.REACT_APP_BASEURL}`



//  const baseUrl="http://192.168.0.120:5050"
//  const baseUrl="http://192.168.0.145:5050"
//  const baseUrl="http://192.168.0.243:5050"




//  const baseUrl="http://192.168.0.179:5050"






export default axios.create({
    baseURL:baseUrl,
    headers: {
        // 'Authorization': 'Bearer yourToken',
        'Content-Type': 'application/json',
      }
})



export const PrivateComponent = () => {
  const {auth} = useAuth();

  
  // console.log(auth.token)
  
  const privateAxios = axios.create({
    baseURL: baseUrl,
    headers: {
      'Authorization': `Bearer ${auth.token}`,
    }
  });

  // Now you can use privateAxios for making API requests
  // ...

  return privateAxios;
};


