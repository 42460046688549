import React from 'react'// Import React library
import unAuthorizedStyle from '../components/WorkSpaceCommonCss/Unauthorized.module.css';// Import CSS module for styling
import { Button } from '@mui/material'; // Import MUI Button component
import { useNavigate } from 'react-router-dom';  // Import React Router hook for navigation


const UnAuthorized = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook for programmatic navigation

  // Function to handle the home button click
  const handleHomeButtonClick = () => {
    navigate('/'); // Navigate to the home page route
  };
  

  
  return (
    <div className={unAuthorizedStyle.MainCompoUnauth}>
      <div className={unAuthorizedStyle.MainCompoUnauthBox}>
        <div className={unAuthorizedStyle.mainHeadingError}>
          <h1>4</h1><span><img src='https://res.cloudinary.com/del6gyrgn/image/upload/v1693028784/qqqqq_jctqa1.png' /></span><h1>3</h1>

        </div>
        <div className={unAuthorizedStyle.uauthMeassage}>
          <h2>Unauthorized Access</h2>
          <p>We're sorry, but it seems like you don't have the necessary<br />authorization to access this page. Our systems have detected an<br />unauthorized attempt to access restricted content.</p>
        </div>
        <div className={unAuthorizedStyle.unauthButton}>
          <Button variant="contained"  onClick={handleHomeButtonClick}>
            Home
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UnAuthorized
