import {React , useState, useEffect }from "react"; // Importing React, useState, and useEffect from React
import ErrorToastStyle from './ErrorToastStyle.module.css'; // Importing CSS module for styling
import Alert from '@mui/material/Alert'; // Importing Alert component from Material UI


const ErrorToast = ({ message }) => {// Defining the ErrorToast component with message prop

    const [isVisible, setIsVisible] = useState(false);// State for controlling the visibility of the toast

    useEffect(() => {// Effect hook to handle visibility changes
        if (message) {  // If there is a message
            setIsVisible(true);// Show the toast
            const timeout = setTimeout(() => {// Set a timeout to hide the toast after 5 seconds
                setIsVisible(false);// Hide the toast
            }, 5000);
            return () => clearTimeout(timeout);// Clear the timeout if the component unmounts or message changes
        }
    }, [message]); // Effect dependency array, runs effect when message changes

    return (
        <div className={`${ErrorToastStyle.ErrorToastPosition} ${isVisible ? ErrorToastStyle.visible : ''}`}>
            <Alert severity="error"> <strong> {message}</strong>
            </Alert>
        </div>
    )

}

export default ErrorToast;