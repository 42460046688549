import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import TaskStyle from '../../../CreateTask/TaskPannel/TaskPannel.module.css'
import commercialStyle from '../commercialStyles.module.css';
import useAuth from 'hooks/useAuth';
import { PrivateComponent } from 'api/axios';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


// Define the CommercialTemplateSelector component with props
const CommercialTemplateSelector = ({ showToast, data, key, selectedTemplates, setSelectedTemplates, handleTemplateSelect, openBackdrop, closeBackdrop }) => {
    // State to hold available templates
    const [avaiableTemplate, setAvaiableTemplate] = useState([]);
    // State to control the visibility of the template dropdown
    const [templateDropDown, setTemplateDropDown] = React.useState(false);
    // Extract authentication details
    const { auth } = useAuth();
    // Create a private axios instance for making API requests
    const privateAxios = PrivateComponent();


 // Function to toggle the template dropdown visibility
    const handleTemplateDropDown = () => {
        setTemplateDropDown(prev => !prev)
    }

    // Function to fetch available templates from the server
    const getAvailableTemplate = async () => {
        openBackdrop();// Open loading backdrops
        try {
               // Make API request to get approved templates
            const res = await privateAxios.get(`/procureadmin/getAllApprovedTemplates/${auth.orgId}/${data.id}`);
  // Log the response data for debugging
            console.log(res.data);
              // Update state with the fetched templates
            setAvaiableTemplate(res.data);
        } catch (error) {
               // Show error message if the request fails
            showToast("error", "error getting templates data")
        }
  // Close loading backdrop
        closeBackdrop();
    }

 // useEffect to fetch available templates on component mount
    useEffect(() => {
        getAvailableTemplate();
    }, [])


    // ================Add Template Modal===========================

      // State to control the visibility of the add template modal
    const [addTemplateModal, setAddTemplateModal] = useState(false);

     // Function to toggle the add template modal visibility
    const handleAddTemplateModal = () => {
        setAddTemplateModal(prev => !prev);
    }

    // ================Add Template Modal===========================

    // ================get Avaiable Template to Import========

    // State to hold admin templates and selected templates for import
    const [adminTemplates, setAdminTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState([]);

     // Function to fetch templates available for import from super admin
    const getDataFromSuperAdmin = async () => {
        openBackdrop();  // Open loading backdrop
        try {
  // Make API request to get imported templates
            const res = await privateAxios.get(`/procureadmin/getAllImportedTemplatesInKam/${data.id}`);
// Log the response data for debugging
            console.log(avaiableTemplate);
            console.log(res.data);
              // Filter out already available templates
            const requestedTemplates = res.data.filter((req) => {
                return !avaiableTemplate.some(tee => tee.id === req.id)
            })
              // Log the requested templates for debugging
            console.log(requestedTemplates);
             // Update state with the requested templates
            setAdminTemplates(requestedTemplates)



        } catch (error) {
             // Show error message if the request fails
            showToast("error", error.message)
        }
        closeBackdrop();
    }

// useEffect to fetch admin templates when add template modal is opened
    useEffect(() => {
        if (addTemplateModal) {

            getDataFromSuperAdmin();
        }
    }, [addTemplateModal])

    // ================get Avaiable Template to Import========


    // ================handle Template CheckBox========

      // State to hold selected templates items
    const [selectedTemplateItem, setSelectedTemplateItem] = useState([]);

       // Function to handle template checkbox selection
    const handleTemplateCheckBox = (temp) => {
        if (selectedTemplateItem.some((rt) => rt.id === temp.id)) {
              // Remove template from selection if already selected
            setSelectedTemplateItem(prev => prev.filter((rt) => rt.id !== temp.id))
        } else {
             // Add template to selection if not already selected
            setSelectedTemplateItem(prev => [...prev, temp])
        }
    }

           
   // Function to handle template request submission
    const handleRequestTemplate = async () => {
        openBackdrop(); // Open loading backdrop
        try {
   // Prepare payload for the request
            const payload = {
                ids: selectedTemplateItem.map((temp) => temp.id),
                orgId: auth.orgId,
            }
   // Make API request to request the templates
            const res = await privateAxios.post(`/procureadmin/requestTemplateInOrganisationTemplate`, payload)
            console.log(res);  // Log the response for debugging
            showToast("success", "Template Requested Successfully");  // Show success message on successful request

        } catch (error) {
            showToast("error", "Error Requesting Template");    // Show error message if the request fails
        }
        handleAddTemplateModal();    // Show error message if the request fails
        closeBackdrop();

    }



    return (
        <>

            <div className={TaskStyle.taskDetailsRow}>
                <div className={TaskStyle.taskDetailsCol}  >
                    <span>
                        <AttachFileIcon sx={{ color: '#6A6A6A' }} />
                    </span>
                    <p>

                        {data.specName}
                    </p>
                </div>

                <div className={TaskStyle.taskDetailsCol} >
                    <div style={{ position: "relative" }} className={commercialStyle.selectorMain}>
                        <p onClick={() => handleTemplateDropDown()} style={{fontSize:"13px",fontWeight:"500",color:"#3F3F3F"}}>
                            {selectedTemplates.find((selected) => selected.tempId === data.id)?.name || 'Select a template'} <span><KeyboardArrowUpIcon /></span></p>
                        {
                            templateDropDown &&
                            <ul className={commercialStyle.listDropDown}>
                                <li
                                    key={`option-add `}
                                    onClick={() => { handleAddTemplateModal(); handleTemplateDropDown() }}
                                >+ Add</li>
                                {avaiableTemplate.map((opt) => {


                                    return (

                                        <li
                                            key={`option-${opt.id}`}
                                            onClick={() => { handleTemplateSelect(data, opt); handleTemplateDropDown(); }}
                                        >{opt.name}</li>
                                    )
                                })}

                            </ul>
                        }

                    </div>







                </div>
            </div>



            <Modal
                open={addTemplateModal}
                onClose={handleAddTemplateModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>


                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Import Template in {data.specName}</h2>
                            <span onClick={handleAddTemplateModal}><CloseIcon /></span>
                        </div>

                        <div className={commercialStyle.templatesWrapper}>
                            {adminTemplates.length > 0 ?
                                adminTemplates.map((temp, index) => {
                                    return (
                                        <div className={commercialStyle.templateCard}>
                                            <p>{temp.name}</p>
                                            <input type="checkbox" checked={selectedTemplateItem.some((rt) => rt.id === temp.id)} onChange={() => handleTemplateCheckBox(temp)} />
                                        </div>
                                    )
                                })
                                :
                                <h4>
                                    No New Template Available to Import
                                </h4>
                            }



                        </div>
                        {adminTemplates.length > 0 &&
                            <div style={{ width: "100%", display: "flex", }}>
                                <button style={{
                                    height: "35px",
                                    padding: "10px 15px",
                                    backgroundColor: "#16469D",
                                    border: "none",
                                    color: "white",
                                    borderRadius: "5px",
                                    cursor: "pointer",

                                }}
                                    onClick={handleRequestTemplate}
                                >Send Request</button>
                            </div>

                        }


                    </div>



                </div>


            </Modal>

        </>
    )
}

export default CommercialTemplateSelector
