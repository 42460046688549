import React, { useState, useEffect } from 'react';
import taskCategoryStyle from 'pages/TaskCategory/TaskCategory.module.css';
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useParams } from 'react-router-dom';
import { PrivateComponent } from "api/axios";
import { useLocation } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BackButton from 'components/BackButton/BackButton';

const FranchiseRevisions = () => {

  // Destructuring parameters from URL
  const { orgId, productId, templateId } = useParams();

  // Getting location and state from React Router
  const location = useLocation();
  const { state } = location;

  // Navigation functionality
  const navigate = useNavigate();

  // Determining the type from state, if available
  const type = state ? state.type : null;

  // Fetching private axios instance
  const privateAxios = PrivateComponent();

  // State for storing revisions
  const [revisions, setRevisions] = useState([]);

  // Function to fetch technical revisions
  const fetchRevisions = async () => {
    try {
      const response = await privateAxios.get(`/procureadmin/getAllRevisionsOfFranchise/${productId}/${orgId}`);
      const data = response.data;
      setRevisions(data);
    } catch (error) {
      console.error('Error fetching revisions:', error);
    }
  };

  // Function to fetch commercial revisions
  const fetchCommercialRevisions = async () => {
    try {
      const response = await privateAxios.get(`/procureadmin/getAllTemplateRelatedCommercialRevisionOfFranchise/${orgId}/${templateId}`);
      const data = response.data;
      setRevisions(data);
    } catch (error) {
      console.error('Error fetching commercial revisions:', error);
    }
  };

  // Effect hook to fetch revisions based on type
  useEffect(() => {
    if (type === 'Technical-Specification') {
      fetchRevisions();
    } else if (type === 'Commercial') {
      // You need to replace orgId with the correct variable here
      fetchCommercialRevisions();
    }
  }, []);

  // Function to handle viewing details of revisions
  const handleViewDetails = (tempId, revisionId) => {
    // Navigate based on type
    if (type === 'Technical-Specification') {
      navigate(`${revisionId}`);
    }
    else {
      navigate(`${tempId}/${revisionId}`);
    }
  };






  return (
    <div className={workSpaceStyle.workSpaceMain}>
      <div className={workSpaceStyle.workSpaceHeader}>
        <div className={workSpaceStyle.workSpaceHeaderLeft}>
          <h1>
            <BackButton />
            {type === 'Technical-Specification' ? 'Technical-Specification' : 'Commercial Revisions'}

          </h1>
          <p>Revisions</p>
        </div>
        <div className={workSpaceStyle.workSpaceHeaderSearchbar}>
          <input type="text" placeholder="Search Revisions..." />
        </div>
        <div className={workSpaceStyle.workSpaceHeaderRight}>
          <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
            <Tooltip title="Help">
              {/* <HelpOutlineOutlinedIcon /> */}
            </Tooltip>
          </div>
        </div>
      </div>

      <table className={taskCategoryStyle.taskCategoryTable}>
        <tr className={taskCategoryStyle.taskCategoryTableHeader}>
          <th>Title</th>
          <th>Last Modified</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
        <tbody>
          {revisions.map((rev, index) => (
            <tr key={index} className={taskCategoryStyle.taskCategoryTableData}>
              <td>{rev.name}</td>
              <td>{rev.lastModified.split("T")[0]}</td>
              <td>
                <span className={taskCategoryStyle.StatusPill}>
                  {rev.revisionStatus}
                </span>
              </td>
              <td>
                <div className={taskCategoryStyle.actionTd}>
                  <span onClick={() => handleViewDetails(rev.productId, rev.id)}>
                    <Tooltip title="View">
                      <VisibilityIcon />
                    </Tooltip>
                  </span>
                  <span>
                    <Tooltip title="More">
                      <FileDownloadIcon />
                    </Tooltip>
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>

      </table>
    </div>
  );
};

export default FranchiseRevisions;
