import React, { useEffect, useState, useRef } from 'react'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import ProcaHeader from './ProcaHeader/ProcaHeader'
import ProcaContent from './ProcaContent/ProcaContent'
import ProcaStatistics from './ProcaStatistics/ProcaStatistics'
import ProcaSlider from './ProcaSlider/ProcaSlider'
import ProcaDemo from './ProcaDemo/ProcaDemo'


const HomePage = () => {

  // Custom hook to get authentication state
  const { auth } = useAuth();

  // Hook to programmatically navigate
  const navigate = useNavigate();

  // State to manage the loading state of the component
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    // Redirect based on user role and authentication state
    if (auth.companyName !== null && auth.token !== null && auth.userData !== null && auth.userData.stageCount === 5 && ["KAM", "ADMIN", "KAM"].includes(auth.roles)) {
      navigate(`/${auth.companyName}`, { state: { data: null }, replace: true })
    }

    else if (auth.roles === "VENDOR" && auth.userData.stageCount === 5 && auth.token !== null && auth.userData !== null) {
      navigate(`/vendor`, { state: { data: null }, replace: true })
    }
    else {
    // Stop the loader if conditions are not met
      setLoader(false);
    }
  }, [])


// Reference to the subscription section
  const subscriptionRef = useRef(null);

// Function to smoothly scroll to the subscription section
  const scrollToContainer = () => {
    const targetElement = subscriptionRef.current;
    if (targetElement) {
      const targetOffset = targetElement.offsetTop;
      const scrollDuration = 1000; // Set the duration in milliseconds (1 second in this example)
      const startTime = performance.now();

      const scrollStep = (timestamp) => {
        const currentTime = timestamp - startTime;
        if (currentTime < scrollDuration) {
          const scrollProgress = currentTime / scrollDuration;
          const newScrollPosition = targetOffset * scrollProgress;
          window.scrollTo(0, newScrollPosition);
          requestAnimationFrame(scrollStep);
        } else {
          window.scrollTo(0, targetOffset);
        }
      };

      requestAnimationFrame(scrollStep);
    }
  }

  return (
    <div>
      {!loader &&

        <>
          {/* Pass the scrollToContainer function to the ProcaHeader component */}
          <ProcaHeader onPricingButtonClick={scrollToContainer} />
          <ProcaContent />
          <ProcaStatistics />
          <ProcaSlider />
          <ProcaDemo />
        </>
      }
    </div>
  )
}

export default HomePage
