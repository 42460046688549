import React from 'react'
import SendIcon from '@mui/icons-material/Send';
import chatInputStyle from './ChatInputField.module.css'


const ChatInputField = ({revisionId,chatValue,onChatSend,setChatValue}) => {
  return (
    <div className={chatInputStyle.ChatInputMain}>
        <form onSubmit={(e)=>onChatSend(e,revisionId)}>
      <input type="text" value={chatValue} placeholder='Send Comment' onChange={(e)=>setChatValue(e.target.value)}/>
      <button type="submit"><span><SendIcon/></span></button>
      </form>
    </div>
  )
}

export default ChatInputField
