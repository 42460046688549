import React, { useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import superAdminLayoutStyle from './Layout.module.css'
import SuperAdminNavbar from 'components/Navbar/SuperAdminNavbar'
import SuperAdminHeader from 'components/Header/SuperAdminHeader'



const SuperAdminLayout = () => {
  const [isOpen,setIsOpen]=useState(false);

  const handleDrawerOpen=()=>{
    setIsOpen(prev=>!prev)
  }

  
  return (
    <div className={superAdminLayoutStyle.layoutMain}>
      <>
      <SuperAdminHeader isOpen={isOpen} handleDrawerOpen={handleDrawerOpen}/>
      </>
      <div className={`${superAdminLayoutStyle.layoutPage} ${isOpen?superAdminLayoutStyle.open:""}` }>
        <>
        <SuperAdminNavbar/>
        </>
        <div className={superAdminLayoutStyle.PageContainer}>
        <Outlet/>
        </div>
     
      </div>      
    </div>
  )
}

export default SuperAdminLayout
