
import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import templateStyle from './TemplateSelector.module.css';
import useBackdrop from 'hooks/useBackdrop';
import { PrivateComponent } from 'api/axios';
import CloseIcon from '@mui/icons-material/Close';

const TemplateSelector = ({ templateArray, handleModal, onsubmit, showToast }) => {

    // Custom hook to handle backdrop component, show and hide functionality
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

    // State to store template categories
    const [templateCategory, setTemplateCategory] = useState([]);

    // State to store selected template data
    const [templateData, setTemplateData] = useState(null);

    // State to track if a category is selected
    const [categorySelected, setCategorySelected] = useState(false);

    // Function to get an instance of axios for making private API calls
    const privateAxios = PrivateComponent();


    // Function to fetch template categories
    const getTemplateCategory = async () => {
        openBackdrop(); // Show backdrop
        try {

            const res = await privateAxios.get('/procureadmin/getAllSpecificTemplates'); // API call to fetch categories
            const templates = res.data;
            // console.log(res.data);
            setTemplateCategory(res.data)// Update template categories
        } catch (error) {
            showToast("error", error.message);// Show error toast if there's an error
        }
        closeBackdrop();// Close backdrop
    }

    // Effect to fetch template categories when component mounts
    useEffect(() => {
        getTemplateCategory();
    }, [])


    // Function to handle selection of a template category
    const handleTemplateCategorySelect = async (specificationId, categoryId) => {
        if (specificationId !== undefined || specificationId !== null || categoryId !== undefined || categoryId !== null) {
            setTemplateData({ specificationId: specificationId, categoryId: categoryId })
            setCategorySelected(true);
        }
    }


    // ==================fetch Ecom Templates=====================

    // Function to fetch Ecom templates based on selected category
    const [ecomTemplates, setEcomTemplates] = useState([]);

    const getEcomTemplates = async () => {
        openBackdrop();// Show backdrop
        try {
            const res = await privateAxios.get(`/procureadmin/getAllImportedTemplates/${templateData.categoryId}`)
            setEcomTemplates(res.data);// Update Ecom templates
        } catch (error) {
            showToast("error", error.message);// Show error toast if there's an error
        }
        closeBackdrop(); // Close backdrop
    }

    // Effect to fetch Ecom templates when category is selected
    useEffect(() => {

        if (categorySelected) {
            getEcomTemplates();
        }
    }, [categorySelected])


    // State to manage selected templates
    const [selectedTemplate, setSelectedTemplate] = useState([]);

    // Function to handle selection of a template
    const handleTemplateCheckBox = (temp) => {
        if (selectedTemplate.some((rt) => rt.id === temp.id)) {
            setSelectedTemplate(prev => prev.filter((rt) => rt.id !== temp.id))
        }
        else {
            setSelectedTemplate(prev => [...prev, temp]);
        }
    }

    // ==================handle import templae=============================

    // Function to handle addition of selected templates
    const handleTemplateAdd = async () => {
        onsubmit({ selectedTemplate: selectedTemplate });// Call onSubmit function with selected templates
    }



    return (
        <>

            <BackdropComponent />
            {/* commercial Category modal */}


            <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>
                <div style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "felx-start",
                    flexDirection: "column",
                    gap: "20px"

                }}>

                    <div className={workSpaceStyle.modalHeader}>
                        <h2 style={{ fontSize: "19px", fontWeight: "700" }}>{!categorySelected ? "Template Category " : "Import Template"}</h2>
                        <span onClick={handleModal}><CloseIcon /></span>
                    </div>




                    {
                        !categorySelected ?


                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                {

                                    templateCategory.map((row, index) => {
                                        return (

                                            <div onClick={() => handleTemplateCategorySelect(row.specificationId, row.id)} className=''
                                                style={{
                                                    cursor: "pointer", height: "40px", display: "flex",
                                                    width: "100%",
                                                    justifyContent: "space-between", alignItems: "center",
                                                    padding: "0 10px",

                                                }}>


                                                <h3 style={{ fontWeight: "400" }}>

                                                    {row.name}
                                                </h3>
                                                <div style={{ width: "auto" }}>
                                                    <span >

                                                        <ArrowForwardIosIcon />
                                                    </span>

                                                </div>

                                            </div>
                                        )

                                    }
                                    )
                                }




                            </div>

                            :





                            <>


                                <div className={templateStyle.templatesWrapper}>
                                    {
                                        ecomTemplates.map((temp, index) => {
                                            return (
                                                <div className={templateStyle.templateCard}>
                                                    {templateArray.some((tt) => tt.tempId === temp.id) ?

                                                        <>
                                                            <p>{temp.name}</p>
                                                            <p style={{ color: "grey" }}>Already Added</p>
                                                        </>
                                                        :
                                                        <>
                                                            <p>{temp.name}</p>
                                                            <input type="checkbox" checked={selectedTemplate.some((rt) => rt.id === temp.id)} onChange={() => handleTemplateCheckBox(temp)} />

                                                        </>

                                                    }
                                                </div>
                                            )
                                        })
                                    }


                                </div>
                                <div className={workSpaceStyle.d_flex_start} style={{ gap: "10px" }}>
                                    <button className={`${workSpaceStyle.modalBtn}`} style={{ backgroundColor: "#16469D", color: "white" }} onClick={handleTemplateAdd}>Add Template</button>
                                    <button className={`${workSpaceStyle.modalBtn}`} style={{ color: "#16469D" }} onClick={handleModal}> Discard</button>

                                </div>
                            </>
                    }

                </div>



            </div>




            {/* commercial template add */}


        </>
    )
}

export default TemplateSelector
