import { PrivateComponent } from 'api/axios';// Importing PrivateComponent from Axios for authenticated requests
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx';// Importing XLSX library for Excel manipulation
import styles from './PartImport.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExcelUploader from 'components/ExcelUploader/ExcelUploader';
import useAuth from 'hooks/useAuth';// Importing custom hook for authentication
import { useNavigate } from 'react-router-dom';// Importing useNavigate for navigation


const PartImport = () => {
    // Custom hooks and state initialization
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Backdrop hooks
    const { Toast, showToast } = useToast(); // Toast hooks
    const [jsonData, setJsonData] = useState(null); // State for JSON data
    const privateAxios = PrivateComponent(); // Private Axios instance
    const { auth } = useAuth(); // Authentication info
    const navigate = useNavigate(); // Navigation hook


    // Handle file upload
    const handleUpload = async () => {
        try {
            const res = await privateAxios.post(``, jsonData);
        } catch (error) {
            console.log(error);
        }
    }

    // State for selected subcategories
    const [selectedSubcategories, setSelectedSubcategories] = useState([])

    // State for category search
    const [catSearch, setCatSearch] = useState("");

    // State for available categories
    const [availableCategories, setAvailableCategories] = useState([]);

    // State for category dropdown
    const [catDropDown, setCatDropDown] = useState(false);

    // Function to toggle category dropdown
    const toggleCatDropDown = () => {
        setCatDropDown(prev => !prev);
    }

    // Function to fetch categories
    const getCategories = async () => {
        openBackdrop();
        try {
            // API call to fetch all categories
            await privateAxios.get(`/procurement/getAllCategories`).then(res => {
                if (res.status === 200) {
                    setAvailableCategories(res.data);// Set available categories
                }
            })

        } catch (error) {
            showToast("error", "Error Fetching Categories");// Show error toast
        }
        closeBackdrop()// Close backdrop
    }


    // Function to fetch category data
    const getCategoryData = async (category) => {
        openBackdrop();// Show backdrop
        try {
            const res = await privateAxios.get(`/procurement/getCategoriesByParent/${category.categoryId}`); // API call to fetch categories by parent
            setSelectedSubcategories([{ ...res.data }]);// Set selected subcategories
        } catch (err) {
            showToast("error", "Error Fetching Sub Categories"); // Show error toast
        }
        closeBackdrop()
    };

    useEffect(() => {
        getCategories();
    }, [])

    // Use effect to fetch categories on component mount
    const handleCategorySelector = async (cat) => {
        toggleCatDropDown();
        await getCategoryData({ ...cat, level: 1 });
    }

    // Function to handle category selection
    const handleSubcategorySelect = (subcategory, index) => {
        const tt = selectedSubcategories.filter((cat) => {
            return cat.level < subcategory.level;
        })
        const newSub = [
            ...tt,
            { ...subcategory },
        ]
        setSelectedSubcategories(newSub);
    };


    // State for product data
    const [productData, setProductData] = useState([]);
    const handleFileChange = (files) => {
        const tempArray = [];

        const readFile = (file, index) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const binaryString = event.target.result;
                const workbook = XLSX.read(binaryString, { type: 'binary' });

                // Extracting data from Excel sheets
                const productDataSheet = workbook.SheetNames[0];
                const proData = workbook.Sheets[productDataSheet];
                const productData = XLSX.utils.sheet_to_json(proData)[0] || {};

                const specificationSheet = workbook.SheetNames[1];
                const specData = workbook.Sheets[specificationSheet];
                const productSpecification = (XLSX.utils.sheet_to_json(specData)[0] || {}).specification || '';

                const attributeDataSheet = workbook.SheetNames[2];
                const attriData = XLSX.utils.sheet_to_json(workbook.Sheets[attributeDataSheet]);

                const documentDataSheet = workbook.SheetNames[3];
                const docData = XLSX.utils.sheet_to_json(workbook.Sheets[documentDataSheet]);

                // Pushing data to temporary array
                tempArray.push({
                    ...productData,
                    categoryId: selectedSubcategories[selectedSubcategories.length - 1].categoryId,
                    // organisationId: auth.orgId,
                    productSpecification: { name: productSpecification },
                    productAttributes: attriData,
                    productDocument: docData
                });

                if (index === files.length - 1) {
                    // All files have been processed
                    setProductData(tempArray);
                }
            };
            reader.readAsBinaryString(file);
        };

        files.forEach((file, index) => {
            readFile(file, index);
        });
    };

    // Function to build category tree
    const buildTree = (categories) => {
        let tree = [];
        let lookup = {};

        categories.forEach(category => {
            category.branch = [];
            lookup[category.categoryId] = category;
        });

        categories.forEach(category => {
            if (category.parent !== null) {
                const parent = lookup[category.parent];
                parent.branch.push(category);
            } else {
                tree.push(category);
            }
        });
        return tree;
    }


    // Function to add product
    const handleAddPro = async () => {
        if (productData.length === 0) {
            alert("Please Upload a Product")
        }
        else {
            try {
                // Construct updated categories
                let updatedCategories = selectedSubcategories.map((cat) => {
                    return {
                        categoryId: cat.categoryId,
                        categoryName: cat.categoryName,
                        parent: cat.parent,
                        organisationId: auth.orgId,//show be changed to state.data.orgId
                        level: parseInt(cat.level),
                        branch: [],
                    }
                });

                // Build category tree
                const categories = buildTree(updatedCategories);

                // API call to import categories
                const catRes = await privateAxios.post(`/procureadmin/importCategories`, categories);
                if (catRes.status === 200) {
                    const prodRes = await privateAxios.post(`/procurement/createProducts`, productData);
                    if (prodRes.status === 200) {
                        showToast("success", "product Added Successfully");
                        setSelectedSubcategories([])
                        navigate(-1)
                    }
                    else {
                        showToast("error", "Error Adding Product");
                    }
                }
            } catch (error) {
                return null;
            }
        }
    }






    return (
        <>

            <Toast />
            <BackdropComponent />

            <div className={styles.Container} style={{ height: "100%" }}>
                <div className={styles.mainContainer}>
                    <div className={styles.inputGroup}>

                        <div className={styles.inputRow}>
                            <label htmlFor="">Category</label>
                            <div className={styles.valueContainer} onClick={toggleCatDropDown}>
                                {selectedSubcategories[0]?.categoryName || "Please Select A category"}
                                <span className={styles.dropIcon}>
                                    <KeyboardArrowDownIcon />
                                </span>

                            </div>
                            {
                                catDropDown &&
                                <>
                                    <div className={styles.dropdownBackDrop}
                                        onClick={toggleCatDropDown}>
                                    </div>
                                    <div className={styles.selectorDropDownWrapper} >

                                        <div className={styles.selectorSearchBar}>
                                            <input type="text"
                                                value={catSearch}
                                                onChange={(e) => setCatSearch(e.target.value)
                                                }
                                                placeholder="Search for categories..." />
                                        </div>
                                        <ul className={styles.selectorList}>

                                            {
                                                availableCategories
                                                    .filter((cat) => cat.name.trim().toLowerCase().includes(catSearch.toLowerCase()))
                                                    .map((cat) => {
                                                        return (
                                                            <li
                                                                onClick={() => handleCategorySelector(cat)}
                                                            >{cat.name}</li>
                                                        )
                                                    })}
                                        </ul>
                                    </div>
                                </>
                            }
                        </div>


                        {selectedSubcategories.length > 0 &&
                            selectedSubcategories.map((selectedCategory, index) => {

                                if (selectedCategory.branch.length > 0) {
                                    return (
                                        <div className={styles.inputRow}>
                                            <label> Sub-Category</label>
                                            <SubcategorySelectorInput
                                                category={selectedCategory}
                                                selectedSubcategories={selectedSubcategories}
                                                onSelectSubcategory={(subcategory) => handleSubcategorySelect(subcategory, index)}
                                                index={index}

                                            />
                                        </div>
                                    )
                                }
                            }
                            )}

                        {selectedSubcategories[selectedSubcategories.length - 1]?.branch.length === 0 &&
                            <div className={styles.inputRow}>
                                <label htmlFor="">Upload Product</label>
                                <ExcelUploader onChange={handleFileChange} />
                            </div>
                        }
                    </div>
                </div>

                <div>
                    <button onClick={handleAddPro} className={styles.upBtn}>
                        Upload
                    </button>
                </div>
            </div>
        </>
    )
}

export default PartImport


const SubcategorySelectorInput = ({ category, selectedSubcategories, onSelectSubcategory, index }) => {

    const [subCatSelectDropDown, setSubCatSelectDropDown] = useState(false);
    const [subCatSelected, setSubCatSelected] = useState("");
    const [subCatSearch, setSubCatSearch] = useState("");
    const handleSubCatSelectorDropDown = () => {
        setSubCatSelectDropDown(prev => !prev)
    }

    const handleSubCategorySelector = (subCat) => {
        handleSubCatSelectorDropDown();
        onSelectSubcategory(subCat);
        setSubCatSelected(subCat.categoryName);
    }

    return (

        <>
            <div className={styles.valueContainer} onClick={handleSubCatSelectorDropDown}>
                {(index < (selectedSubcategories.length - 1) && subCatSelected) || "Please Select A Sub-Category"}
                <span className={styles.dropIcon}>
                    <KeyboardArrowDownIcon />
                </span>
            </div>

            {
                subCatSelectDropDown &&
                <>
                    <div
                        className={styles.dropdownBackDrop}
                        onClick={handleSubCatSelectorDropDown}
                    >
                    </div>
                    <div className={styles.selectorDropDownWrapper} >

                        <div className={styles.selectorSearchBar}>
                            <input type="text"
                                value={subCatSearch}
                                placeholder='Search for sub-categories'
                                onChange={(e) => setSubCatSearch(e.target.value)} />
                        </div>
                        <ul className={styles.selectorList}>

                            {category.branch.filter((subCat) => {
                                return subCat.categoryName && subCat.categoryName.trim().toLowerCase().includes(subCatSearch.toLowerCase());
                            }).map((subCat) => {
                                return (
                                    <li onClick={() => handleSubCategorySelector(subCat)}>{subCat.categoryName}</li>
                                )
                            })}
                        </ul>
                    </div>
                </>
            }
        </>
    )
}
