import React from "react";
import { useLocation, Navigate, Outlet, useParams } from "react-router-dom";// Import necessary hooks and components from react-router-dom
import useAuth from "../hooks/useAuth";// Import custom hook for authentication
import jwt_decode from 'jwt-decode';// Import library for decoding JWT tokens
import { useDispatch } from 'react-redux';// Import useDispatch hook from react-redux for dispatching actions
import { setLogout } from '../state/AuthState';// Import action creator for logging out

const RequireVendorAuth = () => {
    const { auth } = useAuth(); // Get authentication information using custom hook
    const location = useLocation(); // Get current location using useLocation hook
    const { client } = useParams(); // Get URL parameters using useParams hook
    const dispatch = useDispatch(); // Initialize useDispatch hook for dispatching actions

 // If user is not authenticated, not a vendor, or doesn't have required stage count, redirect to login page
    if (!auth || auth.roles !== "VENDOR" || auth?.userData?.stageCount !== 5) {
        return <Navigate to="/login" state={{ from: location }} replace />;// Redirect to login page with previous location state
    }

     // If user is authenticated
    if (auth) {
        const payloadToken = jwt_decode(auth.token);// Decode JWT token
        const isTokenExpired = Date.now() >= payloadToken.exp * 1000; // Check if token is expired

          // If token is not expired, render child routes
        if (!isTokenExpired) {
            return <Outlet />; // Render child routes
        } else {
            dispatch(setLogout()); // Dispatch action to log out user
            return <Navigate to="/login" state={{ from: location }} replace />;// Redirect to login page with previous location state
        }
    }
}

export default RequireVendorAuth;