import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import kamAssignStyle from "./FranchiseTask.module.css"
import Tooltip from '@mui/material/Tooltip';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { PrivateComponent } from 'api/axios';
import useBackdrop from 'hooks/useBackdrop';
import useAuth from 'hooks/useAuth';
import PreviewIcon from '@mui/icons-material/Preview';
import ViewSuperadminTask from './SuperAdminCreateTask/SuperAdminTaskPannel/ViewSuperadminTask';
import { useParams } from 'react-router-dom';


const AssignTo = [
    { id: 1, email: "surajjadhav.kjss@gmail.com" },
    { id: 2, email: "rajkadam347300@gmail.com" },
    { id: 3, email: "mayur1006@gmail.com" },
];

const FranchiseTask = () => {
    // Get orgId and kamId from URL params
    const { orgId, kamId } = useParams();
    // Initialize state variables
    const [data, setData] = useState([]);
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

    // State variables for managing dropdowns
    const [statusSelectorOpen, setStatusSelectorOpen] = useState('');
    const [prioritySelectorOpen, setPrioritySelectorOpen] = useState('');

    // Initialize private axios instance and get authentication info
    const privateAxios = PrivateComponent();
    const { auth } = useAuth();

    // State variables for handling multi-clicks
    const [multiClick, setMultiClick] = useState(false);
    const handleMultiClick = () => {
        setMultiClick(prev => !prev)
    }
    const [multiClick2, setMultiClick2] = useState(false);
    const handleMultiClick2 = () => {
        setMultiClick2(prev => !prev)
    }
    const [multiClick3, setMultiClick3] = useState(false);
    const handleMultiClick3 = () => {
        setMultiClick3(prev => !prev)
    }
    const [multiClick4, setMultiClick4] = useState(false);
    const handleMultiClick4 = () => {
        setMultiClick4(prev => !prev)
    }
    const [myMulti, setMymulti] = useState(false);
    const handleMyMultiClick = () => {
        setMymulti(prev => !prev)
    }

    // State variable for modal visibility
    const [viewSuperAdminModal, setViewSuperAdminModal] = useState(false)

    // State variable and handler for temporary data
    const [tempData, SetTempData] = useState(null)
    const handleViewtasksModal = (data) => {

        setViewSuperAdminModal(prev => !prev)
        if (data !== null && data !== undefined && data !== "") {
            SetTempData(data)
        }
    }

    //Handle Status Selector
    const handleStatusSelector = (index) => {

        if (statusSelectorOpen === index) {
            setStatusSelectorOpen('')
        } else {
            setStatusSelectorOpen(index)
        }
    }

    //Handle Priorty Selector
    const handlePrioritySelector = (index) => {

        if (prioritySelectorOpen === index) {
            setPrioritySelectorOpen('')
        } else {
            setPrioritySelectorOpen(index)
        }
    }

//Satet in Select Modal
    const [mySelect, setMySelect] = useState('');

    const handleMymodal = (index) => {
        if (mySelect === index) {
            setMySelect('')
        } else {
            setMySelect(index)
        }
    }

    // -------------------------------------------------New Sortting----------------------------------------------------------------------

    // State variables for sorting dropdowns
    const [isStartDateDropdownOpen, setIsStartDateDropdownOpen] = useState(false);

    const toggleStartDateDropdown = () => {
        setIsStartDateDropdownOpen(!isStartDateDropdownOpen);
    };

    const handleStartDateSort = (sortType) => {
        let sortedItems = [...data];

        if (sortType === 'Newest') {
            sortedItems.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        } else if (sortType === 'Oldest') {
            sortedItems.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        }

        setData(sortedItems);
        setIsStartDateDropdownOpen(false);
    };
    // ---------------------------------------------------------------------------

    //Set End Date Dropdown modal
    const [isEndDateDropdownOpen, setIsEndDateDropdownOpen] = useState(false);

    const toggleEndDateDropdown = () => {
        setIsEndDateDropdownOpen(!isEndDateDropdownOpen);
    };

    const handleEndDateSort = (sortType) => {
        let sortedItems2 = [...data];

        if (sortType === 'Newest') {
            sortedItems2.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
        } else if (sortType === 'Oldest') {
            sortedItems2.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
        }

        setData(sortedItems2);
        setIsEndDateDropdownOpen(false);
    };


    // State variables for selected status and priority
    const [selectedStatus, setSelectedStatus] = useState('')
    const handleStatusChange = (taskId, currentStatus, newStatus) => {
        // Your existing handleStatusChange logic here
        // You may want to update the state or perform any other actions
        setSelectedStatus(newStatus);
    };

    const [selectPriority, setSelectPriority] = useState('')

    const handlePriorityChange = () => {
        setSelectPriority(selectPriority)
    }

    // State variable for button disable state
    const [btn, setBtn] = useState({
        isDisable: false, // or true, depending on your initial state
    });

    const handleCheckBox = () => {
        setBtn(prevData => ({
            ...prevData,
            isDisable: !prevData.isDisable,
        }));
    };

    // State variable for selected task
    const [selected, setSelected] = useState('');

    const handleGame = (id, game) => {
        setSelected(id === selected ? '' : id);
    }

    // Function to fetch all tasks
    const getAllTasks = async () => {
        openBackdrop();

        try {
            if (auth.roles === "SUPERADMIN") {
                // Fetch tasks based on user role
                const response = await privateAxios.get(`/procureadmin/getTaskOfFranchise/${kamId}`)
                    .then((res) => {
                        setData(res.data);

                        if (res.status === 200) {
                            const data = res.data;
                            closeBackdrop();
                        }
                        closeBackdrop();

                    }).catch((error) => {
                        // console.log(error)
                        closeBackdrop();
                    }
                    )
            } else {
                const response = await privateAxios.get(`/procureadmin/getTaskOfFranchise/${kamId}`)
                    .then((res) => {
                        setData(res.data);

                        if (res.status === 200) {
                            const data = res.data;
                            closeBackdrop();
                        }
                        closeBackdrop();

                    }).catch((error) => {
                        // console.log(error)
                        closeBackdrop();
                    }
                    )
            }

        } catch (error) {
            // console.log(error)
            closeBackdrop();
        }
    }

// Fetch tasks on component mount
    useEffect(() => {
        getAllTasks()

    }, []);


     // State variable for pagination
    const [taskPage, setTaskPage] = useState(1);

      // Function to load more tasks
    const loadMore = async (page, size) => {
        try {
            const response = await privateAxios.get(`/task/getTaskByRoles?pageNo=${page}&pageSize=${size}`)
                .then((res) => {
                    // console.log(res.data)
                    setData(prev => {
                        return [...prev, ...res.data]
                    });


                    // console.log(data)
                    setTaskPage(prev => prev + 1);

                }).catch((error) => {
                    // console.log(error)
                }
                )
        } catch (error) {
            // console.log(error)
        }
    }



    return (
        <div className={kamAssignStyle.table1}>
            <table style={{ borderCollapse: 'collapse' }}>
                <thead>
                    <tr className={kamAssignStyle.theader}>
                        <th>Task ID</th>
                        <th>Title</th>
                        <th>
                            <div className={kamAssignStyle.SortingDate}>
                                <p onClick={handleMultiClick3} style={{ color: '#000' }}>
                                    <span style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                        Start Date<ImportExportIcon onClick={toggleStartDateDropdown} />
                                    </span>
                                </p>
                                {isStartDateDropdownOpen &&
                                    <ul >
                                        <li onClick={() => handleStartDateSort('Newest')}>Newest</li>
                                        <li onClick={() => handleStartDateSort('Oldest')}>Oldest </li>
                                    </ul>
                                }
                            </div>
                        </th>
                        <th >
                            <div className={kamAssignStyle.SortingDate}>
                                <p className={kamAssignStyle.jjjjj} onClick={handleMultiClick4} style={{ color: '#000' }}>
                                    <span style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
                                        End Date<ImportExportIcon onClick={toggleEndDateDropdown} />
                                    </span>
                                </p>
                                {isEndDateDropdownOpen &&
                                    <ul >
                                        <li onClick={() => handleEndDateSort('Newest')}>Newest</li>
                                        <li onClick={() => handleEndDateSort('Oldest')}>Oldest </li>
                                    </ul>
                                }
                            </div>
                        </th>
                        <th>E/C Date</th>
                        <th>Status</th>
                        <th>Task</th>
                        <th>Label</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </table>

            <table style={{ borderCollapse: 'collapse' }}>
                <tbody >
                    {
                        // .filter((item) =>
                        //     item?.title?.trim().toLowerCase().includes(search?.toLowerCase()))
                        data && data?.map((item, index) =>
                            <tr className={kamAssignStyle.tbody}>
                                <td>{index + 1}</td>
                                <td>{item.title}</td>
                                <td style={{ backgroundColor: '#d2d2d2' }}>{item.startDate}</td>
                                <td style={{ backgroundColor: '#d2d2d2' }}>{item.endDate}</td>
                                <td>{item.completedDate}</td>

                                <td className={kamAssignStyle.SelectBar} style={{ backgroundColor: '#d2d2d2' }}>

                                    <div className={kamAssignStyle.statusSelector}>
                                        <p style={{ color: '#000' }}>
                                            {item.status?.substring(0, 1).toUpperCase() + item.status?.substring(1, item.status.length).toLowerCase()}
                                        </p>         
                                    </div>
                                </td>

                                <td className={kamAssignStyle.Selecttaskbar} >
                                    {item.type === 'Technical Specification' ? (
                                        <span>Technical</span>
                                    ) : item.type === 'Commercial' ? (
                                        <span>Commercial</span>
                                    ) : null}
                                </td>


                                <td className={kamAssignStyle.SelectBar}>
                                    <div className={kamAssignStyle.prioritySelector}>
                                        <p
                                            style={
                                                {
                                                    backgroundColor: item.priority === 'HIGH' ? '#ec5252' : item.priority === 'MEDIUM' ? '#f5ac38' : item.priority === 'LOW' ? '#b3f289' : '#fff',
                                                    color: item.priority === 'High' ? '#fff' : item.priority === 'Medium' ? '#fff' : item.priority === 'Low' ? '#fff' : '#fff',
                                                    height: "27px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }
                                            }
                                        >{item.priority?.substring(0, 1).toUpperCase() + item.priority?.substring(1, item.priority.length).toLowerCase()}
                                           
                                        </p>
                                        
                                    </div>

                                </td>
                                
                                <td>
                                    <div className={kamAssignStyle.actionIcons}>
                                        <Tooltip title='Task view'> <PreviewIcon onClick={(e) => handleViewtasksModal(item)} /> </Tooltip>
                                    </div>
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>

            <Modal
                open={viewSuperAdminModal}
                onClose={handleViewtasksModal}>

                <ViewSuperadminTask handleViewtasksModal={handleViewtasksModal} viewSuperAdminModal={viewSuperAdminModal} getAllTasks={getAllTasks} data={tempData} />

            </Modal>
            <BackdropComponent />
        </div>
    )
}

export default FranchiseTask
