const DateFormater = (date) => {
    const loginDate = new Date(date);
    const currentDate = new Date();
    const timeDifference = currentDate - loginDate;
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    let displayString;

    if (daysDifference > 0) {
        // If the difference is more than 1 day, display the date
        displayString = `${loginDate.toDateString()}`;
    } else if (hoursDifference > 0) {
        // If the difference is more than 1 hour, display the hours
        displayString = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
    } else if (minutesDifference > 0) {
        // If the difference is more than 1 minute, display the minutes
        displayString = `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
    } else {
        // If the login was within the last minute, display "Just now"
        displayString = "Just now";
    }

    return displayString;
}


export default DateFormater;