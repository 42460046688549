import React, { useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import VendorHeader from 'components/Header/VendorHeader'
import layoutStyle from './Layout.module.css'
import VendorNavbar from 'components/Navbar/VendorNavbar'



const VendorLayout = () => {
  const [isOpen,setIsOpen]=useState(false);

  const handleDrawerOpen=()=>{
    setIsOpen(prev=>!prev)
  }

  
  return (
    <div className={layoutStyle.layoutMain}>
      <>
      <VendorHeader isOpen={isOpen} handleDrawerOpen={handleDrawerOpen}/>
      </>
      <div className={`${layoutStyle.layoutPage} ${isOpen?layoutStyle.open:""}` }>
        <>
        <VendorNavbar/>
        </>
        <div className={layoutStyle.PageContainer}>
        <Outlet/>
        </div>
     
      </div>



      
    </div>
  )
}

export default VendorLayout
