import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import multiSelectStyle from './MultiSelect.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



const MultiSelect = ({ categories, setCategories, availableDropDown, placeholder }) => {
    // State to manage dropdown open/close
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // Function to toggle dropdown
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };


    // Function to handle category selection
    const handleCategoryToggle = (category) => {
        if (categories?.includes(category)) {
            const updatedCategories = categories?.filter(cat => cat !== category);
            setCategories(updatedCategories);
        } else {
            // Add category if not selected
            setCategories([...categories, category]);
        }
    };

    // Remove category if already selected
    const handleRemoveCategory = (event, category) => {
        event.stopPropagation(); // Stop event propagation here
        const updatedCategories = categories?.filter(cat => cat !== category);
        setCategories(updatedCategories);



    };


    return (
        <div className={multiSelectStyle.dropdownContainer}>
            <div
                className={`${multiSelectStyle.dropdown} ${dropdownOpen && multiSelectStyle.open}`}
                onClick={toggleDropdown}
            >
                <div className={multiSelectStyle.selectedCategories}>
                    {categories.length > 0 ?
                        categories.map((category, index) => (
                            <div key={index} className={multiSelectStyle.selectedCategory}>
                                {category}
                                <CloseIcon
                                    className={multiSelectStyle.closeIcon}
                                    onClick={(event) => handleRemoveCategory(event, category)} // Pass event
                                />
                            </div>
                        )) :
                        <p>{placeholder || 'Select categories'} </p>}
                </div>
                <KeyboardArrowDownIcon
                    className={multiSelectStyle.arrowIcon}
                    onClick={toggleDropdown}
                />
            </div>
            {dropdownOpen &&
                <>
                    <div className=''></div>
                    <div className={multiSelectStyle.dropdownContent}>
                        {availableDropDown.map((category, index) => (
                            <div
                                key={index}
                                className={multiSelectStyle.dropdownItem}
                                onClick={() => handleCategoryToggle(category)}
                            >
                                <span>{category}</span>
                                <input
                                    type="checkbox"
                                    name='categorySelect'
                                    checked={categories.includes(category)}
                                    readOnly
                                />

                            </div>
                        ))}


                    </div>

                </>
            }

        </div>
    )
}

export default MultiSelect
