import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import superadminStyle from '../../commonCss.module.css';
import Tooltip from '@mui/material/Tooltip';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import DateFormater from 'utility/DateFormater';
import GroupSorterData from 'utility/GroupSorterData';
import TableSorterDropDown from 'components/TableSorterDropDown/TableSorterDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'components/BackButton/BackButton';


// Component for displaying organisations by product
const OrganisationByProduct = () => {
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Using custom hook for backdrop
    const { showToast, Toast } = useToast(); // Using custom hook for toast notifications
    const privateAxios = PrivateComponent(); // Getting private Axios instance
    const navigate = useNavigate(); // Hook for navigation
    const { productId } = useParams(); // Hook to get productId from URL parameters
    const [searchValue, setSearchValue] = useState(''); // State for search value
    const [data, setData] = useState([]); // State for storing fetched data
    const [sorterData, setSorterData] = useState({}); // State for storing data for the sorter


    // Function to fetch data from the server
    const getData = async () => {
        openBackdrop(); // Open the backdrop
        try {
            const res = await privateAxios.get(`/procureadmin/getAllOrganisationByProductId/${productId}`); // Fetch data
            const { data } = res; // Destructure data from response
            setData(data); // Set fetched data to state
            setSorterData(GroupSorterData(data)); // Group data for sorting
        } catch (error) {
            showToast("error", error.message);// Show error toast
        }
        closeBackdrop();  // Close the backdrop
    }

    useEffect(() => {
        getData(); // Fetch data on component mount
    }, []); // Empty dependency array ensures this runs only once

    // =====================handle active org======================


    // Function to handle enabling or disabling product for an organisation
    const handleCheckBox = async (orgId, productId, type) => {
        openBackdrop();// Open the backdrop
        try {
            let url = "";
            if (type) {
                url = `/procureadmin/DisableProductForOrganisation`; // URL for disabling product
            }
            else {
                url = `/procureadmin/EnableProductForOrganisation`; // URL for enabling product

            }
            const res = await privateAxios.post(`${url}/${productId}/${orgId}`);
            if (res.status === 200) {

                if (!type) {
                    showToast("success", "Porduct Enabled for Organaistion"); // Show success toast for enabling
                }
                else {
                    showToast("success", "Porduct Disabled for Organaistion"); // Show success toast for disabling
                }
            }
            getData();// Refresh data
        } catch (error) {
            showToast("error", error.message); // Show error toast
        }
        closeBackdrop(); // Close the backdrop
    }


    // ===================sorting states==================

    const [sorterOpen, setSorterOpen] = useState(""); // State for tracking which sorter is open

    // Function to toggle the visibility of sorter dropdown
    const toggleSorter = (type) => {
        if (sorterOpen === type) {
            setSorterOpen("");// Close the sorter if it's already open
        }
        else {
            setSorterOpen(type);// Open the sorter
        }
    }

    // Function to handle sorting (currently just logs the type)
    const handleSorting = (type) => {
        console.log(type);
    }




    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>

                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <h1 style={{ gap: '10px' }}>
                            <BackButton />
                            Organsations
                        </h1>
                        <p>Available <span>{data.length}</span></p>
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
                        <input type="text"
                            onChange={(e) => setSearchValue(e.target.value)}

                            value={searchValue}
                            placeholder='Search Here...' />
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: 'auto', minWidth: 'auto' }} >
                        <button className={workSpaceStyle.headerbtn} style={{ backgroundColor: "#16469D", color: "white" }} onClick={() => { navigate("view") }}>View Product Details</button>
                        <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                            <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
                        </div>
                    </div>

                </div>


                <div className={workSpaceStyle.workSpaceMainDiv}>

                    <table className={superadminStyle.admintable}>
                        <thead>
                            <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
                                <th>
                                    <p onClick={() => toggleSorter("organisationName")}>Organisation
                                        <span>{sorterOpen === "organisationName" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "organisationName" && <TableSorterDropDown data={sorterData.organisationName} onClick={handleSorting} toggle={() => toggleSorter("organisationName")} />
                                    }
                                </th>
                                <th>
                                    <p onClick={() => toggleSorter("kamName")}> KAM Name
                                        <span>{sorterOpen === "kamName" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "kamName" && <TableSorterDropDown data={sorterData.kamName} onClick={handleSorting} toggle={() => toggleSorter("kamName")} />
                                    }
                                </th>
                                <th>
                                    <p onClick={() => toggleSorter("kamEmail")}> Email
                                        <span>{sorterOpen === "kamEmail" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "kamEmail" && <TableSorterDropDown data={sorterData.kamEmail} onClick={handleSorting} toggle={() => toggleSorter("kamEmail")} />
                                    }
                                </th>
                                <th>
                                    <p onClick={() => toggleSorter("phone")}> Phone
                                        <span>{sorterOpen === "phone" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "phone" && <TableSorterDropDown data={sorterData.phone} onClick={handleSorting} toggle={() => toggleSorter("phone")} />
                                    }
                                </th>
                                <th>
                                    Registerd On
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                data?.filter((val) => val.organisationName.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                                    val.kamName.toLowerCase().includes(searchValue.trim().toLowerCase()))
                                    .map((row, index) => {
                                        return (


                                            <tr className={superadminStyle.admintableRow}>
                                                <td onClick={() => { navigate(`${row.id}`) }} style={{ cursor: "pointer" }}>
                                                    {row.organisationName}
                                                </td>
                                                <td>
                                                    {row.kamName}
                                                </td>
                                                <td>
                                                    {row.kamEmail}
                                                </td>
                                                <td>
                                                    {row.phone}
                                                </td>
                                                <td>
                                                    {DateFormater(row.registeredOn)}
                                                </td>
                                                <td>
                                                    <div className={superadminStyle.actionIcons}>
                                                        <ToggleSwitch checked={!row.isDisable} name={`checkBox_${row.kamName}`} onChange={() => handleCheckBox(row.id, row.productId, !row.isDisable)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default OrganisationByProduct
