import React, { useEffect, useState } from 'react';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { Button, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import specificTempStyle from './CommercialTempleteSpecificView.module.css';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ThreeDotDropDown from '../../components/ThreeDotDropDown/ThreeDotDropDown';
import { useNavigate } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import BackButton from 'components/BackButton/BackButton'; // Is this path correct? Shouldn't it be './BackButton/BackButton'?
import useAuth from 'hooks/useAuth';


function CommercialTempleteSpecificView() {

    const { auth } = useAuth();// Custom hook to get authentication information
    const privateAxios = PrivateComponent();// Axios instance for private requests
    const { client, taskId, productId } = useParams(); // Extracting parameters from the URL
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();// Custom hooks for backdrop and toast
    const { showToast, Toast } = useToast();

    // ******************************Searching************************************************

    const [searchString, setSearchString] = useState('');// Handler for search input change
    const navigate = useNavigate();
    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
    };


    const [data, setData] = useState([]); // State for data fetched from the server
    const [moreDropDown, setMoreDropDown] = useState("");// State for dropdown menu visibility

    // Handler for toggling dropdown menu
    const handleMoreDropDown = (id) => {
        if (moreDropDown === id) {
            setMoreDropDown("");
        }
        else {
            setMoreDropDown(id);
        }
    }

    // Function to handle different status styles
    const handleStatus = (status) => {
        let cstyle = {}
        switch (status?.toLowerCase()) {
            case "active": cstyle = { backgroundColor: "#DCFCE7", color: "#1CA35E" };
                break;
            case "inactive": cstyle = { backgroundColor: "#FFD0CD", color: "#D93025" };
                break;
            case "pending": cstyle = { backgroundColor: "#FFF0C9", color: "#EDBD15" };
                break;
            // default: cstyle = { backgroundColor: "#808080", color: "#101010" };
        }

        return (
            <span className={specificTempStyle.specitemplatetableRowStatus} style={cstyle}>
                {status}
            </span>
        )
    }

    // =================HANDLE GET DATA=======================

    // Function to fetch template data
    const getTemplateData = async () => {
        openBackdrop();//open loader
        try {
            let url = `/commercialTask/getTemplatesByTask`
            if (productId !== null) {
                url = `/commercialTask/getTemplatesByTask`

            }
            const res = await privateAxios.get(`${url}/${taskId}`)
            console.log(res);
            const datar = res.data.data
            setData(datar);

        } catch (error) {
            showToast("error", "Error getting Templates")
        }
        closeBackdrop();//close loader
    }

    // Fetch template data on component mount
    useEffect(() => {
        getTemplateData();
    }, [])

    // ================Back Button========

    // Handler for back button
    const handleBackButton = () => {
        navigate(-1);
    }


    
    return (
        <>
            <BackdropComponent />
            <Toast />

            <div className={workSpaceStyle.workSpaceMain}>
                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <h1>
                            <BackButton />
                            {data.taskName}
                        </h1>
                        <p>
                            Templates
                            <span>
                                {data?.templates?.length}
                            </span>
                        </p>
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderSearchbar}>
                        <input
                            type="text"
                            onChange={handleSearchChange}
                            value={searchString}
                            placeholder="Search Here..."
                        />
                        {auth.roles === "USER" && (
                            <Tooltip title="help">
                                <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081391-commercial-template-specific-view">

                                    <HelpOutlineIcon />
                                </a>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div className={specificTempStyle.tableCon}>

                    <table className={specificTempStyle.specitemplatetable}>
                        <thead>
                            <tr className={specificTempStyle.specitemplatetableRow} >
                                <th>Type</th>

                                <th>Status</th>
                                <th>
                                    -

                                    -
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {data?.templates?.filter((item) =>
                                item.name.trim().toLowerCase().includes(searchString.toLowerCase()))
                                .map((item, index) => (
                                    <tr className={specificTempStyle.specitemplatetableRow} >
                                        <td >{item.name}</td>
                                        <td>
                                            <div style={{ width: "100%", justifyContent: 'center', alignItems: "center", display: "flex" }}>
                                                {handleStatus(item.status)}

                                            </div>


                                        </td>


                                        <td>
                                            <div className={specificTempStyle.specitemplatetableRowIcon}>

                                                <VisibilityIcon onClick={() => { navigate(`${item.productId}/${item.id}`) }} />

                                                <span style={{ position: "relative" }}>
                                                    <MoreVertIcon onClick={() => handleMoreDropDown(item.id)} />
                                                    {moreDropDown === item.id &&
                                                        <ThreeDotDropDown toggle={() => handleMoreDropDown(item.id)}>
                                                            <li onClick={() => { handleMoreDropDown(item.id) }}>
                                                                Something
                                                            </li>
                                                        </ThreeDotDropDown>}
                                                </span>
                                            </div>
                                        </td>

                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                {data.length === 0 &&
                    <div className={specificTempStyle.noTemplatePlaceHolder}>
                        <div className={specificTempStyle.noTemplatePlaceHolderChild}>
                            <span>
                                <PostAddOutlinedIcon />
                            </span>
                            <p>You have templates added to your shipping terms</p>
                            <button className={specificTempStyle.noTemplatePlaceHolderbtn} >
                                <span>
                                    <AddIcon />
                                </span>
                                Add
                            </button>
                        </div>
                    </div>

                }


            </div >
        </>
    );
}

export default CommercialTempleteSpecificView;
