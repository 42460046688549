import React, { useState, useEffect } from 'react'
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import revisionEditStyle from '../../pages/RevisionEdit/RevisionEdit.module.css'
import useBackdrop from 'hooks/useBackdrop'
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatRow from 'components/ChatRows/ChatRow';
import CompareQuestionsTab from './CompareQuestionsTab'
import CompareDocumentsTab from './CompareDocumentsTab'
import CompareDescriptionTab from './CompareDescriptionTab'
import approvalStyle from '../WorkSpaceApproval/WorkSpaceApproval.module.css'
// =======================icons===========================

import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ApprovalRevisionTable from 'components/ApprovalRevisionTable/ApprovalRevisionTable';
import CheckIcon from '@mui/icons-material/Check';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
// =======================icons===========================

// =====================mui compoents====================
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import useToast from 'hooks/useToast';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import BackButton from 'components/BackButton/BackButton';
import CompareRankingModal from 'components/CompareRankingModal/CompareRankingModal';
import BarChartIcon from '@mui/icons-material/BarChart';


const CommercialApprovalRevisionCompare = () => {
  // Importing necessary hooks and components
  const { showToast, Toast } = useToast(); // Toast related functions 
  const privateAxios = PrivateComponent(); // Axios instance for private requests
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Backdrop related functions
  const location = useLocation(); // Accessing current location
  const { state } = location; // Extracting state from location
  const { revisionId } = useParams(); // Extracting revisionId from URL parameters
  const navigate = useNavigate(); // Navigation function
  const { auth } = useAuth(); // Authentication related data
  const [compareHeaderData, setCompareHeaderData] = useState(""); // State for comparison header data
  const [workSpaceHeaderRevisionMore, setworkSpaceHeaderRevisionMore] = useState(false); // State for workspace header


  // State related to withdrawal functionality
  const [people, setPeople] = useState([]); // State for people data
  const [comments, setComments] = useState("");  // State for comments
  const [formError, setFormError] = useState({}); // State for form errors
  const [withdrawalModal, setWithdrawalModal] = useState(false); // State for withdrawal modal

  // Function to toggle withdrawal modal
  const handleWithdrawalModal = (data) => {
    setWithdrawalModal(prev => !prev)
  }
  // Function to handle withdrawal form submission
  const handleWithdrawalSubmit = async (e) => {
    e.preventDefault();
    if (comments !== "" && comments !== undefined && comments !== null) {
      setFormError({

      })

      try {
        // Sending withdrawal request
        await privateAxios.post(`/approval/requestToRevokeApproval/${auth.userData.id}/${revisionId}`, { reason: comments }).then((res) => {
          handleWithdrawalModal(); // Close withdrawal modal
          setComments("");// Clear comments
          showToast("success", "Request Sent") // Show success toast
        }).catch((err) => {
          showToast("error", "Error Sending Request");  // Show error toast
        })
      } catch (error) {
        showToast("error", "Error Sending Request"); // Show error toast
      }
    }
    else {
      setFormError({
        // Display error if comments are empty
        comments: "please enter a reason"
      })

    }
  }


  // ================Back Button========

  // Function to navigate back
  const handleBackButton = () => {
    navigate(-1);//Back Previous page
  }

  // ==============tab selector and state=================

  // State and effect related to tab selection
  const searchParams = new URLSearchParams(location.search);
  const [tabActive, setTabActive] = useState("0");
  useEffect(() => {
    if (searchParams.get("tab") !== undefined && searchParams.get("tab") !== null) {

      setTabActive(searchParams.get("tab"));
    }
  }, [Location, searchParams])

  // Function to handle tab change
  const handleTabChange = (index) => {
    setTabActive(index)
    navigate(`?tab=${index}`, { replace: true })
  }


  // =============header three dot=============

  // State and function related to header three dot
  const [headerThreeDot, setHeaderThreeDot] = useState(false)
  const handleheaderThreeDot = () => {
    setHeaderThreeDot(prev => !prev);
  }

  // =================multisave ================

  // State and function related to multisave
  const [multiSave, setMultiSave] = useState(false);

  const handleMultiSave = () => {
    setMultiSave(prev => !prev)
  }

  // ===================Question State========================

  // State related to questions
  const [questionRows, setQuestionRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [qestionRemark, setQestionRemark] = useState("");



  // ===================== Description State==============

  // State related to revision description
  const [revisionDescription, setRevisionDescription] = useState("");
  const [descriptionRemark, setDescriptionRemark] = useState("");


  //  ===============docuemnt states========================

  // State related to documents
  const [documents, setDocuments] = useState([]);
  const [documentRemark, setDocumentRemark] = useState("");


  // ===================get data================

  // Function to check count for satisfaction
  const CheckCount = (item) => {
    let count = {
      satisfied: 0,
      unstatisfied: 0,
      neutral: 0,
    };

    // Check unit type and perform satisfaction count accordingly
    if (!item || !item.unit) {
      count.neutral = 1;
      return count;
    }

    switch (item.unit) {
      case 'NUMBER':
        const revisedValue = parseFloat(item.revisedValue);
        const value = parseFloat(item.value);
        switch (item.ruleAttribute) {
          case 'LESS':
            if (revisedValue < value) count.satisfied = 1;
            else count.unstatisfied = 1;
            break;
          case 'MORE':
            if (revisedValue > value) count.satisfied = 1;
            else count.unstatisfied = 1;
            break;
          case 'EQUAL':
            if (revisedValue === value) count.satisfied = 1;
            else count.unstatisfied = 1;
            break;
          default:
            count.neutral = 1;
        }
        break;
      case 'RANGE':
        const range = item.value?.split("-").map(item => parseFloat(item.trim()));
        if (range && range.length === 2) {
          const revisedValue = parseFloat(item.revisedValue);
          if (revisedValue >= range[0] && revisedValue <= range[1]) count.satisfied = 1;
          else count.unstatisfied = 1;
        } else {
          count.neutral = 1;
        }
        break;
      case 'BOOLEAN':
        if (item.revisedValue?.trim() === item.value?.trim()) count.satisfied = 1;
        else count.unstatisfied = 1;
        break;
      case 'SINGLESELECT':
        if (item.revisedValue?.trim() === item.expectedAnswer?.trim()) count.satisfied = 1;
        else count.unstatisfied = 1;
        break;
      case 'DATE':
        const revisedDate = new Date(item.revisedValue);
        const originalDate = new Date(item.value);
        switch (item.ruleAttribute) {
          case 'LESS':
            if (revisedDate < originalDate) count.satisfied = 1;
            else count.unstatisfied = 1;
            break;
          case 'MORE':
            if (revisedDate > originalDate) count.satisfied = 1;
            else count.unstatisfied = 1;
            break;
          case 'EQUAL':
            if (revisedDate.getTime() === originalDate.getTime()) count.satisfied = 1;
            else count.unstatisfied = 1;
            break;
          default:
            count.neutral = 1;
        }
        break;
      default:
        count.neutral = 1;
    }
    return count;
  };


  // State related to vendor rankings
  const [vendorRankings, setVendorRankings] = useState({});

  // Function to fetch data related to procurement
  const getDataProcure = async () => {
    openBackdrop();
    try {
      // Fetch approval revision data
      const response = await privateAxios.get(`/approval/getApprovalRevisionById/${revisionId}`);
      const { data } = response;
      const questionArray = []
      if (data.length > 1) {
        if (data) {
          setCompareHeaderData(data[0]);
        }
        data.slice(1).forEach((res, index) => {
          const questions = res.questions

          if (!questionArray[res.vendorName]) {
            questionArray[res.vendorName] = questions;
          }
          else {
            questionArray[`${res?.vendorName?.trim()}_${index}`] = questions;
          }
        })
      }
      else {
        setCompareHeaderData(data[0]);
        questionArray[data[0].vendorName] = data[0].questions;
      }


      const groupedData = {};
      const vendorRanking = {}
      // Iterate through the data array
      Object.values(questionArray).forEach((dataArray, index) => {
        const vendorName = Object.keys(questionArray)[index];

        let count = {
          satisfied: 0,
          unstatisfied: 0,
          neutral: 0,
        }

        dataArray.forEach((item) => {
          const tempCount = CheckCount(item);

          count.satisfied = count.satisfied + tempCount.satisfied;
          count.unstatisfied = count.unstatisfied + tempCount.unstatisfied;
          count.neutral = count.neutral + tempCount.neutral;

          const updatedItem = {
            ...item,
            vName: vendorName
          }
          const itemName = item.name;

          // Check if the item name is already a key in the groupedData object
          if (!groupedData[itemName]) {
            // If not, create an empty array for that key
            groupedData[itemName] = [];
          }

          // Push the item into the corresponding array based on its name
          groupedData[itemName].push(updatedItem);
        });
        vendorRanking[vendorName] = count;

      });

      setVendorRankings(vendorRanking);

      const addedData = Object.values(groupedData).map((data) => {
        if (data.length < Object.values(questionArray).length) {
          let extracolumns = []
          for (let i = data.length; i < Object.values(questionArray).length; i++) {


            const rt = {
              id: (data[0].id + i),
              name: data[0].name,
              unit: data[0].unit,
              value: "N/A",
              remark: "N/A",
              vendorRemark: "N/A",
              vName: "N/A",
              expectedAnswer: data[0].expectedAnswer,
              vendorRemark: data[0].vendorRemark,
              ruleAttribute: data[0].ruleAttribute,
              revisedValue: "N/A"
            }

            extracolumns.push(rt);
          }
          return [
            ...data, ...extracolumns
          ]

        }
        else {
          return data
        }

      })

      // Convert the groupedData object to an array of arrays
      const resultArray = Object.values(addedData).map((smt) => {
        let obb = {
          name: smt[0].name,
          unit: smt[0].unit,
          value: smt[0].value,
          remark: smt[0].remark,
          vendorRemark: [],
          revisedValue: [],
          expectedAnswer: smt[0].expectedAnswer,
          ruleAttribute: smt[0].ruleAttribute,

        };
        smt.forEach((obj, index) => {
          obb = {
            ...obb,
            revisedValue: [...obb.revisedValue, { value: obj.revisedValue, sentBy: obj.vName }],
            vendorRemark: [...obb.vendorRemark, { value: obj.vendorRemark, sentBy: obj.vName }],
          }
        })
        return obb;
      });


      const attributes = resultArray || [];
      let VendorRevisedColumns = attributes[0].revisedValue.map((vv) => {
        return `${vv.sentBy} Value`
      })
      let VendorRemarkColumns = attributes[0].vendorRemark.map((vv) => {
        return `${vv.sentBy} Remark`
      })

      const newColumns = ['Sr No.', 'Name', 'Unit', 'Value',

        ...VendorRevisedColumns,
        "KAM Remark",
        ...VendorRemarkColumns,

      ];

      setColumns(newColumns);
      setQuestionRows(attributes);

      const doc = [];
      const description = []
      if (data.length > 1) {
        data.slice(1).forEach((res, index) => {
          if (res.vendorName === null) {
            doc[`No Vendor Name_${index}`] = res.documents
            description[`No Vendor Name_${index}`] = res.description

          }
          else {
            if (!doc[res.vendorName]) {
              doc[res?.vendorName] = res.documents;
              description[res?.vendorName] = res.description
                ;
            }
            else {
              doc[`${res?.vendorName?.trim()}_${index}`] = res.documents
              description[`${res?.vendorName?.trim()}_${index}`] = res.description
            }
          }
        })

      }
      else {
        doc[data[0].vendorName] = data[0].documents;
        description[data[0].vendorName] = data[0].description
      }

      setDocuments(doc)
      setRevisionDescription(description)

      closeBackdrop()
    } catch (error) {
      console.error("Error:", error);
      closeBackdrop();
    }

  };


  // Fetch data on component mount
  useEffect(() => {
    getDataProcure();
  }, [])


  // ============================add remark on Tabs=================================

  // Function to handle adding remarks on different tabs
  const handleAddRemark = async () => {
    openBackdrop();// Open backdrop
    try {
      // Prepare payload for remarks
      const payload = [
        {
          "remark": descriptionRemark,
          "type": "Commercial Description"
        },
        {
          "remark": qestionRemark,
          "type": "Commercial Questions"
        },
        {
          "remark": documentRemark,
          "type": "Commercial Documents"
        }
      ]
      // Send request to add remarks
      const res = await privateAxios.post(`/approval/addRemarkOnTab/${auth.userData.id}/${revisionId}`, payload)
      if (res.status === 200) {
        showToast("success", "Added Remarks");// Show success toast
      }
    } catch (error) {
      showToast("error", "Erro Saving Changes");// Show error toast
    }
    closeBackdrop();// Close backdrop
  }
  // ===========================get remarks on Tabs====================================

  // Function to get remarks on different tabs
  const [allTabsRemarks, setAllTabsRemarks] = useState(null);// State for all remarks on tabs
  const getRemarksofTabs = async () => {
    try {
      // Fetch remarks data
      const res = await privateAxios.get(`/approval/getRemarksOfTab/${revisionId}`);
      const { data } = res;
      let allRemark = {
        description: [],
        questions: [],
        documents: [],
      }

      // Organize remarks based on type and user
      data.forEach((rem) => {
        if (rem.email === auth.email) {
          switch (rem.type) {
            case "Commercial Description": setDescriptionRemark(rem.remark)
              break;
            case "Commercial Questions": setQestionRemark(rem.remark);
              break;
            case "Commercial Documents": setDocumentRemark(rem.remark);
              break;
          }
        }
        else {
          switch (rem.type) {
            case "Commercial Description":
              allRemark = {
                ...allRemark,
                description: [
                  ...allRemark.description,
                  rem
                ]
              }
              break;
            case "Commercial Questions": allRemark = {
              ...allRemark,
              questions: [
                ...allRemark.questions,
                rem
              ]
            }
              break;
            case "Commercial Documents": allRemark = {
              ...allRemark,
              documents: [
                ...allRemark.documents,
                rem
              ]
            }
              break;
          }
        }
      })

      setAllTabsRemarks(allRemark); // Set remarks state
    } catch (error) {
      console.log(error);
      showToast("error", "Error Fetching Remarks Data"); // Show error toast
    }
  }

  useEffect(() => {
    getRemarksofTabs();// Fetch remarks on component mount
  }, [])


  // =====================================chat states=============================================

  // State and functions related to chat functionality
  const [chatSlider, setChatSlider] = useState(false);// State for chat slider visibility
  const [chatValue, setChatValue] = useState("");// State for chat input value
  const [revisionChats, setRevisionChats] = useState([]);// State for revision chats

  // Function to toggle chat slider
  const handleChatSlider = () => {
    setChatSlider(prev => !prev)

  }

  // Fetch revision chats when chat slider is opened
  useEffect(() => {
    if (chatSlider === true) {

      getRevisionChats()
    }

  }, [chatSlider])

  // Function to send chat message
  const handleChatSend = async (e) => {
    e.preventDefault()
    try {
      if (chatValue.length > 0) {
        // Send chat message
        await privateAxios.post(`/approval/addRemarkOnApprovalRevision${auth.userData.id}/${revisionId}`, { remark: chatValue }).then(res => {
          getRevisionChats(); // Refresh chat messages
          setChatValue("");// Clear chat input

        })
      }
    } catch (error) {
    }

  }


  const getRevisionChats = async () => {
    // Fetch remarks of approval revision
    try {
      await privateAxios.get(`/approval/getRemarksOfApprovalRevision/${revisionId}`).then(res => {
        // Set revision chats data
        setRevisionChats(res.data);
        // Handle chat notification
        handleChatNotification();
      })
    } catch (error) {
    }
  }


  // ===========================add revision======================

  // Add revision section
  const [addProductRevisionModal, setAddProductRevisionModal] = useState(false);
  const [addRevisionData, setAddRevisionData] = useState([]);
  const [selectedVendorRevisions, setSelectedVendorRevisions] = useState([]);

  // Toggle add product revision modal
  const handleAddProductRevisionModal = () => {
    setAddProductRevisionModal(prev => !prev)

  }
  // Fetch revision data when add product revision modal is opened
  useEffect(() => {
    if (addProductRevisionModal === true) {
      fetchRevision();
    }
  }, [addProductRevisionModal])

  const fetchRevision = async () => {
    try {
      openBackdrop();// Open backdrop
      if (compareHeaderData.productName !== null) {
        // Fetch revisions to add in comparison
        await privateAxios.post(`/technicalSpecification/getRevisionsToAddInComparision/${auth.userData.id}`, {
          productName: compareHeaderData.productName
        })
          .then((res) => {
            // Set add revision data
            setAddRevisionData(res.data);
            const alreadyRevId = compareHeaderData.compare.map((tt => tt.revId));
            const selectedRevision = res.data.filter(rev => {
              return alreadyRevId.includes(rev.id);
            })
            setSelectedVendorRevisions(selectedRevision)

          })
          .catch((error) => {
            console.error('Error handling response:', error);
            alert("Error Geeting data")
          });
      }
      else {
        alert("Product Name is Not Available")
      }
      closeBackdrop()
    } catch (error) {
      closeBackdrop();
    }
  }


  const haveSameValues = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false; // Arrays have different lengths, so they can't have the same values.
    }

    return array1.every(item => array2.includes(item));
  };
  const handleAddProductSend = async () => {

    try {
      if (selectedVendorRevisions.length > 1) {
        const alreadyRevId = compareHeaderData.compare.map((tt => tt.revId));
        const selectedRevId = selectedVendorRevisions.map(rev => rev.id);

        const ret = haveSameValues(alreadyRevId, selectedRevId);
        if (!ret) {
          await privateAxios.post(`/technicalSpecification/addRevisionInComparision/${revisionId}`, {
            ids: selectedRevId
          }).then((res) => {
            getDataProcure();
            handleAddProductRevisionModal();
          })
        }
        else {
          handleAddProductRevisionModal();
        }
      }
      else {
        alert("please Select Atleast 2 revisions")
      }

    } catch (error) {
      // console.log(error)
    }
  }


  // ========================decision on revision==================


  const handleDecisionOnRevision = async (isApproval) => {
    try {


      if (isApproval) {
        if (descriptionRemark !== "" || qestionRemark !== "" || documentRemark !== "") {
          await handleAddRemark();
          const response = await privateAxios.post(`/approval/approveApprovalRevisionByUser/${revisionId}/${auth.userData.id}`);
          if (response.status === 200) {
            showToast("success", "Revision Approved Successfully!")
          }
        }
        else {
          showToast("error", "Please Add Remarks on atleast One tab")
        }
      }
      else {
        const response = await privateAxios.post(`/approval/rejectApprovalRevisionByUser/${revisionId}/${auth.userData.id}`);
        if (response.status === 200) {
          showToast("error", "Revision Rejected!")
        }
      }
      getDataProcure();

    } catch (error) {
      console.error('Error fetching vendors:', error);
    }
  }



  // ====================Reject Modal=====================

  const [rejectModal, setRejectModal] = useState(false);
  const handleRejectModal = () => {
    // Toggle reject modal
    setRejectModal(prev => !prev);

  }
  const handleRejectSubmit = () => {
  }

  // =====================chat notification========================

  const [reRender, setReRender] = useState(false);

  const handleChatNotification = () => {
    // Toggle reject modal
    setReRender(prev => !prev);

  }


  // =====================date formater==========================

  const formatIsoDate = (inputDate) => {
    // Convert to Date object
    const dateObject = new Date(inputDate);

    // Format the Date object
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedDate = dateObject.toLocaleString('en-US', options);

    return formattedDate;
  }



  // ======================task history=============================


  const [taskHistory, setTaskHistory] = useState(false);
  const [taskHistoryData, setTaskHistoryData] = useState({});

  const handleTaskHistoryModal = (data) => {
    // Toggle task history modal
    setTaskHistory(prev => !prev);

  }
  // ==========================Get History======================

  const [approvalHistory, setApprovalHistory] = useState([{}]);

  const getApprovalHistory = async () => {
    openBackdrop()// Open backdrop
    try {
      const res = await privateAxios.get(`/approval/getHistoryOfRevision/${revisionId}`);
      setApprovalHistory(res.data)
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
    closeBackdrop();
  }

  useEffect(() => {
    if (taskHistory === true) {
      getApprovalHistory();
    }
  }, [taskHistory])



  // ========================= Reminder to Approver=================

  const handleReminderToApprover = async (approverId) => {
    openBackdrop();// Open backdrop
    try {
      const res = await privateAxios.post(`/approval/sendReminder/${approverId}`);
      console.log(res.data);
      showToast("success", "Reminder Sent");
    } catch (error) {
      console.log(error);
      showToast("error", "Error sending reminder")
    }
    closeBackdrop();
  }

  // ======================delete revision===================

  const [deleteRevisionModal, setDeleteRevisionModal] = useState(false);
  const handleDeleteRevisionModal = (data) => {
    setDeleteRevisionModal(prev => !prev);
  }

  const deleteRevision = async () => {
    openBackdrop();// Open backdrop
    try {
      const res = await privateAxios.delete(`/approval/deleteApprovalRevision/${revisionId}`);
      // console.log(res.data);
      showToast("success", "Revision Deleted");

    } catch (error) {
      showToast("error", "Error deleting revision");
    }
    closeBackdrop();
  }



  // =======================Revoke Request======================

  const [revokeRequestModal, setRevokeRequestModal] = useState(false);
  const [revokeRequestData, setRevokeRequestData] = useState({});
  const [revokeRequestModalData, setRevokeRequestModalData] = useState({});

  const handleRevokeRequestModal = (data) => {
    // Toggle revoke request modal and set data
    setRevokeRequestModal(prev => !prev);
    setRevokeRequestData(data)
  }



  // =========================handleRevokeConfirmationForm============

  const [revokeConfirmationModal, setRevokeConfirmationModal] = useState(false);
  const handleRevokeConfirmationModal = () => {
    // Toggle revoke confirmation modal
    setRevokeConfirmationModal(prev => !prev);
  }

  const handleRevokeConfirmationForm = (e) => {
    e.preventDefault();
    // Close revoke request modal and open revoke confirmation modal
    setRevokeRequestModal(prev => !prev);
    handleRevokeConfirmationModal();
  }

  const handleRevokeConfirmation = async (type, revokeId) => {
    openBackdrop();

    try {
      let url = '';
      if (type) {
        url = `/approval/approveRevokeRequest`
      }
      else {
        url = `/approval/DeclineRevokeRequest`
      }
      const res = await privateAxios.post(`${url}/${revokeId}`);
      if (res.status === 200) {
        if (type) {
          showToast("success", "Revoke Request Approved");
        }
        else {
          showToast("success", "Revoke Request Declined")
        }
      }
    } catch (error) {
      if (type) {
        showToast("error", "Error approving revoke request");
      }
      else {
        showToast("error", "Error Rejecting revoke request")
      }
    }
    closeBackdrop();
  }


  // =======================handleReminderSend=========================


  const handleReminderSend = async () => {
    openBackdrop();// Open backdrop
    try {

      const res = await privateAxios.post(`/approval/sendReminderByApproveLevel/${revisionId}`);
      if (res.status === 200) {
        showToast("success", "Reminder Sent");
      }
    } catch (error) {
      showToast("error", "Error Sending Reminder ")
    }
    closeBackdrop();
  }

  // =======================Ranking Modal=========================


  const [rankingModal, setRankingModal] = useState(false);

  const handleRankingModal = () => {
    // Toggle ranking modal
    setRankingModal(prev => !prev);
  }

  return (
    <>
      <BackdropComponent />
      <Toast />

      <div className={workSpaceStyle.workSpaceMain}>

        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1> <BackButton /> {compareHeaderData?.name || "No Name"}</h1>
            <p>{compareHeaderData?.productName || "Dafault"}</p>

          </div>


          <div className={workSpaceStyle.workSpaceHeaderRight}>


            <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative", width: "auto" }}>
              <button onClick={() => { handleRankingModal() }} style={{ padding: "0 10px" }}><BarChartIcon />Ranking</button>



            </div>

            {auth.roles !== "KAM" && <div className={workSpaceStyle.workSpaceHeaderDecisionButton}>
              {
                compareHeaderData?.revisionApprovalStatus === "PENDING"

                  ?
                  <>
                    <button className={workSpaceStyle.workSpaceHeaderDecisionApprovalBtn} onClick={() => { handleDecisionOnRevision(true) }}><span><CheckIcon /></span> Approve</button>
                    <button className={workSpaceStyle.workSpaceHeaderDecisionRejectBtn} onClick={() => { handleRejectModal(false) }}><span><CloseIcon /></span>Reject</button>
                  </>
                  :
                  compareHeaderData?.revisionApprovalStatus === "APPROVAL"

                    ?
                    <button className={workSpaceStyle.workSpaceHeaderDecisionApprovalBtn}><span><CheckIcon /> </span>Approved</button>

                    :
                    <button className={workSpaceStyle.workSpaceHeaderDecisionRejectBtn} ><span><CloseIcon /></span>Rejected</button>


              }
            </div>}


            <div className={workSpaceStyle.workSpaceThreeDotVertical}>

              <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={handleChatSlider}>
                <ChatIconWithBadge
                  url={`/approval/getNotficationCountOfApprvalRevision`}
                  id={revisionId}
                  reRender={reRender}
                />
              </span>
            </div>





            <div className={workSpaceStyle.workSpaceThreeDotVertical} style={{ position: "relative" }}>

              <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={() => setworkSpaceHeaderRevisionMore(prev => !prev)} >
                <Tooltip title='more'><MoreVertIcon /></Tooltip>
              </span>
              {
                workSpaceHeaderRevisionMore &&
                <ThreeDotDropDown toggle={() => setworkSpaceHeaderRevisionMore(prev => !prev)}>
                  {auth.roles !== "KAM" && <li
                    onClick={() => { handleWithdrawalModal(); setworkSpaceHeaderRevisionMore(prev => !prev) }}
                  > <span><SettingsBackupRestoreIcon /></span>Request for Withdrawal</li>}
                  {auth.roles !== "KAM" && <li
                    onClick={() => { handleAddRemark(); setworkSpaceHeaderRevisionMore(prev => !prev) }}
                  > <span><DriveFileRenameOutlineIcon /></span> Update and Save</li>}
                  {auth.roles === "KAM" && <li
                    onClick={() => { handleReminderSend(); setworkSpaceHeaderRevisionMore(prev => !prev) }}
                  > <span><DriveFileRenameOutlineIcon /></span> Reminder</li>}
                  {auth.roles === "KAM" && <li
                    onClick={() => { handleTaskHistoryModal(); setworkSpaceHeaderRevisionMore(prev => !prev) }}
                  > <span><DriveFileRenameOutlineIcon /></span> Task History</li>}
                  <li
                  // onClick={handleDownloadRevision}
                  ><span><FileDownloadOutlinedIcon /></span>Download</li>

                </ThreeDotDropDown>
              }

            </div>
            <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
              <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
            </div>
          </div>


        </div>

        <div className={revisionEditStyle.tabHeaderMain}>
          <div
            onClick={() => handleTabChange("0")}
            className={tabActive === "0" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Description</div>
          <div
            onClick={() => handleTabChange("1")}
            className={tabActive === "1" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Questions</div>
          <div
            onClick={() => handleTabChange("2")}
            className={tabActive === "2" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Documents</div>
        </div>


        <div className={revisionEditStyle.tabBodyMain} >
          {tabActive === "0" && <CompareDescriptionTab
            // setRevisionDescription={setRevisionDescription}
            revisionDescription={revisionDescription}
            setDescriptionRemark={setDescriptionRemark}
            approverRemarks={allTabsRemarks?.description}
            approovalStatus={compareHeaderData.revisionApprovalStatus}
            descriptionRemark={descriptionRemark}
          />}
          {tabActive === "1" && <CompareQuestionsTab
            questionRows={questionRows}
            columns={columns}
            setQestionRemark={setQestionRemark}
            qestionRemark={qestionRemark}
            approverRemarks={allTabsRemarks?.questions}
            approovalStatus={compareHeaderData.revisionApprovalStatus}
          //  handleInputChange={handleInputChange} handleAddQuestionRow={handleAddQuestionRow} handleQuestionRowDelete={handleQuestionRowDelete}
          />}
          {tabActive === "2" && <CompareDocumentsTab
            setDocumentRemark={setDocumentRemark}
            documentRemark={documentRemark}
            approverRemarks={allTabsRemarks?.documents}
            approovalStatus={compareHeaderData.revisionApprovalStatus}
            //  addDocumentModal={addDocumentModal} handleAddDocumentModal={handleAddDocumentModal} getDataProcure={getDataProcure} 
            documents={documents}
          // handleDocumentUpload={handleDocumentUpload} 
          />}

        </div>

      </div>

      {/* request for withrawal */}
      <Modal
        open={withdrawalModal}
        onClose={handleWithdrawalModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={handleWithdrawalSubmit} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><SettingsBackupRestoreIcon /></span>Request of Withdrawal</h3>
              <div onClick={handleWithdrawalModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            {/* <div className={formStyle.inputRow}>
              <label htmlFor=""> Assign A Workspace:</label>
              <MultiSelect categories={people}
                setCategories={setPeople}
                availableDropDown={['some@gmail.com', 'some4@gmail.com', 'some2@gmail.com', 'some3@gmail.com']} /> */}

            {/* <span>{formErrors.accessRole}</span>  */}
            {/* <span>{formError.workSpace}</span>
            </div> */}
            <div className={formStyle.inputRow}>
              <label htmlFor=""> Reason for Withdrawal:</label>
              <textarea style={{ paddingTop: "10px", height: "120px" }} onChange={(e) => setComments(e.target.value)} value={comments}></textarea>
              {/* <span>{formErrors.accessRole}</span>  */}
              <span>{formError.comments}</span>
            </div>



            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "40px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Send </button>
              <p onClick={() => { setPeople([]); setComments(""); handleWithdrawalModal() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

            <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span>
          </form>

        </div>
      </Modal>

      {/* request for withrawal */}

      <Modal
        open={addProductRevisionModal}
        onClose={handleAddProductRevisionModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxWidth: "80vw", width: "100%", maxHeight: "90vh" }}>
          <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "10px", height: "100%" }}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><PersonAddAltOutlinedIcon /></span>Add More Files For Comparision</h3>
              <div onClick={handleAddProductRevisionModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>


            <ApprovalRevisionTable addData={addRevisionData} selectedRevisions={selectedVendorRevisions} setSelectedRevisions={setSelectedVendorRevisions} />
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "40px", maxWidth: "100px" }} className={formStyle.modalSmallPrimaryButton} onClick={handleAddProductSend}>Apply </button>

            </div>

          </div>

        </div>
      </Modal>

      <Modal
        open={rejectModal}
        onClose={handleRejectModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', width: '375px' }}>
          <form action="" style={{ maxWidth: '100%' }} >

            <div
              className={workSpaceStyle.rejectModalText}
            >
              <h2>Reject Request</h2>
              <p>Are you sure you want to Reject this request?</p>

              <div
                className={workSpaceStyle.rejectModalButton}
              >
                <button onClick={() => { handleDecisionOnRevision(false); handleRejectModal() }} variant="contained">Delete</button>

                <p onClick={handleRejectModal}>Cancel</p>
              </div>

            </div>

          </form>
        </div>
      </Modal>

      <ChatPannel
        open={chatSlider}
        onClose={handleChatSlider}
      >
        <>
          <ChatHeader
            onClose={handleChatSlider}
            downloadData={revisionChats.map((chat) => { return { name: chat.name, date: chat.createdAt, chat: chat.remark } })}
            helpData={{ link: "tosomewhere" }}
            chatName={compareHeaderData.name}
          >
            <h3>{compareHeaderData?.name} </h3>
          </ChatHeader>

          <ChatRows >
            {/* <ChatRow name="Ravinder" chat="soemfhdf a saf sa" date="12-04-2023" index="1"/> */}
            {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
              return (

                <ChatRow name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} />
              )
            })
              :
              <p style={{ textAlign: "center" }}>
                No Previous Chat
              </p>
            }
          </ChatRows>

          <ChatInputField revisionId={revisionId} onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

        </>
      </ChatPannel>

      <Modal
        open={taskHistory}
        onClose={handleTaskHistoryModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", padding: "20px 0px", width: "30vw" }}>
          <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
            <div className={workSpaceStyle.modalHeader} style={{ borderBottom: "2px solid #d2d2d2", padding: "0 10px 10px 10px" }} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

              </span> Task History</h3>
              <div onClick={handleTaskHistoryModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "10px 25px" }}>


              <div className={approvalStyle.historyRowWrapper}>
                {approvalHistory.map((item, index) => {
                  if (item.revokeId === null) {


                    return (
                      <div className={approvalStyle.historyRow}>
                        <div style={{ display: "flex", flexGrow: "1" }}>
                          <div className={approvalStyle.historyRowIcon}>
                            <span style={{ backgroundColor: item.status === "approved" ? "#14c971" : item.status === "pending" ? "#f9d249" : item.status === "na" ? "#d2d2d2" : "red" }}>
                              {
                                item.status === "pending" ?
                                  <ErrorOutlineOutlinedIcon />
                                  :
                                  <CheckOutlinedIcon />

                              }

                            </span>
                          </div>
                          <div className={approvalStyle.historyRowCont}>
                            <div>

                              <h4>
                                {item.status === "approved" ? "Approved by" : item.status === "pending" ? "Apprvoal Pending from" : item.status === "na" ? "Apprvoal Pending from" : "Declined By"}
                                {/* {item.status === "approved" ? "Approved by" : item.status === "pending" ? "Apprvoal Pending from" : "Declined By"} */} {item.approverName}
                              </h4>
                              <span style={{ backgroundColor: item.status === "approved" ? "#14c971" : item.status === "pending" ? "#f9d249" : item.status === "na" ? "#d2d2d2" : "red" }}>{index + 1}/{approvalHistory.length}</span>
                            </div>
                            <p>
                              {formatIsoDate(item.dueDate)}

                            </p>
                          </div>
                        </div>
                        {
                          item.status === "pending" &&
                          <div>
                            <span onClick={() => handleReminderToApprover(item.approverId)}>
                              <NotificationsNoneOutlinedIcon />
                            </span>
                          </div>
                        }

                        {
                          index > 0 &&

                          <div className={approvalStyle.historyRowProgressLine} style={{ backgroundColor: item.status === "approved" ? "#14c971" : item.status === "pending" ? "#f9d249" : item.status === "na" ? "#d2d2d2" : "red" }}></div>
                        }

                      </div>

                    )
                  }
                  else {
                    return (
                      <div className={approvalStyle.historyRow}>
                        <div style={{ display: "flex", flexGrow: "1" }}>
                          <div className={approvalStyle.historyRowIcon}>
                            <span style={{ backgroundColor: "#f9d249" }}>
                              {
                                item.status === "pending" ?
                                  <ErrorOutlineOutlinedIcon />
                                  :
                                  item.status === "na" ?
                                    <CloseIcon />
                                    :
                                    <CheckOutlinedIcon />

                              }

                            </span>
                          </div>
                          <div className={approvalStyle.historyRowCont}>
                            <div>

                              <h4 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { handleRevokeRequestModal(item); handleTaskHistoryModal(); }}>
                                Revoke Request From {item.approverName}
                              </h4>
                              <span style={{ backgroundColor: "#f9d249" }}>{index + 1}/{approvalHistory.length}</span>
                            </div>
                            <p>
                              {formatIsoDate(item.dueDate)}

                            </p>
                          </div>
                        </div>
                        {
                          index > 0 &&

                          <div className={approvalStyle.historyRowProgressLine} style={{ backgroundColor: "#f9d249" }}></div>
                        }

                      </div>

                    )
                  }
                })}





              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* modal to ask for Confirmation of Revision Delete */}
      <Modal
        open={deleteRevisionModal}
        onClose={handleDeleteRevisionModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', minWidth: '375px' }}>
          <form action="" style={{ maxWidth: '100%' }} >

            <div
              className={workSpaceStyle.rejectModalText}
            >
              <h2>Delete Revision</h2>
              <p>Are you sure you want to Delete this Revision?</p>

              <div
                className={workSpaceStyle.rejectModalButton}
              >
                <button onClick={() => { deleteRevision(); handleDeleteRevisionModal() }} variant="contained">Delete</button>

                <p onClick={handleDeleteRevisionModal}>Cancel</p>
              </div>

            </div>

          </form>
        </div>
      </Modal>


      {/* modal to ask for Confirmation of Revision Delete */}

      {/* modal to ask for Confirmation of Revoke Request */}
      <Modal
        open={revokeRequestModal}
        onClose={handleRevokeRequestModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", width: "500px" }}>

          <form action=""
            onSubmit={handleRevokeConfirmationForm}
            style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><SettingsBackupRestoreIcon /></span>Revoke Requst from {revokeRequestModalData?.name}</h3>
              <div onClick={handleRevokeRequestModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>


            <div className={formStyle.inputRow}>
              <label htmlFor=""> Reason for Withdrawal:</label>
              <textarea style={{ paddingTop: "10px", height: "120px", backgroundColor: "#f2f2f2" }}
                //    onChange={(e) => setComments(e.target.value)} value={comments}
                value={revokeRequestData?.reason}
                readOnly
              ></textarea>
              {/* <span>{formErrors.accessRole}</span>  */}
              {/* <span>{formError.comments}</span> */}
            </div>



            <div style={{ display: "flex", gap: "20px", alignItems: "center", justifyContent: "flex-end" }}>
              <p onClick={() => { handleRevokeRequestModal() }} style={{ cursor: "pointer" }}>Decline</p>
              <button style={{ height: "40px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Revoke </button>
            </div>

            {/* <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span> */}
          </form>

        </div>
      </Modal>

      {/* modal to ask for Confirmation of Revoke Request */}


      <Modal
        open={revokeConfirmationModal}
        onClose={handleRevokeConfirmationModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', minWidth: '375px', width: "600px" }}>
          <form action="" style={{ maxWidth: '100%' }} >

            <div
              className={workSpaceStyle.rejectModalText}
            >
              <h2 style={{ fontSize: "25px" }}>Are you sure you want to revoke?</h2>
              <p>All related activities will be reversed, and this action will stop any more
                workflow approvals or denials. Once done it cant be undone!</p>

              <div
                className={workSpaceStyle.rejectModalButton}
                style={{ justifyContent: "flex-end" }}
              >

                <p onClick={() => handleRevokeConfirmationModal()}>Cancel</p>
                <button onClick={() => { handleRevokeConfirmation(true, revokeRequestData.revokeId); handleRevokeConfirmationModal() }} style={{ backgroundColor: "#16469d", cursor: "pointer" }} variant="contained">Revoke</button>
              </div>

            </div>

          </form>
        </div>
      </Modal>


      <CompareRankingModal
        open={rankingModal}
        onClose={handleRankingModal}
        data={vendorRankings}
        total={questionRows.length}
        type={"question"}
      />

    </>
  )
}

export default CommercialApprovalRevisionCompare
