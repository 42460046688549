import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import superadminStyle from '../../commonCss.module.css';
import Tooltip from '@mui/material/Tooltip';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import DateFormater from 'utility/DateFormater';
import { useNavigate } from 'react-router-dom';
import BackButton from 'components/BackButton/BackButton';

const OrganisationList = () => {
  // Hooks
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
  const { showToast, Toast } = useToast();
  const privateAxios = PrivateComponent();
  const navigate = useNavigate();

  // State for search value
  const [searchValue, setSearchValue] = useState('');

  // State for data
  const [data, setData] = useState([]);


  // Function to fetch data
  const getData = async () => {
    openBackdrop();// Show backdrop
    try {

      const res = await privateAxios.get('/procureadmin/getAllApprovedOrganisations');
      const { data } = res;
      console.log("ddddd",data.length);
      setData(data)


    } catch (error) {
      showToast("error", error.message)// Show toast on error
    }
    closeBackdrop();// Close backdrop
  }

  useEffect(() => {// Fetch data on component mount
    getData();
  }, [])



  // Function to handle enabling/disabling organisation
  const handleCheckBox = async (orgId, kamId, type) => {
    openBackdrop();// Show backdrop
    try {
      let url = "";
      if (type) {
        url = `/procureadmin/DisableOrganisation`
      }
      else {
        url = `/procureadmin/enableOrganisation`

      }
      const res = await privateAxios.post(`${url}/${orgId}/${kamId}`);
      if (res.status === 200) {

        if (!type) {

          showToast("success", "Organaistion Enabled Successfully");// Show success toast for enabling
        }
        else {
          showToast("success", "Organisation Disabled Successfully"); // Show success toast for disabling
        }
      }
      getData(); // Refresh data
    } catch (error) {
      showToast("error", error.message)// Show error toast
    }
    closeBackdrop();// Close backdrop
  }

  // =====================handle active org======================



  return (
    <>
      <Toast />
      <BackdropComponent />
      <div className={workSpaceStyle.workSpaceMain}>



        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1 style={{ gap: '10px' }}> <BackButton />
            Organisations
              {/* <i style={{ fontSize: "15px" }}>({auth.roles})</i> */}
              {/* <span>(ADMIN)</span> */}
            </h1>
            {/* <p>Available <span>{data.length}</span></p> */}
            <p>Available <span>{data.length}</span></p>

          </div>
          <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
            <input type="text"
              onChange={(e) => setSearchValue(e.target.value)}

              value={searchValue}
              placeholder='Search Here...' />
          </div>
          <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: 'auto', minWidth: 'auto' }} >
            {/* <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: '450px', gap: '10px' }}> */}













            <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
              <Tooltip title='Help'>
                <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081298-organization-list">
                  <HelpOutlineOutlinedIcon />
                </a>
              </Tooltip>
            </div>
          </div>

        </div>


        <div className={workSpaceStyle.workSpaceMainDiv}>

          <table className={superadminStyle.admintable}>
            <thead>
              <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
                <th>
                  Organisation
                </th>
                <th>
                  KAM Name
                </th>
                <th>
                  Email
                </th>
                <th>
                  Phone
                </th>
                <th>
                  Registerd On
                </th>
                <th>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>

              {

                data?.filter((val) => val.organisationName.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                  val.kamName.toLowerCase().includes(searchValue.trim().toLowerCase()))
                  .map((row, index) => {
                    return (


                      <tr className={superadminStyle.admintableRow}>
                        <td>
                          <p style={{ cursor: "pointer" }} onClick={() => navigate(`/superadmin/organisation/organisation-approved/${row.kamId}/${row.id}`)}>
                            {row.organisationName}

                          </p>
                        </td>
                        <td>
                          {row.kamName}
                        </td>
                        <td>
                          {row.kamEmail}
                        </td>
                        <td>
                          {row.phone}
                        </td>
                        <td>
                          {DateFormater(row.registeredOn)}
                        </td>
                        <td>
                          <div className={superadminStyle.actionIcons}>

                            <ToggleSwitch checked={!row.isDisable} name={`checkBox_${row.kamName}`} 
                            onChange={() => handleCheckBox(row.id, row.kamId, !row.isDisable)} />
                          </div>
                        </td>
                      </tr>
                    )

                  }
                  )
              }

            </tbody>


          </table>


        </div>






      </div>
    </>
  )
}

export default OrganisationList
