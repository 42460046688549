import React from 'react'
import imginfoStyle from '../ProcaImgInfo/ProcaImgInfo.module.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';



const ProcaImgInfo = () => {
  return (
    <div className={imginfoStyle.mainImgInfo}>
      <div className={imginfoStyle.imgDivStart}>
        <div className={imginfoStyle.procImageconnet}>
            <h1>Procure with Precision</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
            <ul>
                <li><CheckCircleIcon/>Add your own vendors</li>
                <li><CheckCircleIcon/>Compare RFx</li>
                <li><CheckCircleIcon/>100% Secure</li>
                <li><CheckCircleIcon/>Customise templates</li>
            </ul>
        </div>
        <div style={{marginBottom:'-7px',marginRight:'-6px'}} className={imginfoStyle.procImagepp}>
        <img src="https://res.cloudinary.com/dtffniutw/image/upload/v1698725337/Dashboard_ywtxqb.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default ProcaImgInfo
