import { useLocation, Navigate, Outlet, useParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";


const RequieWorkSpace = ({allowedWorkSpace}) => {
    const { auth } = useAuth();
    const location = useLocation();
    const { client } = useParams();

    return (
        
        auth?.workSpaces?.includes(allowedWorkSpace)
            ? <Outlet />
            : auth?.userData
                ? <Navigate to={`/${client}/unauthorized`} state={{ from: location }} replace />
                : <Navigate to={"/login"} state={{ from: location }} replace />
       
    )
}

export default RequieWorkSpace;