import React, { useState } from 'react'
import SubcategorySelector from './SubcategorySelector';
import TaskStyle from './TaskPannel.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useEffect } from 'react';
import useBackdrop from "hooks/useBackdrop";
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import CategoryIcon from '@mui/icons-material/Category';


// Functional component for selecting categories and products
const CategorySelector = ({ setProductSelected, productSelected, category, selectedSubcategories, setSelectedSubcategories }) => {
    // Access authentication information from useAuth hook
    const { auth } = useAuth();
    // Access privateAxios instance and backdrop functions
    const privateAxios = PrivateComponent();
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    // State for category selection dropdown
    const [catSelectDropdown, setCatSelectDropdown] = useState(false)

    // State for product dropdown
    const [productDropDown, setProductDropDown] = useState(false);
    // State to store product list
    const [productList, setProductList] = useState([])

    // Toggle category dropdown
    const handleCatSelector = () => {
        setCatSelectDropdown(prev => !prev)
    }

    // Toggle product dropdown
    const handleProductDropDown = () => {
        setProductDropDown(prev => !prev)
    }

    // Fetch product data based on category selection
    const getPorductData = async (categoryId) => {
        openBackdrop();
        try {
            privateAxios.get(`/procurement/getProducts/${auth.orgId}/${categoryId}`)
                .then((res) => {
                    setProductList(res.data.data.products);
                    // console.log(res);
                    closeBackdrop();
                })
                .catch((error) => {
                    console.error('Error fetching products:', error);
                    closeBackdrop();
                });
        } catch (error) {
            setProductList("");
            closeBackdrop();
        }
    }

    // Fetch product data when selected subcategories change
    useEffect(() => {
        if (selectedSubcategories[selectedSubcategories.length - 1]?.branch.length === 0) {
            const sub = selectedSubcategories[selectedSubcategories.length - 1]
            getPorductData(sub.categoryId);
        }

    }, [selectedSubcategories])



    // Handle category selection
    const handleCategorySelect = async (category) => {
        // openBackdrop()
        try {
            setSelectedSubcategories([category]);
            handleCatSelector()
            setProductSelected("");
        } catch (err) {
            handleCatSelector()
        }

        // Reset subcategory when category changes
    };

    // Handle subcategory selection
    const handleSubcategorySelect = (subcategory) => {


        const tt = selectedSubcategories.filter((cat) => {
            return cat.level < subcategory.level;
        })
        const newSub = [
            ...tt,
            { ...subcategory },
        ]
        setProductSelected("");
        setSelectedSubcategories(newSub);

    };

    // Handle product selection
    const handleProductSelect = (pro) => {
        setProductSelected(pro)
        setProductDropDown(false)
    }


    // State for category search
    const [catSearch, setCatSearch] = useState("");
    // State for product search
    const [proSearch, setProSearch] = useState("");

    return (
        <>
            <div className={TaskStyle.taskDetailsRow}>
                <div className={TaskStyle.taskDetailsCol}  >
                    <span>
                        <CategoryIcon sx={{ color: '#6A6A6A' }} />
                    </span>
                    <p>
                        Category
                    </p>
                </div>
                <div className={TaskStyle.taskDetailsCol}  >
                    <div className={TaskStyle.selectorDiv} >
                        <p onClick={handleCatSelector}
                            style={{
                                fontSize: "13px", color: "#3F3F3F",
                                fontWeight: "500", display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                minWidth: "200px"
                            }} >
                            {selectedSubcategories[0]?.categoryName || "Please select a category"}
                            <span>
                                {catSelectDropdown
                                    ?
                                    <KeyboardArrowDownIcon />
                                    :
                                    <KeyboardArrowUpIcon />
                                }

                            </span>
                        </p>
                        {catSelectDropdown && <ThreeDotDropDown toggle={handleCatSelector} style={{ bottom: "100%", top: "auto", width: "100%", borderRadius: "4px", maxHeight: "300px", overFlow: "auto" }}>
                            <li style={{ backgroundColor: "white" }}>
                                <input type="text" value={catSearch} onChange={(e) => setCatSearch(e.target.value)}
                                    style={{ width: "100%", padding: "5px", border: "none", outline: "none", border: "1px solid #ccc", color: "#000" }}
                                />
                            </li>

                            {category.filter((cat) => cat.categoryName.toLowerCase().includes(catSearch.trim().toLowerCase())).map((category) => {
                                // console.log(category)
                                return (
                                    <li key={category.categoryId} onClick={() => handleCategorySelect(category)}>
                                        {category.categoryName}
                                    </li>
                                )
                            }

                            )}
                        </ThreeDotDropDown>
                        }
                    </div>

                </div>
            </div>


            {selectedSubcategories.length > 0 &&

                selectedSubcategories.map((selectedCategory, index) => {
                    // console.log(selectedCategory);
                    if (selectedCategory.branch.length > 0) {
                        return (


                            <div className={TaskStyle.taskDetailsRow}>
                                <div className={TaskStyle.taskDetailsCol}  >

                                    <span>
                                        <CategoryIcon sx={{ color: '#6A6A6A' }} />
                                    </span>
                                    <p>
                                        Sub Category
                                    </p>
                                </div>




                                <SubcategorySelector


                                    category={selectedCategory}
                                    selectedSubcategories={selectedSubcategories}
                                    onSelectSubcategory={(subcategory) => handleSubcategorySelect(subcategory)}
                                    index={index}
                                />

                            </div>
                        )
                    }
                }
                )}

            {selectedSubcategories[selectedSubcategories.length - 1]?.branch.length === 0 &&
                <div className={TaskStyle.taskDetailsRow}>
                    <div className={TaskStyle.taskDetailsCol}  >

                        <span>
                            <Inventory2Icon sx={{ color: '#6A6A6A' }} />
                        </span>
                        <p> Product
                        </p>
                    </div>
                    <div className={TaskStyle.taskDetailsCol}  >
                        <div className={TaskStyle.selectorDiv} >
                            <p onClick={() => { handleProductDropDown(); }} style={{ fontSize: "13px", color: "#3F3F3F", fontWeight: "500", display: "flex", alignItems: "center", justifyContent: "space-between", minWidth: "200px" }}>{productSelected.productName || "Please Select A Product"}
                                <span>
                                    {productDropDown
                                        ?
                                        <KeyboardArrowDownIcon />
                                        :
                                        <KeyboardArrowUpIcon />
                                    }

                                </span>
                            </p>
                            {productDropDown && <ThreeDotDropDown toggle={handleProductDropDown} style={{ bottom: "100%", top: "auto", width: "100%", borderRadius: "4px", maxHeight: "300px", overFlow: "auto" }}>
                                <li style={{ backgroundColor: "white" }}>
                                    <input type="text" value={proSearch} onChange={(e) => setProSearch(e.target.value)}
                                        style={{ width: "100%", padding: "5px", border: "none", outline: "none", border: "1px solid #ccc", color: "#000" }}
                                    />
                                </li>
                                {productList.filter((pro) => pro.productName.toLowerCase().includes(proSearch.trim().toLowerCase())).map((pro) => {
                                    // console.log(pro)
                                    return (
                                        <li
                                            key={pro.productId}
                                            onClick={() => handleProductSelect(pro)}
                                        >
                                            {pro.productName}
                                        </li>
                                    )
                                }

                                )}
                            </ThreeDotDropDown>
                            }
                        </div>

                    </div>
                </div>}


            <BackdropComponent />
        </>
    )
}

export default CategorySelector
