import React from 'react';
import SessionStyle from './SessionExpire.module.css';
import SessionExp from '../../assets/images/SessionExpire.svg';
import { Button } from '@mui/material'; 
import { useNavigate } from 'react-router-dom';

function SessionExpire() {
  const navigate = useNavigate(); 

  return (
    <div className={SessionStyle.mainSession}>
      <div className={SessionStyle.sessionImg}>
        <img src={SessionExp} alt="Loading" />
        <h2>Your session has expired</h2>
        <h3>We're sorry, your session has expired due to inactivity, but don't worry, we’ve kept all your data in place. Please login to continue</h3>
      
        <Button variant='contained' onClick={() => navigate("/login")}>Login</Button>
       
      </div>
    </div>
  );
}

export default SessionExpire;
