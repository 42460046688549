import React, { useState } from 'react';
import attributeStyle from './AttributeUnitSelector.module.css';
import ReactQuill from 'react-quill';
import { PrivateComponent } from '../../api/axios';
import useAuth from '../../hooks/useAuth';


// Define modules for ReactQuill editor toolbar
const modules = {
    toolbar: [
        [{ script: "sub" }, { script: "super" }], // Subscript and Superscript buttons
        ["clean"],// Clean formatting button
    ],
};



const AttributeUnitSelector = ({ handleValue, units, getUnits, handleUnitOpen, value, rowId }) => {
    const { auth } = useAuth(); // Get authentication data from useAuth hook
    const privateAxios = PrivateComponent(); // Axios instance for private requests
    const [localValue, setLocalValue] = useState(value || ""); // Local state for value
    const [specificationValue, setSpecificationValue] = useState(""); // Local state for specification value
    const handleValueRichEditor = () => {
        // Handle value change with rich editor
        handleValue(localValue, rowId); // Call handleValue function with localValue and rowId
        handleUnitOpen(rowId); // Open unit selection
        handleUnitUpload(localValue); // Upload unit
    };
    const [toggleEditor, setToggleEditor] = useState(false); // State to toggle editor visibility
    const handleEditorToggle = () => {
        // Toggle editor visibility
        setToggleEditor(prev => !prev);
        // Invert previous state
    };


    // Function to upload unit
    const handleUnitUpload = async (value) => {
        try {
            let url = auth.roles === "VENDOR" ? `/technicalSpecification/addUnitOfAttributeInVendor/${auth.userData.id}` : `/technicalSpecification/addUnitOfAttribute/${auth.orgId}`;

            await privateAxios.post(`${url}`, { unit: value }).then((res) => {
                getUnits();// Refresh units
            }).catch(err => {
                // console.log(err);
            })
        } catch (error) {
            // console.log(error)
        }
    }


    return (
        <div className={attributeStyle.attributeUnitMain}>

            {
                toggleEditor
                    ?
                    <>
                        <div className={attributeStyle.attributeUnitEditor} >
                            <ReactQuill modules={modules} theme="snow" value={localValue} onChange={setLocalValue} />

                        </div>
                        <div className={attributeStyle.attributeUnitButton}>

                            <button onClick={handleValueRichEditor}>Set</button>
                        </div>
                    </>
                    :
                    <>
                        <ul className={attributeStyle.attributeUnitList}>
                            <li onClick={() => { handleEditorToggle() }}>
                                + Add Unit
                            </li>
                            {units?.map((data, index) => {
                                // console.log(data)
                                return (
                                    <li key={index} onClick={() => { handleValue(data.unit, rowId); handleUnitOpen(rowId) }} dangerouslySetInnerHTML={{ __html: data.unit }}>

                                    </li>
                                )

                            })}


                        </ul>
                    </>
            }




        </div>
    );
};

export default AttributeUnitSelector;
