import React, { useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './DescriptionTab.module.css'
const DescriptionTab = ({ value, onChange, viewType, descriptionRemark, descriptionRemarkOnChange }) => {


  return (
    <div className={styles.specficationTabMain}>
      <div className={styles.richEditor}>
        <ReactQuill theme="snow" value={value} onChange={onChange} />
      </div>
      {
        viewType &&

        <div className={styles.vendorRemarkCont}>
          <label htmlFor=""> Remarks</label>
          <textarea name="" id="" value={descriptionRemark} onChange={descriptionRemarkOnChange} />
        </div>
      }


    </div>
  )
}

export default DescriptionTab
