import React from 'react'
import { useState } from 'react'
import priortyStyle from './PrioritySelector.module.css'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const PrioritySelector = ({ value, onChange, name, options, inputStyleProps, optionStyleProps, disabled }) => {

    const [listOpen, setListOpen] = useState(false)
    const handleClick = () => {

    }

    return (
        <div className={priortyStyle.selectorWrapper}>
            <p onClick={() => { setListOpen(prev => !prev) }}
                style={{
                    backgroundColor: options.find((item) => item.value === value)?.backgroundColor,
                    color: options.find((item) => item.value === value)?.color
                }}

            >{value || "Select Priority"}
                <span>
                    {
                        listOpen
                            ?
                            <ArrowDropUpIcon  style={{
                                backgroundColor: options.find((item) => item.value === value)?.backgroundColor,
                                color: options.find((item) => item.value === value)?.color
                            }} />
                            :
                            <ArrowDropDownIcon   style={{
                                backgroundColor: options.find((item) => item.value === value)?.backgroundColor,
                                color: options.find((item) => item.value === value)?.color
                            }} />
                    }

                </span></p>
            {
                (!disabled && listOpen) && (
                    <>

                        <div className={priortyStyle.backdrop}
                            onClick={() => { setListOpen(prev => !prev) }}
                        ></div>

                        <ul className={priortyStyle.dropDownList}>
                            {options?.map((option, index) => (
                                <li key={index} onClick={() => { onChange({ target: { value: option?.value, name: name } }); setListOpen(prev => !prev) }}
                                    style={{ backgroundColor: option?.backgroundColor, color: option?.color }}
                                >{option?.label}</li>
                            ))}
                        </ul>

                    </>
                )
            }

        </div>
    )
}

export default PrioritySelector
