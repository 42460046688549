import React, { useEffect, useState } from 'react'
import cStyle from 'components/ModalCommonCSS/ModalCommonCSS.module.css'
import style from './RevokeRequestModal.module.css'
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';



const RevokeRequestModal = ({ open, onClose,onCancel, onSubmit, prefilled,header,readOnly,discardText,confimBtnText }) => {

    const [name, setName] = useState("")

    const handleSubmit = async(e) => {
        e.preventDefault();
        await onSubmit({name:name})
        setName("")
    }
    useEffect(() => {
        // Update the state when the prefilled prop changes
        console.log(prefilled);
        setName(prefilled);
    }, [prefilled]);

    return (
        <Modal
            open={open}
            onClose={onClose}

        >
            <div className={cStyle.modalContainer}>

                <form action="" onSubmit={handleSubmit} className={style.formContainer} >
                    <div className={style.modalHeader} >
                        
                        <h3>
                            <span>
                                <PeopleOutlineIcon/>
                            </span>
                            {header}
                        </h3>
                        <span onClick={()=>{onClose()}}>
                           <CloseIcon />
                        </span>
                    </div>

                    <div className={style.formGroup}>
                        <label htmlFor="comment">Reason For Withdrawal:</label>

                        <textarea type="text" id='comment' name='comment' value={name} onChange={(e) => setName(e.target.value)} readOnly={readOnly} />
                    </div>




                    <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>

                        <button className={`${cStyle.modalBtn} ${cStyle.pimaryModalBtn}`} type='submit'>
                            {confimBtnText||"Send"}  
                            </button>


                        <p
                            onClick={() => {onCancel() }}
                            className={`${cStyle.modalBtn} ${cStyle.secondaryModalBtn}`}
                        >{discardText||"Cancel"}</p>
                    </div>


                </form>

            </div>
        </Modal>
    )
}

export default RevokeRequestModal
