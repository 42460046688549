import { createSlice } from '@reduxjs/toolkit'

// Define the initial state for the authentication slice
const initialState = {
    name: "",               // User's name
    companyName: "",       // User's company name
    email: null,            // User's email
    token: null,            // Authentication token
    userData: null,         // User data
    roles: [],              // User roles
    orgId: null,            // Organization ID
    workSpaces: [],         // User workspaces
    allData: null,          // All user data
    orgLogo: null,          // Organization logo
    mobNum: '',             // User's mobile number
    profilePic: null,       // User's profile picture
}

// Create the authentication slice using Redux Toolkit
export const AuthSlice = createSlice({
    name: "AuthData", // Name of the slice
    initialState,// Initial state
    reducers: {
        setLogin: (state, action) => {
            state.token = action.payload.token;
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.roles = action.payload.roles;
            state.userData = action.payload.userData;
            state.companyName = action.payload.companyName;
            state.orgId = action.payload.orgId;
            state.workSpaces = action.payload.workSpaces;
            state.allData = action.payload.allData;
            state.orgLogo = action.payload.orgLogo;
            state.mobNum = action.payload.mobNum;
            state.profilePic = action.payload.profilePic;

        },
        // Reducer to set logout
        setLogout: (state) => {
            // Reset all authentication-related state to null or empty
            state.name = null;
            state.companyName = null;
            state.email = null;
            state.token = null;
            state.userData = null;
            state.roles = [];
            state.orgId = null;
            state.workSpaces = [];
            state.allData = null;
            state.orgLogo = null;
            state.mobNum = null;
            state.profilePic = null;

        },
        // Reducer to set user data
        setUserData: (state, action) => {
            state.userData = action.payload.userData;
        },
        // Reducer to set organization logo
        setOrgLogo: (state, action) => {
            state.orgLogo = action.payload.orgLogo;
        },
        // Reducer to set mobile number
        setMobNum: (state, action) => {
            state.mobNum = action.payload.mobNum;
        },
        // Reducer to set profile picture
        setProfilePic: (state, action) => {
            state.profilePic = action.payload.profilePic;
        },
        // Reducer to set all user data
        setAllData: (state, action) => {
            state.allData = action.payload.allData;
        },
        // Reducer to refresh data
        setRefreshData: (state, action) => {
            // Update multiple fields at once with payload data
            state.allData = action.payload.allData;
            state.companyName = action.payload.companyName;
            state.workSpaces = action.payload.workSpaces;
            state.orgId = action.payload.orgId;
            state.orgLogo = action.payload.orgLogo;
            state.profilePic = action.payload.profilePic;
        },


    }
})
// Export actions from the authentication slice
export const { setLogout, setLogin, setUserData, setOrgLogo, setMobNum, setProfilePic, setAllData, setRefreshData } = AuthSlice.actions;

export default AuthSlice.reducer;