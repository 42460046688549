import React from 'react'
import ProcaHeader from './ProcaHeader/ProcaHeader'
import ProcaImages from './ProcaImages/ProcaImages'
import ProcaImgInfo from './ProcaImgInfo/ProcaImgInfo'
import ProcaHelp from './ProcaHelp/ProcaHelp'

const FeaturePage = () => {
  return (
    <div>
      <ProcaHeader/>
      <ProcaImages/>
      <ProcaImgInfo/>
      <ProcaHelp/>    
    </div>
  )
}

export default FeaturePage
