import React, { useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styles from './Document.module.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import useBlobDownload from 'hooks/useBlobDownload';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';

const DocumentsTab = ({ data, documentRemark, setDocumentRemark, approverRemarks, approovalStatus, viewType, showToast }) => {
  const { auth } = useAuth();
  const privateAxios = PrivateComponent();
  const { fileDownload } = useBlobDownload();

  const [openConfidential, setOpenConfidential] = useState(false)


  return (
    <>
      <div className={styles.documentsTabMain}>

        {
          Object.values(data).map((vdocuments, index) => {
            return (
              <div>
                <h3>{Object.keys(data)[index]}</h3>
                <table className={styles.DocumentTable}>
                  <thead>
                    <tr className={`${styles.DocumentTableRow} ${styles.DocumentTableHead}`}>
                      <td>Title</td>
                      <td>Date Added</td>
                      {/* <td>Remark</td> */}
                      <td>
                        <div className={styles.documentIconRow}>

                          <a><FileDownloadIcon /></a>


                          <a><MoreVertIcon /></a>

                        </div>
                      </td>

                    </tr>
                  </thead>
                  <tbody>
                    {
                      vdocuments.normal.length > 0 ?
                        <>

                          {vdocuments.normal?.map((doc, index) => {
                            return (
                              <tr className={styles.DocumentTableRow}>
                                <td>{doc.name || doc.url}</td>
                                <td>{doc?.date?.split('T')[0] || "Null"}</td>

                                <td> <div className={styles.documentIconRow}>

                                  <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}><FileDownloadIcon /></Link>



                                </div></td>
                              </tr>
                            )

                          })
                          }
                        </>
                        :
                        <>
                          <tr className={styles.DocumentTableRow}>
                            <td>No Document Found</td>
                            <td>
                              -
                            </td>
                            <td>
                              -
                            </td>
                          </tr>
                        </>

                    }
                  </tbody>
                </table>




                {
                  vdocuments.confidential.length > 0 &&

                  <div className={styles.accordianDocumentab}>
                    <div className={styles.accordianDocumentabHeader} onClick={() => setOpenConfidential(prev => !prev)}><span>
                      {openConfidential ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
                    </span>Confidential Documents</div>

                    <div className={styles.accordianDocumentabBody}>

                      {openConfidential &&
                        <table className={styles.DocumentTable}>
                          <tbody>
                            {vdocuments.normal?.map((doc, index) => {

                              return (
                                <tr className={styles.DocumentTableRow}>
                                  <td>{doc.name || doc.url}</td>
                                  <td>{doc?.date?.split('T')[0] || "Null"}</td>

                                  <td> <div className={styles.documentIconRow}>

                                    <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}><FileDownloadIcon /></Link>
                                  </div></td>
                                </tr>
                              )

                            })

                            }

                          </tbody>
                        </table>

                      }
                    </div>


                  </div>
                }
              </div>
            )
          })
        }

        {
          viewType === "WAPPROVAL" &&
          <>

            {approverRemarks?.length > 0 &&

              <table className={styles.specficationTable}>
                <tr className={`${styles.tableHeader}`}>
                  <th>
                    Approver Remark
                  </th>
                  <th style={{ textAlign: "left", paddingRight: "20px" }}>
                    From
                  </th>
                </tr>
                {approverRemarks?.map((rem, index) => {
                  return (
                    <tr className={styles.tableRow}>


                      {/* // <div className={styles.richEditor} style={{ border: "1px solid grey" }}> */}

                      <td className={styles.reactQuillTd}>
                        <ReactQuill modules={{ toolbar: false }}
                          readOnly={true}
                          style={{ border: "none", outline: "none" }}
                          // theme="snow" 
                          value={rem.remark}
                        />
                      </td>
                      <td className={styles.vendorInfo}>
                        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                          <ProfileIcon data={{ email: rem?.email }} height={"26px"} width={"26px"} showProfileBanner={rem.name == "No Name" ? false : true} />
                          {rem.name}
                        </div>
                      </td>

                      {/* </div> */}
                    </tr>
                  )

                })}
              </table>
            }


            {auth.roles !== "KAM"
              &&
              <div style={{ display: "flex", height: "20vh", width: "100%", flexDirection: "column", gap: "5px" }}>
                <h3 style={{ marginBottom: "3px", fontSize: "16px", fontWeight: "800" }}>Add Remark</h3>
                <textarea name="" id="" cols="30" rows="10" value={documentRemark} onChange={(e) => {
                  if (approovalStatus !== "APPROVAL" && approovalStatus !== "REJECTED") {
                    setDocumentRemark(e.target.value)

                  } else {
                    showToast("error", "You can't add remark in after Decision");
                  }
                }} className={styles.descriptionCompareTextBox} style={{ height: "100%" }} />
              </div>

            }

          </>
        }


      </div>






    </>
  )
}

export default DocumentsTab


