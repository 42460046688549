import React, { useState, useEffect } from 'react';// Import React and necessary hooks
import { Outlet, useParams } from 'react-router-dom'; // Import Outlet and useParams from react-router-dom
import Header from 'components/Header/Header';// Import Header component
import layoutStyle from './Layout.module.css';// Import CSS module for layout styles
import Navbar from 'components/Navbar/Navbar';// Import Navbar component
import useAuth from 'hooks/useAuth';// Import custom hook useAuth
import SubscriptionExpire from '../Subscription/SubscriptionExpire';// Import SubscriptionExpire component
import { PrivateComponent } from 'api/axios';// Import PrivateComponent from axios API
import { useDispatch } from 'react-redux';// Import useDispatch hook from react-redux
import { setRefreshData } from 'state/AuthState';// Import action creator setRefreshData



const Layout = () => {
  const { auth } = useAuth();// Destructure auth from useAuth hook
  const { client } = useParams();// Destructure client from useParams hook
  const privateAxios = PrivateComponent();// Initialize privateAxios with PrivateComponent
  const [isOpen, setIsOpen] = useState(false); // State for drawer open/close
  const [isSubsOpen, setIsSubsOpen] = useState(false);// State for subscription modal open/close
  const [remainingDays, setRemainingDays] = useState(null);// State for remaining subscription days
  const [hasExpired, setHasExpired] = useState(false);// State for subscription expiry
  const dispatch = useDispatch(); // Initialize dispatch function

  const handleDrawerOpen = () => {// Function to handle drawer open/close
    setIsOpen(prev => !prev); // Toggle isOpen state
  };
  const handleSubsToggle = () => {// Function to handle subscription modal open/close
    setIsSubsOpen(prev => !prev);// Toggle isSubsOpen state
  };

  useEffect(() => {// Effect to calculate remaining subscription days and check expiry
    const today = new Date(); // Current date and time
    const subscriptionExpiryDate = new Date(auth.allData.subscriptionExpiry);// Subscription expiry date from auth data

    const differenceInTime = subscriptionExpiryDate - today;// Difference between expiry and today
    const differenceInDays = Math.max(Math.floor(differenceInTime / (1000 * 3600 * 24)) + 1, 0);// Difference in days

    setRemainingDays(differenceInDays);// Set remaining subscription days

    if (differenceInTime <= 0) {// Check if subscription has expired
      console.log("Subscription has expired.");// Log expiration message
      setIsSubsOpen(true);// Open subscription modal
      setHasExpired(true);// Set hasExpired to true
    }
  }, [auth.allData.subscriptionExpiry]);// Dependency array for useEffect


  const getEmployeeDetails = async () => {// Function to fetch employee details
    try {
      const response = await privateAxios.get(`/procurement/getEmployeeDetails`).then((res) => {

        let data = res.data;// Extract data from response

        let workArray = data?.data?.workspaces?.map((work) => {// Map workspace IDs to names
          switch (work.id) {
            case 1: return "technical-specification";
              break;
            case 2: return "commercial";
              break;
            case 3: return "procurement";
              break;
            case 4: return "approvals";
              break;
          }

        })

        dispatch(setRefreshData({// Dispatch action to set refreshed data

          allData: data.data,
          companyName: data.data.organisationName,
          workSpaces: workArray,
          orgId: data.data.organisationId,
          orgLogo: data.data.orgLogo,
          profilePic: data.data.profilePhoto,

        }))

      })
    } catch (error) {

    }
  }

  useEffect(() => {// Effect to fetch employee details periodically
    getEmployeeDetails(); // Initial call to fetch employee details
    const intervalId = setInterval(() => {// Set interval to periodically fetch employee details
      getEmployeeDetails();// Call function to fetch employee details
    }, 120000); // 2 minutes in milliseconds

    return () => clearInterval(intervalId); // Cleanup function
  }, []);




  return (
    <div className={layoutStyle.layoutMain}>
      <>
        <Header clientName={client} isOpen={isOpen} handleDrawerOpen={handleDrawerOpen} />
      </>
      <div className={`${layoutStyle.layoutPage} ${isOpen ? layoutStyle.open : ""}`}>
        <>
          <Navbar remainingDays={remainingDays} />
        </>
        <div className={layoutStyle.PageContainer}>
          {!hasExpired ? <Outlet /> : ""}

        </div>
      </div>
      {auth.roles == "KAM" &&
        <div className={layoutStyle.trailDayButton}>
          <button onClick={() => {
            if (!hasExpired) {

              handleSubsToggle()
            }
            else {
              console.log("Subscription Expired")
            }

          }}>

            {remainingDays} days trial left
          </button>
        </div>
      }

      <SubscriptionExpire isOpen={isSubsOpen} onClose={() => {
        if (!hasExpired) {

          handleSubsToggle()
        }
        else {
          console.log("Subscription Expired")
        }

      }} remainingDays={remainingDays} />



    </div>
  );
};

export default Layout;
