import React from 'react'
import TaskStyle from './TaskPannel.module.css'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GroupsIcon from '@mui/icons-material/Groups';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import ChattPanel from '../ChattPanel/ChattPanel';
import { PrivateComponent } from 'api/axios';
import { useRef } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import useAuth from 'hooks/useAuth';
import CloseIcon from '@mui/icons-material/Close';
import CategorySelector from './CategorySelector';
import useBackdrop from "hooks/useBackdrop";
import DescriptionIcon from '@mui/icons-material/Description';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tooltip } from '@mui/material';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FetchUsersComponent from 'components/FetchUsersComponent/FetchUsersComponent'
import useToast from 'hooks/useToast';
import PrioritySelector from 'components/TaskComponents/PrioritySelector/PrioritySelector';
import OptionSelector from 'components/TaskComponents/OptionSelector/OptionSelector';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};

const styleUploadFiles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    p: 2,

};

const styleSelectEmails = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    p: 2,
};



function TaskPannel({ handlecreateTaskModal, getAllTasks }) {

    // UseToast hook for displaying toast messages
    const { Toast, showToast } = useToast();
    // State variable for the selected product
    const [productSelected, setProductSelected] = useState("");
    // UseAuth hook to get authentication data
    const { auth } = useAuth();
    // UseBackdrop hook for managing backdrop component
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    // Get current date in YYYY-MM-DD format
    const currentDate = new Date().toISOString().slice(0, 10);
    // PrivateComponent hook for accessing private Axios instance
    const privateAxios = PrivateComponent();
    // State variable for selected files
    const [selectedFiles, setSelectedFiles] = useState([]);
    // Reference for file input element
    const inputRef = useRef(null);
    // Reference for date input element
    const dateRef = useRef(null);
    // State variable for selected subcategories
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    // State variable for uploaded files
    const [files, setFiles] = useState([]);
    // State variable for loading user data
    const [loadingUsers, setLoadingUsers] = useState(false);

    const [data, setData] = useState({    // set all data
        title: "",
        description: "",
        startDate: currentDate, // Set the default value to today's date
        endDate: "",
        status: "TODO",
        priority: "",
    })

    const [formError, setFormError] = useState({ })

    // New state for upload modal

   // State variable for upload modal visibility
   const [uploadModalOpen, setUploadModalOpen] = useState(false);
   // State variable for email modal visibility
   const [emailModalOpen, setEmailModalOpen] = useState(false);
   // State variable for KAM ID
   const [kamId, setKamId] = useState('');
   // State variable for selected emails
   const [selectedEmails, setSelectedEmails] = useState([]);
   // State variable for selected file names
   const [selectedFileNames, setSelectedFileNames] = useState([]);
   // State variable for file sizes
   const [fileSizes, setFileSizes] = useState([]);
   // State variable for chat messages
   const [chats, setChats] = useState([]);
   // Workspace ID
   const workspaceId = 1;
   // Fetch user data
   const [userList, allEmails, notLoggedIn] = FetchUsersComponent({ orgId: auth.orgId, workspaceId: workspaceId })

   
    const handleUploadModalClose = () => {
        setUploadModalOpen(false);
    };



    // ======================fetch category========================

 // State variable for available categories
    const [availableCategory, setAvailableCategory] = useState([]);
      // Fetch categories from backend on component mount
    const fetchCategory = async () => {
        openBackdrop();
        try {
            await privateAxios.get(`/procurement/getCategories/${auth.orgId}`).then(res => {
                setAvailableCategory(res.data.data.categories);
            }).catch((err) => {
                alert("error getting categories");
            })
        } catch (error) {
            // console.log(error)
        }

        closeBackdrop();
    }

    useEffect(() => {
        fetchCategory();
    }, [])


    // Handle opening the email selection modal and fetching users
    const handleEmailModalOpen = () => {
        setEmailModalOpen(true); // Open the modal
    };

 // Handle checkbox change for email selection
    const handleEmailCheckboxChange = (email) => {
        const isSelected = selectedEmails.includes(email);
        // Clone the selectedEmails array to avoid mutating the state directly
        const updatedSelectedEmails = [...selectedEmails];

        if (updatedSelectedEmails.includes(email)) {
            // If the email is already in the selectedEmails, remove it
            const index = updatedSelectedEmails.indexOf(email);
            if (index > -1) {
                updatedSelectedEmails.splice(index, 1);
            }
        } else {
            // If the email is not in the selectedEmails, add it
            updatedSelectedEmails.push(email);
        }

        // Update the state with the new selected emails
        setSelectedEmails(updatedSelectedEmails);
    };

      // Handle removing selected file
    const handleRemoveSelectedFile = (index) => {
        const updatedSelectedFiles = [...selectedFiles];
        const updatedSelectedFileNames = [...selectedFileNames];
        updatedSelectedFiles.splice(index, 1);
        updatedSelectedFileNames.splice(index, 1);
        setSelectedFiles(updatedSelectedFiles);
        setSelectedFileNames(updatedSelectedFileNames);
    };

  // Handle closing the email selection modal
    const handleEmailModalClose = () => {
        // Store only the first letter of the last selected email in selectedEmailsDisplay
        if (selectedEmails.length > 0) {
            const lastSelectedEmail = selectedEmails[selectedEmails.length - 1];
            const firstLetter = lastSelectedEmail.charAt(0);

        }

        setEmailModalOpen(false);
    };
    // Declare and initialize setIsDateSelected and isDateSelected
    const [isDateSelected, setIsDateSelected] = useState(false);
    const allowedExtensions = ['.jpg', '.jpeg', '.pdf', '.xls', '.xlsx', '.docx'];

    //files
    const handleUploadModalOpen = () => {
        // setSelectedFiles([]); // Clear the selected files
        setUploadModalOpen(true);
    };
    // Function to handle file selection

   // Function to handle file input change
    const handleFileInputChange = (e) => {
        const tfiles = e.target.files || e.dataTransfer.files;
        const fileNames = Array.from(tfiles).map((file) => file.name);
        const upFiles = files.filter((ty) => !fileNames.includes(ty.name));
        const templist = [...upFiles, ...tfiles]
        setFiles(templist);
        setSelectedFiles(templist);
        // Get the file sizes and update the state
        const sizes = Array.from(templist).map((file) => formatFileSize(file.size));
        setFileSizes(sizes);
    };
    
    // Function to get file extension
    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        return parts.length > 1 ? `.${parts.pop()}` : '';
    };

  // Function to truncate file name
    function truncateFileName(fileName, maxLength) {
        if (typeof fileName !== 'string' || fileName.length === 0) {
            return ''; // Return an empty string or handle this case as needed
        }

        if (fileName.length <= maxLength) {
            return `${fileName}`;
        }

        const truncatedFileName = fileName.substring(0, maxLength - 5) + '...' + getFileExtension(fileName);
        return truncatedFileName;
    }
    // Function to format file size for display
    const formatFileSize = (size) => {
        if (size < 1024) {
            return size + ' B';
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(2) + ' KB';
        } else if (size < 1024 * 1024 * 1024) {
            return (size / (1024 * 1024)).toFixed(2) + ' MB';
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        }
    };




    const handleInputValidation = () => {
        const isEmpty = Object.values(data).some(dd => dd === '');

        if (!isEmpty && productSelected !== "" && selectedEmails.length !== 0) {
            setFormError({});
            return true;
        } else {
            setFormError({});
            if (data.title === '') {
                alert('Please Enter title');
                setFormError(prev => ({
                    ...prev,
                    title: "Please Enter Title"
                }));
            }
            else if (data.description === '') {
                alert('Please Enter Description');
                setFormError(prev => ({
                    ...prev,
                    description: "Please Add Description"
                }));
            }
            else if (data.startDate === '') {
                alert('Please select start date');
                setFormError(prev => ({
                    ...prev,
                    startDate: "Please select start date"
                }));
            }
            else if (data.endDate === '') {
                alert('Please select end date');
                setFormError(prev => ({
                    ...prev,
                    endDate: "Please select end date"
                }));
            } else if (data.priority === '') {
                alert('Please select priority');
                setFormError(prev => ({
                    ...prev,
                    priority: "Please select priority"
                }));
            } else if (selectedEmails.length === 0) {
                alert('Please select email');
                setFormError(prev => ({
                    ...prev,
                    emailSelected: "Please select Atleast One Person"
                }));
            } else if (productSelected === "") {
                // console.log('Alert called');
                alert('Please select A Product.');
                setFormError(prev => ({
                    ...prev,
                    productSelected: "Please select A Product"
                }));
            }
            return false;
        }
    };



    const handleCreateTask = async () => {
        if (handleInputValidation()) {
            openBackdrop()
            try {
                const selectedProduct = {
                    // id: productSelected.productId,
                    productId: productSelected.productId,
                    productName: productSelected.productName,
                    category: productSelected.categoryId,
                };

                const assignBreadCrum = `${selectedSubcategories.map((sub) => sub.categoryName).join(">")} > ${productSelected.productName}`;

                // console.log(categories)
                const payload = {
                    title: data.title,
                    description: data.description,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    userExpectedCompletionDate: "",
                    status: data.status,
                    priority: data.priority,
                    // asign: [...categories, { id: productSelected.productId, level: "", name: productSelected.name }]
                    assign: assignBreadCrum,
                    product: { productId: productSelected.productId }
                };
                // console.log("payload ", payload);
                // console.log(files);

                const response = await privateAxios.post(`/task/addTasks/${auth.userData.id}/${auth.orgId}`, payload)
                    .then(async (response) => {
                        const taskId = response.data.taskId;

                        // console.log("pj", response.data);
                        // window.location.reload();

                        if (taskId !== null) {

                            console.log(files);

                            if (files.length > 0) {
                                const fd = new FormData();
                                for (let i = 0; i < files.length; i++) {
                                    fd.append(`files`, files[i]);
                                }

                                // setMsg("uploading...");


                                await privateAxios.post(`/task/uploadTaskAttachments/${taskId}`, fd, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                    }
                                }).catch((err) => {
                                    alert("error uploading Files")
                                    console.error(err);
                                });

                            }


                            await privateAxios.post(`/task/addUsersToTask/${taskId}`, { emails: selectedEmails }).catch(err => {
                                alert("Error Adding User to task")
                                // console.log("err in user add creation" + err)
                            })

                            if (chats.length > 0) {
                                chats.forEach(async (chat) => {
                                    await privateAxios.post(`/task/addComments/${taskId}`,
                                        { comment: chat.comment, name: chat.name })
                                })
                            }

                            setUploadModalOpen(false);

                            setFiles("");
                            setProductSelected("");
                            getAllTasks();
                            // setSelectedSubCategory([])
                        }
                        else {
                            alert("error getting task Id");
                        }


                    }).catch(err => {

                        // console.log("err in task creation" + err)
                    })
                handlecreateTaskModal()
                closeBackdrop();

            } catch (error) {


                closeBackdrop()
            }

        }
        else {
            // console.log(formError);

        }
    };


    const handlePriorityChange = (event) => {
        setData({ ...data, priority: event.target.value });
    };
    const handleStatusChange = (event) => {
        setData({ ...data, status: event.target.value });
    };

    const handleStartDateChange = (event) => {
        const selectedStartDate = event.target.value;
        const startDate = new Date(selectedStartDate);

        // Check if the selected date is in the past
        const today = new Date();
        if (startDate < today) {
            // If the selected date is in the past, set it to today
            event.target.value = today.toISOString().slice(0, 10);
            setData({
                ...data,
                startDate: today.toISOString().slice(0, 10),
                endDate: today.toISOString().slice(0, 10),
            });
        } else {
            // If the selected date is not in the past, update the state
            setData({
                ...data,
                startDate: selectedStartDate,
                endDate: selectedStartDate,
            });
        }
    };


    const handleEndDateChange = (event) => {
        setData({ ...data, endDate: event.target.value });

        // Check if the end date is earlier than the start dat
    };

    const handleEcDateChange = (event) => {
        const selectedEcDate = event.target.value;
        setData({ ...data, userExpectedCompletionDate: selectedEcDate });

        // Set the minimum date for the "End date" to the selected "E/C date"
        dateRef.current.min = selectedEcDate;
    };


    // ----------------------------Date----------------------------

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return date.toLocaleDateString(undefined, options);
    };

    // ----------------------------Date----------------------------




    const [hoveredFileIndex, setHoveredFileIndex] = useState(null);
    // Event handler for onMouseEnter
    const handleMouseEnter = (index) => {
        setHoveredFileIndex(index);
    };

    // Event handler for onMouseLeave
    const handleMouseLeave = () => {
        setHoveredFileIndex(null);
    };


    const handleFilePreview = (file) => {

        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = file.name;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(a);
    }


    //DATE
    // const [selectedDate, setSelectedDate] = useState(today);
    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    // const handleDateChange = (date) => {
    //     setSelectedDate(date);
    // };

    const handleOpenModal = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const saveSelectedDate = () => {
        // Handle saving the selected date here
        // You can perform any desired actions with the selectedDate state
        // For example, you can close the modal by calling handleCloseModal()
        handleCloseModal();
    };

    return (
        <>
            <BackdropComponent />
            <Toast />
            <div className={TaskStyle.taskModalWrapper} >

                <div className={TaskStyle.taskModal}>

                    <div className={TaskStyle.taskInfoWrapper} >


                        <div className={TaskStyle.taskInfo}>

                            <div className={TaskStyle.taskNav}>
                                <h1 className={TaskStyle.createTask} >Create a task</h1>
                                {/* <h4>#001</h4> */}
                            </div>

                            <div className={TaskStyle.taskHeading}>

                                <input
                                    placeholder='Title'
                                    value={data.title}
                                    onChange={(e) => setData({ ...data, title: e.target.value })}
                                />

                                <textarea value={data.description}
                                    placeholder='Description'
                                    onChange={(e) => setData({ ...data, description: e.target.value })}
                                />
                            </div>
                            <div className={TaskStyle.taskDetailsWrapper}>

                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Start Date
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <input

                                            type="date"
                                            value={data.startDate}
                                            min={new Date().toISOString().slice(0, 10)}
                                            onChange={handleStartDateChange}
                                        />
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            End Date
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <input
                                            type="date"
                                            value={data.endDate}
                                            onChange={handleEndDateChange}
                                            ref={dateRef}
                                            min={data.startDate} // Set the minimum date for the end date
                                        />
                                    </div>


                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            E/C date
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <input
                                            disabled
                                            type="date"
                                            value={data.userExpectedCompletionDate}
                                            onChange={handleEcDateChange}
                                        />
                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <DateRangeIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Status
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <OptionSelector
                                         value={data.status}
                                         onChange={handleStatusChange}
                                         name="status"
                                         // inputStyleProps={}
                                         // optionStyleProps={}
                                         options={[
                                             {
                                                 value: "TODO",
                                                 color: "#000",
                                                //  backgroundColor: "#ec5252",
                                                 label: "To-Do",
                                             },
                                             {
                                                 value: "INPROGRESS",
                                                 color: "#000",
                                                //  backgroundColor: "#ec5252",
                                                 label: "In-Progress",
                                             },
                                             {
                                                 value: "DONE",
                                                 color: "#000",
                                                //  backgroundColor: "#f5ac38",
                                                 label: "Done",
                                             },
                                             {
                                                 value: "COMPLETED",
                                                 color: "#000",
                                                //  backgroundColor: "#b3f289",
                                                 label: "Completed",
                                             },

                                         ]}
                                        
                                        />
                                      
                                    </div>



                                </div>

                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <DateRangeIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Priority
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <PrioritySelector
                                            value={data.priority}
                                            onChange={handlePriorityChange}
                                            name="priortiy"
                                            // inputStyleProps={}
                                            // optionStyleProps={}
                                            options={[
                                                {
                                                    value: "HIGH",
                                                    color: "#fff",
                                                    backgroundColor: "#ec5252",
                                                    label: "High",
                                                },
                                                {
                                                    value: "MEDIUM",
                                                    color: "#fff",
                                                    backgroundColor: "#f5ac38",
                                                    label: "Medium",
                                                },
                                                {
                                                    value: "LOW",
                                                    color: "#fff",
                                                    backgroundColor: "#b3f289",
                                                    label: "Low",
                                                },

                                            ]}
                                        />


                                     
                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <GroupsIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Assignee
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <p>
                                            {auth.name}
                                        </p>
                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol} style={{ height: "100%" }} >
                                        <span>
                                            <AttachFileIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Attachment
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol} style={{ height: "auto", padding: "4px 12px" }}>
                                        <div className={TaskStyle.taskFileWrapper}>

                                            {selectedFiles.map((file, index) => (
                                                <div key={index} className={TaskStyle.selectedFileItem}
                                                >
                                                    <p
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleFilePreview(file)}
                                                    >

                                                        {truncateFileName(file.name, 15)}

                                                    </p>
                                                    <span onClick={() => handleRemoveSelectedFile(index)} style={{ textDecoration: 'none', border: 'none' }}>
                                                        <CloseIcon

                                                        />
                                                    </span>
                                                </div>
                                            ))}

                                            <button className={TaskStyle.uploadButton}
                                                onClick={() => { handleUploadModalOpen() }}
                                            >Upload Files</button>

                                        </div>
                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol} >
                                        <span>
                                            <GroupsIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                        Assigned to
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                  
                                        {Array.isArray(selectedEmails) && selectedEmails.length > 0 && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {selectedEmails.map((email, index) => (
                                                    <div key={email} style={{ display: 'flex', alignItems: 'center' }}>
                                                        {index !== 0 && <div style={{ fontSize: '12px', padding: '0px 5px' }}></div>}
                                                        <div
                                                        >
                                                            <ProfileIcon data={{ email: email }} height={"26px"} width={"26px"} showProfileBanner={true} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                          <AddIcon onClick={handleEmailModalOpen} sx={{ color: '#6A6A6A' }} />
                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}  >
                                        <span>
                                            <GroupsIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                        Assign
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol} >
                                                <p>
                                                    
                                            {`${selectedSubcategories.map((cat) => cat.categoryName).join(" > ")}`} {productSelected !== '' ? `> ${productSelected?.productName}` : ""}
                                                </p>
                                      
                                       </div>



                                </div>
                                {/* Category */}
                                <CategorySelector
                                    category={availableCategory}
                                    selectedSubcategories={selectedSubcategories}
                                    setSelectedSubcategories={setSelectedSubcategories}
                                    productSelected={productSelected}
                                    setProductSelected={setProductSelected}
                                />

                            </div>

                        </div>


                        <div style={{ display: "flex", height: "40px" }}>
                            <button
                                style={{
                                    backgroundColor: '#16469D',
                                }}
                                className={TaskStyle.taskButton}
                                onClick={handleCreateTask}
                            >Create a Task</button>
                        </div>

                    </div>



                    <ChattPanel chats={chats} setChats={setChats} handlecreateTaskModal={handlecreateTaskModal} />


                </div>

            </div>

            {/* *************************************Upload Files Modal ******************************/}

            <Modal open={uploadModalOpen}
            //  onClose={handleUploadModalClose}

            >
                <Box sx={styleUploadFiles} className={TaskStyle.uploadFilesModal}
                    style={{
                        borderRadius: '6px',

                    }}>
                    <div style={{
                        display: 'flex', justifyContent: 'space-between'
                    }}>
                        <Typography variant="h5" component="h2"
                        >
                            Upload Files
                        </Typography>
                        <Tooltip>
                            <CancelIcon
                                onClick={handleUploadModalClose}
                            />
                        </Tooltip>
                    </div>
                    <Box className={TaskStyle.dragAndDrop}>
                        <Typography variant="subtitle1">Drag and drop files here</Typography>
                        <Button variant="contained" onClick={() => inputRef.current.click()}
                            sx={{ textTransform: 'capitalize' }}>
                            Upload
                            <input
                                multiple // Allow multiple file selection
                                ref={inputRef}
                                type="file"
                                hidden
                                accept={allowedExtensions.join(',')}
                                onChange={handleFileInputChange}

                            />
                        </Button>
                        <div className={TaskStyle.supportedFileTypes}>
                            <Typography variant="body2" color="textSecondary" >
                                {allowedExtensions.join(', ')}
                            </Typography>
                        </div>
                        <div className={TaskStyle.selectedFilesList}
                            style={{
                                display: 'flex', gap: '10px',
                                backgroundColor: '#D9D9D9'
                            }}
                        >
                            <div
                                //  key={index}
                                className={`${TaskStyle.selectedFileItem} ${TaskStyle.hoveredDiv2}`}
                                style={{
                                    border: '1px solid #D9D9D9',
                                    backgroundColor: '#F6FAFF',
                                    borderRadius: '8px', display: 'flex', gap: '10px',

                                }}>
                            </div>

                        </div>

                    </Box>
                    <div className={TaskStyle.FileSelect}
                    >
                        {selectedFiles.map((file, index) => (
                            <div
                                key={index} className={`${TaskStyle.selectedFileItem} ${TaskStyle.hoveredDiv}`}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={() => handleMouseLeave()}
                                style={{
                                    borderRadius: '8px',
                                    display: 'flex',
                                    gap: '10px',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    marginBottom: '20px',
                                    backgroundColor: hoveredFileIndex === index ? '#F6F6F6' : '#ffff',
                                }}>
                                <div style={{ display: 'flex', width: '70%', padding: '12px', alignItems: 'center', gap: '10px' }}>
                                    <DescriptionIcon
                                        style={{
                                            color: '#16469D',

                                        }} />
                                    <p
                                        style={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            lineHeight: '13.8px',
                                            color: '#000000'
                                        }}>{truncateFileName(file.name, 30)}</p>

                                    <Button
                                        variant='text'
                                        target='_blank'
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            lineHeight: '13.8px',
                                            color: '#16469D',
                                            textTransform: 'capitalize',
                                        }}
                                        onClick={() => handleFilePreview(file)}
                                    >
                                        Preview
                                    </Button>

                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        padding: '12px',
                                        alignItems: 'center',
                                    }}>
                                    <p style={{
                                        fontWeight: '400',
                                        fontSize: '10px',
                                        lineHeight: '11.5px',
                                        color: '#000000'
                                    }}>{fileSizes[index]}</p>
                                </div>
                                {hoveredFileIndex === index && (
                                    <CancelIcon
                                        className={TaskStyle.hoveredDiv2}
                                        onClick={() => handleRemoveSelectedFile(index)}
                                        style={{
                                            //   position:'absolute',
                                            //   top:'64%',

                                            // color:'#16469D',
                                            //   left:'90%',
                                            //   padding:'0px',
                                            //     height: '30px',
                                            //     width: '50px',

                                            cursor: 'pointer', // Add cursor pointer style
                                            color: '#16469D', // Change color on hover
                                            fontSize: '18px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>

                    <div className={TaskStyle.saveDiscardButtons}>
                        <Button variant="contained"
                            onClick={() => {
                                handleUploadModalClose();
                            }} >
                            Upload files
                        </Button>
                        {/* <Button variant="outlined" onClick={handleUploadModalClose}>
                Discard
            </Button> */}
                    </div>
                </Box>
            </Modal>

            <Modal open={emailModalOpen} onClose={handleEmailModalClose}>
                <Box sx={styleSelectEmails}
                    className={TaskStyle.thirdModal}>
                    <Typography variant="h5" >
                        <PersonIcon sx={{ color: '#6A6A6A' }} /> Add member
                    </Typography>
                    <Typography variant="h6"> Board member</Typography>
                    <div className={TaskStyle.emailSelection}>
                        {/* Display the list of users */}
                        {loadingUsers ? (
                            <p>Loading users...</p>
                        ) : (
                            userList.map((email) => {
                                // console.log(email)
                                return (
                                    <label >
                                        {email}
                                        <input
                                            style={{ width: '20px' }}
                                            type="checkbox"
                                            checked={selectedEmails.includes(email)}
                                            onChange={() => handleEmailCheckboxChange(email)}
                                        />
                                    </label>
                                )
                            })
                        )}

                        {notLoggedIn?.map((email, index) => {
                            return (
                                <label>{email}
                                    <input style={{ width: '105px' }} disabled value={"Never Logged In"}></input>
                                </label>
                            )
                        })}
                    </div>

                </Box>
            </Modal>
        </>
    )
}

export default TaskPannel