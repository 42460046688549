import React, { useEffect } from 'react'
import templeteStyle from './CommercialTempleteRevisionView.module.css'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import TaskStyle from "pages/TechnicalSpecification/TaskTable/TaskDisplay.module.css"
// ==============icons===================
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useState } from 'react';

// ==============icons===================

// ================mui compoents============

import { Typography, Button, Badge } from '@mui/material';
import Modal from '@mui/material/Modal';

// ================mui compoents============

const TempleteQuestionsTab = ({ questionRows, handleInputChange, handleAddQuestionRow, handleQuestionRowDelete }) => {

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteModal = (id) => {

    setDeleteModal(prev => !prev);
    setDeleteId(id)
  }


  return (
    <>
      <div className={templeteStyle.questionsTabMain}>
        <table className={templeteStyle.questionsTable}>
          <thead>
            <tr className={`${templeteStyle.questionsTableRow}`}>

              <th>
                <p></p>
              </th>
              <th>Name</th>
              <th>Unit</th>
              <th>Value</th>
              <th>Remarks</th>
              <th> Action</th>
            </tr>
          </thead>
          <tbody>
            {
              questionRows.map((row, index) => {
                return (
                  <tr className={`${templeteStyle.questionsTableRow}`} key={`question${index}`}>
                    <td>
                      {index + 1}
                    </td>
                    <td>
                      <textarea
                        value={row.name}
                        className={templeteStyle.questionRowTextarea}
                        name="name"
                        onChange={(e) => handleInputChange(e, index)}
                      />

                    </td>
                    <td>
                      <select className={templeteStyle.questionRowSelect} name="unit" id="" value={row.unit} onChange={(e) => handleInputChange(e, index)}>
                        <option value="date">Date</option>
                        <option value="text">Text</option>
                        <option value="select">Select</option>
                        <option value="percentage">Percentage</option>
                      </select>



                    </td>
                    <td>
                      <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "10px" }}>
                        <textarea
                          value={row.expectedAnswer}
                          className={templeteStyle.questionRowTextarea2}
                          name="expectedAnswer"
                          onChange={(e) => handleInputChange(e, index)}
                        />
                      </div>

                      {/* <DynamicInput value={row.value} unit={row.unit} index={index} handleInputChange={handleInputChange}/> */}

                    </td>
                    {/* <td>


                      <DynamicInput value={row.value} unit={row.unit} index={index} handleInputChange={handleInputChange} expectedAnswer={row.expectdAnswer} />

                    </td> */}
                    <td>
                      <textarea
                        value={row.remark}
                        className={templeteStyle.questionRowTextarea}
                        name="remark"
                        onChange={(e) => handleInputChange(e, index)}
                      />
                    </td>
                    <td> <span onClick={() => { handleDeleteModal(row.id) }}>
                      <DeleteOutlineIcon />
                    </span></td>
                  </tr>
                )

              })
            }

          </tbody>
        </table>
        <div
          className={templeteStyle.questiontableFooter}
          onClick={handleAddQuestionRow}
        ><span  ><AddCircleOutlineIcon /></span>Add Question</div>
      </div>

      <Modal
        open={deleteModal}
        onClose={handleDeleteModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', width: '375px' }}>
          <form action="" onSubmit={(e) => {
            e.preventDefault();
            handleQuestionRowDelete(deleteId);
            handleDeleteModal();
          }} style={{ maxWidth: '100%' }} >

            <div className={TaskStyle.modalKam}>
              <Typography variant="h1">Delete Question</Typography>
              <Typography variant="h6">Are you sure you want to delete this Question?</Typography>

              <div className={TaskStyle.kamModalButton}>
                <Button type='submit' variant="contained">Delete</Button>

                <p onClick={handleDeleteModal}>Cancel</p>
              </div>

            </div>

          </form>
        </div>
      </Modal>


    </>
  )
}

export default TempleteQuestionsTab


const DynamicInput = ({ value, unit, index, handleInputChange, expectedAnswer }) => {

  const [selectOptions, setSelectOptions] = useState([]);
  useEffect(() => {
    if (unit === "select") {

      const rr=expectedAnswer?.split(",");
      console.log(expectedAnswer);
      console.log(rr);
      setSelectOptions(rr);
    }
  }, [unit,expectedAnswer])

  switch (unit.toLowerCase()) {
    case "text": return (
      <input className={templeteStyle.questionRowInput} type="text" name='value' value={value} onChange={(e) => handleInputChange(e, index)} />
    )
      break;
    case "select": return (
      <select name="value" id="" className={templeteStyle.questionRowSelect} value={value} onChange={(e) => handleInputChange(e, index)}>
        {
          selectOptions?.map((opt) => {
            return (
              <option value={opt}>{opt}</option>
            )
          })
        }



      </select>

    )
      break;
    case "date": return (
      <input type="date" className={templeteStyle.questionRowInput} name='value' value={value} onChange={(e) => handleInputChange(e, index)} />

    )
      break;
    case "percentage": return (
      <input type="text" name='value' pattern="[0-9]*"
        className={templeteStyle.questionRowInput}
        onKeyPress={(e) => {
          const onlyNumeric = /[0-9]/;
          const key = e.key;
          if (!onlyNumeric.test(key)) {
            e.preventDefault();
          }
        }}
        value={value} onChange={(e) => handleInputChange(e, index)} />

    )
      break;

  }

}
