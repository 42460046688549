import React from 'react'
import { useState, useEffect } from 'react';
import myaxios from 'api/axios'
import { useDispatch } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { setLogin } from 'state/AuthState';
import useBackdrop from 'hooks/useBackdrop'
import { useNavigate } from 'react-router-dom'


const RedirectLogin = () => {

  // Redux dispatch hook
  const dispatch = useDispatch();

  // Custom hook to manage backdrop state
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

  // Navigation hook
  const navigate = useNavigate();

  // State to manage token details
  const [tokenDetails, setTokenDetails] = useState("")

  // State to manage token
  const [token, setToken] = useState("");

  // State to manage redirect URL
  const [redirectURL, setRedirectURL] = useState("");

  // State to manage redirect type
  const [redirectType, setRedirectType] = useState("");


  useEffect(() => {
    openBackdrop();
    // Extract parameters from URL query string
    const params = new URLSearchParams(window.location.search);
    if (params.size > 0 && params.get('token') !== "") {
      let token = params.get('token')
      let redirecturl = params.get('redirecturl')
      let redirectType = params.get('redirectionType')

      // Decode the token to get its details
      const decodedToken = jwt_decode(token);
      setTokenDetails(decodedToken);
      setToken(token);
      setRedirectURL(redirecturl);
      setRedirectType(redirectType);
    }
    else {
      // Redirect to login page if token is not present in query params
      navigate("/login", { replace: true })
    }
  }, [])

  // Function to verify token and handle redirection
  const verifyToken = async () => {
    try {
      let URL = "/procurement/verifyToken"
      if (redirectType === "FRANCHISE") {
        URL = "/procurement/verifyTokenForRedirectFranchise"
      }

      // Verify token by making an API call
      await myaxios.post(URL, { token: token }).then(async (res) => {
        // Get employee details after successful token verification
        await myaxios.get(`/procurement/getEmployeeDetails`, {
          headers: {
            'Authorization': `Bearer ${res.data.accessToken}`,
          }
        }).then((res2) => {
          const logindata = res.data;
          const data = res2.data;
          let workArray = data?.data?.workspaces?.map((work) => {
            switch (work.id) {
              case 1: return "technical-specification";
                break;
              case 2: return "commercial";
                break;
              case 3: return "procurement";
                break;
              case 4: return "approvals";
                break;
            }

          })

          // Dispatch login action with user data
          dispatch(setLogin({
            token: logindata.accessToken,
            name: logindata.name,
            email: logindata.data.email,
            roles: logindata.data.role.name,
            allData: data.data,
            userData: logindata.data,
            companyName: data.data.organisationName,
            workSpaces: workArray,
            orgId: data.data.organisationId,
            orgLogo: data.data.orgLogo,
            mobNum: logindata.data.phone,
            profilePic: data.data.profilePhoto,

          }))
          // Redirect user based on conditions
          if (redirectURL === "" || redirectURL === undefined || redirectURL === null) {
            navigate(`/${data.data.organisationName}`, { state: { data: null }, replace: true });
          }
          else {
            window.location.replace(redirectURL);
          }
        })
      }).catch((error) => {
        // Redirect to login page on error
        navigate("/login", { replace: true })
      })
    } catch (error) {
      // Redirect to login page on error
      navigate("/login", { replace: true })
    }
  }

  useEffect(() => {
    if (token !== "") {
      verifyToken();
     // Call verifyToken function when token is available
    }
  }, [token])

  return (
    // Render backdrop component
    <BackdropComponent />
  )
}

export default RedirectLogin
