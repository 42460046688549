import React from 'react'
import TaskStyle from 'pages/CreateTask/TaskPannel/TaskPannel.module.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GroupsIcon from '@mui/icons-material/Groups';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import EditChatPanel from '../EditChatPanel/EditChatPanel';
import { PrivateComponent } from 'api/axios';
import { useRef } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import useAuth from 'hooks/useAuth';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { Navigate, useNavigate } from 'react-router';
import useBackdrop from "hooks/useBackdrop";
import CancelIcon from '@mui/icons-material/Cancel';
import DescriptionIcon from '@mui/icons-material/Description';
import { useParams } from 'react-router-dom';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import FetchUsersComponent from 'components/FetchUsersComponent/FetchUsersComponent'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useToast from 'hooks/useToast';
import OptionSelector from 'components/TaskComponents/OptionSelector/OptionSelector';
import PrioritySelector from 'components/TaskComponents/PrioritySelector/PrioritySelector';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',

    zIndex: '144'
};

const styleUploadFiles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    p: 2,

};

const styleSelectEmails = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    p: 2,
};


function EditTaskPannel({ getAllTasks, handleEditTaskModal, editData, setEditData, isOpen, onClose }) {

    // Importing necessary hooks and components
    const { client } = useParams(); // Extracting client from URL parameters
    const { showToast, Toast } = useToast(); // Toast notification utilities
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Backdrop utilities
    const [formError, setFormError] = useState({}); // State for form errors
    const [taskId, setTaskId] = useState(''); // State for task ID
    const { auth } = useAuth(); // Authentication context
    const navigate = useNavigate(); // Navigation utility

    // Toggling email modal
    const toggleEmailModal = () => {
        setEmailModalOpen(!emailModalOpen);
    };

    const [data, setData] = useState([]); // State for task data
    const privateAxios = PrivateComponent(); // Axios instance for private requests

    // State and handlers for file uploads
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(true);
    const [selectedFiles, setSelectedFiles] = useState([]); // State for selected files
    const [originalUploadedFiles, setOriginalUploadedFiles] = useState([]); // State for original uploaded files

    const inputRef = useRef(null); // Reference to file input element
    const [loadingUsers, setLoadingUsers] = useState(false); // State for loading users

    // Function to handle attached document
    const handleAttachedDocument = async (doc) => {

        try {
            const response = await privateAxios.get(`/task/file/${doc.documentNewName}`, {
                responseType: "blob" // Request response as a Blob
            });
            const file = new File([response.data], doc.documentName, { type: response.headers["content-type"] });
            return file;
        } catch (err) {
            // console.log(err);
            return null;
        }

    };


    useEffect(() => {
        // Set data and task ID on editData change
        setData(editData);
        setTaskId(editData.taskId);
        // Fetch and set attached documents
        if (editData?.attachmentDocuments && editData.attachmentDocuments.length > 0) {
            const getDocuemnts = async () => {
                const promises = editData.attachmentDocuments.map((doc) => handleAttachedDocument(doc));
                openBackdrop(); // Open backdrop while fetching documents
                await Promise.all(promises)
                    .then((files) => {
                        setSelectedFiles(files.filter((file) => file !== null));
                        setOriginalUploadedFiles(files.filter((file) => file !== null));
                    })
                    .catch((error) => {
                        // console.log(error);
                        showToast("error", "Error fetching documents") // Show error toast if fetching fails
                    });
                closeBackdrop();// Close backdrop after fetching
            }
            getDocuemnts();

        }

        // Set selected emails if available
        if (editData) {
            setSelectedEmails(editData.assignTo.map(per => per.email));
            setOriginalSelectedEmails(editData.assignTo.map(per => per.email));
        }

    }, [editData]);


    // State and handlers for email modal and file upload modal
    const [uploadModalOpen, setUploadModalOpen] = useState(false); // State for upload modal
    const [selectedSubCategoryOptions, setSelectedSubCategoryOptions] = useState([]); // State for selected sub-category options


    const [selectedCategory, setSelectedCategory] = useState(''); // State for selected category
    const [dynamicRows, setDynamicRows] = useState([]); // State for dynamic rows
    const [selectedSubCategory, setSelectedSubCategory] = useState(''); // State for selected sub-category
    const [emailModalOpen, setEmailModalOpen] = useState(false); // State for email modal

    const [selectedCategoryBreadcrumbs, setSelectedCategoryBreadcrumbs] = useState(''); // State for selected category breadcrumbs

    const [kamId, setKamId] = useState(''); // State for KAM ID
    const [selectedEmails, setSelectedEmails] = useState([]); // State for selected emails
    const [originalSelectedEmails, setOriginalSelectedEmails] = useState([]); // State for original selected emails

    const [selectedEmailsDisplay, setSelectedEmailsDisplay] = useState([]);// State for selected emails display

    // Create a state variable to store file sizes
    const [fileSizes, setFileSizes] = useState([]);

    const [selectedFileNames, setSelectedFileNames] = useState([]); // State to store selected file names
    const workspaceId = 1;
    const [userList, allEmails, notLoggedIn] = FetchUsersComponent({ orgId: auth.orgId, workspaceId: workspaceId }) // Fetch users component

    // Function to format file size
    const formatFileSize = (size) => {
        if (size < 1024) {
            return size + ' B';
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(2) + ' KB';
        } else if (size < 1024 * 1024 * 1024) {
            return (size / (1024 * 1024)).toFixed(2) + ' MB';
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        }
    };

    // Handler to close the upload modal
    const handleUploadModalClose = () => {
        setUploadModalOpen(false);
    };

    // Handler for email checkbox change
    const handleEmailCheckboxChange = (email) => {
        const isSelected = selectedEmails.includes(email);

        if (isSelected) {

            setSelectedEmails(selectedEmails.filter((e) => e !== email));
        } else {

            setSelectedEmails([...selectedEmails, email]);
        }
    };

    const handleRemoveSelectedFile = (index) => {
        const updatedSelectedFiles = [...selectedFiles];
        updatedSelectedFiles.splice(index, 1);
        setSelectedFiles(updatedSelectedFiles);
    };

    // Handler to open the email modal
    const handleEmailModalOpen = () => {
        setSelectedEmailsDisplay(selectedEmails); // Store selected emails for display
        setEmailModalOpen(true); // Open the modal
    };

    // Handler to close the email modal
    const handleEmailModalClose = () => {
        setEmailModalOpen(false);
    };
    const [fileInputKey, setFileInputKey] = useState(0); // Add a key to reset the file input
    const allowedExtensions = ['.jpg', '.jpeg', '.pdf', '.xls', '.xlsx', '.docx'];

    const handleUploadModalOpen = () => {
        // Reset the file input by changing the key
        setFileInputKey((prevKey) => prevKey + 1);
        setUploadModalOpen(true);
    };

    // Handler for file input change
    const handleFileInputChange = (e) => {
        const newFiles = e.target.files || e.dataTransfer.files;

        for (const file of newFiles) {
            const fileExtension = getFileExtension(file.name).toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                // Show an alert for unsupported files
                alert(`Unsupported file type: ${file.name}`);
            } else {
                // Append the valid file to the existing selectedFiles array
                setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, file]);

                // Get the file size and update the state
                const fileSize = formatFileSize(file.size);
                setFileSizes((prevFileSizes) => [...prevFileSizes, fileSize]);

                const fileName = file.name;
                setSelectedFileNames((prevFileNames) => [...prevFileNames, fileName]);
            }
        }
    };

    // Handler to remove a file
    const handleRemoveFile = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    };

    // Handler to truncate file name
    const removeSelectedFile = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    };

    function truncateFileName(fileName, maxLength) {
        if (typeof fileName !== 'string' || fileName.length === 0) {
            return ''; // Return an empty string or handle this case as needed
        }

        if (fileName.length <= maxLength) {
            return fileName;
        }

        const truncatedFileName = fileName.substring(0, maxLength - 5) + '...';
        return truncatedFileName;
    }

    // Function to get file extension from file name
    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        return parts.length > 1 ? `.${parts.pop()}` : '';
    };

    // Categories and subcategories options
    const categoryOptions = ['ironMaking', 'equipment'];
    const subcategories = {
        ironMaking: ['GranShort', 'HotMaterial'],
        equipment: ['item1', 'item2'],
    };
    const subcategories1 = {
        GranShort: ['Gran1', 'Gran2'],
        HotMaterial: ['Hot1', 'Hot2'],
        item1: ['item11', 'item11'],
        item2: ['item22', 'item222'],
    };

    const [productSelected, setProductSelected] = useState(""); // State for selected product

    // Effect to update subcategory options based on selected category
    useEffect(() => {
        if (selectedCategory) {
            // Set the default sub-category based on the selected category
            setSelectedSubCategory(subcategories[selectedCategory]?.[0] || '');

            // Set dynamicRows to the subcategories of the selected category
            setDynamicRows(subcategories[selectedCategory] || []);
            setSelectedSubCategoryOptions([]); // Clear previous options when changing subcategories

            setSelectedCategoryBreadcrumbs(selectedCategory); // Update breadcrumbs when a category is selected
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (selectedSubCategory) {
            setSelectedSubCategoryOptions(subcategories1[selectedSubCategory] || []);
        } else {
            setSelectedSubCategoryOptions([]);
        }
    }, [selectedSubCategory]);



    // Document upload state and handlers
    const [files, setFiles] = useState(null); // State for uploaded files
    const [progress, setProgress] = useState({ started: false, pc: 0 }); // State for progress
    const [msg, setMsg] = useState(null); // State for message

    // Handler to save documents
    const handleSaveDoc = async () => {
        try {
            // const taskId = response.data.taskId;
            setTaskId(taskId);
            if (files !== null) {
                const fd = new FormData();
                for (let i = 0; i < files.length; i++) {
                    fd.append(`files`, files[i]);
                }

                await privateAxios.post(`/task/uploadTaskAttachments/${data.taskId}`, fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }).then((res) => {
                    if (!res.ok) {
                        throw new Error("Bad response");
                    }
                    setMsg("Upload Successfully");
                    return res.json();
                }).catch((err) => {
                    setMsg("file Upload Failed");
                    console.error(err);
                });

            }
        } catch (error) {

        }
    }


    // Function to validate input fields before updating task
    const handleInputValidation = () => {
        // Check if any value in data object is empty
        const isEmpty = Object.values(data).some(dd => dd === '');
        // Check if all required fields are filled
        if (data.title !== '' && data.description !== '' && data.priority !== '' && data.status !== '' && data.startDate !== '' && data.endDate !== '' && selectedEmails.length !== 0) {
            // If all fields are filled, reset form errors and return true
            setFormError({});
            // console.log("error free")
            return true;
        } else {
            // If any field is empty, set form errors and display alerts
            setFormError({});
            if (data.title === '') {
                alert('Please Enter title');
                setFormError(prev => ({
                    ...prev,
                    title: "Please Enter Title"
                }));
            }
            else if (data.description === '') {
                alert('Please Enter Description');
                setFormError(prev => ({
                    ...prev,
                    description: "Please Add Description"
                }));
            }
            else if (data.startDate === '') {
                alert('Please select start date');
                setFormError(prev => ({
                    ...prev,
                    startDate: "Please select start date"
                }));
            }
            else if (data.endDate === '') {
                alert('Please select end date');
                setFormError(prev => ({
                    ...prev,
                    endDate: "Please select end date"
                }));
            } else if (data.priority === '') {
                alert('Please select priority');
                setFormError(prev => ({
                    ...prev,
                    priority: "Please select priority"
                }));
            } else if (selectedEmails.length === 0) {
                alert('Please select email');
                setFormError(prev => ({
                    ...prev,
                    emailSelected: "Please select Atleast One Person"
                }));
            }
            // Return false as validation failed
            return false;
        }
    };


    const reAssignUsersToTask = async () => {
        try {
            const taskId = data.taskId;
            const response = await privateAxios.post(`/task/reAssignUsersToTask/${taskId}`, {
                emails: selectedEmails,
            });
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            return false;
        }
    };

    const handleAttachedDeleteDocument = async (doc) => {

        await privateAxios
            .delete(`/task/deleteDoc/${data.taskId}/${doc.taskDocumentId}`)
            .then((res) => {
                if (res.status === 200) {
                    // closeBackdrop();
                    // console.log(`${doc.taskDocumentId} taskId deleted`)
                }
            })
            .catch((error) => {
                console.error('Error deleting document:', error);
            });
    }

    const handleDeleteDocument = async (attachmentDocuments) => {
        const promises = attachmentDocuments.map((doc) => handleAttachedDeleteDocument(doc));
        // Wait for all promises to resolve
        Promise.all(promises)
            .catch((error) => {
                // console.log(error);
            });
    };

    function arraysAreEqual(array1, array2) {
        if (array1.length !== array2.length) {
            return false;
        }

        for (let i = 0; i < array1.length; i++) {
            if (array1[i] !== array2[i]) {
                return false;
            }
        }

        return true;
    }


    // Function to update task details
    const handleUpdateTask = async () => {
        openBackdrop()

        // Check if input validation passes
        if (handleInputValidation()) {
            try {
                // Define the payload with updated task data
                const payload = {
                    title: data.title,
                    description: data.description,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    userExpectedCompletionDate: data.userExpectedCompletionDate === "" ? "" : data.userExpectedCompletionDate,
                    completedDate: data.completedDate === " " ? "" : data.completedDate,
                    status: data.status,
                    priority: data.priority,
                };

                const payloadToSend = [];

                // Check for changes in selected files
                if (selectedFiles && originalUploadedFiles) {
                    // Extract file names from selected and original files
                    const payloadFiles = selectedFiles.map((file) => file.name);
                    const originalFiles = originalUploadedFiles.map((file) => file.name);

                    // Identify added and deleted files
                    const addedFiles = payloadFiles.filter(file => !originalFiles.includes(file));
                    const deletedFiles = originalFiles.filter(file => !payloadFiles.includes(file));

                    let previousLogs = '';
                    let newLogs = '';

                    // Check if file changes exist
                    if (!arraysAreEqual(payloadFiles, originalFiles)) {
                        if (addedFiles.length > 0 && deletedFiles.length === 0) {
                            previousLogs += `File${addedFiles.length > 1 ? 's' : ''} ${addedFiles.join(', ')} ${addedFiles.length > 1 ? 'were' : 'was'} not present. `;
                            newLogs += `File${addedFiles.length > 1 ? 's' : ''} ${addedFiles.join(', ')} ${addedFiles.length > 1 ? 'were' : 'was'} added. `;
                        } else if (deletedFiles.length > 0 && addedFiles.length === 0) {
                            previousLogs += `File${deletedFiles.length > 1 ? 's' : ''} ${deletedFiles.join(', ')} ${deletedFiles.length > 1 ? 'were' : 'was'} present. `;
                            newLogs += `File${deletedFiles.length > 1 ? 's' : ''} ${deletedFiles.join(', ')} ${deletedFiles.length > 1 ? 'were' : 'was'} removed. `;
                        } else {
                            // alert("is here")
                            previousLogs += `File${deletedFiles.length > 1 ? 's' : ''} ${deletedFiles.join(', ')} ${deletedFiles.length > 1 ? 'were' : 'was'} present but removed now. `;
                            newLogs += `File${addedFiles.length > 1 ? 's' : ''} ${addedFiles.join(', ')} ${addedFiles.length > 1 ? 'were' : 'was'} added. `;
                        }

                        // Push file change logs to payload
                        payloadToSend.push({
                            name: auth.name,
                            logDetails: `${auth.name} updated the files`,
                            previousLogs,
                            newLogs,
                        });
                    }
                }

                // Check for changes in selected emails
                if (selectedEmails && originalSelectedEmails) {

                    // Identify added and deleted emails
                    const addedEmails = selectedEmails.filter(email => !originalSelectedEmails.includes(email));
                    const deletedEmails = originalSelectedEmails.filter(email => !selectedEmails.includes(email));

                    let previousLogs = '';
                    let newLogs = '';

                    // Check if email changes exist
                    if (!arraysAreEqual(selectedEmails, originalSelectedEmails)) {
                        if (addedEmails.length > 0 && deletedEmails.length === 0) {
                            previousLogs += `Email${addedEmails.length > 1 ? 's' : ''} ${addedEmails.join(', ')} ${addedEmails.length > 1 ? 'were' : 'was'} not present. `;
                            newLogs += `Email${addedEmails.length > 1 ? 's' : ''} ${addedEmails.join(', ')} ${addedEmails.length > 1 ? 'were' : 'was'} added. `;
                        } else if (deletedEmails.length > 0 && addedEmails.length === 0) {
                            previousLogs += `Email${deletedEmails.length > 1 ? 's' : ''} ${deletedEmails.join(', ')} ${deletedEmails.length > 1 ? 'were' : 'was'} present. `;
                            newLogs += `Email${deletedEmails.length > 1 ? 's' : ''} ${deletedEmails.join(', ')} ${deletedEmails.length > 1 ? 'were' : 'was'} removed. `;
                        } else {
                            previousLogs += `Email${deletedEmails.length > 1 ? 's' : ''} ${deletedEmails.join(', ')} ${deletedEmails.length > 1 ? 'were' : 'was'} present but removed now. `;
                            newLogs += `Email${addedEmails.length > 1 ? 's' : ''} ${addedEmails.join(', ')} ${addedEmails.length > 1 ? 'were' : 'was'} added. `;
                        }

                        payloadToSend.push({
                            name: auth.name,
                            logDetails: `${auth.name} updated the emails`,
                            previousLogs,
                            newLogs,
                        });
                    }
                }

                // Loop through the payload object to compare each key-value pair with editData
                for (const key in payload) {
                    // Check if the key exists in payload and editData, and if their values are different
                    if (payload.hasOwnProperty(key) && editData.hasOwnProperty(key) && payload[key] !== editData[key]) {
                        // If the values are different, push a log object to payloadToSend
                        payloadToSend.push({
                            name: auth.name,
                            logDetails: `${auth.name} updated the ${key}`,
                            previousLogs: editData[key],
                            newLogs: payload[key],
                        });
                    }
                }

                // Send a POST request to update the task with the new payload
                const response = await privateAxios.post(`/task/editTasks/${data.taskId}/${auth.orgId}`, payload).then(async (res) => {

                    if (res.status === 200) {
                        // If the task is successfully updated
                        const reAssignUserRes = await reAssignUsersToTask();
   // Reassign users to the task if necessary
                        if (reAssignUserRes) {
                           // Handle success or error
                        }
                        else {
                            // console.log("Error User Reassigned Success")
                        }

                           // Delete any attachment documents if present
                        if (data.attachmentDocuments.length > 0) {

                            await handleDeleteDocument(data.attachmentDocuments);
                        }
  // Upload selected files if present
                        if (selectedFiles.length > 0) {
                            const fd = new FormData();
                            for (let i = 0; i < selectedFiles.length; i++) {
                                fd.append(`files`, selectedFiles[i]);
                            }

                            // setMsg("uploading...");

    // Log the changes in the task history
                            await privateAxios.post(`/task/uploadTaskAttachments/${data.taskId}`, fd, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                }
                            }).catch((err) => {
                                alert("error uploading Files")
                                console.error(err);
                            });
                        }
                        await privateAxios.post(`/task/getTaskHistory/${data.taskId}`, payloadToSend).then((res) => {
                        })
                    }
                })

                // Refresh the list of all tasks and close the modal
                getAllTasks();
                closeBackdrop();
                handleEditTaskModal();

            } catch (error) {
                // Handle error while updating task
                handleEditTaskModal();
                closeBackdrop();
                console.error('Error updating task:', error);
            }

        }
        else {
            // If input validation fails, close the modal
            handleEditTaskModal();
            closeBackdrop();
        }
    };

    // State for storing documents
    const [documents, setDocuments] = useState([]);

// Fetch documents when data.taskId changes
    useEffect(() => {
        const fetchDocuments = async () => {
            // openBackdrop();
            try {
                if (data.taskId) {
                    const response = await privateAxios.get(`/task/getAllDocuments/${data.taskId}`);
                    if (response.status === 200) {
                        closeBackdrop();
                        // console.log("doccc", response)
                        setDocuments(response.data); // Initialize with an empty array if documents are undefined
                    }
                }
            } catch (error) {
                console.error('Error fetching documents:', error);
                setDocuments([]); // Handle the error by initializing with an empty array
            }
        };

        // Call the fetchDocuments function when data.taskId changes
        fetchDocuments();
    }, [data?.taskId]);


    useEffect(() => {
        if (data.status === 'DONE') {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;

            // Check if the status has changed to "Done"
            if (data.status !== 'DONE') {
                // Add the history message only when the status is changed to "Done"
                const historyMessage = 'userExpectedCompletionDate was changed';
                // Add this history message to the history array
                const updatedHistory = [...data.history, historyMessage];
                // Update the state with the new history
                setData({ ...data, history: updatedHistory });
            }

            // Update the E/C Date when the status is "Done"
            setData({ ...data, userExpectedCompletionDate: formattedDate });
        } else if (data.status === 'INPROGRESS') {
            // Reset the E/C Date when the status is "In Progress"
            setData({ ...data, userExpectedCompletionDate: null });
        }
    }, [data.status]);


    // Event handlers for priority, status, start date, end date, and E/C date changes
    const handlePriorityChange = (event) => {
        setData({ ...data, priority: event.target.value });
    };
    const handleStatusChange = (event) => {
        setData({ ...data, status: event.target.value });
    };
    const [selectedStartDate, setSelectedStartDate] = useState(data.startDate);

    const handleStartDateChange = (event) => {
        const selectedStartDate = event.target.value;
        setSelectedStartDate(selectedStartDate);

        if (selectedStartDate >= currentDate) {
            const newEndDate = new Date(selectedStartDate);
            newEndDate.setDate(newEndDate.getDate() + 1); // Set end date to one day after the start date

            setData({
                ...data,
                startDate: selectedStartDate,
                endDate: newEndDate.toISOString().slice(0, 10),
            });
        } else {
            // You can show an error message or perform another action to inform the user
            console.log("You cannot select a past date for the start date.");
        }
    };
    const currentDate = new Date().toISOString().slice(0, 10); // Get the current date in YYYY-MM-DD format
    const handleEndDateChange = (event) => {
        const selectedEndDate = event.target.value;
        const currentDate = new Date().toISOString().slice(0, 10); // Get the current date in YYYY-MM-DD format

        if (selectedEndDate >= currentDate) {
            setData({ ...data, endDate: selectedEndDate });
        } else {
            // You can show an error message or perform another action to inform the user
            console.log("You cannot select a past date for the end date.");
        }
    };
    const handleEcDateChange = (event) => {
        // setData({ ...data, assignTo: event.target.value });
    };

// Function to navigate to a specific page
    const handleCTA = async (productId, taskId) => {
        navigate(`/${client}/technical-specification/${taskId}/${productId}`)

    }


    // ----------------------------Date----------------------------


    // Function to format date string
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return date.toLocaleDateString(undefined, options);
    };

    // ----------------------------Date----------------------------

// State for managing file preview
    const [openFileUrl, setOpenFileUrl] = useState('');

    const handleFileClick = (document) => {
        fetch(`${process.env.REACT_APP_BASEURL}/task/file/${document.documentNewName}`)
            .then((res) => {
                if (res.ok) {
                    return res.blob();
                } else {
                    console.error('Failed to fetch file:', res.statusText);
                    throw new Error('Failed to fetch file');
                }
            })
            .then((fileContent) => {
                const fileUrl = URL.createObjectURL(fileContent);
                setOpenFileUrl(fileUrl);
            })
            .catch((error) => {
                console.error('Error opening file:', error);
            });
    };





// State and event handlers for file preview
    const [hoveredFileIndex, setHoveredFileIndex] = useState(null);
    // Event handler for onMouseEnter
    const handleMouseEnter = (index) => {
        setHoveredFileIndex(index);
    };

    // Event handler for onMouseLeave
    const handleMouseLeave = () => {
        setHoveredFileIndex(null);
    };

    // console.log(data);

    const handleFilePreview = (file) => {
        console.log(file)
        const fileURL = URL.createObjectURL(file);
        console.log(fileURL)
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = file.name;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(a);
    }


    return (
        <>
            <BackdropComponent />
            <Toast />
            <div className={TaskStyle.taskModalWrapper} >

                <div className={TaskStyle.taskModal}>

                    <div className={TaskStyle.taskInfoWrapper} >


                        <div className={TaskStyle.taskInfo}>

                            <div className={TaskStyle.taskNav}>
                                {auth.roles === "KAM" ?
                                    <h1 className={TaskStyle.createTask}>Edit a task</h1>
                                    :
                                    <h1 className={TaskStyle.createTask}>{data?.title}</h1>
                                }
                            </div>



                            <div className={TaskStyle.taskHeading}>
                                {auth.roles === "KAM" ? (
                                    <input
                                        value={data?.title}
                                        onChange={(e) => setData({ ...data, title: e.target.value })}
                                        // disabled={auth.roles === "USER" ? true : false}
                                        disabled={auth.roles === "USER" ? true : false}
                                    />
                                ) : null}
                                <textarea value={data?.description}
                                    placeholder='description'
                                    disabled={auth.roles === "USER" ? true : false}
                                    onChange={(e) => setData({ ...data, description: e.target.value })}
                                />
                            </div>

                            <div className={TaskStyle.taskDetailsWrapper}>

                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Start Date
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <input
                                            disabled={auth.roles !== "KAM" ? true : false}
                                            type="date"
                                            value={data.startDate}
                                            // min={new Date().toISOString().slice(0, 10)}
                                            onChange={handleStartDateChange}
                                        />
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            End Date
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <input
                                            type="date"
                                            value={data.endDate}
                                            onChange={handleEndDateChange}
                                            disabled={auth.roles !== "KAM" ? true : false}
                                            min={data.startDate} // Set the minimum date for the end date
                                        />
                                    </div>


                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            E/C date
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <input
                                            disabled
                                            type="date"
                                            value={data.userExpectedCompletionDate}
                                            onChange={handleEcDateChange}
                                        />
                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <DateRangeIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Status
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <OptionSelector
                                            value={data.status}
                                            // disabled={auth.roles !== "KAM" ? true : false}
                                            onChange={handleStatusChange}
                                            name="status"
                                            // inputStyleProps={}
                                            // optionStyleProps={}
                                            options={[
                                                {
                                                    value: "TODO",
                                                    color: "#000",
                                                    //  backgroundColor: "#ec5252",
                                                    label: "To-Do",
                                                },
                                                {
                                                    value: "INPROGRESS",
                                                    color: "#000",
                                                    //  backgroundColor: "#ec5252",
                                                    label: "In-Progress",
                                                },
                                                {
                                                    value: "DONE",
                                                    color: "#000",
                                                    //  backgroundColor: "#f5ac38",
                                                    label: "Done",
                                                },
                                                {
                                                    value: "COMPLETED",
                                                    color: "#000",
                                                    //  backgroundColor: "#b3f289",
                                                    label: "Completed",
                                                },

                                            ]}

                                        />

                                    </div>



                                </div>

                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <DateRangeIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Priority
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <PrioritySelector
                                            value={data.priority}
                                            onChange={handlePriorityChange}
                                            name="priortiy"
                                            disabled={auth.roles !== "KAM" ? true : false}
                                            // inputStyleProps={}
                                            // optionStyleProps={}
                                            options={[
                                                {
                                                    value: "HIGH",
                                                    color: "#fff",
                                                    backgroundColor: "#ec5252",
                                                    label: "High",
                                                },
                                                {
                                                    value: "MEDIUM",
                                                    color: "#fff",
                                                    backgroundColor: "#f5ac38",
                                                    label: "Medium",
                                                },
                                                {
                                                    value: "LOW",
                                                    color: "#fff",
                                                    backgroundColor: "#b3f289",
                                                    label: "Low",
                                                },

                                            ]}
                                        />



                                    </div>



                                </div>

                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <GroupsIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Assignee
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <p>
                                            {data.kamName}
                                        </p>
                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol} style={{ height: "100%" }} >
                                        <span>
                                            <AttachFileIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Attachment
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol} style={{ height: "auto", padding: "4px 12px" }}>
                                        <div className={TaskStyle.taskFileWrapper}>

                                            {selectedFiles.map((file, index) => (
                                                <div key={index} className={TaskStyle.selectedFileItem}
                                                >
                                                    <p
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleFilePreview(file)}
                                                    >

                                                        {truncateFileName(file.name, 15)}

                                                    </p>
                                                    <span onClick={() => handleRemoveSelectedFile(index)} style={{ textDecoration: 'none', border: 'none' }}>
                                                        <CloseIcon

                                                        />
                                                    </span>
                                                </div>
                                            ))}

                                            <button className={TaskStyle.uploadButton}
                                                onClick={() => { handleUploadModalOpen() }}
                                            >Upload Files</button>

                                        </div>
                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol} >
                                        <span>
                                            <GroupsIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Assigned to
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>

                                        {Array.isArray(selectedEmails) && selectedEmails.length > 0 && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {selectedEmails.map((email, index) => (
                                                    <div key={email} style={{ display: 'flex', alignItems: 'center' }}>
                                                        {index !== 0 && <div style={{ fontSize: '12px', padding: '0px 5px' }}></div>}
                                                        <div
                                                        >
                                                            <ProfileIcon data={{ email: email }} height={"26px"} width={"26px"} showProfileBanner={true} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {
                                            (auth.roles === "KAM" || auth.roles === "ADMIN") &&
                                            <AddIcon onClick={handleEmailModalOpen} sx={{ color: '#6A6A6A' }} />
                                        }
                                    </div>



                                </div>


                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}  >
                                        <span>
                                            <GroupsIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Assign
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol} >
                                        <p>

                                            {`${data.assign}`}
                                        </p>

                                    </div>



                                </div>

                            </div>

                        </div>
                        <div style={{ display: "flex", height: "40px", gap: "20px" }}>
                            {auth.roles === "KAM" ?
                                <button
                                    style={{
                                        backgroundColor: '#16469D',
                                    }}
                                    className={TaskStyle.taskButton}
                                    onClick={handleUpdateTask}
                                >Save Changes</button>
                                :
                                <button
                                    style={{
                                        backgroundColor: '#16469D',
                                    }}
                                    className={TaskStyle.taskButton}
                                    onClick={() => { handleCTA(data.taskProductId, data.taskId) }}

                                >Create TS</button>
                            }
                            {auth.roles === "USER" &&
                                <button variant='text'

                                    onClick={handleUpdateTask}
                                    style={{
                                        color: '#16469D',
                                        fontWeight: "500",
                                        backgroundColor: "transparent",
                                        fontSize: '14px',
                                        border: "none",
                                        padding: "8px 10px",
                                        cursor: "pointer"
                                    }}>Save</button>
                            }
                        </div>

                        {/* *************************************Upload Files Modal ******************************/}

                    </div>


                    <EditChatPanel taskId={data?.taskId} data={data} handleEditTaskModal={handleEditTaskModal} />



                </div>
            </div>

            <Modal open={uploadModalOpen} onClose={handleUploadModalClose}>
                <Box sx={styleUploadFiles}
                    className={TaskStyle.uploadFilesModal}
                    style={{
                        borderRadius: '6px',

                    }}
                >
                    <div style={{
                        display: 'flex', justifyContent: 'space-between'
                    }}>
                        <Typography variant="h5" component="h2">
                            Upload Files
                        </Typography>
                        <CancelIcon

                            onClick={handleUploadModalClose}
                        />
                    </div>

                    <Box className={TaskStyle.dragAndDrop}>
                        <Typography variant="subtitle1"  >
                            Drag and drop files here
                        </Typography>
                        <Button variant="contained" onClick={() => inputRef.current.click()}
                            sx={{ textTransform: 'capitalize' }}>
                            Upload
                            <input

                                multiple // Allow multiple file selection
                                ref={inputRef}
                                type='file'
                                hidden
                                // onChange={(e) => {
                                //     setFiles(e.target.files)
                                // }}
                                accept={allowedExtensions.join(',')}
                                onChange={handleFileInputChange}
                            />

                        </Button>

                        <div className={TaskStyle.supportedFileTypes}>
                            <Typography variant="body2" color="textSecondary" >
                                {allowedExtensions.join(', ')}
                            </Typography>
                        </div>
                    </Box>

                    {/* {selectedFileNames.map((fileName, index) => ( */}
                    <div className={TaskStyle.FileSelect}
                    >
                        {selectedFiles.map((file, index) => (
                            <div
                                key={index} className={`${TaskStyle.selectedFileItem} ${TaskStyle.hoveredDiv}`}
                                // onMouseEnter={() => handleMouseEnter(index)}
                                // onMouseLeave={() => handleMouseLeave()}
                                style={{
                                    borderRadius: '8px',
                                    display: 'flex',
                                    gap: '10px',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    marginBottom: '20px',
                                    backgroundColor: hoveredFileIndex === index ? '#F6F6F6' : '#ffff',
                                }}>
                                <div style={{ display: 'flex', width: '70%', padding: '12px', alignItems: 'center', gap: '10px' }}>
                                    <DescriptionIcon
                                        style={{
                                            color: '#16469D',

                                        }} />
                                    <p
                                        style={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            lineHeight: '13.8px',
                                            color: '#000000'
                                        }}>{truncateFileName(file?.name, 30)}
                                        {/* {getFileExtension(file?.name)} */}
                                    </p>

                                    <Button
                                        variant='text'
                                        // target='_blank'
                                        onClick={() => handleFilePreview(file)}
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            lineHeight: '13.8px',
                                            color: '#16469D',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        Preview
                                    </Button>

                                </div>

                                <HighlightOffIcon
                                    onClick={(index) => handleRemoveSelectedFile(index)}
                                    style={{
                                        height: 'none',
                                        width: '20px',
                                        backgroundColor: '#F6FAFF',
                                    }}
                                />
                            </div>
                        ))}

                    </div>
                    {/* ))} */}
                    <div className={TaskStyle.saveDiscardButtons}>
                        <Button variant="contained"
                            onClick={() => {
                                handleUploadModalClose();
                            }} >
                            Upload files
                        </Button>

                    </div>

                </Box>
            </Modal>
            <Modal open={emailModalOpen} onClose={handleEmailModalClose}>
                <Box sx={styleSelectEmails}
                    className={TaskStyle.thirdModal}>
                    <Typography variant="h5" >
                        <PersonIcon sx={{ color: '#6A6A6A' }} /> Add member
                    </Typography>
                    <Typography variant="h6"> Board member</Typography>
                    <div className={TaskStyle.emailSelection}>
                        {/* Display the list of users */}
                        {loadingUsers ? (
                            <p>Loading users...</p>
                        ) : (
                            userList.map((user, index) => {
                                // console.log(user)
                                return (
                                    <label key={index}>
                                        {user}  <input
                                            type="checkbox" style={{ width: '20px' }}
                                            checked={selectedEmails.includes(user)}
                                            onChange={() => handleEmailCheckboxChange(user)}
                                        />
                                    </label>
                                )
                            })
                        )}

                        {notLoggedIn?.map((email, index) => {
                            return (
                                <label>{email}
                                    <input style={{ width: '90px' }} disabled value={"Not Logged In"}></input>
                                </label>
                            )
                        })}
                    </div>

                </Box>
            </Modal>

        </>
    )
}

export default EditTaskPannel

