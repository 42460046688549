import { useState, useEffect, useRef } from 'react';// Import necessary hooks from React
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import { PrivateComponent } from 'api/axios'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Tooltip from '@mui/material/Tooltip';
import tableStyle from '../../components/ApprovalRevisionTable/ApprovalRevisionTable.module.css'
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DateFormater from 'utility/DateFormater';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import VendorApprovastyle from "../Approvals/VendorApproval.module.css"
import { HelpOutlineOutlined, TableRows } from '@mui/icons-material';


const QuotesSent = () => {
    const { auth } = useAuth(); // Get authentication info from custom hook
    const privateAxios = PrivateComponent(); // Get Axios instance
    const [searchValue, setSearchValue] = useState(""); // Define state for search value
    const navigate = useNavigate(); // Get navigate function from useNavigate
    const [userDropDownOpen, setUserDropDownOpen] = useState(false); // Define state for dropdown toggle
    const [assignSelected, setAssignSelected] = useState(""); // Define state for selected assignment filter
    const [userSorterList, setUserSorterList] = useState([]); // Define state for user sorter list
    const [tableRows, setTableRows] = useState([]); // Define state for table rows
    const [revisionMore, setRevisionMore] = useState(""); // Define state for revision more toggle
    
    
    const getData = async () => { // Define async function to fetch data
        try {
            await privateAxios.get(`/technicalSpecification/getAllRevisionsForApprovalOfVendor/${auth.userData.id}`).then((res) => {
               // Fetch data from API and set table rows
                setTableRows(res.data);
            })
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {// useEffect to fetch data on component mount
        getData();
    }, [])

    const handleNavigate = () => {

    }

    const handleToggle = (id) => { // Define handleToggle function for revision more
        if (revisionMore === id) {
            setRevisionMore("")
        }
        else {
            setRevisionMore(id);
        }
    }


    const handleLastSeen = (loginTime) => {// Define handleLastSeen function
        const loginDate = new Date(loginTime); // Parse login time to date
        const currentDate = new Date();// Get current date
        const timeDifference = currentDate - loginDate;// Calculate time difference


        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60)); // Calculate hours difference
        const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60); // Calculate minutes difference
        const daysDifference = Math.floor(hoursDifference / 24); // Calculate days difference


        let displayString; // Define display string

        if (daysDifference > 0) {
            // If the difference is more than 1 day, display the date
            displayString = `${loginDate.toDateString()}`;
        } else if (hoursDifference > 0) {
            // If the difference is more than 1 hour, display the hours
            displayString = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else if (minutesDifference > 0) {
            // If the difference is more than 1 minute, display the minutes
            displayString = `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
        } else {
            // If the login was within the last minute, display "Just now"
            displayString = "Just now";
        }

        return displayString;
    }

    return (
        <div className={workSpaceStyle.workSpaceMain}>

            <div className={workSpaceStyle.workSpaceHeader}>
                <div className={workSpaceStyle.workSpaceHeaderLeft} style={{}}>
                    <h1>
                        {/* <span style={{ fontSize: "20px" }}>
                        </span> */}
                        Quotes Sent
                    </h1>
                </div>
                <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{}}>
                    <input type="text"
                        onChange={(e) => setSearchValue(e.target.value)}
                        value={searchValue}
                        placeholder='Search Here...' />
                </div>

                <div className={workSpaceStyle.workSpaceHeaderRight} style={{}} >

                    <div className={workSpaceStyle.WorkSpaceHeaderSorters} style={{}}>
                        <p>Sort By:</p>
                        <h3 onClick={() => { setUserDropDownOpen(prev => !prev); }}><Tooltip title='Filter Assign'><ImportExportIcon /></Tooltip>{`${assignSelected.slice(0, 7) + '...'}`}</h3>
                        {userDropDownOpen &&
                            <>
                                <div className={workSpaceStyle.workSpaceHeaderDropDownBackDrop} onClick={() => setUserDropDownOpen(prev => !prev)}></div>
                                <div className={workSpaceStyle.workSpaceHeaderSorterDropdown} style={{ width: '220px' }}>
                                    <h5 style={{ fontSize: '15px' }}>Send to:</h5>

                                    <ul>
                                        <li style={{ fontSize: '12px' }} onClick={() => ("All")} >All</li>
                                        {
                                            userSorterList.map((email, index) => {
                                                return (

                                                    <li style={{ fontSize: '12px' }} onClick={() => (email)} key={index}>{email}</li>
                                                )
                                            })
                                        }


                                    </ul>


                                </div>
                            </>
                        }
                    </div>
                    <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{}}>
                        <Tooltip title='Help'>
                            <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000080854-quotes-send">
                                <HelpOutlineOutlined />
                            </a>
                        </Tooltip>
                    </div>
                </div>

            </div>
            <div className={tableStyle.approvalVendorMain}>
                <table className={tableStyle.approvalVendorTable} style={{ width: "100%" }}>
                    <thead>
                        <tr className={`${tableStyle.tableHead}`}>

                            <td className={`${VendorApprovastyle.tableColumnSticky}`} style={{ minWidth: "150px" }} >Name</td>
                            <td>Product</td>
                            <td>
                                <div>
                                    <p>
                                        <span style={{ gap: '10px' }}> Submitted on</span>
                                    </p>
                                </div>
                            </td>
                            <td>Due Date</td>
                            <td>Sent to</td>
                            <td>Actions</td>

                        </tr>
                    </thead>

                    <tbody>

                        {tableRows
                            .filter((item) =>
                                item?.name?.trim().toLowerCase().includes(searchValue.toLowerCase()))
                            .map((row, index) => {

                                return (


                                    <tr key={index} className={`${VendorApprovastyle.tableRow}`}>
                                        <td className={`${VendorApprovastyle.tableColumnSticky2} `}>{row.name}</td>
                                        <td>{row.productName}</td>
                                        <td>{DateFormater(row.submittedOn)}</td>
                                        <td>{row.dueDate}</td>
                                        <td> <div className={VendorApprovastyle.tdRow}>

                                            <ProfileIcon data={{ email: row.source, }} height={"26px"} width={"26px"} showProfileBanner={row.sentBy ? true : false} />
                                            {row.sentBy}
                                        </div></td>
                                        <td>
                                            <div className={tableStyle.actionsRow}>

                                                <span onClick={() => {
                                                    row.type === "Technical Specification" ?
                                                        navigate(`view/${row.id}?tab=0`)
                                                        :

                                                        navigate(`commercialview/${row.id}?tab=0`)

                                                }}>

                                                    <RemoveRedEyeIcon />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default QuotesSent
