import React from 'react';
import EmailVerifyOTPStyle from './EmailVerifyOTPPageStyle.module.css';
import SliderTwo from 'components/SliderTwo/SliderTwo';


const EmailVerifyOTP = () => {
  return (
    <div className={EmailVerifyOTPStyle.OTPVerifyColumn}>
      <div className={EmailVerifyOTPStyle.OtpVerifyForm}>
        <form action="">
          VErify your mail here
        </form>
      </div>
      <div className={EmailVerifyOTPStyle.slider}>
        <SliderTwo />
      </div>
    </div>
  )
}

export default EmailVerifyOTP;
