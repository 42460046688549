import React, { useRef ,Component } from 'react';
import StyleSilde from './ProcaSlider.module.css'
import Slider from "react-slick"
import './SliderPro.css'
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';

const Slide = [
    {
        id: 1,
        title: 'It’s just incredible!',
        para: 'I had a great experience working with this companys customer service team. They were able to answer all my questions and provide me with the information I needed to make an informed decision about purchasing steel products.',
        pic: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698427353/image_4_ehdtse.png',
        name: 'Nakul Arya',
        position: 'Position',

    },
    {
        id: 2,
        title: 'Satisfied User Here!',
        para: 'I had a great experience working with this company customer service team. They were able to answer all my questions and provide me with the information I needed to make an informed decision about purchasing steel products.',
        pic: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698427353/image_6_memuay.png',
        name: 'Abhinandan',
        position: 'Position',
    },
    {
        id: 3,
        title: 'No doubt, Spend.In is the best!',
        para: 'The website is easy to use and the range of steel products they offer is impressive. I was looking for a specific type of steel for my construction project, and I found what I needed on their website.',
        pic: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698427353/image_5_pxwbua.png',
        name: 'Preshita Koli',
        position: 'Backend-Developer',

    },
    {
        id: 4,
        title: 'It’s just incredible!',
        para: 'I had a great experience working with this companys customer service team. They were able to answer all my questions and provide me with the information I needed to make an informed decision about purchasing steel products.',
        pic: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698427353/image_4_ehdtse.png',
        name: 'Prathamesh Jepal',
        position: 'Frontend-Developer',

    },
    {
        id: 5,
        title: 'Satisfied User Here!',
        para: 'I recently purchased steel coils from this company and I couldnt be happier with the quality and performance of the product. It was delivered on time and exceeded my expectations.',
        pic: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698427353/image_5_pxwbua.png',
        name: 'Soniya Singh',
        position: 'UI/UX Designer',

    },
    {
        id: 6,
        title: 'No doubt, Spend.In is the best!',
        para: 'I was impressed with the variety of steel products available on this ecommerce portal. It made it easy for me to find exactly what I needed for my project, and the quick delivery was a bonus.',
        pic: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698427353/image_6_memuay.png',
        name: 'Suraj Jadhav',
        position: 'Frontend-Developer',

    },

];

const ProcaSlider = () => {
    const sliderRef = useRef(null);
    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
        pauseOnHover: true,
};

    function updateSlidesToShow() {
        if (window.innerWidth <= 768) {
            settings.slidesToShow = 1; // Set to 1 when screen size is 768px or smaller
        } else {
            settings.slidesToShow = 3; // Set back to 3 for larger screens
        }
    }
    updateSlidesToShow(); // Call the function initially

    window.addEventListener('resize', updateSlidesToShow);

    const handleSliderPrev = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const handleSliderNext = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    return (
        <div className={StyleSilde.MainSlider}>
            <div className={StyleSilde.startSlider}>
                <div>
                    <h4>WHAT THEY SAY</h4>
                    <h1>Our Users Kind Words</h1>
                    <p>Here are some testimonials from our user after using Proca to manage their procurement.</p>
                </div>

                <div className={StyleSilde.procurementSlider}>
                    <div className={StyleSilde.UloginBoxStart2} >

                        <Slider ref={sliderRef} {...settings} >
                            {Slide.map((item) =>
                                <div key={item.id} className={StyleSilde.slidesProcure}>
                                    <div>
                                        <h6>{item.title}</h6>
                                        <p>{item.para}</p>
                                        <div className={StyleSilde.slidesProcureInfo}>
                                            <div>
                                                <img src={item.pic} alt="" />
                                            </div>
                                            <div>
                                                <h2>{item.name}</h2>
                                                <h5>{item.position}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Slider>
                        <div className={StyleSilde.SliderArrow}>
                            <ArrowCircleLeftRoundedIcon onClick={handleSliderPrev} />
                            <ArrowCircleRightRoundedIcon onClick={handleSliderNext} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ProcaSlider
