import React, { useState, useEffect, useRef } from 'react';
import Slickstyle from "./ProcureRegister.module.css"
import Box from '@mui/material/Box';// MUI Box component
import { Typography } from '@mui/material';// MUI Typography component
import Button from '@mui/material/Button';// MUI Button component
import 'react-phone-input-2/lib/style.css';// Styles for phone input
import PhoneInput from 'react-phone-input-2';// Phone input component
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';// React Router hooks and components
import Visibility from '@mui/icons-material/Visibility';// MUI Visibility icon
import VisibilityOff from '@mui/icons-material/VisibilityOff';// MUI VisibilityOff icon
import CloseIcon from '@mui/icons-material/Close';// MUI CloseIcon icon
import SliderTwo from 'components/SliderTwo/SliderTwo'// Custom SliderTwo component
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'// Styles for forms
import RegisterHeader from 'components/RegisterHeader/RegisterHeader';// Custom RegisterHeader component
import Tooltip from '@mui/material/Tooltip';// MUI Tooltip component
import { MuiOtpInput } from 'mui-one-time-password-input';// Custom OTP input component
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'// Styles for workspace
import Modal from '@mui/material/Modal';// MUI Modal component
import myaxios from 'api/axios';// Axios instance
import ErrorToast from 'components/ErrorToast/ErrorToast';// Custom ErrorToast component
import ProfileStyle from '../ProfilePage/ProfileStyle.module.css';// Styles for profile page
import useBackdrop from "hooks/useBackdrop";// Custom hook for backdrop
import { passwordEncryptor } from 'utility/passwordEncryptor.js'// Utility function for password encryption




const ProcureRegisterNew = () => {

    // State variables and hooks initialization
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Custom backdrop hook
    const [showPassword, setShowPassword] = useState(false); // State for password visibility
    const [isPasswordValid, setIsPasswordValid] = useState(false); // State for password validity
    const [phoneVerified, setPhoneVerified] = useState(false); // State for phone verification
    const [phoneOtp, setPhoneOtp] = useState(""); // State for phone OTP
    const [phoneVerifyId, setPhoneVerifyId] = useState(""); // State for phone verification ID
    const [modalError, setModalError] = useState(""); // State for modal error message
    const [commonError, setCommonError] = useState(""); // State for common error message
    const [subId, setSubId] = useState(""); // State for subscription ID
    const navigate = useNavigate(); // React Router navigation hook
    const [phoneVerifyModal, setPhoneVerifyModal] = useState(false); // State for phone verification modal
    const [hasVerifyApi, setHasVerifyApi] = useState(false); // State for phone verification API

    // Function to handle opening and closing of phone verification modal
    const handlePhoneVerifyModal = () => {
        setPhoneVerifyModal(prev => !prev);
    };

    // Ref for name input field
    const nameRef = useRef();
    const [loginDetail, setLoginDetail] = useState({// State for login details
        name: "",
        email: "",
        phone: "",
        gst: "",
        password: "",
    })
    const [formErrors, setFormErrors] = useState({// State for form errors
        name: "",
        email: "",
        phone: "",
        gst: "",
        password: "",
    });

    // Function to handle input field changes
    const handleInputField = (e) => {
        const { name, value } = e.target;

        // Define a regular expression pattern that allows only letters and spaces
        const pattern = /^[a-zA-Z\s]*$/;

        if (name === 'name') {
            if (!pattern.test(value)) {
                // If the input doesn't match the pattern, remove unwanted characters
                const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
                setLoginDetail((prev) => {// Set filtered value
                    return {
                        ...prev,
                        [name]: filteredValue,
                    };
                });
            } else {
                setLoginDetail((prev) => { // Set value as it is
                    return {
                        ...prev,
                        [name]: value,
                    };
                });
            }
        }
        else if (name === 'gst') {// If input is GST
            // Define a regular expression pattern for GST format (adjust as needed)
            const gstPattern = /^([0-9]{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1})$/;

            if (!gstPattern.test(value)) {
                // If the input doesn't match the GST pattern, remove unwanted characters
                const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
                setLoginDetail((prev) => {// Set value as it is
                    return {
                        ...prev,
                        [name]: filteredValue,
                    };
                });
            } else {
                setLoginDetail((prev) => {// Set value as it is
                    return {
                        ...prev,
                        [name]: value,
                    };
                });
            }
        }
        else {
            setLoginDetail((prev) => {
                return {
                    ...prev,
                    [name]: value,
                };
            });
        }
    };

    // Function to handle form field validation
    const handleValidation = (e) => {
        const { name, value } = e.target;
        let errorMessage = '';
        if (name === 'name') {
            if (value.trim() === '') {
                errorMessage = 'Name is required';
            } else if (!/^[a-zA-Z\s]+$/.test(value)) {
                errorMessage = 'Name should only contain letters and spaces';
            }
        } else if (name === 'email') {
        } else if (name === 'email') {
            if (value.trim() === '') {
                errorMessage = 'Mail id is required';
            }
            else if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
                errorMessage = 'Invalid Email Id'
            }
        } else if (name === 'phone') {
            if (value.length < 4) {
                errorMessage = 'Phone number is required.';
            }
            else if (value.length < 12) {
                errorMessage = 'Phone number length should be greater than 10 ';
            }
        }
        else if (name === 'gst') {
            if (value.trim() === '') {
                errorMessage = 'GST / VAT details are required';
            } else if (!/^([0-9]{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1})$/.test(value)) {
                errorMessage = 'Invalid GST format. It should be like XXAAAAA1234A1Z1.';
            }
        }
        else if (name === 'password') {
            if (value.length < 8) {
                errorMessage = 'Password must be at least 8 characters long';
            } else if (!/[!@#$%^&*()_~]/.test(value)) {
                errorMessage += 'Password must include at least one special character. ';
            } else if (!/[A-Z]/.test(value)) {
                errorMessage += 'Password must include at least one capital letter. ';
            } else if (!/\d/.test(value)) {
                errorMessage += 'Password must include at least one number. ';
            } else if (!/[a-z]/.test(value)) {
                errorMessage += 'Password must include at least one small letter. ';
            }
        }

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage,
        }));
    };


    const [errorMessage, setErrorMessage] = useState('');

    // Function to handle vendor registration
    const handleVendorRegister = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        const hasErrors = Object.values(formErrors).some((error) => error !== '');
        const notEmpty = Object.values(loginDetail).some((dd) => dd.length < 1);
        // Mark this function as async
        const encPass = await passwordEncryptor(loginDetail.password, 'AesPassword12345');
        console.log(encPass);
        openBackdrop();

        if (!phoneVerified) {
            // Phone is not verified, show an alert message
            alert("Please verify your phone number before registering.");
            closeBackdrop();

        } else if (!hasErrors && !notEmpty && subId !== null) {

            myaxios.post(`/procurement/sendEmailOtpWhileRegister/${phoneVerifyId}/${loginDetail.email}`)
                .then((res) => {
                    if (res.status === 200) {
                        const forwardDetails = loginDetail;
                        forwardDetails.subId = subId;
                        forwardDetails.phoneVerifyId = phoneVerifyId;
                        navigate("/emailVerify", { state: { data: forwardDetails }, replace: true });
                    }
                    closeBackdrop();
                })
                .catch(err => {
                    if (err?.response?.status === 404) {
                        setErrorMessage(err.response.data.message);
                    }
                    else if (err.response.status === 409 || err.response.status === 403) {
                        setErrorMessage("Email already exists!!");
                        closeBackdrop();
                    }
                    else {
                        setErrorMessage("Something Went Wrong. Please Try Again or Refresh");
                    }
                    closeBackdrop();
                });
        } else {
            closeBackdrop();

            setErrorMessage("Error in fields. No SubId");
        }
    };

    // Function to handle sending phone OTP
    const handleSendPhoneOtp = () => {

        openBackdrop()
        if (loginDetail.phone.length > 8 && !hasVerifyApi) {
            myaxios.post(`/procurement/mobileOtpWhileRegister/+${loginDetail.phone}`)
                .then((res) => {

                    if (res.status === 200) {
                        closeBackdrop();
                        handlePhoneVerifyModal();
                        setPhoneVerifyId(res.data.data.id);
                        setHasVerifyApi(true);  // Set the flag to true
                    }

                }).catch(err => {
                    closeBackdrop()
                    setHasVerifyApi(false)
                })
        }
        else if (hasVerifyApi) {
            handlePhoneVerifyModal();
            closeBackdrop();
        }
        else {
            closeBackdrop()
            setHasVerifyApi(false)
        }
    }


    // Function to handle phone verification
    const handlePhoneVerify = (e) => {
        e.preventDefault();
        openBackdrop()
        if (phoneOtp.length > 0 && loginDetail.phone.length > 0 && phoneVerifyId !== "") {
            myaxios.post(`/procurement/verifyMobileOtpWhileRegister/${phoneVerifyId}/+${loginDetail.phone}`, { verifyMobileOtp: phoneOtp })
                .then((res) => {
                    closeBackdrop()
                    if (res.status === 200) {
                        setPhoneVerified(true);
                        handlePhoneVerifyModal();
                    }
                }).catch(err => {
                    if (err?.response?.status === 400) {
                        closeBackdrop()
                        setModalError("Incorrect OTP!");
                    } else {
                        closeBackdrop()
                        setErrorMessage("Soemthing Went Wrong Please Try Again or Refresh")
                    }
                })
        }
        else {
            closeBackdrop()
            setModalError("Please Enter the OTP!");
        }
    }

    // State variable and function to handle resend OTP timer
    const [resendTimer, setResendTimer] = useState(60);

    const startResendTimer = () => {
        if (hasVerifyApi) {
            setResendTimer(60);
        }
    };

    useEffect(() => {
        if (resendTimer > 0 && phoneVerifyModal === true) {
            const timerInterval = setInterval(() => {
                setResendTimer(prevTime => prevTime - 1);
            }, 1000);
            return () => clearInterval(timerInterval);
        }
    }, [resendTimer, phoneVerifyModal]);


    // Function to handle resending phone OTP
    const handleResendPhoneOtp = () => {
        openBackdrop()
        setResendTimer(60); // Reset the timer to 60 seconds
        setPhoneOtp(""); // Clear the input field
        if (hasVerifyApi && loginDetail.phone) {
            myaxios.post(`/procurement/resendMobileOtpWhileRegister/${phoneVerifyId}`)
                .then(response => {
                    closeBackdrop()
                    if (response.status === 200) {
                        startResendTimer();
                    }
                })
                .catch(error => {
                });
        }
    };

    // Function to handle key press events
    //Function to Allow only numbers 0-9, backspace, and delete key  
    const handleKeyPress = (e) => {
        const keyCode = e.keyCode || e.which;
        const keyValue = String.fromCharCode(keyCode);
        // Allow number keys (both top row and numeric keypad) and backspace/delete
        const validInput = /^[0-9\b]+$/.test(keyValue) || (
            (keyCode >= 96 && keyCode <= 105) && // Numeric keypad
            (keyCode !== 110 && keyCode !== 190) // Exclude decimal point
        );
        if (!validInput) {
            e.preventDefault();
        }
    }

    return (
        <>
            <div className={Slickstyle.uloginMain}>
                <RegisterHeader />
                {errorMessage && <ErrorToast message={errorMessage} />}
                <div className={Slickstyle.uloginStart}>
                    <Box className={Slickstyle.uloginBox}>


                        <form action='/'
                            // style={{ gap:'30px'}}
                            className={formStyle.inputForm} onSubmit={handleVendorRegister} >

                            <div >
                                <Typography variant='h3'>Welcome 👋</Typography>
                                <Typography variant='h6'>Let’s set up your procure account</Typography>
                            </div>

                            <div className={formStyle.inputRow}>
                                <label>Kam name</label>
                                <input name='name'
                                    autoComplete='off'
                                    ref={nameRef}
                                    placeholder='Your Kam name'
                                    type='KamName'
                                    value={loginDetail.name}
                                    onChange={handleInputField}
                                    onBlur={handleValidation} />
                                <span>{formErrors.name}</span>

                            </div>


                            <div className={formStyle.inputRow}>
                                <label>KAM Email Id</label>
                                <input name='email'

                                    placeholder='Your email'
                                    type='email'
                                    autoComplete="off"
                                    value={loginDetail.email}
                                    onChange={handleInputField}
                                    onBlur={handleValidation} />
                                <span>{formErrors.email}</span>
                            </div>
                            <div className={formStyle.inputRow}>
                                <label>KAM phone no</label>
                                <div className={Slickstyle.phoneinputtt} style={{ width: '100%', display: 'grid', gridTemplateColumns: '75% auto', alignItems: 'center' }}>
                                    <PhoneInput required
                                        country={'in'}
                                        style={{ width: "100%" }}
                                        type='number'
                                        name='phone'
                                        value={loginDetail.phone}
                                        onChange={(value) => setLoginDetail(prev => { return { ...prev, phone: value } })}
                                        onBlur={handleValidation}
                                        inputProps={{ name: "phone", autoComplete: 'off' }}
                                        inputStyle={{ width: '100%', height: '36px', borderRadius: "9px 0px 0px 9px" }} // Add padding to accommodate the button
                                    />

                                    <Button variant="text"
                                        style={{
                                            width: '100%',
                                            minWidth: '0',

                                            height: '36px'
                                        }}
                                        sx={{ textTransform: 'capitalize', color: '#fff' }}
                                        onClick={handleSendPhoneOtp}
                                        disabled={phoneVerified}
                                    >{phoneVerified ?
                                        "verified"
                                        : "Verify"
                                        }
                                    </Button>
                                </div>
                                <span style={{ fontSize: '9px', color: 'rgb(211, 9, 9)', fontWeight: '600' }}>{formErrors.phone}</span>
                            </div>





                            <div className={formStyle.inputRow}>
                                <label>GST Details / VAT Details</label>
                                <input name='gst'
                                    autoComplete='off'
                                    type='gst'
                                    value={loginDetail.gst}
                                    onChange={handleInputField}
                                    onBlur={handleValidation} />
                                <span>{formErrors.gst}</span>

                            </div>

                            <div className={formStyle.inputRow}>
                                <label style={{ color: '#252628', fontSize: '12px', fontWeight: '500', lineHeight: '16px', fontStyle: 'normal' }}>Create a Password</label>
                                <input
                                    name='password'

                                    id="outlined-basic"
                                    label="Password"
                                    autoComplete='off'
                                    variant="outlined"
                                    value={loginDetail.password}
                                    onChange={handleInputField}
                                    onBlur={handleValidation}
                                    type={showPassword ? 'text' : 'password'}
                                    error={isPasswordValid}
                                    helperText={isPasswordValid && 'Password is required'}
                                />
                                <div className={Slickstyle.Visible}>
                                    {showPassword ? (
                                        <Tooltip title='Hide'><VisibilityOff onClick={() => setShowPassword(false)} /></Tooltip>
                                    ) : (
                                        <Tooltip title='Show'><Visibility onClick={() => setShowPassword(true)} /></Tooltip>
                                    )}
                                </div>
                                <span>{formErrors.password}</span>
                            </div>


                            <div className={Slickstyle.box2}>
                                <Typography variant='h1' style={{ display: 'block !important' }}>By continuing you agree to the KJSS <Link to='https://www.kjssteel.com/terms' target='_Blank'><span>terms of service </span></Link> and <Link to='https://www.kjssteel.com/privacy' target='_Blank'><span>privacy policy. </span></Link></Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>

                                <Button className={Slickstyle.registerButtonn} variant="contained" type="submit"
                                    onClick={handleVendorRegister} >
                                    Register
                                </Button>
                                <span style={{ fontSize: "10px", color: "red" }}>{commonError}</span>
                                <p >Already a user? <Link to="/login">Login</Link> </p>
                            </div>
                        </form>

                    </Box>

                    <Box className={Slickstyle.boxTwo}>
                        <SliderTwo />

                    </Box>



                </div>
                <BackdropComponent />
            </div >
            <Modal
                open={phoneVerifyModal}
            >
                <div className={workSpaceStyle.modalCont} style={{ minWidth: "350px" }}>

                    <form onSubmit={handlePhoneVerify} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
                        <div className={workSpaceStyle.modalHeader} >

                            <h3>Verify with OTP</h3>

                            <div onClick={handlePhoneVerifyModal} >
                                <Tooltip title='Close'><CloseIcon /></Tooltip>
                            </div>

                        </div>
                        <div className={Slickstyle.otpSent}>
                            <h4>OTP sent to +{loginDetail.phone} </h4>
                        </div>
                        <div className={formStyle.inputRow}>
                            <MuiOtpInput className={ProfileStyle.verifyOTPInput} style={{ width: "100%" }} length={5} value={phoneOtp} onChange={(e) => setPhoneOtp(e)} onKeyDown={handleKeyPress} autoComplete='off' name='otp'
                                placeholder='otp' />

                            <span style={{ marginTop: "15px" }}>{modalError}</span>
                        </div>

                        <div>
                            <button style={{ marginTop: "15px", width: '100%' }} className={formStyle.modalSmallPrimaryButton} type='submit'>Verify </button>
                        </div>

                        <div className={Slickstyle.resendOTP}>
                            <button
                                variant='text'
                                onClick={handleResendPhoneOtp}
                                disabled={resendTimer > 0}
                                style={{
                                    border: 'none',
                                    fontWeight: '600',
                                    color: resendTimer > 0 ? 'black' : '#16469D',
                                    cursor: resendTimer > 0 ? 'default' : 'pointer',
                                    backgroundColor: resendTimer > 0 ? '#ffff' : '#ffff'

                                }}
                            >
                                {resendTimer > 0
                                    ? `Resend in 00:${resendTimer < 10 ? '0' + resendTimer : resendTimer}`
                                    : 'Resend OTP'}
                            </button>
                        </div>

                        <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{modalError.fromError}</span>
                    </form>

                </div>
            </Modal>
        </>
    );
};

export default ProcureRegisterNew;


