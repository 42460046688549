import React, { useEffect, useState, useRef } from 'react'; 
import RegisterHeader from 'components/RegisterHeader/RegisterHeader';
import SliderTwo from 'components/SliderTwo/SliderTwo';// Importing SliderTwo component
import RedirectRegistrationStyle from './RedirectRegistrationStyle.module.css';
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'
import { Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import useBackdrop from "hooks/useBackdrop";
import ErrorToast from 'components/ErrorToast/ErrorToast';

const RedirectRegistrationNew = () => {

    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();// Destructuring hook methods for backdro
    const location = useLocation();// Getting current location using useLocation hook
    const navigate = useNavigate() // Getting navigation function using useNavigate hook


    const [errorMessage, setErrorMessage] = useState('');
    const [registerDetails, setRegisterDetails] = useState(null);
    const [subId, setSubId] = useState('');
    const oranizationRef = useRef();
    const [data, setData] = useState({
        organisationName: "",
    })


    const [formErrors, setFormErrors] = useState({ // State for form validation errors
        organisationName: '', // Default value for organization name error
    })

    // Focus on organization name input when component mounts
    useEffect(() => {
        oranizationRef.current.focus();
    }, [])


    // Retrieve registration details from location state
    useEffect(() => {
        if (location.state === null) {
            navigate("/procureregister");
        }
        else {
            setSubId(location.state.data.subId);
            setRegisterDetails(location.state.data);


        }
    }, [])

    // Handle input field changes
    const handleInputField = (e) => {
        setData(prev => {

            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [e.target.name]: '',
        }));
    };

    // Validate input fields
    const handleValidation = (e) => {
        const { name, value } = e.target;
        let errorMessage = '';

        if (name === 'organisationName') {
            if (value.trim() === '') {
                errorMessage = 'Oranisation name is required';
            }
        }

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage,
        }));
    }

    // Handle redirection after registration
    const handleredirectRegister = async (e) => {
        e.preventDefault();
        openBackdrop()
        setErrorMessage('');
        try {
            const hasErrors = Object.values(formErrors).some((error) => error !== '');
            const notEmpty = data.organisationName;

            if (!hasErrors && notEmpty && registerDetails !== null) {
                // API call to register organization
                await axios.post(`${process.env.REACT_APP_BASEURL}/procurement/addOrganisation/${registerDetails.subId}/${registerDetails.email}`, {
                    organisationName: data.organisationName.replace(/ /g, '-'),
                },
                    {
                        headers: {
                            'Authorization': `Bearer ${registerDetails.token}`,
                        }
                    }
                ).then((res) => {
                    // Redirect to product selection page after successful registration
                    navigate("/productSelect", {
                        state: {
                            data: {
                                orgId: res.data.data.orgId,
                                ...registerDetails
                            }
                        }, replace: true
                    });
                })
                closeBackdrop();
            } else {
                closeBackdrop();

            }
        } catch (error) {
            closeBackdrop();
            console.error(error);

            if (error.response) {
                if (error.response.status === 400) {
                    setFormErrors((prev) => ({
                        ...prev,
                        // organisationName: error.response.data.message?.slice(0, 15),
                        organisationName: error.response.data.message,
                    }));
                } else {
                    setErrorMessage("An unexpected error occurred");
                }
            } else {
                setErrorMessage("An unexpected error occurred");
            }
        }
    };

    return (
        <>
            <div className={RedirectRegistrationStyle.redirectRegisterMain}>
                <RegisterHeader />

                <div className={RedirectRegistrationStyle.redirectRegisterColumn}>
                    <div className={RedirectRegistrationStyle.redirectRegisterBox}>
                        <form action='' onSubmit={handleredirectRegister} className={RedirectRegistrationStyle.redirectRegisterForm}>

                            {errorMessage && <ErrorToast className={RedirectRegistrationStyle.redirectRegisterToast} message={errorMessage} />}
                            <div className={RedirectRegistrationStyle.formHeading}>
                                <Typography variant='h2'>Welcome 👋</Typography>
                                <Typography variant='h5'>Let's set up your procure account</Typography>
                            </div>


                            <div className={formStyle.inputRow}>
                                <label>Organisation Name </label>
                                <input
                                    ref={oranizationRef}
                                    name='organisationName'
                                    type="text"
                                    placeholder='Your first name'
                                    autoComplete="off"
                                    onChange={handleInputField}
                                    onBlur={handleValidation}
                                    required
                                />

                                <span>{formErrors.organisationName}</span>
                            </div>

                            <div className={formStyle.formBottomLine}>
                                <p>By continuing you agree to the KJSS <Link to='https://www.kjssteel.com/terms' target='_Blank'>terms of service</Link> and <Link to='https://www.kjssteel.com/privacy' target='_Blank'>privacy policy.</Link></p>
                            </div>

                            <button type="submit" className={formStyle.formPrimarySubmit}>
                                Continue
                            </button>
                        </form>
                    </div>

                    <div className={RedirectRegistrationStyle.slider}>
                        <SliderTwo />
                    </div>

                </div>
                <BackdropComponent />
            </div></>
    )
}

export default RedirectRegistrationNew;