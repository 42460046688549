import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import styles from './ExcelUploader.module.css';



const ExcelUploader = ({ onChange }) => {

  const [selectedFiles, setSelectedFiles] = useState([]);
  const onDrop = (acceptedFiles) => {
    const files = acceptedFiles;
    setSelectedFiles(files);
    onChange(files);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.xlsx', // Only allow Excel files
    multiple: true // Allow only one file to be uploaded at a time
  });

  return (
    <div {...getRootProps()} className={styles.uploadContainer} >
      <input {...getInputProps()} />
      {
        selectedFiles.length===0 &&
        <div>

        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>Drag 'n' drop an Excel file here, or click to select one</p>
        )}
      </div>
      }
      <div style={{display:"flex",flexDirection:"column"}}>

     
      {
       selectedFiles.map((item)=>{
       
        return (
          <>
          <p>{item.name}</p>
          </>
        )
       })
      }
       </div>
     
    </div>
  )
}

export default ExcelUploader
