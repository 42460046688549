import React, { useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import revisionEditStyle from './RevisionEdit.module.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import useBlobDownload from 'hooks/useBlobDownload';
import useBackdrop from 'hooks/useBackdrop';
import TaskStyle from "pages/TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import { Typography, Badge, Button } from '@mui/material';
import DocumentUploadModal from 'components/DocumentUploadModal/DocumentUploadModal';


const DocumentsTab = ({ addDocumentModal, handleAddDocumentModal, getDataProcure, documents, handleDocumentUpload, setDocuments, revisionId }) => {
  // Import necessary hooks and components
  const { auth } = useAuth(); // Get authentication information
  const privateAxios = PrivateComponent(); // Initialize a private Axios instance
  const { fileDownload } = useBlobDownload(); // Get file download functionality
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Get backdrop functionality for loading states

  // State hooks for various functionalities
  const [deleteModal, setDeleteModal] = useState(false); // State for delete modal visibility
  const [deleteId, setDeleteId] = useState(""); // State for the ID of the item to be deleted
  const inputRef = useRef(); // Reference for the file input
  const [documentName, setDocumentName] = useState(""); // State for the document name
  const [selectedFiles, setSelectedFiles] = useState([]); // State for the selected files
  const [selectedFileNames, setSelectedFileNames] = useState([]); // State for the selected file names
  const [documentMore, setDocumentMore] = useState(""); // State for more document information
  const [renameModal, setRenameModal] = useState(false); // State for rename modal visibility
  const [renameText, setRenameText] = useState(""); // State for the rename text
  const [docId, setDocId] = useState(""); // State for the document ID
  const [documentChats, setDocumentChats] = useState([]); // State for document chats
  const [docChatLoading, setDocChatLoading] = useState(true); // State for document chat loading indicator
  const [docType, setDocType] = useState(false); // State for document type


  // Function to handle file input change
  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files); // Convert file list to array
    setSelectedFiles([...selectedFiles, ...files]); // Add new files to selected files
    setSelectedFileNames([...selectedFileNames, ...files.map((file) => file.name)]); // Update selected file names
  };


  // Function to remove a selected file
  const handleRemoveSelectedFile = (index) => {
    const updatedSelectedFiles = [...selectedFiles]; // Copy selected files array
    updatedSelectedFiles.splice(index, 1); // Remove the file at the given index
    const updatedSelectedFileNames = [...selectedFileNames]; // Copy selected file names array
    updatedSelectedFileNames.splice(index, 1); // Remove the file name at the given index
    setSelectedFiles(updatedSelectedFiles); // Update selected files state
    setSelectedFileNames(updatedSelectedFileNames); // Update selected file names state
  };

  // Function to truncate file name
  function truncateFileName(fileName, maxLength) {
    if (typeof fileName !== 'string' || fileName.length === 0) {
      return ''; // Return an empty string or handle this case as needed
    }

    if (fileName.length <= maxLength) {
      return fileName;// Return the original file name if it's within the max length
    }

    const truncatedFileName = fileName.substring(0, maxLength - 5) + '...';// Truncate and add ellipsis
    return truncatedFileName;// Return the truncated file name
  }

  // Function to handle delete modal visibility
  const handleDeleteModal = (id) => {
    setDeleteModal(prev => !prev);// Toggle delete modal visibility
    if (id !== null) {
      setDeleteId(id);// Set the delete ID if provided
    }
  }

  // Function to handle rename modal visibility
  const handleRenameModal = (name) => {
    setRenameModal(prev => !prev);// Toggle rename modal visibility
    if (name !== null) {
      setRenameText(name);// Set the rename text if provided
    }
  }


  // Function to handle document validation and upload
  const handleDocumentValidation = async () => {
    openBackdrop(); // Show loading backdrop
    if (documentName !== "" && selectedFiles.length > 0 && revisionId !== "0") {
      const res = await handleDocumentUpload(documentName, selectedFiles, docType, revisionId);
      setSelectedFiles([]); // Clear selected files
      setSelectedFileNames([]); // Clear selected file names
      setDocumentName(""); // Clear document name
      handleAddDocumentModal(); // Handle document modal
      closeBackdrop(); // Hide loading backdrop
    }
    else {
      closeBackdrop();// Hide loading backdrop
      if (revisionId === null) {
        alert("this is the default Revision please save a copy and then upload documents");// Alert user
      }
      else {
        closeBackdrop();
        alert("all feilds are required check document name and files ");// Alert user
      }
    }
  }

  // Function to handle document deletion
  const handleDocDelete = async (docId) => {
    try {
      await privateAxios.delete(`/technicalSpecification/deleteDocumentOfRevision/${docId}`).then(res => {
        getDataProcure();// Fetch updated data
      }).catch(err => {
        // Handle error
      })
    } catch (error) {
      // Handle error
    }
  }

  // Function to handle document renaming
  const handleRenameDoc = async (e) => {
    e.preventDefault();
    try {
      if (docId) {
        await privateAxios.post(`/technicalSpecification/renameDocument/${docId}?name=${renameText}`).then(res => {
          handleRenameModal(); // Close rename modal
          getDataProcure(); // Fetch updated data
          setRenameText(""); // Clear rename text
        }).catch(err => {
          handleRenameModal();// Close rename modal
        })
      }
      else {
        alert("Document ID Not Present"); // Alert user
        handleRenameModal(); // Close rename modal
      }

    } catch (error) {
      handleRenameModal();// Close rename modal
    }
  }
  const [openConfidential, setOpenConfidential] = useState(false); // State for confidential modal visibility


  // ---------------------document chat states--------------------
  const [documentChatOpen, setDocumentChatOpen] = useState("");// State for document chat open
  const handleChatOpen = (data, docId) => {

    if (data === documentChatOpen) {
      setDocumentChatOpen(""); // Close chat if already open
      setDocumentChats(""); // Clear document chats
    }
    else {
      getDocumentChat(docId); // Fetch document chats
      setDocumentChatOpen(data); // Set document chat open state
    }
  }

  const [documentChatValue, setDocumentChatValue] = useState("");// State for document chat input value

  // Function to handle sending a document chat message
  const handleDocumentChatSend = async (e, documentId) => {
    e.preventDefault();
    try {
      if (documentChatValue.length > 0) {
        await privateAxios.post(`/technicalSpecification/addRemarkOnDocumentByEmployee/${auth.userData.id}/${documentId}`, { remark: documentChatValue }).then(res => {
          setDocumentChatValue(""); // Clear chat input value
          getDocumentChat(documentId);// Fetch updated document chats
        }).catch((err) => {
        })
      }
    } catch (error) {
    }
  }

  // Function to fetch document chats
  const getDocumentChat = async (documentId) => {
    try {
      await privateAxios.get(`/technicalSpecification/getRemarksOfDocument/${documentId}`).then(res => {
        handleChatNotification(); // Trigger chat notification
        setDocumentChats(res.data); // Set document chats state
        setDocChatLoading(false); // Set chat loading state to false
      }).catch((err) => {
        setDocChatLoading(false);// Set chat loading state to false
      })
    } catch (error) {
      setDocChatLoading(false);// Set chat loading state to false
    }
  }


  // Function to handle more document information visibility
  const handleDocumentMore = (index) => {
    if (documentMore === index) {
      setDocumentMore("");// Close more information if already open
    }
    else {
      setDocumentMore(index);// Set more information state
    }
  }


  // Chat notification state
  const [reRender, setReRender] = useState(false);// State for re-rendering

  // Function to handle chat notification
  const handleChatNotification = () => {
    setReRender(prev => !prev); // Toggle re-render state
  }




  return (
    <>
      <div className={revisionEditStyle.documentsTabMain}>

        <table className={revisionEditStyle.DocumentTable}>
          <thead>
            <tr className={`${revisionEditStyle.DocumentTableRow} ${revisionEditStyle.DocumentTableHead}`}>
              <td>Title</td>
              <td>Date Added</td>
              {/* <td>Remark</td> */}
              <td>
                <div className={revisionEditStyle.documentIconRow}>

                  <a><FileDownloadIcon /></a>


                  <a><MoreVertIcon /></a>

                </div>
              </td>

            </tr>
          </thead>
          <tbody>
            {documents?.map((doc, index) => {
              if (doc.docType !== "CONFIDENTIAL") {


                return (
                  <tr key={index} className={revisionEditStyle.DocumentTableRow}>
                    <td>{doc.name || doc.url}</td>
                    <td>{doc?.date?.split('T')[0] || "Null"}</td>
                    {/* <td style={{ position: "relative" }} >
                      <Tooltip title='Comments'>
                        <span onClick={() => { handleChatOpen(index, doc.id); }}>

                          <ChatIconWithBadge url={`/technicalSpecification/getNotficationCountOfProductDocument`} id={doc.id} reRender={reRender} />
                        </span>
                      </Tooltip>
                      {revisionId === "0" ? <>{documentChatOpen === index && <div className={revisionEditStyle.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                        <DocumentChatRows>
                          {docChatLoading ? <div>Chat Loading ..... </div> :

                            documentChats.length === 0 ? <p>No Previous Chats</p> :
                              documentChats?.map((chat, index) => {
                                return (
                                  <DocumentChatRow key={index}
                                    name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                    email={chat.email}
                                  // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                  />
                                )

                              })}
                        </DocumentChatRows>
                        <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                      </DocumentChatPannel>}
                    </td> */}
                    <td> <div className={revisionEditStyle.documentIconRow}>

                      <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}><FileDownloadIcon /></Link>


                      <Link style={{ position: "relative" }} className={revisionEditStyle.documentMoreDots} onClick={(e) => { e.preventDefault(); handleDocumentMore(index) }} ><MoreVertIcon />
                        {documentMore === index && <ul style={{ position: "absolute" }}>
                          {
                            revisionId !== "0"

                            && <li onClick={() => { handleRenameModal(doc?.name || ""); setDocId(doc.id) }}> <span><DriveFileRenameOutlineIcon /></span>Rename</li>
                          }
                          {
                            revisionId !== "0"
                            // revisionId === "0"
                            &&
                            <li onClick={() => handleDeleteModal(doc.id)}> <span><DeleteOutlineIcon /></span>Delete</li>
                          }
                          {/* <li> <span><DeleteOutlineIcon /></span>Some Other Option</li> */}
                        </ul>}
                      </Link>

                    </div></td>
                  </tr>
                )
              }
            })
            }

          </tbody>
        </table>




        <div className={revisionEditStyle.accordianDocumentab}>
          <div className={revisionEditStyle.accordianDocumentabHeader} onClick={() => setOpenConfidential(prev => !prev)}><span>
            {openConfidential ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </span>Confidential Documents</div>

          <div className={revisionEditStyle.accordianDocumentabBody}>

            {openConfidential &&
              <table className={revisionEditStyle.DocumentTable}>
                <tbody>
                  {documents?.map((doc, index) => {
                    if (doc.docType === "CONFIDENTIAL") {
                      return (
                        <tr key={index} className={revisionEditStyle.DocumentTableRow}>
                          <td>{doc.name || doc.url}</td>
                          <td>{doc?.date?.split('T')[0] || "Null"}</td>
                          {/* <td style={{ position: "relative" }} >
                            <Tooltip title='Comments'>
                              <span onClick={() => { handleChatOpen(index, doc.id); }}>

                                <ChatIconWithBadge url={`/technicalSpecification/getNotficationCountOfProductDocument`} id={doc.id} reRender={reRender} />
                              </span>
                            </Tooltip>
                            {revisionId === "0" ? <>{documentChatOpen === index && <div className={revisionEditStyle.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                              <DocumentChatRows>
                                {docChatLoading ? <div>Chat Loading ..... </div> :

                                  documentChats.length === 0 ? <p>No Previous Chats</p> :
                                    documentChats?.map((chat) => {
                                      return (
                                        <DocumentChatRow key={index}
                                          name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                          email={chat.email}

                                        // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                        />
                                      )

                                    })}
                              </DocumentChatRows>
                              <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                            </DocumentChatPannel>}
                          </td> */}
                          <td> <div className={revisionEditStyle.documentIconRow}>

                            <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}
                            // target='_blank'
                            ><FileDownloadIcon /></Link>


                            <Link style={{ position: "relative" }} className={revisionEditStyle.documentMoreDots} onClick={(e) => { e.preventDefault(); handleDocumentMore(index) }} ><MoreVertIcon />
                              {documentMore === index && <ul style={{ position: "absolute" }}>
                                {
                                  revisionId !== "0"
                                  // revisionId === "0"
                                  && <li onClick={() => { handleRenameModal(doc?.name || ""); setDocId(doc.id) }}> <span><DriveFileRenameOutlineIcon /></span>Rename</li>
                                }
                                {
                                  revisionId !== "0"
                                  // revisionId === "0"
                                  &&
                                  <li onClick={() => handleDeleteModal(doc.id)}> <span><DeleteOutlineIcon /></span>Delete</li>
                                }
                                {/* <li> <span><DeleteOutlineIcon /></span>Some Other Option</li> */}
                              </ul>}
                            </Link>

                          </div></td>
                        </tr>
                      )
                    }
                  })

                  }

                </tbody>
              </table>

            }
          </div>

          <BackdropComponent />
        </div>


      </div>

      <DocumentUploadModal
        open={addDocumentModal}
        onClose={handleAddDocumentModal}
        onSubmit={handleDocumentUpload}

      />



      <Modal
        open={renameModal}
        onClose={handleRenameModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={handleRenameDoc} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><DriveFileRenameOutlineIcon /></span>Rename</h3>
              <div onClick={handleRenameModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>

              <input type="text" value={renameText} onChange={(e) => setRenameText(e.target.value)} placeholder='Revision Name' />
              {/* <span>{formErrors.accessRole}</span>  */}
              {/* <span>{formError.workSpace}</span> */}
            </div>



            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>

              <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>


              <p onClick={() => { setRenameText(""); handleRenameModal() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

            {/* <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span> */}
          </form>

        </div>
      </Modal>

      <Modal
        open={deleteModal}
        onClose={handleDeleteModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', width: '375px' }}>
          <form action="" style={{ maxWidth: '100%' }} >

            <div className={TaskStyle.modalKam}>
              <Typography variant="h1">Delete Document</Typography>
              <Typography variant="h6">Are you sure you want to delete this Document?</Typography>

              <div className={TaskStyle.kamModalButton}>
                <Button onClick={() => { handleDocDelete(deleteId); handleDeleteModal() }} variant="contained">Delete</Button>

                <p onClick={handleDeleteModal}>Cancel</p>
              </div>

            </div>

          </form>
        </div>
      </Modal>
    </>
  )
}

export default DocumentsTab



/**
 * RevisionEdit.js
 * 
 * This is the Document Tab of Revision Edit component, responsible for rendering Document Tab of Revision Edit. 
 * 
 *
 * Author: Ravinder Kumar
 *   
 * 
 * Created on: 2023-08-29
 * 
 * 
 * Last Modified on: 2023-09-28
 */