import React, { useEffect, useRef, useState } from 'react'
import styles from './AttributeTab.module.css'
import { PrivateComponent } from 'api/axios'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import CustomSelector from 'components/CustomSelector/CustomSelector';
import UnitSelector from './UnitSelector/UnitSelector';
import useAuth from 'hooks/useAuth';
import AddIcon from '@mui/icons-material/Add';
import RenameModal from 'components/RenameModal/RenameModal';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';



// ---------------------rules-----------------------

const rules = {
    "NUMBER": ["LESS", "MORE", "EQUAL"],
    "TEXT": [],
    "DATE": ["LESS", "MORE", "EQUAL"],
    "BOOLEAN": ["YES", "NO"],
    "RANGE": ["INRANGE", "OUTRANGE"],
    "SINGLESELECT": [],


}

// ---------------------rules-----------------------



const AttributeTab = ({ viewType, tableRows, duplicateRow, setTableRows, columns, setColumns, showToast }) => {
    const privateAxios = PrivateComponent();
    const { auth } = useAuth();

    // handle Extra Column state

    const [extraColumnMoreDropDown, setExtraColumnDropDown] = useState("");
    const extraColumnMore = (id) => {
        if (extraColumnMoreDropDown === id) {
            setExtraColumnDropDown("");
        }
        else {
            setExtraColumnDropDown(id);
        }
    }


    const handleAddColumn = () => {
        if (tableRows.length < 1) {
            alert("please add a row first")
        }
        else {
            let newColumns = [];
            let currentId = (tableRows[0].additionalFields[tableRows[0].additionalFields.length - 1]?.columnId || 0) + 1;

            // Inserting the new column before the 'Add' column
            newColumns = [
                ...columns,
                { id: currentId, name: 'New Column', createdBy: auth.roles !== "VENDOR" ? "KAM" : "VENDOR", },
            ];
            setColumns(newColumns);

            // Creating a new row with the corresponding column information
            let newCol = {
                columnId: currentId,
                columnName: 'New column',
                createdBy: auth.roles !== "VENDOR" ? "KAM" : "VENDOR",
                columnValue: '',
            };

            // Adding the new row to each existing row's additionalFields
            const newTableRows = tableRows.map((row) => ({
                ...row,
                additionalFields: [...row.additionalFields, newCol],
            }));
            setTableRows(newTableRows);
        }
    };

    const handleAddRow = () => {

        let additionalColumns = []
        if (tableRows.length > 0) {

            additionalColumns = tableRows[0].additionalFields.map((col) => {
                return {
                    ...col,
                    columnValue: ""
                }
            })
        }

        const newRow = {
            id: tableRows.length + 1,
            name: "",
            type: "",
            unit: "",
            value: "",
            revisedValue: "",
            remark: "",
            vendorRemark: "",
            additionalFields: additionalColumns,
            valueType: "TEXT",
            ruleAttribute: "",
            expectedAns: "",
            createdBy: auth.roles !== "VENDOR" ? "KAM" : "VENDOR",
        };
        setTableRows([...tableRows, newRow]);
    };

    const [rowDeleteModal, setRowDeleteModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const handleRowDeleteModal = (data) => {
        if (data !== undefined && data !== null && Object.keys(data).length > 0) {

            if ((data.createdBy === "VENDOR" && auth.roles === "VENDOR") || (data.createdBy === "KAM" && auth.roles !== "VENDOR")) {
                setRowDeleteModal(prev => !prev);

                setSelectedRow(data);

            }
            else {

                showToast("error", "Not Allowed to delete this row");
            }
        }
    }

    const handleRowDelete = () => {
        const updatedRows = tableRows.filter(row => row.id !== selectedRow.id);
        setTableRows(updatedRows);
    };



    // column rename 
    const [columnData, setColumnData] = useState({});
    const [columnRenameModal, setColumnRenameModal] = useState(false);
    const handleColumnNameChangeModal = (data) => {
        setColumnRenameModal(prev => !prev);
        if (data !== undefined && data !== null && Object.keys(data).length > 0) {

            setColumnData(data);
        }
    }



    const handleColumnNameChange = (data) => {
        handleColumnNameChangeModal();
        setColumns(prev => {
            return prev.map(col => {
                if (col.id === columnData.id) {
                    return {
                        ...col,
                        name: data.name,
                    }
                }
                else {
                    return col
                }
            })

        })


        setTableRows(prev => {
            return prev.map(row => {
                return {
                    ...row,
                    additionalFields: row.additionalFields.map((field) => {
                        if (field.columnId === columnData.id) {
                            return {
                                ...field,
                                columnName: data.name
                            }
                        }
                        else {
                            return field;
                        }
                    }),
                }
            })
        })
    }



    // column rename 
    const [columnDeleteModal, setColumnDeleteModal] = useState(false);
    const handleColumnDeleteModal = (data) => {
        setColumnDeleteModal(prev => !prev);
        if (data !== undefined && data !== null && Object.keys(data).length > 0) {
            setColumnData(data);
        }
    }

    const handleColumnDelete = () => {
        setColumns(prev => {
            return prev.filter(col => col.id !== columnData.id);
        })
        setTableRows(prev => {
            return prev.map(row => {
                return {
                    ...row,
                    additionalFields: row.additionalFields.filter((field) => field.columnId !== columnData.id),
                }
            })
        })
    }

    const handleInputChange = (e, id) => {
        const { name, value } = e.target;

        setTableRows(prev => {
            return prev.map(row => {
                if (row.id === id) {
                    if (auth.roles === "VENDOR" && (name === "revisedValue" || name === "vendorRemark")) {
                        return {
                            ...row,
                            [name]: value
                        };
                    }

                    if (auth.roles !== "VENDOR" && name !== "revisedValue" && name !== "vendorRemark") {
                        if (name === "valueType") {
                            return {
                                ...row,
                                [name]: value,
                                ruleAttribute: ""
                            };
                        } else {
                            return {
                                ...row,
                                [name]: value
                            };
                        }
                    }
                }

                return row;
            });
        });
    };


    const handleAdditionalColumnChange = (e, id, columnId) => {
        const { name, value } = e.target;
        setTableRows(prev => {
            return prev.map(row => {
                if (row.id === id) {
                    return {
                        ...row,
                        additionalFields: row.additionalFields.map((field) => {
                            if (field.columnId === columnId) {
                                return {
                                    ...field,
                                    columnValue: value
                                }
                            }
                            else {
                                return field;
                            }
                        }),
                    }
                }
                else {
                    return row;
                }
            })
        })

    }


    return (
        <>

            <div className={styles.tableWrapper}>
                <table className={styles.attributeTable}>
                    <thead>
                        <tr className={styles.tableHeader}>

                            <th style={{ width: "70px", maxWidth: "100px", minWidth: "70px" }}>
                                <p style={{ textAlign: "left" }}>Sr No.</p>
                            </th>
                            <th style={{ minWidth: "250px" }}>
                                <p> Name </p>
                            </th>
                            <th>
                                <p> Type </p>
                            </th>
                            <th>
                                <p> Unit </p>
                            </th>
                            {
                                viewType !== "VENDOR" &&
                                <>
                                    <th style={{ minWidth: "180px" }}>
                                        <p> Value Type </p>
                                    </th>
                                    <th>
                                        <p>  Set up a Rule </p>
                                    </th>
                                </>
                            }
                            <th style={{ minWidth: "200px" }}>
                                <p> Value </p>
                            </th>
                            {
                                viewType !== "VENDOR" &&
                                <th>
                                    <p> Expected Answer </p>
                                </th>
                            }
                            {
                                viewType === "VENDOR" &&
                                <th>
                                    <p> Vendor Value </p>
                                </th>
                            }

                            <th style={{ minWidth: "250px" }}>
                                <p> Remark </p>
                            </th>
                            {
                                viewType === "VENDOR" &&
                                <th style={{ minWidth: "250px" }}>
                                    <p> Vendor Remark </p>
                                </th>
                            }
                            {
                                columns.map((item, index) => {
                                    return (
                                        <th style={{ minWidth: "250px" }}>
                                            <div>
                                                <p>{item.name}</p>
                                                <div className={styles.moreWrapper}>

                                                    {
                                                        (item.createdBy === auth.roles || (item.createdBy === "KAM" && auth.roles !== "VENDOR")) &&
                                                        <span onClick={() => extraColumnMore(index)}>
                                                            <MoreHorizIcon />
                                                        </span>
                                                    }

                                                    {
                                                        extraColumnMoreDropDown === index &&
                                                        <ThreeDotDropDown toggle={() => extraColumnMore(index)}>
                                                            <li onClick={() => { extraColumnMore(index); handleColumnNameChangeModal({ id: item.id, name: item.name }) }}
                                                            >Rename Column</li>

                                                            <li onClick={() => { extraColumnMore(index); handleColumnDeleteModal({ id: item.id, name: item.name }) }}
                                                            >Delete Column</li>
                                                        </ThreeDotDropDown>
                                                    }
                                                </div>
                                            </div>
                                        </th>
                                    )
                                })
                            }
                            {
                                true &&

                                <th style={{ width: "70px", maxWidth: "100px", minWidth: "70px" }}>
                                    <div style={{ justifyContent: "center" }}>
                                        <span className={styles.addIcon} onClick={handleAddColumn}>
                                            <AddIcon />
                                        </span>
                                    </div>
                                </th>
                            }
                            {
                                true &&

                                <th style={{ width: "70px", maxWidth: "100px", minWidth: "70px" }}>
                                    <p>Action</p>
                                </th>
                            }

                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableRows.map((item, index) => {
                                const duplicate = duplicateRow.includes(item.id);
                                return (
                                    <tr id={`row-${item.id}`} key={`attributeTable_${index}`} className={duplicate ? `${styles.tableData} ${styles.tableDataDuplicate} ` : `${styles.tableData} `}>
                                        <td >
                                            <p style={{ textAlign: "center", paddingLeft: "0" }}>{index + 1}</p>
                                        </td>
                                        <td>
                                            <input type="text" value={item.name}
                                                name="name" onChange={(e) => handleInputChange(e, item.id)}
                                                disabled={auth.roles === "VENDOR" ? true : false}
                                            />
                                        </td>
                                        <td>
                                            <input type="text" value={item.type}
                                                name="type" onChange={(e) => handleInputChange(e, item.id)}
                                                disabled={auth.roles === "VENDOR" ? true : false}
                                            />
                                        </td>
                                        <td>
                                            <div>
                                                <UnitSelector value={item.unit}
                                                    name="unit" onChange={(e) => handleInputChange(e, item.id)}
                                                />

                                            </div>
                                        </td>
                                        {
                                            viewType !== "VENDOR"
                                            &&
                                            <>
                                                <td>
                                                    <div>
                                                        <CustomSelector value={item.valueType} style={{ width: "90%", height: "30px" }}
                                                            disabled={auth.roles === "VENDOR" ? true : false}
                                                        >
                                                            <>
                                                                {
                                                                    Object.keys(rules).map((type, index) => {
                                                                        return (
                                                                            <li key={index} onClick={() => { handleInputChange({ target: { value: type, name: "valueType" } }, item.id); }}>
                                                                                {type}
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        </CustomSelector>
                                                    </div>
                                                </td>

                                                <td>
                                                    {
                                                        (item.valueType && item.valueType !== "TEXT" && item.valueType !== "SINGLESELECT" && item.valueType !== "BOOLEAN") ?
                                                            <div>
                                                                <CustomSelector value={item.ruleAttribute} style={{ width: "90%", height: "30px" }}
                                                                    disabled={auth.roles === "VENDOR" ? true : false}
                                                                >
                                                                    <>
                                                                        {
                                                                            rules[item.valueType].map((rule) => {
                                                                                return (

                                                                                    <li onClick={() => { handleInputChange({ target: { value: rule, name: "ruleAttribute" } }, item.id); }}>
                                                                                        {rule}
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                </CustomSelector>
                                                            </div>

                                                            :
                                                            <p style={{ textAlign: "center" }}>
                                                                -
                                                            </p>
                                                    }
                                                </td>
                                            </>
                                        }
                                        <td>
                                            <div>
                                                <KamValueInput
                                                    value={item.value}
                                                    onChange={(e) => handleInputChange(e, item.id)}
                                                    type={item.valueType}
                                                    name="value"
                                                    style={{ width: "90%", height: "30px", color: "black" }}
                                                    disabled={auth.roles === "VENDOR" ? true : false}
                                                />
                                            </div>
                                        </td>

                                        {
                                            viewType !== "VENDOR"
                                            &&
                                            <>
                                                {
                                                    item.valueType == "SINGLESELECT" ?
                                                        <td>
                                                            <div>
                                                                <ExpectedAnsInput
                                                                    value={item.expectedAns}
                                                                    onChange={(e) => handleInputChange(e, item.id)}
                                                                    kamValue={item.value}
                                                                    type={item.valueType}
                                                                    name="expectedAns"
                                                                    style={{ width: "90%", height: "30px" }}
                                                                    disabled={auth.roles === "VENDOR" ? true : false}
                                                                />
                                                            </div>
                                                        </td>
                                                        :
                                                        <td>
                                                            <p style={{ textAlign: "center" }}>
                                                                -
                                                            </p>
                                                        </td>
                                                }
                                            </>
                                        }


                                        {
                                            viewType === "VENDOR"
                                            &&
                                            <td>
                                                <div>
                                                    <VendorValueInput
                                                        value={item.revisedValue}
                                                        kamValue={item.valueType === "SINGLESELECT" ? item.expectedAns : item.value}
                                                        dropOption={item.value}
                                                        rule={item.ruleAttribute}
                                                        type={item.valueType}
                                                        name="revisedValue"
                                                        onChange={(e) => { handleInputChange(e, item.id) }} style={{ width: "90%", height: "30px" }}
                                                        disabled={auth.roles !== "VENDOR" ? true : false}
                                                    />
                                                </div>
                                            </td>
                                        }
                                        <td>
                                            <textarea value={item.remark} name="remark" onChange={(e) => handleInputChange(e, item.id)}
                                                disabled={auth.roles === "VENDOR" ? true : false} />
                                        </td>
                                        {
                                            viewType === "VENDOR"
                                            &&
                                            <td>
                                                <textarea value={item.vendorRemark} name="vendorRemark" onChange={(e) => handleInputChange(e, item.id)}
                                                    disabled={auth.roles !== "VENDOR" ? true : false}
                                                />
                                            </td>
                                        }

                                        {
                                            item.additionalFields.map((field) => {
                                                return (
                                                    <td>
                                                        <textarea
                                                            value={field.columnValue}
                                                            onChange={(e) => handleAdditionalColumnChange(e, item.id, field.columnId)}
                                                            disabled={
                                                                (field.createdBy === "VENDOR" && auth.roles === "VENDOR")
                                                                    ||
                                                                    (field.createdBy === "KAM" && auth.roles !== "VENDOR")
                                                                    ? false : true
                                                            }
                                                        />
                                                    </td>
                                                )
                                            })}

                                        <td>
                                        </td>
                                        <td>
                                            <div>
                                                <span onClick={() => { handleRowDeleteModal({ id: item.id, createdBy: item.createdBy }) }}>
                                                    <DeleteOutlineOutlinedIcon />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div >


            <RenameModal
                open={columnRenameModal}
                onClose={handleColumnNameChangeModal}
                onSubmit={handleColumnNameChange}
                prefilled={columnData.name}
                header="Rename"
            />


            <DeleteConfirmation
                onCancel={handleColumnDeleteModal}
                onConfirm={() => { handleColumnDeleteModal(); handleColumnDelete(); }}
                open={columnDeleteModal}
            >
                <h2>Delete Column</h2>
                <p>Are you sure you want to delete this Column?</p>

            </DeleteConfirmation>

            <DeleteConfirmation
                onCancel={() => { setRowDeleteModal(false) }}
                onConfirm={() => { handleRowDeleteModal(); handleRowDelete(); }}
                open={rowDeleteModal}

            >
                <h2>Delete Row</h2>
                <p>Are you sure you want to delete this Row?</p>

            </DeleteConfirmation>

        </>
    )
}
export default AttributeTab

const VendorValueInput = ({ value, onChange, kamValue, dropOption, rule, type, name, style, ...props }) => {
    const [vStyle, setVStyle] = useState({
        ...style,
        color: "black",

    });

    let options = [];
    if (type == "SINGLESELECT") {

        if (dropOption == undefined || dropOption == null || dropOption == "") {
            options = ["please add value separeated by comma"];
        }
        else {

            options = dropOption.split(",").map((item) => item.trim());
        }
    }
    useEffect(() => {
        let newStyle = { ...style };

        switch (type) {
            case 'NUMBER':
                switch (rule) {
                    case 'LESS':
                        newStyle.backgroundColor = parseFloat(value) <= parseFloat(kamValue) ? '#00ff001a' : '#ff00001a';
                        break;
                    case 'MORE':
                        newStyle.backgroundColor = parseFloat(value) >= parseFloat(kamValue) ? ' #00ff001a' : '#ff00001a';
                        break;
                    case 'EQUAL':
                        newStyle.backgroundColor = parseFloat(value) === parseFloat(kamValue) ? '#00ff001a' : '#ff00001a';
                        break;
                    default:
                        newStyle.backgroundColor = '';
                }
                break;

            case 'RANGE':
                const rte = kamValue?.split("-").map((item) => item.trim());

                if (rte?.length > 1) {

                    switch (rule) {
                        case 'INRANGE':
                            newStyle.backgroundColor = (parseFloat(value) >= parseFloat(rte[0]) && parseFloat(value) <= parseFloat(rte[1])) ? '#00ff001a' : '#ff00001a';
                            break;
                        case 'OUTRANGE':
                            newStyle.backgroundColor = (parseFloat(value) <= parseFloat(rte[0]) || parseFloat(value) >= parseFloat(rte[1])) ? ' #00ff001a' : '#ff00001a';
                            break;

                        default:
                            newStyle.backgroundColor = '';
                    }
                }
                else {
                    newStyle.backgroundColor = '';
                }
                break;

            case 'BOOLEAN':
                newStyle.backgroundColor = value?.trim() === kamValue?.trim() ? '#00ff001a' : '#ff00001a'
                break;

            case 'SINGLESELECT':
                newStyle.backgroundColor = value?.trim() === kamValue?.trim() ? '#00ff001a' : '#ff00001a'
                break;

            case 'DATE':
                switch (rule) {
                    case 'LESS':
                        newStyle.backgroundColor = new Date(value) < new Date(kamValue) ? '#00ff001a' : '#ff00001a';
                        break;

                    case 'MORE':
                        newStyle.backgroundColor = new Date(value) > new Date(kamValue) ? ' #00ff001a' : '#ff00001a';
                        break;

                    case 'EQUAL':
                        newStyle.backgroundColor = new Date(value) === new Date(kamValue) ? '#00ff001a' : '#ff00001a';
                        break;
                }

                break;
            // Add other cases as needed

            default:
                newStyle.backgroundColor = '';
        }

        setVStyle(newStyle);
    }, [value, kamValue, rule, type, style]);

    switch (type) {
        case 'NUMBER':
            return <input type="number" value={value ?? ''} onChange={onChange} name={name} style={{ ...vStyle, textAlign: 'center' }} {...props} />;
        case 'DATE':
            console.log(vStyle)
            return <input type="date" value={value ?? ''} onChange={onChange} name={name} style={{ ...vStyle, textAlign: 'center' }} {...props} />;
        case 'BOOLEAN':
            return (
                <CustomSelector value={value} style={vStyle} {...props}>
                    <li onClick={() => onChange({ target: { value: 'YES', name: name } })}>YES</li>
                    <li onClick={() => onChange({ target: { value: 'NO', name: name } })}>NO</li>
                </CustomSelector>
            );
        case 'RANGE':
            return <input type="number" value={value ?? ''} style={{ ...vStyle, textAlign: 'center' }} name={name} onChange={onChange} {...props} />;
        case 'SINGLESELECT':
            return (
                <CustomSelector value={value ?? ''} style={vStyle} {...props}>
                    {
                        options.map((item) => {
                            return (
                                <li onClick={() => onChange({ target: { value: item, name: name } })}>{item}</li>
                            )
                        })
                    }

                </CustomSelector>
            )
        default:
            return <input type="text" value={value ?? ''} style={{ ...vStyle, textAlign: 'center', color: "black", resize: "horizontal" }} name={name} onChange={onChange} {...props} />;
    }
};
const KamValueInput = ({ value, onChange, type, name, style, ...props }) => {
    const inputRef = useRef(null);
    const { auth } = useAuth();
    const calculateWidth = () => {
        if (inputRef.current) {
            const inputWidth = inputRef.current.scrollWidth;
            const containerWidth = inputRef.current.parentElement.clientWidth;

            // Check if the input width is greater than the container width
            if (inputWidth > containerWidth) {
                return `${inputWidth}px`;
            }
        }

        // If the input width is not greater than the container width, use 100%.
        return '100%';
    };

    const vStyle = {
        ...style,
        // width: calculateWidth(),
        width: "100%",
        height: "100%",
        resize: "vertical",
        border: "none",
        paddingTop: "10px"

    };

    switch (type) {
        case 'NUMBER':
            return <textarea ref={inputRef} type="number" value={value ?? ''} onChange={onChange} name={name} style={{ ...vStyle, textAlign: 'center' }} {...props} />;
        case 'DATE':
            return <input ref={inputRef} type="date" value={value ?? ''} onChange={onChange} name={name} style={{ ...style, textAlign: 'center' }} {...props} />;
        case 'BOOLEAN':
            return (
                <CustomSelector value={value} style={style} {...props} disabled={auth.roles === "VENDOR" ? true : false}>
                    <li onClick={() => onChange({ target: { value: 'YES', name: name } })}>YES</li>
                    <li onClick={() => onChange({ target: { value: 'NO', name: name } })}>NO</li>
                </CustomSelector>
            );
        case 'RANGE':
            return <textarea ref={inputRef} type="range" value={value ?? ''} style={{ ...vStyle, textAlign: 'center' }} name={name} onChange={onChange} {...props} />;
        case 'SINGLESELECT':
            return <textarea ref={inputRef} type="text" value={value ?? ''} style={{ ...vStyle, textAlign: 'center' }} name={name} onChange={onChange} {...props} />;
        default:
            return <textarea ref={inputRef} type="text" value={value ?? ''} style={{ ...vStyle, textAlign: 'center' }} name={name} onChange={onChange} {...props} />;
    }
};
const ExpectedAnsInput = ({ value, onChange, type, kamValue, name, style, ...props }) => {
    const inputRef = useRef(null);

    let options = [];
    if (kamValue == undefined || kamValue == null || kamValue == "") {
        options = ["please add value separeated by comma"];
    }
    else {
        options = kamValue.split(",").map((item) => item.trim());
    }

    const vStyle = {
        ...style,
        // width: calculateWidth(),
        width: "100%",
        height: "100%",
        resize: "vertical",
        border: "none",
        paddingTop: "10px"

    };

    switch (type) {

        case 'SINGLESELECT':
            return (
                <CustomSelector value={value} style={style} {...props}>
                    {
                        options.map((item) => {
                            return (
                                <li onClick={() => onChange({ target: { value: item, name: name } })}>{item}</li>
                            )
                        })
                    }
                </CustomSelector>
            );


        default:
            return <textarea ref={inputRef} type="text" value={value ?? ''} style={{ ...vStyle, textAlign: 'center' }} name={name} onChange={onChange} {...props} />;
    }
};



