// Importing necessary modules and components
import { useState, useEffect, useRef } from 'react';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import useAuth from 'hooks/useAuth';
import useToast from 'hooks/useToast';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import TaskStyle from "../TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import FetchUsersComponent from 'components/FetchUsersComponent/FetchUsersComponent'
import KAMViewApproval from './KAMViewApproval';
import UserViewApproval from './UserViewApproval';
import { PrivateComponent } from 'api/axios';
import { useNavigate, useParams } from 'react-router-dom';

// Functional component for workspace approval
const WorkSpaceApproval = () => {
  // Custom hooks and necessary states
  const { auth } = useAuth();
  const { Toast, showToast } = useToast();
  const [loggedIn, userList, notLoggedIn] = FetchUsersComponent({ orgId: auth.orgId, workspaceId: 4 })
  const privateAxios = PrivateComponent();
  const navigate = useNavigate();
  const { client } = useParams();
  const [searchValue, setSearchValue] = useState("")


  // Function to handle last seen time
  const handleLastSeen = (loginTime) => {
    // Calculating time difference
    const loginDate = new Date(loginTime);
    const currentDate = new Date();
    const timeDifference = currentDate - loginDate;
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    let displayString;

    if (daysDifference > 0) {
      // If the difference is more than 1 day, display the date
      displayString = `${loginDate.toDateString()}`;
    } else if (hoursDifference > 0) {
      // If the difference is more than 1 hour, display the hours
      displayString = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
    } else if (minutesDifference > 0) {
      // If the difference is more than 1 minute, display the minutes
      displayString = `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
    } else {
      // If the login was within the last minute, display "Just now"
      displayString = "Just now";
    }
    return displayString;
  }

  // ============================get data====================================

  // Function to get approval revisions
  const [approvalRevisions, setApprovalRevisions] = useState([]);

  const getApprovalRevisions = async () => {
    try {
      let url = "";
      switch (auth.roles) {
        case "USER": url = "/approval/getAllApprovalsInUser";
          break;
        case "ADMIN": url = "/approval/getAllApprovals";
          break;
        case "KAM": url = "/approval/getAllApprovals";
          break;

      }
      const res = await privateAxios.get(`${url}/${auth.userData.id}`)
      const { data } = res
      console.log(data);
      setApprovalRevisions(data);

    } catch (error) {
      showToast("error", "Error Getting data");
    }

  }

  useEffect(() => {
    getApprovalRevisions();
  }, [])

  // ======================handle file type===================================

  // Function to handle file type
  const handleFileType = (fileType) => {

    if (fileType === "PRODUCT") {
      return (
        <span style={{ padding: "2px 10px", fontWeight: "500", borderRadius: "4px" }}>Product</span>
      )
    }
    else if (fileType === "GROUP") {
      return (
        <span style={{ backgroundColor: "#EEEEEE", fontWeight: "500", padding: "2px 10px", borderRadius: "4px" }}>Group</span>
      )
    }
    else if (fileType === "COMPARISION") {
      return (
        <span style={{ backgroundColor: "#16469D", fontWeight: "500", color: "white", padding: "2px 10px", borderRadius: "4px" }}>Comparision</span>
      )
    }
  }

  // -=========================navigate===============================

  // Function to navigate based on data
  const handleNavigate = (data) => {
    if (data?.fileType.toLowerCase() === "group") {
      navigate(`/${client}/approvals/group/${data.id}`)
    }
    else {
      if (data.type.toLowerCase() === "technical specification") {
        navigate(`/${client}/approvals/technical/${data.id}/${data.revId}`)

      }
      else if (data.type.toLowerCase() === "commercial") {
        navigate(`/${client}/approvals/commercial/${data.id}/${data.revId}`)

      }
    }
  }



  return (
    <>
      <Toast />
      <div className={workSpaceStyle.workSpaceMain}>
        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1 style={{ gap: '10px' }}><span style={{ fontSize: "20px" }}><LayersOutlinedIcon /></span> Approval
            </h1>
            <p style={{ marginLeft: '40px' }}>All tasks <span>
              {approvalRevisions.length}
            </span></p>

          </div>
          <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
            <input type="text"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder='Search Here...' />
          </div>
          <div className={workSpaceStyle.workSpaceHeaderRight} style={{ minWidth: '250px', justifyContent: 'flex-end' }}>

            {(auth.roles === "KAM" || auth.roles === "ADMIN") && <div className={TaskStyle.addAssignMain} style={{ display: 'flex', width: '20%', justifyContent: 'center', alignItems: 'center' }}>

              <div className={TaskStyle.addAssign} style={{ width: '100%' }}>
                {userList.length > 0 && userList.slice(0, 3).map((item, index) => {
                  const colorArray = ["#0077b6", "#f77f00", "#a7c957"];
                  const randomIndex = Math.floor(Math.random() * colorArray.length);
                  const randomColor = colorArray[randomIndex];

                  return (
                    <div key={index} className={TaskStyle.name1} style={{ backgroundColor: randomColor, color: '#fff' }}>
                      <ProfileIcon data={{ email: item }} height={"26px"} width={"26px"} showProfileBanner={false} />
                    </div>
                  );
                })}

              </div>
            </div>
            }

            <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
              <Tooltip title='Help'>
                <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000080852-approval">
                  <HelpOutlineOutlinedIcon />
                </a>
              </Tooltip>
            </div>
          </div>

        </div>


        {
          ["KAM"].includes(auth.roles)
            ?
            <KAMViewApproval handleLastSeen={handleLastSeen} handleNavigate={handleNavigate} getApprovalRevisions={getApprovalRevisions} searchValue={searchValue} approvalRevisions={approvalRevisions} handleFileType={handleFileType} />
            :
            <UserViewApproval handleLastSeen={handleLastSeen} handleNavigate={handleNavigate} getApprovalRevisions={getApprovalRevisions} searchValue={searchValue} approvalRevisions={approvalRevisions} handleFileType={handleFileType} />
        }

      </div>
    </>
  )
}

export default WorkSpaceApproval
