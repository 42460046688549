import React from 'react'
import documentChatStyle from './DocumentChat.module.css';



const DocumentChatRows = ({ children }) => {
  
  return (
    <div className={documentChatStyle.documentChatRowsMain}>
     {children}
    </div>
  )
}

export default DocumentChatRows
