import React, { useState } from 'react'
import helpStyle from '../ProcaHelp/ProcaHelp.module.css'
import CallIcon from '@mui/icons-material/Call';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import AddIcon from '@mui/icons-material/Add';

const allQnAData = [
    {
        question: 'How do I open an Proca account?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui accumsan sit amet nulla facilisi morbi. Eget gravida cum sociis natoque penatibus et magnis dis parturient.'
    },
    {
        question: 'How do I add new users?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui accumsan sit amet nulla facilisi morbi. Eget gravida cum sociis natoque penatibus et magnis dis parturient.'
    },

    {
        question: 'How to change my account limits?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui accumsan sit amet nulla facilisi morbi. Eget gravida cum sociis natoque penatibus et magnis dis parturient.'
    },

    {
        question: 'How does Proca premium works?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui accumsan sit amet nulla facilisi morbi. Eget gravida cum sociis natoque penatibus et magnis dis parturient.'
    },

    {
        question: 'Can I have two proca accounts?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui accumsan sit amet nulla facilisi morbi. Eget gravida cum sociis natoque penatibus et magnis dis parturient.'
    },

]

const ProcaHelp = () => {

    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        if (openIndex === index) {
            setOpenIndex(null);
        } else {
            setOpenIndex(index);
        }
    };


    return (
        <div className={helpStyle.procaHelpMain}>
            <div className={helpStyle.procaHelpStart}>
                <div className={helpStyle.procaHelpContent}>
                    <h1>Need help?</h1>
                    <div>
                        <CallIcon />
                        <p>
                            <span>+91 9321714108</span>
                            <span style={{opacity:'70%'}}>Support Hotline</span>
                        </p>
                    </div>
                    <div>
                        <MarkEmailUnreadOutlinedIcon />
                        <p>
                            <span>Info@kjsscosmos.com</span>
                            <span style={{opacity:'70%'}}>Support Email</span>
                        </p>
                    </div>

                </div>
                <div className={helpStyle.ProcaHelpQuestion}>

                    <div className={helpStyle.faqContainer}>
                        {allQnAData.map((faq, index) => (
                            <div key={index} className={helpStyle.faqItem}>
                                <div style={{opacity:'80%'}} className={helpStyle.faqQuestion} onClick={() => toggleFAQ(index)}>
                                    {faq.question}
                                    <AddIcon className={`rotateIcon ${openIndex === index ? helpStyle.rotated : ''}`} />
                                </div>
                                {openIndex === index && (
                                    <div style={{opacity:'60%',lineHeight:'25px'}} className={helpStyle.faqAnswer}>
                                        {faq.answer}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ProcaHelp
