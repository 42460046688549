import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import useToast from 'hooks/useToast';
import useBackdrop from 'hooks/useBackdrop';
import ProdStyle from './OrgProductSelector.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'
import myaxios from 'api/axios';

const OrgProductSelector = ({ productsArray, handleAddProductModal, onsubmit, orgId }) => {
    // Custom hooks for toast notifications and backdrops
    const { Toast, showToast } = useToast();
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

    // State variables
    const [availableCategories, setAvailableCategories] = useState([]); // Store available categories
    const [selectedSubcategories, setSelectedSubcategories] = useState([]); // Store selected subcategories
    const [productList, setProductList] = useState([]); // Store products list
    const [productSelected, setProductSelected] = useState([]); // Store selected products

 // Fetch categories from API
    const getCategories = async () => {
        openBackdrop(); // Show backdrop
        try {
            await myaxios.get(`/procurement/getAllCategories`).then(res => {
                if (res.status === 200) {
                    setAvailableCategories(res.data);// Update available categories
                }
            })

        } catch (error) {
            showToast("error", "Error Fetching Categories") // Show error toast


        }
        closeBackdrop()// Hide backdrop
    }

    // Fetch categories on component mount
    useEffect(() => {
        getCategories();
    }, [])

// Fetch subcategories and products for a given category
    const getCategoryData = async (category) => {
        openBackdrop(); // Show backdrop
        try {
            const res = await myaxios.get(`/procurement/getCategoriesByParent/${category.categoryId}`).then(res => {// API call to fetch subcategories
                setSelectedSubcategories([{ ...res.data }]);// Set selected subcategories
                setProductSelected("");// Clear selected product
               // Close backdrop after fetching data
            }).catch((err) => {
                closeBackdrop();// Close backdrop in case of error

            })

        } catch (err) {
            showToast("error", "Error Fetching Sub Categories")// Show error toast

        }
        closeBackdrop();// Hide backdrop
    };

     // Fetch products for a given category level
    const getPorductData = async (id, level) => {
        openBackdrop(); // Show backdrop
        try {
            await myaxios.get(`/procurement/getAllProductByCategory/${id}`)// API call to fetch products
                .then((res) => {
                    setProductList(res.data);
                    setProductSelected("")
                })
                .catch((error) => {
                    setProductList([]);
                    setProductSelected("")
                    console.error('Error fetching products:', error);
                });

        } catch (error) {
            setProductList([]);
            setProductSelected("")
        }
        closeBackdrop();// Hide backdrop
    }

    // Fetch products when a subcategory without further branches is selected
    useEffect(() => {
        if (selectedSubcategories[selectedSubcategories.length - 1]?.branch.length === 0) {
            const sub = selectedSubcategories[selectedSubcategories.length - 1]
            getPorductData(sub.categoryId, sub.level);
        }
    }, [selectedSubcategories])




    // =======================category Selector========================

     // State for category search and dropdown visibility
     const [catSearch, setCatSearch] = useState(""); // Store search query for categories
     const [catSelectDropdown, setCatSelectDropdown] = useState(false); // Store dropdown visibility state

      // Toggle category selector dropdown
    const handleCatSelectorDropDown = () => {
        setCatSelectDropdown((prev) => !prev);

    }

    // Handle category selection
    const handleCategorySelector = async (cat) => {
        handleCatSelectorDropDown()
        await getCategoryData({ ...cat, level: 1 });// Fetch subcategories and products
        setProductList([])
        setProductSelected("")



    }

    // =======================category Selector========================


    // =======================Subcategory Selector========================

        // Handle subcategory selection
    const handleSubcategorySelect = (subcategory, index) => {


        const tt = selectedSubcategories.filter((cat) => {
            return cat.level < subcategory.level; // Filter out subcategories at higher levels
        })
        const newSub = [
            ...tt,
            { ...subcategory },
        ]
        setProductList([]);
        setProductSelected("");
        setSelectedSubcategories(newSub);// Update selected subcategories

    };



    // =======================Subcategory Selector========================

    // ====================tree builder========================
      // Build category tree structure
    const buildTree = (categories) => {
        let tree = [];
        let lookup = {};

          // Create a lookup map of categories
        categories.forEach(category => {
            category.branch = [];
            lookup[category.categoryId] = category;
        });

           // Build the tree structure
        categories.forEach(category => {
            if (category.parent !== null) {
                const parent = lookup[category.parent];
                parent.branch.push(category);
            } else {
                tree.push(category);
            }
        });

        return tree;
    }

    // ====================tree builder========================


    // =========================handle Discard==========================

     // Handle discard action
    const handleDiscard = () => {
        setSelectedSubcategories([]);
        setProductSelected([]);
        handleAddProductModal();
    }

    // =========================handle Discard==========================

       // Handle adding product
    const handleAddPro = () => {
        if (productList.length === 0) {
            alert("Please Select a Product");// Alert if no product selected

        }
        else {

            try {
                let updatedCategories = selectedSubcategories.map((cat) => {

                    if (orgId !== null && orgId !== undefined && orgId !== "") {
                        return {
                            categoryId: cat.categoryId,
                            categoryName: cat.categoryName,
                            parent: cat.parent,
                            organisationId: orgId,//show be changed to state.data.orgId
                            level: cat.level,
                            branch: [],
                        }
                    }
                    else {


                        return {
                            categoryId: cat.categoryId,
                            categoryName: cat.categoryName,
                            parent: cat.parent,

                            level: cat.level,
                            branch: [],
                        }
                    }
                })
                const updateProducts = productSelected?.map((pro) => {
                    if (orgId !== null && orgId !== undefined && orgId !== "") {
                        return {
                            productName: pro.name,
                            productId: pro.productId,
                            organisationId: orgId,//show be changed to state.data.orgId
                            categoryId: selectedSubcategories[selectedSubcategories.length - 1].categoryId,
                        }
                    }
                    else {
                        return {
                            productName: pro.name,
                            productId: pro.productId,

                            categoryId: selectedSubcategories[selectedSubcategories.length - 1].categoryId,
                        }
                    }
                })
                const tree = buildTree(updatedCategories);// Build category tree
                onsubmit({ categories: tree, products: updateProducts }); // Submit data
            } catch (error) {
                return null;
            }
        }
    }





    return (
        <>
            <Toast />
            <BackdropComponent />

            <div className={ProdStyle.productSelectors}>
                <div className={ProdStyle.productSelectorColumn}>
                    <label htmlFor="">Select Category</label>
                    <div className={ProdStyle.productSelector}  >
                        <p onClick={() => { handleCatSelectorDropDown(); }} >
                            {selectedSubcategories[0]?.categoryName || "Please Select A category"}
                            <span>
                                {catSelectDropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </span>
                        </p>
                        {catSelectDropdown &&
                            <>

                                <div className={ProdStyle.dropdownBackDrop} onClick={handleCatSelectorDropDown}>
                                </div>
                                <div className={ProdStyle.productDropDownDiv} >

                                    <div className={ProdStyle.productSearchBar}>
                                        <input type="text" value={catSearch} onChange={(e) => setCatSearch(e.target.value)}
                                            placeholder="Search for Products..." />
                                    </div>
                                    <ul className={ProdStyle.productDropDownList}>

                                        {availableCategories.filter((cat) => cat.name.trim().toLowerCase().includes(catSearch.toLowerCase())).map((cat) => {
                                            return (
                                                <li onClick={() => handleCategorySelector(cat)}>{cat.name}</li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </>
                        }
                    </div>
                </div>

                {selectedSubcategories.length > 0 &&
                    selectedSubcategories.map((selectedCategory, index) => {

                        if (selectedCategory.branch.length > 0) {
                            return (
                                <div className={ProdStyle.productSelectorColumn}>
                                    <label> Sub-Category</label>
                                    <SubcategorySelectorInput
                                        category={selectedCategory}
                                        selectedSubcategories={selectedSubcategories}
                                        onSelectSubcategory={(subcategory) => handleSubcategorySelect(subcategory, index)}
                                        index={index}

                                    />
                                </div>
                            )
                        }
                    }
                    )}


                {selectedSubcategories[selectedSubcategories.length - 1]?.branch.length === 0 &&
                    <div className={ProdStyle.productSelectorColumn}>
                        <label>
                            {/* <GroupsIcon sx={{ color: '#6A6A6A' }} /> */}
                            Product </label>
                        <ProductSelectorInput productList={productList} productSelected={productSelected}

                            setProSelected={setProductSelected} productsArray={productsArray} />

                    </div>}

            </div>


            <div className={workSpaceStyle.d_flex_end} style={{ gap: "10px" }}>
                <button className={`${workSpaceStyle.modalBtn}`} style={{ color: "#16469D" }} onClick={handleDiscard}> Discard</button>
                <button className={`${workSpaceStyle.modalBtn}`} style={{ backgroundColor: "#16469D", color: "white" }} onClick={handleAddPro}>Add Product</button>

            </div>
        </>
    )
}

export default OrgProductSelector



const SubcategorySelectorInput = ({ category, selectedSubcategories, onSelectSubcategory, index }) => {

    const [subCatSelectDropDown, setSubCatSelectDropDown] = useState(false);
    const [subCatSelected, setSubCatSelected] = useState("");
    const [subCatSearch, setSubCatSearch] = useState("");
    const handleSubCatSelectorDropDown = () => {
        setSubCatSelectDropDown(prev => !prev)
    }

    const handleSubCategorySelector = (subCat) => {
        handleSubCatSelectorDropDown();
        onSelectSubcategory(subCat);
        setSubCatSelected(subCat.categoryName);


    }
 

    return (
        <div className={ProdStyle.AllProductmodal} key={index} >
            <div className={ProdStyle.productSelector} >

                <p onClick={() => {


                    handleSubCatSelectorDropDown();


                }



                }
                // style={{ color: subCatSelected ? 'black' : 'gray' }}
                >
                    {(index < (selectedSubcategories.length - 1) && subCatSelected)
                     || "Please Select A Sub-Category"}
                    <span>
                        {subCatSelectDropDown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </span>
                </p>
                {
                    subCatSelectDropDown &&
                    <>


                        <div className={ProdStyle.dropdownBackDrop} onClick={handleSubCatSelectorDropDown}>
                        </div>
                        <div className={ProdStyle.productDropDownDiv}>
                            <div className={ProdStyle.productSearchBar}>
                                <input type="text" value={subCatSearch} onChange={(e) => setSubCatSearch(e.target.value)} />
                            </div>
                            <ul className={ProdStyle.productDropDownList} >

                                {category.branch.filter((subCat) => {
                                    return subCat.categoryName && subCat.categoryName.trim().toLowerCase().includes(subCatSearch.toLowerCase());
                                }).map((subCat) => {
                                    return (
                                        <li onClick={() => handleSubCategorySelector(subCat)}>{subCat.categoryName}</li>
                                    )
                                })}

                            </ul>
                        </div>
                    </>
                }
            </div>

        </div>
    )
}


const ProductSelectorInput = ({ productSelected, productList, addBtnToggle, setProSelected, productsArray }) => {

    // console.log(productsArray)
   
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    


    // console.log(productList)
    const toggleDropdown = () => {

        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        setProSelected(selectedProduct)
    }, [selectedProduct])

    const handleProductToggle = (product) => {
        if (!productsArray.some((selectedProduct) => selectedProduct.productId === product.productId)) {
            const categoryIndex = selectedProduct.findIndex((cat) => cat.name === product.name);

            if (categoryIndex !== -1) {
                // Category already selected, remove it
                const updatedCategories = [...selectedProduct];
                updatedCategories.splice(categoryIndex, 1);
                setSelectedProduct(updatedCategories);
            } else {
                // Category not selected, add it
                setSelectedProduct([...selectedProduct, product]);
            }
        }
    };


    const handleRemoveProduct = (event, product) => {
        event.stopPropagation();


        const updatedCategories = selectedProduct.filter((cat) => cat.name !== product.name);
        setSelectedProduct(updatedCategories);


    };

    console.log(productsArray)

    return (

        <div className={formStyle.inputRow}>
            <div className={ProdStyle.dropdownContainer}>
                <div
                    className={`${ProdStyle.dropdown} ${dropdownOpen && ProdStyle.open}`}
                    onClick={() => {




                        toggleDropdown();

                    }
                    }
                >
                    <div className={ProdStyle.selectedCategories}>
                        {selectedProduct.length > 0 ?
                            selectedProduct.map((category, index) => (
                                <div key={index} className={ProdStyle.selectedCategory}>
                                    {category.name}
                                    <CloseIcon
                                        className={ProdStyle.closeIcon}
                                        onClick={(event) => handleRemoveProduct(event, category)}
                                    />
                                </div>
                            )) :
                            'Select products'}
                    </div>
                    <KeyboardArrowDownIcon
                        className={ProdStyle.arrowIcon}

                    />
                </div>
                {dropdownOpen && (
                    <>


                        <div className={ProdStyle.dropdownBackDrop} onClick={toggleDropdown}>
                        </div>
                        <div className={ProdStyle.dropdownContent}>
                            {productList.map((pro, index) => {
                                const alreadyPresent=productsArray.some((selectedProduct) => parseInt(selectedProduct.productId) === parseInt(pro.productId));
                                
                                return (
                                <div
                                    key={index}
                                    className={ProdStyle.dropdownItem}
                                    onClick={() => handleProductToggle(pro)}
                                    style={{
                                        backgroundColor: alreadyPresent ? '#efeaea' : 'white',
                                        color: alreadyPresent ? '#737374' : 'black',
                                        cursor: alreadyPresent ? 'auto' : 'pointer',
                                    }}
                                >
                                    <span>{pro.name}</span>
                                    <input
                                        type="checkbox"
                                        name='categorySelect'
                                        checked={selectedProduct.some((cat) => cat.name === pro.name) || alreadyPresent}
                                        readOnly
                                        required
                                        disabled={alreadyPresent}
                                    />
                                </div>
                            )
                        }
                            )}


                        </div>
                    </>
                )}

            </div>

        </div>


    )
} 