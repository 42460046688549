import React, { useState, useEffect } from 'react';
import { PrivateComponent } from 'api/axios';

// Define a functional component called FetchUsersComponent which takes orgId and workspaceId as props

const FetchUsersComponent = ({ orgId, workspaceId }) => {
  // Access the PrivateComponent function from the imported axios module
  const privateAxios = PrivateComponent();

  // Define state variables for loggedInEmails, allEmails, and notLoggedInEmails using useState
  const [loggedInEmails, setLoggedInEmails] = useState([]);
  const [allEmails, setAllEmails] = useState([]);
  const [notLoggedInEmails, setNotLoggedInEmails] = useState([]);

  // Use useEffect to fetch data when component mounts or when orgId or workspaceId changes
  useEffect(() => {
    // Define an asynchronous function getUsers
    const getUsers = async () => {
      try {
        // Send a GET request using privateAxios to fetch all workspace users
        const response = await privateAxios.get(`/task/getAllWorkspaceUsers/${orgId}/${workspaceId}`);
        // Extract all emails from the response data
        const allEmails = response.data.map(item => item.email);
        // Extract logged in emails from the response data where loggedIn is true
        const loggedInEmails = response.data.filter(item => item.loggedIn === true).map(item => item.email);
        // Extract not logged in emails from the response data where loggedIn is false
        const notLoggedInEmails = response.data.filter(item => item.loggedIn === false).map(item => item.email);

        // Update state variables with the extracted emails
        setAllEmails(allEmails);
        setLoggedInEmails(loggedInEmails);
        setNotLoggedInEmails(notLoggedInEmails);
      } catch (error) {
        // Log an error message if there's an error fetching users
        console.error('Error fetching users:', error);
      }
    };
    // Call the getUsers function to initiate the data fetching process
    getUsers();
  }, [orgId, workspaceId]);// Dependencies for useEffect: orgId and workspaceId

  // Return an array containing loggedInEmails, allEmails, and notLoggedInEmails
  return [loggedInEmails, allEmails, notLoggedInEmails];
};

export default FetchUsersComponent;