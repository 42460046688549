import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import Tooltip from '@mui/material/Tooltip';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import ProductSelector from 'components/ProductSelector/ProductSelector';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@mui/material';
import orgAppStyle from './OrganisationApproved.module.css';
import CloseIcon from '@mui/icons-material/Close';
import ProductsTab from './ProductsTab';
import CommercialTab from './CommercialTab';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css';
import TemplateSelector from 'components/TemplateSelector/TemplateSelector';
import { HelpOutlineOutlined } from '@mui/icons-material';
import OrgProductSelector from 'components/OrgProductSelector/OrgProductSelector';



const OrganisationApproved = () => {
 // Extract orgId and kamId from the URL parameters
    const { orgId, kamId } = useParams();
     // Hooks for managing backdrop and toast notifications
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const { showToast, Toast } = useToast();
     // Axios instance with private configurations
    const privateAxios = PrivateComponent();
       // Hook for navigation
    const navigate = useNavigate();
     // State for selected items
    const [selectedItems, setSelectedItems] = useState([]);

// Handle checkbox change for product selection
    const handleCheckboxChange = (product) => {
         // Check if the product is already selected
        const allreadySelected = selectedItems.some((item) => item.id === product.id);
         // If selected, remove it from the list, otherwise add it
        if (allreadySelected) {
            setSelectedItems(selectedItems.filter((item) => item.id !== product.id))
        }
        else {
            setSelectedItems([...selectedItems, product])
        }
    }

    
 // Placeholder click handler
    const handleClick = () => {
        alert("Clicked")
    }

    // =======================org details=============================

     // State for organization data
    const [orgData, setOrgData] = useState({})

      // Fetch organization data from the server
    const getOrgData = async () => {
        openBackdrop();
        try {
             // Make a GET request to fetch organization data
            const res = await privateAxios.get(`/procureadmin/getOrganisation/${orgId}`);
            const { data } = res;
            setOrgData(data);// Set organization data

         
        } catch (error) {
            showToast("error", "Error Getting Data")// Show error toast
        }
        closeBackdrop(); // Close backdrop
    }

      // Fetch organization data on component mount
    useEffect(() => {
        getOrgData();
    }, [])

    // ====================flat tree========================

   // Flatten categories tree structure into a single level array
    const flattenCategories = (categories, result = []) => {
        categories.forEach(category => {
            result.push(category);// Add category to result
            if (category.branch && category.branch.length > 0) {
                flattenCategories(category.branch, result); // Recursively flatten branches
            }
            delete category.branch; // Remove branch property
        });
        return result;
    }


    // ====================flat tree========================

 // State for selected products and categories
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    // Fetch product and category data
    const getProductData = async () => {
        openBackdrop();// Open backdrop
        try {
                 // Make a GET request to fetch categories
            const catRes = await privateAxios.get(`/procurement/getCategories/${orgId}`)
            const catData = catRes.data.data.categories;

             // Flatten category data
            const flattenCat = flattenCategories(catData);
             // Make a GET request to fetch approved products
            const productRes = await privateAxios.get(`/procureadmin/getAllProductsOfOrg/${orgId}`);
            const productData = productRes.data;
          
            setSelectedProduct(productData); // Set selected products
            setSelectedCategories(flattenCat); // Set selected categories
        } catch (error) {
            showToast("error", "Error Getting ProductData") // Show error toast
        }
        closeBackdrop(); // Close backdrop
    }

    

    //  ====================get category path==========================

  // Get category path based on category ID
    const getCategoryPath = (categoryId) => {
        const category = selectedCategories.find((category) => category.categoryId === categoryId);
        if (category) {
            return category.parent ? `${getCategoryPath(category.parent)} > ${category.categoryName}` : category.categoryName;
        }
        return '';
    }


    // ====================add product modal========================

  // State for add product modal visibility
    const [addProduct, setAddProduct] = useState(false);

     // Toggle add product modal visibility
    const handleAddProductModal = () => {
        setAddProduct(prev => !prev);
    }



    // ====================handle add product success modal================

     // Show success or error toast on add product success
    const showAddProductSuccess = (type) => {
        handleAddProductModal(); // Close add product modal

        if (type) {
            showToast("success", "Product Added Successfully") // Show success toast
        }
        else {
            showToast("error", "Error Adding Product")// Show error toast
        }
    }


    // ====================disable Organisation===========================

  // Enable or disable organization
    const handleOrgCheckBox = async (orgId, kamId, type) => {
        openBackdrop();// Open backdrop
        try {
            let url = "";
             // Set URL based on organization's acceptance status
             if (type) {
                url = `/procureadmin/DisableOrganisation`
            }
            else {
                url = `/procureadmin/enableOrganisation`

            }
             // Make a POST request to enable or disable organization
            const res = await privateAxios.post(`${url}/${orgId}/${kamId}`);
            if (res.status === 200) {

                if (!type) {

                    showToast("success", "Organaistion Enabled Successfully"); // Show success toast
                }
                else {
                    showToast("success", "Organisation Disabled Successfully"); // Show success toast
                }
            }
            getOrgData();// Refresh organization data

        } catch (error) {
            showToast("error", error.message);// Show error toast
        }
        closeBackdrop();// Close backdrop

    }



    // ==================tab selector=====================================

  // Location and search parameters for tab selection
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [tabActive, setTabActive] = useState("0");

     // Update active tab based on URL search parameters
    useEffect(() => {
        if (searchParams.get("tab") !== null && searchParams.get("tab") !== undefined && searchParams.get("tab") !== "") {

            setTabActive(searchParams.get("tab"));
        }
        else {
            setTabActive("0");
        }
    }, [Location, searchParams])

      // Handle tab change and update URL search parameters
    const handleTabChange = (index) => {
        setTabActive(index)
        navigate(`?tab=${index}`, { replace: true })
    }


    // ====================get Commercial Data========================


    // State for commercial data
    const [commercialData, setCommercialData] = useState([]);

     // Fetch commercial data
    const getCommercialData = async () => {
        openBackdrop();
        try {
                // Make a GET request to fetch commercial data
            const res = await privateAxios.get(`/procureadmin/getAllFranchiseRelatedAllTemplates/${orgId}`);
            const { data } = res;
            setCommercialData(data);// Set commercial data

           
        } catch (error) {
            showToast("error", "Error Getting Commercial Data"); // Show error toast
        }
        closeBackdrop(); // Close backdrop
    }

      // Fetch product and commercial data when orgData changes
    useEffect(() => {
        getProductData();
        getCommercialData();
    }, [orgData])



    // ====================handle add product ================
  // Handle add product
    const handleAddProduct = async (data) => {
        openBackdrop(); // Open backdrop
        try {
            const { products, categories } = data;
      // Make a POST request to add approved categories
            const catRes = await privateAxios.post(`/procureadmin/addApprovedCategories/${orgId}`, categories);
            if (catRes.status === 200) {
                  // Make a POST request to add approved products
                const prodRes = await privateAxios.post(`procureadmin/addApprovedProducts/${orgId}`, products);
                if (prodRes.status === 200) {
                    showToast("success", "Product Added Successfully"); // Show success toast
                }
                else {
                    showToast("error", "Error Adding Product");// Show error toast
                }
            }
            else {
                showToast("error", "Error Adding Category");// Show error toast

            }
            getProductData();// Refresh product data
            handleAddProductModal(); // Close add product modal
        }
        catch (error) {
            showToast("error", error.message); // Show error toast
        }
        closeBackdrop(); // Close backdrop
    }


    // ========================= due date modal=========================

 // State for due date modal visibility and errors
    const [dueDate, setDueDate] = useState("");
    const [dueDateError, setDueDateError] = useState("");
    const [dueDateModal, setDueDateModal] = useState(false);

      // Toggle due date modal visibility
    const handleChangeDueDateModal = () => {
        setDueDateModal(prev => !prev);
    }

 // Handle change due date
    const handleChangeDueDate = async () => {// Set error if date is not selected
        openBackdrop();// Open backdrop
        try {
            if (dueDate === "") {
                setDueDateError("Please Select Date");
                closeBackdrop();
                return;
            }
              // Calculate difference in days between new and current due date
            const differenceInMilliseconds = new Date(dueDate) - new Date(orgData.dueDate.split("T")[0]);
            const tempDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

              // Make a POST request to renew subscription
            const res = await privateAxios.post(`/subscription/renewSubcription/${orgId}`,
                {
                    type: "Ultra",
                    price: "$90",
                    days: tempDays
                }
            );
            if (res.status === 200) {
                showToast("success", "Due Date Changed Successfully"); // Show success toast
            }
            else {
                showToast("error", "Error Changing Due Date"); // Show error toast
            }
            getOrgData(); // Refresh organization data
            handleChangeDueDateModal(); // Close due date modal
        } catch (error) {
            showToast("error", error.message);// Show error toast
        }
        closeBackdrop(); // Close backdrop
    }



    // ========================= add commercial template=========================

 // State for add commercial template modal visibility
    const [addCommercialTemplateModal, setAddCommercialTemplateModal] = useState(false);

 // Toggle add commercial template modal visibility
    const handleAddCommercialTemplateModal = () => {
        setAddCommercialTemplateModal(prev => !prev);
    }

        // Handle add commercial template
    const handleAddCommercialTemplate = async (data) => {
        openBackdrop();
        try {
            const { selectedTemplate } = data;
            const payload = {
                ids: selectedTemplate.map((temp) => temp.id),
                orgId: orgId,
            }
              // Make a POST request to request templates in organization template
            const res = await privateAxios.post(`/procureadmin/requestTemplateInOrganisationTemplate`, payload)
            showToast("success", "Template Requested Successfully");// Show success toast

        } catch (error) {
            console.log(error)
            showToast("error", "Error Requesting Template"); // Show error toast
        }

        closeBackdrop();// Close backdrop
        handleAddCommercialTemplateModal(); // Close add commercial template modal
        getCommercialData();// Refresh commercial data
    }


    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain} style={{ padding: "20px" }}>

                <div className={orgAppStyle.header}>
                    <h2>
                        {orgData.organisationName}
                        {/* Org Name */}
                    </h2>
                    <ToggleSwitch 
                    checked={!orgData.isDisable} name={`checkBox_org`}
                    //  onChange={() => handleOrgCheckBox()}
                     onChange={() => handleOrgCheckBox(orgData.id, orgData.kamId, !orgData.isDisable)}
                     />

                    <Tooltip title="Help">
                        <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081301-organization-detail">
                            <HelpOutlineOutlined sx={{ cursor: 'pointer' }} />
                        </a>
                    </Tooltip>
                </div>

                <div className={orgAppStyle.orgDetailsCont}>


                    <div className={orgAppStyle.orgDeailsWrapper}>
                        <div className={orgAppStyle.OrgRow}>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    Organisation Name
                                </p>
                                <h5>{orgData.organisationName}</h5>
                            </div>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    Kam Phone No.
                                </p>
                                <h5>{orgData.phone}</h5>
                            </div>


                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    Due Date
                                </p>
                                <h5>{orgData?.dueDate?.split("T")[0]}
                                    <span onClick={handleChangeDueDateModal}>
                                        <EditCalendarIcon />
                                    </span>
                                </h5>
                            </div>
                        </div>
                        <div className={orgAppStyle.OrgRow}>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    KAM Email ID
                                </p>
                                <h5>{orgData.kamEmail}</h5>
                            </div>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    GST / VAT Deatils
                                </p>
                                <h5>{orgData.gst}</h5>
                            </div>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    User Added
                                </p>
                                <h5>{orgData.totalUsers} Users</h5>
                            </div>
                        </div>
                        <div className={orgAppStyle.OrgRow}>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    Managed by
                                </p>
                                <h5>{orgData.kamName}</h5>
                            </div>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    Registered On
                                </p>
                                <h5>{orgData?.registeredOn?.split("T")[0]}</h5>
                            </div>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    Pricing Plan
                                </p>
                                <h5>{orgData.pricingPlan}</h5>
                            </div>
                        </div>


                    </div>
                </div>

                <div className={orgAppStyle.tabCont}>
                    <div className={orgAppStyle.tabSelectorWrapper}>
                        <div style={{ display: "flex", gap: "10px" }}>

                            <div onClick={() => handleTabChange("0")}
                                className={tabActive === "0" ? `${orgAppStyle.tabItem} ${orgAppStyle.active}` : `${orgAppStyle.tabItem}`}>
                                Products
                            </div>
                            <div onClick={() => handleTabChange("1")}
                                className={tabActive === "1" ? `${orgAppStyle.tabItem} ${orgAppStyle.active}` : `${orgAppStyle.tabItem}`}>
                                Commercial
                            </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", height: "90%", width: "auto" }}>
                            {
                                tabActive === "0" &&

                                <button className={orgAppStyle.tabAddBtn} onClick={handleAddProductModal}><span>+</span> Product  </button>
                            }
                            {
                                tabActive === "1" &&

                                <button className={orgAppStyle.tabAddBtn} onClick={handleAddCommercialTemplateModal}><span>+</span> Template  </button>
                            }
                        </div>

                    </div>


                    <div className={orgAppStyle.tabWrapper}>
                        {
                            tabActive === "0" && <ProductsTab openBackdrop={openBackdrop} closeBackdrop={closeBackdrop} showToast={showToast} data={selectedProduct} getCategoryPath={getCategoryPath} getProductData={getProductData} />
                        }
                        {
                            tabActive === "1" && <CommercialTab openBackdrop={openBackdrop} closeBackdrop={closeBackdrop} showToast={showToast} data={commercialData} getCommercialData={getCommercialData} />
                        }
                    </div>

                </div>


            </div>




            <Modal
                open={addProduct}
                onClose={handleAddProductModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>


                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "felx-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Add Product</h2>
                            <span onClick={handleAddProductModal}><CloseIcon /></span>
                        </div>

                        <OrgProductSelector
                            productsArray={selectedProduct}

                            handleAddProductModal={handleAddProductModal}
                            onsubmit={handleAddProduct}
                            orgId={orgId}

                        />

                    </div>



                </div>


            </Modal>


            <Modal
                open={dueDateModal}
                onClose={handleChangeDueDateModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>


                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "felx-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Change Account Expiry Date</h2>
                            <span onClick={() => { handleChangeDueDateModal(); setDueDate("") }}><CloseIcon /></span>
                        </div>

                        <div >
                            <div className={formStyle.inputRow}>
                                <label htmlFor="">Expriry Date</label>
                                <input type="date"
                                    onChange={(e) => setDueDate(e.target.value)}
                                    value={dueDate} style={{ padding: "0 10px", textIndent: "0px" }} onBlur={(e) => {
                                        if (e.target.value === "") {
                                            setDueDateError("Please Select Date");
                                        }
                                        else {
                                            setDueDateError("");
                                        }
                                    }}
                                    min={orgData?.dueDate?.split("T")[0]}



                                />
                                <span>{dueDateError}</span>
                            </div>
                        </div>

                        <div className={workSpaceStyle.d_flex_end} style={{ gap: "10px" }}>
                            <button className={`${workSpaceStyle.modalBtn}`} style={{ color: "#16469D" }} onClick={() => { handleChangeDueDateModal(); setDueDate("") }}> Cancel</button>
                            <button className={`${workSpaceStyle.modalBtn}`} style={{ backgroundColor: "#16469D", color: "white" }} onClick={handleChangeDueDate}>Change Date</button>

                        </div>


                    </div>



                </div>


            </Modal>


            {/* commercial  */}


            <Modal
                open={addCommercialTemplateModal}
            // onClose={handleAddMiddleWareModal}

            >

                <TemplateSelector
                    templateArray={commercialData}
                    handleModal={handleAddCommercialTemplateModal}
                    onsubmit={handleAddCommercialTemplate}
                    showToast={showToast}

                />


            </Modal>




            {/* commercial*/}






        </>
    )
}

export default OrganisationApproved
