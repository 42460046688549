import { React, useState, useEffect } from 'react'; // Import React and necessary hooks
import { Typography } from '@mui/material';// Import Typography component from Material-UI
import vendorStyle from './VendorDashStyle.module.css'; // Import CSS module for styling
import dashboardStyle from '../Dashboard/Dashboard.module.css'// Import CSS module for styling
import useAuth from 'hooks/useAuth';// Import custom hook for authentication
import Box from '@mui/material/Box';// Import Box component from Material-UI
import AddIcon from '@mui/icons-material/Add';// Import AddIcon from Material-UI
import MoreVertIcon from '@mui/icons-material/MoreVert';// Import MoreVertIcon from Material-UI
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';// Import FolderOutlinedIcon from Material-UI
import Tooltip from '@mui/material/Tooltip';// Import Tooltip component from Material-UI
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';// Import StarOutlineOutlinedIcon from Material-UI
import { useNavigate } from 'react-router-dom';// Import useNavigate hook from react-router-dom for navigation
import { PrivateComponent } from 'api/axios';// Import PrivateComponent from axios for making private requests
import useBackdrop from 'hooks/useBackdrop'; // Import custom hook for backdrop
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';// Import NavigateNextOutlinedIcon from Material-UI

const mytasksData = [
    {
        id: 1,
        name: 'write something',
        level: 'High',
        date: 'Aug 10'
    },
    {
        id: 2,
        name: 'play music',
        level: 'Low',
        date: 'Aug 20'
    },
    {
        id: 3,
        name: 'Play cricket',
        level: 'Medium',
        date: 'Aug 15'
    },
]
const VendorDashboard = () => {
    const { auth } = useAuth()
    const privateAxios = PrivateComponent();
    const navigate = useNavigate();
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const [organisationData, setOrganisationData] = useState([])

    // Function to fetch all organisation data
    const getAllOrgData = async () => {
        openBackdrop();
        try {
            const response = await privateAxios.get(`/vendor/getAllVendorOrganisation`)
                .then((res) => {
                    let data = res.data
                    setOrganisationData(data); // Set organisation data after fetching
                    closeBackdrop(); // Close backdrop after successful operation
                }).catch((error) => {
                    closeBackdrop();// Close backdrop in case of error
                }
                )

        } catch (error) {
            closeBackdrop();// Close backdrop in case of error
        }
    }
    useEffect(() => {
        getAllOrgData(); // Fetch all organisation data on component mount

    }, []);

    // Function to handle click on organisation item
    const handleOrganisationClick = (item) => {
        alert("I got clicked " + item);// Display alert on organisation click
    }

    // Function to handle click on task item
    const handlemytaskClick = (item) => {
        alert("I got clicked " + item.name + " " + item.id);// Display alert on task click
    }

    // Function to determine time of day
    const timeOfDay = () => {
        const currentTime = new Date();
        const currentHour = currentTime.getHours();

        if (currentHour >= 5 && currentHour < 12) {
            return "Morning";
        } else if (currentHour >= 12 && currentHour < 18) {
            return "Afternoon";
        } else {
            return "Evening";
        }
    }

    return (
        <>

            <div className={dashboardStyle.dashboardMain}>
                <div className={dashboardStyle.dashboardHeader}>

                    <div className={dashboardStyle.dashboardHeaderImg}>
                        <div>{auth.name.charAt(0)}</div></div>
                    <div className={dashboardStyle.dashboardHeaderText}>
                        Good {timeOfDay()}, <span> {auth.name}!</span>
                    </div>
                </div>




                <div className={dashboardStyle.dashboardCards}>
                    <div className={dashboardStyle.dashboardCard}>
                        <div className={dashboardStyle.header}>

                            <div onClick={() => navigate('/vendor/organisations')} className={dashboardStyle.headerContent} style={{ cursor: "pointer" }}>
                                <FolderOutlinedIcon />
                                <Typography variant='h6'>Organisations</Typography>
                            </div>
                            <Box className={dashboardStyle.headerIcons}>
                                <Tooltip title='More'><MoreVertIcon /></Tooltip>
                            </Box>
                        </div>

                        {
                            organisationData?.map((item) => {

                                return (
                                    <>
                                        {item.isApproved &&
                                            <div className={vendorStyle.cardrowData} onClick={() => {

                                                item.isApproved && navigate(`/vendor/organisations/${item?.orgName.toLowerCase().replace(/ /g, "-")}/${item.id}`)
                                            }} >
                                                <ul className={vendorStyle.insideCard} >
                                                    <li><StarOutlineOutlinedIcon style={{ color: '#979797' }} />{item?.orgName}</li>
                                                    {/* <li>{item?.gstNumber}</li> */}
                                                    <li ><NavigateNextOutlinedIcon /></li>
                                                </ul>
                                            </div>}

                                    </>
                                )
                            })
                        }

                    </div>
                    <div className={dashboardStyle.dashboardCard}>
                        <div className={dashboardStyle.header}>
                            <div onClick={() => navigate('/vendor/organisations')} className={dashboardStyle.headerContent} style={{ cursor: "pointer" }}>
                                <FolderOutlinedIcon />
                                <Typography variant='h6'>My Tasks</Typography>
                            </div>
                            <Box className={dashboardStyle.headerIcons}>
                                <Tooltip title='Add'><AddIcon /></Tooltip>
                                <Tooltip title='More'><MoreVertIcon /></Tooltip>


                            </Box>
                        </div>
                        {
                            mytasksData.map((item) =>
                                <div className={vendorStyle.cardrowData}>
                                    <div className={vendorStyle.insideCardSingleElement}>
                                        <div className={vendorStyle.insideCardSingleElementChild}>
                                            <StarOutlineOutlinedIcon style={{ color: '#979797' }} />
                                            <button className={vendorStyle.linksInCardRow} onClick={() => handlemytaskClick(item)}>{item.name}</button>
                                        </div>
                                        <div className={vendorStyle.levelInsideCard}>
                                            <span style={{ backgroundColor: item.level === 'Low' ? "#ffff7e" : item.level === 'Medium' ? "rgb(245, 172, 56)" : "rgb(236, 82, 82)" }}>{item.level}</span>
                                        </div>

                                        <div className={vendorStyle.levelInsideCard2}>
                                            <Tooltip className={vendorStyle.toolTipInsideCard} title='date'>{item.date}</Tooltip>
                                        </div>
                                    </div>

                                </div>
                            )
                        }
                    </div>
                </div>
                <BackdropComponent />
            </div>
        </>
    )
}

export default VendorDashboard;