import React, { useState, useEffect } from 'react'
import { Modal, Typography, useScrollTrigger } from '@mui/material';
import Box from '@mui/material/Box';
import dashboardStyle from './SuperAdminDashboard.module.css'
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import Tooltip from '@mui/material/Tooltip';
import headerStyle from 'components/Header/Header.module.css'
import { PrivateComponent } from 'api/axios';
import useToast from 'hooks/useToast';
import useBackdrop from 'hooks/useBackdrop';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


// Define your component
const SuperAdminDashboard = () => {
// React hooks
    const navigate = useNavigate();
    const { Toast, showToast } = useToast();
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const privateAxios = PrivateComponent();
    const { auth } = useAuth();


    // ======================get all organisation requests======================

    // State for requested organizations
    const [requestedOrgnisation, setRequestedOrganisation] = useState([]);

 // Function to get all requested organizations
    const getRequestedOrganisation = async () => {
        openBackdrop();
        try {
            const response = await privateAxios.get('/procureadmin/getAllOrganisationRequests');
            setRequestedOrganisation(response.data);
        }
        catch (error) {
            showToast("error", "Error getting requested organisation")
        }
        closeBackdrop();
    }

    // ======================get all approved organisation======================
  // State for approved organizations
    const [approvedOrganisation, setApprovedOrganisation] = useState([]);

     // Function to get all approved organizations
    const getApprovedOrganisation = async () => {

        openBackdrop();
        try {
            const response = await privateAxios.get('/procureadmin/getAllApprovedOrganisations');
            setApprovedOrganisation(response.data);// Set approved organizations
        }
        catch (error) {
            showToast("error", "Error getting approved organisation"); // Show toast on error
        }
        closeBackdrop();// Close backdrop

    }
    // ======================get all approved organisation======================

      // Fetch requested and approved organizations on component mount
    useEffect(() => {
        getRequestedOrganisation();
        getApprovedOrganisation();
    }, []);


   // Function to determine time of day
    const timeOfDay = () => {
        const currentTime = new Date();
        const currentHour = currentTime.getHours();

        if (currentHour >= 5 && currentHour < 12) {
            return "Morning";
        } else if (currentHour >= 12 && currentHour < 18) {
            return "Afternoon";
        } else {
            return "Evening";
        }
    }


    return (

        <>
            <Toast />
            <BackdropComponent />
            <div className={dashboardStyle.dashboardMain}>
                <div className={dashboardStyle.dashboardHeader}>

                    {auth.profilePic?.newImage ?
                        <img className={headerStyle.smallProfileIcon} style={{ width: "60px", height: '60px' }}
                            src={`${process.env.REACT_APP_BASEURL}/adminuser/file/${auth.profilePic.newImage}`}
                        />
                        :
                        <div className={dashboardStyle.dashboardHeaderImg}>
                            <div>{auth?.name?.charAt(0)}</div></div>
                    }


                    <div className={dashboardStyle.dashboardHeaderText}>
                        Good {timeOfDay()}, <span> {auth.name}!</span>
                    </div>
                </div>

                <div className={dashboardStyle.dashboardCards}>
                    <div className={dashboardStyle.dashboardCard}>
                        <div className={dashboardStyle.header}>
                            <Box className={dashboardStyle.headerContent}>
                                <ListTwoToneIcon />
                                <Typography variant='h6'>Organsation Requests</Typography>
                            </Box>
                            <Box className={dashboardStyle.headerIcons}>
                                <button variant='text' sx={{ textTransform: 'initial' }} onClick={() => navigate(`/superadmin/organisation-requests`)} >
                                    View all
                                </button>
                            </Box>
                        </div>
                        <div className={dashboardStyle.dashboardCardCont}>
                            {
                                requestedOrgnisation.map((data, index) => (
                                    <div className={dashboardStyle.cardData} onClick={()=>navigate(`/superadmin/organisation/organisation-approval/${data.kamId}/${data.id}`)}>
                                        <Typography variant='h6'>{data.organisationName}</Typography>
                                        <Tooltip title='See More'><KeyboardArrowRightIcon /></Tooltip>
                                    </div>
                                ))
                            }

                        </div>

                    </div>
                    <div className={dashboardStyle.dashboardCard}>
                        <div className={dashboardStyle.header}>
                            <Box className={dashboardStyle.headerContent}>
                                <FolderOutlinedIcon />
                                <Typography variant='h6'>Organisations</Typography>
                            </Box>
                            <Box className={dashboardStyle.headerIcons}>

                                <Tooltip title='More'><MoreVertIcon /></Tooltip>
                            </Box>
                        </div>
                        {
                            approvedOrganisation.map((data, index) => (
                                <div className={dashboardStyle.cardData} onClick={()=>navigate(`/superadmin/organisation/organisation-approved/${data.kamId}/${data.id}`)}>
                                    <Typography variant='h6'>{data.organisationName}</Typography>
                                    <Tooltip title='See More'><KeyboardArrowRightIcon /></Tooltip>
                                </div>
                            ))
                        }
                    </div>
                    <div className={dashboardStyle.dashboardCard}>
                        <div className={dashboardStyle.header}>
                            <Box className={dashboardStyle.headerContent}>
                                <FolderOutlinedIcon />
                                <Typography variant='h6'>Announcements</Typography>
                            </Box>
                            <Box className={dashboardStyle.headerIcons}>
                                <Tooltip title='Add'><AddIcon /></Tooltip>
                                <Tooltip title='More'><MoreVertIcon /></Tooltip>
                            </Box>
                        </div>
                    </div>
                    <div className={dashboardStyle.dashboardCard}>
                        <div className={dashboardStyle.header}>
                            <Box className={dashboardStyle.headerContent}>
                                <FolderOutlinedIcon />
                                <Typography variant='h6'>My Tasks</Typography>
                            </Box>
                            <Box className={dashboardStyle.headerIcons}>
                                <Tooltip title='Add'><AddIcon /></Tooltip>
                                <Tooltip title='More'><MoreVertIcon /></Tooltip>
                            </Box>
                        </div>
                    </div>
                    <div className={dashboardStyle.dashboardCard}>
                        <div className={dashboardStyle.header}>
                            <Box className={dashboardStyle.headerContent}>
                                <FolderOutlinedIcon />
                                <Typography variant='h6'>franchises</Typography>
                            </Box>
                            <Box className={dashboardStyle.headerIcons}>
                                <Tooltip title='Add'><AddIcon /></Tooltip>
                                <Tooltip title='More'><MoreVertIcon /></Tooltip>
                            </Box>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default SuperAdminDashboard
