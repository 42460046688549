import React, { useState, useEffect } from 'react'
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import revisionEditStyle from '../../pages/RevisionEdit/RevisionEdit.module.css'
import useBackdrop from 'hooks/useBackdrop'
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatRow from 'components/ChatRows/ChatRow';
import TempleteDescriptionTab from './TempleteDescriptionTab';
import TempleteDucumentsTab from './TempleteDocumentsTab';
import TempleteQuestionsTab from './TempleteQuestionsTab'
import TaskStyle from "../../pages/TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import { createExcel } from 'utility/excelUtils'

// =======================icons===========================

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';



// =====================mui compoents====================
import Tooltip from '@mui/material/Tooltip';
import { Typography, Button, Badge } from '@mui/material';
import Modal from '@mui/material/Modal';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import useToast from 'hooks/useToast';
import BackButton from 'components/BackButton/BackButton';



// =====================mui compoents====================

// Importing necessary hooks and components
const QuoteSentCommercialTempleteRevisionView = () => {

  const { showToast, Toast } = useToast(); // Toast notification hook
  const privateAxios = PrivateComponent(); // Axios instance with private configurations
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Backdrop management hooks
  const location = useLocation(); // Hook to get the current location
  const { state } = location; // Destructure state from location
  const { taskId, tempId, templeteName, revisionId, productId } = useParams(); // Destructure URL parameters
  const navigate = useNavigate(); // Hook to navigate programmatically
  const { auth } = useAuth(); // Hook to get authentication information
  const [revisionDetails, setRevisionDetails] = useState(null); // State for revision details


  // ================Back Button========
  const handleBackButton = () => {
    navigate(-1); // Navigate back to the previous page
  }


  // ==============tab selector and state=================
  const searchParams = new URLSearchParams(location.search);// Parse URL search parameters
  const [tabActive, setTabActive] = useState("0");// State for active tab
  useEffect(() => {
    if (searchParams.get("tab") !== undefined && searchParams.get("tab") !== null) {

      setTabActive(searchParams.get("tab")); // Set active tab based on URL parameter
    }
  }, [Location, searchParams]) // Effect depends on location and searchParams

  const handleTabChange = (index) => {

    setTabActive(index)// Update active tab
    navigate(`?tab=${index}`, { replace: true }) // Update URL without reloading
  }


  // =============header three dot=============


  const [headerThreeDot, setHeaderThreeDot] = useState(false) // State for header three dot menu
  const handleheaderThreeDot = () => {
    setHeaderThreeDot(prev => !prev);// Toggle header three dot menu
  }

  // =================multisave ================


  const [multiSave, setMultiSave] = useState(false);// State for multi-save option

  const handleMultiSave = () => {
    setMultiSave(prev => !prev)// Toggle multi-save option
  }

  // ==================chat state =================


  const [chatSlider, setChatSlider] = useState(false); // State for chat slider
  const [revisionChats, setRevisionChats] = useState([]); // State for revision chats
  const [chatValue, setChatValue] = useState(""); // State for chat input value

  const handleChatSlider = () => {
    setChatSlider(prev => !prev);// Toggle chat slider
  }

  const handleChatSend = async (e) => {
    e.preventDefault();// Prevent default form submission
    if (chatValue !== "") {
      try {
        const res = await privateAxios.post(`/commercial/addRemarkOncommercialRevision/${auth.userData.id}/${revisionId}`, { remark: chatValue })
        if (res.status === 200) {
          getRevisionChat();// Refresh chat after sending
          setChatValue("");// Clear chat input
        }
      } catch (error) {
        console.log(error);
        showToast("error", "error adding Comment");// Show error toast
      }
    }

  }

  const getRevisionChat = async () => {
    handleChatNotification();// Trigger chat notification
    try {
      const res = await privateAxios.get(`/commercial/getRemarksOfcommercialRevision/${revisionId}`);// Get chat remarks
      setRevisionChats(res.data);// Set chat remarks state
    } catch (error) {
      console.log(error);
      showToast("error", "error getting Chats");// Show error toast
    }
  }

  useEffect(() => {
    if (chatSlider) {
      getRevisionChat();// Fetch chat remarks if chat slider is open
    }
  }, [chatSlider])


  // ==================rename  state =================


  const [renameText, setRenameText] = useState(""); // State for rename input
  const [saveAs, setSaveAs] = useState(false); // State for save-as option
  const [renameModal, setRenameModal] = useState(false); // State for rename modal



  const handleRenameModal = () => {
    setRenameModal(prev => !prev);// Toggle rename modal
  }



  // ============document states=========================

  const [documents, setDocuments] = useState([]);// State for documents
  const [addDocumentModal, setAddDocumentModal] = useState(false);// State for add document modal
 
  const handleAddDocumentModal = () => {
    setAddDocumentModal(prev => !prev);// Toggle add document modal
  }
  const handleDocumentUpload = async (documentName, documentFiles, docType) => {

    try {
      const fd = new FormData(); // Create a new FormData object
      for (let i = 0; i < documentFiles.length; i++) {
        fd.append(`files`, documentFiles[i]);// Append each file to FormData
      }
      fd.append("name", documentName); // Append document name
      fd.append("docType", docType ? "CONFIDENTIAL" : "NORMAL");// Append document type
      fd.append("uploadedBy", auth.roles);// Append uploader role
      const res = await privateAxios.post(`/commercial/uploadCommercialDocuments/${revisionId}`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      const { data } = res;
      console.log(data);
      await getDataProcure();// Refresh data after upload
      showToast("success", "Document Uploaded Successfully");// Show success toast
    } catch (error) {
      showToast("error", "Error Uploading Document");// Show error toast
    }
  }


  const [revisionDescription, setRevisionDescription] = useState("");// State for revision description


  // ===================Question State========================

  const [questionRows, setQuestionRows] = useState([]);// State for question rows

  const handleAddQuestionRow = () => {
    const newRow = {
      id: questionRows[questionRows.length - 1].id + 1,
      name: "",
      unit: "text",
      value: "",
      remark: "",
      vendorRemark: "",
      expectdAnswer: ""

    }

    setQuestionRows((prev) => {
      return [
        ...prev,
        newRow
      ]
    })
  }

  const handleQuestionRowDelete = (id) => {
    setQuestionRows((prev) => {
      return prev.filter((row) => row.id !== id);// Filter out the row to be deleted
    })

  }
  const handleInputChange = (e, rowIndex) => {
    const { value, name } = e.target;
    setQuestionRows((prev) => {
      return prev.map((row, index) => {
        if (index === rowIndex) {
          if (name === "unit") {
            return {
              ...row,
              [name]: value,
              expectdAnswer: value
            }
          }
          else {
            return {
              ...row,
              [name]: value,

            }
          }

        }
        else {
          return row;
        }
      })
    })

  }


  // ===================get data================


  const getDataEcom = () => {

    try {

      setRevisionDetails({
        name: "com rev 2",
        productId: productId,
        productName: "SCREENS AND VIBRO FEEDERS",
        description: {
          name: "size"
        },
        questions: [
          {
            id: 1,
            name: "Some Question",
            unit: "date",
            value: "Some Value",
            remark: "Sosadsa dsa fa faf",
            vendorRemark: "",
            expectdAnswer: ""

          },
          {
            id: 2,
            name: "Some Question2",
            unit: "text",
            value: "Some Value2",
            remark: "Sosadsa dsa fa faf2",
            vendorRemark: "",
            expectdAnswer: ""
          },

        ],
        documents: [
          {
            name: "doc confidential",
            url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            docType: "CONFIDENTIAL"
          },
          {
            name: "doc normal",
            url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            docType: "NORMAL"
          }

        ]
      })


      setQuestionRows([
        {
          id: 1,
          name: "Some Question",
          unit: "date",
          value: "Some Value",
          remark: "Sosadsa dsa fa faf",
          vendorRemark: "",
          expectdAnswer: ""

        },
        {
          id: 2,
          name: "Some Question2",
          unit: "text",
          value: "Some Value2",
          remark: "Sosadsa dsa fa faf2",
          vendorRemark: "",
          expectdAnswer: ""
        },

      ])

      setDocuments([
        {
          name: "doc confidential",
          url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          docType: "CONFIDENTIAL"
        },
        {
          name: "doc normal",
          url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          docType: "NORMAL"
        }

      ])

      setRevisionDescription("size")

    } catch (error) {

    }


  }

  const getDataProcure = async () => {
    openBackdrop();// Show backdrop
    try {
      const res = await privateAxios.get(`/technicalSpecification/getQuoteSentRevisionById/${revisionId}`)

      const { data } = res;
      console.log(data);
      setRevisionDetails({
        name: data.name,
        productId: data.productId,
        productName: data.productName,
        description: {
          name: data.description.name
        },
        questions: data.questions,
        documents: data.documents,
      });
      setQuestionRows(data.questions); // Set question rows state
      setDocuments(data.documents); // Set documents state
      setRevisionDescription(data.description.name); // Set revision description state
      closeBackdrop(); // Hide backdrop
    } catch (error) {
      console.log(error);
      closeBackdrop() // Hide backdrop in case of error
    }
  }


  useEffect(() => {
    if (revisionId === "0") {

      getDataEcom();// Fetch e-commerce data if revisionId is 0
    }
    else {
      getDataProcure(); // Fetch procurement data otherwise
    }
  }, [])


  // ===================saveas revisions================

  const handleSaveAsRevision = async (e) => {
    openBackdrop();// Show backdrop
    e.preventDefault(); // Prevent default form submission
    try {

      const updatedQuestions = questionRows.map((ques) => {
        let que = ques;
        delete que.id;
        return que;
      })
      const updatedDocuments = documents.map((doc) => {
        let dd = doc;
        delete dd.id;
        return dd;
      })
      let payload = {}
      if (revisionId === 0) {
        payload = { ...revisionDetails, name: renameText, description: { name: revisionDescription }, questions: updatedQuestions }

      }
      else {
        payload = { ...revisionDetails, name: renameText, description: { name: revisionDescription }, documents: updatedDocuments, questions: updatedQuestions }

      }

      const res = await privateAxios.post(`/commercial/addCommercialRevision/${auth.orgId}/${taskId}/${tempId}`, payload)
      const { data } = res;
      handleRenameModal(); // Hide rename modal
      showToast("success", "Revision Saved"); // Show success toast
      handleBackButton(); // Navigate back
      closeBackdrop(); // Hide backdrop

    } catch (error) {
      showToast("error", "Error Saving Revision"); // Show error toast
      handleRenameModal(); // Hide rename modal
      closeBackdrop(); // Hide backdrop
    }
  }


  // ==================save Revisions========================

  const handleSaveRevision = async () => {
    openBackdrop();// Show backdrop

    try {

      const updatedQuestions = questionRows.map((ques) => {
        let que = ques;
        delete que.id;
        return que;
      })
      const updatedDocuments = documents.map((doc) => {
        let dd = doc;
        delete dd.id;
        return dd;
      })
      const payload = { name: revisionDetails.name, description: { name: revisionDescription }, documents: updatedDocuments, questions: updatedQuestions }




      const res = await privateAxios.post(`/commercial/updateCommercialRevision/${revisionId}`, payload)
      const { data } = res;
      showToast("success", "Revision Saved"); // Show success toast
      getDataProcure(); // Refresh data
      closeBackdrop(); // Hide backdrop

    } catch (error) {
      showToast("error", "Error Saving Revision"); // Show error toast
      closeBackdrop(); // Hide backdrop
    }
  }


  // =============change revision Name==============================

  const handleChangeTempleteName = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const res = await privateAxios.post(`/commercial/renameCommercialRevision/${revisionId}?name=${renameText}`)
      if (res.status == 200) {

        getDataProcure(); // Refresh data
        showToast("success", "Name Update Successful"); // Show success toast
        handleRenameModal(); // Hide rename modal
      }
    } catch (error) {
      showToast("error", "Name Update failed");// Show error toast

    }

  }


  // ============duplicate revision==============================
  const [duplicateNameModal, setDuplicateNameModal] = useState(false); // State for duplicate name modal
  const [duplicateName, setDuplicateName] = useState(""); // State for duplicate name input
 
  const handleDuplicateNameModal = (e) => {
    setDuplicateNameModal(prev => !prev); // Toggle duplicate name modal
  }

  const handleDuplicateRevisison = async (e) => {
    e.preventDefault(); // Prevent default form submission
    try {
      const res = await privateAxios.post(`/commercial/duplicateCommercialRevision/${revisionId}?name=${duplicateName}`);
      showToast("success", "Duplicate Revision Created"); // Show success toast
      handleBackButton(); // Navigate back
      handleDuplicateNameModal(); // Hide duplicate name modal
    } catch (error) {
      showToast("error", "Error Creating Revision Duplicate"); // Show error toast
      handleDuplicateNameModal(); // Hide duplicate name modal
    }

  }

  // ================delete Revision================================

  const [deleteModal, setDeleteModal] = useState(false);// State for delete modal
  const handleDeleteModal = () => {
    setDeleteModal(prev => !prev); // Toggle delete modal
  }

  const handleDeleteRevision = async () => {
    try {
      const res = privateAxios.delete(`/commercial/deleteCommercialRevision/${revisionId}`);
      handleBackButton();// Navigate back
    } catch (error) {
      showToast("error", "Error deleting Revision"); // Show error toast
    }
  }


  // =================download revision==============================

  const handleDownloadRevision = () => {

    // extracting text from Richeditor string
    let tempElement = document.createElement('div');
    tempElement.innerHTML = revisionDescription;
    let extractedText = tempElement.textContent;
    extractedText = extractedText.trim();

    // taking nessarry feilds from attributes
    const questions = questionRows.map((attri) => {
      const modifiedAttri = { ...attri };
      delete modifiedAttri.id;
      if (revisionId !== 0) {
        delete modifiedAttri.revisedValue;
        delete modifiedAttri.vendorRemark;

      }
      return { ...modifiedAttri };

    });
    let revisionDocument = []
    if (revisionId === "0") {

      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })

    }
    else {
      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })
    }


    createExcel([[{ description: extractedText }], questions, revisionDocument], (revisionDetails.name || "Revision 0"))
  }


  // =================download revision==============================

  // ==============send to Kam=====================================
  
  const [comments, setComments] = useState(""); // State for comments input
  const [formError, setFormError] = useState({}); // State for form errors
  const [approvalModal, setApprovalModal] = useState(false); // State for approval modal

  const handleApprovalModal = (data) => {
    setApprovalModal(prev => !prev);// Toggle approval modal
  }

  const handleAppovalSubmit = async (e) => {
    e.preventDefault();// Prevent default form submission
   

    try {
      await privateAxios.post(`/commercial/sendCommercialRevisionForApprovalToKAM/${auth.userData.id}/${revisionId}`, { comments: comments }).then((res) => {
        handleApprovalModal(); // Hide approval modal
        showToast("success","Revision Sent For Approval");// Show success toast
      }).catch((err) => {
        showToast("error","Error Sendind Revision to Kam"); // Show error toast
      })
    } catch (error) {
      showToast("error","Error Sendind Revision to Kam");// Show error toast
    }
  }

// =====================chat notification========================
const [reRender, setReRender] = useState(false);// State to trigger re-render

const handleChatNotification = () => {
    setReRender(prev => !prev);// Toggle re-render states

}

  return (
    <>
      <BackdropComponent />
      <Toast />

      <div className={workSpaceStyle.workSpaceMain}>

        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1> <BackButton/>{revisionDetails?.name || "No Name"}</h1>
            <p>{revisionDetails?.productName || "Dafault"}</p>

          </div>

          <div className={workSpaceStyle.workSpaceHeaderRight}>

            <div className={workSpaceStyle.workSpaceThreeDotVertical} style={{ position: "relative" }}>

              <span className={workSpaceStyle.workSpaceHeaderRevisionMore}
                onClick={handleheaderThreeDot}
              >
                <Tooltip title='more'><MoreVertIcon /></Tooltip>

              </span>
              {
                headerThreeDot
                &&
                <ThreeDotDropDown toggle={handleheaderThreeDot}>
                  
                  <li
                    onClick={() => { handleDownloadRevision(); handleheaderThreeDot() }}
                  ><span><FileDownloadOutlinedIcon /></span>Download</li>
                  {revisionId !== "0" &&
                    <li
                      onClick={() => { handleheaderThreeDot(); handleDeleteModal(); }}
                    ><span><DeleteOutlineIcon /></span>Delete</li>

                  }
                </ThreeDotDropDown>

              }
            </div>
            <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
              <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
            </div>
          </div>

        </div>

        <div className={revisionEditStyle.tabHeaderMain}>
          <div
            onClick={() => handleTabChange("0")}
            className={tabActive === "0" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Description</div>
          <div
            onClick={() => handleTabChange("1")}
            className={tabActive === "1" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Questions</div>
          <div
            onClick={() => handleTabChange("2")}
            className={tabActive === "2" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Documents</div>
        </div>

        <div className={revisionEditStyle.tabBodyMain} >
          {tabActive === "0" && <TempleteDescriptionTab  revisionDescription={revisionDescription} />}
          {tabActive === "1" && <TempleteQuestionsTab questionRows={questionRows}  />}
          {tabActive === "2" && <TempleteDucumentsTab  handleAddDocumentModal={handleAddDocumentModal} getDataProcure={getDataProcure} documents={documents} handleDocumentUpload={handleDocumentUpload} />}

        </div>

      </div>

      <Modal
        open={renameModal}
        onClose={handleRenameModal}>
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={!saveAs ? handleChangeTempleteName : handleSaveAsRevision} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}>
                {!saveAs ? "Rename" : "Save As"}</h3>
              <div onClick={handleRenameModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <input type="text" value={renameText} onChange={(e) => setRenameText(e.target.value)} placeholder='Revision Name' />
            </div>

            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>
              <p onClick={() => { setRenameText(""); handleRenameModal() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

          </form>

        </div>
      </Modal>

      <Modal
        open={duplicateNameModal}
        onClose={handleDuplicateNameModal}
      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={handleDuplicateRevisison} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><PersonAddAltOutlinedIcon /></span>Rename</h3>
              <div onClick={handleDuplicateNameModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <input type="text" value={duplicateName} onChange={(e) => setDuplicateName(e.target.value)} placeholder='Rename Name' required />
            </div>

            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>
              <p onClick={() => { setDuplicateName(""); handleDuplicateNameModal() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

          </form>

        </div>
      </Modal>

      <ChatPannel
        open={chatSlider}
        onClose={handleChatSlider}
      >
        <>
          <ChatHeader onClose={handleChatSlider}
            downloadData={revisionChats.map((chat) => { return { name: chat.name, date: chat.createdAt, chat: chat.remark } })
            }
            helpData={{ link: "tosomewhere" }} 
            chatName={revisionDetails?.name}
            >
            <h3>{revisionDetails?.name}</h3>
          </ChatHeader>

          <ChatRows>
            {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
              return (

                <ChatRow key={index} name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} email={rev.email} />
              )
            })
              :
              <p style={{ textAlign: "center" }}>
                No Previous Chat
              </p>
            }

          </ChatRows>
          <ChatInputField onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />
        </>
      </ChatPannel>

      <Modal
        open={deleteModal}
        onClose={handleDeleteModal} >

        <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', width: '375px' }}>
          <form action="" style={{ maxWidth: '100%' }} >

            <div className={TaskStyle.modalKam}>
              <Typography variant="h1">Delete Document</Typography>
              <Typography variant="h6">Are you sure you want to delete this Revision?</Typography>

              <div className={TaskStyle.kamModalButton}>
                <Button onClick={() => { handleDeleteRevision(); handleDeleteModal() }} variant="contained">Delete</Button>
                <p onClick={handleDeleteModal}>Cancel</p>
              </div>

            </div>

          </form>
        </div>
      </Modal>

      <Modal
        open={approvalModal}
        onClose={handleApprovalModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={handleAppovalSubmit} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >
              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><PersonAddAltOutlinedIcon /></span>Send For Approval</h3>
              <div onClick={handleApprovalModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <label htmlFor=""> Comments if Any:</label>
              <textarea style={{ paddingTop: "10px", height: "120px" }} onChange={(e) => setComments(e.target.value)} value={comments}></textarea>
              <span>{formError.workSpace}</span>
            </div>

            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "40px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Send </button>
              <p onClick={() => {setComments(""); handleApprovalModal() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

            <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span>
          </form>

        </div>
      </Modal>

    </>
  )
}

export default QuoteSentCommercialTempleteRevisionView
