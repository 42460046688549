import { useState, useEffect, useRef } from 'react';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import TaskDisplay from './TaskTable/TaskDisplay';
import TaskDisplay2 from './TaskTable/TaskDisplay2';
import useAuth from 'hooks/useAuth';
import { PrivateComponent } from 'api/axios'
import Modal from '@mui/material/Modal';
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import CloseIcon from '@mui/icons-material/Close';
import TaskPannel from '../CreateTask/TaskPannel/TaskPannel';
import EditTaskPannel from '../EditCreateTask/EditTaskPannel/EditTaskPannel';
import useBackdrop from 'hooks/useBackdrop';
import TaskStyle from "../TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import Alert from '@mui/material/Alert';
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import FetchUsersComponent from 'components/FetchUsersComponent/FetchUsersComponent'

// Array of user emails for demo purposes

const Mycolor = [
  {
    email: 'surajjadhav4621@gmail.com',
  },
  {
    email: 'pranjai4621@gmail.com',
  },
  {
    email: 'ravinder4621@gmail.com',
  },
  {
    email: 'prathamesh@gmail.com',
  },
]


const TechnicalSpecification = ({ currentUser }) => {

  // Custom hooks and state variables
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Custom hook for backdrop
  const [createTaskModal, setCreateTaskModal] = useState(false); // State for create task modal
  const { auth } = useAuth(); // Authentication hook
  const privateAxios = PrivateComponent(); // Custom Axios instance
  const emailRef = useRef(); // Ref for email input

  const [tabOpen, settabOpen] = useState({// State for tab open/close status
    kam: false,
    admin: false,
    user: false
  })
  const [editTaskModal, setEditTaskModal] = useState(false)// State for edit task modal

  // Form data and errors
  const [formData, setFormData] = useState({
    email: "",
    accessRole: "",
    designation: "",
  })

  const [formError, setFormErrors] = useState({
    email: "",
    accessRole: "",
    designation: "",
    formError: ""
  })

  // Edit data state
  const [editData, setEditData] = useState({})

  // Tab show states
  const [tabShow, setTabShow] = useState({
    KAM: true,
    USER: true,
    ADMIN: true,
  })

  // Tab label show states
  const [tabLabelShow, setLabelTabShow] = useState({
    KAM: true,
    USER: true,
    ADMIN: true,
  })
  // Tab assign show states
  const [tabAssignShow, setAssignTabShow] = useState({
    KAM: true,
    USER: true,
    ADMIN: true,
  })

  // Search value state
  const [searchValue, setSearchValue] = useState("")

  // User data state
  const [userData, setUserData] = useState([])

  // Add KAM modal state
  const [addKamModal, setaddKamModal] = useState(false);

  // Workspace dropdown state
  const [workspacedropDownOpen, setWorkspaceDropDownOpen] = useState(false)

  // Workspace selected state
  const [workSpaceSelected, setWorkSpaceSelected] = useState("ALL");

  // Total user state
  const [totalUser, setTotalUser] = useState("");

  // Priority data state
  const [priorityData, setPriorityData] = useState([]);

  // Assign data state
  const [assignData, setAssignData] = useState([]);

  // Function to handle create task modal
  const handlecreateTaskModal = () => {
    setCreateTaskModal(prev => !prev)
  }

  // Function to handle edit task modal
  const handleEditTaskModal = () => {
    setEditTaskModal(prev => !prev)
  }

  // Function to handle add KAM modal
  const handleaddKamModal = () => {
    setaddKamModal(prev => !prev);

    // Reset form data and errors
    setFormData({
      email: "",
      accessRole: "",
      designation: "",

    }
    )

    setFormErrors({
      email: "",
      accessRole: "",
      designation: "",
      formError: ""
    })

  }

  // Function to handle form input changes
  const handleInput = (e) => {
    setFormData(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      }
    })

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: '',
      formError: "",
    }));

  }


  // Function to handle workspace sorter
  const handleWorkspaceSorter = (value) => {
    setWorkspaceDropDownOpen(false);
    setWorkSpaceSelected(value);

  }

  //------------------------------------- get all ts tasks-----------------------------------
  // State to hold fetched data
  const [data, setData] = useState([]); // State to hold fetched data

  // Function to fetch all tasks
  const getAllTasks = async () => {
    openBackdrop();// Function to open backdrop/loader

    try {
      // Check user role
      if (auth.roles === "ADMIN") {
        const response = await privateAxios.get(`/task/getTaskByRoles`)
          .then((res) => {
            setData(res.data);// Set fetched data

            if (res.status === 200) {
              const data = res.data;
              closeBackdrop(); // Close backdrop/loader
            }
            closeBackdrop(); // Close backdrop/loader

          }).catch((error) => {
            closeBackdrop(); // Close backdrop/loader
          }
          )
      } else {
        const response = await privateAxios.get(`/task/getTaskByRoles`)
          .then((res) => {
            setData(res.data);// Set fetched data

            if (res.status === 200) {
              const data = res.data;
              closeBackdrop();
            }
            closeBackdrop();//close loader

          }).catch((error) => {
            closeBackdrop();//close loader
          }
          )
      }
    } catch (error) {
      closeBackdrop();//close loader
    }

  }
  // Call getAllTasks function when component mounts
  useEffect(() => {
    getAllTasks();
  }, []);

  // State for pagination
  const [taskPage, setTaskPage] = useState(1);

  // Function to load more tasks
  const loadMore = async (page, size) => {
    try {
      const response = await privateAxios.get(`/task/getTaskByRoles?pageNo=${page}&pageSize=${size}`)
        .then((res) => {
          setData(prev => {
            return [...prev, ...res.data]
          }); // Concatenate new data with existing data
          setTaskPage(prev => prev + 1);
        }).catch((error) => {
        })
    } catch (error) {
    }
  }


  // ----------------------------filter-Status-----------------------------

  // State for status dropdown
  const [statusDropDownOpen, setStatusDropDownOpen] = useState(false)
  const [statusSelected, setStatusSelected] = useState("All");

  // State for success alert
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  // Function to fetch data based on status filter
  const fetchData = async (value) => {
    setStatusSelected(value)
    setStatusDropDownOpen(false)
    setStatusSelected(value)
    openBackdrop();
    try {
      let param = "ALL"
      switch (value.toLowerCase()) {
        case "to do": param = "TODO";
          break;
        case "in progress": param = "INPROGRESS";
          break;
        case "done": param = "DONE";
          break;
        case "completed": param = "COMPLETED";
          break;
        default: param = "ALL"
      }
      if (param === "ALL") {
        const response = privateAxios.get(`/task/getTaskByRoles`).then((res) => {
          // setStatusDropDownOpen(response.data);
          setData(res.data);
          closeBackdrop();
        }).catch(err => {
          // console.log(err);
          closeBackdrop();
        })
      } else {
        const response = privateAxios.get(`/task/getTaskByStatus?status=${param}`).then((res) => {
          // setStatusDropDownOpen(response.data);
          setData(res.data);
          closeBackdrop();
        })
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      closeBackdrop();
    }
  };





  // --------------------------------------------------------------get all Assign------------------------------------------------
  // State for assign dropdown
  const [assignDropDownOpen, setAssignDropDownOpen] = useState(false)
  const [assignSelected, setAssignSelected] = useState("All");
  const [loadingKam, setLoadingKam] = useState(false);

  const workspaceId = 1;
  const [loggedIn, userList, notLoggedIn] = FetchUsersComponent({ orgId: auth.orgId, workspaceId: workspaceId })




  // --------------------------------------------------------------Filter-Assign-----------------------------------------------------------------
  const fetchData2 = async (email) => {
    setAssignSelected(email)
    openBackdrop();
    try {
      if (email.toLowerCase() === "all") {
        getAllTasks();
        closeBackdrop();
      } else {
        await privateAxios.get(`/task/getTaskByAssigned?email=${email}`).then((res) => {
          setData(res.data);
          closeBackdrop();
        }).catch(err => {
          closeBackdrop();
        })
      }
      setAssignDropDownOpen(false)
      closeBackdrop();
    } catch (error) {
      closeBackdrop();
    }
  };


  // ----------------------------------------------------------------Filter Label----------------------------------------------------------------------
  const [labelDropDownOpen, setLabelDropDownOpen] = useState(false)
  const [labelSelected, setLabelSelected] = useState("All");

  const fetchData3 = async (value) => {
    setLabelDropDownOpen(false)
    setLabelSelected(value)
    openBackdrop();
    try {
      let param = "ALL"
      switch (value.toLowerCase()) {
        case "low": param = "LOW";
          break;
        case "medium": param = "MEDIUM";
          break;
        case "high": param = "HIGH";
          break;
        default: param = "ALL"
      }
      if (param === "ALL") {
        const response = privateAxios.get(`/task/getTaskByRoles`).then((res) => {
          setData(res.data);
          closeBackdrop();

        }).catch(err => {
          closeBackdrop();
        })
      } else {
        const response = privateAxios.get(`/task/getTaskByPriority?priority=${param}`).then((res) => {
          setData(res.data);
          closeBackdrop();
        }).catch(err => {
          closeBackdrop();
        })
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      closeBackdrop();
    }
  };


  // ---------------------------------------------------------Seraching-Api-------------------------------------------------------------

  // Function to handle search input change
  const handleSerachChange = async (e) => {
    setSearchValue(e.target.value)
    try {
      if (e.target.value === "") {
        getAllTasks();
      }
      else {
        await privateAxios.post(`/task/searchTask?title=${e.target.value}&description=${e.target.value}`).then(res => {
          setData(res.data);
        }).catch(err => {
        })
      }

    } catch (error) {
    }
  };
  // --------------------------------------------------------------------------------------------------------------------------------------
  // Function to handle form validation

  const handleValidation = (e) => {
    const { name, value } = e.target;

    let errorMessage = '';

    if (name === 'email') {
      // if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
      //     errorMessage = 'Invalid email address';
      // }
      if (value.trim() === '') {
        errorMessage = 'Add atleast One email address';
      }
    } else if (name === 'accessRole') {
      if (value.trim() === '') {
        errorMessage = 'Select an Access Role';
      }
    }
    else if (name === 'designation') {
      if (value.trim() === '') {
        errorMessage = 'Select a Designation';
      }
    }



    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
      formError: ""
    }));
  }

  // Function to handle invite user
  const handleInviteUser = async (e) => {
    e.preventDefault();
    openBackdrop()
    setFormErrors(prev => {
      return {
        ...prev,
        formError: ""
      }
    })
    const hasErrors = Object.values(formError).some((error) => error !== '');
    const notEmpty = Object.values(formData).some((dd) => dd.length < 1);
    if (!hasErrors && !notEmpty) {
      const emailArr = formData.email.split(",").map((email) => {
        return email.trim().toLowerCase();


      });


      await privateAxios.post(`/adminuser/inviteUser/${auth.orgId}`,
        { emails: emailArr, role: formData.accessRole, designation: formData.designation, workspace: [`Technical Specifications`] },

      )
        .then((res) => {
          if (res.status === 200) {
            setShowSuccessAlert(true);
            const data = res.data;
            getAllUser();
            handleaddKamModal();
          }
          closeBackdrop()
        }).catch((err) => {
          setFormErrors(prev => {
            return {
              ...prev,
              formError: err?.response?.data.message || "Something Went Wrong"
            }
          })

          closeBackdrop()
        }
        )

    } else {
      setFormErrors(prev => {
        return {
          ...prev,
          formError: "Please Check all the Feilds"
        }
      })
    }
  }


  // Function to get all users
  const getAllUser = async () => {
    try {
      await privateAxios.get(`/procurement/getAllUsers/${auth.orgId}`)
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            const dataArr = [];
            const groupedData = {};
            res.data.data.employees.forEach(entry => {
              const role_name = entry?.user.entityType;
              if (!groupedData[role_name]) {
                groupedData[role_name] = [];
              }
              groupedData[role_name].push(entry);
            })
            let tt = 0;

            if (res.data?.KAM) {
              tt += res.data.kamEntity.length;
            }

            if (groupedData?.USER) {
              tt += groupedData.USER.length;
            }

            if (groupedData?.ADMIN) {
              tt += groupedData.ADMIN.length;
            }

            setTotalUser(tt)
            setUserData({
              USER: groupedData?.USER,
              KAM: res.data?.KAM,
              ADMIN: groupedData?.ADMIN
            })
          }

        }).catch((err) => {
        }
        )
    } catch (error) {
    }
  }

  useEffect(() => {
    getAllUser()
  }, [])

  useEffect(() => {
    let timeoutId;

    if (showSuccessAlert) {
      timeoutId = setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000); // 3000 milliseconds (3 seconds)
    }

    return () => {
      clearTimeout(timeoutId); // Clear the timeout if the component unmounts or the alert is hidden before 3 seconds
    };
  }, [showSuccessAlert]);





  return (
    <div className={workSpaceStyle.workSpaceMain}>
      <div className={workSpaceStyle.workSpaceHeader}>
        <div className={workSpaceStyle.workSpaceHeaderLeft}>
          <h1 style={{ gap: '10px' }}><span style={{ fontSize: "20px" }}><PeopleOutlineIcon /></span>
            Technical Specification
          </h1>
          <p>All Task <span>{data.length}</span></p>{/* Display total number of tasks */}

        </div>
        <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
          <input type="text"
            onChange={handleSerachChange}// Handle search input change
            value={searchValue}// Display search value
            placeholder='Search Here...' />
        </div>
        <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: '450px', minWidth: '450px' }} >

          {/* Status dropdown */}
          <div className={workSpaceStyle.WorkSpaceHeaderSorters} style={{ width: '25%' }}>
            <p>Status:</p>
            <h3 onClick={() => { setStatusDropDownOpen(prev => !prev); setWorkspaceDropDownOpen(false) }}><Tooltip title='Filter Status'><AdminPanelSettingsIcon /></Tooltip>{statusSelected}</h3>

            {/* Dropdown menu for status */}
            {statusDropDownOpen &&
              <>
                <div>
                  <div className={workSpaceStyle.workSpaceHeaderDropDownBackDrop} onClick={() => setStatusDropDownOpen(false)}></div>

                  <div className={workSpaceStyle.workSpaceHeaderSorterDropdown} style={{ width: '140px' }}>
                    <h5 style={{ fontSize: '15px' }}>Status:</h5>
                    <ul>
                      <li style={{ fontSize: '14px' }} onClick={() => fetchData("All")}>All</li>
                      <li style={{ fontSize: '14px' }} onClick={() => fetchData("To Do")}>To-do</li>
                      <li style={{ fontSize: '14px' }} onClick={() => fetchData("In Progress")}>In Progress</li>
                      <li style={{ fontSize: '14px' }} onClick={() => fetchData("Done")}>Done</li>
                      <li style={{ fontSize: '14px' }} onClick={() => fetchData("Completed")}>Completed</li>
                    </ul>
                  </div>
                </div>
              </>
            }
          </div>

          {/* Assign dropdown (visible to ADMIN and KAM roles) */}
          {
            auth.roles === 'ADMIN' || auth.roles === 'KAM' ? (
              <div className={workSpaceStyle.WorkSpaceHeaderSorters} style={{ width: '25%' }}>
                <p>Assigned:</p>
                <h3 onClick={() => { setAssignDropDownOpen(prev => !prev); }}><Tooltip title='Filter Assign'><ImportExportIcon /></Tooltip>{`${assignSelected.slice(0, 7) + '...'}`}</h3>
                {/* Dropdown menu for assign */}
                {assignDropDownOpen &&
                  <>
                    <div>
                      <div className={workSpaceStyle.workSpaceHeaderDropDownBackDrop} onClick={() => setAssignDropDownOpen(prev => !prev)}></div>

                      <div className={workSpaceStyle.workSpaceHeaderSorterDropdown} style={{ width: '220px' }}>
                        <h5 style={{ fontSize: '15px' }}>Users:</h5>

                        <ul>
                          <li style={{ fontSize: '12px' }} onClick={() => fetchData2("All")} >All</li>
                          {/* Mapping user list */}
                          {userList.map((email, index) => {
                            return (
                              <li style={{ fontSize: '12px' }} onClick={() => fetchData2(email)} key={index}>{email}</li>
                            )
                          })
                          }
                        </ul>

                      </div>
                    </div>
                  </>
                }
              </div>
            ) : (
              <></>
            )
          }

          {/* Label dropdown */}
          <div className={workSpaceStyle.WorkSpaceHeaderSorters} style={{ width: '20%' }}>
            <p>Lables:</p>
            <h3 onClick={() => { setLabelDropDownOpen(prev => !prev); setWorkspaceDropDownOpen(false) }}><Tooltip title='Filter Lables'><ImportExportIcon /></Tooltip>{labelSelected}</h3>
            {/* Dropdown menu for label */}
            {labelDropDownOpen &&
              <>
                <div>
                  <div className={workSpaceStyle.workSpaceHeaderDropDownBackDrop} onClick={() => setLabelDropDownOpen(false)}></div>

                  <div className={workSpaceStyle.workSpaceHeaderSorterDropdown} style={{ width: '140px' }}>
                    <h5 style={{ fontSize: '15px' }}>Priority:</h5>
                    <ul >
                      <li style={{ fontSize: '14px' }} onClick={() => fetchData3("All")}>All</li>
                      <li style={{ fontSize: '14px' }} onClick={() => fetchData3("Low")}>Low</li>
                      <li style={{ fontSize: '14px' }} onClick={() => fetchData3("Medium")}>Medium</li>
                      <li style={{ fontSize: '14px' }} onClick={() => fetchData3("High")}>High</li>

                    </ul>
                  </div>
                </div>
              </>
            }
          </div>


          {/* Add assign section (visible to KAM and ADMIN roles) */}
          {(auth.roles === "KAM" || auth.roles === "ADMIN") && <div className={TaskStyle.addAssignMain} style={{ display: 'flex', width: '20%', justifyContent: 'center', alignItems: 'center' }}>
            <div className={TaskStyle.addAssign} style={{ width: '100%' }}>

              {/* Display assigned users */}
              {userList.length > 0 && userList.slice(0, 3).map((item, index) => {
                const colorArray = ["#0077b6", "#f77f00", "#a7c957"];
                const randomIndex = Math.floor(Math.random() * colorArray.length);
                const randomColor = colorArray[randomIndex];

                return (
                  <div key={index} className={TaskStyle.name1} style={{ backgroundColor: randomColor, color: '#fff' }}>
                    <ProfileIcon data={{ email: item }} height={"26px"} width={"26px"} showProfileBanner={false} />
                  </div>
                );
              })}

              {/* Add user button */}
              {(auth.roles === "KAM" || auth.roles === "ADMIN") && <div className={TaskStyle.name3} onClick={getAllUser}>
                <Tooltip title='Add'>
                  <DataSaverOnOutlinedIcon
                    onClick={handleaddKamModal} />
                </Tooltip>
              </div>
              }
            </div>
          </div>}

          <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>

            {/* Conditionally render help links based on user roles */}
            {auth.roles === "KAM" && (
              <Tooltip title="Help">
                <a target="_blank" href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000080849-technical-specification">
                  <HelpOutlineOutlinedIcon />
                </a>
              </Tooltip>
            )}

            {auth.roles === "ADMIN" && (
              <Tooltip title="Help">
                <a target="_blank" href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081383-create-task-technical-specification">
                  <HelpOutlineOutlinedIcon />
                </a>
              </Tooltip>
            )}

            {auth.roles === "USER" && (
              <Tooltip title="Help">
                <a target="_blank" href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081385-user-display-technical-specification-task">
                  <HelpOutlineOutlinedIcon />
                </a>
              </Tooltip>
            )}

          </div>

        </div>

      </div>
      <div>

        {["USER"].includes(auth.roles) && <TaskDisplay data={data} setData={setData} getAllUSers={getAllTasks} setEditData={setEditData} handleEditTaskModal={handleEditTaskModal} search={searchValue} updatedData={data} handlecreateTaskModal={handlecreateTaskModal} loadMore={loadMore} taskPage={taskPage} setTaskPage={setTaskPage} />}

        {["KAM", "ADMIN"].includes(auth.roles) && <TaskDisplay2 getAllKam={getAllTasks} editData={editData} setEditData={setEditData} search={searchValue} updatedData={data} priorityData={priorityData} assignData={assignData} handlecreateTaskModal={handlecreateTaskModal} handleEditTaskModal={handleEditTaskModal} loadMore={loadMore} taskPage={taskPage} setTaskPage={setTaskPage} />}

      </div>
      <BackdropComponent />

      <Modal
        open={addKamModal}>
        <div className={workSpaceStyle.modalCont}>

          <form action="" onSubmit={handleInviteUser} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader}>
              <h3>Invite members on board</h3>
              <div onClick={handleaddKamModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <label htmlFor=""> Email:</label>
              <input onChange={handleInput} autoComplete='off' name='email' onBlur={handleValidation} ref={emailRef}
                type="text" placeholder='Enter Emails Separated by commas' />
              <span>{formError.email}</span>
            </div>

            <div className={formStyle.inputRow}>
              <label htmlFor=""> Access Role:</label>

              <select
                value={formData.accessRole}
                onChange={handleInput}
                className={formStyle.formSelect}
                name='accessRole'
                onBlur={handleValidation}
              >
                <option value="" style={{ color: "#878699" }}>Plese Select a Role</option>
                <option value="ADMIN">Admin</option>
                <option value="USER">User</option>
              </select>
              <span>{formError.accessRole}</span>
            </div>

            <div className={formStyle.inputRow}>
              <label htmlFor=""> Designation :</label>
              <input onChange={handleInput} autoComplete='off' value={formData.designation} name='designation' onBlur={handleValidation}
                type="text" placeholder='Please Enter A designation' />
              <span>{formError.designation}</span>
            </div>

            <div className={formStyle.inputRow}>
              <label htmlFor=""> Assign A Workspace:</label>
              <input onChange={handleInput} autoComplete='off' value={formData.workSpace} name='workSpace' onBlur={handleValidation}
                type="text" disabled placeholder='Techincal Specifications' style={{ backgroundColor: '#CECECE' }} />
              <span>{formError.workSpace}</span>
            </div>

            <div>
              <button style={{ fontSize: '15px' }} className={formStyle.modalSmallPrimaryButton} type='submit'>Invite </button>
            </div>

            <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span>

          </form>

        </div>
      </Modal>

      <Modal
        open={createTaskModal}
        onClose={handlecreateTaskModal}
      >
        <TaskPannel getAllTasks={getAllTasks} handlecreateTaskModal={handlecreateTaskModal} handleEditTaskModal={handleEditTaskModal} />

      </Modal>

      <Modal
        open={editTaskModal}
        onClose={handleEditTaskModal}
      >
        <EditTaskPannel getAllTasks={getAllTasks} handleEditTaskModal={handleEditTaskModal} editData={editData} setEditData={setEditData} />

      </Modal>
      <div>
        {showSuccessAlert && (
          <Alert severity="success" sx={{
            display: 'flex',
            justifyContent: 'left',
            position: 'absolute',
            left: '50%',
            top: '80%',
            zIndex: '500',
            width: '20%'
          }}>Invite sent successfully</Alert>
        )}
      </div>
    </div>
  )
}

export default TechnicalSpecification


