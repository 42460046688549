
 export const passwordEncryptor = async (plainPassword, secretKey) => {
    try {
      const encoder = new TextEncoder();
      const data = encoder.encode(plainPassword);
      const keyBuffer = await crypto.subtle.importKey('raw', encoder.encode(secretKey), 'AES-CBC', false, ['encrypt']);
      const encryptedData = await crypto.subtle.encrypt({ name: 'AES-CBC', iv: new Uint8Array(16) }, keyBuffer, data);
      const encryptedPassword = btoa(String.fromCharCode.apply(null, new Uint8Array(encryptedData)));
      
      return encryptedPassword;
    } catch (error) {
        console.error('Encryption error:', error);
      return null;
    }
  };


