import * as React from 'react';
import { useState, useEffect } from 'react';
import TaskStyle from "../../../TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box';
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Hidden, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PrivateComponent } from 'api/axios'
import PersonIcon from '@mui/icons-material/Person';
import workSpaceStyle from '../../../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import categoryData from 'api/categories.json'
import useAuth from 'hooks/useAuth';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import useBackdrop from "hooks/useBackdrop";
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import { format } from 'date-fns';
import commercialStyle from '../commercialStyles.module.css';
import gif from 'assets/images/gif.gif';
import FetchUsersComponent from 'components/FetchUsersComponent/FetchUsersComponent'
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';



const AdminKAMTaskDisplay = ({ getAllKam, search, handlecreateTaskModal, setEditData, handleEditTaskModal, updatedData, priorityData, assignData, editData, loadMore, taskPage, setTaskPage, }) => {

  const AvaialableCommercialTemplates = [ 'Hey', "Hiiii", 'Hello' ]// Array of available templates

  const privateAxios = PrivateComponent();  // Axios instance for private requests
  const [sortedData, setSortedData] = useState([]);  // State to store sorted data
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();  // Backdrop functions and component

  const { auth } = useAuth();  // Authentication context
  const navigate = useNavigate();  // Navigation function

  const [deleteKamModal, setDeleteKamModal] = useState(false);  // State for delete modal
  const [selectedOptionId, setSelectedOptionId] = useState(null);  // State for selected option ID
  const [prioritySelectorOpen, setPrioritySelectorOpen] = useState('');  // State for priority selector open
  const [statusSelectorOpen, setStatusSelectorOpen] = useState('');  // State for status selector open
  const [selectedEmailTaskId, setSelectedEmailTaskId] = useState("");  // State for selected email task ID


  const taskData = categoryData;  // Assigning categoryData to taskData
  const [multiClick, setMultiClick] = useState(false);  // State for multi-click
  const handleMultiClick = () => {
    setMultiClick(prev => !prev);  // Toggle multi-click state
  };

  const [multiClick2, setMultiClick2] = useState(false);  // State for second multi-click
  const handleMultiClick2 = () => {
    setMultiClick2(prev => !prev);  // Toggle second multi-click state
  };

  const [multiClick3, setMultiClick3] = useState(false);  // State for third multi-click
  const handleMultiClick3 = () => {
    setMultiClick3(prev => !prev);  // Toggle third multi-click state
  };

  const [multiClick4, setMultiClick4] = useState(false);  // State for fourth multi-click
  const handleMultiClick4 = () => {
    setMultiClick4(prev => !prev);  // Toggle fourth multi-click state
  };

  const [data, setData] = useState([]);  // State for data
  const [selectedEmails, setSelectedEmails] = useState([]);  // State for selected emails
  const [emailModalOpen, setEmailModalOpen] = useState(false);  // State for email modal open
  const [selectedEmailsDisplay, setSelectedEmailsDisplay] = useState([]);  // State for selected emails display


  const handleEmailModalOpen = (emailArr, taskId) => {
    const emails = emailArr.filter((err) => err.disabled === false).map((per) => { return per.email })// Filter and map emails
    setSelectedEmails(emails);  // Set selected emails
    setSelectedEmailTaskId(taskId);  // Set selected email task ID
    setEmailModalOpen(true);  // Open email modal
  };

  useEffect(() => {
    if (emailModalOpen === false) {
      setSelectedEmailTaskId(""); // Reset selected email task ID when modal closes
    }
  }, [emailModalOpen])

  const handleEmailModalClose = async () => {
    setEmailModalOpen(false); // Close email modal
  };

  // ---------------------------------
  useEffect(() => {
    if (updatedData) {
      setSortedData(updatedData); // Set sorted data if updatedData is available
    }
  }, [updatedData])



  // ------------------------------------get All users(assign)-----------------------------------

  const [loadingKam, setLoadingKam] = useState(false); // State for loading KAM

  const handleEmailCheckboxChange = (email) => {
    // Toggle the selected email on checkbox change
    setSelectedEmails(prev => {
      if (prev.includes(email)) {
        return prev.filter((em) => em != email)// Remove email from selected emails
      }
      else {
        return [...prev, email];// Add email to selected emails
      }

    })


  };
  const handleAssignEmailChange = async (taskId) => {
    if (selectedEmails.length > 0 && taskId !== "") {
      openBackdrop();// Open backdrop
      try {
        await privateAxios
          .post(`/task/addUsersToTask/${taskId}`, { emails: selectedEmails })// Post request to add users to task
          .then((res) => {
            getAllKam(); // Fetch all KAM
            setEmailModalOpen(false); // Close email modal
            if (selectedEmails && selectedEmailsDisplay) {
              const addedEmails = selectedEmails.filter(email => !selectedEmailsDisplay.includes(email));// Find added emails
              const deletedEmails = selectedEmailsDisplay.filter(email => !selectedEmails.includes(email));// Find deleted emails

              let previousLogs = '';
              let newLogs = '';

              if (!arraysAreEqual(selectedEmails, selectedEmailsDisplay)) {
                if (addedEmails.length > 0 && deletedEmails.length === 0) {
                  previousLogs += `Email${addedEmails.length > 1 ? 's' : ''} ${addedEmails.join(', ')} ${addedEmails.length > 1 ? 'were' : 'was'} not present. `;
                  newLogs += `Email${addedEmails.length > 1 ? 's' : ''} ${addedEmails.join(', ')} ${addedEmails.length > 1 ? 'were' : 'was'} added. `;
                } else if (deletedEmails.length > 0 && addedEmails.length === 0) {
                  previousLogs += `Email${deletedEmails.length > 1 ? 's' : ''} ${deletedEmails.join(', ')} ${deletedEmails.length > 1 ? 'were' : 'was'} present. `;
                  newLogs += `Email${deletedEmails.length > 1 ? 's' : ''} ${deletedEmails.join(', ')} ${deletedEmails.length > 1 ? 'were' : 'was'} removed. `;
                } else {
                  previousLogs += `Email${deletedEmails.length > 1 ? 's' : ''} ${deletedEmails.join(', ')} ${deletedEmails.length > 1 ? 'were' : 'was'} present but removed now. `;
                  newLogs += `Email${addedEmails.length > 1 ? 's' : ''} ${addedEmails.join(', ')} ${addedEmails.length > 1 ? 'were' : 'was'} added. `;
                }

                const payloadToSend = [{
                  name: auth.name,
                  logDetails: `${auth.name} updated the emails`,
                  previousLogs,
                  newLogs,
                }];

                // console.log(payloadToSend)

                handlePostTaskHistory(payloadToSend, taskId)// Post task history
              }
            }
          })
          .catch((err) => {
            // Handle error
          });
      } catch (error) {
        // Handle error
      }
      closeBackdrop();
    } else {
    }
  };

  function arraysAreEqual(array1, array2) {
    if (array1.length !== array2.length) {
      return false; // Return false if arrays have different lengths
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;// Return false if elements are not equal
      }
    }

    return true; // Return true if arrays are equal
  }





  // --------------------------------------------------------------------------------------------

  const handleChoose = (selectedId) => {
    setSelectedOptionId(selectedId);// Set selected option ID
  };

  const handleDeleteModal = () => {
    setDeleteKamModal(prev => !prev);// Toggle delete modal state

  }
  const [selectedOption, setSelectedOption] = useState(null);// State for selected option

  const handleOptionSelect = (option) => {
    setSelectedOption(option); // Set selected option
  };

  const handlePrioritySelector = (index) => {

    if (prioritySelectorOpen === index) {
      setPrioritySelectorOpen('')// Close priority selector if already open
    } else {
      setPrioritySelectorOpen(index)// Open priority selector
    }


  }


  const handleStatusSelector = (index) => {

    if (statusSelectorOpen === index) {
      setStatusSelectorOpen('')// Close status selector if already open
    } else {
      setStatusSelectorOpen(index); // Open Status Selector 
    }
  }


  // -----------------------------------------Update Status Api---------------------------------------------------
  const handleStatusChange = async (taskId, oldValue, value) => {
    try {
      let param = 'ALL';

      switch (value.toLowerCase()) {
        case 'to do':
          param = 'TODO'; // Set status to TODO
          break;
        case 'in progress':
          param = 'INPROGRESS';  // Set status to INPROGRESS
          break;
        case 'done':
          param = 'DONE'; // Set status to DONE
          // Update completedDate to today's date
          const todayDate = format(new Date(), 'yyyy-MM-dd');// Get today's date
          await privateAxios.post(`/task/updateTaskStatus/${taskId}?status=${param}&completedDate=${todayDate}`).then((res) => {
            // console.log(res.data);
            window.location.reload();//reload page
            const payloadToSend = [{
              name: auth.name,
              logDetails: `${auth.name} updated the status`,
              previousLogs: oldValue,
              newLogs: res.data.status,
            }]
            handlePostTaskHistory(payloadToSend, taskId); // Post task history
            getAllKam(); // Fetch all KAM
          });
          break;
        case 'completed':
          param = 'COMPLETED';// Set status to COMPLETED
          break;
        default:
          param = 'ALL';
      }



      // For other statuses, don't update completedDate
      if (value.toLowerCase() !== 'done') {
        await privateAxios.post(`/task/updateTaskStatus/${taskId}?status=${param}`).then((res) => {
          // console.log(res.data);

          const payloadToSend = [{
            name: auth.name,
            logDetails: `${auth.name} updated the status`,
            previousLogs: oldValue,
            newLogs: res.data.status,
          }]

          // console.log(payloadToSend)

          handlePostTaskHistory(payloadToSend, taskId);  // Post task history
          window.location.reload();  // Reload page
          getAllKam();  // Fetch all KAM
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handlePostTaskHistory = async (payloadToSend, taskId) => {
    // alert("HELoooo")
    // console.log(payloadToSend)
    try {
      await privateAxios.post(`/task/getTaskHistory/${taskId}`, payloadToSend).then((res) => {
        // Task history API call
      })
    } catch (error) {
      console.log(error) // Handle error
    }
  }
  // -----------------------------------------Update Priority Api---------------------------------------------------
  const handlePriorityChange = async (taskId, oldValue, value) => {
    try {
      // console.log("This is handling" + taskId)
      let param = "ALL"
      switch (value.toLowerCase()) {
        case "low": param = "LOW";// Set priority to LOW
          break;
        case "medium": param = "MEDIUM"; // Set priority to MEDIUM
          break;
        case "high": param = "HIGH";// Set priority to HIGH
          break;
        default: param = "ALL"
      }
      await privateAxios.post(`/task/updateTaskPriority/${taskId}?priority=${param}`).then(res => {
        getAllKam();// Fetch all KAM
        window.location.reload(); // Reload page
        const payloadToSend = [{
          name: auth.name,
          logDetails: `${auth.name} updated the priority`,
          previousLogs: oldValue,
          newLogs: res.data.priority,
        }]
        handlePostTaskHistory(payloadToSend, taskId); // Post task history
      })
    } catch (error) {
      // console.log(error)
    }
  };

  // -------------------------------------------------New Sortting----------------------------------------------------------------------


  const [isStartDateDropdownOpen, setIsStartDateDropdownOpen] = useState(false); // State for start date dropdown

  const toggleStartDateDropdown = () => {
    setIsStartDateDropdownOpen(!isStartDateDropdownOpen); // Toggle start date dropdown
  };

  const handleStartDateSort = (sortType) => {
    let sortedItems = [...updatedData];

    if (sortType === 'Newest') {
      sortedItems.sort((a, b) => new Date(b.startDate) - new Date(a.startDate)); // Sort by newest start date
    } else if (sortType === 'Oldest') {
      sortedItems.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));// Sort by oldest start date
    }

    setSortedData(sortedItems);  // Set sorted data
    setIsStartDateDropdownOpen(false);  // Close dropdown
  };
  // ---------------------------------------------------------------------------
  const [isEndDateDropdownOpen, setIsEndDateDropdownOpen] = useState(false);  // State for end date dropdowns

  const toggleEndDateDropdown = () => {
    setIsEndDateDropdownOpen(!isEndDateDropdownOpen);// Toggle end date dropdown
  };

  const handleEndDateSort = (sortType) => {
    let sortedItems2 = [...updatedData];

    if (sortType === 'Newest') {
      sortedItems2.sort((a, b) => new Date(b.endDate) - new Date(a.endDate)); // Sort by newest end date
    } else if (sortType === 'Oldest') {
      sortedItems2.sort((a, b) => new Date(a.endDate) - new Date(b.endDate)); // Sort by oldest end date
    }

    setSortedData(sortedItems2);  // Set sorted data
    setIsEndDateDropdownOpen(false);  // Close dropdown
  };


  // -----------------------------------------------------------------------------------------------------------------------------------

  const workspaceId = 2; // Workspace ID
  const [userList, allEmails, notLoggedIn] = FetchUsersComponent({ orgId: auth.orgId, workspaceId: workspaceId })// Fetch users
  const [isEmailIconClicked, setEmailIconClicked] = useState(false); // State for email icon click

  useEffect(() => {
    if (isEmailIconClicked) {
      // Reset the flag to false after the API call
      setEmailIconClicked(false); // Reset email icon click state
    }
  }, [isEmailIconClicked]);// State for temporary task ID



  const [tempTaskId, setTempTaskId] = useState("");

  const deleteTask = async () => {
    try {
      privateAxios.delete(`/task/deleteTask/${tempTaskId}`)
        .then((res) => {
          if (res.status === 200) {
            // console.log("Response data:", res.data);
            // Refresh the page on a successful delete (HTTP status 200)
            window.location.reload(); // Reload page on successful delete
          }
        });

    } catch (error) {
      console.error('Error deleting Task:', error);
    }
  };




  return (
    <div>
      {AvaialableCommercialTemplates.length == 0 ?
        <div className={commercialStyle.zerotemplateBox}>
          <div className={commercialStyle.zerotemplateInnerBox}>
            <div className={commercialStyle.zerotemplateSvg}><img src={gif} alt="" /></div>
            <div className={commercialStyle.zerotemplateInnerBox2}>
              <div><h3>Create your first commercial</h3></div>
              <div className={commercialStyle.zerotemplateInfo}><p>Ready to take the next step? We've got plans for teams of all shapes and sizes</p></div>
              <div className={commercialStyle.zerotemplatebutton}><button>Templates</button></div>
            </div>
          </div>
        </div> :
        <div className={TaskStyle.table1}>

          <table style={{ borderCollapse: 'collapse' }}>
            <thead>
              <tr className={TaskStyle.theader}>
                <th>Task ID</th>
                <th style={{ textAlign: "left" }}>Title</th>
                <th>
                  <div className={TaskStyle.SortingDate}>
                    <p onClick={toggleStartDateDropdown} >
                      Start Date
                      <span className=''>
                        <ImportExportIcon />
                      </span>
                    </p>
                    {isStartDateDropdownOpen &&
                      <ul >
                        <li onClick={() => handleStartDateSort('Newest')}>Newest</li>
                        <li onClick={() => handleStartDateSort('Oldest')}>Oldest </li>
                      </ul>
                    }
                  </div>
                </th>
                {/* <th> End Date</th> */}
                <th >
                  <div className={TaskStyle.SortingDate}>
                    <p onClick={toggleEndDateDropdown} >
                      <span
                        style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
                        End Date<ImportExportIcon />
                      </span>
                    </p>
                    {isEndDateDropdownOpen &&
                      <ul >
                        <li onClick={() => handleEndDateSort('Newest')}>Newest</li>
                        <li onClick={() => handleEndDateSort('Oldest')}>Oldest </li>
                      </ul>
                    }
                  </div>
                </th>
                <th>E/C Date</th>
                <th>Status</th>
                <th>Assigned To</th>
                <th>Label</th>
                <th>Action</th>
              </tr>
            </thead>
          </table>

          <div className={TaskStyle.addTask} onClick={handlecreateTaskModal} ><Tooltip title='New Task'><AddCircleOutlineOutlinedIcon /></Tooltip><p>Create a task</p></div>


          <table style={{ borderCollapse: 'collapse' }}>
            <tbody >
              {
                sortedData.filter((item) =>

                  item?.title?.trim().toLowerCase().includes(search?.toLowerCase())
                ).map((item, index) =>

                  <tr key={item.taskId} className={TaskStyle.tbody}>
                    <td >{index + 1}</td>
                    <td onClick={() => { setEditData(item); handleEditTaskModal(); }}>{item.title}</td>

                    <td>{item.startDate}</td>
                    <td>{item.endDate}</td>
                    <td>{item.completedDate}</td>

                    <td className={TaskStyle.SelectBar} style={{ backgroundColor: '#d2d2d2' }}>

                      <div onClick={() => handleStatusSelector(index)} className={TaskStyle.statusSelector}>
                        <p onClick={handleMultiClick2} style={{ color: '#000' }}

                        >{item.status.substring(0, 1).toUpperCase() + item.status.substring(1, item.status.length).toLowerCase()}

                          <span>
                            {statusSelectorOpen === index
                              ? <KeyboardArrowUpIcon />
                              : <KeyboardArrowDownIcon />
                            }
                            {/*  */}
                          </span>
                        </p>

                        {statusSelectorOpen === index &&
                          <ul>
                            <li onClick={() => handleStatusChange(item.taskId, item.status, "To Do")}>To do</li>
                            <li onClick={() => handleStatusChange(item.taskId, item.status, "In Progress")}>In Progress</li>
                            <li onClick={() => handleStatusChange(item.taskId, item.status, "Done")}>Done</li>
                            <li onClick={() => handleStatusChange(item.taskId, item.status, "Completed")}>Completed</li>
                          </ul>
                        }
                      </div>


                    </td>

                    <td>
                      <div className={TaskStyle.addAssignMain}>
                        <div className={TaskStyle.addAssign}>


                          {item.assignTo.slice(0, 2).map((iit) => {

                            const colorArray = ["#0077b6", "#f77f00", "#a7c957"]
                            const randomIndex = Math.floor(Math.random() * colorArray.length);

                            const randomColor = colorArray[randomIndex]
                            if (iit?.disabled === false) {
                              return (

                                <div className={TaskStyle.name1} style={{ backgroundColor: randomColor }}>
                                  {/* {iit?.email?.charAt(0).substring(0, 1).toUpperCase()} */}
                                  <ProfileIcon data={{ email: iit?.email }} height={"26px"} width={"26px"} showProfileBanner={false} />
                                </div>
                              )
                            }
                          })}
                          <div className={TaskStyle.name3}>
                            <Tooltip title='Add Member'>
                              <DataSaverOnOutlinedIcon onClick={() => {
                                handleEmailModalOpen(item.assignTo, item.taskId);
                                setEmailIconClicked(true); // Set the flag to true when clicked
                              }} />
                            </Tooltip>
                          </div>

                        </div>
                      </div>
                    </td>

                    <td className={TaskStyle.SelectBar}>
                      <div onClick={() => handlePrioritySelector(index)} className={TaskStyle.prioritySelector}>
                        <p onClick={handleMultiClick}
                          style={
                            {
                              backgroundColor: item.priority === 'HIGH' ? '#ec5252' : item.priority === 'MEDIUM' ? '#f5ac38' : '#b3f289',

                            }
                          }
                        >{item.priority.substring(0, 1).toUpperCase() + item.priority.substring(1, item.priority.length).toLowerCase()}
                          <span>
                            {prioritySelectorOpen === index
                              ? <KeyboardArrowUpIcon />
                              : <KeyboardArrowDownIcon />
                            }
                            {/*  */}
                          </span>
                        </p>
                        {prioritySelectorOpen === index &&
                          <ul>
                            <li style={{ backgroundColor: "#ec5252", color: '#fff' }} onClick={() => handlePriorityChange(item.taskId, item.priority, "High")}>High</li>
                            <li style={{ backgroundColor: "#f5ac38", color: '#fff' }} onClick={() => handlePriorityChange(item.taskId, item.priority, "Medium")}>Medium</li>
                            <li style={{ backgroundColor: "#b3f289", color: '#fff' }} onClick={() => handlePriorityChange(item.taskId, item.priority, "Low")}>Low</li>
                          </ul>
                        }
                      </div>

                    </td>

                    <td  >
                      <div className={TaskStyle.icons} >
                        <Link onClick={(e) => { e.preventDefault(); setEditData(item); handleEditTaskModal(); }}><Tooltip title='Edit Task'><EditOutlinedIcon /></Tooltip></Link>
                        <Link><Tooltip title='Delete Task'>
                          <DeleteOutlineOutlinedIcon onClick={() => { handleDeleteModal(); setTempTaskId(item.taskId) }} />
                        </Tooltip></Link>
                      </div>
                    </td>

                  </tr>
                )
              }


            </tbody>
          </table>


          {sortedData.length !== 0 && sortedData.length % 10 === 0 &&
            <p className={TaskStyle.loadmoreBtn} onClick={() => { loadMore((taskPage), 10) }}>load more</p>}
          <Modal
            open={emailModalOpen}
            onClose={handleEmailModalClose}>
            <div className={workSpaceStyle.modalCont} style={{ width: '400px', minHeight: 'auto' }}>

              <Box className={TaskStyle.taskModalDisplay}
                style={{ width: "100%" }}
              >
                <span >
                  <PersonIcon sx={{ color: '#000' }} /><p>Add member</p>
                </span>
                <Typography variant="h6"> Board member</Typography>
                <div className={TaskStyle.emailSelection}>

                  {loadingKam ? (
                    <p>Loading users...</p>
                  ) : (
                    userList?.map((email, index) => {

                      return (
                        <label key={index}>
                          {email}
                          <input style={{ width: '20px' }} type="checkbox"
                            checked={selectedEmails.includes(email)}
                            onChange={() => handleEmailCheckboxChange(email)}
                          />
                        </label>
                      )
                    }
                    )



                  )}

                  {notLoggedIn?.map((email, index) => {
                    return (
                      <label>{email}
                        <input style={{ width: '90px' }} disabled value={"Not Logged In"}></input>
                      </label>
                    )
                  })}

                  <div><Button variant='contained' style={{ width: "20%", height: "30px", borderRadius: '6px', backgroundColor: '#16469d', color: '#fff', cursor: 'pointer', border: 'none', textTransform: 'capitalize' }}
                    onClick={() => handleAssignEmailChange(selectedEmailTaskId)}
                    disabled={selectedEmails.length === 0} >Save</Button></div>

                </div>

              </Box>
            </div>
          </Modal>

          <DeleteConfirmation
            onCancel={handleDeleteModal}
            onConfirm={deleteTask}
            open={deleteKamModal}
          // confimBtnText={""} 
          // discardText={""}
          >
            <h2>Delete Task</h2>
            <p>Are you sure you want to delete task?</p>

          </DeleteConfirmation>

          {/* <Modal
            open={deleteKamModal}
            onClose={handleDeleteModal}

          >
            <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', width: '350px' }}>
              <form action="" style={{ maxWidth: '100%' }} >

                <div className={TaskStyle.modalKam} onClick={handleDeleteModal}>
                  <Typography variant="h1">Delete Task</Typography>
                  <Typography variant="h6">Are you sure you want to delete task?</Typography>

                  <div className={TaskStyle.kamModalButton}>


                    <Button variant="contained"
                      onClick={deleteTask}>Delete</Button>

                    <Link><p>Cancel</p></Link>
                  </div>

                </div>

              </form>
            </div>
          </Modal> */}
          <BackdropComponent />
        </div>

      }

    </div>
  );
}
export default AdminKAMTaskDisplay