import React, { useEffect } from 'react'
import revisionEditStyle from './RevisionEdit.module.css'



const AttributeTab = ({ columns, setColumns, tableRows, setTableRows }) => {



  return (
    <div className={revisionEditStyle.attributeTableMain}>
      <table className={revisionEditStyle.attributeTable}>
        {/* Render table header */}
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>
                {
                  column.name
                }
              </th>
            ))}

          </tr>
        </thead>
        <tbody>
          {tableRows.map((row, index) => (
            <TableRow
              key={row.id}
              row={row}
              rowIndex={index}
              columns={columns}
            />
          ))}
        </tbody>
      </table>


    </div>
  )
};


const TableRow = ({
  row,
  rowIndex,
  columns,
}) => {
  return (
    <tr>
      <td>
        <span style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
          <p>{rowIndex + 1}</p>
        </span>
      </td>
      <td style={{ minWidth: "200px" }} className={revisionEditStyle.nameCell}>
        <input type="text" name="name" value={row.name} />
      </td>
      <td style={{ minWidth: "120px", maxWidth: "120px" }} className={revisionEditStyle.nameCell}>
        <input type="text" name="type" value={row.type} />
      </td>
      <td style={{ minWidth: "180px", maxWidth: "180px" }} className={revisionEditStyle.nameCell}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          <div style={{ position: "relative" }} className={revisionEditStyle.unitBox} >
            <div
              style={{ width: "100%", border: "1px solid #B6B6B6", borderRadius: "4px", minHeight: "30px", display: "flex", alignItems: "center", padding: "5px" }}

              //  onClick={() => handleUnitOpen(row.id)}
              dangerouslySetInnerHTML={{ __html: row.unit }}>
              {/* <ReactQuill modules={{ toolbar: false }} readOnly={true} theme="snow" value={row.unit} style={{ width: "100%", height: "100%" }} /> */}

            </div>

          </div>
        </div>
      </td>
      <td style={{ maxWidth: "120px", minWidth: "100px" }} className={revisionEditStyle.valueField}>
        <input type="text" name="value" value={row.value} />
      </td>


      
        <td  style={{ maxWidth: "250px", minWidth: "100px" }} className={revisionEditStyle.remarkField}>
          <textarea
            type="text"
            // name={`remark_${row.id}_${index}`}
            value={row.remark}

          />
        </td>
    

      {row.additionalFields.map((col, index) => (
        <td key={index} style={{ maxWidth: "250px", minWidth: "100px" }} className={revisionEditStyle.remarkField}>
          <textarea
            type="text"
            name={`additional_Colmun_${row.id}_${index}`}
            value={col.columnValue}
           
          />
        </td>
      ))}


    </tr>
  );
};

export default AttributeTab
