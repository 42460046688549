import * as XLSX from 'xlsx';


export const createExcel = (data, fileName) => {
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    // Create a new worksheet
    data.map((data,index)=>{
        let worksheet = XLSX.utils.json_to_sheet(data);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, `Sheet ${index}`);
    })
   

    const blob = XLSX.writeFile(workbook, `${fileName}.xlsx`);
    
    // Create a temporary <a> element
    const link = document.createElement('a'); 
    link.download = `${fileName}.xlsx`; // Set the filename
    link.style.display = 'none'; // Hide the link

    // Add the link to the DOM
    document.body.appendChild(link);

    // Trigger the download programmatically
    link.click();

    // Remove the link from the DOM
    document.body.removeChild(link);

  };