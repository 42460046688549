import React,{useState,useEffect} from 'react'
import styles from './SendToApprovalCommityModal.module.css'
import cStyle from 'components/ModalCommonCSS/ModalCommonCSS.module.css';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CloseIcon from '@mui/icons-material/Close';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import useBackdrop from 'hooks/useBackdrop';
import { PrivateComponent } from 'api/axios';
import { Modal } from '@mui/material';
import useAuth from 'hooks/useAuth';



const SendToApprovalCommityModal = ({ open, onClose, header,showToast,revisionId }) => {
    // Custom hook to get authentication details
    const { auth } = useAuth();
    
    // Function to get an instance of axios for making private API calls
    const privateAxios = PrivateComponent();

    // Custom hook to handle backdrop component, show and hide functionality
    const { BackdropComponent, openBackdrop, closeBackdrop } = useBackdrop();

    // State to manage the type of approval (single or multiple levels)
    const [sendForApprovalType, setSendForApprovalType] = useState(true);

    // State to store the list of users available for approval
    const [approvalUsers, setApprovalUsers] = useState([]);

    // State to manage the list of members in the approval workflow
    const [members, setMembers] = useState([{ email: "" }]);

    // State to manage the due days for approval
    const [dueIn, setDueIn] = useState(1);

    // State to manage the comment input
    const [comment, setComment] = useState("");

 // Function to increment or decrement the due days
    const handleDayIncDec = (type) => {
        let tempDue = dueIn
        if (type) {// Increment due days
            tempDue = tempDue + 1;
        }
        else {// Decrement due days
            if (dueIn > 1) {
                tempDue = tempDue - 1
            }
        }

        setDueIn(tempDue);// Update the state
    }


      // Function to handle selection of members in the approval workflow
    const handleMemberSelect = (e, ind) => {
        const { name, value } = e.target;

        setMembers(prev => {
            return prev.map((mem, index) => {
                if (index === ind) {
                    return {
                        email: value,
                    }
                }
                else {
                    return mem
                }
            })
        })

    }


  // Function to toggle the type of approval (single or multiple levels)
    const toggleSendForApprovalType = () => {
        setSendForApprovalType(prev => !prev)
    }

       // Effect to reset members when the type of approval is changed to single level
    useEffect(() => {
        if (sendForApprovalType) {
            setMembers([{ email: "" }])

        }
    }, [sendForApprovalType]);

      // Function to add a new level in the approval workflow
    const addLevel = () => {
        setMembers(prev => {
            return [
                ...prev,
                {
                    email: "",
                }
            ]
        })
    }

     // Function to remove a level from the approval workflow
    const removeLevel = (ind) => {
        setMembers(prev => {
            return prev.filter((_, index) => index !== ind)
        })
    }

    // Function to fetch users available for approval
    const fetchApprovalUsers = async () => {
        openBackdrop(); // Show the backdrop
        try {
 // API call to get all workspace users
            const response = await privateAxios.get(`/task/getAllWorkspaceUsers/${auth.orgId}/4`);
// Filter and map logged-in users to get their email
            const loggedInEmails = response.data.filter(item => item.loggedIn === true).map(item => { return { email: item.email } });
            setApprovalUsers(loggedInEmails);// Update the state with the fetched users
        } catch (error) {
            console.log(error);// Log any errors
        }
        closeBackdrop();// Hide the backdrop
    };

      // Effect to fetch approval users when the modal is opened
    useEffect(() => {
        if (open) {
            fetchApprovalUsers();
        }
    }, [open])


     // Function to validate the form data for sending approval
    const validateApprovalFormData = () => {
        const isEmptyEmail = members.some(item => item.email === ""); // Check if any email field is empty
        if (!isEmptyEmail && dueIn > 0) {
            return true; // Form data is valid
        } else if (dueIn < 1) {
            showToast("caution", "due days should greater than 1");// Show caution toast for invalid due days
        } else if (isEmptyEmail) {
            showToast("caution", "Please Select the Member");// Show caution toast for empty email
        }
        return false; // Form data is invalid
    }


   // Function to handle the form submission for sending approval
    const handleSendforApproval = async (e) => {
        e.preventDefault();
        openBackdrop();// Show the backdrop
        try {
            if (validateApprovalFormData()) {
                  // Map the members to create the payload for the API call
                const emailsorted = members.map((eml, index) => {
                    return {
                        email: eml.email,
                        stage: `${index + 1}`,
                    }
                })
                const payload = {
                    dueInDays: dueIn,
                    emailAndStage: emailsorted,
                    comments: comment,
                }
                 // API call to send the approval
                const res = await privateAxios.post(`/approval/sendApprovalToApprover/${revisionId}/${auth.userData.id}`, payload);
                showToast("success", "Revision Sent For Approval"); // Show success toast
                onClose(); // Close the modal
                discardApprovalModalData();// Reset the form data
            } else {
                showToast("caution", "Please Check Feilds");// Show caution toast for invalid form data
            }
            closeBackdrop();// Hide the backdrop

        } catch (error) {
            showToast("error", "Error sending data");// Show error toast
            closeBackdrop(); // Hide the backdrop
        }
    }

    // Function to reset the form data in the modal
    const discardApprovalModalData = () => {
        setMembers([{ email: "" }])
        setComment("")
        setDueIn(1);
        setSendForApprovalType(true)
    }


    // ========================send for approval states=====================


    return (
        <>
            <BackdropComponent />
            <Modal
                open={open}
                onClose={onClose}
            >
                <div className={styles.modalContainer}>
                    <form onSubmit={handleSendforApproval} className={styles.formContainer}>
                        <div className={styles.modalHeader} >

                            <h3>
                                <span>
                                    <PeopleOutlineIcon />
                                </span>
                                {header}
                            </h3>
                            <span onClick={() => {discardApprovalModalData(); onClose() }}>
                                <CloseIcon />
                            </span>
                        </div>

                        <div className={styles.formGroup}>
                            <label htmlFor="">Due in</label>
                            <div className={styles.daysSelector}>
                                <p>{dueIn} Days </p>
                                <div className={styles.daysSelectorbtns}>

                                    <span onClick={() => handleDayIncDec(false)}>
                                        -
                                    </span>
                                    <span onClick={() => handleDayIncDec(true)}>
                                        +
                                    </span>
                                </div>
                            </div>
                            {/* <input type="date" name="dueDate" value={dueData} onChange={(e)=>setDueDate(e.target.value)}  /> */}

                        </div>
                        {members.map((member, index) => {
                            return (

                                <div className={styles.formGroup} key={`selector ${index}`}>
                                    <label htmlFor="">Select Member</label>
                                    <select name="" id="" value={member.email} onChange={(e) => handleMemberSelect(e, index)}>
                                        <option value="">Select A Member</option>
                                        {approvalUsers.map(item => {
                                            const isAlreadySelected = members.some(m => m.email === item.email);
                                            return (
                                                <option key={item.email} value={item.email} disabled={isAlreadySelected}>
                                                    {item.email}
                                                </option>
                                            );
                                        })}


                                    </select>
                                    {
                                        index > 0 &&
                                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                            <span style={{ fontSize: "12px", color: "#16469D", cursor: "pointer" }} onClick={() => removeLevel(index)}>Remove</span>
                                        </div>
                                    }

                                </div>
                            )
                        })
                        }
                        {
                            !sendForApprovalType &&
                            <div>
                                <p onClick={addLevel} style={{ fontSize: "14px", fontWeight: "600", textDecoration: "underline", cursor: "pointer" }}>Add Level</p>
                            </div>
                        }

                        <div className={styles.formGroup}>
                            <label htmlFor="comment">Comments (if any)</label>

                            <textarea type="text" id='comment' name='comment' value={comment} onChange={(e) => setComment(e.target.value)} />
                        </div>

                        <div className={styles.orDivider}>
                            <span>OR</span>
                        </div>

                        <div
                            className=''
                            onClick={() => toggleSendForApprovalType()}
                            style={{ cursor: "pointer", width: "100%", borderRadius: "5px", height: "40px", backgroundColor: "#F6FAFF", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h4 style={{ color: "#16469D", display: "flex", justifyContent: "center", gap: "5px", alignItems: "center", fontWeight: "600" }}>
                                <span style={{ color: "inherit", height: "20px", width: "20px", justifyContent: "center", alignItems: "center" }}>
                                    {sendForApprovalType ?
                                        <SettingsOutlinedIcon style={{ color: "inherit", height: "inherit", width: "inherit" }} />
                                        :

                                        <ArrowBackOutlinedIcon style={{ color: "inherit", height: "inherit", width: "inherit" }} />
                                    }
                                </span>
                                {sendForApprovalType ?

                                    "Set up approval workflow"
                                    :
                                    "Go Back"
                                }
                            </h4>


                        </div>






                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>

                            <button className={`${cStyle.modalBtn} ${cStyle.pimaryModalBtn}`} type='submit'>Send </button>


                            <p
                                onClick={() => { discardApprovalModalData(); onClose() }}
                                className={`${cStyle.modalBtn} ${cStyle.secondaryModalBtn}`}
                            >Cancel</p>
                        </div>



                    </form>
                </div>

            </Modal>
        </>
    )
}

export default SendToApprovalCommityModal
