import { useState, useEffect, useRef } from 'react';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import { PrivateComponent } from 'api/axios'
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Tooltip from '@mui/material/Tooltip';
import { Link, useLocation } from 'react-router-dom';
import VendorApproval from './VendorApproval';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import UserApproval from './UserApproval';
import useToast from 'hooks/useToast';
import { HelpOutlineOutlined } from '@mui/icons-material';


const Approvals = ({ handleEditTaskModal, onSearch }) => {// State to store the revision ID
    const [revId, setRevId] = useState(""); // Hook to get the authentication details
    const { auth } = useAuth();
    const [email, setEmail] = useState([]);

    const { Toast, showToast } = useToast()
    const [userApprovalCount, setUserApprovalCount] = useState("0");// State to store user approval count
    const [vendorApprovalCount, setVendorApprovalCount] = useState("0");// State to store vendor approval count

    // Function to get the vendor approval count
    const getVendorApprovalCount = async () => {
        try {
            await privateAxios.get(`/technicalSpecification/getAllVendorRevisionsInApproval/${auth.userData.id}`)
                .then(res => {
                    setVendorApprovalCount(res.data.length); // Set the vendor approval count
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    // Function to get the user approval count
    const getUserApprovalCount = async () => {
        try {
            const response = await privateAxios.get(`/technicalSpecification/getAllRevisionsForApprovalOfUser/${auth.userData.id}`)
                .then(res => {
                    setUserApprovalCount(res.data.length)
                })
        }
        catch (error) {
            console.log(error);
        }
    }

    // useEffect hook to fetch approval counts on component mount
    useEffect(() => {
        getVendorApprovalCount();// Fetch vendor approval count
        getUserApprovalCount();// Fetch user approval count
    }, [])


    const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);// State to manage reassign modal open status
    const [resignedData, setResignedData] = useState(false);  // State to store resigned data

    // Function to open the reassign modal
    const openReassignModal = async (taskId) => {
        try {
            const response = await privateAxios.get(`/task/getTaskById/${taskId}`);// Fetch task by ID
            const responseData = response.selectedVendorRevisions; // Get the selected vendor revisions
            setResignedData(responseData);// Set the resigned data
            setIsReassignModalOpen(true);// Open the reassign modal
        } catch (error) {
        }
    };

    // Function to close the reassign modal
    const closeReassignModal = () => {
        setIsReassignModalOpen(false);
    };



    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);// Update search value
    };
    const [selectedEmails, setSelectedEmails] = useState([]);// State to store selected emails

    // Function to handle email checkbox change
    const handleEmailCheckboxChange = (email) => {
        if (selectedEmails.includes(email)) {
            setSelectedEmails(selectedEmails.filter((selectedEmail) => selectedEmail !== email));// Remove email from selected list
        } else {
            setSelectedEmails([...selectedEmails, email]); // Add email to selected list
        }
    };


    // useEffect hook to fetch vendors on component mount
    useEffect(() => {
        const fetchVendors = async () => {
            try {
                const response = await privateAxios.get(`/vendor/getAllVendors/${auth.orgId}`); // Fetch all vendors

                if (response.status === 200) {
                    const vendorEmails = response.data.map((vendor) => vendor.email);// Map vendor emails
                    setEmail(vendorEmails);// Set vendor emails in state
                }
            } catch (error) {
                console.error('Error fetching vendors:', error); // Log any errors
            }
        };
        fetchVendors();// Call fetchVendors function
    }, [auth.orgId]);


    const [formData, setFormData] = useState({
        emails: [],
        dueDate: "",
        comments: "",
    }); // State to store form data

    // Function to handle input change in form
    const handleInputChange = (e) => {
        const { name, value } = e.target; // Destructure name and value from event target
        setFormData({
            ...formData,
            [name]: value,// Update form data
        });
    };

    // Function to send revision for approval to vendor
    const sendRevisionForApprovalToVendor = (data) => {
        privateAxios.post(`/technicalSpecification/sendRevisionForApprovalToVendor/${auth.userData.id
            }/${revId}`, data)
            .then((response) => {

                if (response.status === 200) {
                    setSendVendor(false);// Close the send vendor modal
                } else {
                    console.error('Error sending for approval to vendor');
                }
                setRevId(""); // Reset revision ID
            })
            .catch((error) => {
                setRevId("");// Reset revision ID
                console.error('Error sending for approval to vendor:', error);// Log any errors
            });
    };


    // Function to handle send button click
    const handleSendButtonClick = (e) => {
        e.preventDefault();// Prevent default form submission

        const data = {
            emails: emails,
            comments: formData.comments,
            dueDate: formData.dueDate,
        };// Prepare data for sending


        sendRevisionForApprovalToVendor(data);// Call function to send revision for approval
    };


    const navigate = useNavigate();// Hook to navigate
    const handleNavigate = (link) => {
        navigate(link);// Navigate to the specified link
    }
    const [containerStyle, setContainerStyle] = useState({
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: '#fff',
        transition: 'background-color 0.3s',
    });// State to manage container style

    // Function to handle mouse enter event
    const handleMouseEnter = () => {
        setContainerStyle(prevStyle => ({
            ...prevStyle,
            backgroundColor: '#fff',
        }));// Update container style on mouse enter
    };

    // Function to handle mouse leave event
    const handleMouseLeave = () => {
        setContainerStyle(prevStyle => ({
            ...prevStyle,
            backgroundColor: '#fff',
        }));// Update container style on mouse leave
    };

    // Function to handle search input change
    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);// Update search value
    };

    // Function to handle search
    const handleSearch = () => {
        onSearch(searchValue);// Call onSearch function with search value
    };


    const privateAxios = PrivateComponent();// Initialize privateAxios
    const [searchValue, setSearchValue] = useState("")// State to store search value
    const [emails, setEmails] = useState([]);// State to store emails
    const [userDropDownOpen, setUserDropDownOpen] = useState(false)// State to manage user dropdown open status
    const [assignSelected, setAssignSelected] = useState("All");// State to store selected assign option
    const [userSorterList, setUserSorterList] = useState([]); // State to store user sorter list

    const [sendVendor, setSendVendor] = useState(false);// State to manage send vendor status

    // Function to toggle send vendor status
    const handlesendVendor = (id) => {
        setSendVendor(prev => !prev);// Toggle send vendor status
        setRevId(id); // Set revision ID
    }
    const [selectedTab, setSelectedTab] = useState(0);// State to store selected tab index
    const [renameModalOpen, setRenameModalOpen] = useState(false);// State to manage rename modal open status

    // Inside the rendering code, ensure that the "Rename" modal is opened correctly
    const openRenameModal = (id) => {
        // Set the revId and renameText values based on your logic
        setRevId(id); // Set the correct revId
        // setRenameText(someInitialValue); // Set the initial value for renameText
        setRenameModalOpen(true);
    };
    const closeRenameModal = () => {
        setRenameModalOpen(false);
    };


    const location = useLocation();// Hook to get the location
    const { state } = location;// Destructure state from location
    const searchParams = new URLSearchParams(location.search); // Get search params from location

    // useEffect hook to set selected tab based on search params
    useEffect(() => {
        if (searchParams.get("tab") !== null) {
            setSelectedTab(searchParams.get("tab")); // Set selected tab from search params
        }
        else {
            setSelectedTab("0"); // Default to tab 0
        }

    }, [Location, searchParams]);

    // Function to handle tab change
    const handleTabChange = (index) => {
        setSelectedTab(index); // Set selected tab index
        navigate(`?tab=${index}`, { replace: true });// Navigate to the selected tab
    }

    const [revisionsData, setRevisionsData] = useState([]);// State to store revisions data

    // Function to fetch revisions based on search
    const fetchRevisionsBySearch = async () => {
        // Determine the search URL based on the selected tab
        const searchURL = selectedTab === 0 ? '/technicalSpecification/searchRevisionsForApproval' : '/technicalSpecification/searchVendorRevisionsForApproval';
        try {
            // Fetch revisions based on search criteria
            const response = await privateAxios.get(searchURL, {
                params: {
                    search: searchValue,
                    userId: auth.userData.id,
                },
            });

            if (response.status === 200) {
                setRevisionsData(response.data);// Set fetched revisions data
            } else {
                console.error('Error fetching revisions based on search criteria');
            }
        } catch (error) {
            console.error('Error fetching revisions based on search criteria:', error);
        }
    };


    // useEffect hook to fetch revisions on searchValue change
    useEffect(() => {
        let timer;
        if (searchValue !== '') {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fetchRevisionsBySearch(); // Fetch revisions based on search value
            }, 500);
        }


        return () => clearTimeout(timer);
    }, [searchValue]);


    const [selectedVendorRevisions, setSelectedVendorRevisions] = useState([]);
    const [compareModal, setCompareModal] = useState(false);
    const [groupModal, setGroupModal] = useState(false);

    // Function to handle selection of vendor revisions
    const handleVendorRevisionSelect = (data) => {

        const selectedIndex = selectedVendorRevisions.findIndex(
            (item) => item.id === data.id
        );

        if (selectedIndex === -1) {
            // If revision not selected, add to selected list
            setSelectedVendorRevisions((prevSelected) => [...prevSelected, data]);
        } else {
            // If revision already selected, remove from list
            setSelectedVendorRevisions((prevSelected) =>
                prevSelected.filter((item) => item.id !== data.id)
            );
        }

    };

    // Function to validate selected revisions for comparison
    const compareValidation = () => {
        if (selectedVendorRevisions.length < 2) {
            alert("please select atleast 2 Options");// Alert user if less than 2 revisions selected
        }
        else {
            // Check if all selected revisions have the same name and category
            const firstObjectName = selectedVendorRevisions[0].productName;
            const firstObjectCategory = selectedVendorRevisions[0].type;
            let allObjectsHaveSameName = true;
            let allObjectsHaveSameCategory = true;

            for (let i = 1; i < selectedVendorRevisions.length; i++) {
                if (selectedVendorRevisions[i].productName !== firstObjectName) {
                    allObjectsHaveSameName = false;
                    break;
                }
                if (selectedVendorRevisions[i].type !== firstObjectCategory) {
                    allObjectsHaveSameCategory = false;
                    break;
                }
            }
            const hasGroup = selectedVendorRevisions.some(obj => obj.vendorRevisionType === "GROUP");
            // Perform validation based on conditions
            if (allObjectsHaveSameName && !hasGroup && allObjectsHaveSameCategory) {
                handleCompareNameModal();

            } else if (hasGroup) {
                alert("Compare Can't have Group in it");// Alert user if any revision is a group
            }
            else if (!allObjectsHaveSameName) {
                alert("Not all Selected Revision have the same Product name.");// Alert user if not all revisions have the same name
            }
            else if (!allObjectsHaveSameCategory) {
                alert("Not all Selected Revision have the same Product Category."); // Alert user if not all revisions have the same category
            }

        }
    }

    // Function to toggle compare modal
    const handleCompareNameModal = () => {
        setCompareModal(prev => !prev);
    }

    // Function to perform group validation
    const groupValidation = async () => {
        if (selectedVendorRevisions.length < 2) {
            alert("please select atleast 2 Options");// Alert user if less than 2 revisions selected
        }
        else {
            handleGroupNameModal();
        }
    }

    // Function to toggle group modal
    const handleGroupNameModal = () => {
        setGroupModal(prev => !prev);
    }



    return (
        <>

            <Toast />

            <div className={workSpaceStyle.workSpaceMain}>

                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft} style={{}}>
                        <h1>


                            Approval
                        </h1>
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{}}>
                        <input type="text"
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            placeholder='Search...' />
                    </div>
                    {selectedTab === "0" ?
                        <div className={workSpaceStyle.workSpaceHeaderRight} style={{}} >

                            <div className={workSpaceStyle.WorkSpaceHeaderSorters} style={{}}>
                                <p>Sort By:</p>
                                <h3 onClick={() => { setUserDropDownOpen(prev => !prev); console.log("soemr") }}><Tooltip title='Filter Assign'><ImportExportIcon /></Tooltip>{`${assignSelected.slice(0, 7) + '...'}`}</h3>
                                {userDropDownOpen &&
                                    <>
                                        <div className={workSpaceStyle.workSpaceHeaderDropDownBackDrop} onClick={() => setUserDropDownOpen(prev => !prev)}></div>
                                        <div className={workSpaceStyle.workSpaceHeaderSorterDropdown} style={{ width: '220px' }}>
                                            <h5 style={{ fontSize: '15px' }}>Send by:</h5>

                                            <ul>
                                                <li style={{ fontSize: '12px' }} onClick={() => ("All")} >All</li>
                                                {
                                                    userSorterList.map((email, index) => {
                                                        return (

                                                            <li style={{ fontSize: '12px' }} onClick={() => (email)} key={index}>{email}</li>
                                                        )
                                                    })
                                                }


                                            </ul>


                                        </div>
                                    </>
                                }
                            </div>
                            <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{}}>
                                <Tooltip title='Help'>
                                    <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000080852-approval">
                                        <HelpOutlineOutlined />
                                    </a>
                                </Tooltip>
                            </div>
                        </div>
                        :
                        <div className={workSpaceStyle.workSpaceHeaderRight}>

                            <button className={workSpaceStyle.createGroupBtn} onClick={groupValidation}>
                                Create A Group
                            </button>
                            <button className={workSpaceStyle.CompareBtn} onClick={compareValidation}>
                                Compare
                            </button>

                        </div>
                    }
                </div>



                <div className={workSpaceStyle.tabHeaderMain}>
                    <div
                        onClick={() => handleTabChange("0")}
                        className={selectedTab === "0" ? `${workSpaceStyle.revisionTab} ${workSpaceStyle.revisionTabActive} ` : `${workSpaceStyle.revisionTab}`}>
                        User <span>{userApprovalCount}</span>
                    </div>
                    {auth.roles === "KAM" && <div
                        onClick={() => handleTabChange("1")}
                        className={selectedTab === "1" ? `${workSpaceStyle.revisionTab} ${workSpaceStyle.revisionTabActive} ` : `${workSpaceStyle.revisionTab}`}>
                        Vendor <span>{vendorApprovalCount}</span>
                    </div>}

                </div>

                {selectedTab === "0" && (
                    <UserApproval searchValue={searchValue} setUserApprovalCount={setUserApprovalCount} showToast={showToast} />
                )}


                {selectedTab === "1" && auth.roles === "KAM" &&

                    <VendorApproval searchValue={searchValue}
                        showToast={showToast}
                        handleVendorRevisionSelect={handleVendorRevisionSelect}
                        setSelectedVendorRevisions={setSelectedVendorRevisions}
                        selectedVendorRevisions={selectedVendorRevisions}
                        compareModal={compareModal}
                        handleCompareNameModal={handleCompareNameModal}
                        groupModal={groupModal}
                        handleGroupNameModal={handleGroupNameModal}

                        setVendorApprovalCount={setVendorApprovalCount}

                    />

                }
            </div>
        </>
    )
}

export default Approvals



