import React, { useState, useEffect } from 'react'
import styles from './TaskHistoryModal.module.css'
import CloseIcon from '@mui/icons-material/Close';
import useBackdrop from 'hooks/useBackdrop';
import { PrivateComponent } from 'api/axios';
import { Modal } from '@mui/material';
import useAuth from 'hooks/useAuth';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';


const TaskHistoryModal = ({ open, onClose, header, showToast, revisionId,handleRevokeRequest }) => {
     // Custom hook to get authentication details
    const { auth } = useAuth();

     // Function to get an instance of axios for making private API calls
    const privateAxios = PrivateComponent();

     // Custom hook to handle backdrop component, show and hide functionality
    const { BackdropComponent, openBackdrop, closeBackdrop } = useBackdrop();


    // Function to format ISO date into a readable format
    const formatIsoDate = (inputDate) => { 
        const dateObject = new Date(inputDate);// Convert to Date object
        // Format the Date object
        const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
        const formattedDate = dateObject.toLocaleString('en-US', options);// Format and return the date

        return formattedDate;
    }

    // ===================date formating==============================

    // ==========================gET HISTORY======================

    // State to manage the approval history data
    const [approvalHistory, setApprovalHistory] = useState([{}]);

      // Function to get approval history for a specific revision
    const getApprovalHistory = async () => {
         // Show the backdrop
        openBackdrop();
        try {
            const res = await privateAxios.get(`/approval/getHistoryOfRevision/${revisionId}`);
            setApprovalHistory(res.data); // Update the state with the fetched data
        } catch (error) {
            console.log(error);// Log any errors
        }
        closeBackdrop();// Hide the backdrop
    }

      // Effect to get approval history when the component mounts
    useEffect(() => {
        getApprovalHistory(); // Fetch the approval history
    }, []); // Empty dependency array means this runs once when the component mounts

    // ==========================gET HISTORY======================

    // ========================= Reminder to Approver=================


       // Function to handle sending a reminder to an approver
    const handleReminderToApprover = async (approverId) => {
        openBackdrop(); // Show the backdrop
        try {
            const res = await privateAxios.post(`/approval/sendReminder/${approverId}`); // API call to send reminder
            console.log(res.data); // Log the response data
            showToast("success", "Reminder Sent"); // Show success toast
        } catch (error) {
            console.log(error);
            showToast("error", "Error sending reminder"); // Show error toast
        }
        closeBackdrop();// Hide the backdrop
    }

    // ========================= Reminder to Approver=================





    return (
        <>
            <BackdropComponent />
            <Modal
                open={open}
                onClose={onClose}
            >
                <div className={styles.modalContainer}>
                    <div className={styles.formContainer}>
                        <div className={styles.modalHeader} >

                            <h3>
                                {/* <span>
                                    <PeopleOutlineIcon />
                                </span> */}
                                {header}
                            </h3>
                            <span onClick={() => { onClose() }}>
                                <CloseIcon />
                            </span>
                        </div>


                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>


                            <div className={styles.historyRowWrapper}>
                                {approvalHistory.map((item, index) => {
                                    if (item.revokeStatus!=="requested") {


                                        return (
                                            <div className={styles.historyRow}>
                                                <div style={{ display: "flex", flexGrow: "1" }}>
                                                    <div className={styles.historyRowIcon}>
                                                        <span style={{
                                                            backgroundColor: item.status === "approved"
                                                                ? "#14c971"
                                                                : item.status === "pending"
                                                                    ? "#f9d249" : item.status === "na"
                                                                        ? "#d2d2d2" : "red"
                                                        }}>
                                                            {
                                                                item.status === "pending" ?
                                                                    <ErrorOutlineOutlinedIcon />
                                                                    :
                                                                    item.status === "na" ?
                                                                        <CloseIcon />
                                                                        :
                                                                        <CheckOutlinedIcon />

                                                            }

                                                        </span>
                                                    </div>
                                                    <div className={styles.historyRowCont}>
                                                        <div>

                                                            <h4>
                                                                {item.status === "approved" ? "Approved by" : item.status === "pending" ? "Apprvoal Pending from" : item.status === "na" ? "Apprvoal Pending from" : "Declined By"}
                                                                {/* {item.status === "approved" ? "Approved by" : item.status === "pending" ? "Apprvoal Pending from" : "Declined By"} */} {item.approverName}
                                                            </h4>
                                                            <span style={{ backgroundColor: item.status === "approved" ? "#14c971" : item.status === "pending" ? "#f9d249" : item.status === "na" ? "#d2d2d2" : "red" }}>{index + 1}/{approvalHistory.length}</span>
                                                        </div>
                                                        <p>
                                                            {formatIsoDate(item.dueDate)}

                                                        </p>
                                                    </div>
                                                </div>
                                                {
                                                    item.status === "pending" &&
                                                    <div>
                                                        <span onClick={() => handleReminderToApprover(item.approverId)}>
                                                            <NotificationsNoneOutlinedIcon />
                                                        </span>
                                                    </div>
                                                }

                                                {
                                                    index > 0 &&

                                                    <div className={styles.historyRowProgressLine} style={{ backgroundColor: item.status === "approved" ? "#14c971" : item.status === "pending" ? "#f9d249" : item.status === "na" ? "#d2d2d2" : "red" }}></div>
                                                }

                                            </div>

                                        )
                                    }
                                    else {
                                        return (
                                            <div className={styles.historyRow}>
                                                <div style={{ display: "flex", flexGrow: "1" }}>
                                                    <div className={styles.historyRowIcon}>
                                                        <span style={{ backgroundColor: "#f9d249" }}>
                                                           
                                                                    <ErrorOutlineOutlinedIcon />
                                                                   

                                                        </span>
                                                    </div>
                                                    <div className={styles.historyRowCont}>
                                                        <div>

                                                            <h4 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => {
                                                                //  handleRevokeRequestModal({ id: taskHistoryData.id, name: item.approverName }); 
                                                                onClose();
                                                                handleRevokeRequest(item);
                                                            }}>
                                                                Revoke Request From {item.approverName}
                                                            </h4>
                                                            <span style={{ backgroundColor: "#f9d249" }}>{index + 1}/{approvalHistory.length}</span>
                                                        </div>
                                                        <p>
                                                            {formatIsoDate(item.dueDate)}

                                                        </p>
                                                    </div>
                                                </div>
                                                {
                                                    index > 0 &&

                                                    <div className={styles.historyRowProgressLine} style={{ backgroundColor: "#f9d249" }}></div>
                                                }

                                            </div>

                                        )
                                    }
                                })}





                            </div>


                        </div>
                    </div>
                </div>

            </Modal>
        </>
    )
}

export default TaskHistoryModal
