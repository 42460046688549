import React, { useState, useEffect, useRef } from 'react';
import ProfileStyle from '../ProfilePage/ProfileStyle.module.css';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import CustomModal from 'components/CustomModal/CustomModal';
import { MuiOtpInput } from 'mui-one-time-password-input';
import PhoneInput from 'react-phone-input-2';
import Tooltip from '@mui/material/Tooltip';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useAuth from 'hooks/useAuth';
import useBackdrop from 'hooks/useBackdrop';
import { useDispatch } from 'react-redux';
import { PrivateComponent } from 'api/axios';
import ErrorToast from 'components/ErrorToast/ErrorToast';
import { HelpOutlineOutlined } from '@mui/icons-material';

const VendorProfile = () => {
    // Define state variables and initialize them using useState hook
    const privateAxios = PrivateComponent(); // PrivateComponent is a custom hook or function returning an instance of Axios for private requests
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Custom hook for handling backdrop component
    const { auth, setUserData, setMobNum, setProfilePic } = useAuth(); // Custom hook for handling authentication
    const inputRefNewPassword = useRef(null); // Ref for new password input field
    const inputRefConfirmPassword = useRef(null); // Ref for confirm password input field
    const [verifyOtp, setVerifyOtp] = useState('') // State for OTP verification
    const [mobileNumber, setmobileNumber] = useState('') // State for mobile number input
    const dispatch = useDispatch() // Redux dispatch function
    const [changeTabOpen, setChangeTabOpen] = useState(false); // State for managing tab change
    const [OTPOldNum, setOTPOldNum] = useState(false); // State for old phone number OTP
    const [OTPNewNum, setOTPNewNum] = useState(false); // State for new phone number OTP
    const [OTPOldMail, setOTPOldMail] = useState(false); // State for old email OTP
    const [updateMobileOpen, setupdateMobileOpen] = useState(false); // State for updating mobile number
    const [changenumToMail, setchangenumToMail] = useState(false); // State for changing phone to email
    const [showPasswordNew, setShowPasswordNew] = useState(false); // State for showing new password
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false); // State for showing confirm password
    const [isPasswordValid, setIsPasswordValid] = useState(false); // State for password validation
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const [mobOTPError, setMobOTPError] = useState(""); // State for mobile OTP error
    const [phoneVerifyId, setPhoneVerifyId] = useState(""); // State for phone verification ID
    const [oldPhoneVerified, setOldPhoneVerified] = useState(false); // State for old phone verification
    const [phoneOtp, setPhoneOtp] = useState(""); // State for phone OTP
    const [updatedMob, setUpdatedMob] = useState(''); // State for updated mobile number
    const [mobilenumErr, setMobilenumErr] = useState(""); // State for mobile number error
    const [addMobileOpen, setAddMobileOpen] = useState(false); // State for adding new mobile number
    const [OTPAddNum, setOTPAddNum] = useState(false); // State for adding new mobile number OTP
    const [numberAddErr, setNumberAddErr] = useState(""); // State for new mobile number error
    const [addedNum, setAddedNum] = useState(""); // State for added mobile number

    // Function to toggle tab change
    const handleChangeTab = () => {
        setChangeTabOpen((prev) => !prev);

    };

     // Function to handle sending OTP to old phone number
    const handleOTPOldNumTab = () => {
           // Prepare phone number for API request
        const phoneNumber = auth.mobNum.charAt(0) !== '+' ? `+${auth.mobNum}` : auth.mobNum;
            // Send request to send OTP to registered phone number
        privateAxios.post(`/procurement/sendOtpOnRegisteredPhoneToChange/${auth.email}`, {
            phone: phoneNumber
        })
            .then((res) => {
                if (res.status === 200) {
                      // Update states on successful response
                    setChangeTabOpen(false);
                    setOTPOldNum((prev) => !prev);
                    setPhoneVerifyId(res.data.data.kamId);
                    setPhoneOtp("");
                }
            }).catch(err => {
            })
    };

    // Function to submit OTP for old phone number verification
    const submitOTP = (e) => {
        e.preventDefault();
        openBackdrop();//Open backdrop

        if (phoneVerifyId !== "" && phoneOtp.length > 0) {
             // Send OTP verification request
            privateAxios.post(`/vendor/verifyOtpOnRegisteredPhoneToChange/${auth.userData.id}/${auth.mobNum}`, { mobOtpCode: phoneOtp })
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        // Update states on successful verification
                        setOldPhoneVerified(true);
                        setOTPOldNum(false);
                        setOTPNewNum(false);
                        setOTPOldMail(false);
                        setupdateMobileOpen((prev) => !prev);
                        setmobileNumber('')
                        closeBackdrop();//Close backdrop
                    } else {
                        setMobOTPError("IncorrectOTP")
                        closeBackdrop();//Close backdrop
                    }
                    closeBackdrop();//Close backdrop
                }).catch(err => {
                    setMobOTPError("Soemthing Went Wrong Please Try Again or Refresh")
                    closeBackdrop();//Close backdrop
                })
        }
        else {
            setMobOTPError("Please Enter the OTP!");
            closeBackdrop();//Close backdrop
        }
    };

     // Function to toggle change from phone number to email
    const changePhonetoMail = () => {
        setChangeTabOpen(false);
        setchangenumToMail((prev) => !prev);
    }

     // Function to handle sending OTP to old email
    const handleOTPOldEmailTab = () => {
        openBackdrop();//Open backdrop
         // Send request to send OTP to registered email
        privateAxios.post(`/procurement/sendOtpOnRegisteredEmailToChangeNo`, {
            email: auth.email
        })
            .then((res) => {
                if (res.status === 200) {
                      // Update states on successful response
                    setPhoneVerifyId(res.data.data.kamId);
                    setchangenumToMail(false);
                    setOTPOldMail((prev) => !prev);
                    setVerifyOtp('')
                    setMobOTPError('')
                    closeBackdrop();//Close backdrop
                }
                closeBackdrop();//Close backdrop
            }).catch(err => {
                closeBackdrop();//Close backdrop
            })
    };

    const handleOTPOldEmailTabc = () => {
        setOTPOldMail((prev) => !prev);
    }

      // Function to handle submitting OTP for old email verification
    const submitMailOTP = (e) => {
        e.preventDefault();
        openBackdrop();//Open Backdrop
        if (phoneVerifyId !== "" && verifyOtp.length > 0) {
             // Send OTP verification request for email
            privateAxios.post(`/vendor/verifyOtpOnRegisteredEmailToChange/${auth.email}/${auth.userData.id}`, { emailOtpCode: verifyOtp })
                .then((res) => {
                    if (res.data.statusCode === 200) {
                         // Update states on successful verification
                        setOldPhoneVerified(true);
                        setOTPOldNum(false);
                        setOTPNewNum(false);
                        setOTPOldMail(false);
                        setupdateMobileOpen((prev) => !prev);
                        setmobileNumber('')
                        closeBackdrop();//Close backdrop
                    } else {
                        setMobOTPError("Incorrect OTP!")
                        closeBackdrop();//Close backdrop
                    }
                }).catch(err => {
                    setMobOTPError("Soemthing Went Wrong!")
                    closeBackdrop();//Close backdrop
                })
        }
        else {
            setMobOTPError("Please Enter the OTP!");
            closeBackdrop();//Close backdrop
        }
    };


  // Function to handle OTP input change
    const handleChange = (newValue) => {
        setVerifyOtp(newValue);
    };

    // Function to handle OTP submission for new phone number
    const handleNewNumOTP = (e) => {
        e.preventDefault();
        openBackdrop();//open backdrop

          // Validate mobile number
        if (mobileNumber.length < 3) {
            setMobilenumErr("Enter Mobile Number!");
            closeBackdrop();
        } else if (mobileNumber.length < 8) {
            setMobilenumErr("Enter Correct Mobile Number!")
            closeBackdrop();
        } else if ("+" + mobileNumber == auth.mobNum) {
            setMobilenumErr("You have entered the same Mobile Number!")
        } else {
              // Send request to update phone number and send OTP
            privateAxios.post(`/vendor/UpdatePhoneAndSendOtp/${auth.userData.id}/${auth.email}`,
                { phone: mobileNumber }
            )
                .then((res) => {
                    if (res.status === 200) {
                          // Update states on successful response
                        setUpdatedMob("+" + res.data.data.phone)
                        setupdateMobileOpen(false);
                        setOTPNewNum((prev) => !prev);
                        setVerifyOtp("")
                        closeBackdrop();//Close backdrop
                    }
                    closeBackdrop();
                }).catch(err => {
                    setMobOTPError("Soemthing Went Wrong Please Try Again or Refresh")
                    closeBackdrop();//Close backdrop
                })
        }
    }

     // Function to handle OTP submission for verifying updated phone number
    const handleNumberChange = (e) => {
        setMobOTPError('');
        e.preventDefault();
        openBackdrop();//Open Backdrop
        if (phoneVerifyId !== "" && verifyOtp.length > 0) {
             // Send request to verify OTP for updated phone number
            privateAxios.post(`/vendor/verifyOtpOnUpdatedPhone/${auth.userData.id}/${updatedMob}`, { mobOtpCode: verifyOtp })
                .then((res) => {
                    if (res.status === 200) {
                        // Update states on successful verification
                        alert("Updated Successfully!");
                        dispatch(setMobNum({ mobNum: updatedMob }));
                        setOTPNewNum(false);
                        closeBackdrop();//Close backdrop
                    }
                    closeBackdrop();
                }).catch(err => {
                    setMobOTPError("Incorrect OTP")
                    closeBackdrop();//Close backdrop
                })
        }
        else {
            setMobOTPError("Please Enter the OTP!");
            closeBackdrop();//Close backdrop
        }
    }
    // -----------------------------------------------------------------------------

     // Function to toggle adding new mobile number
    const handleAddButtonClick = () => {
        setAddMobileOpen((prev) => !prev);
        setVerifyOtp('')
        setmobileNumber("")
        setNumberAddErr('')
        setOTPAddNum(false);
    }

     // Function to reset fields when adding new mobile number
    const handleAddNumOTPS = () => {
        setVerifyOtp('')
        setmobileNumber("")
        setNumberAddErr('')
        setOTPAddNum(false);
    }

    // Function to handle OTP submission for adding new mobile number 
    const handleAddNumOTP = (e) => {
        e.preventDefault();
        openBackdrop();//open backdrop
        if (mobileNumber.length < 3) {
            setNumberAddErr("Enter Mobile Number");
            closeBackdrop();//close backdrop
        } else if (mobileNumber.length < 8) {
            setNumberAddErr("Enter Correct Mobile Number");
            closeBackdrop();//close backdrop
        } else {
            // Send request to add new mobile number
            privateAxios.post(`/vendor/addPhoneForVendor/${auth.userData.id}`,
                { phone: "+" + mobileNumber })
                .then((res) => {
                    if (res.status === 200) {
                         // Update states on successful response
                        setAddMobileOpen(false);
                        setOTPAddNum((prev) => !prev);
                        setVerifyOtp('');
                        setMobOTPError('');
                        setAddedNum(mobileNumber)
                        closeBackdrop();
                    }
                    closeBackdrop();//close backdrop
                }).catch(err => {
                    closeBackdrop();//close backdrop
                })
        }
    }

       // Function to handle submission of OTP for adding new mobile number
    const handleNumberAdd = (e) => {
        setMobOTPError('');
        e.preventDefault();
        openBackdrop();//open backdrop
        if (verifyOtp.length > 0) {
              // Send request to verify OTP for adding new mobile number
            privateAxios.post(`/vendor/verifyVendorMobileOtp/${auth.userData.id}/+${mobileNumber}`, { mobOtpCode: verifyOtp })
                .then((res) => {
                    if (res.status === 200) {
                        // Update states on successful verification
                        setOTPAddNum(false)
                        dispatch(setMobNum({ mobNum: addedNum }));
                        alert("submitted Number")
                        closeBackdrop();//close backdrop
                    }
                    closeBackdrop();//close backdrop
                }).catch(err => {
                    closeBackdrop();//close backdrop
                    setMobOTPError("Incorrect OTP!")
                })
        }
        else {
            setMobOTPError("Please Enter the OTP!");
            closeBackdrop();//close backdrop
        }
    }

   
     // Function to handle image upload for profile picture
    const handleImageUpload = () => {
  // Create file input element
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = async (e) => {
            const file = e.target.files[0];

            if (file) {
                const formData = new FormData();
                formData.append('file', file);

                try {
                    openBackdrop();//open backdrop
                      // Upload profile photo
                    await privateAxios.post(`/vendor/uploadProfilePhotoVendor`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(res => {
                        closeBackdrop();//close backdrop
                           // Update user data and profile picture
                        const updatedUserData = { ...auth.userData, photo: res.data };
                        dispatch(setUserData({
                            userData: updatedUserData
                        }));
                        dispatch(setProfilePic({ profilePic: res.data }));
                        alert('Profile photo updated successfully.');
                    })

                } catch (error) {
                    closeBackdrop();//close backdrop
                      // Handle error while updating profile photo
                    console.error('Error updating profile photo:', error);
                    alert('An error occurred while updating profile photo.');
                }
            }
        };
        input.click(); // Trigger file input click
    }

    //Function to Allow only numbers 0-9, backspace, and delete key  
    const handleKeyPress = (e) => {
        const keyCode = e.keyCode || e.which;
        const keyValue = String.fromCharCode(keyCode);

        const validInput = /^[0-9\b]+$/.test(keyValue) || (
            (keyCode >= 96 && keyCode <= 105) && // Numeric keypad
            (keyCode !== 110 && keyCode !== 190) // Exclude decimal point
        );

        if (!validInput) {
            e.preventDefault();
        }
    };

    // Function to get initials from a name
    const getInitials = (name) => {
        const nameParts = name.split(' ');
        const initials = nameParts.map((part) => part.charAt(0).toUpperCase()).join('');
        return initials;
    }


    // Update password functionality:
    const [updatePassword, setupdatePassword] = useState(false);
    const changePassword = () => {
        setupdatePassword((prev) => !prev);
    }
    const HandleResetPassword = (e) => {
        e.preventDefault();
        setErrorMessage('');
        openBackdrop();//open backdrop
        // Check for form errors
        if (formErrors.newPassword || formErrors.confirmPassword) {
            // Don't send the API request if there are errors
            closeBackdrop();//close backdrop
            // console.log("Validation errors exist. Cannot reset password.");
            return;
        }

        // Check if new password and confirm password match
        if (passwordChange.newPassword !== passwordChange.confirmPassword) {
            closeBackdrop();//close backdrop
            setErrorMessage("Passwords do not match");
            return;
        }
        if (passwordChange.newPassword === passwordChange.confirmPassword) {
            closeBackdrop();//close backdrop
            if (passwordChange.newPassword.trim() === '') {
                // Display an error message or handle empty new password
                setErrorMessage("New password cannot be empty");
                return;
            }
            privateAxios.post(`/procurement/resetPasswordInProfile/${auth.email}`, {
                newPassword: passwordChange.newPassword,
                confirmPassword: passwordChange.confirmPassword
            })
                .then((res) => {
                    // Handle successful password change
                    closeBackdrop();//close backdrop
                    alert("Password changed successfully.");
                    // Reset the input fields and state variables
                    setpasswordChange({
                        newPassword: "",
                        confirmPassword: ""
                    });
                    setupdatePassword((prev) => !prev);

                })
                .catch((err) => {
                    closeBackdrop();//close backdrop
                    // Handle error
                    console.error("Error changing password:", err);
                    if (err.response.status == 400) {
                        setErrorMessage("Old password cannot be a new password!");
                    } else {
                        setErrorMessage("Something went wrong, please try again later!");
                    }

                });
        } else {
            // Passwords do not match
            closeBackdrop();//close backdrop
            // setErrorMessage("An error occurred while changing the password.");
        }
    }
    const [passwordChange, setpasswordChange] = useState({
        newPassword: "",
        confirmPassword: "",
    })

    const [formErrors, setFormErrors] = useState({
        newPassword: "",
        confirmPassword: "",
    });

    const handleInputField = (e) => {
        setpasswordChange(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            }
        })

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [e.target.name]: '',
        }));
    };

    const handleValidation = (e) => {
        const { name, value } = e.target;
        let errorMessage = '';


        if (name === 'newPassword') {
            if (value.length == 0) {
                errorMessage = 'Please Enter password'
            }
            else if (value.length < 8) {
                errorMessage = 'Password must be at least 8 characters long';
            } else if (!/[!@#$%^&*()_~]/.test(value)) {
                errorMessage += 'Password must include at least one special character. ';
            } else if (!/[A-Z]/.test(value)) {
                errorMessage += 'Password must include at least one capital letter. ';
            } else if (!/\d/.test(value)) {
                errorMessage += 'Password must include at least one number. ';
            } else if (!/[a-z]/.test(value)) {
                errorMessage += 'Password must include at least one small letter. ';
            }
        }
        if (name === 'confirmPassword') {
            if (value.length == 0 && passwordChange.newPassword > 8) {
                errorMessage = 'Please Enter password'
            }
            else if (value !== passwordChange.newPassword) {
                errorMessage = 'Passwords does not match';
            }
        }

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: errorMessage,
        }));
    };



    return (
        <div className={workSpaceStyle.workSpaceMain}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', paddingRight: '20%' }}>
                {errorMessage && <ErrorToast message={errorMessage} />}
            </div>
            <div className={ProfileStyle.profileMainContainer}>
                <div className={ProfileStyle.profileFirstRow}>
                    <div>
                        <h3>My Profile</h3>
                    </div>
                    <div>
                        <Tooltip title='Help'>
                            <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081369-vendor-profile-section">
                                <HelpOutlineOutlined />
                            </a>

                        </Tooltip>
                    </div>
                </div>


                <div className={ProfileStyle.profileMainRow}>
                    <form action='' className={ProfileStyle.ProfileInfoTable}>
                        <div className={ProfileStyle.formInfoColumn}>
                            <div className={ProfileStyle.formGroup}>
                                <label>Name</label>
                                <input type='text' placeholder='Name' value={auth.name} disabled />
                            </div>
                            <div className={ProfileStyle.formGroup}>
                                <label>Email</label>
                                <input type='email' placeholder='Email' value={auth.email} disabled />
                            </div>
                            <div className={ProfileStyle.formGroup}>
                                <label>Role</label>
                                <input type='text' placeholder='Role' value={auth.roles} disabled />
                            </div>
                        </div>
                        <div className={ProfileStyle.formInfoColumn}>
                            <div className={ProfileStyle.formGroup}>
                                <label>Mobile Number</label>
                                <div className={ProfileStyle.mobileInputField}>
                                    {auth.mobNum ?
                                        (
                                            <div><input type='tel' placeholder='Mobile Number' value={auth.mobNum} disabled />
                                                <button type='button' onClick={handleChangeTab}>Change</button> </div>
                                        ) : (<div>    <input type='tel' placeholder='Mobile Number' value="" disabled />
                                            <button type='button' onClick={handleAddButtonClick}>Add</button> </div>)
                                    }
                                </div>
                            </div>
                            <div className={ProfileStyle.formGroup}>
                                <label>Designation</label>
                                <input placeholder='Designation' value="Designation" disabled />
                            </div>
                        </div>
                    </form>




                    <div className={ProfileStyle.ProfilePictureBox}>
                        <figure>
                            {auth.profilePic?.newImage ? (
                                <img
                                    src={`${process.env.REACT_APP_BASEURL}/vendor/file/${auth.profilePic.newImage}`}
                                />
                            ) : (
                                <div className={ProfileStyle.initialsContainer}>
                                    <p className={ProfileStyle.initials}>{getInitials(auth.name)}</p>
                                </div>
                            )}


                            <figcaption><button onClick={handleImageUpload}>Edit Photo</button></figcaption>

                        </figure>
                        <button type='button' onClick={changePassword}>Change Password</button>
                    </div>
                    <BackdropComponent />
                </div>

            </div>

            <>
                {/* Old Mobile Number Modal and Verification */}
                <>
                    <CustomModal
                        isOpen={changeTabOpen}
                        onClose={handleChangeTab}
                        title="Verification required"
                        content={
                            <>
                                <div className={ProfileStyle.changeModalInfo}>
                                    <p>For better security, OTP is sent to a previously used number on your account.</p>
                                </div>
                                <div className={ProfileStyle.changeModalInput}>
                                    <input style={{ color: "black" }} placeholder='+9188888888888' value={auth.mobNum} disabled />
                                    <p>In case you don't have access to this number please <button type='button' onClick={changePhonetoMail}>click here</button></p>
                                </div>
                                <div className={ProfileStyle.changeModalButton}>
                                    <button type='button' onClick={handleOTPOldNumTab}>Request OTP</button>
                                </div>
                            </>
                        }
                    />

                    <CustomModal
                        isOpen={OTPOldNum}
                        onClose={handleOTPOldNumTab}
                        title="Verify With OTP"
                        content={
                            <>
                                <div className={ProfileStyle.changeModalInfo}>
                                    <p>Sent to SMS to {auth.mobNum}</p>
                                </div>
                                <div>
                                    <MuiOtpInput className={ProfileStyle.verifyOTPInput} style={{ width: "100%", gap: "6px" }} length={5} value={phoneOtp} onChange={(e) => setPhoneOtp(e)} onKeyDown={handleKeyPress} />
                                    {mobOTPError ? <span style={{ marginTop: "15px", color: 'red', fontSize: '12px' }}>{mobOTPError}</span> : ''}
                                </div>
                                <div className={ProfileStyle.changeModalButton}>
                                    <button type='button' onClick={submitOTP}>Verify</button>
                                </div>
                            </>
                        }
                    />
                </>


                {/* Mail modal and verification */}
                <>
                    <CustomModal
                        isOpen={changenumToMail}
                        onClose={changePhonetoMail}
                        title="Verification required"
                        content={
                            <>
                                <div className={ProfileStyle.changeModalInfo}>
                                    <p>For better security, OTP is sent to your registered email ID.</p>
                                </div>
                                <div className={ProfileStyle.changeModalInput}>
                                    <input style={{ color: "black" }} placeholder='mailId@gmail.com' value={auth.email} disabled />
                                    {/* <p>In case you don't have access to this email ID please <button type='button'>click here</button></p> */}
                                </div>
                                <div className={ProfileStyle.changeModalButton}>
                                    <button type='button' onClick={handleOTPOldEmailTab}>Request OTP</button>
                                </div>
                            </>
                        }
                    />
                    <CustomModal
                        isOpen={OTPOldMail}
                        onClose={handleOTPOldEmailTabc}
                        title="Verify With OTP"
                        content={
                            <>
                                <div className={ProfileStyle.changeModalInfo}>
                                    <p>Sent to SMS to {auth.email}</p>
                                </div>
                                <div>
                                    <MuiOtpInput className={ProfileStyle.verifyOTPInput} style={{ width: "100%", gap: "6px" }} length={5} value={verifyOtp} onChange={handleChange} onKeyDown={handleKeyPress} />
                                    {mobOTPError ? <span style={{ marginTop: "15px", color: 'red', fontSize: '12px' }}>{mobOTPError}</span> : ''}
                                </div>
                                <div className={ProfileStyle.changeModalButton}>
                                    <button type='button' onClick={submitMailOTP}>Verify</button>
                                </div>
                            </>
                        }
                    />
                </>


                {/* Update new number and verify it */}
                <>
                    <CustomModal
                        isOpen={updateMobileOpen}
                        onClose={submitOTP}
                        title="Update mobile number"
                        content={
                            <>
                                <div className={ProfileStyle.changeModalInput} >

                                    <PhoneInput
                                        style={{ width: '100%', alignItems: 'left' }}
                                        country={'in'}
                                        value={mobileNumber}
                                        onChange={(e) => { setmobileNumber(e) }}
                                        name='MobileNum'

                                        inputStyle={{ width: "100%" }} // Add padding to accommodate the button
                                    />
                                    {mobilenumErr ? <span style={{ marginTop: "15px", color: 'red', fontSize: '12px' }}>{mobilenumErr}</span> : ''}
                                </div>
                                <div className={ProfileStyle.changeModalButton}>
                                    <button type='button' onClick={handleNewNumOTP}>Submit</button>
                                </div>
                            </>
                        }
                    />
                    <CustomModal
                        isOpen={OTPNewNum}
                        onClose={handleNewNumOTP}
                        title="Verify With OTP"
                        content={
                            <>
                                <div className={ProfileStyle.changeModalInfo}>
                                    <p>Sent to SMS to {mobileNumber}</p>
                                </div>
                                <div>
                                    <MuiOtpInput className={ProfileStyle.verifyOTPInput} style={{ width: "100%", gap: "6px" }} length={5} value={verifyOtp} onChange={handleChange} onKeyDown={handleKeyPress} />
                                </div>
                                {mobOTPError ? <span style={{ marginTop: "15px", color: 'red', fontSize: '12px' }}>{mobOTPError}</span> : ''}
                                <div className={ProfileStyle.changeModalButton}>
                                    <button type='button' onClick={handleNumberChange}>Verify</button>
                                </div>
                            </>
                        }
                    />
                </>


                {/* Add number and verify it */}
                <>
                    <CustomModal

                        isOpen={addMobileOpen}
                        onClose={handleAddButtonClick}
                        title="Add mobile number"
                        content={
                            <>
                                <div className={ProfileStyle.changeModalInput} >

                                    <PhoneInput
                                        style={{ width: '100%', alignItems: 'left' }}
                                        country={'in'}
                                        value={mobileNumber}
                                        onChange={(e) => { setmobileNumber(e) }}


                                        inputStyle={{ width: "100%" }}
                                    />
                                    {numberAddErr ? <span style={{ marginTop: "15px", color: 'red', fontSize: '12px' }}>{numberAddErr}</span> : ''}

                                </div>
                                <div className={ProfileStyle.changeModalButton}>
                                    <button type='button' onClick={handleAddNumOTP}>Submit</button>
                                </div>
                            </>
                        }
                    />
                    <CustomModal
                        isOpen={OTPAddNum}
                        onClose={handleAddNumOTPS}
                        title="Verify With OTP"
                        content={
                            <>
                                <div className={ProfileStyle.changeModalInfo}>
                                    <p>Sent to SMS to {mobileNumber}</p>
                                </div>
                                <div>
                                    <MuiOtpInput className={ProfileStyle.verifyOTPInput} style={{ width: "100%", gap: "6px" }} length={5} value={verifyOtp} onChange={handleChange} onKeyDown={handleKeyPress} />
                                </div>
                                {mobOTPError ? <span style={{ marginTop: "15px", color: 'red', fontSize: '12px' }}>{mobOTPError}</span> : ''}
                                <div className={ProfileStyle.changeModalButton}>
                                    <button type='button' onClick={handleNumberAdd}>Verify</button>
                                </div>
                            </>
                        }
                    />
                </>


                {/* Update password Modal: */}
                <>
                    <CustomModal
                        isOpen={updatePassword}
                        onClose={(e) => {
                            e.preventDefault(); // Prevent default behavior
                            changePassword(); // Close the modal
                            setpasswordChange({
                                newPassword: "",
                                confirmPassword: ""
                            });
                            setFormErrors({
                                newPassword: "",
                                confirmPassword: ""
                            });
                        }}
                        title="New Password"
                        content={
                            <>

                                <div className={`${ProfileStyle.changeModalInput} ${passwordChange.newPassword ? ProfileStyle.emptyInput : ''}`}>
                                    <input
                                        name='newPassword'
                                        required
                                        // id="outlined-basic"
                                        variant="outlined"
                                        value={passwordChange.newPassword}
                                        onChange={handleInputField}
                                        onBlur={handleValidation}
                                        type={showPasswordNew ? 'text' : 'password'}
                                        error={isPasswordValid}
                                        ref={inputRefNewPassword}
                                        autocomplete="off"
                                    />


                                    <span className={ProfileStyle.placeholder} onClick={() => inputRefNewPassword.current.focus()}>
                                        {passwordChange.newPassword === '' ? 'New Password' : ''}
                                    </span>
                                    <div className={ProfileStyle.VisibleS}>
                                        {showPasswordNew ? (
                                            <Tooltip title='Hide'><VisibilityOff onClick={() => setShowPasswordNew(false)} /></Tooltip>
                                        ) : (
                                            <Tooltip title='Show'><Visibility onClick={() => setShowPasswordNew(true)} /></Tooltip>
                                        )}
                                    </div>
                                    <span className={ProfileStyle.validationErrors}>{formErrors.newPassword}</span>
                                </div>


                                <div className={`${ProfileStyle.changeModalInput} ${passwordChange.confirmPassword ? ProfileStyle.emptyInput : ''}`}>
                                    <input
                                        name='confirmPassword'
                                        required
                                        // id="outlined-basic"
                                        variant="outlined"
                                        value={passwordChange.confirmPassword}
                                        onChange={handleInputField}
                                        onBlur={handleValidation}
                                        type={showPasswordConfirm ? 'text' : 'password'}
                                        error={isPasswordValid}
                                        ref={inputRefConfirmPassword}
                                        autocomplete="off"
                                    />


                                    <span className={ProfileStyle.placeholder} onClick={() => inputRefConfirmPassword.current.focus()}>
                                        {passwordChange.confirmPassword === '' ? 'Confirm Password' : ''}
                                    </span>
                                    <div className={ProfileStyle.VisibleS}>
                                        {showPasswordConfirm ? (
                                            <Tooltip title='Hide'><VisibilityOff onClick={() => setShowPasswordConfirm(false)} /></Tooltip>
                                        ) : (
                                            <Tooltip title='Show'><Visibility onClick={() => setShowPasswordConfirm(true)} /></Tooltip>
                                        )}
                                    </div>
                                    <span className={ProfileStyle.validationErrors}>{formErrors.confirmPassword}</span>
                                </div>


                                <div className={ProfileStyle.changeModalButton}>
                                    <button type='button' onClick={HandleResetPassword}>Reset Password</button>
                                </div>
                            </>
                        }
                    />
                </>
            </>

        </div >
    )
}

export default VendorProfile;



