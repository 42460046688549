import React, { useEffect, useRef, useState } from 'react'
import myaxios from 'api/axios'
import { Link, useNavigate } from 'react-router-dom'
import VendorRegisterStyle from './VendorRegister.module.css'
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import jwt_decode from 'jwt-decode';
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { passwordEncryptor } from 'utility/passwordEncryptor.js'
import axios from 'api/axios'



const VendorRegister = () => {

  // Ref for email input field
  const emailRef = useRef();

  // Navigation hook
  const navigate = useNavigate();

  // State to manage password visibility
  const [showPassword, setShowPassword] = useState(false);

  // State to store token details
  const [tokenDetails, setTokenDetails] = useState({});

  // State to store decoded token claims
  const [claims, setClaims] = useState({});

  // State to store form data
  const [data, setData] = useState({
    email: "",
    name: "",
    gstNumber: "",
    password: "",
  })
  // State to store blob URL for company logo
  const [blobUrl, setBlobUrl] = useState('');

  // State to manage form validation errors
  const [formErrors, setFormErrors] = useState({
    email: '',
    name: '',
    gstNumber: '',
    password: '',
  });

// Decode token and set token details on component mount
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.size > 0) {
      let token = params.get('token')
      const decodedToken = jwt_decode(token);
      setTokenDetails({
        token: token,
        vendorId: decodedToken.vendorId,
        orgId: decodedToken.orgId,
        mail: decodedToken.email
      });
      setClaims({ email: decodedToken.email, time: decodedToken.vendorId });
      setData(prev => {
        return {
          ...prev,
          email: decodedToken.email
        }
      })
    }
    else {
      navigate("/", { replace: true })
    }
    // Output: The value of the 'token' parameter from the current URL
  }, []);


  // Handle input field changes
  const handleInputField = (e) => {
    const { name, value } = e.target;

     // Regular expression patterns for validation
    const pattern = /^[a-zA-Z\s]*$/; // Allow letters and spaces
    if (name === 'name') {
      if (!pattern.test(value)) {
        // If the input doesn't match the pattern, remove unwanted characters
        const filteredValue = value.replace(/[^a-zA-Z\s]/g, '');
        setData((prev) => {
          return {
            ...prev,
            [name]: filteredValue,
          };
        });
      } else {
        setData((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    }
    else if (name === 'gstNumber') {
      // Define a regular expression pattern for GST format (adjust as needed)
      const gstPattern = /^([0-9]{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1})$/;

      if (!gstPattern.test(value)) {
        // If the input doesn't match the GST pattern, remove unwanted characters
        const filteredValue = value.replace(/[^a-zA-Z0-9]/g, '');
        setData((prev) => {
          return {
            ...prev,
            [name]: filteredValue,
          };
        });
      } else {
        setData((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    }
    else {
      setData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

    // Validate input fields
  const handleValidation = (e) => {
    const { name, value } = e.target;
    let errorMessage = '';

    if (name === 'email') {
      if (value.trim() === '') {
        errorMessage = 'Mail id is required';
      }
      else if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
        errorMessage = 'Invalid Email Id'
      }
    } else if (name === 'name') {
      if (value.trim() === '') {
        errorMessage = 'Name is required';
      } else if (!/^[a-zA-Z\s]*$/.test(value)) {
        errorMessage = 'Name should only contain letters and spaces';
      }

    } else if (name === 'gstNumber') {
      if (value.trim() === '') {
        errorMessage = 'GST / VAT details are required';
      } else if (!/^([0-9]{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1})$/.test(value)) {
        errorMessage = 'Invalid GST format. It should be like XXAAAAA1234A1Z1.';
      }
    } else if (name === 'password') {
      if (value.length < 8) {
        errorMessage = 'Password must be at least 8 characters long';
      }
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  // Handle vendor registration form submission
  const handleVendorRegister = async (e) => {
    e.preventDefault();
    const hasErrors = Object.values(formErrors).some((error) => error !== '');
    const notEmpty = Object.values(data).some((dd) => dd.length < 1);
    const encPass = await passwordEncryptor(data.password, 'AesPassword12345');

    if (!hasErrors && !notEmpty && tokenDetails.token !== "") {
      myaxios.post(`/vendor/registerVendor/${tokenDetails.orgId}/${tokenDetails.token}`, { name: data.name, password: encPass, gstNumber: data.gstNumber, email: data.email })
        .then((res) => {
          if (res.status === 200) {
            navigate(`/redirectvendorlogin?token=${res.data.token}`, { replace: true });
          }

        }).catch(err => {
          console.log(err)
        })
    } else {
      console.log('Form has errors. Cannot submit.');
    }
  };

  // Fetch company logo on component mount
  useEffect(() => {
    axios.get(`/adminuser/logo/${tokenDetails.orgId}`, { responseType: 'blob' })
      .then(response => {
        const url = URL.createObjectURL(response.data);
        setBlobUrl(url);
      })
      .catch(error => {
        console.error('Error fetching image:', error);
      });

    // Cleanup the blob URL when the component unmounts
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [tokenDetails]);

  return (
    <div className={VendorRegisterStyle.mainPage}>
      <div className={VendorRegisterStyle.container}>
        <div className={VendorRegisterStyle.companyLogo}>
          {blobUrl !== '' && <img
            src={blobUrl} alt="" />}</div>
        <div className={VendorRegisterStyle.welcome}>
          <h3>Sign up to continue</h3>
        </div>
        <form action='/' onSubmit={handleVendorRegister} className={formStyle.inputForm}>
          <div className={formStyle.inputRow}>
            <label>Email</label>
            <input
              ref={emailRef}
              type="email"
              disabled
              placeholder="Enter your email"
              autoComplete="off"
              name='email'
              value={data.email}
              onChange={handleInputField}
              onBlur={handleValidation}

            />
            <span>{formErrors.email}</span>

          </div>
          <div className={formStyle.inputRow}>
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter your name"
              autoComplete="off"
              name='name'
              value={data.name}
              onChange={handleInputField}
              onBlur={handleValidation}

            />
            <span>{formErrors.name}</span>
          </div>
          <div className={formStyle.inputRow}>
            <label>GST / VAT Details</label>
            <input
              type="text"
              placeholder="GST / VAT Details"
              autoComplete="off"
              name='gstNumber'
              value={data.gstNumber}
              onChange={handleInputField}
              onBlur={handleValidation}

            />
            <span>{formErrors.gstNumber}</span>
          </div>
          <div className={formStyle.inputRow}>
            <label>Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter Your Password"
              autoComplete="off"
              name='password'
              value={data.password}
              onChange={handleInputField}
              onBlur={handleValidation}
            />
            <div className={VendorRegisterStyle.VisibleVendorPass}>
              {showPassword ? (
                <VisibilityOff onClick={() => setShowPassword(false)} />
              ) : (
                <Visibility onClick={() => setShowPassword(true)} />
              )}
            </div>

            <span>{formErrors.password}</span>
          </div>


          <div className={formStyle.formBottomLine}>
            <p>By continuing you agree to the KJSS <Link to='https://www.kjssteel.com/terms' target='_Blank'>terms of service</Link> and <Link to='https://www.kjssteel.com/privacy' target='_Blank'>privacy policy.</Link></p>
          </div>

          <button type="submit" className={formStyle.formPrimarySubmit}>
            Continue
          </button>

        </form>

      </div>
    </div>
  )
}

export default VendorRegister

// The VendorRegister component facilitates user registration for a vendor account, ensuring data validation,
//  password encryption, dynamic logo fetching, and subsequent redirection to the login page upon successful registration.