import React, { useState, useEffect } from 'react'
import styles from './CompareRankingModal.module.css'
import CloseIcon from '@mui/icons-material/Close';
import useBackdrop from 'hooks/useBackdrop';
import { PrivateComponent } from 'api/axios';
import { Modal } from '@mui/material';
import useAuth from 'hooks/useAuth';
import BarChartIcon from '@mui/icons-material/BarChart';

const CompareRankingModal = ({ open, onClose, data, total, type }) => {
    const { auth } = useAuth();
    const privateAxios = PrivateComponent();
    const { BackdropComponent, openBackdrop, closeBackdrop } = useBackdrop();




    return (

        <>
            <BackdropComponent />
            <Modal
                open={open}
                onClose={onClose}
            >
                <div className={styles.modalContainer}>
                    <div className={styles.mainCont}>


                        <div className={styles.modalHeader} >

                            <h3>
                                <span>
                                    <BarChartIcon />
                                </span>
                                Vendor Ranking
                            </h3>
                            <span onClick={() => { onClose() }}>
                                <CloseIcon />
                            </span>
                        </div>
                        <div className={styles.infoCont}>
                            <p>
                                Total Vendor: <span> {Object.values(data).length}</span>
                            </p>
                            <p>
                                Total {type === "question" ? "Questions" : "Attributes"}: <span> {total}</span>
                            </p>
                        </div>


                        <div className={styles.vendorCardsWrapper}>


                            {
                                Object.values(data).map((vendor, index) => {
                                    const vendorName = Object.keys(data)[index];
                                    return (
                                        <div className={styles.vendorCard}>
                                            <div className={styles.vendorCardName}>
                                                <p>
                                                    {vendorName}
                                                </p>
                                            </div>
                                            <div className={styles.vendorCardRanks}>
                                                <div style={{ width: `${(vendor.satisfied / total) * 100}%`, backgroundColor: "#06d6a0" }}>
                                                    Matched : {vendor.satisfied}
                                                </div>

                                                <div style={
                                                    vendor.unstatisfied > 0 ?
                                                        { width: `${(vendor.unstatisfied / total) * 100}%`, backgroundColor: "#d62828" }
                                                        :
                                                        { width: `100%`, color: "black" }
                                                }>
                                                    Not Matched : {vendor.unstatisfied}
                                                </div>
                                                <div style={
                                                    vendor.neutral > 0 ?
                                                        { width: `${(vendor.neutral / total) * 100}%`, backgroundColor: "#fca311" }
                                                        :
                                                        { width: `100%`, color: "black" }
                                                }>
                                                    Neutral : {vendor.neutral}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>

                    </div>

                </div>
            </Modal>
        </>
    )
}

export default CompareRankingModal
