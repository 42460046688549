import React, { useState } from 'react'
import TaskStyle from '../../../../../EditCreateTask/EditTaskPannel/EditTaskPannel.module.css'
import Box from '@mui/material/Box';
import GroupsIcon from '@mui/icons-material/Groups';
import DateRangeIcon from '@mui/icons-material/DateRange';
import _ from 'lodash';
import useAuth from 'hooks/useAuth';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import ViewSuperadminChat from './ViewSuperadminChat';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  bgcolor: 'background.paper',
  boxShadow: 24,
  border: 'none',

  zIndex: '144'
};

const styleUploadFiles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  border: 'none',
  p: 2,

};

const styleSelectEmails = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  border: 'none',
  p: 2,
};

const ViewSuperadminTask = ({ handleViewtasksModal, data, selectedFiles }) => {

  const { auth } = useAuth();
  const handleFilePreview = (file) => {
    if (file.type.startsWith('image/')) {
      const imageUrl = URL.createObjectURL(file);
      window.open(imageUrl);
    } else {
      alert('File preview not supported for this file type');
    }
    console.log(file)
    const fileURL = URL.createObjectURL(file);
    console.log(fileURL)
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = file.name;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(fileURL);
    document.body.removeChild(a);
  };

  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length <= maxLength) {
      return fileName;
    } else {
      const truncatedName = fileName.substring(0, maxLength - 3) + '...';
      return truncatedName;
    }
  };

  const getFileExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex !== -1) {
      const extension = fileName.substring(lastDotIndex + 1);
      return extension.toUpperCase(); // You can remove .toUpperCase() if you want the extension in lowercase
    } else {
      return ''; // No extension found
    }
  };



  return (
    <>

      <div className={TaskStyle.mainTask} style={{ gap: '0' }} >

        <Box
          sx={style}
          className={TaskStyle.divider}
        >
          <div style={{ display: "flex", flexGrow: "1", gap: "0px", height: "100%", flexDirection: "column", padding: "13px 0px 20px  20px", justifyContent: "space-between" }}>
            <div className={TaskStyle.modalClass}>
              <div className={TaskStyle.taskNav} style={{ gap: '1px' }}>
                <h1 className={TaskStyle.createTask}>View a task</h1>
                <input disabled />
                <h4>{data?.taskId}</h4>
              </div>

              <div className={TaskStyle.taskHeading}>
                <input value={data?.title} />
                <textarea value={data?.description} />
              </div>

              <div className={TaskStyle.container}>

                <ul className={TaskStyle.list}>
                  <li> <DateRangeIcon sx={{ color: '#6A6A6A' }} /> Start Date</li>
                  <li>
                    <input type="date" value={data?.startDate} />
                  </li>
                  <li>End Date <DateRangeIcon sx={{ width: '15px', color: '#6A6A6A' }} /> </li>
                  <li>
                    <input type="date" value={data?.endDate} />
                  </li>
                </ul>
                <ul className={TaskStyle.list}>
                  <li>
                    <DateRangeIcon sx={{ color: '#6A6A6A' }} />
                    E/C date</li>
                  <li>
                    <input disabled type="date" value={data?.userExpectedCompletionDate} />
                  </li>
                </ul>
                <ul className={TaskStyle.list}>
                  <li> <DateRangeIcon sx={{ color: '#6A6A6A' }} /> Status</li>
                  <li style={{ flexGrow: '1' }}>
                    {data?.status}
                  </li>
                </ul>
                <ul className={TaskStyle.list}>
                  <li>
                    <LocalOfferOutlinedIcon sx={{ color: '#6A6A6A' }} />
                    Priority</li>
                  <li style={{
                    flexGrow: '1'
                  }}>
                    <p
                      style={{
                        padding: '5px 15px', borderRadius: '8px', backgroundColor:
                          data.priority === "HIGH"
                            ? "#ec5252"
                            : data.priority === "MEDIUM"
                              ? "#f5ac38"
                              : data.priority === "LOW"
                                ? "#b3f289"
                                : undefined,
                        color:
                          data.priority === "HIGH"
                            ? "#ffff"
                            : data.priority === "MEDIUM"
                              ? "#ffff"
                              : data.priority === "LOW"
                                ? "#ffff"
                                : undefined,
                      }}> {data?.priority}</p>
                  </li>
                </ul>
                <ul className={TaskStyle.list}>
                  <li>
                    <AttachmentOutlinedIcon sx={{ color: '#6A6A6A' }} />
                    Attachment</li>
                  <li style={{ flexGrow: '1' }} className={TaskStyle.AttachmentColumnRr}>
                    
                    {selectedFiles && selectedFiles.length > 0 && (
                      <div className={TaskStyle.selectedFilesList}
                        style={{
                          display: 'flex',
                          gap: '10px',
                          maxHeight: '200px',
                        }}>
                        {selectedFiles?.map((file, index) => {
                          return (
                            <div key={index} className={TaskStyle.selectedFileItem}
                              style={{
                                border: '1px solid #D9D9D9',
                                backgroundColor: '#F6FAFF',
                                padding: '0px 0px',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '10px',
                                alignItems: 'center',
                                minHeight: '35px',
                                minWidth: '165px',
                              }}>
                              <p
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleFilePreview(file)}
                              >
                                {truncateFileName(file?.name, 20)}
                                {getFileExtension(file?.name)}
                                
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </li>
                </ul>

                <ul className={TaskStyle.list}>
                  <li
                    value={data?.asign}
                  > <GroupsIcon sx={{ color: '#6A6A6A' }} /> Assign</li>
                  <li style={{ flexGrow: '1' }}>

                    <div className={TaskStyle.breadcrumbs}>
                      {data?.assign}
                    </div>

                  </li>
                </ul>

              </div>

            </div> 
          </div>

          <div className={TaskStyle.chattComp}>
            <ViewSuperadminChat handleViewtasksModal={handleViewtasksModal}/>
          </div>

        </Box>
      </div>
    
    </>
  )
}

export default ViewSuperadminTask
