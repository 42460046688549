import React, { useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import revisionEditStyle from '../../pages/RevisionEdit/RevisionEdit.module.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Tooltip from '@mui/material/Tooltip';
import DocumentChatPannel from 'components/DocumentChat/DocumentChatPannel';
import DocumentChatRows from 'components/DocumentChat/DocumentChatRows';
import DocumentChatInput from 'components/DocumentChat/DocumentChatInput';
import { Link } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';
import DocumentChatRow from 'components/DocumentChat/DocumentChatRow';
import useAuth from 'hooks/useAuth';
import useBlobDownload from 'hooks/useBlobDownload';
import useBackdrop from 'hooks/useBackdrop';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';
import RenameModal from 'components/RenameModal/RenameModal';
import DocumentUploadModal from 'components/DocumentUploadModal/DocumentUploadModal';



// =====================mui compoents====================
const TempleteDocumentsTab = ({ addDocumentModal, handleAddDocumentModal, getDataProcure, documents, handleDocumentUpload, showToast, setDocuments, revisionId }) => {
  const { auth } = useAuth(); // Get authentication details
  const privateAxios = PrivateComponent(); // Initialize private Axios instance
  const { fileDownload } = useBlobDownload(); // Initialize file download utility
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Initialize backdrop utilities for loading indicators

  const [deleteModal, setDeleteModal] = useState(false); // State to control delete modal visibility
  const [deleteId, setDeleteId] = useState(""); // State to store the ID of the document to be deleted

  const inputRef = useRef(); // Reference for file input
  const [documentName, setDocumentName] = useState(""); // State to store the name of the document
  const [selectedFiles, setSelectedFiles] = useState([]); // State to store selected files
  const [selectedFileNames, setSelectedFileNames] = useState([]); // State to store selected file names
  const [documentMore, setDocumentMore] = useState(""); // State to control more options visibility for a document
  const [renameModal, setRenameModal] = useState(false); // State to control rename modal visibility
  const [renameText, setRenameText] = useState(""); // State to store the new name for renaming
  const [docId, setDocId] = useState(""); // State to store the document ID for renaming
  const [documentChats, setDocumentChats] = useState([]); // State to store document chats
  const [docChatLoading, setDocChatLoading] = useState(true); // State to control loading indicator for document chats
  const [docType, setDocType] = useState(false); // State to store document type


 // Handle file input change
  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files); // Convert file list to array
    setSelectedFiles([...selectedFiles, ...files]); // Update selected files state
    setSelectedFileNames([...selectedFileNames, ...files.map((file) => file.name)]); // Update selected file names state
  };

   // Handle removing a selected file
  const handleRemoveSelectedFile = (index) => {
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);// Remove file from selected files

    const updatedSelectedFileNames = [...selectedFileNames];
    updatedSelectedFileNames.splice(index, 1);// Remove file name from selected file names


    setSelectedFiles(updatedSelectedFiles); // Update selected files state
    setSelectedFileNames(updatedSelectedFileNames); // Update selected file names state
  };

   // Truncate file name for display
  function truncateFileName(fileName, maxLength) {
    if (typeof fileName !== 'string' || fileName.length === 0) {
      return ''; // Return an empty string or handle this case as needed
    }

    if (fileName.length <= maxLength) {
      return fileName;// Return file name if it's within the max length
    }

    const truncatedFileName = fileName.substring(0, maxLength - 5) + '...';// Truncate and add ellipsis
    return truncatedFileName;
  }

  // Toggle delete modal visibility and set delete ID
  const handleDeleteModal = (id) => {
    setDeleteModal(prev => !prev); // Toggle delete modal visibility
    if (id !== null) {
      setDeleteId(id); // Set delete ID
    }
  };

  // Toggle rename modal visibility and set rename text
  const handleRenameModal = (name) => {
    setRenameModal(prev => !prev); // Toggle rename modal visibility
    if (name !== null) {
      setRenameText(name); // Set rename text
    }
  };


   // Handle document validation before uploading
  const handleDocumentValidation = async () => {
    openBackdrop()// Show loading indicator

    if (documentName !== "" && selectedFiles.length > 0 && revisionId !== "0") {
  // Check if document name, selected files, and revision ID are valid
      const res = await handleDocumentUpload(documentName, selectedFiles, docType, revisionId);
      setSelectedFiles([]); // Clear selected files state
      setSelectedFileNames([]); // Clear selected file names state
      setDocumentName(""); // Clear document name state
      handleAddDocumentModal(); // Close add document modal
      closeBackdrop(); // Hide loading indicator
    }
    else {
      closeBackdrop(); // Hide loading indicator

      if (revisionId === null) {
        alert("this is the default Revision please save a copy and then upload documents") // Alert for default revision
      }
      else {
        closeBackdrop();
        alert("all feilds are required check document name and files ") // Alert for missing fields
      }
    }
  }

   // Handle document deletion
  const handleDocDelete = async (docId) => {
    openBackdrop();// Show loading indicator
    try {
      await privateAxios.delete(`/commercial/deleteDocumentOfCommercialVendorRevision/${docId}`)
      await getDataProcure();// Refresh document list
      showToast("success", "Document Deleted Successfully") // Show success toast

    } catch (error) {
      showToast("error", "Error Deleting Document")// Show error toast
      // console.log(error)
    }
    closeBackdrop();// Hide loading indicator
  }


  // Handle document renaming
  const handleRenameDoc = async (data) => {
    openBackdrop();// Show loading indicator
 
    try {
      if (docId) {
        await privateAxios.post(`/commercial/renameDocumentOfCommercialRevision/${docId}?name=${data.name}`)
          handleRenameModal();// Close rename modal
          await getDataProcure() // Refresh document list
       
          showToast("success", "Document Renamed Successfully") // Show success toast
      }
      else {
        showToast("error", "Error Renaming Document")// Show error toast
        handleRenameModal();// Close rename modal
      }

    } catch (error) {
      handleRenameModal();// Close rename modal
      // console.log(error)
    }
    closeBackdrop();// Hide loading indicator
  }


  const [openConfidential, setOpenConfidential] = useState(false)// State to control confidential modal visibility


  // ---------------------document chat states--------------------
  const [documentChatOpen, setDocumentChatOpen] = useState("")// State to control document chat visibilitys
  const handleChatOpen = (data, docId) => {

    if (data === documentChatOpen) {
      setDocumentChatOpen("");// Close document chat if it's already open
      setDocumentChats("");// Clear document chats
    }
    else {
      getDocumentChat(docId); // Fetch document chat
      setDocumentChatOpen(data); // Open document chat
    }
  }

  const [documentChatValue, setDocumentChatValue] = useState("") // State to store new chat message

   // Handle sending a chat message
  const handleDocumentChatSend = async (e, documentId) => {
    e.preventDefault();// Prevent default form submission
    try {
      if (documentChatValue.length > 0) {

        let url = ""
        if (auth.roles === "KAM") {
          url = "/commercial/addRemarkOnCommercialDocumentOfVendorByKam" // URL for KAM role
        }
        else {
          url = "/commercial/addRemarkOnCommercialDocumentByVendor" // URL for vendor role
        }
        await privateAxios.post(`${url}/${auth.userData.id}/${documentId}`, { remark: documentChatValue }).then(res => {
          setDocumentChatValue(""); // Clear chat input
          getDocumentChat(documentId); // Refresh document chat
        }).catch((err) => {
          // console.log(err);
        })
      }
    } catch (error) {
      // console.log(error)
    }

  }


   // Fetch document chat messages
  const getDocumentChat = async (documentId) => {
    handleChatNotification();// Handle chat notification
    try {
      await privateAxios.get(`/commercial/getRemarksOfCommercialVendorDocuments/${documentId}`).then(res => {

        setDocumentChats(res.data); // Set document chats state
        setDocChatLoading(false); // Hide loading indicator
      }).catch((err) => {
        // console.log(err);
        setDocChatLoading(false)// Hide loading indicator
      })
    } catch (error) {
      // console.log(error)
      setDocChatLoading(false)// Hide loading indicator
    }

  }

  // Toggle document more options visibility
  const handleDocumentMore = (index) => {

    if (documentMore === index) {
      setDocumentMore("");
    }
    else {
      setDocumentMore(index)
    }

  }
  // =====================chat notification========================
  const [reRender, setReRender] = useState(false);

  const handleChatNotification = () => {// Close document more options if it's already open
    setReRender(prev => !prev); // Open document more options

  }

  // =====================chat notification========================


  return (
    <>
      <div className={revisionEditStyle.documentsTabMain}>

        <table className={revisionEditStyle.DocumentTable}>
          <thead>
            <tr className={`${revisionEditStyle.DocumentTableRow} ${revisionEditStyle.DocumentTableHead}`}>
              <td>Title</td>
              <td>Date Added</td>
              <td>Remark</td>
              <td>
                <div className={revisionEditStyle.documentIconRow}>

                </div>
              </td>

            </tr>
          </thead>
          <tbody>
            {documents?.map((doc, index) => {
              if (doc.docType !== "CONFIDENTIAL") {



                return (
                  <tr key={index} className={revisionEditStyle.DocumentTableRow}>
                    <td>{doc?.name || doc.url} <span style={{ fontSize: '12px', color: '#585858' }}>Uploded by {doc?.uplodedBy}</span></td>
                    <td>{doc?.createdAt?.split('T')[0] || "Null"}</td>
                    <td style={{ position: "relative" }} >
                      <Tooltip title='Comments'>
                        <span onClick={() => { handleChatOpen(index, doc.id); }}>

                          <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialDocuments`} id={doc.id} reRender={reRender} />
                        </span>
                      </Tooltip>
                      {revisionId === "0" ? <>{documentChatOpen === index && <div className={revisionEditStyle.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                        <DocumentChatRows>
                          {docChatLoading ? <div>Chat Loading ..... </div> :

                            documentChats.length === 0 ? <p>No Previous Chats</p> :
                              documentChats?.map((chat, index) => {
                                return (
                                  <DocumentChatRow key={index}
                                    name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                    email={chat.email}
                                  // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                  />
                                )

                              })}
                        </DocumentChatRows>
                        <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                      </DocumentChatPannel>}
                    </td>
                    <td> <div className={revisionEditStyle.documentIconRow}>

                      <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}><FileDownloadIcon /></Link>

                      {doc.uploadedBy === "VENDOR" && (
                        <Link style={{ position: "relative" }} className={revisionEditStyle.documentMoreDots}
                          onClick={(e) => { e.preventDefault(); handleDocumentMore(index) }} ><MoreVertIcon />

                          {documentMore === index && (
                            <ul style={{ position: "absolute" }}>
                              {revisionId !== "0" && (
                                <li onClick={() => { handleRenameModal(doc?.name || ""); setDocId(doc.id) }}> <span><DriveFileRenameOutlineIcon /></span>Rename</li>
                              )}
                              {revisionId !== "0" && (
                                <li onClick={() => handleDeleteModal(doc.id)}> <span><DeleteOutlineIcon /></span>Delete</li>
                              )}
                            </ul>
                          )}
                        </Link>
                      )}
                    </div>
                    </td>
                  </tr>
                )
              }
            })
            }

          </tbody>
        </table>



        {
          auth.roles !== 'VENDOR' ? (
            <div className={revisionEditStyle.accordianDocumentab}>
              <div className={revisionEditStyle.accordianDocumentabHeader} onClick={() => setOpenConfidential(prev => !prev)}><span>
                {openConfidential ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
              </span>Confidential Documents</div>

              <div className={revisionEditStyle.accordianDocumentabBody}>

                {openConfidential &&
                  <>

                    <table className={revisionEditStyle.DocumentTable}>
                      <tbody>
                        {documents?.map((doc, index) => {
                          if (doc.docType === "CONFIDENTIAL") {
                            return (
                              <tr key={index} className={revisionEditStyle.DocumentTableRow}>
                                <td>{doc.name || doc.url}<span style={{ fontSize: '12px', color: '#585858' }}>Uploded by {doc?.uplodedBy}</span></td>
                                <td>{doc?.createdAt?.split('T')[0] || "Null"}</td>
                                <td style={{ position: "relative" }} >
                                  <Tooltip title='Comments'>
                                    <span onClick={() => { handleChatOpen(index, doc.id); }}>

                                      <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialDocuments`} id={doc.id} reRender={reRender} />
                                    </span>
                                  </Tooltip>
                                  {revisionId === "0" ? <>{documentChatOpen === index && <div className={revisionEditStyle.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                                    <DocumentChatRows>
                                      {docChatLoading ? <div>Chat Loading ..... </div> :

                                        documentChats.length === 0 ? <p>No Previous Chats</p> :
                                          documentChats?.map((chat) => {
                                            return (
                                              <DocumentChatRow key={index}
                                                name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                                email={chat.email}
                                              // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                              />
                                            )



                                          })}
                                    </DocumentChatRows>
                                    <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                                  </DocumentChatPannel>}
                                </td>
                                <td> <div className={revisionEditStyle.documentIconRow}>

                                  {
                                    doc?.uplodedBy == "VENDOR" ? (

                                      <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}><FileDownloadIcon /></Link>
                                    ) : (
                                      <p>This document uploaded by kam.</p>
                                    )
                                  }


                                  <Link style={{ position: "relative" }} className={revisionEditStyle.documentMoreDots} onClick={(e) => { e.preventDefault(); handleDocumentMore(index) }} ><MoreVertIcon />
                                    {documentMore === index && <ul style={{ position: "absolute" }}>
                                      {
                                        revisionId !== "0"

                                        && <li onClick={() => { handleRenameModal(doc?.name || ""); setDocId(doc.id) }}> <span><DriveFileRenameOutlineIcon /></span>Rename</li>
                                      }
                                      {
                                        revisionId !== "0"
                                        // revisionId === "0"
                                        &&
                                        <li onClick={() => handleDeleteModal(doc.id)}> <span><DeleteOutlineIcon /></span>Delete</li>
                                      }
                                      {/* <li> <span><DeleteOutlineIcon /></span>Some Other Option</li> */}
                                    </ul>}
                                  </Link>

                                </div></td>
                              </tr>
                            )
                          }
                        })
                        }

                      </tbody>
                    </table>



                  </>
                }

              </div>
            </div>
          ) :
            <></>
        }

      </div>


      <DocumentUploadModal
        open={addDocumentModal}
        onClose={handleAddDocumentModal}
        onSubmit={handleDocumentUpload}

      />

   


      <RenameModal
        open={renameModal}
        onClose={handleRenameModal}
        onSubmit={handleRenameDoc}
        prefilled={renameText}
        header="Rename"
      />
     
      <DeleteConfirmation
        onCancel={handleDeleteModal}
        onConfirm={() => { handleDocDelete(deleteId); handleDeleteModal() }}
        open={deleteModal}
      // confimBtnText={""} 
      // discardText={""}
      >
        <h2>Delete Document</h2>
        <p>Are you sure you want to delete this Document?</p>

      </DeleteConfirmation>
      <BackdropComponent />
    </>
  )
}

export default TempleteDocumentsTab;
