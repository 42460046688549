import { useState, useEffect, useRef } from 'react';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import { PrivateComponent } from 'api/axios'
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ApprovalStyle from "../Approvals/Approvals.module.css"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Modal from '@mui/material/Modal';
import taskCategoryStyle from '../TaskCategory/TaskCategory.module.css'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import EditTaskPannel from '../EditCreateTask/EditTaskPannel/EditTaskPannel';
import ChatRow from 'components/ChatRows/ChatRow';
import { createExcel } from 'utility/excelUtils'
import VendorApprovastyle from '../Approvals/VendorApproval.module.css';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import useBackdrop from "hooks/useBackdrop";
import Alert from '@mui/material/Alert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import DateFormater from 'utility/DateFormater';
import SendToVendorModal from 'components/SendToVendorModal/SendToVendorModal';
import SendForApprovalModal from 'components/SendForApprovalModal/SendForApprovalModal';
import RenameModal from 'components/RenameModal/RenameModal';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';
import EditTasksCommercial from 'pages/CommercialViewKAM/KamCreateTask/TasksModals/EditTasksCommercial';


const UserApproval = ({ handleEditTaskModal, searchValue, onSearch, setUserApprovalCount, showToast }) => {
    const [revId, setRevId] = useState(""); // State for revision ID
    const { auth } = useAuth(); // Auth context
    const [email, setEmail] = useState([]); // State for email
    const [successAddedVendor, setSuccessAddedVendor] = useState(false); // State for success in adding vendor
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Backdrop related states and functions
    const [selectedEmails, setSelectedEmails] = useState([]); // State for selected emails

    // States and functions for modals
    const [isCommercialReassignModalOpen, setIsCommercialReassignModalOpen] = useState(false);
    const [isTechnicalReassignModalOpen, setIsTechnicalReassignModalOpen] = useState(false);
    const [resignedData, setResignedData] = useState(false);
    const [selectedTaskId, setSelectedTaskId] = useState(null); // State to track selected task ID
    const [taskIds, setTaskIds] = useState([]); // State for task IDs

    // Function to open reassign modal
    const openReassignModal = async (taskIds) => {
        openBackdrop();
        try {
            const response = await privateAxios.get(`/task/getTaskById/${taskIds}`);
            if (response.status === 200) {
                const responseData = response.data;
                setResignedData(responseData);
                if (responseData.type === "Technical Specification") {
                    handleTechnicalReassignModal();
                }
                else {

                    handleCommercialReAssignModal();

                }
            }
            closeBackdrop();
        } catch (error) {
            closeBackdrop();
            console.error(error);
        }
    };

    // Function to toggle technical reassign modal
    const handleTechnicalReassignModal = () => {
        setIsTechnicalReassignModalOpen(prev => !prev);
    };

    // Function to toggle commercial reassign modal
    const handleCommercialReAssignModal = () => {
        setIsCommercialReassignModalOpen(prev => !prev);
    };

    // Function to handle email checkbox change
    const handleEmailCheckboxChange = (email) => {
        if (selectedEmails.includes(email)) {
            setSelectedEmails(selectedEmails.filter((selectedEmail) => selectedEmail !== email));
        } else {
            setSelectedEmails([...selectedEmails, email]);
        }
    };

    // Function to fetch vendors
    const fetchVendors = async () => {
        try {
            const response = await privateAxios.get(`/vendor/getAllVendors/${auth.orgId}`);
            if (response.status === 200) {
                const vendorEmails = response.data.filter((vendor) => !vendor.disable).map((vendor) => vendor.email);
                setEmail(vendorEmails); // Update the 'email' state with the fetched vendor emails
            }
        } catch (error) {
            // console.error('Error fetching vendors:', error);
        }
    };
    useEffect(() => {
        fetchVendors();// Run only once when component mounts
    }, []);


    // State and function for form data
    const [formData, setFormData] = useState({
        emails: [],
        dueDate: "",
        comments: "",
    });

    // Function to handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const todayDate = new Date().toISOString().split('T')[0]; // Get today's date in "yyyy-mm-dd" format


    // ----------------------Modal sendToVendor----------------------------------


    // Function to send revision for approval to vendor
    const sendRevisionForApprovalToVendor = (data) => {
        try {

            privateAxios.post(`/technicalSpecification/sendRevisionForApprovalToVendor/${auth.userData.id}/${revId}`, data)
            showToast("success", "Revision sent to vendor")
            handlesendVendor();
        } catch (error) {
            showToast("error", "Error sending revision to vendor")
        }
    };

    const [sendAdminModal, setSendAdminModal] = useState(false);// State for send admin modal
    // Function to toggle send admin modal
    const handleSendAdminModal = (id) => {
        setSendAdminModal(prev => !prev);
        if (id !== null && id !== undefined && id !== "") {

            setRevId(id)
        }
    }

    // Function to send revision to KAM from admin
    const sendRevisionToKamFromAdmin = async (data) => {
        try {
            const data = await privateAxios.post(`/technicalSpecification/sendRevisionForApprovalToKAMFromSUBKAM/${auth.userData.id}/${revId}`,
                {
                    comments: data.name
                });

            showToast("success", "Revision Sent to Kam");
            handleSendAdminModal();

        } catch (error) {
            console.log(error);
        }
        handlesendVendor();
    }

    useEffect(() => {
        let timeoutId;

        if (successAddedVendor) {
            timeoutId = setTimeout(() => {
                setSuccessAddedVendor(false);
            }, 3000); // 3000 milliseconds (3 seconds)
        }

        return () => {
            clearTimeout(timeoutId); // Clear the timeout if the component unmounts or the alert is hidden before 3 seconds
        };
    }, [successAddedVendor]);



    const handleSendButtonClick = (e) => {
        e.preventDefault();

        const data = {
            emails: emails,
            comments: formData.comments,
            dueDate: formData.dueDate,
        };
        sendRevisionForApprovalToVendor(data);
    };


    // Function to navigate
    const navigate = useNavigate();
    const handleNavigate = (link) => {
        navigate(link)
    }

    // State for container style
    const [containerStyle, setContainerStyle] = useState({
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: '#fff',
        transition: 'background-color 0.3s', // Adding transition for hover effect
        // Add other CSS properties here as needed
    });

    const handleMouseEnter = () => {
        // Change the background color on hover
        setContainerStyle(prevStyle => ({
            ...prevStyle,
            backgroundColor: '#fff',
        }));
    };

    const handleMouseLeave = () => {
        // Revert back to the original background color when not hovering
        setContainerStyle(prevStyle => ({
            ...prevStyle,
            backgroundColor: '#fff',
        }));
    };


    // States and functions related to axios and other functionalities
    const privateAxios = PrivateComponent();
    const [data, setData] = useState([])
    const [emails, setEmails] = useState([]);
    const [isStartDateDropdownOpen, setIsStartDateDropdownOpen] = useState(false);
    const [sendVendor, setSendVendor] = useState(false);

    // Function to handle sending vendor
    const handlesendVendor = (id) => {
        setSendVendor(prev => !prev);
        setRevId(id)
    }

    // State and functions for modals, dropdowns, etc.
    const [openMassege, setOpenMassege] = useState(false);
    const [renameModalOpen, setRenameModalOpen] = useState(false);
    const [renameText, setRenameText] = useState('');


    const handleMassege = () => {
        setOpenMassege(prev => !prev);
    }

    // State and functions for multi-click and more dropdown
    const [multiClick, setMultiClick] = useState(false);
    const handleMultiClick = () => {
        setMultiClick(prev => !prev)
    }
    const [multiClick2, setMultiClick2] = useState(false);
    const handleMultiClick2 = () => {
        setMultiClick2(prev => !prev)
    }

    const [moreDropdown, setMoreDropdown] = useState(null);


    // Function to toggle more dropdown
    const toggleMoreDrop = (index) => {

        if (moreDropdown === index) {
            setMoreDropdown(null);
        } else {
            setMoreDropdown(index);
        }
    };

    // Function to toggle start date dropdown
    const toggleStartDateDropdown = () => {
        setIsStartDateDropdownOpen(!isStartDateDropdownOpen);
    };


    // Function to handle start date sort
    const handleStartDateSort = (sortType) => {
        let sortedItems = [...data];

        if (sortType === 'Newest') {
            sortedItems.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        } else if (sortType === 'Oldest') {
            sortedItems.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        }

        setData(sortedItems);

        // Close the dropdown
        setIsStartDateDropdownOpen(false);
    };

    // -------------------------------testing-----------------------

    // State for popup open/close
    const [isPopupOpen, setPopupOpen] = useState(false);

    // Function to open the popup
    const openPopup = () => {
        setPopupOpen(true);
    };

    // Function to close the popup
    const closePopup = () => {
        setPopupOpen(false);
    };

    // State and function for revisions data
    const [revisionsData, setRevisionsData] = useState([]);

    // Function to fetch revisions data
    const fetchRevisionsData = async () => {
        openBackdrop();
        try {
            const response = await privateAxios.get(`/technicalSpecification/getAllRevisionsForApprovalOfUser/${auth.userData.id}`);
            if (response.status === 200) {
                const groupedObject = {};
                const taskIds = [];
                response.data.forEach(obj => {
                    // Store taskIds in the array
                    taskIds.push(obj.taskIds);// Store taskIds in the array

                    for (const key in obj) {
                        const value = obj[key];

                        if (key in groupedObject) {
                            if (Array.isArray(groupedObject[key]) && !groupedObject[key].includes(value)) {
                                groupedObject[key].push(value);
                            } else if (!Array.isArray(groupedObject[key]) && groupedObject[key] !== value) {
                                groupedObject[key] = [groupedObject[key], value];
                            }
                        } else {
                            groupedObject[key] = [value];
                        }
                    }
                });

                setSorterData(groupedObject); // Set sorter data
                setRevisionsData(response.data); // Set revisions data
                setUserApprovalCount(response.data.length); // Set user approval count
                setTaskIds(taskIds); // Set the taskIds in the state
            } else {
                console.error('Error fetching revisions data');
            }
            closeBackdrop();
        } catch (error) {
            closeBackdrop();
            console.error('Error fetching revisions data:', error);
        }
    };

    // Call the fetchRevisionsData function when the component mounts
    useEffect(() => {
        fetchRevisionsData();
    }, []); // Empty dependency array to run it only once when the component mounts


    // ==========================table sorter======================

    // State and functions for table sorting
    const [sorterData, setSorterData] = useState(); // State for sorter data
    const [sortedData, setSortedData] = useState([]); // State for sorted data
    const [sorterOpen, setSorterOpen] = useState(""); // State for sorter open

    // Function to toggle table sorter
    const handleTableSorterToggle = (sorter) => {

        if (sorterOpen === sorter) {
            setSorterOpen("");
        }
        else {
            setSorterOpen(sorter);
        }
    }

    // Update sorted data when revisions data changes
    useEffect(() => {
        setSortedData(revisionsData);
    }, [revisionsData]);

    // Function to handle sorting
    const handleSorting = (value, type) => {
        switch (type) {
            case "product": setSortedData(revisionsData.filter((dd) => dd.productName === value));

                break;
            case "type": setSortedData(revisionsData.filter((dd) => dd.type === value));

                break;
            case "revisionStatus": setSortedData(revisionsData.filter((dd) => dd.revisionStatus === value));

                break;
            // case "category": categorySorting(value);

            //     break;
            case "submittedOn": dateSorting("submittedOn", value);
                break;
            case "sentBy": setSortedData(revisionsData.filter((dd) => dd.sentBy === value));
                break;
            case "lastModified": dateSorting("lastModified", value);
                break;
            default: clearSorting();
        }

        setSorterOpen("");


    }

    // Function to clear sorting
    const clearSorting = () => {
        setSortedData(revisionsData);
        setSorterOpen("");
    }

    // Function for date sorting
    const dateSorting = (type, value) => {
        // console.log(value);
        let sortedData = []
        if (type === "submittedOn") {
            if (value === "asc") {
                sortedData = [...revisionsData].sort((a, b) => {
                    return new Date(a.submittedOn) - new Date(b.submittedOn);
                });
            }
            else {
                sortedData = [...revisionsData].sort((a, b) => {
                    return new Date(b.submittedOn) - new Date(a.submittedOn);
                });
            }

        }
        else {
            if (value === "asc") {
                sortedData = [...revisionsData].sort((a, b) => {
                    return new Date(a.lastModified) - new Date(b.lastModified);
                });
            }
            else {
                sortedData = [...revisionsData].sort((a, b) => {
                    return new Date(b.lastModified) - new Date(a.lastModified);
                });
            }

        }

        setSortedData(sortedData);
    }



    // ==========================table sorter======================



    // Function to format date string to a readable format

    const formatDateString = (dateString) => {
        const dateObject = new Date(dateString);
        // Use JavaScript's Date methods to format the date as needed
        const formattedDate = dateObject.toLocaleDateString(); // This will give you the date in "MM/DD/YYYY" or "DD/MM/YYYY" format
        return formattedDate;
    }


    // Function to calculate time elapsed since last login
    const handleLastSeen = (loginTime) => {
        // Calculate time difference
        const loginDate = new Date(loginTime);
        const currentDate = new Date();
        const timeDifference = currentDate - loginDate;

        // Calculate hours, minutes, and days difference
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
        const daysDifference = Math.floor(hoursDifference / 24);

        // Generate display string based on time elapsed
        let displayString;

        if (daysDifference > 0) {
            // If the difference is more than 1 day, display the date
            displayString = `${loginDate.toDateString()}`;
        } else if (hoursDifference > 0) {
            // If the difference is more than 1 hour, display the hours
            displayString = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else if (minutesDifference > 0) {
            // If the difference is more than 1 minute, display the minutes
            displayString = `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
        } else {
            // If the login was within the last minute, display "Just now"
            displayString = "Just now";
        }

        return displayString;
    }

    // ----------------------------Revison Rename----------------------------------
    // Function to rename the revision
    const [revData, setRevData] = useState({});// State for revision data
    const handleRenameModal = (data) => {
        setRenameModalOpen(prev => !prev); // Toggle rename modal
        console.log(data)
        if (data !== null && data !== undefined && data !== "") {
            setRenameText(data.name);
            setRevData(data)
        }
    }

    // Function to save renamed revision
    const handleRenameSave = async (data) => {
        // Open backdrop for loading indication
        openBackdrop()
        if (revData?.id !== "") {
            if (revData.type.toLowerCase() === "technical specification") {
                // Depending on the type of revision, send a request to rename it

                await privateAxios.post(`/technicalSpecification/renameRevision/${revData?.id}`, { name: data.name })
                    .then((response) => {
                        if (response.status === 200) {
                            // console.log('Revision renamed successfully');
                            showToast("success", "Revision renamed successfully")
                            fetchRevisionsData();
                            handleRenameModal();
                        }

                    })
                    .catch((error) => {
                        showToast("error", "Error renaming revision")
                        handleRenameModal();
                    });
            }
            else {
                await privateAxios
                    .post(`/commercial/renameCommercialRevision/${revData.id}?name=${data.name}`)
                    .then((response) => {
                        if (response.status === 200) {
                            // If successful, fetch updated revision data and close the modal
                            fetchRevisionsData();
                            handleRenameModal();
                        }

                    })
                    .catch((error) => {
                        // If there's an error, close the modal and show an error message
                        showToast("error", "Error renaming revision")
                        handleRenameModal();
                    });
            }

            closeBackdrop()
        } else {
            showToast("error", "Error renaming revision Rev Id not found")
            handleRenameModal();
        }
        closeBackdrop();

    };


    //   --------------------------Revison Rename----------------------------------

    // Function to delete a revision
    const deleteRevision = (revId) => {
        openBackdrop();
        privateAxios
            .delete(`/technicalSpecification/deleteRevision/${revId}`)
            .then((response) => {
                if (response.status === 200) {

                    showToast("success", "Revision deleted successfully")
                }

            })
            .catch((error) => {

                showToast("error", "Error deleting revision")
            });

        closeBackdrop();
    };



    // Create a state variable to store the duplicated revision name



    const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);


    const handleDuplicateNameModal = (data) => {
        setDuplicateModalOpen(prev => !prev);
        if (data !== null && data !== undefined && data !== "") {

            setRenameText(data.name);
            setRevData(data)
        }

    }

    // Function to save duplicated revision

    const handleDuplicateSave = async (data) => {

        if (revData.id !== "") {
            // Send request to duplicate the revision

            await privateAxios.post(`/technicalSpecification/duplicateRevision/${revId}?name=${data.name}`)
                .then((response) => {
                    if (response.status === 200) {
                        // If successful, fetch updated revision data and close the modal
                        fetchRevisionsData(); // Fetch updated revision data
                        handleDuplicateNameModal(); // Close the duplication modal
                    } else {
                        console.error('Error duplicating revision');
                    }
                })
                .catch((error) => {
                    console.error('Error duplicating revision:', error);
                });
        } else {
            console.error('Invalid revId or duplicateValue');
        }
    };
    // --------------------------------Chatt-----------------------------------------


    // State and functions for handling chat
    const [chatSlider, setChatSlider] = useState(false)
    const [chatValue, setChatValue] = useState("");
    const [revisionChats, setRevisionChats] = useState([]);


    // Function to toggle chat slider
    const handleChatSlider = (revData, revName) => {
        setChatSlider(prev => !prev)
        setRevData(revData);
    }

    // Fetch revision chats when chat slider is open
    useEffect(() => {
        if (chatSlider === true && revData !== "") {

            getRevisionChats(revData.id, revData.type);
        }

    }, [chatSlider])

    
    const handleChatSend = async (e, revId) => {
        e.preventDefault()

        try {
            let url = ""
            switch (revData.type.toLowerCase()) {
                case "commercial": url = `/commercial/addRemarkOncommercialRevision`;
                    break;
                case "technical specification": url = `/technicalSpecification/addRemarkOnRevisionByEmployee`;
                    break;
                case "procure": url = ``;
                    break;
                case "approval": url = ``;
                    break;
                default: return;
            }
            if (chatValue.length > 0) {

                await privateAxios.post(`${url}/${auth.userData.id}/${revData.id}`, { remark: chatValue }).then(res => {
                    // Refresh chat after sending message
                    getRevisionChats(revId);
                    setChatValue("")

                })
            }
        } catch (error) {
            // console.log(error)
        }

    }

// Function to fetch revision chats
    const getRevisionChats = async (revId, type) => {

        try {


            let url = ""
            switch (revData.type.toLowerCase()) {
                case "commercial": url = `/commercial/getRemarksOfcommercialRevision`;
                    break;
                case "technical specification": url = `/technicalSpecification/getRemarksOfRevision`;
                    break;
                case "procure": url = ``;
                    break;
                case "approval": url = ``;
                    break;
                default: return;
            }

            await privateAxios.get(`${url}/${revId}`).then(res => {
                handleChatNotification();
                setRevisionChats(res.data)
            })
        } catch (error) {
            // console.log(error)
            showToast("error", "Error getting chats")

        }
    }
    // --------------------------------Chatt-----------------------------------------

    const [documents, setDocuments] = useState([])
    const [tableRows, setTableRows] = useState([]);

    const handleDownloadRevision = () => {

        // extracting text from Richeditor string
        let tempElement = document.createElement('div');
        tempElement.innerHTML = specificationValue;
        let extractedText = tempElement.textContent;
        extractedText = extractedText.trim();

        // taking nessarry feilds from attributes
        const Attributes = tableRows.map((attri) => {
            const modifiedAttri = { ...attri };
            delete modifiedAttri.id;

            const remarks = {};
            attri.remark.forEach((rem, index) => {
                remarks[`Remark ${index}`] = rem.value;
            });
            delete modifiedAttri.remark;
            return { ...modifiedAttri, ...remarks };

        });
        // console.log(tableRows)
        // console.log(Attributes)
        // documents
        let revisionDocument = []
        if (revId === "0") {

            revisionDocument = documents.map((doc) => {
                return {
                    name: "Not Available",
                    link: doc.url,
                    type: "normal"

                }
            })

        }
        else {
            revisionDocument = documents.map((doc) => {
                return {
                    name: "Not Available",
                    link: doc.url,
                    type: "normal"

                }
            })
        }

        createExcel([[{ Specification: extractedText }], Attributes, revisionDocument],
            ("Revision 0")
        )
    }


    const [specificationValue, setSpecificationValue] = useState('');
    const handelSpecification = () => {
        // console.log(specificationValue)
    }


    // Function to fetch revisions by search criteria
    const fetchVendorRevisionsBySearch = async () => {
        try {
            const response = await privateAxios.post(
                '/technicalSpecification/searchVendorRevisionsForApproval',
                {
                    search: searchValue, // Pass the search query from your state
                    userId: auth.userData.id, // Pass the user ID or any other required data
                }
            );

            if (response.status === 200) {
                // console.log("Revisions fetched successfully based on search criteria:", response.data);
                setRevisionsData(response.data); // Update the state with the fetched data
            } else {
                console.error('Error fetching revisions based on search criteria');
            }
        } catch (error) {
            console.error('Error fetching revisions based on search criteria:', error);
        }
    };

    // Use the fetchRevisionsBySearch function when the searchValue changes
    useEffect(() => {
        if (searchValue !== "") {

            fetchVendorRevisionsBySearch();
        }
    }, [searchValue]);

    function getBackgroundColor(revisionStatus) {
        switch (revisionStatus) {
            case 'Pending':
                return 'red';
            case 'Approve':
                return 'yellow';
            case 'Reject':
                return 'green';
            default:
                return 'white'; // You can set a default color if needed
        }
    }


    const [deleteTaskId, setDeleteTaskId] = useState(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const handleDeleteModal = (taskId) => {
        setDeleteTaskId(taskId);
        setDeleteModal(prev => !prev);

    }

    const handleStatusString = (status) => {

        let statusStyle = {}
        switch (status) {
            case "CREATED": statusStyle = { backgroundColor: "#FFF0C9", color: "#EDBD15" };
                break;
            case "PENDING": statusStyle = { backgroundColor: "#FFF0C9", color: "#EDBD15" };
                break;
            case "REJECTED": statusStyle = { backgroundColor: "#FFD7D7", color: "#EC5252" };
                break;
            case "APPROVED": statusStyle = { backgroundColor: "#DCFCE7", color: "#1CA35E" };
                break;

        }





        return (
            <span className={taskCategoryStyle.StatusPill} style={statusStyle}>{status?.charAt(0).toUpperCase() + status?.slice(1).toLowerCase()}</span>
        )
    }


    const handleRevisionNavigate = (type, row) => {
        switch (type.toLowerCase()) {

            case "technical specification": navigate(`user/${row.taskIds}/${row.productId}/${row.id}`, { state: { from: "APVIEW" } });
                break;
            case "commercial": navigate(`user/commercial/${row.taskIds}/${row.productId}/${row.id}`, { state: { from: "APVIEW" } });
                break;
        }

    }
    // =====================chat notification========================
    const [reRender, setReRender] = useState(false);

    const handleChatNotification = () => {// Trigger chat notification
        setReRender(prev => !prev); // Set revision chats in state

    }

    // =====================chat notification========================

    return (
        <>
            <BackdropComponent />

            <div className={VendorApprovastyle.approvalVendorMain}>

                <table className={VendorApprovastyle.approvalVendorTable}>
                    <thead>
                        <tr className={`${VendorApprovastyle.tableHead}`} style={{ zIndex: '2' }}>
                            <td className={`${VendorApprovastyle.tableColumnSticky}`} style={{ minWidth: "200px" }}>
                                <div className={VendorApprovastyle.tdRow}>
                                    {/* <input type="checkbox" onChange={()=>handleSelectAllToggle()} checked={checkBoxAll} /> */}
                                    <p>Name</p>
                                </div>

                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("product")}>Products
                                        <span>{sorterOpen === "product" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "product" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("product")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.productName?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "product")} >{ss === null ? "Group" : ss}</li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("type")}>File Type
                                        <span>{sorterOpen === "type" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "type" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("type")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.type?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "type")} >{ss !== null && ss}</li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            {/* <th>Product</th> */}
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("sentBy")}>Sent By
                                        <span>{sorterOpen === "sentBy" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "sentBy" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("sentBy")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.sentBy?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "sentBy")} >{ss !== null && ss}</li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </>
                                    }

                                </div>

                            </td>

                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("submittedOn")}>Submited On
                                        <span>{sorterOpen === "submittedOn" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "submittedOn" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("submittedOn")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                <li onClick={() => handleSorting("desc", "submittedOn")} >Latest</li>
                                                <li onClick={() => handleSorting("asc", "submittedOn")} >Oldest</li>

                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>

                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("revisionStatus")}>Status
                                        <span>{sorterOpen === "revisionStatus" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "revisionStatus" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("revisionStatus")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.revisionStatus?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "revisionStatus")} >{ss !== null && ss}</li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td> <div className={workSpaceStyle.tableSorterDiv} >
                                <p onClick={() => handleTableSorterToggle("lastModified")}>last Modified
                                    <span>{sorterOpen === "lastModified" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                </p>
                                {
                                    sorterOpen === "lastModified" &&
                                    <>
                                        <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("lastModified")}></div>
                                        <ul className={workSpaceStyle.tableSorterDropDown}>
                                            <li onClick={() => clearSorting()} >All</li>
                                            <li onClick={() => handleSorting("desc", "lastModified")} >Latest</li>
                                            <li onClick={() => handleSorting("asc", "lastModified")} >Oldest</li>

                                        </ul>
                                    </>
                                }

                            </div></td>
                            <td>Actions</td>

                        </tr>
                    </thead>
                    <tbody >

                        {

                            sortedData.filter((item) =>
                                item?.name?.trim().toLowerCase().includes(searchValue.toLowerCase()) || item?.productName?.trim().toLowerCase().includes(searchValue.toLowerCase())).map((item, index) => {
                                    // console.log("Item data:", item);
                                    return (



                                        <tr key={index} className={`${VendorApprovastyle.tableRow}`}>

                                            <td className={`${VendorApprovastyle.tableColumnSticky2} `}>
                                                <div className={VendorApprovastyle.tdRow}>
                                                    <p>{item.name}</p>
                                                </div>
                                            </td>

                                            <td>{item.productName}
                                            </td>
                                            <td>{item.type}
                                            </td>

                                            <td>
                                                <div className={ApprovalStyle.PeofileSendBy}>
                                                    <ProfileIcon data={{ email: item.source }} height={"26px"} width={"26px"} showProfileBanner={true} /> {item.sentBy}
                                                </div>
                                            </td>


                                            <td>{DateFormater(item.submittedOn)}</td>
                                            <td>

                                                <div className={ApprovalStyle.getProduct}>
                                                    <span style={{ backgroundColor: getBackgroundColor(item.revisionStatus) }}>{handleStatusString(item.revisionStatus)}</span>
                                                </div>

                                            </td>
                                            <td>{handleLastSeen(item.lastModified)}</td>
                                            <td className={ApprovalStyle.AllIcons}>
                                                <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-start' }}>
                                                    <Tooltip title='Send to vendor'>
                                                        <PersonAddAltIcon onClick={() =>
                                                            auth.roles === "KAM"

                                                                ? handlesendVendor(item.id) : handleSendAdminModal(item.id)} />
                                                    </Tooltip>

                                                    <div >
                                                        <p
                                                            onClick={() => handleRevisionNavigate(item.type, item)}
                                                        // to={`user/${item.taskIds}/${item.productId}/${item.id}?tab=0`}
                                                        >
                                                            <RemoveRedEyeIcon />
                                                        </p>
                                                        {isPopupOpen && (
                                                            <div className={ApprovalStyle.popup} onClick={closePopup}>
                                                                gg
                                                            </div>
                                                        )}
                                                    </div>
                                                    <Tooltip title='Chat'>
                                                        <span onClick={() => handleChatSlider({ id: item.id, type: item.type, name: item.name })} >

                                                            {item?.type?.toLowerCase() === 'technical specification'
                                                                ?
                                                                <ChatIconWithBadge url={`/technicalSpecification/getNotficationCount`} id={item.id} reRender={reRender} />
                                                                :
                                                                <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialRevision`} id={item.id} reRender={reRender} />


                                                            }
                                                        </span>
                                                        {/* <Badge className={ProductRevisonsStyle.BadgeCSS} badgeContent={4} color="primary">
                                                            <SmsOutlinedIcon  />
                                                        </Badge> */}
                                                    </Tooltip>
                                                    <td>
                                                        <div className={ApprovalStyle.actionTd} style={{ position: "relative" }}>
                                                            <span onClick={() => {
                                                                toggleMoreDrop(index);
                                                            } // Close the modal after clicking
                                                            } >
                                                                <Tooltip title='More'><MoreVertIcon /></Tooltip>
                                                            </span>
                                                            {moreDropdown === index && (
                                                                <ThreeDotDropDown toggle={() => toggleMoreDrop(index)}>

                                                                    <li

                                                                        onClick={() => {
                                                                            openReassignModal(item.taskIds);
                                                                            toggleMoreDrop(index); // Close the modal after clicking
                                                                        }} // Pass the task ID when opening the modal
                                                                        style={{ display: 'flex', alignItems: 'center', fontSize: '14px', gap: '15px' }}>
                                                                        <span><PersonOutlinedIcon /> </span>Re-assign
                                                                    </li>



                                                                    {auth.roles === "KAM" &&
                                                                        <li onClick={() => {
                                                                            handlesendVendor(item.id);
                                                                            toggleMoreDrop(index);
                                                                        }
                                                                        } style={{ display: 'flex', alignItems: 'center', fontSize: '14px', gap: '15px' }}><span ><PersonAddAltOutlinedIcon /> </span>Send to vendor</li>
                                                                    }
                                                                    {auth.roles === "ADMIN" &&
                                                                        <li onClick={() => {
                                                                            handlesendVendor(item.id);
                                                                            toggleMoreDrop(index);
                                                                        }} style={{ display: 'flex', alignItems: 'center', fontSize: '14px', gap: '15px' }}><span ><PersonAddAltOutlinedIcon /> </span>Send to Kam</li>
                                                                    }
                                                                    <li onClick={() => {
                                                                        handleRenameModal({ id: item.id, type: item.type, name: item.name });
                                                                        toggleMoreDrop(index);
                                                                    }} style={{ display: 'flex', alignItems: 'center', fontSize: '14px', gap: '15px' }}><span  ><DriveFileRenameOutlineIcon /> </span>Rename</li>

                                                                    <li
                                                                        onClick={() => {
                                                                            handleDuplicateNameModal({ id: item.id, type: item.type, name: item.name });
                                                                            toggleMoreDrop(index);
                                                                        }}
                                                                        style={{ display: 'flex', alignItems: 'center', fontSize: '14px', gap: '15px' }}><span ><ContentCopyIcon /> </span>Duplicate</li>

                                                                    <li style={{ display: 'flex', alignItems: 'center', fontSize: '14px', gap: '15px' }}


                                                                        onClick={() => {
                                                                            handleDeleteModal(item.id);
                                                                            toggleMoreDrop(index);
                                                                        }}
                                                                    ><span ><DeleteOutlineIcon /> </span>Delete</li>

                                                                    {/* <li
                                                                        onClick={() => {
                                                                            handleDownloadRevision();
                                                                            toggleMoreDrop(index);
                                                                        }}
                                                                        style={{ display: 'flex', alignItems: 'center', fontSize: '14px', gap: '15px' }}><span ><FileDownloadOutlinedIcon /> </span>Download</li> */}
                                                                </ThreeDotDropDown>
                                                            )}


                                                        </div>
                                                    </td>
                                                </div>
                                            </td>
                                        </tr>
                                    )

                                }


                                )
                        }

                    </tbody>




                </table>













            </div>


            <Modal
                open={isCommercialReassignModalOpen}
                onClose={handleCommercialReAssignModal}
            >
                <EditTasksCommercial isOpen={isCommercialReassignModalOpen} handleEditTaskModal={handleCommercialReAssignModal} editData={resignedData} taskId={selectedTaskId} />
                {/* <EditTaskPannel isOpen={isReassignModalOpen} handleEditTaskModal={handleReAssignModal} editData={resignedData} taskId={selectedTaskId} /> */}
            </Modal>
            <Modal
                open={isTechnicalReassignModalOpen}
                onClose={handleTechnicalReassignModal}
            >

                <EditTaskPannel isOpen={isTechnicalReassignModalOpen} handleEditTaskModal={handleTechnicalReassignModal} editData={resignedData} taskId={selectedTaskId} />
            </Modal>


            {successAddedVendor && (
                <div style={{ display: 'flex', minWidth: "max-content", left: "50%", justifyContent: 'center', alignItems: 'center', position: "fixed", bottom: "100px" }}>
                    <Alert severity="success"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%'
                        }}>Revision Sent Successfully</Alert>
                </div>
            )}



            <ChatPannel
                open={chatSlider}
                onClose={handleChatSlider}
            >
                <>
                    <ChatHeader
                        onClose={handleChatSlider}
                        downloadData={revisionChats.map((chat) => {
                            return { name: chat.name, chat: chat.remark, date: chat.createdAt, };
                        })}
                        helpData={{ link: "tosomewhere" }}
                        chatName={revData.name}
                    >
                        <h3>{revData?.name}</h3>
                    </ChatHeader>

                    <ChatRows >
                        {/* <ChatRow name="Ravinder" chat="soemfhdf a saf sa" date="12-04-2023" index="1"/> */}
                        {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
                            return (

                                <ChatRow name={rev.name} email={rev.email} chat={rev.remark} date={rev.createdAt} index={index} />
                            )
                        })
                            :
                            <p style={{ textAlign: "center" }}>
                                No Previous Chat
                            </p>
                        }
                    </ChatRows>

                    <ChatInputField revisionId={revData?.id} onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

                </>
            </ChatPannel>



            <SendToVendorModal
                open={sendVendor}
                onClose={handlesendVendor}
                header={"Send to Vendor"}
                onSubmit={sendRevisionForApprovalToVendor}
            />

            <SendForApprovalModal
                open={sendAdminModal}
                onClose={handleSendAdminModal}
                onSubmit={sendRevisionToKamFromAdmin}
                prefilled={""}
                header="Send For Approval"
            />


            <RenameModal
                open={renameModalOpen}
                onClose={handleRenameModal}
                onSubmit={handleRenameSave}
                prefilled={revData?.name}
                header="Rename"
            />
            {/* <RenameModal
                open={saveAsModal}
                onClose={handleSaveAsModal}
                onSubmit={handleSaveAsRevison}
                prefilled={productDetails?.name}
                header="Save As"
            />
            */}
            <RenameModal
                open={duplicateModalOpen}
                onClose={handleDuplicateNameModal}
                onSubmit={handleDuplicateSave}
                prefilled={revData?.name}
                header="Duplicate"
            />




            <DeleteConfirmation
                onCancel={handleDeleteModal}
                onConfirm={() => { deleteRevision(deleteTaskId); handleDeleteModal() }}
                open={deleteModal}
            // confimBtnText={""} 
            // discardText={""}
            >
                <h2>Delete Revision</h2>
                <p>Are you sure you want to delete this Revision?</p>

            </DeleteConfirmation>


            <BackdropComponent />
        </>
    )
}

export default UserApproval



