
import * as React from 'react';
import { useState, useEffect } from 'react';
import TaskStyle from "../../../TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import Button from '@mui/material/Button';
import categoryData from 'api/categories.json'
import { useNavigate } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { PrivateComponent } from 'api/axios'
import axios from 'axios';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import useAuth from 'hooks/useAuth';

// Import necessary dependencies and components
const UsersTaskskDisplay = ({ data, setData, getAllUSers, updatedData, search, handleEditTaskModal, setEditData, loadMore, taskPage, setTaskPage }) => {
  // Initialize task data
  const taskData = categoryData;
  // Extract authentication details
  const { auth } = useAuth()
  // Create a private axios instance for making API requests
  const privateAxios = PrivateComponent();
  // Initialize state for sorted data
  const [sortedData, setSortedData] = useState([]);

  // Initialize navigate function for navigation
  const navigate = useNavigate();

  // State for controlling the delete confirmation modal
  const [deleteKamModal, setDeleteKamModal] = useState(false);

  // State for tracking selected option ID
  const [selectedOptionId, setSelectedOptionId] = useState(null);

  // State for controlling priority selector visibility
  const [prioritySelectorOpen, setPrioritySelectorOpen] = useState('');

  // State for controlling status selector visibility
  const [statusSelectorOpen, setStatusSelectorOpen] = useState('');

  // State for handling multiple clicks (multiClick1 to multiClick4)
  const [multiClick, setMultiClick] = useState(false);
  const handleMultiClick = () => {
    setMultiClick(prev => !prev)
  }
  const [multiClick2, setMultiClick2] = useState(false);
  const handleMultiClick2 = () => {
    setMultiClick2(prev => !prev)
  }
  const [multiClick3, setMultiClick3] = useState(false);
  const handleMultiClick3 = () => {
    setMultiClick3(prev => !prev)
  }
  const [multiClick4, setMultiClick4] = useState(false);
  const handleMultiClick4 = () => {
    setMultiClick4(prev => !prev)
  }
  // -----------------------------------------------------------------------
  
   // Use effect to update sorted data when updatedData changes
  useEffect(() => {
    if (updatedData) {
      setSortedData(updatedData);

    }
  }, [updatedData])
  // ----------------------------------------

// State for controlling email selection modal visibility
  const [emailModalOpen, setEmailModalOpen] = useState(false);

    // Functions to open and close the email selection modal
  const handleEmailModalOpen = () => {
    setEmailModalOpen(true);
  };

  const handleEmailModalClose = () => {
    setEmailModalOpen(false);
  };


  // -------------------------------------------------------------

 // Function to handle call to action, navigates to task details page
  const handleCTA = async (productId, taskId) => {
    navigate(`${taskId}`)
  }


  //------------------------------------------------------------------------------------

    // Function to handle option selection
  const handleChoose = (selectedId) => {
    setSelectedOptionId(selectedId);
  };

    // Function to toggle the delete confirmation modal
  const handleDeleteModal = () => {
    setDeleteKamModal(prev => !prev);
  }

// State and function for handling option selection
  const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

    // Function to toggle priority selector visibility
  const handlePrioritySelector = (index) => {
    if (prioritySelectorOpen === index) {
      setPrioritySelectorOpen('')
    } else {
      setPrioritySelectorOpen(index)
    }
  }


// Function to handle priority change
  const handlePriorityChange = (id, value) => {
    setData(prev => {
      return prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            label: value
          }
        }
        return item
      })
    });
  };

  // Function to toggle status selector visibility
  const handleStatusSelector = (index) => {
    if (statusSelectorOpen === index) {
      setStatusSelectorOpen('')
    } else {
      setStatusSelectorOpen(index)
    }
  }

    // Function to handle status change
  const handleStatusChange = async (taskId, oldValue, value) => {
    try {
      let param = "ALL"
      switch (value.toLowerCase()) {
        case "to do": param = "TODO";
          break;
        case "in progress": param = "INPROGRESS";
          break;
        case "done": param = "DONE";
          break;
        case "completed": param = "COMPLETED";
          break;
        default: param = "ALL"
      }
      await privateAxios.post(`/task/updateTaskStatus/${taskId}?status=${param}`).then(res => {
        const payloadToSend = [{
          name: auth.name,
          logDetails: `${auth.name} updated the status`,
          previousLogs: oldValue,
          newLogs: res.data.status,
        }]
        handlePostTaskHistory(payloadToSend, taskId);
        window.location.reload();
        getAllUSers();
      })
    } catch (error) {
      // console.log(error)
    }
  };

   // Function to post task history
  const handlePostTaskHistory = async (payloadToSend, taskId) => {
    try {
      await privateAxios.post(`/task/getTaskHistory/${taskId}`, payloadToSend).then((res) => {
      })
    } catch (error) {
      console.log(error)
    }
  }
  // -------------------------------------------------New Sortting----------------------------------------------------------------------

 // State for controlling start date sort dropdown visibility
  const [isStartDateDropdownOpen, setIsStartDateDropdownOpen] = useState(false);

   // Function to toggle start date sort dropdown
  const toggleStartDateDropdown = () => {
    setIsStartDateDropdownOpen(!isStartDateDropdownOpen);
  };

   // Function to handle start date sorting
  const handleStartDateSort = (sortType) => {
    let sortedItems = [...updatedData];

    if (sortType === 'Newest') {
      sortedItems.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    } else if (sortType === 'Oldest') {
      sortedItems.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    }

    setSortedData(sortedItems);
    setIsStartDateDropdownOpen(false);
  };
  // ---------------------------------------------------------------------------
  
  
  // State for controlling end date sort dropdown visibility
  const [isEndDateDropdownOpen, setIsEndDateDropdownOpen] = useState(false);

   // Function to toggle end date sort dropdown
  const toggleEndDateDropdown = () => {
    setIsEndDateDropdownOpen(!isEndDateDropdownOpen);
  };

   // Function to handle end date sorting
  const handleEndDateSort = (sortType) => {
    let sortedItems2 = [...updatedData];

    if (sortType === 'Newest') {
      sortedItems2.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
    } else if (sortType === 'Oldest') {
      sortedItems2.sort((a, b) => new Date(a.endDate) - new Date(b.endDate));
    }

    setSortedData(sortedItems2);

    setIsEndDateDropdownOpen(false);
  };


  //------------------------------------------------------------------

  return (
    <div className={TaskStyle.table1}>

      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr className={TaskStyle.theader}>
            <th>Task ID</th>
            <th style={{ textAlign: "left" }}>Title</th>
            <th>
              <div className={TaskStyle.SortingDate}>
                <p onClick={toggleStartDateDropdown} >
                  Start Date
                  <span className=''>
                    <ImportExportIcon />
                  </span>
                </p>
                {isStartDateDropdownOpen &&
                  <ul >
                    <li onClick={() => handleStartDateSort('Newest')}>Newest</li>
                    <li onClick={() => handleStartDateSort('Oldest')}>Oldest </li>
                  </ul>
                }
              </div>
            </th>
            {/* <th> End Date</th> */}
            <th >
              <div className={TaskStyle.SortingDate}>
                <p onClick={toggleEndDateDropdown} >
                  <span
                    style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '15px' }}>
                    End Date<ImportExportIcon />
                  </span>
                </p>
                {isEndDateDropdownOpen &&
                  <ul >
                    <li onClick={() => handleEndDateSort('Newest')}>Newest</li>
                    <li onClick={() => handleEndDateSort('Oldest')}>Oldest </li>
                  </ul>
                }
              </div>
            </th>
            <th>E/C Date</th>
            <th>Status</th>
            <th>Assigned To</th>
            <th>Label</th>
            <th>Action</th>
          </tr>
        </thead>
      </table>

      <table style={{ borderCollapse: 'collapse' }}>
        <tbody >
          {
            sortedData.filter((item) =>
              item?.title?.trim().toLowerCase().includes(search?.toLowerCase())
            ).map((item, index) =>
              <tr key={item.taskId} className={TaskStyle.tbody}>
                <td>{index + 1}</td>
                <td onClick={() => { setEditData(item); handleEditTaskModal(); }}>{item.title}</td>
                <td style={{ backgroundColor: '#d2d2d2' }}>{item.startDate}</td>
                <td style={{ backgroundColor: '#d2d2d2' }}>{item.endDate}</td>
                <td>{item.completedDate}</td>

                <td className={TaskStyle.SelectBar} style={{ backgroundColor: '#d2d2d2' }}>

                  <div onClick={() => handleStatusSelector(index)} className={TaskStyle.statusSelector}>
                    <p onClick={handleMultiClick2} style={{ color: '#000' }}

                    >{item.status.substring(0, 1).toUpperCase() + item.status.substring(1, item.status.length).toLowerCase()}
                      <span>
                        {statusSelectorOpen === index
                          ? <KeyboardArrowUpIcon />
                          : <KeyboardArrowDownIcon />
                        }

                      </span>
                    </p>
                    {statusSelectorOpen === index &&
                      <ul>
                        <li onClick={() => handleStatusChange(item.taskId, item.status, "In Progress")}>In Progress</li>
                        <li onClick={() => handleStatusChange(item.taskId, item.status, "Done")}>Done</li>
                      </ul>
                    }
                  </div>


                </td>

                <td >
                  <div className={TaskStyle.addAssignMain} style={{ width: '100%', justifyContent: 'center' }}>
                    <div className={TaskStyle.addAssign}>

                      {item.assignTo.slice(0, 2).map((iit) => {

                        const colorArray = ["#0077b6", "#f77f00", "#a7c957"]
                        const randomIndex = Math.floor(Math.random() * colorArray.length);

                        const randomColor = colorArray[randomIndex]
                        if (iit?.disabled === false) {
                          return (

                            <div className={TaskStyle.name1} style={{ backgroundColor: randomColor }}>
                              {/* {iit?.email?.charAt(0).substring(0, 1).toUpperCase()}  */}
                              <ProfileIcon data={{ email: iit?.email }} height={"26px"} width={"26px"} showProfileBanner={true} />
                            </div>
                          )
                        }

                      })}

                    </div>
                  </div>
                </td>

                <td className={TaskStyle.SelectBar}>


                  <div onClick={() => handlePrioritySelector(index)} className={TaskStyle.prioritySelector}>
                    <p onClick={handleMultiClick}
                      style={
                        {
                          backgroundColor: item.priority === 'HIGH' ? '#ec5252' : item.priority === 'MEDIUM' ? '#f5ac38' : item.priority === 'LOW' ? '#b3f289' : '#fff',
                          color: item.priority === 'High' ? '#fff' : item.priority === 'Medium' ? '#fff' : item.priority === 'Low' ? '#fff' : '#fff',
                          height: "27px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center"

                        }
                      }
                    >{item.priority.substring(0, 1).toUpperCase() + item.priority.substring(1, item.priority.length).toLowerCase()}

                    </p>

                  </div>

                </td>


                <td className={TaskStyle.taskDisplayBtn} >
                  <Button variant="contained" onClick={() => { handleCTA(item.taskProductId, item.taskId); }}>CTA</Button>
                </td>

              </tr>
            )
          }
        </tbody>
      </table>


    </div>
  );
}
export default UsersTaskskDisplay
