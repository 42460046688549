import React from 'react'
import underConstructStyle from './UnderConstruction.module.css'
import underConstructionImg from '../../assets/images/underConstruction.png'

const UnderConstruction = () => {
  return (
    <div className={underConstructStyle.underMainDiv}>
      <img src={underConstructionImg} alt="" />
    </div>
  )
}

export default UnderConstruction
