import { PrivateComponent } from 'api/axios'


const useBlobDownload = () => {
    const privateAxios = PrivateComponent();
    const fileDownload=async(url,name)=>{
        try {
          const urlE=url.split(".");
          const ext=urlE[urlE.length-1];
          const docName=`${name||"default"}.${ext}`
          await privateAxios.get(`${url}`, { responseType: 'blob' }).then((response)=>{
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = docName; // Change 'yourFileName.png' to the desired file name.
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
        } catch (error) {
          // console.log(error);
        }
      }
  return {fileDownload}
}

export default useBlobDownload
