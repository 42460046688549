// Import necessary hooks from React-Redux
import { useSelector, useDispatch } from 'react-redux';

// Import action creators from AuthState
import { setLogin, setLogout, setUserData, setOrgLogo, setMobNum, setProfilePic } from '../state/AuthState';

// Custom hook for authentication state and actions
const useAuth = () => {
    // Use useSelector hook to access specific pieces of state from Redux store
    const auth = {
        name: useSelector((state) => state.authData.name), // User's name
        companyName: useSelector((state) => state.authData.companyName), // User's company name
        email: useSelector((state) => state.authData.email),// User's email
        token: useSelector((state) => state.authData.token),  // Authentication token
        userData: useSelector((state) => state.authData.userData), // Additional user data
        roles: useSelector((state) => state.authData.roles), // User's roles
        orgId: useSelector((state) => state.authData.orgId), // Organization ID
        workSpaces: useSelector((state) => state.authData.workSpaces),// User's workspaces
        allData: useSelector((state) => state.authData.allData),// All user data
        orgLogo: useSelector((state) => state.authData.orgLogo),// Organization logo
        mobNum: useSelector((state) => state.authData.mobNum), // User's mobile number
        profilePic: useSelector((state) => state.authData.profilePic),// User's profile picture
    }
    // Return the auth object and action creators
    return { setLogin, setLogout, auth, setUserData, setOrgLogo, setMobNum, setProfilePic }
}

export default useAuth;

