import React from "react";


const VendorCompanyProfile = () => {
    return (
        <>
        Hey its my company!
        </>
    )
}

export default VendorCompanyProfile;