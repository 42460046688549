import React, { useEffect, useState } from 'react';
import TempleteStyle from './CommercialTempleteView.module.css';
import { Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { useNavigate } from 'react-router-dom';
import useBackdrop from 'hooks/useBackdrop'; // Importing custom hook for managing backdrop
import useToast from 'hooks/useToast'; // Importing custom hook for showing toast messages
import { PrivateComponent } from 'api/axios'; // Importing custom axios instance
import useAuth from 'hooks/useAuth'; // Importing custom hook for authentication
import BackButton from 'components/BackButton/BackButton'; // Importing custom BackButton component


function CommercialTempleteViewKam() {

  const {auth}=useAuth(); // Destructuring auth from useAuth hook
  const navigate = useNavigate(); // Getting navigation function from react-router-dom
  const [data, setData] = useState([]); // Initializing state for data

  const {openBackdrop,closeBackdrop,BackdropComponent}=useBackdrop(); // Destructuring functions and component from useBackdrop hook
  const {showToast,Toast}=useToast(); // Destructuring functions and component from useToast hook
  const privateAxios=PrivateComponent(); // Getting privateAxios function from custom axios instance
  const [searchTerm, setSearchTerm] = useState(''); // Initializing state for search term

  const handleSearchChange = (e) => { // Function to handle search input change
    const searchValue = e.target.value;
    setSearchTerm(searchValue);
  }

  const handleBackButton = () => { // Function to handle back button click
    navigate(-1); // Navigating back
  }

  // ====================get Data====================
  
   // Function to fetch data
  const getData = async() => { // Opening backdrop
    openBackdrop();
    try {
      const res=await privateAxios.get(`/procureadmin/getAllSpecificTemplates`) // Sending GET request to fetch templates data

      setData(res.data); // Setting fetched data to state
    } catch (error) {
        showToast("error","Something went wrong!"); // Showing toast message in case of error
    }
    closeBackdrop(); // Closing backdrop
  }
  
  useEffect(() => { // Fetching data on component mounts
    getData();
  }, []);
  // ====================get Data====================



  return (
    <>
   
    <BackdropComponent/>
    <Toast/>
    <div className={workSpaceStyle.workSpaceMain}>
      <div className={workSpaceStyle.workSpaceHeader}>
        <div className={workSpaceStyle.workSpaceHeaderLeft}>
          <h1>
          <BackButton/>
            template Categories
          </h1>
          <p>
            Available
            <span>
              {data.length}
            </span>
          </p>
        </div>
        <div className={workSpaceStyle.workSpaceHeaderSearchbar}>
          <input
            type="text"
            onChange={handleSearchChange} 
            value={searchTerm}
            placeholder="Search Here..."
          />
          <Tooltip title="help">
            <HelpOutlineIcon />
          </Tooltip>
        </div>
      </div>
      <div className={TempleteStyle.templetetableMain}> 
        <table className={TempleteStyle.templetetable} >
          <thead>
            <tr className={TempleteStyle.templetetableRow}  >
              <th>Type</th>
              <th>-</th>
            </tr>
          </thead>
          <tbody>
            {data.filter(item => item.name.trim().toLowerCase().includes(searchTerm.toLocaleLowerCase())).map((item, index) => (
              <tr key={index} className={TempleteStyle.templetetableRow} onClick={() => navigate(`${item.id}`)}>

                <td>
                  {item.name}
                </td>
                <td>
                  <ArrowForwardIosIcon />
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

    </>
  );
}

export default CommercialTempleteViewKam;
