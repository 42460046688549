import React from 'react'
import procaImgStyle from '../ProcaImages/ProcaImages.module.css'

const ImgData = [
    {
        id: 1,
        title: 'Vendor Management',
        para: 'Streamline all supplier management with a single site, fostering safe and transparent procurement processes.',
        paraImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698663911/Grid_1_jwj9bx.png',
    },
    {
        id: 2,
        title: 'Editable techno-commerical templates',
        para: 'Dynamic streamlining with editable techno-commercial, enhancing agility and efficiency',
        paraImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698663911/Ts_Attributessd_1_klgets.png',
    },
    {
        id: 3,
        title: 'Multi functional dashboard',
        para: 'All-in-one  dashboard for seamless operations, optimising efficiency.',
        paraImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698663911/Group_427320610_rxnjta.png',
    },
    {
        id: 4,
        title: 'RFx Comparison',
        para: 'Simplified approvals through efficient workflows within  portal, enhancing process control.',
        paraImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698663910/Frame_427320633_1_no5mub.png',
    },
    {
        id: 5,
        title: 'Approval Workflows',
        para: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.',
        paraImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1698663911/grid_etip8l.png',
    },
]


const ProcaImages = () => {
    return (
        <div className={procaImgStyle.ImagesStartDiv}>
            <div className={procaImgStyle.ImagesMain}>
                
                            <div className={procaImgStyle.imgDiv}>
                                <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
                                <h1>Vendor Management</h1>
                                <p>Streamline all supplier management with a single site, fostering safe and transparent procurement processes.</p>
                                </div>
                                <div className={procaImgStyle.featureCardBottom}>

                                <img src="https://res.cloudinary.com/dtffniutw/image/upload/v1698663911/Grid_1_jwj9bx.png" alt="" />
                                </div>
                            </div>
                            <div className={procaImgStyle.imgDiv}>
                                <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
                                <h1>Editable techno-commerical templates</h1>
                                <p>Dynamic streamlining with editable techno-commercial, enhancing agility and efficiency</p>
                                </div>
                                <div className={procaImgStyle.featureCardBottom} >

                                <img src="https://res.cloudinary.com/dtffniutw/image/upload/v1698663911/Ts_Attributessd_1_klgets.png" alt="" />
                                </div>
                            </div>
                            <div className={procaImgStyle.imgDiv}>
                                <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
                                <h1>Multi functional dashboard</h1>
                                <p>All-in-one  dashboard for seamless operations, optimising efficiency.</p>
                                </div>
                                <div className={procaImgStyle.featureCardBottom} >

                                <img src="https://res.cloudinary.com/dtffniutw/image/upload/v1698663911/Group_427320610_rxnjta.png" alt="" style={{width:"100%"}}/>
                                </div>
                            </div>
                            <div className={procaImgStyle.imgDiv}>
                                <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
                                <h1>RFx <br />Comparison</h1>
                                <p>Easily access and analyse quotes from various vendors without any manual effort</p>
                                </div>
                                <div className={procaImgStyle.featureCardBottom}  style={{justifyContent:"flex-start"}}>

                                <img src="https://res.cloudinary.com/dtffniutw/image/upload/v1698663910/Frame_427320633_1_no5mub.png" alt=""  />
                                </div>
                            </div>
                            <div className={procaImgStyle.imgDiv}>
                                <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
                                <h1>Approval Workflows</h1>
                                <p>Simplified approvals through efficient workflows within  portal, enhancing process control.</p>
                                </div>
                                <div className={procaImgStyle.featureCardBottom}  style={{justifyContent:"flex-end"}}>

                                <img src="https://res.cloudinary.com/dtffniutw/image/upload/v1698663911/grid_etip8l.png" alt="" style={{width:"auto",height:"100%"}} />
                                </div>
                            </div>
                       

            </div>
        </div>
    )
}

export default ProcaImages
