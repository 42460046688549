import React from 'react'
import { Button, Typography } from '@mui/material'
import SubsStyle from './SubscriptionExpire.module.css'
import gif from 'assets/images/gif.gif'
import useAuth from 'hooks/useAuth';

function SubscriptionExpire({ isOpen, onClose, remainingDays }) {

  const { auth } = useAuth();
  return isOpen ? (
    <>
      <div className={SubsStyle.whiteBackDrop} onClick={onClose} >
      </div>
      {auth.roles === "KAM" &&
        <div
          className={SubsStyle.MainSubClass}>
          <img src={gif} alt="" />

          <Typography variant="h1">
            Your trial ends in {remainingDays}  days
          </Typography>
          <p>
            Ready to take the next step? We’ve got plans for teams of all shapes and sizes.
          </p>
          <div className={SubsStyle.ButtonSection}

          >
            <Button variant="contained" sx={{ backgroundColor: '#16469d' }}>See plans</Button>
            <Button variant="outlined" onClick={onClose} sx={{ border: 'none', color: '#000000' }}>Maybe later</Button>
          </div>

          <div>
          </div>
        </div>
      }
    </>
  ) : null
}

export default SubscriptionExpire;
