import React, { useRef, useState } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import revisionEditStyle from './RevisionEdit.module.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import useBlobDownload from 'hooks/useBlobDownload';


const DocumentsTab = ({ addDocumentModal, handleAddDocumentModal,getDataProcure, documents, handleDocumentUpload, setDocuments, revisionId }) => {

 // Importing necessary hooks and functions
 const { auth } = useAuth(); // Authentication hook
 const privateAxios = PrivateComponent(); // Axios instance for private requests
 const inputRef = useRef(); // Ref for file input
 const { fileDownload } = useBlobDownload(); // Blob download hook

   // State variables initialization
  const [documentName, setDocumentName] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([])
  const [documentMore, setDocumentMore] = useState("");
  const [renameModal, setRenameModal] = useState(false)
  const [renameText, setRenameText] = useState("");
  const [docId, setDocId] = useState("");
  const [documentChats,setDocumentChats]=useState([])
  const[docChatLoading,setDocChatLoading]=useState(true);
  const [docType,setDocType]=useState(false);

     // Function to handle file input change
  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    // setFiles(files); 
    setSelectedFiles([...selectedFiles, ...files]);
    setSelectedFileNames([...selectedFileNames, ...files.map((file) => file.name)]); // Update selected file names
  };

    // Function to remove selected file
  const handleRemoveSelectedFile = (index) => {
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);

    const updatedSelectedFileNames = [...selectedFileNames];
    updatedSelectedFileNames.splice(index, 1);

    setSelectedFiles(updatedSelectedFiles);
    setSelectedFileNames(updatedSelectedFileNames);
  };


  // Function to truncate file name if it's too long
  function truncateFileName(fileName, maxLength) {
    if (typeof fileName !== 'string' || fileName.length === 0) {
      return ''; // Return an empty string or handle this case as needed
    }

    if (fileName.length <= maxLength) {
      return fileName;
    }

    const truncatedFileName = fileName.substring(0, maxLength - 5) + '...';
    return truncatedFileName;
  }


    // Function to toggle rename modal
  const handleRenameModal = () => {
    setRenameModal(prev => !prev)
  }

   // Function to handle document validation and upload
  const handleDocumentValidation = async () => {
    if (documentName !== "" && selectedFiles.length > 0 && revisionId !== "0") {

      const res = await handleDocumentUpload(documentName, selectedFiles,docType, revisionId);
      setSelectedFiles([]);
      setSelectedFileNames([]);
      setDocumentName("");
      handleAddDocumentModal()

    }
    else {
      if (revisionId === null) {
        alert("this is the default Revision please save a copy and then upload documents")
      }
      else {

        alert("all feilds are required check document name and files ")
      }
    }
  }


    // Function to handle document deletion
  const handleDocDelete = async (docId) => {
    try {
      await privateAxios.delete(`/technicalSpecification/deleteDocumentOfRevision/${docId}`).then(res => {
        // console.log(res.data);
        getDataProcure();
      }).catch(err => {
        // console.log(err)
      })
    } catch (error) {

      // console.log(error)
    }
  }

  // Function to handle document renaming
  const handleRenameDoc = async (e) => {
    e.preventDefault();
    try {
      // console.log(docId)
      if (docId) {
        await privateAxios.post(`/technicalSpecification/renameDocument/${docId}?name=${renameText}`).then(res => {
          // console.log(res.data);
          handleRenameModal();
          getDataProcure()
          setRenameText("");
        }).catch(err => {
          // console.log(err)
          handleRenameModal();
        })
      }
      else {
        alert("Document ID Not Present")
        handleRenameModal();
      }

    } catch (error) {
      handleRenameModal();
      // console.log(error)
    }
  }

     // State variable for confidential document
  const [openConfidential, setOpenConfidential] = useState(false)


 // Function to handle opening/closing document chat
  const [documentChatOpen, setDocumentChatOpen] = useState("")
  const handleChatOpen = (data,docId) => {

    if (data === documentChatOpen) {
      setDocumentChatOpen("")
      setDocumentChats("");
    }
    else {
      getDocumentChat(docId)
      setDocumentChatOpen(data);
    }
  }

   // State variables for document chat
  const [documentChatValue, setDocumentChatValue] = useState("")

    // Function to handle sending document chat
  const handleDocumentChatSend = async (e, documentId) => {
    e.preventDefault();
    try {
      if(documentChatValue.length>0)
      {

        await privateAxios.post(`/technicalSpecification/addRemarkOnDocumentByEmployee/${auth.userData.id}/${documentId}`, { remark: documentChatValue }).then(res => {
          // console.log(res.data);
          setDocumentChatValue("");
          // console.log(documentChatValue)
          getDocumentChat(documentId);
        }).catch((err)=>{
          // console.log(err);
        })
      }
    } catch (error) {
      // console.log(error)
    }

  }

  const getDocumentChat = async (documentId) => {
    try {
      await privateAxios.get(`/technicalSpecification/getRemarksOfDocument/${documentId}`).then(res => {
       
        setDocumentChats(res.data);
        setDocChatLoading(false)
      }).catch((err)=>{
        // console.log(err);
        setDocChatLoading(false)
      })
    } catch (error) {
      // console.log(error)
      setDocChatLoading(false)
    }

  }

 // Function to handle showing/hiding more options for documents
  const handleDocumentMore = (index) => {

    if (documentMore === index) {
      setDocumentMore("");
    }
    else {
      setDocumentMore(index)
    }

  }



  // ---------------------------------------

  return (
    <>
      <div className={revisionEditStyle.documentsTabMain}>

        <table className={revisionEditStyle.DocumentTable}>
          <thead>
            <tr className={`${revisionEditStyle.DocumentTableRow} ${revisionEditStyle.DocumentTableHead}`}>
              <td>Title</td>
              <td>Date Added</td>
            
              <td>
                <div className={revisionEditStyle.documentIconRow}>

                  <a><FileDownloadIcon /></a>


                  

                </div>
              </td>

            </tr>
          </thead>
          <tbody>
            {documents?.map((doc, index) => {
              if(doc.docType!=="CONFIDENTIAL")
              {

              
              return (
                <tr className={revisionEditStyle.DocumentTableRow}>
                  <td>{doc.name || doc.url}</td>
                  <td>{doc?.date?.split('T')[0] || "Null"}</td>
                
                  <td> <div className={revisionEditStyle.documentIconRow}>

                  <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url,doc.name) }}><FileDownloadIcon /></Link>



                   

                  </div></td>
                </tr>
              )
            }
            })
            }

          </tbody>
        </table>

    


        <div className={revisionEditStyle.accordianDocumentab}>
          <div className={revisionEditStyle.accordianDocumentabHeader} onClick={() => setOpenConfidential(prev => !prev)}><span>
            {openConfidential ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </span>Confidential Documents</div>

          <div className={revisionEditStyle.accordianDocumentabBody}>

            {openConfidential &&
              <table className={revisionEditStyle.DocumentTable}>
                <tbody>
                  {documents?.map((doc, index) => {
                     if(doc.docType==="CONFIDENTIAL")
                     {
                    return (
                      <tr className={revisionEditStyle.DocumentTableRow}>
                        <td>{doc.name || doc.url}</td>
                        <td>{doc?.date?.split('T')[0] || "Null"}</td>
                        {/* <td><Tooltip title='Comments'><ChatIcon /></Tooltip></td> */}
                        <td> <div className={revisionEditStyle.documentIconRow}>

                        <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url,doc.name) }}><FileDownloadIcon /></Link>



                  

                  </div></td>
                      </tr>
                    )
                     }
                  })
                  
                  }

                </tbody>
              </table>

            }
          </div>


        </div>


      </div>
      <Modal
        open={addDocumentModal}
        onClose={handleAddDocumentModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action=""
            // onSubmit={}
            style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}></span>Add Document</h3>
              <div onClick={handleAddDocumentModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <label>Name</label>
              <input type="text" value={documentName} onChange={(e) => setDocumentName(e.target.value)} placeholder='Document Name' />
              {/* <span>{formErrors.accessRole}</span>  */}
              {/* <span>{formError.workSpace}</span> */}
            </div>
            <div className={formStyle.inputRow}>
              <label>Upload File</label>
              <div style={{ height: "100px", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#f6f6f6", borderRadius: "10px", border: "1px dotted grey" }}>

                {/* <Button variant="contained" >
                  <input
                    multiple // Allow multiple file selection
                    ref={inputRef}
                    type='file'
                    hidden
                    // onChange={(e) => {
                    //     setFiles(e.target.files)
                    // }}
                    onChange={handleFileInputChange}
                  /> */}
                <input type="file" multiple
                  ref={inputRef}
                  onChange={handleFileInputChange}
                  hidden placeholder='Document Name' />
                <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", width: "100%", overflow: "auto", padding: "10px 10px" }}>

                  {!selectedFileNames.length > 0 && <p style={{ fontSize: "14px", }}>Upload A file</p>}
                  <ul style={{ width: "100%", display: "flex", flexDirection: "column", }}>
                    {selectedFileNames.map((fileName, index) => (

                      <li key={index}
                        //  className={TaskStyle.selectedFileItem}
                        style={{
                          display: 'flex',
                          justifyContent: "space-between", alignItems: 'center',
                          border: "1px solid grey",
                          padding: "0 10px"
                        }}>
                        <p style={{ fontSize: "14px" }}>

                          {truncateFileName(fileName, 15)}
                        </p>
                        <span onClick={() => handleRemoveSelectedFile(index)} style={{ textDecoration: 'none', border: 'none', display: "flex", justifyContent: "center", alignItems: "center" }}
                        ><CloseIcon sx={{ textDecoration: "none", border: 'none' }} /></span>
                      </li>

                    ))}
                  </ul>


                  <p style={{ fontSize: "14px", color: "blue" }} onClick={() => inputRef.current.click()}>Upload</p>
                </div>
              </div>
              {/* <span>{formErrors.accessRole}</span>  */}
              {/* <span>{formError.workSpace}</span> */}
            </div>
            <div className={formStyle.inputRowCheckBox}>
              <input type="checkbox" checked={docType} onChange={(e) => setDocType(e.target.checked)} />
              <label>Confidential Document</label>
              {/* <span>{formErrors.accessRole}</span>  */}
              {/* <span>{formError.workSpace}</span> */}
            </div>


            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='button' onClick={handleDocumentValidation}>Add </button>
              {/* <p onClick={() => { setDocumentFile("");setDocumentName(""); }} style={{ cursor: "pointer" }}>Discard</p> */}
            </div>

            {/*<span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span> */}
          </form>

        </div>
      </Modal>


      <Modal
        open={renameModal}
        onClose={handleRenameModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={handleRenameDoc} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><DriveFileRenameOutlineIcon /></span>Rename</h3>
              <div onClick={handleRenameModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>

              <input type="text" value={renameText} onChange={(e) => setRenameText(e.target.value)} placeholder='Revision Name' />
              {/* <span>{formErrors.accessRole}</span>  */}
              {/* <span>{formError.workSpace}</span> */}
            </div>



            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>

              <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>


              <p onClick={() => { setRenameText(""); handleRenameModal() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

            {/* <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span> */}
          </form>

        </div>
      </Modal>

    </>
  )
}

export default DocumentsTab



/**
 * RevisionEdit.js
 * 
 * This is the Document Tab of Revision Edit component, responsible for rendering Document Tab of Revision Edit. 
 * 
 *
 * Author: Ravinder Kumar
 *   
 * 
 * Created on: 2023-08-29
 * 
 * 
 * Last Modified on: 2023-09-28
 */