import * as React from 'react';
import { useState, useEffect } from 'react';
import VendorStyle from './VendorRevisonList.module.css'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import { useParams } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ChatRow from 'components/ChatRows/ChatRow';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TelegramIcon from '@mui/icons-material/Telegram';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import ApprovalStyle from '../Approvals/Approvals.module.css';
import { PrivateComponent } from 'api/axios'
import useAuth from 'hooks/useAuth';
import VendorApprovastyle from '../Approvals/VendorApproval.module.css';
import useBackdrop from 'hooks/useBackdrop';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import SendForApprovalModal from 'components/SendForApprovalModal/SendForApprovalModal';
import useToast from 'hooks/useToast';


const VendorRevisonList = () => {

  const { Toast, showToast } = useToast(); // Hook to use toast notifications
  const { client, orgId } = useParams();// Hook to get URL parameters
  const { auth } = useAuth() // Hook to get authentication information
  const privateAxios = PrivateComponent(); // Custom Axios instance for making requests
  const navigate = useNavigate();  // Hook for navigation

  const handleNavigate = (link) => {  // Function to handle navigation
    navigate(link)
  }
  // Hooks for backdrop component
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
  // State to store revision data
  const [revisonData, setRevisionData] = useState([]);
  // State to store search input value
  const [searchValue, setSearchValue] = useState("");
  // State to store filtered table data
  const [filteredTableData, setFilteredTableData] = useState([]);
  // State to handle approval modal visibility
  const [approvalModal, setApprovalModal] = useState(false);
  // State to store search file type
  const [searchFileType, setSearchFileType] = useState("");
  // State to store remark data
  const [remarkData, setRemarkData] = useState([]);

  // Function to toggle approval modal
  const handleApprovalModal = (data) => {
    setApprovalModal(prev => !prev);
  };

  // Function to fetch all revisions
  const GetAllRevisons = async () => {
    openBackdrop();// Show loading backdrop
    try {
      const response = await privateAxios.get(`/technicalSpecification/getAllVendorSpecificVendorRevisions/${orgId}`)
        .then((res) => {
          setRevisionData(res.data);// Set revision data from response

          if (res.status === 200) {
            const groupedObject = {};
            // Iterate through each object in the array
            res.data.forEach(obj => {
              for (const key in obj) {
                const value = obj[key];
                if (key in groupedObject) {
                  // If it's an array and the value is not already in it, push the value
                  if (Array.isArray(groupedObject[key]) && !groupedObject[key].includes(value)) {
                    groupedObject[key].push(value);
                  } else if (!Array.isArray(groupedObject[key]) && groupedObject[key] !== value) {
                    // If it's not an array and the value is different, create an array and add both values
                    groupedObject[key] = [groupedObject[key], value];
                  }
                } else {
                  // If the key is not in groupedObject, add it with the value
                  groupedObject[key] = [value];
                }
              }
            });
            setSorterData(groupedObject); // Set grouped data
            setRevisionData(res.data); // Set revision data

          } else {
            console.error('Error fetching revisions data');
          }
          closeBackdrop(); // Hide loading backdrop
        }).catch((error) => {
          closeBackdrop(); // Hide loading backdrop
          console.error('Error fetching revisions data:', error);
        }
        )
    } catch (error) {
      closeBackdrop(); // Hide loading backdrop
    }
  }

  // Fetch revisions on component mount
  useEffect(() => {
    GetAllRevisons();

  }, []);

  // Update filtered table data when revision data changes
  useEffect(() => {
    setFilteredTableData(revisonData)
  }, [revisonData])
  // // -------------------------------------------------New Sortting-- start/End-Date------------------------------------------------------------------

  // State to handle start date dropdown visibility
  const [isStartDateDropdownOpen, setIsStartDateDropdownOpen] = useState(false);

  // Function to toggle start date dropdown
  const toggleStartDateDropdown = () => {
    setIsStartDateDropdownOpen(!isStartDateDropdownOpen);
  };

  // Function to handle sorting by start date
  const handleStartDateSort = (sortType) => {
    const sortedItems = [...revisonData]; // Use tableData, not updatedData

    if (sortType === 'Newest') {
      sortedItems.sort((a, b) => new Date(b.submittedOn).getTime() - new Date(a.submittedOn).getTime());
    } else if (sortType === 'Oldest') {
      sortedItems.sort((a, b) => new Date(a.submittedOn).getTime() - new Date(b.submittedOn).getTime());
    }

    setFilteredTableData(sortedItems); // Update the displayed data
    setIsStartDateDropdownOpen(false);
  };
  // ----------------------------------------

  // State to handle end date dropdown visibility
  const [isEndDateDropdownOpen, setIsEndDateDropdownOpen] = useState(false);

  // Function to toggle end date dropdown
  const toggleEndDateDropdown = () => {
    setIsEndDateDropdownOpen(!isEndDateDropdownOpen);
  };

  // State to handle multi-click functionality for end date sort
  const [multiClick4, setMultiClick4] = useState(false);
  const handleMultiClick4 = () => {
    setMultiClick4(prev => !prev)
  }

  // Function to handle sorting by end date
  const handleEndDateSort = (sortType) => {
    const sortedItems2 = [...revisonData]; // Use tableData, not updatedData

    if (sortType === 'Newest') {
      sortedItems2.sort((a, b) => new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime());
    } else if (sortType === 'Oldest') {
      sortedItems2.sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());
    }

    setFilteredTableData(sortedItems2); // Update the displayed data
    setIsEndDateDropdownOpen(false);
  };

  // -----------------------------------------------products-filter------------------------------------------------------------

  // State to handle product search dropdown visibility
  const [isProductSerach, setIsProductSerach] = useState(false)
  const toggleProductDropDown = () => {
    setIsProductSerach(!isProductSerach);
  };

  // State to handle multi-click functionality for product search
  const [multiClick6, setMultiClick6] = useState(false);
  const handleMultiClick6 = () => {
    setMultiClick6(prev => !prev)
  }

  // State to handle file search dropdown visibility
  const [isFileSerach, setIsFileSerach] = useState(true)
  const toggleFIleDropDown = () => {
    setIsFileSerach(!isFileSerach);
  };

  // State to handle multi-click functionality for file search
  const [multiClickFile, setMultiClickFIle] = useState(true);
  const handleMultiClickFile = () => {
    setMultiClickFIle(prev => !prev)
  }

  // State to handle send by icon dropdown visibility
  const [isSendByIconOpen, setIsSendByIconOpen] = useState(false);
  const toggleSendByIcon = () => {
    setIsSendByIconOpen(prevState => !prevState);
  };

  // --------------------------------------------Function to handle search input change --------------------------------

  // Function to handle search input change
  const handleSearchChange = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchValue(inputValue);

    // Filter the tableData based on the searchValue
    const filteredData = revisonData?.filter((row) =>
      row?.name?.toLowerCase()?.includes(inputValue) ||
      row?.sentBy?.toLowerCase()?.includes(inputValue) ||
      row?.product?.toLowerCase()?.includes(inputValue) ||
      row?.vendorRevisionType?.toLowerCase()?.includes(searchFileType)
    );

    setFilteredTableData(filteredData);
  };

  // ----------------------------------------------product seach-------------------------------------------------------------
  // State to store search term for product search
  const [searchTerm, setSearchTerm] = useState('');
  // Filter products based on search term
  const filteredProducts = revisonData?.filter(pp => pp?.productName?.trim().toLowerCase().includes(searchTerm?.toLowerCase()));
  // State to store selected product for filtering
  const [productFilter, setProductFilter] = useState('');

  const filterTableData = () => {
    // Filter the data based on the search value and selected product
    const filteredData = revisonData.filter((row) => {
      const productNameMatch = row.productName.trim().toLowerCase().includes(searchTerm.toLowerCase());
      const searchValueMatch = row.name.trim().toLowerCase().includes(searchValue.toLowerCase());
      return productNameMatch && searchValueMatch;
    });

    setFilteredTableData(filteredData);// Update filtered data
  };

    // Apply filtering when search value or term changes
  useEffect(() => {
    filterTableData();
  }, [searchValue, searchTerm, revisonData]);

// Function to handle product filter selection
  const handleProductFilter = (product) => {
    setProductFilter(product);
  };

  // **************************************************Add Revision remark*************************************************************
  // State variables for chat functionality
  const [chatSlider, setChatSlider] = useState(false); // Toggle chat slider
  const [chatValue, setChatValue] = useState(""); // Store chat input value
  const [revData, setRevData] = useState(""); // Store revision data for chat
  const [revId, setRevId] = useState(""); // Store revision ID for chat

   // Function to toggle chat slider
  const handleChatSlider = (data) => {
    setChatSlider(prev => !prev)
    setRevData(data)
  }

   // Fetch chat remarks when chat slider is open
  useEffect(() => {
    if (chatSlider === true && revData !== "") {
      getRemarkAllChat(revData)
    }
  }, [chatSlider, revData])

    // Function to send chat remark
  const handleChatSend = async (e) => {
    e.preventDefault();
    if (chatValue.trim() !== "" && chatValue !== undefined && chatValue !== null) {
      try {
        let url = ""
        switch (revData.type.toLowerCase()) {
          case "commercial": url = `/commercial/addRemarkOnCommercialRevisionByVendor`;
            break;
          case "technical specification": url = `/technicalSpecification/addRemarkOnRevisionByVendor`;
            break;
          case "procure": url = ``;
            break;
          case "approval": url = ``;
            break;
          default: return;
        }

        const res = await privateAxios.post(`${url}/${auth.userData.id}/${revData.revId}`, { remark: chatValue });

        getRemarkAllChat(revData); // Fetch updated remarks
        setChatValue(""); // Clear chat input

      } catch (error) {
        console.error("API error:", error);
      }
    }
  };

  // ***********************************************Get Remark revision***************************************************

   // Function to fetch all chat remarks for a revision
  const getRemarkAllChat = async (revData) => {
    openBackdrop();//Show loader
    try {
      let url = ""
      switch (revData.type.toLowerCase()) {
        case "commercial": url = `/commercial/getRemarksOfCommercialVendorRevision`;
          break;
        case "technical specification": url = `/technicalSpecification/getRemarksOfRevisionOfVendorandKam`;
          break;
        case "procure": url = ``;
          break;
        case "approval": url = ``;
          break;
        default: return;
      }
      const res = await privateAxios.get(`${url}/${revData.revId}`).then(res => {
        setRemarkData(res.data); // Set remarks data
        closeBackdrop(); // Hide loader

        handleChatNotification();
      }).catch((err) => {
        // console.log(err)
      })
    } catch (error) {
      // console.log("Api error " + error)
    }
  }
  const [commentError, setCommentError] = useState(''); // State for comment error
  const [sorterData, setSorterData] = useState(); // State for sorter data
  const [sortedData, setSortedData] = useState([]); // State for sorted data
  const [sorterOpen, setSorterOpen] = useState(""); // State for open sorter

  // Function to toggle sorters
  const handleTableSorterToggle = (sorter) => {
    if (sorterOpen === sorter) {
      setSorterOpen("");
    }
    else {
      setSorterOpen(sorter);
    }
  }

    // Update sorted data when revision data changes
  useEffect(() => {
    setSortedData(revisonData);
  }, [revisonData]);


  // Function to handle sorting by different criteria
  const handleSorting = (value, type) => {
    switch (type) {
      case "product": setSortedData(revisonData.filter((dd) => dd.productName === value));
        break;
      case "type": setSortedData(revisonData.filter((dd) => dd.type === value));
        break;
      case "submitedOn": dateSorting("submitedOn", value);
        break;
      case "sentBy": setSortedData(revisonData.filter((dd) => dd.sentBy === value));
        break;
      case "lastModified": dateSorting("lastModified", value);
        break;
      default: clearSorting();
    }
    setSorterOpen("");
  };

  // Function to clear sorting
  const clearSorting = () => {
    setSortedData(revisonData);
    setSorterOpen("");
  };


   // Function to handle date sorting
  const dateSorting = (type, value) => {
    let sortedData = []
    if (type === "submitedOn") {
      if (value === "asc") {
        sortedData = [...revisonData].sort((a, b) => {
          return new Date(a.submittedOn) - new Date(b.submittedOn);
        });
      }
      else {
        sortedData = [...revisonData].sort((a, b) => {
          return new Date(b.submittedOn) - new Date(a.submittedOn);
        });
      }
    }
    else {
      if (value === "asc") {
        sortedData = [...revisonData].sort((a, b) => {
          return new Date(a.lastModified) - new Date(b.lastModified);
        });
      }
      else {
        sortedData = [...revisonData].sort((a, b) => {
          return new Date(b.lastModified) - new Date(a.lastModified);
        });
      }

    }
    setSortedData(sortedData);// Update sorted data
  }

  // *************************************************************************************************************
  const [isOpen, setIsOpen] = useState(false); // State for popup
  const [sendToKamModal, setSendToKAMModal] = useState(false); // State for KAM modal


   // Function to toggle popup
  const handleDivClick = () => {
    setIsOpen(true);
  };

   // Function to toggle KAM modal
  const handleSendToKAMModal = (data) => {
    setRevData(data);
    setSendToKAMModal(prev => !prev)
  }

  // Function to handle approval submission
  const handleAppovalSubmit = async (data) => {
    openBackdrop();//show loader


    try {
      let url = "";
      switch (revData.type.toLowerCase()) {
        case "commercial":
          url = `/commercial/sendCommercialVendorRevisionToKAMFromVendor`;
          break;
        case "technical specification":
          url = `/technicalSpecification/sendVendorRevisionToKAMFromVendor`;
          break;
        case "procure":
          url = ``;
          break;
        case "approval":
          url = ``;
          break;
        default:
          return;
      }

      const response = await privateAxios.post(`${url}/${revData.revId}`, {
        comments: data.name
      });

      if (response.status === 200) {
        showToast("success", "Approval sent successfully!");
        setApprovalModal(prev => !prev);

      }
    } catch (error) {
      showToast("error", "Something went wrong!");

    }
    closeBackdrop();
  };

  // -------------------------------testing-----------------------

  const [isPopupOpen, setPopupOpen] = useState(false);// State for popup


  const openPopup = () => {
    setPopupOpen(true);// Open popup
  };

  const closePopup = () => {
    setPopupOpen(false);// Close popup
  };

// Function to navigate to specific revision type
  const handleRevisionNavigate = (type, row) => {
    switch (type.toLowerCase()) {

      case "technical specification": navigate(`technical/${row.id}?tab=0`);
        break;
      case "commercial": navigate(`commercial/${row.id}?tab=0`);
        break;
    }

  }
  // --------------------------------------------------------------------------------

  // Function to handle displaying last seen time
  const handleLastSeen = (loginTime) => {
    const loginDate = new Date(loginTime);
    const currentDate = new Date();
    const timeDifference = currentDate - loginDate;
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    let displayString;

    if (daysDifference > 0) {
      // If the difference is more than 1 day, display the date
      displayString = `${loginDate.toDateString()}`;
    } else if (hoursDifference > 0) {
      // If the difference is more than 1 hour, display the hours
      displayString = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
    } else if (minutesDifference > 0) {
      // If the difference is more than 1 minute, display the minutes
      displayString = `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
    } else {
      // If the login was within the last minute, display "Just now"
      displayString = "Just now";
    }

    return displayString;
  }
  // =====================chat notification========================

  
  const [reRender, setReRender] = useState(false); // State for re-rendering component

  const handleChatNotification = () => {
    setReRender(prev => !prev); // Toggle re-render state
  }

  return (
    <>
      <Toast />
      <BackdropComponent />
      <div className={workSpaceStyle.workSpaceMain} >

        <div className={workSpaceStyle.workSpaceHeader} >
          <div className={VendorStyle.VendorMain} >

            <div style={{ display: 'flex', width: '60%', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <h1 style={{ gap: '10px' }}><span style={{ fontSize: "20px" }}>{client}</span></h1>
              </div>
              <div className={workSpaceStyle.workSpaceHeaderSearchbar}
                style={{ width: '300px', minWidth: '300px', display: 'flex', justifyContent: 'center' }}>
                <input
                  type="text"
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder='Search Here...'
                />
              </div>
            </div>
            <div className={workSpaceStyle.WorkSpaceHeaderSorters} style={{ width: '40%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flexDirection: 'row', gap: '50px' }}>
              <div style={{ width: '20%', display: 'flex', alignItems: 'center' }}>
                <Tooltip title='Help'>
                  <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081324-organisations-details">

                    <HelpOutlineOutlinedIcon />
                  </a>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <div className={VendorApprovastyle.approvalVendorMain}>
          <table className={VendorApprovastyle.approvalVendorTable}>
            <thead>
              <tr className={`${VendorApprovastyle.tableHead}`} style={{ zIndex: '2', overflowX: 'scroll' }}>
                <td className={`${VendorApprovastyle.tableColumnSticky}`} style={{ minWidth: "200px" }}>
                  <div className={VendorApprovastyle.tdRow}>
                    <p>Name</p>
                  </div>
                </td>
                <td>
                  <div className={workSpaceStyle.tableSorterDiv} >
                    <p onClick={() => handleTableSorterToggle("product")}>Products
                      <span>{sorterOpen === "product" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                    </p>
                    {
                      sorterOpen === "product" &&
                      <>
                        <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("product")}></div>
                        <ul className={workSpaceStyle.tableSorterDropDown}>
                          <li onClick={() => clearSorting()} >All</li>
                          {
                            sorterData?.productName?.map((ss) => {
                              return (

                                <li onClick={() => handleSorting(ss, "product")} >{ss === null ? "Group" : ss}</li>
                              )
                            })
                          }
                        </ul>
                      </>
                    }
                  </div>
                </td>
                <td>
                  <div className={workSpaceStyle.tableSorterDiv} >
                    <p onClick={() => handleTableSorterToggle("type")}>File Type
                      <span>{sorterOpen === "type" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                    </p>
                    {
                      sorterOpen === "type" &&
                      <>
                        <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("type")}></div>
                        <ul className={workSpaceStyle.tableSorterDropDown}>
                          <li onClick={() => clearSorting()} >All</li>
                          {
                            // sortedData?.type?.map((ss) => {
                            sorterData?.type?.map((ss) => {


                              return (

                                <li onClick={() => handleSorting(ss, "type")} >{ss !== null && ss}</li>
                              )
                            })
                          }

                        </ul>
                      </>
                    }
                  </div>
                </td>
                <td>
                  <div className={workSpaceStyle.tableSorterDiv} >
                    <p onClick={() => handleTableSorterToggle("sentBy")}>Sent By
                      <span>{sorterOpen === "sentBy" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                    </p>
                    {
                      sorterOpen === "sentBy" &&
                      <>
                        <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("sentBy")}></div>
                        <ul className={workSpaceStyle.tableSorterDropDown}>
                          <li onClick={() => clearSorting()} >All</li>
                          {
                            sorterData?.sentBy?.map((ss) => {
                              return (

                                <li onClick={() => handleSorting(ss, "sentBy")} >{ss !== null && ss}</li>
                              )
                            })
                          }
                        </ul>
                      </>
                    }
                  </div>

                </td>

                <td>
                  <div className={workSpaceStyle.tableSorterDiv} style={{ minWidth: '200px' }}>
                    <p onClick={() => handleTableSorterToggle("submitedOn")}>Submited On
                      <span>{sorterOpen === "submitedOn" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                    </p>
                    {
                      sorterOpen === "submitedOn" &&
                      <>
                        <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("submitedOn")}></div>
                        <ul className={workSpaceStyle.tableSorterDropDown}>
                          <li onClick={() => clearSorting()} >All</li>
                          <li onClick={() => handleSorting("asc", "submitedOn")} >Latest</li>
                          <li onClick={() => handleSorting("desc", "submitedOn")} >Oldest</li>

                        </ul>
                      </>
                    }

                  </div>
                </td>

                <td style={{ minWidth: '200px' }}>Due Date</td>
                <td style={{ minWidth: '200px' }}>
                  <div className={workSpaceStyle.tableSorterDiv} >
                    <p onClick={() => handleTableSorterToggle("lastModified")}>last Modified
                      <span>{sorterOpen === "lastModified" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                    </p>
                    {
                      sorterOpen === "lastModified" &&
                      <>
                        <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("lastModified")}></div>
                        <ul className={workSpaceStyle.tableSorterDropDown}>
                          <li onClick={() => clearSorting()} >All</li>
                          <li onClick={() => handleSorting("desc", "lastModified")} >Latest</li>
                          <li onClick={() => handleSorting("asc", "lastModified")} >Oldest</li>

                        </ul>
                      </>
                    }

                  </div>
                </td>

                <td style={{ minWidth: '200px' }}>Actions</td>

              </tr>
            </thead>
            <tbody >

              {
                // filteredTableData?.map((rowData, id) => (
                sortedData.filter((item) =>
                  item?.name?.trim().toLowerCase().includes(searchValue.toLowerCase())).map((item, index) => {
                    return (


                      <tr key={index} className={`${VendorApprovastyle.tableRow}`}>
                        <td className={`${VendorApprovastyle.tableColumnSticky2} `}>
                          <div className={VendorApprovastyle.tdRow}>
                            <p>{item?.name}</p>

                          </div>
                        </td>
                        <td>{item?.productName}</td>
                        <td>{item?.type}</td>
                        {/* <td>{selectedFileType}</td> */}

                        <td>
                          <div className={ApprovalStyle.PeofileSendBy} style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <ProfileIcon data={{ email: item?.source }} width={"25px"} height={"25px"} showProfileBanner={true} />
                            <span>{item?.sentBy}</span>
                          </div>
                        </td>
                        {/* <td> {item?.revisionApprovalOfVendor?.dateCreated ? item.revisionApprovalOfVendor?.dateCreated.split('T')[0] : ''}</td> */}
                        <td> {handleLastSeen(item?.revisionApprovalOfVendor?.dateCreated)}</td>
                        <td>{item?.revisionApprovalOfVendor?.dueCreated}</td>


                        {/* <td>{handleLastSeen(item?.lastModified ? item.lastModified.split('T')[0] : '')}</td> */}
                        <td>{handleLastSeen(item?.lastModified)}</td>

                        <td className={VendorApprovastyle.AllIcons}>
                          <ul style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>

                            <div >
                              <p onClick={() => handleRevisionNavigate(item.type, item)}>
                                <RemoveRedEyeIcon />
                              </p>
                              {isPopupOpen && (
                                <div className={ApprovalStyle.popup} onClick={closePopup}>
                                  gg
                                </div>
                              )}
                            </div>

                            {/* <ul onClick={() => handleChatSlider({ revId: item.id, type: item.type })}> */}
                            <span onClick={() => handleChatSlider({ revId: item.id, type: item.type, name: item.name })}>

                              {item.type.toLowerCase() === 'technical specification'
                                ?
                                <ChatIconWithBadge url={`/technicalSpecification/getNotficationCountOfVendorTsRevision`} id={item.id} reRender={reRender} />
                                :
                                <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialVendorRevision`} id={item.id} reRender={reRender} />


                              }

                            </span>
                            {/* </ul> */}
                            <ul onClick={handleApprovalModal}><TelegramIcon onClick={() => handleSendToKAMModal({ revId: item.id, type: item.type })} /></ul>

                          </ul>
                        </td>
                      </tr>
                    )
                  })
              }
            </tbody>
          </table>


        </div>
      </div>

      <SendForApprovalModal
        open={approvalModal}
        onClose={handleApprovalModal}
        onSubmit={handleAppovalSubmit}
        prefilled={""}
        header="Send to Organisation KAM"
      />





      <ChatPannel
        open={chatSlider}
        onClose={handleChatSlider}
      >
        <>
          <ChatHeader onClose={handleChatSlider}
            downloadData={remarkData.map((chat) => {
              return { name: chat.name, date: chat.createdAt, chat: chat.remark };
            })}
            helpData={{ link: "tosomewhere" }}
            chatName={revData?.name}
          >
            <h3>{revData?.name}</h3>
          </ChatHeader>

          <ChatRows>
            {
              remarkData.map((data) => {
                return (
                  <ChatRow name={data.name} email={data.email} chat={data.remark} date={data?.createdAt} />
                )
              })
            }

          </ChatRows>

          <ChatInputField onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

        </>
      </ChatPannel>

    </>

  );
}


export default VendorRevisonList

