import React, { useState } from 'react'
import sorterStyle from './TableSorterDropDown.module.css';

const TableSorterDropDown = ({ data, onClick, toggle, type }) => {
  const [searchValue, setSearchValue] = useState("");


  return (
    <>
      <div className={sorterStyle.dropDownBackDrop} onClick={toggle}></div>
      <div className={sorterStyle.dropDownSorter}>
        {type !== "date" && <div className={sorterStyle.dropDownSorterSearch}>
          <input type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
        </div>
        }
        <ul className={sorterStyle.dropDownSorterList}>
          {type !== "date" ?
            <>
              <li onClick={() => { onClick("All"); toggle(); }}>All</li>
              {
                data?.filter((item) => item?.toLowerCase().includes(searchValue.trim().toLowerCase()))
                  .map((item, index) => {
                    return (

                      <li key={index} onClick={() => { onClick(item); toggle(); }}>{item}</li>
                    )
                  })
              }

            </>
            :
            <>
              <li onClick={() => { onClick("desc"); toggle(); }}>Latest </li>
              <li onClick={() => { onClick("asc"); toggle(); }}>Oldest</li>
            </>
          }
        </ul>

      </div>
    </>
  )
}

export default TableSorterDropDown
