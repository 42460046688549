import React from 'react';
import SliderStyle from "./SliderTwo.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Typography from '@mui/material/Typography'
import './slider.css'

const Slide = [
    {
        id: 1,
        linkImg: 'https://res.cloudinary.com/djk0rgonn/image/upload/v1701665003/ga8gkgmqis7mxcuja0ok.svg',
    },
    {
        id: 2,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },
    {
        id: 3,
        linkImg: 'https://res.cloudinary.com/djk0rgonn/image/upload/v1701665003/ga8gkgmqis7mxcuja0ok.svg',
        
    },
    {
        id: 4,
        linkImg: 'https://res.cloudinary.com/dtffniutw/image/upload/v1689518297/Mockup_PSD_FREE___iPad_Pro___Pencil_2018-removebg-preview_1_bnh73w.svg',
    },
];

function SliderTwo() {
    
    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        fade: true,
        cssEase: 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',

    }

    return (
        <div className={SliderStyle.boxTwo}>
                <div className={SliderStyle.UloginBoxStart2} >
                    <Slider {...settings} className='sideSlider'>
                        {Slide.map((item) => (
                            <div key={item.id} className={SliderStyle.slides}>
                                <img src={item.linkImg} />
                            </div>
                        ))}
                    </Slider>
                </div>
                <div className={SliderStyle.boxInfo}>
                    <Typography variant='h4'>Join the Steel Revolution!</Typography>
                    <Typography variant='h6'>Shop from more than 2000 trusted suppliers <br />for over 3000+ products</Typography>
                </div>
            </div>
    )
}

export default SliderTwo;






