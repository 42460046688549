import React, { useEffect, useState } from 'react'
import axios from 'api/axios'
import useBackdrop from 'hooks/useBackdrop';
import proStyle from './ProductSelectStyle.module.css';


const ProductSelectNew = ({ productsArray, setProductsArray }) => {
    // State variables initialization
    const [availableCategories, setAvailableCategories] = useState([]);
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    const [productList, setProductList] = useState([]);
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop()
    const [catSearch, setCatSearch] = useState("");
    const [catSelectDropdown, setCatSelectDropdown] = useState(false);
    const [productSelected, setProductSelected] = useState("");

    // Function to fetch available categories
    const getCategories = async () => {
        openBackdrop();
        try {
            await axios.get(`${process.env.REACT_APP_ECOM}/api/subgroups`).then(res => {
                if (res.status === 200) {
                    setAvailableCategories(res.data);
                }
            })
            closeBackdrop()
        } catch (error) {
            closeBackdrop();
            // console.log(error)
        }

    }

    // Fetch categories on component mount
    useEffect(() => {
        getCategories();
    }, [])


    // Function to fetch subcategories for a given category
    const getCategoryData = async (category) => {
        openBackdrop();
        try {
            const res = await axios.get(`${process.env.REACT_APP_ECOM}/api/hierarchy/${category.id}/${category.level}`).then(res => {
                // console.log(res.data);
                setSelectedSubcategories([{ ...res.data }]);

                setProductSelected("");
                closeBackdrop()
            }).catch((err) => {
                // console.log(err)
                closeBackdrop()

            })
        } catch (err) {
            // console.log(err);
            closeBackdrop()

        }

        // Reset subcategory when category changes
    };

    // Function to fetch products for a given subcategory
    const getPorductData = async (id, level) => {
        openBackdrop()
        try {
            axios.get(`${process.env.REACT_APP_ECOM}/api/productsforlevel/${id}/${level}`)
                .then((res) => {
                    setProductList(res.data);
                    setProductSelected("")
                    // console.log(res);
                })
                .catch((error) => {
                    setProductList([]);
                    setProductSelected("")
                    console.error('Error fetching products:', error);
                });
            closeBackdrop();
        } catch (error) {
            setProductList([]);
            setProductSelected("")
            closeBackdrop();
            // console.log(error)
        }
    }

    // Fetch products when the selected subcategory changes
    useEffect(() => {
        if (selectedSubcategories[selectedSubcategories.length - 1]?.Branch.length === 0) {
            const sub = selectedSubcategories[selectedSubcategories.length - 1]
            getPorductData(sub.Id, sub.Level);
        }

    }, [selectedSubcategories])



    // Function to toggle category selector dropdown
    const handleCatSelectorDropDown = () => {
        setCatSelectDropdown((prev) => !prev);
    }

    // Function to handle category selection
    const handleCategorySelector = async (cat) => {
        await getCategoryData({ ...cat, level: 1 });
        setProductList([])
        setProductSelected("")
        handleCatSelectorDropDown()
    }

    // Function to handle subcategory selection
    const handleSubcategorySelect = (subcategory, index) => {
        const tt = selectedSubcategories.filter((cat) => {
            return cat.Level < subcategory.Level;
        })
        const newSub = [
            ...tt,
            { ...subcategory },
        ]
        setProductList([]);
        setProductSelected("");
        setSelectedSubcategories(newSub);

    };


    const handleContinue = () => {
        if (productSelected === "") {
            alert("Please Select Product")
        }
        else {


        }
    }


    return (
        <>
            <BackdropComponent />
            <div style={{ width: "500px" }}>

                <div>
                    <p>Category</p>
                    <div className={proStyle.SelectorMain} >
                        <h2 onClick={handleCatSelectorDropDown}>{selectedSubcategories[0]?.Name || "Please Select A category"}</h2>
                        {
                            catSelectDropdown &&
                            <>
                                <div className={proStyle.BackDropStyle} onClick={handleCatSelectorDropDown}>

                                </div>
                                <div className={proStyle.SelectorDropDown}>
                                    <div className={proStyle.Searchbox}>
                                        <input type="text" value={catSearch} onChange={(e) => setCatSearch(e.target.value)} />
                                    </div>
                                    <ul className={proStyle.SelectorList}>
                                        {availableCategories.filter((cat) => cat.name.trim().toLowerCase().includes(catSearch.toLowerCase())).map((cat) => {
                                            return (
                                                <li onClick={() => handleCategorySelector(cat)}>{cat.name}</li>
                                            )
                                        })}

                                    </ul>
                                </div>
                            </>
                        }
                    </div>
                </div>



                {selectedSubcategories.length > 0 &&

                    selectedSubcategories.map((selectedCategory, index) => {
                        if (selectedCategory.Branch.length > 0) {
                            return (
                                <div>
                                    <p> Sub-Category</p>
                                    <SubcategorySelector
                                        category={selectedCategory}
                                        selectedSubcategories={selectedSubcategories}
                                        onSelectSubcategory={(subcategory) => handleSubcategorySelect(subcategory, index)}
                                        index={index}
                                    />

                                </div>
                            )
                        }
                    }
                    )}

                {selectedSubcategories[selectedSubcategories.length - 1]?.Branch.length === 0 &&
                    <div>
                        <p>  Product </p>
                        <ProductSelector productList={productList} productSelected={productSelected} setProSelected={setProductSelected} />

                    </div>}


                <button onClick={handleContinue} type='button'>Continue</button>



            </div>
        </>
    )
}

export default ProductSelectNew




const SubcategorySelector = ({ category, selectedSubcategories, onSelectSubcategory, index }) => {

    const [subCatSelectDropDown, setSubCatSelectDropDown] = useState(false);
    const [subCatSelected, setSubCatSelected] = useState("");
    const [subCatSearch, setSubCatSearch] = useState("");
    const handleSubCatSelectorDropDown = () => {
        setSubCatSelectDropDown(prev => !prev)
    }

    const handleSubCategorySelector = (subCat) => {
        onSelectSubcategory(subCat);
        setSubCatSelected(subCat.Name);
        handleSubCatSelectorDropDown();

    }

    return (
        <div className={proStyle.SelectorMain} key={index} >
            <h2 onClick={handleSubCatSelectorDropDown}>{(index < (selectedSubcategories.length - 1) && subCatSelected) || "Please Select A Sub-Category"}</h2>
            {
                subCatSelectDropDown &&
                <>
                    <div className={proStyle.BackDropStyle} onClick={handleSubCatSelectorDropDown}>

                    </div>
                    <div className={proStyle.SelectorDropDown}>
                        <div className={proStyle.Searchbox}>
                            <input type="text" value={subCatSearch} onChange={(e) => setSubCatSearch(e.target.value)} />
                        </div>
                        <ul className={proStyle.SelectorList}>
                            {category.Branch.filter((subCat) => {
                                return subCat.Name && subCat.Name.trim().toLowerCase().includes(subCatSearch.toLowerCase());
                            }).map((subCat) => {
                                return (
                                    <li onClick={() => handleSubCategorySelector(subCat)}>{subCat.Name}</li>
                                )
                            })}

                        </ul>
                    </div>
                </>
            }


        </div>
    )
}


const ProductSelector = ({ productSelected, productList, setProSelected }) => {

    const [proSelectDropDown, setProSelectDropDown] = useState(false);

    const [proSearch, setProSearch] = useState("");
    const handleProSelectorDropDown = () => {
        setProSelectDropDown(prev => !prev)
    }

    const handleProSelector = (pro) => {
        setProSelected(pro)
        handleProSelectorDropDown();

    }

    return (
        <div className={proStyle.SelectorMain} >
            <h2 onClick={handleProSelectorDropDown}>{productSelected.name || "Please Select A Product"}</h2>
            {
                proSelectDropDown &&
                <>
                    <div className={proStyle.BackDropStyle} onClick={handleProSelectorDropDown}>

                    </div>
                    <div className={proStyle.SelectorDropDown}>
                        <div className={proStyle.Searchbox}>
                            <input type="text" value={proSearch} onChange={(e) => setProSearch(e.target.value)} />
                        </div>
                        <ul className={proStyle.SelectorList}>
                            {productList.filter((pro) => {
                                return pro.name && pro.name.trim().toLowerCase().includes(proSearch.toLowerCase());
                            }).map((pro) => {
                                return (
                                    <li onClick={() => handleProSelector(pro)}>{pro.name}</li>
                                )
                            })}

                        </ul>
                    </div>
                </>
            }


        </div>
    )
}