import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import revisionEditStyle from 'pages/RevisionEdit/RevisionEdit.module.css'
import useBackdrop from 'hooks/useBackdrop'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatRow from 'components/ChatRows/ChatRow';

// =======================icons===========================

import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';


// =====================mui compoents====================


import Tooltip from '@mui/material/Tooltip';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import useToast from 'hooks/useToast';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import BackButton from 'components/BackButton/BackButton';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';
import RenameModal from 'components/RenameModal/RenameModal';
import DescriptionTab from 'components/CommercialSpecificationTabs/DescriptionTab/DescriptionTab';
import QuestionTab from 'components/CommercialSpecificationTabs/QuestionTab/QuestionTab';
import DocumentsTab from 'components/CommercialSpecificationTabs/DocumentTab/DocumentTab';
import { createExcel } from 'utility/excelUtils';
import SendForApprovalModal from 'components/SendForApprovalModal/SendForApprovalModal';
import SendToApprovalCommityModal from 'components/SendToApprovalCommityModal/SendToApprovalCommityModal';



// =====================mui compoents====================

const VendorCommercialTempleteRevisionView = () => {

  const { showToast, Toast } = useToast();//Hook for toast notifications
  const privateAxios = PrivateComponent();// Axios instance for private API calls 
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop()//Hooks for managing backdrop
  const location = useLocation();// Location hook for getting the current URL
  const { state } = location; // State from location
  const { taskId, tempId, templeteName, revisionId, productId } = useParams();// Params from URL
  const navigate = useNavigate();//Hook for navigation 
  const { auth } = useAuth();// Authentication hook
  const [descriptionVendorRemark, setDescriptionVendorRemark] = useState("");// State for remark
  const [revisionDetails, setRevisionDetails] = useState(null);
  const [isDefaultRevision, setIsDefaultRevision] = useState(false);
  const [viewType, setViewType] = useState("");// State for view type



  // =======================set View Type==============================

  // This effect hook runs when 'state' changes.
  useEffect(() => {
    // If 'state' exists, set the 'viewType' based on 'state.from'.
    if (state) {
      setViewType(state.from);
    }

  }, [state]);// Dependency array containing 'state'.


  // =======================Back Button================================

  // Function to handle the back button functionality.
  const handleBackButton = () => {
    // Get the current path from the URL.
    const currentPathname = window.location.pathname;
    // Find the index of the last slash in the current path.
    const lastSlashIndex = currentPathname.lastIndexOf('/');
    // Get the previous path by slicing up to the last slash.
    const previousPathname = currentPathname.substring(0, lastSlashIndex);
    // Navigate to the previous path.
    navigate(-1);
  }

  // ==============tab selector and state=================

  // Create a URLSearchParams object to handle query parameters.
  const searchParams = new URLSearchParams(location.search);
  // State to manage the active tab.
  const [tabActive, setTabActive] = useState("0");

  // Effect hook to set the active tab based on URL parameters.
  useEffect(() => {
    if (searchParams.get("tab") !== undefined && searchParams.get("tab") !== null) {
      setTabActive(searchParams.get("tab"));
    }
  }, [Location, searchParams]);// Dependency array containing 'location' and 'searchParams'.


  // Function to handle tab change.
  const handleTabChange = (index) => {
    setTabActive(index);// Update the active tab state.
    navigate(`?tab=${index}`, { replace: true });// Update the URL query parameter.
  }


  // =============header three dot=============

  // State to manage the visibility of the header three-dot menu.
  const [headerThreeDot, setHeaderThreeDot] = useState(false);
  // Function to toggle the header three-dot menu.
  const handleheaderThreeDot = () => {
    setHeaderThreeDot(prev => !prev);
  }

  // =================multisave ================

  // State to manage the multi-save functionality.
  const [multiSave, setMultiSave] = useState(false);
  // Function to toggle the multi-save state.
  const handleMultiSave = () => {
    setMultiSave(prev => !prev)
  }

  // ==================chat state =================

  // State to manage the visibility of the chat slider.
  const [chatSlider, setChatSlider] = useState(false);
  // State to store chat messages.
  const [revisionChats, setRevisionChats] = useState([]);
  // State to store the current chat input value.
  const [chatValue, setChatValue] = useState("");

  // Function to handle chat slider
  const handleChatSlider = () => {
    setChatSlider(prev => !prev);
  }

  // Function to handle chat send
  const handleChatSend = async (e) => {
    e.preventDefault();
    if (chatValue !== "") {
      try {
        const res = await privateAxios.post(`/commercial/addRemarkOnCommercialRevisionByVendor/${auth.userData.id}/${revisionId}`, { remark: chatValue })
        if (res.status === 200) {
          getRevisionChat();
          setChatValue("")
        }
      } catch (error) {
        console.log(error);
        showToast("error", "error adding Comment")

      }
    }

  }

  // Function to get revision chats
  const getRevisionChat = async () => {
    handleChatNotification();
    try {
      const res = await privateAxios.get(`/commercial/getRemarksOfCommercialVendorRevision/${revisionId}`)
      setRevisionChats(res.data);
    } catch (error) {
      console.log(error);
      showToast("error", "error getting Chats");
    }
  }

  useEffect(() => {
    if (chatSlider) {
      getRevisionChat();
    }
  }, [chatSlider])

  // ==================chat state =================

  // ==================rename  state =================

  // State to manage the visibility of the save-as modal.
  const [saveAs, setSaveAs] = useState(false);
  const [renameModal, setRenameModal] = useState(false);

  // Function to toggle the save-as modal.
  const handleRenameModal = () => {
    setRenameModal(prev => !prev)
  }

  const [saveAsModal, setSaveAsModal] = useState(false);
  const handleSaveAsModal = () => {
    setSaveAsModal(prev => !prev)

  }

  // State to manage the visibility of the send-for-approval modal.
  const [sendForApprovalModal, setSendForApprovalModal] = useState(false);
  // Function to toggle the send-for-approval modal.
  const handleSendForApprovalModal = (data) => {
    setSendForApprovalModal(prev => !prev)
  }

  // State to manage the visibility of the reassign modal.
  const [reassignModal, setReassignModal] = useState(false);
  // Function to toggle the reassign modal.
  const handleReassignModal = () => {
    setReassignModal(prev => !prev)
  }


  // ==================rename  state =================


  // ============document states=========================

  // State to store document data.
  const [documents, setDocuments] = useState([]);
  // State to manage the visibility of the add-document modal.
  const [addDocumentModal, setAddDocumentModal] = useState(false);
  // Function to toggle the add-document modal.
  const handleAddDocumentModal = () => {
    setAddDocumentModal(prev => !prev)
  }

  // Function to handle document upload.
  const handleDocumentUpload = async (data) => {
    openBackdrop();// Show backdrop during upload.
    try {
      const fd = new FormData();
      fd.append(`files`, data.files);
      fd.append("name", data.fileName);
      fd.append("docType", data.type);
      fd.append("uploadedBy", auth.roles);

      // Upload document to the server.
      const res = await privateAxios.post(`/commercial/uploadCommercialDocuments/${revisionId}`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })

      await getDataProcure();// Fetch updated data.
      handleAddDocumentModal(); // Close the add-document modal.
      showToast("success", "Document Uploaded Successfully"); // Show success toast notification.
    } catch (error) {
      // console.log(error);
      handleAddDocumentModal(); // Close the add-document modal.
      showToast("error", "Error Uploading Document");// Show error toast notification.
    }

    closeBackdrop();// Hide backdrop after upload.
  }

  // State to store revision description.
  const [revisionDescription, setRevisionDescription] = useState("");


  // ===================Question State========================

  // State to store question rows.
  const [questionRows, setQuestionRows] = useState([]);

  // Function to add a new question row.
  const handleAddQuestionRow = () => {
    const newRow = {
      id: questionRows[questionRows.length - 1].id + 1,
      name: "",
      unit: "text",
      value: "",
      remark: "",
      vendorRemark: "",
      expectdAnswer: ""

    }

    setQuestionRows((prev) => {
      return [
        ...prev,
        newRow
      ]
    })
  }

  // Function to delete a question row.
  const handleQuestionRowDelete = (id) => {
    setQuestionRows((prev) => {
      return prev.filter((row) => row.id !== id)
    })

  }

  // Function to handle input changes in question rows.
  const handleInputChange = (e, rowIndex) => {
    const { value, name } = e.target;
    setQuestionRows((prev) => {
      return prev.map((row, index) => {
        if (index === rowIndex) {
          if (name === "unit") {
            return {
              ...row,
              [name]: value,
              expectdAnswer: value
            }
          }
          else {
            return {
              ...row,
              [name]: value,

            }
          }

        }
        else {
          return row;
        }
      })
    })

  }



  // ===================Question State========================



  // ===================get data================

  // Function to fetch data for e-commerce.
  const getDataEcom = () => {

    try {

      setRevisionDetails({
        name: "com rev 2",
        productId: productId,
        productName: "SCREENS AND VIBRO FEEDERS",
        description: {
          name: "size"
        },
        questions: [
          {
            id: 1,
            name: "Some Question",
            unit: "date",
            value: "Some Value",
            remark: "Sosadsa dsa fa faf",
            vendorRemark: "",
            expectdAnswer: ""

          },
          {
            id: 2,
            name: "Some Question2",
            unit: "text",
            value: "Some Value2",
            remark: "Sosadsa dsa fa faf2",
            vendorRemark: "",
            expectdAnswer: ""
          },

        ],
        documents: [
          {
            name: "doc confidential",
            url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            docType: "CONFIDENTIAL"
          },
          {
            name: "doc normal",
            url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
            docType: "NORMAL"
          }

        ]
      })


      setQuestionRows([
        {
          id: 1,
          name: "Some Question",
          unit: "date",
          value: "Some Value",
          remark: "Sosadsa dsa fa faf",
          vendorRemark: "",
          expectdAnswer: ""

        },
        {
          id: 2,
          name: "Some Question2",
          unit: "text",
          value: "Some Value2",
          remark: "Sosadsa dsa fa faf2",
          vendorRemark: "",
          expectdAnswer: ""
        },

      ])

      setDocuments([
        {
          name: "doc confidential",
          url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          docType: "CONFIDENTIAL"
        },
        {
          name: "doc normal",
          url: "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
          docType: "NORMAL"
        }

      ])

      setRevisionDescription("size")

    } catch (error) {

    }


  }


  // Function to fetch procurement data.
  const getDataProcure = async () => {
    openBackdrop();// Show backdrop during data fetch.
    try {
      const res = await privateAxios.get(`/commercial/getVendorCommercialRevision/${revisionId}`)

      const { data } = res;
      setRevisionDetails({
        name: data.name,
        productId: data.productId,
        productName: data.productName,
        description: {
          name: data?.description?.name
        },
        questions: data.questions.map((ques) => {
          return {
            ...ques,
            unit: ques.unit === null || ques.unit === undefined || ques.unit === ""
              ?
              "TEXT"
              :
              ques.unit === 'days'
                ?
                "DATE"
                :
                ques.unit.toUpperCase()
          }
        }),
        documents: data.documents,
        default: data.default,
        revisionStatus: data.revisionStatus,
      });
      setQuestionRows(data.questions.map((ques) => {
        return {
          ...ques,
          unit: ques.unit === null || ques.unit === undefined || ques.unit === "" ? "TEXT" : ques.unit === 'days' ? "DATE" : ques.unit.toUpperCase()
        }
      }))
      setIsDefaultRevision(data.default)
      setDocuments(data.documents)

      setRevisionDescription(data.description.name)
      setDescriptionVendorRemark(data.description.vendorRemark)

    } catch (error) {
      console.log("Error", error);

    }

    closeBackdrop();
  }

  // Effect hook to fetch procurement data on component mount.
  useEffect(() => {
    getDataProcure();
  }, []) // Empty dependency array to run only once on mount.


  // =======================question rows Validation===========================

  // State to store duplicate error messages.
  const [duplicateError, setDuplicateError] = useState([]);

  // Function to validate question rows.
  const handleQuestinValidation = () => {
    const hasNullName = questionRows.some(rr => rr.name === null || rr.name === undefined || rr.name === "");

    if (hasNullName) {

      alert("please check Attribute Name No Name can be empty");
      return false;
    }
    else {
      const nameOccurrences = {};
      const duplicateNamesId = [];
      questionRows.forEach((item) => {
        const name = item.name;
        // Check if the name has been encountered before
        if (nameOccurrences[name]) {
          // If it has, mark it as a duplicate
          duplicateNamesId.push(item.id);
        } else {
          // Otherwise, record it in the occurrences object
          nameOccurrences[name] = true;
        }
      });
      if (duplicateNamesId.length > 0) {
        // console.log(duplicateError)
        setDuplicateError(duplicateNamesId)
        alert("Please Remove Duplicate Attributes");
        return false;
      }
      else {
        setDuplicateError([]);
        return true;
      }

    }

  }


  // =======================question rows Validation===========================



  // ===================saveas revisions================

  // Function to handle saving a revision with a new name ("Save As")
  const handleSaveAsRevision = async (temp) => {
    openBackdrop(); // Show backdrop during the operation.

    if (handleQuestinValidation()) {// Validate questions before proceeding.
      try {
        // Update question rows by removing the 'id' and ensuring 'unit' is set.
        const updatedQuestions = questionRows.map((ques) => {
          let que = ques;
          delete que.id;
          if (que.unit === null || que.unit === undefined || que.unit === "") {
            que.unit = "TEXT"
          }
          return que;
        })
        // Update documents by removing the 'id'.
        const updatedDocuments = documents.map((doc) => {
          let dd = doc;
          delete dd.id;
          return dd;
        })

        // Prepare the payload based on whether it's a new revision or an existing one.
        let payload = {};
        if (revisionId === 0) {
          payload = { ...revisionDetails, name: temp.name, description: { name: revisionDescription }, questions: updatedQuestions }

        }
        else {
          payload = { ...revisionDetails, name: temp.name, description: { name: revisionDescription }, documents: updatedDocuments, questions: updatedQuestions }

        }


        // Send the request to save the revision.
        const res = await privateAxios.post(`/commercial/addCommercialRevision/${auth.orgId}/${taskId}/${tempId}`, payload)
        handleSaveAsModal(); // Close the "Save As" modal.
        showToast("success", "Revision Saved"); // Show success toast notification.
        handleBackButton(); // Navigate back to the previous screen.
        closeBackdrop(); // Hide the backdrop.

      } catch (error) {
        console.log(error);
        showToast("error", "Error Saving Revision"); // Show error toast notification.
        handleSaveAsModal(); // Close the "Save As" modal.
        closeBackdrop(); // Hide the backdrop.
      }

    }
    else {
      closeBackdrop(); // Hide the backdrop if validation fails.
    }
  }


  // ==================save Revisions========================

  // Function to handle saving the current revision.
  const handleSaveRevision = async () => {
    openBackdrop();// Show backdrop during the operation.
    if (handleQuestinValidation()) { // Validate questions before proceeding.
      try {
        // Update question rows by removing the 'id' and ensuring 'unit' is set.
        const updatedQuestions = questionRows.map((ques) => {
          let que = ques;
          delete que.id;
          if (que.unit === null || que.unit === undefined || que.unit === "") {
            que.unit = "TEXT"
          }
          return que;
        })

        // Update documents by removing the 'id'.
        const updatedDocuments = documents.map((doc) => {
          let dd = doc;
          delete dd.id;
          return dd;
        });

        // Prepare the payload for updating the revision.
        const payload = { name: revisionDetails.name, description: { name: revisionDescription, vendorRemark: descriptionVendorRemark }, documents: updatedDocuments, questions: updatedQuestions }

        // Send the request to update the revision.
        const res = await privateAxios.post(`/commercial/updateVendorCommercialRevision/${revisionId}`, payload)
        const { data } = res;

        showToast("success", "Revision Saved"); // Show success toast notification.
        getDataProcure(); // Fetch updated procurement data.
        closeBackdrop(); // Hide the backdrop.

      } catch (error) {
        showToast("error", "Error Saving Revision"); // Show error toast notification.
        closeBackdrop(); // Hide the backdrop.
      }
    }
    else {
      closeBackdrop(); // Hide the backdrop if validation fails.
    }
  }
  // ==================save Revisions========================


  // =============change revision Name==============================

  // Function to handle renaming a revision.
  const handleChangeTempleteName = async (data) => {
    openBackdrop();// Show backdrop during the operation.
    try {
      // Send the request to rename the revision.
      const res = await privateAxios.post(`/commercial/renameCommercialRevision/${revisionId}?name=${data.name}`)
      if (res.status == 200) {

        getDataProcure(); // Fetch updated procurement data.
        showToast("success", "Name Update Successful"); // Show success toast notification.
        handleRenameModal(); // Close the rename modal.
      }
    } catch (error) {
      console.log(error);
      showToast("error", "Name Update Failed"); // Show error toast notification.

    }
    closeBackdrop(); // Hide the backdrop.
  }

  // =============change revision Name==============================

  // ============duplicate revision==============================
  const [duplicateNameModal, setDuplicateNameModal] = useState(false);
  const [duplicateName, setDuplicateName] = useState("");
  const handleDuplicateNameModal = (e) => {
    setDuplicateNameModal(prev => !prev);
    setDuplicateName(revisionDetails?.name || "");
  }


  // Function to handle duplicating revision
  const handleDuplicateRevisison = async (data) => {
    openBackdrop();
    try {
      // Send post request to duplicate revision
      const res = await privateAxios.post(`/commercial/duplicateCommercialRevision/${revisionId}?name=${data.name}`);
      showToast("success", "Duplicate Revision Created"); // Show success toast notification.
      handleBackButton(); // Navigate back to the previous screen.
      handleDuplicateNameModal(); // Close the duplicate name modal.
    } catch (error) {
      showToast("error", "Error Creating Revision Duplicate"); // Show error toast notification.
      handleDuplicateNameModal(); // Close the duplicate name modal.
    }
    closeBackdrop();
  }
  // ============duplicate revision==============================

  // ================delete Revision================================

  const [deleteModal, setDeleteModal] = useState(false);
  const handleDeleteModal = () => {
    setDeleteModal(prev => !prev)
  }

  // Function to handle deleting revision
  const handleDeleteRevision = async () => {
    // Check if revision ID is not null before deleting
    try {
      // Send delete request to delete revision
      const res = privateAxios.delete(`/commercial/deleteCommercialRevision/${revisionId}`);
      console.log(res.data);
      handleBackButton();
    } catch (error) {
      showToast("error", "Error deleting Revision");
    }
  }

  // ================delete Revision================================


  // =================download revision==============================

  // Function to handle downloading revision
  const handleDownloadRevision = () => {

    // extracting TEXT from Richeditor string
    let tempElement = document.createElement('div');
    tempElement.innerHTML = revisionDescription;
    let extractedText = tempElement.textContent;
    extractedText = extractedText.trim();

    // taking nessarry feilds from attributes
    const questions = questionRows.map((attri) => {
      const modifiedAttri = { ...attri };
      delete modifiedAttri.id;
      if (revisionId !== 0) {
        delete modifiedAttri.revisedValue;
        delete modifiedAttri.vendorRemark;

      }
      return { ...modifiedAttri };

    });

    let revisionDocument = [];
    // Check if it's the default revision to handle documents differently
    if (revisionId === "0") {
      // Map documents to a different structure if it's the default revision
      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })

    }
    else {
      // Map documents normally 
      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })
    }

    // Create an Excel file with the extracted data.
    createExcel([[{ description: extractedText }], questions, revisionDocument], (revisionDetails.name || "Revision 0"))
  }


  // =================download revision==============================

  // ==============send to Kam=====================================
  const [comments, setComments] = useState("")
  const [formError, setFormError] = useState({

  })
  const [approvalModal, setApprovalModal] = useState(false);
  const handleApprovalModal = (data) => {
    setApprovalModal(prev => !prev)
  }

  // Function to handle sending a revision to KAM.
  const handleAppovalSubmit = async (data) => {
    openBackdrop();// Show backdrop during the operation.
    try {
      // Send the request to send the revision to KAM.
      const res = await privateAxios.post(`/commercial/sendCommercialVendorRevisionToKAMFromVendor/${revisionId}`, { comments: data.name });
      showToast("success", "revision Sent to Kam");// Show success toast notification.


    } catch (error) {
      showToast("error", "Error");// Show error toast notification.

    }
    handleApprovalModal() // Close the approval modal.
    closeBackdrop(); // Close the approval modal.


  }
  // ==============send to Kam=====================================

  // =====================chat notification========================


  const [reRender, setReRender] = useState(false);

  // Function to handle chat notifications.
  const handleChatNotification = () => {
    setReRender(prev => !prev);// Toggle re-render state.

  }


  // =====================chat notification========================

  // ======================Decision Handle=========================

  // Function to handle decision on a revision (approval/rejection).
  const handleDecisionOnRevision = async (isApproval) => {
    openBackdrop();// Show backdrop during the operation.
    try {

      if (isApproval) {
        // Send the request to approve the revision.
        const response = await privateAxios.post(`/technicalSpecification/approveRevisionByKam/${revisionId}`);
        if (response.status === 200) {
          alert("Revision Approved Successfully!");// Show success alert.
        }
      }
      else {
        // Send the request to reject the revision.
        const response = await privateAxios.post(`/technicalSpecification/rejectRevisionByKam/${revisionId}`);
        if (response.status === 200) {
          alert("Revision Rejected!");// Show success alert.
        }
      }

      getDataProcure(); // Fetch updated procurement data.
      closeBackdrop(); // Hide the backdrop.
    } catch (error) {
      console.error('Error fetching vendors:', error);
      closeBackdrop(); // Hide the backdrop.
    }
  }


  return (
    <>
      <BackdropComponent />
      <Toast />

      <div className={workSpaceStyle.workSpaceMain}>

        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1><BackButton />{revisionDetails?.name || "No Name"}</h1>
            <p>{revisionDetails?.productName || "Dafault"}</p>

          </div>

          <div className={workSpaceStyle.workSpaceHeaderRight}>

            {
              (viewType === "APVIEW" && auth.roles === "KAM") &&

              <div className={workSpaceStyle.workSpaceHeaderDecisionButton}>
                {
                  revisionDetails?.revisionStatus === "PENDING"
                    ?
                    <>
                      <button className={workSpaceStyle.workSpaceHeaderDecisionApprovalBtn} onClick={() => { handleDecisionOnRevision(true) }}><span><CheckIcon /></span> Approve</button>
                      <button className={workSpaceStyle.workSpaceHeaderDecisionRejectBtn} onClick={() => { handleDecisionOnRevision(false) }}><span><CloseIcon /></span>Reject</button>
                    </>
                    :
                    revisionDetails?.revisionStatus === "APPROVED" ?
                      <button className={workSpaceStyle.workSpaceHeaderDecisionApprovalBtn}><span><CheckIcon /> </span>Approved</button>

                      :
                      <button className={workSpaceStyle.workSpaceHeaderDecisionRejectBtn} ><span><CloseIcon /></span>Rejected</button>


                }
              </div>
            }


            {(tabActive === "2" && !isDefaultRevision) && <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative" }}>
              <button onClick={handleAddDocumentModal}><AddIcon />Add</button>


            </div>}
            <div className={workSpaceStyle.multiSaveButton}>
              <button onClick={handleMultiSave}>
                Save
                <span>
                  {multiSave
                    ? <KeyboardArrowUpIcon />
                    : <KeyboardArrowDownIcon />
                  }
                  {/*  */}
                </span> </button>

              {multiSave &&
                <ul>
                  {!revisionDetails?.default && <li onClick={() => { setMultiSave(false); handleSaveRevision(); }}>Update & Save</li>}
                </ul>
              }

            </div>

            {!revisionDetails?.default &&
              <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={handleChatSlider}>
                <Tooltip title='Comments'>
                  <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialRevision`} id={revisionId} reRender={reRender} />

                </Tooltip>
              </span>
            }
            <div className={workSpaceStyle.workSpaceThreeDotVertical} style={{ position: "relative" }}>

              <span className={workSpaceStyle.workSpaceHeaderRevisionMore}
                onClick={handleheaderThreeDot}
              >
                <Tooltip title='more'><MoreVertIcon /></Tooltip>

              </span>
              {
                headerThreeDot
                &&
                <ThreeDotDropDown toggle={handleheaderThreeDot}>
                  {!isDefaultRevision &&
                    <>
                      {
                        auth.roles === "VENDOR" ?
                          <li onClick={() => { handleheaderThreeDot(); handleApprovalModal(); }}>
                            <span><PersonAddAltOutlinedIcon /></span>Send for Approval</li>
                          :

                          <li onClick={() => { handleheaderThreeDot(); handleSendForApprovalModal(); }}>
                            <span><PersonAddAltOutlinedIcon /></span>Send For Approval</li>

                      }
                    </>
                  }

                  <li onClick={() => { handleheaderThreeDot(); handleDownloadRevision(); }}><span>
                    <FileDownloadOutlinedIcon />
                  </span>Download</li>
                </ThreeDotDropDown>

              }
            </div>
            <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
              <Tooltip title='Help'>
                <a target='_blank' href='https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081346-commercial-revision-details'>
                  <HelpOutlineOutlinedIcon />
                </a>
              </Tooltip>
            </div>
          </div>

        </div>

        <div className={revisionEditStyle.tabHeaderMain}>
          <div
            onClick={() => handleTabChange("0")}
            className={tabActive === "0" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Description</div>
          <div
            onClick={() => handleTabChange("1")}
            className={tabActive === "1" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Questions</div>
          <div
            onClick={() => handleTabChange("2")}
            className={tabActive === "2" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Documents</div>
        </div>

        <div className={revisionEditStyle.tabBodyMain} >
          {tabActive === "0" && <DescriptionTab
            viewType={"VENDOR"}
            onChange={setRevisionDescription} value={revisionDescription}

            descriptionRemark={descriptionVendorRemark}
            descriptionRemarkOnChange={(e) => setDescriptionVendorRemark(e.target.value)}
          />}
          {tabActive === "1" && <QuestionTab
            viewType={"VENDOR"}
            tableRows={questionRows}
            duplicateRow={duplicateError}
            setTableRows={setQuestionRows}
            showToast={showToast}
          />}
          {tabActive === "2" && <DocumentsTab

            isDefaultRevision={isDefaultRevision}
            viewType={"VENDOR"}
            showToast={showToast}
            addDocumentModal={addDocumentModal}
            handleAddDocumentModal={handleAddDocumentModal}
            getDataProcure={getDataProcure}
            documents={documents}
            revisionId={revisionId}
          />}

        </div>



      </div>


      <RenameModal
        open={renameModal}
        onClose={handleRenameModal}
        onSubmit={handleChangeTempleteName}
        prefilled={revisionDetails?.name}
        header="Rename"
      />
      <RenameModal
        open={saveAsModal}
        onClose={handleSaveAsModal}
        onSubmit={handleSaveAsRevision}
        prefilled={revisionDetails?.name}
        header="Save As"
      />
      <RenameModal
        open={duplicateNameModal}
        onClose={handleDuplicateNameModal}
        onSubmit={handleDuplicateRevisison}
        prefilled={revisionDetails?.name}
        header="Duplicate"
      />


      <ChatPannel
        open={chatSlider}
        onClose={handleChatSlider}
      >
        <>
          <ChatHeader onClose={handleChatSlider}
            downloadData={revisionChats.map((chat) => { return { name: chat.name, date: chat.createdAt, chat: chat.remark } })
            }
            helpData={{ link: "tosomewhere" }}
            chatName={revisionDetails?.name}
          >
            <h3>{revisionDetails?.name}</h3>
          </ChatHeader>

          <ChatRows>
            {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
              return (
                <ChatRow key={index} name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} email={rev.email} />
              )
            })
              :
              <p style={{ textAlign: "center" }}>
                No Previous Chat
              </p>
            }

          </ChatRows>

          <ChatInputField onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

        </>
      </ChatPannel>


      <DeleteConfirmation
        onCancel={handleDeleteModal}
        onConfirm={() => { handleDeleteRevision(); handleDeleteModal() }}
        open={deleteModal}
      >
        <h2>Delete Revision</h2>
        <p>Are you sure you want to delete this Revision?</p>

      </DeleteConfirmation>

      <SendForApprovalModal
        open={approvalModal}
        onClose={handleApprovalModal}
        onSubmit={handleAppovalSubmit}
        prefilled={""}
        header="Send For Approval"
      />


      <SendToApprovalCommityModal
        open={sendForApprovalModal} onClose={handleSendForApprovalModal}
        showToast={showToast}
        revisionId={revisionId}
        header={"Send for Approval"}
      />




    </>
  )
}

export default VendorCommercialTempleteRevisionView
