import React, { useState, useEffect } from 'react'
import taskCategoryStyle from '../TaskCategory/TaskCategory.module.css'
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Navigate, useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import ChatRow from 'components/ChatRows/ChatRow';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import useAuth from 'hooks/useAuth';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { PrivateComponent } from 'api/axios';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useBackdrop from "hooks/useBackdrop";
import useToast from 'hooks/useToast'
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import BackButton from 'components/BackButton/BackButton';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';
import SendForApprovalModal from 'components/SendForApprovalModal/SendForApprovalModal';
import RenameModal from 'components/RenameModal/RenameModal';

const ProductRevisons = () => {

    // Importing custom hooks and necessary libraries
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const { showToast, Toast } = useToast();
    const { auth } = useAuth();
    const location = useLocation();
    const { state } = location;
    const { taskId, productId } = useParams();
    const privateAxios = PrivateComponent(); // Assuming PrivateComponent is a custom hook or function

    // State variables initialization
    const navigate = useNavigate();
    const [approvalModal, setApprovalModal] = useState(false);
    const [renameModal, setRenameModal] = useState(false);
    const [comments, setComments] = useState("")
    const [renameText, setRenameText] = useState("")
    const [revisionId, setRevisionId] = useState("")

    const [duplicateNameModal, setDuplicateNameModal] = useState(false);
    const [revisionSearch, setRevisionSearch] = useState("");
    const [procureRevisions, setProcureRevisions] = useState([])
    const [moreDropdown, setMoreDropdown] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);
    // Revision api call to Ecom and Procurement


    // Function to fetch revisions from Procurement
    const getRevisionsProcure = async () => {
        openBackdrop(); // Open Loader

        try {
            await privateAxios.get(`/technicalSpecification/getAllRevisions/${productId}/${auth.orgId}/${taskId}`).then((res) => {
                if (res.data.length > 0) {
                    const data = res.data
                    setProcureRevisions(data); // Set data
                }
            })
        } catch (error) {
            showToast("error", "Error Getting Revisions")// Show error massege
        }
        closeBackdrop();// Close Loader
    }

    useEffect(() => {
        Promise.all([getRevisionsProcure()]).then((res) => {
            setPageLoading(false)
        })
            .catch((error) => {
                console.error('Error:', error);
            });

    }, [])


    // revsion chat

    const [chatSlider, setChatSlider] = useState(false)
    const [revData, setRevData] = useState("");
    const [chatValue, setChatValue] = useState("");
    const [revisionChats, setRevisionChats] = useState([]);

    // Function to handle opening/closing chat slider
    const handleChatSlider = (revData) => {
        setChatSlider(prev => !prev)
        setRevData(revData);
        handleChatNotification();
    }

    // useEffect hook to fetch revision chats when chat slider is opened
    useEffect(() => {
        if (chatSlider === true && revData !== null) {

            getRevisionChats(revData.revId)
        }

    }, [chatSlider, revData])

    // Function to handle sending chat messages
    const handleChatSend = async (e, revId) => {
        e.preventDefault()
        try {
            if (chatValue.length > 0) {

                await privateAxios.post(`/technicalSpecification/addRemarkOnRevisionByEmployee/${auth.userData.id}/${revId}`, { remark: chatValue }).then(res => {
                    getRevisionChats(revId);
                    setChatValue("")
                })
            }
        } catch (error) {

        }

    }

    // Function to fetch revision chats
    const getRevisionChats = async (revId) => {
        try {
            await privateAxios.get(`/technicalSpecification/getRemarksOfRevision/${revId}`).then(res => {
                setRevisionChats(res.data)
            })
        } catch (error) {
        }
    }

    // State variables and functions for handling approval modal
    const [approvalRevId, setApprovalRevId] = useState("")
    const handleApprovalModal = (data) => {
        setApprovalModal(prev => !prev)
        setApprovalRevId(data)
    }

    // Function to handle submitting approval
    const handleAppovalSubmit = async (data) => {
        openBackdrop()

        if (approvalRevId !== "") {
            try {
                await privateAxios.post(`/technicalSpecification/sendRevisionForApprovalToKAM/${auth.userData.id}/${approvalRevId}`, { comments: data.name }).then((res) => {
                    handleApprovalModal();
                    setComments("");

                    setApprovalRevId("")
                    getRevisionsProcure()

                }).catch((err) => {
                    setApprovalRevId("")
                })
                showToast("success", "Revision Sent to KAM")
                closeBackdrop()
            } catch (error) {
                showToast("error", "error Sending Revison to KAM")
            }
        }
    }

    // Function to navigate to a different page
    const handelNavigate = (data) => {
        navigate(`${data.id}?tab=0`, { state: { from: "PRVIEW" } })
    }

    // State variables and functions for handling rename modal
    const handleRenameModal = (data, name) => {

        if (data === 0) {
            setRevisionId("")
            alert("Revision 0 Name can't be Changed")
        }
        else {
            setRenameModal(prev => !prev)
            setRevisionId(data);
            setRenameText(name)
        }
    }

    // Function to toggle more dropdown
    const toggleMoreDrop = (index) => {

        if (moreDropdown === index) {
            setMoreDropdown(null);
        } else {
            setMoreDropdown(index);
        }
    };

    // Function to handle renaming a revision
    const handleRenameSubmit = async (data) => {
        openBackdrop();

        try {
            if (revisionId !== "");
            {
                await privateAxios.post(`/technicalSpecification/renameRevision/${revisionId}`, { name: data.name }).then((res) => {
                    // getRevisionsEcom();
                    getRevisionsProcure();
                    handleRenameModal();
                    setRenameText("")
                    setRevisionId("");
                })
            }

            showToast("success", "Revision Renamed")
            closeBackdrop();
        } catch (error) {
            closeBackdrop();
            showToast("error", "Error Renaming Revision")
        }

    }


    // Function to handle deleting a revision
    const handleDeleteTask = async (id) => {
        openBackdrop();
        try {
            if (id !== null) {

                await privateAxios.delete(`/technicalSpecification/deleteRevision/${id}`).then((res) => {
                    // getRevisionsEcom();
                    getRevisionsProcure();
                    showToast("success", "Revision Deleted")
                })
            }
            closeBackdrop();
        } catch (error) {
            showToast("error", "Error Deleting Revision")
            closeBackdrop();
        }
    }


    // State variables and functions for handling duplicate revision modal
    const [duplicateName, setDuplicateName] = useState("");
    const [duplicateId, setDuplicateId] = useState("");
    const handleDuplicateNameModal = (id, name) => {
        setDuplicateNameModal(prev => !prev);
        if (id !== undefined) {
            setDuplicateId(id)
            setDuplicateName(name)
        }
    }

    // Function to handle creating a duplicate revision
    const handleDuplicateRevisison = async (data) => {
        openBackdrop()
        try {

            await privateAxios.post(`/technicalSpecification/duplicateRevision/${duplicateId}?name=${data.name}`).then((res) => {
                // getRevisionsEcom();
                getRevisionsProcure();
                handleDuplicateNameModal("-1");
                setDuplicateName("");
                closeBackdrop();
                showToast("success", "Duplicate Created")
            })
        } catch (error) {
            closeBackdrop();
            showToast("error", "Error creating Duplicate Revision")
        }
    }

    const handleLastSeen = (loginTime) => {
        const loginDate = new Date(loginTime);
        const currentDate = new Date();
        const timeDifference = currentDate - loginDate;
        const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);
        const daysDifference = Math.floor(hoursDifference / 24);

        let displayString;

        if (daysDifference > 0) {
            // If the difference is more than 1 day, display the date
            displayString = `${loginDate.toDateString()}`;
        } else if (hoursDifference > 0) {
            // If the difference is more than 1 hour, display the hours
            displayString = `${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`;
        } else if (minutesDifference > 0) {
            // If the difference is more than 1 minute, display the minutes
            displayString = `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`;
        } else {
            // If the login was within the last minute, display "Just now"
            displayString = "Just now";
        }

        return displayString;
    }

    const [selectedStatus, setSelectedStatus] = useState(''); // State for selected status
    const [statusDropDown, setStatusDropDown] = useState("");// State for status dropdown

    // Function to handle status change
    const handleStatusChange = (newStatus) => {
        setSelectedStatus(newStatus);// Set selected status
        handleStatusSortingDropDown();// Call function to handle status sorting dropdown
    };

    // Function to handle status sorting dropdown
    const handleStatusSortingDropDown = () => {
        setStatusDropDown(prev => !prev);// Toggle status dropdown
    };

    // Function to handle status string
    const handleStatusString = (status) => {

        let statusStyle = {}// Object to store status styles
        switch (status) {// Switch based on status
            case "CREATED": statusStyle = { backgroundColor: "#caf0f8", color: "#023e8a" };
                break;
            case "PENDING": statusStyle = { backgroundColor: "#FFF0C9", color: "#EDBD15" };
                break;
            case "REJECTED": statusStyle = { backgroundColor: "#FFD7D7", color: "#EC5252" };
                break;
            case "APPROVED": statusStyle = { backgroundColor: "#DCFCE7", color: "#1CA35E" };
                break;
        }


        return (
            <div className={taskCategoryStyle.StatusPillMAin}>
                <span className={taskCategoryStyle.StatusPill} style={statusStyle}>{status?.charAt(0).toUpperCase() + status?.slice(1).toLowerCase()}</span>
            </div>
        )
    }

    // ----------------------------------------------------------------------

    // State variables for delete modal
    const [deleteTaskId, setDeleteTaskId] = useState(null);// State for delete task ID
    const [deleteModal, setDeleteModal] = useState(false);// State for delete modal visibility

    // Function to handle delete modal
    const handleDeleteModal = (taskId) => {
        setDeleteTaskId(taskId);// Set delete task ID
        setDeleteModal(prev => !prev);// Toggle delete modal
    }

    // =====================chat notification========================

    // State variable for chat notification
    const [reRender, setReRender] = useState(false);// State for chat notification

    // Function to handle chat notification
    const handleChatNotification = () => {
        setReRender(prev => !prev);// Toggle chat notification
    }



    return (
        <>
            <Toast />
            <BackdropComponent />

            <div className={workSpaceStyle.workSpaceMain}>

                {procureRevisions.length > 0
                    ?
                    <>
                        <div className={workSpaceStyle.workSpaceHeader}>
                            <div className={workSpaceStyle.workSpaceHeaderLeft}>
                                <h1> <BackButton /> {procureRevisions[0]?.name}</h1>
                                <p>Revisons <span>{procureRevisions.length}</span></p>

                            </div>
                            <div className={workSpaceStyle.workSpaceHeaderSearchbar}>
                                <input type="text" placeholder='Search Revisions...' value={revisionSearch} onChange={(e) => setRevisionSearch(e.target.value)} />
                            </div>
                            <div className={workSpaceStyle.workSpaceHeaderRight}>

                                <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
                                    <Tooltip title='Help'>
                                        <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081386-technical-revision-display">
                                            <HelpOutlineOutlinedIcon />
                                        </a>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>

                        <table className={taskCategoryStyle.taskCategoryTable}>
                            <thead>
                                <tr className={taskCategoryStyle.taskCategoryTableHeader}>
                                    <th>Title </th>
                                    <th>Last Modified </th>
                                    <th style={{ position: "relative" }}>
                                        <div className={taskCategoryStyle.headerSorter} onClick={handleStatusSortingDropDown}>
                                            <p> Status </p> <span>
                                                {statusDropDown
                                                    ? <KeyboardArrowUpIcon />
                                                    : <KeyboardArrowDownIcon />
                                                }</span>
                                        </div>
                                        {statusDropDown &&
                                            <>
                                                <div className={workSpaceStyle.popsBackDrop} onClick={handleStatusSortingDropDown}>

                                                </div>
                                                <ul className={taskCategoryStyle.headerSorterList} style={{ position: "absolute", top: "110%" }} >
                                                    <li onClick={() => handleStatusChange('')}>All</li>
                                                    <li onClick={() => handleStatusChange('Created')}>Created</li>
                                                    <li onClick={() => handleStatusChange('Pending')}>Pending</li>
                                                    <li onClick={() => handleStatusChange('Rejected')}>Rejected</li>
                                                    <li onClick={() => handleStatusChange('Approved')}>Approved</li>

                                                </ul>
                                            </>
                                        }
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            {procureRevisions.length === 0 ? <div><p>No Previous Revisions Saved</p></div> : procureRevisions
                                .filter(rows => rows?.name?.toLowerCase().includes(revisionSearch.toLowerCase()))

                                .map((data, index) => {

                                    return (
                                        <tr key={index} className={taskCategoryStyle.taskCategoryTableData}>
                                            <td style={{ cursor: "pointer" }} onClick={() => handelNavigate(data)}>{data.name}</td>
                                            {/* {console.log(data.lastModified)} */}
                                            <td>{handleLastSeen(data.lastModified)}</td>
                                            <td>{handleStatusString(data.revisionStatus)}</td>
                                            {/* <td>{allStatusData}</td> */}
                                            <td>
                                                <div className={taskCategoryStyle.actionTd}>
                                                    {
                                                        !data.default &&
                                                        <>

                                                            <span onClick={() => handleApprovalModal(data.id)}> <Tooltip title='Approval'><PersonAddAltOutlinedIcon /></Tooltip></span>

                                                            <span onClick={() => handleChatSlider({ revId: data.id, name: data.name })}>
                                                                <Tooltip title='Chats' >
                                                                    <ChatIconWithBadge url={`/technicalSpecification/getNotficationCount`} id={data.id} reRender={reRender} />
                                                                </Tooltip>
                                                            </span>
                                                        </>
                                                    }
                                                    <span onClick={() => handelNavigate(data)}> <Tooltip title='edit icon'><EditIcon /></Tooltip></span>
                                                    {
                                                        !data.default &&
                                                        <span style={{ position: "relative" }} onClick={() => toggleMoreDrop(index)} > <Tooltip title='More'><MoreVertIcon /></Tooltip>
                                                            {moreDropdown === index && (
                                                                <ThreeDotDropDown toggle={() => toggleMoreDrop(index)} >
                                                                    <li onClick={() => handleRenameModal(data.id, data.name)} > <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><DriveFileRenameOutlineIcon /> </span>Rename</li>
                                                                    <li onClick={() => handleDuplicateNameModal(data.id, data.name)}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><ContentCopyIcon /> </span>Duplicate</li>
                                                                    <li onClick={() => handleDeleteModal(data.id)}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><DeleteOutlineIcon /> </span>Delete</li>
                                                                </ThreeDotDropDown>
                                                            )}
                                                        </span>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                        </table>
                    </>
                    :
                    <>

                        {!pageLoading
                            &&
                            <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                <h2 style={{ color: "red" }}>Technical Specification Not Available!!</h2>
                                <h3 style={{ color: "red" }}>Contact Admin!!</h3>
                            </div>
                        }
                    </>
                }
            </div>

            {/* Render modals */}
            <SendForApprovalModal
                open={approvalModal}
                onClose={handleApprovalModal}
                onSubmit={handleAppovalSubmit}
                prefilled={""}
                header="Send For Approval"
            />


            <RenameModal
                open={renameModal}
                onClose={handleRenameModal}
                onSubmit={handleRenameSubmit}
                prefilled={renameText}
                header="Rename"
            />

            <RenameModal
                open={duplicateNameModal}
                onClose={handleDuplicateNameModal}
                onSubmit={handleDuplicateRevisison}
                prefilled={duplicateName}
                header="Duplicate"
            />

            {/* Render chat panel */}
            <ChatPannel
                open={chatSlider}
                onClose={handleChatSlider}
            >
                <>
                    <ChatHeader
                        onClose={handleChatSlider}
                        downloadData={revisionChats.map((chat) => {
                            return { name: chat.name, chat: chat.remark, date: chat.createdAt, };
                        })}
                        helpData={{ link: "tosomewhere" }}
                        chatName={revData.name}
                    >

                        <h3>{revData.name}</h3>
                    </ChatHeader>

                    {/* Render chat rows */}
                    <ChatRows>
                        {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
                            return (
                                <ChatRow name={rev.name} chat={rev.remark} date={rev.createdAt} email={rev.email} index={index} />
                            )
                        })
                            :
                            <p style={{ textAlign: "center" }}>
                                No Previous Chat
                            </p>
                        }

                    </ChatRows>

                    <ChatInputField revisionId={revData.revId} onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />
                </>
            </ChatPannel>

            {/* Render delete confirmation modal */}
            <DeleteConfirmation
                onCancel={handleDeleteModal}
                onConfirm={() => { handleDeleteTask(deleteTaskId); handleDeleteModal() }}
                open={deleteModal}
            >
                <h2>Delete Revision</h2>
                <p>Are you sure you want to delete this Revision?</p>

            </DeleteConfirmation>
        </>
    )
}

export default ProductRevisons




















