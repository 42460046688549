import React from 'react'
import workSpaceStyle from '../../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import revisionEditStyle from './VendorRevision.module.css'
import AddIcon from '@mui/icons-material/Add';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import { Navigate, useParams } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import formStyle from '../../../components/FormsCommonCss/FormsCommonCss.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { PrivateComponent } from 'api/axios'
import ChatIcon from '@mui/icons-material/Chat';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import VendorSpecification from './VendorSpecification';
import VendorAttribute from './VendorAttribute';
import VendorDocuments from './VendorDocuments';
import MultiSelect from 'components/MultiSelect/MultiSelect';

import { Badge } from '@mui/material';
import ProductRevisonsStyle from '../../ProductRevisions/ProductRevisions.module.css';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ChatRow from 'components/ChatRows/ChatRow';
import { createExcel } from 'utility/excelUtils';
import BackButton from 'components/BackButton/BackButton';


const VendorRevision = () => {
  const { revisionId } = useParams(); // Get revisionId from URL parameters
  const { auth } = useAuth(); // Get authentication information
  const privateAxios = PrivateComponent(); // Initialize axios instance for private routes
  const location = useLocation(); // Get location object from react-router
  const { state } = location; // Destructure state from location object

  const searchParams = new URLSearchParams(location.search); // Extract search parameters from URL
  const [approvalModal, setApprovalModal] = useState(false);// State to manage approval modal visibility
  const [comments, setComments] = useState("")// State to manage comments
  const [formError, setFormError] = useState({})// State to manage form errors
  const [tabActive, setTabActive] = useState(0 || searchParams.get("tab")); // State to manage active tab, defaults to 0 or URL parameter "tab"
  const [multiSave, setMultiSave] = useState(false);  // State to manage multi-save functionality
  const [multiAddDropDown, setMultiAddDropDown] = useState(false); // State to manage multi-add dropdown visibility
  const navigate = useNavigate();// Get navigate function from react-router
  const [productDetails, setProductDetails] = useState({});  // State to manage product details
  const [workSpaceHeaderRevisionMore, setworkSpaceHeaderRevisionMore] = useState(false);  // State to manage visibility of workspace header revision more options
  const [documents, setDocuments] = useState([]); // State to manage documents
  const [renameModal, setRenameModal] = useState(false);// State to manage rename modal visibility
  const [people, setPeople] = useState([]); // State to manage people list


  // -----------------------Attribute Data---------------------

  // Initial state for table columns
  const initialColumns = [
    " ", "Name", "Type", "Unit", "value", "Revised Value", "Remarks", ...Array.from({ length: 1 }, (_, i) => `Remark Vendor ${i}`), "Add"
  ];

  // State variables for columns and table rows
  const [columns, setColumns] = useState(initialColumns);
  const [tableRows, setTableRows] = useState([]);

  // Function to handle adding a new column
  const handleAddColumn = () => {
    // Find the index of the "Add" column
    const addColumnIndex = columns.indexOf("Add");
    // Calculate the number of existing "Remark Vendor" columns
    const newRemarkNumber = columns.filter(column => column.startsWith("Remark Vendor")).length;
    // Generate a new column name
    const newColumnName = `Remark Vendor ${newRemarkNumber}`;
    // Create updated columns array with the new column added

    const newColumns = [
      ...columns.slice(0, addColumnIndex),
      newColumnName,
      ...columns.slice(addColumnIndex, columns.length - 1),
      "Add"
    ];

    // Update table rows to include the new column
    const newTableRows = tableRows.map(row => ({
      ...row,
      remark: [
        ...row.remark,
        { value: "" }
      ]
    }));
    // Set the updated columns and table rows state
    setColumns(newColumns);
    setTableRows(newTableRows);
  };

  // Function to handle adding a new row
  const handleAddRow = () => {
    let maxRemarksCount = Math.max(...tableRows.map(row => row.remark.length));
    if (maxRemarksCount <= 0) {
      maxRemarksCount = 1;
    }

    // Create a new row object with empty values
    const newRow = {
      id: tableRows.length + 1,
      name: "",
      type: "",
      unit: "",
      value: "",
      remark: Array.from({ length: maxRemarksCount }, () => ({ value: "" })),
    };
    // Update table rows state with the new row
    setTableRows([...tableRows, newRow]);
  };

  // Function to handle deleting a row
  const handleRowDelete = (id) => {
    const updatedRows = tableRows.filter(row => row.id !== id);
    // Update table rows state after removing the row
    setTableRows(updatedRows);
  };



  // Function to fetch data from the server when the component mounts or when revisionId changes
  const handleGetData = async () => {
    try {
      const response = await privateAxios.get(`/technicalSpecification/getRevisionByRevisionId/${revisionId}`);
      const data = response.data;

      // Set product details state
      setProductDetails(data);
      const attributes = data?.productAttributes;
      const maxRemarksCount = Math.max(...attributes.map(row => row.remark.length));
      const addColumnIndex = columns.indexOf("Add");
      const newColumnNames = Array.from({ length: maxRemarksCount - 1 }, (_, i) => `Remark ${i + 1}`);

      const newColumns = [
        ...columns.slice(0, addColumnIndex),
        ...newColumnNames,
        ...columns.slice(addColumnIndex, columns.length - 1),
        "Add"
      ];

      // Update columns state
      setColumns(newColumns);
      // }

      // Add columns to attributes for rows without remarks
      const updatedAttributes = attributes.map(row => {
        if (!row.remark || row.remark.length === 0) {
          // If remarks are missing or empty, add new empty remarks
          const emptyRemarks = Array.from({ length: maxRemarksCount }, () => ({ value: "" }));
          return { ...row, remark: emptyRemarks };
        }
        return row;
      });

      // Update tableRows state with updated attributes
      setTableRows(updatedAttributes);

      setSpecificationValue(data.productSpecification?.name);

      // Update documents state
      setDocuments(data.productDocuments);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error (e.g., display an error message to the user)
    }
  };
  useEffect(() => {
    handleGetData();

  }, [revisionId])
  // ****************************************************************************************************************************
  useEffect(() => {
    setTabActive(searchParams.get("tab"));
  }, [Location, searchParams])

  const handleMultiSave = () => {
    setMultiSave(prev => !prev)
  }
  const handleRenameModal = () => {
    setRenameModal(prev => !prev)
  }
  const handleTabChange = (index) => {

    setTabActive(index)
    navigate(`?tab=${index}`)
  }


  // **************************************************Add Revision remark*************************************************************

  // Function to toggle chat slider state and fetch remark data if slider is opened
  const [chatSlider, setChatSlider] = useState(false)
  const [chatValue, setChatValue] = useState("");
  const [remarkData, setRemarkData] = useState([]);
  const handleChatSlider = () => {
    setChatSlider(prev => !prev)

  }

  // useEffect hook to fetch remark data when chatSlider state changes
  useEffect(() => {
    if (chatSlider === true) {

      getRemarkAllChat()
    }

  }, [chatSlider])

  // Function to handle sending chat message
  const handleChatSend = async (e) => {
    e.preventDefault()
    try {
      if (chatValue.length > 0) {
        const res = await privateAxios.post(`/technicalSpecification/addRemarkOnRevisionByVendor/${auth.userData.id}/${revisionId}`, { remark: chatValue }).then(res => {
          getRemarkAllChat()
          setChatValue("")
        })
      }
    } catch (error) {
      // console.log("Api error " + error)
    }
  }

  // Function to toggle approval modal state
  const handleApprovalModal = (data) => {
    setApprovalModal(prev => !prev)
  }

  // Function to handle approval form submission
  const handleAppovalSubmit = (e) => {
    e.preventDefault();
  }


  // ***********************************************Get Remark revision***************************************************

  // Function to fetch remark data
  const getRemarkAllChat = async () => {
    try {
      const res = await privateAxios.get(`/technicalSpecification/getRemarksOfRevisionOfVendorandKam/${revisionId}`).then(res => {
        setRemarkData(res.data)
      }).catch((err) => {
      })
    } catch (error) {
    }
  }


  // -----------------------Document Data----------------------

  // Function to toggle add document modal state
  const [addDocumentModal, setAddDocumentModal] = useState(false);
  const handleAddDocumentModal = () => {
    setAddDocumentModal(prev => !prev);
  }

  // -----------------------Specification Data------------------

  // Function to handle specification value
  const [specificationValue, setSpecificationValue] = useState('');
  const handelSpecification = () => {
  }

  // Function to handle saving revision data
  const handleSaveRevision = async () => {
    try {
      const updatedtableRow = tableRows
      const updatedProducts = productDetails;
      const updatedDocuments = documents

      const requestPayload = { ...updatedProducts, productSpecification: { name: specificationValue }, productAttributes: updatedtableRow, productDocuments: updatedDocuments }
      await privateAxios.post(`/technicalSpecification/updateRevision/${revisionId}`, requestPayload).then(res => {
        window.location.reload()
      }).catch((err) => {
        // console.log("error saving revision");
        alert("error saving revision")
      })
    } catch (error) {
      // console.log(error);
    }
  }

  // Function to handle document upload
  const handleDocumentUpload = async (documentName, documentFiles, docType, revisionId) => {
    try {
      const fd = new FormData();
      for (let i = 0; i < documentFiles.length; i++) {
        fd.append(`files`, documentFiles[i]);
      }
      fd.append("name", documentName)
      fd.append("docType", docType ? "CONFIDENTIAL" : "NORMAL")


      await privateAxios.post(`/technicalSpecification/uploadProductDocumentsOnVendorRevision/${revisionId}} `, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      }).then(async (res) => {
        await handleGetData();
        return true;
      }).catch((err) => {
        // console.log("error in uploading")
      })
    } catch (error) {
      // console.log(error)
    }
  }


  // Function to handle downloading revision data
  const handleDownloadRevision = () => {
    // extracting text from Richeditor string
    let tempElement = document.createElement('div');
    tempElement.innerHTML = specificationValue;
    let extractedText = tempElement.textContent;
    extractedText = extractedText.trim();

    // taking nessarry feilds from attributes
    const Attributes = tableRows.map((attri) => {
      const modifiedAttri = { ...attri };
      delete modifiedAttri.id;

      const remarks = {};
      attri.remark.forEach((rem, index) => {
        remarks[`Remark ${index}`] = rem.value;
      });
      delete modifiedAttri.remark;
      return { ...modifiedAttri, ...remarks };

    });

    let revisionDocument = []
    if (revisionId === "0") {

      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })

    }
    else {
      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })
    }

    createExcel([[{ Specification: extractedText }], Attributes, revisionDocument], (productDetails.name || "Revision 0"))
  }




  return (
    <>
      <div className={workSpaceStyle.workSpaceMain}>

        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1> <BackButton />{productDetails.name || "Revision 0"}</h1>
            <p>1 Product Name</p>

          </div>

          <div className={workSpaceStyle.workSpaceHeaderRight}>

            <div className={workSpaceStyle.workSpaceHeaderProfiles}>
              <ProfileIcon data={{ name: "Harsh Belose", email: "harsh@gece.net.in", type: "KAM", profileLink: "SomeLink" }} height={"26px"} width={"26px"} showProfileBanner={true} />
              <div>TT</div>
              {/* <div><AddIcon style={{color:'#fff'}}/></div> */}

            </div>
            {revisionId !== "0" && <div className={workSpaceStyle.workSpaceThreeDotVertical}>

              <span className={workSpaceStyle.workSpaceHeaderRevisionMore} >
                <Tooltip title='Comments'>
                  <Badge className={ProductRevisonsStyle.BadgeCSS} badgeContent={4} color="primary">
                    <ChatIcon onClick={() => { handleChatSlider(); getRemarkAllChat() }} />
                  </Badge>
                </Tooltip>
              </span>
            </div>
            }

            {tabActive === "1" && <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative" }}>
              <button onClick={() => { setMultiAddDropDown(prev => !prev) }}><AddIcon />Add</button>
              {multiAddDropDown &&
                <ul className={workSpaceStyle.workSpaceHeaderAddButtonDropDown}>
                  <li onClick={handleAddColumn}>Add Column</li>
                  <li onClick={handleAddRow}>Add Row</li>
                </ul>
              }

            </div>}
            {tabActive === "2" && <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative" }}>
              <button onClick={handleAddDocumentModal}><AddIcon />Add</button>


            </div>}
            <div className={workSpaceStyle.multiSaveButton}>
              <button onClick={handleMultiSave}>
                Save
                <span>
                  {multiSave
                    ? <KeyboardArrowUpIcon style={{ color: '#fff' }} />
                    : <KeyboardArrowDownIcon style={{ color: '#fff' }} />
                  }
                  {/*  */}
                </span> </button>

              {multiSave &&
                <ul>
                  {revisionId !== "0" && <li onClick={() => { handleSaveRevision(); setMultiSave(false); }}>Update & Save</li>}
                  {/* <li onClick={() => { setMultiSave(false); handleRenameModal(); setSaveAs(true) }}>Save as </li> */}
                </ul>
              }



            </div>

            <div className={workSpaceStyle.workSpaceThreeDotVertical}>

              <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={() => setworkSpaceHeaderRevisionMore(prev => !prev)} style={{ position: "relative" }}>
                <Tooltip title='more'><MoreVertIcon /></Tooltip>
                {
                  workSpaceHeaderRevisionMore &&
                  <ul>

                    {revisionId !== "0" && <li onClick={handleApprovalModal} style={{ display: 'flex', alignItems: 'center', fontSize: '14px', gap: '15px' }}><span ><PersonAddAltOutlinedIcon /> </span>Send for Approval</li>}
                    <li onClick={handleDownloadRevision} style={{ display: 'flex', alignItems: 'center', fontSize: '14px', gap: '15px' }}><span >
                      <FileDownloadOutlinedIcon />
                    </span>Download</li>
                  </ul>
                }
              </span>
            </div>
            <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
              <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
            </div>
          </div>

        </div>


        <div className={revisionEditStyle.tabHeaderMain}>
          <div
            onClick={() => handleTabChange("0")}
            className={tabActive === "0" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Specification</div>
          <div
            onClick={() => handleTabChange("1")}
            className={tabActive === "1" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Attributes</div>
          <div
            onClick={() => handleTabChange("2")}
            className={tabActive === "2" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Documents</div>
        </div>

        <div className={revisionEditStyle.tabBodyMain} >

          {tabActive === "0" && <VendorSpecification specificationValue={specificationValue} setSpecificationValue={setSpecificationValue} handelSpecification={handelSpecification} handleSaveRevision={handleSaveRevision} />}
          {tabActive === "1" && <VendorAttribute handleAddColumn={handleAddColumn} handleAddRow={handleAddRow} columns={columns} setColumns={setColumns} tableRows={tableRows} setTableRows={setTableRows} />}
          {tabActive === "2" && <VendorDocuments documents={documents} setDocuments={setDocuments} addDocumentModal={addDocumentModal} handleAddDocumentModal={handleAddDocumentModal} revisionId={revisionId} handleGetData={handleGetData} handleDocumentUpload={handleDocumentUpload} />}

        </div>

      </div>




      <Modal
        open={approvalModal}
        onClose={handleApprovalModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={handleAppovalSubmit} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex", alignItems: 'center', color: '#000', fontSize: '18px', fontWeight: '600', gap: '15px' }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><PersonAddAltOutlinedIcon /></span>Send For Approval</h3>
              <div onClick={handleApprovalModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>


            <div className={formStyle.inputRow}>
              <label htmlFor=""> Assign A Workspace:</label>
              <MultiSelect categories={people}
                setCategories={setPeople}
                availableDropDown={['some@gmail.com', 'some4@gmail.com', 'some2@gmail.com', 'some3@gmail.com']} />

              {/* <span>{formErrors.accessRole}</span>  */}
              <span>{formError.workSpace}</span>
            </div>
            <div className={formStyle.inputRow}>
              <label htmlFor=""> Comments if Any:</label>
              <textarea style={{ paddingTop: "10px", height: "120px" }} onChange={(e) => setComments(e.target.value)} value={comments}></textarea>
              {/* <span>{formErrors.accessRole}</span>  */}
              <span>{formError.workSpace}</span>
            </div>



            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "40px", fontSize: '15px' }} className={formStyle.modalSmallPrimaryButton} type='submit'>Invite </button>
              <p onClick={() => { setPeople([]); setComments(""); handleApprovalModal() }} style={{ cursor: "pointer", fontSize: '15px' }}>Discard</p>
            </div>

            <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span>
          </form>

        </div>
      </Modal>

      {/* <Modal
        open={renameModal}
        onClose={handleRenameModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" onSubmit={!saveAs ? handleChangeRevisionName : null} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><PersonAddAltOutlinedIcon /></span>Rename</h3>
              <div onClick={handleRenameModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>

              <input type="text" value={renameText} onChange={(e) => setRenameText(e.target.value)} placeholder='Revision Name' />
     
            </div>



            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              {
                saveAs
                  ?
                  <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='button' onClick={handleSaveRevison}>Save </button>
                  : <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>

              }
              <p onClick={() => { setRenameText(""); handleRenameModal() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

          </form>

        </div>
      </Modal> */}

      <ChatPannel
        open={chatSlider}
        onClose={handleChatSlider}
      >
        <>
          <ChatHeader onClose={handleChatSlider}
            downloadData={remarkData.map((chat) => {
              return { name: chat.name, date: chat.createdAt, chat: chat.remark };
            })}
            helpData={{ link: "tosomewhere" }}
            chatName={productDetails.name}
          >
            <h3>productDetails.name</h3>
          </ChatHeader>

          <ChatRows>

            {remarkData.map((data) => {

              return (

                <ChatRow name={data.name} chat={data.remark} date={data?.createdAt} />
                // {rowData?.lastModified ? rowData.lastModified.split('T')[0] : ''}
              )
            })

            }

          </ChatRows>

          <ChatInputField revisionId={"4"} onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

        </>
      </ChatPannel>





    </>
  )
}

export default VendorRevision
