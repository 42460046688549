import React, { useEffect, useRef, useState } from 'react'
import myaxios from 'api/axios'
import { Link, useNavigate } from 'react-router-dom'
import UserRegisterStyle from './UserRegister.module.css'
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import jwt_decode from 'jwt-decode';
import Alert from '@mui/material/Alert';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { passwordEncryptor } from '../../utility/passwordEncryptor.js'
import axios from 'api/axios'



const UserRegister = () => {

  // Ref for email input
  const emailRef = useRef();

  // Hook for navigation
  const navigate = useNavigate();

  // State for token
  const [token, setToken] = useState("");

  // State for password visibility toggle
  const [showPassword, setShowPassword] = useState(false);

  // State for decoded token claims
  const [claims, setClaims] = useState({});

  // State for form data
  const [data, setData] = useState({
    email: "",
    name: "",
    password: "",
  })

  // State for form errors
  const [formErrors, setFormErrors] = useState({
    email: '',
    name: '',
    password: '',
  });
  // State for error message
  const [errorMessage, setErrorMessage] = useState(null);

  // State for showing alert
  const [showAlert, setShowAlert] = useState(false);

  // State for blob URL
  const [blobUrl, setBlobUrl] = useState('');

  // useEffect to extract token from URL
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.size > 0) {
      let token = params.get('token')
      setToken(token);
      const decodedToken = jwt_decode(token);
      console.log(decodedToken)
      setClaims({ email: decodedToken.sub, orgId: decodedToken.orgId, time: decodedToken.exp });
      setData(prev => {
        return {
          ...prev,
          email: decodedToken.sub
        }
      })
    }
    else {
      navigate("/procureregister", { replace: true })
    }
    // Output: The value of the 'token' parameter from the current URL
  }, []);

  // Function to handle input field changes
  const handleInputField = (e) => {

    setData(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      }
    })

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: '',
    }));
  };

  // Function to validate form inputs
  const handleValidation = (e) => {
    const { name, value } = e.target;
    let errorMessage = '';

    if (name === 'email') {
      // if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
      //   errorMessage = 'Invalid email address';
      // }
      if (value.trim() === '') {
        errorMessage = 'email is required';
      }
    } else if (name === 'name') {
      if (value.trim() === '') {
        errorMessage = 'Name is required';
      }
    } else if (name === 'password') {
      if (value.length < 8) {
        errorMessage = 'Password must be at least 8 characters long';
      }
      // else if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8}$/)) {
      //   errorMessage = 'one lowercase & one uppercase letter, one digit, and be at least 6 characters long';
      // }
    }

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };


  // Function to handle user registration
  const handleVendorRegister = async (e) => {
    e.preventDefault();
    const hasErrors = Object.values(formErrors).some((error) => error !== '');
    const notEmpty = Object.values(data).some((dd) => dd.length < 1);

    if (!hasErrors && !notEmpty && token !== "") {
      const encPass = await passwordEncryptor(data.password, 'AesPassword12345');
      console.log(encPass)


      try {
        const res = await myaxios.post(`/adminuser/registerUser/${token}`, {
          name: data.name,
          password: encPass,
          email: data.email,
        });

        if (res.status === 200) {
          navigate(`/redirectlogin?token=${res.data.token}`, { replace: true });
        }
      } catch (err) {
        if (err.response && err.response.data) {
          setErrorMessage(err.response.data.message);
        } else {
          setErrorMessage('An error occurred. Please try again later.');
        }
      }

    }
  };


  // useEffect to handle error message display
  useEffect(() => {
    if (errorMessage) {
      setShowAlert(true);

      // Automatically hide the alert after 3 seconds
      const timer = setTimeout(() => {
        setShowAlert(false);
        setErrorMessage(null);
      }, 3000);

      return () => {
        clearTimeout(timer); // Clear the timer if the component unmounts
      };
    }
  }, [errorMessage]);


  // useEffect to fetch organization logo
  useEffect(() => {
    axios.get(`/adminuser/logo/${claims.orgId}`, { responseType: 'blob' })
      .then(response => {
        const url = URL.createObjectURL(response.data);
        setBlobUrl(url);
      })
      .catch(error => {
        console.error('Error fetching image:', error);
      });

    // Cleanup the blob URL when the component unmounts
    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [claims]);


  // JSX structure for UserRegister component
  return (
    <>
      {showAlert && (
        <div
          style={{
            width: '26%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '13%',
            left: '37%',
          }}>
          <Alert severity="error">{errorMessage}</Alert>
        </div>
      )}
      <div className={UserRegisterStyle.mainPage}>
        <div className={UserRegisterStyle.container}>
          <div className={UserRegisterStyle.companyLogo}>
            {blobUrl !== '' && <img
              src={blobUrl} alt="" />}
          </div>
          <div className={UserRegisterStyle.welcome}>
            <h3>Sign up to continue</h3>
          </div>

          <form action='/' onSubmit={handleVendorRegister} className={formStyle.inputForm}>
            <div className={formStyle.inputRow}>
              <label>Email</label>
              <input
                ref={emailRef}
                type="email"
                placeholder="Enter your email"
                autoComplete="off"
                name='email'
                value={data.email}
                disabled
                onChange={handleInputField}
                onBlur={handleValidation}

              />
              <span>{formErrors.email}</span>

            </div>
            <div className={formStyle.inputRow}>
              <label>Name</label>
              <input
                type="text"
                placeholder="Enter your name"
                autoComplete="off"
                name='name'
                value={data.name}
                onChange={handleInputField}
                onBlur={handleValidation}

              />
              <span>{formErrors.name}</span>
            </div>
            <div className={formStyle.inputRow}>
              <label>Password</label>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter Your Password"
                autoComplete="off"
                name='password'
                value={data.password}
                onChange={handleInputField}
                onBlur={handleValidation}

              />
              <div className={UserRegisterStyle.VisibleShowPAss}>
                {showPassword ? (
                  <VisibilityOff onClick={() => setShowPassword(false)} />
                ) : (
                  <Visibility onClick={() => setShowPassword(true)} />
                )}
              </div>
              <span>{formErrors.password}</span>
            </div>


            <div className={formStyle.formBottomLine}>
              <p>By continuing you agree to the KJSS <Link to='https://www.kjssteel.com/terms' target='_Blank'>terms of service</Link> and <Link to='https://www.kjssteel.com/privacy' target='_Blank'>privacy policy.</Link></p>
            </div>

            <button type="submit" className={formStyle.formPrimarySubmit}>
              Register
            </button>

          </form>

        </div>
      </div>
    </>
  )
}

export default UserRegister

// The User Register component manages user registration, including form validation, password encryption, and error handling.
//  It utilizes state hooks to track form data and errors, and useEffect hooks for side effects like fetching organization logos. 
//  The component offers a streamlined user registration process with interactive password visibility toggling and alerts for error feedback.