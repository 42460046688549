import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import axios from 'axios';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import superadminStyle from '../../commonCss.module.css';
import ProductSelector from '../FranchiseAppoval/ProductSelector';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@mui/material';
import orgAppStyle from './FranchiseApproved.module.css';
import CloseIcon from '@mui/icons-material/Close';
import ProductsTab from './ProductsTab';
import CommercialTab from './CommercialTab';
import FranchiseIcon from '../../../../assets/images/Franchise icon 1.png'
import SuperAdminTaskPannel from 'pages/SuperAdmin/Franchise/FranchiseTask/SuperAdminCreateTask/SuperAdminTaskPannel/SuperAdminTaskPannel';
import FranchiseTask from '../FranchiseTask/FranchiseTask';
import GroupSorterData from 'utility/GroupSorterData.js';
import commercialListStyle from '../../Commercial/CommercialList/CommercialList.module.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';


const FranchiseApproved = () => {
    // Extracting parameters from URL
    const { kamId, orgId, } = useParams();

    // State variables initialization
    const [ecomSpecId, setEcomSpecId] = useState("");
    const [catId, setCatId] = useState("");
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const { showToast, Toast } = useToast();
    const privateAxios = PrivateComponent();
    const navigate = useNavigate();
    const [selectedItems, setSelectedItems] = useState([]);
    const [franchiseData, setFranchiseData] = useState({});
    const [createTaskModal, setCreateTaskModal] = useState(false);

    // Function to handle create task modal
    const handlecreateTaskModal = () => {
        setCreateTaskModal(prev => !prev)
    }
    const [addTemplates, setAddTemplates] = useState(false)

    // Function to handle add templates modal
    const handleAddtemplatesModal = () => {
        setAddTemplates(prev => !prev)
    }

    // Function to handle checkbox change
    const handleCheckboxChange = (product) => {
        const allreadySelected = selectedItems.some((item) => item.id === product.id);
        if (allreadySelected) {
            setSelectedItems(selectedItems.filter((item) => item.id !== product.id))
        }
        else {
            setSelectedItems([...selectedItems, product])
        }
    }

    // Function to handle click event
    const handleClick = () => {
        alert("Clicked")
    }

    // =======================org details=============================

    // Function to fetch franchise data
    const getFranchiseData = async () => {
        openBackdrop();
        try {
            const res = await privateAxios.get(`/franchise/getFranchise/${orgId}`);
            const { data } = res;
            setFranchiseData(data);
        } catch (error) {
            showToast('error', 'Error Getting Franchise Data');
        }
        closeBackdrop();
    };

    useEffect(() => {
        getFranchiseData();
    }, [orgId]);

    // ====================flat tree========================


    // Function to flatten categories
    const flattenCategories = (categories, result = []) => {
        categories.forEach(category => {
            result.push(category);
            if (category.branch && category.branch.length > 0) {
                flattenCategories(category.branch, result);
            }
            delete category.branch;
        });
        return result;
    }
    // ====================tree builder========================

    // Function to build tree structure
    const buildTree = (categories) => {
        let tree = [];
        let lookup = {};

        categories.forEach(category => {
            category.branch = [];
            lookup[category.categoryId] = category;
        });

        categories.forEach(category => {
            if (category.parent !== null) {
                const parent = lookup[category.parent];
                parent.branch.push(category);
            } else {
                tree.push(category);
            }
        });

        return tree;
    }

    // State variable for sorting
    const [sorterOpen, setSorterOpen] = useState("");

    // Function to toggle sorter
    const toggleSorter = (type) => {
        if (sorterOpen === type) {
            setSorterOpen("");
        }
        else {
            setSorterOpen(type);
        }
    }
    // Function to handle sorting
    const handleSorting = (type) => {
        console.log(type);
    }

    // ====================tree builder========================

    // State variables for product and categories
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [categoriesData, setCategoriesData] = useState([]);

    //  ====================get category path==========================

    // Function to get category path
    const getCategoryPath = (categoryId) => {

        const category = selectedCategories.find((category) => category.categoryId === categoryId);
        if (category) {
            return category.parent ? `${getCategoryPath(category.parent)} > ${category.categoryName}` : category.categoryName;
        }
        return '';
    }
    //  ====================get category path==========================

    // State variable for middleware modal
    const [addMiddleWare, setAddMiddleWare] = useState(false);
    const handleAddMiddleWareModal = () => {
        setAddMiddleWare(prev => !prev);

    }
    // ====================add product modal========================

    // State variable for add product modal
    const [addProduct, setAddProduct] = useState(false);

    // Function to handle add product modal
    const handleAddProductModal = (id, catId) => {
        setAddProduct(prev => !prev);
        if (id !== undefined && id !== null) {
            setEcomSpecId(id);
            setCatId(catId);
            handleAddMiddleWareModal();
        }
    }

    // State variable for add product modal in TS
    const [addProductTS, setAddProductTS] = useState(false);
    const handleAddProductModalTS = () => {
        setAddProductTS(prev => !prev);
    }

    // ====================handle add product success modal================

    // Function to show add product success modal
    const showAddProductSuccess = (type) => {
        handleAddProductModal();

        if (type) {
            showToast("success", "Product Added Successfully")
        }
        else {
            showToast("error", "Error Adding Product")
        }
    }


    // ====================remove product========================

    // Function to remove product
    const handleRemoveProduct = (productId) => () => {
        setSelectedItems(prev => {
            return prev.filter((item) => item.productId !== productId);
        });

        setSelectedProduct(prev => {
            return prev.filter((item) => item.productId !== productId)
        });
    }


    // State variables for selecting all and products data
    const [selectAll, setSelectAll] = useState(false);
    const [productsData, setProductsData] = useState([]);

    // Function to get product data
    const getProductData = async () => {
        openBackdrop();
        try {
            const catRes = await privateAxios.get(`/procureadmin/getApprovedProductsByOrganisation/${orgId}`)
            const catData = catRes.data.data.categories;
            setSelectAll(catRes);
            setProductsData(catRes.data.data.products)
        } catch (error) {
        }
        closeBackdrop();
    }
    useEffect(() => {
        getProductData();
    }, [])

    // ====================Match Category with product============================

    // Function to find all upper level category
    const findAllUpperLevelCategory = (categoryId) => {
        const category = selectedCategories.find((category) => category.categoryId === categoryId);
        if (category) {
            return category.parent ? [category, ...findAllUpperLevelCategory(category.parent)] : [category];
        }
        return [];
    }

    // Function to match category with product
    const matchCategoryWithProduct = () => {
        let matchingCategory = [];
        if (selectedItems.length === 0) {
            showToast("error", "Please Select Atlest One Product");
        }
        else {
            selectedItems.forEach((product) => {

                const category = selectedCategories.find((category) => category.categoryId === product.categoryId);
                if (category) {
                    const categoryArray = findAllUpperLevelCategory(category.categoryId);

                    if (matchingCategory.length === 0) {
                        matchingCategory = [...categoryArray];
                    }
                    else {

                        let updatedCategories = [...matchingCategory]
                        categoryArray.forEach((category) => {
                            if (!matchingCategory.some((item) => item.categoryId === category.categoryId)) {
                                updatedCategories = [...updatedCategories, category];
                            }

                        }
                        );

                        matchingCategory = updatedCategories;
                    }
                }
            })
        }
    }



    const handleOrgCheckBox = async (type) => {// Function to handle organization checkbox
        openBackdrop();// Open backdrop for loading
        try {
            let url = "";// Declare url variable
            if (!franchiseData.isDisable) { // Check if franchise data is not disabled
                url = `/procureadmin/DisableOrganisation`; // Set URL for disabling organization
            }
            else {
                url = `/procureadmin/enableOrganisation`; // Set URL for enabling organization
            }
            const res = await privateAxios.post(`${url}/${orgId}/${kamId}`); // Send post request with organization and KAM ID
            if (res.status === 200) {// If response status is 200
                if (franchiseData.isDisable) {// If franchise data is disabled
                    showToast("success", "Franchise Enabled Successfully");// Show success toast for enabling franchise
                }
                else {
                    showToast("success", "Franchise Disabled Successfully");
                }
            }
            getFranchiseData();// Get franchise data

        } catch (error) {
            showToast("error", error.message); // Show error toast
        }
        closeBackdrop(); // Close backdrop

    }

    // ==================tab selector=====================================

    const location = useLocation(); // Get current location
    const searchParams = new URLSearchParams(location.search); // Get search parameters from location
    const [tabActive, setTabActive] = useState("0"); // Set initial tab active state

    useEffect(() => {// Effect to update tab active state
        if (searchParams.get("tab") !== null && searchParams.get("tab") !== undefined && searchParams.get("tab") !== "") {
            setTabActive(searchParams.get("tab"));// If tab parameter exists in search, set tab active state
        }
        else {
            setTabActive("0"); // Set default tab active state
        }
    }, [Location, searchParams])// Dependencies for effect

    const handleTabChange = (index) => {// Function to handle tab change
        setTabActive(index)// Set tab active state
        navigate(`?tab=${index}`, { replace: true })// Navigate with new tab index
    }

    // ==================tab selector=====================================

    const handleAddProduct = async (data) => {// Function to handle adding product
        openBackdrop();// Open backdrop for loading
        try {
            const { products, categories } = data;// Destructure products and categories from data
            const catRes = await privateAxios.post(`/procureadmin/addApprovedCategories/${orgId}`, categories);// Add approved categories
            if (catRes.status === 200) {
                const prodRes = await privateAxios.post(`procureadmin/addApprovedProducts/${orgId}`, products); // Add approved products
                if (prodRes.status === 200) {// If product addition is successful
                    showToast("success", "product Added Successfully"); // Show success toast for product addition
                }
                else {
                    showToast("error", "Error Adding Product");// Show error toast for product addition
                }
            }
            else {
                showToast("error", "Error Adding Category");// Show error toast for category addition
            }
            getProductData(); // Get product data
            handleAddProductModalTS();// Handle add product modal transition state
        }
        catch (error) {
            showToast("error", error.message);// Show error toast
        }
        closeBackdrop(); // Close backdrop
    }

    // =====================handle Redirection======================

    // State for search value
    const [searchValue, setSearchValue] = useState('');

    // Function to handle redirection
    const handleRedirection = async () => {
        openBackdrop();// Open backdrop for loading
        try {
            // Check if franchise data's kamEmail exists
            if (franchiseData.kamEmail !== undefined && franchiseData.kamEmail !== null && franchiseData.kamEmail !== "") {
                // Generate token for franchise
                const res = await privateAxios.post(`/procurement/generateTokenForFranchise/${franchiseData.kamEmail}`);
                const { data } = res; // Destructure data from response
                // Redirect to login page with token and redirection type
                navigate(`/redirectlogin?token=${data.token}&redirectionType=FRANCHISE`, { replace: true });
            }
        }
        catch (error) {
            showToast("error", "Error Redirecting");// Show error toast
        }
        closeBackdrop(); // Close backdrop
    }


    // ==================fetch Ecom Templates=====================

    // State for e-commerce templates
    const [ecomTemplates, setEcomTemplates] = useState([]);

    // Function to get e-commerce templates
    const getEcomTemplates = async () => {
        openBackdrop();// Open backdrop for loading
        try {
            // Fetch e-commerce templates based on category ID
            const res = await privateAxios.get(`/procureadmin/getAllImportedTemplates/${catId}`)
            setEcomTemplates(res.data);// Set fetched templates to state
        } catch (error) {
            showToast("error", error.message)// Show error toast
        }
        closeBackdrop();// Close backdrop

    }

    useEffect(() => {
        if (addProduct) {
            getEcomTemplates(); // Call getEcomTemplates when addProduct changes
        }
    }, [addProduct])

    // ==================fetch Ecom Templates=====================

    // ==================handle template checkbox=====================

    // State for selected template
    const [selectedTemplate, setSelectedTemplate] = useState([]);

    // Function to handle template checkbox
    const handleTemplateCheckBox = (temp) => {
        if (selectedTemplate.some((rt) => rt.id === temp.id)) {
            setSelectedTemplate(prev => prev.filter((rt) => rt.id !== temp.id))
        }
        else {
            setSelectedTemplate(prev => [...prev, temp]);
        }
    }

    // ==================handle import templae=============================

    // Function to fetch template data for e-commerce
    const getTemplateDataEcom = async (id) => {
        try {
            const res = await axios.get(`https://ecom.kjssteel.com/api/GetCommercialData/${id}`)
            return res.data;
        } catch (error) {
            console.log("id", error)
        }
    }


    // Function to handle adding template
    const handleTemplateAdd = async () => {
        openBackdrop(); // Open backdrop for loading
        try {
            // Prepare payload for requesting template in organization template
            const payload = {
                ids: selectedTemplate.map((temp) => temp.id),
                orgId: orgId,
            }
            // Request template in organization template
            const res = await privateAxios.post(`/procureadmin/requestTemplateInOrganisationTemplate`, payload)
            showToast("success", "Template Requested Successfully"); // Show success toast

        } catch (error) {
            console.log(error)// Log error to console
            showToast("error", "Error Requesting Template");
        }

        closeBackdrop(); // Close backdrop
        setSelectedTemplate([]); // Reset selected template
        handleAddProductModal(); // Handle add product modal
        handleProductCheckBox(); // Handle product checkbox
    }

    // State for data
    const [data, setData] = useState([]);

    // State for sorter data
    const [sorterData, setSorterData] = useState({});

    // Function to get data
    const getData = async () => {
        openBackdrop();// Open backdrop for loading
        try {

            const res = await privateAxios.get('/procureadmin/getAllSpecificTemplates');// Fetch specific templates
            const templates = res.data; // Store fetched templates
            setData(res.data)// Set fetched data to state
            let specificationId; // Variable to store specification ID
            for (const template of templates) { // Loop through templates
                if (template.specificationId) { // Check if specification ID exists
                    specificationId = template.specificationId; // Set specification ID
                    break;
                }
            }
            setSorterData(GroupSorterData(res.data));// Set sorted data
        } catch (error) {
            showToast("error", error.message);// Show error toast
        }
        closeBackdrop();// Close backdrop
    }

    useEffect(() => {
        getData(); // Call getData on component mount
    }, [])

    // State for commercial response
    const [commercialRes, setCommercialRes] = useState(null);

    // Function to handle product checkbox 
    const handleProductCheckBox = async () => {
        openBackdrop();// Open backdrop for loading
        try {
            const response = await privateAxios.get(`/procureadmin/getAllFranchiseRelatedAllTemplates/${orgId}`);
            setCommercialRes(response.data);// Set fetched data to state
        } catch (error) {
            showToast('error', 'Error getting Product');// Show error toast
        }
        closeBackdrop();// Close backdrop
    };



    // ========================= due date modal=========================

    // State for due date
    const [dueDate, setDueDate] = useState("");
    // State for due date error
    const [dueDateError, setDueDateError] = useState("");
    // State for due date modal
    const [dueDateModal, setDueDateModal] = useState(false);


    // Function to toggle due date modal
    const handleChangeDueDateModal = () => {
        setDueDateModal(prev => !prev);
    }

    // Function to handle changing due date
    const handleChangeDueDate = async () => {
        openBackdrop(); // Open backdrop for loading
        try {
            if (dueDate === "") { // Check if due date is empty
                setDueDateError("Please Select Date"); // Set due date error
                closeBackdrop(); // Close backdrop
                return;
            }

            const differenceInMilliseconds = new Date(dueDate) - new Date(franchiseData.dueDate.split("T")[0]); // Calculate difference in milliseconds
            const tempDays = differenceInMilliseconds / (1000 * 60 * 60 * 24); // Convert milliseconds to days
            const res = await privateAxios.post(`/subscription/renewSubcription/${orgId}`, // Send request to renew subscription
                {
                    type: "Ultra", // Subscription type
                    price: "$90", // Subscription price
                    days: tempDays // Number of days
                }
            );
            if (res.status === 200) { // If status is 200
                showToast("success", "Due Date Changed Successfully"); // Show success toast
            }
            else {
                showToast("error", "Error Changing Due Date"); // Show error toast
            }
            getFranchiseData(); // Refresh franchise data
            handleChangeDueDateModal(); // Toggle due date modal
        } catch (error) {
            showToast("error", error.message);// Show error toast
        }
        closeBackdrop(); // Close backdrop
    }




    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain} style={{ padding: "20px" }}>

                <div className={orgAppStyle.header}>
                    <h2 style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>

                        {franchiseData.organisationName}

                        <span style={{ display: 'flex', alignItems: 'center' }} onClick={handleRedirection}> <img src={FranchiseIcon}

                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} alt="" /> </span>

                    </h2>
                    <ToggleSwitch checked={!franchiseData.isDisable} name={`checkBox_org`} onChange={() => handleOrgCheckBox()} />
                </div>

                <div className={orgAppStyle.orgDetailsCont}>
                    <div className={orgAppStyle.orgDeailsWrapper}>
                        <div className={orgAppStyle.OrgRow}>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    Organisation Name
                                </p>
                                <h5>{franchiseData.organisationName}</h5>
                            </div>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    Kam Phone No.
                                </p>
                                <h5>{franchiseData.phone}</h5>
                            </div>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    Due Date
                                </p>
                                <h5>{franchiseData?.dueDate?.split("T")[0]} <span onClick={handleChangeDueDateModal}>
                                    <EditCalendarIcon />
                                </span>
                                </h5>
                            </div>
                        </div>
                        <div className={orgAppStyle.OrgRow}>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    KAM Email ID
                                </p>
                                <h5>{franchiseData.kamEmail}</h5>
                            </div>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    GST / VAT Deatils
                                </p>
                                <h5>{franchiseData.gst}</h5>
                            </div>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    User Added
                                </p>
                                <h5>{franchiseData.totalUsers}</h5>
                            </div>
                        </div>
                        <div className={orgAppStyle.OrgRow}>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    Managed by
                                </p>
                                <h5>{franchiseData.kamName}</h5>
                            </div>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    Registered On
                                </p>
                                <h5>{franchiseData?.registeredOn?.split("T")[0]}</h5>
                            </div>
                            <div className={orgAppStyle.OrgCol}>
                                <p>
                                    Pricing Plan
                                </p>
                                <h5>{franchiseData.pricingPlan}</h5>
                            </div>
                        </div>


                    </div>
                </div>

                <div className={orgAppStyle.tabCont}>
                    <div className={orgAppStyle.tabSelectorWrapper}>
                        <div onClick={() => handleTabChange("0")}
                            className={tabActive === "0" ? `${orgAppStyle.tabItem} ${orgAppStyle.active}` : `${orgAppStyle.tabItem}`}>
                            Products
                        </div>
                        <div onClick={() => handleTabChange("1")}
                            className={tabActive === "1" ? `${orgAppStyle.tabItem} ${orgAppStyle.active}` : `${orgAppStyle.tabItem}`}>
                            Commercial
                        </div>
                        <div onClick={() => handleTabChange("2")}
                            className={tabActive === "2" ? `${orgAppStyle.tabItem} ${orgAppStyle.active}` : `${orgAppStyle.tabItem}`}>
                            Tasks
                        </div>

                        <div className={orgAppStyle.tabButtonTabNew}>
                            {
                                tabActive === "0" && <button
                                    onClick={handleAddProductModalTS}>Add Products</button>
                            }

                            {
                                tabActive === "1" && <button
                                    // onClick={() => { handleAddProductModal() }}
                                    onClick={() => { handleAddMiddleWareModal() }}
                                >Add Templates</button>
                            }

                            {
                                tabActive === "2" && <button onClick={handlecreateTaskModal}>Assign Task</button>
                            }

                        </div>
                    </div>
                    <div className={orgAppStyle.tabWrapper}>
                        {
                            tabActive === "0" && <ProductsTab
                                openBackdrop={openBackdrop}
                                closeBackdrop={closeBackdrop}
                                showToast={showToast}
                                orgId={orgId}
                                categoriesData={categoriesData}
                                getCategoryPath={getCategoryPath}
                                productsData={productsData}
                                getProductData={getProductData}
                            />
                        }
                        {
                            tabActive === "1" && <CommercialTab openBackdrop={openBackdrop} closeBackdrop={closeBackdrop} showToast={showToast}
                                orgId={orgId}
                                handleProductCheckBox={handleProductCheckBox}
                                commercialRes={commercialRes}
                            />

                        }
                        {
                            tabActive === "2" && <FranchiseTask openBackdrop={openBackdrop} closeBackdrop={closeBackdrop} showToast={showToast} />
                        }
                    </div>

                </div>

            </div>
            <Modal
                open={createTaskModal}
                onClose={handlecreateTaskModal}>

                <SuperAdminTaskPannel handlecreateTaskModal={handlecreateTaskModal} createTaskModal={createTaskModal} franchiseData={franchiseData} />

            </Modal>

            <Modal
                open={addProduct}
            // onClose={handleAddProductModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>


                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Import Template</h2>
                            <span onClick={handleAddProductModal}><CloseIcon /></span>
                        </div>

                        <div className={commercialListStyle.templatesWrapper}>
                            {
                                ecomTemplates.map((temp, index) => {
                                    return (
                                        <div className={commercialListStyle.templateCard}>
                                            <p>{temp.name}</p>
                                            <input type="checkbox" checked={selectedTemplate.some((rt) => rt.id === temp.id)} onChange={() => handleTemplateCheckBox(temp)} />
                                        </div>
                                    )
                                })
                            }


                        </div>
                        <div className={workSpaceStyle.d_flex_start} style={{ gap: "10px" }}>
                            <button className={`${workSpaceStyle.modalBtn}`} style={{ backgroundColor: "#16469D", color: "white" }} onClick={handleTemplateAdd}>Add Template</button>
                            <button className={`${workSpaceStyle.modalBtn}`} style={{ color: "#16469D" }} onClick={handleAddProductModal}> Discard</button>

                        </div>


                    </div>



                </div>


            </Modal>

            {/* Commercial middleware Modal   */}

            <Modal
                open={addMiddleWare}
            // onClose={handleAddMiddleWareModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>
                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "felx-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}> Template Category</h2>
                            <span onClick={handleAddMiddleWareModal}><CloseIcon /></span>
                        </div>





                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            {

                                data.map((row, index) => {
                                    return (

                                        <div onClick={() => handleAddProductModal(row.specificationId, row.id)} className=''
                                            style={{
                                                cursor: "pointer", height: "40px", display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between", alignItems: "center",
                                                padding: "0 10px",
                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                            }}>


                                            <h3 style={{ fontWeight: "400" }}>

                                                {row.name}
                                            </h3>
                                            <div className={superadminStyle.actionIcons} style={{ width: "auto" }}>
                                                <span >

                                                    <ArrowForwardIosIcon />
                                                </span>

                                            </div>

                                        </div>
                                    )

                                }
                                )
                            }




                        </div>

                    </div>
                </div>


            </Modal>


            {/* Product Add Modal Open  */}
            <Modal
                open={addProductTS}
            // onClose={handleAddProductModalTS}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>
                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "felx-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Add Product</h2>
                            <span onClick={handleAddProductModalTS}><CloseIcon /></span>
                        </div>

                        <ProductSelector
                            productsArray={selectedProduct}
                            handleAddProductModal={handleAddProductModal}
                            showAddProductSuccess={showAddProductSuccess}
                            setProductsArray={setSelectedProduct}
                            setAllCategories={setSelectedCategories}
                            onsubmit={handleAddProduct}
                            orgId={orgId}
                            handleAddProductModalTS={handleAddProductModalTS}
                        />
                    </div>
                </div>
            </Modal>



            <Modal
                open={dueDateModal}
                onClose={handleChangeDueDateModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>


                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "felx-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Change Account Expiry Date</h2>
                            <span onClick={handleChangeDueDateModal}><CloseIcon /></span>
                        </div>

                        <div >
                            <div className={formStyle.inputRow}>
                                <label htmlFor="">Expriry Date</label>
                                <input type="date" onChange={(e) => setDueDate(e.target.value)}
                                    min={franchiseData?.dueDate?.split("T")[0]}
                                    value={dueDate} style={{ padding: "0 10px", textIndent: "0px" }} onBlur={(e) => {
                                        if (e.target.value === "") {
                                            setDueDateError("Please Select Date");
                                        }
                                        else {
                                            setDueDateError("");
                                        }
                                    }} />
                                <span>{dueDateError}</span>
                            </div>
                        </div>

                        <div className={workSpaceStyle.d_flex_end} style={{ gap: "10px" }}>
                            <button className={`${workSpaceStyle.modalBtn}`} style={{ color: "#16469D" }} onClick={() => { handleChangeDueDateModal(); setDueDate("") }}> Cancel</button>
                            <button className={`${workSpaceStyle.modalBtn}`} style={{ backgroundColor: "#16469D", color: "white" }} onClick={handleChangeDueDate}>Change Date</button>

                        </div>


                    </div>



                </div>


            </Modal>




        </>
    )
}

export default FranchiseApproved
