import React, { useEffect } from "react";// Import React and useEffect hook
import { useLocation, Navigate, Outlet, useParams } from "react-router-dom";// Import necessary hooks and components from react-router-dom
import useAuth from "../hooks/useAuth";// Import useAuth custom hook
import jwt_decode from 'jwt-decode';// Import jwt_decode library for decoding JWT tokens
import { useDispatch } from 'react-redux';// Import useDispatch hook from react-redux
import { setLogout } from '../state/AuthState';// Import setLogout action from AuthState

const RequireAuth = () => { // Define RequireAuth component
    const { auth } = useAuth();// Get authentication information using useAuth hook
    const location = useLocation();// Get current location using useLocation hook
    const { client } = useParams();// Get client parameter from URL using useParams hook
    const dispatch = useDispatch();// Get dispatch function using useDispatch hook

    // useEffect(() => {
    //     // Check authentication conditions and dispatch logout if needed
    //     if (!auth || !auth.token || auth.roles === "VENDOR" || auth?.userData?.stageCount !== 5 || auth.allData.isOrgDisable|| auth.allData.isUserDisable) {
    //         dispatch(setLogout());
    //     }
    // }, [auth, dispatch]);


     // Check authentication conditions and redirect if not authenticated
    if (!auth || !auth.token || auth.roles === "VENDOR" || auth?.userData?.stageCount !== 5 || auth.allData.isOrgDisable || auth.allData.isUserDisable) {
        return <Navigate to="/login" state={{ from: location }} replace />;// Redirect to login page if conditions are not met
    }

     // Decode JWT token and check if it's expired
    const payloadToken = jwt_decode(auth.token);
    const isTokenExpired = Date.now() >= payloadToken.exp * 1000;


     // Check if token is expired and if client matches company name
    if (!isTokenExpired) {
        if (auth.companyName.toLowerCase() === client.toLowerCase()) {
            return <Outlet />;// Render nested routes if client matches
        } else {
            dispatch(setLogout());// Dispatch logout action if client does not match
            return <Navigate to="/login" state={{ from: location }} replace />; // Redirect to login page
        }
    } else {
        dispatch(setLogout());// Dispatch logout action if token is expired
        return <Navigate to="/login" state={{ from: location }} replace />;// Redirect to login page
    }
};

export default RequireAuth;
