import React, { useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styles from './Document.module.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Tooltip from '@mui/material/Tooltip';

import DocumentChatPannel from 'components/DocumentChat/DocumentChatPannel';
import DocumentChatRows from 'components/DocumentChat/DocumentChatRows';
import DocumentChatInput from 'components/DocumentChat/DocumentChatInput';
import { Link } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';
import DocumentChatRow from 'components/DocumentChat/DocumentChatRow';
import useAuth from 'hooks/useAuth';
import useBlobDownload from 'hooks/useBlobDownload';
import useBackdrop from 'hooks/useBackdrop';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';
import DocumentUploadModal from 'components/DocumentUploadModal/DocumentUploadModal';
import RenameModal from 'components/RenameModal/RenameModal';


const DocumentsTab = ({ isDefaultRevision, viewType, showToast, addDocumentModal, handleAddDocumentModal, getDataProcure, documents, revisionId }) => {
  // Importing necessary hooks and functions
  const { auth } = useAuth(); // Authentication context
  const privateAxios = PrivateComponent(); // Custom Axios instance
  const { fileDownload } = useBlobDownload(); // File download utility
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Backdrop utility for loading states

  // State variables for managing modals and document actions
  const [deleteModal, setDeleteModal] = useState(false); // State for delete modal visibility
  const [deleteId, setDeleteId] = useState(""); // State for the ID of the document to be deleted
  const [documentMore, setDocumentMore] = useState(""); // State for showing more document details
  const [renameModal, setRenameModal] = useState(false); // State for rename modal visibility
  const [renameText, setRenameText] = useState(""); // State for storing the rename text
  const [docId, setDocId] = useState(""); // State for storing the document ID for renaming
  const [documentChats, setDocumentChats] = useState([]); // State for storing document chats
  const [docChatLoading, setDocChatLoading] = useState(true); // State for loading indicator of document chats




  // Function to handle delete modal toggle
  const handleDeleteModal = (id) => {
    setDeleteModal(prev => !prev); // Toggle delete modal
    if (id !== null) {
      setDeleteId(id); // Set the document ID to be deleted
    }
  };

  // Function to handle rename modal toggle
  const handleRenameModal = (name) => {
    setRenameModal(prev => !prev); // Toggle rename modal
    if (name !== null && name !== undefined && name !== "") {
      setRenameText(name); // Set the rename text
    }
  };


  // Function to handle document upload
  const handleDocumentUpload = async (data) => {
    openBackdrop(); // Open backdrop for loading state
    try {
      const fd = new FormData(); // Create a new FormData object
      fd.append(`files`, data.files); // Append file data
      fd.append("name", data.fileName); // Append file name
      fd.append("docType", data.type); // Append document type
      fd.append("uploadedBy", auth.roles); // Append uploader's role

      let URL = "";// Initialize URL variable
      if (viewType === "APVVIEW") {
        URL = `/technicalSpecification/uploadProductDocumentsOnVendorRevision/${revisionId}` // Set URL for APV view
      }
      else {
        URL = `/technicalSpecification/uploadProductDocuments/${revisionId}`// Set URL for other views
      }

      // Send POST request to upload the document
      const res = await privateAxios.post(`${URL}`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      getDataProcure(); // Fetch updated document data
      handleAddDocumentModal(); // Close add document modal
      showToast("success", "Document Uploaded"); // Show success toast message

    } catch (error) {
      showToast("error", "Error Uploading Document")// Show Error toast message

    }
    closeBackdrop();
  }


  // Function to handle document deletion
  const handleDocDelete = async (docId) => {
    openBackdrop();// Open backdrop for loading state
    try {
      await privateAxios.delete(`/technicalSpecification/deleteDocumentOfRevision/${docId}`).then(res => {
        getDataProcure();// Fetch updated document data
      }).catch(err => {
        showToast("success", "Document Deleted Successfully"); // Show success toast message
      })
    } catch (error) {
      showToast("error", "Error Deleting Document");// Show error toast message
    }
    closeBackdrop();// Close backdrop
  }


  // Function to handle document renaming
  const handleRenameDoc = async (data) => {
    openBackdrop();// Open backdrop for loading state
    try {
      if (docId) {
        await privateAxios.post(`/technicalSpecification/renameDocument/${docId}?name=${data.name}`).then(res => {
          handleRenameModal(); // Close rename modal
          getDataProcure(); // Fetch updated document data
          setRenameText(""); // Reset rename text
        }).catch(err => {
          handleRenameModal(); // Close rename modal
        })
      }
      else {
        alert("Document ID Not Present"); // Alert if document ID is not present
        handleRenameModal(); // Close rename modal
      }

    } catch (error) {
      handleRenameModal();// Close rename modal
    }
    closeBackdrop();// Close backdrop
  }


  // State for confidential modal visibility
  const [openConfidential, setOpenConfidential] = useState(false)


  // States and functions for document chat functionality
  const [documentChatOpen, setDocumentChatOpen] = useState(""); // State for open document chat
  const handleChatOpen = (data, docId) => {

    if (data === documentChatOpen) {
      setDocumentChatOpen("");// Close document chat if it's already open
      setDocumentChats(""); // Reset document chats
    }
    else {
      getDocumentChat(docId);// Fetch document chat data
      setDocumentChatOpen(data);// Open the document chat
    }
  }

  const [documentChatValue, setDocumentChatValue] = useState("");// State for chat input value

  const handleDocumentChatSend = async (e, documentId) => {
    e.preventDefault(); // Prevent default form submission
    try {
      if (documentChatValue.length > 0) {

        await privateAxios.post(`/technicalSpecification/addRemarkOnDocumentByEmployee/${auth.userData.id}/${documentId}`, { remark: documentChatValue }).then(res => {
          setDocumentChatValue("");// Reset chat input value
          getDocumentChat(documentId);// Fetch updated document chat data
        }).catch((err) => {
        })
      }
    } catch (error) {
    }

  }

  const getDocumentChat = async (documentId) => {
    try {
      await privateAxios.get(`/technicalSpecification/getRemarksOfDocument/${documentId}`).then(res => {
        handleChatNotification(); // Handle chat notification
        setDocumentChats(res.data); // Set document chats data
        setDocChatLoading(false);// Set chat loading to false
      }).catch((err) => {
        setDocChatLoading(false); // Set chat loading to false on error
      })
    } catch (error) {
      setDocChatLoading(false);// Set chat loading to false on error
    }

  }


  const handleDocumentMore = (index) => {

    if (documentMore === index) {
      setDocumentMore(""); // Close more document details if already open
    }
    else {
      setDocumentMore(index);  // Open more document details
    }

  }


 // State and function for re-rendering chat notifications

  const [reRender, setReRender] = useState(false);

  const handleChatNotification = () => {
    setReRender(prev => !prev);// Toggle re-render state

  }

  // =====================chat notification========================



  return (
    <>
      <div className={styles.documentsTabMain}>

        <table className={styles.DocumentTable}>
          <thead>
            <tr className={`${styles.DocumentTableRow} ${styles.DocumentTableHead}`}>
              <td>Title</td>
              <td>Date Added</td>
              <td>Remark</td>
              <td>
                <div className={styles.documentIconRow}>

                  <a><FileDownloadIcon /></a>


                  <a><MoreVertIcon /></a>

                </div>
              </td>

            </tr>
          </thead>
          <tbody>
            {documents?.map((doc, index) => {
              if (doc.docType !== "CONFIDENTIAL") {


                return (
                  <tr key={index} className={styles.DocumentTableRow}>
                    <td>{doc.name || doc.url}</td>
                    <td>{doc?.date?.split('T')[0] || "Null"}</td>
                    <td style={{ position: "relative" }} >
                      <Tooltip title='Comments'>
                        <span onClick={() => { handleChatOpen(index, doc.id); }}>

                          <ChatIconWithBadge url={`/technicalSpecification/getNotficationCountOfProductDocument`} id={doc.id} reRender={reRender} />
                        </span>
                      </Tooltip>
                      {isDefaultRevision ? <>{documentChatOpen === index && <div className={styles.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                        <DocumentChatRows>
                          {docChatLoading ? <div>Chat Loading ..... </div> :

                            documentChats.length === 0 ? <p>No Previous Chats</p> :
                              documentChats?.map((chat, index) => {
                                return (
                                  <DocumentChatRow key={index}
                                    name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                    email={chat.email}
                                  // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                  />
                                )

                              })}
                        </DocumentChatRows>
                        <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                      </DocumentChatPannel>}
                    </td>
                    <td> <div className={styles.documentIconRow}>

                      <Link

                        onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}
                      ><FileDownloadIcon /></Link>


                      <Link style={{ position: "relative" }} className={styles.documentMoreDots} onClick={(e) => { e.preventDefault(); handleDocumentMore(index) }} ><MoreVertIcon />
                        {documentMore === index && <ul style={{ position: "absolute" }}>
                          {
                            !isDefaultRevision

                            && <li onClick={() => { handleRenameModal(doc?.name); setDocId(doc.id) }}> <span><DriveFileRenameOutlineIcon /></span>Rename</li>
                          }
                          {
                            !isDefaultRevision
                            // revisionId === "0"
                            &&
                            <li onClick={() => handleDeleteModal(doc.id)}> <span><DeleteOutlineIcon /></span>Delete</li>
                          }
                          {/* <li> <span><DeleteOutlineIcon /></span>Some Other Option</li> */}
                        </ul>}
                      </Link>

                    </div></td>
                  </tr>
                )
              }
            })
            }

          </tbody>
        </table>




        <div className={styles.accordianDocumentab}>
          <div className={styles.accordianDocumentabHeader} onClick={() => setOpenConfidential(prev => !prev)}><span>
            {openConfidential ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </span>Confidential Documents</div>

          <div className={styles.accordianDocumentabBody}>

            {openConfidential &&
              <table className={styles.DocumentTable}>
                <tbody>
                  {documents?.map((doc, index) => {
                    if (doc.docType === "CONFIDENTIAL") {
                      return (
                        <tr key={index} className={styles.DocumentTableRow}>
                          <td>{doc.name || doc.url}</td>
                          <td>{doc?.date?.split('T')[0] || "Null"}</td>
                          <td style={{ position: "relative" }} >
                            <Tooltip title='Comments'>
                              <span onClick={() => { handleChatOpen(index, doc.id); }}>

                                <ChatIconWithBadge url={`/technicalSpecification/getNotficationCountOfProductDocument`} id={doc.id} reRender={reRender} />
                              </span>
                            </Tooltip>
                            {isDefaultRevision ? <>{documentChatOpen === index && <div className={styles.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                              <DocumentChatRows>
                                {docChatLoading ? <div>Chat Loading ..... </div> :

                                  documentChats.length === 0 ? <p>No Previous Chats</p> :
                                    documentChats?.map((chat) => {
                                      return (
                                        <DocumentChatRow key={index}
                                          name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                          email={chat.email}

                                        // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                        />
                                      )

                                    })}
                              </DocumentChatRows>
                              <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                            </DocumentChatPannel>}
                          </td>
                          <td> <div className={styles.documentIconRow}>

                            <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}
                            // target='_blank'
                            ><FileDownloadIcon /></Link>


                            <Link style={{ position: "relative" }} className={styles.documentMoreDots} onClick={(e) => { e.preventDefault(); handleDocumentMore(index) }} ><MoreVertIcon />
                              {documentMore === index && <ul style={{ position: "absolute" }}>
                                {
                                  !isDefaultRevision
                                  // revisionId === "0"
                                  && <li onClick={() => { handleRenameModal(doc?.name || ""); setDocId(doc.id) }}> <span><DriveFileRenameOutlineIcon /></span>Rename</li>
                                }
                                {
                                  !isDefaultRevision
                                  // revisionId === "0"
                                  &&
                                  <li onClick={() => handleDeleteModal(doc.id)}> <span><DeleteOutlineIcon /></span>Delete</li>
                                }
                                {/* <li> <span><DeleteOutlineIcon /></span>Some Other Option</li> */}
                              </ul>}
                            </Link>

                          </div></td>
                        </tr>
                      )
                    }
                  })

                  }

                </tbody>
              </table>

            }
          </div>

          <BackdropComponent />
        </div>


      </div>

      <DocumentUploadModal
        open={addDocumentModal}
        onClose={handleAddDocumentModal}
        onSubmit={handleDocumentUpload}

      />




      <RenameModal
        open={renameModal}
        onClose={handleRenameModal}
        onSubmit={handleRenameDoc}
        prefilled={renameText}
        header="Rename"
      />




      <DeleteConfirmation
        onCancel={handleDeleteModal}
        onConfirm={() => { handleDocDelete(deleteId); handleDeleteModal() }}
        open={deleteModal}
      // confimBtnText={""} 
      // discardText={""}
      >
        <h2>Delete Document</h2>
        <p>Are you sure you want to delete this Document?</p>

      </DeleteConfirmation>



    </>
  )
}

export default DocumentsTab



/**
 * RevisionEdit.js
 * 
 * This is the Document Tab of Revision Edit component, responsible for rendering Document Tab of Revision Edit. 
 * 
 *
 * Author: Ravinder Kumar
 *   
 * 
 * Created on: 2023-08-29
 * 
 * 
 * Last Modified on: 2023-09-28
 */