
import UnderVerificationStyle from './UnderVerificationStyle.module.css';
import RegisterHeader from '../../components/RegisterHeader/RegisterHeader';
import { Image } from 'cloudinary-react';
import { Button } from '@mui/material';
import {useNavigate } from 'react-router-dom';

function UnderVerification() {

    const navigate = useNavigate();

    return (
        <>
            <RegisterHeader />
            <div className={UnderVerificationStyle.Firstclass}>
                <div className={UnderVerificationStyle.secondMainClass}>
                    <Image publicId="https://res.cloudinary.com/duzmzc074/image/upload/v1689399553/animation_640_lj5kpmcf_1_c1iszu.svg" cloudName="duzmzc074" />
                </div>
                <div className={UnderVerificationStyle.secondMainText}>
                    <h1>
                        Your account is currently under verification.<br />
                        Feel free to explore our services in the meantime!
                    </h1>
                    <Button variant='contained' onClick={() => navigate("/", { replace: true })} className={UnderVerificationStyle.buttonFirst}>Home</Button>
                </div>
            </div>
        </>
    )
}


export default UnderVerification;