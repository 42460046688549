import React, { useEffect, useState } from 'react'
import navbarStyle from './Navbar.module.css'
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import Collapse from '@mui/material/Collapse';
import { useNavigate, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tooltip from '@mui/material/Tooltip';
import useAuth from 'hooks/useAuth';
import GridViewIcon from '@mui/icons-material/GridView';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import ChecklistIcon from '@mui/icons-material/Checklist';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';


const Navbar = ({ remainingDays }) => {
  const navigate = useNavigate();// Hook to programmatically navigate
  const location = useLocation()// Hook to get the current location
  const [workSpacesDropOpen, setWorkSpacesDropOpen] = useState(false);// State to manage workspace dropdown
  const [myTaskOpen, setMyTaskOpen] = useState(false);// State to manage my task dropdown
  const [url, setUrl] = useState([]); // State to manage the current URL
  const { auth } = useAuth();// Get authentication data from custom hook
  const [planExpand, setPlanExpand] = useState(false)// State to manage plan expand

  // Function to navigate to a URL and close workspace dropdown
  const handleNavigate = (url) => {
    navigate(url)
    setWorkSpacesDropOpen(false)
  }

  // Function to toggle workspace dropdown
  const handleNavItemClick = (navItem) => {
    setWorkSpacesDropOpen(prev => !prev)
  }

  // useEffect to update the URL state when the location changes
  useEffect(() => {
    const urlArray = location.pathname.split("/"); // Split the pathname into an array
    setUrl(urlArray)// Update the URL state
  }, [location])

  // Function to check if a tab is selected based on the current URL
  const handleTabSelected = (tab) => {
    return url.includes(tab);
  }

  return (
    <div className={navbarStyle.navWrapper} >
      <div className={navbarStyle.navMain} >


        <List sx={{ padding: "0px" }} style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

          <ListItem disablePadding onClick={() => handleNavigate("dashboard")}>
            <ListItemButton
              selected={handleTabSelected('dashboard')}
              sx={{
                '&:focus': {
                  backgroundColor: '#E3F5FF',
                },
                backgroundColor: handleTabSelected('dashboard') ? '#E3F5FF' : 'transparent',

                borderRadius: "8px"
              }}
            >
              <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
                <GridViewIcon
                  sx={{
                    borderRadius: '3px',
                    color: handleTabSelected('dashboard') ? '#16469D' : '#344054',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}

                sx={{
                  color: handleTabSelected('dashboard') ? '#16469D' : '#344054',
                }}
              >
                Dashboard

              </ListItemText>

            </ListItemButton>
          </ListItem>


          <ListItem disablePadding onClick={() => handleNavItemClick("workspaces")}>
            <ListItemButton

              selected={workSpacesDropOpen}
              sx={{
                // '&:focus': {
                //   backgroundColor: '#E3F5FF',
                // },
                backgroundColor: workSpacesDropOpen ? '#E3F5FF' : 'transparent',
                color: workSpacesDropOpen ? '#16469D' : '#344054',
                borderRadius: "8px"
              }}
            >
              <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
                <LayersOutlinedIcon
                  sx={{
                    borderRadius: '3px',
                    color: workSpacesDropOpen ? '#16469D' : '#344054',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}

                sx={{
                  color: workSpacesDropOpen ? '#16469D' : '#344054',
                }}
              >
                Workspaces

              </ListItemText>
              <ExpandMoreIcon />
            </ListItemButton>
          </ListItem>
          {auth?.workSpaces?.includes("technical-specification") &&
            <Collapse in={workSpacesDropOpen} timeout="auto" unmountOnExit >
              <List component="div" disablePadding >
                <ListItemButton
                  style={{ backgroundColor: '#fff', }}
                  sx={{
                    pl: 7,

                    // color: url === 'technical-specification' ? 'darkblue' : 'inherit',
                  }}
                  onClick={() => navigate('technical-specification')}
                  selected={handleTabSelected('technical-specification')}
                >
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}
                    sx={{
                      // pl: 7,
                      // backgroundColor: 'transparent',
                      color: handleTabSelected('technical-specification') ? 'darkblue' : 'inherit',
                    }}
                  >
                    Technical Specification
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
          }

          {auth?.workSpaces?.includes("commercial") &&
            <Collapse in={workSpacesDropOpen} timeout="auto" unmountOnExit >
              <List component="div" disablePadding >
                <ListItemButton
                  style={{ backgroundColor: '#fff', }}
                  sx={{
                    pl: 7,

                    // color: url === 'technical-specification' ? 'darkblue' : 'inherit',
                  }}
                  onClick={() => navigate('commercial')}
                  selected={handleTabSelected('commercial')}
                >
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}
                    sx={{
                      // pl: 7,
                      // backgroundColor: 'transparent',
                      color: handleTabSelected('commercial') ? 'darkblue' : 'inherit',
                    }}
                  >
                    Commercial
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
          }
          {auth?.workSpaces?.includes("procurement") &&
            <Collapse in={workSpacesDropOpen} timeout="auto" unmountOnExit >
              <List component="div" disablePadding >
                <ListItemButton
                  style={{ backgroundColor: '#fff', }}
                  sx={{
                    pl: 7,


                  }}
                  onClick={() => navigate('procurement')}
                  selected={handleTabSelected('procurement')}
                >
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}
                    sx={{

                      color: handleTabSelected('procurement') ? 'darkblue' : 'inherit',
                    }}
                  >
                    Procurement
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
          }
          {auth?.workSpaces?.includes("approvals") &&
            <Collapse in={workSpacesDropOpen} timeout="auto" unmountOnExit >
              <List component="div" disablePadding >
                <ListItemButton
                  style={{ backgroundColor: '#fff', }}
                  sx={{
                    pl: 7,


                  }}
                  onClick={() => navigate('approvals')}
                  selected={handleTabSelected('approvals')}
                >
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}
                    sx={{

                      color: handleTabSelected('approvals') ? 'darkblue' : 'inherit',
                    }}
                  >
                    Approval
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
          }

          {/* mytasks  */}
          {(auth.roles === "KAM" || auth.roles === "ADMIN") &&
            <>
              <ListItem disablePadding onClick={() => setMyTaskOpen(prev => !prev)}>
                <ListItemButton
                  selected={myTaskOpen}
                  sx={{
                    // '&:focus': {
                    //   backgroundColor: '#E3F5FF',
                    // },
                    backgroundColor: myTaskOpen ? '#E3F5FF' : 'transparent',

                    borderRadius: "8px"
                  }}
                >
                  <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
                    <ChecklistIcon
                      sx={{
                        borderRadius: '6px',
                        color: myTaskOpen ? '#16469D' : '#344054',
                        border: '2px solid ',
                      }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}

                    sx={{
                      color: handleTabSelected('mytasks') ? '#16469D' : '#344054',
                    }}
                  >
                    My Tasks

                  </ListItemText>
                  <span className={navbarStyle.navItemBubble}>
                    10
                  </span>
                  <ExpandMoreIcon />
                </ListItemButton>
              </ListItem>
              {auth.roles === "ADMIN" && <Collapse in={myTaskOpen} timeout="auto" unmountOnExit >
                <List component="div" disablePadding >
                  {/* {console.log(url)} */}
                  <ListItemButton
                    style={{ backgroundColor: '#fff', }}
                    sx={{
                      pl: 7,
                    }}
                    onClick={() => navigate('mytasks/assigned-to-me')}
                    selected={handleTabSelected('assigned-to-me')}
                  >
                    <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}
                      sx={{
                        // pl: 7,
                        // backgroundColor: 'transparent',
                        color: handleTabSelected('assigned-to-me') ? 'darkblue' : 'inherit',
                      }}
                    >
                      Assigned to me
                    </ListItemText>
                  </ListItemButton>
                </List>
              </Collapse>}

              {auth.roles === "KAM" && auth.userData.orgType !== "ORGANISATION" && <Collapse in={myTaskOpen} timeout="auto" unmountOnExit >
                <List component="div" disablePadding >
                  {/* {console.log(url)} */}
                  <ListItemButton
                    style={{ backgroundColor: '#fff', }}
                    sx={{
                      pl: 7,
                    }}
                    onClick={() => navigate('mytasks/assigedkam')}
                    selected={handleTabSelected('assigedkam')}
                  >
                    <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}
                      sx={{
                        // pl: 7,
                        // backgroundColor: 'transparent',
                        color: handleTabSelected('assigedkam') ? 'darkblue' : 'inherit',
                      }}
                    >
                      Assign To Me
                    </ListItemText>
                  </ListItemButton>
                </List>
              </Collapse>}


              <Collapse in={myTaskOpen} timeout="auto" unmountOnExit >
                <List component="div" disablePadding >
                  {/* {console.log(url)} */}
                  <ListItemButton
                    style={{ backgroundColor: '#fff', }}
                    sx={{
                      pl: 7,
                    }}
                    onClick={() => navigate('mytasks/approval')}
                    selected={handleTabSelected('approval')}
                  >
                    <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}
                      sx={{
                        // pl: 7,
                        // backgroundColor: 'transparent',
                        color: handleTabSelected('approval') ? 'darkblue' : 'inherit',
                      }}
                    >
                      Approval
                    </ListItemText>
                  </ListItemButton>
                </List>
              </Collapse>
              {auth.roles === "KAM" && <Collapse in={myTaskOpen} timeout="auto" unmountOnExit >
                <List component="div" disablePadding >
                  <ListItemButton
                    style={{ backgroundColor: '#fff', }}
                    sx={{
                      pl: 7,
                    }}
                    onClick={() => navigate('mytasks/quotes-sent')}
                    selected={handleTabSelected('quotes-sent')}
                  >
                    <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}
                      sx={{
                        // pl: 7,
                        // backgroundColor: 'transparent',
                        color: handleTabSelected('quotes-sent') ? 'darkblue' : 'inherit',
                      }}
                    >
                      Quotes Send
                    </ListItemText>
                  </ListItemButton>
                </List>
              </Collapse>}


            </>
          }


          <ListItem disablePadding onClick={() => handleNavigate("underconstruction")}>
            <ListItemButton
              selected={handleTabSelected('messages')}
              sx={{
                '&:focus': {
                  backgroundColor: '#E3F5FF',
                },
                backgroundColor: handleTabSelected('messages') ? '#E3F5FF' : 'transparent',

                borderRadius: "8px"
              }}
            >
              <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
                <TextsmsOutlinedIcon
                  sx={{
                    borderRadius: '3px',
                    color: handleTabSelected('messages') ? '#16469D' : '#344054',
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}

                sx={{
                  color: handleTabSelected('messages') ? '#16469D' : '#344054',
                }}
              >
                Messages

              </ListItemText>

            </ListItemButton>
          </ListItem>

          {auth?.roles === "KAM" &&
            <>
              <ListItem disablePadding onClick={() => handleNavigate("users")}>
                <ListItemButton
                  selected={handleTabSelected('users')}
                  sx={{
                    '&:focus': {
                      backgroundColor: '#E3F5FF',
                    },
                    backgroundColor: handleTabSelected('users') ? '#E3F5FF' : 'transparent',

                    borderRadius: "8px"
                  }}
                >
                  <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
                    <PeopleOutlineIcon
                      sx={{
                        borderRadius: '3px',
                        color: handleTabSelected('users') ? '#16469D' : '#344054',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}

                    sx={{
                      color: handleTabSelected('users') ? '#16469D' : '#344054',
                    }}
                  >
                    Users

                  </ListItemText>

                </ListItemButton>
              </ListItem>


              <ListItem disablePadding onClick={() => handleNavigate("vendors")}>
                <ListItemButton
                  selected={handleTabSelected('vendors')}
                  sx={{
                    '&:focus': {
                      backgroundColor: '#E3F5FF',
                    },
                    backgroundColor: handleTabSelected('vendors') ? '#E3F5FF' : 'transparent',

                    borderRadius: "8px"
                  }}
                >
                  <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
                    <Inventory2OutlinedIcon
                      sx={{
                        borderRadius: '3px',
                        color: handleTabSelected('vendors') ? '#16469D' : '#344054',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: '15px', fontWeight: '500' }}

                    sx={{
                      color: handleTabSelected('vendors') ? '#16469D' : '#344054',
                    }}
                  >
                    Vendors

                  </ListItemText>

                </ListItemButton>
              </ListItem>
            </>
          }



        </List>



      </div>

      {auth.roles === "KAM" &&
        <div className={navbarStyle.planBox}>
          <Divider />

          <div className={navbarStyle.planMain}>
            <div className={navbarStyle.planText}>
              <h3>Plan</h3>
              <p>{auth.allData.susbscriptionName}- Expires in {remainingDays} days</p>
            </div>
            <div className={navbarStyle.planThreeDotButton}>
              <button onClick={() => setPlanExpand(prev => !prev)}> <Tooltip title='More'> <MoreHorizIcon /> </Tooltip></button>
            </div>


            <div className={navbarStyle.planButtonExpand} style={{ display: planExpand ? "block" : "none" }}>
              <div>
                <ListItem disablePadding onClick={() => { handleNavigate("companyProfile"); setPlanExpand(false) }}>
                  <ListItemButton>
                    <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
                      <ArticleRoundedIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: '15px', fontWeight: '300' }}
                    >
                      Company Profile

                    </ListItemText>

                  </ListItemButton>

                </ListItem>

              </div>
            </div>


          </div>


        </div>
      }
    </div>
  )


}

export default Navbar
