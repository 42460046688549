import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import styles from './TechnicalApprovalRevisionView.module.css'
import BackButton from 'components/BackButton/BackButton';
import Tooltip from '@mui/material/Tooltip';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useAuth from 'hooks/useAuth';
import { PrivateComponent } from 'api/axios';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AttributeTab from 'components/TechnicalSpecificationCompareTabs/AttributeTab/AttributeTab';
import SpecificationTab from 'components/TechnicalSpecificationCompareTabs/SpecificationTab/SpecificationTab';
import DocumentsTab from 'components/TechnicalSpecificationCompareTabs/DocumentTab/DocumentTab';
import RenameModal from 'components/RenameModal/RenameModal';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatRow from 'components/ChatRows/ChatRow';
import TaskHistoryModal from 'components/TaskHistoryModal/TaskHistoryModal';
import RevokeRequestModal from 'components/RevokeRequestModal/RevokeRequestModal';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import CompareRankingModal from 'components/CompareRankingModal/CompareRankingModal';
import BarChartIcon from '@mui/icons-material/BarChart';

const TechnicalApprovalRevisionView = () => {
    // Importing necessary hooks and components
    const { showToast, Toast } = useToast();// Toast notifications
    const { taskId, productId, revisionId } = useParams();// Getting parameters from URL
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Backdrop component for loading state
    const { auth } = useAuth();// Authentication state
    const privateAxios = PrivateComponent(); // Axios instance for private requests
    const location = useLocation();// Current URL location
    const { state } = location;// State from location
    const navigate = useNavigate();// Navigation function
    const searchParams = new URLSearchParams(location.search);// Query parameters
    const [viewType, setViewType] = useState("");// State for view type


    // =======================set View Type==============================

    // Effect to set view type from state
    useEffect(() => {
        // console.log(state)
        if (state) {
            setViewType(state.from)
        }

    }, [state])


    //=======================button states==========================

    // State for button visibility
    const [workSpaceHeaderRevisionMore, setworkSpaceHeaderRevisionMore] = useState(false);
    const [multiSave, setMultiSave] = useState(false);

    // Handling multi save toggle
    const handleMultiSave = () => {
        setMultiSave(prev => !prev)
    }

    // =================Modal States===========================

    // State for modals
    const [renameModal, setRenameModal] = useState(false);
    const handleRenameModal = () => {
        setRenameModal(prev => !prev)
    }

    // State for active tab
    const [rejectModal, setRejectModal] = useState(false);
    const handleRejectModal = () => {
        setRejectModal(prev => !prev);

    }

    // ==================Active Tab========================


    const [tabActive, setTabActive] = useState("0");
    // Effect to set active tab from query parameter
    useEffect(() => {
        if (searchParams.get("tab") !== null && searchParams.get("tab") !== undefined && searchParams.get("tab") !== "") {
            setTabActive(searchParams.get("tab"));
        }
    }, [Location, searchParams])

    // Handling tab change
    const handleTabChange = (index) => {
        setTabActive(index)
        navigate(`?tab=${index}`, { replace: true })
    }



    // ==================Product Details========================

    // State for product details and attributes
    const [specificationArray, setSpecificationArray] = useState("");
    const [productDetails, setProductDetails] = useState({});
    const [documentsArray, setDocumentsArray] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [duplicateRow, setDuplicateRow] = useState([]);

    // Function to handle attribute validation
    const handleAttributeValidation = (type) => {
        const hasNullName = tableRows.some(rr => rr.name === null);
        if (hasNullName) {
            alert("please check Attribute Name No Name can be empty");
            return false;
        }
        else {
            const nameOccurrences = {};
            const duplicateNamesId = [];
            tableRows.forEach((item) => {
                const name = item.name;
                // Check if the name has been encountered before
                if (nameOccurrences[name]) {
                    // If it has, mark it as a duplicate
                    duplicateNamesId.push(item.id);
                } else {
                    // Otherwise, record it in the occurrences object
                    nameOccurrences[name] = true;
                }
            });
            if (duplicateNamesId.length > 0) {
                // console.log(duplicateError)
                setDuplicateRow(duplicateNamesId)
                if (type === "check") {

                    alert("Please Remove Duplicate Attributes");
                    return false;
                }
                else {
                    return duplicateNamesId
                }
            }
            else {
                setDuplicateRow([]);
                return true;
            }

        }
    }

    // Function to delete all duplicate rows
    const deleteAllDuplicateRows = () => {
        const ids = handleAttributeValidation();
        if (ids.length > 0) {

            const newRows = tableRows.filter((item) => !ids.includes(item.id));
            setTableRows(newRows);
        }
    }

    // Function to check count based on rules
    const CheckCount = (item) => {
        let count = {
            satisfied: 0,
            unstatisfied: 0,
            neutral: 0,
        };

        // Handle neutral cases
        if (!item || !item.revisedValue) {
            count.unstatisfied = 1
            return count
        }
        if (!item || !item.valueType) {
            count.neutral = 1;
            return count;
        }

        switch (item.valueType) {
            case 'NUMBER':
                const revisedValue = parseFloat(item.revisedValue);
                const value = parseFloat(item.value);
                switch (item.ruleAttribute) {
                    case 'LESS':
                        if (revisedValue < value) count.satisfied = 1;
                        else count.unstatisfied = 1;
                        break;
                    case 'MORE':
                        if (revisedValue > value) count.satisfied = 1;
                        else count.unstatisfied = 1;
                        break;
                    case 'EQUAL':
                        if (revisedValue === value) count.satisfied = 1;
                        else count.unstatisfied = 1;
                        break;
                    default:
                        count.neutral = 1;
                }
                break;
            case 'RANGE':
                const range = item.value?.split("-").map(item => parseFloat(item.trim()));
                if (range && range.length === 2) {
                    const revisedValue = parseFloat(item.revisedValue);
                    if (revisedValue >= range[0] && revisedValue <= range[1]) count.satisfied = 1;
                    else count.unstatisfied = 1;
                } else {
                    count.neutral = 1;
                }
                break;
            case 'BOOLEAN':
                if (item.revisedValue?.trim() === item.value?.trim()) count.satisfied = 1;
                else count.unstatisfied = 1;
                break;
            case 'SINGLESELECT':
                if (item.revisedValue?.trim() === item.expectedAnswer?.trim()) count.satisfied = 1;
                else count.unstatisfied = 1;
                break;
            case 'DATE':
                const revisedDate = new Date(item.revisedValue);
                const originalDate = new Date(item.value);
                switch (item.ruleAttribute) {
                    case 'LESS':
                        if (revisedDate < originalDate) count.satisfied = 1;
                        else count.unstatisfied = 1;
                        break;
                    case 'MORE':
                        if (revisedDate > originalDate) count.satisfied = 1;
                        else count.unstatisfied = 1;
                        break;
                    case 'EQUAL':
                        if (revisedDate.getTime() === originalDate.getTime()) count.satisfied = 1;
                        else count.unstatisfied = 1;
                        break;
                    default:
                        count.neutral = 1;
                }
                break;
            default:
                count.neutral = 1;
        }
        return count;
    };


    // State for vendor rankings
    const [vendorRankings, setVendorRankings] = useState([]);

    // Function to build rows from data
    const buildRows = async (data) => {
        openBackdrop();
        try {
            const attributeArray = [];
            if (data.length > 1) {
                data.slice(1).forEach((res, index) => {
                    const attributes = res.productAttributes.map((dd) => {
                        let addFields = dd.additionalFields;
                        if (typeof addFields === 'string') {
                            try {
                                if (addFields === "{}") {

                                    addFields = [];
                                }
                                else {
                                    addFields = JSON.parse(addFields);
                                }
                            } catch (error) {
                                addFields = [];
                            }
                        }
                        return {
                            ...dd,
                            additionalFields: addFields,
                        };
                    });

                    if (!attributeArray[res.vendorName]) {
                        attributeArray[res.vendorName] = attributes;
                    } else {
                        attributeArray[`${res?.vendorName?.trim()}_${index}`] = attributes;
                    }
                });
            }
            else {
                const attributes = data[0].productAttributes.map((dd) => {
                    let addFields = dd.additionalFields;
                    if (typeof addFields === 'string') {
                        try {
                            addFields = JSON.parse(addFields);
                        } catch (error) {
                            // console.log("Error parsing additionalFields:", error);
                            addFields = [];
                        }
                    }
                    return {
                        ...dd,
                        additionalFields: addFields,
                    };
                })
                attributeArray[data[0].vendorName] = attributes;
            }


            const attributeValues = Object.values(attributeArray);
            const groupedData = {};
            const vendorRanking = {}
            for (const [vendorName, dataArray] of Object.entries(attributeArray)) {


                let count = {
                    satisfied: 0,
                    unstatisfied: 0,
                    neutral: 0,
                }


                dataArray.forEach((item) => {


                    const tempCount = CheckCount(item);

                    count.satisfied = count.satisfied + tempCount.satisfied;
                    count.unstatisfied = count.unstatisfied + tempCount.unstatisfied;
                    count.neutral = count.neutral + tempCount.neutral;


                    const updatedItem = {
                        ...item,
                        vName: vendorName,
                    };

                    const itemName = item.name;

                    if (!groupedData[itemName]) {
                        groupedData[itemName] = [];
                    }

                    groupedData[itemName].push(updatedItem);
                });
                vendorRanking[vendorName] = count;
            }
            setVendorRankings(vendorRanking);
            const addedData = Object.values(groupedData).map((data) => [
                ...data,
                ...(data.length < attributeValues.length
                    ? Array.from({ length: attributeValues.length - data.length }, (_, i) => ({
                        id: data[0].id + i,
                        name: data[0].name,
                        type: data[0].type,
                        unit: data[0].unit,
                        value: "N/A",
                        revisedValue: "N/A",
                        remark: "N/A",
                        vendorRemark: "N/A",
                        additionalFields: [],
                        valueType: data[0].valueType,
                        ruleAttribute: data[0].ruleAttribute,
                        expectedAns: data[0].expectedAns,
                        createdBy: "N/A",
                        vName: "N/A",
                    }))
                    : []),
            ]);

            const resultArray = addedData.map((smt, index) =>
                smt.reduce(
                    (obb, obj) => ({
                        ...obb,
                        revisedValue: [...obb.revisedValue, { value: obj.revisedValue, sentBy: obj.vName }],
                        vendorRemark: [...obb.vendorRemark, { value: obj.vendorRemark, sentBy: obj.vName }],
                        additionalFields: [...obb.additionalFields, ...obj.additionalFields],
                    }),
                    {
                        id: index + 1,
                        name: smt[0].name,
                        type: smt[0].type,
                        unit: smt[0].unit,
                        value: smt[0].value,
                        remark: smt[0].remark,
                        valueType: smt[0].valueType,
                        ruleAttribute: smt[0].ruleAttribute,
                        expectedAns: smt[0].expectedAns,
                        createdBy: "N/A",
                        revisedValue: [],
                        vendorRemark: [],
                        additionalFields: [],
                    }
                )
            );

            const attributes = resultArray || [];
            buildColumn(attributes[0]);
            setTableRows(attributes);
        } catch (error) {
            console.error("Error Sorting Data:", error);
            showToast("error", "Error Sorting Data");
        } finally {
            closeBackdrop();
        }
    };

    // Function to build documents and specifications
    const buildDocAndSpecification = (data) => {
        const doc = {};
        const specifications = {};

        if (data.length > 1) {
            data.slice(1).forEach((res, index) => {
                const key = res?.vendorName === null ? `No Vendor Name_${index}` : `${res?.vendorName?.trim()}`;

                if (!doc[key]) {
                    doc[key] = res.productDocuments;
                    specifications[key] = res.productSpecification;
                }
            });
        }
        else {
            doc[data[0].vendorName] = data[0].productDocuments;
            specifications[data[0].vendorName] = data[0].productSpecification;
        }
        const documents = {}

        Object.values(doc).forEach((item, index) => {
            let key = Object.keys(doc)[index];
            let confidential = [];
            let normal = [];
            item.forEach(dd => {
                if (doc.docType === "CONFIDENTIAL") {
                    confidential.push(dd);
                }
                else {
                    normal.push(dd)
                }
            })
            documents[key] = {
                normal,
                confidential
            }

        })
        setDocumentsArray(documents);
        setSpecificationArray(specifications);
    };

    // Function to build columns
    const buildColumn = (data) => {
        let vendorValueColumns = data.revisedValue.map((vv) => {
            return `${vv.sentBy} Value`
        })
        let vendorRemarkColumns = data.vendorRemark.map((vv) => {
            return `${vv.sentBy} Remark`
        })
        let additionalColumnNames = data.additionalFields.map((col) => {
            return col.columnName

        });

        const newColumns = { vendorValueColumns, vendorRemarkColumns, additionalColumnNames }
        setColumns(newColumns);
    }

    // Function to get data
    const getData = async () => {
        openBackdrop();
        try {
            // TASK ID CHECK IS REMAINING BUT WE THOUGHT ABOUT IT.
            const response = await privateAxios.get(`/approval/getApprovalRevisionById/${revisionId}`);
            // const response = await privateAxios.get(`/technicalSpecification/getCompareRevisionById/4`);
            let data = response.data;
            setProductDetails(data[0])
            buildRows(data);
            buildDocAndSpecification(data);
        } catch (error) {
            showToast("error", "Error Fetching Data");
        }

        closeBackdrop();
    }
    // Effect to get data on component mount
    useEffect(() => {
        getData();
    }, [])

    // ==================Chat Slider========================

    // State for chat slider and chat value
    const [chatSlider, setChatSlider] = useState(false)
    const [chatValue, setChatValue] = useState("");
    const [revisionChats, setRevisionChats] = useState([]);

    // Function to handle chat slider visibility
    const handleChatSlider = () => {
        setChatSlider(prev => !prev)
    }
    // Effect to fetch revision chats when chat slider is opened
    useEffect(() => {
        if (chatSlider === true) {
            getRevisionChats()
        }

    }, [chatSlider]);

    // Function to handle sending chat messages
    const handleChatSend = async (e) => {
        e.preventDefault()
        try {
            if (chatValue.length > 0) {
                await privateAxios.post(`/approval/addRemarkOnApprovalRevision${auth.userData.id}/${revisionId}`, { remark: chatValue }).then(res => {
                    getRevisionChats();
                    setChatValue("")
                })
            }
        } catch (error) {
        }

    }

    // Function to fetch revision chats
    const getRevisionChats = async () => {
        try {
            await privateAxios.get(`/approval/getRemarksOfApprovalRevision/${revisionId}`).then(res => {
                setRevisionChats(res.data)
                handleChatNotification();
            })
        } catch (error) {

        }
    }

    // State for re-rendering
    const [reRender, setReRender] = useState(false);

    // Function to handle chat notifications
    const handleChatNotification = () => {
        setReRender(prev => !prev);

    }

    // ======================Document Data=====================
    // State for add document modal
    const [addDocumentModal, setAddDocumentModal] = useState(false);

    // Function to handle add document modal visibility
    const handleAddDocumentModal = () => {
        setAddDocumentModal(prev => !prev);
    }

    // ======================Rename Revision====================

    // Function to handle renaming revision
    const handleChangeRevisionName = async (data) => {
        openBackdrop();
        try {

            await privateAxios.post(`/technicalSpecification/renameGroupOrComparison/${productDetails.id}?name=${data.name}`)

            await getData();
            showToast("success", "Revision Renamed")
        } catch (error) {
            console.log(error)
            showToast("error", "Error while Rename Revision")

        }
        handleRenameModal();
        closeBackdrop();
    }

    // ======================Duplicate Revision====================

    // Function to handle duplicating revision
    const handleDuplicateRevisison = async (data) => {
        openBackdrop();
        try {
            await privateAxios.post(`/technicalSpecification/duplicateRevision/${productDetails.id}?name=${data.name}`).then((res) => {
                navigate(-1);
            })
        } catch (error) {
            showToast("error", "Error Duplicating Revision")
        }
        closeBackdrop();
    }


    // =====================Delete Revision===============================

    // Function to handle deleting revision
    const handleRevisionDelete = async () => {
        openBackdrop();
        try {
            if (productDetails.id !== null) {
                await privateAxios.delete(`/technicalSpecification/deleteRevision/${productDetails.id}`).then((res) => {
                    navigate(-1, { replace: true })
                })
            }
        } catch (error) {
            showToast("error", "Error Deleting Revision!")
        }
        closeBackdrop()
    }


    // =========================Download Revision==============================]

    // const handleDownloadRevision = () => {


    //     let tempElement = document.createElement('div');
    //     tempElement.innerHTML = specificationValue;
    //     let extractedText = tempElement.textContent;
    //     extractedText = extractedText.trim();

    //     // taking nessarry feilds from attributes
    //     const Attributes = tableRows.map((attri) => {
    //         const modifiedAttri = { ...attri };
    //         delete modifiedAttri.id;
    //         if (revisionId !== 0) {
    //             delete modifiedAttri.revisedValue;
    //             delete modifiedAttri.vendorRemark;

    //         }
    //         const ttadditionsFields = [];
    //         attri.additionalFields.forEach((rem, index) => {

    //             ttadditionsFields[rem.columnName] = rem.columnValue;

    //         });
    //         delete modifiedAttri.additionalFields;
    //         return { ...modifiedAttri, ...ttadditionsFields };

    //     });

    //     let revisionDocument = []
    //     if (isDefaultRevision) {

    //         revisionDocument = documents.map((doc) => {
    //             return {
    //                 name: "Not Available",
    //                 link: doc.url,
    //                 type: "normal"

    //             }
    //         })

    //     }
    //     else {
    //         revisionDocument = documents.map((doc) => {
    //             return {
    //                 name: doc?.name,
    //                 link: doc.url,
    //                 type: doc?.type

    //             }
    //         })
    //     }


    //     createExcel([[{ Specification: extractedText }], Attributes, revisionDocument], (productDetails.name || "Revision 0"))
    // }



    // =========================Jump to Row==============================

    // State to manage the search term for jump search functionality
    const [jumpSearch, setJumpSearch] = useState("");

    // Function to handle the selection of a value from the search dropdown
    const handleSelect = (value) => {
        const selectedValue = value;
         // Find the selected row from the tableRows based on the selected value
        const selectedRow = tableRows.find((row) => row.name === selectedValue);
        if (selectedRow) {
               // Scroll the selected row into view smoothly and center it
            const selectedRowElement = document.getElementById(`row-${selectedRow.id}`);
            if (selectedRowElement) {
                selectedRowElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    };



    // ======================task history=============================

    // State to manage the visibility of the task history modal
    const [taskHistoryModal, setTaskHistoryModal] = useState(false);

    // State to store the task history data
    const [taskHistoryData, setTaskHistoryData] = useState({});

    // Function to toggle the task history modal
    const handleTaskHistoryModal = (data) => {
        setTaskHistoryModal(prev => !prev);

    }


    // =======================Revoke Request======================

    // State to store the data for a revoke request
    const [revokeRequestData, setRevokeRequestData] = useState({});

    // State to manage the visibility of the revoke request modal
    const [revokeRequestModal, setRevokeRequestModal] = useState(false);

    // State to store data about the revoker
    const [revokerData, setRevokerData] = useState({});

    // Function to toggle the revoke request modal and set the revoke request data
    const handleRevokeRequestModal = (data) => {
        setRevokeRequestModal(prev => !prev);
        console.log(data)
        if (data) {
            setRevokeRequestData(data);
        }

    }


    // =========================handleRevokeConfirmationForm============

    // State to manage the visibility of the revoke confirmation modal
    const [revokeConfirmationModal, setRevokeConfirmationModal] = useState(false);

    // Function to toggle the revoke confirmation modal
    const handleRevokeConfirmationModal = () => {
        setRevokeConfirmationModal(prev => !prev);
    }

    // Function to handle the confirmation form for revoking a request
    const handleRevokeConfirmationForm = () => {
        handleRevokeRequestModal();
        handleRevokeConfirmationModal();
    }

    // Function to handle the confirmation of a revoke request
    const handleRevokeConfirmation = async (type, revokeId) => {
        openBackdrop();// Show the backdrop to indicate a loading state

        try {
            let url = '';
            if (type) {
                url = `/approval/approveRevokeRequest`
            }
            else {
                url = `/approval/DeclineRevokeRequest`
            }
            const res = await privateAxios.post(`${url}/${revokeId}`);
            if (res.status === 200) {
                if (type) {
                    showToast("success", "Revoke Request Approved");
                }
                else {
                    showToast("success", "Revoke Request Declined")
                }
            }
        } catch (error) {
            if (type) {
                showToast("error", "Error approving revoke request");
            }
            else {
                showToast("error", "Error Rejecting revoke request")
            }

        }
        getData(); // Refresh data after the operation
        closeBackdrop();// Hide the backdrop
    }




    // =========================handleRevokeConfirmationForm============

    // State to manage the visibility of the withdrawal modal
    const [withdrawalModal, setWithdrawalModal] = useState(false);

    // Function to toggle the withdrawal modal
    const handleWithdrawalModal = (data) => {
        setWithdrawalModal(prev => !prev)
    }

    // Function to handle the submission of a withdrawal request
    const handleWithdrawalSubmit = async (data) => {
        openBackdrop(); // Show the backdrop to indicate a loading state
        try {
            await privateAxios.post(`/approval/requestToRevokeApproval/${auth.userData.id}/${revisionId}`, { reason: data.name }).then((res) => {
                handleWithdrawalModal()

                showToast("success", "Request Sent")
            }).catch((err) => {
                // console.log(err);
                showToast("error", "Error Sending Request")
            })
        } catch (error) {
            showToast("error", "Error Sending Request")
            // console.log(error);
        }
        closeBackdrop();
    }

    // =======================handleReminderSend=========================

// Function to handle the sending of a reminder
    const handleReminderSend = async () => {
        try {

            const res = await privateAxios.post(`/approval/sendReminderByApproveLevel/${revisionId}`);
            if (res.status === 200) {
                showToast("sucess", "Reminder Sent");
            }


        } catch (error) {
            showToast("error", "Error Sending Reminder ")
        }
    }

    // ============================add remark on Tabs=================================

// States to manage remarks on different tabs
    const [specificationRemark, setSpecificationRemark] = useState("");
    const [documentRemark, setDocumentRemark] = useState("");
    const [attributeRemark, setAttributeRemark] = useState("");


// Function to handle adding remarks to different tabs
    const handleAddRemark = async () => {
        openBackdrop();// Show the backdrop to indicate a loading state
        try {
            const payload = [
                {
                    "remark": specificationRemark,
                    "type": "Product Specification"
                },
                {
                    "remark": attributeRemark,
                    "type": "Product Attributes"
                },
                {
                    "remark": documentRemark,
                    "type": "Product Documents"
                }
            ]
            const res = await privateAxios.post(`/approval/addRemarkOnTab/${auth.userData.id}/${revisionId}`, payload)
            if (res.status === 200) {
                showToast("success", "Added Remarks")
                getRemarksofTabs();// Refresh the remarks data
            }
        } catch (error) {
            showToast("error", "Erro Saving Changes")
        }
        closeBackdrop();// Hide the backdrop
    }

    // ===========================get remarks on Tabs====================================

    // State to store all remarks of tabs
    const [allTabsRemarks, setAllTabsRemarks] = useState(null);

    // Function to fetch remarks of tabs
    const getRemarksofTabs = async () => {
        try {
            const res = await privateAxios.get(`/approval/getRemarksOfTab/${revisionId}`);
            const { data } = res;

            let allRemark = {
                specification: [],
                documents: [],
                attributes: [],
            }

            data.forEach((rem) => {
                if (rem.email === auth.email) {
                    switch (rem.type) {
                        case "Product Specification": setSpecificationRemark(rem.remark)
                            break;
                        case "Product Attributes": setAttributeRemark(rem.remark);
                            break;
                        case "Product Documents": setDocumentRemark(rem.remark);
                            break;
                    }
                }
                else {
                    switch (rem.type) {
                        case "Product Specification":
                            allRemark = {
                                ...allRemark,
                                specification: [
                                    ...allRemark.specification,
                                    rem
                                ]
                            }
                            break;
                        case "Product Attributes": allRemark = {
                            ...allRemark,
                            attributes: [
                                ...allRemark.attributes,
                                rem
                            ]
                        }
                            break;
                        case "Product Documents": allRemark = {
                            ...allRemark,
                            documents: [
                                ...allRemark.documents,
                                rem
                            ]
                        }
                            break;
                    }
                }
            })

            setAllTabsRemarks(allRemark)
        } catch (error) {
            console.log(error);
            showToast("error", "Error Fetching Remarks Data");
        }
    }

    // Fetch remarks data when the component mounts
    useEffect(() => {
        getRemarksofTabs();
    }, [])


    //========================= Decision on Revision=========================

    // Function to handle decision on revision approval or rejection
    const handleDecisionOnRevision = async (isApproval) => {
        try {
            if (isApproval) {
                 // Check if remarks are provided for all tabs before approving
                if (specificationRemark !== "" || attributeRemark !== "" || documentRemark !== "") {
                    await handleAddRemark();// Add remarks before approval
                     // API call to approve the revision
                    const response = await privateAxios.post(`/approval/approveApprovalRevisionByUser/${revisionId}/${auth.userData.id}`);
                    if (response.status === 200) {
                        showToast("success", "Revision Approved Successfully!")
                    }
                }
                else {
                     // API call to reject the revision
                    showToast("error", "Please Add Remarks on all tabs")
                }
            }
            else {
                const response = await privateAxios.post(`/approval/rejectApprovalRevisionByUser/${revisionId}/${auth.userData.id}`);
                if (response.status === 200) {
                    showToast("error", "Revision Rejected!")
                }
            }

            getData();// Refresh data after the operation

        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    }


    //========================= Decision on Revision========================= 

    // State to manage the visibility of the ranking modal
    const [rankingModal, setRankingModal] = useState(false);

    // Function to toggle the ranking modal
    const handleRankingModal = () => {
        setRankingModal(prev => !prev);

    }





    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>



                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft} style={{ maxWidth: "none" }}>
                        <h1>
                            <BackButton />
                            {productDetails.name || "Not Name"}</h1>
                        <p>#0{productDetails.id} {productDetails?.productName || "Dafault"}</p>

                    </div>

                    <div className={workSpaceStyle.workSpaceHeaderRight}>

                        <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative", width: "auto" }}>
                            <button onClick={() => { handleRankingModal() }} style={{ padding: "0 10px" }}><BarChartIcon />Ranking</button>



                        </div>


                        {auth.roles !== "KAM" && <div className={workSpaceStyle.workSpaceHeaderDecisionButton}>

                            {
                                productDetails.revisionApprovalStatus === "PENDING"

                                    ?
                                    <>
                                        <button className={workSpaceStyle.workSpaceHeaderDecisionApprovalBtn} onClick={() => { handleDecisionOnRevision(true) }}><span><CheckIcon /></span> Approve</button>
                                        <button className={workSpaceStyle.workSpaceHeaderDecisionRejectBtn} onClick={() => { handleRejectModal() }}><span><CloseIcon /></span>Reject</button>
                                    </>
                                    :
                                    productDetails.revisionApprovalStatus === "APPROVAL"

                                        ?
                                        <button className={workSpaceStyle.workSpaceHeaderDecisionApprovalBtn}><span><CheckIcon /> </span>Approved</button>

                                        :
                                        <button className={workSpaceStyle.workSpaceHeaderDecisionRejectBtn} ><span><CloseIcon /></span>Rejected</button>


                            }
                        </div>}






                        <div className={workSpaceStyle.workSpaceThreeDotVertical} style={{ position: "relative" }}>

                            <span className={workSpaceStyle.workSpaceHeaderRevisionMore} onClick={() => setworkSpaceHeaderRevisionMore(prev => !prev)}>
                                <MoreVertIcon />
                            </span>
                            {
                                workSpaceHeaderRevisionMore &&
                                <ThreeDotDropDown toggle={() => setworkSpaceHeaderRevisionMore(prev => !prev)} >


                                    {
                                        auth.roles !== "KAM" &&
                                        <>
                                            <li
                                                onClick={() => { handleWithdrawalModal(); setworkSpaceHeaderRevisionMore(prev => !prev) }}
                                            >
                                                <span>
                                                    <SettingsBackupRestoreIcon />
                                                </span>
                                                Request for Withdrawal
                                            </li>

                                        </>
                                    }



                                    {
                                        auth.roles === "KAM" &&
                                        <>
                                            <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleReminderSend(); }}>
                                                <span><PersonAddAltOutlinedIcon /></span>Reminder </li>
                                            <li onClick={() => { setworkSpaceHeaderRevisionMore(prev => !prev); handleTaskHistoryModal(); }}>
                                                <span><PersonAddAltOutlinedIcon /></span>Task History </li>

                                        </>
                                    }






                                    <li onClick={() => {
                                        setworkSpaceHeaderRevisionMore(prev => !prev);
                                        //  handleDownloadRevision();
                                    }}
                                    ><span>
                                            <FileDownloadOutlinedIcon />
                                        </span>Download</li>
                                </ThreeDotDropDown>
                            }

                        </div>
                        <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
                            <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
                        </div>
                    </div>

                </div>

                <div className={styles.tabHeaderMain}>
                    <div className={styles.tabHeaderWapper} >


                        <div
                            onClick={() => handleTabChange("0")}
                            className={tabActive === "0" ? `${styles.revisionTab} ${styles.revisionTabActive} ` : `${styles.revisionTab}`}>Specification</div>
                        <div
                            onClick={() => handleTabChange("1")}
                            className={tabActive === "1" ? `${styles.revisionTab} ${styles.revisionTabActive} ` : `${styles.revisionTab}`}>Attributes</div>
                        <div
                            onClick={() => handleTabChange("2")}
                            className={tabActive === "2" ? `${styles.revisionTab} ${styles.revisionTabActive} ` : `${styles.revisionTab}`}>Documents</div>
                    </div>
                    {
                        tabActive === "1" &&
                        <div className={styles.searchContainer}>
                            <div className={styles.searchBox}>
                                <input type="text" placeholder="Search" value={jumpSearch} onChange={(e) => setJumpSearch(e.target.value)} />
                            </div>

                            {
                                jumpSearch.length > 0 &&

                                <>

                                    <div className={styles.jumpListDropDown}>

                                        <ul className={styles.jumpToRowList}>
                                            {tableRows.filter((row) => row.name.trim().toLowerCase().includes(jumpSearch.toLowerCase())).map((row) => {
                                                return (
                                                    <li onClick={() => handleSelect(row.name)}>{row.name}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>

                                </>
                            }

                        </div>
                    }
                </div>

                <div className={styles.tabBodyMain} >

                    {tabActive === "0" && <SpecificationTab

                        specificationRemark={specificationRemark}
                        setSpecificationRemark={setSpecificationRemark}
                        approverRemarks={allTabsRemarks?.specification}
                        approovalStatus={productDetails.revisionApprovalStatus}
                        data={specificationArray}
                        viewType={"WAPPROVAL"}
                        showToast={showToast}

                    />}
                    {tabActive === "1" && <AttributeTab
                        tableRows={tableRows}
                        setTableRows={setTableRows}
                        showToast={showToast}
                        columns={columns}
                        setColumns={setColumns}
                        duplicateRow={duplicateRow}
                        attributeRemark={attributeRemark}
                        setAttributeRemark={setAttributeRemark}
                        approverRemarks={allTabsRemarks?.attributes}
                        approovalStatus={productDetails.revisionApprovalStatus}
                        viewType={"WAPPROVAL"}
                    />}
                    {tabActive === "2" && <DocumentsTab
                        data={documentsArray}
                        showToast={showToast}
                        documentRemark={documentRemark}
                        setDocumentRemark={setDocumentRemark}
                        approverRemarks={allTabsRemarks?.documents}
                        approovalStatus={productDetails.revisionApprovalStatus}

                        viewType={"WAPPROVAL"}


                    />}

                </div>




            </div >


            <CompareRankingModal
                open={rankingModal}
                onClose={handleRankingModal}
                data={vendorRankings}
                total={tableRows.length}
                type={"attributes"}
            />

            <RenameModal
                open={renameModal}
                onClose={handleRenameModal}
                onSubmit={handleChangeRevisionName}
                prefilled={productDetails?.name}
                header="Rename"
            />

            <RevokeRequestModal
                open={withdrawalModal}
                onClose={handleWithdrawalModal}
                onCancel={handleWithdrawalModal}
                onSubmit={handleWithdrawalSubmit}
                prefilled={""}
                header={"Revoke Request"}
            />





            <TaskHistoryModal
                open={taskHistoryModal}
                onClose={handleTaskHistoryModal}
                header={"Task History"}
                showToast={showToast}
                revisionId={revisionId}
                handleRevokeRequest={handleRevokeRequestModal}
            />








            <RevokeRequestModal
                open={revokeRequestModal}
                onClose={() => { handleRevokeRequestModal(); }}
                onCancel={() => { handleRevokeConfirmationModal(); handleRevokeRequestModal(); }}
                onSubmit={() => { handleRevokeConfirmation(true, revokeRequestData.revokeId); handleRevokeRequestModal() }}
                prefilled={revokeRequestData?.reason}
                header={"Revoke Request"}
                readOnly={true}
                confimBtnText={"Approve"}
                discardText={"Reject"}
            />


            <DeleteConfirmation
                onCancel={handleRevokeConfirmationModal}
                onConfirm={() => { handleRevokeConfirmation(false, revokeRequestData.revokeId); handleRevokeConfirmationModal() }}
                open={revokeConfirmationModal}
            // confimBtnText={"Approve"}
            // discardText={"Reject"}
            >
                <h2>Are you sure you want to revoke?</h2>
                <p>All related activities will be reversed, and this action will stop any more
                    workflow approvals or denials. Once done it cant be undone!</p>

            </DeleteConfirmation>

            <DeleteConfirmation
                onCancel={handleRejectModal}
                onConfirm={() => { handleDecisionOnRevision(false); handleRejectModal() }}
                open={rejectModal}
                confimBtnText={"Reject"}
            // discardText={""}
            >
                <h2>Reject Request</h2>
                <p>Are you sure you want to Reject this request?</p>

            </DeleteConfirmation>




            <ChatPannel
                open={chatSlider}
                onClose={handleChatSlider}
            >
                <>
                    <ChatHeader
                        onClose={handleChatSlider}
                        downloadData={revisionChats.map((chat) => {
                            return { name: chat.name, date: chat.createdAt, chat: chat.remark };
                        })}
                        helpData={{ link: "tosomewhere" }}
                        chatName={productDetails?.name}
                    >
                        <h3>{productDetails?.name}</h3>
                    </ChatHeader>

                    <ChatRows>

                        {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
                            return (

                                <ChatRow key={index} name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} email={rev.email} />
                            )
                        })
                            :
                            <p style={{ textAlign: "center" }}>
                                No Previous Chat
                            </p>
                        }

                    </ChatRows>

                    <ChatInputField onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

                </>
            </ChatPannel>










        </>
    )
}

export default TechnicalApprovalRevisionView
