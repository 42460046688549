import React, { useState } from 'react'
import StyleProc from './ProcaStatistics.module.css'
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const ProcaStatistics = () => {
    // State for managing the active state of the first button
    const [isFirstButtonActive, setFirstButtonActive] = useState(true);

    // Function to handle click on the first button
    const handleFirstButtonClick = () => {
        // Set the first button active
        setFirstButtonActive(true);
    }

    // Function to handle click on the second button
    const handleSecondButtonClick = () => {
        // Set the first button inactive
        setFirstButtonActive(false);
    }

    // State for managing the checked state of the checkbox
    const [isChecked, setIsChecked] = useState(false);

    // Function to handle checkbox state change
    const handleCheckboxChange = () => {
        // Toggle the checkbox state
        setIsChecked(!isChecked);
    };

    return (
        <div className={StyleProc.MainStatic}>
            <div className={StyleProc.StartStatic}>
                <div>
                    <h1>Unlock efficiency in procurement
                        source smarter, save easier.</h1>
                </div>
                {
                    isFirstButtonActive ? (
                        <div className={StyleProc.sliderStatic}>
                            <div>
                                <div className={StyleProc.sliderButton}>
                                    <Button variant="contained" onClick={handleFirstButtonClick}
                                        style={{
                                            backgroundColor: isFirstButtonActive ? '#16469d' : '#fff',
                                            color: isFirstButtonActive ? '#fff' : '#90a3bf',
                                        }}

                                    >With PROCA</Button>
                                    <Button variant="contained" onClick={handleSecondButtonClick}
                                        style={{
                                            backgroundColor: isFirstButtonActive ? '#fff' : '#16469d',
                                            color: isFirstButtonActive ? '#90a3bf' : '#fff',
                                        }}
                                    >Without PROCA</Button>

                                </div>

                                <div className={isFirstButtonActive ? StyleProc.fadeIn : StyleProc.fadeOut}>
                                    <h2>Streamline your success, eliminate procurement stress</h2>
                                    <ul className={StyleProc.firstUlListStyle} >
                                        <li><CheckCircleIcon sx={{ width: '50px' }} />Take control of your RFx with effortless management and tailored multi-step approvals for procurement success.</li>
                                        <li><CheckCircleIcon />Discover a vast vendor pool and put your personal touch on it by adding your own favorites.</li>
                                        <li><CheckCircleIcon />Effortlessly distribute tasks to your team and maintain a real-time pulse on all activities, all within your grasp.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className={StyleProc.SliderImg}>
                                <img
                                    src={
                                        isFirstButtonActive
                                            ? 'https://res.cloudinary.com/dtffniutw/image/upload/v1698422833/Success_Stories_e9onfb.png'
                                            : 'https://res.cloudinary.com/dtffniutw/image/upload/v1698592374/Screenshot_2023-10-29_203444_mzdjbm.png'
                                    }
                                    alt=""
                                    className={isFirstButtonActive ? StyleProc.ImageRotateIn : StyleProc.ImageRotateOut
                                    }
                                />
                            </div>
                        </div>
                    ) : (
                        <div className={StyleProc.sliderStatic}>
                            <div>
                                <div className={StyleProc.sliderButton}>
                                    <Button variant="contained" onClick={handleFirstButtonClick}
                                        style={{
                                            backgroundColor: isFirstButtonActive ? '#16469d' : '#fff',
                                            color: isFirstButtonActive ? '#fff' : '#90a3bf',
                                        }}

                                    >With PROCA</Button>
                                    <Button variant="contained" onClick={handleSecondButtonClick}
                                        style={{
                                            backgroundColor: isFirstButtonActive ? '#fff' : '#16469d',
                                            color: isFirstButtonActive ? '#90a3bf' : '#fff',
                                        }}
                                    >Without PROCA</Button>
                                </div>
                                <div className={isFirstButtonActive ? StyleProc.fadeIn : StyleProc.fadeOut}>
                                    <h2>Where Inefficiency and Risk Collide.</h2>
                                    <ul className={StyleProc.secondUlListStyle}>
                                        <li><CheckCircleIcon />Results in higher labor costs due to the time and effort
                                            required for paperwork and communication.</li>
                                        <li><CheckCircleIcon />Sensitive procurement information is at risk of being lost or
                                            accessed by unauthorized personnel.</li>
                                        <li><CheckCircleIcon />It may become increasingly challenging to manage
                                            procurement activities as the company expands.</li>
                                    </ul>
                                </div>
                            </div>

                            <div className={StyleProc.SliderImg}>
                                <img
                                    src={
                                        isFirstButtonActive
                                            ? 'https://res.cloudinary.com/dtffniutw/image/upload/v1698422833/Success_Stories_e9onfb.png'
                                            : 'https://res.cloudinary.com/dtffniutw/image/upload/v1698592374/Screenshot_2023-10-29_203444_mzdjbm.png'
                                    }
                                    alt=""
                                    className={isFirstButtonActive ? StyleProc.ImageRotateIn : StyleProc.ImageRotateOut
                                    }
                                />
                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    )

}


export default ProcaStatistics
