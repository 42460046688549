import React, { useEffect, useState } from 'react'
import cStyle from 'components/ModalCommonCSS/ModalCommonCSS.module.css'
import style from './SendForApprovalModal.module.css'
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';


const SendToVendorModal = ({ open, onClose, onSubmit, prefilled, header }) => {

    const [name, setName] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onSubmit({ name: name })
        setName("")
    }
    useEffect(() => {
        // Update the state when the prefilled prop changes
        setName(prefilled);
    }, [prefilled]);



    return (
        <Modal
            open={open}
            onClose={onClose}

        >
            <div className={cStyle.modalContainer}>

                <form action="" onSubmit={handleSubmit} className={style.formContainer} >
                    <div className={style.modalHeader} >

                        <h3>
                            <span>
                                <PeopleOutlineIcon />
                            </span>
                            {header}
                        </h3>
                        <span onClick={() => { setName(""); onClose() }}>
                            <CloseIcon />
                        </span>
                    </div>

                    <div className={style.formGroup}>
                        <label htmlFor="comment">Comments (if any)</label>

                        <textarea type="text" id='comment' name='comment' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>


                    <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                        <button className={`${cStyle.modalBtn} ${cStyle.pimaryModalBtn}`} type='submit'>Send </button>
                        <p onClick={() => { setName(""); onClose() }}
                            className={`${cStyle.modalBtn} ${cStyle.secondaryModalBtn}`}>Cancel</p>
                    </div>


                </form>

            </div>
        </Modal>
    )
}

export default SendToVendorModal
