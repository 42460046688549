import { useState, useEffect, useRef } from 'react';
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import UsersTaskskDisplay from './CommercialTaskDisplay/UsersTasksDisplay'
import AdminKAMTaskDisplay from './CommercialTaskDisplay/AdminKAMTaskDisplay'
import useAuth from 'hooks/useAuth';
import { PrivateComponent } from 'api/axios'
import Modal from '@mui/material/Modal';
import formStyle from '../../../components/FormsCommonCss/FormsCommonCss.module.css'
import CloseIcon from '@mui/icons-material/Close';
import CreateTaskCommercial from './TasksModals/CreateTaskCommercial';
import EditTasksCommercial from './TasksModals/EditTasksCommercial';
import useBackdrop from 'hooks/useBackdrop';
import TaskStyle from "../../TechnicalSpecification/TaskTable/TaskDisplay.module.css"
import Alert from '@mui/material/Alert';
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import commercialStyle from './commercialStyles.module.css';
import { Link } from 'react-router-dom';
import FetchUsersComponent from 'components/FetchUsersComponent/FetchUsersComponent'



const CreateTaskForCommercial = ({ currentUser }) => {

  // Custom hook for managing backdrop loader
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

  // State for managing the create task modal visibility
  const [createTaskModal, setCreateTaskModal] = useState(false);

  // Custom hook for authentication
  const { auth } = useAuth();

  // Axios instance for private requests
  const privateAxios = PrivateComponent();

  // Reference for email input
  const emailRef = useRef();

  // State for managing tab visibility
  const [tabOpen, settabOpen] = useState({
    kam: false,
    admin: false,
    user: false
  })

  // State for managing edit task modal visibility
  const [editTaskModal, setEditTaskModal] = useState(false)

  // State for form data
  const [formData, setFormData] = useState({
    email: "",
    accessRole: "",
    designation: "",

  })

  // State for form errors
  const [formError, setFormErrors] = useState({
    email: "",
    accessRole: "",
    designation: "",

    formError: ""
  })

  // State for edit data
  const [editData, setEditData] = useState({})//Edit data State



  // State for tab display settings
  const [tabShow, setTabShow] = useState({
    KAM: true,
    USER: true,
    ADMIN: true,
  })

  // State for tab label display settings
  const [tabLabelShow, setLabelTabShow] = useState({
    KAM: true,
    USER: true,
    ADMIN: true,
  })

  // State for tab assignment display settings
  const [tabAssignShow, setAssignTabShow] = useState({
    KAM: true,
    USER: true,
    ADMIN: true,
  })
  const [searchValue, setSearchValue] = useState("") // State for search value
  const [userData, setUserData] = useState([]) // State for user data
  const [addKamModal, setaddKamModal] = useState(false);// State for add KAM modal visibility
  const [workspacedropDownOpen, setWorkspaceDropDownOpen] = useState(false) // State for workspace dropdown visibility
  const [workSpaceSelected, setWorkSpaceSelected] = useState("ALL");  // State for selected workspace
  const [totalUser, setTotalUser] = useState(""); // State for total user count

  const [priorityData, setPriorityData] = useState([]);// State for priority data
  const [assignData, setAssignData] = useState([]);// State for assignment data

  const handlecreateTaskModal = () => {// Handler for toggling create task modal
    setCreateTaskModal(prev => !prev)
  }
  const handleEditTaskModal = () => {// Handler for toggling edit task modal
    console.log(editData)
    setEditTaskModal(prev => !prev)
  }
  const handleaddKamModal = () => { // Handler for toggling add KAM modal and resetting form
    setaddKamModal(prev => !prev);

    setFormData({
      email: "",
      accessRole: "",
      designation: "",

    }
    )

    setFormErrors({
      email: "",
      accessRole: "",
      designation: "",
      formError: ""
    })

  }

  const handleInput = (e) => { // Handler for form input changes
    setFormData(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      }
    })

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: '',
      formError: "",
    }));

  }
  const handleWorkspaceSorter = (value) => { // Handler for workspace sorter
    setWorkspaceDropDownOpen(false);
    setWorkSpaceSelected(value);

  }

  //------------------------------------- get all ts tasks-----------------------------------

  const [data, setData] = useState([]) // State for tasks data

  // Function to get all tasks
  const getAllTasks = async () => {
    openBackdrop();

    try {
      // Check if user is admin
      if (auth.roles === "ADMIN") { //applied condition if his role is Admin then hit then api else no.
        const response = await privateAxios.get(`/commercialTask/getCommercialTaskByRoles`) // Get all Commercial tasks Displayed 
          .then((res) => {
            setData(res.data);// Set tasks data
            if (res.status === 200) {
              const data = res.data;
              closeBackdrop();//close loader
            }
            closeBackdrop();//close loader 

          }).catch((error) => {
            closeBackdrop();//close loader
          }
          )
      } else {
        // If not admin, still fetch tasks
        const response = await privateAxios.get(`/commercialTask/getCommercialTaskByRoles`)
          .then((res) => {
            setData(res.data);//set Data

            if (res.status === 200) {
              const data = res.data;
              closeBackdrop(); //close loader
            }
            closeBackdrop();

          }).catch((error) => {
            closeBackdrop();//close loader
          }
          )
      }

    } catch (error) {
      closeBackdrop();//close loader
    }

  }
  // Effect to fetch all tasks on component mount
  useEffect(() => {
    getAllTasks()

  }, []);

  // State for managing task page for pagination
  const [taskPage, setTaskPage] = useState(1);//add Page
  // Function to load more tasks for pagination
  const loadMore = async (page, size) => {
    try {
      const response = await privateAxios.get(`/commercialTask/getCommercialTaskByRoles?pageNo=${page}&pageSize=${size}`)
        .then((res) => {
          setData(prev => {// Append new tasks to existing tasks
            return [...prev, ...res.data]
          });

          setTaskPage(prev => prev + 1);// Increment page number

        }).catch((error) => {
        }
        )
    } catch (error) {
    }
  }


  // ----------------------------filter-Status-----------------------------

  // State for status dropdown visibility
  const [statusDropDownOpen, setStatusDropDownOpen] = useState(false);

  // State for selected status
  const [statusSelected, setStatusSelected] = useState("All");

  // State for showing success alert
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  // Function to fetch data based on status filter
  const fetchData = async (value) => {
    setStatusSelected(value)
    setStatusDropDownOpen(false)
    setStatusSelected(value)
    openBackdrop();
    try {
      let param = "ALL"
      switch (value.toLowerCase()) {
        case "to do": param = "TODO";
          break;
        case "in progress": param = "INPROGRESS";
          break;
        case "done": param = "DONE";
          break;
        case "completed": param = "COMPLETED";
          break;
        default: param = "ALL"
      }
      if (param === "ALL") {
        const response = privateAxios.get(`/commercialTask/getCommercialTaskByRoles`).then((res) => {
          setData(res.data);
          closeBackdrop();
        }).catch(err => {
          closeBackdrop();
        })
      } else {
        const response = privateAxios.get(`/commercialTask/getCommercialTaskByStatus?status=${param}`).then((res) => {
          setData(res.data);
          closeBackdrop();
        })
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      closeBackdrop();
    }
  };





  // --------------------------------------------------------------get all Assign------------------------------------------------

  const [assignDropDownOpen, setAssignDropDownOpen] = useState(false)
  const [assignSelected, setAssignSelected] = useState("All");

  const workspaceId = 2;
  //Fetch Users components use Param 
  const [loggedIn, userList, notLoggedIn] = FetchUsersComponent({ orgId: auth.orgId, workspaceId: workspaceId })

  // --------------------------------------------------------------Filter-Assign-----------------------------------------------------------------
  const fetchData2 = async (email) => {
    // console.log(email)
    setAssignSelected(email)
    openBackdrop();
    try {
      if (email.toLowerCase() === "all") {
        getAllTasks();//Get all tasks 
        closeBackdrop();//Close loader
      } else {
        await privateAxios.get(`/commercialTask/getCommercialTaskByAssigned?email=${email}`).then((res) => {
          // console.log(res.data);
          setData(res.data);
          closeBackdrop();//Close loader
        }).catch(err => {
          closeBackdrop();//Close loader
        })
      }
      setAssignDropDownOpen(false)
      closeBackdrop(); //Close loader
    } catch (error) {
      closeBackdrop();//Close loader
    }
  };

  const [labelDropDownOpen, setLabelDropDownOpen] = useState(false)
  const [labelSelected, setLabelSelected] = useState("All");

  const fetchData3 = async (value) => {
    setLabelDropDownOpen(false)
    setLabelSelected(value)
    openBackdrop();
    try {
      let param = "ALL"
      switch (value.toLowerCase()) {
        case "low": param = "LOW";
          break;
        case "medium": param = "MEDIUM";
          break;
        case "high": param = "HIGH";
          break;
        default: param = "ALL"
      }
      if (param === "ALL") {
        const response = privateAxios.get(`/commercialTask/getCommercialTaskByRoles`).then((res) => {
          // setStatusDropDownOpen(response.data);

          setData(res.data);//Set Data      
          closeBackdrop(); //Close loader

        }).catch(err => {
          closeBackdrop();//Close loader
        })
      } else {
        const response = privateAxios.get(`/commercialTask/getCommercialTaskByPriority?priority=${param}`).then((res) => {
          // setStatusDropDownOpen(response.data);
          setData(res.data);//Set Data
          closeBackdrop();//Close loader
        }).catch(err => {
          closeBackdrop();//Close loader
        })
      }

    } catch (error) {
      // console.error('Error fetching data:', error);
      closeBackdrop();//Close loader
    }
  };


  // ---------------------------------------------------------Seraching-Api-------------------------------------------------------------


  //Serach Api 
  const handleSerachChange = async (e) => {
    setSearchValue(e.target.value)
    try {
      if (e.target.value === "") {
        getAllTasks();//Get all tasks 
      }
      else {
        await privateAxios.post(`/task/searchTask?title=${e.target.value}&description=${e.target.value}`).then(res => {
          setData(res.data);//Set data
        }).catch(err => {
        })
      }

    } catch (error) {
    }
  };

  //This is validations 
  const handleValidation = (e) => {
    const { name, value } = e.target;

    let errorMessage = '';//empty errormessage 1st time

    if (name === 'email') {

      if (value.trim() === '') {
        errorMessage = 'Add atleast One email address';
      }
    } else if (name === 'accessRole') {
      if (value.trim() === '') {
        errorMessage = 'Select an Access Role';
      }
    }
    else if (name === 'designation') {
      if (value.trim() === '') {
        errorMessage = 'Select a Designation';
      }
    }



    setFormErrors((prevErrors) => ({// form error
      ...prevErrors,
      [name]: errorMessage,
      formError: ""
    }));
  }
  const handleInviteUser = async (e) => {//Invite Users
    e.preventDefault();
    setFormErrors(prev => {
      return {
        ...prev,
        formError: ""
      }
    })
    const hasErrors = Object.values(formError).some((error) => error !== '');
    const notEmpty = Object.values(formData).some((dd) => dd.length < 1);
    if (!hasErrors && !notEmpty) {
      const emailArr = formData.email.split(",").map((email) => {
        return email.trim().toLowerCase();
      });

      await privateAxios.post(`/adminuser/inviteUser/${auth.orgId}`,
        { emails: emailArr, role: formData.accessRole, designation: formData.designation, workspace: [`Technical Specifications`] },

      )
        .then((res) => {
          if (res.status === 200) {
            setShowSuccessAlert(true);
            const data = res.data;
            getAllUser();
            handleaddKamModal();
          }
          closeBackdrop();//Close loader
        }).catch((err) => {
          setFormErrors(prev => {
            return {
              ...prev,
              formError: err?.response?.data.message || "Something Went Wrong"
            }
          })

          closeBackdrop();//Close loader
        }
        )

    } else {
      setFormErrors(prev => {
        return {
          ...prev,
          formError: "Please Check all the Feilds"
        }
      })
    }
  }

//Get all users
  const getAllUser = async () => {
    try {
      //This is get allusers API
      await privateAxios.get(`/procurement/getAllUsers/${auth.orgId}`)
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            const dataArr = [];
            const groupedData = {};
            res.data.data.employees.forEach(entry => {
              const role_name = entry?.user.entityType;
              if (!groupedData[role_name]) {
                groupedData[role_name] = [];
              }
              groupedData[role_name].push(entry);
            })
            let tt = 0;

            if (res.data?.KAM) {
              tt += res.data.kamEntity.length;
            }

            if (groupedData?.USER) {
              tt += groupedData.USER.length;
            }

            if (groupedData?.ADMIN) {
              tt += groupedData.ADMIN.length;
            }

            setTotalUser(tt)
            setUserData({
              USER: groupedData?.USER,
              KAM: res.data?.KAM,
              ADMIN: groupedData?.ADMIN
            })
          }

        }).catch((err) => {
        }
        )

    } catch (error) {
    }
  }
//useEffect hook to each fetch users
  useEffect(() => {
    getAllUser();
  }, [])

  useEffect(() => {
    let timeoutId;

    if (showSuccessAlert) {
      timeoutId = setTimeout(() => {
        setShowSuccessAlert(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showSuccessAlert]);


  return (
    <div className={workSpaceStyle.workSpaceMain}>

      <div className={workSpaceStyle.workSpaceHeader}>
        <div className={workSpaceStyle.workSpaceHeaderLeft}>
          <h1 style={{ gap: '10px' }}><span style={{ fontSize: "20px" }}><LayersOutlinedIcon /></span> Commercial </h1>
          <p style={{ marginLeft: '40px' }}>All tasks <span>{data.length}</span></p>
        </div>
        
        
        <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
          <input type="text"
            onChange={handleSerachChange}
            value={searchValue}
            placeholder='Search Here...' />
        </div>
        <div className={workSpaceStyle.workSpaceHeaderRight} style={{ minWidth: '250px', justifyContent: 'flex-end' }}>


          {(auth.roles === "KAM" || auth.roles === "ADMIN") && <div className={TaskStyle.addAssignMain} style={{ display: 'flex', width: '20%', justifyContent: 'center', alignItems: 'center' }}>

            <div className={TaskStyle.addAssign} style={{ width: '100%' }}>

              {userList.length > 0 && userList.slice(0, 3).map((item, index) => {
                const colorArray = ["#0077b6", "#f77f00", "#a7c957"];
                const randomIndex = Math.floor(Math.random() * colorArray.length);
                const randomColor = colorArray[randomIndex];

                return (
                  <div key={index} className={TaskStyle.name1} style={{ backgroundColor: randomColor, color: '#fff' }}>
                    <ProfileIcon data={{ email: item }} height={"26px"} width={"26px"} showProfileBanner={false} />
                  </div>
                );
              })}

              {(auth.roles === "KAM" || auth.roles === "ADMIN") && <div className={TaskStyle.name3} onClick={getAllUser}>
                <Tooltip title='Add'>
                  <DataSaverOnOutlinedIcon
                    onClick={handleaddKamModal} />
                </Tooltip>
              </div>
              }
            </div>
          </div>}


          {
            auth.roles === "KAM" &&
            <div >
              <Link to={"templates"} className={commercialStyle.templateStyle}>
                <button>Templates</button>
              </Link>
            </div>
          }


          <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>

            {auth.roles === "KAM" && (
              <Tooltip title="Help">
                <a target="_blank" href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000080850-commercial">
                  <HelpOutlineOutlinedIcon />
                </a>
              </Tooltip>
            )}

            {auth.roles === "ADMIN" && (
              <Tooltip title="Help">
                <a target="_blank" href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081390-create-commercial-task">
                  <HelpOutlineOutlinedIcon />
                </a>
              </Tooltip>
            )}

            {auth.roles === "USER" && (
              <Tooltip title="Help">
                <a target="_blank" href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081388-user-display-commercial-template-task">
                  <HelpOutlineOutlinedIcon />
                </a>
              </Tooltip>
            )}

          </div>
        </div>

      </div>
      <div>

        {["USER"].includes(auth.roles) && <UsersTaskskDisplay data={data} setData={setData} getAllUSers={getAllTasks} setEditData={setEditData} handleEditTaskModal={handleEditTaskModal} search={searchValue} updatedData={data} handlecreateTaskModal={handlecreateTaskModal} loadMore={loadMore} taskPage={taskPage} setTaskPage={setTaskPage} />}
        {["KAM", "ADMIN"].includes(auth.roles) && <AdminKAMTaskDisplay getAllKam={getAllTasks} editData={editData} setEditData={setEditData} search={searchValue} updatedData={data} priorityData={priorityData} assignData={assignData} handlecreateTaskModal={handlecreateTaskModal} handleEditTaskModal={handleEditTaskModal} loadMore={loadMore} taskPage={taskPage} setTaskPage={setTaskPage} />}

      </div>
      <BackdropComponent />

      <Modal //Add KAM modal
        open={addKamModal}
      >
        <div className={workSpaceStyle.modalCont}>

          <form action="" onSubmit={handleInviteUser} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader}
            >

              <h3>Invite members on board</h3>
              <div onClick={handleaddKamModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <label htmlFor=""> Email:</label>
              <input onChange={handleInput} autoComplete='off' name='email' onBlur={handleValidation} ref={emailRef}
                type="text" placeholder='Enter Emails Separated by commas' />
              <span>{formError.email}</span>
            </div>


            <div className={formStyle.inputRow}>
              <label htmlFor=""> Access Role:</label>

              <select
                value={formData.accessRole}
                onChange={handleInput}
                className={formStyle.formSelect}
                name='accessRole'
                onBlur={handleValidation}
              >
                <option value="" style={{ color: "#878699" }}>Plese Select a Role</option>
                <option value="ADMIN">Admin</option>
                <option value="USER">User</option>
              </select>
              <span>{formError.accessRole}</span>
            </div>
            <div className={formStyle.inputRow}>
              <label htmlFor=""> Designation :</label>
              <input onChange={handleInput} autoComplete='off' value={formData.designation} name='designation' onBlur={handleValidation}
                type="text" placeholder='Please Enter A designation' />


              <span>{formError.designation}</span>
            </div>
            <div className={formStyle.inputRow}>
              <label htmlFor=""> Assign A Workspace:</label>


              <input onChange={handleInput} autoComplete='off' value={formData.workSpace} name='workSpace' onBlur={handleValidation}
                type="text" disabled placeholder='Commercial' style={{ backgroundColor: '#CECECE' }} />
              <span>{formError.workSpace}</span>
            </div>

            <div>
              <button style={{ fontSize: '15px' }} className={formStyle.modalSmallPrimaryButton} type='submit'>Invite </button>
            </div>

            <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span>
          </form>

        </div>
      </Modal>

      <Modal //Create Modal
        open={createTaskModal}
        onClose={handlecreateTaskModal}
      >
        <CreateTaskCommercial getAllTasks={getAllTasks} handlecreateTaskModal={handlecreateTaskModal} handleEditTaskModal={handleEditTaskModal} />

      </Modal>

      <Modal //Edit Modal
        open={editTaskModal}
        onClose={handleEditTaskModal}
      >
        <EditTasksCommercial getAllTasks={getAllTasks} handleEditTaskModal={handleEditTaskModal} editData={editData} setEditData={setEditData} />

      </Modal>
      <div>
        {showSuccessAlert && (
          <Alert severity="success" sx={{//show masseges
            display: 'flex',
            justifyContent: 'left',
            position: 'absolute',
            left: '50%',
            top: '80%',
            zIndex: '500',
            width: '20%'
          }}>Invite sent successfully</Alert>
        )}
      </div>
    </div>
  )
}

export default CreateTaskForCommercial


