import React from 'react'
import superadminStyle from '../../commonCss.module.css'
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch'
import { useNavigate, useParams } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';
import DateFormater from 'utility/DateFormater';



const CommercialTab = ({ openBackdrop, closeBackdrop, getCommercialData, showToast, data }) => {
  //Destructure orgID from URl param
  const { orgId } = useParams();

  //Get private axios instance of privateComponent
  const privateAxios = PrivateComponent();

  //Get navigate function from usenavigate hooks
  const navigate = useNavigate();

  //Define a function to handle checkbox change 
  const handleProductCheckBox = async (id, type) => {
    openBackdrop();//Open backdrop
    try {
      let url = "";//Determine URl based on type
      if (type) {
        url = `/procureadmin/DisableTemplateForOrganisation`
      }
      else {

        url = `/procureadmin/EnableTemplateForOrganisation`
      }
      //Send a Post request to Enable/Disable template
      const res = await privateAxios.post(`${url}/${id}/${orgId}`);
      if (res.status === 200) {
        //If request to successfull
        if (!type) {
          //Show success massege based on type
          showToast("success", "Template Enabled for Organaistion");
        }
        else {
          showToast("success", "Template Disabled for Organaistion");
        }
      }
      //Fetch Update commercial data 
      getCommercialData();
    } catch (error) {
      //Show error massege if request fails
      showToast("error", error.message)
    }
    //Close backdrop Regardless of success of failure
    closeBackdrop();//Close Backdrop
  }






  return (
    <table className={superadminStyle.admintable}>
      <thead>
        <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
          <th style={{ width: "45%" }}>Commercials</th>
          <th>Requested On </th>
          <th>Active </th>
          <th>Action </th>
        </tr>
      </thead>
      <tbody>
        {
          data.map((item, index) => {
            return (
              <tr className={superadminStyle.admintableRow}>

                <td>
                  {item.name}
                </td>
                <td>
                  {DateFormater(item.requestedOn)}
                </td>
                <td>
                  <div className={superadminStyle.actionIcons}>

                    <ToggleSwitch checked={!item.isDisable} name={`checkBox_`}
                      onChange={() => handleProductCheckBox(item.id, !item.isDisable)}
                    />
                  </div>
                </td>
                <td>
                  <p style={{ color: "#16469D", textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => { navigate(`/superadmin/commercial/organisation-by-commercial/${item.id}/${item.productId}/${orgId}`) }}>
                    View Details
                  </p>
                </td>
              </tr>
            )
          })
        }

      </tbody>
    </table>
  )
}

export default CommercialTab
