import React, { useState, useEffect } from 'react';
import superadminStyle from '../../commonCss.module.css';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { PrivateComponent } from 'api/axios';
import DateFormater from 'utility/DateFormater';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

const ProductsTab = ({ openBackdrop, closeBackdrop, showToast, getProductData, data, productsData }) => {
  // Custom axios instance for private requests
  const privateAxios = PrivateComponent();
  // Get the URL parameters using react-router's useParams hook
  const { kamId, orgId } = useParams();

  // React router's navigation hook
  const navigate = useNavigate();

  // Handle checkbox action for enabling/disabling product
  const handleCheckBox = async (orgId, id, type) => {
    openBackdrop();
    try {
      let url = '';
      // Determine the API endpoint based on the action type
      if (type) {
        url = `/procureadmin/DisableProductForOrganisation`;
      } else {
        url = `/procureadmin/EnableProductForOrganisation`;
      }

      // Make API request to enable/disable product
      const res = await privateAxios.post(`${url}/${id}/${orgId}`);
      if (res.status === 200) {
        // Show success message based on action type
        if (!type) {
          showToast('success', 'Product Enabled for Organization');
        } else {
          showToast('success', 'Product Disabled for Organization');
        }
      }

      // Fetch updated product data after action
      getProductData();

    } catch (error) {
      // Show error message if API request fails
      showToast('error', error.message);
    }
    // Close backdrop after operation
    closeBackdrop();
  };

  // Handle view details of a product
  const handleViewDetails = (productId, id) => {
    navigate(`/superadmin/franchises/product/revisions/${productId}/${orgId}`, {
      state: { type: 'Technical-Specification' }
    });
  };


  return (
    <div style={{ maxHeight: '50vh', overflowY: 'auto' }} >
      <table className={superadminStyle.admintable}>
        <thead>
          <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
            <th style={{ width: '45%' }}>Products</th>
            <th>Requested On</th>
            <th style={{ zIndex: '2222' }}>Active</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody >

          {productsData?.map((row, index) => {
            return (
              <tr className={superadminStyle.admintableRow} key={index}>
                <td>{row.productName}</td>
                <td>{DateFormater(row.approvedOn)}</td>
                <td>
                  <div className={superadminStyle.actionIcons}>
                    <ToggleSwitch
                      style={{ index: '0' }}
                      checked={!row.disable}
                      name={`checkBox_index`}
                      onChange={() => handleCheckBox(row.organisationId, row.id, !row.disable)}
                    />
                  </div>
                </td>
                <td>
                  <p
                    style={{ color: '#16469D', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => handleViewDetails(row.productId, row.id)}
                  >
                    View Details
                  </p>
                </td>
              </tr>
            );
          })}

        </tbody>
      </table>
    </div>
  );
};

export default ProductsTab;
