import React, { useState } from 'react'
import superAdminHeaderStyle from './Header.module.css'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SettingsIcon from '@mui/icons-material/Settings';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LogoutIcon from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import workSpaceStyle from '../WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


const SuperAdminHeader = ({ handleDrawerOpen, isOpen }) => {
  // State to manage profile expansion panel open/close
  const [headerProfileExpandOpen, setHeaderProfileExpandOpen] = useState(false)

  // Get authentication state and functions from useAuth hook
  const { auth, setLogout } = useAuth();

  // Initialize navigate function for routing
  const navigate = useNavigate();

  // Function to handle navigation to a specific URL
  const handleNavigate = (url) => {
    navigate(url)
  }

  // Function to generate initials from user's name
  const handleInitals = (name) => {
    const initalsArray = name.split(" ");
    let initial = "";
    if (initalsArray.length > 1) {

      initial = initalsArray[0].charAt(0) + initalsArray[1].charAt(0);
    }
    else {
      initial = initalsArray[0].charAt(0);
    }

    return initial

  }

  // Dispatch function from Redux
  const dispatch = useDispatch();

  // Function to handle user logout
  const handleLogout = () => {
    // Dispatch action to set logout state
    dispatch(setLogout());
    // Navigate to login page after logout
    navigate('/login', { state: { data: null }, replace: true });

  };


  return (
    <div className={superAdminHeaderStyle.headerMain}>
      <div className={superAdminHeaderStyle.headerContent}>
        <div className={superAdminHeaderStyle.MenuTogglerDiv}>
          <div className={superAdminHeaderStyle.hamBurger} onClick={handleDrawerOpen}><MenuIcon /> </div>
          <div className={superAdminHeaderStyle.headerClientLogo}>


            <img src="https://res.cloudinary.com/dxluokypg/image/upload/v1682423845/steel_1_lgk5mz.svg" alt="logo" />



          </div>
        </div>


        <div className={superAdminHeaderStyle.headerLinks}>
          <div className={superAdminHeaderStyle.headerIcons}>

            <Link >
              <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
            </Link>
          </div>
          <div className={superAdminHeaderStyle.headerProfile}
            onClick={() => setHeaderProfileExpandOpen(prev => !prev)}
          >
            {/* auth.profilePic?.newImage ? */}
            {/* <img className={superAdminHeaderStyle.smallProfileIcon}
                src={`${process.env.REACT_APP_BASEURL}/adminuser/file/${auth.profilePic.newImage}`}
              />
              : */}
            <div className={superAdminHeaderStyle.headerProfileimg} >{handleInitals
              //   (auth.name)
              ("SuperAdmin")
            }</div>
            {/*  } */}
            <div className={superAdminHeaderStyle.headerProfileText} >
              <p>
                {/* {auth.name} */}
                SuperAdmin
              </p>
              {
                headerProfileExpandOpen
                  ? <KeyboardArrowUpIcon />
                  : <KeyboardArrowDownIcon />
              }


            </div>



          </div>


          {headerProfileExpandOpen &&
            <>

              <div className={workSpaceStyle.workSpaceHeaderDropDownBackDrop} onClick={() => setHeaderProfileExpandOpen(false)}></div>
              <div className={superAdminHeaderStyle.headerProfileExpand} >
                <Link to={"profile"} className={superAdminHeaderStyle.headerProfileExpandHeader} >
                  {/* {auth.userData.photo?.newImage ?
                    <img className={superAdminHeaderStyle.smallProfileIcon} style={{width:"35px", height:'35px'}}
                      src={`${process.env.REACT_APP_BASEURL}/adminuser/file/${auth.userData.photo.newImage}`}
                    />
                    : */}
                  <div className={superAdminHeaderStyle.headerProfileimg} >{handleInitals("SuperAdmin")
                    // (auth.name)
                  }</div>
                  {/* } */}
                  <p>
                    {/* {auth.name} */}
                    SuperAdmin
                  </p>
                </Link>
                <Divider />
                <ul className={superAdminHeaderStyle.headerProfileExpandList}>
                  <Link ><PermIdentityIcon />Profile</Link>
                  <Link ><SettingsIcon />Settings</Link>
                  <Link><InsertDriveFileIcon />Plans</Link>
                </ul>
                <Divider />
                <ul className={superAdminHeaderStyle.headerProfileExpandList}>
                  <Link onClick={handleLogout} to={"/login"} state={{ data: null }} replace="true"><LogoutIcon />Logout</Link>
                </ul>

              </div>


            </>

          }


        </div>
      </div>
    </div >
  )
}

export default SuperAdminHeader
