import React, { useEffect, useState } from 'react'
import navbarStyle from './Navbar.module.css'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { useNavigate, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useAuth from '../../hooks/useAuth';
import GridViewIcon from '@mui/icons-material/GridView';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import VillaOutlinedIcon from '@mui/icons-material/VillaOutlined';
import VerticalSplitOutlinedIcon from '@mui/icons-material/VerticalSplitOutlined';


const Navbar = ({remainingDays}) => {
    // Initialize navigate and location hooks
  const navigate = useNavigate();
  const location = useLocation()

  // State to store current URL
  const [url, setUrl] = useState([]);

  // Get authentication state from useAuth hook
  const { auth } = useAuth();
  const handleNavigate = (url) => {

    navigate(url)
    setDropDown((prevState) => ({
      ...Object.fromEntries(Object.entries(prevState).map(([key, value]) => [key,false])),
    }));
  }

  useEffect(() => {
    const urlArray = location.pathname.split("/");
    setUrl(urlArray)
  }, [location])

  const handleTabSelected=(tab)=>{
    
    return url.includes(tab);
  }


  // ==================handle dropdown==================

  const [dropDown,setDropDown]=useState({
    shippingTerms:false,
    organisation:false,
    products:false,
    categories:false,
  });




const handleDropDownClick = (item) => {
  setDropDown((prevState) => ({
    ...Object.fromEntries(Object.entries(prevState).map(([key, value]) => [key, key === item ? !value : false])),
  }));
};

  


  return (
    <div className={navbarStyle.navWrapper} >
    <div className={navbarStyle.navMain} >


      <List sx={{ padding: "0px" }} style={{ display: "flex", flexDirection: "column", gap: "8px" }}>

        <ListItem disablePadding onClick={() => handleNavigate("dashboard")}>
          <ListItemButton
            selected={handleTabSelected('dashboard')}
            sx={{
              '&:focus': {
                backgroundColor: '#E3F5FF',
              },
              backgroundColor: handleTabSelected('dashboard') ? '#E3F5FF' : 'transparent',

              borderRadius: "8px"
            }}
          >
            <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
              <GridViewIcon
                sx={{
                  borderRadius: '3px',
                  color: handleTabSelected('dashboard') ? '#16469D' : '#344054',
                }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}

              sx={{
                color: handleTabSelected('dashboard') ? '#16469D' : '#344054',
              }}
            >
              Dashboard

            </ListItemText>

          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={() => handleNavigate("underconstruction")}>
          <ListItemButton
            selected={handleTabSelected('messages')}
            sx={{
              '&:focus': {
                backgroundColor: '#E3F5FF',
              },
              backgroundColor: handleTabSelected('messages') ? '#E3F5FF' : 'transparent',

              borderRadius: "8px"
            }}
          >
            <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
              <TextsmsOutlinedIcon
                sx={{
                  borderRadius: '3px',
                  color: handleTabSelected('messages') ? '#16469D' : '#344054',
                }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}

              sx={{
                color: handleTabSelected('messages') ? '#16469D' : '#344054',
              }}
            >
              Messages

            </ListItemText>

          </ListItemButton>
        </ListItem>


        <ListItem disablePadding onClick={() => handleDropDownClick("organisation")}>
          <ListItemButton

            selected={dropDown.organisation}
            sx={{
              // '&:focus': {
              //   backgroundColor: '#E3F5FF',
              // },
              backgroundColor: dropDown.organisation ? '#E3F5FF' : 'transparent',
              color: dropDown.organisation ? '#16469D' : '#344054',
              borderRadius: "8px"
            }}
          >
            <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
              <LayersOutlinedIcon
                sx={{
                  borderRadius: '3px',
                  color: dropDown.organisation ? '#16469D' : '#344054',
                }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}

              sx={{
                color: dropDown.organisation ? '#16469D' : '#344054',
              }}
            >
              Organisation

            </ListItemText>
            <ExpandMoreIcon />
          </ListItemButton>
        </ListItem>
       
          <Collapse in={dropDown.organisation} timeout="auto" unmountOnExit >
            <List component="div" disablePadding >
              <ListItemButton
                style={{ backgroundColor: '#fff', }}
                sx={{
                  pl: 7,

                  // color: url === 'technical-specification' ? '#16469D' : '#344054',
                }}
                onClick={() => navigate('organisation-lists')}
                selected={handleTabSelected('organisation-lists')}
              >
                <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}
                  sx={{
                    // pl: 7,
                    // backgroundColor: 'transparent',
                    color: handleTabSelected('organisation-lists') ? '#16469D' : '#344054',
                  }}
                >
                  Lists
                </ListItemText>
              </ListItemButton>
            </List>
          </Collapse>
      

      
          <Collapse in={dropDown.organisation} timeout="auto" unmountOnExit >
            <List component="div" disablePadding >
              <ListItemButton
                style={{ backgroundColor: '#fff', }}
                sx={{
                  pl: 7,

                  // color: url === 'technical-specification' ? '#16469D' : '#344054',
                }}
                onClick={() => navigate('organisation-requests')}
                selected={handleTabSelected('organisation-requests')}
              >
                <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}
                  sx={{
                    // pl: 7,
                    // backgroundColor: 'transparent',
                    color: handleTabSelected('organisation-requests') ? '#16469D' : '#344054',
                  }}
                >
                 Requests
                </ListItemText>
              </ListItemButton>
            </List>
          </Collapse>
     
        
     

        {/* mytasks  */}
     
        <>
            <ListItem disablePadding onClick={() => handleDropDownClick("categories")}>
              <ListItemButton
                selected={dropDown.categories}
                sx={{
                  // '&:focus': {
                  //   backgroundColor: '#E3F5FF',
                  // },
                  backgroundColor: dropDown.categories ? '#E3F5FF' : 'transparent',

                  borderRadius: "8px"
                }}
              >
                <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
                  <VerticalSplitOutlinedIcon
                    sx={{
                      borderRadius: '6px',
                      color: dropDown.categories ? '#16469D' : '#344054',
                      // border: '2px solid ',
                    }}
                  />
                </ListItemIcon>
                
                <ListItemText
                  primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}

                  sx={{
                    color: dropDown.categories ? '#16469D' : '#344054',
                  }}
                >
                 Categories

                </ListItemText>
                {/* <span className={navbarStyle.navItemBubble}>
                  10
                </span> */}
                <ExpandMoreIcon />
              </ListItemButton>
            </ListItem>
          
          
            
            
            <Collapse in={dropDown.categories} timeout="auto" unmountOnExit >
              <List component="div" disablePadding >
                {/* {console.log(url)} */}
                <ListItemButton
                  style={{ backgroundColor: '#fff', }}
                  sx={{
                    pl: 7,
                  }}
                  onClick={() => navigate("categories")}
                  selected={handleTabSelected('categories')}
                >
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}
                    sx={{
                      // pl: 7,
                      // backgroundColor: 'transparent',
                      color: handleTabSelected('categories') ? '#16469D' : '#344054',
                    }}
                  >
                   View
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
           
           {/* <Collapse in={dropDown.categories} timeout="auto" unmountOnExit >
              <List component="div" disablePadding >
                <ListItemButton
                  style={{ backgroundColor: '#fff', }}
                  sx={{
                    pl: 7,
                  }}
                  onClick={() => navigate('products-requests')}
                  selected={handleTabSelected('products-requests')}
                >
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}
                    sx={{
                      // pl: 7,
                      // backgroundColor: 'transparent',
                      color: handleTabSelected('products-requests') ? '#16469D' : '#344054',
                    }}
                  >
                    Requests
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
             */}


          </>


          <>
            <ListItem disablePadding onClick={() => handleDropDownClick("products")}>
              <ListItemButton
                selected={dropDown.products}
                sx={{
                  // '&:focus': {
                  //   backgroundColor: '#E3F5FF',
                  // },
                  backgroundColor: dropDown.products ? '#E3F5FF' : 'transparent',

                  borderRadius: "8px"
                }}
              >
                <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
                  <AutoAwesomeMotionOutlinedIcon
                    sx={{
                      borderRadius: '6px',
                      color: dropDown.products ? '#16469D' : '#344054',
                      // border: '2px solid ',
                    }}
                  />
                </ListItemIcon>
                
                <ListItemText
                  primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}

                  sx={{
                    color: dropDown.products ? '#16469D' : '#344054',
                  }}
                >
                 Products

                </ListItemText>
                {/* <span className={navbarStyle.navItemBubble}>
                  10
                </span> */}
                <ExpandMoreIcon />
              </ListItemButton>
            </ListItem>
          
          
            
            
            <Collapse in={dropDown.products} timeout="auto" unmountOnExit >
              <List component="div" disablePadding >
                {/* {console.log(url)} */}
                <ListItemButton
                  style={{ backgroundColor: '#fff', }}
                  sx={{
                    pl: 7,
                  }}
                  onClick={() => navigate('products-lists')}
                  selected={handleTabSelected('products-lists')}
                >
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}
                    sx={{
                      // pl: 7,
                      // backgroundColor: 'transparent',
                      color: handleTabSelected('products-lists') ? '#16469D' : '#344054',
                    }}
                  >
                   lists
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
           
           <Collapse in={dropDown.products} timeout="auto" unmountOnExit >
              <List component="div" disablePadding >
                <ListItemButton
                  style={{ backgroundColor: '#fff', }}
                  sx={{
                    pl: 7,
                  }}
                  onClick={() => navigate('products-requests')}
                  selected={handleTabSelected('products-requests')}
                >
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}
                    sx={{
                      // pl: 7,
                      // backgroundColor: 'transparent',
                      color: handleTabSelected('products-requests') ? '#16469D' : '#344054',
                    }}
                  >
                    Requests
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
            


          </>
      

          <>
            <ListItem disablePadding onClick={() => handleDropDownClick("shippingTerms")}>
              <ListItemButton
                selected={dropDown.shippingTerms}
                sx={{
                  // '&:focus': {
                  //   backgroundColor: '#E3F5FF',
                  // },
                  backgroundColor: dropDown.shippingTerms ? '#E3F5FF' : 'transparent',

                  borderRadius: "8px"
                }}
              >
                <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
                  <VillaOutlinedIcon
                    sx={{
                      borderRadius: '6px',
                      color: dropDown.shippingTerms ? '#16469D' : '#344054',
                      // border: '2px solid ',
                    }}
                  />
                </ListItemIcon>
                
                <ListItemText
                  primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}

                  sx={{
                    color: dropDown.shippingTerms ? '#16469D' : '#344054',
                  }}
                >
                 Commercial

                </ListItemText>
               
                <ExpandMoreIcon />
              </ListItemButton>
            </ListItem>
          
          
            
            
            <Collapse in={dropDown.shippingTerms} timeout="auto" unmountOnExit >
              <List component="div" disablePadding >
                {/* {console.log(url)} */}
                <ListItemButton
                  style={{ backgroundColor: '#fff', }}
                  sx={{
                    pl: 7,
                  }}
                  onClick={() => navigate('commercial-list')}
                  selected={handleTabSelected('commercial-list')}
                >
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}
                    sx={{
                      // pl: 7,
                      // backgroundColor: 'transparent',
                      color: handleTabSelected('commercial-list') ? '#16469D' : '#344054',
                    }}
                  >
                   lists
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
           
           <Collapse in={dropDown.shippingTerms} timeout="auto" unmountOnExit >
              <List component="div" disablePadding >
                <ListItemButton
                  style={{ backgroundColor: '#fff', }}
                  sx={{
                    pl: 7,
                  }}
                  onClick={() => navigate('commercial-requests')}
                  selected={handleTabSelected('commercial-requests')}
                >
                  <ListItemText primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}
                    sx={{
                      // pl: 7,
                      // backgroundColor: 'transparent',
                      color: handleTabSelected('commercial-requests') ? '#16469D' : '#344054',
                    }}
                  >
                    Requests
                  </ListItemText>
                </ListItemButton>
              </List>
            </Collapse>
            


          </>
          <ListItem disablePadding onClick={() => handleNavigate("franchises-list")}>

          <ListItemButton
            selected={handleTabSelected('franchises-list')}
            sx={{
              '&:focus': {
                backgroundColor: '#E3F5FF',
              },
              backgroundColor: handleTabSelected('franchises-list') ? '#E3F5FF' : 'transparent',

              borderRadius: "8px"
            }}
          >
            <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
              <TextsmsOutlinedIcon
                sx={{
                  borderRadius: '3px',
                  color: handleTabSelected('franchises-list') ? '#16469D' : '#344054',
                }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}

              sx={{
                color: handleTabSelected('franchises-list') ? '#16469D' : '#344054',
              }}
            >
              Franchises

            </ListItemText>

          </ListItemButton>
        </ListItem>
          <ListItem disablePadding onClick={() => handleNavigate("kam-approvals")}>

          <ListItemButton
            selected={handleTabSelected('kam-approvals')}
            sx={{
              '&:focus': {
                backgroundColor: '#E3F5FF',
              },
              backgroundColor: handleTabSelected('kam-approvals') ? '#E3F5FF' : 'transparent',

              borderRadius: "8px"
            }}
          >
            <ListItemIcon style={{ all: "revert", marginRight: "12px" }}>
              <PersonAddOutlinedIcon
                sx={{
                  borderRadius: '3px',
                  color: handleTabSelected('kam-approvals') ? '#16469D' : '#344054',
                }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: '15px', fontWeight: '600' }}

              sx={{
                color: handleTabSelected('kam-approvals') ? '#16469D' : '#344054',
              }}
            >
              Kam Approvals

            </ListItemText>

          </ListItemButton>
        </ListItem>
       
    


      </List>



    </div>
    
  
 </div>
  )

 
}

export default Navbar
