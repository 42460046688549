import React, { useEffect } from 'react'
import { useState } from 'react'
import revisionEditStyle from './VendorRevision.module.css'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { PrivateComponent } from 'api/axios';

const myAttribute = [
  {
    id: 1,
    name: 'Brand',
    type: 'General',
    unit: 'kg kilogram',
    value: '30',
  },
  {
    id: 2,
    name: 'Brand',
    type: 'General',
    unit: 'kg kilogram',
    value: '20',
  },
  {
    id: 3,
    name: 'Brand',
    type: 'General',
    unit: 'kg kilogram',
    value: '30',
  },
  {
    id: 4,
    name: 'Brand',
    type: 'General',
    unit: 'kg kilogram',
    value: '20',
  }
]


const VendorAttribute = ({ handleAddColumn, handleAddRow, columns, setColumns, tableRows, setTableRows }) => {
  const privateAxios = PrivateComponent(); // Initialize PrivateComponent
  const [unitOpen, setUnitOpen] = useState(""); // State for keeping track of which unit dropdown is open
  const [units, setUnits] = useState(); // State for units

  // Handler to toggle unit dropdown
  const handleUnitOpen = (id) => {
    if (unitOpen === id) {
      setUnitOpen(""); // Close dropdown if it's already open
    } else {
      setUnitOpen(id); // Open dropdown for the given id
    }
  };

   // Handler to set unit value in table rows
   const handleUnitValue = (value, id) => {
    setTableRows((prev) => {
      return prev.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            unit: value, // Set unit value
            status: "UPDATED", // Update status to 'UPDATED'
          };
        }
        return row; // Return row unchanged if id doesn't match
      });
    });
  };


   // Handler to set value for a specific field in table rows
  const handleValue = (e, id) => {
    setTableRows(prev => {
      return prev.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            [e.target.name]: e.target.value,// Set field value dynamically based on name
            status: "UPDATED", // Update status to 'UPDATED'
          }

        }
        return row;// Return row unchanged if id doesn't match
      })
    })
  }


   // Handler to set remark value in table rows
  const handleRemark = (e, id, index) => {
    const { name, value } = e.target;// Destructure name and value from event target

    setTableRows((prev) =>
      prev.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            remark: row.remark.map((r, i) =>
              i === index ? { ...r, value: value } : r // Update specific remark based on index
            ),
            status: "UPDATED", // Update status to 'UPDATED'
          };
        }
        return row;  // Return row unchanged if id doesn't match
      })
    );
  };


  // Handler to set name value in table rows
  const handleName = (e, id) => {
    setTableRows(prev => {
      return prev.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            name: e.target.value, // Set name value
            status: "UPDATED",// Update status to 'UPDATED'
          }

        }
        return row; // Return row unchanged if id doesn't match
      })
    })
  }

  // Handler to set unit value in table rows
  const hadleUnit = (e, id) => {
    setTableRows(prev => {
      return prev.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            unit: e.target.value, // Set unit value
            status: "UPDATED",// Update status to 'UPDATED'
          }
        }
        return row; // Return row unchanged if id doesn't match
      })
    })

  }






  return (
    <div className={revisionEditStyle.attributeTableMain}>
      <table className={revisionEditStyle.attributeTable}>
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>
                {column === "Add" ? (
                  <span onClick={handleAddColumn}>
                    <AddIcon />
                  </span>
                ) : (
                  column
                )}
              </th>
            ))}
            <th><p></p></th>
          </tr>
        </thead>
        <tbody>
          {tableRows?.map((row, index) => (
            <TableRow
              key={row.id}
              row={row}
              rowIndex={index}
              columns={columns}
              // handleRowDelete={handleRowDelete}
              handleName={handleName}
              handleValue={handleValue}
              handleRemark={handleRemark}
              handleUnitOpen={handleUnitOpen}
              handleUnitValue={handleUnitValue}
              unitOpen={unitOpen}
              units={units}
            //  getUnits={getUnits}
            />
          ))}
        </tbody>
      </table>

      <div className={revisionEditStyle.tableFooterAddAttribute} onClick={handleAddRow}><span  ><AddCircleOutlineIcon /></span>Add Attribute</div>
    </div>
  )
};
const TableRow = ({
  row,
  rowIndex,
  columns,
  handleRowDelete,
  handleName,
  handleValue,
  handleRemark,
  handleUnitOpen,
  handleUnitValue,
  unitOpen,
  getUnits,
  units
}) => {
  return (
    <tr>
      <td >
        <span style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
          <p>{rowIndex + 1}</p>
        </span>
      </td>
      <td style={{ minWidth: "200px" }} className={revisionEditStyle.nameCell}>
        <input type="text" name="name" value={row.name} onChange={(e) => { handleName(e, row.id) }} />
        </td>
      {/* <td style={{ minWidth: "80px" }}>{row.type}</td> */}
      <td style={{ minWidth: "120px", maxWidth: "120px" }} className={revisionEditStyle.nameCell}>
        <input type="text" name="type" value={row.type} onChange={(e) => handleValue(e, row.id)} />
      </td>
      <td style={{ minWidth: "180px", maxWidth: "180px" }} className={revisionEditStyle.nameCell}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          <div style={{ position: "relative" }} className={revisionEditStyle.unitBox} >
            <div 
            style={{ width: "100%",border:"1px solid #B6B6B6",borderRadius:"4px",minHeight:"30px",display:"flex",alignItems:"center",padding:"5px" }}

             onClick={() => handleUnitOpen(row.id)}
             dangerouslySetInnerHTML={{ __html: row.unit }}>
              {/* <ReactQuill modules={{ toolbar: false }} readOnly={true} theme="snow" value={row.unit} style={{ width: "100%", height: "100%" }} /> */}

            </div>

            {/* {unitOpen === row.id &&
              <AttributeUnitSelector getUnits={getUnits} units={units} handleUnitOpen={handleUnitOpen} handleValue={handleUnitValue} value={row.unit} rowId={row.id} />
            } */}
          </div>
        </div>
      </td>
      <td style={{ maxWidth: "120px", minWidth: "100px" }} className={revisionEditStyle.valueField}>
        <input type="text" name="value" value={row.value} onChange={(e) => handleValue(e, row.id)} />
      </td>
      {row.remark.map((remark, index) => (
        <td key={index} style={{ maxWidth: "250px", minWidth: "100px" }} className={revisionEditStyle.remarkField}>
          <textarea
            type="text"
            name={`remark_${row.id}_${index}`}
            value={remark.value}
            onChange={(e) => handleRemark(e, row.id, index)}
          />
        </td>
      ))}
      <td style={{ minWidth: "38px" }}>
        <p></p>
      </td>
      <td style={{ minWidth: "38px" }}>
        <span onClick={() => handleRowDelete(row.id)}>
          <DeleteOutlineIcon />
        </span>
      </td>
    </tr>
  );
};


export default VendorAttribute
