import React, { useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import revisionEditStyle from './VendorRevision.module.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import workSpaceStyle from '../../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import formStyle from '../../../components/FormsCommonCss/FormsCommonCss.module.css'
import Tooltip from '@mui/material/Tooltip';
import ChatIcon from '@mui/icons-material/Chat';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import DocumentChatPannel from 'components/DocumentChat/DocumentChatPannel';
import DocumentChatRows from 'components/DocumentChat/DocumentChatRows';
import DocumentChatInput from 'components/DocumentChat/DocumentChatInput';
import { PrivateComponent } from 'api/axios';
import DocumentChatRow from 'components/DocumentChat/DocumentChatRow';
import useAuth from 'hooks/useAuth';
import { Badge } from '@mui/material';
import ProductRevisonsStyle from '../../ProductRevisions/ProductRevisions.module.css';



const VendorDocuments = ({ addDocumentModal, handleAddDocumentModal, handleGetData, documents, handleDocumentUpload, setDocuments, revisionId }) => {

  // Import necessary hooks and components
  const { auth } = useAuth(); // Authentication context
  const privateAxios = PrivateComponent(); // Private Axios instance for authenticated requests
  const inputRef = useRef(); // Ref for input element

  // State variables
  const [documentName, setDocumentName] = useState(""); // Document name
  const [selectedFiles, setSelectedFiles] = useState([]); // Selected files for upload
  const [selectedFileNames, setSelectedFileNames] = useState([]); // Names of selected files
  const [documentChats, setDocumentChats] = useState([]); // Document chat messages
  const [documentMore, setDocumentMore] = useState(""); // Expanded document index
  const [renameModal, setRenameModal] = useState(false); // Rename modal state
  const [renameText, setRenameText] = useState(""); // Text for renaming
  const [renameModal2, setRenameModal2] = useState(false); // Second rename modal state
  const [saveAs, setSaveAs] = useState(false); // Save as state
  const [docChatLoading, setDocChatLoading] = useState(true); // Document chat loading state
  const [docType, setDocType] = useState(false); // Document type state

// Handle file input change
  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
    setSelectedFileNames([...selectedFileNames, ...files.map((file) => file.name)]); // Update selected file names
  };

    // Handle removal of selected file
  const handleRemoveSelectedFile = (index) => {
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);

    const updatedSelectedFileNames = [...selectedFileNames];
    updatedSelectedFileNames.splice(index, 1);

    setSelectedFiles(updatedSelectedFiles);
    setSelectedFileNames(updatedSelectedFileNames);
  };

    // Function to truncate file name
  function truncateFileName(fileName, maxLength) {
    if (typeof fileName !== 'string' || fileName.length === 0) {
      return ''; // Return an empty string or handle this case as needed
    }

    if (fileName.length <= maxLength) {
      return fileName;
    }

    const truncatedFileName = fileName.substring(0, maxLength - 5) + '...';
    return truncatedFileName;
  }

 // Document validation before upload
  const handlDocumentValidation = async () => {
    if (documentName !== "" && selectedFiles.length > 0 && revisionId !== "0") {
       // If all necessary fields are filled, proceed with document upload
      const res = await handleDocumentUpload(documentName, selectedFiles, revisionId);
      setSelectedFiles([]);
      setSelectedFileNames([]);
      setDocumentName("");
      handleAddDocumentModal()

    }
    else {
      if (revisionId === null) {
        alert("this is the default Revision please save a copy and then upload documents")
      }
      else {

        alert("all feilds are required check document name and files ")
      }
    }
  }

    // Function to handle document deletion
  const handleDocDelete = async (docId) => {
    try {
        // Send request to delete document
      await privateAxios.delete(`/technicalSpecification/deleteProductDocumentsOfVendorRevision/${docId}`).then(res => {
        handleGetData();// Refresh data after deletion
      }).catch(err => {
      })
    } catch (error) {
    }
  }

  // --------------------------------------------------------------------------------------------------

 // Function to toggle second rename modal
  const handleRenameModal2 = () => {
    setRenameModal2(prev => !prev)
  }

 // State for confidential documents
  const [openConfidential, setOpenConfidential] = useState(false)


    // State for document chat
  const [documentChatOpen, setDocumentChatOpen] = useState("");

  // Function to handle opening and closing document chat
  const handleChatOpen = (data, docId) => {

    if (data === documentChatOpen) {
      setDocumentChatOpen("")
      setDocumentChats("");
    }
    else {
      getDocumentChat(docId)
      setDocumentChatOpen(data);
    }
  }
  // **************************************************Add Chat ( Document )*************************************************************

 // State for document chat input value
  const [documentChatValue, setDocumentChatValue] = useState("")

    // Function to handle sending document chat message
  const handleDocumentChatSend = async (e, documentId) => {
    e.preventDefault();
    try {
      if (documentChatValue.length > 0) {
          // Send request to add document chat message
        await privateAxios.post(`technicalSpecification/addRemarkOnDocumentByVendor/${auth.userData.id}/${documentId}`, { remark: documentChatValue }).then(res => {
          setDocumentChatValue("");
          getDocumentChat(documentId);
        }).catch((err) => {
        })
      }
    } catch (error) {
    }


  }
  // ***********************************************Get Chat document***************************************************
  
    // Function to get document chat messages
  const getDocumentChat = async (documentId) => {

    try {
        // Retrieve document chat messages
      await privateAxios.get(`technicalSpecification/getRemarksOfDocumentOfVendorandKam/${documentId}`).then(res => {
        setDocumentChats(res.data);
        setDocChatLoading(false)
      }).catch((err) => {
        // console.log(err);
        setDocChatLoading(false)
      })
    } catch (error) {
      // console.log(error)
      setDocChatLoading(false)
    }

  }


  // Function to handle expanding/collapsing document more options
  const handleDocumentMore = (index) => {

    if (documentMore === index) {
      setDocumentMore("");
    }
    else {
      setDocumentMore(index)
    }

  }



  // -------------------------------------------------------------------------------------------------
  return (
    <>
      <div className={revisionEditStyle.documentsTabMain}>

        <table className={revisionEditStyle.DocumentTable}>
          <thead>
            <tr className={`${revisionEditStyle.DocumentTableRow} ${revisionEditStyle.DocumentTableHead}`}>
              <th>Title</th>
              <th>Date Added</th>
              <th>Remarks</th>
              <th></th>
              <th>
                <div className={revisionEditStyle.documentIconRow}>
                  <span>
                    <a><FileDownloadIcon /></a>
                  </span>
                  <span>
                    <a><MoreVertIcon /></a>
                  </span>
                </div>
              </th>

            </tr>
          </thead>
          <tbody>
            {documents?.map((doc, index) => {
              if (doc.docType !== "CONFIDENTIAL") {

                return (
                  <tr className={revisionEditStyle.DocumentTableRow}>
                    <td>{doc.name || doc.url}</td>
                    <td>{doc?.date?.split('T')[0] || "Null"}</td>
                    <td style={{ position: "relative" }} >
                      <Tooltip title='Comments'>
                        <Badge className={ProductRevisonsStyle.BadgeCSS} badgeContent={4} color="primary">
                          <ChatIcon onClick={() => { handleChatOpen(index, doc.id); }} />
                        </Badge>
                      </Tooltip>
                      {revisionId === "0" ? <>{documentChatOpen === index && <div className={revisionEditStyle.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                        <DocumentChatRows>
                          {docChatLoading ? <div>Chat Loading ..... </div> :

                            documentChats.length === 0 ? <p>No Previous Chats</p> :
                              documentChats?.map((chat) => {
                                return (
                                  <DocumentChatRow
                                    name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                    email={chat.email}
                                  // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                  />
                                )

                              })}
                        </DocumentChatRows>
                        <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                      </DocumentChatPannel>}
                    </td>


                    <td>
                      <div>
                        <span>
                          <a><FileDownloadOutlinedIcon /></a>
                        </span>
                      </div>
                    </td>
                  </tr>
                )
              }
            })
            }



          </tbody>
        </table>

        <div className={revisionEditStyle.accordianDocumentab}>
          <div className={revisionEditStyle.accordianDocumentabHeader} onClick={() => setOpenConfidential(prev => !prev)}><span>
            {openConfidential ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </span>Confidential Documents</div>

          <div className={revisionEditStyle.accordianDocumentabBody}>

            {openConfidential &&
              <table className={revisionEditStyle.DocumentTable}>
                <tbody>
                  {documents?.map((doc, index) => {
                    if (doc.docType === "CONFIDENTIAL") {
                      return (
                        <tr className={revisionEditStyle.DocumentTableRow}>
                          <td>{doc.name || doc.url}</td>
                          <td>{doc?.date?.split('T')[0] || "Null"}</td>
                          <td>
                            <Tooltip title='Comments'>
                              <Badge className={ProductRevisonsStyle.BadgeCSS} badgeContent={4} color="primary">
                                <ChatIcon />
                              </Badge>
                            </Tooltip></td>
                          <td>  <div className={revisionEditStyle.documentIconRow}>

                            <a><FileDownloadIcon /></a>


                            <a><MoreVertIcon /></a>

                          </div></td>
                        </tr>
                      )
                    }
                  })

                  }

                </tbody>
              </table>

            }
          </div>
        </div>
      </div>



      <Modal
        open={addDocumentModal}
        onClose={handleAddDocumentModal}

      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action=""
            // onSubmit={handleDocumentUpload}
            style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}></span>Add Document</h3>
              <div onClick={handleAddDocumentModal}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <label>Name</label>
              <input type="text" value={documentName} onChange={(e) => setDocumentName(e.target.value)} placeholder='Document Name' />

            </div>
            <div className={formStyle.inputRow}>
              <label>Upload File</label>
              <div style={{ height: "100px", display: "flex", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#f6f6f6", borderRadius: "10px", border: "1px dotted grey" }}>

                <input type="file" multiple
                  ref={inputRef}
                  onChange={handleFileInputChange}
                  hidden placeholder='Document Name' />
                <div style={{ display: "flex", flexDirection: "column", height: "100%", alignItems: "center", width: "100%", overflow: "auto", padding: "10px 10px" }}>

                  {!selectedFileNames.length > 0 && <p style={{ fontSize: "14px", }}>Upload A file</p>}
                  <ul style={{ width: "100%", display: "flex", flexDirection: "column", }}>
                    {selectedFileNames.map((fileName, index) => (

                      <li key={index}
                        //  className={TaskStyle.selectedFileItem}
                        style={{
                          display: 'flex',
                          justifyContent: "space-between", alignItems: 'center',
                          border: "1px solid grey",
                          padding: "0 10px"
                        }}>
                        <p style={{ fontSize: "14px" }}>

                          {truncateFileName(fileName, 15)}
                        </p>
                        <span onClick={() => handleRemoveSelectedFile(index)} style={{ textDecoration: 'none', border: 'none', display: "flex", justifyContent: "center", alignItems: "center" }}
                        ><CloseIcon sx={{ textDecoration: "none", border: 'none' }} /></span>
                      </li>

                    ))}
                  </ul>


                  <p style={{ fontSize: "14px", color: "blue" }} onClick={() => inputRef.current.click()}>Upload</p>
                </div>
              </div>
            </div>

            <div className={formStyle.inputRowCheckBox}>
              <input type="checkbox" checked={docType} onChange={(e) => setDocType(e.target.checked)} />
              <label>Confidential Document</label>

            </div>


            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='button' onClick={handlDocumentValidation}>Add </button>
              {/* <p onClick={() => { setDocumentFile("");setDocumentName(""); }} style={{ cursor: "pointer" }}>Discard</p> */}
            </div>

            {/*<span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span> */}
          </form>

        </div>
      </Modal>

    
      <Modal
        open={renameModal2}
        onClose={handleRenameModal2}
      >
        <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

          <form action="" style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
            <div className={workSpaceStyle.modalHeader} >

              <h3 style={{ display: "flex", alignItems: 'center', gap: '15px' }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><PersonAddAltOutlinedIcon /></span>Rename</h3>
              <div onClick={handleRenameModal2}>
                <Tooltip title='Close'><CloseIcon /></Tooltip>
              </div>
            </div>

            <div className={formStyle.inputRow}>
              <input type="text" value={renameText} onChange={(e) => setRenameText(e.target.value)} placeholder='Revision Name' />
            </div>

            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              {
                saveAs
                  ?
                  <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='button'>Save </button>
                  : <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>

              }
              <p onClick={() => { setRenameText(""); handleRenameModal2() }} style={{ cursor: "pointer" }}>Discard</p>
            </div>

          </form>

        </div>
      </Modal>
    </>
  )
}

export default VendorDocuments
