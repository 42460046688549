import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import axios from 'axios';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import superadminStyle from '../../commonCss.module.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';
import GroupSorterData from 'utility/GroupSorterData.js';
import TableSorterDropDown from 'components/TableSorterDropDown/TableSorterDropDown';
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import DateFormater from 'utility/DateFormater';
import commercialListStyle from './CommercialList.module.css'
import BackButton from 'components/BackButton/BackButton';


const CommercialList = () => {
    // Hooks to handle backdrop (loading screen)
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    // Hooks to handle toast notifications
    const { showToast, Toast } = useToast();
    // Custom Axios instance for making requests
    const privateAxios = PrivateComponent();
    // Hook to get route parameters
    const { catId, ecomSpecId } = useParams();
    // Hook for navigation
    const navigate = useNavigate();
    // State to store template data
    const [templateData, setTemplateData] = useState([]);
    // State to store search value
    const [searchValue, setSearchValue] = useState('');
    // State to manage the open state of the sorter dropdown
    const [sorterOpen, setSorterOpen] = useState("");

    // Function to toggle the sorter dropdown
    const toggleSorter = (type) => {
        if (sorterOpen === type) {
            setSorterOpen("");
        } else {
            setSorterOpen(type);
        }
    };

    // ===================sorting states==================


    // =====================data======================

    // State to store fetched data
    const [data, setData] = useState([]);
    // State to store sorter data
    const [sorterData, setSorterData] = useState({});

    // Function to fetch data
    const getData = async () => {
        openBackdrop();// Show loading screen
        try {
            // Fetch data from API
            const res = await privateAxios.get(`/procureadmin/getAllImportedTemplates/${catId}`);
            // Set data to state
            setData(res.data)
            // Set sorted data to state
            setSortedData(res.data)
            // Group sorter data
            setSorterData(GroupSorterData(res.data));
        } catch (error) {
            // Show error toast
            showToast("error", error.message)
        }
        closeBackdrop(); // Hide loading screen
    }

    // Fetch data on component mount
    useEffect(() => {
        getData();
    }, [])


    // ===================sorting states==================

    // State to store sorted data
    const [sortedData, setSortedData] = useState([]);

    // Function to handle sorting by type and value
    const handleSorting = (type, value) => {
        if (value === "All") {
            setSortedData(data);
        }
        else {
            setSortedData(data.filter((dd) => dd[type] === value));
        }
    }

    // Function to handle date sorting
    const dateSorting = (type, value) => {
        console.log(value);
        let sortedData = []

        if (value === "asc") {
            sortedData = [...data].sort((a, b) => {
                return new Date(a[type]) - new Date(b[type]);
            });
        }
        else {
            sortedData = [...data].sort((a, b) => {
                return new Date(b[type]) - new Date(a[type]);
            });
        }
        setSortedData(sortedData);
    }

    // ===================sorting states==================


    // =====================handle active org======================

    // Function to handle checkbox state change
    const handleCheckBox = async (id) => {
        openBackdrop(); // Show loading screen
        try {
            // Update data state with the toggled active status
            setData((prev) => {
                return prev.map((val) => {
                    if (val.id === id) {
                        return { ...val, active: !val.active }
                    }
                    return val;
                })
            })
        } catch (error) {
            // Show error toast
            showToast("error", error.message)
        }
        closeBackdrop();
    }

    // =====================handle active org======================

    // ====================add product modal========================

      // State to control the add product modal visibility
    const [addProduct, setAddProduct] = useState(false);

     // Function to toggle add product modal
    const handleAddProductModal = () => {
        setAddProduct(prev => !prev);
    }


    // ====================add product modal========================

    // ====================handle add product success modal================

     // Function to show add product success or error message
    const showAddProductSuccess = (type) => {
        handleAddProductModal();

        if (type) {
            showToast("success", "Product Added Successfully")
        }
        else {
            showToast("error", "Error Adding Product")
        }
    }


    // ====================handle add product success modal================


    // ===================toggle more====================

     // State to control the visibility of the more dropdown
    const [moreDropdown, setMoreDropdown] = useState(null);

     // Function to toggle the more dropdown
    const toggleMoreDrop = (index) => {
        if (moreDropdown === index) {
            setMoreDropdown(null);
        }
        else {
            setMoreDropdown(index);

        }
    }

    // ===================toggle more====================

    // ====================delete template modal========================

      // State to control the delete template modal visibility
    const [deleteTemplateModal, setDeleteTemplateModal] = useState(false);

        // Function to toggle delete template modal and set template data
    const handleDeleteTemplateModal = (data) => {

        setDeleteTemplateModal(prev => !prev);
        if (data !== undefined && data !== null && data !== "") {
            setTemplateData(data);
        }
    }

    // ====================delete template modal========================

    // ====================delete template========================


     // Function to handle template deletion
    const handleDeleteTemplate = async () => {
        console.log(templateData);
    }

    // ====================delete template========================

    // ==================fetch Ecom Templates=====================

        // State to store e-commerce templates
    const [ecomTemplates, setEcomTemplates] = useState([]);

     // Function to fetch e-commerce templates
    const getEcomTemplates = async () => {
        openBackdrop();

        try {
            // Fetch e-commerce templates from API
            const res = await axios.get(`https://ecom.kjssteel.com/api/productsforlevel/${ecomSpecId}/2`);
            setEcomTemplates(res.data);

        } catch (error) {
               // Show error toast
            showToast("error", error.message)
        }
        closeBackdrop(); // Hide loading screen
    }

    useEffect(() => {  // Fetch e-commerce templates when addProduct state changes
        if (addProduct) {
            getEcomTemplates();
        }
    }, [addProduct])

    // ==================fetch Ecom Templates=====================

    // ==================handle template checkbox=====================


    // State to store selected templates for checkbox
    const [selectedTemplate, setSelectedTemplate] = useState([]);

    // Function to handle template checkbox state change

    const handleTemplateCheckBox = (temp) => {
        if (selectedTemplate.some((rt) => rt.productId === temp.productId)) {
            setSelectedTemplate(prev => prev.filter((rt) => rt.productId !== temp.productId))
        }
        else {
            setSelectedTemplate(prev => [...prev, temp]);
        }
    }
    // ==================handle template checkbox=====================

    // ==================handle import templae=============================

  // Function to fetch template data from e-commerce API
    const getTemplateDataEcom = async (id) => {
        try {
            const res = await axios.get(`https://ecom.kjssteel.com/api/GetCommercialData/${id}`)
            return res.data;
        } catch (error) {
            console.log("id", error)
        }
    }

     // Function to handle template import
    const handleTemplateAdd = async () => {
        openBackdrop(); // Show loading screen
        try {
              // Get IDs of selected templates
            const ids = selectedTemplate.map((temp) => temp.productId);

             // Fetch data for each selected template
            const results = await Promise.all(ids.map(async (id) => {
                return await getTemplateDataEcom(id);
            }));
             // Import each template to procurement
            const res = await Promise.all(results.map(async (data) => {
                try {
                    const payload = {
                        ...data,
                        specificationId: parseInt(ecomSpecId),

                        description: data.productSpecification
                    }

                    delete payload.productSpecification;
                    await privateAxios.post(`/procureadmin/importTemplate/${catId}`, payload);

                } catch (error) {
                    showToast("error", `error adding data in procurement ${data.productId}`)
                }
            }
            ))
             // Refresh data and reset selected templates
            getData();
            setSelectedTemplate([]);
            handleAddProductModal();

        } catch (error) {
            console.error("Error in mainFunction", error);
        }
        closeBackdrop();
    }

    // ==================handle import templae=============================

    // ==================rename  state =================
    const [renameText, setRenameText] = useState("");
    const [saveAs, setSaveAs] = useState(false);
    const [renameModal, setRenameModal] = useState(false);

    const handleRenameModal = (data) => {
        setRenameModal(prev => !prev)
        if (data !== undefined && data !== null && data !== "") {

            setTemplateData(data);
            setRenameText(data?.name || "");
        }
    }

    // ==================rename  state =================


    // =============change revision Name==============================

     // Function to handle template name change
    const handleChangeTempleteName = async (e) => {
        e.preventDefault();

        try {
            // Send request to update template name
            const res = await privateAxios.post(`/procureadmin/renameTemplate/${templateData.id}/${renameText}`)
            if (res.status == 200) {

                getData();
                showToast("success", "Name Update Successfull");
                handleRenameModal();
            }
        } catch (error) {
            showToast("error", "Name Update failed");

        }
    }

    // =============change revision Name==============================


    // ============duplicate revision==============================
    // State to control the duplicate name modal visibility
    const [duplicateNameModal, setDuplicateNameModal] = useState(false);
    // State to store duplicate name
    const [duplicateName, setDuplicateName] = useState("");

     // Function to toggle duplicate name modal and set template data
    const handleDuplicateNameModal = (data) => {
        setDuplicateNameModal(prev => !prev);
        if (data !== undefined && data !== null && data !== "") {

            setTemplateData(data);

            setDuplicateName(data?.name || "");
        }
    }

     // Function to handle template duplication
    const handleDuplicateRevisison = async (e) => {
        e.preventDefault();
        try {
              // Send request to duplicate template
            const res = await privateAxios.post(`/procureadmin/duplicateTemplate/${templateData.id}?name=${duplicateName}`);
            showToast("success", "Duplicate Revision Created");
            getData(); // Refresh data
            handleDuplicateNameModal(); // Close duplicate name modal
        } catch (error) {
            showToast("error", "Error Creating Revision Duplicate");
            handleDuplicateNameModal();// Close duplicate name modal
        }
    }

    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>
                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <h1 style={{ gap: '10px' }}>
                            <BackButton />
                            Commercial List
                        </h1>
                        <p>Available <span>{data.length}</span></p>

                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
                        <input type="text"
                            onChange={(e) => setSearchValue(e.target.value)}

                            value={searchValue}
                            placeholder='Search Here...' />
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: 'auto', minWidth: 'auto' }} >
                        <button className={workSpaceStyle.headerbtn} style={{ backgroundColor: "#16469D", color: "white" }} onClick={() => { navigate(`fileImport`) }}>File Import Category</button>
                        <button className={workSpaceStyle.headerbtn} style={{ backgroundColor: "#16469D", color: "white" }} onClick={() => { handleAddProductModal() }}>Import Template</button>

                        <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                            <Tooltip title='Help'>
                                <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081308-commercial-category-list-page">
                                    <HelpOutlineOutlinedIcon />
                                </a>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                <div className={workSpaceStyle.workSpaceMainDiv}>

                    <table className={superadminStyle.admintable}>
                        <thead>
                            <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
                                <th style={{ width: "5%" }}>
                                    Sr No.
                                </th>
                                <th style={{ width: "60%" }}>
                                    <p onClick={() => toggleSorter("name")}>Name
                                        <span>{sorterOpen === "name" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "name" && <TableSorterDropDown data={sorterData.name} onClick={(item) => handleSorting("name", item)} toggle={() => toggleSorter("name")} />
                                    }
                                </th>
                                <th>
                                    <p onClick={() => toggleSorter("updatedAt")}>Last Modified
                                        <span>{sorterOpen === "updatedAt" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "updatedAt" && <TableSorterDropDown type={"date"} data={sorterData.updatedAt} onClick={(item) => dateSorting("updatedAt", item)} toggle={() => toggleSorter("updatedAt")} />
                                    }
                                </th>

                                <th style={{ width: "15%" }}>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sortedData
                                    .filter((val) => val.name.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                                        val.kamName.toLowerCase().includes(searchValue.trim().toLowerCase()))
                                    .map((row, index) => {
                                        return (


                                            <tr className={superadminStyle.admintableRow} key={index}>
                                                <td>{index + 1}</td>
                                                <td>{row.name}</td>
                                                <td>{DateFormater(row.updatedAt)} </td>
                                                <td>
                                                    <div className={superadminStyle.actionIcons}>
                                                        <span onClick={() => navigate(`/superadmin/commercial/organisation-by-commercial/${row.id}/${row.productId}`)}>
                                                            <RemoveRedEyeIcon />
                                                        </span>
                                                        <span onClick={() => handleDeleteTemplateModal(row)}>
                                                            <DeleteOutlineIcon />
                                                        </span>
                                                        <span style={{ position: "relative" }} onClick={() => toggleMoreDrop(index)} > <MoreVertIcon />
                                                            {moreDropdown === index && (
                                                                <ThreeDotDropDown toggle={() => toggleMoreDrop(index)} >
                                                                    <li onClick={() => { handleRenameModal(row); toggleMoreDrop(index); }}> <span ><TextFormatIcon /></span> Rename </li>
                                                                    <li onClick={() => { handleDuplicateNameModal(row); toggleMoreDrop(index); }}> <span><ContentCopyIcon /></span> Duplicate </li>
                                                                    <li> <span><DownloadIcon /></span> Download </li>
                                                                </ThreeDotDropDown>
                                                            )}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal
                open={addProduct}
                onClose={handleAddProductModal}
            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>

                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Import Template</h2>
                            <span onClick={handleAddProductModal}><CloseIcon /></span>
                        </div>

                        <div className={commercialListStyle.templatesWrapper}>
                            {
                                ecomTemplates.map((temp, index) => {
                                    return (
                                        <div className={commercialListStyle.templateCard}>
                                            <p>{temp.name}</p>
                                            <input type="checkbox" checked={selectedTemplate.some((rt) => rt.productId === temp.productId)} onChange={() => handleTemplateCheckBox(temp)} />
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className={workSpaceStyle.d_flex_start} style={{ gap: "10px" }}>
                            <button className={`${workSpaceStyle.modalBtn}`} style={{ backgroundColor: "#16469D", color: "white" }} onClick={handleTemplateAdd}>Add Template</button>
                            <button className={`${workSpaceStyle.modalBtn}`} style={{ color: "#16469D" }} onClick={handleAddProductModal}> Discard</button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                open={deleteTemplateModal}
                onClose={handleDeleteTemplateModal}
            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', width: '375px' }}>
                    <form action="" style={{ width: '100%' }} >

                        <div className={workSpaceStyle.rejectModalText} >
                            <h2>Delete Template</h2>
                            <p>Are you sure you want to Delete this Template?</p>

                            <div className={workSpaceStyle.rejectModalButton}>
                                <button onClick={() => { handleDeleteTemplate(); handleDeleteTemplateModal() }} variant="contained">Delete</button>
                                <p onClick={handleDeleteTemplateModal}>Cancel</p>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            <Modal
                open={renameModal}
                onClose={handleRenameModal}
            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

                    <form action="" onSubmit={handleChangeTempleteName} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
                        <div className={workSpaceStyle.modalHeader} >

                            <h3 style={{ display: "flex" }}> Rename</h3>
                            <div onClick={handleRenameModal}>
                                <Tooltip title='Close'><CloseIcon /></Tooltip>
                            </div>
                        </div>

                        <div className={formStyle.inputRow}>

                            <input type="text" value={renameText} onChange={(e) => setRenameText(e.target.value)} placeholder='Revision Name' required />
                        </div>

                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>

                            <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>


                            <p onClick={() => { setRenameText(""); handleRenameModal() }} style={{ cursor: "pointer" }}>Discard</p>
                        </div>
                    </form>

                </div>
            </Modal>

            <Modal
                open={duplicateNameModal}
                onClose={handleDuplicateNameModal}
            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto" }}>

                    <form action="" onSubmit={handleDuplicateRevisison} style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
                        <div className={workSpaceStyle.modalHeader} >
                            <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}></span>Rename</h3>
                            <div onClick={handleDuplicateNameModal}>
                                <Tooltip title='Close'><CloseIcon /></Tooltip>
                            </div>
                        </div>

                        <div className={formStyle.inputRow}>
                            <input type="text" value={duplicateName} onChange={(e) => setDuplicateName(e.target.value)} placeholder='Rename Name' required />
                        </div>

                        <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
                            <button style={{ height: "30px", fontSize: "16px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Save </button>
                            <p onClick={() => { setDuplicateName(""); handleDuplicateNameModal() }} style={{ cursor: "pointer" }}>Discard</p>
                        </div>
                    </form>

                </div>
            </Modal>
        </>
    )
}

export default CommercialList
