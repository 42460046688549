import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ForgotStyle from './ForgotPassword.module.css';
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'
import myaxios from 'api/axios'
import ErrorToast from 'components/ErrorToast/ErrorToast';
import forgotImg from '../../assets/images/forgotpass.svg'
import { Button } from '@mui/material';
import useBackdrop from 'hooks/useBackdrop';



const ForgotPassword = () => {

  // Destructure openBackdrop, closeBackdrop, and BackdropComponent from the useBackdrop custom hook
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

  const emailRef = useRef(); // Initialize useRef hook for email input

  // State to manage email input value
  const [data, setData] = useState({
    email: "",
  })

  // State to manage form errors
  const [formErrors, setFormErrors] = useState({
    email: '',
  });

  // Function to handle change in input fields
  const handleChange = (event, field) => {
    let actualValue = event.target.value
    setData({
      ...data,
      [field]: actualValue,
    });
  };

  // Focus on email input on component mount
  useEffect(() => {
    emailRef.current.focus();// Focus on email input on component mount
  }, [])

  // Initialize useNavigate hook
  const navigate = useNavigate();// Initialize useNavigate hook

  // Function to handle input field changes
  const handleInputField = (e) => {
    setData(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      }
    })

    // Clear error message for the field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: '',
    }));
  };

  // Function to handle input field validation
  const handleValidation = (e) => {
    const { name, value } = e.target;
    let errorMessage = '';

    // Validate email format
    if (name === 'email') {
      if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {
        errorMessage = 'Invalid email address';
      }
    }

    // Set error message for the field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  // State to manage error message
  const [errorMessage, setErrorMessage] = useState(''); // State to manage error message

  // Function to handle form submission
  const forgotSubmit = (e) => {
    e.preventDefault();
    const hasErrors = Object.values(formErrors).some((error) => error !== '');
    setErrorMessage('')

    if (!hasErrors && data.email.trim() !== '') {
      openBackdrop();
      const emailArr = data.email.split(',').map((email) => email.trim().toLowerCase());

      // Send password reset email
      myaxios.post('/procurement/forgotPassword', {
        email: data.email,
      })
        .then((response) => {
          if (response.status === 200) {
            alert("Email has been sent");
            navigate('/login');

          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setErrorMessage("Mail id doesn't exists!");
          }
          console.error('Error sending password reset email:', error);
        })
        .finally(() => {
          closeBackdrop(); // Hide the backdrop when the request is done
        });

    } else {
      // Set error message for invalid form submission
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Please provide a valid email address.',
      }));
    }
  };


  // Render the component

  return (
    <div className={ForgotStyle.mainPage}>
      {errorMessage && <ErrorToast message={errorMessage} />}

      <div className={ForgotStyle.container}>
        <div className={ForgotStyle.companyLogo} >
          <img src={forgotImg} alt="loading" />
          <h3>Trouble with logging in?</h3>
          <h4>Enter your email address and we'll send you a link to get back into your account.</h4>
        </div>
        <form action=''
          onSubmit={forgotSubmit}// Form submission handler
          className={` ${formStyle.inputForm} ${ForgotStyle.inputButton} `}  >
          <div className={formStyle.inputRow}>

            <input
              ref={emailRef}
              type="email"
              placeholder="Enter your email"
              autoComplete="off"
              name='email'
              value={data.email}
              onChange={handleInputField} // Input change handler
              onBlur={handleValidation} // Input blur handler
            />
            <span>{formErrors.email}</span> {/* Display error message */}
          </div>

          <button type='submit' className={formStyle.formPrimarySubmit}> {/* Submit button */}
            Send link
          </button>
          <div className={ForgotStyle.backLogin}>
            <Button variant='text' >
              <Link to='/login'>Back Login</Link>{/* Link to login page */}
            </Button>
          </div>

        </form>

      </div>
      <BackdropComponent />
    </div>
  )
}

export default ForgotPassword
