import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import superadminStyle from '../../commonCss.module.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip from '@mui/material/Tooltip';
import GroupSorterData from 'utility/GroupSorterData.js';
import TableSorterDropDown from 'components/TableSorterDropDown/TableSorterDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mui/material';
import ProductSelector from 'components/ProductSelector/ProductSelector';
import CloseIcon from '@mui/icons-material/Close';
import BackButton from 'components/BackButton/BackButton';


const ProductList = () => {
    // Using custom hooks for backdrop and toast notifications
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const { showToast, Toast } = useToast();
    // Getting private Axios instance
    const privateAxios = PrivateComponent();
    // Hook for navigation
    const navigate = useNavigate();
    // State for search value
    const [searchValue, setSearchValue] = useState('');
    // State for tracking which sorter is open
    const [sorterOpen, setSorterOpen] = useState("");

     // Function to toggle the visibility of sorter dropdown
    const toggleSorter = (type) => {
        if (sorterOpen === type) {
            setSorterOpen("");// Close the sorter if it's already open
        }
        else {
            setSorterOpen(type); // Open the sorter
        }
    }


    // =====================data======================

      // State for storing fetched data
    const [data, setData] = useState([]);
     // State for storing data for the sorter
    const [sorterData, setSorterData] = useState({});
      // State for storing sorted data
    const [sortedData, setSortedData] = useState([]);

     // Function to fetch data from the server
    const getData = async () => {
        openBackdrop();// Open the backdrop
        try {
            const res = await privateAxios.get('/procurement/getAllImportedProducts');
            setData(res.data); // Set fetched data to state
            setSortedData(res.data); // Set fetched data to sorted data
            setSorterData(GroupSorterData(res.data)); // Group data for sorting

        } catch (error) {
            showToast("error", error.message)// Show error toast
        }
        closeBackdrop(); // Close the backdrop
    }

     // Effect to fetch data on component mount
    useEffect(() => {
        getData();
    }, [])


    // =====================handle active org======================

     // Function to handle checkbox toggle for activation
    const handleCheckBox = async (id) => {
        openBackdrop();// Open the backdrop
        try {
            setData((prev) => {
                return prev.map((val) => {
                    if (val.id === id) {
                        return { ...val, active: !val.active }// Toggle active state
                    }
                    return val;
                })
            })
        } catch (error) {
            showToast("error", error.message)// Show error toast
        }
        closeBackdrop(); // Close the backdrop
    }

    // ====================add product modal========================


     // State for add product modal visibility
    const [addProduct, setAddProduct] = useState(false);

    // Function to toggle add product modal visibility
    const handleAddProductModal = () => {
        setAddProduct(prev => !prev);
    }



    // ====================handle add product ================

      // Function to handle adding a product
    const handleAddProduct = async (data) => {
        openBackdrop(); // Open the backdrop
        try {
            const { products, categories } = data;
            const catRes = await privateAxios.post(`/procurement/importCategoriesFromEcom`, categories);// Import categories
            if (catRes.status === 200) {
                const prodRes = await privateAxios.post(`/procureadmin/importProducts`, products);// Import products
                if (prodRes.status === 200) {
                    showToast("success", "product Added Successfully");// Show success toast
                }
                else {
                    showToast("error", "Error Adding Product");// Show error toast
                }
                getData();// Refresh data
            }
            else {
                showToast("error", "Error Adding Category"); // Show error toast

            }

            handleAddProductModal(); // Close add product modal
        }
        catch (error) {
            showToast("error", error.message);// Show error toast
        }

        closeBackdrop();// Close the backdrop

    }


    // ===================sorting states==================

  

  // Function to handle sorting of data
    const handleSorting = (type, value) => {

        if(value==="All"){
            setSortedData(data); // Show all data
        }
        else{
          setSortedData(data.filter((dd) => dd[type] === value)); // Filter data by type and value

        }
    }




    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>

                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <h1 style={{ gap: '10px' }}>
                        <BackButton/>
                            Products List
                        </h1>
                        <p>Available <span>{data.length}</span></p>

                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
                        <input type="text"
                            onChange={(e) => setSearchValue(e.target.value)}

                            value={searchValue}
                            placeholder='Search Here...' />
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: 'auto', minWidth: 'auto' }} >
                        <button className={workSpaceStyle.headerbtn} style={{ backgroundColor: "#16469D", color: "white" }} onClick={() => { navigate(`/superadmin/products/fileImport`) }}>File Import Product</button>
                        <button className={workSpaceStyle.headerbtn} style={{ backgroundColor: "#16469D", color: "white" }} onClick={() => { handleAddProductModal() }}>Import Product</button>

                        <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                            <Tooltip title='Help'>
                            <a target='_blank' href="https://kjssteel-help.freshdesk.com/support/solutions/articles/1060000081304-product-list">
                            <HelpOutlineOutlinedIcon />
                            </a>
                            </Tooltip>
                        </div>
                    </div>
                </div>


                <div className={workSpaceStyle.workSpaceMainDiv}>

                    <table className={superadminStyle.admintable}>
                        <thead>
                            <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
                                <th style={{ width: "5%" }}>
                                    Sr No.
                                </th>
                                <th>
                                    <p onClick={() => toggleSorter("productName")}>Product
                                        <span>{sorterOpen === "productName" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "productName" && <TableSorterDropDown data={sorterData.productName} onClick={(item) => handleSorting("productName", item)} toggle={() => toggleSorter("productName")} />
                                    }
                                </th>
                                <th>
                                    <p onClick={() => toggleSorter("importedFrom")}>Imported From
                                        <span>{sorterOpen === "importedFrom" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "importedFrom" && <TableSorterDropDown data={sorterData.importedFrom} onClick={(item) => handleSorting("importedFrom", item)} toggle={() => toggleSorter("importedFrom")} />
                                    }
                                </th>
                                <th> <p> Category  </p> </th>
                                <th> Action </th>

                            </tr>
                        </thead>
                        <tbody>

                            {

                                sortedData
                                    .filter((val) => val?.productName?.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                                        val.importedFrom?.toLowerCase().includes(searchValue.trim().toLowerCase()))
                                    .map((row, index) => {
                                        return (

                                            <tr className={superadminStyle.admintableRow} key={index}>
                                                <td> {index + 1} </td>
                                                <td> {row.productName} </td>
                                                <td>{row.importedFrom} </td>
                                                <td>  {row.categoryPath} </td>
                                                <td>
                                                    <div className={superadminStyle.actionIcons} onClick={() => navigate(`/superadmin/products/organisation-by-product/${row.productId}`)}>
                                                        <RemoveRedEyeIcon />
                                                    </div>
                                                </td>
                                            </tr>
                                        )

                                    }
                                    )
                            }
                        </tbody>
                    </table>
                </div>
            </div>

            <Modal
                open={addProduct}
                onClose={handleAddProductModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", maxHeight: "60h", alignItems: "flex-start", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>

                    <div style={{
                        display: "flex",
                        height: "100%",
                        width: "100%",
                        justifyContent: "flex-start",
                        alignItems: "felx-start",
                        flexDirection: "column",
                        gap: "20px"

                    }}>

                        <div className={workSpaceStyle.modalHeader}>
                            <h2 style={{ fontSize: "19px", fontWeight: "700" }}>Add Product</h2>
                            <span onClick={handleAddProductModal}><CloseIcon /></span>
                        </div>

                        <ProductSelector productsArray={data} handleAddProductModal={handleAddProductModal} onsubmit={handleAddProduct} orgId={null} />
                    </div>
                </div>

            </Modal>



        </>
    )
}

export default ProductList
