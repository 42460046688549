import React from 'react'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

// BackButton component
const BackButton = () => {

  const navigate = useNavigate();// Hook for navigation

  return (
    // Render a span with an onClick event that navigates back (-1 step in history) when clicked
    <span onClick={() => { navigate(-1) }}><ArrowBackIosIcon /></span>
  )
}

export default BackButton
