import React, { useState } from 'react'; // Import React and useState hook
import toastStyle from '../components/ToastMessage/ToastMessage.module.css'; // Import custom toast styles
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';// Import success icon
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';// Import error icon
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';// Import caution icon

// Custom hook for displaying toast messages
const useToast = () => {
    // State to manage whether the toast message is visible or not
    const [showToastMessage, setShowToastMessage] = useState(false);

    // State to manage the content of the toast message
    const [toastMessage, setToastMessage] = useState("");

    // State to manage the type of the toast message (success, error, caution)
    const [toastMessageType, setToastMessageType] = useState("");

     // Function to show the toast message
    const showToast = (type, message) => {
        setToastMessage(message); // Set the message content
        setToastMessageType(type); // Set the message type
        setTimeout(() => {
            setShowToastMessage(true);// Show the toast message after a delay
        }, 500);


        setTimeout(() => {
            setShowToastMessage(false);// Hide the toast message after a delay
        }, 8000);
    };

     // Component to render the toast message based on the type
    const Toast = () => {
        switch (toastMessageType.toLowerCase()) {
            case "success":
                 // Render success toast message
                return showToastMessage ? (
                    <div className={`${toastStyle.success} ${toastStyle.toastMain}`}>
                        <span>
                            <CheckCircleOutlineOutlinedIcon />
                        </span>
                        <h5>
                            {toastMessage}
                        </h5>
                    </div>
                ) : null;
            case "error":
                  // Render error toast message
                return showToastMessage ? (
                    <div className={`${toastStyle.error} ${toastStyle.toastMain}`}>
                        <span>
                            <HighlightOffOutlinedIcon />
                        </span>
                        <h5>
                            {toastMessage}
                        </h5>
                    </div>
                ) : null;
            case "caution":
                 // Render caution toast message
                return showToastMessage ? (
                    <div className={`${toastStyle.caution}  ${toastStyle.toastMain}`}>
                        <span>
                            <ReportGmailerrorredOutlinedIcon />
                        </span>
                        <h5>
                            {toastMessage}
                        </h5>
                    </div>
                ) : null;
            default:
                return null; // Return null for unknown type
        }
    };
  // Return the showToast function and Toast component
    return { showToast, Toast };
};

export default useToast;