import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import revisionEditStyle from '../../pages/RevisionEdit/RevisionEdit.module.css'


const TempleteDescriptionTab = ({ revisionDescription, setRevisionDescription }) => {
  return (
    <div className={revisionEditStyle.specficationTabMain}>

      <div className={revisionEditStyle.richEditor}>
        <ReactQuill theme="snow" value={revisionDescription} readOnly />
      </div>

    </div>
  )
}

export default TempleteDescriptionTab
