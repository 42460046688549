import React, { useEffect, useState } from 'react'
import approvalStyle from './WorkSpaceApproval.module.css'
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ChatRow from 'components/ChatRows/ChatRow';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';

import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatRows from 'components/ChatRows/ChatRows';
import formStyle from '../../components/FormsCommonCss/FormsCommonCss.module.css'
import useAuth from 'hooks/useAuth';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import { Backdrop, Modal, Tooltip } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { PrivateComponent } from 'api/axios';
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import WatchIconWithBadge from 'components/WatchIconWithBadge/WatchIconWithBadge';
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';



const KAMViewApproval = ({ data, handleLastSeen, handleNavigate, getApprovalRevisions, searchValue, approvalRevisions, handleFileType }) => {
     // Define necessary state variables and hooks
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const { auth } = useAuth();
    const navigate = useNavigate();
    const { client } = useParams()
    const { showToast, Toast } = useToast();
    const privateAxios = PrivateComponent();
    const [sorterData, setSorterData] = useState();
    const [sortedData, setSortedData] = useState([]);
    const [sorterOpen, setSorterOpen] = useState("");

  // Function to handle opening/closing of table sorter
    const handleTableSorterToggle = (sorter) => {
        if (sorterOpen === sorter) {
            setSorterOpen("");
        }
        else {
            setSorterOpen(sorter);
        }
    }

    useEffect(() => {
        setSortedData(data);
    }, [data]);

     // Function to handle sorting based on different criteria
    const handleSorting = (value, type) => {
        switch (type) {
            case "product": setSortedData(data.filter((dd) => dd.productName === value));
                break;

            case "fileType": setSortedData(data.filter((dd) => dd.vendorRevisionType === value));
                break;

            case "category": setSortedData(data.filter((dd) => dd.type === value));
                break;

            case "submitedOn": dateSorting("submitedOn", value);
                break;

            case "ownedBy": setSortedData(data.filter((dd) => dd.sentTo === value));
                break;

            case "lastModified": dateSorting("lastModified", value);
                break;

            default: clearSorting();
        }
        setSorterOpen("");
    }

       // Function to clear sorting and reset data to original
    const clearSorting = () => {
        setSortedData(data);
        setSorterOpen("");
    }


    // Function to sort data based on date
    const dateSorting = (type, value) => {
        let sortedData = []
        if (type === "submitedOn") {
            if (value === "asc") {
                sortedData = [...data].sort((a, b) => {
                    return new Date(a.submittedOn) - new Date(b.submittedOn);
                });
            }
            else {
                sortedData = [...data].sort((a, b) => {
                    return new Date(b.submittedOn) - new Date(a.submittedOn);
                });
            }

        }
        else {
            if (value === "asc") {
                sortedData = [...data].sort((a, b) => {
                    return new Date(a.lastModified) - new Date(b.lastModified);
                });
            }
            else {
                sortedData = [...data].sort((a, b) => {
                    return new Date(b.lastModified) - new Date(a.lastModified);
                });
            }
        }
        setSortedData(sortedData);
    }

    // =====================table sorter====================

      // State variable and function to toggle more icon
    const [moreIcon, setMoreIcon] = useState("");

    const handleMoreIcon = (id) => {
        if (moreIcon === id) {
            setMoreIcon("")
        }
        else {
            setMoreIcon(id);
        }
    }


    // ======================chat pannel=================================

    // State variables and functions for chat functionality
    const [chatSlider, setChatSlider] = useState(false)
    const [chatValue, setChatValue] = useState("");
    const [revisionChats, setRevisionChats] = useState([]);
    const [revData, setRevData] = useState("");


       // Function to toggle chat slider and fetch revision chats
    const handleChatSlider = (revData) => {
        setChatSlider(prev => !prev)
        setRevData(revData);


    }
    useEffect(() => {
        if (chatSlider === true && revData !== "") {

            getRevisionChats(revData);
        }

    }, [chatSlider])


    // Function to send chat message
    const handleChatSend = async (e, revId) => {
        e.preventDefault();
        try {
            let url = `/approval/addRemarkOnApprovalRevision`; // Define the API endpoint based on file type
            if (chatValue.length > 0) {
                await privateAxios.post(`${url}/${auth.userData.id}/${revData.id}`, { remark: chatValue }).then(res => {
                    getRevisionChats(revData);
                    setChatValue("");
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

  // Function to fetch revision chats
    const getRevisionChats = async (revData) => {
        handleChatNotification();
        try {
            let url = "/approval/getRemarksOfApprovalRevision"
            await privateAxios.get(`${url}/${revData.id}`).then(res => {
                setRevisionChats(res.data)
            })

        } catch (error) {
            showToast("error", "Error getting chats")
        }
    }

   // State variable and function to trigger chat notification
    const [reRender, setReRender] = useState(false);

    const handleChatNotification = () => {
        setReRender(prev => !prev);

    }


      // State variables and functions for task history modal
    const [taskHistory, setTaskHistory] = useState(false);
    const [taskHistoryData, setTaskHistoryData] = useState({});

    const handleTaskHistoryModal = (data) => {
        setTaskHistory(prev => !prev);
        setTaskHistoryData(data);
    }

    // ==========================gET HISTORY======================

     // Function to fetch approval history
    const [approvalHistory, setApprovalHistory] = useState([{}]);

    const getApprovalHistory = async () => {
        openBackdrop();//open loader
        try {
            const res = await privateAxios.get(`/approval/getHistoryOfRevision/${taskHistoryData.id}`);
            setApprovalHistory(res.data)
            console.log(res.data);
        } catch (error) {
            console.log(error);
        }
        closeBackdrop();//close loader
    }

    useEffect(() => {
        if (taskHistory === true) {
            getApprovalHistory();
        }
    }, [taskHistory])


    // ========================= Reminder to Approver=================


    // Function to send reminder to approver
    const handleReminderToApprover = async (approverId) => {
        openBackdrop();//open loader
        try {
            const res = await privateAxios.post(`/approval/sendReminder/${approverId}`);
            console.log(res.data);
            showToast("success", "Reminder Sent");
        } catch (error) {
            console.log(error);
            showToast("error", "Error sending reminder")
        }
        closeBackdrop();//close loader
    }

    // ========================= Reminder to Approver=================





   // Function to format ISO date
    const formatIsoDate = (inputDate) => {
        // Convert to Date object
        const dateObject = new Date(inputDate);

        // Format the Date object
        const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
        const formattedDate = dateObject.toLocaleString('en-US', options);

        return formattedDate;
    }

    // =====================date formater====================

    // ======================delete revision===================

     // State variables and functions for delete revision modal
    const [deleteRevisionModal, setDeleteRevisionModal] = useState(false);
    const handleDeleteRevisionModal = (data) => {
        setDeleteRevisionModal(prev => !prev);
        setRevData(data);
    }

        // Function to delete revision
    const deleteRevision = async () => {
        openBackdrop();//open loader
        try {

            const res = await privateAxios.delete(`/approval/deleteApprovalRevision/${revData.id}`);
            // console.log(res.data);
            showToast("success", "Revision Deleted");

        } catch (error) {
            showToast("error", "Error deleting revision");
        }

        closeBackdrop();//close loader
    }


    // =======================Revoke Request======================

     // State variables and functions for revoke request modal
    const [revokeRequestModal, setRevokeRequestModal] = useState(false);
    const [revokeRequestData, setRevokeRequestData] = useState({});
    const [revokeRequestModalData, setRevokeRequestModalData] = useState({});
    const handleRevokeRequestModal = (data) => {
        setRevokeRequestModal(prev => !prev);
        setRevokeRequestData(data)
    }

// State variables and functions for revoke confirmation modal
    const [revokeConfirmationModal, setRevokeConfirmationModal] = useState(false);
    const handleRevokeConfirmationModal = () => {
        setRevokeConfirmationModal(prev => !prev);
    }

       // Function to handle revoke confirmation form submission
    const handleRevokeConfirmationForm = (e) => {
        e.preventDefault();
        setRevokeRequestModal(prev => !prev);
        handleRevokeConfirmationModal();
    }

      // Function to handle revoke confirmation
    const handleRevokeConfirmation = async (type, revokeId) => {
        openBackdrop();//open loader

        try {
            let url = '';
            if (type) {
                url = `/approval/approveRevokeRequest`
            }
            else {
                url = `/approval/DeclineRevokeRequest`
            }
            const res = await privateAxios.post(`${url}/${revokeId}`);
            if (res.status === 200) {
                if (type) {
                    showToast("success", "Revoke Request Approved");
                }
                else {
                    showToast("success", "Revoke Request Declined")
                }
                getApprovalRevisions();
            }
        } catch (error) {
            if (type) {
                showToast("error", "Error approving revoke request");
            }
            else {
                showToast("error", "Error Rejecting revoke request")
            }

        }
        closeBackdrop();//close loader
    }




 


    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={approvalStyle.tableWraper}>
                <table className={approvalStyle.ApprovalTable} >
                    <thead>
                        <tr className={`${approvalStyle.tableHead}`} style={{ zIndex: '2' }}>


                            <td className={approvalStyle.tableColumnSticky} style={{ minWidth: "250px" }}>
                                <div className={approvalStyle.tdRow}>

                                    <p>Name</p>
                                </div>

                            </td>

                            <td >
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("product")}>Products
                                        <span>{sorterOpen === "product" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "product" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("product")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.productName?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "product")} >{ss === null ? "Group" : ss}</li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </>
                                    }

                                </div>

                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("type")}>File Type
                                        <span>{sorterOpen === "type" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "type" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("type")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.vendorRevisionType?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "type")} >{ss === null ? "Group" : ss}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("category")}> Category
                                        <span>{sorterOpen === "category" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "category" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("category")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    // sorterData.sentBy.map((ss)=>{
                                                    sorterData?.type?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "category")} >{ss}</li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("submitedOn")}>Submited On
                                        <span>{sorterOpen === "submitedOn" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "submitedOn" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("submitedOn")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                <li onClick={() => handleSorting("asc", "submitedOn")} >Latest</li>
                                                <li onClick={() => handleSorting("desc", "submitedOn")} >Oldest</li>

                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("duedate")}>Due Date
                                        <span>{sorterOpen === "duedate" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "duedate" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("duedate")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.sentTo?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "duedate")} >{ss === null ? "Null" : ss}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("currentstage")}>Current Stage
                                        <span>{sorterOpen === "currentstage" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "currentstage" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("currentstage")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                {
                                                    sorterData?.sentTo?.map((ss) => {
                                                        return (

                                                            <li onClick={() => handleSorting(ss, "currentstage")} >{ss === null ? "Null" : ss}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>

                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("approvername")}>Approver Name
                                        <span>{sorterOpen === "approvername" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "approvername" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("approvername")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                <li onClick={() => handleSorting("asc", "lastModified")} >Latest</li>
                                                <li onClick={() => handleSorting("desc", "lastModified")} >Oldest</li>

                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td>
                                <div className={workSpaceStyle.tableSorterDiv} >
                                    <p onClick={() => handleTableSorterToggle("lastUpdated")}>Last Updated
                                        <span>{sorterOpen === "lastUpdated" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "lastUpdated" &&
                                        <>
                                            <div className={workSpaceStyle.backDrop} onClick={() => handleTableSorterToggle("lastUpdated")}></div>
                                            <ul className={workSpaceStyle.tableSorterDropDown}>
                                                <li onClick={() => clearSorting()} >All</li>
                                                <li onClick={() => handleSorting("asc", "lastModified")} >Latest</li>
                                                <li onClick={() => handleSorting("desc", "lastModified")} >Oldest</li>

                                            </ul>
                                        </>
                                    }

                                </div>
                            </td>
                            <td
                            // style={{textAlign:"right"}}
                            >
                                Actions
                            </td>
                        </tr>
                    </thead>
                    <tbody>

                        {approvalRevisions.filter((row) => row.name.trim().toLowerCase().includes(searchValue.toLowerCase())).map((row, index) => {
                            return (
                                <tr className={`${approvalStyle.tableRow}`}>
                                    <td className={approvalStyle.tableColumnSticky}>{row.name} </td>
                                    <td>{row.productName} </td>
                                    <td>{handleFileType(row.fileType)} </td>
                                    <td>{row.category} </td>
                                    <td>{handleLastSeen(row.submittedOn)} </td>
                                    <td>{row.dueDate.split("T")[0]} </td>
                                    <td style={{ padding: "0 20px" }}> <ProgressBar approvedStage={row.approvedStage} current={row.currentStage} total={row.totalUser} declined={row.status === "PENDING" || row.status === "APPROVAL" ? false : true} /> </td>

                                    <td>{row.approverName} </td>
                                    <td>{handleLastSeen(row.lastModified)} </td>
                                    <td>
                                        <div className={approvalStyle.iconRow}>
                                            <span onClick={() => handleNavigate({ id: row.id, fileType: row.fileType, type: row.category, revId: row.id })}>
                                                <RemoveRedEyeIcon />
                                            </span>
                                            <span onClick={() => handleChatSlider({ id: row.id, type: row.category, revName: row.name })}>
                                                <ChatIconWithBadge
                                                    url={`/approval/getNotficationCountOfApprvalRevision`}
                                                    id={row.id}
                                                    reRender={reRender}
                                                />
                                            </span>
                                            <span onClick={() => handleTaskHistoryModal({ id: row.id, data: "something" })}>
                                                <WatchIconWithBadge
                                                    url={`/approval/getNotficationCountOfHistory`}
                                                    id={row.id}
                                                    reRender={reRender}
                                                />
                                            </span>
                                            <span style={{ position: "relative" }}>
                                                <MoreVertOutlinedIcon onClick={() => handleMoreIcon(index)} />
                                                {moreIcon === index &&
                                                    <ThreeDotDropDown toggle={() => handleMoreIcon(index)} >
                                                        {/* approver id is not there in the approval revision */}
                                                        <li onClick={() => { handleMoreIcon(index) }}><span><NotificationsNoneIcon /></span> Send a reminder</li>
                                                        <li onClick={() => { handleTaskHistoryModal({ id: row.id, data: "something" }); handleMoreIcon(index) }} ><span><DateRangeIcon /></span> Approval History</li>
                                                        <li onClick={() => { handleDeleteRevisionModal({ id: row.id }); handleMoreIcon(index) }}><span><DeleteOutlineIcon /></span>Delete</li>
                                                        <li><span><FileDownloadOutlinedIcon /></span> Download</li>
                                                    </ThreeDotDropDown>}
                                            </span>
                                        </div>
                                    </td>
                                </tr>

                            )
                        })}



                    </tbody>
                </table>

            </div>
            <ChatPannel
                open={chatSlider}
                onClose={handleChatSlider}
            >
                <>
                    <ChatHeader onClose={handleChatSlider}
                        downloadData={revisionChats.map((chat) => {
                            return { name: chat.name, date: chat.createdAt, chat: chat.remark };
                        })}
                        helpData={{ link: "tosomewhere" }}
                        chatName={revData?.revName}
                    >
                        <h3>{revData?.revName} </h3>
                    </ChatHeader>

                    <ChatRows >
                        {/* <ChatRow name="Ravinder" chat="soemfhdf a saf sa" date="12-04-2023" index="1"/> */}
                        {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
                            return (

                                <ChatRow name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} />
                            )
                        })
                            :
                            <p style={{ textAlign: "center" }}>
                                No Previous Chat
                            </p>
                        }
                    </ChatRows>

                    <ChatInputField revisionId={revData?.id} onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

                </>
            </ChatPannel>
            <Modal
                open={taskHistory}
                onClose={handleTaskHistoryModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", padding: "20px 0px", width: "30vw" }}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
                        <div className={workSpaceStyle.modalHeader} style={{ borderBottom: "2px solid #d2d2d2", padding: "0 10px 10px 10px" }} >

                            <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                            </span> Task History</h3>
                            <div onClick={handleTaskHistoryModal}>
                                <Tooltip title='Close'><CloseIcon /></Tooltip>
                            </div>
                        </div>

                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", padding: "10px 25px" }}>


                            <div className={approvalStyle.historyRowWrapper}>
                                {approvalHistory.map((item, index) => {
                                    if (item.revokeStatus!=="requested") {


                                        return (
                                            <div className={approvalStyle.historyRow}>
                                                <div style={{ display: "flex", flexGrow: "1" }}>
                                                    <div className={approvalStyle.historyRowIcon}>
                                                        <span style={{ backgroundColor: item.status === "approved" ? "#14c971" : item.status === "pending" ? "#f9d249" : item.status === "na" ? "#d2d2d2" : "red" }}>
                                                            {
                                                                item.status === "pending" || item.status === "na" ?
                                                                    <ErrorOutlineOutlinedIcon />
                                                                    :

                                                                    <CheckOutlinedIcon />

                                                            }

                                                        </span>
                                                    </div>
                                                    <div className={approvalStyle.historyRowCont}>
                                                        <div>

                                                            <h4>
                                                                {item.status === "approved" ? "Approved by" : item.status === "pending" ? "Apprvoal Pending from" : item.status === "na" ? "Apprvoal Pending from" : "Declined By"}
                                                                {/* {item.status === "approved" ? "Approved by" : item.status === "pending" ? "Apprvoal Pending from" : "Declined By"} */} {item.approverName}
                                                            </h4>
                                                            <span style={{ backgroundColor: item.status === "approved" ? "#14c971" : item.status === "pending" ? "#f9d249" : item.status === "na" ? "#d2d2d2" : "red" }}>{index + 1}/{approvalHistory.length}</span>
                                                        </div>
                                                        <p>
                                                            {formatIsoDate(item.dueDate)}

                                                        </p>
                                                    </div>
                                                </div>
                                                {
                                                    item.status === "pending" &&
                                                    <div>
                                                        <span onClick={() => handleReminderToApprover(item.approverId)}>
                                                            <NotificationsNoneOutlinedIcon />
                                                        </span>
                                                    </div>
                                                }

                                                {
                                                    index > 0 &&

                                                    <div className={approvalStyle.historyRowProgressLine} style={{ backgroundColor: item.status === "approved" ? "#14c971" : item.status === "pending" ? "#f9d249" : item.status === "na" ? "#d2d2d2" : "red" }}></div>
                                                }

                                            </div>

                                        )
                                    }
                                    else {
                                        return (
                                            <div className={approvalStyle.historyRow}>
                                                <div style={{ display: "flex", flexGrow: "1" }}>
                                                    <div className={approvalStyle.historyRowIcon}>
                                                        <span style={{ backgroundColor: "#f9d249" }}>


                                                            <ErrorOutlineOutlinedIcon />




                                                        </span>
                                                    </div>
                                                    <div className={approvalStyle.historyRowCont}>
                                                        <div>

                                                            <h4 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => { handleRevokeRequestModal(item); handleTaskHistoryModal(); }}>
                                                                Revoke Request From {item.approverName}
                                                            </h4>
                                                            <span style={{ backgroundColor: "#f9d249" }}>{index + 1}/{approvalHistory.length}</span>
                                                        </div>
                                                        <p>
                                                            {formatIsoDate(item.dueDate)}

                                                        </p>
                                                    </div>
                                                </div>
                                                {
                                                    index > 0 &&

                                                    <div className={approvalStyle.historyRowProgressLine} style={{ backgroundColor: "#f9d249" }}></div>
                                                }

                                            </div>

                                        )
                                    }
                                })}





                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {/* modal to ask for Confirmation of Revision Delete */}

            <DeleteConfirmation
                onCancel={handleDeleteRevisionModal}
                onConfirm={() => { deleteRevision(); handleDeleteRevisionModal() }}
                open={deleteRevisionModal}
            // confimBtnText={""} 
            // discardText={""}
            >
                <h2>Delete Revision</h2>
                            <p>Are you sure you want to Delete this Revision?</p>

            </DeleteConfirmation>
         


            {/* modal to ask for Confirmation of Revision Delete */}

            {/* modal to ask for Confirmation of Revoke Request */}
            <Modal
                open={revokeRequestModal}
                onClose={handleRevokeRequestModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: "auto", width: "500px" }}>

                    <form action=""
                        onSubmit={handleRevokeConfirmationForm}
                        style={{ maxWidth: "100%", }} className={formStyle.inputForm}>
                        <div className={workSpaceStyle.modalHeader} >

                            <h3 style={{ display: "flex" }}><span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><SettingsBackupRestoreIcon /></span>Revoke Requst from {revokeRequestModalData?.name}</h3>
                            <div onClick={handleRevokeRequestModal}>
                                <Tooltip title='Close'><CloseIcon /></Tooltip>
                            </div>
                        </div>


                        <div className={formStyle.inputRow}>
                            <label htmlFor=""> Reason for Withdrawal:</label>
                            <textarea style={{ paddingTop: "10px", height: "120px", backgroundColor: "#f2f2f2", maxWidth: "100%", minWidth: "100%" }}
                                //    onChange={(e) => setComments(e.target.value)} value={comments}
                                value={revokeRequestData?.reason}
                                readOnly
                            ></textarea>
                            {/* <span>{formErrors.accessRole}</span>  */}
                            {/* <span>{formError.comments}</span> */}
                        </div>



                        <div style={{ display: "flex", gap: "20px", alignItems: "center", justifyContent: "flex-end" }}>
                            <p onClick={() => { handleRevokeRequestModal() }} style={{ cursor: "pointer" }}>Decline</p>
                            <button style={{ height: "40px" }} className={formStyle.modalSmallPrimaryButton} type='submit'>Revoke </button>
                        </div>

                        {/* <span style={{ fontSize: "9px", color: "rgb(211, 9, 9)", fontWeight: "600", position: "absolute", top: "100%" }}>{formError.formError}</span> */}
                    </form>

                </div>
            </Modal>

            {/* modal to ask for Confirmation of Revoke Request */}


            <Modal
                open={revokeConfirmationModal}
                onClose={handleRevokeConfirmationModal}

            >
                <div className={workSpaceStyle.modalCont} style={{ minHeight: 'auto', minWidth: '375px', width: "600px" }}>
                    <form action="" style={{ maxWidth: '100%' }} >

                        <div
                            className={workSpaceStyle.rejectModalText}
                        >
                            <h2 style={{ fontSize: "25px" }}>Are you sure you want to revoke?</h2>
                            <p>All related activities will be reversed, and this action will stop any more
                                workflow approvals or denials. Once done it cant be undone!</p>
                            {revokeRequestData.revokeId}
                            <div
                                className={workSpaceStyle.rejectModalButton}
                                style={{ justifyContent: "flex-end" }}
                            >

                                <p onClick={() => handleRevokeConfirmationModal()}>Cancel</p>
                                <button onClick={() => { handleRevokeConfirmation(true, revokeRequestData.revokeId); handleRevokeConfirmationModal() }} style={{ backgroundColor: "#16469d", cursor: "pointer" }} variant="contained">Revoke</button>
                            </div>

                        </div>

                    </form>
                </div>
            </Modal>



        </>
    )
}

export default KAMViewApproval


const ProgressBar = ({ approvedStage, current, total, declined }) => {
    const percentage = total !== 0 ? (approvedStage / total) * 100 : 0;

    return (
        <div className={approvalStyle.progressWrapper}>
            <p style={{ color: declined ? "white" : "black" }}>
                {declined ?
                    <>
                        Declined
                    </>
                    :
                    <>
                        {
                            approvedStage === total ?
                                <>
                                    Approved
                                </>
                                :
                                <>
                                    Step {current}/{total}
                                </>
                        }

                    </>
                }

            </p>
            <span style={{ width: declined ? "100%" : `${percentage}%`, backgroundColor: declined ? "#D93025" : "#72F6B6" }}></span>
        </div>
    );
};