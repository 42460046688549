import React, { useState, useEffect } from 'react'
import workSpaceStyle from 'components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';
import { PrivateComponent } from "api/axios";
import useBackdrop from 'hooks/useBackdrop';
import useToast from 'hooks/useToast';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import superadminStyle from '../../commonCss.module.css';
import Tooltip from '@mui/material/Tooltip';
import GroupSorterData from 'utility/GroupSorterData.js';
import TableSorterDropDown from 'components/TableSorterDropDown/TableSorterDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useNavigate } from 'react-router-dom';
import DateFormater from 'utility/DateFormater';
import BackButton from 'components/BackButton/BackButton';


const CommercialRequest = () => {
     // Hooks
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const { showToast, Toast } = useToast();
    const privateAxios = PrivateComponent();
    const navigate = useNavigate();

     // State for search value and sorter
    const [searchValue, setSearchValue] = useState('');
    const [sorterOpen, setSorterOpen] = useState("");

     // Function to toggle sorter
    const toggleSorter = (type) => {
        if (sorterOpen === type) {
            setSorterOpen("");
        }
        else {
            setSorterOpen(type);
        }
    }

// State for data and sorter data
    const [data, setData] = useState([]);
    const [sorterData, setSorterData] = useState({});

    // Function to fetch data
    const getData = async () => {
        openBackdrop();
        try {
            const res = await privateAxios.get('/procureadmin/getAllTemplateRequestFromOrganization');
            setData(res.data)
            setSortedData(res.data); // Assuming setSortedData is defined elsewhere
            setSorterData(GroupSorterData(res.data)); // Assuming GroupSorterData function is defined elsewhere
        } catch (error) {
            showToast("error", error.message)
        }
        closeBackdrop();
    }

    useEffect(() => {
        getData();
    }, [])

    // =====================data======================

 // State for sorted data
    const [sortedData, setSortedData] = useState([]);



// Function for handling sorting
    const handleSorting = (type, value) => {

        if (value === "All") {
            setSortedData(data);
        }
        else {
            setSortedData(data.filter((dd) => dd[type] === value));
        }
    }

     // Function for date sorting
    const dateSorting = (type, value) => {
        console.log(value);
        let sortedData = []

        if (value === "asc") {
            sortedData = [...data].sort((a, b) => {
                return new Date(a[type]) - new Date(b[type]);
            });
        }
        else {
            sortedData = [...data].sort((a, b) => {
                return new Date(b[type]) - new Date(a[type]);
            });
        }
        setSortedData(sortedData);
    }

    // ===================sorting states==================



    // =====================handle active org======================

    // Function to handle checkbox
    const handleCheckBox = async (id) => {
        openBackdrop();
        try {
            setData((prev) => {
                return prev.map((val) => {
                    if (val.id === id) {
                        return { ...val, active: !val.active }
                    }
                    return val;
                })
            })
        } catch (error) {
            showToast("error", error.message)
        }
        closeBackdrop();
    }



    // Function to handle template decision
    const handleTemplateDecision = (id, decision) => async () => {
        openBackdrop();
        try {
            let url = ``
            if (decision) {
                url = `/procureadmin/approveTemplateRequest`
            }
            else {
                url = `/procureadmin/deniedTemplateRequest`
            }

            const res = await privateAxios.post(`${url}/${id}`);

            if (res.status === 200) {
                if (decision) {
                    showToast("success", "Template Approved Successfully")
                }
                else {
                    showToast("success", "Template Declined Successfully")
                }

            }

            getData();
        } catch (error) {
            showToast("error", error.message)
        }

        closeBackdrop();
    }




    return (
        <>
            <Toast />
            <BackdropComponent />
            <div className={workSpaceStyle.workSpaceMain}>



                <div className={workSpaceStyle.workSpaceHeader}>
                    <div className={workSpaceStyle.workSpaceHeaderLeft}>
                        <h1 style={{ gap: '10px' }}>
                        <BackButton/>
                            Commercial Request
                            {/* <i style={{ fontSize: "15px" }}>({auth.roles})</i> */}
                            {/* <span>(ADMIN)</span> */}
                        </h1>
                        {/* <p>Available <span>{data.length}</span></p> */}
                        <p>Available <span>{data.length}</span></p>

                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderSearchbar} style={{ width: '300px', minWidth: '300px' }}>
                        <input type="text"
                            onChange={(e) => setSearchValue(e.target.value)}

                            value={searchValue}
                            placeholder='Search Here...' />
                    </div>
                    <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: 'auto', minWidth: 'auto' }} >
                        {/* <div className={workSpaceStyle.workSpaceHeaderRight} style={{ width: '450px', gap: '10px' }}> */}


                        <div className={workSpaceStyle.WorkSpaceHeaderhelp} style={{ width: '10%', display: 'flex', justifyContent: "flex-end", alignItems: 'center' }}>
                            <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
                        </div>
                    </div>

                </div>


                <div className={workSpaceStyle.workSpaceMainDiv}>

                    <table className={superadminStyle.admintable}>
                        <thead>
                            <tr className={`${superadminStyle.admintableRow} ${superadminStyle.admintableRowheader}`}>
                                <th>
                                    Sr No.
                                </th>

                                <th >
                                    <p onClick={() => toggleSorter("appliedFor")}>Requesting For
                                        <span>{sorterOpen === "appliedFor" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "appliedFor" && <TableSorterDropDown data={sorterData.appliedFor} onClick={(item)=>handleSorting("appliedFor",item)} toggle={() => toggleSorter("appliedFor")} />
                                    }
                                </th>
                                <th>


                                    <p onClick={() => toggleSorter("organisation")}>Organisation Name
                                        <span>{sorterOpen === "organisation" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "organisation" && <TableSorterDropDown data={sorterData.organisation} onClick={(item)=>handleSorting("organisation",item)} toggle={() => toggleSorter("organisation")} />
                                    }
                                </th>
                                <th>


                                    <p onClick={() => toggleSorter("requeston")}> Requested On
                                        <span>{sorterOpen === "requeston" ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
                                    </p>
                                    {
                                        sorterOpen === "requeston" && <TableSorterDropDown type={"date"} data={sorterData.requeston} onClick={(item)=>dateSorting("requeston",item)} toggle={() => toggleSorter("requeston")} />
                                    }
                                </th>
                                <th>
                                    Remarks
                                </th>
                                <th style={{ textAlign: "center" }}>
                                    Action
                                </th>

                            </tr>
                        </thead>
                        <tbody>

                            {

                                sortedData
                                    .filter((val) => val.appliedFor.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                                        val.organisation.toLowerCase().includes(searchValue.trim().toLowerCase()))
                                    .map((row, index) => {
                                        return (


                                            <tr className={superadminStyle.admintableRow} key={index}>

                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    {row?.appliedFor}
                                                </td>
                                                <td>
                                                    {row?.organisation}
                                                </td>
                                                <td>
                                                    {DateFormater(row?.requeston)}
                                                </td>
                                                <td>
                                                    {row?.remarks}
                                                </td>


                                                <td>
                                                    <div className={superadminStyle.decisionBtnWrapper}>
                                                        {
                                                            row.isRequested ?
                                                                <>

                                                                    <button className={`${superadminStyle.rowDecisionbtn} ${superadminStyle.rowDecisionSecondarybtn}`} onClick={handleTemplateDecision(row.id, false)}>
                                                                        Decline
                                                                    </button>
                                                                    <button className={`${superadminStyle.rowDecisionbtn} ${superadminStyle.rowDecisionPrimarybtn}`} onClick={handleTemplateDecision(row.id, true)}>
                                                                        Approve
                                                                    </button>
                                                                </>
                                                                :
                                                                row.isApproved ?
                                                                    <>
                                                                        <button className={`${superadminStyle.rowDecisionbtn}`} style={{ fontWeight: "600", color: "#16469D" }}>
                                                                            Approved
                                                                        </button>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <button className={`${superadminStyle.rowDecisionbtn}`}>
                                                                            Declined
                                                                        </button>
                                                                    </>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )

                                    }
                                    )
                            }

                        </tbody>


                    </table>


                </div>






            </div>
        </>
    )
}

export default CommercialRequest
