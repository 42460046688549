import React, { useState } from 'react'// Importing React and useState hook
import proStyle from "./ProcaNavbar.module.css"// Importing CSS module for styles
import Button from '@mui/material/Button';// Importing Button component from Material-UI
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';// Importing Down Arrow Icon from Material-UI
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';// Importing Up Arrow Icon from Material-UI
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';// Importing Menu Icon from Material-UI
import { Link, useLocation, useNavigate } from 'react-router-dom';// Importing Link, useLocation, and useNavigate from React Router DOM
import { useDispatch } from 'react-redux';// Importing useDispatch from Redux
import useAuth from 'hooks/useAuth';// Custom hook for authentication
import { Image } from 'cloudinary-react';// Importing Image component from Cloudinary

// Array containing product data
const proData = [
    {
        id: 1,
        name: "Iron Making",
    },
    {
        id: 2,
        name: "Granshot",
    },
    {
        id: 3,
        name: "Hot Material",
    },
    {
        id: 4,
        name: "Castable Refractories",
    },
    {
        id: 5,
        name: "Air Pre Cooling Unit",
    },
]


const ProcaNavbar = ({ props }) => {

    // Initializing hooks and variables
    const navigate = useNavigate();// Navigate hook
    const { auth, setLogout } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState(null);// State for anchor element
    const location = useLocation();
    const { state } = location; // State from location

    // State for dropdown
    const [selectProd, setSelectProd] = useState(false);// State for product selection

    // Function to handle dropdown
    const handleDropdown = (event) => {
        event.stopPropagation();// Stop event propagation
        setMultiClick(!multiClick);// Toggle multiClick state
    }

    // State for multi-click
    const [multiClick, setMultiClick] = useState(false);// State for multi-click
    const [multiClick2, setMultiClick2] = useState(false);// State for multi-click 2

    // Function to handle multi-click
    const handleMultiClick = () => { // Toggle multiClick state
        setMultiClick(prev => !prev)
    }

    // Function to handle multi-click 2
    const handleMultiClick2 = () => { // Toggle multiClick2 state
        setMultiClick2(prev => !prev)
    }

    // State for mobile menu
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);// State for mobile menu

    // Function to handle mobile menu
    const handleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);// Toggle mobile menu state
    };


    // Dispatch hook
    const dispatch = useDispatch();

    // Function to handle logout
    const handleLogout = () => {
        dispatch(setLogout());// Dispatch logout action
        navigate('/login', { state: { data: null }, replace: true });// Navigate to login page

    };



    return (
        <div className={proStyle.proNavbar}>
            <nav>
                <div className={proStyle.proNavbarStart}>

                    <Link to={"/"} className={proStyle.proLogo}>
                        <Image style={{ marginLeft: '25%' }}
                            publicId="https://res.cloudinary.com/dxluokypg/image/upload/v1682423845/steel_1_lgk5mz.svg"
                            cloudName="dxluokypg" />                    </Link>
                    <div className={proStyle.proList}>
                        <ul>
                            <li className={proStyle.proDropDown} onClick={handleMultiClick} >
                                <p onClick={handleDropdown}>
                                    <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
                                        Products
                                        <span>
                                            {selectProd ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </span>
                                    </Link>

                                </p>
                                {multiClick && (
                                    <ul style={{ width: '200px' }}>
                                        {proData.map((pp) => {
                                            return (
                                                <li key={pp.id}>{pp.name}</li>
                                            )
                                        })
                                        }
                                    </ul>
                                )
                                }
                            </li>
                            <li>
                                <Link to="/feature" style={{ color: location.pathname === '/feature' ? "#2e59a7" : "" }}>
                                    Features
                                </Link>
                            </li>


                        </ul>
                    </div>
                    <div className={proStyle.proButton}>

                        {
                            state?.data?.email && state?.data?.token !== null ? (
                                <>
                                    {
                                        auth.userData == ! null ? <p>{auth.name}</p> : <p>Profile</p>
                                    }

                                    {
                                        state?.data !== null ? (

                                            <p onClick={(e) => { e.preventDefault(); handleLogout(); }} state={{ data: null }} replace="true">Logout</p>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </>

                            ) : (

                                <div style={{ display: 'flex', alignItems: 'center', gap: '25px' }}>
                                    <p onClick={() => navigate("/login", { replace: true })}>Login</p>
                                    <Button variant="contained" onClick={() => { navigate('/procureregister') }}>Get Started</Button>
                                </div>

                            )
                        }
                    </div>

                </div>
                <div className={proStyle.mobileMenuIcon} onClick={handleMultiClick2}>
                    <p onClick={handleMobileMenu}>
                        <MenuRoundedIcon />
                    </p>
                    {multiClick2 && (
                        <ul style={{ width: '150px', right: '10px' }}>
                            <li className={proStyle.proDropDown} onClick={handleMultiClick} >
                                <p onClick={handleDropdown}>
                                    <Link to="/" className={location.pathname === '/' ? 'active' : ''}>
                                        Product
                                        <span >
                                            {selectProd ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        </span>
                                    </Link>
                                </p>
                                {multiClick && (
                                    <ul style={{ width: '250px', left: '-100px', zIndex: '9999999' }}>
                                        {proData.map((pp) => {
                                            return (
                                                <li style={{ color: "#000" }} key={pp.id}>{pp.name}</li>
                                            )
                                        })
                                        }
                                    </ul>
                                )
                                }
                            </li>
                            <li>
                                <Link to="/feature" style={{ color: location.pathname === '/feature' ? "#2e59a7" : "#000" }}>
                                    Feature
                                </Link>
                            </li>
                            <li>
                                <Link to="/how-it-works" style={{ color: location.pathname === '/how-it-works' ? "#2e59a7" : "#000" }}>
                                    How it Works
                                </Link>
                            </li>
                            <li>
                                <Link to="/pricing" className={location.pathname === '/pricing' ? 'active' : ''}>
                                    Pricing
                                </Link>
                            </li>
                            <li style={{ color: '#16469d', fontWeight: 600 }} onClick={() => navigate("/login", { replace: true })}>Login</li>
                            <Button variant="contained" onClick={() => { navigate('/demopage'); console.log("hello") }}>Get Demo</Button>
                        </ul>
                    )}
                </div>


            </nav>

        </div>
    )
}

export default ProcaNavbar
