import React, { useEffect } from 'react'
import templeteStyle from './CommercialTempleteViewVendor.module.css'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useState } from 'react';
import useAuth from 'hooks/useAuth';// Adjust the path to where useAuth is located
import DeleteConfirmation from 'components/DeleteConfirmation/DeleteConfirmation';

// ================mui compoents============

const TempleteQuestionsTab = ({ questionRows, handleInputChange, handleAddQuestionRow, handleQuestionRowDelete }) => {

  const { auth } = useAuth();// Get authentication details
  const [deleteModal, setDeleteModal] = useState(false); // State to control delete modal visibility
  const [deleteId, setDeleteId] = useState(""); // State to store the ID of the question to be deleted

  // Toggle delete modal visibility and set delete ID
  const handleDeleteModal = (id) => {
    setDeleteModal(prev => !prev); // Toggle delete modal visibility
    setDeleteId(id); // Set delete ID
  }


  return (
    <>
      <div className={templeteStyle.questionsTabMain}>
        <table className={templeteStyle.questionsTable}>
          <thead>
            <tr className={`${templeteStyle.questionsTableRow}`}>

              <th>
                <p>Sr No.</p>
              </th>
              <th>Name</th>
              <th>Unit</th>
              <th>Kam Remark</th>
              <th>Vendor Reply</th>
              <th>Vendor Remarks</th>
              {/* <th> Action</th> */}
            </tr>
          </thead>
          <tbody>
            {
              questionRows.map((row, index) => {
                return (
                  <tr className={`${templeteStyle.questionsTableRow}`}>
                    <td style={{textAlign:"center"}}>
                      {index + 1}
                    </td>
                    <td>
                      <textarea
                        // style={{
                        //   display: 'flex', justifyContent: 'center', textAlign: 'justify', padding: '0px 15px'
                        // }}
                        value={row.name}
                        className={templeteStyle.questionRowTextarea}
                        name="name"
                        disabled={auth.roles !== "KAM"}
                        onChange={(e) => {
                          auth.roles === "KAM" && handleInputChange(e, index)
                        }}
                      />

                    </td>
                    <td>
                      {auth.roles === "KAM" ? <select className={templeteStyle.questionRowSelect} name="unit" id="" value={row.unit} onChange={(e) => handleInputChange(e, index)}>
                        <option value="date">Date</option>
                        <option value="text">Text</option>
                        <option value="select">Select</option>
                        <option value="percentage">Percentage</option>
                      </select>
                        :
                        <input className={templeteStyle.questionRowInput} type="text" value={row.unit} readOnly />
                      }

                    </td>
                    <td>

                      <textarea
                        // style={{
                        //   display: 'flex', justifyContent: 'center', textAlign: 'justify', padding: '0px 15px'
                        // }}
                        value={row.remark}
                        className={templeteStyle.questionRowTextarea}
                        name="remark"
                        disabled={auth.roles !== "KAM"}
                        onChange={(e) => {
                          auth.roles === "KAM" && handleInputChange(e, index)
                        }}
                      />

                    </td>
                    <td>

                      {auth.roles === "VENDOR" ?
                        <DynamicInput value={row.value} unit={row.unit} index={index} handleInputChange={handleInputChange} expectedAnswer={row.expectedAnswer} />
                        :
                        <input className={templeteStyle.questionRowInput} type="text" value={row.value} readOnly />


                      }

                    </td>
                    <td>
                      <textarea
                        value={row.vendorRemark}
                        className={templeteStyle.questionRowTextarea}
                        name="vendorRemark"
                        onChange={(e) => {
                          if (auth.roles === "VENDOR") {
                            handleInputChange(e, index);
                          }
                        }}
                        disabled={auth.roles !== "VENDOR"}
                      />
                    </td>
                    <td>
                      {/* <span onClick={() => {handleDeleteModal(row.id) }}>
                      <DeleteOutlineIcon />
                    </span> */}
                    </td>
                  </tr>
                )

              })
            }

          </tbody>
        </table>
        {
          auth.roles !== "VENDOR" &&
        <div
          className={templeteStyle.questiontableFooter}
          onClick={handleAddQuestionRow}
        ><span  ><AddCircleOutlineIcon /></span>Add Question</div>
}
      </div>


      <DeleteConfirmation
        onCancel={handleDeleteModal}
        onConfirm={() => { handleQuestionRowDelete(deleteId); handleDeleteModal() }}
        open={deleteModal}
      // confimBtnText={""} 
      // discardText={""}
      >
        <h2>Delete Question</h2>
        <p>Are you sure you want to delete this Question?</p>

      </DeleteConfirmation>

    

    </>
  )
}

export default TempleteQuestionsTab



const DynamicInput = ({ value, unit, index, handleInputChange, expectedAnswer }) => {

  const [selectOptions, setSelectOptions] = useState([]);
  useEffect(() => {
    if (unit === "select") {

      const rr = expectedAnswer?.split(",");
      console.log(expectedAnswer);
      console.log(rr);
      setSelectOptions(rr);
    }
  }, [unit, expectedAnswer])

  switch (unit?.toLowerCase()) {
    case "text": return (
      <input className={templeteStyle.questionRowInput} type="text" name='value' value={value} onChange={(e) => handleInputChange(e, index)} />
    )
      break;
    case "select": return (
      <select
      name="value"
      id=""
      className={templeteStyle.questionRowSelect}
      value={value}
      onChange={(e) => handleInputChange(e, index)}
    >
       <option  value={""} >
          please Select Option
        </option>
      {selectOptions?.map((opt, i) => (
        <option key={i} value={opt} >
          {opt}
        </option>
      ))}
    </select>

    )
      break;
    case "date": return (
      <input type="date" className={templeteStyle.questionRowInput} name='value' value={value} onChange={(e) => handleInputChange(e, index)} />

    )
      break;
    case "percentage": return (
      <input type="text" name='value' pattern="[0-9]*"
        className={templeteStyle.questionRowInput}
        onKeyPress={(e) => {
          const onlyNumeric = /[0-9]/;
          const key = e.key;
          if (!onlyNumeric.test(key)) {
            e.preventDefault();
          }
        }}
        value={value} onChange={(e) => handleInputChange(e, index)} />

    )
      break;
      default: return (
        <input className={templeteStyle.questionRowInput} type="text" name='value' value={value} onChange={(e) => handleInputChange(e, index)} />
      )

  }

}

