import React, { useState, useEffect } from 'react'
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css'
import revisionEditStyle from '../../pages/RevisionEdit/RevisionEdit.module.css'
import useBackdrop from 'hooks/useBackdrop'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ChatPannel from 'components/ChatPannel/ChatPannel';
import ChatRows from 'components/ChatRows/ChatRows';
import ChatHeader from 'components/ChatHeader/ChatHeader';
import ChatInputField from 'components/ChatInputField/ChatInputField';
import ChatRow from 'components/ChatRows/ChatRow';
import TempleteDescriptionTab from './TempleteDescriptionTab';
import TempleteDucumentsTab from './TempleteDocumentsTab';
import TempleteQuestionsTab from './TempleteQuestionsTab'
import { createExcel } from 'utility/excelUtils';
// =======================icons===========================

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// =======================icons===========================

// =====================mui compoents====================
import Tooltip from '@mui/material/Tooltip';
import ThreeDotDropDown from 'components/ThreeDotDropDown/ThreeDotDropDown';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';
import useToast from 'hooks/useToast';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';
import BackButton from 'components/BackButton/BackButton';
import SendForApprovalModal from 'components/SendForApprovalModal/SendForApprovalModal';


// =====================mui compoents====================

const CommercialTempleteViewVendor = () => {

  // Hook initialization
  const { showToast, Toast } = useToast();
  const { revisionId } = useParams();
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop()
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const privateAxios = PrivateComponent();
  const { auth } = useAuth();

  // State initialization
  // Description related states
  const [revisionDescription, setRevisionDescription] = useState("");
  const [descriptionVendorRemark, setDescriptionVendorRemark] = useState("");

  // Send for approval modal state
  const [sendForApprovalModalOpen, setSendForApprovalModalOpen] = useState(false);
  const [revisionComment, setRevisonComment] = useState("");

  // Function to handle approval modal
  const handleApprovalModal = () => {
    setSendForApprovalModalOpen(prev => !prev);
  }

  // Function to handle approval submit
  const handleAppovalSubmit = async (data) => {
    try {
      const res = await privateAxios.post(`/commercial/sendCommercialVendorRevisionToKAMFromVendor/${revisionId}`, { comments: data.name });
      showToast("success", "revision Sent to Kam")
      setRevisonComment("");
      setSendForApprovalModalOpen();
    } catch (error) {
      showToast("error", "Error");
      setSendForApprovalModalOpen();
    }
  }
  // ================Back Button========

  const handleBackButton = () => {
    navigate(-1);
  }

  // ==============tab selector and state=================
  const searchParams = new URLSearchParams(location.search);
  const [tabActive, setTabActive] = useState(0 || searchParams.get("tab"));
  useEffect(() => {
    setTabActive(searchParams.get("tab"));
  }, [Location, searchParams])

  const handleTabChange = (index) => {

    setTabActive(index)
    navigate(`?tab=${index}`, { replace: true })
  }


  // =============header three dot=============
  const [headerThreeDot, setHeaderThreeDot] = useState(false)
  const handleheaderThreeDot = () => {
    setHeaderThreeDot(prev => !prev);
  }

  // =================multisave ================
  const [multiSave, setMultiSave] = useState(false);

  const handleMultiSave = () => {
    setMultiSave(prev => !prev);
  }


  // ==================chat state =================
  const [chatSlider, setChatSlider] = useState(false);
  const [chatValue, setChatValue] = useState("");
  const [revisionChats, setRevisionChats] = useState([]);

  const handleChatSlider = () => {
    setChatSlider(prev => !prev);
  }
  useEffect(() => {
    if (chatSlider === true) {

      getRemarkAllChat()
    }

  }, [chatSlider])

  const handleChatSend = async (e) => {
    e.preventDefault();

    try {
      if (chatValue.trim() !== "" && chatValue !== undefined && chatValue !== null) {
        const res = await privateAxios.post(`/commercial/addRemarkOnCommercialRevisionByVendor/${auth.userData.id}/${revisionId}`, { remark: chatValue });

        if (res.status === 200) {
          // Check the status code or other relevant information in the response
          getRemarkAllChat();
          setChatValue("");
          showToast("success", "Remark added successfully");
        } else {
          showToast("error", "Failed to add remark. Please try again.");
        }
      } else {
        showToast("info", "Please enter a non-empty remark");
      }
    } catch (error) {
      console.error("Error adding remark:", error);
      showToast("error", "Error adding remark. Please try again.");
    }
  };






  // ==================chat state =================
  const getRemarkAllChat = async () => {
    handleChatNotification();// Trigger a chat notification refresh
    try {
      const res = await privateAxios.get(`/commercial/getRemarksOfCommercialVendorRevision/${revisionId}`).then(res => {
        setRevisionChats(res.data);// Set the chat remarks state with response data
      }).catch((err) => {
        // Handle any errors from the axios get request
      })
    } catch (error) {
      console.log("Api error " + error); // Log any errors encountered in the try block
    }
  }
  // ==================rename  state =================
  const [renameModal, setRenameModal] = useState(false);// State for rename modal visibility
  const [revisionDetails, setRevisionDetails] = useState(null);// State for revision details

  const handleRenameModal = () => {
    setRenameModal(prev => !prev);// Toggle rename modal visibility
  }

  // ============document states=========================

  const [documents, setDocuments] = useState([]);// State for documents
  const [addDocumentModal, setAddDocumentModal] = useState(false);// State for add document modal visibility
  const handleAddDocumentModal = () => {
    setAddDocumentModal(prev => !prev); // Toggle add document modal visibility
  }
  const handleDocumentUpload = async (data) => {
    openBackdrop(); // Open a backdrop/loading indicator
    try {
      const fd = new FormData();
      fd.append(`files`, data.files);// Append file to FormData
      fd.append("name", data.fileName);// Append file name to FormData
      fd.append("docType", data.type);// Append document type to FormData
      fd.append("uploadedBy", auth.roles);// Append uploader role to FormData
      const res = await privateAxios.post(`/commercial/uploadCommercialDocumentsOnVendorRevision/${revisionId}`, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })

      await getDataProcure();// Refresh procurement data
      showToast("success", "Document Uploaded Successfully");// Show success toast
    } catch (error) {
      showToast("error", "Error Uploading Document");// Show error toast
    }
    closeBackdrop();// Close the backdrop/loading indicator

  }

  // ============document states=========================

  // ===================Question State========================

  const [questionRows, setQuestionRows] = useState([]);// State for question rows


  const handleAddQuestionRow = () => {
    const newRow = {
      id: questionRows[questionRows.length - 1].id + 1,// Generate new row id
      name: "",
      unit: "text",
      value: "",
      remark: ""

    }

    setQuestionRows((prev) => {
      return [
        ...prev,
        newRow
      ]
    })
  }

  const handleQuestionRowDelete = (id) => {
    setQuestionRows((prev) => {
      return prev.filter((row) => row.id !== id);// Remove row by id
    })

  }
  const handleInputChange = (e, rowIndex) => {
    const { value, name } = e.target;
    setQuestionRows((prev) => {
      return prev.map((row, index) => {
        if (index === rowIndex) {

          return {
            ...row,
            [name]: value,// Update the specific field in the row
          }
        }
        else {
          return row;
        }
      })
    })

  }


  // ===================Question State========================

  const getDataProcure = async () => {
    openBackdrop();// Open backdrop/loading indicator
    try {
      const res = await privateAxios.get(`/commercial/getVendorCommercialRevision/${revisionId}`)

      const { data } = res;
      setRevisionDetails({
        name: data.name,
        description: {
          name: data.description.name
        },
        questions: data.questions,
        documents: data.documents,
      });
      setQuestionRows(data.questions);// Set questions state
      setDocuments(data.documents); // Set documents state
      setRevisionDescription(data.description.name);// Set revision description state
      setDescriptionVendorRemark(data.description.vendorRemark);// Set vendor remark state
      closeBackdrop();// Close backdrop/loading indicator
    } catch (error) {
      console.log("Erro// Close backdrop/loading indicator on errorr");
      closeBackdrop();
    }
  }

  useEffect(() => {
    getDataProcure();// Fetch data on component mount
  }, [])


  // ==================save Revisions========================

  const handleSaveRevision = async () => {
    openBackdrop();// Open backdrop/loading indicator

    try {

      const updatedQuestions = questionRows.map((ques) => {
        let que = ques;
        delete que.id;// Remove id from question object
        if (que.unit === null || que.unit === undefined || que.unit === "") {
          que.unit = "text";// Set default unit if not specified
        }
        return que;
      })
      const updatedDocuments = documents.map((doc) => {
        let dd = doc;
        delete dd.id;// Remove id from document object
        return dd;
      })
      const payload = { name: revisionDetails.name, description: { name: revisionDescription, vendorRemark: descriptionVendorRemark }, documents: updatedDocuments, questions: updatedQuestions }

      const res = await privateAxios.post(`/commercial/updateVendorCommercialRevision/${revisionId}`, payload)
      const { data } = res;

      showToast("success", "Revision Saved");// Show success toast
      getDataProcure();// Refresh data
      closeBackdrop();// Close backdrop/loading indicator

    } catch (error) {
      showToast("error", "Error Saving Revision");// Show error toast
      closeBackdrop();// Close backdrop/loading indicator
    }
  }

  // ==================save as update Revisions========================
  // =================download revision==============================

  const handleDownloadRevision = () => {

    // extracting text from Richeditor string
    let tempElement = document.createElement('div');
    tempElement.innerHTML = revisionDescription;
    let extractedText = tempElement.textContent;
    extractedText = extractedText.trim();

    // taking nessarry feilds from attributes
    const questions = questionRows.map((attri) => {
      const modifiedAttri = { ...attri };
      delete modifiedAttri.id;

      return { ...modifiedAttri };

    });
    let revisionDocument = [] // Initialize revisionDocument array

    // Check if revisionId is "0"
    if (revisionId === "0") {
        // If revisionId is "0", set revisionDocument based on documents array
      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })

    }
    else {
      revisionDocument = documents.map((doc) => {
        return {
          name: "Not Available",
          link: doc.url,
          type: "normal"

        }
      })
    }


    createExcel([[{ description: extractedText }], questions, revisionDocument], (revisionDetails.name || "Revision 0"))
  }

  // =====================chat notification========================
  const [reRender, setReRender] = useState(false);

  const handleChatNotification = () => {
    setReRender(prev => !prev);

  }

  // =====================chat notification========================

  return (
    <>
      <Toast />
      <BackdropComponent />

      <div className={workSpaceStyle.workSpaceMain}>

        <div className={workSpaceStyle.workSpaceHeader}>
          <div className={workSpaceStyle.workSpaceHeaderLeft}>
            <h1><BackButton />{revisionDetails?.name || "No Name"}</h1>
            <p>{revisionDetails?.productName}</p>


          </div>

          <div className={workSpaceStyle.workSpaceHeaderRight}>

            {tabActive === "2" && <div className={workSpaceStyle.workSpaceHeaderAddButton} style={{ position: "relative" }}>
              <button onClick={handleAddDocumentModal}><AddIcon />Add</button>


            </div>}
            <div className={workSpaceStyle.multiSaveButton}>
              <button onClick={handleMultiSave}>
                Save
                <span>
                  {multiSave
                    ? <KeyboardArrowUpIcon />
                    : <KeyboardArrowDownIcon />
                  }
                  {/*  */}
                </span> </button>

              {multiSave &&
                <ul>
                  {revisionId !== "0" && <li onClick={() => { setMultiSave(false); handleSaveRevision(); }}>Update & Save</li>}
                </ul>
              }
            </div>

            <span className={workSpaceStyle.workSpaceHeaderRevisionMore}>
              <Tooltip title='Comments'>
                <span onClick={() => { handleChatSlider(); getRemarkAllChat() }} >
                  <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialVendorRevision`} id={revisionId} reRender={reRender} />
                </span>
              </Tooltip>
            </span>

            <div className={workSpaceStyle.workSpaceThreeDotVertical} style={{ position: "relative" }}>

              <span className={workSpaceStyle.workSpaceHeaderRevisionMore}
                onClick={handleheaderThreeDot}
              >
                <Tooltip title='more'><MoreVertIcon /></Tooltip>

              </span>
              {
                headerThreeDot
                &&
                <ThreeDotDropDown toggle={handleheaderThreeDot}>
                  {revisionId !== "0" && <li onClick={() => { handleheaderThreeDot(); handleApprovalModal() }}> <span ><PersonAddAltOutlinedIcon /></span>Send for Approval</li>}
                  <li onClick={() => { handleheaderThreeDot(); handleDownloadRevision() }}>
                    <span><FileDownloadOutlinedIcon /></span>Download</li>

                </ThreeDotDropDown>

              }
            </div>
            <div className={workSpaceStyle.WorkSpaceHeaderhelp}>
              <Tooltip title='Help'><HelpOutlineOutlinedIcon /></Tooltip>
            </div>
          </div>

        </div>

        <div className={revisionEditStyle.tabHeaderMain}>
          <div
            onClick={() => handleTabChange("0")}
            className={tabActive === "0" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Description</div>
          <div
            onClick={() => handleTabChange("1")}
            className={tabActive === "1" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Questions</div>
          <div
            onClick={() => handleTabChange("2")}
            className={tabActive === "2" ? `${revisionEditStyle.revisionTab} ${revisionEditStyle.revisionTabActive} ` : `${revisionEditStyle.revisionTab}`}>Documents</div>
        </div>

        <div className={revisionEditStyle.tabBodyMain} >
          {tabActive === "0" && <TempleteDescriptionTab showToast={showToast} setRevisionDescription={setRevisionDescription} revisionDescription={revisionDescription} descriptionVendorRemark={descriptionVendorRemark} setDescriptionVendorRemark={setDescriptionVendorRemark} />}
          {tabActive === "1" && <TempleteQuestionsTab showToast={showToast} questionRows={questionRows} handleInputChange={handleInputChange} handleAddQuestionRow={handleAddQuestionRow} handleQuestionRowDelete={handleQuestionRowDelete} />}
          {tabActive === "2" && <TempleteDucumentsTab showToast={showToast} addDocumentModal={addDocumentModal} handleAddDocumentModal={handleAddDocumentModal} getDataProcure={getDataProcure} documents={documents} handleDocumentUpload={handleDocumentUpload} />}

        </div>



      </div>


      <SendForApprovalModal
        open={sendForApprovalModalOpen}
        onClose={handleApprovalModal}
        onSubmit={handleAppovalSubmit}
        prefilled={""}
        header="Send to Orgnaisation KAM"
      />



      {/* chat pannel */}

      <ChatPannel
        open={chatSlider}
        onClose={handleChatSlider}
      >
        <>
          <ChatHeader onClose={handleChatSlider}
            downloadData={revisionChats.map((chat) => { return { name: chat.name, date: chat.createdAt, chat: chat.remark } })
            }
            helpData={{ link: "tosomewhere" }}
            chatName={revisionDetails?.name || "No Name"}
          >
            <h3>{revisionDetails?.name || "No Name"}</h3>
          </ChatHeader>

          <ChatRows>
            {revisionChats.length > 0 ? revisionChats.map((rev, index) => {
              return (
                <ChatRow key={index} name={rev.name} chat={rev.remark} date={rev.createdAt} index={index} email={rev.email} />
              )
            })
              :
              <p style={{ textAlign: "center" }}>
                No Previous Chat
              </p>
            }

          </ChatRows>

          <ChatInputField onChatSend={handleChatSend} chatValue={chatValue} setChatValue={setChatValue} />

        </>
      </ChatPannel>
    </>
  )
}

export default CommercialTempleteViewVendor
