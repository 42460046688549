import React from 'react'
import TaskStyle from 'pages/CreateTask/TaskPannel/TaskPannel.module.css'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Typography from '@mui/material/Typography';
import GroupsIcon from '@mui/icons-material/Groups';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { PrivateComponent } from 'api/axios';
import CloseIcon from '@mui/icons-material/Close';
import { useRef } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import useAuth from 'hooks/useAuth';
import useBackdrop from "hooks/useBackdrop";
import DescriptionIcon from '@mui/icons-material/Description';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tooltip } from '@mui/material';
import ProfileIcon from 'components/ProfileIcon/ProfileIcon';
import EditChatPanel from '../../../EditCreateTask/EditChatPanel/EditChatPanel';
import FetchUsersComponent from '../../../../components/FetchUsersComponent/FetchUsersComponent'
import { useNavigate, useParams } from 'react-router-dom';
import PrioritySelector from 'components/TaskComponents/PrioritySelector/PrioritySelector';
import OptionSelector from 'components/TaskComponents/OptionSelector/OptionSelector';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
};

const styleUploadFiles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    p: 2,

};

const styleSelectEmails = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    p: 2,
};


function EditTasksCommercial({ handleEditTaskModal, getAllTasks, editData }) {
    // Extract client parameter from URL
    const { client } = useParams();

    // Get authentication information
    const { auth } = useAuth();

    // Navigation hook from react-router-dom
    const navigate = useNavigate();

    // Backdrop handling functions
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();

    // Current date in YYYY-MM-DD format
    const currentDate = new Date().toISOString().slice(0, 10);

    // Private Axios instance
    const privateAxios = PrivateComponent();

    // State to store the original selected emails
    const [originalSelectedEmails, setOriginalSelectedEmails] = useState([]);

    // State to store selected files
    const [selectedFiles, setSelectedFiles] = useState([]);

    // State to store original uploaded files
    const [originalUploadedFiles, setOriginalUploadedFiles] = useState([]);

    // Reference to input element
    const inputRef = useRef(null);

    // Reference to date input element
    const dateRef = useRef(null);

    // State to store files
    const [files, setFiles] = useState("");

    // State to manage loading of users
    const [loadingUsers, setLoadingUsers] = useState(false);

    // State to store all task data, initialized with editData
    const [data, setData] = useState({    // set all data
        title: editData.title,
        description: editData.description,
        startDate: editData.startDate, // Set the default value to today's date
        endDate: editData.endDate,
        status: editData.status,
        priority: editData.priority,
        userExpectedCompletionDate: '',
        id: editData.taskId,
        productId: editData.taskProductId

    })

    // Workspace ID (hardcoded as 2)
    const workspaceId = 2;

    // Fetch users component call
    const [userList, allEmails, notLoggedIn] = FetchUsersComponent({ orgId: auth.orgId, workspaceId: workspaceId })

    // Effect to handle attachment documents and assignTo emails on editData change
    useEffect(() => {

        if (editData?.attachmentDocuments && editData.attachmentDocuments.length > 0) {
            const promises = editData.attachmentDocuments.map((doc) => handleAttachedDocument(doc));

            Promise.all(promises)
                .then((files) => {
                    setSelectedFiles(files.filter((file) => file !== null));
                    setOriginalUploadedFiles(files.filter((file) => file !== null));
                })
                .catch((error) => {
                    // console.log(error);
                });
        }

        if (editData) {
            setSelectedEmails(editData.assignTo.map(per => per.email));
            setOriginalSelectedEmails(editData.assignTo.map(per => per.email));
        }
    }, [editData]);


    // Function to handle attached documents
    const handleAttachedDocument = async (doc) => {
        try {
            const response = await privateAxios.get(`/task/file/${doc.documentNewName}`, {
                responseType: "blob" // Request response as a Blob
            });
            const file = new File([response.data], doc.documentName, { type: response.headers["content-type"] });
            return file;
        } catch (err) {
            // console.log(err);
            return null;
        }
    };
    // Initial selected emails from editData
    const initialEmails = editData.assignTo.map(item => item.email);

    // State to store delivery terms
    const [deliveryTerms, setDeliveryTerms] = useState([]);

    // State to manage form errors
    const [formError, setFormError] = useState({});

    // State for upload modal visibility
    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    // State for email selection modal visibility
    const [emailModalOpen, setEmailModalOpen] = useState(false);

    // State to store KAM ID
    const [kamId, setKamId] = useState('');

    // State for selected emails
    const [selectedEmails, setSelectedEmails] = useState(initialEmails);

    // State for selected file names
    const [selectedFileNames, setSelectedFileNames] = useState([]);

    // State to store file sizes
    const [fileSizes, setFileSizes] = useState([]);

    // Close the upload modal
    const handleUploadModalClose = () => {
        setUploadModalOpen(false);
    };

    // State for chat messages
    const [chats, setChats] = useState([]);



    // ======================fetch category========================

    // State to store available categories
    const [availableCategory, setAvailableCategory] = useState([]);

    // Fetch category data
    const fetchCategory = async () => {
        openBackdrop();
        try {
            await privateAxios.get(`/procurement/getCategories/${auth.orgId}`).then(res => {

                // console.log(res.data.data.categories)
                setAvailableCategory(res.data.data.categories);
            }).catch((err) => {
                alert("error getting categories");
                // console.log("error getting categories");
            })
        } catch (error) {
            // console.log(error)
        }

        closeBackdrop();
    }

    // Fetch categories on component mounts
    useEffect(() => {
        fetchCategory();
    }, [])

    // State to store documents
    const [documents, setDocuments] = useState([]);

    // Open email selection modal
    const handleEmailModalOpen = () => {
        setEmailModalOpen(true); // Open the modal
    };

    // Handle checkbox change in email selection
    const handleEmailCheckboxChange = (email) => {
        const isSelected = selectedEmails.includes(email);
        // Clone the selectedEmails array to avoid mutating the state directly
        const updatedSelectedEmails = [...selectedEmails];

        if (updatedSelectedEmails.includes(email)) {
            // If the email is already in the selectedEmails, remove it
            const index = updatedSelectedEmails.indexOf(email);
            if (index > -1) {
                updatedSelectedEmails.splice(index, 1);
            }
        } else {
            // If the email is not in the selectedEmails, add it
            updatedSelectedEmails.push(email);
        }

        // Update the state with the new selected emails
        setSelectedEmails(updatedSelectedEmails);
    };

    // Remove a selected file by index
    const handleRemoveSelectedFile = (index) => {
        const updatedSelectedFiles = [...selectedFiles];
        const updatedSelectedFileNames = [...selectedFileNames];
        updatedSelectedFiles.splice(index, 1);
        updatedSelectedFileNames.splice(index, 1);
        setSelectedFiles(updatedSelectedFiles);
        setSelectedFileNames(updatedSelectedFileNames);
    };

    // Close the email selection modal
    const handleEmailModalClose = () => {
        // Store only the first letter of the last selected email in selectedEmailsDisplay
        if (selectedEmails.length > 0) {
            const lastSelectedEmail = selectedEmails[selectedEmails.length - 1];
            const firstLetter = lastSelectedEmail.charAt(0);

        }

        setEmailModalOpen(false);
    };
    // Declare and initialize setIsDateSelected and isDateSelected
    const [isDateSelected, setIsDateSelected] = useState(false);

    // Open the file upload modal
    const handleUploadModalOpen = () => {
        setSelectedFiles([]); // Clear the selected files
        setUploadModalOpen(true);
    };
    // Function to handle file selection
    const handleFileInputChange = (e) => {
        const files = e.target.files || e.dataTransfer.files;
        setFiles(files);

        // Get the file sizes and update the state
        const sizes = Array.from(files).map((file) => formatFileSize(file.size));
        setFileSizes(sizes);

        const fileNames = Array.from(files).map((file) => file.name);
        setSelectedFiles([...selectedFiles, ...files]);
        setSelectedFileNames([...selectedFileNames, ...fileNames]);
    };

    // Get the file extension from file name
    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        return parts.length > 1 ? `.${parts.pop()}` : '';
    };


    // Truncate file name if it exceeds maxLength
    function truncateFileName(fileName, maxLength) {
        if (typeof fileName !== 'string' || fileName.length === 0) {
            return ''; // Return an empty string or handle this case as needed
        }

        if (fileName.length <= maxLength) {
            return fileName;
        }

        const truncatedFileName = fileName.substring(0, maxLength - 5) + '...';
        return truncatedFileName;
    }
    // Function to format file size for display
    const formatFileSize = (size) => {
        if (size < 1024) {
            return size + ' B';
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(2) + ' KB';
        } else if (size < 1024 * 1024 * 1024) {
            return (size / (1024 * 1024)).toFixed(2) + ' MB';
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        }
    };



    // Function to validate form input
    const handleInputValidation = () => {
        const isEmpty = Object.values(data).some(dd => dd === '');
        // Check if any field in data is empty
        if (!isEmpty && selectedEmails.length !== 0) {
            // If no empty fields and at least one email selected, clear form error and return true
            setFormError({});
            return true;
        } else {
            setFormError({});
            // If there are empty fields or no emails selected, set form error and return false
            if (data.title === '') {
                alert('Please Enter title');
                setFormError(prev => ({
                    ...prev,
                    title: "Please Enter Title"
                }));
                return false
            }
            else if (data.description === '') {
                alert('Please Enter Description');
                setFormError(prev => ({
                    ...prev,
                    description: "Please Add Description"
                })); return false
            }
            else if (data.startDate === '') {
                alert('Please select start date');
                setFormError(prev => ({
                    ...prev,
                    startDate: "Please select start date"
                })); return false
            }
            else if (data.endDate === '') {
                alert('Please select end date');
                setFormError(prev => ({
                    ...prev,
                    endDate: "Please select end date"
                })); return false
            } else if (data.priority === '') {
                alert('Please select priority');
                setFormError(prev => ({
                    ...prev,
                    priority: "Please select priority"
                })); return false
            } else if (selectedEmails.length === 0) {
                alert('Please select email');
                setFormError(prev => ({
                    ...prev,
                    emailSelected: "Please select Atleast One Person"
                })); return false
            }
            return true;
        }
    };

    // Function to check if two arrays are equal
    function arraysAreEqual(array1, array2) {
        if (array1.length !== array2.length) {
            return false;
        }

        for (let i = 0; i < array1.length; i++) {
            if (array1[i] !== array2[i]) {
                return false;
            }
        }

        return true;
    }

    // Function to handle task creation/update
    const handleCreateTask = async () => {
        // Validate form input
        if (handleInputValidation()) {
            openBackdrop()

            try {
                const payload = {
                    title: data.title,
                    description: data.description,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    // userExpectedCompletionDate: "",
                    status: data.status,
                    priority: data.priority,
                };

                const payloadToSend = [];

                // Check for changes in selected files
                if (selectedFiles && originalUploadedFiles) {
                    const payloadFiles = selectedFiles.map((file) => file.name);
                    const originalFiles = originalUploadedFiles.map((file) => file.name);
                    const addedFiles = payloadFiles.filter(file => !originalFiles.includes(file));
                    const deletedFiles = originalFiles.filter(file => !payloadFiles.includes(file));

                    let previousLogs = '';
                    let newLogs = '';
                    if (!arraysAreEqual(payloadFiles, originalFiles)) {
                        // Generate logs for file changes
                        if (addedFiles.length > 0 && deletedFiles.length === 0) {
                            previousLogs += `File${addedFiles.length > 1 ? 's' : ''} ${addedFiles.join(', ')} ${addedFiles.length > 1 ? 'were' : 'was'} not present. `;
                            newLogs += `File${addedFiles.length > 1 ? 's' : ''} ${addedFiles.join(', ')} ${addedFiles.length > 1 ? 'were' : 'was'} added. `;
                        } else if (deletedFiles.length > 0 && addedFiles.length === 0) {
                            previousLogs += `File${deletedFiles.length > 1 ? 's' : ''} ${deletedFiles.join(', ')} ${deletedFiles.length > 1 ? 'were' : 'was'} present. `;
                            newLogs += `File${deletedFiles.length > 1 ? 's' : ''} ${deletedFiles.join(', ')} ${deletedFiles.length > 1 ? 'were' : 'was'} removed. `;
                        } else {
                            // alert("is here")
                            previousLogs += `File${deletedFiles.length > 1 ? 's' : ''} ${deletedFiles.join(', ')} ${deletedFiles.length > 1 ? 'were' : 'was'} present but removed now. `;
                            newLogs += `File${addedFiles.length > 1 ? 's' : ''} ${addedFiles.join(', ')} ${addedFiles.length > 1 ? 'were' : 'was'} added. `;
                        }

                        payloadToSend.push({
                            name: auth.name,
                            logDetails: `${auth.name} updated the files`,
                            previousLogs,
                            newLogs,
                        });
                    }
                }

                // Check for changes in selected emails
                if (selectedEmails && originalSelectedEmails) {
                    const addedEmails = selectedEmails.filter(email => !originalSelectedEmails.includes(email));
                    const deletedEmails = originalSelectedEmails.filter(email => !selectedEmails.includes(email));

                    let previousLogs = '';
                    let newLogs = '';

                    if (!arraysAreEqual(selectedEmails, originalSelectedEmails)) {
                        if (addedEmails.length > 0 && deletedEmails.length === 0) {
                            previousLogs += `Email${addedEmails.length > 1 ? 's' : ''} ${addedEmails.join(', ')} ${addedEmails.length > 1 ? 'were' : 'was'} not present. `;
                            newLogs += `Email${addedEmails.length > 1 ? 's' : ''} ${addedEmails.join(', ')} ${addedEmails.length > 1 ? 'were' : 'was'} added. `;
                        } else if (deletedEmails.length > 0 && addedEmails.length === 0) {
                            previousLogs += `Email${deletedEmails.length > 1 ? 's' : ''} ${deletedEmails.join(', ')} ${deletedEmails.length > 1 ? 'were' : 'was'} present. `;
                            newLogs += `Email${deletedEmails.length > 1 ? 's' : ''} ${deletedEmails.join(', ')} ${deletedEmails.length > 1 ? 'were' : 'was'} removed. `;
                        } else {
                            previousLogs += `Email${deletedEmails.length > 1 ? 's' : ''} ${deletedEmails.join(', ')} ${deletedEmails.length > 1 ? 'were' : 'was'} present but removed now. `;
                            newLogs += `Email${addedEmails.length > 1 ? 's' : ''} ${addedEmails.join(', ')} ${addedEmails.length > 1 ? 'were' : 'was'} added. `;
                        }

                        payloadToSend.push({
                            name: auth.name,
                            logDetails: `${auth.name} updated the emails`,
                            previousLogs,
                            newLogs,
                        });
                    }
                }


                for (const key in payload) {
                    if (payload.hasOwnProperty(key) && editData.hasOwnProperty(key) && payload[key] !== editData[key]) {
                        // Generate logs for email changes
                        console.log(key + "Thi is key \n" + "payload" + payload[key] + "\neditdatakey" + editData[key])
                        payloadToSend.push({
                            name: auth.name,
                            logDetails: `${auth.name} updated the ${key}`,
                            previousLogs: editData[key],
                            newLogs: payload[key],
                        });
                    }
                }

                const response = await privateAxios.post(`/commercialTask/editCommercialTask/${editData.taskId}/${auth.orgId}`, payload)
                    .then(async (response) => {

                        const taskId = response.data.taskId;

                        if (taskId !== null) {


                            if (files.length > 0) {
                                const fd = new FormData();
                                for (let i = 0; i < files.length; i++) {
                                    fd.append(`files`, files[i]);
                                }

                                // setMsg("uploading...");
                                console.log(files)

                                await privateAxios.post(`/task/uploadTaskAttachments/${editData.taskId}`, fd, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data',
                                    }
                                }).catch((err) => {
                                    alert("error uploading Files")
                                    console.error(err);
                                });

                            }


                            await privateAxios.post(`/task/addUsersToTask/${editData.taskId}`, { emails: selectedEmails }).catch(err => {
                                alert("Error Adding User to task")
                                // console.log("err in user add creation" + err)
                            })

                            if (chats.length > 0) {
                                chats.forEach(async (chat) => {
                                    await privateAxios.post(`/task/addComments/${taskId}`,
                                        { comment: chat.comment, name: chat.name })
                                })
                            }

                            console.log(payloadToSend)
                            await privateAxios.post(`/task/getTaskHistory/${editData.taskId}`, payloadToSend).then((res) => {
                            })
                            setUploadModalOpen(false);

                            setFiles("");
                            getAllTasks();
                        }
                        else {
                            alert("error getting task Id");
                        }


                    }).catch(err => {
                    })
                handleEditTaskModal()
                closeBackdrop()

            } catch (error) {
                closeBackdrop()
            }

        }
        else {
        }
    };


    const handlePriorityChange = (event) => {// Handle changing the priority
        setData({ ...data, priority: event.target.value });
    };
    const handleStatusChange = (event) => {// Handle changing the status
        setData({ ...data, status: event.target.value });
    };


    // Handle changing the start dates
    const handleStartDateChange = (event) => {
        const selectedStartDate = event.target.value;
        const startDate = new Date(selectedStartDate);
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 1); // Set the end date to the day after the start date

        // Update the start and end date in your component's state
        setData({
            ...data,
            startDate: selectedStartDate,
            endDate: endDate.toISOString().slice(0, 10) // Format as 'YYYY-MM-DD'
        });
    };


    const handleEndDateChange = (event) => {
        setData({ ...data, endDate: event.target.value });

        // Check if the end date is earlier than the start dat
    };

    const handleEcDateChange = (event) => {
        const selectedEcDate = event.target.value;
        setData({ ...data, userExpectedCompletionDate: selectedEcDate });

        // Set the minimum date for the "End date" to the selected "E/C date"
        dateRef.current.min = selectedEcDate;
    };


    // ----------------------------Date----------------------------

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return date.toLocaleDateString(undefined, options);
    };

    // ----------------------------Date----------------------------




    const [hoveredFileIndex, setHoveredFileIndex] = useState(null);
    // Event handler for onMouseEnter
    const handleMouseEnter = (index) => {
        setHoveredFileIndex(index);
    };

    // Event handler for onMouseLeave
    const handleMouseLeave = () => {
        setHoveredFileIndex(null);
    };

    // Event handler for onMouseEnter
    const handleFilePreview = (file) => {
        console.log(file)
        const fileURL = URL.createObjectURL(file);
        console.log(fileURL)
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = file.name;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(a);
    }
    // console.log(selectedFiles)

    return (
        <>
            <BackdropComponent />
            <div className={TaskStyle.taskModalWrapper} >

                <div className={TaskStyle.taskModal}>

                    <div className={TaskStyle.taskInfoWrapper} >


                        <div className={TaskStyle.taskInfo}>



                            <div className={TaskStyle.taskNav}>
                                <h1 className={TaskStyle.createTask} >
                                    {auth.roles === "KAM" ?
                                        " Edit a task"

                                        : 'View Task'}
                                </h1>
                            </div>


                            <div className={TaskStyle.taskHeading}>

                                <input
                                    disabled={auth.roles !== "KAM"}
                                    placeholder='Title'
                                    value={data.title}
                                    onChange={(e) => setData({ ...data, title: e.target.value })}
                                />

                                <textarea value={data.description}
                                    disabled={auth.roles !== "KAM"}
                                    placeholder='description'
                                    onChange={(e) => setData({ ...data, description: e.target.value })}
                                />
                            </div>
                            <div className={TaskStyle.taskDetailsWrapper}>

                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Start Date
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <input

                                            type="date"
                                            value={data.startDate}
                                            // min={new Date().toISOString().slice(0, 10)}
                                            onChange={handleStartDateChange}
                                            disabled={auth.roles !== "KAM"}
                                        />
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            End Date
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <input
                                            type="date"
                                            value={data.endDate}
                                            onChange={handleEndDateChange}
                                            disabled={auth.roles !== "KAM"}
                                            min={data.startDate} // Set the minimum date for the end date
                                        />
                                    </div>


                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            E/C date
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <input
                                            disabled
                                            type="date"
                                            value={data.userExpectedCompletionDate}
                                            onChange={handleEcDateChange}
                                        />
                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <DateRangeIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Status
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <OptionSelector
                                            value={data.status}
                                            onChange={handleStatusChange}
                                            name="status"
                                            // disabled={auth.roles !== "KAM"}
                                            // inputStyleProps={}
                                            // optionStyleProps={}
                                            options={[
                                                {
                                                    value: "TODO",
                                                    color: "#000",
                                                    //  backgroundColor: "#ec5252",
                                                    label: "To-Do",
                                                },
                                                {
                                                    value: "INPROGRESS",
                                                    color: "#000",
                                                    //  backgroundColor: "#ec5252",
                                                    label: "In-Progress",
                                                },
                                                {
                                                    value: "DONE",
                                                    color: "#000",
                                                    //  backgroundColor: "#f5ac38",
                                                    label: "Done",
                                                },
                                                {
                                                    value: "COMPLETED",
                                                    color: "#000",
                                                    //  backgroundColor: "#b3f289",
                                                    label: "Completed",
                                                },

                                            ]}

                                        />

                                    </div>



                                </div>

                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <DateRangeIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Priority
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <PrioritySelector
                                            value={data.priority}
                                            onChange={handlePriorityChange}
                                            name="priortiy"
                                            // inputStyleProps={}
                                            // optionStyleProps={}
                                            disabled={auth.roles !== "KAM"}
                                            options={[
                                                {
                                                    value: "HIGH",
                                                    color: "#fff",
                                                    backgroundColor: "#ec5252",
                                                    label: "High",
                                                },
                                                {
                                                    value: "MEDIUM",
                                                    color: "#fff",
                                                    backgroundColor: "#f5ac38",
                                                    label: "Medium",
                                                },
                                                {
                                                    value: "LOW",
                                                    color: "#fff",
                                                    backgroundColor: "#b3f289",
                                                    label: "Low",
                                                },

                                            ]}
                                        />



                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <span>
                                            <GroupsIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Assignee
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>
                                        <p>
                                            {editData?.kamName}
                                        </p>
                                    </div>



                                </div>

                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol} style={{ height: "100%" }} >
                                        <span>
                                            <AttachFileIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Attachment
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol} style={{ height: "auto", padding: "4px 12px" }}>
                                        <div className={TaskStyle.taskFileWrapper}>

                                            {selectedFiles.map((file, index) => (
                                                <div key={index} className={TaskStyle.selectedFileItem}
                                                >
                                                    <p
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => handleFilePreview(file)}
                                                    >

                                                        {truncateFileName(file.name, 15)}

                                                    </p>
                                                    <span onClick={() => handleRemoveSelectedFile(index)} style={{ textDecoration: 'none', border: 'none' }}>
                                                        <CloseIcon

                                                        />
                                                    </span>
                                                </div>
                                            ))}

                                            <button className={TaskStyle.uploadButton}
                                                onClick={() => { handleUploadModalOpen() }}
                                            >Upload Files</button>

                                        </div>
                                    </div>



                                </div>
                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol} >
                                        <span>
                                            <GroupsIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Assigned to
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol}>

                                        {Array.isArray(selectedEmails) && selectedEmails.length > 0 && (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {selectedEmails.map((email, index) => (
                                                    <div key={email} style={{ display: 'flex', alignItems: 'center' }}>
                                                        {index !== 0 && <div style={{ fontSize: '12px', padding: '0px 5px' }}></div>}
                                                        <div
                                                        >
                                                            <ProfileIcon data={{ email: email }} height={"26px"} width={"26px"} showProfileBanner={true} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {
                                            (auth.roles === "KAM" || auth.roles === "ADMIN") &&
                                            <AddIcon onClick={handleEmailModalOpen} sx={{ color: '#6A6A6A' }} />
                                        }
                                    </div>



                                </div>




                                <div className={TaskStyle.taskDetailsRow}>
                                    <div className={TaskStyle.taskDetailsCol}  >
                                        <span>
                                            <GroupsIcon sx={{ color: '#6A6A6A' }} />
                                        </span>
                                        <p>
                                            Product
                                        </p>
                                    </div>
                                    <div className={TaskStyle.taskDetailsCol} >
                                        <p>

                                            {`${editData.assign}`}
                                        </p>

                                    </div>



                                </div>



                                {editData?.template?.map((temp, index) => (
                                    <div className={TaskStyle.taskDetailsRow}>
                                        <div className={TaskStyle.taskDetailsCol}  >
                                            <span>
                                                <AttachFileIcon sx={{ color: '#6A6A6A', transform: 'rotate(30deg)', height: '18px' }} />

                                            </span>
                                            <p>


                                                {temp.category}
                                            </p>
                                        </div>

                                        <div className={TaskStyle.taskDetailsCol} >

                                            <p>{temp.name}</p>


                                        </div>
                                    </div>
                                ))}




                            </div>

                        </div>

                        {auth.roles === "KAM" ?
                            <div style={{ display: "flex", height: "40px" }}>
                                <button style={{
                                    backgroundColor: '#16469D',
                                }}
                                    className={TaskStyle.taskButton}
                                    onClick={handleCreateTask}
                                >Save Changes</button>
                            </div> :
                            <div style={{ display: "flex", height: "40px", gap: "10px" }}>
                                <button style={{
                                    backgroundColor: '#16469D',
                                    minWidth: "100px"
                                }}
                                    className={TaskStyle.taskButton}
                                    onClick={() => navigate(`/${client}/commercial/${data.id}`)}
                                >CTA</button>
                                <button
                                    style={{
                                        backgroundColor: "transparent",
                                        color: "#16469D"
                                    }}
                                    className={TaskStyle.taskButton}
                                    onClick={handleCreateTask}
                                >Save Changes</button>
                            </div>

                        }
                    </div>




                    <EditChatPanel taskId={editData.taskId} data={editData} handleEditTaskModal={handleEditTaskModal} />



                </div >

            </div >

            {/* *************************************Upload Files Modal ******************************/}



            <Modal open={uploadModalOpen}
            //  onClose={handleUploadModalClose}

            >
                <Box sx={styleUploadFiles} className={TaskStyle.uploadFilesModal}
                    style={{
                        borderRadius: '6px',

                    }}>
                    <div style={{
                        display: 'flex', justifyContent: 'space-between'
                    }}>
                        <Typography variant="h5" component="h2"
                        >
                            Upload Files
                        </Typography>
                        <Tooltip>
                            <CancelIcon
                                onClick={handleUploadModalClose}
                            />
                        </Tooltip>
                    </div>
                    <Box className={TaskStyle.dragAndDrop}>
                        <Typography variant="subtitle1">Drag and drop files here</Typography>
                        <Button variant="contained" onClick={() => inputRef.current.click()}
                            sx={{ textTransform: 'capitalize' }}>
                            Upload
                            <input
                                multiple // Allow multiple file selection
                                ref={inputRef}
                                type="file"
                                hidden
                                onChange={handleFileInputChange}
                            />
                        </Button>
                        <div className={TaskStyle.selectedFilesList}
                            style={{
                                display: 'flex', gap: '10px',
                                backgroundColor: '#D9D9D9'
                            }}
                        >
                            <div
                                //  key={index}
                                className={`${TaskStyle.selectedFileItem} ${TaskStyle.hoveredDiv2}`}
                                style={{
                                    border: '1px solid #D9D9D9',
                                    backgroundColor: '#F6FAFF',
                                    borderRadius: '8px', display: 'flex', gap: '10px',

                                }}>
                            </div>

                        </div>

                    </Box>
                    <div className={TaskStyle.FileSelect}
                    >
                        {selectedFiles.map((file, index) => (
                            <div
                                key={index} className={`${TaskStyle.selectedFileItem} ${TaskStyle.hoveredDiv}`}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={() => handleMouseLeave()}
                                style={{
                                    borderRadius: '8px',
                                    display: 'flex',
                                    gap: '10px',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    marginBottom: '20px',
                                    backgroundColor: hoveredFileIndex === index ? '#F6F6F6' : '#ffff',
                                }}>
                                <div style={{ display: 'flex', width: '70%', padding: '12px', alignItems: 'center', gap: '10px' }}>
                                    <DescriptionIcon
                                        style={{
                                            color: '#16469D',

                                        }} />
                                    <p
                                        style={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            lineHeight: '13.8px',
                                            color: '#000000'
                                        }}>{truncateFileName(file.name, 30)}</p>

                                    <Button
                                        variant='text'
                                        target='_blank'
                                        sx={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            lineHeight: '13.8px',
                                            color: '#16469D',
                                            textTransform: 'capitalize',
                                        }}
                                        onClick={() => handleFilePreview(file)}
                                    >
                                        Preview
                                    </Button>

                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        padding: '12px',
                                        alignItems: 'center',
                                    }}>
                                    <p style={{
                                        fontWeight: '400',
                                        fontSize: '10px',
                                        lineHeight: '11.5px',
                                        color: '#000000'
                                    }}>{fileSizes[index]}</p>
                                </div>
                                {hoveredFileIndex === index && (
                                    <CancelIcon
                                        className={TaskStyle.hoveredDiv2}
                                        onClick={() => handleRemoveSelectedFile(index)}
                                        style={{
                                            //   position:'absolute',
                                            //   top:'64%',

                                            // color:'#16469D',
                                            //   left:'90%',
                                            //   padding:'0px',
                                            //     height: '30px',
                                            //     width: '50px',

                                            cursor: 'pointer', // Add cursor pointer style
                                            color: '#16469D', // Change color on hover
                                            fontSize: '18px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>

                    <div className={TaskStyle.saveDiscardButtons}>
                        <Button variant="contained"
                            onClick={() => {
                                handleUploadModalClose();
                            }} >
                            Upload files
                        </Button>
                        {/* <Button variant="outlined" onClick={handleUploadModalClose}>
                                        Discard
                                    </Button> */}
                    </div>
                </Box>
            </Modal>

            <Modal open={emailModalOpen} onClose={handleEmailModalClose}>
                <Box sx={styleSelectEmails}
                    className={TaskStyle.thirdModal}>
                    <Typography variant="h5" >
                        <PersonIcon sx={{ color: '#6A6A6A' }} /> Add member
                    </Typography>
                    <Typography variant="h6"> Board member</Typography>
                    <div className={TaskStyle.emailSelection}>
                        {/* Display the list of users */}
                        {loadingUsers ? (
                            <p>Loading users...</p>
                        ) : (
                            userList.map((email) => {
                                // console.log(email)
                                return (
                                    <label >
                                        {email}
                                        <input
                                            style={{ width: '20px' }}
                                            type="checkbox"
                                            checked={selectedEmails.includes(email)}
                                            onChange={() => handleEmailCheckboxChange(email)}
                                        />
                                    </label>
                                )
                            })
                        )}

                        {notLoggedIn?.map((email, index) => {
                            return (
                                <label>{email}
                                    <input style={{ width: '105px' }} disabled value={"Never Logged In"}></input>
                                </label>
                            )
                        })}
                    </div>

                </Box>
            </Modal>


        </>
    )
}

export default EditTasksCommercial