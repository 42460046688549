import React, { useEffect, useState } from 'react'// Import React and necessary hooks
import VerifyStyle from './EmailVerify.module.css'// Import styles
import SliderTwo from 'components/SliderTwo/SliderTwo'// Import Slider component
import Mail from 'assets/images/mail1.svg'// Import mail image
import { Button, Typography } from '@mui/material'// Import MUI components
import { MuiOtpInput } from 'mui-one-time-password-input'// Import OTP input component
import { useLocation, useNavigate } from 'react-router-dom'// Import React Router hooks
import myaxios from 'api/axios';// Import axios instance
import useBackdrop from "hooks/useBackdrop";// Import custom hook for backdrop
import ErrorToast from 'components/ErrorToast/ErrorToast'// Import ErrorToast component
import {passwordEncryptor} from 'utility/passwordEncryptor.js'// Import passwordEncryptor utility function

function EmailVerify() {
  const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();// Initialize the useBackdrop hook

  const [otpError, setOtpError] = useState(false);// State to manage OTP error
  const [otpValue, setOtpValue] = useState('');// State to manage OTP value
  const [shouldFocusOtp, setShouldFocusOtp] = useState(true);// State to manage focusing OTP input
  const location = useLocation() // Initialize useLocation hook
  const navigate = useNavigate()// Initialize useNavigate hook
  const [resendLoading, setResendLoading] = useState(false);// State to manage resend loading
  const [data, setData] = useState(null);// State to manage data from location
  const [errorMessage, setErrorMessage] = useState('');// State to manage error message

  useEffect(() => {
    // Focus the OTP input once, then set shouldFocusOtp to false
    if (shouldFocusOtp) {
      setShouldFocusOtp(false);
    }
  }, [shouldFocusOtp]);

  useEffect(() => {
     // Redirect to login page if data is null
    if (location.state === null) {
      navigate("/login",{replace:true});
    }
    else {
      setData(location.state.data);
    }
  }, [])

  const [resendTimer, setResendTimer] = useState(60);// State to manage resend timer

  const startResendTimer = () => {
    setResendTimer(60);// Reset the resend timer
  };

  useEffect(() => {
    // Update the resend timer
    if (resendTimer > 0) {
      const timerInterval = setInterval(() => {
        setResendTimer(prevTime => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerInterval);
    }
  }, [resendTimer]);


  const handleVerify =async (event) => {
    event.preventDefault();
    setErrorMessage('')
    openBackdrop()
    if (data.email !== null) {
      const encPass = await passwordEncryptor(data.password,'AesPassword12345');
      console.log(encPass);
      myaxios.post(`/procurement/verifyEmailOtpWhileRegister/${data.phoneVerifyId}/${data.email}`, { verifyEmailOtp: otpValue })
        .then(async(res) => {
          if (res.status === 200) {
            startResendTimer();
            {
              await myaxios.post(`/procurement/register/${data.email}`,{
                name:data.name,
                gstDetails:data.gst,
                password: encPass,
              }).then((res2)=>{

                navigate("/underVerification",{replace:true,state:{data:null}})
              }).catch(err=>{
              
              })
            }
          }
          closeBackdrop()
        }).catch(err => {
          closeBackdrop()
          if (!otpValue) {
            closeBackdrop()
            setOtpError(true)
            setErrorMessage("Please Enter the OTP")
          }
          else if (err?.response?.status === 400) {
            closeBackdrop()
            setErrorMessage("Incorrect OTP!");
          } else {
            closeBackdrop()
            setErrorMessage("Soemthing Went Wrong Please Try Again or Refresh")
          }
        })
    }
  };

  const handleResendOTP = () => {
    openBackdrop()
    setOtpValue("");
    if (data.email !== null) {
      setResendLoading(true);
      myaxios
        .post(`/procurement/resendEmailOtpWhileRegister/${data.phoneVerifyId}/${data.email}`)
        .then((res) => {
          closeBackdrop()
          if (res.status === 200) {
            startResendTimer(); // Start the countdown
          }
        })
        .catch((err) => {
          setErrorMessage("There is problem in resending OTP")
          // Handle error and show appropriate message
        })
        .finally(() => {
          closeBackdrop()
          setResendLoading(false);
        });
    } else {
      closeBackdrop()
      // console.log("Email not found");
    }
  };

//Function to Allow only numbers 0-9, backspace, and delete key  
const handleKeyPress = (e) => {
  const keyCode = e.keyCode || e.which;
  const keyValue = String.fromCharCode(keyCode);
  // Allow number keys (both top row and numeric keypad) and backspace/delete
  const validInput = /^[0-9\b]+$/.test(keyValue) || (
      (keyCode >= 96 && keyCode <= 105) && // Numeric keypad
      (keyCode !== 110 && keyCode !== 190) // Exclude decimal point
  );
  if (!validInput) {
      e.preventDefault();
  }
}

  return (
    <div className={VerifyStyle.emailMain}>
      <div className={VerifyStyle.emailVerify}>
        {errorMessage && <ErrorToast  message={errorMessage} />}
        <h1>Lets go!</h1>
        <img src={Mail} alt='Loading' />
        <form action='' onSubmit={handleVerify} className={VerifyStyle.emailForm}>
          <h2>We’ve sent a code to {data?.email}</h2>
          <div className={VerifyStyle.muiInput}>
            <MuiOtpInput
              name="otp" // Add the name attribute
              value={otpValue}
              className={VerifyStyle.EmailVerifyOTPInput}
              length={5}
              onChange={(value) => setOtpValue(value)}
              autoFocus={shouldFocusOtp} // Use autoFocus attribute
              style={{ border: 'none', color: '#585858' }}
              onKeyDown={handleKeyPress}
            />

            <Button variant='contained' type='submit'

            >Verify</Button>


            <Typography variant='h3' className={VerifyStyle.resendOtp}>
              Didn’t receive the OTP?
              <Button variant='text'
                onClick={handleResendOTP}
                disabled={resendTimer > 0}
                style={{
                  color:'#16469D',
                  fontWeight:'500',
                }}
                >
                {resendTimer > 0 ? `Resend in 00:${resendTimer < 10 ? '0' + resendTimer : resendTimer}` : 'Resend'}

              </Button></Typography>
          </div>


        </form>
      </div>
      <div className={VerifyStyle.sliderT}>
        <SliderTwo />
      </div>
      <BackdropComponent />

    </div>
  )
}

export default EmailVerify