import React, { useEffect, useState, useRef } from 'react'
import ProcaNavbar from '../HomePage/ProcaNavbar/ProcaNavbar'
import Footer from '../HomePage/Footer/Footer'
import { Outlet } from 'react-router-dom'

const HomeLayout = () => {

  

  return (
    <>
      <ProcaNavbar />
      <Outlet/>
      <Footer/>
    </>
  )
}

export default HomeLayout
