import React from 'react'
import TaskStyle from '../SuperAdminTaskPannel/SuperAdminTaskPannel.module.css'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import GroupsIcon from '@mui/icons-material/Groups';
import { useState, useEffect } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { PrivateComponent } from 'api/axios';
import { useRef } from 'react';
import useAuth from 'hooks/useAuth';
import CategorySelector from './CategorySelector';
import useBackdrop from "hooks/useBackdrop";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DescriptionIcon from '@mui/icons-material/Description';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tooltip } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SuperAdminChatPannel from '../SuperAdminChatPannel/SuperAdminChatPannel';
import axios from 'api/axios';
import useToast from 'hooks/useToast';
import commercialStyle from '../../../../../CommercialViewKAM/KamCreateTask/commercialStyles.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AttachmentOutlinedIcon from '@mui/icons-material/AttachmentOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import RedirectRegistrationStyle from '../../../../../RedirectRegistration/RedirectRegistrationStyle.module.css'
import formStyle from '../../../../../../components/FormsCommonCss/FormsCommonCss.module.css'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CommercialTemplateSelector from './CommercialTemplateSelector';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
};

const styleUploadFiles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    p: 2,

};

const styleSelectEmails = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    p: 2,
};


function SuperAdminTaskPannel({ handlecreateTaskModal, getAllTasks, franchiseData }) {
    // Importing and initializing hooks
    const { showToast, Toast } = useToast()
    const { auth } = useAuth();
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop();
    const currentDate = new Date().toISOString().slice(0, 10);
    const privateAxios = PrivateComponent();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const inputRef = useRef(null); // Reference for opening file input
    const dateRef = useRef(null);
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);
    const [files, setFiles] = useState([]);
    const [deliveryTerms, setDeliveryTerms] = useState([]);
    const [productSelected, setProductSelected] = useState([]);
    const { orgId } = useParams();


    const [data, setData] = useState({ // State for task data
        title: "",
        description: "",
        startDate: currentDate, // Set the default value to today's date
        endDate: "szczsc",
        status: "TODO",
        priority: "",
    })


    // State variables for managing modals, email selection, and file names
    const [formError, setFormError] = useState({})
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [selectedFileNames, setSelectedFileNames] = useState([]); // State to store selected file names

    // Create a state variable to store file sizes
    const [fileSizes, setFileSizes] = useState([]);

    const handleUploadModalClose = () => {
        setUploadModalOpen(false);
    };

    const [chats, setChats] = useState([]);

    // ------------------------------------------------------------------------------------

    // State variables for shipping modal
    const [shippingModalOpen, setShippingModalOpen] = useState(false);
    const [currentTerm, setCurrentTerm] = useState(''); // State variable to store the current term
    const handleShippingModalOpen = (term) => {
        setSelectedCategories([]);
        setDropdownOpen(false)
        setCurrentTerm(term);
        setShippingModalOpen(true);
    };
    const handleShippingModalClose = () => {
        setShippingModalOpen(false);
    };


    // ======================fetch category========================

    const [availableCategory, setAvailableCategory] = useState([]);
    const fetchCategory = async () => {
        openBackdrop();
        try {
            await axios.get(`${process.env.REACT_APP_ECOM}/api/subgroups`).then(res => {
                setAvailableCategory(res.data);
            }).catch((err) => {
                alert("error getting categories");
            })
        } catch (error) {
        }
        closeBackdrop();
    }

    useEffect(() => {
        fetchCategory();
    }, [])// Fetch categories on component mount

    // Function to handle removing selected file
    const handleRemoveSelectedFile = (index) => {
        const updatedSelectedFiles = [...selectedFiles];
        const updatedSelectedFileNames = [...selectedFileNames];
        updatedSelectedFiles.splice(index, 1);
        updatedSelectedFileNames.splice(index, 1);
        setSelectedFiles(updatedSelectedFiles);
        setSelectedFileNames(updatedSelectedFileNames);
    };
    const allowedExtensions = ['.jpg', '.jpeg', '.pdf', '.xls', '.xlsx', '.docx'];

    //files
    const handleUploadModalOpen = () => {
        setSelectedFiles([]); // Clear the selected files
        setUploadModalOpen(true);
    };
    // Function to handle file selection


    // Function to handle file input change
    const handleFileInputChange = (e) => {
        const tfiles = e.target.files || e.dataTransfer.files;
        const fileNames = Array.from(tfiles).map((file) => file.name);
        const upFiles = files?.filter((ty) => !fileNames.includes(ty.name));
        const templist = [...upFiles, ...tfiles]
        setFiles(templist);
        setSelectedFiles(templist);
        // Get the file sizes and update the state
        const sizes = Array.from(templist).map((file) => formatFileSize(file.size));
        setFileSizes(sizes);
    };

    // Allowed file extensions
    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        return parts.length > 1 ? `.${parts.pop()}` : '';
    };


    // Function to truncate file name if it exceeds maxLength
    function truncateFileName(fileName, maxLength) {
        if (typeof fileName !== 'string' || fileName.length === 0) {
            return ''; // Return an empty string or handle this case as needed
        }

        if (fileName.length <= maxLength) {
            return fileName;
        }

        const truncatedFileName = fileName.substring(0, maxLength - 5) + '...';
        return truncatedFileName;
    }
    // Function to format file size for display
    const formatFileSize = (size) => {
        if (size < 1024) {
            return size + ' B';
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(2) + ' KB';
        } else if (size < 1024 * 1024 * 1024) {
            return (size / (1024 * 1024)).toFixed(2) + ' MB';
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        }
    };


    // Function to build category tree
    const buildTree = (categories) => {
        let tree = [];
        let lookup = {};
        categories.forEach(category => {
            category.branch = [];
            lookup[category.categoryId] = category;
        });

        categories.forEach(category => {
            if (category.parent !== null) {
                const parent = lookup[category.parent];
                parent.branch.push(category);
            } else {
                tree.push(category);
            }
        });

        return tree;
    }
    // ====================add products=========================


    // Function to add products
    const handleProductAdd = async () => {
        openBackdrop();
        try {
            const products = [{
                productName: productSelected.name,
                productId: productSelected.productId,
                organisationId: orgId,
                categoryId: selectedSubcategories[selectedSubcategories.length - 1]?.Id
            }];

            const tempCat = selectedSubcategories.map((cat) => {
                return {
                    branch: cat.Branch,
                    level: cat.Level,
                    categoryId: cat.Id,
                    parent: cat.ParentID,
                    organisationId: orgId,
                    categoryName: cat.Name
                }
            })
            const categories = buildTree(tempCat);
            const categoryRes = await privateAxios.post(`/procureadmin/addApprovedCategories/${orgId}`, categories)
            const productRes = await privateAxios.post(`/procureadmin/addApprovedProducts/${orgId}`, products);

            if (categoryRes.status === 200 && productRes.status === 200) {
                return true;
            }
            else {
                return false
            }
        } catch (error) {
            return false;
        }
        closeBackdrop();
    }


    // Function to validate input fields
    const handleInputValidation = () => {
        const isEmpty = Object.values(data).some(dd => dd === '');

        if (!isEmpty && productSelected !== "") {
            setFormError({});
            return true;
        } else {
            setFormError({});
            if (data.title === '') {
                alert('Please Enter title');
                setFormError(prev => ({
                    ...prev,
                    title: "Please Enter Title"
                }));
            }
            else if (data.description === '') {
                alert('Please Enter Description');
                setFormError(prev => ({
                    ...prev,
                    description: "Please Add Description"
                }));
            }
            else if (data.startDate === '') {
                alert('Please select start date');
                setFormError(prev => ({
                    ...prev,
                    startDate: "Please select start date"
                }));
            }
            else if (data.endDate === '') {
                alert('Please select end date');
                setFormError(prev => ({
                    ...prev,
                    endDate: "Please select end date"
                }));
            } else if (data.priority === '') {
                alert('Please select priority');
                setFormError(prev => ({
                    ...prev,
                    priority: "Please select priority"
                }));

            } else if (productSelected === "") {
                // console.log('Alert called');
                alert('Please select A Product.');
                setFormError(prev => ({
                    ...prev,
                    productSelected: "Please select A Product"
                }));
            }
            return false;
        }
    };

    // State variables for task selector
    const [taskselector, setTaskSelector] = useState('Commercial');
    const [multiClick, setMulticlick] = useState(false);

    // Function to handle multi-click
    const handelMultiClick = () => {
        setMulticlick(prev => !prev)
    }

    // Function to handle task selector change
    const handleTasksSelector = (event) => {
        setTaskSelector(event.target.value);
    };


    // Function to handle creation of tasks
    const handleCreateTask = async () => {
        if (handleInputValidation()) {
            openBackdrop()
            try {
                const catres = await handleProductAdd();
                if (catres) {
                    // Prepare payload for task creation
                    const selectedProduct = {
                        productId: productSelected.productId,
                        productName: productSelected.productName,
                        category: productSelected.categoryId,
                    };


                    // const assignBreadCrum = selectedSubcategories ? `${selectedSubcategories.map((sub) => sub.Name).join(">")|| ""} ${productSelected !== '' ? `> ${productSelected?.name || ""}` : "";
                    const assignBreadCrum = selectedSubcategories
                        ? `${selectedSubcategories.map((sub) => sub.Name).join(">") || ""} ${productSelected !== '' ? `> ${productSelected?.name || ""}` : ""
                        }`
                        : "";
                    const payload = {
                        title: data.title,
                        description: data.description,
                        startDate: data.startDate,
                        endDate: data.endDate,
                        userExpectedCompletionDate: "",
                        status: data.status,
                        priority: data.priority,
                        assign: assignBreadCrum,
                        product: { productId: productSelected.productId },
                        template: selectedTemplates.map((temp) => {
                            return {
                                id: temp.id
                            }
                        })
                    };


                    if (taskselector === 'Technical') {
                        // Handle Technical task creation
                        const response = await privateAxios.post(`/task/addTasks/${auth.userData.id}/${auth.orgId}`, payload)
                            .then(async (response) => {
                                const taskId = response.data.taskId;

                                if (taskId !== null) {
                                    // Upload task attachments
                                    if (files.length > 0) {
                                        const fd = new FormData();
                                        for (let i = 0; i < files.length; i++) {
                                            fd.append(`files`, files[i]);
                                        }

                                        await privateAxios.post(`/task/uploadTaskAttachments/${taskId}`, fd, {
                                            headers: {
                                                'Content-Type': 'multipart/form-data',
                                            }
                                        }).catch((err) => {
                                            alert("error uploading Files")
                                            console.error(err);
                                        });
                                    }

                                    await privateAxios.post(`/task/addUsersToTask/${taskId}`, { emails: [franchiseData.kamEmail] }).catch(err => {
                                        alert("Error Adding User to task")
                                    })
                                    // Add comments to task
                                    if (chats.length > 0) {
                                        chats.forEach(async (chat) => {
                                            await privateAxios.post(`/task/addComments/${taskId}`,
                                                { comment: chat.comment, name: chat.name })
                                        })
                                    }
                                    // Handle Commercial task creation
                                    setUploadModalOpen(false);
                                    setFiles("");
                                    setProductSelected("");
                                    getAllTasks();
                                }
                                else {
                                    alert("error getting task Id");
                                }
                            }).catch(err => {
                            })

                    } else if (taskselector === 'Commercial') {
                        const response = await privateAxios.post(`/commercialTask/createCommercialTasks/${auth.userData.id}/${auth.orgId}`, payload)
                            .then(async (response) => {
                                const taskId = response.data.taskId;
                                if (taskId !== null) {
                                    if (files.length > 0) {
                                        const fd = new FormData();
                                        for (let i = 0; i < files.length; i++) {
                                            fd.append(`files`, files[i]);
                                        }
                                        await privateAxios.post(`/task/uploadTaskAttachments/${taskId}`, fd, {
                                            headers: {
                                                'Content-Type': 'multipart/form-data',
                                            }
                                        }).catch((err) => {
                                            alert("error uploading Files")
                                            console.error(err);
                                        });
                                    }
                                    // Add users to task
                                    await privateAxios.post(`/task/addUsersToTask/${taskId}`, { emails: [franchiseData.kamEmail] }).catch(err => {
                                        alert("Error Adding User to task")
                                    })
                                    // Add comments to task
                                    if (chats.length > 0) {
                                        chats.forEach(async (chat) => {
                                            await privateAxios.post(`/task/addComments/${taskId}`,
                                                { comment: chat.comment, name: chat.name })
                                        })
                                    }
                                    setUploadModalOpen(false);
                                    setFiles("");
                                    setProductSelected("");
                                    getAllTasks();
                                }
                                else {
                                    alert("error getting task Id");
                                }
                            }).catch(err => {
                            })
                    }
                }
                else {
                    alert("error adding product")
                    showToast("error", "Error Adding Product")
                }
                handlecreateTaskModal()
                closeBackdrop();

            } catch (error) {
                closeBackdrop()
            }
        }
        else {
            console.log(handleInputValidation())
        }
    };

    // Function to handle priority change
    const handlePriorityChange = (event) => {
        setData({ ...data, priority: event.target.value });
    };

    // Function to handle status change
    const handleStatusChange = (event) => {
        setData({ ...data, status: event.target.value });
    };

    // Function to handle start date change
    const handleStartDateChange = (event) => {
        const selectedStartDate = event.target.value;
        const startDate = new Date(selectedStartDate);

        // Check if the selected date is in the past
        const today = new Date();
        if (startDate < today) {
            // If the selected date is in the past, set it to today
            event.target.value = today.toISOString().slice(0, 10);
            setData({
                ...data,
                startDate: today.toISOString().slice(0, 10),
                endDate: today.toISOString().slice(0, 10),
            });
        } else {
            // If the selected date is not in the past, update the state
            setData({
                ...data,
                startDate: selectedStartDate,
                endDate: selectedStartDate,
            });
        }
    };


    const handleEndDateChange = (event) => {
        setData({ ...data, endDate: event.target.value });

        // Check if the end date is earlier than the start dat
    };

    const handleEcDateChange = (event) => {
        const selectedEcDate = event.target.value;
        setData({ ...data, userExpectedCompletionDate: selectedEcDate });

        // Set the minimum date for the "End date" to the selected "E/C date"
        dateRef.current.min = selectedEcDate;
    };


    // ----------------------------Date----------------------------

    // Function to format date for display
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return date.toLocaleDateString(undefined, options);
    };

    // ----------------------------Date----------------------------

    // State variables for modal
    const [openModal, setOpenModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    // Function to open modal
    const handleOpenModal = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenModal(true);
    };

    // Function to close modal
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    // Function to save selected date
    const saveSelectedDate = () => {
        handleCloseModal();
    };


    // ///////////////////////////////////////////////////////////////////////////////////////////////////

    const [hoveredFileIndex, setHoveredFileIndex] = useState(null);
    // Event handler for onMouseEnter
    const handleMouseEnter = (index) => {
        setHoveredFileIndex(index);
    };

    // Event handler for onMouseLeave
    const handleMouseLeave = () => {
        setHoveredFileIndex(null);
    };

    // Function to handle file preview
    const handleFilePreview = (file) => {

        const fileURL = URL.createObjectURL(file);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = file.name;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(fileURL);
        document.body.removeChild(a);
    }


    // ======================fetch Templates==========================



    const [templates, setTemplates] = useState([]);

    // Function to fetch templates
    const getTemplate = async () => {
        openBackdrop();
        try {
            const res = await privateAxios.get(`/procureadmin/getAllSpecificTemplates`);
            setTemplates(res.data);
        } catch (error) {
            showToast("error", "error getting templates")
        }
        closeBackdrop();
    }
    useEffect(() => {
        getTemplate();
    }, [])



    const [selectedTemplates, setSelectedTemplates] = useState([])

    // Function to handle selection of templates
    const handleTemplateSelect = (temp, option) => {
        const alreadyPresent = selectedTemplates.some((opt) => opt.tempId === temp.id);
        if (!alreadyPresent) {
            setSelectedTemplates((prev) => {
                let template = {
                    ...option,
                    tempId: temp.id,
                    tempName: temp.name,

                };
                return [...prev, template];
            });
        } else {
            setSelectedTemplates((prev) => {
                let template = {
                    ...option,
                    tempId: temp.id,
                    tempName: temp.name,

                };
                let temps = prev.filter((las) => las.tempId !== temp.id); // Modified line
                return [...temps, template];
            });
        }


    };


    // ======================template states and dropdowns=============


    const termArray = ["Shipping Term", "Payment Term", "Billing Schedule"];
    const rawData = ['FOB', 'CIFd', 'Ex-Works', 'CIF', 'FOBED', 'CID', 'ExRef', 'CAD'];

    const termArrayTerms = {
        "shippingterm": ["Term 1", "Term 2", "Term 3"],
        "paymentterm": ["Pay 1", "Pay 2", "Pay 3"],
        "billingschedule": ["Bill 1", "Bill 2", "Bill 3"]
    }
    const [selectedTerm, setSelectedTerm] = useState('');

    // Function to handle status change of terms
    const handleTermStatusChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedTerm(selectedValue);
    };

    // Function to handle selection of term from dropdown
    const selectedTermFromDropDown = (term, item) => {
        console.log(term + " " + item);
        const termExists = deliveryTerms.some((obj) => obj.hasOwnProperty(term));
        if (termExists) {
            const updatedDeliveryTerms = deliveryTerms.map((obj) =>
                obj.hasOwnProperty(term) ? { [term]: item } : obj
            );
            setDeliveryTerms(updatedDeliveryTerms);
        } else {
            const selectedObject = { [term]: item };
            setDeliveryTerms((prevValues) => [...prevValues, selectedObject]);
        }
    };
    useEffect(() => {
        console.log(deliveryTerms);
    }, [deliveryTerms]);



    // Modal related states and functions
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addBtnToggle, setAddBtnToggle] = useState(true)
    const [selectedCategories, setSelectedCategories] = useState([]);
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const handleRemoveCategory = (event, category) => {
        event.stopPropagation();
        const updatedCategories = selectedCategories.filter((cat) => cat !== category);
        setSelectedCategories(updatedCategories);

    };
    const handleCategoryToggle = (category) => {
        const categoryIndex = selectedCategories.findIndex((cat) => cat === category);
        if (categoryIndex !== -1) {
            // Category already selected, remove it
            const updatedCategories = [...selectedCategories];
            updatedCategories.splice(categoryIndex, 1);
            setSelectedCategories(updatedCategories);
        } else {
            // Category not selected, add it
            setSelectedCategories([...selectedCategories, category]);
        }

        console.log(selectedCategories)
    };
    useEffect(() => {
        console.log(selectedCategories)
    }, [selectedCategories])

    const [requestModal, setRequestModal] = useState(false)
    const [remark, setRemark] = useState('')
    // Function to handle remark change
    const handleRemarkChange = (event) => {
        setRemark(event.target.value);
    };

    // Function to handle terms request
    const handleTermsRequest = () => {
        if (selectedCategories.length == 0) {
            alert("Select atleast one term!")
        } else {
            console.log("selectedCategories : " + selectedCategories)
            console.log("Remark : " + remark)
            setShippingModalOpen(false)
            setRequestModal(true)
        }

    }

    return (
        <div className={TaskStyle.mainTask}>
            <Box sx={style} className={TaskStyle.divider}>

                <div style={{ display: "flex", flexGrow: "1", gap: "20px", height: "100%", flexDirection: "column", padding: "13px 0px 20px  20px", justifyContent: "space-between" }}>
                    <div className={TaskStyle.modalClass}>

                        <div className={TaskStyle.taskNav}>
                            <h1 className={TaskStyle.createTask}>Create a task</h1>
                        </div>

                        <div className={TaskStyle.taskHeading}>
                            <input
                                placeholder='Title'
                                value={data.title}
                                onChange={(e) => setData({ ...data, title: e.target.value })}
                            />

                            <textarea
                                placeholder='description'
                                value={data.description}
                                onChange={(e) => setData({ ...data, description: e.target.value })}
                            />
                        </div>

                        <div className={TaskStyle.container}>

                            <ul className={TaskStyle.list}>
                                <li> <CalendarMonthIcon sx={{ color: '#6A6A6A' }} /> Start Date</li>
                                <li>
                                    <input
                                        className={TaskStyle.dateInput}
                                        type="date"
                                        value={data.startDate}
                                        min={new Date().toISOString().slice(0, 10)}
                                        onChange={handleStartDateChange}
                                    />
                                </li>

                                <li> <CalendarMonthIcon sx={{ color: '#6A6A6A' }} /> End Date </li>

                                <li>
                                    <input
                                        type="date"
                                        value={data.endDate}
                                        onChange={handleEndDateChange}
                                        ref={dateRef}
                                        min={data.startDate} // Set the minimum date for the end date
                                    />
                                </li>
                            </ul>

                            <ul className={TaskStyle.list}>
                                <li>
                                    <CalendarMonthIcon sx={{ color: '#6A6A6A' }} />
                                    E/C date</li>
                                <li>
                                    <input
                                        disabled
                                        type="date"
                                        value={data.userExpectedCompletionDate}
                                        onChange={handleEcDateChange}
                                    />
                                </li>
                            </ul>

                            <ul className={TaskStyle.list}>
                                <li> <ContentPasteOutlinedIcon sx={{ color: '#6A6A6A' }} /> Status</li>
                                <li style={{ flexGrow: '1' }}>
                                    <Select

                                        value={data.status}
                                        onChange={handleStatusChange}
                                        displayEmpty
                                        className={TaskStyle.selectCustomStyle}
                                        sx={{
                                            boxShadow: "none",
                                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                        }}>

                                        <MenuItem value="TODO"> To Do </MenuItem>
                                        <MenuItem value="INPROGRESS">In Progress</MenuItem>
                                        <MenuItem value="DONE" >Done</MenuItem>
                                        <MenuItem value="COMPLETED">Complete</MenuItem>

                                    </Select>
                                </li>
                            </ul>

                            <ul className={TaskStyle.list}>
                                <li>
                                    <LocalOfferOutlinedIcon sx={{ color: '#6A6A6A' }} />
                                    {/* <img src={Priority} alt="Priority icon loading" /> */}
                                    Priority</li>
                                <li style={{ flexGrow: '1' }}>
                                    <Select

                                        value={data.priority}
                                        onChange={handlePriorityChange}
                                        displayEmpty
                                        className={TaskStyle.selectCustomStyle}
                                        sx={{
                                            boxShadow: "none",
                                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },

                                            backgroundColor:
                                                data.priority === "HIGH"
                                                    ? "#ec5252"
                                                    : data.priority === "MEDIUM"
                                                        ? "#f5ac38"
                                                        : data.priority === "LOW"
                                                            ? "#b3f289"
                                                            : undefined,
                                            color:
                                                data.priority === "HIGH"
                                                    ? "#ffff"
                                                    : data.priority === "MEDIUM"
                                                        ? "#ffff"
                                                        : data.priority === "LOW"
                                                            ? "#ffff"
                                                            : undefined,
                                        }}
                                    >
                                        <MenuItem value="" disabled sx={{ display: 'none' }}>
                                            Select Priority
                                        </MenuItem>
                                        <MenuItem value="HIGH" style={{ backgroundColor: "#ec5252" }}>High</MenuItem>
                                        <MenuItem value="MEDIUM" style={{ backgroundColor: "#f5ac38" }}>Medium</MenuItem>
                                        <MenuItem value="LOW" style={{ backgroundColor: "#b3f289" }}>Low</MenuItem>
                                    </Select>
                                </li>
                            </ul>

                            <ul className={TaskStyle.list}>
                                <li>
                                    {/* <img src={Attachments} alt="Attachments icon Loading" */}
                                    <AttachmentOutlinedIcon sx={{ color: '#6A6A6A' }} />
                                    Attachment</li>

                                <li style={{ flexGrow: '1' }}
                                    className={TaskStyle.AttachmentColumnRr}>

                                    <Button variant="contained"
                                        onClick={() => { handleUploadModalOpen() }}
                                        style={{
                                            color: "grey",
                                            minHeight: '37px',
                                            minWidth: '115px',
                                            maxWidth: '115px',
                                            maxHeight: '37px'

                                        }}>Upload Files</Button>
                                    {selectedFiles.length > 0 && (
                                        <div className={TaskStyle.selectedFilesList}
                                            style={{
                                                display: 'flex',
                                                gap: '10px',
                                                maxHeight: '200px',
                                            }}>
                                            {selectedFiles?.map((file, index) => (
                                                <div key={index} className={TaskStyle.selectedFileItem}
                                                    style={{
                                                        border: '1px solid #D9D9D9',
                                                        backgroundColor: '#F6FAFF',
                                                        padding: '0px 0px',
                                                        borderRadius: '8px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        gap: '10px',
                                                        alignItems: 'center',
                                                        minHeight: '35px',
                                                        minWidth: '165px',
                                                    }}>
                                                    <p style={{ cursor: 'pointer' }}
                                                        onClick={() => handleFilePreview(file)}
                                                    >
                                                        {truncateFileName(file.name, 15)} {getFileExtension(file.name)}
                                                    </p>

                                                    <button
                                                        onClick={() => handleRemoveSelectedFile(index)}
                                                        style={{ textDecoration: 'none', border: 'none' }}>
                                                        <HighlightOffIcon
                                                            style={{
                                                                height: 'none',
                                                                width: '20px',
                                                                backgroundColor: '#F6FAFF',
                                                            }}
                                                        />
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </li>
                            </ul>


                            <ul className={TaskStyle.list}>
                                <li> <GroupsIcon sx={{ color: '#6A6A6A' }} /> Assigned to</li>
                                <li style={{ flexGrow: '1', display: 'flex', alignItems: 'center' }} className={TaskStyle.logo}>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {franchiseData?.kamEmail}
                                    </div>

                                </li>
                            </ul>

                            <ul className={TaskStyle.list}>
                                <li> <GroupsOutlinedIcon sx={{ color: '#6A6A6A' }} /> Assign </li>

                                <li style={{ flexGrow: '1' }}>
                                    <div className={TaskStyle.breadcrumbs}>
                                        {`${selectedSubcategories?.map((cat) => cat.Name).join(" > ") || ""}`} {productSelected !== '' ? `> ${productSelected?.name || ""}` : ""}
                                    </div>
                                </li>
                            </ul>

                            <ul className={TaskStyle.list}>
                                <li> <TaskOutlinedIcon sx={{ color: '#6A6A6A' }} />Tasks</li>
                                <li style={{ flexGrow: '1', fontSize: '13px' }}>
                                    <Select
                                        value={taskselector}
                                        onChange={handleTasksSelector}
                                        className={TaskStyle.selectCustomStyle}
                                        sx={{
                                            boxShadow: "none",
                                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                                border: 0,
                                            },
                                        }}>

                                        <MenuItem value="Technical">Technical-Specification</MenuItem>
                                        <MenuItem value="Commercial" >Commercial</MenuItem>

                                    </Select>
                                </li>
                            </ul>

                            <CategorySelector
                                category={availableCategory}
                                selectedSubcategories={selectedSubcategories}
                                setSelectedSubcategories={setSelectedSubcategories}
                                productSelected={productSelected}
                                setProductSelected={setProductSelected}
                            />



                            {
                                taskselector === 'Commercial' &&
                                <>
                                    {templates?.map((temp, index) => {
                                        return (
                                            <CommercialTemplateSelector
                                                showToast={showToast}
                                                data={temp}
                                                key={index}
                                                selectedTemplates={selectedTemplates}
                                                setSelectedTemplates={setSelectedTemplates}
                                                openBackdrop={openBackdrop}
                                                closeBackdrop={closeBackdrop}
                                                handleTemplateSelect={handleTemplateSelect}
                                            />
                                        )
                                    })}
                                </>
                            }

                        </div>

                    </div>


                    <div style={{ display: "flex", height: "40px" }}>
                        <Button variant="contained"
                            style={{ textTransform: 'capitalize' }}
                            onClick={handleCreateTask}
                        >Create Task</Button>
                    </div>

                </div>
                {/* *************************************Upload Files Modal ******************************/}

                <Modal open={shippingModalOpen} onClose={handleShippingModalClose}>
                    <Box sx={styleSelectEmails} className={commercialStyle.commercialModalss}>
                        <div className={commercialStyle.modalHeadingNames}>
                            <h3 className={commercialStyle.modalHeadingCo}>
                                Add template for {currentTerm}
                            </h3>
                            <CloseIcon sx={{ color: '#6A6A6A' }} onClick={handleShippingModalClose} />
                        </div>

                        <div className={formStyle.inputRow}>
                            <label className={commercialStyle.formShippingItem} style={{ fontSize: '14px', fontWeight: '450', marginBottom: '7px' }}>Delivery Terms</label>
                            <div className={RedirectRegistrationStyle.dropdownContainer}>
                                <div
                                    className={`${RedirectRegistrationStyle.dropdown} ${dropdownOpen && RedirectRegistrationStyle.open}`}
                                    onClick={() => {

                                        if (addBtnToggle) {
                                            toggleDropdown();
                                        }
                                    }}
                                >

                                    <div className={RedirectRegistrationStyle.selectedCategories}>
                                        {selectedCategories.length > 0 ?
                                            selectedCategories.map((category, index) => (
                                                <div key={index} className={RedirectRegistrationStyle.selectedCategory}>
                                                    {category}
                                                    <CloseIcon
                                                        className={RedirectRegistrationStyle.closeIcon}
                                                        onClick={(event) => handleRemoveCategory(event, category)}
                                                    />
                                                </div>
                                            )) :
                                            ''}
                                    </div>
                                    <KeyboardArrowDownIcon
                                        className={RedirectRegistrationStyle.arrowIcon}
                                        onClick={toggleDropdown}
                                    />
                                </div>
                                {dropdownOpen && (
                                    <div className={RedirectRegistrationStyle.dropdownContent}>
                                        {rawData.map((category, index) => (
                                            <div
                                                key={index}
                                                className={RedirectRegistrationStyle.dropdownItem}
                                                onClick={() => handleCategoryToggle(category)}
                                            >
                                                <span>{category}</span>
                                                <input
                                                    type="checkbox"
                                                    name='categorySelect'
                                                    checked={selectedCategories.some((cat) => cat === category)}
                                                    readOnly
                                                    required
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                        </div>

                        <label className={commercialStyle.formShippingItem}>
                            Remarks
                            <textarea onChange={handleRemarkChange} style={{ color: 'black', border: '1px solid #ccc' }} type="text" />
                        </label>

                        <div className={commercialStyle.formShippingItem}>
                            <button onClick={handleTermsRequest}>Send Request</button>
                        </div>

                    </Box>
                </Modal>


                <Modal open={uploadModalOpen}>

                    <Box sx={styleUploadFiles} className={TaskStyle.uploadFilesModal} style={{ borderRadius: '6px' }}>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h5" component="h2">
                                Upload Files
                            </Typography>

                            <Tooltip>
                                <CancelIcon onClick={handleUploadModalClose} />
                            </Tooltip>
                        </div>

                        <Box className={TaskStyle.dragAndDrop}>
                            <Typography variant="subtitle1">Drag and drop files here</Typography>
                            <Button variant="contained" onClick={() => inputRef.current.click()}
                                sx={{ textTransform: 'capitalize' }}>
                                Upload
                                <input
                                    multiple // Allow multiple file selection
                                    ref={inputRef}
                                    type="file"
                                    hidden
                                    accept={allowedExtensions.join(',')}
                                    onChange={handleFileInputChange}
                                />
                            </Button>

                            <div className={TaskStyle.supportedFileTypes}>
                                <Typography variant="body2" color="textSecondary" >
                                    {allowedExtensions.join(', ')}
                                </Typography>
                            </div>
                            <div className={TaskStyle.selectedFilesList}
                                style={{
                                    display: 'flex', gap: '10px',
                                    backgroundColor: '#D9D9D9'
                                }}
                            >
                                <div className={`${TaskStyle.selectedFileItem} ${TaskStyle.hoveredDiv2}`}
                                    style={{
                                        border: '1px solid #D9D9D9',
                                        backgroundColor: '#F6FAFF',
                                        borderRadius: '8px', display: 'flex', gap: '10px',
                                    }}>
                                </div>
                            </div>
                        </Box>

                        <div className={TaskStyle.FileSelect}>

                            {selectedFiles.map((file, index) => (
                                <div
                                    key={index} className={`${TaskStyle.selectedFileItem} ${TaskStyle.hoveredDiv}`}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={() => handleMouseLeave()}
                                    style={{
                                        borderRadius: '8px',
                                        display: 'flex',
                                        gap: '10px',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        marginBottom: '20px',
                                        backgroundColor: hoveredFileIndex === index ? '#F6F6F6' : '#ffff',
                                    }}>
                                    <div style={{ display: 'flex', width: '70%', padding: '12px', alignItems: 'center', gap: '10px' }}>
                                        <DescriptionIcon
                                            style={{ color: '#16469D' }} />
                                        <p style={{
                                            fontWeight: '500',
                                            fontSize: '12px',
                                            lineHeight: '13.8px',
                                            color: '#000000'
                                        }}>{truncateFileName(file.name, 30)}
                                        </p>

                                        <Button
                                            variant='text'
                                            target='_blank'
                                            sx={{
                                                fontWeight: '500',
                                                fontSize: '12px',
                                                lineHeight: '13.8px',
                                                color: '#16469D',
                                                textTransform: 'capitalize',
                                            }}
                                            onClick={() => handleFilePreview(file)}
                                        >
                                            Preview
                                        </Button>

                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            padding: '12px',
                                            alignItems: 'center',
                                        }}>
                                        <p style={{
                                            fontWeight: '400',
                                            fontSize: '10px',
                                            lineHeight: '11.5px',
                                            color: '#000000'
                                        }}>{fileSizes[index]}</p>
                                    </div>
                                    {hoveredFileIndex === index && (
                                        <CancelIcon
                                            className={TaskStyle.hoveredDiv2}
                                            onClick={() => handleRemoveSelectedFile(index)}
                                            style={{
                                                cursor: 'pointer', // Add cursor pointer style
                                                color: '#16469D', // Change color on hover
                                                fontSize: '18px',
                                                marginLeft: '10px',
                                            }}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className={TaskStyle.saveDiscardButtons}>
                            <Button variant="contained"
                                onClick={() => {
                                    handleUploadModalClose();
                                }}>
                                Upload files
                            </Button>
                        </div>
                    </Box>
                </Modal>


                <Modal open={requestModal} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className={commercialStyle.requestModalClass}>
                        <div className={commercialStyle.requestModalIcon}><CheckCircleOutlineIcon /> </div>
                        <div className={commercialStyle.requestModalSpan}>Request sent successfully <span onClick={() => { alert("navigate to requests page"); setRequestModal(false) }}>View Request</span></div>
                    </div>
                </Modal>

                <div className={TaskStyle.chattComp}>
                    <SuperAdminChatPannel chats={chats} setChats={setChats} handlecreateTaskModal={handlecreateTaskModal} selectedFiles={selectedFiles} />
                </div>

            </Box>

            <BackdropComponent />
        </div >
    )
}

export default SuperAdminTaskPannel