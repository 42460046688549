import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import profileIconStyle from './ProfileIcon.module.css';
import { PrivateComponent } from 'api/axios';
import useAuth from 'hooks/useAuth';

const ProfileIcon = ({ data, width, height, showProfileBanner }) => {
   // Get authentication context
  const { auth } = useAuth();

   // State for hover effect
  const [isHovered, setIsHovered] = useState(false);

    // State for profile data
  const [profileData, setProfileData] = useState({});

   // Create an instance of the private Axios component
  const privateAxios = PrivateComponent();


  // Function to handle initials from a name
  const handleInitals = useCallback((name) => {
      // Split the name into an array
    const initalsArray = name?.split(' ');
    let initial = '';
     // If name has more than one part, take the first character of the first two parts
    if (initalsArray?.length > 1) {
      initial = initalsArray[0].charAt(0) + initalsArray[1].charAt(0);
    } else {
       // Otherwise, take the first character of the name
      initial = name?.charAt(0);
    }

    // Return the initials
    return initial;
  }, []);

   // Function to get profile data from API
  const getData = async () => {
    try {
        // Make a GET request to fetch profile data
      const response = await privateAxios.get(`/task/getShortProfileDetails/${data.email}`);
      // Set the profile data in state
      setProfileData(response.data);
    } catch (error) {
      console.error('Error:', error);// Log any errors
    }
  };

   // Fetch profile data when the component mounts or email changes
  useEffect(() => {
    getData();
  }, [data.email]);

  // Memoized initials based on profile data or email
  const memoizedInitials = useMemo(() => handleInitals(profileData?.data?.name || data?.email), [
    handleInitals, // Function to generate initials
    profileData?.data?.name, // Dependency: profile name
    data?.email, // Dependency: email
  ]);

  return (
    <div
      className={profileIconStyle.profileIconMain}
      onClick={getData}
      // onMouseEnter={getData}
    >
      {profileData?.data?.profilePic?.newImage ? (
        <img
          style={{ width: '26px', height: '26px', borderRadius: '50%' }}
          src={`${process.env.REACT_APP_BASEURL}/adminuser/file/${profileData?.data?.profilePic?.newImage}`}
        />
      ) : (
        <span style={{ width: width, height: height }}>{memoizedInitials}</span>
      )}

      {showProfileBanner && (
        <div className={profileIconStyle.ProfileIconMaximized}>
          <div>
            <div className={profileIconStyle.closeDiv}>
              <span>
                <CloseIcon />
              </span>
            </div>
            <div className={profileIconStyle.profileIconRow}>
              {profileData?.data?.profilePic?.newImage ? (
                <img
                  style={{ width: '35px', height: '35px', borderRadius: '50%' }}
                  src={`${process.env.REACT_APP_BASEURL}/adminuser/file/${profileData?.data?.profilePic?.newImage}`}
                />
              ) : (
                <div className={profileIconStyle.profileIconInitials}>
                  <span style={{ width: width, height: height }}>{memoizedInitials}</span>
                </div>
              )}
              <div className={profileIconStyle.pofileIconInfo}>
                <h3>{profileData?.data?.name}</h3>
                <p>{data?.email}</p>
                <p style={{ fontSize: '12px', color: 'gray' }}>{`(${profileData?.data?.role.name})`}</p>
              </div>
            </div>
          </div>
          { auth.email === data.email ? '':
            <div className={profileIconStyle.viewProfile}>
              <Link
                to={profileData?.data?.name !== null ? `/${auth.companyName}/userProfile` : ''}
                state={{ data: { ...profileData, email: data.email } }}
              >
                View Profile
              </Link>
            </div>
          }

        </div>
      )}
    </div>
  );
};

export default ProfileIcon;
