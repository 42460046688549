import React, { useEffect, useState } from 'react'
import Slickstyle from "../../ProcureRegister/ProcureRegister.module.css"
import Box from '@mui/material/Box';
import SliderTwo from 'components/SliderTwo/SliderTwo'
import ProdStyle from './ProductSelect.module.css'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import myaxios from 'api/axios'; // Importing Axios instance for API requests
import { useLocation, useNavigate } from 'react-router-dom';// React Router hooks for navigation
import useBackdrop from 'hooks/useBackdrop';// Custom hook for managing backdrop
import formStyle from 'components/FormsCommonCss/FormsCommonCss.module.css'
import RedirectRegistrationStyle from '../RedirectRegistrationStyle.module.css';
import CloseIcon from '@mui/icons-material/Close';
import { setLogout } from 'state/AuthState'// Redux action for logging out
import { useDispatch } from 'react-redux';
import useAuth from 'hooks/useAuth';// Custom hook for managing authentication state
import Alert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';




const ProductSelect = () => {

    const [confirmationMessage, setConfirmationMessage] = useState('');
    const navigate = useNavigate()// Navigation hook
    const location = useLocation();// Location hook for getting current location
    const { state } = location;// Destructuring state from location
    const { auth } = useAuth();// Custom hook for authentication
    const dispatch = useDispatch()// Dispatch hook for Redux

    const [availableCategories, setAvailableCategories] = useState([]); // State for available categories
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);// State for selected subcategories
    const [productList, setProductList] = useState([]);// State for product list
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Custom hook for managing backdrop
    const [catSearch, setCatSearch] = useState(""); // State for category search
    const [catSelectDropdown, setCatSelectDropdown] = useState(false);// State for category dropdown
    const [productSelected, setProductSelected] = useState("");// State for selected product
    const [loader, setLoader] = useState(true);// State for loader

    // Function to fetch categories from API
    const getCategories = async () => {
        openBackdrop();// Open backdrop while fetching data
        try {
            await myaxios.get(`/procurement/getAllCategories`).then(res => {// API call to fetch categories

                if (res.status === 200) {
                    setAvailableCategories(res.data);// Set available categories in state
                }
            })
            closeBackdrop();// Close backdrop after fetching data
        } catch (error) {
            closeBackdrop();// Close backdrop in case of error
        }
    }

    // Effect hook to fetch categories on component mount
    useEffect(() => {
        console.log("product select ", state)
        if (state === null) {// Redirect to login if state is null
            dispatch(setLogout());// Dispatch logout action
            navigate("/login", { state: {}, replace: true });// Navigate to login page
        }
        else {

            getCategories();// Fetch categories
            setLoader(false);// Set loader to false
        }

    }, [state])

    // Function to fetch subcategories of a category
    const getCategoryData = async (category) => {
        openBackdrop(); // Open backdrop while fetching data
        try {
            const res = await myaxios.get(`/procurement/getCategoriesByParent/${category.categoryId}`).then(res => {// API call to fetch subcategories
                setSelectedSubcategories([{ ...res.data }]);// Set selected subcategories
                setProductSelected("");// Clear selected product
               // Close backdrop after fetching data
            }).catch((err) => {
                closeBackdrop();// Close backdrop in case of error

            })

        } catch (err) {
            // showToast("error", "Error Fetching Sub Categories")// Show error toast

        }
        
        closeBackdrop();// Close backdrop in case of error
    };

    // Function to fetch products of a category
    const getPorductData = async (id, level) => {
        openBackdrop(); // Open backdrop while fetching data
        try {
            await myaxios.get(`/procurement/getAllProductByCategory/${id}`)// API call to fetch products
                .then((res) => {
                    setProductList(res.data);
                    setProductSelected("")
                })
                .catch((error) => {
                    setProductList([]);
                    setProductSelected("")
                    console.error('Error fetching products:', error);
                });

        } catch (error) {
            setProductList([]);
            setProductSelected("")
        }
        closeBackdrop();
    }

    // Effect hook to fetch products when subcategories change
    useEffect(() => {
        if (selectedSubcategories[selectedSubcategories.length - 1]?.branch.length === 0) {
            const sub = selectedSubcategories[selectedSubcategories.length - 1]
            getPorductData(sub.categoryId, sub.level);
        }
    }, [selectedSubcategories])

    // Function to toggle category selector dropdown
    const handleCatSelectorDropDown = () => {
        setCatSelectDropdown((prev) => !prev);
    }

    // Function to handle category selection
    const handleCategorySelector = async (cat) => {
        handleCatSelectorDropDown();
        await getCategoryData({ ...cat, level: 1 });
        setProductList([])
        setProductSelected("")
    }

    // Function to handle subcategory selection
    const handleSubcategorySelect = (subcategory, index) => {

        const tt = selectedSubcategories.filter((cat) => {
            return cat.level < subcategory.level;
        })
        const newSub = [
            ...tt,
            { ...subcategory },
        ]
        setProductList([]);
        setProductSelected("");
        setSelectedSubcategories(newSub);

    };

    // Function to build category tree
    const buildTree = (categories) => {
        let tree = [];
        let lookup = {};

        categories.forEach(category => {
            category.branch = [];
            lookup[category.categoryId] = category;
        });

        categories.forEach(category => {
            if (category.parent !== null) {
                const parent = lookup[category.parent];
                parent.branch.push(category);
            } else {
                tree.push(category);
            }
        });

        return tree;
    }


    const [addBtnToggle, setAddBtnToggle] = useState(true)
    const [productsArray, setProductsArray] = useState([]);
    const [successAddedProduct, setSuccessAddedProduct] = useState('')

    const [allCategories, setAllCategories] = useState([]);

    // Function to add product
    const handleAddPro = () => {
        if (productList.length === 0) {
            alert("Please Select a Product")
            return false;
        }
        else {
            let updatedCategories = selectedSubcategories.map((cat) => {
                return {
                    categoryId: cat.categoryId,
                    categoryName: cat.categoryName,
                    parent: cat.parent,
                    organisationId: state.data.orgId,//show be changed to state.data.orgId
                    level: cat.level,
                    branch: [],
                }
            })
            const updateProducts = productSelected?.map((pro) => {
                return {
                    productName: pro.name,
                    productId: pro.productId,
                    organisationId: state.data.orgId,//show be changed to state.data.orgId
                    categoryId: selectedSubcategories[selectedSubcategories.length - 1].categoryId,
                }
            })

            setAllCategories(prev => {
                const catId = prev.map(cat => cat.categoryId);
                const filterCat = updatedCategories.filter(cat => !catId.includes(cat.categoryId))

                return [
                    ...prev,
                    ...filterCat
                ]
            })

            setProductsArray(prev => {
                // Check if the product already exists in the productsArray
                const productIds = prev.map(product => product.productId);
                const filteredUpdateProducts = updateProducts.filter(product => !productIds.includes(product.productId));

                return [
                    ...prev,
                    ...filteredUpdateProducts,
                ];
            });

            setAddBtnToggle(false)
            if (productList.length > 1) {
                setSuccessAddedProduct("Products added successfully")
            } else {
                setSuccessAddedProduct("Product added successfully")
            }
            return true;
        }
    }

    const handleAddMore = () => {
        setProductList([]);
        setProductSelected("");
        setSelectedSubcategories([]);
        setAddBtnToggle(true);
        setSuccessAddedProduct('')
    }

    // Function to continue to the next step
    const handleContinue = async () => {
        const catTree = await buildTree(allCategories);
        let orgId = state?.data?.orgId;

        navigate('/productshow', {
            state: {
                products: productsArray,
                categories: catTree,
                flatCategory: allCategories,
                ...state.data
            }, replace: true
        });
    }

    return (
        <>
            <BackdropComponent />
            <div className={ProdStyle.productSelectMain}>
                {!loader && <>

                    <div className={ProdStyle.productSelectLeft}>
                        <div>
                            <div>
                                {confirmationMessage && (
                                    <div className={ProdStyle.ConfirmationMessage}>
                                        {/* <p><CheckCircleOutlineIcon /> {confirmationMessage}</p> */}
                                    </div>
                                )}

                            </div>
                            {successAddedProduct !== '' ? <Alert className={ProdStyle.succesSVg} style={{ border: "1px solid green", color: "green" }} severity="success">{successAddedProduct}</Alert> : ''}
                            <div className={ProdStyle.productSelectHeaders}>

                                {
                                    addBtnToggle && productsArray.length > 0 &&
                                    <button className={ProdStyle.backButtonCSS} onClick={() => setAddBtnToggle(false)} style={{
                                        width: "15%",
                                        border: " 1px solid #16469D",
                                        backgroundColor: "white", cursor: "pointer"
                                    }}><ArrowBackIcon /></button>
                                }
                                <h1>We are thrilled to have you on board</h1>
                                <p>These will be the products that you and your team will be working on.</p>
                            </div>

                            <div className={ProdStyle.productSelectors}>
                                <div className={ProdStyle.productSelectorColumn}>
                                    <label htmlFor="">Select Category</label>
                                    <div className={ProdStyle.productSelector}  >
                                        <p onClick={() => {
                                            if (addBtnToggle) {

                                                handleCatSelectorDropDown();
                                            }

                                        }} >
                                            {selectedSubcategories[0]?.categoryName || "Please Select A category"}
                                            <span>
                                                {catSelectDropdown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </span>
                                        </p>
                                        {catSelectDropdown &&
                                            <>

                                                <div className={ProdStyle.dropdownBackDrop} onClick={handleCatSelectorDropDown}>
                                                </div>
                                                <div className={ProdStyle.productDropDownDiv} >

                                                    <div className={ProdStyle.productSearchBar}>
                                                        <input type="text" value={catSearch} onChange={(e) => setCatSearch(e.target.value)}
                                                            placeholder="Search for Products..." />
                                                    </div>
                                                    <ul className={ProdStyle.productDropDownList}>

                                                        {availableCategories.filter((cat) => cat.name.trim().toLowerCase().includes(catSearch.toLowerCase())).map((cat) => {
                                                            return (
                                                                <li onClick={() => handleCategorySelector(cat)}>{cat.name}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                                {selectedSubcategories.length > 0 &&
                                    selectedSubcategories.map((selectedCategory, index) => {

                                        if (selectedCategory.branch.length > 0) {
                                            return (
                                                <div className={ProdStyle.productSelectorColumn}>
                                                    <label> Sub-Category</label>
                                                    <SubcategorySelector
                                                        category={selectedCategory}
                                                        selectedSubcategories={selectedSubcategories}
                                                        onSelectSubcategory={(subcategory) => handleSubcategorySelect(subcategory, index)}
                                                        index={index}
                                                        addBtnToggle={addBtnToggle}
                                                    />
                                                </div>
                                            )
                                        }
                                    }
                                    )}


                                {selectedSubcategories[selectedSubcategories.length - 1]?.branch.length === 0 &&
                                    <div className={ProdStyle.productSelectorColumn}>
                                        <label>
                                            Product </label>
                                        <ProductSelector productList={productList} productSelected={productSelected} addBtnToggle={addBtnToggle} setProSelected={setProductSelected} productsArray={productsArray} />

                                    </div>}

                            </div>
                            <div className={ProdStyle.productSelectbtn}>
                                {
                                    addBtnToggle &&
                                    <>
                                        <button style={{ cursor: "pointer" }} onClick={() => handleAddPro()} className={ProdStyle.proSelectPrimaryBtn}>Add Product</button>
                                    </>
                                }

                                {
                                    !addBtnToggle &&
                                    <>
                                        <button style={{ cursor: "pointer" }} onClick={handleContinue} className={ProdStyle.proSelectPrimaryBtn}>Next</button>
                                        <button style={{ cursor: "pointer" }} onClick={() => handleAddMore()} className={ProdStyle.proSelectSecondaryBtn}>Add More Products</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <Box className={Slickstyle.boxTwo} >
                        <SliderTwo />
                    </Box>
                </>
                }
            </div>
        </>
    )
}
export default ProductSelect

// Component for selecting subcategories
const SubcategorySelector = ({ category, selectedSubcategories, onSelectSubcategory, addBtnToggle, index }) => {

    const [subCatSelectDropDown, setSubCatSelectDropDown] = useState(false);// State for dropdown visibility
    const [subCatSelected, setSubCatSelected] = useState("");// State for selected subcategory
    const [subCatSearch, setSubCatSearch] = useState("");// State for subcategory search query

    // Function to toggle subcategory dropdown
    const handleSubCatSelectorDropDown = () => {
        setSubCatSelectDropDown(prev => !prev)
    }

    // Function to handle subcategory selection
    const handleSubCategorySelector = (subCat) => {
        handleSubCatSelectorDropDown();
        onSelectSubcategory(subCat);
        setSubCatSelected(subCat.categoryName);

    }

    return (
        <div className={ProdStyle.AllProductmodal} key={index} >
            <div className={ProdStyle.productSelector} >

                <p onClick={() => {
                    if (addBtnToggle) {
                        handleSubCatSelectorDropDown();
                    }
                }}

                >{(index < (selectedSubcategories.length - 1) && subCatSelected) || "Please Select A Sub-Category"}
                    <span>
                        {subCatSelectDropDown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </span>
                </p>
                {
                    subCatSelectDropDown &&
                    <>
                        <div className={ProdStyle.dropdownBackDrop} onClick={handleSubCatSelectorDropDown}>
                        </div>
                        <div className={ProdStyle.productDropDownDiv}>
                            <div className={ProdStyle.productSearchBar}>
                                <input type="text" value={subCatSearch} onChange={(e) => setSubCatSearch(e.target.value)} />
                            </div>
                            <ul className={ProdStyle.productDropDownList} >

                                {category.branch.filter((subCat) => {
                                    return subCat.categoryName && subCat.categoryName.trim().toLowerCase().includes(subCatSearch.toLowerCase());
                                }).map((subCat) => {
                                    return (
                                        <li onClick={() => handleSubCategorySelector(subCat)}>{subCat.categoryName}</li>
                                    )
                                })}

                            </ul>
                        </div>
                    </>
                }
            </div>

        </div>
    )
}

// Component for selecting products
const ProductSelector = ({ productSelected, productList, addBtnToggle, setProSelected, productsArray }) => {

    // State variables
    const [availableCategories, setAvailableCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    // Effect hook to update available categories
    useEffect(() => {
        setAvailableCategories(productList)
        if (productList.length === 0) {
            setSelectedCategories([])
        }
    }, [productList])

    // Function to toggle dropdown visibility
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    // Effect hook to update selected categories
    useEffect(() => {
        setProSelected(selectedCategories)
    }, [selectedCategories])

    // Function to handle category selection
    const handleCategoryToggle = (category) => {
        if (!productsArray.some((selectedProduct) => selectedProduct.productId === category.productId)) {
            const categoryIndex = selectedCategories.findIndex((cat) => cat.name === category.name);

            if (categoryIndex !== -1) {
                // Category already selected, remove it
                const updatedCategories = [...selectedCategories];
                updatedCategories.splice(categoryIndex, 1);
                setSelectedCategories(updatedCategories);
            } else {
                // Category not selected, add it
                setSelectedCategories([...selectedCategories, category]);
            }
        }
    };

    // Function to remove selected category
    const handleRemoveCategory = (event, category) => {
        event.stopPropagation();

        // Remove the category from the selectedCategories array
        const updatedCategories = selectedCategories.filter((cat) => cat.name !== category.name);
        setSelectedCategories(updatedCategories);
    };

   
    return (

        <div className={formStyle.inputRow}>
            <div className={RedirectRegistrationStyle.dropdownContainer}>
                <div
                    className={`${RedirectRegistrationStyle.dropdown} ${dropdownOpen && RedirectRegistrationStyle.open}`}
                    onClick={() => {

                        if (addBtnToggle) {
                            toggleDropdown();
                        }
                    }
                    }
                >
                    <div className={RedirectRegistrationStyle.selectedCategories}>

                          {/* Mapping over selected categories to display */}
                        {selectedCategories.length > 0 ?
                            selectedCategories.map((category, index) => (
                                <div key={index} className={RedirectRegistrationStyle.selectedCategory}>
                                    {category.name}
                                    <CloseIcon
                                        className={RedirectRegistrationStyle.closeIcon}
                                        onClick={(event) => handleRemoveCategory(event, category)}
                                    />
                                </div>
                            )) :
                            'Select products'}
                    </div>
                    <KeyboardArrowDownIcon
                        className={RedirectRegistrationStyle.arrowIcon}
                        onClick={() => {
                            if (addBtnToggle) {
                                toggleDropdown();
                            }
                        }
                        }
                    />
                </div>

                  {/* Dropdown content */}
                {dropdownOpen && (
                    <>
                        <div className={ProdStyle.dropdownBackDrop} onClick={toggleDropdown}>
                        </div>
                        <div className={RedirectRegistrationStyle.dropdownContent}>
                            {availableCategories.map((category, index) => (
                                <div
                                    key={index}
                                    className={RedirectRegistrationStyle.dropdownItem}
                                    onClick={() => handleCategoryToggle(category)}
                                    style={{
                                        backgroundColor: productsArray.some((selectedProduct) => selectedProduct.productId === category.productId) ? '#efeaea' : 'white',
                                        color: productsArray.some((selectedProduct) => selectedProduct.productId === category.productId) ? '#737374' : 'black',
                                        cursor: productsArray.some((selectedProduct) => selectedProduct.productId === category.productId) ? 'auto' : 'pointer',
                                    }}
                                >
                                    <span>{category.name}</span>
                                    <input
                                        type="checkbox"
                                        name='categorySelect'
                                        checked={selectedCategories.some((cat) => cat.name === category.name) || productsArray.some((selectedProduct) => selectedProduct.productId === category.productId)}
                                        readOnly
                                        required
                                        disabled={productsArray.some((selectedProduct) => selectedProduct.productId === category.productId)}
                                    />
                                </div>
                            ))}
                        </div>
                    </>
                )}

            </div>

        </div>

    )
} 