import React from 'react';
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ProfileStyle from '../../pages/ProfilePage/ProfileStyle.module.css';
import workSpaceStyle from '../../components/WorkSpaceCommonCss/WorkSpaceCommonCss.module.css';

const CustomModal = ({ isOpen, onClose, title, content }) => {
  if (!isOpen) return null;

  const handleClose = (event) => {
    if (event.target.closest('.close-icon')) {
      onClose();
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={workSpaceStyle.modalCont}>
        <form className={ProfileStyle.ChangeNumberModal} action="" onSubmit={(e) => e.preventDefault()}>
          <div className={workSpaceStyle.modalHeader}>
            <h3>{title}</h3>
            <div onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          {content}
        </form>
      </div>
    </Modal>
  );
};
 
export default CustomModal;
