import React from 'react'
import chatRowStyle from './ChatRows.module.css'
import ProfileIcon from '../ProfileIcon/ProfileIcon';


const ChatRows = ({children}) => {

    const handleInitals = (name) => {
        const initalsArray = name.split(" "); // Split name into words
        let initial = "";
        if (initalsArray.length > 1) {// If there are more than one word
    
          initial = initalsArray[0].charAt(0) + initalsArray[1].charAt(0); // Take first letters of first two words
        }
        else {
          initial = initalsArray[0].charAt(0);// Otherwise, take first letter of the only word
        }
    
        return initial; // Return initials
    
      }
    
  return (
    <div className={chatRowStyle.chatRowContainer}>
   {children}
   </div>
  )
}

export default ChatRows
