import React, { useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import revisionEditStyle from '../../pages/RevisionEdit/RevisionEdit.module.css'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Tooltip from '@mui/material/Tooltip';
import DocumentChatPannel from 'components/DocumentChat/DocumentChatPannel';
import DocumentChatRows from 'components/DocumentChat/DocumentChatRows';
import DocumentChatInput from 'components/DocumentChat/DocumentChatInput';
import { Link } from 'react-router-dom';
import { PrivateComponent } from 'api/axios';
import DocumentChatRow from 'components/DocumentChat/DocumentChatRow';
import useAuth from 'hooks/useAuth';
import useBlobDownload from 'hooks/useBlobDownload';
import useBackdrop from 'hooks/useBackdrop';
import ChatIconWithBadge from 'components/ChatIconWithBadge/ChatIconWithBadge';



// =====================mui compoents====================
const TempleteDocumentsTab = ({ addDocumentModal, handleAddDocumentModal, getDataProcure, documents, handleDocumentUpload, setDocuments, revisionId }) => {
    // Importing necessary hooks and functions
    const { auth } = useAuth(); // Authentication hook
    const privateAxios = PrivateComponent(); // Axios instance for private requests
    const { fileDownload } = useBlobDownload(); // Blob download hook
    const { openBackdrop, closeBackdrop, BackdropComponent } = useBackdrop(); // Backdrop hooks

      // State variables initialization
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const inputRef = useRef();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFileNames, setSelectedFileNames] = useState([])
  const [documentMore, setDocumentMore] = useState("");
  const [renameModal, setRenameModal] = useState(false)
  const [renameText, setRenameText] = useState("");
  const [docId, setDocId] = useState("");
  const [documentChats, setDocumentChats] = useState([])
  const [docChatLoading, setDocChatLoading] = useState(true);

   // Function to handle file input change
  const handleFileInputChange = (e) => {
    const files = Array.from(e.target.files);
    // setFiles(files); 
    setSelectedFiles([...selectedFiles, ...files]);
    setSelectedFileNames([...selectedFileNames, ...files.map((file) => file.name)]); // Update selected file names
  };

   // Function to remove selected file
  const handleRemoveSelectedFile = (index) => {
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles.splice(index, 1);

    const updatedSelectedFileNames = [...selectedFileNames];
    updatedSelectedFileNames.splice(index, 1);

    setSelectedFiles(updatedSelectedFiles);
    setSelectedFileNames(updatedSelectedFileNames);
  };


      // Function to truncate file name if it's too long
  function truncateFileName(fileName, maxLength) {
    if (typeof fileName !== 'string' || fileName.length === 0) {
      return ''; // Return an empty string or handle this case as needed
    }

    if (fileName.length <= maxLength) {
      return fileName;
    }

    const truncatedFileName = fileName.substring(0, maxLength - 5) + '...';
    return truncatedFileName;
  }

   // Function to toggle delete modal
  const handleDeleteModal = (id) => {
    setDeleteModal(prev => !prev);
    if (id !== null) {
      setDeleteId(id)
    }
  }

   // Function to toggle rename modal
  const handleRenameModal = (name) => {
    setRenameModal(prev => !prev)
    if (name !== null) {
      setRenameText(name)
    }

  }

  const handleDocumentValidation = async () => {

  }


    // Function to handle document deletion
  const handleDocDelete = async (docId) => {
    try {
      await privateAxios.delete(`/commercial/deleteDocumentOfCommercialRevision/${docId}`).then(res => {
        // console.log(res.data);
        getDataProcure();
      }).catch(err => {
        // console.log(err)
      })
    } catch (error) {

      // console.log(error)
    }
  }

     // Function to handle document renaming
  const handleRenameDoc = async (e) => {
    e.preventDefault();
    try {
      // console.log(docId)
      if (docId) {
        await privateAxios.post(`/commercial/renameDocumentOfCommercialRevision/${docId}?name=${renameText}`).then(res => {
          // console.log(res.data);
          handleRenameModal();
          getDataProcure()
          setRenameText("");
        }).catch(err => {
          // console.log(err)
          handleRenameModal();
        })
      }
      else {
        alert("Document ID Not Present")
        handleRenameModal();
      }

    } catch (error) {
      handleRenameModal();
      // console.log(error)
    }
  }


    // State variables for chat
  const [openConfidential, setOpenConfidential] = useState(false)
  const [documentChatOpen, setDocumentChatOpen] = useState("")

     // Function to handle opening/closing document chat
  const handleChatOpen = (data, docId) => {
    if (data === documentChatOpen) {
      setDocumentChatOpen("")
      setDocumentChats("");
    }
    else {
      getDocumentChat(docId)
      setDocumentChatOpen(data);
    }
  }

  const [documentChatValue, setDocumentChatValue] = useState("")

// Function to handle sending document chat
  const handleDocumentChatSend = async (e, documentId) => {
    e.preventDefault();
    try {
      if (documentChatValue.length > 0) {

        await privateAxios.post(`/commercial/addRemarkOncommercialDocumentTemplate/${auth.userData.id}/${documentId}`, { remark: documentChatValue }).then(res => {
          // console.log(res.data);
          setDocumentChatValue("");
          // console.log(documentChatValue)
          getDocumentChat(documentId);
        }).catch((err) => {
          // console.log(err);
        })
      }
    } catch (error) {
      // console.log(error)
    }

  }

      // Function to fetch document chat
  const getDocumentChat = async (documentId) => {
    handleChatNotification();
    try {
      await privateAxios.get(`/commercial/getRemarksOfcommercialDocumentTemplate/${documentId}`).then(res => {

        setDocumentChats(res.data);
        setDocChatLoading(false)
      }).catch((err) => {
        // console.log(err);
        setDocChatLoading(false)
      })
    } catch (error) {
      // console.log(error)
      setDocChatLoading(false)
    }

  }


     // Function to handle showing/hiding more options for documents
  const handleDocumentMore = (index) => {

    if (documentMore === index) {
      setDocumentMore("");
    }
    else {
      setDocumentMore(index)
    }

  }


 // State for chat notification
  const [reRender, setReRender] = useState(false);

      // Function to handle chat notification
  const handleChatNotification = () => {
    setReRender(prev => !prev);

  }



  return (
    <>
      <div className={revisionEditStyle.documentsTabMain}>

        <table className={revisionEditStyle.DocumentTable}>
          <thead>
            <tr className={`${revisionEditStyle.DocumentTableRow} ${revisionEditStyle.DocumentTableHead}`}>
              <td>Title</td>
              <td>Date Added</td>
              <td>Remark</td>
              <td>
                <div className={revisionEditStyle.documentIconRow}>

                  <a><FileDownloadIcon /></a>


                  <a><MoreVertIcon /></a>

                </div>
              </td>

            </tr>
          </thead>
          <tbody>
            {documents?.map((doc, index) => {
              if (doc.docType !== "CONFIDENTIAL") {


                return (
                  <tr key={index} className={revisionEditStyle.DocumentTableRow}>
                    <td>{doc.name || doc.url}</td>
                    <td>{doc?.date?.split('T')[0] || "Null"}</td>
                    <td style={{ position: "relative" }} >
                      <Tooltip title='Comments'>
                        <span onClick={() => { handleChatOpen(index, doc.id); }}>
                          <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialDocuments`} id={doc.id} reRender={reRender} />

                        </span>
                      </Tooltip>
                      {revisionId === "0" ? <>{documentChatOpen === index && <div className={revisionEditStyle.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                        <DocumentChatRows>
                          {docChatLoading ? <div>Chat Loading ..... </div> :

                            documentChats.length === 0 ? <p>No Previous Chats</p> :
                              documentChats?.map((chat, index) => {
                                return (
                                  <DocumentChatRow key={index}
                                    name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                    email={chat.email}
                                  // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                  />
                                )

                              })}
                        </DocumentChatRows>
                        <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                      </DocumentChatPannel>}
                    </td>
                    <td> <div className={revisionEditStyle.documentIconRow}>

                      <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}><FileDownloadIcon /></Link>


                      <Link style={{ position: "relative" }} className={revisionEditStyle.documentMoreDots} onClick={(e) => { e.preventDefault(); handleDocumentMore(index) }} ><MoreVertIcon />
                        {documentMore === index && <ul style={{ position: "absolute" }}>
                          {
                            revisionId !== "0"

                            && <li onClick={() => { handleRenameModal(doc?.name || ""); setDocId(doc.id) }}> <span><DriveFileRenameOutlineIcon /></span>Rename</li>
                          }
                          {
                            revisionId !== "0"
                            // revisionId === "0"
                            &&
                            <li onClick={() => handleDeleteModal(doc.id)}> <span><DeleteOutlineIcon /></span>Delete</li>
                          }
                          {/* <li> <span><DeleteOutlineIcon /></span>Some Other Option</li> */}
                        </ul>}
                      </Link>

                    </div></td>
                  </tr>
                )
              }
            })
            }

          </tbody>
        </table>




        <div className={revisionEditStyle.accordianDocumentab}>
          <div className={revisionEditStyle.accordianDocumentabHeader} onClick={() => setOpenConfidential(prev => !prev)}><span>
            {openConfidential ? <ExpandMoreIcon /> : <KeyboardArrowRightIcon />}
          </span>Confidential Documents</div>

          <div className={revisionEditStyle.accordianDocumentabBody}>

            {openConfidential &&
              <table className={revisionEditStyle.DocumentTable}>
                <tbody>
                  {documents?.map((doc, index) => {
                    if (doc.docType === "CONFIDENTIAL") {
                      return (
                        <tr key={index} className={revisionEditStyle.DocumentTableRow}>
                          <td>{doc.name || doc.url}</td>
                          <td>{doc?.date?.split('T')[0] || "Null"}</td>
                          <td style={{ position: "relative" }} >
                            <Tooltip title='Comments'>
                              <span onClick={() => { handleChatOpen(index, doc.id); }}>
                                <ChatIconWithBadge url={`/commercial/getNotficationCountOfCommercialDocuments`} id={doc.id} reRender={reRender} />

                              </span>

                            </Tooltip>
                            {revisionId === "0" ? <>{documentChatOpen === index && <div className={revisionEditStyle.DefaultRevisionDocument} >Please Make A Copy of Revision Then you can comment On Documents</div>} </> : <DocumentChatPannel documentId={"2"} onClose={documentChatOpen === index} >
                              <DocumentChatRows>
                                {docChatLoading ? <div>Chat Loading ..... </div> :

                                  documentChats.length === 0 ? <p>No Previous Chats</p> :
                                    documentChats?.map((chat) => {
                                      return (
                                        <DocumentChatRow key={index}
                                          name={chat.name} date={chat.dateCreated} chat={chat.remark}
                                          email={chat.email}
                                        // name={"Rainder"} date={"23-08-1232"} chat={"soemthing"}
                                        />
                                      )

                                    })}
                              </DocumentChatRows>
                              <DocumentChatInput handleDocumentChatSend={handleDocumentChatSend} documentId={doc.id} chatValue={documentChatValue} setChatValue={setDocumentChatValue} />
                            </DocumentChatPannel>}
                          </td>
                          <td>
                            <div className={revisionEditStyle.documentIconRow}>
                              <Link onClick={(e) => { e.preventDefault(); fileDownload(doc.url, doc.name) }}>
                                <FileDownloadIcon />
                              </Link>
                            </div>

                          </td>
                        </tr>
                      )
                    }
                  })

                  }

                </tbody>
              </table>

            }
          </div>

          <BackdropComponent />
        </div>


      </div>

    </>
  )
}

export default TempleteDocumentsTab
